import {getFetchInterval} from '../useful'

export const strategiesEquityProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const query = `{
      strategiesStatistics {
        strategyName
        accountId
        remoteId
        numberOfFollowers
        numberOfTrades
        overallPnL
        pnlSixtyDays
        followersEquity
        whitelabel
        withdrawalsThirtyDays
        withdrawalsNinetyDays
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Strategies equity loaded',
    (state, res) =>(
      {
        ...state,
        statistics: res.strategiesStatistics || [],
      }
    ),
    [res]
  ),

}
