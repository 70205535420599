import {companies} from '@bdswiss/common-enums'
import {getRuntimeBaseDomain} from '../config'


function getSecondLevelDomain(hostname) {
  const result =  hostname.split('.').reverse().slice(1).reverse().join('.')
  // it's one of the simple ways to allow for multi-leve-domain names and still account for staging
  // if you try get N-1 domain here, then domains like mauritius.tauromarkets.com won't be feasable
  const cleanerResult = result.replace('.skyground-staging','')
  // console.log(hostname, cleanerResult)
  return cleanerResult
}
export function companyName () {
  let companyName
  const hostname = getRuntimeBaseDomain()
  // we take second level domain to have the same code on local machine and production
  // not sure how it's gonna play with staging server
  const currentDomainName = getSecondLevelDomain(hostname)
  for (const company_key of Object.keys(companies)) {
    const domainName = getSecondLevelDomain(companies[company_key].domain)
    if (currentDomainName.endsWith(domainName)) {
      companyName = companies[company_key].value.toLowerCase()
      break
    }
  }
  // console.log('Company name:', companyName)
  return companyName
}
