import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import BookAllocationForm from './BookAllocationForm'

export default class BookAllocationCreate extends PureComponent {
  render() {
    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/book-allocation"
            active={false}
          >
            <BreadcrumbItem>
              BookAllocation
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            Create
          </BreadcrumbItem>
        </Breadcrumb>
        <BookAllocationForm />
      </Container>
    )
  }
}
