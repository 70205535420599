import {generateKeys} from '@bdswiss/common-enums'

export const depositorsFilterOptions = generateKeys({
  depositors: {
    value: 'true',
    label: 'Depositors',
  },
  notDepositors: {
    value: 'false',
    label: 'Non-Depositors',
  },
})

export const clientTabs = generateKeys({
  details: {
    label: 'Details',
    sidebars: {
      changeClientCompany: {name: 'changeClientCompany'},
      attachToAffiliate: {name: 'attachToAffiliate'},
      securityCode: {name: 'securityCode'},
      resetPassword: {name: 'resetPassword'},
      resetTotp: {name: 'resetTotp'},
      edit: {name: 'edit'},
      accountChecks: {name: 'accountChecks'},
      updateWalkthroughs: {name: 'updateWalkthroughs'},
      sendInvitation: {name: 'sendInvitation'},
      updateEconomicProfile: {name: 'updateEconomicProfile'},
      updatePotentials: {name: 'updatePotentials'},
      generateTaxDocument: {name: 'generateTaxDocument'},
      linkClient: {name: 'linkClient'},
      editPromotingCountries: {name: 'editPromotingCountries'},
      loginVerificationDetails: {name: 'loginVerificationDetails'},
      sendEmailTemplate: {name: 'sendEmailTemplate'},
      newClientWithdrawal: {name: 'newClientWithdrawal'},
      newClientDeposit: {name: 'newClientDeposit'},
      createTransferClient: {name: 'createTransferClient'},
    },
  },
  accounts: {
    label: 'Accounts',
    sidebars: {
      createTransfer: {name: 'createTransfer'},
      createVpsSubscription: {name: 'createVpsSubscription'},
    },
  },
  vps: {
    label: 'VPS'
  },
  deposits: {
    label: 'Deposits',
  },
  withdrawals: {
    label: 'Withdrawals',
  },
  documents: {
    label: 'Documents',
  },
  positions: {
    label: 'Positions',
  },
  acknowledgements: {
    label: 'Acknowledgements',
    sidebars: {
      addAppendix: {name: 'addAppendix'},
    },
  },
  referrals: {
    label: 'Referrals',
  }
})

export const eventCategories = generateKeys({
  appointment: {
    keyPrefix: 'app',
    tooltipPrefix: 'Start Date',
  },
  activityLog: {
    keyPrefix: 'al',
    tooltipPrefix: 'Date',
  },
})

export const events = generateKeys({
  fetchProviders: {},
})

export const clientPageTypes = generateKeys({
  all: {
    label: 'All'
  },
  retail: {
    label: 'Retail',
  },
  partners: {
    label: 'Partners',
  },
  strategic: {
    label: 'Strategic Partners',
  },
})
