import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {withdrawalStatuses, withdrawalTypes, companies, withdrawalPaymentVendors, yesNo, clientTypes,
  userDepartments, depositVendors, orderDirections, currencies,
} from '@bdswiss/common-enums'
import {merge, kebabCase, debounce, map, filter, some, range, get} from 'lodash'
import {canQueryWithdrawals, canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import {Row, Col, Card, Table, Pagination, ButtonGroup, Button, Form, Container, InputGroup, Modal} from 'react-bootstrap'
import {events} from '../enums'
import style from './withdrawals.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {isCentAccount, orderByFields} from '../common/utils'
import SelectAgent from '../components/SelectAgent'
import StylishSelect from '../components/StylishSelect'
import {getWithdrawalStatusLabel} from '../utils/rendering'
import WithdrawalEditor from '../components/WithdrawalEditor'
import SelectAgentFilter from '../components/SelectAgentFilter'
import AccountTypesFilter from '../components/AccountTypesFilter'
import ExportWithdrawalsModal from '../components/ExportWithdrawalsModal'
import {safeLinkClientWithdrawals, linkClientDocuments} from '../utils/links'
import {getAllowedCompanies, getWithdrawalVendorLabel} from '../utils/general'
import {withdrawalsProvider, thisMonthWithdrawalsProvider} from './providers'
import {getFormattedAmount, getFullName, unassigned, getPageCount, readableDate,
  getWithdrawalPaymentMethodDetails,
  getPageRange} from '../useful'
import {countriesAndRegionsOptions, createCountriesFilter} from '../utils/country'
import {compose, provideProps, mountDataProviders, predispatch, uiMount, checkRights} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {companyName} from '../utils/companyName'
import {fxRatesProvider} from '../fxrates/providers'

const DEPOSIT_VENDORS = [
  depositVendors.safecharge,
  depositVendors.checkout,
  depositVendors.cardpay,
  depositVendors.appex,
  depositVendors.powercash21,
  depositVendors.paypugs,
]

const EXPORT_LIMIT = 20000

class Withdrawals extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
    this.doFilterByAmount = debounce(this.doFilterByAmount, 1500)

    this.state = {
      exportPage: 1,
      exportModalShown: false,
    }
  }

  static contextTypes = {
    withdrawalsProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    thisMonthWithdrawalsProvider: PropTypes.object.isRequired,
    fxRatesProvider: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {
      createdFrom: moment().subtract(14, 'days'),
    }
    this.setState({dateFilters: stateDateFilters})

    const stateAmountFilters = this.props.amountFilters || {
      amountFrom: '',
      amountTo: '',
    }
    this.setState({amountFilters: stateAmountFilters})
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.withdrawalsProvider.fetch()
    this.context.thisMonthWithdrawalsProvider.fetch()
    this.context.fxRatesProvider.fetch()
  }

  activateFilterButton(value) {
    this.props.uiDispatch(
      'Filter withdrawals by status',
      (state) => ({...state, withdrawalStatus: value, page: 1, showWithdrawalId: undefined})
    )
  }

  activateSalesAgentFilter(value) {
    this.props.uiDispatch(
      'Filter withdrawals by sales agent',
      (state) => ({...state, salesAgentFilter: value, page: 1, showWithdrawalId: undefined})
    )
  }

  activateAccountTypeFilter(filter) {
    this.props.uiDispatch(
      'Filter withdrawals by accounts',
      (state) => ({...state, accountsFilter: filter, page: 1, showWithdrawalId: undefined})
    )
  }

  doFilterByCompany(company) {
    this.props.uiDispatch(
      'Filter withdrawals by company',
      (state, arg) => ({...state, companyFilter: company, page: 1})
    )
  }

  doWithdrawalsSearch(searchText) {
    this.props.uiDispatch(
      'Search withdrawals',
      (state, arg) => ({...state, withdrawalsSearch: arg, withdrawalsPage: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  handleAmountFilterChanged(name, value) {
    const {amountFilters} = this.state
    this.setState({
      amountFilters: {...amountFilters, [name]: value},
    })
    this.doFilterByAmount()
  }

  doFilterByAmount() {
    const {amountFilters} = this.state
    this.props.uiDispatch(
      'Filter withdrawals by amount range',
      (state, arg) => ({...state, amountFilters, page: 1}),
      [amountFilters]
    )
  }

  handleDateFilterChanged(name, value) {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })
    if (!value || moment.isMoment(value)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilters} = this.state
    if (dateFilters && moment.isMoment(dateFilters.createdFrom)) {
      dateFilters.createdFrom.startOf('day')
    }
    if (dateFilters && moment.isMoment(dateFilters.createdTo)) {
      dateFilters.createdTo.endOf('day')
    }
    this.props.uiDispatch(
      'Filter documents by dates',
      (state, arg) => ({...state, dateFilters, page: 1})
    )
  }

  doFilterByClientTypes(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter withdrawals by client types',
      (state, arg) => ({...state, clientTypesFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByVendors(selections) {
    const values = selections && selections.length > 0 && selections.map((v) => v.value)
    this.props.uiDispatch(
      'Filter withdrawals by vendor',
      (state, arg) => ({...state, vendorsFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByTypes(selections) {
    const values = selections && selections.length > 0 && selections.map((v) => v.value)
    this.props.uiDispatch(
      'Filter withdrawals by types',
      (state, arg) => ({...state, typesFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByCountry(selection) {
    this.props.uiDispatch(
      'Filter withdrawals by country or region',
      (state) => ({
        ...state,
        countriesFilter: createCountriesFilter(selection),
        countriesFilterValue: selection.value,
        page: 1,
      })
    )
  }

  doFilterByDepositVendor(selection) {
    this.props.uiDispatch(
      'Filter withdrawals by deposit vendor',
      (state) => ({
        ...state,
        depositVendorFilterValue: selection.value,
        page: 1,
      })
    )
  }

  doFilterByTopVip(e) {
    const {topVipFilter} = this.state
    this.props.uiDispatch(
      'Filter withdrawals by VIP',
      (state, arg) => ({...state, topVipFilter: e.value, page: 1}),
      [topVipFilter]
    )
  }

  doOrderByFilter(e) {
    const {orderByFilter} = this.state
    this.props.uiDispatch(
      'Order withdrawals by filter',
      (state, arg) => ({...state, orderByFilter: e.value, page: 1}),
      [orderByFilter]
    )
  }

  doOrderDirectionFilter(e) {
    const {orderDirectionFilter} = this.state
    this.props.uiDispatch(
      'Order withdrawals by direction',
      (state, arg) => ({...state, orderDirectionFilter: e.value, page: 1}),
      [orderDirectionFilter]
    )
  }

  doFilterByHasPendingFundingDocuments(selection) {
    const {hasPendingFundingDocumentsFilter} = this.state
    this.props.uiDispatch(
      'Filter withdrawals by client has pending funding documents',
      (state, arg) => ({...state, hasPendingFundingDocumentsFilter: selection.value, page: 1}),
      [hasPendingFundingDocumentsFilter]
    )
  }

  exportWithdrawals(dateFrom, dateTo, onlyBannedCountries) {
    this.setState({exporting: true})
    this.props.actions.withdrawals.exportWithdrawals({dateFrom, dateTo, onlyBannedCountries}).then((res) => {
      res.exportWithdrawals.forEach(href => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = href
        a.target = '_blank'
        document.body.appendChild(a)
        a.click()
      })
      this.setState({showExportWithdrawalsModal: false, exporting: false})
    }).catch((e) => {
      e.isShowActualError = true
      this.context.logError(e)
      this.setState({exporting: false})
    })
  }

  renderFilterButton(value, label, filterStatus) {
    return (
      <Button
        key={value}
        id={`t-toolbar-filter-${kebabCase(label)}`}
        style={{fontSize: 11}}
        variant="outline-secondary"
        active={value === filterStatus}
        onClick={() => this.activateFilterButton(value)}
      >{label}</Button>
    )
  }

  onSearch() {
    this.setState({loading: true})
    const {exportPage} = this.state
    this.props.actions.withdrawals.withdrawalsExportSearch(this.props, exportPage, EXPORT_LIMIT).then((res) => {
      this.setState({loading: false, exportModalShown: false, exportPage: 1})
      this.context.showNotification({
        title: 'Exports Tool',
        message: 'Export Tool working in background. You will receive the report on your email shortly',
        position: 'tr',
        level: 'success',
      })
    }).catch((e) => {
      this.setState({loading: false})
      this.context.showNotification({
        message: e.message,
        level: 'error',
        autoDismiss: 0,
      })
    })
  }

  renderFilters() {
    const {agents, salesAgentFilter, accountsFilter, viewer, companyFilter, clientTypesFilter, hasPendingFundingDocumentsFilter,
      vendorsFilter, countriesFilterValue, topVipFilter, withdrawalsSearch, typesFilter, partnersAgentFilter,
      depositVendorFilterValue, orderByFilter, orderDirectionFilter,
    } = this.props

    const {searchText} = this.state || {}
    const companyOptions = [
      {label: 'All Companies', value: undefined},
      ...StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer)),
    ]
    const {dateFilters, amountFilters} = this.state
    const amountFromStyle = amountFilters.amountFrom ? 'success' : null
    const amountToStyle = amountFilters.amountTo ? 'success' : null
    const activeAgents = filter(agents, (agent) => agent.isActive)
    const partnerAgents = filter(activeAgents, (agent) =>
      some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)))

    return (
      <div>
        <Row>
          <Col xs={4}>
            <span className={style.label}>&nbsp;</span>
            <InputGroup>
              <Form.Control
                type="text"
                value={searchText !== undefined ? searchText : withdrawalsSearch || ''}
                id="searchWithrawal"
                placeholder="Search by ID"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doWithdrawalsSearch(searchText)) ||
                  (e.key === 'Escape' && this.doWithdrawalsSearch(''))
                )}
              />
              <InputGroup.Append>
                {withdrawalsSearch && <Button
                  key={1}
                  title="Clear"
                  variant={withdrawalsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doWithdrawalsSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={withdrawalsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doWithdrawalsSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-withdrawals-country-filter"
              placeholderText="All Countries"
              value={countriesFilterValue}
              options={countriesAndRegionsOptions}
              highlightIfActive
              onChange={(e) => this.doFilterByCountry(e)}
            />
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>&nbsp;</span>
              <StylishSelect
                id="t-client-types-filter"
                placeholderText="All Client Types"
                value={clientTypesFilter}
                options={StylishSelect.enumToStylishOptions(clientTypes)}
                highlightIfActive
                multi
                clearable
                onChange={(e) => this.doFilterByClientTypes(e)}
              />
            </div>
          </Col>
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <SelectAgentFilter
              viewer={viewer}
              agents={activeAgents}
              onChange={(target) => this.activateSalesAgentFilter(target.value)}
              value={salesAgentFilter}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <SelectAgent
              id="t-withdrawals-page-partners-agent-filter"
              agents={partnerAgents}
              value={partnersAgentFilter}
              placeholderText="All Partners Agents"
              optionsPrefix={[{value: '', label: 'All Partners Agents'}]}
              onChange={({value}) => this.props.uiDispatch(
                'Filter by partners agent',
                (state, arg) => ({...state, partnersAgentFilter: value, page: 1}),
                [value]
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={2}>
            <div>
              <span className={style.label}> Amount From </span>
              <Form.Control
                id="t-withdrawals-amount-from-filter"
                variant={amountFromStyle}
                type="number"
                onChange={(e) => this.handleAmountFilterChanged('amountFrom', e.target.value)}
                value={amountFilters.amountFrom}
                onFocus={() => this.doFilterByAmount.cancel()}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}> Amount To </span>
              <Form.Control
                id="t-withdrawals-amount-to-filter"
                variant={amountToStyle}
                type="number"
                onChange={(e) => this.handleAmountFilterChanged('amountTo', e.target.value)}
                value={amountFilters.amountTo}
                onFocus={() => this.doFilterByAmount.cancel()}
              />
            </div>
          </Col>
          {
            viewer.companies.length > 1 &&
              <Col xs={2}>
                <span className={style.label}>&nbsp;</span>
                <StylishSelect
                  id="t-company-filter"
                  placeholderText="All Companies"
                  value={companyFilter}
                  options={companyOptions}
                  highlightIfActive
                  onChange={(e) => this.doFilterByCompany(e.value)}
                />
              </Col>
          }
          <Col xs={viewer.companies.length > 1 ? 2 : 4}>
            <span className={style.label}>&nbsp;</span>
            <AccountTypesFilter
              companies={viewer.companies}
              value={accountsFilter}
              onChange={this.activateAccountTypeFilter.bind(this)}
            />
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}> Created From </span>
              <DateTime
                timeFormat={false}
                onChange={(e) => this.handleDateFilterChanged('createdFrom', e)}
                value={dateFilters.createdFrom}
                onFocus={() => this.doDateFilterSearch.cancel()}
                closeOnSelect
                className={style.datetime}
                id="t-withdrawals-filter-created-from"
              />
            </div>
          </Col>
          <Col xs={2}>
            <span className={style.label}> Created To </span>
            <DateTime
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('createdTo', e)}
              value={dateFilters.createdTo}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              className={style.datetime}
              id="t-withdrawals-filter-created-to"
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-withdrawals-vendors-filter"
              placeholderText="All Vendors"
              value={vendorsFilter}
              options={map(withdrawalPaymentVendors, (p) => ({
                value: p.key,
                label: getWithdrawalVendorLabel(p.key),
              }))}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByVendors(e)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-withdrawals-deposit-vendor-filter"
              placeholderText="All Deposit Vendors"
              value={depositVendorFilterValue}
              options={StylishSelect.enumToStylishOptions(DEPOSIT_VENDORS, 'All Deposit Vendors')}
              highlightIfActive
              onChange={(e) => this.doFilterByDepositVendor(e)}
            />
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Vip</span>
              <StylishSelect
                id="t-withdrawals-top-vip-filter"
                className={'form-group'}
                placeholderText="All Clients"
                value={topVipFilter}
                options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                highlightIfActive
                onChange={(e) => this.doFilterByTopVip(e)}
              />
            </div>
          </Col>
          <Col xs={3}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-withdrawals-types-filter"
              placeholderText="All Withdrawal Types"
              value={typesFilter}
              options={StylishSelect.enumToStylishOptions(withdrawalTypes)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByTypes(e)}
            />
          </Col>
          <Col xs={3}>
            <span className={style.label}>Has Pending / Not Processed Funding Documents</span>
            <StylishSelect
              id="t-withdrawals-has-pending-funding-docs-filter"
              placeholderText=""
              value={hasPendingFundingDocumentsFilter}
              options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
              highlightIfActive
              onChange={(e) => this.doFilterByHasPendingFundingDocuments(e)}
            />
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={2}>
            <div>
              <span className={style.label}>Order By</span>
              <StylishSelect
                id="t-withdrawals-order-by-filter"
                className={'form-group'}
                placeholderText="ID"
                value={orderByFilter}
                options={StylishSelect.enumToStylishOptions(orderByFields)}
                highlightIfActive
                onChange={(e) => this.doOrderByFilter(e)}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Order Direction</span>
              <StylishSelect
                id="t-withdrawals-order-direction-filter"
                className={'form-group'}
                placeholderText="Descending"
                value={orderDirectionFilter}
                options={StylishSelect.enumToStylishOptions(orderDirections)}
                highlightIfActive
                onChange={(e) => this.doOrderDirectionFilter(e)}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  renderWithdrawal(withdrawal) {
    const {id, type, status, account, createdAt, pendingFundingDocuments, salesAgent} = withdrawal
    const {client} = account || {}
    const linkEnabled = !canQueryAffiliateManagerClients(this.props.viewer)

    return (
      <tr
        className={classNames([
          't-withdrawal',
          style.clickable,
          `${this.props.showWithdrawalId === id ? client.topVip ? 'vip-active' : 'active' : ''}`,
          `${client.topVip ? 'vip' : ''}`,
        ])}
        key={id}
        onClick={() => this.props.uiDispatch(
          'Showing withdrawal',
          (state) => ({...state, showWithdrawalId: id})
        )}
      >
        <td>{id}</td>
        <td>
          {safeLinkClientWithdrawals(getFullName(client), client, id, linkEnabled)}
        </td>
        <td>{companies[client.company].label}</td>
        <td>
          {getFormattedAmount(withdrawal)}
          <br />
          {isCentAccount(account)
            ? getFormattedAmount({
              ...withdrawal,
              currency: currencies.CUD.baseCurrency,
              amount: withdrawal.amount / currencies.CUD.baseCurrencyRate,
              paymentFields: {
                ...(withdrawal.paymentFields || {}),
                ...(get(withdrawal, 'paymentFields.withdrawalNetAmount')
                  ? {withdrawalNetAmount: withdrawal.paymentFields.withdrawalNetAmount / currencies.CUD.baseCurrencyRate}
                  : {}
                ),
              }
            })
            : ''
          }
        </td>
        <td>{type ? withdrawalTypes[type].label : ''}</td>
        <td>{getWithdrawalPaymentMethodDetails(withdrawal, client.id)}</td>
        <td className={style.externalLink}>
          <div>
            {map(pendingFundingDocuments, (doc) =>
              <div>{linkClientDocuments(`Document ID: ${doc.id}`, client.id, doc.id, true)}</div>
            )}
          </div>
        </td>
        <td title={createdAt.fromNow()}>{readableDate(createdAt)}</td>
        <td className="text-center">{status ? getWithdrawalStatusLabel(withdrawalStatuses[withdrawal.status]) : ''}</td>
        <td>{getFullName(salesAgent, unassigned)}</td>
      </tr>
    )
  }

  renderTable() {
    const {withdrawals, withdrawalsCount, page, withdrawalStatus, viewer} = this.props
    const {loading} = this.state

    return (
      <div>
        <Card>
          <Card.Body style={{overflowX: 'scroll'}}>
            <Row className="mb-3">
              <Col xs={12}>
                <ButtonGroup id="t-toolbar-filter">
                  {this.renderFilterButton('', 'All', withdrawalStatus)}
                  {Object.keys(withdrawalStatuses).map((status) => (
                    this.renderFilterButton(status, withdrawalStatuses[status].label, withdrawalStatus)
                  ))}
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-3">
                {[userDepartments.boAccountOpening.key, userDepartments.boFunding.key].includes(viewer.department) && <div>
                  <Button
                    size="sm"
                    variant="outline-secondary"
                    onClick={() => this.setState({showExportWithdrawalsModal: true})}
                  >Export Withdrawals</Button>&nbsp;<span className={style.note}>*only Accepted Bank Transfers</span></div>}
              </Col>
              <Col xs={6} className="mb-3">
                {viewer.department === userDepartments.accounting.key && withdrawals.length > 0 && <Button
                  id="t-deposits-export-button"
                  variant="outline-secondary"
                  onClick={() => this.setState({exportModalShown: true})}
                  disabled={loading}
                  size="sm"
                  className="float-right"
                >
                  {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                </Button>}
              </Col>
            </Row>
            <Table bordered hover className={style.table}>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Full Name</th>
                  <th>Company</th>
                  <th>Amount</th>
                  <th>Type</th>
                  <th>Payment Method</th>
                  <th>Pending POF Documents</th>
                  <th>Created</th>
                  <th>Status</th>
                  <th>Sales Agent</th>
                </tr>
              </thead>
              <tbody>
                {filter(withdrawals, (w) => w.account?.client).map((withdrawal) => this.renderWithdrawal(withdrawal))}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch(
                'Show page',
                (state) => ({...state, newsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(page, getPageCount(withdrawalsCount)).map((p) => {
                if (p === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={p}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, page: page - 1})
                    )}
                  />
                }

                if (p === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={p}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, page: page + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={p === page}
                  key={p}
                  onClick={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, page: p})
                  )}
                >
                  {p}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </div>
    )
  }

  renderSelectedWithdrawal() {
    const {withdrawals, showWithdrawalId, uiDispatch} = this.props

    let withdrawal = null
    if (showWithdrawalId != null) {
      withdrawal = withdrawals.find((w) => w.id === showWithdrawalId)
    }

    if (withdrawal == null) {
      return (<Card><Card.Body>Select withdrawal from the list.</Card.Body></Card>)
    }

    return (
      <WithdrawalEditor
        withdrawal={withdrawal}
        client={(withdrawal.account && withdrawal.account.client) || {}}
        hideActivityLog
        onClose={() => {
          uiDispatch(
            'Set withdrawal to null',
            (state) => ({...state, showWithdrawalId: undefined})
          )
        }}
        onUpdate={() => this.fetchProvider()}
      />
    )
  }

  renderAmountSummary() {

    // Not sure where to add these utils
    const formatCommaSeparationNumber = (number) => {
      const formattedFractional = (number - Math.trunc(number)).toString().slice(1, 4)
      const formattedInt = Math.trunc(number).toString().length > 3 ? Math.trunc(number).toString().slice(0, -3) + ',' + Math.trunc(number).toString().slice(-3) : Math.trunc(number).toString()
      return `${formattedInt}${formattedFractional}`
    }
    const getHoursFromMS = (ms) => ms / (1000 * 60 * 60)

    const {thisMonthWithdrawals, fxRates} = this.props

    const withdrawalsByCurrency = {}
    thisMonthWithdrawals.forEach((withdrawal) => {
      if (!Object.hasOwn(withdrawalsByCurrency, withdrawal.currency)) {
        withdrawalsByCurrency[withdrawal.currency] = [withdrawal]
      } else {
        withdrawalsByCurrency[withdrawal.currency].push(withdrawal)
      }
    })
    for (const property in withdrawalsByCurrency) {
      const currentMonthAmountSum = withdrawalsByCurrency[property].reduce((acc, withdrawal) => acc + withdrawal.amount, 0)
      const thisWeekDeposits = withdrawalsByCurrency[property].filter((withdrawal) => (getHoursFromMS(new Date() - new Date(withdrawal.createdAt)) <= 24 * 7))
      const currentWeekAmountSum = thisWeekDeposits.reduce((acc, withdrawal) => acc + withdrawal.amount, 0)
      const todayDeposits = withdrawalsByCurrency[property].filter((withdrawal) => (getHoursFromMS(new Date() - new Date(withdrawal.createdAt)) <= 24))
      const todayAmountSum = todayDeposits.reduce((acc, withdrawal) => acc + withdrawal.amount, 0)
      withdrawalsByCurrency[property] = {
        currentMonthAmountSum,
        currentWeekAmountSum,
        todayAmountSum
      }
    }

    const currentSums = {
      currentMonthAmountSummary: 0,
      currentWeekAmountSummary: 0,
      todayAmountSummary: 0,
    }
    for (const property in withdrawalsByCurrency) {
      const rate = parseInt(fxRates[property]['EUR'])
      currentSums.currentMonthAmountSummary += withdrawalsByCurrency[property].currentMonthAmountSum * rate
      currentSums.currentWeekAmountSummary += withdrawalsByCurrency[property].currentWeekAmountSum * rate
      currentSums.todayAmountSummary += withdrawalsByCurrency[property].todayAmountSum * rate
    }
    currentSums.currentMonthAmountSummary = formatCommaSeparationNumber(currentSums.currentMonthAmountSummary)
    currentSums.currentWeekAmountSummary = formatCommaSeparationNumber(currentSums.currentWeekAmountSummary)
    currentSums.todayAmountSummary = formatCommaSeparationNumber(currentSums.todayAmountSummary)

    return (
      <Card className="my-3 px-5 py-3 w-100" style={{fontSize: 16}}>
        <Row>
          <div className="pr-5">
            Today: {currentSums.todayAmountSummary}
          </div>
          <div className="pr-5">
            Current week: {currentSums.currentWeekAmountSummary}
          </div>
          <div>
            Current month: {currentSums.currentMonthAmountSummary}
          </div>
        </Row>
      </Card>
    )
  }

  render() {
    const {withdrawalsCount} = this.props
    const {showExportWithdrawalsModal, exporting, exportModalShown, exportPage, loading} = this.state

    return (
      <Container>
        <div className={style.toolbar}>
          {this.renderFilters()}
        </div>

        {(companyName() === 'lydian') && <>{this.renderAmountSummary()}</>}

        <Row>
          <Col xs={8}>
            {this.renderTable()}
          </Col>
          <Col xs={4}>
            {!canQueryAffiliateManagerClients(this.props.viewer)  && this.renderSelectedWithdrawal()}
          </Col>
          {showExportWithdrawalsModal && <ExportWithdrawalsModal
            show={showExportWithdrawalsModal}
            header="Export Accepted Bank Transfer Withdrawals"
            onConfirm={(dateFrom, dateTo, onlyBannedCountries) => {
              this.exportWithdrawals(dateFrom, dateTo, onlyBannedCountries)
            }}
            onCancel={() => this.setState({showExportWithdrawalsModal: false})}
            exporting={exporting}
          />}
          <Modal
            keyboard
            show={exportModalShown}
            onHide={() => this.setState({
              exportModalShown: false,
              exportPage: 1,
            })}
          >
            <Modal.Header closeButton className={style.modalHeader}>
              Export Withdrawals
            </Modal.Header>
            <Modal.Body>
              <Row className="mb-3">
                <Col xs={12}>
                  <span style={{fontSize: 12}}>
                    *The export is limited to 20,000 results. If the search results are more than 20,000, please select
                    another page from the below dropdown or use a more specific search.
                  </span>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col xs={6}>
                  <StylishSelect
                    id="t-deposits-export-page-select"
                    placeholderText="Select a page"
                    defaultValue={{value: 1, label: 1}}
                    options={map(range(0, getPageCount(withdrawalsCount, EXPORT_LIMIT)), (p) => ({
                      value: p + 1,
                      label: p + 1,
                    }))}
                    onChange={(e) => this.setState({exportPage: e.value})}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button
                    id="t-deposits-export-modal-button"
                    variant="outline-secondary"
                    className="float-right"
                    onClick={() => this.onSearch()}
                    disabled={loading || !exportPage}
                  >
                    {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                  </Button>
                </Col>
              </Row>
            </Modal.Body>
          </Modal>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryWithdrawals),

  uiMount(() => ['withdrawalsUi']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for withdrawalStatus',
      (state) => {
        let dateFilters = state && state.dateFilters
        if (!dateFilters) {
          dateFilters = {createdFrom: moment().subtract(14, 'days').startOf('day')}
          dateFilters.createdFrom.isValid()
        }
        return merge({withdrawalStatus: withdrawalStatuses.notProcessed.key, dateFilters}, state)
      }
    )
  }),

  provideProps((state, uiState) => {
    const {withdrawals, withdrawalsCount, agents, thisMonthWithdrawals, fxRates} = state
    const {withdrawalStatus, accountsFilter, salesAgentFilter, page, showWithdrawalId, companyFilter, orderByFilter,
      dateFilters, clientTypesFilter, vendorsFilter, countriesFilter, countriesFilterValue, topVipFilter,
      amountFilters, withdrawalsSearch, typesFilter, partnersAgentFilter, hasPendingFundingDocumentsFilter,
      depositVendorFilterValue, orderDirectionFilter,
    } = uiState

    return {
      // data
      withdrawals,
      withdrawalsCount,
      agents,
      // filters
      withdrawalsSearch,
      salesAgentFilter,
      accountsFilter,
      withdrawalStatus,
      companyFilter,
      dateFilters,
      clientTypesFilter,
      vendorsFilter,
      countriesFilter,
      countriesFilterValue,
      topVipFilter,
      amountFilters,
      typesFilter,
      hasPendingFundingDocumentsFilter,
      partnersAgentFilter,
      orderByFilter,
      orderDirectionFilter,
      depositVendorFilterValue,
      // pagination
      page: page || 1,
      // selected withdrawal
      showWithdrawalId,
      thisMonthWithdrawals,
      fxRates,
    }
  }),

  mountDataProviders({withdrawalsProvider, thisMonthWithdrawalsProvider, fxRatesProvider}),
)(Withdrawals)
