import moment from 'moment'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

function initFtds(ftds) {
  for (const ftd of ftds) {
    ftd.date = moment(ftd.date)
    ftd.date.isValid() // set _isValid property now
  }
  return ftds
}

export const reportFtdProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {dateFilter} = props
    const condition = dateFilter?.date ? `ftdDate: "${dateFilter.date}"` : ''
    const {ftdSort} = props

    const query = `{
          getFTDReport(
            ${condition}
            limit: ${getPageSize()}
            offset: ${getOffset(props.reportPage)}
            ${ftdSort?.orderBy ? `orderBy: ${ftdSort.orderBy}` : ''}
            ${ftdSort?.orderDirection ? `orderDirection: ${ftdSort.orderDirection}` : ''}
          ) {
            id
            fullName
            entity
            dob
         }
          getFTDReportCount${condition ? '(' + condition + ')' : ''}
      }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'First Time Deposits Report loaded',
    (state, res) => ({
      ...state,
      ftds: res.getFTDReport != null
        ? initFtds(res.getFTDReport)
        : state.ftds || [],
      ftdCount: res.getFTDReportCount != null
        ? res.getFTDReportCount
        : state.ftdCount || 0,
    }),
    [res]
  ),

}
