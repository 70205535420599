import uiMount from './uiMount'
import predispatch from './predispatch'
import translate from './translate'
import provideProps from './provideProps'
import mountDataProviders from './mountDataProviders'
import checkRights from './checkRights'
import {checkOneOfPermissions} from './checkOneOfPermissions'

const compose = (f, ...fs) => fs.length > 0 ? (x) => f(compose(...fs)(x)) : f

export {
  uiMount,
  compose,
  predispatch,
  translate,
  provideProps,
  mountDataProviders,
  checkRights,
  checkOneOfPermissions
}
