import {putFile} from '../utils/net'
import {endsWith, find, includes, isEmpty, map} from 'lodash'
import React from 'react'
import {Container, Row, Col, Card, Table, Form, Button} from 'react-bootstrap'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PropTypes from 'prop-types'
import {accountTypes, companies, depositVendors, withdrawalPaymentVendors} from '@bdswiss/common-enums'

const fieldsToInputEnum = [
  {
    key: 'depositId',
    value: 'depositId',
    label: 'Deposit ID',
  },
  {
    key: 'withdrawalId',
    value: 'withdrawalId',
    label: 'Withdrawal ID',
  },
  {
    key: 'memberId',
    value: 'memberId',
    label: 'Member ID',
  },
]

const fieldsToExportEnum = [
  {
    key: 'receiptNumber',
    value: 'receiptNumber',
    label: 'Receipt Number',
  },
  {
    key: 'memberId',
    value: 'memberId',
    label: 'Member ID',
  },
  {
    key: 'company',
    value: 'company',
    label: 'Company',
  },
  {
    key: 'clientEmail',
    value: 'clientEmail',
    label: 'Client Email',
  },
  {
    key: 'productName',
    value: 'productName',
    label: 'Product Name',
  },
  {
    key: 'vendor',
    value: 'vendor',
    label: 'Vendor',
  }
]

class AccountingSearchTool extends React.Component {
  static contextTypes = {
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props)

    this.state = {
      fileToUpload: [],
    }
  }

  handleSubmit() {
    const {fileToUpload, fieldToInput, fieldsToExport} = this.state

    if (!endsWith(fileToUpload[0]?.type, 'csv')) {
      this.context.showNotification({
        title: 'Accounting Search Tool',
        message: 'Wrong file format. Only csv is allowed',
        position: 'tr',
        level: 'error',
      })
      return
    }

    return this.props.actions.accounting.signSearchToolFileUrl()
      .then((res) => {
        const {csvSignedUrl, csvPlainUrl} = res.signSearchToolFileUrl
        return putFile(fileToUpload[0], csvSignedUrl)
          .then(() => {
            const args = {
              csvLink: csvPlainUrl,
              inputField: fieldToInput,
              outputFields: fieldsToExport,
            }
            this.props.actions.accounting.searchTool(args)
              .then((res) => {
                const {deposits, withdrawals} = res.searchTool
                this.setState({deposits, withdrawals})
                let successNotificationMessage
                if (res?.searchTool?.status === 'background') {
                  successNotificationMessage = 'Search tool working in background. You will receive the report on your email shortly.'
                } else {
                  successNotificationMessage = 'Search Tool Successfully Finished.'
                }
                this.context.showNotification({
                  title: 'Accounting Search Tool',
                  message: successNotificationMessage,
                  position: 'tr',
                  level: 'success',
                })
              })
              .catch(this.context.logError)
          })
      })
  }

  renderDepositRow(deposit) {
    const {memberId, receiptNumber, productName, company, vendor, email} = deposit
    const {fieldsToExport} = this.state
    const accountType = find(accountTypes, {value: productName})
    return (
      <tr>
        {includes(fieldsToExport, 'receiptNumber') && <td> {receiptNumber} </td>}
        {includes(fieldsToExport, 'memberId') && <td> {memberId} </td>}
        {includes(fieldsToExport, 'company') && <td> {companies[company].label} </td>}
        {includes(fieldsToExport, 'clientEmail') && <td> {email} </td>}
        {includes(fieldsToExport, 'productName') && <td> {accountType.label} </td>}
        {includes(fieldsToExport, 'vendor') && <td> {depositVendors[vendor].label} </td>}
      </tr>
    )
  }

  renderWithdrawalRow(withdrawal) {
    const {memberId, receiptNumber, productName, company, vendor, email} = withdrawal
    const {fieldsToExport} = this.state
    const accountType = find(accountTypes, {value: productName})
    const paymentVendor = find(withdrawalPaymentVendors, {value: vendor})
    return (
      <tr>
        {includes(fieldsToExport, 'receiptNumber') && <td> {receiptNumber} </td>}
        {includes(fieldsToExport, 'memberId') && <td> {memberId} </td>}
        {includes(fieldsToExport, 'company') && <td> {companies[company].label} </td>}
        {includes(fieldsToExport, 'clientEmail') && <td> {email} </td>}
        {includes(fieldsToExport, 'productName') && <td> {accountType.label} </td>}
        {includes(fieldsToExport, 'vendor') && <td> {paymentVendor?.label ?? '-'} </td>}
      </tr>
    )
  }

  render() {
    const {deposits, withdrawals} = this.state

    return <Container>
      <Row xs={12}>
        <Col xs={3}>
          <StylishSelect
            id="accounting-search-tool-input-field"
            placeholderText="Field to input"
            value={this.state.fieldToInput}
            options={StylishSelect.enumToStylishOptions(fieldsToInputEnum)}
            onChange={(e) => this.setState({fieldToInput: e.value})}
          />
        </Col>
        <Col xs={3}>
          <StylishSelect
            multi
            clearable
            id="accounting-search-tool-output-fields"
            placeholderText="Fields to export"
            value={this.state.fieldsToExport}
            options={StylishSelect.enumToStylishOptions(fieldsToExportEnum)}
            onChange={(e) => this.setState({fieldsToExport: map(e, 'value')})}
          />
        </Col>
        <Col xs={3}>
          <Form.Control
            id="accounting-search-tool-input-file"
            type="file"
            onChange={(e) => this.setState({fileToUpload: e.target.files})}
          />
        </Col>
        <Col xs={3}>
          <Button onClick={() => this.handleSubmit()}> Export Data </Button>
        </Col>
      </Row>
      <br />
      <Row xs={12}>
        <Col xs={12}>
          <Card>
            <Card.Body>
              {deposits && !isEmpty(deposits) && <h2> Deposits </h2>}
              {deposits && !isEmpty(deposits) && <Table>
                <thead>
                  <tr>
                    {map(this.state.fieldsToExport, (field, index) => (
                      <th id={`accounting-search-tool-deposit-row-${index}`}> {find(fieldsToExportEnum, {key: field}).label} </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {map(deposits, (deposit) => this.renderDepositRow(deposit))}
                </tbody>
              </Table>}
              {withdrawals && !isEmpty(withdrawals) && <h2> Withdrawals </h2>}
              {withdrawals && !isEmpty(withdrawals) && <Table>
                <thead>
                  <tr>
                    {map(this.state.fieldsToExport, (field, index) => (
                      <th id={`accounting-search-tool-withdrawal-row-${index}`}> {find(fieldsToExportEnum, {key: field}).label} </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {map(withdrawals, (withdrawal) => this.renderWithdrawalRow(withdrawal))}
                </tbody>
              </Table>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  }
}

export default provideProps()(AccountingSearchTool)
