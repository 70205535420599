/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {find, map, get, isNil, isArray} from 'lodash'
import {Row, Col, Button, Badge} from 'react-bootstrap'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import JsonDataModal from '../components/JsonDataModal'
import {provideProps} from '../decorators'
import {getFullName, readableDate} from '../useful'
import {shortenFilename} from '../textUtils'
import {replaceParameters} from '../utils/general'
import {activityLogTypes, conversionStatuses, tradingStatuses, kycStatuses, clientTypes, userDepartments,
  accountTypes, alertTypes, companies, rawSubscriptionPlans, clientPotentialLevels, dueDiligenceStatuses,
  partnersAppendices, appointmentCategories, vpsSubscriptionPlans, vpsSubscriptionTypes, verificationTypes,
} from '@bdswiss/common-enums'
import {parseJSON, safeParseJSON} from '../common/utils'
import {eventCategories} from '../enums'
import {canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import {linkClientWithdrawals, linkClientDeposits, linkClientBonusOffer, linkClientDocuments,
  linkClientProfileChanges, linkClientAppropTests, linkUser, linkClient} from '../utils/links'
import style from './client.module.scss'
import PureComponent from '../PureComponent'

const MIN_PANEL_HEIGHT = 82

function eventObjectUrl(event, clientId, linkEnabled) {
  if (event.objectWithdrawal) {
    const withdrawalId = event.objectWithdrawal.id
    return linkClientWithdrawals(`withdrawal #${withdrawalId}`, clientId, withdrawalId, linkEnabled)
  }

  if (event.objectDeposit) {
    const depositId = event.objectDeposit.id
    return linkClientDeposits(`deposit #${depositId}`, clientId, depositId, linkEnabled)
  }

  if (event.objectBonusOffer) {
    const bonusOfferId = event.objectBonusOffer.id
    return linkClientBonusOffer(`bonus offer #${bonusOfferId}`, clientId, bonusOfferId, linkEnabled)
  }

  if (event.objectDocument) {
    const documentId = event.objectDocument.id
    return linkClientDocuments(`document #${documentId}`, clientId, documentId, linkEnabled)
  }

  if (event.objectProfileChange) {
    const profileChangeId = event.objectProfileChange.id
    const content = (event.type !== activityLogTypes.updateOwnDetails.key ? 'profile change ' : '') +
      `#${profileChangeId}`
    return linkClientProfileChanges(content, clientId, profileChangeId, linkEnabled)
  }

  if (event.objectAppropTest) {
    const appropTestId = event.objectAppropTest.id
    return linkClientAppropTests(`appropriateness test #${appropTestId}`, clientId, appropTestId, linkEnabled)
  }

  if ((event.objectUser && event.user) && (event.objectUser.id !== event.user.id)) {
    return linkUser(getFullName(event.objectUser), event.objectUser.id, linkEnabled)
  }

  if (event.type === activityLogTypes.spoaAuthorization.key) {
    return linkClient(`${event.spoaIbFName} ${event.spoaIbLName} #${event.spoaIbID}`, event.spoaIbID)
  }

  if (event.type === activityLogTypes.ibSpoaAuthorization.key) {
    return linkClient(`${event.spoaClientFName} ${event.spoaClientLName} #${event.spoaClientID}`,
      event.spoaClientID, linkEnabled)
  }

  if ([activityLogTypes.requestTranslation.key, activityLogTypes.submitTranslation.key].includes(event.type)) {
    const documentId = event.documentId
    return documentId && linkClientDocuments(`document #${documentId}`, clientId, documentId, linkEnabled)
  }

  if ([activityLogTypes.clientHasSamePhoneNumber.key, activityLogTypes.clientHasSameLastnameAndDob.key]
    .includes(event.type)) {
    return map(event.otherUsers, (u, i) => <span key={i} className={style.link}>{linkClient(`#${u}`, u, linkEnabled)}</span>)
  }

  if ([activityLogTypes.clientLinked.key, activityLogTypes.clientUnlinked.key].includes(event.type)) {
    return linkClient(`${event.retailClientName} #${event.retailClientId}`,
      event.retailClientId, linkEnabled)
  }

  if ( [activityLogTypes.ticketCreatedUnresolved.key,activityLogTypes.ticketResolved.key].includes(event.type)) {
    let meta = event.meta
    if (event.meta) {
      meta = JSON.parse(event.meta)
    }
    return  <span> (ID:{meta.id}) for {meta.issueType} Priority: {meta.priority} </span>

  }
}

export default provideProps()(class Event extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    event: PropTypes.object.isRequired,
    showFull: PropTypes.bool,
    toggleShowFull: PropTypes.func,
    // following properties are needed only for appointment events
    salesAgentId: PropTypes.number,
    supportAgentId: PropTypes.number,
    externalAgentId: PropTypes.number,
    editAppointment: PropTypes.func,
    deleteAppointment: PropTypes.func,
    sideTab: PropTypes.string.isRequired,
  };

  state = {
    showGBGResult: false,
    showSkrillResult: false,
    isExpanded: false,
    showMoreButton: false,
  }

  constructor(props) {
    super(props)
    this.panel = React.createRef()
  }

  componentDidMount() {
    this.setState({showMoreButton: this.panel.current.scrollHeight > MIN_PANEL_HEIGHT})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sideTab !== this.props.sideTab && ['activity', 'notifications'].includes(this.props.sideTab)) {
      this.setState({showMoreButton: this.panel.current.scrollHeight > MIN_PANEL_HEIGHT})
    }
  }

  renderToggleLink(showFull, toggleContent) {
    return (
      <a
        className={`float-right ${style.showMore}`}
        onClick={toggleContent}
      >
        {showFull ? 'Show less' : 'Show more'}
      </a>
    )
  }

  canEditOrDeleteAppointment = () => {
    const {viewer, agents = [], event: {user}} = this.props
    const userId = get(user, 'id')
    const usersManagedIds = agents.filter(a => get(a, 'managerId') === viewer.id).map(a => a.managerId)
    return viewer.id === userId || usersManagedIds.includes(viewer.id)
  }

  renderAppointementToolbar() {
    const {event, clientId} = this.props
    return (
      <div className={style.appointmentToolbar}>
        {this.canEditOrDeleteAppointment() &&
          <FontAwesomeIcon
            icon="pencil"
            title="Edit"
            className={style.clickable}
            onClick={() => this.props.editAppointment({...event, clientId, category: event.appointmentCategory})}
          />
        }
        {this.canEditOrDeleteAppointment() &&
          <FontAwesomeIcon
            icon="trash"
            title="Delete"
            className={style.clickable}
            onClick={() => this.props.completeOrDeleteAppointment(event, 'delete')}
          />
        }
        {this.canEditOrDeleteAppointment() && <input
          className={style.completeCheckbox}
          type="checkbox"
          checked={!event.isNew}
          onChange={() => this.props.completeOrDeleteAppointment(event, 'complete')}
        />}
      </div>
    )
  }

  renderAppointmentMiniDescription() {
    const {event: {date}} = this.props

    return (
      <div className={style.gray}>
        <small>
          <FontAwesomeIcon icon="calendar" />&nbsp;
          <span className={`${style.gray}`}>{readableDate(date)}</span>
        </small>
      </div>
    )
  }

  getAppointmentCategory = (categoryKey) => {
    if (categoryKey) {
      const category = get(appointmentCategories, categoryKey)
      return category.parentCategory
        ? appointmentCategories[category.parentCategory].label + ' - ' + category.label
        : category.label
    } else {
      return ''
    }
  }

  beautifyDiff = (diff, fallbackContent) => {
    if (isArray(diff)) {
      if (diff.length === 1 && diff[0][0] === 1) {
        return fallbackContent
      } else {
        return map(diff, (part, index) => <span
          key={String(index)}
          className={{
            [-1]: style.diffMinus,
            0: '',
            1: style.diffPlus,
          }[part[0]]}
        >{part[1]}</span>
        )
      }
    } else {
      return fallbackContent
    }
  }

  toggleMore = (e) => {
    e.preventDefault()
    const panel = this.panel.current
    const isExpanded = !this.state.isExpanded
    if (isExpanded) {
      panel.style.height = `${panel.scrollHeight + 10}px`
    } else {
      panel.style.height = `${MIN_PANEL_HEIGHT}px`
    }
    this.setState({isExpanded})
  }

  renderLongTextBody() {
    const {event, event: {content, fileName, fileUrl, description, appointmentCategory, diff, __typename},
      clientId, viewer} = this.props
    const noteDiff = this.beautifyDiff(safeParseJSON(diff), content)

    const linkEnabled = !canQueryAffiliateManagerClients(viewer)
    const eventLink = eventObjectUrl(event, clientId, linkEnabled)
    // appointments use 'description' and notes uses 'content'
    const comment = __typename === 'Note'
      ? <>Created general note: {noteDiff}</>
      : appointmentCategory === appointmentCategories.other.value
        ? description || ''
        : `${this.getAppointmentCategory(appointmentCategory)}${description ? ` - ${description}` : ''}`

    return (
      <div className={style.noteText}>
        {comment}
        {fileUrl && (
          <div className={style.noteLink}>
            <a href={fileUrl} target="_blank" title={fileName} rel="noopener noreferrer">
              <FontAwesomeIcon icon="paperclip" /> {shortenFilename(fileName)}
            </a>
          </div>
        )}
        {eventLink && (
          <div className={style.noteLink}>
            Relates to {eventLink}
          </div>
        )}
      </div>
    )
  }

  getUserDepartmentColor(department) {
    return {
      [userDepartments.boAccountOpening.key]: {color: '#0d6efd'}, // primary
      [userDepartments.boFunding.key]: {color: '#0d6efd'}, // primary
      [userDepartments.sales.key]: {color: '#198754'}, // success
      [userDepartments.dealing.key]: {color: '#dc3545'}, // danger
      [userDepartments.support.key]: {color: '#ffc107'}, // warning
      [userDepartments.marketing.key]: {color: '#0dcaf0'}, // info
      [userDepartments.it.key]: {color: '#212529'}, // dark
      [userDepartments.system.key]: {color: '#212529'}, // dark
      [userDepartments.development.key]: {color: '#212529'}, // dark
      [userDepartments.partnershipOperations.key]: {color: '#f519de'}, // pink
    }[department]?.color || '#6c757d' // secondary
  }

  renderActivityDescription() {
    const {event, event: eventParams, clientId, viewer} = this.props
    const {type, agentType, conversionStatus, tradingStatus, kycStatus, clientType, otherUsersCount,
      accountName, remoteId, allowTransferFunds, alertType, topVip, allowNewAccounts, content,
      underMonitoring, canGrantSpoa, canIbTransfer, spoaAuthorization, smsInOut, translator, args, meta,
      effectiveFTD, toggleAutochartist, toggleBDXCallCompleted, bdxWaiverAccepted, manualWithdrawals,
      isPhoneVerified, bdxInvestmentChoiceFinal, previousBDXInvestmentChoice, automaticAffiliateCommissions,
      newPlan, lastActiveDate, assignee, toggleHideWDMethods, emailConfirmed, dormantReactivation,
      toggleAutochartistEmails, toggleAutochartistPlugin, toggleAutochartistCoelationEmail, isDirectIB,
      ignoreIbTermination, toggleCopyTrading, clientRequestCopyTrading, userUpdateCopyTrading,
      hasHotAssets, hasTelegram,  competitionName, competitionId, copyTradingMethod, copyTradingPercentage,
      hasOpenComplaint, hasSurvey, hasPreSurvey, hasPortfolioManagement, hasDisabledWithdrawals,
      performanceFee, provideCopyTrading, clientAccountName, oldAccountName, providerRemoteId, action, details,
      statusAndNotes, manuallyAccountType, allowManuallyAccount, hasRawAccount, hasVipAccount, acceptedSpoaBonusTerms,
      spoaForce, showMasterStrategies,
    } = eventParams
    const linkEnabled = !canQueryAffiliateManagerClients(viewer)
    const eventLink = eventObjectUrl(event, clientId, linkEnabled)
    const activityLogType = activityLogTypes[type]
    let text = activityLogType.activityLogMessage
    const metaParams = parseJSON(meta)
    const accountTypeLabel = get(find(accountTypes, {value: accountName}), 'label')
    const textParameters = {
      ...event,
      agentType: agentType === 'supportAgent' ? 'partners' : 'sales',
      conversionStatus: conversionStatus ? conversionStatuses[conversionStatus].label : undefined,
      tradingStatus: tradingStatus ? tradingStatuses[tradingStatus].label : undefined,
      kycStatus: kycStatus ? kycStatuses[kycStatus].label : undefined,
      clientType: clientType ? clientTypes[clientType].label : undefined,
      otherUsersCount, remoteId, allowTransferFunds: allowTransferFunds ? 'Enabled' : 'Disabled',
      allowNewAccounts: allowNewAccounts ? 'Enabled' : 'Disabled',
      accountLabel: accountName ? accountTypeLabel : undefined,
      alertType: alertType ? alertTypes[alertType].label : undefined,
      topVip: topVip ? 'Added' : 'Removed',
      translator: translator ? [translator.firstName, translator.lastName].join(' ') : '',
      underMonitoring: underMonitoring ? 'Added' : 'Removed',
      canGrantSpoa: canGrantSpoa ? 'Added' : 'Removed',
      canIbTransfer: canIbTransfer ? 'Enabled' : 'Disabled',
      hasHotAssets: hasHotAssets ? 'Enabled' : 'Disabled',
      hasTelegram: hasTelegram ? 'Enabled' : 'Disabled',
      hasPortfolioManagement: hasPortfolioManagement ? 'Enabled' : 'Disabled',
      hasRawAccount: hasRawAccount ? 'has' : 'doesn\'t have',
      hasVipAccount: hasVipAccount ? 'has' : 'doesn\'t have',
      ignoreIbTermination: ignoreIbTermination ? 'Ignoring' : 'Normal',
      isDirectIB: isDirectIB ? 'is' : 'is not',
      hasOpenComplaint: hasOpenComplaint ? 'submitted' : 'resolved',
      smsInOut: smsInOut ? 'In' : 'Out',
      spoaAuthorization: spoaAuthorization ? 'granted to' : 'revoked from',
      effectiveFTD: effectiveFTD ? 'True' : 'False',
      toggleAutochartist: toggleAutochartist ? 'Enabled' : 'Disabled',
      toggleAutochartistEmails: toggleAutochartistEmails ? 'Enabled' : 'Disabled',
      toggleAutochartistPlugin: toggleAutochartistPlugin ? 'Enabled' : 'Disabled',
      toggleAutochartistCoelationEmail: toggleAutochartistCoelationEmail ? 'Enabled' : 'Disabled',
      toggleBDXCallCompleted: toggleBDXCallCompleted ? 'Enabled' : 'Disabled',
      bdxWaiverAccepted: bdxWaiverAccepted ? 'Enabled' : 'Disabled',
      manualWithdrawals: manualWithdrawals ? 'Enabled' : 'Disabled',
      toggleIsPhoneVerified: isPhoneVerified ? 'is' : 'is not',
      toggleEmailConfirmed: emailConfirmed ? 'is' : 'is not',
      bdxInvestmentChoiceFinal: bdxInvestmentChoiceFinal ? bdxInvestmentChoiceFinal : '',
      previousBDXInvestmentChoice: previousBDXInvestmentChoice ? previousBDXInvestmentChoice : '',
      automaticAffiliateCommissions: automaticAffiliateCommissions ? 'enabled' : 'disabled',
      newPlan: newPlan ? find(rawSubscriptionPlans, {value: newPlan}).label : undefined,
      lastActiveDate: lastActiveDate ? readableDate(moment(lastActiveDate), false) : undefined,
      assignee: assignee ? getFullName(assignee) : 'unassigned',
      hideWDMethods: toggleHideWDMethods ? 'Enabled' : 'Disabled',
      hasDisabledWithdrawals: hasDisabledWithdrawals ? 'disabled' : 'enabled',
      potentialLevel: get(metaParams, 'potentialType') ? find(clientPotentialLevels, {value: get(metaParams, 'potentialType')}).label : undefined,
      dueDiligenceStatus: get(metaParams, 'dueDiligenceStatus') ? find(dueDiligenceStatuses, {value: get(metaParams, 'dueDiligenceStatus')}).label : undefined,
      dormantReactivation: dormantReactivation ? 'Enabled' : 'Disabled',
      allowCopyTrading: toggleCopyTrading ? 'Enabled' : 'Disabled',
      clientCopyTrading: clientRequestCopyTrading ? 'start' : 'stop',
      userCopyTrading: userUpdateCopyTrading ? 'Enabled' : 'Disabled',
      competitionName: competitionName ? competitionName : undefined,
      competitionId: competitionId ? competitionId : undefined,
      partnersTemplate:  get(metaParams, 'partnersTemplate') ? find(partnersAppendices, {value:  get(metaParams, 'partnersTemplate')}).label : undefined,
      hasSurvey: hasSurvey ? 'Enabled' : 'Disabled',
      hasPreSurvey: hasPreSurvey ? 'Enabled' : 'Disabled',
      provideCopyTrading: provideCopyTrading ? 'Enabled' : 'Disabled',
      oldAccountName: oldAccountName ? oldAccountName : accountTypeLabel || undefined,
      clientAccountName: clientAccountName ? clientAccountName : undefined,
      referralType: get(metaParams, 'referralType'),
      rewardAmount: get(metaParams, 'rewardAmount'),
      providerRemoteId: providerRemoteId ? providerRemoteId : undefined,
      action: action ? action : undefined,
      details: details ? details : undefined,
      statusAndNotes: statusAndNotes ? statusAndNotes : undefined,
      manuallyAccountType: manuallyAccountType ? get(find(accountTypes, {key: manuallyAccountType}), 'label') : undefined,
      allowManuallyAccount: allowManuallyAccount ? 'Allowed' : 'Disallowed',
      mobileLogin: get(metaParams, 'mobileLogin'),
      isiOSLogin: !!get(metaParams, 'isiOSLogin'),
      isAndroidLogin: !!get(metaParams, 'isAndroidLogin'),
      appBuild: get(metaParams, 'appBuild'),
      browserInfo: get(metaParams, 'browserInfo'),
      vpsPlanName:  get(metaParams, 'vpsPlanName') ? find(vpsSubscriptionPlans, {value:  get(metaParams, 'vpsPlanName')}).label : undefined,
      vpsType:  get(metaParams, 'vpsType') ? find(vpsSubscriptionTypes, {value:  get(metaParams, 'vpsType')}).label : undefined,
      docType: get(metaParams, 'docType'),
      verificationType: get(metaParams, 'verificationType') ? find(verificationTypes, {value: get(metaParams, 'verificationType')}).label : undefined,
      showMasterStrategies: showMasterStrategies ? 'Enabled' : 'Disabled',
      toggleTradeCompanion: get(metaParams, 'toggleTradeCompanion') ? 'Enabled' : 'Disabled',
      ignoreAutoMigration: get(metaParams, 'ignoreAutoMigration') ? 'Disabled' : 'Enabled',
      passwordProtectedStrategies: get(metaParams, 'passwordProtectedStrategies') ? 'Enabled' : 'Disabled',
      addPasswordStrategy: get(metaParams, 'addPasswordStrategy') ? 'Added' : 'Removed',
      tradingCentralEnabled: get(metaParams, 'tradingCentralEnabled') ? 'Enabled' : 'Disabled',
      affiliateId: get(metaParams, 'affiliateId'),
      loginReason: get(metaParams, 'loginReason')
    }

    const extraContent = [
      '\n',
      copyTradingMethod ? `Method: ${copyTradingMethod}` : '',
      copyTradingPercentage ? `Percentage: ${copyTradingPercentage}` : '',
      acceptedSpoaBonusTerms ? 'Accepted SPOA Bonus Terms' : '',
      spoaForce ? 'Automatically Granted after KYC Approved' : '',
    ]

    if (performanceFee) {
      extraContent.push(`Performance fee: ${performanceFee || 0}%`)
    }

    if (!isNil(get(metaParams, 'globalQuestionnaire'))) {
      extraContent.push(metaParams.globalQuestionnaire ? 'Economic Profile' : 'Personal Details')
    }

    if (activityLogType.key === activityLogTypes.clientLoginSuccess.key) {
      if (textParameters.mobileLogin) {
        const appBuild = textParameters.appBuild ? ` - Build Number: ${textParameters.appBuild}` : ''
        if (!textParameters.isiOSLogin && !textParameters.isAndroidLogin) {
          extraContent.push('(Mobile Application Login)')
        } else if (textParameters.isiOSLogin) {
          extraContent.push(`iOS Login${appBuild}`)
        } else if (textParameters.isAndroidLogin) {
          extraContent.push(`Android Login${appBuild}`)
        }
      } else if (textParameters.browserInfo) {
        extraContent.push(textParameters.browserInfo)
      }
    }

    let reasonText, rejectionCodeText, pendingCodeText
    if (event.reason || event.tradingStatusReason || event.kycStatusReason || event.underMonitoringReason) {
      reasonText = `Description: ${event.reason || event.tradingStatusReason || event.kycStatusReason ||
          event.underMonitoringReason}`
    }
    if (event.rejectionCode || event.tradingStatusReasonCode || event.rejectionReasonCode ||
      event.kycStatusReasonCode || event.underMonitoringReasonCode || get(metaParams, 'reason')) {
      rejectionCodeText = `Reason: ${event.rejectionCode || event.tradingStatusReasonCode ||
        event.rejectionReasonCode || event.kycStatusReasonCode || event.underMonitoringReasonCode || get(metaParams, 'reason')}`
    }

    if (event.pendingCode) {
      pendingCodeText = `Reason: ${event.pendingCode}`
    }

    let reason = activityLogType.activityLogShowReason && (reasonText || rejectionCodeText || pendingCodeText) && (
      <p>
        <strong>{rejectionCodeText || pendingCodeText}</strong><br />
        <strong>{event.reason || event.tradingStatusReason || event.kycStatusReason || event.underMonitoringReason
          ? reasonText : ''}</strong>
      </p>
    )

    text = replaceParameters(text, textParameters)

    if (type === activityLogTypes.changedCompany.key) {
      const {newCompany, oldCompany} = parseJSON(meta)
      if (newCompany && oldCompany && (oldCompany !== newCompany)) {
        reason = (<div>
          <p>
            From: <strong>{companies[oldCompany].label}</strong>
            <br />
            To: <strong>{companies[newCompany].label}</strong>
          </p>
        </div>)
      }
    }

    return (
      <div className={style.noteText}>
        {text} {eventLink}
        {reason}
        {content}
        {extraContent.filter((c) => c).join((copyTradingMethod || copyTradingMethod) ? '\n' : '')}
        {args}
      </div>
    )
  }

  render() {
    const {event: {date, user, category, type, origin, ip, ipCountry, gbgStatus, gbgCheckResult,
      skrillVerificationResult}, index,
    } = this.props
    const {avatar, department} = user ?? {}
    const eventCategory = eventCategories[category]
    const noteOrAppointment = eventCategory === eventCategories.appointment || type === activityLogTypes.note.key
    const className = classNames([
      't-event',
      style.expandableEvent,
      date.isAfter() ? style.futureEvent : style.pastEvent,
      style[eventCategory.key],
      style[type],
      style[`origin-${origin}`],
      index % 2 ? style.alternateRow : '',
    ])
    let activityLogType = 'Log'
    if (eventCategory === eventCategories.appointment) {
      activityLogType = 'Appointment'
    } else if (type === activityLogTypes.note.key) {
      activityLogType = activityLogTypes[type].label
    }

    return (
      <Row
        className={className}
        title={`${eventCategory.tooltipPrefix}: ${readableDate(date)}\nType: ${activityLogType}`}
        ref={this.panel}
      >
        <Col xs={2} className={style.eventUserCol}>
          {avatar
            ? <img alt="avatar" src={avatar} className={style.avatar} />
            : <FontAwesomeIcon icon="user" className={style.clientIcon} />
          }
        </Col>
        <Col xs={10} className={style.eventMainCol}>
          {eventCategory === eventCategories.appointment && this.renderAppointementToolbar()}

          <h2 className={classNames(style.title, style.eventTitle)}>
            {getFullName(user, 'Client')}&nbsp;
            {eventCategory === eventCategories.activityLog && <small className="float-right">{readableDate(moment(date), true)}</small>}
          </h2>
          {department && <Badge
            pill
            style={{backgroundColor: this.getUserDepartmentColor(department), color: 'white'}}
          >
            {userDepartments[department]?.label}{user.jobTitle ? ` - ${user.jobTitle}` : ''}
          </Badge>}
          {eventCategory === eventCategories.appointment && this.renderAppointmentMiniDescription()}

          {noteOrAppointment ? this.renderLongTextBody() : this.renderActivityDescription()}

          {
            gbgCheckResult &&
              <Row>
                <Col xs={6}>
                  <strong>GBG Status: </strong> {gbgStatus}
                </Col>
                <Col xs={6}>
                  <Button variant="outline-secondary" className="btn-xs" onClick={() => this.setState({showGBGResult: true})}>
                    GBG Result
                  </Button>
                </Col>
              </Row>
          }
          {skrillVerificationResult &&
            <Row>
              <Col xs={12}>
                <Button variant="outline-secondary" className="btn-xs" onClick={() => this.setState({showSkrillResult: true})}>
                  Result
                </Button>
              </Col>
            </Row>
          }

          {
            ip &&
              <small className={style.ip}>IP:{ip} ({ipCountry})</small>
          }
        </Col>
        <JsonDataModal
          show={this.state.showGBGResult}
          data={gbgCheckResult ? parseJSON(gbgCheckResult) : {}}
          onHide={() => this.setState({showGBGResult: false})}
          heading={'GBG Check Result'}
        />
        <JsonDataModal
          show={this.state.showSkrillResult}
          data={skrillVerificationResult ? parseJSON(skrillVerificationResult) : {}}
          onHide={() => this.setState({showSkrillResult: false})}
          heading={'Skrill Verification Result'}
        />
        {this.state.showMoreButton && <a
          className={style.moreButton}
          onClick={this.toggleMore}
          href="#"
        >&hellip;</a>
        }
      </Row>
    )
  }
})
