import {unset} from '@bdswiss/common-enums'

export default function create(dbClient) {
  return {
    upsertPaymentOption(data) {
      const variables = {
        androidMinBuildVersion: data.androidMinBuildVersion,
        bankDetails: data.bankDetails,
        ccPrimary: data.ccPrimary,
        ccType: data.ccType || unset.unset.key,
        ccVolumeLimit: data.ccVolumeLimit,
        companies: data.companies,
        confluenceLink: data.confluenceLink,
        flowType: data.flowType,
        iOSMinBuildVersion: data.iOSMinBuildVersion,
        id: data.id,
        localizationKey: data.localizationKey,
        logoUrl: data.logoUrl,
        method: data.method,
        name: data.name,
        notes: data.notes,
        paymentKey: data.paymentKey,
        provider: data.provider,
        status: data.status,
        withKYCStatus: data.withKYCStatus,
      }
      const query = `mutation (
                      $androidMinBuildVersion: Int,
                      $bankDetails: [BankTransferDetailsInputType],
                      $ccPrimary: Boolean,
                      $ccType: UnsetOrCardType,
                      $ccVolumeLimit: Int,
                      $companies: [Company],
                      $confluenceLink: String,
                      $flowType: DepositFlowType,
                      $iOSMinBuildVersion: Int,
                      $id: Int,
                      $localizationKey: String,
                      $logoUrl: String!,
                      $method: PaymentOptionMethodsType,
                      $name: String!,
                      $notes: String,
                      $paymentKey: String,
                      $provider: String!,
                      $status: PaymentOptionStatusType
                      $withKYCStatus: WithKYCStatusType
                      ){
                        upsertPaymentOption(
                          androidMinBuildVersion: $androidMinBuildVersion,
                          bankDetails: $bankDetails,
                          ccPrimary: $ccPrimary,
                          ccType: $ccType,
                          ccVolumeLimit: $ccVolumeLimit,
                          companies: $companies,
                          confluenceLink: $confluenceLink,
                          flowType: $flowType,
                          iOSMinBuildVersion: $iOSMinBuildVersion,
                          id: $id,
                          localizationKey: $localizationKey,
                          logoUrl: $logoUrl,
                          method: $method,
                          name: $name,
                          notes: $notes,
                          paymentKey: $paymentKey,
                          provider: $provider,
                          status: $status
                          withKYCStatus: $withKYCStatus
                        ) {
                            id
                        }
                      }`
      return dbClient.query(query, variables)
    },

    signUploadUrl(paymentProvider) {
      const query = `mutation($paymentProvider:String!) {
                      signPaymentLogoFileUrl(paymentProvider: $paymentProvider) {
                        key
                        plainUrl
                        signedUrl
                    }
                  }`

      return dbClient.query(query, {paymentProvider})
    },

    upsertCountryPaymentOption(data) {
      const variables = {
        id: data.id,
        country: data.country,
        paymentOptionId: data.paymentOptionId,
        popular: data.popular,
        rank: data.rank,
        enabled: data.enabled,
      }
      const query = `mutation (
                      $id: Int,
                      $country: PaymentOptionCountry,
                      $paymentOptionId:Int,
                      $popular:Boolean,
                      $rank:Int,
                      $enabled:Boolean,
                      ){
                        upsertCountryPaymentOption(
                          id: $id,
                          country: $country,
                          paymentOptionId: $paymentOptionId,
                          popular: $popular,
                          rank: $rank,
                          enabled: $enabled,
                        ) {
                           id
                        }
                      }`

      return dbClient.query(query, variables)
    },
    replaceCountryPaymentOptions(country, paymentOptions) {
      const variables = {
        country,
        paymentOptions,
      }

      const query = `
        mutation ($country: PaymentOptionCountry! $paymentOptions:[CountryPaymentOptionInputType]!) {
          replaceCountryPaymentOptions(country: $country paymentOptions: $paymentOptions) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    getDynamicFields(paymentProvider, clientId) {
      const variables = {
        paymentProvider,
        clientId
      }
      const query = `
        query ($paymentProvider: String!, $clientId: Int) {
          dynamicWithdrawalFields(
            vendorName: $paymentProvider
            clientId: $clientId
          ) {
            fields
          }
        }
      `
      return dbClient.query(query, variables)
    },

    deletePaymentOption(paymentOptionId) {
      const variables = {
        id: paymentOptionId
      }

      const query = `
        mutation(
          $id: Int!,
          ) {
            deletePaymentOption(
              id: $id,
            )
          }
      `

      return dbClient.query(query, variables)
    },

    showAvailableCountries(paymentOptionId) {
      const variables = {
        id: paymentOptionId
      }

      const query = `
        query ($id: Int!){
          paymentOption(id: $id) {
            id
            countries
          }
        }
      `

      return dbClient.query(query, variables)
    },

    changeCurrentCcProvider() {
      const query = `mutation {
        changeCurrentCcProvider
      }`
      return dbClient.query(query)
    },
  }
}
