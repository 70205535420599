import {get} from 'lodash'
import moment from 'moment-timezone'
import {getPageSize, getOffset} from '../useful'

function initCompetitions(competitions) {
  for (const competition of competitions) {
    initCompetition(competition)
  }
  return competitions
}

function initCompetition(competition) {
  competition.startDate = competition.startDate ? moment(competition.startDate).tz('utc') : ''
  competition.endDate = competition.endDate ? moment(competition.endDate).tz('utc') : ''
  return competition
}

export const competitionsProvider = {

  getQuery: (props) => {
    const {nameFilter, countriesFilter, page} = props

    const args = [
      nameFilter && nameFilter.value ? `name: ${nameFilter.value}` : '',
      countriesFilter && countriesFilter.value ? `countries: ${countriesFilter.value}` : '',
    ].join('\n').trim()

    const query = `{
      competitions(
        ${args}
        limit: ${getPageSize()}
        offset: ${getOffset(page)}
        orderBy: id
        orderDirection: descending
      ) {
        id
        name
        startDate
        endDate
        rules
        prizes
        icon
        termsLink
        applicableClientTypes
        applicableCountries
        applicableCompanies
        createdAt
        updatedAt
        limitedSymbols
        excludedAffiliates
        allowedAffiliates
        competitionWinner
        leaderboard(limit: 3) {
          clientId
          login
          nickname
          roi
          rank
        }
        acceptPreviousWinners
      }
      competitionsCount ${args && `(${args})`}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Competitions loaded',
    (state, res) => ({
      ...state,
      competitions: initCompetitions(res.competitions),
      competitionsCount: res.competitionsCount,
    }),
    [res]
  ),
}

export const allCompetitionsProvider = {

  getQuery: () => {
    const query = `{
      competitions {
        id
        name
      }
    }`

    return query
  },

  onData: (res, dispatch) => dispatch(
    'All Competitions loaded',
    (state, res) => ({
      ...state,
      competitions: res.competitions,
    }),
    [res]
  ),
}

export const competitionProvider = {
  getQuery: (props) => {
    const query = `{
      competition(id: ${get(props, 'match.params.competitionId')}) {
        id
        name
        startDate
        endDate
        rules
        prizes
        icon
        termsLink
        applicableClientTypes
        applicableCountries
        applicableCompanies
        createdAt
        updatedAt
        acceptPreviousWinners
        limitedSymbols
        excludedAffiliates
        allowedAffiliates
        competitionWinner
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Competition loaded',
    (state, res) => ({
      ...state,
      competition: res.competition,
    }),
    [res]
  ),
}

export const leaderboardProvider = {
  getQuery: (props) => {
    const competitionId = get(props, 'match.params.competitionId')
    const {page, pageSize} = props
    const query = `{
      leaderboardForCRM(
        id: ${competitionId}
        limit: ${pageSize}
        offset: ${getOffset(page, pageSize)}
        orderBy: id
        orderDirection: descending
        page: ${page}
      ) {
        login
        rank
        clientId
        fullName
        tradesCount
        balance
        roi
      }
      leaderboardTotalInfo(id: ${competitionId}) {
        participants
        totalROI
        totalAmountOfTrades
      }
    }`

    return query
  },
  onData: (res, dispatch, props) => dispatch(
    'Leaderboard loaded',
    (state, res) => ({
      ...state,
      leaderboard: res.leaderboardForCRM,
      totalInfo: res.leaderboardTotalInfo,
    }),
    [res]
  ),
}
