export default function create(dbClient) {
  return {
    createManualExecution(name, type, params, createdBy) {
      const query = `
        mutation (
          $name: String!,
          $type: String!,
          $params: String!,
          $createdBy: Int!,
        ) {
          createManualExecution (
            name: $name,
            type: $type,
            params: $params,
            createdBy: $createdBy,
          ) {
            name,
            type,
            params,
            createdBy
          }
        }
      `
      return dbClient.query(query, {
        name,
        type,
        params,
        createdBy,
      })
    },

  }
}
