import React from 'react'
import PropTypes from 'prop-types'
import {ButtonGroup, Button} from 'react-bootstrap'
import {includes} from 'lodash'
import PureComponent from '../PureComponent'

export default class TransactionTypeFilter extends PureComponent {

  static propTypes = {
    //typees property assumes input is an enum
    types: PropTypes.object.isRequired,
    value: PropTypes.array,
    onClick: PropTypes.func.isRequired,
  };

  renderButton(value, label, filterType, onClick) {
    return (
      <Button
        id={`t-filter-type-${label.toLowerCase()}`}
        key={value}
        sizr="sm"
        variant="outline-secondary"
        active={includes(filterType, value)}
        onClick={(e) => onClick(value)}
        style={{fontSize: 12}}
      >{label}</Button>
    )
  }

  render() {
    let {types, value, onClick} = this.props
    value = value == null ? '' : value

    return (
      <ButtonGroup>
        {Object.keys(types).map((type) => (
          this.renderButton(type, types[type].label, value, onClick)
        ))}
      </ButtonGroup>
    )
  }

}
