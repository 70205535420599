import {get} from 'lodash'
import {getPageSize, getOffset, getFetchInterval} from '../useful'

export const seminarsProvider = {
  fetchInterval: getFetchInterval,
  getQuery: (props) => {
    try {
      const {seminarsSort, seminarsPage, seminarsSearch} = props
      let searchTextProperty = ''
      if (seminarsSearch) {
        searchTextProperty = `search: "${seminarsSearch}"`
      }
      const args = [searchTextProperty].join('\n').trim()
      const query = `{
        seminars(
          limit: ${getPageSize()}
          offset: ${getOffset(seminarsPage)}
          orderBy: ${seminarsSort.orderBy}
          orderDirection: ${seminarsSort.orderDirection}
          ${args}
        ) {
          id
          seminarCode
          seminarName
          seminarDate
          location
          city
          country
          company
          createdAt
          updatedAt
          ibSeminar
        }
        seminarsCount
      }`

      return query

    } catch (e) {
      throw e
    }
  },

  onData: (res, dispatch, props) => dispatch(
    'Seminars loaded',
    (state, res) => ({
      ...state,
      seminars: res.seminars,
      seminarsCount: res.seminarsCount,
    }),
    [res]
  ),
}

export const seminarProvider = {
  getQuery: (props) => {
    const query = `{
      seminar(id: ${get(props, 'match.params.seminarId')}) {
        id
        seminarCode
        seminarName
        seminarLink
        seminarDate
        location
        city
        country
        company
        createdAt
        updatedAt
        deletedAt
        ibSeminar
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Seminar loaded',
    (state, res) => ({
      ...state,
      seminar: res.seminar,
    }),
    [res]
  ),
}
