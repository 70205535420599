import React from 'react'
import {get} from 'lodash'
import PureComponent from '../PureComponent'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import PartnersCustomizationForm from './PartnersCustomizationForm'
import {compose, provideProps, mountDataProviders} from '../decorators'
import {partnersSepcificCustomizationProvider} from './providers'

class PartnersCustomizationEdit extends PureComponent {
  render() {
    const {selectedCustomization, agents} = this.props

    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/partners-customization"
            active={false}
          >
            <BreadcrumbItem>
              Partners Customization
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            {get(selectedCustomization, 'id')}
          </BreadcrumbItem>
        </Breadcrumb>
        <PartnersCustomizationForm
          selectedPartnerCustomization={selectedCustomization}
          agents={agents}
        />
      </Container>
    )
  }
}
export default compose(
  provideProps((state, _, __, props) => {
    const {agents, selectedCustomization} = state
    return ({
      agents,
      selectedCustomization,
    })
  }),
  mountDataProviders({partnersSepcificCustomizationProvider}),
)(PartnersCustomizationEdit)
