import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Col, Row, Button} from 'react-bootstrap'
import {filter, size} from 'lodash'
import style from './components.module.scss'
import SelectAgent from './SelectAgent'
import {compose, provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {conversionStatuses} from '@bdswiss/common-enums'
import {agentTypes} from '../client/ChangeAgent'

class ReassignAgentClientsModal extends PureComponent {

  static contextTypes = {
    usersProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    user: PropTypes.object,
    args: PropTypes.object,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  setDefaultState() {
    this.setState({
      status: undefined,
      salesAgentId: undefined,
      isTouched: false,
    })
  }

  valueChanged(salesAgentId) {
    this.setState({
      salesAgentId,
      isTouched: true,
    })
  }

  valueChangedStatus(status) {
    this.setState({
      status,
      isTouched: true,
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  save() {
    const {user, args, selectedClients} = this.props
    const clientsIds = Object.keys(selectedClients)?.map((item) => Number(item))
    this.props.actions.user.reassignAgentClients(user, args, this.state.salesAgentId, this.state.status, clientsIds)
      .then((res) => {
        this.hideModal()
        if (this.props.notify) {
          this.props.notify('Clients would be reassigned shortly', 'info')
        }
        if (this.context.usersProvider) {
          this.context.usersProvider.fetch()
        }
      })
      .catch(e => {
        e.isShowActualError = true
        this.context.logError(e)
      })
  }

  render() {
    const {onHide, show, selectedClients} = this.props
    const selectedClientsCount = size(selectedClients)
    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Reassign Clients
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col id="t-select-new-sales-agent" xs={5}>
              <SelectAgent.Input
                placeholderText="Sales Agents"
                label="Choose a new Sales Agent"
                agents={this.props.agents}
                onChange={(target) => this.valueChanged(target.value)}
                value={this.state.salesAgentId}
                optionsFilter={agentTypes.salesAgent.filter}
              />
            </Col>
            <Col id="t-select-new-sales-agent" xs={7}>
              <StylishSelect.Input
                id="t-list-clients-filter-conversion-statuses"
                placeholderText="All Sales Statuses"
                label="Choose new Sales Statuses"
                value={this.state.status}
                options={StylishSelect.enumToStylishOptions(conversionStatuses, 'All Sales Statuses')}
                onChange={(target) => this.valueChangedStatus(target.value)}
              />
            </Col>
            <Col id="t-note" xs={12} className={style.warningMessage}>
              <div className="float-right">
                <strong><sup>*</sup>Important Note:</strong> You are about to re-assign <strong>
                  {selectedClientsCount}</strong> clients.
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-reassign-clients-cancel"
            variant="outline-secondary"
            size="sm"
            className="mr-1"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-reassign-clients-save"
            variant="success"
            size="sm"
            disabled={!this.state.salesAgentId || !this.state.status}
            onClick={() => this.save()}
          >
            Reassign
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  provideProps((state) => {
    const {agents} = state

    return ({
      agents: filter(agents, (agent) => agent.isActive),
    })
  }),
)(ReassignAgentClientsModal)
