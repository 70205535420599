import React from 'react'
import PropTypes from 'prop-types'
import {Button, Row, Col, Form, Card, ButtonToolbar} from 'react-bootstrap'
import {cloneDeep, get, map, omit} from 'lodash'
import {dueDiligenceQuestions} from '@bdswiss/common-enums'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {provideProps} from '../decorators'

export default provideProps()(class DueDiligenceForm extends PureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
    clientProvider: PropTypes.object,
  }

  static propTypes = {
    dueDiligence: PropTypes.object,
    approvedDueDiligenceTest: PropTypes.object,
    header: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const {dueDiligence} = this.props
    this.setState({
      form: {
        answers:  (dueDiligence && cloneDeep(dueDiligence.answers)) || {},
      },
      formError: {},
      formState: {},
    })
  }

  handleOptionInput(event, key) {
    const {form, formErrors} = this.state
    form.answers[key] = event
    const newFormErrors = omit(formErrors, [key])
    this.setState({form: form, formErrors: newFormErrors})
  }

  createDueDiligence = () => {
    const {clientProvider} = this.context
    const {clientId} = this.props
    const {form:{answers}} = this.state

    this.props.actions.client.createDueDiligence(answers, clientId)
      .then((res) => {
        this.props.onClose()
        clientProvider.subProviders.dueDiligences.fetch()
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.activityLogs.fetch()
      })
      .catch(this.context.logError)
  }

  render() {
    const {dueDiligence = {}, header, onClose} = this.props
    const {form} = this.state
    const questions =  {...dueDiligenceQuestions}

    return (
      <div>
        <Row className={style.toolbar}>
          <Col md={12} xs={12}>
            <ButtonToolbar className="float-right">
              <Button
                id="t-client-update-economic-profile-cancel-button"
                tabIndex={-1}
                onClick={onClose}
                variant="outline-secondary"
                size="sm"
                className="mr-1"
              >Cancel
              </Button>
              <Button
                id="t-client-update-economic-profile-update-button"
                variant="success"
                size="sm"
                onClick={() => this.createDueDiligence()}
              >Save
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        {dueDiligence.answers && (
          <Card id="t-client-due-diligence-details-sidebar">
            {header && <Card.Header><strong>{header}</strong></Card.Header>}
            <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
              {Object.values(questions).map((question) => {
                const answerKey = get(form, `answers[${question.key}]` ,'')
                return (
                  <Row key={question.key}>
                    <Col xs={12}>
                      {question.multiselect && question.key !== 'termsConditions' && <Col md={12} xs={12}>
                        <div>
                          <strong>{question.label}</strong>
                          <StylishSelect.Input
                            id={`t-edd-multi-${question.key}`}
                            placeholderText="All Types"
                            value={answerKey || ''}
                            options={StylishSelect.enumToStylishOptions(question.options)}
                            multi
                            clearable
                            onChange={(e) => this.handleOptionInput(map(e, 'value'), question.key)}
                          />
                        </div>
                      </Col>}
                      {question.radioBtn && <Col md={12} xs={12}>
                        <div>
                          <strong>{question.label}</strong>
                          <StylishSelect
                            id={`t-edd-dropdown-${question.key}`}
                            placeholderText="All Types"
                            value={answerKey || ''}
                            options={StylishSelect.enumToStylishOptions(question.options)}
                            onChange={(e) => this.handleOptionInput(e.value, question.key)}
                          />
                        </div>
                      </Col>}
                      {question.textfield && <Col md={12} xs={12}>
                        <Form.Group>
                          <Form.Label>{question.label}</Form.Label>
                          <Form.Control
                            id={`t-edd-text-${question.key}`}
                            type="text"
                            defaultValue={answerKey || ''}
                            onChange={(e) => this.handleOptionInput(e.target.value, question.key)}
                          />
                        </Form.Group>
                      </Col>
                      }
                    </Col>
                  </Row>
                )
              })}
            </Card.Body>
          </Card>
        )}
      </div>
    )
  }
})
