import React from 'react'
import PropTypes from 'prop-types'
import {Row, Card, Col} from 'react-bootstrap'
import classnames from 'classnames'
import {compose, uiMount, provideProps} from '../decorators'
import 'react-datetime/css/react-datetime.css'
import PureComponent from '../PureComponent'
import {alertTypes} from '@bdswiss/common-enums'
import ResolveAlertModal from '../components/ResolveAlertModal'
import JsonDataModal from './../components/JsonDataModal'
import Alert from './Alert'

class Alerts extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    alerts: PropTypes.array,
    uiDispatch: PropTypes.func.isRequired,
    uiState: PropTypes.object.isRequired,
    onCreate: PropTypes.func,
  }

  closeResolveAlertModal() {
    this.props.uiDispatch('Close resolve alert modal', (state) => ({
      ...state,
      showResolveAlertModal: false,
      currentAlert: undefined,
      alertDetails: undefined,
    }))
  }

  closeAlertDetailsModal() {
    this.props.uiDispatch('Close alert details modal', (state) => ({
      ...state,
      showAlertDetailsModal: false,
      currentAlert: undefined,
      alertDetails: {},
    }))
  }

  resolveAlert(alert, comment, reason) {
    this.props.actions.client.resolveAlert(alert, comment, reason)
      .then((res) => {
        this.context.clientProvider.fetch()
      })
      .catch(this.context.logError)
  }

  render() {
    const {alerts, showResolveAlertModal, currentAlert, showAlertDetailsModal, alertDetails} = this.props
    const alertLabel = currentAlert ? alertTypes[currentAlert.type].label : ''

    if (!alerts) {
      return (
        <Row>
          Loading...
        </Row>
      )
    }

    return (
      <div>
        <Row>
          <Col xs={12}>
            <Card className={classnames(['panel-events'])} id="alert-client">
              <Card.Body>
                {alerts.map((alert) => <Alert alert={alert} key={alert.id} />)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {currentAlert && <ResolveAlertModal
          show={showResolveAlertModal}
          onHide={this.closeResolveAlertModal.bind(this)}
          onDone={(comment, reason) => {
            this.resolveAlert(currentAlert, comment, reason)
            this.closeResolveAlertModal()
          }}
          alert={currentAlert}
        />}
        <JsonDataModal
          show={showAlertDetailsModal}
          data={alertDetails || {}}
          onHide={() => this.closeAlertDetailsModal()}
          heading={`Alert "${alertLabel}"`}
        />
      </div>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'alertsUi']),

  provideProps((state, uiState) => {
    const {showResolveAlertModal, currentAlert, showAlertDetailsModal, alertDetails} = uiState
    return {
      showResolveAlertModal,
      currentAlert,
      showAlertDetailsModal,
      alertDetails,
    }
  }),
)(Alerts)
