import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import {filter, find, isArray, isEmpty, merge} from 'lodash'
import classNames from 'classnames'
import PureComponent from '../PureComponent'

/*
 * Renders option in dropdown
 */
// class SelectOption extends PureComponent {

//   handleMouseDown = (event) => {
//     event.stopPropagation()
//     this.props.onSelect(this.props.option, event)
//   }

//   render() {
//     const {avatar, label} = this.props.option

//     return (
//       <div
//         className={`${this.props.className}`}
//         onMouseDown={this.handleMouseDown}
//       >
//         {avatar ? <img alt="avatar" src={avatar} className={style.avatarStyle} /> : ''}
//         &zwnj;
//         {label}
//       </div>
//     )
//   }

// }

/*
 * Renders selected value (not in dropdown)
 */
// class SelectValue extends PureComponent {

//   onRemove = (event) => {
//     event.stopPropagation()
//     this.props.onRemove(this.props.value)
//   }

//   hasRemoveIcon() {
//     return !this.props.disabled && this.props.onRemove
//   }

//   renderRemoveIcon() {
//     if (!this.hasRemoveIcon()) {
//       return
//     }

//     return (
//       <span className="Select-value-icon"
//         aria-hidden="true"
//         onMouseDown={this.onRemove}
//       >&times;</span>
//     )
//   }

//   render() {
//     const {value: {label, avatar}} = this.props
//     const avatarStyle = this.hasRemoveIcon() ? style.avatarStyleWithRemove : style.avatarStyle

//     return (
//       <div className="Select-value">
//         {this.renderRemoveIcon()}
//         <span className="Select-value-label">
//           {avatar
//             ? <img alt="avatar" src={avatar} className={avatarStyle} />
//             : ''
//           }
//           &zwnj;
//           {label}
//         </span>
//       </div>
//     )
//   }
// }

export default class StylishSelect extends PureComponent {

  static propTypes = {
    id: PropTypes.string,
    highlightIfActive: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    placeholderText: PropTypes.string,
    //each item MUST have `value` and `label` fields and MAY have avatar
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    openUp: PropTypes.bool,
    multi: PropTypes.bool,
    className: PropTypes.string,
  };

  static defaultProps = {
    disabled: false,
    clearable: false,
  };

  /**
   * Use this when you want to render an enumeration option.
   *
   * Convenience method that transforms an enumeration into
   * form this component options array. E.g. it translates
   * `key` enum field value into `value` option field.
   *
   * Note: the option wont have an avatar.
   *
   * @param enumeration - enumeration to be translated
   * @param emptyOptionText - (optional) string that will be added in front of all options and
   *   represents 'none selected' option
   * @param sort - (optional) sort options by label (empty option is always first)
   * @returns {Array} of options
   */
  static enumToStylishOptions(enumeration, emptyOptionText, sort) {
    let enumValues = Object.values(enumeration).map((option) => ({
      label: option.label,
      value: option.key,
      disabledAndActive: option.disabledAndActive,
    }))

    if (sort) {
      enumValues = enumValues.sort((a, b) => a.label.localeCompare(b.label))
    }

    if (emptyOptionText != null) {
      enumValues.unshift({
        label: emptyOptionText,
        value: '',
      })
    }
    return enumValues
  }

  setValue(value) {
    this.setState({value})
  }

  render() {
    const {id, options, onChange, value, placeholderText, disabled, clearable, isInvalid,
      highlightIfActive, openUp, multi, ...otherProps}
      = this.props

    // filters need to be colored when active
    const valueSet = isArray(value) ? !isEmpty(value) : !!value
    const highlightOptions = highlightIfActive && valueSet ? {className: 'Select-active'} : {}

    // this is needed, because react-select does not support id
    const idOptions = id ? {id: id} : {}

    const outerDivProps = merge({}, highlightOptions, idOptions)

    const selectClassName = classNames({'Select--up': openUp}, this.props.className)
    const selectValue = multi
      ? filter(options, (o) => isArray(value) ? value.includes(o.value) : o.value === value )
      : find(options, {value})
    return (
      //tabindex is there so that component can be colored blue on focus. Focus on div works only when
      //tabindex is set.
      <div {...outerDivProps} tabIndex={0}>
        <Select
          classNamePrefix="select-box"
          isClearable={clearable}
          onChange={onChange}
          options={options}
          placeholder={placeholderText}
          value={selectValue}
          isDisabled={disabled}
          className={selectClassName}
          isMulti={multi}
          {...otherProps}
          styles={{
            control: styles => ({
              ...styles,
              borderColor: isInvalid ? 'red' : styles.borderColor,
            }),
            menu: styles => ({...styles, zIndex: 9999}),
          }}
        />
      </div>
    )
  }
}

/**
 * Dropdown mimics react-bootstrap select component as closely as possible so that it can be
 * used in the same form as other react-bootstrap inputs. It has the same properties, color
 * on error/focus, height and most importantly padding around.
 *
 * Known differences:
 *  * react-bootstrap select broadcasts selected value in `e.target.value`
 *  * this component broadcasts selected value in `e.value`
 */
class StylishSelectInput extends PureComponent {

  static propTypes = merge({}, StylishSelect.propTypes, {
    bsStyle: PropTypes.string,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
  });

  render() {
    const classes = {
      'has-error': this.props.bsStyle === 'error',
      'has-success': this.props.bsStyle === 'success',
    }

    return (
      <div className={classNames('form-group', classes)}>
        {this.props.label &&
          <label className={classNames('control-label', this.props.labelClassName)}>{this.props.label}</label>
        }
        <StylishSelect
          {...this.props}
          className={this.props.wrapperClassName || ''}
        />
      </div>
    )
  }

}

StylishSelect.Input = StylishSelectInput
