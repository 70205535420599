import {getFetchInterval} from '../useful'

// function initReport(report) {
//   for (const item of report) {
//     item['start_requested_date'] = item['start_requested_date'] ? item['start_requested_date'].slice(0, 10) : ''
//     item['stop_requested_date'] = item['stop_requested_date'] ? item['stop_requested_date'].slice(0, 10) : ''
//   }
//   return report
// }

export const copiersReportProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {dateFilter, companyNameFilter, reportSort} = props
    const reportDateStart = dateFilter?.reportDateStart ? `reportDateStart: "${dateFilter.reportDateStart}"` : ''
    const reportDateEnd = dateFilter?.reportDateEnd ? `reportDateEnd: "${dateFilter.reportDateEnd}"` : ''
    const companyCondition = companyNameFilter && companyNameFilter !== 'All' ? `company: "${companyNameFilter}"` : ''

    const query = `{
      getCopiersReport(
        ${companyCondition}
        ${reportDateStart}
        ${reportDateEnd}
        ${reportSort?.orderBy ? `orderBy: ${reportSort.orderBy}` : ''}
        ${reportSort?.orderDirection ? `orderDirection: ${reportSort.orderDirection}` : ''}
      ) {
        member_id
        ib_account_copying
        account_id
        start_requested_date
        stop_requested_date
        company
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Copiers Report loaded',
    (state, res) => ({
      ...state,
      copiersReport: res.getCopiersReport != null
        ? res.getCopiersReport
        : state.copiersReport || [],
    }),
    [res]
  ),
}
