import {getFetchInterval} from '../useful'

export const manualExecutionsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => `{
      manualExecutions(
        limit: 100
        orderBy: id
        orderDirection: descending
      ) {
        id
        name
        type
        params
        createdBy
        createdAt
      }
    }`,

  onData: (res, dispatch) =>
    dispatch(
      'Manual executions list loaded',
      (state, res) => ({
        ...state,
        manualExecutions: res.manualExecutions ? res.manualExecutions : [],
      }),
      [res]
    ),
}

export const existingScriptNamesProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => `{
      scripts {
        name,
        description
      }
    }`,

  onData: (res, dispatch) => dispatch(
    'Existing script names loaded',
    (state, res) => ({
      ...state,
      scripts: res.scripts ? res.scripts : [],
    }),
    [res]
  ),
}
