import {isNil} from 'lodash'

function validateNonempty(obj, key, errors) {
  if ((!obj[key] && key in obj) || isNil(obj[key]) || obj[key].length === 0) {
    errors[key] = 'This field is required.'
  }
}

export function validateTag(tag) {
  const errors = {}
  validateNonempty(tag, 'name', errors)
  validateNonempty(tag, 'tagCategory', errors)
  errors._direct_count = Object.keys(errors).length
  return errors
}

export function noTagValidationError() {
  return {
    _direct_count: 0,
    _count: 0,
  }
}
