import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {
  Row, Col, Table, Button, Card, Pagination, ButtonToolbar, Form, Container,
  OverlayTrigger, Tooltip, InputGroup, Modal,
} from 'react-bootstrap'
import {merge, get, capitalize, includes, reject, debounce, map, filter, some, first, isEmpty, range} from 'lodash'
import {accountTypes, depositStatuses, depositVendors, beginDepositResults,
  bonusOfferTypes, companies, clientTypes, currencies, yesNo, orderDirections, conversionStatuses, userDepartments,
  frontends} from '@bdswiss/common-enums'
import {canQueryDeposits, canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import {events} from '../enums'
import style from './deposits.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {isCentAccount, orderByFields} from '../common/utils'
import MaskedField from '../clients/MaskedField'
import SelectAgent from '../components/SelectAgent'
import {safeLinkClientDeposits} from '../utils/links'
import StylishSelect from '../components/StylishSelect'
import StatusFilter from './../components/StatusFilter'
import {getDepositStatusLabel} from '../utils/rendering'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import SelectAgentFilter from '../components/SelectAgentFilter'
import AccountTypesFilter from '../components/AccountTypesFilter'
import DepositReceiptModal from './../components/DepositReceiptModal'
import {getAllowedCompanies, getAccountLabel} from '../utils/general'
import {countriesAndRegionsOptions, createCountriesFilter} from '../utils/country'
import {depositsProvider, showEnum, depositsTopVipCountProvider, thisMonthDepositsProvider} from './providers'
import {allCampaignsProvider} from '../campaigns/providers'
import {getFormattedAmount, getFullName, unassigned, getPageCount, readableDate, getVendorPaymentType,
  getVendorCardDetails, getPageRange, getFailedReason} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'
import {companyName} from '../utils/companyName'
import {fxRatesProvider} from '../fxrates/providers'
import CampaignsFilter from '../components/CampaignsFilter'

const filterableDepositStatuses = {
  pending: depositStatuses.pending,
  authorized: depositStatuses.authorized,
  completed: depositStatuses.completed,
  failed: depositStatuses.failed,
  chargeback: depositStatuses.chargeback,
  held: depositStatuses.held,
  incomplete: depositStatuses.incomplete,
  cancelled: depositStatuses.cancelled,
  rejected: depositStatuses.rejected,
  reversed: depositStatuses.reversed,
  partiallyReversed: depositStatuses.partiallyReversed,
  awaitingPayment: depositStatuses.awaitingPayment,
}

const EXPORT_LIMIT = 20000

class Deposits extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
    this.doFilterByAmount = debounce(this.doFilterByAmount, 1500)

    this.state = {
      exportPage: 1,
      exportModalShown: false,
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    depositsProvider: PropTypes.object.isRequired,
    depositsTopVipCountProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
    thisMonthDepositsProvider: PropTypes.object.isRequired,
    fxRatesProvider: PropTypes.object.isRequired,
    allCampaignsProvider: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
    this.polling = setInterval(this.fetchProvider, 60000)
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {
      createdFrom: '',
    }
    this.setState({dateFilters: stateDateFilters})

    const stateAmountFilters = this.props.amountFilters || {
      amountFrom: '',
      amountTo: '',
    }
    this.setState({amountFilters: stateAmountFilters})
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
    clearInterval(this.polling)
  }

  fetchProvider() {
    this.context.depositsProvider.fetch()
    this.context.depositsTopVipCountProvider.fetch()
    this.context.thisMonthDepositsProvider.fetch()
    this.context.fxRatesProvider.fetch()
    this.context.allCampaignsProvider.fetch()
  }

  activateShowFilter(e) {
    this.props.uiDispatch(
      'Show on Admin Bonus Pending',
      (state) => ({...state, show: e.target.value, depositsPage: 1})
    )
  }

  activateSalesAgentFilter(value) {
    this.props.uiDispatch(
      'Filter deposits by sales agents',
      (state, arg) => ({...state, depositsSalesAgentFilter: arg, depositsPage: 1}),
      [value]
    )
  }

  activateStatusFilter(value) {
    const oldValue = this.props.depositsStatusFilter
    let depositsStatusFilter
    if (includes(oldValue, value)) {
      depositsStatusFilter = reject(oldValue, (v) => v === value)
    } else {
      depositsStatusFilter = [...oldValue, value]
    }
    this.props.uiDispatch(
      'Filter deposits by status',
      (state, arg) => ({...state, depositsStatusFilter: arg, depositsPage: 1}),
      [depositsStatusFilter]
    )
  }

  activateAccountTypeFilter(filter) {
    this.props.uiDispatch(
      'Filter accounts',
      (state) => ({...state, depositsAccountsFilter: filter, depositsPage: 1})
    )
  }

  selectCampaign(campaign) {
    this.setState({selectedCampaignId: campaign?.value || null})
    this.doFilterByCampaignId(campaign?.value || null)
  }

  doDepositsSearch(searchText) {
    this.props.uiDispatch(
      'Search deposits',
      (state, arg) => ({...state, depositsSearch: arg, depositsPage: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  doFilterByCampaignId(cid) {
    this.props.uiDispatch(
      'Filter deposits by campaign',
      (state, arg) => ({...state, campaignId: cid, depositsPage: 1}),
      [cid]
    )
  }

  doFilterByCompany(company) {
    this.props.uiDispatch(
      'Filter deposits by company',
      (state, arg) => ({...state, companyFilter: company, depositsPage: 1}),
      [company]
    )
  }

  doFilterByCountry(selection) {
    this.props.uiDispatch(
      'Filter deposits by country or region',
      (state, arg) => ({
        ...state,
        countriesFilter: createCountriesFilter(selection),
        countriesFilterValue: selection.value,
        depositsPage: 1,
      }),
      [selection.value]
    )
  }

  doFilterByVendors(selections) {
    const values = selections && selections.length > 0 && selections.map((v) => v.value)
    this.props.uiDispatch(
      'Filter deposits by company',
      (state, arg) => ({...state, vendorsFilter: values, depositsPage: 1}),
      [values]
    )
  }

  doFilterByClientTypes(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter deposits by client types',
      (state, arg) => ({...state, clientTypesFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByCurrency(selection) {
    this.props.uiDispatch(
      'Filter deposits by currency',
      (state, arg) => ({
        ...state,
        currencyFilter: selection.value,
        currencyFilterValue: selection.value,
        depositsPage: 1,
      }),
      [selection.value]
    )
  }

  doFilterByTopVip(e) {
    const {topVipFilter} = this.state
    this.props.uiDispatch(
      'Filter deposits by VIP',
      (state, arg) => ({...state, topVipFilter: e.value, depositsPage: 1}),
      [topVipFilter]
    )
  }

  doOrderByFilter(e) {
    const {orderByFilter} = this.state
    this.props.uiDispatch(
      'Sorting deposits by selected field',
      (state, arg) => ({...state, orderByFilter: e.value, depositsPage: 1}),
      [orderByFilter]
    )
  }

  doOrderDirectionFilter(e) {
    const {orderDirectionFilter} = this.state
    this.props.uiDispatch(
      'Sorting deposits by direction',
      (state, arg) => ({...state, orderDirectionFilter: e.value, depositsPage: 1}),
      [orderDirectionFilter]
    )
  }


  handleAmountFilterChanged(name, value) {
    const {amountFilters} = this.state
    this.setState({
      amountFilters: {...amountFilters, [name]: value},
    })
    this.doFilterByAmount()
  }

  doFilterByAmount() {
    const {amountFilters} = this.state
    this.props.uiDispatch(
      'Filter documents by amount range',
      (state, arg) => ({...state, amountFilters, page: 1}),
      [amountFilters]
    )
  }

  handleDateFilterChanged(name, value) {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })

    if (!value || moment.isMoment(value)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilters} = this.state
    if (dateFilters && moment.isMoment(dateFilters.createdFrom)) {
      dateFilters.createdFrom.startOf('day')
    }
    if (dateFilters && moment.isMoment(dateFilters.createdTo)) {
      dateFilters.createdTo.endOf('day')
    }
    this.props.uiDispatch(
      'Filter documents by dates',
      (state, arg) => ({...state, dateFilters, page: 1}),
      [dateFilters]
    )
  }

  onSearch() {
    this.setState({loading: true})
    const {exportPage} = this.state
    this.props.actions.deposits.depositsExportSearch(this.props, exportPage, EXPORT_LIMIT).then((res) => {
      this.setState({loading: false, exportModalShown: false, exportPage: 1})
      this.context.showNotification({
        title: 'Exports Tool',
        message: 'Export Tool working in background. You will receive the report on your email shortly',
        position: 'tr',
        level: 'success',
      })
    }).catch((e) => {
      this.setState({loading: false})
      this.context.showNotification({
        message: e.message,
        level: 'error',
        autoDismiss: 0,
      })
    })
  }

  getFrontendLabel(frontend) {
    switch (frontend) {
      case frontends.mobile.value:
        return 'Mobile'
      case frontends.web.value:
      case frontends.web2.value:
        return 'Dashboard'
      default:
        return ' '
    }
  }

  renderDeposit(deposit) {
    const {id, account, status, createdAt, salesAgent} = deposit
    const {client} = account || {}
    const clientName = getFullName(client)
    const vendor = deposit.payment ? deposit.payment.vendor : ''
    const formattedVendor = get(depositVendors, [vendor, 'label'], capitalize(vendor))
    const {label: paymentType} = getVendorPaymentType(deposit)
    const linkEnabled = !canQueryAffiliateManagerClients(this.props.viewer)

    const payment = {...deposit.payment}
    payment.receipt = payment.receipt && JSON.parse(payment.receipt)
    payment.productReceipt = payment.productReceipt && JSON.parse(payment.productReceipt)
    payment.meta = payment.meta && JSON.parse(payment.meta)

    let statusCell = safeLinkClientDeposits(client, getDepositStatusLabel(depositStatuses[status]), id, linkEnabled)
    if (status === beginDepositResults.failed.value) {
      const reason = getFailedReason(deposit) || 'Unknown'
      const tooltip = (
        <Tooltip id={`status-tooltip-${id}`}>{reason}</Tooltip>
      )
      statusCell = (
        <OverlayTrigger placement="bottom" overlay={tooltip}>
          {statusCell}
        </OverlayTrigger>
      )
    }

    const remoteId = account && account.remoteId
    const accountCell = safeLinkClientDeposits(client,
      account ?
        (<span> {getAccountLabel(accountTypes[account.__typename])} <br /> [{remoteId}] </span>)
        : '',
      id, linkEnabled)

    const hasOpenPositions = get(account, 'forexPositionsCount', 0) > 0
    const campaignLabel = map(client.registrationCampaigns, 'campaignId').join(', ')
    const noCall = get(first(client.registrationCampaigns), 'campaign.noCall')
    const campaignStyle = noCall ? {backgroundColor: '#F1959B'} : {}
    return (
      <tr key={id} id={`t-client-deposits-deposit-id-${id}`}
        className={classNames([
          hasOpenPositions && status === depositStatuses.held.key ? 'open-positions' : '',
          vendor === depositVendors.bonus.key ? 'bonusBackground' : '',
          client.topVip ? 'vip' : '',
        ])}
      >
        <td>{safeLinkClientDeposits(client, id, id, linkEnabled)}</td>
        <td>{safeLinkClientDeposits(client, companies[client.company].label, id, linkEnabled)}</td>
        <td>{safeLinkClientDeposits(client, clientName, id, linkEnabled)}</td>
        <td><MaskedField client={client} key="email" column={{key: 'email'}} {...this.props} /></td>
        <td className={style.amountCell}>
          {safeLinkClientDeposits(client, getFormattedAmount(deposit), id, linkEnabled)}
          {isCentAccount(account)
            ? safeLinkClientDeposits(client, getFormattedAmount({
              currency: currencies.CUD.baseCurrency,
              amount: deposit.amount / currencies.CUD.baseCurrencyRate,
            }), id, linkEnabled)
            : ''
          }
        </td>
        <td>
          {safeLinkClientDeposits(client, `${formattedVendor}${paymentType || ''}`, id, linkEnabled)}
        </td>
        <td>
          {safeLinkClientDeposits(client, getVendorCardDetails(deposit, client.id), id, linkEnabled)}
        </td>
        <td style={campaignStyle}>{safeLinkClientDeposits(client, campaignLabel, id, linkEnabled)}</td>
        <td>{accountCell}</td>
        <td title={createdAt.fromNow()}>
          {safeLinkClientDeposits(client, readableDate(createdAt), id, linkEnabled)}
        </td>
        <td className="status text-center">
          {statusCell}
        </td>
        <td>{this.getFrontendLabel(payment.meta?.frontend)}</td>
        <td>{safeLinkClientDeposits(client, getFullName(salesAgent, unassigned), id, linkEnabled)}</td>
        <td>
          {!canQueryAffiliateManagerClients(this.props.viewer) && <div>
            <Button
              className="btn-xs"
              variant={client.topVip ? 'light' : 'outline-secondary'}
              onClick={(e) => {
                this.props.uiDispatch(
                  'Show receipt',
                  (state, arg) => ({...state, showReceipt: arg}), [{id, payment}]
                )
              }}
            >Receipt</Button>
          </div>}
        </td>
      </tr>
    )
  }

  renderAdminBonus(bonusoffer) {
    const {id, account, createdAt, type, status} = bonusoffer
    const {client} = account || {}
    const clientName = getFullName(client)
    const salesAgent = getFullName(client && client.salesAgent, unassigned)
    const statusCell = capitalize(status)

    return (
      <tr key={id} id={`t-client-deposits-deposit-id-${id}`}>
        <td>{safeLinkClientDeposits(client, id, id)}</td>
        <td>{safeLinkClientDeposits(client, companies[client.company].label, id)}</td>
        <td>{safeLinkClientDeposits(client, clientName, id)}</td>
        <td>{safeLinkClientDeposits(client, client.email, id)}</td>
        <td className={style.amountCell}>
          {safeLinkClientDeposits(client, getFormattedAmount(bonusoffer), id)}
        </td>
        <td>
          {bonusOfferTypes[type].label}
        </td>
        <td />
        <td />
        <td>
          {safeLinkClientDeposits(client, account ? getAccountLabel(accountTypes[account.__typename]) : '', id)}
        </td>
        <td title={createdAt.fromNow()}>
          {safeLinkClientDeposits(client, readableDate(createdAt), id)}
        </td>
        <td className="status">
          {statusCell}
        </td>
        <td>{' '}</td>
        <td>{safeLinkClientDeposits(client, salesAgent, id)}</td>
        <td />
      </tr>
    )
  }

  hideModalReceipt() {
    this.props.uiDispatch('Close show receipt', (state) => ({...state, showReceipt: null}))
  }

  renderDeposits(deposits) {
    return deposits.map((deposit) => this.renderDeposit(deposit))
  }

  renderAdminBonuses(bonusOffers) {
    return bonusOffers.map((bonusoffer) => this.renderAdminBonus(bonusoffer))
  }

  renderFilterDeposits() {
    const {depositsStatusFilter, depositsTopVipCount} = this.props
    return (
      <div>
        <ButtonToolbar>
          <StatusFilter
            value={depositsStatusFilter}
            statuses={filterableDepositStatuses}
            depositsTopVipCount={depositsTopVipCount}
            onClick={this.activateStatusFilter.bind(this)}
          />
        </ButtonToolbar>
      </div>
    )
  }


  renderFilterAdminBonus() {
    return (
      <ButtonToolbar>
        <Button
          className="active"
          size="sm"
          variant="outline-secondary"
          value={showEnum.adminBonus.key}
          onClick={this.activateShowFilter.bind(this)}
        >
          Pending Admin Bonus
        </Button>
        <Button
          id="t-admin-deposits"
          size="sm"
          variant="outline-secondary"
          value={showEnum.deposits.key}
          onClick={this.activateShowFilter.bind(this)}
        >
          Deposits
        </Button>
      </ButtonToolbar>
    )
  }

  renderAmountSummary() {

    // Not sure where to add these utils
    const formatCommaSeparationNumber = (number) => {
      const formattedFractional = (number - Math.trunc(number)).toString().slice(1, 4)
      const formattedInt = Math.trunc(number).toString().length > 3 ? Math.trunc(number).toString().slice(0, -3) + ',' + Math.trunc(number).toString().slice(-3) : Math.trunc(number).toString()
      return `${formattedInt}${formattedFractional}`
    }
    const getHoursFromMS = (ms) => ms / (1000 * 60 * 60)

    const {thisMonthDeposits, fxRates} = this.props

    const depositsByCurrency = {}
    thisMonthDeposits.forEach((deposit) => {
      if (!Object.hasOwn(depositsByCurrency, deposit.currency)) {
        depositsByCurrency[deposit.currency] = [deposit]
      } else {
        depositsByCurrency[deposit.currency].push(deposit)
      }
    })
    for (const property in depositsByCurrency) {
      const currentMonthAmountSum = depositsByCurrency[property].reduce((acc, deposit) => acc + deposit.amount, 0)
      const thisWeekDeposits = depositsByCurrency[property].filter((deposit) => (getHoursFromMS(new Date() - new Date(deposit.createdAt)) <= 24 * 7))
      const currentWeekAmountSum = thisWeekDeposits.reduce((acc, deposit) => acc + deposit.amount, 0)
      const todayDeposits = depositsByCurrency[property].filter((deposit) => (getHoursFromMS(new Date() - new Date(deposit.createdAt)) <= 24))
      const todayAmountSum = todayDeposits.reduce((acc, deposit) => acc + deposit.amount, 0)
      depositsByCurrency[property] = {
        currentMonthAmountSum,
        currentWeekAmountSum,
        todayAmountSum
      }
    }

    const currentSums = {
      currentMonthAmountSummary: 0,
      currentWeekAmountSummary: 0,
      todayAmountSummary: 0,
    }
    for (const property in depositsByCurrency) {
      const rate = parseInt(fxRates[property]['EUR'])
      currentSums.currentMonthAmountSummary += depositsByCurrency[property].currentMonthAmountSum * rate
      currentSums.currentWeekAmountSummary += depositsByCurrency[property].currentWeekAmountSum * rate
      currentSums.todayAmountSummary += depositsByCurrency[property].todayAmountSum * rate
    }
    currentSums.currentMonthAmountSummary = formatCommaSeparationNumber(currentSums.currentMonthAmountSummary)
    currentSums.currentWeekAmountSummary = formatCommaSeparationNumber(currentSums.currentWeekAmountSummary)
    currentSums.todayAmountSummary = formatCommaSeparationNumber(currentSums.todayAmountSummary)

    return (
      <Card className="my-3 px-5 py-3 w-100" style={{fontSize: 16}}>
        <Row>
          <div className="pr-5">
            Today: {currentSums.todayAmountSummary}
          </div>
          <div className="pr-5">
            Current week: {currentSums.currentWeekAmountSummary}
          </div>
          <div>
            Current month: {currentSums.currentMonthAmountSummary}
          </div>
        </Row>
      </Card>
    )
  }

  render() {
    const {deposits, depositsSalesAgentFilter, depositsAccountsFilter, depositsSearch, agents,
      depositsPage, depositsCount, showReceipt, show,
      bonusOffers, bonusOffersCount, viewer, companyFilter, vendorsFilter, clientTypesFilter,
      countriesFilterValue, currencyFilterValue, topVipFilter, partnersAgentFilter,
      orderByFilter, orderDirectionFilter, conversionStatusFilter, campaigns,
    } = this.props

    const filteredDeposits = filter(deposits, (d) => get(d, 'account.client'))
    const {searchText, selectedCampaignId} = this.state || {}
    const rows = show === 'adminBonus' ? this.renderAdminBonuses(bonusOffers) : this.renderDeposits(filteredDeposits)
    const itemCount = show === 'adminBonus' ? bonusOffersCount : depositsCount
    const filters = show === 'adminBonus' ? this.renderFilterAdminBonus() : this.renderFilterDeposits()
    const searchFilters = show !== 'adminBonus'
    const companyOptions = [
      {label: 'All Companies', value: undefined},
      ...StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer)),
    ]

    const {dateFilters, amountFilters, loading, exportModalShown, exportPage} = this.state
    const currencyOptions = [{label: 'All Currencies', value: undefined},
      ...Object.values(currencies).map((currency) => ({
        value: currency.key,
        label: `${currency.label} ${currency.symbol ? `(${currency.symbol})` : ''}`,
      }))]
    const activeAgents = filter(agents, (agent) => agent.isActive)
    const partnerAgents = filter(activeAgents, (agent) =>
      some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)))

    return (
      <Container>
        {
          searchFilters && [
            <Row key="filter-row-1">
              <Col xs={viewer.companies.length > 1 ? 3 : 3}>
                <InputGroup>
                  <Form.Control
                    type="text"
                    value={searchText !== undefined ? searchText : depositsSearch || ''}
                    placeholder="Search by ID"
                    onChange={(e) => this.setState({searchText: e.target.value})}
                    onKeyUp={(e) => (
                      (e.key === 'Enter' && this.doDepositsSearch(searchText)) ||
                      (e.key === 'Escape' && this.doDepositsSearch(''))
                    )}
                  />
                  <InputGroup.Append>
                    {depositsSearch && <Button
                      key={1}
                      title="Clear"
                      variant={depositsSearch ? 'success' : 'outline-dark'}
                      onClick={() => this.doDepositsSearch('')}
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>}
                    <Button
                      key={2}
                      title="Search"
                      variant={depositsSearch ? 'success' : 'outline-dark'}
                      onClick={() => this.doDepositsSearch(searchText)}
                    >
                      <FontAwesomeIcon icon="search" />
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col xs={3}>
                <AccountTypesFilter
                  companies={viewer.companies}
                  value={depositsAccountsFilter}
                  onChange={this.activateAccountTypeFilter.bind(this)}
                />
              </Col>
              <Col xs={viewer.companies.length > 1 ? 2 : 3}>
                <SelectAgentFilter
                  viewer={viewer}
                  agents={activeAgents}
                  onChange={(target) => this.activateSalesAgentFilter(target.value)}
                  value={depositsSalesAgentFilter}
                />
              </Col>
              {
                viewer.companies.length > 1 &&
                  <Col xs={2}>
                    <StylishSelect
                      id="t-deposits-company-filter"
                      placeholderText="All Companies"
                      value={companyFilter}
                      options={companyOptions}
                      highlightIfActive
                      onChange={(e) => this.doFilterByCompany(e.value)}
                    />
                  </Col>
              }
              <Col xs={2}>
                <StylishSelect
                  id="t-deposit-country-filter"
                  placeholderText="All Countries"
                  value={countriesFilterValue}
                  options={countriesAndRegionsOptions}
                  highlightIfActive
                  onChange={(e) => this.doFilterByCountry(e)}
                />
              </Col>
            </Row>,
            <Row key="filter-row-2" className="mt-3">
              <Col xs={2}>
                <span className={style.label}>&nbsp;</span>
                <StylishSelect
                  id="t-deposits-vendors-filter"
                  placeholderText="All Vendors"
                  value={vendorsFilter}
                  options={StylishSelect.enumToStylishOptions(depositVendors)}
                  highlightIfActive
                  multi
                  clearable
                  onChange={(e) => this.doFilterByVendors(e)}
                />
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}> Created From </span>
                  <DateTime
                    id="t-deposits-created-from-filter"
                    timeFormat={false}
                    onChange={(e) => this.handleDateFilterChanged('createdFrom', e)}
                    value={dateFilters.createdFrom}
                    onFocus={() => this.doDateFilterSearch.cancel()}
                    closeOnSelect
                    className={style.datetime}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <span className={style.label}> Created To </span>
                <DateTime
                  id="t-deposits-created-to-filter"
                  timeFormat={false}
                  onChange={(e) => this.handleDateFilterChanged('createdTo', e)}
                  value={dateFilters.createdTo}
                  onFocus={() => this.doDateFilterSearch.cancel()}
                  closeOnSelect
                  className={style.datetime}
                />
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}>&nbsp;</span>
                  <StylishSelect
                    id="t-client-types-filter"
                    placeholderText="All Client Types"
                    value={clientTypesFilter}
                    options={StylishSelect.enumToStylishOptions(clientTypes)}
                    highlightIfActive
                    multi
                    clearable
                    onChange={(e) => this.doFilterByClientTypes(e)}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}> Amount From </span>
                  <Form.Control
                    id="t-deposits-amount-from-filter"
                    type="number"
                    onChange={(e) => this.handleAmountFilterChanged('amountFrom', e.target.value)}
                    value={amountFilters.amountFrom}
                    onFocus={() => this.doFilterByAmount.cancel()}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}> Amount To </span>
                  <Form.Control
                    id="t-deposits-amount-to-filter"
                    type="number"
                    onChange={(e) => this.handleAmountFilterChanged('amountTo', e.target.value)}
                    value={amountFilters.amountTo}
                    onFocus={() => this.doFilterByAmount.cancel()}
                  />
                </div>
              </Col>
            </Row>,
            <Row key="filter-row-3" className="mt-3">
              <Col xs={2}>
                <span className={style.label}>&nbsp;</span>
                <StylishSelect
                  id="t-deposits-currency-filter"
                  placeholderText="All Currencies"
                  value={currencyFilterValue}
                  options={currencyOptions}
                  highlightIfActive
                  onChange={(e) => this.doFilterByCurrency(e)}
                />
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}>Vip</span>
                  <StylishSelect
                    id="t-deposits-top-vip-filter"
                    placeholderText="All Clients"
                    value={topVipFilter}
                    options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                    highlightIfActive
                    onChange={(e) => this.doFilterByTopVip(e)}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <span className={style.label}>&nbsp;</span>
                <SelectAgent
                  id="t-deposits-page-partners-agent-filter"
                  agents={partnerAgents}
                  value={partnersAgentFilter}
                  placeholderText="All Partners Agents"
                  optionsPrefix={[{value: '', label: 'All Partners Agents'}]}
                  onChange={({value}) => this.props.uiDispatch(
                    'Filter by partners agent',
                    (state, arg) => ({...state, partnersAgentFilter: value, page: 1}),
                    [value]
                  )}
                />
              </Col>
              <Col xs={2}>
                <span className={style.label}>&nbsp;</span>
                <StylishSelect
                  id="t-list-clients-filter-conversion-statuses"
                  placeholderText="All Sales Statuses"
                  value={conversionStatusFilter}
                  options={StylishSelect.enumToStylishOptions(conversionStatuses, 'All Sales Statuses')}
                  highlightIfActive
                  onChange={(e) => this.props.uiDispatch(
                    'Filter sales status',
                    (state) => ({...state, conversionStatusFilter: e.value, page: 1})
                  )}
                />
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}>Order By</span>
                  <StylishSelect
                    highlightIfActive
                    id="t-deposits-order-by-filter"
                    placeholderText="ID"
                    value={orderByFilter}
                    options={StylishSelect.enumToStylishOptions(orderByFields)}
                    onChange={(e) => this.doOrderByFilter(e)}
                  />
                </div>
              </Col>
              <Col xs={2}>
                <div>
                  <span className={style.label}>Order Direction</span>
                  <StylishSelect
                    highlightIfActive
                    id="t-deposits-order-direction-filter"
                    placeholderText="Descending"
                    value={orderDirectionFilter}
                    options={StylishSelect.enumToStylishOptions(orderDirections)}
                    onChange={(e) => this.doOrderDirectionFilter(e)}
                  />
                </div>
              </Col>
            </Row>,
            <Row key="filter-row-4" className="mt-4 mb-4">
              <Col xs={3}>
                <CampaignsFilter
                  campaigns={campaigns}
                  viewer={viewer}
                  value={selectedCampaignId}
                  onChange={(campaign) => this.selectCampaign(campaign)}
                />
              </Col>
            </Row>,
          ]
        }

        {(companyName() === 'lydian') && <>{this.renderAmountSummary()}</>}

        <Row className="mt-3">
          <Col xs={12}>
            <Card className="two-rows-filter">
              <Card.Body style={{overflowX: 'scroll'}}>
                <Row className="mb-3">
                  <Col xs={11}>
                    {filters}
                  </Col>
                  <Col xs={1}>
                    {viewer.department === userDepartments.accounting.key && !isEmpty(rows) && <Button
                      id="t-deposits-export-button"
                      variant="outline-secondary"
                      onClick={() => this.setState({exportModalShown: true})}
                      disabled={loading}
                      size="sm"
                      className="float-right"
                    >
                      {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                    </Button>}
                  </Col>
                </Row>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Company</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Amount</th>
                      <th>Vendor/Type</th>
                      <th>Card Holder/Number</th>
                      <th>Campaign</th>
                      <th>Account</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th>Platform</th>
                      <th>Sales Agent</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {rows}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, depositsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(depositsPage, getPageCount(itemCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={() => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, depositsPage: depositsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={() => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, depositsPage: depositsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === depositsPage}
                      key={page}
                      onClick={() => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, depositsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>

            <DepositReceiptModal
              show={!!showReceipt}
              receipt={showReceipt}
              onHide={this.hideModalReceipt.bind(this)}
            />

            <Modal
              keyboard
              show={exportModalShown}
              onHide={() => this.setState({
                exportModalShown: false,
                exportPage: 1,
              })}
            >
              <Modal.Header closeButton className={style.modalHeader}>
                Export Deposits
              </Modal.Header>
              <Modal.Body>
                <Row className="mb-3">
                  <Col xs={12}>
                    <span style={{fontSize: 12}}>
                      *The export is limited to 20,000 results. If the search results are more than 20,000, please select
                      another page from the below dropdown or use a more specific search.
                    </span>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col xs={6}>
                    <StylishSelect
                      id="t-deposits-export-page-select"
                      placeholderText="Select a page"
                      defaultValue={{value: 1, label: 1}}
                      options={map(range(0, getPageCount(depositsCount, EXPORT_LIMIT)), (p) => ({
                        value: p + 1,
                        label: p + 1,
                      }))}
                      onChange={(e) => this.setState({exportPage: e.value})}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <Button
                      id="t-deposits-export-modal-button"
                      variant="outline-secondary"
                      className="float-right"
                      onClick={() => this.onSearch()}
                      disabled={loading || !exportPage}
                    >
                      {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                    </Button>
                  </Col>
                </Row>
              </Modal.Body>
            </Modal>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryDeposits),

  uiMount((state) => ['ui', 'deposits']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/deposits',
    (state) => {
      const dateFilters = state && state.dateFilters
      let amountFilters = state && state.amountFilters
      if (!amountFilters) {
        amountFilters = {amountFrom: '', amountTo: ''}
      }
      return merge({
        depositsStatusFilter: [
          'completed',
          'failed',
          'chargeback',
          'held',
          'incomplete',
          'authorized',
          'cancelled',
          'partiallyReversed',
          'reversed',
          'awaitingPayment',
        ],
        dateFilters,
        amountFilters,
      }, state)
    })
  ),

  provideProps((state, uiState) => {
    const {deposits, depositsCount, agents, bonusOffers, bonusOffersCount, depositsTopVipCount, thisMonthDeposits, fxRates, campaigns} = state
    const {campaignId ,depositsAccountsFilter, depositsStatusFilter, depositsSalesAgentFilter, clientTypesFilter,
      depositsSearch, depositsPage, showReceipt, show, companyFilter, vendorsFilter, dateFilters,
      countriesFilter, countriesFilterValue, amountFilters, currencyFilter, currencyFilterValue,
      topVipFilter, partnersAgentFilter, orderByFilter, orderDirectionFilter, conversionStatusFilter,
    } = uiState

    return ({
      deposits,
      depositsCount,
      bonusOffers,
      bonusOffersCount,
      depositsTopVipCount,
      agents,
      depositsAccountsFilter,
      campaignId,
      depositsStatusFilter,
      depositsSalesAgentFilter,
      depositsSearch,
      depositsPage: depositsPage || 1,
      showReceipt,
      show,
      companyFilter,
      vendorsFilter,
      dateFilters,
      clientTypesFilter,
      countriesFilter,
      countriesFilterValue,
      amountFilters,
      currencyFilter,
      currencyFilterValue,
      topVipFilter,
      partnersAgentFilter,
      orderByFilter,
      orderDirectionFilter,
      conversionStatusFilter,
      thisMonthDeposits,
      fxRates,
      campaigns,
    })
  }),

  mountDataProviders({depositsProvider, depositsTopVipCountProvider, thisMonthDepositsProvider, fxRatesProvider, allCampaignsProvider}),
)(Deposits)
