import {Card, Col, Row, Table, Tabs, Tab} from 'react-bootstrap'
import {useEffect} from 'react'

import {roleDetailsProvider} from './providers'
import {compose, mountDataProviders, provideProps} from '../../decorators'

import styles from './RoleDetails.module.scss'

export const RoleDetails = ({role, roleId, dispatch}) => {
  const isRoleFetched = role && roleId

  useEffect(
    () => () => {
      dispatch('Clear role details', (state) => ({...state, role: null}))
    },
    [dispatch]
  )
  return (
    <Card className="fit-to-page">
      <Card.Body>
        {!isRoleFetched && <p>Please select role from the list</p>}
        {isRoleFetched && (
          <>
            <Row xs={10}>
              <Col xs={5}>
                <p>Name</p>
              </Col>
              <Col xs={7}>
                <p>{role.name}</p>
              </Col>
            </Row>
            <Row xs={10}>
              <Col xs={5}>
                <p>Description</p>
              </Col>
              <Col xs={7}>
                <p>{role.description}</p>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="permissions"
              className={styles['RoleDetails-tabs']}
            >
              <Tab eventKey="permissions" title="Permissions">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Description</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {role.permissions?.map(({id, name, description}) => (
                      <tr key={id} id={id}>
                        <td>{name}</td>
                        <td>{description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="users" title="Users">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Email</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {role.users?.map(({id, firstName, lastName, email}) => (
                      <tr key={id} id={id}>
                        <td>
                          {firstName} {lastName}
                        </td>
                        <td>{email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default compose(
  provideProps(({role}) => ({role})),
  mountDataProviders({roleDetailsProvider})
)(RoleDetails)
