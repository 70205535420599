export const keys = {
  enter: {
    keyName: 'Enter',
    keyCode: 0x0D,
  },
}

export function isKey(event, key) {
  return event.key != null ? event.key === key.keyName : event.keyCode === key.keyCode
}
