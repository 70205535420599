import React from 'react'
import PropTypes from 'prop-types'
import {pickBy, map, filter, find} from 'lodash'
import {accountTypes, accountSubtypes, mt4Servers} from '@bdswiss/common-enums'
import {ButtonToolbar, Button, Row, Col, Card, Form} from 'react-bootstrap'
import {setIn} from '../stateUtils'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {getAccountForexServers, isEmptyStr, safeParseJSON} from '../common/utils.js'
import {compose, provideProps, mountDataProviders} from '../decorators'
import {getNewAccountCurrencyOptions, getAccountLabel} from '../utils/general'
import {configProvider} from '../providers'

const ibProductKeys = map(filter(accountTypes, {subCategory: 'ib'}), 'key')
const affiliateProductKeys = map(filter(accountTypes, {category: 'affiliate'}), 'key')

class CreateAccount extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    configProvider: PropTypes.object.isRequired,
  };

  static propTypes = {
    clientCompany: PropTypes.string.isRequired,
    clientWhiteLabel: PropTypes.string,
    partnerDefaultMT4Server: PropTypes.string,
    accounts: PropTypes.array.isRequired,
    clientId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  componentWillMount() {
    const state = {form: {}, formError: {}}
    // if there is only one accountType option, prefill it
    const accountTypeOptions = this.getInactiveAccountTypes()
    if (accountTypeOptions.length === 1) {
      state.form.accountType = accountTypeOptions[0].value
    }
    this.setState(state)
  }

  getProductSubtypes(accountType) {
    const acctSupportedSubTypes = accountTypes[accountType].supportedSubtypes
    return StylishSelect.enumToStylishOptions(
      pickBy(accountSubtypes, (a) => acctSupportedSubTypes.includes(a.value)))
  }

  formValueChanged(name, value) {
    let {state} = this
    state = setIn(state, ['form', name], value)
    state = setIn(state, ['formError', name], null)
    this.setState(state)
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  getInactiveAccountTypes() {
    const {accounts, clientCompany} = this.props
    const activeAccounts = (accounts || []).filter((a) => !a.deletedAt).map((a) => a.__typename)
    const inactiveAccounts = pickBy(accountTypes, (a) =>
      (a.supportsMultipleAccounts || !activeAccounts.includes(a.key))
      && a.company === clientCompany
      && !a.deprecated
    )
    return map(inactiveAccounts, (a) => ({...a, label: getAccountLabel(a)}))
  }

  validateForm() {
    const result = {}
    let wasError = false
    const {form} = this.state

    if (isEmptyStr(form.accountType)) {
      result.accountType = 'error'
      wasError = true
    }

    if (isEmptyStr(form.currency)) {
      result.currency = 'error'
      wasError = true
    }

    if (accountTypes[form.accountType].supportedSubtypes) {
      if (isEmptyStr(form.accountSubtype)) {
        result.accountSubtype = 'error'
        wasError = true
      }
    }

    if (ibProductKeys.includes(form.accountType)) {
      if (!isEmptyStr(form.parentIbId) && isNaN(Number(form.parentIbId))) {
        result.parentIbId = 'error'
        wasError = true
      }
    }

    if (affiliateProductKeys.includes(form.accountType)) {
      if (!isEmptyStr(form.affiliateId) && isNaN(Number(form.affiliateId))) {
        result.affiliateId = 'error'
        wasError = true
      }
    }

    this.setFormErrors(result)
    return !wasError
  }

  tryCreateNewAccount = () => {
    if (!this.validateForm()) {
      return
    }

    this.disableCreateButton()
    const {
      context: {clientProvider},
      props: {clientId, actions, onClose},
      state: {form: {currency, accountType, accountSubtype, parentIbId, affiliateId, server}},
    } = this
    const newAccount = {
      currency,
      clientId,
      accountType,
      accountSubtype: accountSubtype,
      parentIbId,
      affiliateId: Number(affiliateId),
      server,
    }
    return actions.client.createAccount(newAccount)
      .then((res) => {
        clientProvider.subProviders.accounts.fetch()
        clientProvider.subProviders.activityLogs.fetch()
        clientProvider.subProviders.basicData.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
      })
      .then(onClose)
  }

  render() {
    const currencyOptions =
      StylishSelect.enumToStylishOptions(getNewAccountCurrencyOptions(this.state.form.accountType))
    const accountTypeOptions = StylishSelect.enumToStylishOptions(this.getInactiveAccountTypes())
    const {accountType, accountSubtype} = this.state.form
    const {forexServers, defaultServer} = getAccountForexServers(
      accountType,
      accountSubtype,
      this.props.clientWhiteLabel,
      this.props.partnerDefaultMT4Server,
      safeParseJSON(this.props.appConfig?.serversWeights ?? {}),
      safeParseJSON(this.props.appConfig?.whitelabelServersWeights ?? {}),
      this.props.clientCompany,
    )

    return (
      <Card id="t-client-new-account-sidebar" className="panel-short">
        <Card.Header><strong>New Account</strong></Card.Header>
        <Card.Body>
          <Row>
            <Col md={12} xs={12}>
              <StylishSelect.Input
                id="t-client-accounts-new-account-product"
                label="Product"
                value={this.state.form.accountType || ''}
                bsStyle={this.state.formError.accountType}
                onChange={(e) => {
                  this.formValueChanged('accountType', e.value)
                }}
                placeholderText="Select Product"
                options={accountTypeOptions}
              />
            </Col>
          </Row>
          {
            accountType && accountTypes[accountType].supportedSubtypes &&
            (<Row>
              <Col md={12} xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-new-account-product-subtype"
                  label="Product Sub Type"
                  value={this.state.form.accountSubtype || ''}
                  bsStyle={this.state.formError.accountSubtype}
                  onChange={(e) => {
                    this.formValueChanged('accountSubtype', e.value)
                  }}
                  placeholderText="Select Account Sub Type"
                  options={this.getProductSubtypes(accountType)}
                />
              </Col>
            </Row>)
          }
          {
            forexServers.length > 0 &&
            (<Row>
              <Col md={12} xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-new-account-product-subtype"
                  label={<div>Server <Form.Text className="text-muted">
                    *If no server is selected the account will be opened to the default server: {defaultServer}
                  </Form.Text></div>}
                  value={this.state.form.server || ''}
                  onChange={(e) => {
                    this.formValueChanged('server', e.value)
                  }}
                  placeholderText="Select Server"
                  options={map(forexServers, (s) => ({
                    value: find(mt4Servers, {value: s})?.key,
                    label: s,
                  }))}
                />
              </Col>
            </Row>)
          }
          <Row>
            <Col md={12} xs={12}>
              <StylishSelect.Input
                id="t-client-accounts-new-account-currency"
                label="Currency"
                value={this.state.form.currency || ''}
                bsStyle={this.state.formError.currency}
                onChange={(e) => {
                  this.formValueChanged('currency', e.value)
                }}
                placeholderText="Choose Currency"
                options={currencyOptions}
              />
            </Col>
          </Row>
          {ibProductKeys.includes(accountType) &&
            <Row>
              <Col md={12} xs={12}>
                <Form.Group>
                  <Form.Label>Parent IB</Form.Label>
                  <Form.Control
                    id="t-client-accounts-new-account-parent-ib"
                    type="text"
                    value={this.state.form.parentIbId || ''}
                    isInvalid={this.state.formError.parentIbId}
                    onChange={(e) => this.formValueChanged('parentIbId', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
          {affiliateProductKeys.includes(accountType) &&
            <Row>
              <Col md={12} xs={12}>
                <Form.Group>
                  <Form.Label>Affiliate ID</Form.Label>
                  <Form.Control
                    id="t-client-accounts-new-account-affiliate-id"
                    type="text"
                    value={this.state.form.affiliateId || ''}
                    isInvalid={this.state.formError.affiliateId}
                    onChange={(e) => this.formValueChanged('affiliateId', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
          <Row>
            <Col md={12} xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-accounts-new-account-cancel-button"
                  tabIndex={-1}
                  onClick={this.props.onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >Cancel
                </Button>
                <Button
                  id="t-client-accounts-new-account-create-button"
                  variant="success"
                  size="sm"
                  disabled={this.state.createButtonDisabled}
                  onClick={this.tryCreateNewAccount}
                >
                  Create
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default compose(
  provideProps((state, uiState) => ({
    appConfig: state.appConfig,
  })),
  mountDataProviders({
    configProvider: {
      ...configProvider,
      cache: true,
      cacheInterval: 24 * 60 * 60 * 1000, // 1d
    }}, true),
)(CreateAccount)
