import React from 'react'
import {first, map, get, find} from 'lodash'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {provideProps} from '../decorators'
import classnames from 'classnames'
import styles from './client.module.scss'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import StylishSelect from '../components/StylishSelect'
import {countries} from '@bdswiss/common-enums'

export default provideProps()(class EditPromotingCountries extends PureComponent {
  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {countriesPromoted} = this.props

    this.setState({updatedCountriesPromoted: first(countriesPromoted)})
  }

  render() {
    const {clientId, onClose, updateCountriesPromoted} = this.props
    const {updatedCountriesPromoted} = this.state

    return <Card
      id="t-client-update-walkthrough-sidebar"
      className={classnames(['panel-short', styles.fixedSidePanel])}
    >
      <Card.Header><strong>Edit Promoting Countries</strong></Card.Header>
      <Card.Body>
        <Row className="mb-3">
          <Col xs={12}>
            <StylishSelect
              multi
              id="t-client-update-promoting-countries"
              label="Update Client Promoting Countries"
              value={updatedCountriesPromoted}
              onChange={(e) => this.setState({updatedCountriesPromoted: map(e, 'value')})}
              placeholderText="Select Promoting Countries"
              options={StylishSelect.enumToStylishOptions(countries)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <ButtonToolbar className="float-right">
              <Button
                id="t-client-update-promoting-countries-cancel-button"
                tabIndex={-1}
                onClick={onClose}
                variant="outline-secondary"
                size="sm"
                className="mr-1"
              >Cancel
              </Button>
              <Button
                id="t-client-update-promoting-countries-update-button"
                variant="success"
                size="sm"
                onClick={() => {
                  const args = {
                    clientId: clientId,
                    updatedCountriesPromoted: map(updatedCountriesPromoted, (c) => get(find(countries, {key: c}), 'value')),
                  }

                  updateCountriesPromoted(args)
                    .then((res) => {
                      this.context.clientProvider.subProviders.affiliateCountries.fetch()
                      this.context.clientProvider.subProviders.activityLogs.fetch()
                    })
                    .catch(this.context.logError)
                    .then(this.props.onClose)
                }}
              >
                Save
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  }
})
