import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Pagination, Table, Card, OverlayTrigger,
  Tooltip, Image, Collapse, ButtonGroup, ButtonToolbar, DropdownButton, Dropdown, Container, InputGroup, Modal} from 'react-bootstrap'
import {keys, isEmpty, sumBy, values, map, debounce, head, filter, first, get, find, some, uniqBy, range, isNumber} from 'lodash'
import classNames from 'classnames'
import {claimedStatuses, conversionStatuses, accountTypes, clientTypes, yesNo,
  tradingStatuses, kycStatuses, appropTestQuestions, riskCategories,
  clientPotentialLevels, trackEventsTypes, salesAgentTypes, copyTradingFilterTypes, userDepartments,
  languages, profileNetWorth, appropTestEmployerCategory, balanceFilterCurrencies,
  conversionSteps,departmentRoles
} from '@bdswiss/common-enums'
import {unassigned, noSelection, getPageCount, sanitizeId, getPageRange} from '../useful'
import {compareAccounts, getAllowedCompanies, getAccountLabel, getFullName} from '../utils/general'
import {depositorsFilterOptions, events} from '../enums'
import {createAccountIconUrl, safeLinkToClient} from '../utils/links'
import {countriesAndRegionsOptions, createCountriesFilter} from '../utils/country'
import {renderOnlineStatus} from '../utils/rendering'
import SelectAgent from '../components/SelectAgent'
import StylishSelect from '../components/StylishSelect'
import AccountTypesFilter from '../components/AccountTypesFilter'
import AffiliateUserNameField from './AffiliateUserNameField'
import MaskedField from './MaskedField'
import {InviteClientsButton, ActiveWhiteLabelNames} from './InviteClientsButton/index'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {
  canWriteClientSalesAgent, canUpdateClientSupportAgent,
  canQueryAffiliateManagerClients, canSendPushNotifications, canWriteClientConversionStatus,
} from '@bdswiss/common-permissions'
import BatchAssign from './BatchAssign'
import DisabledTooltipButton from '../components/DisabledTooltipButton'
import style from './clients.module.scss'
import PureComponent from '../PureComponent'
import ReassignAgentClientsModal from '../components/ReassignAgentClientsModal'
import {formatAccountTypeFilterInput} from '../schemaUtils'
import SearchHelpModal from './SearchHelpModal'
import moment from 'moment'
import {agentTypes} from '../client/ChangeAgent'
import DateTime from '../components/DateTime'
import PushNotificationsModal from './PushNotificationsModal'

const mineSelectAgent = 'mine'
const allSelectOptionValue = ''

const EXPORT_LIMIT = 20000

const salesAgentFilterOptions = [
  {
    value: allSelectOptionValue,
    label: 'All Sales Agents',
  },
  {
    value: mineSelectAgent,
    label: 'Mine',
  },
  {
    value: claimedStatuses.unclaimed.key,
    label: claimedStatuses.unclaimed.label,
  },
  ...map(salesAgentTypes, (t) => ({
    value: t.value,
    label: t.label,
  })),
]

const allClientsDepositorsSelectOption = {
  value: '',
  label: 'All Clients',

}
const depositorsSelectOptions = [
  allClientsDepositorsSelectOption,
  ...Object.values(depositorsFilterOptions),
]

const resetSelections = {
  selectedClients: {},
  selectedClientsHeader: false,
}

const resetAfterFilter = {
  clientsPage: 1,
  ...resetSelections,
}

export default class List extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doFilterByApTest = debounce(this.doFilterByApTest, 1000)
    this.doFilterbyBalance = debounce(this.doFilterbyBalance, 1500)
    this.doFilterbyPotentialss = debounce(this.doFilterbyPotentials, 1500)
    this.doFilterbyAgentPool = debounce(this.doFilterbyAgentPool, 1500)
    this.searchFieldSelect = this.searchFieldSelect.bind(this)
    this.clearClientSearch = this.clearClientSearch.bind(this)
    this.doClientsSearch = this.doClientsSearch.bind(this)
    this.doIbIdSearch = this.doIbIdSearch.bind(this)
    this.clearIbIdSearch = this.clearIbIdSearch.bind(this)
    this.doTrackEventSearch = this.doTrackEventSearch.bind(this)
    this.clearTrackEventSearch = this.clearTrackEventSearch.bind(this)
    this.doTrackEventCampaignSearch = this.doTrackEventCampaignSearch.bind(this)
    this.clearTrackEventCampaignSearch = this.clearTrackEventCampaignSearch.bind(this)
    this.searchFieldSelectTrackEvents = this.searchFieldSelectTrackEvents.bind(this)
    this.doTrackEventSearchParams = this.doTrackEventSearchParams.bind(this)
    this.clearTrackEventSearchParams = this.clearTrackEventSearchParams.bind(this)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    clientsProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {potentialsFilter} = this.props
    const maxForexAPTestPoint =
      sumBy(Object.values(appropTestQuestions), (a) => a.options ?
        Math.max(...map(values(a.options), (o) => o.forex_points || 0)) : 0)
    const maxBinaryAPTestPoint =
      sumBy(Object.values(appropTestQuestions), (a) => a.options ?
        Math.max(...map(values(a.options), (o) => o.binary_points || 0)) : 0)

    this.setState({
      searchText: '',
      searchField: '',
      searchTextParams: '',
      searchFieldTrackEvent: '',
      maxForexAPTestPoint,
      maxBinaryAPTestPoint,
      binaryAPTestScore: this.props.binaryAPTestScoreFilter,
      tags: [],
      forexAPTestScore: this.props.forexAPTestScoreFilter,
      showAdvanceSettings: false,
      activePotentialFilters:{
        lowPotential: potentialsFilter && !! potentialsFilter['lowPotential'],
        mediumPotential: potentialsFilter && !! potentialsFilter['mediumPotential'],
        highPotential: potentialsFilter && !! potentialsFilter['highPotential']
      },
      activeAgentPoolFilter: {
        poolonly: false,
        hidepool: true,
      },
      searchIbIdText: '',
      searchPammMMIDText: '',
      subCampId: '',
      showOnlyMasterIbsFilter: false,
      trackEventText: '',
      trackEventField: '',
      searchTrackEventText: '',
      searchCampaignText: '',
      exportPage: 1,
      loading: false,
      exportModalShown: false,
    })
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.clientsProvider.fetch()
  }

  showNotification(message, level) {
    this.context.showNotification({
      message,
      level,
      autoDismiss: 5,
    })
  }

  toggleClientSelection(client) {
    const id = client.id
    this.props.uiDispatch(
      'Toggle client selection ' + id,
      (state) => {
        const selectedClients = {...state.selectedClients}

        if (selectedClients[id]) {
          delete selectedClients[id]
        } else {
          selectedClients[id] = true
        }

        return ({
          ...state,
          selectedClients: selectedClients,
        })
      }
    )
  }

  allClientsIds() {
    const result = {}
    this.props.clients.forEach((client) => {result[client.id] = true})
    return result
  }

  toggleClientSelectionHeader() {
    const shouldSelectAll = !this.props.selectedClientsHeader
    const selectedClients = shouldSelectAll ? this.allClientsIds() : {}

    this.props.uiDispatch(
      'Toggle clients selection',
      (state) => ({
        ...state,
        selectedClients: selectedClients,
        selectedClientsHeader: shouldSelectAll,
      })
    )
  }

  toggleBatchAssign() {
    this.props.uiDispatch(
      'Toggle batch assign sales ',
      (state) => ({...state, assignSalesModal: !state.assignSalesModal})
    )
  }

  clearBatchAssignSelection() {
    this.props.uiDispatch(
      'Toggle batch assign sales ',
      (state) => ({...state, assignSalesModal: false, selectedClients: {}, selectedClientsHeader: false})
    )
  }

  batchAssign(agentId, conversionStatusKey, partnersAgentId) {
    const allClients = keys(this.props.selectedClients)
    this.props.actions.client
      .batchSetSalesAgentAndConversionStatus(allClients, agentId, conversionStatusKey, partnersAgentId)
      .then((res) => {
        this.clearBatchAssignSelection()
        this.context.clientsProvider.fetch()
      })
      .catch(this.context.logError)
  }

  dispatchAccountTypeSelection(filter) {
    this.props.uiDispatch(
      'Filter accounts',
      (state) => ({...state, clientsAccountsFilter: filter, ...resetAfterFilter})
    )
  }

  dispatchCompanySelection(filter) {
    const values = map(filter, 'value')
    this.props.uiDispatch(
      'Filter companies',
      (state) => ({...state, companyFilter: values, ...resetAfterFilter})
    )
  }

  dispatchKYCStatusSelection(filter) {
    const values = map(filter, 'value')
    this.props.uiDispatch(
      'Filter KYC Status',
      (state) => ({...state, kycStatusFilter: values, ...resetAfterFilter})
    )
  }

  dispatchRiskCategorySelection(filter) {
    const values = map(filter, 'value')
    this.props.uiDispatch(
      'Filter by Risk Category',
      (state) => ({...state, riskCategoryFilter: values, ...resetAfterFilter})
    )
  }

  dispatchClientTypeSelection(filter) {
    const values = map(filter, 'value')
    this.props.uiDispatch(
      'Filter clients by client type',
      (state) => ({...state, clientTypesFilter: values, ...resetAfterFilter}),
    )
  }

  dispatchSubtagSelection(filter) {
    const values = map(filter, 'value')
    this.props.uiDispatch(
      'Filter Subtags',
      (state) => ({...state, tagFilter: values, ...resetAfterFilter})
    )
  }

  activateCountryRegionFilter(selection) {
    this.props.uiDispatch(
      'Filter clients by country or region',
      (state) => ({
        ...state,
        clientsCountriesFilter: createCountriesFilter(selection),
        clientsCountriesFilterValue: selection.value,
        clientsPage: 1,
      })
    )
  }

  activateLanguageFilter(selection) {
    this.props.uiDispatch(
      'Filter clients by language',
      (state) => ({
        ...state,
        languageFilter: selection ? selection.value : null,
        clientsPage: 1,
        ...resetAfterFilter,
      })
    )
  }

  renderConversionStatus(client) {
    return (
      <div className="Select-transparent t-list-clients-select-conversion-status">
        <StylishSelect
          disabled={!canWriteClientConversionStatus(this.props.viewer)}
          placeholderText={noSelection}
          options={StylishSelect.enumToStylishOptions(conversionStatuses)}
          onChange={(target) => {
            const newValue = target.value
            this.props.actions.client.setConversionStatus(client.id, newValue)
              .then((res) => {
                this.context.clientsProvider.fetch()
              })
              .catch(this.context.logError)
          }}
          value={client.conversionStatus}
        />
      </div>
    )
  }

  renderConversionStep(client) {
    return (
      <div className="Select-transparent t-list-clients-select-conversion-status">
        <StylishSelect
          disabled={true}
          placeholderText={noSelection}
          options={StylishSelect.enumToStylishOptions(conversionSteps)}
          value={client.conversionStep}
        />
      </div>
    )
  }

  renderAccounts(client) {
    if (!client.accounts) {
      return safeLinkToClient(this.props.viewer, '', client)
    }

    // ensure stable icons ordering
    const accounts = [...client.accounts].sort(compareAccounts).map((account) => accountTypes[account.__typename])

    const icons = accounts.map((account, ind) => {
      const tooltip = (<Tooltip id={account.key}>{getAccountLabel(account)}</Tooltip>)

      return (
        <OverlayTrigger key={ind} placement="bottom" overlay={tooltip}>
          <Image className={style.accountIcon} src={createAccountIconUrl(account)} />
        </OverlayTrigger>
      )
    })

    return safeLinkToClient(this.props.viewer, icons, client)
  }

  renderSelectAgent(client) {
    const agent = client.salesAgent
    const agents = filter(this.props.state.agents, (agent) => agent.isActive)
    if (canWriteClientSalesAgent(this.props.viewer)) {
      return (
        <div className="Select-transparent t-list-clients-select-sales-agent">
          <SelectAgent
            disabled={!canWriteClientSalesAgent(this.props.viewer)}
            clearable={agent && canWriteClientSalesAgent(this.props.viewer, agent.id, null, [])}
            placeholderText={unassigned}
            agents={agents}
            onChange={(o) => {
              const agentId = o && o.value
              this.props.actions.client.setSalesAgent(client.id, agentId)
                .then((res) => {
                  this.context.clientsProvider.fetch()
                })
                .catch(this.context.logError)
            }}
            value={agent ? agent.id : ''}
            optionsFilter={agentTypes.salesAgent.filter}
          />
        </div>
      )
    } else {
      const currentAgent = find(agents, {id: get(agent, 'id')})
      return (
        <div>
          {safeLinkToClient(this.props.viewer, getFullName(currentAgent, 'Unassigned'), client)}
        </div>
      )
    }
  }

  renderSelectPartnerAgent(client) {
    const agent = client.supportAgent
    const partnerAgents = filter(this.props.agents, (agent) =>
      agent.isActive && some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)))
    if (canUpdateClientSupportAgent(this.props.viewer)) {
      return (
        <div className="Select-transparent t-list-clients-select-partner-agent">
          <SelectAgent
            disabled={!canUpdateClientSupportAgent(this.props.viewer)}
            clearable={agent && canUpdateClientSupportAgent(this.props.viewer, agent.id, null, [])}
            placeholderText={unassigned}
            agents={partnerAgents}
            onChange={(o) => {
              const agentId = o && o.value
              this.props.actions.client.setSupportAgent(client.id, agentId)
                .then((res) => {
                  this.context.clientsProvider.fetch()
                })
                .catch(this.context.logError)
            }}
            value={agent ? agent.id : ''}
            optionsFilter={agentTypes.supportAgent.filter}
          />
        </div>
      )
    } else {
      const currentAgent = find(partnerAgents, {id: get(agent, 'id')})
      return (
        <div>
          {safeLinkToClient(this.props.viewer, getFullName(currentAgent, 'Unassigned'), client)}
        </div>
      )
    }
  }

  renderSelection(client) {
    const id = client.id
    return (
      <div className="clientsTableCheckbox">
        <Form.Check
          id={'t-list-clients-selection-' + id}
          label=""
          checked={!!this.props.selectedClients[id]}
          onChange={this.toggleClientSelection.bind(this, client)}
          className={style.checkbox}
        />
      </div>
    )
  }

  renderSelectionHeader(columnId, key, label) {
    return (
      <th
        id={columnId}
        key={key}
      >
        <div className="clientsTableCheckbox clientsTableHeaderCheckbox">
          <Form.Check
            id="t-list-clients-selection-header"
            checked={!!this.props.selectedClientsHeader}
            onChange={this.toggleClientSelectionHeader.bind(this)}
            className={style.checkbox}
          />
        </div>
      </th>
    )
  }

  renderClient(client) {
    return (
      <tr className={classNames([
        `t-list-clients-item-${sanitizeId(`${client.firstName} ${client.lastName}`)}`,
        `${client.whiteLabel ? `${client.whiteLabel}WhiteLabel` : ''}`,
        `${client.topVip ? 'vip' : ''}`,
      ])}
      key={client.id}
      >
        {this.props.columns.map(
          (key) => {
            let highlight = ''
            const column = this.props.columnDefinitions[key]
            if (column.condition && !column.condition(this.props.viewer)) {
              return false
            }
            let value, className = ''
            switch (key) {
              case 'selection': {
                value = this.renderSelection(client)
                className = 'align-middle'
                break
              }
              case 'salesAgent': {
                value = this.renderSelectAgent(client)
                break
              }
              case 'supportAgent': {
                value = this.renderSelectPartnerAgent(client)
                break
              }
              case 'conversionStatus': {
                value = this.renderConversionStatus(client)
                break
              }
              case 'conversionStep': {
                value = this.renderConversionStep(client)
                break
              }
              case 'registrationCampaign': {
                const campaigns = map(client.registrationCampaigns, 'campaignId').join(', ')
                highlight = find(client.registrationCampaigns, (r) => get(r, 'campaign.noCall')) ? '#F1959B' : ''
                value = client.registrationCampaigns ? safeLinkToClient(this.props.viewer, [campaigns], client) : ''
                break
              }
              case 'registrationCampaignAffiliate': {
                value = client.registrationCampaigns
                  ? <AffiliateUserNameField client={client} {...this.props} /> : null
                break
              }
              case 'accounts': {
                value = this.renderAccounts(client)
                break
              }
              case 'name' : {
                const fullName = column.resolve(client)
                const onlineLabelClass = classNames([style.onlineStatusLabel,
                  client.isOnline ? 'client-online' : 'client-offline'])
                const onlineLabel = window.ioSocket &&
                  renderOnlineStatus(client, onlineLabelClass)
                value = safeLinkToClient(this.props.viewer, [onlineLabel, fullName], client)
                break
              }
              case 'phone':
              case 'email' : {
                value = <MaskedField client={client} key={key} column={column} {...this.props} />
                break
              }
              default: {
                value = column.resolve ? column.resolve(client) : client[key]
                if (!column.containsLink) {
                  value = safeLinkToClient(this.props.viewer, value, client)
                }
              }
            }
            return (
              <td
                key={key}
                style={{
                  minWidth: `${this.props.columnDefinitions[key].minWidth || 100}px`,
                  backgroundColor: highlight,
                }}
                className={`${column.containsLink ? '' : style.rowLink} ${className}`}
              >
                {value}
              </td>
            )
          }
        )}
      </tr>
    )
  }

  doClientsSearch() {
    const {searchField, searchText} = this.state
    let clientsSearch
    if (searchField !== '' && searchText !== '') {
      if (searchField === 'email:') {
        clientsSearch = `email:${searchText} ` +
        `OR secondaryEmails.secondaryEmail1:${searchText} ` +
        `OR secondaryEmails.secondaryEmail2:${searchText} ` +
        `OR secondaryEmails.secondaryEmail3:${searchText}`
      } else {
        clientsSearch = `${searchField}${searchText}`
      }
    } else {
      clientsSearch = searchText
    }
    this.props.uiDispatch(
      'Search clients',
      (state) => ({...state, clientsSearch, ...resetAfterFilter})
    )
  }

  clearClientSearch() {
    this.setState({
      searchText: '',
    }, () => this.doClientsSearch())
  }

  searchFieldSelect = (searchField) => {
    if (searchField === 'none') {
      searchField = ''
    }
    if (searchField === 'more') {
      return this.showSearchHelpModal()
    }
    this.setState({searchField}, () => {
      this.doClientsSearch()
    })
  }

  doFilterByApTest(type) {
    if (type === 'forex') {
      this.props.uiDispatch(
        'Filter By Forex AP Test',
        (state) => ({...state, forexAPTestScoreFilter: this.state.forexAPTestScore, ...resetAfterFilter})
      )
    } else {
      this.props.uiDispatch(
        'Filter By Binary AP Test',
        (state) => ({...state, binaryAPTestScoreFilter: this.state.binaryAPTestScore, ...resetAfterFilter})
      )
    }
  }

  handlePotentialFilterSelected(key) {
    const {activePotentialFilters} = this.state
    const currentValue = activePotentialFilters[key]
    Object.keys(activePotentialFilters).forEach((k) => {
      (k !== key) ? activePotentialFilters[k] = false : activePotentialFilters[k] = !currentValue})
    this.setState({activePotentialFilters: {...activePotentialFilters, [key]: !currentValue}})
    this.doFilterbyPotentials()
  }

  doFilterbyPotentials() {
    const {activePotentialFilters} = this.state
    this.props.uiDispatch(
      'Filter By Potentials',
      (state) => ({...state, potentialsFilter: {...activePotentialFilters}, ...resetAfterFilter})
    )
  }

  doFilterbyAgentPool(key) {
    const {activeAgentPoolFilter} = this.state
    this.props.uiDispatch(
      'Filter By Agent Pool',
      (state) => ({...state, agentPoolFilter: {...activeAgentPoolFilter}, ...resetAfterFilter})
    )
  }

  handleAgentPoolFilterSelected(key) {
    const {activeAgentPoolFilter} = this.state
    const currentValue = activeAgentPoolFilter[key]
    // Set all to false before setting current to true
    Object.keys(activeAgentPoolFilter).forEach((k) => {
      activeAgentPoolFilter[k] = (k === key) ? !currentValue : false
    })
    this.setState({activeAgentPoolFilter: {...activeAgentPoolFilter, [key]: !currentValue}})
    this.doFilterbyAgentPool(key)
  }

  handleBalanceFilterChanged(key, value) {
    value = value ? parseFloat(value) : value
    if (!value || !isNaN(value)) {
      this.doFilterbyBalance(key, value)
    }
  }

  doFilterbyBalance(key, value) {
    this.props.uiDispatch(
      'Dispatch Filter By Balance',
      (state) => ({...state,
        [key]: value, ...resetAfterFilter})
    )
  }

  getSearchArgs() {
    const {clientsSalesAgents, clientsCountriesFilter, clientsSearch,
      clientsAccountsFilter, conversionStatusFilter,conversionStepFilter, tradingStatusFilter, kycStatusFilter, riskCategoryFilter,
      depositorsFilter, onlineOnlyFilter, underMonitoringFilter, forexAPTestScoreFilter, binaryAPTestScoreFilter,
      companyFilter, clientTypesFilter, viewer, balanceMaxFilter,
      balanceMinFilter, tagCategoriesFilter, tagFilter, agentPoolFilter, potentialsFilter,
      ibApprovedFilter, searchIbId, searchPammMMID, masterIbOnlyFilter, attendedFilter,
      searchTrackEvent, searchCampaign, searchPparams, trackEventClientType, eventTypeFilter, competitionFilter,
      copyTradingFilter, missingPoiPorFilter, signedUpFromFilter, signedUpToFilter, convertedFromFilter, convertedToFilter, ftdFromFilter, ftdToFilter,
      lastEffectiveCallFromFilter, lastEffectiveCallToFilter, lastLoginFromFilter, lastLoginToFilter, noEffectiveCallFilter, languageFilter,
      birthdayFromFilter, birthdayToFilter, lastActivityFromFilter, lastActivityToFilter, jobTitleFilter, netWorthFilter, lastAttemptedCallFromFilter, lastAttemptedCallToFilter,
      balanceCurrencyFilter,
    } = this.props

    const accountsFilter = formatAccountTypeFilterInput(clientsAccountsFilter)
    const countriesAsList = clientsCountriesFilter ? clientsCountriesFilter.map((c) => c.toUpperCase()) : ''
    const providedPotentialsFilter = filter(keys(potentialsFilter), (k) => potentialsFilter[k] === true)
    const providedAgentPoolFilter = filter(keys(agentPoolFilter), (k) => agentPoolFilter[k] === true)
    let args = {}
    if (head(clientsSalesAgents)) {args.salesAgentId = clientsSalesAgents}
    if (clientsCountriesFilter) {args.countries = countriesAsList}
    if (conversionStatusFilter) {args.conversionStatus = conversionStatusFilter}
    if (conversionStepFilter) {args.conversionStep = conversionStepFilter}
    const tradingStatusesValue = map(tradingStatusFilter,
      (t) => get(find(tradingStatuses, (s) => s.key === t), 'value'))
    if (head(tradingStatusFilter)) {args.tradingStatuses = tradingStatusesValue}
    if (head(kycStatusFilter)) {args.kycStatuses = kycStatusFilter}
    if (head(riskCategoryFilter)) {args.riskCategories = riskCategoryFilter}
    if (clientsSearch) {args.search = clientsSearch}
    if (depositorsFilter) {args.isDepositor = depositorsFilter}
    if (tagCategoriesFilter) {args.tagCategories = tagCategoriesFilter}
    if (onlineOnlyFilter) {args.onlineOnly = onlineOnlyFilter}
    if (underMonitoringFilter) {args.underMonitoring = underMonitoringFilter}
    if (forexAPTestScoreFilter) {args.forexAPScoreLimit = forexAPTestScoreFilter}
    if (binaryAPTestScoreFilter) {args.binaryAPScoreLimit = binaryAPTestScoreFilter}
    if (providedPotentialsFilter.length > 0) {args.providedPotentials = providedPotentialsFilter}
    if (providedAgentPoolFilter.length > 0) {args.providedAgentPool = first(providedAgentPoolFilter)}
    if (head(companyFilter)) {args.companies = companyFilter}
    if (head(tagFilter)) {args.tags = tagFilter}
    if (head(clientTypesFilter)) {args.clientTypes = clientTypesFilter}
    if (accountsFilter) {args = {...args, ...accountsFilter}}
    if (isNumber(balanceMaxFilter)) {args.balanceMaxFilter = balanceMaxFilter}
    if (isNumber(balanceMinFilter)) {args.balanceMinFilter = balanceMinFilter}
    if (ibApprovedFilter) {args.isIbApproved = ibApprovedFilter === yesNo.yes.key}
    if (searchIbId) {args.ibId = searchIbId}
    if (searchPammMMID) {args.pammMMID = searchPammMMID}
    if (masterIbOnlyFilter) {args.masterIbOnly = masterIbOnlyFilter}
    if (searchTrackEvent) {args.subCampId = searchTrackEvent}
    if (searchCampaign) {args.campaign = searchCampaign}
    if (searchPparams) {args.searchPparamsText = searchPparams}
    if (trackEventClientType) {args.trackEventClientType = trackEventClientType}
    if (attendedFilter) {args.attended = attendedFilter === yesNo.yes.key}
    if (eventTypeFilter) {args.eventType = eventTypeFilter}
    if (competitionFilter) {args.competition = competitionFilter}
    if (head(missingPoiPorFilter)) {args.missingPoiPor = missingPoiPorFilter}
    if (copyTradingFilter) {args.copyTradingFilter = copyTradingFilter}

    if (signedUpFromFilter) {args.signedUpFrom = signedUpFromFilter}
    if (signedUpToFilter) {args.signedUpTo = signedUpToFilter}
    if (convertedFromFilter) {args.convertedFrom = convertedFromFilter}
    if (convertedToFilter) {args.convertedTo = convertedToFilter}
    if (ftdFromFilter) {args.ftdFrom = ftdFromFilter}
    if (ftdToFilter) {args.ftdTo = ftdToFilter}
    if (lastEffectiveCallFromFilter) {args.lastEffectiveCallFrom = lastEffectiveCallFromFilter}
    if (lastEffectiveCallToFilter) {args.lastEffectiveCallTo = lastEffectiveCallToFilter}
    if (lastAttemptedCallFromFilter) {args.lastAttemptedCallFrom = lastAttemptedCallFromFilter}
    if (lastAttemptedCallToFilter) {args.lastAttemptedCallTo = lastAttemptedCallToFilter}
    if (lastLoginFromFilter) {args.lastLoginFrom = lastLoginFromFilter}
    if (lastLoginToFilter) {args.lastLoginTo = lastLoginToFilter}
    if (noEffectiveCallFilter) {args.noEffectiveCall = noEffectiveCallFilter}
    if (languageFilter) {args.language = languageFilter}
    if (birthdayFromFilter) {args.birthdayFrom = birthdayFromFilter}
    if (birthdayToFilter) {args.birthdayTo = birthdayToFilter}
    if (lastActivityFromFilter) {args.lastActivityFrom = lastActivityFromFilter}
    if (lastActivityToFilter) {args.lastActivityTo = lastActivityToFilter}
    if (netWorthFilter) {args.netWorth = netWorthFilter}
    if (jobTitleFilter) {args.jobTitle = netWorthFilter}
    if (balanceCurrencyFilter) {args.balanceCurrencyFilter = balanceCurrencyFilter}
    args.companies = viewer.companies
    return args
  }

  onReassignClients() {
    this.props.uiDispatch(
      'Show reassign clients',
      (state) => ({...state, showReassignClients: true, reassignClientsArgs: this.getSearchArgs()})
    )
  }

  hideReassignClientsModal() {
    this.props.uiDispatch(
      'Close reassign clients modal',
      (state) => ({...state, showReassignClients: false, reassignClientsArgs: {}})
    )
  }

  showPushNotificationModal() {
    this.props.uiDispatch('Opening/Closing Push Notifications Modal',
      (state) => ({...state, showPushNotificationsModal: true, reassignClientsArgs: this.getSearchArgs()}))
  }

  hidePushNotificationModal() {
    this.props.uiDispatch('Opening/Closing Push Notifications Modal',
      (state) => ({...state, showPushNotificationsModal: false, reassignClientsArgs: {}}))
  }

  showMarketingEmailsModal = () => {
    this.props.uiDispatch('Opening/Closing Marketing Emails Modal',
      (state) => ({...state, showMarketingEmailsModal: true, reassignClientsArgs: this.getSearchArgs()}))
  }

  hideMarketingEmailsModal = () => {
    this.props.uiDispatch('Opening/Closing Marketing Emails Modal',
      (state) => ({...state, showMarketingEmailsModal: false, reassignClientsArgs: {}}))
  }

  allTagCategoriesOptions(tagCategories) {
    return [{
      label: 'All tag categories',
      value: '',
      key: '',
      tags: [],
    }].concat(Object.values(tagCategories).map((i) => ({
      key: i.id,
      value: i.id,
      label: i.name,
      tags: i.tags,
    })))
  }

  allMissingPoiPorOptions() {
    return [
      {
        key: 'poi',
        label: 'POI',
        value: 'poi',
      },
      {
        key: 'por',
        label: 'POR',
        value: 'por',
      }
    ]
  }

  showSearchHelpModal = () => {
    this.props.uiDispatch('Show search help modal',
      (state) => ({...state, showSearchHelpModal: true}))
  }

  hideSearchHelpModal = () => {
    this.props.uiDispatch('Hide search help modal',
      (state) => ({...state, showSearchHelpModal: false}))
  }

  doIbIdSearch() {
    const {searchIbIdText} = this.state
    this.props.uiDispatch(
      'Search IBs by ID',
      (state) => ({...state, searchIbId: searchIbIdText})
    )
  }

  doPammMMIDSearch() {
    const {searchPammMMIDText} = this.state
    this.props.uiDispatch(
      'Search by PAMM MM ID',
      (state) => ({...state, searchPammMMID: searchPammMMIDText})
    )
  }

  clearIbIdSearch() {
    this.setState({
      searchIbIdText: '',
    }, () => this.doIbIdSearch())
  }

  clearPammMMIDSearch() {
    this.setState({
      searchPammMMIDText: '',
    }, () => this.doPammMMIDSearch())
  }

  doTrackEventSearch() {
    const {searchTrackEventText} = this.state
    this.props.uiDispatch(
      'Search Track Event by ID',
      (state) => ({...state, searchTrackEvent: searchTrackEventText})
    )
  }

  clearTrackEventSearch() {
    this.setState({
      searchTrackEventText: '',
    }, () => this.doTrackEventSearch())
  }

  doTrackEventCampaignSearch() {
    const {searchCampaignText} = this.state
    this.props.uiDispatch(
      'Search Track Event by campaign',
      (state) => ({...state, searchCampaign: searchCampaignText})
    )
  }

  clearTrackEventCampaignSearch() {
    this.setState({
      searchCampaignText: '',
    }, () => this.doTrackEventCampaignSearch())
  }

  doTrackEventSearchParams() {
    const {searchPparamsText, searchFieldTrackEvent} = this.state
    this.props.uiDispatch(
      'Search Track Event by ib/affiliate',
      (state) => ({...state, searchPparams: searchPparamsText, trackEventClientType: searchFieldTrackEvent})
    )
  }

  clearTrackEventSearchParams() {
    this.setState({
      searchPparamsText: '',
      searchFieldTrackEvent: '',
    }, () => this.doTrackEventSearchParams())
    this.searchFieldSelectTrackEvents()
  }

  searchFieldSelectTrackEvents = (searchFieldTrackEvent) => {
    if (searchFieldTrackEvent === 'none') {
      searchFieldTrackEvent = ''
    }
    this.setState({searchFieldTrackEvent}, () => {
      this.doTrackEventSearchParams()
    })
  }

  doDateFilterSearch(name, value) {
    this.props.uiDispatch(
      'Filter dates',
      (state) => ({...state, [name]: value, ...resetAfterFilter}))
  }

  handleDateFilterChanged(name, value) {
    if ( !value || moment.isMoment(value)) {
      this.doDateFilterSearch(name, value)
    } else {
      this.doDateFilterSearch(name, '')
    }
  }

  onSearch() {
    const {exportPage} = this.state
    this.setState({loading: true})
    const searchArgs = this.getSearchArgs()
    this.props.actions.client.clientsExportSearch(searchArgs, exportPage, EXPORT_LIMIT).then((res) => {
      this.setState({loading: false, exportModalShown: false, exportPage: 1})
      this.context.showNotification({
        title: 'Exports Tool',
        message: 'Export Tool working in background. You will receive the report on your email shortly',
        position: 'tr',
        level: 'success',
      })
    }).catch((e) => {
      this.setState({loading: false})
      this.context.showNotification({
        message: e.message,
        level: 'error',
        autoDismiss: 0,
      })
    })
  }

  canInviteIbOrAffiliate() {
    const {viewer} = this.props
    const hasProperDepartmentRole = [departmentRoles.partnerManager.value, departmentRoles.acquisitionManager.value].includes(
      viewer.departmentRoleType)
    const hasProperRole = viewer.roles.some((r) => ['sales_agent', 'affiliate_manager'].includes(r))
    const atLeastOneCompanyPermitted = ActiveWhiteLabelNames.some((wl) => viewer.companies?.includes(wl))

    return hasProperDepartmentRole && hasProperRole && atLeastOneCompanyPermitted
  }

  render() {
    const {uiDispatch, clientsSearch, clientsSalesAgents, clientsCountriesFilterValue, clientsPage,
      clientsSalesAgentFilterValue, clientsAccountsFilter, conversionStatusFilter, tradingStatusFilter,
      kycStatusFilter, riskCategoryFilter, depositorsFilter, clients, clientsCount, selectedClients,
      columnDefinitions, columns, viewer, onlineOnlyFilter, underMonitoringFilter, companyFilter, clientTypesFilter,
      showReassignClients, reassignClientsArgs, tagCategoriesFilter, tagFilter,
      showSearchHelpModal, ibApprovedFilter, watchlistCheckedAt, masterIbOnlyFilter,
      attendedFilter, searchIbId, searchPammMMID, searchTrackEvent, searchCampaign, searchPparams, eventTypeFilter, agents,
      partnersAgentFilter, competitionFilter, copyTradingFilter, missingPoiPorFilter,
      signedUpFromFilter, signedUpToFilter, convertedFromFilter, convertedToFilter, ftdFromFilter, ftdToFilter,
      lastEffectiveCallFromFilter, lastEffectiveCallToFilter, lastLoginFromFilter, lastLoginToFilter, noEffectiveCallFilter,
      showPushNotificationsModal, languageFilter, birthdayFromFilter, birthdayToFilter, lastActivityFromFilter, lastActivityToFilter,
      jobTitleFilter, netWorthFilter, lastAttemptedCallFromFilter, lastAttemptedCallToFilter, onCurrencySelect,
      balanceCurrencyFilter,
      conversionStepFilter
    } = this.props
    const {searchText, maxForexAPTestPoint, maxBinaryAPTestPoint, binaryAPTestScore, forexAPTestScore,
      showAdvanceSettings, activeAgentPoolFilter,
      searchField, activePotentialFilters, searchIbIdText, searchPammMMIDText, searchTrackEventText,
      searchCampaignText, searchPparamsText, searchFieldTrackEvent, loading, exportModalShown, exportPage,
    } = this.state

    const salesSelect = clientsSalesAgentFilterValue === mineSelectAgent ? mineSelectAgent : clientsSalesAgents
    const forexAPTestLabel =
    `Forex AP score ${forexAPTestScore > 0 ? `of [${forexAPTestScore}] or below` : 'filter disabled'}`
    const binaryAPTestLabel =
    `Binary AP score ${binaryAPTestScore > 0 ? `of [${binaryAPTestScore}] or below` : 'filter disabled'}`
    const tagCategory = Object.values(this.state.tags).map((i) => ({
      key: i.id,
      value: i.id,
      label: i.name,
    }))

    const allowedCompanies = getAllowedCompanies(viewer)
    const partnerAgents = filter(agents, (agent) =>
      agent.isActive && some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)))

    return (
      <Container>
        <Row className="mb-3">
          <Col xs={4}>
            <InputGroup>
              <InputGroup.Prepend>
                <DropdownButton
                  title={searchField || 'Select field...'}
                  id="input-dropdown-addon"
                  onSelect={(searchField) => this.searchFieldSelect(searchField)}
                  variant="outline-secondary"
                  className={style.dropdownSearch}
                >
                  <Dropdown.Item key="select" eventKey="none">Select field...</Dropdown.Item>
                  {['id:', 'email:', 'first_name:', 'last_name:', 'name:', 'account.id:', 'account.remote_id:', 'ip:', 'vps.vps_id:']
                    .map(v => <Dropdown.Item key={v} eventKey={v}>{v}</Dropdown.Item>)}
                  <Dropdown.Item key="more" eventKey="more">Advanced Search Help...</Dropdown.Item>
                </DropdownButton>
              </InputGroup.Prepend>
              <Form.Control
                id="t-list-clients-search"
                type="text"
                value={searchText}
                placeholder="Search by ID, Name, Email, Account ID, IP, Phone"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doClientsSearch()) ||
                  (e.key === 'Escape' && this.clearClientSearch())
                )}
              />
              <InputGroup.Append>
                {clientsSearch && <Button
                  size="sm"
                  key={1}
                  title="Clear"
                  variant={clientsSearch ? 'success' : 'outline-secondary'}
                  onClick={() => this.clearClientSearch()}
                  id="t-list-clients-clear-button"
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  size="sm"
                  key={2}
                  title="Search"
                  variant={clientsSearch ? 'success' : 'outline-secondary'}
                  onClick={() => this.doClientsSearch()}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={2}>
            <StylishSelect
              id="t-list-clients-filter-depositor"
              placeholderText="All Clients"
              value={depositorsFilter}
              options={depositorsSelectOptions}
              highlightIfActive
              onChange={(e) => uiDispatch(
                'Filter depositors',
                (state) => ({...state, depositorsFilter: e.value, ...resetAfterFilter})
              )}
            />
          </Col>
          <Col xs={2}>
            <StylishSelect
              id="t-list-clients-filter-country"
              placeholderText="All Countries"
              value={clientsCountriesFilterValue}
              options={countriesAndRegionsOptions}
              highlightIfActive
              onChange={this.activateCountryRegionFilter.bind(this)}
            />
          </Col>
          <Col xs={3} id="t-list-clients-filter-sales-agent">
            <SelectAgent
              placeholderText="All Sales Agents"
              agents={filter(this.props.agents, (agent) => (agent.isActive))}
              onChange={(target) => {
                let agents = map(target, 'value')
                let origin = ''
                if (agents.includes(mineSelectAgent)) {
                  agents = [this.props.viewer.id]
                  origin = mineSelectAgent
                }
                if (find(target, {value: ''})) {
                  agents = ['']
                }
                uiDispatch(
                  'Filter clients',
                  (state) => ({
                    ...state,
                    clientsSalesAgents: agents,
                    clientsSalesAgentFilterValue: origin,
                    ...resetAfterFilter,
                  })
                )
              }}
              value={salesSelect}
              highlightIfActive
              optionsPrefix={salesAgentFilterOptions}
              optionsFilter={agentTypes.salesAgent.filter}
              multi
              clearable
            />
          </Col>
          <Col id="t-list-clients-columns-settings" xs={1}>
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={this.props.popoverContent}
              rootClose
            >
              <Button
                className="float-right"
                variant="outline-secondary"
              >
                <FontAwesomeIcon icon="cog" />&zwnj;
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={4}>
            <AccountTypesFilter
              companies={viewer.companies}
              noAccountOption
              value={clientsAccountsFilter}
              onChange={this.dispatchAccountTypeSelection.bind(this)}
            />
          </Col>
          <Col xs={2}>
            <StylishSelect
              id="t-list-clients-filter-conversion-steps"
              placeholderText="All Conversion Statuses"
              value={conversionStepFilter}
              options={StylishSelect.enumToStylishOptions(conversionSteps, 'All Conversion Statuses')}
              highlightIfActive
              onChange={(e) => uiDispatch(
                'Filter conversion status',
                (state) => ({...state, conversionStepFilter: e.value, ...resetAfterFilter})
              )}
            />
          </Col>
          {viewer.companies.length > 1 &&
            <Col xs={2}>
              <StylishSelect
                id="t-list-clients-filter-company"
                placeholderText="All Companies"
                value={companyFilter}
                options={StylishSelect.enumToStylishOptions(allowedCompanies)}
                highlightIfActive
                multi
                clearable
                onChange={this.dispatchCompanySelection.bind(this)}
              />
            </Col>}
          <Col xs={3}>
            <SelectAgent
              id="t-clients-page-partners-agent-filter"
              agents={partnerAgents}
              value={partnersAgentFilter}
              placeholderText="All Partners Agents"
              optionsPrefix={[
                {value: '', label: 'All Partners Agents'},
                {value: claimedStatuses.unclaimed.key, label: claimedStatuses.unclaimed.label}
              ]}
              onChange={({value}) => this.props.uiDispatch(
                'Filter by partners agent',
                (state, arg) => ({...state, partnersAgentFilter: value, page: 1}),
                [value]
              )}
              highlightIfActive
            />
          </Col>
          <Col xs={1}>
            {columnDefinitions.selection.condition(this.props.viewer) &&
              <DisabledTooltipButton
                id="t-list-clients-batch-assign"
                className="float-right"
                variant="success"
                disabled={isEmpty(selectedClients)}
                onClick={() => this.toggleBatchAssign()}
                disabledTooltip="Select clients first"
              >Batch</DisabledTooltipButton>
            }
          </Col>
        </Row>
        <Row>
          <Col xs={4}>
            <StylishSelect
              id="t-list-clients-filter-client-types"
              placeholderText="All Client Types"
              value={clientTypesFilter}
              options={StylishSelect.enumToStylishOptions(clientTypes)}
              highlightIfActive
              multi
              clearable
              onChange={this.dispatchClientTypeSelection.bind(this)}
            />
          </Col>
          <Col xs={2}>
            <StylishSelect
              id="t-list-clients-filter-conversion-statuses"
              placeholderText="All Sales Statuses"
              value={conversionStatusFilter}
              options={StylishSelect.enumToStylishOptions(conversionStatuses, 'All Sales Statuses')}
              highlightIfActive
              onChange={(e) => uiDispatch(
                'Filter Sales Statuses',
                (state) => ({...state, conversionStatusFilter: e.value, ...resetAfterFilter})
              )}
            />
          </Col>
          <Col xs={2}>
            <StylishSelect
              clearable
              id="t-list-clients-filter-language"
              placeholderText="Language"
              value={languageFilter}
              options={StylishSelect.enumToStylishOptions(languages)}
              highlightIfActive
              onChange={this.activateLanguageFilter.bind(this)}
            />
          </Col>
          <Col xs={2}>
            <ButtonGroup>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="t-filtertooltip-poolonly"> Agent Pool Only </Tooltip>}
              >
                <Button
                  active={activeAgentPoolFilter.poolonly}
                  onClick={() => this.handleAgentPoolFilterSelected('poolonly')}
                  id="t-filter-agent-pool"
                  variant="outline-secondary"
                  size="sm"
                > Pool </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="t-filtertooltip-hidepool"> Hide Agent Pool </Tooltip>}
              >
                <Button
                  active={activeAgentPoolFilter.hidepool}
                  onClick={() => this.handleAgentPoolFilterSelected('hidepool')}
                  id="t-filter-agent-pool"
                  variant="outline-secondary"
                  size="sm"
                > No Pool </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className={style.advanceSettingDivider}>&nbsp;</Row>
        <Row>
          <Col xs={12}>
            <Collapse in={showAdvanceSettings}>
              <div id="t-advance-filters">
                <Row className={style.advanceSettings}>
                  <Col xs={12}>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <div className={style.label}>
                          Potential Level filters
                        </div>
                        <ButtonGroup>
                          {map(clientPotentialLevels, (level) =>
                            <Button
                              active={activePotentialFilters[level.value]}
                              onClick={() => this.handlePotentialFilterSelected(level.value)}
                              id={`t-filter-${level.value}`}
                              key={level.value}
                              variant="outline-secondary"
                              size="sm"
                            >{level.code}</Button>
                          )}
                        </ButtonGroup>
                      </Col>
                      <Col xs={3}>
                        <div className={style.label}>{forexAPTestLabel}</div>
                        <div className={style.rangeSlider}>
                          <Form.Control
                            type="range" step={1} max={maxForexAPTestPoint} min={0}
                            value={forexAPTestScore}
                            onChange={(e) => {
                              this.setState({forexAPTestScore: e.target.value})
                              this.doFilterByApTest('forex')
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div className={style.label}>{binaryAPTestLabel}</div>
                        <div className={style.rangeSlider}>
                          <Form.Control
                            type="range" step={1} max={maxBinaryAPTestPoint} min={0}
                            value={binaryAPTestScore}
                            onChange={(e) => {
                              this.setState({binaryAPTestScore: e.target.value})
                              this.doFilterByApTest('binary')
                            }}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <span className={style.label}>&nbsp;</span>
                          <StylishSelect
                            id="t-list-clients-filter-risk-category"
                            placeholderText="All Risk Categories"
                            value={riskCategoryFilter}
                            options={StylishSelect.enumToStylishOptions(riskCategories)}
                            multi
                            clearable
                            highlightIfActive
                            onChange={(e) => this.dispatchRiskCategorySelection(e)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Balance From [{balanceCurrencyFilter}]</span>
                          <Form.Control
                            id="t-balance-eur-min-filter"
                            type="number"
                            min={0}
                            onChange={(e) =>
                              this.handleBalanceFilterChanged('balanceMinFilter', e.target.value)}
                            onFocus={() => this.doFilterbyBalance.cancel()}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Balance To [{balanceCurrencyFilter}] </span>
                          <Form.Control
                            id="t-balance-eur-max-filter"
                            type="number"
                            min={0}
                            onChange={(e) =>
                              this.handleBalanceFilterChanged('balanceMaxFilter', e.target.value)}
                            onFocus={() => this.doFilterbyBalance.cancel()}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}>Balance currency</span>
                        <StylishSelect
                          id="t-list-clients-filter-balance-currency"
                          placeholderText="Select balance currency"
                          value={balanceCurrencyFilter}
                          options={StylishSelect.enumToStylishOptions(balanceFilterCurrencies)}
                          onChange={(e) => onCurrencySelect(e, resetAfterFilter)}
                        />
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}>Tag Category</span>
                        <StylishSelect
                          id="t-list-clients-filter-tags"
                          placeholderText="All Tags Category"
                          value={tagCategoriesFilter}
                          options={this.allTagCategoriesOptions(this.props.tagCategories)}
                          highlightIfActive
                          onChange={(e) => {
                            uiDispatch('Filter Tags',
                              (state) => ({...state, tagCategoriesFilter: e.value, ...resetAfterFilter})
                            )
                            uiDispatch('Set Sub Tags',
                              (state) => ({...state, tagFilter: [], ...resetAfterFilter})
                            )
                            this.setState({tags: e.tags})
                          }}
                        />
                      </Col>
                      <Col id="t-list-clients-filter-subtags" xs={3}>
                        <span className={style.label}>Tags</span>
                        <StylishSelect
                          id="t-list-clients-filter-subtags"
                          placeholderText="All Tags"
                          value={tagFilter}
                          options={tagCategory}
                          highlightIfActive
                          multi
                          clearable
                          onChange={this.dispatchSubtagSelection.bind(this)}
                        />
                      </Col>
                      <Col xs={3}>
                        <span className={style.label}>Copy Trading</span>
                        <StylishSelect
                          placeholderText="All Clients"
                          value={copyTradingFilter}
                          options={StylishSelect.enumToStylishOptions(copyTradingFilterTypes, 'All')}
                          highlightIfActive
                          onChange={(e) => {
                            this.props.uiDispatch(
                              'Filter by copy trading',
                              (state) => ({...state, copyTradingFilter: e.value, ...resetAfterFilter})
                            )
                          }}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={3}>
                        <div>
                          <span className={style.label}>&nbsp;</span>
                          <StylishSelect
                            id="t-list-clients-filter-trading-statuses"
                            placeholderText="All Trading Statuses"
                            value={tradingStatusFilter}
                            options={StylishSelect.enumToStylishOptions(tradingStatuses)}
                            highlightIfActive
                            multi
                            onChange={(e) => uiDispatch(
                              'Filter trading status',
                              (state) => ({...state, tradingStatusFilter: map(e, 'value'), ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <span className={style.label}>&nbsp;</span>
                          <StylishSelect
                            id="t-list-clients-filter-kyc-status"
                            placeholderText="All KYC Statuses"
                            value={kycStatusFilter}
                            options={StylishSelect.enumToStylishOptions(kycStatuses)}
                            multi
                            clearable
                            highlightIfActive
                            onChange={(e) => this.dispatchKYCStatusSelection(e)}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <span className={style.label}>Trading Competitions</span>
                          <StylishSelect
                            id="t-list-clients-filter-competitions"
                            placeholderText="All Clients"
                            value={competitionFilter}
                            options={Object.values(this.props.competitions).map((i) => ({
                              key: i.id,
                              value: i.id,
                              label: i.name,
                            }))}
                            clearable
                            highlightIfActive
                            onChange={(e) => uiDispatch(
                              'Filter by competition',
                              (state) => ({...state, competitionFilter: e ? e.value : '', ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <span className={style.label}>Missing POI / POR</span>
                          <StylishSelect
                            multi
                            clearable
                            highlightIfActive
                            id="t-list-clients-filter-missing-poi-por"
                            placeholderText="POI / POR"
                            value={missingPoiPorFilter}
                            options={this.allMissingPoiPorOptions()}
                            onChange={(e) => uiDispatch(
                              'Filter by missing POI / POR',
                              (state) => ({...state, missingPoiPorFilter: map(e, 'value'), ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Sign up From </span>
                          <DateTime
                            id="signed-up-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('signedUpFromFilter', e  && moment.isMoment(e) ? moment(e).startOf('day') : '' )}
                            value={signedUpFromFilter}
                            onFocus={() => this.doDateFilterSearch('signedUpFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}> Sign up To </span>
                        <DateTime
                          id="signed-up-to-filter"
                          timeFormat={false}
                          onChange={(e) => this.handleDateFilterChanged('signedUpToFilter', e  && moment.isMoment(e) ? moment(e).endOf('day') : '')}
                          value={signedUpToFilter}
                          onFocus={() => this.doDateFilterSearch('signedUpToFilter', '').cancel()}
                          closeOnSelect
                          className={style.datetime}
                        />
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Converted From </span>
                          <DateTime
                            id="converted-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('convertedFromFilter', e  && moment.isMoment(e) ? moment(e).startOf('day') : '')}
                            value={convertedFromFilter}
                            onFocus={() => this.doDateFilterSearch('convertedFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}> Converted To </span>
                        <DateTime
                          id="converted-to-filter"
                          timeFormat={false}
                          onChange={(e) => this.handleDateFilterChanged('convertedToFilter', e  && moment.isMoment(e) ? moment(e).endOf('day') : '')}
                          value={convertedToFilter}
                          onFocus={() => this.doDateFilterSearch('convertedToFilter', '').cancel()}
                          closeOnSelect
                          className={style.datetime}
                        />
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> FTD From </span>
                          <DateTime
                            id="ftd-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('ftdFromFilter', e  && moment.isMoment(e) ? moment(e).startOf('day') : '')}
                            value={ftdFromFilter}
                            onFocus={() => this.doDateFilterSearch('ftdFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}> FTD To </span>
                        <DateTime
                          id="ftd-to-filter"
                          timeFormat={false}
                          onChange={(e) => this.handleDateFilterChanged('ftdToFilter', e  && moment.isMoment(e) ? moment(e).endOf('day') : '')}
                          ftdTo={ftdToFilter}
                          onFocus={() => this.doDateFilterSearch('ftdToFilter', '').cancel()}
                          closeOnSelect
                          className={style.datetime}
                        />
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Last login From </span>
                          <DateTime
                            id="last-login-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('lastLoginFromFilter', e  && moment.isMoment(e) ? moment(e).startOf('day') : '')}
                            ftdTo={lastLoginFromFilter}
                            onFocus={() => this.doDateFilterSearch('lastLoginFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}> Last login To </span>
                        <DateTime
                          id="last-login-to-filter"
                          timeFormat={false}
                          onChange={(e) => this.handleDateFilterChanged('lastLoginToFilter', e  && moment.isMoment(e) ? moment(e).endOf('day') : '')}
                          ftdTo={lastLoginToFilter}
                          onFocus={() => this.doDateFilterSearch('lastLoginToFilter', '').cancel()}
                          closeOnSelect
                          className={style.datetime}
                        />
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Last effective call From </span>
                          <DateTime
                            utc
                            id="last-effective-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('lastEffectiveCallFromFilter', e  && moment.isMoment(e) ? moment(e).startOf('day') : '')}
                            lastEffectiveCallFrom={lastEffectiveCallFromFilter}
                            onFocus={() => this.doDateFilterSearch('lastEffectiveCallFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <span className={style.label}> Last effective call To </span>
                        <DateTime
                          utc
                          id="last-effective-to-filter"
                          timeFormat={false}
                          onChange={(e) => this.handleDateFilterChanged('lastEffectiveCallToFilter', e  && moment.isMoment(e) ? moment(e).endOf('day') : '')}
                          value={lastEffectiveCallToFilter}
                          onFocus={() => this.doDateFilterSearch('lastEffectiveCallToFilter', '').cancel()}
                          closeOnSelect
                          className={style.datetime}
                        />
                      </Col>
                      <Col xs={2}>
                        <input id="no-effective-call-filter" type="checkbox"
                          className={classNames([style.toggleSwitch, style.toggleLight])}
                          checked={!!noEffectiveCallFilter}
                          onChange={(e) => {
                            this.props.uiDispatch(
                              'Filter By No effective call',
                              (state) => ({...state, noEffectiveCallFilter: e.target.checked, ...resetAfterFilter})
                            )
                          }
                          }
                        />
                        <label id="t-no-effective-call-filter" htmlFor="no-effective-call-filter" className={style.toggleButton} />
                        <span> No effective call</span>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Last Attempted Call From </span>
                          <DateTime
                            utc
                            id="last-attempted-call-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('lastAttemptedCallFromFilter', e  && moment.isMoment(e) ? moment(e).startOf('day') : '')}
                            value={lastAttemptedCallFromFilter}
                            onFocus={() => this.doDateFilterSearch('lastAttemptedCallFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Last Attempted Call To </span>
                          <DateTime
                            utc
                            id="last-attempted-call-to-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('lastAttemptedCallToFilter', e  && moment.isMoment(e) ? moment(e).endOf('day') : '')}
                            value={lastAttemptedCallToFilter}
                            onFocus={() => this.doDateFilterSearch('lastAttemptedCallToFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Birthday From </span>
                          <DateTime
                            id="birthday-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('birthdayFromFilter', e  && moment.isMoment(e) ? moment.utc(e).startOf('day') : '' )}
                            value={birthdayFromFilter}
                            onFocus={() => this.doDateFilterSearch('birthdayFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                            utc
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Birthday To </span>
                          <DateTime
                            id="birthday-to-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('birthdayToFilter', e  && moment.isMoment(e) ? moment.utc(e).endOf('day') : '' )}
                            value={birthdayToFilter}
                            onFocus={() => this.doDateFilterSearch('birthdayToFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                            utc
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Last Activity From </span>
                          <DateTime
                            id="last-activity-from-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('lastActivityFromFilter', e  && moment.isMoment(e) ? moment.utc(e).endOf('day') : '' )}
                            value={lastActivityFromFilter}
                            onFocus={() => this.doDateFilterSearch('lastActivityFromFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                            utc
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}> Last Activity To </span>
                          <DateTime
                            id="last-activity-to-filter"
                            timeFormat={false}
                            onChange={(e) => this.handleDateFilterChanged('lastActivityToFilter', e  && moment.isMoment(e) ? moment.utc(e).endOf('day') : '' )}
                            value={lastActivityToFilter}
                            onFocus={() => this.doDateFilterSearch('lastActivityToFilter', '').cancel()}
                            closeOnSelect
                            className={style.datetime}
                            utc
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}>Industry</span>
                          <StylishSelect
                            id="t-list-clients-filter-job-title"
                            placeholderText="All Industries"
                            value={jobTitleFilter}
                            options={StylishSelect.enumToStylishOptions(appropTestEmployerCategory)}
                            clearable
                            highlightIfActive
                            onChange={(e) => uiDispatch(
                              'Filter by job title',
                              (state) => ({...state, jobTitleFilter: e ? e.value : '', ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}>Net Worth</span>
                          <StylishSelect
                            id="t-list-clients-filter-net-worth"
                            placeholderText="All Net Worths"
                            value={netWorthFilter}
                            options={StylishSelect.enumToStylishOptions(profileNetWorth)}
                            clearable
                            highlightIfActive
                            onChange={(e) => uiDispatch(
                              'Filter by net worth',
                              (state) => ({...state, netWorthFilter: e ? e.value : '', ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        {showAdvanceSettings && <Row className={style.advanceSettingDivider}>&nbsp;</Row>}
        <Row>
          <Col xs={12}>
            <Collapse in={showAdvanceSettings}>
              <div id="t-ib-filters">
                <Row className={style.advanceSettings}>
                  <Col xs={12} className={style.ibFilters}>IB FILTERS</Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={2}>
                        <div>
                          <span className={style.label}>IB Approved</span>
                          <StylishSelect
                            id="t-clients-ib-approved-filter"
                            placeholderText="All Clients"
                            value={ibApprovedFilter}
                            options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                            highlightIfActive
                            onChange={(e) => uiDispatch(
                              'Filter IB Approved',
                              (state) => ({...state, ibApprovedFilter: e.value, ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                      <Col xs={3}>
                        <div>
                          <br />
                          <Button
                            id="search-ibs-btn"
                            variant="outline-secondary"
                            onClick={this.props.onSearchIbs}
                          >
                            Search IBs by Keyword
                          </Button>
                        </div>
                      </Col>
                      <Col xs={3}><br />
                        <InputGroup>
                          <Form.Control
                            type="number"
                            min={0}
                            value={searchIbIdText}
                            placeholder="Search IBs by IB ID"
                            onChange={(e) => this.setState({searchIbIdText: e.target.value})}
                            onKeyUp={(e) => (e.key === 'Enter' && this.doIbIdSearch()) ||
                              (e.key === 'Escape' && this.clearIbIdSearch())}
                            className={style.numberNoArrows}
                          />
                          <InputGroup.Append>
                            {searchIbId && <Button
                              key={1}
                              title="Clear"
                              variant={searchIbId ? 'success' : 'outline-dark'}
                              onClick={() => this.clearIbIdSearch()}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>}
                            <Button
                              key={2}
                              title="Search"
                              variant={searchIbId ? 'success' : 'outline-dark'}
                              onClick={() => this.doIbIdSearch()}
                            >
                              <FontAwesomeIcon icon="search" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col xs={4}><br />
                        <Button
                          id="t-filter-masterOnly"
                          active={masterIbOnlyFilter}
                          variant="outline-secondary"
                          onClick={() =>
                            this.props.uiDispatch(
                              'Filter By Online Status',
                              (state) => ({...state, masterIbOnlyFilter: !masterIbOnlyFilter, ...resetAfterFilter})
                            )
                          }
                        >Master IBs Only</Button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Collapse in={showAdvanceSettings}>
              <div id="t-ib-filters">
                <Row className={style.advanceSettings}>
                  <Col xs={12} className={style.ibFilters}>PAMM FILTERS</Col>
                  <Col xs={12}>
                    <Row>
                      <Col xs={3}><br />
                        <InputGroup>
                          <Form.Control
                            type="number"
                            min={0}
                            value={searchPammMMIDText}
                            placeholder="Search by PAMM MM ID"
                            onChange={(e) => this.setState({searchPammMMIDText: e.target.value})}
                            onKeyUp={(e) => (e.key === 'Enter' && this.doPammMMIDSearch()) ||
                              (e.key === 'Escape' && this.clearPammMMIDSearch())}
                            className={style.numberNoArrows}
                          />
                          <InputGroup.Append>
                            {searchPammMMID && <Button
                              key={1}
                              title="Clear"
                              variant={searchPammMMID? 'success' : 'outline-dark'}
                              onClick={() => this.clearPammMMIDSearch()}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>}
                            <Button
                              key={2}
                              title="Search"
                              variant={searchPammMMID ? 'success' : 'outline-dark'}
                              onClick={() => this.doPammMMIDSearch()}
                            >
                              <FontAwesomeIcon icon="search" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        {showAdvanceSettings && <Row className={style.advanceSettingDivider}>&nbsp;</Row>}
        <Row>
          <Col xs={12}>
            <Collapse in={showAdvanceSettings}>
              <div id="t-ib-filters">
                <Row className={style.advanceSettings}>
                  <Col xs={12} className={style.ibFilters}>TRACK EVENTS FILTERS</Col>
                  <Col xs={12}>
                    <Row className="mb-3">
                      <Col xs={3}><br />
                        <InputGroup>
                          <Form.Control
                            type="text"
                            value={searchTrackEventText}
                            placeholder="Track event ID"
                            onChange={(e) => this.setState({searchTrackEventText: e.target.value})}
                            onKeyUp={(e) => (e.key === 'Enter' && this.doTrackEventSearch()) ||
                              (e.key === 'Escape' && this.clearTrackEventSearch())}
                            className={style.numberNoArrows}
                          />
                          <InputGroup.Append>
                            {searchTrackEvent && <Button
                              key={1}
                              title="Clear"
                              variant={searchTrackEvent ? 'success' : 'outline-dark'}
                              onClick={() => this.clearTrackEventSearch()}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>}
                            <Button
                              key={2}
                              title="Search"
                              variant={searchTrackEvent ? 'success' : 'outline-dark'}
                              onClick={() => this.doTrackEventSearch()}
                            >
                              <FontAwesomeIcon icon="search" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col xs={3}><br />
                        <InputGroup>
                          <Form.Control
                            type="text"
                            value={searchCampaignText}
                            placeholder="Track event campaign"
                            onChange={(e) => this.setState({searchCampaignText: e.target.value})}
                            onKeyUp={(e) => (e.key === 'Enter' && this.doTrackEventCampaignSearch()) ||
                              (e.key === 'Escape' && this.clearTrackEventCampaignSearch())}
                            className={style.numberNoArrows}
                          />
                          <InputGroup.Append>
                            {searchCampaign && <Button
                              key={1}
                              title="Clear"
                              variant={searchCampaign ? 'success' : 'outline-dark'}
                              onClick={() => this.clearTrackEventCampaignSearch()}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>}
                            <Button
                              key={2}
                              title="Search"
                              variant={searchCampaign ? 'success' : 'outline-dark'}
                              onClick={() => this.doTrackEventCampaignSearch()}
                            >
                              <FontAwesomeIcon icon="search" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col xs={6}><br />
                        <InputGroup>
                          <InputGroup.Prepend>
                            <DropdownButton
                              title={searchFieldTrackEvent || 'Select field...'}
                              id="input-dropdown-addon"
                              onSelect={(searchFieldTrackEvent) => this.searchFieldSelectTrackEvents(searchFieldTrackEvent)}
                              variant="outline-secondary"
                              className={style.dropdownSearch}
                            >
                              <Dropdown.Item key="select" eventKey="none">Select field...</Dropdown.Item>
                              {['IB ID', 'Affiliate ID']
                                .map(v => <Dropdown.Item key={v} eventKey={v}>{v}</Dropdown.Item>)}
                            </DropdownButton>
                          </InputGroup.Prepend>
                          <Form.Control
                            type="text"
                            value={searchPparamsText}
                            placeholder="Track event p params"
                            onChange={(e) => this.setState({searchPparamsText: e.target.value})}
                            onKeyUp={(e) => (e.key === 'Enter' && this.doTrackEventSearchParams()) ||
                              (e.key === 'Escape' && this.clearTrackEventSearchParams())}
                            className={style.numberNoArrows}
                          />
                          <InputGroup.Append>
                            {searchPparams && <Button
                              key={1}
                              title="Clear"
                              variant={searchPparams ? 'success' : 'outline-dark'}
                              onClick={() => this.clearTrackEventSearchParams()}
                            >
                              <FontAwesomeIcon icon="times" />
                            </Button>}
                            <Button
                              key={2}
                              title="Search"
                              variant={searchPparams ? 'success' : 'outline-dark'}
                              onClick={() => this.doTrackEventSearchParams()}
                            >
                              <FontAwesomeIcon icon="search" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={3}>
                        <div>
                          <span className={style.label}>Event Type</span>
                          <StylishSelect
                            id="t-event-type"
                            placeholderText="All Types"
                            value={eventTypeFilter}
                            options={StylishSelect.enumToStylishOptions(trackEventsTypes, 'All Types')}
                            highlightIfActive
                            onChange={(e) => uiDispatch(
                              'Filter event type',
                              (state) => ({...state, eventTypeFilter: e.value,
                                attendedFilter: (e.value === trackEventsTypes.webinars.value) ? '' :  attendedFilter, ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>
                      {eventTypeFilter !== trackEventsTypes.webinars.value && <Col xs={2}>
                        <div>
                          <span className={style.label}>Attended Seminar</span>
                          <StylishSelect
                            id="t-clients-ib-attended-filter"
                            placeholderText="All Clients"
                            value={attendedFilter}
                            options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                            highlightIfActive
                            onChange={(e) => uiDispatch(
                              'Filter attended clients',
                              (state) => ({...state, attendedFilter: e.value, ...resetAfterFilter})
                            )}
                          />
                        </div>
                      </Col>}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Collapse>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={12} >
            <OverlayTrigger placement="bottom"
              overlay={<Tooltip id="t-showadvancefilters">
                {`${showAdvanceSettings ? 'Hide' : 'Show'} Advance Filters`}</Tooltip>}
            >
              <div
                className={style.advanceSettingIconBar}
                onClick={() => this.setState({showAdvanceSettings: !showAdvanceSettings})}
                id="t-advance-settings-icon-bar"
              >
                <FontAwesomeIcon icon={`angle-double-${showAdvanceSettings ? 'up' : 'down'}`} />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <Card className="two-rows-filter">
              <Card.Body style={{overflowX: 'scroll', maxHeight: 'calc(100vh - 135px)'}}>
                <div className="panel-heading">
                  <Row>
                    <Col xs={2}>
                      <input id="online-only-filter" type="checkbox"
                        className={classNames([style.toggleSwitch, style.toggleLight])}
                        checked={onlineOnlyFilter}
                        onChange={(e) =>
                          this.props.uiDispatch(
                            'Filter By Online Status',
                            (state) => ({...state, onlineOnlyFilter: e.target.checked, ...resetAfterFilter})
                          )
                        }
                      />
                      <label id="t-online-only-filter" htmlFor="online-only-filter" className={style.toggleButton} />
                      <span> Show Online Only</span>
                    </Col>
                    <Col xs={2}>
                      <input id="under-monitoring-filter" type="checkbox"
                        className={classNames([style.toggleSwitch, style.toggleLight])}
                        checked={underMonitoringFilter}
                        onChange={(e) =>
                          this.props.uiDispatch(
                            'Filter By Under Monitoring Status',
                            (state) => ({...state, underMonitoringFilter: e.target.checked, ...resetAfterFilter})
                          )
                        }
                      />
                      <label
                        id="t-under-monitoring-filter"
                        htmlFor="under-monitoring-filter"
                        className={style.toggleButton}
                      />
                      <span><b> Under Monitoring </b></span>
                    </Col>
                    <Col xs={8} className="text-right">
                      {watchlistCheckedAt && <p>Watchlists checked on: {moment(watchlistCheckedAt).format('LLL')}</p>}
                      <ButtonToolbar className={classNames(['float-right', style.buttonsContainer]) }>
                        {/* Currently Disabled */}
                        {/* {canSendMarketingEmails(viewer) &&
                          <Button id="marketing-emails" variant="success"
                            onClick={this.showMarketingEmailsModal}
                            size="sm"
                          > Marketing Emails
                          </Button>
                        } */}
                        {canSendPushNotifications(viewer) &&
                          <Button id="marketing-push-notifications" variant="success"
                            onClick={this.showPushNotificationModal.bind(this)}
                            size="sm"
                          > Push Notifications
                          </Button>
                        }
                        {canWriteClientSalesAgent(this.props.viewer) &&
                          <DisabledTooltipButton
                            id="t-editclients-reassign-agent"
                            variant="outline-secondary"
                            onClick={this.onReassignClients.bind(this)}
                            // disabled={!(size(this.getSearchArgs()) > 1 || !isEmpty(selectedClients))}
                            disabled={isEmpty(selectedClients)}
                            disabledTooltip="Select filters from above first"
                            size="sm"
                          >
                            Reassign Clients
                          </DisabledTooltipButton>
                        }
                        {!canQueryAffiliateManagerClients(this.props.viewer) &&
                          <Button id="add-lead-btn" variant="success"
                            onClick={this.props.onAddLead}
                            size="sm"
                          >
                            <FontAwesomeIcon icon="plus" /> Add New Lead
                          </Button>
                        }
                        {this.props.viewer.department === userDepartments.accounting.key &&
                          <Button
                            id="t-clients-export-button"
                            variant="outline-secondary"
                            onClick={() => this.setState({exportModalShown: true})}
                            disabled={loading}
                            size="sm"
                            className="float-right"
                          >
                            {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                          </Button>
                        }
                        {this.canInviteIbOrAffiliate() ? <InviteClientsButton viewer={viewer} /> : null}
                      </ButtonToolbar>
                    </Col>
                  </Row>
                  <div className="clearfix">{null}</div>
                </div>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      {columns.map(
                        (key) => {
                          const column = columnDefinitions[key]
                          if (column.condition && !column.condition(this.props.viewer)) {
                            return false
                          }
                          const {label} = column
                          const columnId = 't-list-clients-column-' + sanitizeId(label)
                          switch (key) {
                            case 'selection': {
                              return this.renderSelectionHeader(columnId, key, label)
                            }
                            default: {
                              return (
                                <th
                                  id={columnId}
                                  key={key}
                                >
                                  {label}
                                </th>
                              )
                            }
                          }
                        }
                      )}
                    </tr>
                  </thead>
                  <tbody id="t-list-clients">
                    {uniqBy(clients, (c) => c.id).map((client) => this.renderClient(client))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, clientsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(clientsPage, getPageCount(clientsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, clientsPage: clientsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, clientsPage: clientsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === clientsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, clientsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>

        <BatchAssign
          show={this.props.assignSalesModal}
          selectedClientsCount={selectedClients ? keys(selectedClients).length : 0}
          onCancel={this.toggleBatchAssign.bind(this)}
          onSave={this.batchAssign.bind(this)}
        />

        <ReassignAgentClientsModal
          show={showReassignClients}
          onHide={this.hideReassignClientsModal.bind(this)}
          notify={(level, message) => this.showNotification(level, message)}
          args={reassignClientsArgs}
          selectedClients={selectedClients}
        />

        <PushNotificationsModal
          show={showPushNotificationsModal}
          searchParams={reassignClientsArgs}
          onHide={this.hidePushNotificationModal.bind(this)}
          sendPushNotifications={this.props.actions.client.sendPushNotifications}
          signPushNotificationFileUrl={this.props.actions.client.signPushNotificationFileUrl}
        />

        {/* Currently Disabled */}
        {/* {canSendMarketingEmails(viewer) && <MarketingEmailsModal
          show={showMarketingEmailsModal}
          searchParams={reassignClientsArgs}
          onHide={this.hideMarketingEmailsModal}
          sendMarketingEmails={this.props.actions.client.sendMarketingEmails}
        />} */}

        <SearchHelpModal
          show={showSearchHelpModal}
          onHide={this.hideSearchHelpModal}
        />

        <Modal
          keyboard
          show={exportModalShown}
          onHide={() => this.setState({
            exportModalShown: false,
            exportPage: 1,
          })}
        >
          <Modal.Header closeButton className={style.modalHeader}>
            Export Clients
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col xs={12}>
                <span style={{fontSize: 12}}>
                  *The export is limited to 20,000 results. If the search results are more than 20,000, please select
                  another page from the below dropdown or use a more specific search.
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}>
                <StylishSelect
                  id="t-deposits-export-page-select"
                  placeholderText="Select a page"
                  defaultValue={{value: 1, label: 1}}
                  options={map(range(0, getPageCount(clientsCount, EXPORT_LIMIT)), (p) => ({
                    value: p + 1,
                    label: p + 1,
                  }))}
                  onChange={(e) => this.setState({exportPage: e.value})}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                  id="t-deposits-export-modal-button"
                  variant="outline-secondary"
                  className="float-right"
                  onClick={() => this.onSearch()}
                  disabled={loading || !exportPage}
                >
                  {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

      </Container>
    )
  }
}
