export default function create(dbClient) {
  return {
    exportPerformanceFees(data) {
      const {performanceFeesSearch, dateFilters, amountFilters} = data

      const condition = [
        dateFilters && dateFilters.createdFrom ?
          `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : '',
        dateFilters && dateFilters.createdTo ?
          `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : '',
        amountFilters && amountFilters.amountFrom ? `amountFrom: ${amountFilters.amountFrom}` : '',
        amountFilters && amountFilters.amountTo ? `amountTo: ${amountFilters.amountTo}` : '',
        performanceFeesSearch ? `search: "${performanceFeesSearch}"` : '',
      ].join('\n').trim()

      const query = `mutation {
        exportPerformanceFees${condition ? `(${condition})` : ''}
      }`

      return dbClient.query(query, {})
    },
  }
}
