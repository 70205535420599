import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import classnames from 'classnames'
import {keyBy} from 'lodash'
import {clientWalkthroughs} from '@bdswiss/common-enums'
import {canWriteClientWalkthroughs} from '@bdswiss/common-permissions'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import styles from './client.module.scss'

export default provideProps()(class UpdateWalkthrough extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {walkthroughs, viewer} = this.props
    const walkthroughOptions = StylishSelect.enumToStylishOptions(clientWalkthroughs)
    const selectedWalkthrough = walkthroughs.length > 0 ?
      walkthroughs[0].type : clientWalkthroughs.websiteWalkThrough.key
    this.setState({walkthroughOptions, selectedWalkthrough, renderSidebar: canWriteClientWalkthroughs(viewer)})
  }

  saveChanges(isDeleteOp) {
    const {actions, walkthroughs, clientId} = this.props
    const {selectedWalkthrough} = this.state

    if (isDeleteOp) {
      const args = {walkthroughId: walkthroughs.find((w) => w.type === selectedWalkthrough).id}
      return actions.client.deleteWalkthroughRecord(args).then((res) => {
        this.context.clientProvider.subProviders.walkthroughs.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
        .catch(this.context.logError)
        .then(this.props.onClose)
    } else {
      const args = {
        type: selectedWalkthrough,
        clientId,
      }
      return actions.client.logWalkthroughProvision(args).then((res) => {
        this.context.clientProvider.subProviders.walkthroughs.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
        .catch(this.context.logError)
        .then(this.props.onClose)
    }
  }

  render() {
    let {walkthroughs} = this.props
    walkthroughs = walkthroughs.length > 0 && keyBy(walkthroughs, 'type')
    const {selectedWalkthrough, walkthroughOptions, renderSidebar} = this.state
    const deletable = !!walkthroughs[selectedWalkthrough]

    return renderSidebar ? (
      <Card
        id="t-client-update-walkthrough-sidebar"
        className={classnames(['panel-short', styles.fixedSidePanel])}
      >
        <Card.Header><strong>Edit Walkthroughs</strong></Card.Header>
        <Card.Body>
          <Row>
            <Col md={12} xs={12}>
              <StylishSelect.Input
                id="t-client-update-walkthrough"
                label="Update Walkthrough"
                value={selectedWalkthrough}
                onChange={(e) => this.setState({selectedWalkthrough: e.value, touched: true})}
                placeholderText="Select Walkthrough Type to Update"
                options={walkthroughOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-update-walkthrough-cancel-button"
                  tabIndex={-1}
                  onClick={this.props.onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >Cancel
                </Button>
                <Button
                  id="t-client-update-walkthrough-update-button"
                  variant={`${deletable ? 'danger' : 'success'}`}
                  size="sm"
                  onClick={() => this.saveChanges(deletable)}
                >
                  {`${deletable ? 'Delete Record' : 'Log Provision'}`}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    ) : null
  }
})
