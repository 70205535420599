import {passwordErrors} from '@bdswiss/common-enums'
import {isEmpty} from 'lodash'
import {Address4, Address6} from 'ip-address'

export function validatePassword(password) {
  //
  // The client master password is subject to all of the following requirements:
  //
  //  1. BDSwiss password requirements:
  //      -- at least 5 chars long
  //
  //  2. Forex account password requirements:
  //      -- between 5-15 chars long
  //      -- at least one letter
  //      -- at least one digit
  //
  //  3. Spot Options account password requirements:
  //      -- none
  //
  //  4. Cuboid account password requirements:
  //      -- ignored
  //
  if (!password) {
    throw new Error(passwordErrors.isRequired.message)
  }
  if (password.length < 6 || password.length > 32) {
    throw new Error(passwordErrors.isLessThan6CharsMoreThan32.message)
  }
  if (!/[a-zA-Z]/.test(password)) {
    throw new Error(passwordErrors.noAnyLetter.message)
  }
  if (!/[0-9]/.test(password)) {
    throw new Error(passwordErrors.noDigit.message)
  }
}

export function isValidEmail(email) {
  return email != null && email.indexOf && email.indexOf('@') > -1 && email.indexOf('.') > -1
}

export function isValidOptionalEmail(email) {
  return isEmpty(email) || isValidEmail(email)
}

/**
 * Check IP is valid v4 or v6 IP
 *
 * @param {String} ip
 * @return {Boolean}
 */
export function isValidIp(ip) {
  const address4 = new Address4(ip)
  const address6 = new Address6(ip)
  return address4.isValid() || address6.isValid()
}

/**
 * Check phone is numeric and optionally starts with +
 *
 * @param {String} phone
 * @return {Boolean}
 */
export function isValidPhone(phone) {
  return /^\+?\d+$/.test(phone)
}
