import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import classnames from 'classnames'
import {clientPotentialLevels} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import styles from './client.module.scss'

export default provideProps()(class UpdatePotential extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {client:{generalInfo: {potentialLevel}}} = this.props
    const potentialOptions = StylishSelect.enumToStylishOptions(clientPotentialLevels)
    this.setState({selectedPotential: potentialLevel, potentialOptions})
  }

  saveChanges() {
    const {actions, clientId} = this.props
    const {selectedPotential} = this.state
    const {optimistic} = this.context.clientProvider

    const args = {
      potentialLevel: selectedPotential,
      clientId,
    }

    optimistic.setPotentialLevel(this.props.dispatch, this.props.generalInfo.id, selectedPotential)
    return actions.client.updatePotentialLevel(args).then((res) => {
      this.context.clientProvider.subProviders.basicData.fetch()
      this.context.clientProvider.subProviders.activityLogs.fetch()
    })
      .catch(this.context.logError)
      .then(this.props.onClose)
  }

  render() {
    const {selectedPotential, potentialOptions} = this.state
    return <Card
      id="t-client-update-walkthrough-sidebar"
      className={classnames(['panel-short', styles.fixedSidePanel])}
    >
      <Card.Header><strong>Edit Potentials</strong></Card.Header>
      <Card.Body>
        <Row>
          <Col md={12} xs={12}>
            <StylishSelect.Input
              id="t-client-update-potential"
              label="Update Client Pontential Level"
              value={selectedPotential}
              onChange={(e) => this.setState({selectedPotential: e.value, touched: true})}
              placeholderText="Select Potential Level to Update"
              options={potentialOptions}
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ButtonToolbar className="float-right">
              <Button
                id="t-client-update-potential-cancel-button"
                tabIndex={-1}
                onClick={this.props.onClose}
                variant="outline-secondary"
                size="sm"
                className="mr-1"
              >Cancel
              </Button>
              <Button
                id="t-client-update-potential-update-button"
                variant="success"
                size="sm"
                className="mr-1"
                onClick={() => this.saveChanges()}
              >
                Save
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  }
})
