import React from 'react'
import PropTypes from 'prop-types'
import 'font-awesome/css/font-awesome.css'
import style from './components.module.scss'
import PureComponent from '../PureComponent'

export default class FontAwesomeIcon extends PureComponent {
  static propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string,
    className: PropTypes.string,
  };

  render() {
    const {icon, title, className, onClick, id, ...rest} = this.props
    return (
      <i id={id} className={`fa fa-${icon} ${style.fa} ${className}`} title={title} onClick={onClick} {...rest}/>
    )
  }
}
