import {clone, has, isArray, isObject} from 'lodash'

/*
 * In this module, `path` is an array of string or int representing the path in the nested
 * Object/Array.
 *
 * Example: state.user.posts[8] corresponds to path ['user', 'posts', 8]
 */

/*
 * Lookups value on a specific path in a given state. If the lookup fails, the error is thrown
 * unless default value is specified. Last argument is a map of default values:
 * - last is used when access fails at last step
 * - any is used when access fails at any step
 */

export function getIn(state, path, {last, any} = {}) {
  checkValidPath(path)
  let value = state
  for (let i = 0; i < path.length; i++) {
    if (has(value, path[i])) {
      value = value[path[i]]
    } else {
      if (i === path.length - 1 && last !== undefined) {
        return last
      } else if (any !== undefined) {
        return any
      } else {
        throwError('getIn', state, path.slice(0, i + 1), value)
      }
    }
  }
  return value
}

/*
 * Updates the specific `path` in a `state` by a given function `fn`. This function accepts a
 * previous value (looked up at the `path`) as its argument.
 */

export function updateIn(state, path, fn, force = false) {
  checkValidPath(path, 0)
  if (path.length === 0) {
    return fn(state)
  }
  return recursiveUpdate('updateIn', state, state, path, 0, fn, force)
}

/*
 * Sets a specific `path` in a `state` to a given value, immutable style. If force = true, creates the
 * nonexistent path with empty objects.
 *
 * path: array of string keys
 * returns: new state
 */
export function setIn(state, path, val, force = false) {
  checkValidPath(path, 0)
  if (path.length === 0) {
    return val
  }
  return recursiveUpdate('setIn', state, state, path, 0, () => val, force)
}

/*
 * Sets a specific `path` in a `component.state` to a given value, immutable style. If force = true,
 * creates the nonexistent path with empty objects.
 *
 * path: array of string keys
 */
export function setInState(component, path, val, force = false) {
  checkValidPath(path, 1)
  const newState = setIn(component.state || {}, path, val, force)
  component.setState(newState)
}

/*
 * Modifies dispatch function to work with specific path
 *
 * dispatch: original dispatch function
 * path: array of string keys
 */
export function dispatchIn(dispatch, path) {
  return (msg, fn, args) => dispatch(
    msg,
    (state, ...args) => {
      const _state = getIn(state, path, {any: ({})})
      const newState = fn(_state, ...args)
      return setIn(state, path, newState, true)
    },
    args
  )
}

/*
 * Sets specific `path` of connected component state using uiDispatch.
 *
 * component: component decorated by connect
 * path: array of string keys
 * val: new value for the `path`
 * msg: dispatch message
 * args: dispatch arguments
 */
export function setInLocal(component, msg, path, val) {
  dispatchIn(component.props.uiDispatch, path)(msg, (state, _val) => _val, [val])
}


// taskName and whole state and path for debugging purposes
function recursiveUpdate(taskName, state, resolvedState, path, index, fn, force = false) {
  // (shallow) clone from lodash, on which we edit/descend down the desired attribute
  let shallowCopy = clone(resolvedState)
  if (!shallowCopy && force) {
    shallowCopy = {}
  }
  if (path.length - 1 === index) {
    shallowCopy[path[index]] = fn(has(shallowCopy, path[index]) ? shallowCopy[path[index]] : undefined)
  } else {
    if (!has(shallowCopy, path[index])) {
      if (force) {
        shallowCopy[path[index]] = {}
      } else {
        throwError(taskName, state, path.slice(0, index + 1), shallowCopy)
      }
    }
    shallowCopy[path[index]] = recursiveUpdate(taskName, state,
      shallowCopy[path[index]], path, index + 1, fn, force)
  }
  return shallowCopy
}

function checkValidPath(path, minLength = 0) {
  if (!(path instanceof Array) || path.length < minLength) {
    throw new Error(`Expected path to be non-empty array, got: ${path}`)
  }
  // path may consist only of numbers and strings
  for (const e of path) {
    if (!((typeof e === 'string') || (typeof e === 'number'))) {
      throw new TypeError(`Path contains element that is not a number or a string. Path: ${path} Element: ${e}`)
    }
  }
}

function throwError(taskName, state, pathSegment, value) {
  /* eslint-disable no-console */
  console.error(`${taskName} failed - can not find
    ${pathSegment[pathSegment.length - 1]} in ${JSON.stringify(value)}`)
  console.error('State: ', state)
  console.error('Path (until failure): ', pathSegment)
  /* eslint-enable no-console */
  throw new Error(`Can not find ${pathSegment[pathSegment.length - 1]} in ${value}`)
}

// similar to lodash.isEqual, but handles also functions by ===
export function equals(obj1, obj2) {
  if (obj1 === obj2) {
    return true
  }
  if (isNaN(obj1) && isNaN(obj2)) {
    return true
  }
  if ((isArray(obj1) && isArray(obj2)) ||
      (isObject(obj1) && isObject(obj2))) {
    for (const key of Object.keys(obj1).concat(Object.keys(obj2))) {
      if ((key in obj1) && (key in obj2)) {
        if (!equals(obj1[key], obj2[key])) {
          return false
        }
      } else {
        return false
      }
    }
  } else {
    return obj1 === obj2
  }
  return true
}

export function shallowEqual(a, b) {
  if ((typeof a !== 'object') || (typeof b !== 'object') || a == null || b == null) {
    return a === b
  }
  for (const key in a) {
    if (!(key in b) || a[key] !== b[key]) {
      return false
    }
  }
  for (const key in b) {
    if (!(key in a)) {
      return false
    }
  }
  return true
}
