import {getPageSize, getOffset} from '../useful'

export const marketingEmailsProvider = {
  getQuery: (props) => {
    try {
      const {marketingEmailsSort, marketingEmailsPage} = props

      const query = `{
        marketingEmails (
          limit: ${getPageSize()}
          offset: ${getOffset(marketingEmailsPage)}
          orderBy: ${marketingEmailsSort.orderBy}
          orderDirection: ${marketingEmailsSort.orderDirection}
        ) {
          id
          jobId
          clientIDs
          searchParams
          defaultLanguage
          clientCount
          template
          unsubscribeGroup
          createdBy {
            id
          }
          createdAt
          status
        }
        marketingEmailsCount
      }`
      return query
    } catch (e) {
      throw e
    }

  },

  onData: (res, dispatch, props) => dispatch(
    'Marketing Emails Loaded',
    (state, res) => ({
      ...state,
      marketingEmails: res.marketingEmails,
      marketingEmailsCount: res.marketingEmailsCount,
    }),
    [res]
  ),
}
