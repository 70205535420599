import React from 'react'
import {debounce, map} from 'lodash'
import {Row, Col, Button, Form, InputGroup} from 'react-bootstrap'
import * as enums from '@bdswiss/common-enums'
import {agentsProvider} from '../providers'
import style from './calllogs.module.scss'
import DateTime from '../components/DateTime'
import SelectAgent from '../components/SelectAgent'
import StylishSelect from '../components/StylishSelect'
import {compose, provideProps, mountDataProviders, uiMount} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class CallLogsFilter extends React.Component {
  constructor(props) {
    super(props)
    this.dispatchFilterDebounced = debounce(this.dispatchFilter, 1500)
    this.state = {}
  }

  dispatchFilter() {
    this.props.uiDispatch(
      'Search call logs',
      (state, arg) => ({...state, callLogsFilter: arg, callLogsPage: 1}),
      [{...this.state}]
    )
  }

  doCallLogsSearch(search) {
    this.setState({search}, () => this.dispatchFilter())
  }

  applyFilter(key, value, delaySearch = false) {
    this.setState({[key]: value}, () => {
      if (delaySearch) {
        this.dispatchFilterDebounced()
      } else {
        this.dispatchFilter()
      }
    })
  }

  render() {
    const {agents} = this.props
    const {search, needsComplianceCheck, minDuration, userId, monitoringAgent, companies, startedAt, endedAt,
      monitoredOnly, minScore, maxScore,
    } = this.state

    return (
      <div>
        <Row>
          <Col xs={3}>
            <span className={style.label}>&nbsp;</span>
            <InputGroup>
              <Form.Control
                type="text"
                value={search || ''}
                size="sm"
                placeholder="Search by Client ID"
                onChange={(e) => this.setState({search: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doCallLogsSearch(search)) ||
                  (e.key === 'Escape' && this.doCallLogsSearch(''))
                )}
              />
              <InputGroup.Append>
                {search && <Button
                  key={1}
                  title="Clear"
                  variant={search ? 'success' : 'outline-dark'}
                  onClick={() => this.doCallLogsSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={search ? 'success' : 'outline-dark'}
                  onClick={() => this.doCallLogsSearch(search)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={2}>
            <span className={style.label}> Started At </span>
            <DateTime
              closeOnSelect
              timeFormat={false}
              className={style.datetime}
              id="t-call-logs-started-at-filter"
              value={startedAt}
              onFocus={() => this.dispatchFilterDebounced.cancel()}
              onChange={(value) => this.applyFilter('startedAt', value)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}> Ended At </span>
            <DateTime
              closeOnSelect
              timeFormat={false}
              className={style.datetime}
              id="t-call-logs-end-at-filter"
              value={endedAt}
              onFocus={() => this.dispatchFilterDebounced.cancel()}
              onChange={(value) => this.applyFilter('endedAt', value)}
            />
          </Col>
          <Col xs={1}>
            <span className={style.label}> Min Duration </span>
            <Form.Control
              type="number"
              size="sm"
              id="t-call-logs-min-duration-filter"
              value={minDuration}
              onFocus={() => this.dispatchFilterDebounced.cancel()}
              onChange={(e) => this.applyFilter('minDuration', e.target.value, true)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}> Sales/Partners Agent </span>
            <SelectAgent
              id="t-call-logs-sales-agent-filter"
              agents={agents}
              value={userId}
              placeholderText=""
              onChange={({value}) => this.applyFilter('userId', value)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}> Monitoring Agent </span>
            <SelectAgent
              id="t-call-logs-monitoring-agent-filter"
              agents={agents}
              value={monitoringAgent}
              placeholderText=""
              optionsFilter={(agent) => map(agent.roles, 'id').includes('call_monitoring_agent')}
              onChange={({value}) => this.applyFilter('monitoringAgent', value)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={2}>
            <span className={style.label}> &nbsp; </span>
            <Form.Check
              checked={needsComplianceCheck}
              label="Needs compliance check"
              onChange={(e) => this.applyFilter('needsComplianceCheck', e.target.checked)} />
          </Col>
          <Col xs={2}>
            <span className={style.label}> &nbsp; </span>
            <Form.Check
              checked={monitoredOnly}
              label="Only monitored calls"
              onChange={(e) => this.applyFilter('monitoredOnly', e.target.checked)} />
          </Col>
          <Col xs={2}>
            <span className={style.label}> &nbsp; </span>
            <Form.Check
              checked={minDuration >= 60}
              label="Effective calls only"
              onChange={(e) => this.applyFilter('minDuration', e.target.checked ? 60 : 0)} />
          </Col>
          <Col xs={2}>
            <Row>
              <Col xs={6}>
                <span className={style.label}> Min Score </span>
                <Form.Control
                  type="number"
                  size="sm"
                  id="t-call-logs-min-score-filter"
                  value={minScore}
                  onFocus={() => this.dispatchFilterDebounced.cancel()}
                  onChange={(e) => this.applyFilter('minScore', e.target.value, true)}
                />
              </Col>
              <Col xs={6}>
                <span className={style.label}> Max Score </span>
                <Form.Control
                  type="number"
                  size="sm"
                  id="t-call-logs-max-score-filter"
                  value={maxScore}
                  onFocus={() => this.dispatchFilterDebounced.cancel()}
                  onChange={(e) => this.applyFilter('maxScore', e.target.value, true)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <span className={style.label}> Company </span>
            <StylishSelect.Input
              options={StylishSelect.enumToStylishOptions(enums.companies)}
              value={companies || []}
              multi
              onChange={(list) => this.applyFilter('companies', map(list, 'value'))}
            />
          </Col>

        </Row>
      </div>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'call-logs']),

  provideProps((state) => ({agents: state.agents})),

  mountDataProviders({agentsProvider}),
)(CallLogsFilter)
