export default function create(dbClient) {
  return {
    upsertTag(data) {
      const variables = {
        id: data.id,
        name: data.name,
        tagCategory: data.tagCategory,
      }
      const query = `mutation (
                      $id: Int,
                      $name:String!,
                      $tagCategory:Int!,
                      ){
                        upsertTag(
                          id: $id,
                          name:$name,
                          tagCategory:$tagCategory
                        ) {
                           id
                        }
                      }`

      return dbClient.query(query, variables)
    },
  }
}
