import {Card, Col, Row, Table, Tabs, Tab} from 'react-bootstrap'
import {useEffect, useMemo} from 'react'

import {permissionDetailsProvider} from './providers'
import {compose, mountDataProviders, provideProps} from '../../decorators'

import styles from './PermissionDetails.module.scss'

export const PermissionDetails = ({permission, permissionId, dispatch}) => {
  const isPermissionFetched = permission && permissionId

  useEffect(
    () => () => {
      dispatch('Clear permission details', (state) => ({...state, permission: null}))
    },
    [dispatch]
  )

  const users = useMemo(() => {
    if (!permission || !permission.roles) {
      return []
    }

    return permission.roles.reduce((summ, role) => {
      summ = [...summ, ...role.users]
      return summ
    }, [])
  }, [permission])

  return (
    <Card className="fit-to-page">
      <Card.Body>
        {!isPermissionFetched && <p>Please select permission from the list</p>}
        {isPermissionFetched && (
          <>
            <Row xs={10}>
              <Col xs={5}>
                <p>Name</p>
              </Col>
              <Col xs={7}>
                <p>{permission.name}</p>
              </Col>
            </Row>
            <Row xs={10}>
              <Col xs={5}>
                <p>Description</p>
              </Col>
              <Col xs={7}>
                <p>{permission.description}</p>
              </Col>
            </Row>
            <Tabs
              defaultActiveKey="roles"
              className={styles['PermissionDetails-tabs']}
            >
              <Tab eventKey="roles" title="Roles">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Description</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {permission.roles?.map(({id, name, description}) => (
                      <tr key={id} id={id}>
                        <td>{name}</td>
                        <td>{description}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
              <Tab eventKey="users" title="Users">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Name</span>
                      </th>
                      <th>
                        <span>Email</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map(({id, firstName, lastName, email}) => (
                      <tr key={id} id={id}>
                        <td>
                          {firstName} {lastName}
                        </td>
                        <td>{email}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Tab>
            </Tabs>
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default compose(
  provideProps(({permission}) => ({permission})),
  mountDataProviders({permissionDetailsProvider})
)(PermissionDetails)
