import {Client, cacheExchange, fetchExchange} from 'urql'
import runtimeConfig from '../config'

const {backendUrl} = runtimeConfig.getInstance()

export const client = new Client({
  url: `${backendUrl}/graphql`,
  exchanges: [cacheExchange, fetchExchange],
  fetchOptions: {credentials: 'include'},
})

