import PureComponent from '../PureComponent'
import {provideProps} from '../decorators'
import {Button, ButtonToolbar, Col,Row} from 'react-bootstrap'
import style from './client.module.scss'
import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {get, isEmpty} from 'lodash'
import {List as Loading} from 'react-content-loader'
import {vpsSubscriptionPlans, vpsSubscriptionStatuses} from '@bdswiss/common-enums'
import {safeParseJSON} from '../common/utils'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {createVpsSubscriptionUrl} from '../utils/links'
import Promise from 'bluebird'
import ConfirmationModal from '../components/ConfirmationModal'
import {readableDate} from '../useful'
import {canCancelVpsSubscription} from '@bdswiss/common-permissions'

class VPS extends PureComponent {

  static propTypes = {
    client: PropTypes.object,
    switchSidebar: PropTypes.func.isRequired,
  };

  state = {
    dataFetched: false,
    dataError: '',
    dataLoading: false,
    openConfirmationModal: false,
    loadingConfirmation: false,
    subscription: null,
    action: null, // 'cancel', 'terminate'
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {dataFetched, dataError, dataLoading} = this.state
    if (!dataLoading && !dataError && !dataFetched) {
      this.setState({dataLoading: true}, () => {
        Promise.all([
          this.context.clientProvider.subProviders.vpsSubscriptions.fetch(),
        ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const {isActive} = newProps
    const {dataFetched, dataLoading, dataError} = this.state

    if (isActive && !dataLoading && !dataError && !dataFetched) {
      Promise.all([
        this.context.clientProvider.subProviders.vpsSubscriptions.fetch(),
      ]).then(() => {
        this.setState({dataFetched: true, dataLoading: false})
      }).catch((e) => this.setState({dataError: e, dataFetched: true, dataLoading: false}))
      this.setState({dataLoading: true})
    }
  }

  renderConfirmationModal() {
    const {action, subscription} = this.state
    let actionOptions = {}
    let dialogContent = null
    switch (true) {
      case action === 'cancel':
        actionOptions = {...actionOptions,
          onAgree: () => this.cancelVpsSubscription(subscription),
          onDisagree: this.closeConfirmationModal,
          disagreeText: 'Cancel',
          agreeText: 'Proceed',
        }
        dialogContent = `Do you want to cancel your ${vpsSubscriptionPlans[subscription.planName].label} subscription?`
        break
      case action === 'terminate':
        actionOptions = {...actionOptions,
          onAgree: () => this.terminateVpsSubscription(subscription),
          onDisagree: this.closeConfirmationModal,
          disagreeText: 'Cancel',
          agreeText: 'Proceed',
        }
        dialogContent = `Do you want to terminate your ${vpsSubscriptionPlans[subscription.planName].label} subscription?`
        break
      default:
    }
    return <ConfirmationModal
      show
      body={dialogContent}
      confirmLabel={actionOptions.agreeText}
      confirmStyle="danger"
      onConfirm={actionOptions.onAgree}
      onCancel={actionOptions.onDisagree}
    />
  }

  openConfirmationModal = (action, subscription) => {
    this.setState({
      openConfirmationModal: true,
      action,
      subscription
    })
  }

  closeConfirmationModal = () => {
    this.setState({
      openConfirmationModal: false,
      action: null,
      subscription: null
    })
  }

  createVpsSubscription(accountId) {
    this.context.router.push(createVpsSubscriptionUrl(this.props.client.generalInfo.id, accountId))
  }

  cancelVpsSubscription(subscription) {
    this.setState({dataLoading: true})
    const {actions, client} = this.props
    const {context: {clientProvider: {subProviders}}} = this

    return actions.client.cancelVpsSubscription(subscription.id, client.personalDetails.id, 'Cancelled by admin', false)
      .then((res) => {
        this.setState({dataLoading: false, dataError: ''})
        subProviders.vpsSubscriptions.fetch()
        this.context.showNotification({
          title: 'Cancel Subscription',
          message: 'VPS Subscription cancelled',
          position: 'tr',
          level: 'success',
        })
      })
      .catch((e) => {
        this.setState({dataLoading: false, dataError: e})
        e.isShowActualError = true
        this.context.showNotification({
          title: 'Cancel Subscription',
          message: e.message,
          position: 'tr',
          level: 'error',
        })
      })
      .then(this.setState({openConfirmationModal: false}))
  }

  terminateVpsSubscription(subscription) {
    this.setState({dataLoading: true})
    const {actions, client} = this.props
    const {context: {clientProvider: {subProviders}}} = this

    return actions.client.terminateVpsSubscription(subscription.id, client.personalDetails.id, 'Cancelled by admin')
      .then((res) => {
        this.setState({dataLoading: false, dataError: ''})
        subProviders.vpsSubscriptions.fetch()
        this.context.showNotification({
          title: 'Terminate Subscription',
          message: 'VPS Subscription terminated',
          position: 'tr',
          level: 'success',
        })
      })
      .catch((e) => {
        this.setState({dataLoading: false, dataError: e})
        e.isShowActualError = true
        this.context.showNotification({
          title: 'Terminate Subscription',
          message: e.message,
          position: 'tr',
          level: 'error',
        })
      })
      .then(this.setState({openConfirmationModal: false}))
  }

  render() {
    const {client, dataLoading, viewer} = this.props
    const {openConfirmationModal} = this.state
    const vpsSubscriptions = get(client, 'vpsSubscriptions')

    if (dataLoading) return <Loading speed={1} style={{padding: '20px'}}/>

    if (isEmpty(vpsSubscriptions) || vpsSubscriptions.length === 0 || !vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)) {
      return (
        <div id="t-client-accounts">
          No VPS.
        </div>
      )
    }

    const vpsSubscription = vpsSubscriptions.length === 1 ? vpsSubscriptions[0] : vpsSubscriptions.find(s => s.status !== vpsSubscriptionStatuses.deleted.value)
    if (isEmpty(vpsSubscription)) {
      return (
        <div id="t-client-accounts">
          No VPS.
          <Row>
            <Col xs={12}>
              There is an issue to create a new VPS Subscription. Please contact IT.
            </Col>
          </Row>
        </div>
      )
    }

    const vmInfo = safeParseJSON(vpsSubscription.vms)
    if (isEmpty(vmInfo)) {
      return (
        <div id="t-client-accounts">
          No VPS.
          <Row>
            <Col xs={12}>
              VPS Subscription request is sent. It might take a few minutes to process it.
            </Col>
          </Row>
        </div>
      )
    }
    const subscriptionDetails = safeParseJSON(vpsSubscription.meta)

    return (
      <div id="t-client-accounts">
        {openConfirmationModal && this.renderConfirmationModal()}
        {canCancelVpsSubscription(viewer) && <Row>
          <Col xs={12}>
            <ButtonToolbar className="pull-right">
              {!isEmpty(vpsSubscription.serviceId) && <Button
                id="t-client-vps-subscription-cancel"
                onClick={() => this.openConfirmationModal('cancel', vpsSubscription)}
                bsStyle="primary"
                disabled={dataLoading}
              >
                {dataLoading && <FontAwesomeIcon icon={'spinner'} className="fa-spin" />}
                Cancel
              </Button>}

              {!isEmpty(vpsSubscription.serviceId) && <Button
                id="t-client-vps-subscription-terminate"
                onClick={() => this.openConfirmationModal('terminate', vpsSubscription)}
                bsStyle="primary"
                disabled={dataLoading}
              >
                {dataLoading && <FontAwesomeIcon icon={'spinner'} className="fa-spin" />}
                Terminate
              </Button>}
            </ButtonToolbar>
          </Col>
        </Row>}
        <hr />
        <Row key={vpsSubscription.id} className={`${style.vpsSubscription} t-client-account`}>
          <Col xs={12}>
            <dl className="dl-horizontal">
              <dt>VPS Plan</dt>
              <dd className={'t-client-vps-plan-name'} >{vpsSubscriptionPlans[vpsSubscription.planName].label}</dd>

              <dt>VPS Type</dt>
              <dd className={'t-client-vps-type'} key={2}>{vpsSubscription.type}</dd>
            </dl>
            <dl className="dl-horizontal">
              <dt>Service Id</dt>
              <dd className={'t-client-vps-service-id'} key={2}>{vpsSubscription.serviceId}</dd>
            </dl>
            <dl className="dl-horizontal">
              <dt id="t-client-vps-subscription-details">VM Status</dt>
              <dd>{vmInfo.status ? 'Running' : 'Stopped'}</dd>
            </dl>
            <dl className="dl-horizontal">
              <dt id="t-client-vps-subscription-details">VM IP</dt>
              <dd>IP: {Object.values(vmInfo.ips).join()}</dd>
            </dl>
            {vpsSubscription.createdAt && <dl className="dl-horizontal">
              <dt id="t-client-vps-subscription-details">Created At</dt>
              <dd>{readableDate(moment(vpsSubscription.createdAt))}</dd>
            </dl>}
            {subscriptionDetails.cancelled_at && <dl className="dl-horizontal">
              <dt id="t-client-vps-subscription-details">Cancelled At</dt>
              <dd> {readableDate(moment(subscriptionDetails.cancelled_at))}</dd>
            </dl>}
            {vpsSubscription.deactivatedAt && <dl className="dl-vps-service-dates">
              <dt>Deactivated at:</dt>
              <dd>{vpsSubscription.deactivatedAt}
              </dd>
            </dl>}
          </Col>
        </Row>
      </div>
    )
  }
}

export default provideProps()(VPS)
