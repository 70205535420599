import moment from 'moment'
import {head, find, get} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {yesNo, generateKeys} from '@bdswiss/common-enums'

export const showEnum = generateKeys({
  documents: {
    label: 'Documents',
  },
  profileChanges: {
    label: 'Profile Changes',
  },
  appropTests: {
    label: 'Approp Tests',
  },
  dueDiligences: {
    label: 'Due Diligences',
  },
})

function initDocuments(documents) {
  for (const document of documents) {
    document.createdAt = moment(document.createdAt)
    document.createdAt.isValid()
    const expiration = moment(document.expiration)
    document.expiration = expiration.isValid() ? expiration : null
  }
  return documents
}

function initProfileChanges(profileChanges) {
  for (const profileChange of profileChanges) {
    profileChange.createdAt = moment(profileChange.createdAt)
    profileChange.createdAt.isValid()
  }
  return profileChanges
}

function initAppropTests(appropTests) {
  for (const appropTest of appropTests) {
    appropTest.createdAt = moment(appropTest.createdAt)
    appropTest.createdAt.isValid()
  }
  return appropTests
}

function initDueDiligences(dueDiligences) {
  for (const dueDiligence of dueDiligences) {
    dueDiligence.createdAt = moment(dueDiligence.createdAt)
    dueDiligence.createdAt.isValid()
  }
  return dueDiligences
}

export const documentsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {show, documentStatus, profileChangeStatus, appropTestStatus, countriesFilter, page,
      companyFilter, dateFilters, documentTypesFilter, documentSalesAgentFilter,
      clientTypesFilter, depositorsFilter, kycStatusFilter, topVipFilter, tradingStatusesFilter,
      assigneeFilter, dueDiligenceStatus, partnersAgentFilter, hasPendingWithdrawalsFilter, priorityProfileChange,
      profileChangeCountry, documentSearch} = props

    const countriesCondition = countriesFilter ? `countries: [${countriesFilter.join(', ')}]` : ''
    const companyCondition = companyFilter ? `company: ${companyFilter}` : ''
    const kycStatusCondition = head(kycStatusFilter) ? `kycStatuses: [${kycStatusFilter.join(', ')}]` : ''
    const createDateFromCondition = dateFilters && dateFilters.createdFrom ?
      `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : ''
    const createDateToCondition = dateFilters && dateFilters.createdTo ?
      `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : ''
    const expirationFromCondition = dateFilters && dateFilters.expirationFrom ?
      `expirationTimeFrom: "${dateFilters.expirationFrom.toISOString()}"` : ''
    const expirationToCondition = dateFilters && dateFilters.expirationTo ?
      `expirationTimeTo: "${dateFilters.expirationTo.toISOString()}"` : ''
    const lastUpdatedCondition = dateFilters && dateFilters.lastUpdated ?
      `lastUpdated: "${dateFilters.lastUpdated.toISOString()}"` : ''
    const assigneeCondition = assigneeFilter ? `assignee: ${assigneeFilter}` : ''

    let query
    switch (show) {
      case showEnum.documents.key: {
        const conditions = [
          documentStatus ? `status: ${documentStatus}` : '',
          countriesCondition,
          companyCondition,
          createDateToCondition,
          createDateFromCondition,
          expirationFromCondition,
          expirationToCondition,
          documentSalesAgentFilter ? `salesAgentId: ${documentSalesAgentFilter}` : '',
          head(documentTypesFilter) ? `types: [${documentTypesFilter}]` : '',
          head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
          depositorsFilter ? `isDepositor: ${depositorsFilter}` : '',
          topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : '',
          hasPendingWithdrawalsFilter ? `hasPendingWithdrawals: ${get(find(yesNo, {value: hasPendingWithdrawalsFilter}), 'boolValue')}` : '',
          head(tradingStatusesFilter) ? `tradingStatuses: [${tradingStatusesFilter}]` : '',
          kycStatusCondition,
          assigneeCondition,
          partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
          documentSearch ? `searchDocId: "${documentSearch}"` : '',
        ].join('\n').trim()

        query = `{
          documents(
            ${conditions}
            limit: ${getPageSize()}
            offset: ${getOffset(page)}
            orderBy: id
            orderDirection: descending
          ) {
            id
            type
            url
            expiration
            autoProcessingResult
            status
            rejectionReason
            assignee {
              id
              firstName
              lastName
            }
            client {
              id
              firstName
              lastName
              company
              topVip
              address {
                country
              }
            }
            createdAt
            pendingWithdrawals {
              id
            }
          }
          documentsCount(estimated: false ${conditions})
        }`
        break
      }
      case showEnum.profileChanges.key: {
        const conditions = [
          profileChangeStatus ? `status: ${profileChangeStatus}` : '',
          countriesCondition,
          companyCondition,
          createDateToCondition,
          createDateFromCondition,
          documentSalesAgentFilter ? `salesAgentId: ${documentSalesAgentFilter}` : '',
          head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
          topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : '',
          partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
          priorityProfileChange ? 'priorityProfileChange: true' : '',
          profileChangeCountry ? `toOrFromCountry: ${profileChangeCountry}` : '',
        ].join('\n').trim()

        query = `{
          profileChanges(
            ${conditions}
            limit: ${getPageSize()}
            offset: ${getOffset(page)}
            orderBy: id
            orderDirection: descending
          ) {
            id
            status
            comments
            client {
              id
              firstName
              lastName
              company
              topVip
              address {
                country
              }
            }
            createdAt
          }
          profileChangesCount(estimated: false ${conditions})
        }`
        break
      }
      case showEnum.appropTests.key: {
        const conditions = [
          appropTestStatus ? `status: ${appropTestStatus}` : '',
          countriesCondition,
          companyCondition,
          createDateToCondition,
          createDateFromCondition,
          lastUpdatedCondition,
          documentSalesAgentFilter ? `salesAgentId: ${documentSalesAgentFilter}` : '',
          head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
          kycStatusCondition,
          topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : '',
          partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
        ].join('\n').trim()

        query = `{
          appropTests(
            ${conditions}
            limit: ${getPageSize()}
            offset: ${getOffset(page)}
            orderBy: id
            orderDirection: descending
          ) {
            id
            status
            client {
              id
              firstName
              lastName
              company
              topVip
              address {
                country
              }
            }
            createdAt
          }
          appropTestsCount(estimated: false ${conditions})
        }`
        break
      }
      case showEnum.dueDiligences.key: {
        const conditions = [
          dueDiligenceStatus ? `status: ${dueDiligenceStatus}` : '',
          countriesCondition,
          companyCondition,
          createDateToCondition,
          createDateFromCondition,
          lastUpdatedCondition,
          head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
          documentSalesAgentFilter ? `salesAgentId: ${documentSalesAgentFilter}` : '',
          partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
        ].join('\n').trim()

        query = `{
          dueDiligences(
            ${conditions}
            limit: ${getPageSize()}
            offset: ${getOffset(page)}
            orderBy: id
            orderDirection: descending
          ) {
            id
            status
            client {
              id
              firstName
              lastName
              company
              address {
                country
              }
            }
            createdAt
          }
          dueDiligencesCount(estimated: false ${conditions})
        }`
        break
      }
      default:
        console.warn('Unexpected show property ', show) // eslint-disable-line no-console
        return
    }

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Documents/Profile changes/Approp tests/Due Diligences loaded',
    (state, res) => ({
      ...state,
      documents: res.documents != null
        ? initDocuments(res.documents)
        : state.documents || [],
      documentsCount: res.documentsCount != null
        ? res.documentsCount
        : state.documentsCount || 0,
      profileChanges: res.profileChanges != null
        ? initProfileChanges(res.profileChanges)
        : state.profileChanges || [],
      profileChangesCount: res.profileChangesCount != null
        ? res.profileChangesCount
        : state.profileChangesCount || 0,
      appropTests: res.appropTests != null
        ? initAppropTests(res.appropTests)
        : state.appropTests || [],
      appropTestsCount: res.appropTestsCount != null
        ? res.appropTestsCount
        : state.appropTestsCount || 0,
      dueDiligences: res.dueDiligences != null
        ? initDueDiligences(res.dueDiligences)
        : state.dueDiligences || [],
      dueDiligencesCount: res.dueDiligencesCount != null
        ? res.dueDiligencesCount
        : state.dueDiligencesCount || 0,
    }),
    [res]
  ),

}

export const documentsTopVipCountProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const query = `{
      documentsTopVipCount {
        count
        status
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Documents Vip Count loaded',
    (state, res) => ({
      ...state,
      documentsTopVipCount: res.documentsTopVipCount != null
        ? res.documentsTopVipCount
        : state.documentsTopVipCount || 0
    }),
    [res]
  ),
}
