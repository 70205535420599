import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import {List as Loading} from 'react-content-loader'
import {filter, get} from 'lodash'
import {vpsSubscriptionPlans} from '@bdswiss/common-enums'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
class CreateVpsSubscription extends PureComponent {

  static propTypes = {
    onClose: PropTypes.func.isRequired,
  };

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  state = {
    created: false,
    values: {},
    dataFetched: false,
    dataError: '',
    dataLoading: false,
  }

  componentDidMount() {
    const {dataFetched, dataError, dataLoading} = this.state
    if (!dataLoading && !dataError && !dataFetched) {
      this.setState({dataLoading: true}, () => {
        Promise.all([
          this.context.clientProvider.subProviders.vpsSubscriptions.fetch(),
        ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  createVpsSubscription = () => {
    const {values} = this.state
    const {client, onClose} = this.props

    return this.props.actions.client.createVpsSubscription(
      client.personalDetails.id,
      values.vpsPlanName
    )
      .then(() => {
        this.setState({created: true})
        this.context.clientProvider.subProviders.vpsSubscriptions.fetch()
      })
      .catch((e) => {
        this.setState({created: false})
        this.context.logError(e)
      })
      .then(onClose)
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    this.setState({values})
  }

  renderCreateVpsSubscriptionForm() {
    const {onClose} = this.props
    const {created, values} = this.state
    const selected = values && values.vpsPlanName ? values.vpsPlanName : ''

    const vpsPlans = filter(vpsSubscriptionPlans, (a) => a.isFree.includes('raw_pro'))

    return (
      <Card
        id="t-client-create-vps-subscription-sidebar"
        key={1}
        className="panel-short"
        header={<div><strong>Create VPS Subscription</strong></div>}
      >
        <Row>
          <Col md={12} xc={12}>
            <StylishSelect.Input
              id="t-vps-summary-plans-filter"
              placeholderText="Choose Plan"
              options={StylishSelect.enumToStylishOptions(vpsPlans)}
              value={selected}
              highlightIfActive
              onChange={(e) => this.formValueChanged('vpsPlanName', e.value)}
              label='VPS Subscription Plan'
            />
          </Col>
        </Row>
        <Row>
          <Col md={12} xs={12}>
            <ButtonToolbar className="pull-right">
              <Button
                id="t-client-create-vps-subscription-close"
                tabIndex={-1}
                onClick={onClose}
              >Close
              </Button>
              <Button
                id="t-client-vps-subscription-generate"
                bsStyle="primary"
                onClick={() => this.createVpsSubscription()}
                disabled={created}
              >
                {created && <FontAwesomeIcon icon={'spinner'} className="fa-spin" />}
                {created ? 'Creating' : 'Create VPS subscription'}
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
      </Card>
    )
  }

  render() {
    const {client, dataLoading} = this.props
    const accounts = get(client, 'accounts')

    if (!accounts || dataLoading) return <Loading speed={1} style={{padding:'20px'}}/>

    return (
      <Row>
        <Col xs={12}>
          {this.renderCreateVpsSubscriptionForm()}
        </Col>
      </Row>
    )
  }
}

export default provideProps()(CreateVpsSubscription)
