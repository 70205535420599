/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {Modal, Button, ButtonToolbar, Form, Row, Col} from 'react-bootstrap'
import StylishSelect from '../components/StylishSelect'
import {languages} from '@bdswiss/common-enums'
import {map, split, isEmpty, reject, includes} from 'lodash'
import PushNotification from './PushNotification'
import {putFile} from '../utils/net'
import {provideProps, compose} from '../decorators'
import style from './clients.module.scss'

const defaultForm = () => ({
  type: 'marketing',
  pushNotifications: [emptyMessage()],
  inputType: 'manual',
  manualClientIDs: [],
  fallbackLanguage: '',
  file: null
})

const emptyMessage = () => ({
  language: '',
  title: 'Title',
  body: '',
})

class PushNotificationsModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    sendPushNotifications: PropTypes.func.isRequired,
  }

  static contextTypes = {
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState(defaultForm())
  }

  inputClientIDs(value) {
    const clientIdsArray = value !== '' ? split(value, ',') : []
    this.setState({manualClientIDs: clientIdsArray})
  }

  inputCSVFile(files) {
    this.setState({file: files[0]})
  }

  inputFallbackLanguage(value) {
    this.setState({fallbackLanguage: value})
  }

  valueChanged(index, values) {
    const {pushNotifications = []} = this.state
    pushNotifications[index] = values
    this.setState({pushNotifications: [...pushNotifications]})
  }

  addLanguage() {
    this.setState({pushNotifications: [...this.state.pushNotifications, emptyMessage()]})
  }

  removeLanguage(key) {
    const newPushNotifications = reject(this.state.pushNotifications, (message, index) => {
      if (index === key) {
        return message
      }
    })
    this.setState({pushNotifications: [...newPushNotifications]})
  }

  sendWithFileInput() {
    const {fallbackLanguage, pushNotifications, type} = this.state

    return this.props.signPushNotificationFileUrl()
      .then((res) => {
        const {csvSignedUrl, csvPlainUrl} = res.signPushNotificationFileUrl
        return putFile(this.state.file, csvSignedUrl)
          .then(() => {
            const args = {
              csvLink: csvPlainUrl,
              defaultLanguage: fallbackLanguage,
              messages: pushNotifications,
              type: type,
            }
            this.props.sendPushNotifications(args)
              .then((res) => {
                this.setState(defaultForm())
                this.context.showNotification({
                  title: 'Push Notifications',
                  message: 'Push Notifications successfully queued for sending. You will receive an email when it is successfully sent', // eslint-disable-line max-len
                  position: 'tr',
                  level: 'success',
                })
                return this.props.onHide()
              })
              .catch(this.context.logError)
          })
      })
  }

  sendWithFilterOrManualInput() {
    const {manualClientIDs, fallbackLanguage, pushNotifications, type} = this.state
    const {searchParams} = this.props

    const sanitizedManualClientIDs = map(manualClientIDs, (id) => Number(id.trim()))

    const args = {
      clientIds: !isEmpty(sanitizedManualClientIDs) ? sanitizedManualClientIDs : [],
      searchParams: isEmpty(sanitizedManualClientIDs) ? searchParams : undefined,
      defaultLanguage: fallbackLanguage,
      messages: pushNotifications,
      type: type,
    }

    return this.props.sendPushNotifications(args)
      .then((res) => {
        this.setState(defaultForm())
        this.context.showNotification({
          title: 'Push Notifications',
          message: 'Push Notifications successfully queued for sending. You will receive an email when it is successfully sent', // eslint-disable-line max-len
          position: 'tr',
          level: 'success',
        })
        return this.props.onHide()
      })
      .catch(this.context.logError)
  }

  handleSubmit() {
    const {pushNotifications} = this.state
    const emptyMessage = map(pushNotifications, (message) => !message.body || !message.language)

    if (!isEmpty(pushNotifications) && !includes(emptyMessage, true)) {
      if (this.state.inputType === 'file') {
        this.sendWithFileInput()
      } else {
        this.sendWithFilterOrManualInput()
      }
    } else {
      this.context.showNotification({
        title: 'Push Notifications',
        message: 'Push Notification cannot be sent with no content. Please fill in the message content', // eslint-disable-line max-len
        position: 'tr',
        level: 'error',
      })
    }
  }

  renderButtonLabel() {
    const {inputType, manualClientIDs} = this.state
    switch (inputType) {
      case 'manual':
        return `Send Now to ${manualClientIDs.length} client(s)`
      case 'filter':
        return `Send Now to ${this.props.clientsCount} client(s)`
      default:
        return 'Send Now'
    }
  }

  render() {
    const {pushNotifications, manualClientIDs, inputType} = this.state
    const {show, onHide} = this.props

    return (
      <Modal
        show={show}
        onHide={onHide}
        className={style.pushNotificationModalContent}
      >
        <Modal.Header closeButton>
          <Modal.Title> Marketing Push Notifications </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{minWidth: 900}}>
          <Row className="mb-2">
            <Col xs={4}>
              <Form.Check
                type="radio"
                name="inputType"
                label="Filter Input"
                checked={this.state.inputType === 'filter'}
                onChange={(e) => this.setState({inputType: 'filter'})}
              />
            </Col>
            <Col xs={4}>
              <Form.Check
                type="radio"
                name="inputType"
                label="Manual Input"
                checked={this.state.inputType === 'manual'}
                onChange={(e) => this.setState({inputType: 'manual'})}
              />
            </Col>
            <Col xs={4}>
              <Form.Check
                type="radio"
                name="inputType"
                label="File Input"
                checked={this.state.inputType === 'file'}
                onChange={(e) => this.setState({inputType: 'file'})}
              />
            </Col>
          </Row>
          {inputType === 'manual' && <Form.Control
            type="text"
            label="Client IDs"
            value={manualClientIDs}
            placeholder={'Enter client IDs seperated by commas(,)'}
            onChange={(e) => this.inputClientIDs(e.target.value)}
            className="mb-2"
          />}
          {inputType === 'file' && <Form.Control
            type="file"
            onChange={(e) => this.inputCSVFile(e.target.files)}
            className="mb-2"
          />}
          <StylishSelect.Input
            label="Fallback Language"
            variant={!this.state.fallbackLanguage ? 'error' : ''}
            value={this.state.fallbackLanguage}
            options={StylishSelect.enumToStylishOptions(languages)}
            onChange={(e) => this.inputFallbackLanguage(e.value)}
          />
          {map(pushNotifications, (notification, key) => {
            const {language, body, title} = notification
            return (
              <PushNotification
                key={key}
                language={language}
                text={body}
                title={title}
                notificationId={key}
                onChange={(index, values) => this.valueChanged(index, values)}
                removeLanguage={() => this.removeLanguage(key)}
              />
            )
          })}
          <a href="#" onClick={() => {this.addLanguage()}}> Add Another Language </a>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button
              variant="success"
              size="sm"
              className="mr-1"
              onClick={() => this.handleSubmit()}
            >
              {this.renderButtonLabel()}
            </Button>
            <Button
              variant="warning"
              size="sm"
              onClick={onHide}
            >
              Close
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  provideProps((state) => ({
    ...state,
  })),
)(PushNotificationsModal)
