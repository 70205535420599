import moment from 'moment'
import {setIn} from '../stateUtils'
import {getFetchInterval, getPageSize} from '../useful'

const initAppointments = (appointments = []) => appointments.map((a) => {
  const start = moment(a.start)
  start.isValid()
  return {
    ...a,
    date: start,
  }
})

const concatAppointments = (appointments = [], props) => props.offset > 0
  ? [...props.appointments, ...appointments]
  : appointments

export const appointmentsProvider = {
  fetchInterval: getFetchInterval(),

  getQuery: (props) => {
    const {startFrom, startTo, isNew, userId, offset = 0} = props
    let conditions = `orderBy: start\nlimit: ${getPageSize()}\noffset: ${offset}`
    conditions = startFrom ? `${conditions}\nstartFrom: "${startFrom}"` : conditions
    conditions = startTo ? `${conditions}\nstartTo: "${startTo}"` : conditions
    conditions = isNew ? `${conditions}\nisNew: ${isNew}` : conditions
    conditions = userId ? `${conditions}\nuserId: [${userId}]` : conditions
    conditions = conditions ? `(\n${conditions}\n)` : ''
    const query = `{
      appointments ${conditions} {
        id
        start
        color
        addToGoogleCalendar
        category
        description
        client {
          id
          firstName
          lastName
          company
        }
        user {
          id
          firstName
          lastName
          avatar
        }
        isNew
      }
    }`
    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Appointments loaded',
    (state, res) => setIn(state, ['appointments'], initAppointments(concatAppointments(res.appointments, props)), true),
    [res]
  ),
}

export const appointmentsCountProvider = {
  fetchInterval: getFetchInterval(),

  getQuery: (props) => {
    const {startFrom, startTo, isNew, userId} = props
    let conditions = ''
    conditions = startFrom ? `${conditions}\nstartFrom: "${startFrom}"` : conditions
    conditions = startTo ? `${conditions}\nstartTo: "${startTo}"` : conditions
    conditions = isNew ? `${conditions}\nisNew: ${isNew}` : conditions
    conditions = userId ? `${conditions}\nuserId: [${userId}]` : conditions
    conditions = conditions ? `(\n${conditions}\n)` : ''
    const query = `{      
        appointmentsCount ${conditions}
    }`
    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Appointments count loaded',
    (state, res) => setIn(state, ['appointmentsCount'], res.appointmentsCount, true),
    [res]
  ),
}

export const viewerAppointmentsCountProvider = {
  fetchInterval: getFetchInterval(),

  getQuery: (props) => {
    const query = `{
      viewer {
        ... on User {
          appointmentsCount(
            isNew: true
            isDismissed: false
            startFrom: "${moment().startOf('day').toISOString()}",
            startTo: "${moment().endOf('day').toISOString()}",
          )
        }
      }
      appointmentsByDay (
        timezone: "${new window.Intl.DateTimeFormat().resolvedOptions().timeZone}"
        userId: [${props.viewer.id}]
      ) {
        day
        count
        new
        dismissed
      }
    }`
    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Viewer appointments count loaded',
    (state, res) => {
      const newState = setIn(state, ['viewer', 'appointmentsCount'], res.viewer.appointmentsCount, true)
      return setIn(newState, ['appointmentsByDay'], res.appointmentsByDay, true)
    },
    [res]
  ),
}

export const appointmentsByDayProvider = {
  fetchInterval: getFetchInterval(),

  getQuery: (props) => {
    const {userId} = props
    let conditions = `timezone: "${new window.Intl.DateTimeFormat().resolvedOptions().timeZone}"`
    conditions = userId ? `${conditions}\nuserId: [${userId}]` : conditions
    conditions = conditions ? `(\n${conditions}\n)` : ''
    const query = `{
      appointmentsByDay ${conditions} {
        day
        count
        new
        dismissed
      }
    }`
    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'AppointmentsByDay loaded',
    (state, res) => setIn(state, ['appointmentsByDay'], res.appointmentsByDay, true),
    [res]
  ),
}
