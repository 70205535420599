import React from 'react'
import {get, isEmpty, map} from 'lodash'
import {Row, Col, Table, Card, Container, Button} from 'react-bootstrap'
import {provideProps} from '../decorators'
import style from './accounting.module.scss'
import {getFormattedAmount} from '../useful'
import DateTime from '../components/DateTime'
import {safeParseJSON} from '../common/utils'
import {getAllowedCompanies} from '../utils/general'
import StylishSelect from '../components/StylishSelect'
import CheckoutReconciliationModal from '../components/CheckoutReconciliationModal'

const ResultRow = ({result, onClickResult}) => {
  const {id, period_start: periodStart, period_end: periodEnd, date, carriedForwardAmount, currentPeriodAmount,
    netAmount, currency, payoutDate, payoutStartDate, payoutEndDate, payoutId, status, payoutFee,
  } = result
  return (
    <tr key={id}>
      <td>{id}</td>
      <td>{periodStart}</td>
      <td>{periodEnd}</td>
      <td>{date}</td>
      <td>{currency}</td>
      <td>{getFormattedAmount({currency, amount: carriedForwardAmount})}</td>
      <td>{getFormattedAmount({currency, amount: currentPeriodAmount})}</td>
      <td>{getFormattedAmount({currency, amount: netAmount})}</td>
      <td>{payoutDate}</td>
      <td>{payoutStartDate}</td>
      <td>{payoutEndDate}</td>
      <td>{payoutId}</td>
      <td>{status}</td>
      <td>{getFormattedAmount({currency, amount: payoutFee})}</td>
      <td>
        <Button
          className="btn-xs"
          variant="outline-secondary"
          onClick={(e) => onClickResult(result)}
        >Data</Button>
      </td>
    </tr>
  )
}

class CheckoutReconciliation extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      showResultModal: false,
      showingResult: {},
      valid: false,
      count: 0,
      data: [],
      form: {}
    }
  }

  applyFilter(key, value) {
    this.setState((state) => ({
      form: {
        ...state.form,
        [key]: value
      }}
    ), () => {
      const {form} = this.state
      this.setState({valid: !!(form.fromDate && form.toDate && form.company)})
    })
  }

  onSearch(link, download) {
    const {form} = this.state

    if ((form.fromDate && form.toDate) || link) {
      this.setState({loading: true})
      let data = {company: form.company}
      if (!link) {
        data = form
        if (download) {
          data = {...data, download}
        }
      }
      this.props.actions.accounting.checkoutSearch(data, link).then((res) => {
        if (download) {
          const csvContent = 'data:text/csv;charset=utf-8,\ufeff' + res.checkoutSearch
          const data = encodeURI(csvContent)

          const link = document.createElement('a')
          link.href = data
          link.download = `statement-export-${new Date().toISOString()}.csv`

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)
          this.setState({loading: false})
        } else {
          const result = safeParseJSON(res.checkoutSearch)
          this.setState({
            loading: false,
            count: result.count,
            data: result.data,
            nextLink: get(result, '_links.next.href'),
          })
        }
      }).catch(() => {
        this.setState({
          loading: false,
          count: 0,
          data: [],
          nextLink: null,
        })
      })
    }
  }

  render() {
    const {viewer} = this.props
    const {loading, form: {fromDate, toDate, company}, valid, data, nextLink,
      showResultModal, showingResult,
    } = this.state

    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Row>
              <Col xs={2}>
                <span>From</span>
                <DateTime
                  closeOnSelect
                  timeFormat={false}
                  id="t-reconciliation-checkout-from-filter"
                  value={fromDate}
                  onChange={(value) => this.applyFilter('fromDate', value ? value.format('YYYY-MM-DD') : '')}
                />
              </Col>
              <Col xs={2}>
                <span>To</span>
                <DateTime
                  closeOnSelect
                  timeFormat={false}
                  id="t-reconciliation-checkout-to-filter"
                  value={toDate}
                  onChange={(value) => this.applyFilter('toDate', value ? value.format('YYYY-MM-DD') : '')}
                />
              </Col>
              <Col xs={3}>
                <span>Company</span>
                <StylishSelect
                  id="t-reconciliation-checkout-company-filter"
                  value={company}
                  options={StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer))}
                  onChange={(e) => this.applyFilter('company', e.value)}
                />
              </Col>
              <Col xs={5} className="mt-4">
                <Button
                  id="t-reconciliation-checkout-search-button"
                  variant="success"
                  className="float-right"
                  onClick={() => this.onSearch()}
                  disabled={!valid || loading}
                >
                  {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Search'}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="mb-4">
                {!isEmpty(data) && <Button
                  id="t-reconciliation-checkout-export-button"
                  variant="outline-secondary"
                  onClick={() => this.onSearch('', true)}
                  disabled={!valid || loading}
                >
                  {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                </Button>}
              </Col>
              <Col xs={6} className="mb-4">
                {nextLink && <Button
                  id="t-reconciliation-checkout-next-button"
                  variant="outline-secondary"
                  className="float-right"
                  onClick={() => this.onSearch(nextLink, false)}
                  disabled={!valid || loading}
                >
                  {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Next Page'}
                </Button>}
              </Col>
            </Row>
          </Col>
          <Col xs={12} className="mb-5">
            <Card>
              <Card.Body style={{maxHeight: '75vh', overflow: 'scroll'}}>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Statement ID</th>
                      <th>Period Start Date</th>
                      <th>Period End Date</th>
                      <th>Statement Date</th>
                      <th>Currency</th>
                      <th>Carried Forward Amount</th>
                      <th>Current Period Amount</th>
                      <th>Net Amount</th>
                      <th>Payout Date</th>
                      <th>Payout Start Date</th>
                      <th>Payout End Date</th>
                      <th>Payout ID</th>
                      <th>Status</th>
                      <th>Payout Fee</th>
                      <th> </th>
                    </tr>
                  </thead>
                  <tbody>
                    {map((data), (d, i) =>
                      <ResultRow
                        key={i}
                        result={d}
                        onClickResult={(result) => this.setState({showResultModal: true, showingResult: result})}
                      />
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <CheckoutReconciliationModal
            show={!!showResultModal}
            data={showingResult}
            onHide={() => this.setState({showResultModal: false, showingResult: {}})}
          />
        </Row>
      </Container>
    )
  }
}

export default provideProps()(CheckoutReconciliation)
