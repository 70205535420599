import {get} from 'lodash'
import {getFetchInterval} from '../useful'

export const translationProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (get(props.match, 'params.translationId') == null) {
      return null
    }

    const query = `{
      documentTranslation(id: ${get(props.match, 'params.translationId')}) {
        id
        translationType
        meta
        translator {
          id
        }
        client {
          id
        }
        document {
          fileDescription
          type
          id
          url
        }
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => {
    dispatch(
      'Translation loaded',
      (state, res) => ({
        ...state,
        translation: res.documentTranslation,
      }),
      [res]
    )
  },
}
