import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Card, ListGroup, Image, ButtonGroup, InputGroup} from 'react-bootstrap'
import {get} from 'lodash'
import LazyLoad from 'react-lazyload'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {getFullName, nameMatches, sanitizeId} from '../useful'
import {canWriteUsers, canChangeUserRoles, canModifyLeadDistributionSettings} from '@bdswiss/common-permissions'
import PureComponent from '../PureComponent'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import AssociateCampaignByLocationModal from './AssociateCampaignByLocationModal'
import style from './users.module.scss'
import {backendRequest} from '../utils/net'
import LogInAsUserModal from '../users/LogInAsUserModal'
import {UserTimestamp} from './components/UserTimestamp'

class User extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showLoginAsUserModal: false,
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
  }

  isSuperUser(loggedInUser, user) {
    return !user.roles.some(role => role.id === 'super_user') && loggedInUser.roles.includes('super_user')
  }

  handleLogInAsUserClick() {
    this.setState({showLoginAsUserModal: true})
  }

  confirmLoginAsUser(confirm, loginReason) {
    this.setState({showLoginAsUserModal: false})
    if (confirm && loginReason) {
      const {backendUrl} = this.context.config
      const {id} = this.props.user
      backendRequest(`${backendUrl}/auth/force-as/user`, {id: id, reason: loginReason})
        .then(response => response.json())
        .then(async (response) => {
          await backendRequest(response.backendUrl, {token: response.authtoken})
        }).finally(() => {
          localStorage.clear()
          window.location.reload()
        }).catch(this.context.logError)
    }
  }

  render() {
    const {user, active, viewer} = this.props
    const imagePlaceHolder = (<Image height={60} width={60} className={style.avatar} rounded />)
    return (
      <ListGroup.Item
        className={'t-list-users-item-' + sanitizeId(user.firstName + ' ' + user.lastName)}
        active={active}
        key={user.id}
        onClick={() => {
          this.props.history.push(`/users/${user.id}`)
          this.props.onClickUser(user.id)
        }}
        action
      >
        <Row>
          <Col xs={12}>
            <LazyLoad overflow once scroll throttle={100} placeholder={imagePlaceHolder}>
              <Image height={60} width={60} src={user.avatar} className={style.avatar} rounded />
            </LazyLoad>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={5}>
                    <h2 className={style.name}>{getFullName(user)}</h2>
                    <strong>User ID: {user.id}</strong><br/>
                    <strong>{user.jobTitle}</strong><br />
                    {canChangeUserRoles(this.props.viewer)
                      && user.roles.map((role) => role.name).sort((a, b) => a.localeCompare(b)).join(', ')}
                    <UserTimestamp label="Added by:" actor={user.createdBy} timestamp={user.createdAt} />
                    <UserTimestamp label="Updated by:" actor={user.updatedBy} timestamp={user.updatedAt} />
                  </Col>
                  <Col xs={5}>
                    <ul className="list-unstyled">
                      <li><FontAwesomeIcon icon="envelope" title="Email" />&nbsp;
                        <a href={`mailto:${user.email}`} onClick={(e) => e.stopPropagation()}>{user.email}</a>
                      </li>
                      <li><FontAwesomeIcon icon="phone" title="Phone" />&nbsp;
                        <a href={`tel:${user.phone}`} onClick={(e) => e.stopPropagation()}>{user.phone}</a>
                      </li>
                      <li><FontAwesomeIcon icon="skype" title="Skype" />&nbsp;{user.skype}</li>
                    </ul>
                  </Col>
                  <Col xs={2}>
                    <ul className="list-unstyled">
                      <LogInAsUserModal
                        user={user}
                        show={this.state.showLoginAsUserModal}
                        onCancel={() => this.confirmLoginAsUser(false)}
                        onConfirm={(loginReason) => this.confirmLoginAsUser(true, loginReason)}
                      />
                      <li>
                        {this.isSuperUser(viewer, user) ?
                          <Button
                            title="Login as"
                            size="sm"
                            variant='outline-secondary'
                            onClick={(e) => {
                              e.stopPropagation()
                              this.handleLogInAsUserClick()
                            }}
                          >Login as {user.firstName}</Button> : null
                        }
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </ListGroup.Item>
    )
  }
}

class List extends PureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  locationsToOptions(locations) {
    return [{
      label: 'All Locations',
      value: '',
    }].concat(Object.values(locations).map((location) => ({
      label: location.name,
      value: location.id,
    })))
  }

  renderFilters(filterLocation, locations, searchUsers, showInactiveUsers) {
    return (
      <Row className={style.toolbar}>
        <Col xs={3}>
          <StylishSelect.Input
            id="t-list-users-filter-location"
            value={filterLocation}
            variant={filterLocation ? 'success' : null}
            onChange={(e) => this.props.dispatch(
              'Filter locations',
              (state) => ({...state, filterLocation: Number(e.value)})
            )}
            placeholderText="All Locations"
            options={this.locationsToOptions(locations)}
            highlightIfActive
          />
        </Col>
        <Col xs={3}>
          <InputGroup>
            <Form.Control
              id="t-list-users-search"
              type="text"
              size="sm"
              placeholder="All Users"
              value={searchUsers || ''}
              isValid={!!searchUsers}
              onChange={(e) => this.props.dispatch(
                'Search user',
                (state) => ({...state, searchUsers: e.target.value})
              )}
            />
            <InputGroup.Append>
              <Button
                key={1}
                title="Clear"
                size="sm"
                variant={!!searchUsers ? 'outline-success' : 'outline-secondary'}
                onClick={
                  () => this.props.dispatch(
                    'Clear search',
                    (state) => ({...state, searchUsers: ''})
                  )
                }
              >
                <FontAwesomeIcon
                  title="Clear"
                  role="button"
                  className={style.clickable}
                  icon={searchUsers ? 'times' : 'search'}
                />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>

        <Col xs={1}>
          <Form.Check
            id="t-list-users-filter-active"
            label="Active"
            checked={!showInactiveUsers}
            onChange={(e) => this.props.dispatch(
              'Toggle active/inactive users',
              (state) => ({...state, showInactiveUsers: !e.target.checked})
            )}
          />
        </Col>
        <Col xs={5}>
          <ButtonGroup size="sm" className="float-right">
            {canModifyLeadDistributionSettings(this.props.viewer) &&
              <Button
                title="Associate Campaign By Location"
                id="t-associate-campaign-to-location"
                size="sm"
                variant="outline-secondary"
                onClick={() => this.props.dispatch(
                  'Showing Associate Campaigns By Location',
                  (state) => ({...state, showAssociateCampaignByLocationModal: true})
                )}
              >
                Campaign to Location
              </Button>
            }
            {canWriteUsers(this.props.viewer) &&
              <Button
                id="t-add-user"
                title="Add New User"
                size="sm"
                variant="outline-secondary"
                onClick={() => this.props.history.push('/users/new')}
              >
                New User
              </Button>
            }
          </ButtonGroup>
        </Col>
      </Row>
    )
  }

  render() {
    const {filterLocation, searchUsers, showInactiveUsers, users, locations, match} = this.props
    return (
      <div>
        {this.renderFilters(filterLocation, locations, searchUsers, showInactiveUsers)}
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                {[{id: NaN, name: 'None'}].concat(locations).map((location) => {
                  if (filterLocation && filterLocation !== location.id) {
                    return null
                  }
                  const filteredUsers = users.filter((user) =>
                    ((!user.location && isNaN(location.id)) || (user.location && user.location.id === location.id))
                      && (!showInactiveUsers === user.isActive)
                      && (!searchUsers || nameMatches(user, searchUsers))
                  )
                  if (!filteredUsers.length) {
                    return null
                  }
                  return (
                    <ListGroup key={location.id}>
                      {!filterLocation &&
                        <ListGroup.Item
                          id={'t-list-users-location-' + location.name.toLowerCase()}
                          className={style.locationTitle}
                        >
                          {location.name}
                        </ListGroup.Item>
                      }
                      {filteredUsers.map((user) => (
                        <User
                          key={user.id}
                          user={user}
                          dispatch={this.props.dispatch}
                          viewer={this.props.viewer}
                          active={Number(get(match, 'params.userId')) === user.id}
                          history={this.props.history}
                          onClickUser={this.props.onClickUser}
                        />
                      ))}
                    </ListGroup>
                  )
                })}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <AssociateCampaignByLocationModal
          show={this.props.showAssociateCampaignByLocationModal}
          onHide={() => this.props.dispatch(
            'Hiding Associate Campaigns By Location',
            (state) => ({...state, showAssociateCampaignByLocationModal: false})
          )}
        />
      </div>
    )
  }
}

export default provideProps(
  (state) => ({
    viewer: state.viewer,
    filterLocation: state.filterLocation,
    searchUsers: state.searchUsers,
    showInactiveUsers: state.showInactiveUsers,
    users: state.users,
    locations: state.locations,
    showAssociateCampaignByLocationModal: state.showAssociateCampaignByLocationModal,
  })
)(List)
