export default function create(dbClient) {
  return {
    cancelMarketingEmails(id) {
      const variables = {
        id: id,
      }

      const query = `
        mutation (
          $id: Int!,
        ) {
          cancelMarketingEmails (
          id: $id
          ) {
            id
            status
          }
        }
      `

      return dbClient.query(query, variables)
    },
  }
}
