import React from 'react'
import {map} from 'lodash'
import PropTypes from 'prop-types'
import {countries} from '@bdswiss/common-enums'
import {canQueryPaymentOptions} from '@bdswiss/common-permissions'
import {Breadcrumb, Row, Col, Card, Table, Form, Container, InputGroup, Button} from 'react-bootstrap'
import {events} from '../enums'
import style from './paymentOptions.module.scss'
import PureComponent from '../PureComponent'
import {LinkContainer} from 'react-router-bootstrap'
import {getAllowedCompanies} from '../utils/general'
import StylishSelect from '../components/StylishSelect'
import EditCountryPaymentOptions from './EditCountryPaymentOptions'
import {countryPaymentOptionsProvider, paymentOptionsProvider} from './providers'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class CountryPaymentOptions extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    countryPaymentOptionsProvider: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
    this.setState({currentCountry: null, inputCountry: null, searchCountries: ''})
  }

  fetchProvider() {
    this.context.countryPaymentOptionsProvider.fetch()
  }


  saveCountryPaymentOptions(rawPaymentOpts) {
    const {currentCountry: {key: country}} = this.state
    const {actions: {paymentOptions: {replaceCountryPaymentOptions}}} = this.props
    const paymentOpts = map(rawPaymentOpts,
      (opt, i) => ({id: opt.paymentOption.id, rank: i, popular: !!opt.popular}))
    return replaceCountryPaymentOptions(country, paymentOpts)
      .then(() => this.context.countryPaymentOptionsProvider.fetch())
      .catch(this.context.logError)
  }

  openDisplayCountryPaymentForm(paymentOption) {
    this.props.uiDispatch('Displaying Country Payment Form',
      (state) => ({
        ...state,
        showCountryPaymentForm: true,
        paymentOption,
      }))
  }

  closeDisplayCountryPaymentForm() {
    this.props.uiDispatch('Close Country Payment Form',
      (state) => ({
        ...state,
        showCountryPaymentForm: false,
        paymentOption: null,
      }))
  }

  countryPaymentOptionClick(currentCountry) {
    this.props.dispatch(
      'Current country',
      (state) => ({...state, currentCountrySearch: currentCountry})
    )
    this.setState({currentCountry: currentCountry})
  }

  renderCountryPaymentOption(countryOption) {
    const {value, key, label} = countryOption

    const {currentCountry} = this.state
    const activeTrStyle = currentCountry && currentCountry.key === key ? 'active' : ''
    return (
      <tr className={`t-countryPaymentOption ${activeTrStyle} ${style.clickable}`}
        key={key}
      >
        <td id={`countryPaymentOption-${value}`}>
          {
            // eslint-disable-next-line jsx-a11y/anchor-is-valid
            <a id={`country-${value}`} className="t-countryPaymentOption"
              onClick={() => this.countryPaymentOptionClick({key, label})}
            >
              {countryOption.label}
            </a>
          }
        </td>
      </tr>
    )
  }

  dispatchCompanySelection(e) {
    this.props.uiDispatch(
      'Filter companies',
      (state) => ({...state, companyFilter: e.value})
    )
  }

  render() {
    const {searchCountries, paymentOptions, countryPaymentOptions, viewer, companyFilter} = this.props
    const {currentCountry} = this.state
    const allCountries = {
      ...{
        defaultEU: {
          value: '##',
          key: '_default',
          label: 'Default',
        },
        defaultNonEU: {
          value: '_#',
          key: '_defaultNonEU',
          label: 'Default Non EU',
        },
      },
      ...countries,
    }
    const companyOptions = [
      {label: 'All Companies', value: undefined},
      ...StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer)),
    ]

    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/country-payment-options"
            active={false}
          >
            <Breadcrumb.Item>
              Country Payment Options
            </Breadcrumb.Item>
          </LinkContainer>
          <Breadcrumb.Item active>
            {currentCountry && currentCountry.label}
          </Breadcrumb.Item>
        </Breadcrumb>
        <Row>
          <Col xs={4}>
            <InputGroup className="mb-3">
              <Form.Control
                id="t-list-countries-search"
                type="text"
                value={searchCountries || ''}
                placeholder="Search countries"
                onChange={(e) => this.props.dispatch(
                  'Search country',
                  (state) => ({...state, searchCountries: e.target.value})
                )}
              />
              <InputGroup.Append>
                <Button
                  size="sm"
                  variant={searchCountries ? 'success' : 'outline-dark'}
                  onClick={() => this.props.dispatch(
                    'Clear search',
                    (state) => ({...state, searchCountries: ''})
                  )}
                >
                  <FontAwesomeIcon icon={searchCountries ? 'times' : 'search'} />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <Card>
              <Card.Header><h5>Countries</h5></Card.Header>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <tbody>
                    {map(allCountries, (c) => {
                      if (!searchCountries || c.label.toLowerCase().includes(searchCountries.toLowerCase())) {
                        return this.renderCountryPaymentOption(c)
                      }
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={8}>
            <Row>
              <Col xs={5}>
                {viewer.companies.length > 1 &&
                  <StylishSelect
                    id="t-list-clients-filter-company"
                    placeholderText="All Companies"
                    value={companyFilter}
                    options={companyOptions}
                    highlightIfActive
                    onChange={this.dispatchCompanySelection.bind(this)}
                    className={style.companySelect}
                  />
                }
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {!currentCountry
                  ? <Card><Card.Body>Select country from the list.</Card.Body></Card>
                  : <EditCountryPaymentOptions
                    currentCountry={currentCountry}
                    viewer={viewer}
                    paymentOptions={paymentOptions}
                    countryPaymentOptions={countryPaymentOptions}
                    onSaveCountryOptions={(paymentOpts) => this.saveCountryPaymentOptions(paymentOpts)}
                    companyFilter={companyFilter}
                  />
                }
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryPaymentOptions),

  uiMount((state) => ['ui', 'paymentoptions']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for sidebar',
      (state) => ({
        ...state,
      })
    )
  }),

  provideProps((state, uiState) => {
    const {countryPaymentOptions, currentCountry, currentCountrySearch, searchCountries, paymentOptions} = state
    const {showCountryPaymentForm, companyFilter} = uiState
    return ({
      currentCountrySearch,
      countryPaymentOptions,
      showCountryPaymentForm,
      currentCountry,
      searchCountries: searchCountries || '',
      paymentOptions,
      companyFilter,
    })
  }),

  mountDataProviders({countryPaymentOptionsProvider, paymentOptionsProvider}),
)(CountryPaymentOptions)
