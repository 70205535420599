/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty, get} from 'lodash'
import {Row, Card, Col, Button, ButtonGroup} from 'react-bootstrap'
import ReactAudioPlayer from 'react-audio-player'
import classnames from 'classnames'
import moment from 'moment'
import {telephonyProviders} from '@bdswiss/common-enums'
import {canUpdateLastEffectiveCall} from '@bdswiss/common-permissions'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {getFullName, readableDate} from '../useful'
import {compose, uiMount} from '../decorators'
import 'react-datetime/css/react-datetime.css'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'

class CallLog extends PureComponent {

  state = {}

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    calls: PropTypes.array,
    callRecordingsUri: PropTypes.object.isRequired,
    uiDispatch: PropTypes.func.isRequired,
    uiState: PropTypes.object.isRequired,
    onCreate: PropTypes.func,
  };

  componentWillReceiveProps(nextProps) {
    const {isActive} = nextProps
    const {dataLoading, dataFetched, dataError} = this.state
    if (isActive && !dataLoading && !dataFetched && !dataError && !nextProps.calls) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.calls.fetch()
          .then(() => this.setState({dataLoading: false, dataFetched: true, editMode: false}))
          .catch((e) => this.setState({dataLoading: false, dataError: e, dataFetched: true, editMode: false}))
      })
    }
  }

  renderCall(call, i) {
    const {callRecordingsUri} = this.props
    const {durationTotal, durationBillable, provider} = call
    const recordingUrl = get(callRecordingsUri, provider || telephonyProviders.impactech.value)
    const totalDurationTitle = durationTotal > 60 ? `${moment.duration(durationTotal).asMinutes().toFixed(2)} min` : undefined
    const billablelDurationTitle = durationBillable > 60 ? `${moment.duration(durationBillable).asMinutes().toFixed(2)} min` : undefined
    const status = call.disposition || (durationBillable > 0 ? 'answered' : 'unknown')
    return (
      <Row className={classnames([style.event, style.outgoingCall])} key={`call-${i}`}>
        <Col xs={12}>
          <h2 className={style.title} style={{display: 'flex', alignItems: 'baseline'}}>
            <div style={{flex: 1}} title={readableDate(call.createdAt)}>
              {getFullName(call.user)}&nbsp;{<small>{call.createdAt.fromNow()}</small>}
            </div>
            <div>
              {
                durationTotal &&
                  <div className={style.callDuration}>
                    <small title={totalDurationTitle} ><strong>Total</strong>{` ${durationTotal}s`}</small>
                  </div>
              }
              {
                durationBillable &&
                  <div className={style.callDuration}>
                    <small title={billablelDurationTitle}><strong>Billed</strong>{` ${durationBillable}s`}</small>
                  </div>
              }
            </div>
          </h2>

          <div className={style.noteText}>
            <small>Phone: <b>{call.phoneNumber}</b> / Status: <b>{ status }</b> / Provider: <b>{ call.provider }</b></small>
            <p style={{marginTop: 5}}>{call.notes}</p>
            {
              call.recording &&
              (<ReactAudioPlayer
                src={`${recordingUrl}${call.recording}`}
                preload="none"
                controls
              />)
            }
          </div>
        </Col>
      </Row>
    )
  }

  editLastEffectiveCall() {
    const {editMode, newLastEffectiveCall} = this.state
    const {actions, clientId, lastEffectiveCall} = this.props

    const updated = newLastEffectiveCall !== lastEffectiveCall
      || !isEmpty(newLastEffectiveCall)

    if (editMode && updated) {
      actions.updateLastEffectiveCall({
        clientId: clientId,
        lastEffectiveCall: newLastEffectiveCall
      }).then((res) => {
        this.context.clientProvider.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch((error) => {
        this.context.logError(error)
      })
    }

    this.setState({editMode: !this.state.editMode})
  }

  renderSavePhoneButton(title, onClick) {
    return (
      <Button
        className={style.actionButton}
        variant="outline-secondary"
        title={title}
        onClick={onClick.bind(this)}
      >
        <FontAwesomeIcon icon="save" />
      </Button>
    )
  }

  renderCancelEditPhone(editValue, phoneVariable, phoneValue) {
    return (
      <Button
        className={style.actionButton}
        variant="outline-secondary"
        title="Cancel"
        onClick={() => {this.setState({[editValue]: false, [phoneVariable]: phoneValue})}}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    )
  }

  render() {
    const {calls, lastEffectiveCall, viewer} = this.props
    const {dataLoading} = this.state

    if (dataLoading || !calls) {
      return (
        <Row>
          Loading...
        </Row>
      )
    }

    const defaultDate = new Date('1970-01-01').toISOString()
    const latestEffectiveCall = new Date(lastEffectiveCall).toISOString()

    return (
      <div>
        <Row>
          <Col xs={12}>
            {this.state.editMode
              ? <Row>
                <Col xs={8}>
                  <DateTime
                    timeFormat
                    closeOnSelect
                    label="Last Effective Call"
                    onChange={(e) => this.setState({newLastEffectiveCall: e})}
                  />
                </Col>
                <Col xs={4} style={{marginTop: 28}}>
                  <ButtonGroup>
                    {this.renderSavePhoneButton('Save Last Effetive Call', this.editLastEffectiveCall)}
                    {this.renderCancelEditPhone('editMode', 'newLastEffectiveCall', latestEffectiveCall)}
                  </ButtonGroup>
                </Col>
              </Row>
              : !this.state.editMode && <Row>
                <Col xs={9}>
                  <p className={style.generalInfo}>
                    Last Effective Call: {defaultDate === latestEffectiveCall ? 'No Effective Call' : readableDate(moment(lastEffectiveCall))}
                  </p>
                </Col>
                <Col xs={3}>
                  {canUpdateLastEffectiveCall(viewer) &&
                    <a style={{cursor: 'pointer'}} onClick={() => this.editLastEffectiveCall()}>Edit</a>}
                </Col>
              </Row>
            }
            <br />
            {calls.length < 1 && <span>No Call Records Available</span>}
            <Card className={classnames(['panel-events'])}>
              <Card.Body>
                {calls.map((call, i) => this.renderCall(call, i))}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'callLogsUi']),
)(CallLog)
