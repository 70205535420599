import React from 'react'
import {Tab, Badge} from 'react-bootstrap'
import {depositStatuses, withdrawalStatuses, documentStatuses, profileChangeStatuses,
  jobStatuses, suitabilityTestScoreResult, paymentOptionStatuses,
} from '@bdswiss/common-enums'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

/*
 * See https://facebook.github.io/react/docs/jsx-in-depth.html#booleans-null-and-undefined-are-ignored
 * Returns false also for empty strings and arrays.
 * Does not check more complicated content like array of values that are all not rendered.
 */
export function isRendered(content) {
  return content != null && (typeof content !== 'boolean') && content.length !== 0
}

export function renderCloseTab(onClose) {
  return (
    <Tab
      tabClassName="close-tab"
      title={<FontAwesomeIcon icon="times" onClick={() => onClose && onClose()} />}
    />
  )
}

export function renderOnlineStatus(client, className) {
  return (
    <span
      id={`${client.id}_onlinestatus`}
      key={`${client.id}_onlinestatus`}
      title={`${client.isOnline ? 'Online' : 'Offline'}`}
      className={className}
    />
  )
}

export function getDepositStatusLabel(status) {
  let variant = 'secondary'
  switch (status.label) {
    case depositStatuses.incomplete.label :
    case depositStatuses.held.label :
    case depositStatuses.awaitingPayment.label :
      variant = 'warning'
      break
    case depositStatuses.completed.label :
      variant = 'success'
      break
    case depositStatuses.cancelled.label :
    case depositStatuses.failed.label :
    case depositStatuses.rejected.label :
      variant = 'danger'
      break
    case depositStatuses.chargeback.label :
    case depositStatuses.partiallyReversed.label :
    case depositStatuses.reversed.label :
      variant = 'info'
      break
    case depositStatuses.authorized.label :
    case depositStatuses.pending.label :
    default:
      variant = 'secondary'
      break
  }
  return <Badge pill variant={variant}>{status.label}</Badge>
}

export function getWithdrawalStatusLabel(status) {
  let variant = 'secondary'
  switch (status.label) {
    case withdrawalStatuses.processing.label :
    case withdrawalStatuses.accepted.label :
      variant = 'success'
      break
    case withdrawalStatuses.rejected.label :
      variant = 'danger'
      break
    case withdrawalStatuses.onHold.label :
    case withdrawalStatuses.pending.label :
      variant = 'warning'
      break
    case withdrawalStatuses.partiallyReversed.label :
    case withdrawalStatuses.reversed.label :
      variant = 'info'
      break
    case withdrawalStatuses.notProcessed.label :
    default:
      variant = 'secondary'
      break
  }
  return <Badge pill variant={variant}>{status.label}</Badge>
}

export function getDocumentStatusLabel(status) {
  let variant = 'secondary'
  switch (status.label) {
    case documentStatuses.accepted.label :
    case documentStatuses.autoVerificationAccepted.label :
    case 'Approved' :
      variant = 'success'
      break
    case documentStatuses.rejected.label :
    case documentStatuses.autoVerificationRejected.label :
    case documentStatuses.autoVerificationError.label :
    case documentStatuses.failedSentForVerification.label:
      variant = 'danger'
      break
    case documentStatuses.pending.label :
    case documentStatuses.autoVerificationSent.label :
    case documentStatuses.autoVerificationReceived.label :
      variant = 'warning'
      break
    case documentStatuses.notProcessed.label :
    case profileChangeStatuses.underReview.label :
    default:
      variant = 'secondary'
      break
  }
  return <Badge pill variant={variant}>{status.label}</Badge>
}

export function getJobStatusLabel(status) {
  let variant = 'secondary'
  switch (status.key) {
    case jobStatuses.completed.key:
      variant = 'success'
      break
    case jobStatuses.completedWithError.key:
    case jobStatuses.stopped.key:
      variant = 'danger'
      break
    case jobStatuses.running.key:
      variant = 'warning'
      break
    case jobStatuses.queued.key:
    default:
      variant = 'secondary'
      break
  }
  return <Badge pill variant={variant}>{status.label}</Badge>
}

export function getRiskLabel(status) {
  let variant = 'secondary'
  switch (status.label) {
    case suitabilityTestScoreResult.high.label :
      variant = 'danger'
      break
    case suitabilityTestScoreResult.medium.label :
      variant = 'warning'
      break
    case suitabilityTestScoreResult.low.label :
      variant = 'success'
      break
    default:
      variant = 'secondary'
      break
  }
  return <Badge pill variant={variant}>{status.label}</Badge>
}

export function getPaymentOptionStatusLabel(status) {
  let variant = 'secondary'
  switch (status.label) {
    case paymentOptionStatuses.active.label: {
      variant = 'success'
      break
    }
    case paymentOptionStatuses.disabled.label: {
      variant = 'warning'
      break
    }
    case paymentOptionStatuses.terminated.label: {
      variant = 'danger'
      break
    }
    default:
      variant = 'secondary'
      break
  }

  return <Badge pill variant={variant}>{status.label}</Badge>
}
