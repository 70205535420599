import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'

export default class AnnotateCallModal extends PureComponent {

  state = {minimized: false}

  static propTypes = {
    callAnnotationRequest: PropTypes.object,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };


  updateNotes(e) {
    this.setState({notes: e.target.value})
  }

  save(e) {
    const {callAnnotationRequest: {callLogId}, dbClient, onConfirm} = this.props
    const {notes} = this.state
    const query = `
      mutation (
        $callLogId:Int!,
        $notes:String!
      ){
          updateCallLogNotes(id:$callLogId, notes:$notes) {
            id
          }
      }`
    dbClient.query(query, {callLogId, notes}).then(({client}) => {
      onConfirm(e)
    })
  }

  render() {
    const {show, onCancel, callAnnotationRequest: {clientName}} = this.props
    const {notes = '', minimized} = this.state

    return (
      <Modal
        backdrop={false}
        keyboard={false}
        show={show}
        onHide={onCancel}
        className="floating-modal"
        onEntered={() => {
          document.body.classList.remove('modal-open')
          document.body.style.overflow = 'auto'
        }}
      >
        <Modal.Header onClick={() => this.setState({minimized: !minimized})}>
          <strong>Call with {clientName}</strong>
        </Modal.Header>
        {!minimized &&
        <div>
          <Modal.Body>
            <textarea
              value={notes}
              placeholder="Call notes"
              onChange={(e) => this.updateNotes(e)}
              className="form-control"
            />
          </Modal.Body>
          <Modal.Footer>
            <ButtonToolbar className="float-right">
              <Button onClick={onCancel} variant="outline-secondary" size="sm" className="mr-1">
                Cancel
              </Button>
              <Button variant="success" size="sm" onClick={(e) => this.save(e)}>
                Save
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </div>
        }
      </Modal>
    )
  }
}
