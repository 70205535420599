import React from 'react'
import PropTypes from 'prop-types'
import {ButtonGroup, Button, Badge} from 'react-bootstrap'
import {includes} from 'lodash'
import PureComponent from '../PureComponent'

export default class StatusFilter extends PureComponent {

  static propTypes = {
    //statuses property assumes input is an enum
    statuses: PropTypes.object.isRequired,
    value: PropTypes.array,
    onClick: PropTypes.func.isRequired,
  };

  renderButton(value, label, filterStatus, onClick) {
    const {depositsTopVipCount} = this.props
    const vipCount = {}
    if (depositsTopVipCount) {
      for (const result of depositsTopVipCount) {
        vipCount[result.status] = result.count
      }
    }
    const topVipCount = vipCount[value] &&
      <span><Badge id="alert-badge" variant="danger">{vipCount[value]}</Badge></span>
    return (
      <Button
        id={`t-filter-status-${label.toLowerCase()}`}
        key={value}
        size="sm"
        variant="outline-secondary"
        active={includes(filterStatus, value)}
        onClick={(e) => onClick(value)}
        style={{fontSize: 12}}
      >{label}{topVipCount}</Button>
    )
  }

  render() {
    let {statuses, value, onClick} = this.props
    value = value == null ? '' : value

    return (
      <ButtonGroup>
        {Object.keys(statuses).map((status) => (
          this.renderButton(status, statuses[status].label, value, onClick)
        ))}
      </ButtonGroup>
    )
  }

}
