import React from 'react'
import {Button, Card, Col, Container, Form, Pagination, Row, Table} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import moment from 'moment'
import {getPageCount, getPageRange} from '../useful'
import {isEmpty} from 'lodash'
import runtimeConfig from '../config'
import {backendRequest} from '../utils/net'
import ReactJson from 'react-json-view'

const {backendUrl} = runtimeConfig.getInstance()
const DEFAULT_LIMIT = 30
const BACKGROUND_COLORS = {
  'RED': '#ff000080',
  'GREEN': '#00ff0080',
  'ORANGE': '#ffa50080',
  'BLACK': '#00000080'
}


class JobsMonitor extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      jobs: [],
      jobsCount: 0,
      currentPage: 1,
      jobType: 'all', // Track job type ('all' or 'specific')
      specificJobType: '', // Track the specific job type entered by the user
      jsonViewerCollapsed:true,
      jobTypes:[]
    }
  }

  componentDidMount() {
    const {currentPage} = this.state
    const limit = DEFAULT_LIMIT
    const offset = (currentPage - 1) * limit

    this.getJobs(limit, offset)
    this.getJobTypes()
  }
  getJobs(limit = DEFAULT_LIMIT, offset = 0) {
    const {jobType, specificJobType} = this.state
    let requestPath = `${backendUrl}/api/v1/reports/jobs/all/${limit}/${offset}`
    if (jobType === 'specific' && specificJobType) {
      requestPath = `${backendUrl}/api/v1/reports/jobs/by-type/${specificJobType}/${limit}/${offset}`
    }

    return backendRequest(requestPath, null, 'GET')
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          jobs: data.jobs,
          jobsCount: data.jobCount || 0,
        })
      })
      .catch((error) => {
        console.error('Error fetching jobs:', error)
        alert('Something went wrong')
      })
  }

  getJobTypes() {
    const requestPath = `${backendUrl}/api/v1/reports/jobs/types`
    return backendRequest(requestPath, null, 'GET')
      .then((res) => res.json())
      .then((data) => {
        this.setState({
          jobTypes: data,
        })
      })
      .catch((error) => {
        console.error('Error fetching job types:', error)
        alert('Something went wrong')
      })
  }
  handleJobTypeChange = (e) => {
    e.persist()
    this.setState({jobType: e.target.value}, () => {
      if (e.target.value === 'all') {
        this.getJobs()
      }
    })
  };

  handleSpecificJobTypeChange = (e) => {
    this.setState({specificJobType: e.target.value})
  };

  handleSearch = () => {
    this.getJobs()
  };
  requeueJob = (id) => {
    const requestPath = `${backendUrl}/api/v1/reports/jobs/queue/${id}`
    const HTTPMethod = 'PATCH'
    return backendRequest(requestPath, null, HTTPMethod)
      .then((res) => res.json())
      .then((body) => {
        alert(`Job ${id} requeued`)
        this.getJobs() // Refresh data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error requeuing job:', error)
        alert('Error requeuing the job')
      })
  }
  rejectJob = (id) => {
    const requestPath = `${backendUrl}/api/v1/reports/jobs/${id}`
    const HTTPMethod = 'DELETE'
    return backendRequest(requestPath, null, HTTPMethod)
      .then((res) => res.json())
      .then((body) => {
        alert(`Job ${id} rejected`)
        this.getJobs() // Refresh data
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error rejecting job:', error)
        alert('Error rejecting the job')
      })}
  render() {
    const {jobs, jobsCount, currentPage, jobType, specificJobType} = this.state

    return (
      <Container>
        <h3>Worker jobs Monitor</h3>
        <Row className="mb-3">
          <Col xs={4}>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th colSpan="2" className="text-center">Legend</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.GREEN}}></td>
                  <td>Active status</td>
                </tr>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.ORANGE}}></td>
                  <td>Inactive status</td>
                </tr>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.RED}}></td>
                  <td>Delayed status</td>
                </tr>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.BLACK, color: 'white'}}></td>
                  <td>Failed status</td>
                </tr>
                <tr>
                  <td colSpan={2}>* All times displayed in user locale</td>
                </tr>
                <tr>
                  <td colSpan={2}>* When searching by type,results grouped by state first, then ordered by ID</td>
                </tr>

              </tbody>
            </Table>
          </Col>

          <Col xs={8} className="d-flex align-items-start">
            <Form>
              <Form.Check
                type="radio"
                label="Search for all types"
                name="jobType"
                value="all"
                checked={jobType === 'all'}
                onChange={(e) => this.handleJobTypeChange(e)}
              />
              <Form.Check
                type="radio"
                label="Search for specific type"
                name="jobType"
                value="specific"
                checked={jobType === 'specific'}
                onChange={(e) => this.handleJobTypeChange(e)}
              />


              {jobType === 'specific' && (
                <Form.Group className="mt-2">
                  <Form.Control
                    as="select"
                    value={specificJobType}
                    onChange={this.handleSpecificJobTypeChange}
                  >
                    <option value="">Select a job type</option>
                    {this.state.jobTypes.map((jobType, index) => (
                      <option key={index} value={jobType}>
                        {jobType}
                      </option>
                    ))}
                  </Form.Control>

                  <Button className="mt-2" variant="primary" onClick={this.handleSearch}>
                    Search
                  </Button>
                </Form.Group>

              )}
            </Form>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className="text-center">
                  <thead>
                    <tr>
                      <th>
                        <span>Job ID</span>
                      </th>
                      <th>
                        <span>Type</span>
                      </th>
                      <th>
                        <span>Status</span>
                      </th>
                      <th>
                        <span>Started</span>
                      </th>
                      <th>
                        <span>Minutes since scheduled</span>
                      </th>
                      <th>
                        <span>Created</span>
                      </th>
                      <th>
                        <span>Scheduled</span>
                      </th>
                      <th>
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                          <Button className={'btn btn-sm'}
                            onClick={() => this.setState({jsonViewerCollapsed: !this.state.jsonViewerCollapsed})}>
                            collapse/uncollapse
                          </Button>
                          <span>Data</span>
                        </div>
                      </th>

                      <th>Actions</th>

                    </tr>
                  </thead>
                  <tbody>
                    {
                      !isEmpty(jobs) && jobs.map((job) => {
                        const {ID, startedAt, type, createdAt, data, status, scheduledTime} = job
                        const timeSinceScheduled = moment().diff(moment(scheduledTime), 'minutes')
                        let rowStyle = {}

                        // Check the status if timeSinceScheduled condition is not met
                        switch (status.toLowerCase()) {
                          case 'active':
                            rowStyle = {backgroundColor: BACKGROUND_COLORS.GREEN}
                            break
                          case 'inactive':
                            rowStyle = {backgroundColor: BACKGROUND_COLORS.ORANGE}
                            break
                          case 'delayed':
                            rowStyle = {backgroundColor: BACKGROUND_COLORS.RED}
                            break
                          case 'failed':
                            rowStyle = {backgroundColor: BACKGROUND_COLORS.BLACK, color: 'white'}
                            break
                          default:
                            rowStyle = {}
                            break

                        }

                        return (
                          <tr
                            key={ID}
                            id={`t-worker-job-id-${ID}`}
                            style={rowStyle}
                          >
                            <td>{ID}</td>
                            <td>{type}</td>
                            <td>{status}</td>
                            <td>{moment(startedAt).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{timeSinceScheduled}</td>
                            <td>{moment(createdAt).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{moment(scheduledTime).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td style={{maxWidth: '300px', wordWrap: 'break-word', overflow: 'hidden'}}>
                              <ReactJson src={data} collapsed={this.state.jsonViewerCollapsed} enableClipboard={true} name={false}
                                displayDataTypes={false}/>
                            </td>
                            <td style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                              <Button
                                variant="danger"
                                size="sm"
                                onClick={() => this.rejectJob(ID)}
                                className="mr-2" // Add margin to the right
                                style={{width: '100px', paddingLeft: '5px', marginBottom: '5px'}}
                              >
                                Reject
                              </Button>
                              <Button
                                variant="warning"
                                size="sm"
                                onClick={() => this.requeueJob(ID)}
                                style={{width: '100px', paddingLeft: '5px'}}
                              >
                                Requeue
                              </Button>
                            </td>

                          </tr>
                        )
                      })
                    }

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                ...state,
                currentPage: selectedEvent.eventKey
              }))}
            >
              {getPageRange(currentPage, getPageCount(jobsCount,DEFAULT_LIMIT)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      currentPage: currentPage - 1
                    }))}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      currentPage: currentPage + 1
                    }))}
                  />
                }

                return <Pagination.Item
                  active={page === currentPage}
                  key={page}
                  onClick={() => {
                    const offset = (page - 1) * DEFAULT_LIMIT
                    this.setState({currentPage: page}, () => {
                      this.getJobs(DEFAULT_LIMIT, offset)
                    })
                  }}
                >
                  {page}
                </Pagination.Item>

              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default JobsMonitor
