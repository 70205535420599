import moment from 'moment'
import {omitBy, head, find, get} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {formatAccountTypeFilterInput} from '../schemaUtils'
import {withdrawalPaymentFields, yesNo, withdrawalPaymentVendors, orderDirections} from '@bdswiss/common-enums'
import {orderByFields} from '../common/utils'

const paymentFields = omitBy(withdrawalPaymentFields,
  (f) => f.key === 'amount' || f.key === 'withdrawalReason' || f.key === 'withdrawalReasonText')

function initWithdrawals(withdrawals) {
  for (const withdrawal of withdrawals) {
    withdrawal.createdAt = moment(withdrawal.createdAt)
    withdrawal.createdAt.isValid()
  }
  return withdrawals
}

export const withdrawalsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {withdrawalStatus, salesAgentFilter, accountsFilter, companyFilter, page, dateFilters,
      clientTypesFilter, vendorsFilter, countriesFilter, topVipFilter, amountFilters, withdrawalsSearch,
      typesFilter, partnersAgentFilter, hasPendingFundingDocumentsFilter, depositVendorFilterValue,
      orderByFilter, orderDirectionFilter,
    } = props
    const countriesAsList = countriesFilter ? countriesFilter.join(', ') : ''
    const conditions = [
      withdrawalStatus ? `status: ${withdrawalStatus}` : '',
      salesAgentFilter ? `salesAgentId: ${salesAgentFilter}` : '',
      companyFilter ? `company: ${companyFilter}` : '',
      dateFilters && dateFilters.createdFrom ?
        `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : '',
      dateFilters && dateFilters.createdTo ?
        `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : '',
      accountsFilter ? `accountTypeSubtype: ${formatAccountTypeFilterInput(accountsFilter)}` : '',
      head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
      head(vendorsFilter) ? `vendors: [${vendorsFilter}]` : '',
      head(typesFilter) ? `types: [${typesFilter}]` : '',
      countriesFilter ? `countries: [${countriesAsList}]` : '',
      topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : '',
      hasPendingFundingDocumentsFilter ? `hasPendingFundingDocuments: ${get(find(yesNo, {value: hasPendingFundingDocumentsFilter}), 'boolValue')}` : '',
      amountFilters && amountFilters.amountFrom ? `amountFrom: ${amountFilters.amountFrom}` : '',
      amountFilters && amountFilters.amountTo ? `amountTo: ${amountFilters.amountTo}` : '',
      partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
      depositVendorFilterValue ? `depositVendor: ${depositVendorFilterValue}` : '',
    ].join('\n').trim()

    const orderBy = orderByFilter ? `orderBy: ${orderByFields[orderByFilter].key}` : 'orderBy: id'
    const orderDirection = orderDirectionFilter ? `orderDirection: ${orderDirections[orderDirectionFilter].key}` : 'orderDirection: descending'

    const query = `{
      withdrawals(
        ${conditions}
        limit: ${getPageSize()}
        offset: ${getOffset(page)}
        ${orderBy}
        ${orderDirection}
        ${withdrawalsSearch ? `search: "${withdrawalsSearch}"` : ''}
      ) {
        id
        type
        currency
        amount
        withdrawalReason
        withdrawalReasonText
        paymentVendor
        paymentFields {
          ${Object.keys(paymentFields).join(' ')}
        }
        transferToAccount {
          __typename
          ... on BaseAccount {
            id
            remoteId
          }
        }
        salesAgent {
          id
          firstName
          lastName
        }
        account {
          __typename
          ... on BaseAccount {
            id
            remoteId
            client {
              id
              firstName
              lastName
              vps
              company
              topVip
              accounts {
                __typename
                ... on BaseAccount {
                  id
                  remoteId
                  currency
                }
              }
              address {
                country
              }
            }
          }
        }
        type
        status
        comments
        createdAt
        transferToAccount {
          __typename
          ... on BaseAccount {
            id
            remoteId
            client {
              id
            }
          }
        }
        pendingFundingDocuments {
          id
        }
        depositVendor
      }
      withdrawalsCount
        ${conditions ? `(${conditions})` : ''}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Withdrawals loaded',
    (state, res) => ({
      ...state,
      withdrawals: initWithdrawals(res.withdrawals),
      withdrawalsCount: res.withdrawalsCount,
    }),
    [res]
  ),
}

export const withdrawalsTopVipCountProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const query = `{
      withdrawalsTopVipCount {
        count
        status
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Withdrawals Vip Count loaded',
    (state, res) => ({
      ...state,
      withdrawalsTopVipCount: res.withdrawalsTopVipCount != null
        ? res.withdrawalsTopVipCount
        : state.withdrawalsTopVipCount || 0
    }),
    [res]
  ),
}

export const dynamicFieldsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (!get(find(withdrawalPaymentVendors, {key: get(props, 'withdrawal.vendor')}), 'hasDynamicWithdrawalFields', false) ||
      !props.withdrawal
    ) {
      return null
    }
    const query =
      `query DynamicWithdrawalFields {
        dynamicWithdrawalFields(
          vendorName: "${props.withdrawal.vendor}"
          clientId: ${props.client.personalDetails.id}
        ) {
          fields
          __typename
        }
      }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Dynamic Fields loaded',
    (state, res) => ({
      ...state,
      dynamicFields: res.dynamicWithdrawalFields ? JSON.parse(res.dynamicWithdrawalFields.fields) : {},
    }),
    [res]
  ),
}

export const thisMonthWithdrawalsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const now = new Date()
    const startOfTheMonth = new Date(now.getFullYear(),now.getMonth(),1,0, 0, 0).toISOString()

    const query = `{
      withdrawals(creationTimeFrom: "${startOfTheMonth}") {
        id
        currency
        amount
        createdAt
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'This month withdrawals loaded',
    (state, res) => ({
      ...state,
      thisMonthWithdrawals: res.withdrawals || []
    }),
    [res]
  ),
}
