import React from 'react'
import PureComponent from './PureComponent'

export default class NotFound extends PureComponent {

  componentWillMount() {
    this.tryOnceMore()
  }

  //TODO: Deal with this issue with a more solid way
  tryOnceMore() {
    setTimeout(() => {
      // eslint-disable-next-line no-restricted-globals
      history.back()
    }, 1000)
  }

  render() {
    return (
      <h2>
        Page not found
      </h2>
    )
  }

}
