import React from 'react'
import PropTypes from 'prop-types'
import {Image} from 'react-bootstrap'
import {components} from 'react-select'
import * as enums from '@bdswiss/common-enums'
import {sortBy, reject, isEmpty, find} from 'lodash'
import StylishSelect from '../components/StylishSelect'
import {noAccountType, activeForexDemoType, inactiveForexDemoType} from '../schemaUtils'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import {getAccountLabel} from '../utils/general'
import {createAccountIconUrl} from '../utils/links'

const availableAccountTypes = reject(enums.accountTypes, (acc) => acc.hideFromUsers || acc.deprecated)

const accountTypesOptions = (companies) => {
  const accountTypes = []

  sortBy(Object.values(availableAccountTypes)
    .filter((a) => companies.includes(a.company)),
  (a) => ((100 * enums.companies[a.company].sortIndex) + a.orderBy))
    .forEach((account, index) => {
      if (!isEmpty(account.supportedSubtypes)) {
        account.supportedSubtypes.forEach((subType) => {
          const subTypeObject = find(enums.accountSubtypes, {value: subType})
          accountTypes.push({
            label: `${getAccountLabel(account)} - ${subTypeObject.label}`,
            value: `${account.value}-${subTypeObject.key}`,
            avatar: createAccountIconUrl(account),
          })
        })
      } else {
        accountTypes.push({
          label: getAccountLabel(account),
          value: account.value,
          avatar: createAccountIconUrl(account),
        })
      }
    })

  return accountTypes
}

function selectOptions(addNoAccountOption, companies) {
  if (!addNoAccountOption) {
    return accountTypesOptions(companies)
  }

  return [{
    label: 'No Account',
    value: noAccountType,
  }, {
    label: 'Active Forex Demo Account',
    value: activeForexDemoType,
  }, {
    label: 'Inactive Forex Demo Account',
    value: inactiveForexDemoType,
  }].concat(accountTypesOptions(companies))
}

const IconOption = (props) => (
  <components.Option {...props}>
    {props.data.avatar && <Image
      src={props.data.avatar}
      style={{width: 20}}
      alt={props.data.label}
    />}
    &nbsp;{props.data.label}
  </components.Option>
)

export default class AccountTypesFilter extends PureComponent {

  static propTypes = {
    noAccountOption: PropTypes.bool,
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    companies: PropTypes.array.isRequired,
  };

  filterChanged(selection) {
    if (!selection) {
      this.props.onChange()
    } else {
      const clientsAccountsFilter = selection.value
      this.props.onChange(clientsAccountsFilter)
    }
  }

  render() {
    const {value, noAccountOption, companies} = this.props

    return (
      <StylishSelect
        id="t-account-filter"
        placeholderText="Any Account"
        value={value}
        options={selectOptions(noAccountOption, companies)}
        highlightIfActive
        onChange={(e) => this.filterChanged(e)}
        clearable
        className={style.accountType}
        components={{Option: IconOption}}
      />
    )
  }

}
