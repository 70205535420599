import {gql, useQuery} from 'urql'

const unapprovedIBAccountsQuery = (flavor) => gql`
  query {
    ${flavor} {
      ... on BaseAccount {
        id
        client {
          address {
            country
          }
          firstName
          isOnline
          lastName
        }
      }
    }
  }
`

const queryNamesByFlavor = {
  'ib': 'unapprovedIbAccounts',
  'pamm': 'unapprovedPAMMManagerAccounts',
}

export const useUnapprovedAccounts = (flavor) => {
  const [result, reexecuteQuery] = useQuery({
    query: unapprovedIBAccountsQuery(queryNamesByFlavor[flavor]),
  })
  const accounts = result.data?.[queryNamesByFlavor[flavor]] || []
  return {accounts, reexecuteQuery}
}
