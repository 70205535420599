import React from 'react'
import PropTypes from 'prop-types'
import {get, omitBy, filter} from 'lodash'
import {ButtonToolbar, Button, Row, Col, Card, Form} from 'react-bootstrap'
import {profileTransactionPurpose, appropTestEmployerCategory,
  appropTestAnnualIncome, profileNetWorth, appropTestExpectedDeposit, countries,
  appropTestSourceOfFunds, appropNatureOfTransactions, appropOriginOfFunds,
  appropTestPoliticallyExposed, appropTestIsUsReportable, appropTestTinReason,
  appropTestAccountTurnover, appropTestPoliticallyExposedReason} from '@bdswiss/common-enums'
import {canUpdateClientEconomicProfile} from '@bdswiss/common-permissions'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import styles from './client.module.scss'

export default provideProps()(class UpdateEconomicProfile extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {viewer, globalQuestionnaire} = this.props
    const transactionPurposeOptions = StylishSelect.enumToStylishOptions(profileTransactionPurpose)
    const employerCategoryOptions = StylishSelect.enumToStylishOptions(appropTestEmployerCategory)
    const annualIncomeOptions = StylishSelect.enumToStylishOptions(appropTestAnnualIncome)
    const profileNetWorthOptions = StylishSelect.enumToStylishOptions(profileNetWorth)
    const expectedDepositOptions = StylishSelect.enumToStylishOptions(appropTestExpectedDeposit)
    const countryOptions = StylishSelect.enumToStylishOptions(filter(countries, country => !country.hidden))
    const sourceOfFundsOptions = StylishSelect.enumToStylishOptions(appropTestSourceOfFunds)
    const natureOfTransactionsOptions = StylishSelect.enumToStylishOptions(appropNatureOfTransactions)
    const originOfFundsOptions = StylishSelect.enumToStylishOptions(appropOriginOfFunds)
    const politicallyExposedOptions = StylishSelect.enumToStylishOptions(appropTestPoliticallyExposed)
    const isUsExposedOptions = StylishSelect.enumToStylishOptions(appropTestIsUsReportable)
    const tinReasonOptions = StylishSelect.enumToStylishOptions(appropTestTinReason)
    const accountTurnoverOptions = StylishSelect.enumToStylishOptions(omitBy(appropTestAccountTurnover,
      (o)=>o.disable))
    const politicallyExposedReasonOptions = StylishSelect.enumToStylishOptions(appropTestPoliticallyExposedReason)

    const selectedTransactionPurpose = get(globalQuestionnaire, 'transactionPurpose', '')
    const transactionPurposeClarify = get(globalQuestionnaire, 'transactionPurposeClarify', '')
    const selectedEmployerCategory = get(globalQuestionnaire, 'jobTitle', '')
    const selectedAnnualIncome = get(globalQuestionnaire, 'approxYearlyIncome', '')
    const selectedNetWorth = get(globalQuestionnaire, 'approxNetWorth', '')
    const selectedExpectedDeposit = get(globalQuestionnaire, 'approxExpectedDeposit', '')
    const selectedCountry = get(globalQuestionnaire, 'taxJurisdictionCountry', '')
    const selectedSourceofFunds = get(globalQuestionnaire, 'sourceOfFunds', '')
    const sourceOfFundsClarify = get(globalQuestionnaire, 'sourceOfFundsClarify', '')
    const selectedNatureOfTransactions = get(globalQuestionnaire, 'natureOfTransactions', '')
    const natureOfTransactionsClarify = get(globalQuestionnaire, 'natureOfTransactionsClarify', '')
    const selectedOriginOfFunds = get(globalQuestionnaire, 'originOfFunds', '')
    const selectedPoliticallyExposed = get(globalQuestionnaire, 'politicallyExposed', '')
    const selectedUsCitizen = get(globalQuestionnaire, 'usCitizen', '')
    const selectedTinReason = get(globalQuestionnaire, 'tinReason', '')
    const tin = get(globalQuestionnaire, 'tin', '')
    const selectedPoliticallyExposedReason = get(globalQuestionnaire, 'politicallyExposedReason', '')

    this.setState({transactionPurposeOptions, selectedTransactionPurpose, transactionPurposeClarify,
      employerCategoryOptions, selectedEmployerCategory, annualIncomeOptions, selectedAnnualIncome,
      profileNetWorthOptions, selectedNetWorth, expectedDepositOptions, selectedExpectedDeposit,
      countryOptions, selectedCountry, sourceOfFundsOptions, selectedSourceofFunds, natureOfTransactionsOptions,
      selectedNatureOfTransactions, natureOfTransactionsClarify, originOfFundsOptions, selectedOriginOfFunds,
      sourceOfFundsClarify, politicallyExposedOptions,
      selectedPoliticallyExposed, isUsExposedOptions, selectedUsCitizen, tinReasonOptions, selectedTinReason,
      tin, accountTurnoverOptions, renderSidebar: canUpdateClientEconomicProfile(viewer), politicallyExposedReasonOptions,
      selectedPoliticallyExposedReason})
  }

  saveChanges() {
    const {clientId} = this.props
    const {selectedTransactionPurpose, transactionPurposeClarify, selectedEmployerCategory,
      selectedAnnualIncome, selectedNetWorth, selectedExpectedDeposit, selectedCountry, selectedSourceofFunds,
      selectedOriginOfFunds, sourceOfFundsClarify, selectedNatureOfTransactions, natureOfTransactionsClarify,
      selectedPoliticallyExposed, selectedUsCitizen,
      selectedTinReason, tin, selectedPoliticallyExposedReason} = this.state

    const args = {
      approxNetWorth: selectedNetWorth,
      approxYearlyIncome: selectedAnnualIncome,
      sourceOfFunds: selectedSourceofFunds,
      sourceOfFundsClarify: sourceOfFundsClarify,
      jobTitle: selectedEmployerCategory,
      politicallyExposed: selectedPoliticallyExposed || 'no',
      usCitizen: selectedUsCitizen || 'no',
      transactionPurpose: selectedTransactionPurpose,
      transactionPurposeClarify: transactionPurposeClarify,
      approxExpectedDeposit: selectedExpectedDeposit,
      originOfFunds: selectedOriginOfFunds,
      natureOfTransactions: selectedNatureOfTransactions,
      natureOfTransactionsClarify: natureOfTransactionsClarify,
      taxJurisdictionCountry: selectedCountry,
      tin: tin,
      tinReason: selectedTinReason,
      politicallyExposedReason: selectedPoliticallyExposedReason,
    }

    this.props.actions.client.updateEconomicProfile(clientId, args)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
      .then(this.props.onClose)
  }

  render() {
    const {transactionPurposeOptions, selectedTransactionPurpose, transactionPurposeClarify,
      selectedEmployerCategory, annualIncomeOptions, selectedAnnualIncome, employerCategoryOptions,
      profileNetWorthOptions, selectedNetWorth, selectedExpectedDeposit, countryOptions, selectedCountry,
      sourceOfFundsOptions, selectedSourceofFunds, natureOfTransactionsOptions, expectedDepositOptions,
      selectedNatureOfTransactions, natureOfTransactionsClarify, originOfFundsOptions, selectedOriginOfFunds,
      politicallyExposedOptions, sourceOfFundsClarify,
      selectedPoliticallyExposed, isUsExposedOptions, selectedUsCitizen, tinReasonOptions, selectedTinReason,
      tin, renderSidebar, politicallyExposedReasonOptions, selectedPoliticallyExposedReason} = this.state

    return renderSidebar ? (
      <div id="economic-profile-editor">
        <Row className={styles.toolbar}>
          <Col xs={12}>
            <ButtonToolbar className="float-right">
              <Button
                id="t-client-update-economic-profile-cancel-button"
                tabIndex={-1}
                onClick={this.props.onClose}
                variant="outline-secondary"
                size="sm"
                className="mr-1"
              >Cancel
              </Button>
              <Button
                id="t-client-update-economic-profile-update-button"
                variant="success"
                size="sm"
                onClick={() => this.saveChanges()}
              >Save
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        <Card id="t-client-update-walkthrough-sidebar">
          {this.props.header && <Card.Header><strong>{this.props.header}</strong></Card.Header>}
          <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-purpose"
                  label="What is your intended purpose of transactions?"
                  value={selectedTransactionPurpose}
                  onChange={(e) => this.setState({selectedTransactionPurpose: e.value, touched: true})}
                  placeholderText="Select option"
                  options={transactionPurposeOptions}
                />
              </Col>
              {selectedTransactionPurpose === 'other' && <Col xs={12}>
                <Form.Group>
                  <Form.Label>Please clarify (purpose of transactions)</Form.Label>
                  <Form.Control
                    id="t-client-update-economic-profile-purpose-clarify"
                    type="text"
                    value={transactionPurposeClarify}
                    onChange={(e) => this.setState({transactionPurposeClarify: e.target.value})}
                  />
                </Form.Group>
              </Col>}
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-income"
                  label="What is your approximate yearly income in Euro?"
                  value={selectedAnnualIncome}
                  onChange={(e) => this.setState({selectedAnnualIncome: e.value, touched: true})}
                  placeholderText="Select option"
                  options={annualIncomeOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-net"
                  label="What is your approximate total net worth in Euro?"
                  value={selectedNetWorth}
                  onChange={(e) => this.setState({selectedNetWorth: e.value, touched: true})}
                  placeholderText="Select option"
                  options={profileNetWorthOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-sourceOfFunds"
                  label="What is the source of the Funds you wish to deposit?"
                  value={selectedSourceofFunds}
                  onChange={(e) => this.setState({selectedSourceofFunds: e.value, touched: true})}
                  placeholderText="Select option"
                  options={sourceOfFundsOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-job-title"
                  label="What is your job title/position?"
                  value={selectedEmployerCategory}
                  onChange={(e) => this.setState({selectedEmployerCategory: e.value, touched: true})}
                  placeholderText="Select option"
                  options={employerCategoryOptions}
                />
              </Col>
              {selectedSourceofFunds === 'other' && <Col xs={12}>
                <Form.Group>
                  <Form.Label>Please clarify (purpose of transactions)</Form.Label>
                  <Form.Control
                    id="t-client-update-economic-profile-purpose-clarify"
                    type="text"
                    value={sourceOfFundsClarify}
                    onChange={(e) => this.setState({sourceOfFundsClarify: e.target.value})}
                  />
                </Form.Group>
              </Col>}
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-country"
                  label="Country/Jurisdiction of Tax Residence"
                  value={selectedCountry}
                  onChange={(e) => this.setState({selectedCountry: e.value, touched: true})}
                  placeholderText="Select option"
                  options={countryOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-nature"
                  label="What is the nature of transactions?"
                  value={selectedNatureOfTransactions}
                  onChange={(e) => this.setState({selectedNatureOfTransactions: e.value, touched: true})}
                  placeholderText="Select option"
                  options={natureOfTransactionsOptions}
                />
              </Col>
              {selectedNatureOfTransactions === 'other' && <Col xs={12}>
                <Form.Group>
                  <Form.Label>Please clarify (nature of transactions)</Form.Label>
                  <Form.Control
                    id="t-client-update-economic-profile-nature-clarify"
                    type="text"
                    value={natureOfTransactionsClarify}
                    onChange={(e) => this.setState({natureOfTransactionsClarify: e.target.value})}
                  />
                </Form.Group>
              </Col>}
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-amount-deposited"
                  label="Estimated amount to deposit (yearly)"
                  value={selectedExpectedDeposit}
                  onChange={(e) => this.setState({selectedExpectedDeposit: e.value, touched: true})}
                  placeholderText="Select option"
                  options={expectedDepositOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-origin"
                  label="What is the origin of funds?"
                  value={selectedOriginOfFunds}
                  onChange={(e) => this.setState({selectedOriginOfFunds: e.value, touched: true})}
                  placeholderText="Select option"
                  options={originOfFundsOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-politically"
                  label="Are you a politically exposed person?"
                  value={selectedPoliticallyExposed}
                  onChange={(e) => this.setState({selectedPoliticallyExposed: e.value, touched: true})}
                  placeholderText="Select option"
                  options={politicallyExposedOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-politically-reason"
                  label="Politically Exposed Reason"
                  value={selectedPoliticallyExposedReason}
                  onChange={(e) => this.setState({selectedPoliticallyExposedReason: e.value, touched: true})}
                  placeholderText="Select option"
                  options={politicallyExposedReasonOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-usCitizen"
                  label="Are you a US citizen or resident?"
                  value={selectedUsCitizen}
                  onChange={(e) => this.setState({selectedUsCitizen: e.value, touched: true})}
                  placeholderText="Select option"
                  options={isUsExposedOptions}
                />
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-update-economic-profile-tin-reason"
                  label="If TIN is not available, please select a reason"
                  value={selectedTinReason}
                  onChange={(e) => this.setState({selectedTinReason: e.value, touched: true})}
                  placeholderText="Select option"
                  options={tinReasonOptions}
                />
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Taxpayer Identification Number (TIN)</Form.Label>
                  <Form.Control
                    id="t-client-update-economic-profile-tin-number"
                    type="text"
                    value={tin}
                    onChange={(e) => this.setState({tin: e.target.value})}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    ) : null
  }
})
