import React from 'react'
import {omit} from 'lodash'
import classNames from 'classnames'
import {Form} from 'react-bootstrap'
import Textarea from 'react-textarea-autosize'
import PureComponent from '../PureComponent'

/**
 * Converts auto-resizable textarea into react-bootstrap auto-resizable textarea. Meaning it will add
 * same classes and outer components arount it as Input bootstrap would.
 */
export default class TextareaAutosizeInput extends PureComponent {

  render() {
    const {isInvalid, value} = this.props
    const style = isInvalid ? {border: '1px solid rgb(169, 68, 66'} : {}
    return (
      <Form.Group {...omit(this.props, 'isInvalid')}>
        <Textarea
          style={style}
          minRows={3}
          {...omit(this.props, 'isInvalid')}
          className={classNames(this.props.className, 'form-control')}
          value={value || ''}
        />
      </Form.Group>
    )
  }
}
