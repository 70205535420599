import React from 'react'
import PropTypes from 'prop-types'
import {Row, Card, Col, Badge, Button} from 'react-bootstrap'
import classnames from 'classnames'
import {communicationChannels} from '@bdswiss/common-enums'
import {get, isNil} from 'lodash'
import {provideProps} from '../decorators'
import 'react-datetime/css/react-datetime.css'
import PureComponent from '../PureComponent'
import style from './communications/Communications.module.scss'
import CommunicationLink from './communications/CommunicationLink'

class SMS extends PureComponent {
  state = {}
  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
  }
  static propTypes = {
    sms: PropTypes.array.isRequired,
    smsCount: PropTypes.number.isRequired,
  }

  componentWillReceiveProps (nextProps) {
    nextProps.isActive && this.props.sms.length === 0 && this.fetch()
  }

  fetch = () => {
    const {dataLoading, dataError} = this.state
    if (!(dataLoading || dataError)) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.sms.fetch()
          .then(() => this.setState({dataLoading: false}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  render() {
    const {sms, smsCount} = this.props
    const {dataLoading, dataError} = this.state
    if (dataError) return <pre> Error Loading SMSs : {dataError.message} </pre>

    return (
      <Row>
        <Col xs={12} className="sms">
          {sms.length === 0 && <span>No SMSs</span>}
          <Card className={classnames(['panel-events'])}>
            <Card.Body>
              {sms.map((communication, i) => {
                const {channels, createdAt, title} = communication
                return (
                  <Row key={`comm-${i}`} className={style.row}>
                    <Col xs={12}>
                      <CommunicationLink communication={communication}>
                        <div>
                          {channels.map((channel, i) => <Badge
                            className={style.channels}
                            variant="success"
                            pill
                            key={`channel-${i}`}
                          >{communicationChannels[channel].label}</Badge>
                          )}
                          <small className={style.createdAt}>{createdAt.fromNow()}</small>
                        </div>
                        <div className={style.title}>{title}</div>
                      </CommunicationLink>
                    </Col>
                  </Row>
                )
              })}
              {sms.length !== smsCount && <Row>
                <Col xs={12} style={{textAlign: 'center', padding: '10px'}}>
                  <Button onClick={this.fetch}>Fetch more</Button>
                </Col>
              </Row>}
              {(isNil(sms) || dataLoading) && <Row>
                <Col xs={12} className="text-center">Loading SMSs...</Col>
              </Row>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default provideProps((state, _, __, props) => {
  const {client} = state
  const clientId = Number(get(props, 'match.params.clientId'))
  return ({
    sms: get(client[clientId], 'sms', []),
    smsCount: get(client[clientId], 'smsCount', 0),
  })
})(SMS)
