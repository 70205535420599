/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import DataProvider from '../dataProvider.js'
import lodash from 'lodash'
import {getHocName} from '../useful'

function addInvalidateDecorators(dataProvider, functionsObj) {
  if (!functionsObj) {
    return functionsObj
  }

  const result = lodash.mapValues(functionsObj, (value, key, object) => (...args) => {
    dataProvider.rejectOldData()
    functionsObj[key](...args)
  })

  return result
}

export default (dpMap, skipWaiting) => {

  const dpContextTypes = {}
  for (const dpName of lodash.keys(dpMap)) {
    dpContextTypes[dpName] = PropTypes.object
  }

  return (Component) => class DataProviderComponent extends React.Component {

    static displayName = getHocName('WithDataProvider', Component)

    static contextTypes = {
      dbClient: PropTypes.object.isRequired,
      dispatch: PropTypes.func.isRequired,
      router: PropTypes.object.isRequired,
      ...dpContextTypes,
    }

    createSubProviders(dpName, subProvidersObj) {
      if (!subProvidersObj) {
        return subProvidersObj
      }

      const result = lodash.mapValues(subProvidersObj, (value, key, object) => {
        const {getQuery, onData} = value
        // for backwards compatibility
        const dp = new DataProvider(
          this.context.dispatch,
          this.context.dbClient,
          (props) => getQuery(props),
          (data) => onData(data, this.context.dispatch, this.props),
          `${dpName}.${key}`,
        )

        return {
          fetch: () => dp.fetch(true, this.props),
        }
      })

      return result
    }

    componentWillMount() {
      this.dataProviders = {}
      this.childContext = {}
      this.firstFetchDone = false
      this.mounted = true
      const firstFetch = []
      if (!(lodash.isObject(dpMap) && !lodash.isArray(dpMap))) {
        throw new Error(`mountDataProvider expected object, got ${JSON.stringify(dpMap)}`)
      }
      for (const dpName of lodash.keys(dpMap)) {
        let fetchPromise
        if (this.context[dpName]) {
          fetchPromise = this.context[dpName].firstFetch
        } else {
          let {getQuery, onData, fetchInterval, optimistic, subProviders, onError} = dpMap[dpName]
          const errorHandler = lodash.isFunction(onError) && onError
          fetchInterval = lodash.isFunction(fetchInterval) ? fetchInterval() : fetchInterval
          // for backwards compatibility
          const dp = new DataProvider(
            this.context.dispatch,
            this.context.dbClient,
            (props) => getQuery(props),
            (data) => {onData(data, this.context.dispatch, this.props)},
            dpName,
            fetchInterval,
            (error) => {
              errorHandler && errorHandler(error, this.context.dispatch, this.props)
            },
            lodash.pick(dpMap[dpName], ['cache', 'cacheInterval'])
          )
          this.dataProviders[dpName] = dp
          // for the backward compatibility issues, put to context object that mimics data provider
          fetchPromise = !dpMap[dpName].skipInitialFetch && dp.fetch(true, this.props)
          this.childContext[dpName] = {
            fetch: () => dp.fetch(true, this.props),
            firstFetch: fetchPromise,
            optimistic: addInvalidateDecorators(dp, optimistic),
            subProviders: this.createSubProviders(dpName, subProviders),
          }
        }
        firstFetch.push(fetchPromise)
      }
      Promise.all(firstFetch).then(() => {
        this.firstFetchDone = true
        if (this.mounted) {
          this.forceUpdate()
        }
      }).catch((e) => {
        console.error(e) //eslint-disable-line no-console
        this.context.router.push('/notFoundPath')
      })
    }

    componentWillReceiveProps(nextProps) {
      for (const dpName of lodash.keys(this.dataProviders)) {
        if (!dpMap[dpName].skipInitialFetch || this.dataProviders[dpName].fetchedAtLeastOnce) {
          this.dataProviders[dpName].fetch(false, nextProps)
        }
      }
    }

    componentWillUnmount() {
      this.mounted = false
      for (const dpName of lodash.keys(this.dataProviders)) {
        this.dataProviders[dpName].unmount()
      }
    }

    static childContextTypes = dpContextTypes;

    getChildContext() {
      return this.childContext
    }

    render() {
      if (this.firstFetchDone || skipWaiting) {
        return (<Component {...this.props} {...this.childContext} />)
      } else {
        return (<div> Loading data... </div>)
      }
    }
  }
}
