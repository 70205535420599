import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Card, OverlayTrigger, Tooltip} from 'react-bootstrap'
import classNames from 'classnames'
import {get} from 'lodash'
import {legacyAppropTestQuestions, appropTestQuestions, appropTestQuestionsV2} from '@bdswiss/common-enums'
import {canSeeAppropTestAnswerWarning} from '@bdswiss/common-permissions'
import style from './client.module.scss'
import PureComponent from '../PureComponent'

export default class AppropTestDetails extends PureComponent {

  static propTypes = {
    appropTest: PropTypes.object,
    approvedAppropTest: PropTypes.object,
    header: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  };
  getQuestionAnswer(appropTest, {key, options}) {
    const value = get(appropTest, ['answers', key])
    return options && value in options ? options[value].label : value
  }

  render() {
    const {appropTest = {}, approvedAppropTest, header, onClose} = this.props
    let questions = appropTest.id === 'legacy' ? legacyAppropTestQuestions : appropTestQuestions
    questions = {...questions, ...appropTestQuestionsV2}
    return (
      <div>
        <Row className={style.toolbar}>
          <Col xs={12}>
            <Button
              id="t-client-approp-test-details-close"
              key={1}
              variant="outline-secondary"
              className="float-right"
              onClick={onClose}
            >
              Close
            </Button>
          </Col>
        </Row>
        {appropTest.answers && (
          <Card
            id="t-client-approp-test-details-sidebar"
          >
            {header && <Card.Header><strong>{header}</strong></Card.Header>}
            <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
              {Object.values(questions).map((question) => {
                const answer = this.getQuestionAnswer(appropTest, question)
                const approvedAnswer = this.getQuestionAnswer(approvedAppropTest, question)
                const changed = approvedAppropTest && answer !== approvedAnswer
                const alertClass = canSeeAppropTestAnswerWarning(this.props.viewer) &&
                                  question.alert && question.alert(answer)
                  ? style.aptAlert
                  : style.aptAnswerUnchanged

                const formControl = (
                  <Form.Group>
                    <Form.Label>{question.label}</Form.Label>
                    <Form.Control
                      id={`ap-test-${question.key}`}
                      defaultValue={answer}
                      disabled
                      className={classNames([
                        changed ? style.aptAnswerChanged : style.aptAnswerUnchanged,
                        alertClass,
                      ])}
                    />
                  </Form.Group>
                )

                return (
                  <Row key={question.key}>
                    <Col xs={12}>
                      {changed ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            (<Tooltip id="disabled">
                              <span>
                                Previously Approved Answer:<br />
                                {approvedAnswer || '[empty]'}
                              </span>
                            </Tooltip>)
                          }
                        >
                          {answer ? formControl : <div />}
                        </OverlayTrigger>
                      ) : (
                        answer && formControl
                      )}
                    </Col>
                  </Row>
                )
              })}
            </Card.Body>
          </Card>
        )}
      </div>
    )
  }
}
