import React from 'react'
import {debounce} from 'lodash'
import {Button, Col, Form, InputGroup, Row} from 'react-bootstrap'
import moment from 'moment'
import {currencies} from '@bdswiss/common-enums'
import {
  checkUserPermission,
  permissions
} from '@bdswiss/common-permissions'

import {agentsProvider} from '../providers'
import style from './netdeposits.module.scss'
import DateTime from '../components/DateTime'
import SelectAgent from '../components/SelectAgent'
import StylishSelect from '../components/StylishSelect'
import {compose, mountDataProviders, provideProps, uiMount} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

const currencyOptions = [
  {label: 'All Currencies', value: undefined},
  ...Object.values(currencies).map((currency) => ({
    value: currency.key,
    label: `${currency.label} ${currency.symbol ? `(${currency.symbol})` : ''}`,
  }))
]

class NetDepositsFilter extends React.Component {
  constructor(props) {
    super(props)
    this.dispatchFilterDebounced = debounce(this.dispatchFilter, 1500)
    this.state = {}
    this.isOnlyOwnReportAllowed = checkUserPermission(props.viewer, permissions.own_retention_report_read) && !checkUserPermission(props.viewer, permissions.retention_report_read)
  }

  componentWillMount() {
    this.setState(() => {})
  }

  componentDidMount() {
    const {viewer} = this.props
    if (this.isOnlyOwnReportAllowed) {
      this.applyFilter('agentId', viewer.id)
    }
  }

  dispatchFilter(key, value) {
    this.props.dispatch('Update net deposits filters', (state) => ({
      ...state,
      netDepositsFilter: {
        ...state.netDepositsFilter,
        [key]: value
      }
    }))
    this.props.uiDispatch(
      'Search net deposits',
      (state, arg) => ({
        ...state,
        netDepositsPage: 1,
        netDepositsFilter: {
          ...state.netDepositsFilter,
          ...arg
        }}),
      [{...this.state}]
    )
  }

  doNetDepositsSearch(search) {
    this.setState({search}, () => this.dispatchFilter())
  }

  applyFilter(key, value, delaySearch = false) {
    this.setState({[key]: value}, () => {
      if (delaySearch) {
        this.dispatchFilterDebounced()
      } else {
        this.dispatchFilter(key, value)
      }
    })
  }

  handleAgentFilterChange = ({value}) => {
    const {startDate, endDate} = this.state

    this.applyFilter('agentId', value)
    if (!startDate && !endDate) {
      const _1MonthAgo = moment().subtract(1, 'months')
      this.applyFilter('endDate', moment().endOf('day'))
      this.applyFilter('startDate', _1MonthAgo.startOf('day'))
    }
  }

  get activeAgents() {
    const {agents} = this.props
    return agents.filter(agent => agent.isActive)
  }

  render() {
    const {agentId, search, startDate, endDate, currency} = this.state
    return (
      <Row>
        <Col xs={3}>
          <span className={style.label}> Sales Agent </span>
          <SelectAgent
            id="t-net-deposits-sales-agent-filter"
            disabled={this.isOnlyOwnReportAllowed}
            agents={this.activeAgents}
            value={agentId}
            placeholderText=""
            onChange={this.handleAgentFilterChange}
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}> Search by Client ID </span>
          <InputGroup>
            <Form.Control
              type="text"
              value={search || ''}
              onChange={(e) => this.setState({search: e.target.value})}
              onKeyUp={(e) => (
                (e.key === 'Enter' && this.doNetDepositsSearch(search)) ||
                  (e.key === 'Escape' && this.doNetDepositsSearch(''))
              )}
            />
            <InputGroup.Append>
              {search && <Button
                key={1}
                title="Clear"
                variant={search ? 'success' : 'outline-dark'}
                onClick={() => this.doNetDepositsSearch('')}
              >
                <FontAwesomeIcon icon="times" />
              </Button>}
              <Button
                key={2}
                title="Search"
                variant={search ? 'success' : 'outline-dark'}
                onClick={() => this.doNetDepositsSearch(search)}
              >
                <FontAwesomeIcon icon="search" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col xs={2}>
          <span className={style.label}> Started At </span>
          <DateTime
            closeOnSelect
            timeFormat={false}
            id="t-net-deposits-started-at-filter"
            value={startDate}
            onFocus={() => this.dispatchFilterDebounced.cancel()}
            onChange={(value) => this.applyFilter('startDate', value)}
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}> Ended At </span>
          <DateTime
            closeOnSelect
            timeFormat={false}
            id="t-net-deposits-end-at-filter"
            value={endDate}
            onFocus={() => this.dispatchFilterDebounced.cancel()}
            onChange={(value) => this.applyFilter('endDate', value)}
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}> All Currencies </span>
          <StylishSelect
            id="t-net-deposits-currency-filter"
            value={currency}
            options={currencyOptions}
            onChange={(value) => this.applyFilter('currency', value)}
          />
        </Col>
      </Row>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'netDeposits']),
  provideProps((state) => ({agents: state.agents})),
  mountDataProviders({agentsProvider}),
)(NetDepositsFilter)
