import classnames from 'classnames'
import {useState} from 'react'
import {
  Col,
  Row
} from 'react-bootstrap'
import FontAwesomeIcon from '../../../components/FontAwesomeIcon'
import style from '../../client.module.scss'

export const MasterIBSection = ({referrerAccount}) => {
  const [show, setShow] = useState(true)
  const sectionName = 'Master IB'

  return (<Row className={style.outerRow}>
    <Col xs={1} className={style.expandColumn}>
      <Row>
        <Col xs={12}>
          <FontAwesomeIcon
            icon={show ? 'minus-square-o' : 'plus-square-o'}
            className={classnames(['clickable', style.expandableIcon])}
            onClick={() => setShow(!show)}
          />
        </Col>
      </Row>
      <Row style={{height: '100%'}} className={!show ? style.hiddenSection : ''}>
        <Col xs={12} className={style.sectionTitle}>
          {sectionName}
        </Col>
      </Row>
    </Col>
    <Col xs={10}
      className={classnames([style.sectionTitleForHidden, show ? style.hiddenSection : ''])}
    >
      {sectionName}
    </Col>
    <Col xs={11}>
      <Row className={style.personalDetails}>
        <Col xs={12}>
          <Row className={style.personalDetails}>
            <Col xs={6} className="dl-horizontal long-title">
              <dl className="dl-horizontal long-title">
                <dt>Type</dt>
                <dd>
                  Introducing Broker Account
                </dd>
                <dt>Name</dt>
                <dd>
                  {referrerAccount?.client?.email} - {referrerAccount?.remoteId}
                </dd>
              </dl>
            </Col>
            <Col xs={6} className="dl-horizontal long-title">
              <dl className="dl-horizontal long-title">
                <dt>IB Account</dt>
                <dd>
                  {referrerAccount?.remoteId}
                </dd>
                <dt>Master IB Account</dt>
                <dd>
                  <a href={`/clients/${referrerAccount?.client?.id}`}>
                    {referrerAccount?.client?.firstName} {referrerAccount?.client?.lastName} [ID: {referrerAccount?.client?.id}]
                  </a>
                </dd>
              </dl>
            </Col>
            <Col xs={12}>
              <dl className="dl-horizontal long-title">
                <dt>Registration Url</dt>
                <dd>
                  {window.location.origin}/register?referral_code={referrerAccount.remoteId}
                </dd>
              </dl>
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  </Row>
  )
}
