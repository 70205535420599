import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Container} from 'react-bootstrap'
import {isEmpty, debounce} from 'lodash'
import FontAwesomeIcon from './components/FontAwesomeIcon'
import style from './app.module.scss'
import PureComponent from './PureComponent'
import {backendRequest} from './utils/net'

export default class ResetPassword extends PureComponent {

  constructor(props) {
    super(props)
    this.validateEmailForm = debounce(this.validatePasswordForm, 200, {leading: true})
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  state = {}

  resetPassword() {
    if (this.validatePasswordForm()) {
      const {password} = this.state
      const {match: {params: {token}}} = this.props
      const {backendUrl} = this.context.config
      this.setState({changingPassword: true}, () => {
        backendRequest(`${backendUrl}/auth/user/password-reset`, {token, newPassword: password}, 'POST')
          .then((res) => {
            if (res.ok) {
              window.location = '/'
            } else {
              res.json()
                .then((errMessage) => {
                  this.setState({passwordErrors: errMessage.result, changingPassword: false})
                })
                .catch(() => {
                  this.setState({passwordErrors: res.statusText, changingPassword: false})
                })
            }
          })
          .catch((e) => {
            this.context.logError(e)
            this.setState({passwordErrors: e.message, changingPassword: false})
          })
      })
    }
  }

  validatePasswordForm() {
    const {passwordConfirmation, password} = this.state
    let passwordFormErrors = null
    if (!passwordConfirmation || !password) passwordFormErrors = 'Invalid Input'
    if (password !== passwordConfirmation) passwordFormErrors = 'Passwords do not match'
    this.setState({passwordFormErrors})
    return isEmpty(passwordFormErrors)
  }

  render() {
    const {passwordConfirmation = '', password = '', passwordErrors, passwordFormErrors} = this.state
    return (
      <Container className={style.centerContainer}>
        <Col sm={12} md={{span: 4, offset: 4}}>
          <Row>
            <Col sm={12}>
              <Row className={style.resetPasswordContainer}>
                <Col sm={12}>
                  <Row>
                    <Col xs={12}>
                      <div className={style.loginHeader}>
                        <span> <FontAwesomeIcon icon="lock" className={style.loginIcon} /> Reset Password</span>
                        <hr />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Row>
                        <Col xs={12}>
                          <Form.Group>
                            <label>New Password</label>
                            <Form.Control
                              type="password"
                              isInvalid={passwordErrors}
                              value={password}
                              placeholder="Enter New Password"
                              onChange={(e) => this.setState({password: e.target.value}, this.validatePasswordForm)}
                            />
                          </Form.Group>
                          <Form.Group>
                            <label>Confirm New Password</label>
                            <Form.Control
                              type="password"
                              isInvalid={passwordErrors}
                              value={passwordConfirmation}
                              placeholder="Confirm New Password"
                              onChange={(e) => this.setState({passwordConfirmation: e.target.value}, this.validatePasswordForm)}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12} className={style.textCenter}>
                          <Button
                            variant="info"
                            className={style.emailSignInButton}
                            disabled={!isEmpty(passwordFormErrors)}
                            onClick={() => this.resetPassword()}
                          >
                            Reset Your Password
                          </Button>
                          {(passwordErrors || passwordFormErrors) && <div className={style.loginError}>{passwordErrors || passwordFormErrors}</div>}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    )
  }
}
