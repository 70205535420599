import React from 'react'
import classnames from 'classnames'
import {Button, ButtonGroup, Row, Col, Table, Pagination, DropdownButton, Modal,
  Card, Dropdown, FormControl, InputGroup} from 'react-bootstrap'
import moment from 'moment'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import {List as Loading} from 'react-content-loader'
import {round, kebabCase, size, get, debounce, map, startCase, omit} from 'lodash'
import {tradingPositionStatuses, accountTypes, forexCommands, cuboidAssetMap, accountSubtypes} from '@bdswiss/common-enums'
import {getFormattedAmount, readableDate, getPageCount, getPageRange} from '../useful'
import {compareAccounts, getAccountLabel} from '../utils/general'
import DateTime from '../components/DateTime'
import style from './client.module.scss'
import compStyle from '../components/components.module.scss'
import PureComponent from '../PureComponent'
import {isForexAccount, pageSizeEnum} from '../common/utils'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

const resetPaging = {
  spotOptionsPositionsPage: 1,
  forexPositionsPage: 1,
  cuboidPositionsPage: 1,
  tradesmarterPositionsPage: 1,
}

const getFieldName = (field) => {
  switch (field) {
    case 'openedThroughApi':
      return 'Opened via Inhouse Product'
    case 'closedThroughApi':
      return 'Closed via Inhouse Product'
    case 'openedViaPlatform':
      return 'Opened via MT4 or MT5'
    case 'closedViaPlatform':
      return 'Closed via MT4 or MT5'
    case 'ticket':
      return 'Position ID'
    default:
      return startCase(field)
  }
}

const getFieldValue = (field, value) => {
  switch (field) {
    case 'openedThroughApi':
    case 'closedThroughApi':
    case 'openedViaPlatform':
    case 'closedViaPlatform':
      return value === true ? 'Yes' : 'No'
    case 'command':
      return <span style={{color: value === 'sell' ? 'red' : value === 'buy' ? 'green' : ''}}>{startCase(value)}</span>
    case 'openTime':
    case 'closeTime':
      return moment(value).isValid() ? readableDate(moment(value), true) : value
    default:
      return value
  }
}

export default class Positions extends PureComponent {

  state = {
    modalShown: false,
    modalId: null,
    modalMeta: {},
    positionsSearchFilter: '',
    positionsCloseDateFromFilter: '',
    positionsCloseDateToFilter: '',
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
  };

  static propTypes = {
    uiDispatch: PropTypes.func.isRequired,
    uiState: PropTypes.object.isRequired,
    filterDispatch: PropTypes.func.isRequired,
    filterState: PropTypes.object.isRequired,
    accounts: PropTypes.array,
  };

  constructor(props) {
    super(props)
    this.setCloseDateFromFilter = debounce(this.setCloseDateFromFilter, 1500)
    this.setCloseDateToFilter = debounce(this.setCloseDateToFilter, 1500)
  }

  componentDidUpdate(prevProps) {
    const {isActive, filterState, accounts} = this.props
    const {dataFetched, dataLoading, dataError} = this.state
    if ((isActive && !dataLoading && !dataError && !dataFetched) ||
      get(filterState, 'visibleAccount') !== get(prevProps.filterState, 'visibleAccount') ||
      get(filterState, 'tradingPositionStatusFilter') !== get(prevProps.filterState, 'tradingPositionStatusFilter') ||
      get(filterState, 'positionsPageSizeFilter') !== get(prevProps.filterState, 'positionsPageSizeFilter') ||
      get(filterState, 'spotOptionsPositionsPage') !== get(prevProps.filterState, 'spotOptionsPositionsPage') ||
      get(filterState, 'forexPositionsPage') !== get(prevProps.filterState, 'forexPositionsPage') ||
      get(filterState, 'cuboidPositionsPage') !== get(prevProps.filterState, 'cuboidPositionsPage') ||
      get(filterState, 'tradesmarterPositionsPage') !== get(prevProps.filterState, 'tradesmarterPositionsPage') ||
      get(filterState, 'positionsSearchFilter') !== get(prevProps.filterState, 'positionsSearchFilter') ||
      get(filterState, 'positionsCloseDateFromFilter') !== get(prevProps.filterState, 'positionsCloseDateFromFilter') ||
      get(filterState, 'positionsCloseDateToFilter') !== get(prevProps.filterState, 'positionsCloseDateToFilter')
    ) {
      this.setState({dataLoading: true}, () => {
        if (size(accounts) < 1) {
          Promise.all([
            this.context.clientProvider.subProviders.accounts.fetch(),
            this.context.clientProvider.subProviders.positions.fetch()
          ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
            .catch((e) => this.setState({dataLoading: false, dataError: e, dataFetched: true}))
        } else {
          this.context.clientProvider.subProviders.positions.fetch()
            .then(() => this.setState({dataLoading: false, dataFetched: true}))
            .catch((e) => this.setState({dataLoading: false, dataError: e, dataFetched: true}))
        }
      })
    }
  }

  setVisibleAccount(value) {
    this.props.filterDispatch(
      'Filter accounts',
      (state) => ({...state, visibleAccount: Number(value), ...resetPaging})
    )
  }

  setTradingPositionStatusFilter(value) {
    this.props.filterDispatch(
      'Filter positions',
      (state) => ({...state, tradingPositionStatusFilter: value, ...resetPaging})
    )
  }

  setPositionsPageSizeFilter(value) {
    this.props.filterDispatch(
      'Filter positions page size',
      (state) => ({...state, positionsPageSizeFilter: value, ...resetPaging})
    )
  }

  setPositionsSearchFilter(value) {
    this.props.filterDispatch(
      'Filter positions by search field',
      (state) => ({...state, positionsSearchFilter: value, ...resetPaging})
    )
  }

  setCloseDateFromFilter(value) {
    this.props.filterDispatch(
      'Filter positions by close date from',
      (state) => ({...state, positionsCloseDateFromFilter: value, ...resetPaging})
    )
  }

  setCloseDateToFilter(value) {
    this.props.filterDispatch(
      'Filter positions by close date to',
      (state) => ({...state, positionsCloseDateToFilter: value, ...resetPaging})
    )
  }

  doPositionsSearch = (value) => this.setState({positionsSearchFilter: value},
    () => this.setPositionsSearchFilter(value))

  showModal(modalId, position) {
    const modalMeta = {
      comment: position.comment,
      openedThroughApi: position.openedThroughApi,
      closedThroughApi: position.closedThroughApi,
      openedViaPlatform: !position.openedThroughApi,
      closedViaPlatform: !position.closedThroughApi,
      openUuid: position.openUuid,
      closeUuid: position.closeUuid,
    }
    this.setState({
      modalShown: true,
      modalId,
      modalMeta: {
        ...omit(position, ['comment', 'openedThroughApi', 'closedThroughApi']),
        ...modalMeta,
      },
    })
  }

  hideModal = () => this.setState({modalShown: false})

  renderTradingPositionStatusFilter = (tradingPositionStatus) => {
    const {tradingPositionStatusFilter} = this.props.filterState
    return (
      <Button
        id={`t-client-positions-${kebabCase(tradingPositionStatus.label)}`}
        key={tradingPositionStatus.key}
        size="sm"
        variant="outline-secondary"
        active={tradingPositionStatus.key === tradingPositionStatusFilter}
        onClick={() => this.setTradingPositionStatusFilter(tradingPositionStatus.key)}
      >
        {tradingPositionStatus.label}
      </Button>
    )
  }

  renderPositionsPageSizeFilter = (pageSize) => {
    const {positionsPageSizeFilter} = this.props.filterState
    return (
      <Button
        id={`t-client-positions-${pageSize.label}`}
        key={pageSize.key}
        size="sm"
        variant="outline-secondary"
        onClick={() => this.setPositionsPageSizeFilter(pageSize.value)}
        active={pageSize.key === positionsPageSizeFilter}
      >
        {pageSize.label}
      </Button>
    )
  }

  renderSpotOptionPosition = (position, i) => {
    const {name, position: command, amount, openRate, openTime, closeRate, closeTime, payout, currency,
      status, format} = position
    const openMoment = moment(openTime)
    const closeMoment = closeTime && moment(closeTime)
    return (
      <tr key={i}>
        <td>{name}</td>
        <td>{command}</td>
        <td>{getFormattedAmount({amount: round(amount, 2), currency})}</td>
        <td>{openRate && round(openRate, 5)}</td>
        <td>{readableDate(openMoment)}</td>
        <td>{closeRate && round(closeRate, 5)}</td>
        {closeMoment
          ? (<td>{readableDate(closeMoment)}</td>)
          : (<td>&nbsp;</td>)
        }
        <td>{getFormattedAmount({amount: round(payout, 2), currency})}</td>
        <td>{status}</td>
        <td>{format}</td>
      </tr>
    )
  }

  renderSpotOptionPositions = (account = {}) => {
    const {spotOptionsPositionsPage: page, positionsPageSizeFilter} = this.props.filterState
    const positions = account.spotOptionPositions
    const positionsCount = account.spotOptionPositionsCount
    const spotOptionsPositionsPage = page || 1

    if (!positions) {
      return null
    } else if (!positions.length) {
      return <div><br />No Positions.</div>
    }

    return (
      <div>
        <Table bordered className={style.spotOptionPositions}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Position</th>
              <th>Amount</th>
              <th>Open Rate</th>
              <th>Open Time</th>
              <th>Close Rate</th>
              <th>Comment</th>
              <th>Close Time</th>
              <th>Payout</th>
              <th>Status</th>
              <th>Format</th>
            </tr>
          </thead>
          <tbody>
            {positions.map(this.renderSpotOptionPosition)}
          </tbody>
        </Table>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.filterDispatch(
                'Show page',
                (state) => ({...state, spotOptionsPositionsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(spotOptionsPositionsPage, getPageCount(positionsCount, positionsPageSizeFilter)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, spotOptionsPositionsPage: spotOptionsPositionsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, spotOptionsPositionsPage: spotOptionsPositionsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === spotOptionsPositionsPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.filterDispatch(
                    'Show page',
                    (state) => ({...state, spotOptionsPositionsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </div>
    )
  }

  renderForexPosition = (position, i) => {
    const {ticket, symbol, command, volume, openRate, openTime, closeRate, closeTime,
      profit, stopLoss, takeProfit, commission, swaps, comment} = position
    const openMoment = moment(openTime)
    const closeMoment = closeTime && moment(closeTime)
    return (
      <tr key={i}>
        <td>{ticket}</td>
        <td>{symbol}</td>
        <td>{command && forexCommands[command].label}</td>
        <td>{volume}</td>
        <td>{openRate && round(openRate, 5)}</td>
        <td>{readableDate(openMoment)}</td>
        <td>{stopLoss}</td>
        <td>{takeProfit}</td>
        <td>{closeRate && round(closeRate, 5)}</td>
        <td>{comment}</td>
        {closeMoment
          ? (<td>{readableDate(closeMoment)}</td>)
          : (<td>&nbsp;</td>)
        }
        <td>{commission}</td>
        <td>{swaps}</td>
        <td>{profit}</td>
        <td>
          <Button
            variant="outline-secondary"
            className="btn-xs"
            onClick={() => this.showModal(ticket, position)}
          >...</Button>
        </td>
      </tr>
    )
  }

  renderForexPositions = (account = {}) => {
    const {forexPositionsPage: page, positionsPageSizeFilter} = this.props.filterState
    const positions = account.forexPositions
    const positionsCount = account.forexPositionsCount
    if (!positions) {
      return null
    } else if (!positions.length) {
      return <div><br />No Positions.</div>
    }

    const forexPositionsPage = page || 1

    return (
      <div>
        <Table bordered className={style.forexPositions}>
          <thead>
            <tr>
              <th>Ticket</th>
              <th>Symbol</th>
              <th>Command</th>
              <th>Volume</th>
              <th>Open Rate</th>
              <th>Open Time</th>
              <th>S/L</th>
              <th>T/P</th>
              <th>Close Rate</th>
              <th>Comment</th>
              <th>Close Time</th>
              <th>Commision</th>
              <th>Swaps</th>
              <th>Profit</th>
              <th>&nbsp;</th>
            </tr>
          </thead>
          <tbody>
            {positions.map(this.renderForexPosition)}
          </tbody>
        </Table>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.filterDispatch(
                'Show page',
                (state) => ({...state, forexPositionsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(forexPositionsPage, getPageCount(positionsCount, positionsPageSizeFilter)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, forexPositionsPage: forexPositionsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, forexPositionsPage: forexPositionsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === forexPositionsPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.filterDispatch(
                    'Show page',
                    (state) => ({...state, forexPositionsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </div>
    )
  }

  renderCuboidPosition = (position, i) => {
    const {upperBound, failureValue, investment, payout, failureTime, assetId, resultedPrice,
      expiryTime, currency, startTime, lowerBound} = position
    const openMoment = moment(startTime)
    const closeMoment = (failureTime && moment(failureTime)) || (expiryTime && moment(expiryTime))
    return (
      <tr key={i}>
        <td>{assetId && cuboidAssetMap[assetId] && cuboidAssetMap[assetId].label}</td>
        <td>{upperBound}</td>
        <td>{lowerBound}</td>
        <td>{readableDate(openMoment)}</td>
        <td>{getFormattedAmount({amount: round(investment, 2), currency})}</td>
        <td>{failureValue}</td>
        <td>{resultedPrice}</td>
        {closeMoment
          ? (<td>{readableDate(closeMoment)}</td>)
          : (<td>&nbsp;</td>)
        }
        <td>{getFormattedAmount({amount: round(payout || 0, 2), currency})}</td>
      </tr>
    )
  }

  renderCuboidPositions = (account = {}) => {
    const {cuboidPositionsPage: page, positionsPageSizeFilter} = this.props.filterState
    const positions = account.cuboidPositions
    const positionsCount = account.cuboidPositionsCount
    const cuboidPositionsPage = page || 1
    if (positionsCount === 0) {
      return <div><br />No Positions.</div>
    }
    if (!positions) {
      return null
    }

    return (
      <div>
        <Table bordered className={style.cuboidPositions}>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Upper Bound</th>
              <th>Lower Bound</th>
              <th>Open Time</th>
              <th>Investment</th>
              <th>Failure Value</th>
              <th>Resulted Price</th>
              <th>Close/Failure Time</th>
              <th>Payout</th>
            </tr>
          </thead>
          <tbody>
            {positions.map(this.renderCuboidPosition)}
          </tbody>
        </Table>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.filterDispatch(
                'Show page',
                (state) => ({...state, cuboidPositionsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(cuboidPositionsPage, getPageCount(positionsCount, positionsPageSizeFilter)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, cuboidPositionsPage: cuboidPositionsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, cuboidPositionsPage: cuboidPositionsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === cuboidPositionsPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.filterDispatch(
                    'Show page',
                    (state) => ({...state, cuboidPositionsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </div>
    )
  }

  renderTradesmarterPosition = (position, i) => {
    const {tradeId, volume, currency, instrument, expiry, referencePrice,
      expiryPrice, payout, pnl, direction, result, status} = position
    const expiryMoment = moment(expiry)
    return (
      <tr key={i}>
        <td>{tradeId}</td>
        <td>{getFormattedAmount({amount: round(volume, 2), currency})}</td>
        <td>{instrument}</td>
        <td>{referencePrice}</td>
        <td>{expiryPrice}</td>
        <td>{payout}%</td>
        <td>{readableDate(expiryMoment)}</td>
        <td>{getFormattedAmount({amount: round(pnl, 2), currency})}</td>
        <td>{direction === -1 ? 'low' : 'high'}</td>
        <td>{this.getResult(result, status)}</td>
      </tr>
    )
  }

  getResult(result, status) {
    let output
    if (result === -1 && status === 'processed') {
      output = 'lost'
    } else if (result === 2 && status === 'processed') {
      output = 'tie'
    } else if (result === 3 && status === 'processed') {
      output = 'sold'
    } else if (result === 1 && status === 'processed') {
      output = 'won'
    } else if (result === 0 && status === 'active') {
      output = 'open'
    } else if (result === 0 && status === 'canceled') {
      output = 'canceled'
    }
    return output
  }

  renderTradesmarterPositions = (account = {}) => {
    const {tradesmarterPositionsPage: page, positionsPageSizeFilter} = this.props.filterState
    const positions = account.tradesmarterPositions
    const positionsCount = account.tradesmarterPositionsCount
    const tradesmarterPositionsPage = page || 1
    if (positionsCount === 0) {
      return <div><br />No Positions.</div>
    }
    if (!positions) {
      return null
    }

    return (
      <div>
        <Table bordered className={style.tradesmarterPositions}>
          <thead>
            <tr>
              <th>Trade ID</th>
              <th>Volume</th>
              <th>Instrument</th>
              <th>Reference Price</th>
              <th>Expiry Price</th>
              <th>Payout</th>
              <th>Expiry Time</th>
              <th>PnL</th>
              <th>Direction</th>
              <th>Result</th>
            </tr>
          </thead>
          <tbody>
            {positions.map(this.renderTradesmarterPosition)}
          </tbody>
        </Table>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.filterDispatch(
                'Show page',
                (state) => ({...state, tradesmarterPositionsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(tradesmarterPositionsPage, getPageCount(positionsCount, positionsPageSizeFilter)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, tradesmarterPositionsPage: tradesmarterPositionsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.filterDispatch(
                      'Show page',
                      (state) => ({...state, tradesmarterPositionsPage: tradesmarterPositionsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === tradesmarterPositionsPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.filterDispatch(
                    'Show page',
                    (state) => ({...state, tradesmarterPositionsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const {accounts, filterState: {visibleAccount}} = this.props
    const {dataLoading, dataError, modalShown, modalMeta, modalId,
      positionsCloseDateToFilter, positionsCloseDateFromFilter} = this.state
    const positionsSearchFilter = this.state.positionsSearchFilter ?? this.props.positionsSearchFilter

    if (!accounts || dataLoading) return <Loading speed={1} style={{padding:'20px'}}/>
    if (dataError) return <pre> Error Loading Positions : {dataError.message} </pre>

    if (accounts.length === 0) {
      return (
        <div>
          No accounts.
        </div>
      )
    }

    const sortedAccounts = [...accounts].sort(compareAccounts)

    const selectedAccount = visibleAccount != null
      ? sortedAccounts.find((account) => account.id === visibleAccount)
      : sortedAccounts[0]
    const selectedAccountRemoteId = selectedAccount && selectedAccount.remoteId
    const dateTimeInputProps = {
      style: {
        fontSize: '0.9rem',
        lineHeight: 1,
        height: 'auto',
      }
    }
    const subtypeLabel = isForexAccount(selectedAccount)
      ? ` - ${accountSubtypes[selectedAccount.accountSubtype]?.label ?? accountSubtypes.basic.label}`
      : ''
    return (
      <div>
        <Row className="mb-2">
          <Col xs={12}>
            <DropdownButton
              title={`${accountTypes[selectedAccount.__typename].label}${subtypeLabel} [${selectedAccountRemoteId}]`}
              onSelect={(event) => this.setVisibleAccount(event)}
              id="t-client-positions-accounts"
              variant="outline-secondary"
              size="sm"
              className="float-left mr-3"
            >
              {sortedAccounts.map((account, i) => {
                const remoteId = account && account.remoteId
                const subtypeLabel = isForexAccount(account)
                  ? ` - ${accountSubtypes[account.accountSubtype]?.label ?? accountSubtypes.basic.label}`
                  : ''
                return (
                  <Dropdown.Item key={i} eventKey={account.id}>
                    {`${getAccountLabel(accountTypes[account.__typename])}${subtypeLabel} [${remoteId}]`}
                  </Dropdown.Item>
                )
              }
              )}
            </DropdownButton>
            <ButtonGroup className="float-left mr-3">
              {Object.values(pageSizeEnum).map(this.renderPositionsPageSizeFilter)}
            </ButtonGroup>
            <ButtonGroup className="float-left mr-3">
              {Object.values(tradingPositionStatuses).map(this.renderTradingPositionStatusFilter)}
            </ButtonGroup>
            <Button
              id="t-client-positions-refresh-button"
              className="float-right"
              variant="success"
              size="sm"
              onClick={this.context.clientProvider.subProviders.positions.fetch}
            >
              Refresh
            </Button>
          </Col>
        </Row>
        <Row className="mb-1">
          <Col xs={12}>
            <InputGroup
              className="float-left mr-3"
              style={{width: '247'}}
              size="sm"
            >
              <FormControl
                type="text"
                placeholder="Search Ticket or Symbol"
                onChange={(e) => this.setState({positionsSearchFilter: e.target.value})}
                value={positionsSearchFilter}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doPositionsSearch(positionsSearchFilter)) ||
                  (e.key === 'Escape' && this.doPositionsSearch(''))
                )}
              />
              <InputGroup.Append>
                {positionsSearchFilter && <Button
                  key={1}
                  title="Clear"
                  variant={positionsSearchFilter ? 'success' : 'outline-dark'}
                  onClick={(e) => this.doPositionsSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={positionsSearchFilter ? 'success' : 'outline-dark'}
                  onClick={(e) => this.doPositionsSearch(positionsSearchFilter)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
            <DateTime
              timeFormat={false}
              onChange={
                (v) => this.setState({positionsCloseDateFromFilter: v},
                  () => this.setCloseDateFromFilter(moment.isMoment(v) ? v.startOf('day').toISOString() : ''))
              }
              value={positionsCloseDateFromFilter ?? this.props.positionsCloseDateFromFilter}
              closeOnSelect
              className="float-left mr-2"
              inputProps={{...dateTimeInputProps, placeholder: 'Close time from'}}
            />
            <DateTime
              timeFormat={false}
              onChange={
                (v) => this.setState({positionsCloseDateToFilter: v},
                  () => this.setCloseDateToFilter(moment.isMoment(v) ? v.endOf('day').toISOString() : ''))
              }
              value={positionsCloseDateToFilter ?? this.props.positionsCloseDateToFilter}
              closeOnSelect
              className="float-left"
              inputProps={{...dateTimeInputProps, placeholder: 'Close time to'}}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12} style={{overflowX: 'scroll'}}>
            {this.renderSpotOptionPositions(selectedAccount)}
            {this.renderForexPositions(selectedAccount)}
            {this.renderCuboidPositions(selectedAccount)}
            {this.renderTradesmarterPositions(selectedAccount)}
          </Col>
        </Row>
        <Modal
          keyboard
          show={modalShown}
          onHide={this.hideModal}
          className={classnames([style.receipt, compStyle.modal])}
        >
          <Modal.Header closeButton className={style.modalHeader}>
            Position #{modalId}
          </Modal.Header>
          <Modal.Body>
            <Card className={style.receiptContent}>
              <Card.Body>
                <Table bordered className={style.spotOptionPositions}>
                  <tbody>
                    {map(Object.keys(modalMeta), (key) => (
                      <tr>
                        <td>{getFieldName(key)}</td>
                        <td
                          onClick={() => {navigator.clipboard.writeText(modalMeta[key])}}
                        >
                          {getFieldValue(key, modalMeta[key])}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}
