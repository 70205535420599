import React from 'react'
import {filter} from 'lodash'
import classNames from 'classnames'
import {Table} from 'react-bootstrap'
import {appropTestStatuses, companies} from '@bdswiss/common-enums'
import {canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import style from './documents.module.scss'
import PureComponent from '../PureComponent'
import {linkClientAppropTests} from '../utils/links'
import {getDocumentStatusLabel} from '../utils/rendering'
import {getFullName, readableDate, getCountryName} from '../useful'

export default class ListAppropTests extends PureComponent {
  render() {
    const {appropTests, viewer} = this.props
    if (!appropTests.length) {
      return null
    }

    return (
      <Table bordered hover className={style.table}>
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Company</th>
            <th>Country</th>
            <th>Type</th>
            <th>Created</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {filter(appropTests, (a) => a.client).map(
            (appropTest) => <AppropTest key={appropTest.id} appropTest={appropTest} viewer={viewer} />
          )}
        </tbody>
      </Table>
    )
  }
}

const AppropTest = ({appropTest, viewer}) => {
  const {id, status, client, createdAt} = appropTest
  const clientId = client.id
  const linkEnabled = !canQueryAffiliateManagerClients(viewer)

  return (
    <tr className={classNames(['t-approp-test', client.topVip ? 'vip' : ''])}>
      <td>
        {linkClientAppropTests(getFullName(client), clientId, id, linkEnabled)}
      </td>
      <td>
        {linkClientAppropTests(companies[client.company].label, clientId, id, linkEnabled)}
      </td>
      <td>
        {linkClientAppropTests(getCountryName(client.address), clientId, id, linkEnabled)}
      </td>
      <td>
        {linkClientAppropTests('Appropriateness Test', clientId, id, linkEnabled)}
      </td>
      <td title={createdAt.fromNow()}>
        {linkClientAppropTests(readableDate(createdAt), clientId, id, linkEnabled)}
      </td>
      <td>
        {linkClientAppropTests(status ? getDocumentStatusLabel(appropTestStatuses[status]) : '\xA0', clientId, id, linkEnabled)}
      </td>
    </tr>
  )
}
