import React from 'react'
import moment from 'moment'
import {debounce, get} from 'lodash'
import {Row, Col} from 'react-bootstrap'
import style from './agentsCommissions.module.scss'
import DateTime from '../components/DateTime'
import {compose, provideProps, uiMount} from '../decorators'

class AgentsCommissionsFilter extends React.Component {
  constructor(props) {
    super(props)
    this.dispatchFilterDebounced = debounce(this.dispatchFilter, 1500)
    this.state = {}
  }

  componentWillMount() {
    this.setState(() => {})
  }

  dispatchFilter(key, value) {
    this.props.dispatch('Update agents commissions filters', (state) => ({
      ...state,
      agentsCommissionsFilter: {
        ...state.agentsCommissionsFilter,
        [key]: value
      }
    }))

    this.props.uiDispatch(
      'Search call logs',
      (state, arg) => ({
        ...state,
        agentsCommissionsPage: 1,
        agentsCommissionsFilter: {
          ...state.agentsCommissionsFilter,
          ...arg
        }}),
      [{...this.state}]
    )
  }

  applyFilter(key, value, delaySearch = false) {
    this.setState({[key]: value}, () => {
      if (delaySearch) {
        this.dispatchFilterDebounced()
      } else {
        this.dispatchFilter(key, value)
      }
    })
  }

  render() {
    const {startDate, endDate} = this.state

    return (
      <Row key="filter-row-1" className={style.filter}>
        <Col xs={2}>
          <span className={style.label}>Date From</span>
          <DateTime
            id="t-agents-commissions-date-from-filter"
            timeFormat={'YYYY-MM-DD'}
            value={startDate || get(this.props, 'agentsCommissionsFilter.startDate')}
            onFocus={this.dispatchFilterDebounced.cancel}
            onChange={(value) => this.applyFilter('startDate', moment.isMoment(value) ? value.format('YYYY-MM-DD') : '')}
            closeOnSelect
            className={style.datetime}
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}>Date To</span>
          <DateTime
            id="t-agents-commissions-date-to-filter"
            timeFormat={'YYYY-MM-DD'}
            value={endDate || get(this.props, 'agentsCommissionsFilter.endDate')}
            onFocus={this.dispatchFilterDebounced.cancel}
            onChange={(value) => this.applyFilter('endDate', moment.isMoment(value) ? value.format('YYYY-MM-DD') : '')}
            closeOnSelect
            className={style.datetime}
          />
        </Col>
      </Row>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'agentsCommissions']),
  provideProps((state, uiState) => {
    const {agentsCommissionsPage, agentsCommissionsFilter} = uiState

    return ({
      agentsCommissionsFilter,
      agentsCommissionsPage: agentsCommissionsPage || 1,
    })
  }),
)(AgentsCommissionsFilter)
