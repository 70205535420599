import {includes, pickBy, get, remove, filter, find, map,
  isEqual, keys, isEmpty, identity, mapValues, xor, isArray} from 'lodash'
import {companies, accountTypes,  socketEvents, newAccountCurrencies, countries,
  withdrawalPaymentVendors, newCryptoWalletCurrencies,
} from '@bdswiss/common-enums'
import {getFormattedAmount} from '../useful'

export function getAllowedCompanies(viewer) {
  return pickBy(companies, (c) => includes(get(viewer, 'companies'), c.value))
}

export function isIntroducingBrokerAccount({__typename}) {
  return [
    accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    accountTypes.IntroducingBrokerAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    accountTypes.IntroducingBrokerMauritiusAccount.key,
    accountTypes.IntroducingBrokerEquityFlowAccount.key,
    accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
  ].includes(__typename)
}


export function getFullName(userOrClient, defaultValue = '') {
  if (!userOrClient) {
    return defaultValue
  }
  return [userOrClient.firstName, userOrClient.lastName].join(' ')
}

export function getCountryOfResidence(userOrClient, defaultValue = '') {
  if (!userOrClient) {
    return defaultValue
  }
  let country = get(userOrClient, 'personalDetails.address.country')
  if (!country) {
    country = get(userOrClient, 'address.country')
  }
  return country
}

/**
 * Replaces all occurences of `${argument}` in `text` by `parameters.argument`.
 *
 * @param text - text to be replaced
 * @param parameters - a key value collection. The function support nested objects, `something.else` wont work.
 * @returns string
 */
export function replaceParameters(text, parameters) {
  text = text.replace(/\$\{([a-zA-Z0-9_]*)\}/g, (match, group) => parameters[group] || '')

  return text
}

export function compareAccounts(a, b) {
  if (a.deletedAt ?? a.isArchived) {
    return 1
  }
  return accountTypes[a.__typename].orderBy - accountTypes[b.__typename].orderBy
}

export const compareByCreatedAt = (a, b) => a.createdAt ? -a.createdAt.diff(b.createdAt) : Number.MAX_VALUE

export const companyCheck = {
  isSwissMarkets: (company) => company === companies.swissMarkets.value,
  isSwissMarketsMauritius: (company) => company === companies.swissMarketsMauritius.value,
  isSwissMarketsVanuatu: (company) => company === companies.swissMarketsVanuatu.value,
  isSwissMarketsBrand: (company) => includes([
    companies.swissMarkets.value,
    companies.swissMarketsMauritius.value,
    companies.swissMarketsVanuatu.value,
  ], company),
  isBdSwiss: (company) => company === companies.bdSwiss.value,
  isBdSwissMauritius: (company) => company === companies.bdSwissMauritius.value,
  isBdSwissVanuatu: (company) => company === companies.bdSwissVanuatu.value,
  isBdSwissBrand: (company) => includes([
    companies.bdSwiss.value,
    companies.bdSwissMauritius.value,
    companies.bdSwissVanuatu.value], company),
  isGlobal: (company) => includes([
    companies.bdSwissMauritius.value,
    companies.swissMarketsMauritius.value], company),
  isBitrace: (company) => company === companies.bitrace.value,
  isBDX: (company) => company === companies.bdxAccounts.value,
  isCysec: (company) => includes([
    companies.bdSwiss.value,
    companies.swissMarkets.value], company),
  isMakeYourDay: (company) => company === companies.makeYourDay.value,
  isCleanCoin: (company) => company === companies.cleanCoinAccounts.value,
  isCryptoCoinBrand: (company) => includes([
    companies.bitrace.value,
    companies.bdxAccounts.value,
    companies.makeYourDay.value,
    companies.cleanCoinAccounts.value,
  ], company),
  isNonRegulated: (company) => includes([
    companies.bdSwissVanuatu.value,
    companies.swissMarketsVanuatu.value], company),
  isSey: (company) => includes([
    companies.bdSwissVanuatu.value,
    companies.swissMarketsVanuatu.value,
  ], company),
}

export const getCompanyfromRequest = (req, companyUrlPatterns) => {
  if (req.body && req.body.company) return req.body.company
  let assumedCompany = companies.bdSwiss.value
  const hostname = req.headers.host || req.headers.hostname
  if (new RegExp(companyUrlPatterns.swissMarketsVanuatu, 'i').test(hostname)) {
    assumedCompany = companies.swissMarketsVanuatu.value
  } else if (new RegExp(companyUrlPatterns.swissMarketsMauritius, 'i').test(hostname)) {
    assumedCompany = companies.swissMarketsMauritius.value
  } else if (new RegExp(companyUrlPatterns.swissMarkets, 'i').test(hostname)) {
    assumedCompany = companies.swissMarkets.value
  } else if (new RegExp(companyUrlPatterns.bdSwissVanuatu, 'i').test(hostname)) {
    assumedCompany = companies.bdSwissVanuatu.value
  } else if (new RegExp(companyUrlPatterns.bdSwissMauritius, 'i').test(hostname)) {
    assumedCompany = companies.bdSwissMauritius.value
  } else if (new RegExp(companyUrlPatterns.bitrace, 'i').test(hostname)) {
    assumedCompany = companies.bitrace.value
  } else if (new RegExp(companyUrlPatterns.bdxAccounts, 'i').test(hostname)) {
    assumedCompany = companies.bdxAccounts.value
  } else if (new RegExp(companyUrlPatterns.makeYourDay, 'i').test(hostname)) {
    assumedCompany = companies.makeYourDay.value
  } else if (new RegExp(companyUrlPatterns.cleanCoinAccounts, 'i').test(hostname)) {
    assumedCompany = companies.cleanCoinAccounts.value
  }

  return assumedCompany
}

export function joinRoom(room) {
  if (window.ioSocket) {
    if (!includes(window.ioSocket.rooms, room)) {
      window.ioSocket.rooms.push(room)
    }
    window.ioSocket.emit(socketEvents.join.value, room)
  }
}

export function leaveRoom(room) {
  if (window.ioSocket) {
    remove(window.ioSocket.rooms, (r) => r === room)
    window.ioSocket.emit(socketEvents.leave.value, room)
  }
}


export function getAccountName(accountName, company) {
  let normalizedValue
  switch (company) {
    case companies.bdSwissMauritius.value:
      if (accountName === 'forex') {
        normalizedValue = accountTypes.ForexMauritiusAccount.value
      } else if (accountName === 'binary') {
        normalizedValue = accountTypes.SpotOptionMauritiusAccount.value
      } else if (accountName === 'cuboid') {
        normalizedValue = accountTypes.CuboidMauritiusAccount.value
      } else if (accountName === 'tradesmarter') {
        normalizedValue = accountTypes.TradesmarterMauritiusAccount.value
      }
      break
    case companies.bdSwissVanuatu.value:
      if (accountName === 'forex') {
        normalizedValue = accountTypes.ForexVanuatuAccount.value
      } else if (accountName === 'binary') {
        normalizedValue = accountTypes.SpotOptionVanuatuAccount.value
      } else if (accountName === 'cuboid') {
        normalizedValue = accountTypes.CuboidVanuatuAccount.value
      } else if (accountName === 'tradesmarter') {
        normalizedValue = accountTypes.TradesmarterVanuatuAccount.value
      }
      break
    default:
      return accountName
  }
  return normalizedValue
}

export function getVoxUpdateMessage(version) {
  const messages = [
    `In our continuous effort to make vox a better place, please enjoy the latest update ,<b>[${version}]</b>`,
    `Hey, I just got an update! , reload to enjoy my best features to date <b>[${version}]</b>`,
    `Yeah right, another update while i'm working :) Enjoy <b>[${version}]</b> with ♥ from the Dev Team! `,
  ]
  const idx = Math.floor(Math.random() * 3)
  return messages[idx]
}

export function getLeadAssignmentNotifMessage(data) {
  const {agent, campaign, clientCountry, clientName, reassignmentParams} = data

  let message = `${agent ? `${agent.firstName}, ` : ''}`
  if (reassignmentParams) {
    message += reassignmentParams.reassigned ? 'You\'ve just been re-assigned a pooled lead' : 'Your pooled lead'
    message += ` ${clientName}, ${!reassignmentParams.reassigned ? 'has been ' : ' '}`
    message += `reactivated by recent action [${reassignmentParams.activationReason}]`
  } else {
    message += `You've just been assigned a new lead [${clientName}]`
    message += clientCountry ? ` from [${clientCountry}]` : ''
    message += campaign ? ` with originating campaign ${campaign}.` : '.'
  }
  return message
}

export const getBrowserLanguageFromRequest = (req) => get(req, 'headers[accept-language]', '').split(',')[0]

export function getNewAccountCurrencyOptions(account) {
  if (!account) return []
  const accountType = accountTypes[account]
  let availableCurrencies = []
  if (accountType.category === 'cryptoWallet') {
    availableCurrencies = filter(newCryptoWalletCurrencies,
      (c) => !includes(get(accountType, 'disallowedCurrencies'), c.key))
  } else {
    availableCurrencies = filter(newAccountCurrencies,
      (c) => !includes(get(accountType, 'disallowedCurrencies'), c.key))
  }
  return Object.values(availableCurrencies).map((c) => {
    const symbol = c.symbol || c.key
    return {key: c.key, label: `${c.label} (${symbol})`}
  })
}

export function isCountryEU(countryKey) {
  countryKey = countryKey || ''
  const country = find(countries, {key: countryKey.toLowerCase()})
  return country && country.isEU
}

export const getGoldBalance = (gold) => {
  gold = Number(gold)
  return `${(gold || 0).toFixed(2)} oz / ${(gold * 28.3495).toFixed(2)} gr`
}

export function isCryptoVendor(paymentVendor) {
  const isCryptoVendor = includes(map(filter(withdrawalPaymentVendors, {isCrypto: true}), 'key'), paymentVendor)
  return isCryptoVendor
}

export function getAccountLabel(account) {
  switch (account.key) {
    case accountTypes.ForexMauritiusAccountBDX.key:
      return `${account.label} - BDX`
    default:
      return account.label
  }
}

export function formatAccountValue(account, key) {
  const value = get(account, `[${key}]`)
  return value !== null
    ? getFormattedAmount({amount: value, currency: account.currency})
    : 'No data available'
}

export function formatPercentValue(account, key, defaultValue = 'No data available') {
  const value = get(account, key)
  if (value !== null) {
    const num = Number.parseFloat(value)
    if (isNaN(num)) return num
    return new Intl.NumberFormat([], {
      style: 'percent',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value / 100)
  }
  return defaultValue
}

export function getWithdrawalVendorLabel(vendor) {
  return get(withdrawalPaymentVendors[vendor], 'crmLabel') || get(withdrawalPaymentVendors[vendor], 'label')
}

export function objectsDifference(object1, object2) {
  const differences = mapValues(object1, (current, key) => (
    isArray(current) ?
      !isEmpty(xor(current, object2[key])) : !isEqual(current, object2[key])
  ))
  return keys(pickBy(differences, identity))
}
