import React from 'react'
import PropTypes from 'prop-types'
import {Modal, DropdownButton, Dropdown, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {companies} from '@bdswiss/common-enums'
import {filter, map} from 'lodash'

export default class ChangeCompanyModal extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    client: PropTypes.object,
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    header: PropTypes.string,
    saveAction: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const {client: {company: clientCompany}} = this.props
    const clientCompanyLabel = companies[clientCompany].label
    const companyBrands = filter(companies, (c) =>
      c.brand === companies[clientCompany].brand && c.value !== companies[clientCompany].value)

    this.setState({hideConfirmation: true, btnText: clientCompanyLabel,
      oldCompany: clientCompany, companyBrands: companyBrands, buttonDisabled: false})
  }

  trySave() {
    this.setState({buttonDisabled: true})
    const {newCompany} = this.state

    newCompany && this.props.saveAction(this.props.client.id, newCompany)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.context.clientProvider.subProviders.accounts.fetch()
        this.setState({buttonDisabled: false})
        this.props.onHide()
      })
      .catch((error) => {
        error.isShowActualError = true
        this.context.logError(error)
        this.setState({buttonDisabled: false})
      })
  }

  onSelectCompany(selectedCompany) {
    const companyLabel = companies[selectedCompany].label
    const {oldCompany} = this.state

    this.setState({newCompany: selectedCompany, btnText: companyLabel,
      hideConfirmation: oldCompany === selectedCompany})
  }

  render() {
    const {onHide, show} = this.props
    const {companyBrands, buttonDisabled} = this.state
    const confirmationText = 'Are you sure you want to change client\'s company?'

    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          {this.props.header && <strong>{this.props.header}</strong>}
        </Modal.Header>
        <Modal.Body>
          <DropdownButton
            id="dropdown-btn-change-company"
            title={this.state.btnText}
            variant="outline-secondary"
            size="sm"
            onSelect={(event) => this.onSelectCompany(event)}
          >
            { map(companyBrands, (company) =>
              (<Dropdown.Item
                className={`${company.brand}-brand-company-menu-item`}
                key={company.key}
                eventKey={company.key}
              >
                {company.label}
              </Dropdown.Item>)
            )
            }
          </DropdownButton>
        </Modal.Body>
        <Modal.Footer>
          <p hidden={this.state.hideConfirmation}> {confirmationText} </p>
          <ButtonToolbar hidden={this.state.hideConfirmation} bsClass="float-right">
            <Button
              id="change-company-yesbtn"
              variant="success"
              size="sm"
              className="mr-2"
              onClick={this.trySave.bind(this)}
              disabled={buttonDisabled}
            >Yes</Button>
            <Button
              id="change-company-nobtn"
              variant="outline-secondary"
              size="sm"
              onClick={onHide}
              disabled={buttonDisabled}
            >No</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
