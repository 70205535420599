import {isEmpty} from 'lodash'
import {getOffset} from '../useful'

export default function create(dbClient) {
  return {
    communicationsExportSearch(props, page, limit) {

      const {communicationsSearch, communicationsChannelsFilter, communicationsTypesFilter, dateFilters,
        communicationsDepartmentsFilter, communicationsCompaniesFilter, communicationsSalesAgentFilter,
        communicationsExcludeAutoEmailsFilter,
      } = props
      const conditions = [
        communicationsSearch ? `search: "${communicationsSearch}"` : '',
        !isEmpty(communicationsChannelsFilter) ? `channels: [${communicationsChannelsFilter}]` : '',
        !isEmpty(communicationsTypesFilter) ? `types: [${communicationsTypesFilter}]` : '',
        !isEmpty(communicationsDepartmentsFilter) ? `departments: [${communicationsDepartmentsFilter}]` : '',
        dateFilters && dateFilters.createdFrom ? `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : '',
        dateFilters && dateFilters.createdTo ? `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : '',
        !isEmpty(communicationsCompaniesFilter) ? `companies: [${communicationsCompaniesFilter}]` : '',
        communicationsSalesAgentFilter ? `salesAgentId: ${communicationsSalesAgentFilter}` : '',
        communicationsExcludeAutoEmailsFilter ? 'excludeAutomatedEmails: true' : ''
      ].join('\n').trim()

      const query = `{
        communications (
          ${conditions}
          limit: ${limit}
          offset: ${getOffset(page, limit)}
          orderBy: createdAt
          orderDirection: descending
        )
        {
          id
          channels
          type
          department
          title
          summary
          meta
          createdAt
          foreignTable
          foreignId
          client {
            id
            firstName
            lastName
            salesAgent {
              id
              firstName
              lastName
            }
          }
        }
      }`

      return dbClient.query(query)
    },
  }
}
