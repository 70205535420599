import React from 'react'
import PureComponent from '../PureComponent'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import PartnersCustomizationForm from './PartnersCustomizationForm'

export default class PartnersCustomizationCreate extends PureComponent {
  render() {
    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/partners-customization"
            active={false}
          >
            <BreadcrumbItem>
              Back to Partners Customization
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            Create
          </BreadcrumbItem>
        </Breadcrumb>
        <PartnersCustomizationForm />
      </Container>
    )
  }
}
