import React from 'react'
import {get} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import style from './alertLog.module.scss'
import {readableDate} from '../useful'
import PureComponent from '../PureComponent'

export default class AlertLog extends PureComponent {
  static propTypes = {
    log: PropTypes.object.isRequired,
  }

  render() {
    const {log} = this.props
    return (
      <div className={style.log} key={log.createdAt}>
        <div className={style.message}>
          {log.type === 'postpone' &&
            <span className={style.postpone}>
              Postponed <abbr title={readableDate(moment(log.postponeUntil))}>
                {moment(log.postponeUntil).fromNow().replace('in', 'for')}
              </abbr>
            </span>
          }
          {log.type === 'comment' && <span>{log.comment}</span>}
        </div>
        <div className={style.meta}>
          <div className={style.userAndTime}>
            <div className={style.user}>
              {get(log, 'agent.firstName', '')} {get(log, 'agent.lastName', '')} {' '}
            </div>
            <abbr title={readableDate(moment(log.createdAt))}>{moment(log.createdAt).fromNow()}</abbr>
          </div>
        </div>
      </div>
    )
  }
}
