import React from 'react'
import PropTypes from 'prop-types'
import {get, isEmpty, has, filter, isFunction, find, toString} from 'lodash'
import {ButtonToolbar, Button, Form, Row, Col, Card} from 'react-bootstrap'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import {noSelection, sanitizeId} from '../useful'
import StylishSelect from '../components/StylishSelect'
import {canWriteLeverageFunctions} from '@bdswiss/common-permissions'

export default class ClientEditor extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  static propTypes = {
    client: PropTypes.object.isRequired,
    header: PropTypes.string,
    onClose: PropTypes.func.isRequired,
    fields: PropTypes.array.isRequired,
    saveAction: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.resetForm()
  }

  resetForm = () => this.setState({
    values: {},
    errors: {},
    feedback: false,
  })

  formValueChanged(field, value) {
    const values = {...this.state.values, [field.key]: field.fromForm(value)}
    if (this.state.feedback) {
      this.setState({...this.state, values, errors: this.validate(values)})
    } else {
      this.setState({...this.state, values})
    }
  }

  validate(values = this.state.values) {
    const errors = {}
    this.props.fields.forEach((field) => {
      if (field.key in values && !field.isValid(values[field.key])) {
        errors[field.key] = true
      }
    })

    return errors
  }

  trySave() {
    const {verifyFields} = this.props
    const errors = this.validate()

    if (!isEmpty(errors)) {
      this.setState({...this.state, errors, feedback: true})
      return
    }

    const {values} = this.state
    this.resetForm()

    if (!verifyFields && ((has(values, 'phone') && (get(values, 'phone').match(/\*/g) || []).length === 0) ||
      (has(values, 'secondaryPhone1') && (get(values, 'secondaryPhone1').match(/\*/g) || []).length === 0))
    ) {
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-restricted-globals
      if (!confirm('By replacing all * from the phone numbers the entire phone will be changed.')) {
        return
      }
    }

    this.props.saveAction(this.props.client.id, values)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.context.clientProvider.subProviders.alerts.fetch()

        this.props.onClose()
        if (verifyFields) {
          if (isEmpty(get(res, 'verifyClientDetails'))) {
            this.context.showNotification({
              title: 'Client Verification',
              message: 'Client successfully verified.',
              position: 'tr',
              level: 'success',
            })
          } else {
            this.context.showNotification({
              title: 'Client Verification',
              message: `Error on verification. Fields: ${toString(get(res, 'verifyClientDetails'))}`,
              position: 'tr',
              level: 'error',
            })
          }
        }
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
      })
  }

  render() {
    const indexLastDownSelect = Math.max(0, this.props.fields.length - 3)
    const fields = this.props.fields
      .filter((f) => !f.hide || (isFunction(f.hide) && !f.hide({clientType: this.props.client.clientType})))
    const disabled = this.props.verifyFields ? !!(find(this.props.fields, (field) => isEmpty(this.state.values[field.key])))
      : isEmpty(this.state.values)
    return (
      <div>
        <Row className={style.toolbar}>
          <Col xs={12}>
            <ButtonToolbar className="float-right">
              <Button
                id="t-client-edit-details-close"
                key={1}
                variant="outline-secondary"
                className="mr-2"
                size="sm"
                onClick={this.props.onClose}
              >
                {isEmpty(this.state.values) ? 'Close' : 'Cancel'}
              </Button>
              <Button
                id="t-client-edit-details-save"
                key={2}
                variant="success"
                size="sm"
                onClick={this.trySave.bind(this)}
                disabled={disabled}
              >
                {this.props.buttonText || 'Save'}
              </Button>

            </ButtonToolbar>
          </Col>
        </Row>
        <Card
          id="t-client-edit-details-sidebar"
        >
          {this.props.header && <Card.Header><strong>{this.props.header}</strong></Card.Header>}
          <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
            {fields.map((field, index) => {

              const selectFormValueChanged = (o) => this.formValueChanged(field, o.value)
              const inputFormValueChanged = (o) => this.formValueChanged(field, o.target.value)

              const props = {
                type: field.type,
                maxLength: field.maxLength,
                value: ['vps', 'optInMarketing', 'optInSms'].includes(field.key)
                  ? field.toForm(get(this.state.values, field.key, get(this.props.client, field.path) ? 'yes' : 'no'))
                  : field.toForm(get(this.state.values, field.key, get(this.props.client, field.path))),
                isInvalid: this.state.errors[field.key],
                onChange: field.type === 'select' ? selectFormValueChanged : inputFormValueChanged,
              }

              if (field.key === 'canEditLeverage') {
                const canEditLeverageValue = this.props.client.canEditLeverage ? 'yes' : 'no'
                props.value = field.toForm(get(this.state.values, field.key, canEditLeverageValue))
                props.disabled = !canWriteLeverageFunctions(this.props.viewer)
              }

              return (
                <Row id={`t-client-edit-details-${sanitizeId(field.label)}`} key={field.key}>
                  <Col xs={12}>
                    {field.type === 'select' ?
                      <StylishSelect.Input {...props}
                        id={`t-client-edit-select-id-${sanitizeId(field.label)}`}
                        openUp={indexLastDownSelect < index}
                        options={StylishSelect.enumToStylishOptions(filter(field.options, field => !field.hidden),
                          null, field.sort)}
                        placeholderText={noSelection}
                        label={field.label}
                      />
                      :
                      <Form.Group>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control {...props} />
                      </Form.Group>
                    }
                  </Col>
                </Row>
              )
            })}
          </Card.Body>
        </Card>
      </div>
    )
  }
}
