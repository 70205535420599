/* eslint-disable no-console */

import {cloneDeep, isEqual} from 'lodash'
import {time} from './useful'

/*
 * debug:
 *  - 2: log also dispatch parameters and state after dispatch. Test state immutability
 *  - 1: log what dispatches are being done
 *  - 0: do not do any logging / testing
 */
export function createAppstate(initialAppState = {}, onDispatch = () => null, debug = 2, _console = console) {

  let appState = cloneDeep(initialAppState)
  let prevState = cloneDeep(appState)

  // Chromium and Firefox allows such fancy console features, not sure about other browsers
  if (debug > 0) {
    if (!('groupCollapsed' in _console)) {
      _console.groupCollapsed = _console.log
    }
    if (!('groupEnd' in _console)) {
      _console.groupEnd = _console.log
    }
  }

  function checkEquality(prevState, appState, msg) {
    if (!isEqual(prevState, appState)) {
      // console.log('ui', isEqual(prevState.ui.alerts, appState.ui.alerts))
      // console.log(prevState.ui.alerts.dateFilters.lastLogin.toISOString())
      // console.log(appState.ui.alerts.dateFilters.lastLogin.toISOString())
      // Chrome console has a bad habit of not capturing the whole object in the moment of object
      // being logged. We better create deep copies such that the outputs are accurate.
      const printPrev = cloneDeep(prevState)
      const printCurrent = cloneDeep(appState)
      _console.error(msg)
      _console.error('Previous state:', printPrev)
      _console.error('Current state:', printCurrent)
      throw new Error(msg)
    }
  }

  let dispatching = false
  return {
    dispatch: (msg, fn, args) => {
      if (dispatching) {
        throw new Error('cannot dispatch more than once at a time!')
      }
      dispatching = true
      if (typeof msg !== 'string') {
        throw new Error(`Dispatch: 'msg' should be message (string), got ${msg}`)
      }
      if (typeof fn !== 'function') {
        throw new Error(`Dispatch: 'fn' should be function, got ${fn}`)
      }
      if (args == null) {
        args = []
      }
      if (!(args instanceof Array)) {
        throw new Error(`Dispatch: 'args' should be array, got: ${args}`)
      }
      // check if anybody meddled with state outside dispatch
      let prevStateRef
      if (debug >= 2) {
        checkEquality(prevState, appState, 'State mutated between last two dispatches')
        prevStateRef = appState
        prevState = cloneDeep(appState)
      }
      // create map of object_ref => shallow_obj_copy which is checked after the function is dispatched
      if (debug >= 1) {
        _console.log(`dispatching: ${msg} at ${time()}`)
      }
      try {
        // do the state transformation. The only really important thing dispatch does :)
        appState = fn(appState, ...args)
        // check, that the individual parts of old appState stay immutable (although,
        // the appState as a whole changed)
        if (debug >= 2) {
          checkEquality(prevState, prevStateRef, 'State mutated inside dispatched function')
          prevState = cloneDeep(appState)
        }
      } catch (e) {
        _console.error('Error caught, while dispatching. Msg, args: ')
        _console.log(msg)
        _console.log(args)

        //allow application to recover from failed dispatch
        dispatching = false
        throw e
      }
      if (debug >= 2) {
        _console.groupCollapsed('-- details --')
        if (args.length === 1) {
          _console.log('arg: ')
          _console.log(args[0])
        } else {
          _console.log('args: ')
          _console.log(args)
        }
        _console.log('state after dispatch: ')
        _console.log(appState)
        _console.groupEnd()
      }
      dispatching = false
      // notify about state change.
      onDispatch()
    },
    getState: () => appState,
  }
}
