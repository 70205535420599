import React from 'react'
import {client} from './urqlClient'
import {Provider} from 'urql'

import {AccountsTable} from './components/AccountsTable'
import {Body1} from './components/Typography/Body1'
import {H3} from './components/Typography/H3'
import {Page} from './components/Page'
import {PageHeading} from './components/PageHeading'

const headings = {
  ib: 'IB Account Request',
  pamm: 'PAMM Manager Account Request',
}

const bodies = {
  ib: 'Review and approve or reject IB account requests',
  pamm: 'Review and approve or reject PAMM Manager account requests',
}

export const UnapprovedAccounts = ({flavor}) => (<Provider value={client}>
  <Page>
    <PageHeading>
      <H3>
        {headings[flavor]}
      </H3>
      <Body1>
        {bodies[flavor]}
      </Body1>

    </PageHeading>
    <AccountsTable flavor={flavor} />
  </Page>
</Provider>)

export const IBAccounts = () => <UnapprovedAccounts flavor="ib" />
export const PAMMAccounts = () => <UnapprovedAccounts flavor="pamm" />

