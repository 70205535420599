import React from 'react'
import PropTypes from 'prop-types'
import {Table, ButtonToolbar, Button, Col, Row, Form, Modal, Badge, Tabs, Tab} from 'react-bootstrap'
import {List as Loading} from 'react-content-loader'
import Promise from 'bluebird'
import {isEmpty, has, clone, includes, get, find, omit, filter, some, isNumber, map, flatten, first, startsWith,
  replace, size} from 'lodash'
import {readableDate} from '../useful'
import ReasonModal from '../components/ReasonModal'
import {createClientDocumentsUrl, createClientNewDocumentlUrl} from '../utils/links'
import {getDocumentStatusLabel, getRiskLabel} from '../utils/rendering'
import {provideProps, uiMount, compose} from '../decorators'
import ShowHideFilterButton from '../components/ShowHideFilterButton'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import PureComponent from '../PureComponent'
import {documentStatuses, processDocumentResults, appropTestStatuses, processAppropTestResults,
  profileChangeStatuses, processProfileChangeResults, kycStatuses, kycRejectionReasons,
  documentRejectionReasons, scoringResults, appropTestRejectionReasons, companies,
  eIdVerificationReliability, appropTestQuestionsV2, dueDiligenceStatuses, documentTypes, fundingCategories,
  suitabilityTestScoreResult} from '@bdswiss/common-enums'
import {canApproveDocuments, canApproveAffiliateDocuments, canRevertDocuments, canCreateDocuments, canProcessAppropTests,
  canProcessProfileChanges, canWriteClientKycStatus, canRemoveDocuments,
  canRequestDocumentTranslations, canQueryAffiliateManagerClients, canProcessDueDiligence
} from '@bdswiss/common-permissions'
import {getScoringResultV2, getDocumentLabel} from '../common/utils'
import {compareByCreatedAt} from '../utils/general'
import style from './client.module.scss'
import RemoveDocumentModal from './RemoveDocumentModal'
import DocumentTranslationModal from './DocumentTranslationModal'
import RequestTranslationDocumentModal from './RequestTranslationDocumentModal'
import EIDVerificationResultFullViewModal from './EIDVerificationResultFullViewModal'
import DocumentVerificationResultModal from './DocumentVerificationResultModal'
import classNames from 'classnames'
import StylishSelect from '../components/StylishSelect'

const canApproveCurrentDocument = (viewer, document) => document.file_name === 'affiliate_contract' || document.type === 'affiliateContract' ?
  canApproveAffiliateDocuments(viewer) : canApproveDocuments(viewer)

class Documents extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    kycStatus: PropTypes.string.isRequired,
    documents: PropTypes.array,
    profileChanges: PropTypes.array,
    appropTests: PropTypes.array,
    dueDiligences: PropTypes.array,
    suitabilityTests: PropTypes.array,
    activeId: PropTypes.any,
    switchSidebar: PropTypes.func.isRequired,
    uiDispatch: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const {client: {paymentMethods = []}} = this.props

    const notConfirmedMethods = {}
    paymentMethods.forEach(m => {
      notConfirmedMethods[`paymentMethod-${m.id}`] = !m.confirmed
    })
    this.setState({...notConfirmedMethods})
  }

  componentWillReceiveProps(newProps) {
    const {isActive, activeId} = newProps
    const {dataFetched, dataLoading, dataError} = this.state
    if (isActive && !dataLoading && !dataError && !dataFetched) {
      const extraSubProviders = []
      if (startsWith(activeId, 'apt')) {
        extraSubProviders.push(this.context.clientProvider.subProviders.appropTests.fetch())
      } else if (startsWith(activeId, 'pc')) {
        extraSubProviders.push(this.context.clientProvider.subProviders.profileChanges.fetch())
      } else if (startsWith(activeId, 'edd')) {
        extraSubProviders.push(this.context.clientProvider.subProviders.dueDiligences.fetch())
      } else if (startsWith(activeId, 'sui')) {
        extraSubProviders.push(this.context.clientProvider.subProviders.suitabilityTests.fetch())
      }
      this.setState({dataLoading: true}, () => {
        Promise.all([
          this.context.clientProvider.subProviders.documents.fetch(),
          ...extraSubProviders,
          this.context.clientProvider.subProviders.eIDVerificationResults.fetch()
        ]).then(() =>  this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  newDocumentSidebar() {
    this.context.router.push(createClientNewDocumentlUrl(this.props.clientId))
  }

  showDocumentSidebar(documentId) {
    const clientId = this.props.clientId
    if (isNumber(documentId)) {
      this.props.fetchActivityLogs(documentId)
    }
    this.context.router.push(createClientDocumentsUrl(clientId, documentId))
  }

  openRejectionReasonWindow(document, profileChange, appropTest) {
    this.props.uiDispatch('Open rejection reason', (state) => ({
      ...state,
      showRejectionReasonModal: true,
      rejectingDocument: document,
      rejectingProfileChange: profileChange,
      underReviewProfileChange: undefined,
      rejectingAppropTest: appropTest,
    }))
  }

  openKycRejectionReasonWindow() {
    this.props.uiDispatch('Open KYC rejection reason', (state) => ({
      ...state,
      showKycRejectionReasonModal: true,
    }))
  }

  closeKycRejectionReasonWindow() {
    this.props.uiDispatch(
      'Closing KYC rejection reason window',
      (state) => ({
        ...state,
        showKycRejectionReasonModal: false,
      })
    )
  }

  openUnderReviewReasonWindow(document, profileChange, appropTest) {
    this.props.uiDispatch('Open under review comment', (state) => ({
      ...state,
      showRejectionReasonModal: true,
      rejectingDocument: undefined,
      rejectingProfileChange: undefined,
      underReviewProfileChange: profileChange,
      rejectingAppropTest: undefined,
    }))
  }

  closeRejectionReasonWindow() {
    this.props.uiDispatch('Close rejection reason', (state) => ({
      ...state,
      showRejectionReasonModal: false,
      rejectingDocument: undefined,
      rejectingProfileChange: undefined,
      rejectingAppropTest: undefined,
    }))
  }

  setKycStatus(kycStatus) {
    const {clientProvider} = this.context
    this.props.actions.client.setKycStatus(this.props.clientId, kycStatus)
      .then((res) => {
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.activityLogs.fetch()
      })
      .catch(this.context.logError)
  }

  processDocument(document, value, rejectionReason, selectedReasonCode, sendRejectionEmail) {
    const {clientProvider} = this.context
    this.props.actions.client.processDocument(document, value, rejectionReason, selectedReasonCode, sendRejectionEmail)
      .then((res) => {
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.documents.fetch()
        clientProvider.subProviders.activityLogs.fetch()
      })
      .catch(this.context.logError)
  }

  revertDocument(document) {
    const {clientProvider} = this.context
    this.props.actions.client.revertDocument(document)
      .then((res) => {
        clientProvider.subProviders.documents.fetch()
        clientProvider.subProviders.activityLogs.fetch()
        clientProvider.subProviders.basicData.fetch()
      })
      .catch(this.context.logError)
  }

  shouldRemoveDocument(document, remove) {
    this.props.uiDispatch('Close removing document modal', (state) => ({
      ...state,
      showRemoveDocumentModal: false,
      removingDocument: undefined,
    }))
    if (remove) {
      const {clientProvider} = this.context
      this.props.actions.client.removeDocument(document)
        .then((res) => {
          clientProvider.subProviders.documents.fetch()
          clientProvider.subProviders.activityLogs.fetch()
        })
        .catch(this.context.logError)
    }
  }

  toggleRejectedDocumentFilter= () => {
    const value = this.props.uiState.hideRejectedDocuments
    this.props.uiDispatch(
      'Change hide show Rejected Document ',
      (state) => ({...state, hideRejectedDocuments: !value})
    )
  }

  setMissingDocs(type, value) {
    this.props.actions.client.setMissingDocuments(this.props.clientId, type, value)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
      })
  }

  toggleFundingDocIsThirdPartyVerified(paymentMethodId, isThirdPartyVerified) {
    this.props.actions.client.toggleFundingDocIsThirdPartyVerified(paymentMethodId, isThirdPartyVerified)
      .then(() => {
        this.context.clientProvider.subProviders.documents.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  processProfileChange(profileChange, value, rejectionReason) {
    const {clientProvider} = this.context
    let comments
    if (value === processProfileChangeResults.underReview.key) {
      comments = rejectionReason
      rejectionReason = null
    }
    this.props.actions.client.processProfileChange(profileChange, value, rejectionReason, comments)
      .then((res) => {
        clientProvider.subProviders.profileChanges.fetch()
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.activityLogs.fetch()
        clientProvider.subProviders.alerts.fetch()
      })
      .catch(this.context.logError)
  }

  processAppropTest(appropTest, value, rejectionReason, selectedReasonCode) {
    const {clientProvider} = this.context

    this.props.actions.client.processAppropTest(appropTest, value, rejectionReason, selectedReasonCode)
      .then((res) => {
        clientProvider.subProviders.appropTests.fetch()
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.activityLogs.fetch()
      })
      .catch(this.context.logError)
  }

  updateDueDiligence(dueDiligence, value) {
    const {clientProvider} = this.context
    this.props.actions.client.updateDueDiligence(dueDiligence, value)
      .then((res) => {
        clientProvider.subProviders.dueDiligences.fetch()
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.activityLogs.fetch()
      })
      .catch(this.context.logError)
  }

  revertDueDiligence(dueDiligence) {
    const {clientProvider} = this.context
    this.props.actions.client.revertDueDiligence(dueDiligence)
      .then((res) => {
        clientProvider.subProviders.dueDiligences.fetch()
        clientProvider.subProviders.basicData.fetch()
        clientProvider.subProviders.activityLogs.fetch()
      })
      .catch(this.context.logError)
  }

  reloadDocuments() {
    const {clientProvider} = this.context
    clientProvider.subProviders.documents.fetch()
    clientProvider.subProviders.activityLogs.fetch()
  }

  renderPendingDocumentButtons(document) {
    return canApproveCurrentDocument(this.props.viewer, document) && (
      [
        this.renderAcceptDocumentButton(document),
        this.renderRejectDocumentButton(document),
      ]
    )
  }

  renderAcceptDocumentButton(document) {
    return canApproveCurrentDocument(this.props.viewer, document) && (
      <Button
        variant="success"
        className=" t-client-document-accept-button btn-xs mr-1"
        key="accept-btn"
        onClick={(e) => {
          this.processDocument(document, processDocumentResults.approve.key)
          this.props.activeId == null && e.stopPropagation()
        }}
      >{processDocumentResults.approve.label}</Button>
    )
  }

  renderRejectDocumentButton(document) {
    return canApproveDocuments(this.props.viewer, document) && (
      <Button
        variant="danger"
        className="t-client-document-reject-button btn-xs mr-1"
        key="reject-btn"
        onClick={(e) => {
          this.openRejectionReasonWindow(document)
          this.props.activeId == null && e.stopPropagation()
        }}
      >{processDocumentResults.reject.label}</Button>
    )
  }

  renderRevertDocumentButton(document) {
    return canRevertDocuments(this.props.viewer) && (
      <Button
        id="t-client-document-revert-button"
        variant="info"
        className="btn-xs mr-1"
        key="revert-btn"
        onClick={(e) => {
          this.revertDocument(document)
          this.props.activeId == null && e.stopPropagation()
        }}
      >Revert</Button>
    )
  }

  renderPendingProfileChangeButtons(profileChange) {
    return canProcessProfileChanges(this.props.viewer) && (
      <ButtonToolbar>
        <Button
          id="t-client-profile-change-accept-button"
          variant="success"
          className="btn-xs mr-1"
          onClick={(e) => {
            this.processProfileChange(profileChange, processProfileChangeResults.approve.key)
            e.stopPropagation()
          }}
        >{processProfileChangeResults.approve.label}</Button>
        <Button
          id="t-client-profile-change-reject-button"
          variant="danger"
          className="btn-xs mr-1"
          onClick={(e) => {
            this.openRejectionReasonWindow(undefined, profileChange)
            e.stopPropagation()
          }}
        >{processProfileChangeResults.reject.label}</Button>
        {profileChange.status !== 'underReview' && <Button
          id="t-client-profile-change-underReview-button"
          variant="outline-secondary"
          className="btn-xs mr-1"
          onClick={(e) => {
            this.openUnderReviewReasonWindow(undefined, profileChange)
            e.stopPropagation()
          }}
        >{processProfileChangeResults.underReview.label}</Button>}
      </ButtonToolbar>
    )
  }

  renderPendingAppropTestButtons(appropTest) {
    return canProcessAppropTests(this.props.viewer) && (
      <div className={style.butttonGroup}>
        <Button
          id="t-client-approp-test-accept-button"
          variant="success"
          className="btn-xs mr-1"
          onClick={(e) => {
            this.processAppropTest(appropTest, processAppropTestResults.approve.key)
            e.stopPropagation()
          }}
        >{processAppropTestResults.approve.label}</Button>
        <Button
          id="t-client-approp-test-reject-button"
          variant="danger"
          className="btn-xs mr-1"
          onClick={(e) => {
            this.openRejectionReasonWindow(undefined, undefined, appropTest)
            e.stopPropagation()
          }}
        >{processAppropTestResults.reject.label}</Button>
      </div>
    )
  }

  renderAcceptDueDiligenceButton(dueDiligence) {
    return <Button
      key="1"
      id="t-client-edd-accept-button"
      variant="success"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.updateDueDiligence(dueDiligence, dueDiligenceStatuses.approved.key)
        e.stopPropagation()
      }}
    >{processDocumentResults.approve.label}</Button>
  }

  renderRejectDueDiligenceButton(dueDiligence) {
    return <Button
      key="2"
      id="t-client-edd-reject-button"
      variant="danger"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.updateDueDiligence(dueDiligence, dueDiligenceStatuses.rejected.key)
        e.stopPropagation()
      }}
    >{processDocumentResults.reject.label}</Button>
  }

  renderPendingDueDiligenceButton(dueDiligence) {
    return <Button
      key="3"
      id="t-client-edd-pending-button"
      variant="warning"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.updateDueDiligence(dueDiligence, dueDiligenceStatuses.pending.key)
        e.stopPropagation()
      }}
    >{dueDiligenceStatuses.pending.label}</Button>
  }

  renderRevertDueDiligenceButton(dueDiligence) {
    return <Button
      id="t-client-edd-revert-button"
      variant="info"
      className="btn-xs mr-1"
      key="revert-edd-btn"
      onClick={(e) => {
        this.revertDueDiligence(dueDiligence)
        this.props.activeId == null && e.stopPropagation()
      }}
    >Revert</Button>
  }
  renderEditDueDiligenceButton(dueDiligence) {
    const {id} = dueDiligence
    const atId = `editDueDiligence-${id}`
    return <Button
      id="t-client-edd-edit-button"
      variant="outline-secondary"
      className="btn-xs mr-1"
      key="edit-edd-btn"
      onClick={(e) => this.showDocumentSidebar(atId)}
    >Edit</Button>
  }

  renderDueDiligenceButtons(dueDiligence, status) {
    const {dueDiligences} = this.props
    return (
      <div className={style.butttonGroup}>
        {first(dueDiligences).id === dueDiligence.id && this.renderEditDueDiligenceButton(dueDiligence)}
        {status === dueDiligenceStatuses.underReview.key && [
          this.renderAcceptDueDiligenceButton(dueDiligence),
          this.renderRejectDueDiligenceButton(dueDiligence),
          this.renderPendingDueDiligenceButton(dueDiligence)
        ]}
        {status === dueDiligenceStatuses.pending.key && [
          this.renderAcceptDueDiligenceButton(dueDiligence),
          this.renderRejectDueDiligenceButton(dueDiligence)
        ]}
        {[dueDiligenceStatuses.approved.key, dueDiligenceStatuses.rejected.key].includes(status) &&
          this.renderRevertDueDiligenceButton(dueDiligence)
        }
      </div>
    )
  }

  renderDocuments(documents) {
    const {uiState: {hideRejectedDocuments}, missingDocuments} = this.props
    const hasDocuments = documents && documents.length > 0
    const hasRejectDocuments = hasDocuments && documents.some((d) => d.status === documentStatuses.rejected.key)
    const hasCardNumber = hasDocuments && documents.some((d) => !isEmpty(d.cardNumber))
    const showHideFilterButton = (
      <ShowHideFilterButton
        id="t-show-hide-rejected-client-documents-button"
        labelHide="Hide Rejected"
        labelShow="Show Rejected"
        size="sm"
        tooltip="Show / Hide Rejected Documents"
        isHiding={!hideRejectedDocuments}
        onClick={this.toggleRejectedDocumentFilter}
        disabled={!hasRejectDocuments}
      />)
    const newDocumentButton = canCreateDocuments(this.props.viewer) && (
      <Button
        id="t-client-documents-new-document-button"
        variant="success"
        size="sm"
        onClick={() => this.newDocumentSidebar()}
      >New Document</Button>
    )
    const title = hasDocuments ? 'Documents' : 'No Document'
    return (
      <div>
        <Row className={hasRejectDocuments ? 'mb-2' : ''}>
          <Col xs={12}>
            <ButtonToolbar className={'float-right'}>
              {hasRejectDocuments && showHideFilterButton}
            </ButtonToolbar>
          </Col>
        </Row>
        <br />
        <Row className="mb-2">
          <Col xs={2}>
            <h6>
              <FontAwesomeIcon icon="files-o" /> {title}
            </h6>
          </Col>
          <Col xs={6}>
            <Row>
              <Col xs={6}>
                <h6>
                  Missing Documents
                </h6>
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="poi"
                  type="checkbox"
                  label="POI"
                  disabled={!canApproveDocuments(this.props.viewer)}
                  checked={get(missingDocuments, 'poi', false)}
                  onChange={(e) => {this.setMissingDocs('poi', e.target.checked)}}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="poi"
                  type="checkbox"
                  label="POR"
                  disabled={!canApproveDocuments(this.props.viewer)}
                  checked={get(missingDocuments, 'por', false)}
                  onChange={(e) => {this.setMissingDocs('por', e.target.checked)}}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={4}>
            <ButtonToolbar className="float-right">
              {newDocumentButton}
            </ButtonToolbar>
          </Col>
        </Row>
        {hasDocuments &&
          <Table bordered hover className={style.documents}>
            <thead>
              <tr>
                <th>File</th>
                {hasCardNumber && <th>Card Number</th>}
                <th>Expiration</th>
                {/*<th>Assignee</th>*/}
                <th>Reason</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions </th>
              </tr>
            </thead>
            <tbody>
              {documents.map(d => this.renderDocument(d, hasCardNumber))}
            </tbody>
          </Table>
        }
      </div>
    )
  }

  renderPartnersDocuments(documents) {
    const hasDocuments = documents && documents.length > 0
    const title = hasDocuments ? 'Partners Documents' : 'No Partners Documents'
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h6 className={'pull-left'}>
              <FontAwesomeIcon icon={'files-o'} /> {title}
            </h6>
          </Col>
        </Row>
        {hasDocuments &&
          <Table bordered hover className={style.documents}>
            <thead>
              <tr>
                <th>File</th>
                <th>Expiration</th>
                {/*<th>Assignee</th>*/}
                <th>Reason</th>
                <th>Date</th>
                <th>Status</th>
                <th>Actions </th>
              </tr>
            </thead>
            <tbody>
              {documents.map(d => this.renderDocument(d))}
            </tbody>
          </Table>
        }
      </div>
    )
  }

  renderFundingDocumentsSection(documents, paymentMethods) {
    const hasMethods = paymentMethods && paymentMethods.length > 0
    const title = !isEmpty(documents) ? 'Funding Documents' : 'No Funding Documents'
    const associatedDocumentIds = flatten(map(paymentMethods, 'relatedDocumentIds')) || []
    const notAssociatedDocuments = filter(documents, (d) => !associatedDocumentIds.includes(d.id))

    return (
      <div>
        <Modal
          show={this.state.editPaymentMethodModal}
          onHide={() => this.setState({editPaymentMethodModal: !this.state.editPaymentMethodModal})}
        >
          <Modal.Header closeButton>
            <Modal.Title> Edit Funding Document </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <StylishSelect.Input
              value={this.state.editPaymentMethodConfirm}
              options={StylishSelect.enumToStylishOptions([{label: 'Confirmed', key: 'confirm'}, {label: 'Pending', key: 'pending'}])}
              onChange={(e) => this.setState({editPaymentMethodConfirm: e.value})}
            />
            <Form.Control
              className="mb-2"
              disabled={this.state.editPaymentMethodConfirm === 'confirm'}
              as="textarea"
              rows={3}
              placeholder="Funding Document Notes"
              value={this.state.editPaymentMethodPendingNotes}
              onChange={(e) => this.setState({editPaymentMethodPendingNotes: e.target.value})}
            />
            <Button
              variant="success"
              size="sm"
              onClick={() => {
                const args = {
                  fundingDocId: this.state.editPaymentMethodId,
                  confirmed: this.state.editPaymentMethodConfirm === 'confirm',
                  pendingNotes: this.state.editPaymentMethodConfirm === 'confirm' ? '' : this.state.editPaymentMethodPendingNotes,
                }
                this.props.actions.client.editFundingDocument(args)
                  .then((res) => {
                    this.context.clientProvider.subProviders.documents.fetch()
                    this.context.clientProvider.subProviders.activityLogs.fetch()
                    this.setState({
                      editPaymentMethodModal: !this.state.editPaymentMethodModal,
                      editPaymentMethodId: '',
                      editPaymentMethodConfirm: '',
                      editPaymentMethodPendingNotes: '',
                    })
                  })
                  .catch(this.context.logError)
              }}
            >
              Save
            </Button>
          </Modal.Body>
        </Modal>
        <Row className="mb-3">
          <Col xs={4}>
            <h6 className={'pull-left'}>
              <FontAwesomeIcon icon={'files-o'} /> {title}
            </h6>
          </Col>
          <Col xs={8}>
            <ButtonToolbar className="float-right">
              {canCreateDocuments(this.props.viewer) && (
                <Button
                  id="t-client-funding-documents-new-document-button"
                  variant="success"
                  size="sm"
                  onClick={() => this.newDocumentSidebar()}
                >New Document</Button>
              )}
            </ButtonToolbar>
          </Col>
        </Row>
        {hasMethods && <Table bordered hover className={style.documents}>
          <thead>
            <tr>
              <th>Type</th>
              <th>Identifier</th>
              <th>Third Party Verified</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {map(paymentMethods, (m) => {
              const meta = JSON.parse(m.meta)
              return [
                <tr key="tr-1">
                  <td>{get(fundingCategories[m.fundingCategory], 'label', '')}</td>
                  <td>{get(m, 'details', '')}</td>
                  <td>
                    <Form.Check
                      id={`third-party-verified-${m.id}`}
                      type="checkbox"
                      label=" "
                      disabled={!canApproveDocuments(this.props.viewer)}
                      checked={get(meta, 'isThirdPartyVerified', false)}
                      onChange={(e) => {this.toggleFundingDocIsThirdPartyVerified(m.id, e.target.checked)}}
                    />
                  </td>
                  <td>
                    <span className={(m.confirmed) ? style.greenText : style.orangeText}>{(m.confirmed) ? 'Confirmed' : 'Not Confirmed'}</span>
                    <span
                      className={classNames(['clickable', 'float-right'])}
                      onClick={() => this.setState({
                        [`paymentMethod-${m.id}`]: {
                          showConfirmed: !get(this.state[`paymentMethod-${m.id}`], 'showConfirmed')
                        }
                      })}
                    >
                      <FontAwesomeIcon icon={get(this.state[`paymentMethod-${m.id}`], 'showConfirmed') ? 'arrow-up' : 'arrow-down'} />
                    </span>
                    <span
                      style={{marginRight: '5px'}}
                      className={classNames(['clickable', 'float-right'])}
                      onClick={() => {
                        this.setState({
                          editPaymentMethodModal: true,
                          editPaymentMethodId: m.id,
                          editPaymentMethodConfirm: m.confirmed ? 'confirm' : 'pending',
                          editPaymentMethodPendingNotes: m.pendingNotes
                        })
                      }}
                    >
                      <FontAwesomeIcon icon='edit' />
                    </span>
                  </td>
                </tr>,
                get(this.state[`paymentMethod-${m.id}`], 'showConfirmed') && ((m.relatedDocumentIds.length > 0) ? <tr>
                  <td colSpan={4}>
                    <Table bordered hover className={style.documents}>
                      <thead>
                        <tr>
                          <th>File</th>
                          <th>Expiration</th>
                          {/*<th>Assignee</th>*/}
                          <th>Reason</th>
                          <th>Date</th>
                          <th>Status</th>
                          <th>Actions </th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(m.relatedDocumentIds, (doc) => {
                          const renderDoc = find(documents, (d) => doc === d.id)
                          return renderDoc && this.renderDocument(renderDoc)
                        })}
                      </tbody>
                    </Table>
                  </td>
                </tr> : <tr key="no-docs-tr"><td colSpan="4">No Documents</td></tr>)]
            })}
          </tbody>
        </Table>}
        <br />
        {notAssociatedDocuments.length > 0 && <Table bordered hover className={style.documents}>
          <thead>
            <tr>
              <th>File</th>
              <th>Expiration</th>
              {/*<th>Assignee</th>*/}
              <th>Reason</th>
              <th>Date</th>
              <th>Status</th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {map(notAssociatedDocuments, (d) => this.renderDocument(d))}
          </tbody>
        </Table>}
      </div>
    )
  }

  renderDocument(document, hasCardNumber) {
    const {activeId, uiState: {hideRejectedDocuments}, viewer} = this.props
    const {id, type, fileDescription, url, expiration, createdAt, status, autoProcessingResult,
      translation, cardNumber, relatesTo} = document
    const isActiveClass = activeId === id ? 'active' : ''
    if (status === documentStatuses.rejected.key && hideRejectedDocuments) {
      return
    }
    // const showAssignee = viewer.id === get(assignee, 'id') ||
    //   some(viewer.roles, (r) => ['admin', 'super_user'].includes(r))
    const label = getDocumentLabel(type, fileDescription)
    const pendingStatuses = [documentStatuses.pending.key, documentStatuses.pendingTranslation.key]
    const renderedDoc = (
      <tr
        className={`t-document ${isActiveClass} ${style.clickable}`}
        key={id}
        onClick={() => this.showDocumentSidebar(id)}
      >
        <td className={relatesTo && style.indented}>
          <a href={url} title={id} target="_blank" rel="noopener noreferrer">
            {label}
          </a>
        </td>
        {hasCardNumber && <td>
          {cardNumber}
        </td>}
        <td title={expiration && expiration.fromNow()}>
          {expiration && readableDate(expiration, false)}
        </td>
        {/*<td>*/}
        {/*  {assignee && showAssignee ? `${get(assignee, 'firstName')} ${get(assignee, 'lastName')}` : '-'}*/}
        {/*</td>*/}
        <td>
          {document?.rejectionReason}
        </td>
        <td title={createdAt.fromNow()}>
          {readableDate(createdAt)}
        </td>
        <td>
          {getDocumentStatusLabel(documentStatuses[status])}
        </td>
        <td>
          <div className={style.butttonGroup}>
            {
              autoProcessingResult &&
                <Button
                  variant="outline-secondary"
                  className="btn-xs mr-1"
                  onClick={() => this.setState({autoProcessingResult, currDocumentId: id})}
                >
                  Result
                </Button>
            }
            {this.renderDocumentActionButtons(document)}
            {this.renderRemoveDocumentButton(document)}
            {translation && this.renderTranslateDocumentButton(document)}
            {(!translation || includes(pendingStatuses, status)) &&
              canRequestDocumentTranslations(viewer) && this.renderTranslationRequestButton(document)}
          </div>
        </td>
      </tr>
    )

    return document.related
      ? [
        renderedDoc,
        ...document.related.map((d) => this.renderDocument(d, hasCardNumber)),
      ]
      : renderedDoc
  }

  renderDocumentActionButtons(document) {
    switch (document.status) {
      case documentStatuses.accepted.key:
      case documentStatuses.autoVerificationAccepted.key:
      case documentStatuses.rejected.key:
        return this.renderRevertDocumentButton(document)
      case documentStatuses.autoVerificationError.key:
      case documentStatuses.autoVerificationSent.key:
      case documentStatuses.autoVerificationRejected.key:
      case documentStatuses.autoVerificationReceived.key:
      case documentStatuses.pending.key:
      case documentStatuses.notProcessed.key:
      default:
        return this.renderPendingDocumentButtons(document)
    }
  }

  renderRemoveDocumentButton(document) {
    return canRemoveDocuments(this.props.viewer) && (
      <Button
        id="t-client-document-remove-button"
        variant="danger"
        className="btn-xs mr-1"
        key="remove-btn"
        onClick={(e) => {
          this.props.uiDispatch('Open removing document modal', (state) => ({
            ...state,
            showRemoveDocumentModal: true,
            removingDocument: document,
          }))
        }}
      >Remove</Button>
    )
  }

  renderTranslateDocumentButton(document) {
    const btnTitle =
      document.status === documentStatuses.pendingTranslation.key ? 'Translate' : 'View Translation'
    return (
      <Button
        variant="info"
        className="btn-xs mr-1"
        onClick={() => this.openDisplayDocumentTranslateOption(document)}
        id="t-document-translation-dbutton"
      >
        {btnTitle}
      </Button>
    )
  }

  renderTranslationRequestButton(document) {
    return (
      <Button
        variant="warning"
        className="btn-xs mr-1"
        onClick={(e) => {
          this.props.uiDispatch('Open request translation modal', (state) => ({
            ...state,
            showNeedTranslationDocumentModal: true,
            translationDocument: document,
          }))
        }}
        id="t-document-translator-button"
      >
        Request Translation
      </Button>
    )
  }

  closeNeedTranslationButton() {
    this.props.uiDispatch('Close request translation modal',
      (state) => ({
        ...state,
        showNeedTranslationDocumentModal: false,
      }))
  }

  openDisplayDocumentTranslateOption(document) {
    this.props.uiDispatch('Displaying Document Translation Option',
      (state) => ({
        ...state,
        showDocumentTranslationModal: true,
        translationDocument: document,
      }))
  }

  closeDisplayDocumentTranslateOption() {
    this.props.uiDispatch('Close Document Translation Option',
      (state) => ({
        ...state,
        showDocumentTranslationModal: false,
      }))
  }

  renderProfileChanges(profileChanges) {
    const {proflileChangesLoaded, loadingProfileChanges} = this.state

    let loadProfileChanges
    if (!proflileChangesLoaded) {
      loadProfileChanges = (
        <Button
          id="t-documents-load-profile-changes-btn"
          disabled={loadingProfileChanges}
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            this.setState({loadingProfileChanges: true}, () => {
              this.context.clientProvider.subProviders.profileChanges.fetch()
                .then(() => this.setState({proflileChangesLoaded: true}))
                .catch(() => this.setState({proflileChangesLoaded: true}))
            })
          }}
        >
          Load Profile Changes
        </Button>
      )
    }

    const hasProfileChanges = (proflileChangesLoaded && !isEmpty(profileChanges)) || !isEmpty(profileChanges)
    const title = `${proflileChangesLoaded && isEmpty(profileChanges) ? 'No ' : ''}Profiles Changes`
    return (<div>
      <h6> <FontAwesomeIcon icon={'id-badge'} /> {title} </h6>
      {hasProfileChanges &&
        <Table bordered hover className={style.profileChanges}>
          <thead>
            <tr>
              <th />
              <th>Date</th>
              <th>Status</th>
              <th>Actions </th>
            </tr>
          </thead>
          <tbody>
            {profileChanges.map((d) => this.renderProfileChange(d))}
          </tbody>
        </Table>
      }
      {!proflileChangesLoaded && loadProfileChanges}
    </div>)
  }

  renderProfileChange(profileChange) {
    const {activeId} = this.props
    const {id, status, createdAt} = profileChange
    const pcId = `pc-${id}`
    const isActiveClass = activeId === pcId ? 'active' : ''
    return (
      <tr
        key={pcId}
        className={`t-profile-change ${isActiveClass} ${style.clickable}`}
        onClick={() => this.showDocumentSidebar(pcId)}
      >
        <td>
          Profile Change
        </td>
        <td title={createdAt && createdAt.fromNow()}>
          {readableDate(createdAt)}
        </td>
        <td id={`status-${id}`}>
          {status && getDocumentStatusLabel(profileChangeStatuses[status])}
        </td>
        <td style={{minWidth: 160}}>
          {(status === profileChangeStatuses.pending.key || status === profileChangeStatuses.underReview.key)
            && this.renderPendingProfileChangeButtons(profileChange)}
        </td>
      </tr>
    )
  }

  renderAppropTests(appropTests) {
    const {client} = this.props
    const {aptestLoaded, loadingAptest} = this.state

    let loadAppropTest
    if (!aptestLoaded && companies[client.personalDetails.company].requiresAPTest) {
      loadAppropTest = (
        <Button
          id="t-documents-load-approp-test-btn"
          disabled={loadingAptest}
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            this.setState({loadingAptest: true}, () => {
              this.context.clientProvider.subProviders.appropTests.fetch()
                .then(() => this.setState({aptestLoaded: true}))
                .catch(() => this.setState({aptestLoaded: true}))
            })
          }}
        >
          Load AP Tests
        </Button>
      )
    }

    const hasAppropTests = (aptestLoaded && !isEmpty(appropTests)) || !isEmpty(appropTests)
    const title = `${aptestLoaded && isEmpty(appropTests) ? 'No ' : ''}Appropriateness Tests`
    return <div>
      <h6> <FontAwesomeIcon icon={'pencil-square-o'} /> {title} </h6>
      {hasAppropTests &&
        <Table id={'t-client-documents-approp-test-table'} bordered hover className={style.appropTests}>
          <thead>
            <tr>
              <th />
              <th>Date</th>
              <th>Forex Score</th>
              {/* <th>Binary Score</th> */}
              <th>Score Category</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {appropTests.map((a) => this.renderAppropTest(a))}
          </tbody>
        </Table>
      }
      {!aptestLoaded && loadAppropTest}
    </div>
  }

  renderSuitabilityTests(suitabilityTests) {
    const {suitabilityTestLoaded, loadingSuitabilityTest} = this.state

    let loadSuitabilityTest
    if (!suitabilityTestLoaded) {
      loadSuitabilityTest = (
        <Button
          id="t-documents-load-suitability-test-btn"
          disabled={loadingSuitabilityTest}
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            this.setState({loadingSuitabilityTest: true}, () => {
              this.context.clientProvider.subProviders.suitabilityTests.fetch()
                .then(() => this.setState({suitabilityTestLoaded: true}))
                .catch(() => this.setState({suitabilityTestLoaded: true}))
            })
          }}
        >
          Load Suitability Tests
        </Button>
      )
    }

    const hasSuitabilityTests = (suitabilityTestLoaded && !isEmpty(suitabilityTests)) || !isEmpty(suitabilityTests)
    const title = `${suitabilityTestLoaded && isEmpty(suitabilityTests) ? 'No ' : ''}Suitability Tests`
    return (<div>
      <h6> <FontAwesomeIcon icon={'pencil-square-o'} /> {title} </h6>
      {hasSuitabilityTests &&
        <Table id={'t-client-documents-suitability-test-table'} bordered hover className={style.appropTests}>
          <thead>
            <tr>
              <th />
              <th>Date</th>
              <th>Portfolio Points</th>
              <th>Score Result</th>
            </tr>
          </thead>
          <tbody>
            {suitabilityTests.map((a) => this.renderSuitabilityTestRow(a))}
          </tbody>
        </Table>
      }
      {!suitabilityTestLoaded && loadSuitabilityTest}
    </div>)
  }

  renderDueDiligence(dueDiligences) {
    const {eddLoaded, loadingEDD} = this.state

    let loadEDD
    if (!eddLoaded) {
      loadEDD = (
        <Button
          id="t-documents-load-edd-btn"
          disabled={loadingEDD}
          variant="outline-secondary"
          size="sm"
          onClick={() => {
            this.setState({loadingEDD: true}, () => {
              this.context.clientProvider.subProviders.dueDiligences.fetch()
                .then(() => this.setState({eddLoaded: true}))
                .catch(() => this.setState({eddLoaded: true}))
            })
          }}
        >
          Load Due Diligences
        </Button>
      )
    }

    const hasDueDiligences = (eddLoaded && !isEmpty(dueDiligences)) || !isEmpty(dueDiligences)
    const title = `${eddLoaded && isEmpty(dueDiligences) ? 'No ' : ''}Due Diligence Questionaires`
    return (<div>
      <h6> <FontAwesomeIcon icon={'pencil-square-o'} /> {title} </h6>
      {hasDueDiligences &&
        <Table id={'t-client-documents-approp-test-table'} bordered hover className={style.appropTests}>
          <thead>
            <tr>
              <th />
              <th>Date</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {dueDiligences.map((a) => this.renderDueDiligenceRow(a))}
          </tbody>
        </Table>
      }
      {!eddLoaded && loadEDD}
    </div>)
  }

  renderDueDiligenceRow = (dueDiligence) => {
    const {activeId, viewer} = this.props
    const {id, status, createdAt} = dueDiligence
    const atId = `edd-${id}`
    const isActiveClass = activeId === atId ? 'active' : ''

    return (
      <tr
        key={atId}
        className={`t-due-diligence ${isActiveClass} ${style.clickable}`}
      >
        <td onClick={() => this.showDocumentSidebar(atId)}>
          Due Diligence
        </td>
        <td title={createdAt && createdAt.fromNow()} onClick={() => this.showDocumentSidebar(atId)}>
          {readableDate(createdAt)}
        </td>
        <td id={`t-score-result-${id}`} onClick={() => this.showDocumentSidebar(atId)}>
          {status && getDocumentStatusLabel(dueDiligenceStatuses[status])}
        </td>
        <td>
          {canProcessDueDiligence(viewer) && this.renderDueDiligenceButtons(dueDiligence, status)}
        </td>
      </tr>
    )
  }

  renderLabel = (result, company) => (
    `${scoringResults[result] ? scoringResults[result].label : ''}
      ${has(scoringResults[result], 'maxDeposit')
      ? '[€' + scoringResults[result].maxDeposit[company] + ']'
      : scoringResults[result] ? '[unlimited]' : ''}`
  )

  renderAppropTest = (appropTest) => {
    const {activeId, company} = this.props
    const {id, status, createdAt, binaryPoints, forexPoints, scoreResult} = appropTest
    const atId = `apt-${id}`
    const isActiveClass = activeId === atId ? 'active' : ''

    const scoreStrike = scoreResult || getScoringResultV2(forexPoints, binaryPoints, company, appropTestQuestionsV2)
    return (
      <tr
        key={atId}
        className={`t-approp-test ${isActiveClass} ${style.clickable}`}
        onClick={() => this.showDocumentSidebar(atId)}
      >
        <td>
          {id === 'legacy' && 'Legacy'} AP Test
        </td>
        <td title={createdAt && createdAt.fromNow()}>
          {readableDate(createdAt)}
        </td>
        <td>
          {forexPoints}
        </td>
        {/* <td>
          {binaryPoints}
        </td> */}
        <td id={`t-score-result-${id}`}>
          {scoreResult ? this.renderLabel(scoreResult, company) : <strike>{this.renderLabel(scoreStrike)}</strike>}
        </td>
        <td>
          {status && getDocumentStatusLabel(appropTestStatuses[status])}
        </td>
        <td>
          {status === appropTestStatuses.pending.key && this.renderPendingAppropTestButtons(appropTest)}
        </td>
      </tr>
    )
  }

  renderSuitabilityTestRow = (suitabilityTest) => {
    const {activeId} = this.props
    const {id, portfolioPoints, createdAt, scoreResult} = suitabilityTest
    const atId = `sui-${id}`
    const isActiveClass = activeId === atId ? 'active' : ''

    return (
      <tr
        key={atId}
        className={`t-suitability-test ${isActiveClass} ${style.clickable}`}
        onClick={() => this.showDocumentSidebar(atId)}
      >
        <td>
          Suitability Test
        </td>
        <td title={createdAt && createdAt.fromNow()}>
          {readableDate(createdAt)}
        </td>
        <td>
          {portfolioPoints}
        </td>
        <td>
          {scoreResult && getRiskLabel(suitabilityTestScoreResult[scoreResult])}
        </td>
      </tr>
    )
  }

  approveKycButton = (
    <Button
      key="approve-kyc-button"
      id="approve-kyc-button"
      variant="success"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.setKycStatus(kycStatuses.approved.key)
        this.props.activeId == null && e.stopPropagation()
      }}
    >
      Approve
    </Button>
  )

  pendingKycButton = (
    <Button
      key="pending-kyc-button"
      id="pending-kyc-button"
      variant="warning"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.setKycStatus(kycStatuses.pending.key)
        this.props.activeId == null && e.stopPropagation()
      }}
    >
      Pending
    </Button>
  )

  rejectKycButton = (
    <Button
      key="reject-kyc-button"
      id="reject-kyc-button"
      variant="danger"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.openKycRejectionReasonWindow()
        this.props.activeId == null && e.stopPropagation()
      }}
    >
      Reject
    </Button>
  )

  pendingAppropTestButton = (
    <Button
      key="pending-approptest-button"
      variant="info"
      className="btn-xs mr-1"
      onClick={(e) => {
        this.setKycStatus(kycStatuses.pendingAppropTest.key)
        this.props.activeId == null && e.stopPropagation()
      }}
    >
      AP Pending
    </Button>
  )

  saveKycStatus(reason, selectedReasonCode) {
    return this.props.actions.client.setKycStatus(
      this.props.clientId, 'rejected', reason, selectedReasonCode)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.closeKycRejectionReasonWindow()
      }).catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  renderKyc() {
    const {company, kycStatus, showKycRejectionReasonModal} = this.props
    const variant = kycStatus ? kycStatuses[kycStatus].bsStyle : 'outline-secondary'
    const hasPermission = canWriteClientKycStatus(this.props.viewer)

    let buttons = []
    if (kycStatus === kycStatuses.pending.key) {
      buttons = [
        this.approveKycButton,
        this.rejectKycButton,
      ]
    } else if (kycStatus === kycStatuses.approved.key) {
      buttons = [this.pendingKycButton, this.rejectKycButton]
    } else if (kycStatus === kycStatuses.rejected.key) {
      buttons = [this.approveKycButton, this.pendingKycButton]
    } else if (kycStatus === kycStatuses.pendingAppropTest.value) {
      buttons = [
        this.approveKycButton,
        this.rejectKycButton,
      ]
    }
    if (!includes([kycStatuses.approved.value, kycStatuses.pendingAppropTest.value], kycStatus) &&
      companies[company].requiresAPTest) {
      buttons.push(this.pendingAppropTestButton)
    }

    return (
      <div className={style.kycContainer} >
        <ReasonModal
          show={showKycRejectionReasonModal}
          header={'Changing KYC status to Rejected'}
          placeholder="Choose Rejection Reason"
          onCancel={this.closeKycRejectionReasonWindow.bind(this)}
          reasons={kycRejectionReasons}
          onDone={(reason, selectedReasonCode) => {
            this.saveKycStatus(reason, selectedReasonCode)
            this.closeKycRejectionReasonWindow()
          }}
          submitButtonBsClass={'danger'}
          submitButtonText={'Rejected'}
          warningMessage={''}
        />
        <b>KYC Status</b>
        <Badge
          className="mr-1 ml-1"
          id="kyc-status"
          variant={replace(variant, 'default', 'secondary')}
          pill
        >
          {kycStatus && kycStatuses[kycStatus].label}
        </Badge>
        {hasPermission && buttons}
        {hasPermission && kycStatus !== kycStatuses.approved.key && (
          <span className={style.kycInfo}>
            Approving will release all held deposits to designated accounts.
          </span>
        )}
        <Button
          variant="outline-secondary"
          size="sm"
          className="float-right"
          alt="reload"
          onClick={() => this.reloadDocuments()}
        >
          <FontAwesomeIcon icon="refresh" />
        </Button>
      </div>
    )
  }

  renderEIDVerificationResults() {
    const {client: {eIdVerificationResults}} = this.props
    const hasVerificationResults = eIdVerificationResults && eIdVerificationResults.length > 0
    const title = 'eID Verification Results'
    return (
      <div>
        <Row>
          <Col xs={8} >
            <h6> <FontAwesomeIcon icon={'id-badge'} /> {title} </h6>
          </Col>
        </Row>
        {hasVerificationResults &&
          <Table bordered hover className={style.documents}>
            <thead>
              <tr>
                <th>Identity Reliability</th>
                <th>Created On</th>
                <th>Created By</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                eIdVerificationResults.map((result, i) => {
                  // eslint-disable-next-line max-len
                  const createdBy = `${get(result, 'requestedBy.firstName', 'Triggered By Client')} ${get(result, 'requestedBy.lastName')}`
                  const idReliabilityEnum = find(eIdVerificationReliability, {key: result.identityReliability})
                  const idReliabilityLabel = get(idReliabilityEnum, 'label', result.identityReliability)
                  const idReliabilityLabelClass = get(idReliabilityEnum, 'bsStyle', result.identityReliability)
                  return (<tr key={i}>
                    <td><Badge pill variant={idReliabilityLabelClass}>{idReliabilityLabel}</Badge></td>
                    <td>{readableDate(result.createdAt)}</td>
                    <td>{createdBy}</td>
                    <td>
                      <Button
                        onClick={() => this.setState({eIdRawResult: result.rawResult})}
                        size="sm"
                        variant="outline-secondary"
                      > View Full Report
                      </Button>
                    </td>
                  </tr>)
                })
              }
            </tbody>
          </Table>
        }
      </div>
    )
  }

  getDocumentsGraph = (docs) => docs.filter((o) => !o.relatesTo || !some(docs, (d) => d.id === o.relatesTo)).map((parent) => {
    parent = omit(parent, 'relatesTo')
    const children = docs.filter((o) => o.relatesTo === parent.id)
    if (children.length > 0) parent.related = children.sort((a, b) => b.id - a.id)
    return parent
  }).sort((a, b) => b.id - a.id)

  getPendingCountBadge = (label, count) => count > 0
    ? <span>{label} <Badge pill variant="secondary">{count}</Badge></span>
    : label

  render() {
    const {documents, profileChanges, appropTests, legacyAppropTest, showRejectionReasonModal,
      rejectingDocument, rejectingProfileChange, underReviewProfileChange, isActive,
      rejectingAppropTest, showRemoveDocumentModal, showNeedTranslationDocumentModal, removingDocument,
      showDocumentTranslationModal, translationDocument, clientId, viewer, dueDiligences, suitabilityTests,
      client: {paymentMethods}, pendingTotal} = this.props
    const {autoProcessingResult, currDocumentId, eIdRawResult, dataLoading, dataError} = this.state
    if (dataLoading || !isActive) return <Loading speed={1} style={{padding:'20px'}} />
    if (dataError) return <pre> Error Loading Documents : {dataError.message} </pre>
    const rejectionTitle = `${
      rejectingDocument ? 'Document' : rejectingProfileChange ? 'Profile Change' : 'Appropriateness Test'
    } Rejection Reason`
    const title = underReviewProfileChange ? 'Review Comment' : rejectionTitle
    const documentsGraph = this.getDocumentsGraph(
      filter(documents, (d) => documentTypes[d.type] && documentTypes[d.type].category !== 'POF' &&
        !get(documentTypes[d.type], 'subCategories', []).includes('POF')
        && documentTypes[d.type].category !== 'partner'
      )
    )
    const fundingDocumentsGraph = this.getDocumentsGraph(
      filter(documents, (d) => documentTypes[d.type] &&
        (documentTypes[d.type].category === 'POF' ||
          get(documentTypes[d.type], 'subCategories', []).includes('POF')
        )
      )
    )
    const pendingFundingDocumentsCount = size(filter(fundingDocumentsGraph, d => [
      documentStatuses.notProcessed.key,
      documentStatuses.pending.key,
    ].includes(d.status)))
    const partnersDocumentsGraph = this.getDocumentsGraph(
      filter(documents, (d) => d.type && documentTypes[d.type].category === 'partner')
    )
    const sortedProfileChanges = clone((profileChanges || [])).sort(compareByCreatedAt)
    const sortedAppropTests = clone((appropTests || [])).sort(compareByCreatedAt)
    const sortedDueDiligences = clone((dueDiligences || [])).sort(compareByCreatedAt)
    const sortedSuitabilityTests = clone((suitabilityTests || [])).sort(compareByCreatedAt)

    if (legacyAppropTest != null) {
      // FIXME get createdAt from backend and use for sorting
      sortedAppropTests.push({id: 'legacy', answers: legacyAppropTest})
    }

    return (
      <Row>
        <Col xs={12}>
          {this.renderKyc()}
          <Tabs defaultActiveKey="verification" id="uncontrolled-tab-example">
            <Tab eventKey="verification" title={this.getPendingCountBadge('Account verification', pendingTotal - pendingFundingDocumentsCount)}>
              {!canQueryAffiliateManagerClients(viewer) && this.renderEIDVerificationResults()}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
              {!canQueryAffiliateManagerClients(viewer) && this.renderDocuments(documentsGraph)}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
              {!canQueryAffiliateManagerClients(viewer) && this.renderPartnersDocuments(partnersDocumentsGraph)}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
              {!canQueryAffiliateManagerClients(viewer) && this.renderAppropTests(sortedAppropTests)}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
              {this.renderDueDiligence(sortedDueDiligences)}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
              {!canQueryAffiliateManagerClients(viewer) && this.renderSuitabilityTests(sortedSuitabilityTests)}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
              {!canQueryAffiliateManagerClients(viewer) && this.renderProfileChanges(sortedProfileChanges)}
            </Tab>
            <Tab eventKey="documents" title={this.getPendingCountBadge('Funding documents', pendingFundingDocumentsCount)}>
              {!canQueryAffiliateManagerClients(viewer) && this.renderFundingDocumentsSection(fundingDocumentsGraph, paymentMethods)}
              {!canQueryAffiliateManagerClients(viewer) && <hr />}
            </Tab>
          </Tabs>

          <ReasonModal
            show={showRejectionReasonModal}
            showSendEmail={rejectingDocument && documentTypes[rejectingDocument.type].doubleSided}
            header={title}
            placeholder="Choose Rejection Reason"
            onCancel={this.closeRejectionReasonWindow.bind(this)}
            onDone={(rejectionReason, selectedReasonCode, sendRejectionEmail) => {
              if (rejectingDocument) {
                this.processDocument(rejectingDocument, processDocumentResults.reject.key,
                  rejectionReason, selectedReasonCode, sendRejectionEmail)
              } else if (rejectingProfileChange) {
                this.processProfileChange(rejectingProfileChange, processProfileChangeResults.reject.key,
                  rejectionReason)
              } else if (underReviewProfileChange) {
                this.processProfileChange(underReviewProfileChange, processProfileChangeResults.underReview.key,
                  rejectionReason)
              } else if (rejectingAppropTest) {
                this.processAppropTest(rejectingAppropTest, processAppropTestResults.reject.key,
                  rejectionReason, selectedReasonCode)
              }
              this.closeRejectionReasonWindow()
            }}
            reasons={!rejectingProfileChange && !underReviewProfileChange
              ? rejectingAppropTest ? appropTestRejectionReasons : documentRejectionReasons
              : undefined}
            warningMessage={!underReviewProfileChange ?
              'Warning! The rejection reason is visible to client and cannot be changed.'
              : 'Review comments are not visible to clients'}
            submitButtonBsClass={rejectingProfileChange ? 'danger' : 'info'}
            submitButtonText={underReviewProfileChange ? 'Place Under Review' : 'Reject'
            }
          />
        </Col>
        {autoProcessingResult && <DocumentVerificationResultModal
          documentId={currDocumentId}
          rawResult={autoProcessingResult}
          onHide={() => this.setState({autoProcessingResult: null})}
          show={!!autoProcessingResult}
        />
        }
        <RemoveDocumentModal
          show={showRemoveDocumentModal}
          onCancel={() => this.shouldRemoveDocument(removingDocument, false)}
          onConfirm={() => this.shouldRemoveDocument(removingDocument, true)}
        />
        <RequestTranslationDocumentModal
          show={showNeedTranslationDocumentModal}
          document={translationDocument}
          clientId={clientId}
          onHide={this.closeNeedTranslationButton.bind(this)}
          onCancel={() => this.closeNeedTranslationButton()}
          onSave={this.props.actions.client.upsertDocumentTranslation}
        />
        {showDocumentTranslationModal && <DocumentTranslationModal
          show={showDocumentTranslationModal}
          onHide={this.closeDisplayDocumentTranslateOption.bind(this)}
          document={translationDocument}
          clientId={clientId}
          onCancel={() => {this.closeDisplayDocumentTranslateOption()}}
          onSave={this.props.actions.client.upsertDocumentTranslation}
        />}
        {
          eIdRawResult &&
            <EIDVerificationResultFullViewModal
              show={!!eIdRawResult}
              hide={!eIdRawResult}
              onHide={() => this.setState({eIdRawResult: null})}
              rawResult={eIdRawResult}
            />
        }
      </Row>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'documentsUi']),

  provideProps((state, uiState) => {
    const {showRejectionReasonModal, rejectingDocument, rejectingProfileChange, underReviewProfileChange,
      rejectingAppropTest, showKycRejectionReasonModal, showRemoveDocumentModal, showNeedTranslationDocumentModal,
      removingDocument, showDocumentTranslationModal, translationDocument} = uiState
    const {viewer} = state
    return {
      viewer,
      showRejectionReasonModal,
      showKycRejectionReasonModal,
      showDocumentTranslationModal,
      rejectingDocument,
      rejectingProfileChange,
      underReviewProfileChange,
      rejectingAppropTest,
      showRemoveDocumentModal,
      showNeedTranslationDocumentModal,
      removingDocument,
      translationDocument,
    }
  }),
)(Documents)
