import React from 'react'
import PropTypes from 'prop-types'
import {find, get, filter} from 'lodash'
import {Modal, Button, ButtonToolbar, Row, Col} from 'react-bootstrap'
import {documentTranslationTypes} from '@bdswiss/common-enums'
import PureComponent from '../PureComponent'
import {compose, provideProps} from '../decorators'
import SelectAgent from '../components/SelectAgent'

class NeedTranslationDocumentModal extends PureComponent {

  static contextTypes = {
    usersProvider: PropTypes.object,
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  componentWillReceiveProps(newProps) {
    const translation = get(newProps, 'document.translation')
    if (!translation) {
      this.setDefaultState()
    } else {
      this.setState({
        values: {
          id: translation.id,
          translator: translation.translator.id,
        },
        isTouched: false,
        errors: {},
      })
    }
  }

  setDefaultState() {
    this.setState({
      values: {
        id: undefined,
        translator: undefined,
        error: false,
      },
      isTouched: false,
      disabled: false,
    })
  }

  translatorValueChanged(translator) {
    const {values} = this.state
    this.setState({
      values: {
        ...values,
        translator,
      },
      isTouched: true,
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  extractTranslationType(type) {
    return get(find(documentTranslationTypes, (t) => t.documentTypes.includes(type)), 'key')
  }

  save() {
    const {values} = this.state
    const {viewer, document, clientId, onSave} = this.props
    this.setState({disabled: true})
    return onSave({
      id: values.id,
      translationType: this.extractTranslationType(document.type),
      clientId: Number(clientId),
      documentId: Number(document.id),
      translator: Number(values.translator),
      meta: {},
      user: viewer.id,
    }).then(() => {
      this.setState({disabled: false})
      this.context.clientProvider.fetch()
      this.props.onHide()
    })
      .catch(this.context.logError)
  }

  render() {
    const {show, onHide} = this.props
    const {isTouched, disabled} = this.state
    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header>
          <strong>Select Agent to translate document</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col id="t-select-new-sales-agent" xs={5}>
              <SelectAgent.Input
                placeholderText="Sales Agents"
                label="Choose Agent to translate"
                agents={this.props.agents}
                onChange={(target) => this.translatorValueChanged(target.value)}
                value={this.state.values.translator}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              variant="outline-secondary"
              size="sm"
              className="mr-2"
              onClick={() => this.hideModal()} id="t-document-modal-cancel-button"
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => this.save()} id="t-document-modal-submit-button"
              disabled={!this.state.values.translator && (!isTouched || disabled)}
            >
              Submit
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  provideProps((state) => {
    const {agents} = state

    return ({
      agents: filter(agents, {isActive: true}),
    })
  }),
)(NeedTranslationDocumentModal)
