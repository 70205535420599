import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty, map, get, includes, filter, flattenDeep, size} from 'lodash'
import {ButtonToolbar, Button, Row, Col, Card, Form} from 'react-bootstrap'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {partnersAppendices, appendicesOptions, appendicesSubOptions} from '@bdswiss/common-enums'
import {setIn} from '../stateUtils'
import {safeParseJSON} from '../common/utils'
import style from './client.module.scss'

class AppendixForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    onClose: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const state = {
      showAppendixModal: false,
      form: this.props.appendix ?
        {
          id:  this.props.appendix.id,
          values: safeParseJSON(this.props.appendix.values),
          template: this.props.appendix.template,
        } :
        {values:{}, template:''},
      formError: {values:{}},
      formState: {},
    }
    this.setState(state)
  }

  formValueChanged(name, value) {
    let {state} = this
    if (name === 'template') {
      state = setIn(state, ['form', name], value)
    } else {
      state = setIn(state, ['form', ...name], value)
    }
    this.setState(state)
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  validateForm() {
    const errors = {}
    const {form} = this.state

    if (!form.template) {
      errors.template = true
    }
    const getTemp = get(partnersAppendices[form.template], 'appendixOptions')
    const isRequired = filter(appendicesOptions, (a)=> includes(getTemp, a.key) && a.isRequired)
    const checkFields = map(isRequired, (a) => map(a.appendixSubOptions, (l, k) => `${a.key}${l}`))
    const reqFields = flattenDeep(checkFields)
    map(reqFields, (a) => {
      if (isEmpty(form.values[a])) {
        errors[a] = true
      }
    })
    this.setFormErrors(errors)
    return isEmpty(errors)
  }

  formAction = () => {
    const {form} = this.state
    const {client: {generalInfo: {id: clientId}}} = this.props
    const data = {
      form,
      clientId,
      type: 'partners',
    }
    return  this.props.actions.client.upsertClientNotice(data)
  }

  renderButton = () => {
    const {state, state: {form: {id}, formState: {isLoading}},} = this
    return (
      <Button
        id="t-client-generate-appendix-form-submit"
        variant="success"
        size="sm"
        onClick={() => {
          const ok = this.validateForm()
          if (ok) {
            if (!isLoading) {
              this.setState(setIn(state, ['formState', 'isLoading'], true))
              this.formAction()
                .then((res) => {
                  this.context.clientProvider.fetch()
                  this.context.clientProvider.subProviders.activityLogs.fetch()
                  this.context.clientProvider.subProviders.notices.fetch()
                  this.context.router.goBack()
                })
                .catch((e) => {
                  this.setState(setIn(state, ['formState', 'isLoading'], false))
                  this.context.logError(e)
                })
            }
          }

        }}
      >{id ? 'Update' : 'Create'}
      </Button>
    )
  }

  closeAppendixPreview() {
    this.setState({showAppendixModal: false})
  }

  render() {
    const {onClose} = this.props
    const {form: {template, values}, formError} = this.state
    return (
      <Card
        id="t-client-appendix-sidebar"
        key="t-client-appendix"
        className="overrideOverflow"
      >
        <Card.Header><strong>Add Appendix</strong></Card.Header>
        <Card.Body>
          <Col xs={12}>
            <StylishSelect.Input
              id="t-appendix-form-template"
              label="Template Selection *"
              value={template}
              onChange={(evt) => this.formValueChanged('template', evt.value)}
              placeholderText="Select type"
              options={StylishSelect.enumToStylishOptions(partnersAppendices)}
              bsStyle={formError.template && 'error'}
            />
          </Col>
          {!isEmpty(template) && map(appendicesOptions, (label, key) => includes(get(partnersAppendices[template], 'appendixOptions'), key) &&
            map(appendicesOptions[key].appendixSubOptions, (l, k) => {
              const requiredField = appendicesOptions[key].isRequired ? '*' : ''
              const subOptionsSize = size(appendicesOptions[key].appendixSubOptions)
              const subOptionsCols = subOptionsSize >= 4 ? 12 : (12 / subOptionsSize)
              return <Col key={key} xs={subOptionsCols} className={style.appendixFormField}>
                <Form.Group>
                  <Form.Label>{`${appendicesSubOptions[l].label}${requiredField}`}</Form.Label>
                  <Form.Control
                    id="t-appendix"
                    key={`t-client-appendix-${key}${l}`}
                    defaultValue={get(values, `${key}${l}`)}
                    onChange={(evt) => this.formValueChanged(['values', `${key}${l}`], evt.target.value)}
                    isInvalid={formError[`${key}${l}`]}
                  />
                </Form.Group>
              </Col>
            })
          )}
          <Row>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-appendix-form-close"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >Close
                </Button>
                {this.renderButton()}
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(AppendixForm)
