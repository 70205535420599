import {isArray, isObject, kebabCase, deburr, maxBy, get, has, isNull, toLower, filter, sumBy, some,
  find, capitalize, range, isEmpty} from 'lodash'
import {currencies, countries, kalixaPaymentTypes, wirecardPaymentTypes, depositVendors, withdrawalStatuses,
  accountTypes, withdrawalTypes, withdrawalPaymentVendors, skrillPaymentTypes, payzoffPaymentOptions,
  safechargePaymentTypes, findEnum, transactionTypes, bridgerPayPaymentTypes, cardpayPaymentTypes, fundingCategories,
  powercash21PaymentTypes, jmFinancialPaymentTypes,
} from '@bdswiss/common-enums'
import {getFullName, getWithdrawalVendorLabel} from './utils/general'
import {isEmptyStr, parseJSON, safeParseJSON} from './common/utils.js'

export const unassigned = 'Unassigned'
export const allUsers = 'All Users'
export const noSelection = '\u2014\u2014\u2014'
export const minuteMillis = 60 * 1000
export const defaultErrorMessage = 'Please try again and contact the development team if issue persists.'

const defaultPageSize = 15
export const defaultPageSizeActivityLog = 15

export {getFullName} from './utils/general'

export function time() {
  const d = new Date()
  return `${(d.getSeconds() + d.getMilliseconds() / 1000).toFixed(3)}`
}

export function getPageSize() {
  return getFromStorage('pageSize', defaultPageSize)
}

export function setPageSize(size) {
  saveToStorage('pageSize', size)
}

export function getOffset(page, size) {
  size = size || getPageSize()
  return page ? (page - 1) * size : 0
}

export function getPageCount(count, size) {
  size = size || getPageSize()
  return Math.ceil(count / size) || 1
}

export function getPageRange(page, totalPages) {
  const pageNeighbours = 3
  const totalNumbers = (pageNeighbours * 2) + 3
  const totalBlocks = totalNumbers + 2
  if (totalPages > totalBlocks) {
    const startPage = Math.max(2, page - pageNeighbours)
    const endPage = Math.min(totalPages - 1, page + pageNeighbours)
    let pages = range(startPage, endPage + 1)

    /**
     * hasLeftSpill: has hidden pages to the left
     * hasRightSpill: has hidden pages to the right
     * spillOffset: number of hidden pages either to the left or to the right
     */
    const hasLeftSpill = startPage > 2
    const hasRightSpill = (totalPages - endPage) > 1
    const spillOffset = totalNumbers - (pages.length + 1)

    switch (true) {
      // handle: (1) < {5 6} [7] {8 9} (10)
      case (hasLeftSpill && !hasRightSpill): {
        const extraPages = range(startPage - spillOffset, startPage)
        pages = ['LEFT_PAGE', ...extraPages, ...pages]
        break
      }

      // handle: (1) {2 3} [4] {5 6} > (10)
      case (!hasLeftSpill && hasRightSpill): {
        const extraPages = range(endPage + 1, endPage + spillOffset + 1)
        pages = [...pages, ...extraPages, 'RIGHT_PAGE']
        break
      }

      // handle: (1) < {4 5} [6] {7 8} > (10)
      case (hasLeftSpill && hasRightSpill):
      default: {
        pages = ['LEFT_PAGE', ...pages, 'RIGHT_PAGE']
        break
      }
    }

    return [1, ...pages, totalPages]
  }
  return range(1, totalPages + 1)
}

const defaultFetchInterval = 0

export function getFetchInterval() {
  // temp disable to check speed
  return defaultFetchInterval
  // return getFromStorage('fetchInterval', defaultFetchInterval)
}

export function setFetchInterval(interval) {
  saveToStorage('fetchInterval', interval)
}

const defaultDepositNotification = 1

export function getDepositNotificationEnabled() {
  return getFromStorage('depositNotification', defaultDepositNotification)
}

export function setDepositNotificationEnabled(value) {
  saveToStorage('depositNotification', value)
}

const defaultWithdrawalNotification = 1

export function getWithdrawalNotificationEnabled() {
  return getFromStorage('withdrawalNotification', defaultWithdrawalNotification)
}

export function setWithdrawalNotificationEnabled(value) {
  saveToStorage('withdrawalNotification', value)
}

const defaultUserProfileSections = {
  showMarketingSection: true,
  showRiskSection: true,
  showEPSection: true,
  showAPTestSection: true,
  showPartnerDataSection: true,
  showEventsSection: true,
  showStrategicPartnerSection: true,
  showBDXSection: true,
  showClientToolsSection: true,
}

export function getUserProfileSectionsEnabled() {
  return getFromStorage('userProfileSections', defaultUserProfileSections)
}

export function setUserProfileSectionsEnabled(value) {
  saveToStorage('userProfileSections', value)
}

export function isNumeric(n) {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export function isPositiveNumber(n) {
  return isNumeric(n) && n > 0
}

export function stringifyQL(object, forParameters = false) {
  if (forParameters) {
    return Object.keys(object).reduce(
      (prev, key) => `${prev}${key}: ${stringifyQL(object[key])}, `, ''
    )
  }
  if (isObject(object)) {
    if (isArray(object)) {
      return `[${
        object.reduce((prev, value) => `${prev}${stringifyQL(value)}, `, '')
      }]`
    } else {
      return `{${
        Object.keys(object).reduce((prev, key) => (
          object[key] ? `${prev}${key}: ${stringifyQL(object[key])}, ` : prev
        ), '')
      }}`
    }
  }
  return object
}

export function toGraphQlParamsDeclaration(types) {
  return Object.keys(types).map((name) => `${name}: ${types[name]}`).join(', ')
}

function stringifyFields(fields) {
  if (typeof fields === 'string') {
    return fields
  } else if (isArray(fields)) {
    return fields.reduce(
      (prev, fields) => `${prev} ${stringifyFields(fields)}`,
      ''
    )
  } else if (isObject(fields)) {
    return Object.keys(fields).reduce(
      (prev, key) => `${prev} ${key} {${stringifyFields(fields[key])}}`,
      ''
    )
  }
  return ''
}

export function getQueryFields(columnDefinitions, columns) {
  return columns.reduce(
    (prev, key) => `${prev} ${stringifyFields(columnDefinitions[key].fields)}`,
    'id'
  )
}

export function getFormattedAmount(operation) {
  const meta = safeParseJSON(operation.meta)
  const withdrawalNetAmount = (operation.transactionType === transactionTypes.withdrawal.key)
    && ([withdrawalStatuses.accepted.key, withdrawalStatuses.processing.key].includes(operation.status))
    && get(meta, 'feesWithdrawalId') && get(operation, 'paymentFields.withdrawalNetAmount', '')
  if (Intl) {
    const isCrypto = get(currencies[operation.currency], 'isCrypto', false)
    const intlFormat = new Intl.NumberFormat([], {
      style: 'currency',
      currency: operation.currency,
      minimumFractionDigits: isCrypto ? 8 : 2,
    })
      .format(withdrawalNetAmount || operation.amount)
    //replace hack needed for cases when ccyCode == ccySymbol, to add space between amount and symbol
    return intlFormat.replace(operation.currency, ` ${operation.currency} `).trim()
  } else {
    const currency = currencies[operation.currency] || {}
    const amount = withdrawalNetAmount || operation.amount
    return currency.symbol
      ? `${amount < 0 ? '-' : ''}${currency.symbol}${Math.abs(amount)}`
      : `${amount} ${currency || ''}`
  }
}

export function getVendorPaymentType(deposit) {
  const vendor = get(deposit, 'vendor', get(deposit, 'payment.vendor'))
  const paymentReceipt = get(deposit, 'receipt') || get(deposit, 'payment.receipt')
  const receiptJson = paymentReceipt && JSON.parse(paymentReceipt)
  const meta = JSON.parse(get(deposit, 'meta', get(deposit, 'payment.meta')) || '{}')
  if (has(meta, 'revertedTransactionId')) {
    const wdVendor = find(withdrawalPaymentVendors, {value: get(meta, 'vendor')})
    return {
      label: `/${getWithdrawalVendorLabel(get(wdVendor, 'key')) ||
        get(find(withdrawalTypes, {value: get(meta, 'vendor')}), 'label') ||
        capitalize(get(meta, 'vendor') || '')}`
    }
  }
  if (!vendor || !paymentReceipt) return ''
  let paymentTypesEnum
  switch (vendor.toLowerCase()) {
    case depositVendors.kalixa.key:
      paymentTypesEnum = kalixaPaymentTypes
      break
    case depositVendors.spreedly.key:
    case depositVendors.wirecard.key:
      paymentTypesEnum = wirecardPaymentTypes
      break
    case depositVendors.skrill.key:
      paymentTypesEnum = skrillPaymentTypes
      break
    case depositVendors.payzoff.key:
      paymentTypesEnum = payzoffPaymentOptions
      break
    case depositVendors.safecharge.key:
      paymentTypesEnum = safechargePaymentTypes
      break
    case toLower(depositVendors.bridgerPay.key):
      paymentTypesEnum = bridgerPayPaymentTypes
      break
    case depositVendors.cardpay.key:
      paymentTypesEnum = cardpayPaymentTypes
      break
    case depositVendors.powercash21.key:
      paymentTypesEnum = powercash21PaymentTypes
      break
    case depositVendors.jmFinancial.key:
      paymentTypesEnum = jmFinancialPaymentTypes
      break
    default:
      paymentTypesEnum = {}
      break
  }

  let paymentMethod = (
    get(receiptJson, 'handlePaymentStateChangedNotificationRequest.payment.paymentMethod.value')
      || get(receiptJson, 'paymentMethod.value')
      || get(receiptJson, 'payment_method')
      || get(receiptJson, 'card_type')
      || get(receiptJson, 'payment_method.card_type')
      || get(receiptJson, 'payment_type')
      || get(meta, 'method')
      || get(receiptJson, 'data.psp_name')
      || get(receiptJson, 'TAG')
      || ''
  )
  paymentMethod = isObject(paymentMethod) ? '' : paymentMethod.trim()
  const paymentType = findEnum(paymentTypesEnum, paymentMethod)
  const fundingCategory = vendor === depositVendors.safecharge.key && !paymentMethod
    ? fundingCategories.card.value : ''
  return {
    label: paymentType ? `/${paymentType.label}` : '',
    fundingCategory: paymentType && paymentType.fundingCategory
      ? paymentType.fundingCategory : depositVendors[vendor].fundingCategory || fundingCategory
  }
}

export function getVendorCardDetails(deposit, clientId) {
  const vendor = get(deposit, 'vendor', get(deposit, 'payment.vendor')), paymentReceipt = get(deposit, 'receipt', get(deposit, 'payment.receipt'))
  const meta = safeParseJSON(get(deposit, 'meta', get(deposit, 'payment.meta')))
  const receiptJson = paymentReceipt && JSON.parse(paymentReceipt)

  if (!vendor || !paymentReceipt) return ''
  let cardHolder = ''
  switch (vendor.toLowerCase()) {
    case toLower(depositVendors.spreedly.key):
    case toLower(depositVendors.wirecard.key): {
      const getPaymentMethod = get(receiptJson, 'payment_method', '')
      const paymentMethod = isObject(getPaymentMethod) ? getPaymentMethod : receiptJson
      if (paymentMethod) {
        if (isObject(getPaymentMethod)) {
          cardHolder = `${paymentMethod.full_name}/${paymentMethod.number}`
        } else if (has(paymentMethod, 'payment') && isObject(get(paymentMethod, 'payment'))) {
          const fields = get(paymentMethod, 'payment', '')
          cardHolder = `${get(fields, 'account-holder.first-name', '')} ${get(fields, 'account-holder.last-name', '')}/${get(fields, 'card-token.masked-account-number', '')}` // eslint-disable-line max-len
        } else {
          cardHolder = `${paymentMethod.last_name} ${paymentMethod.first_name || ''}/${paymentMethod.masked_account_number}` // eslint-disable-line max-len
        }
      }
      break
    }
    case toLower(depositVendors.kalixa.key): {
      const account = get(receiptJson, 'account', {})
      if (has(account, 'cardNumber')) {
        cardHolder = `${account.holderName}/${account.cardNumber}`
      } else if (has(account, 'accountNumber') && !isEmptyStr(get(account, 'accountNumber'))) {
        cardHolder = `${isNull(account.accountOwner) ? '' : account.accountOwner}/${account.accountNumber}`
      }
      break
    }
    case toLower(depositVendors.zotapay.key): {
      if (has(receiptJson, 'name') && has(receiptJson, 'last-four-digits')) {
        cardHolder = `${get(receiptJson, 'name')}/${get(receiptJson, 'last-four-digits')}`
      }
      break
    }
    case toLower(depositVendors.safecharge.key): {
      const cardNumber = get(receiptJson, 'ccCardNumber') || get(receiptJson, 'cardNumber')
      const name = get(meta, 'tokenDetails.name') || get(receiptJson, 'nameOnCard')
      if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.isignthis.key): {
      if (has(receiptJson, 'card_reference.masked_pan')) {
        cardHolder = `N/A / ${get(receiptJson, 'card_reference.masked_pan')}`
      }
      break
    }
    case toLower(depositVendors.payzoff.key): {
      if (has(receiptJson, 'name') || has(receiptJson, 'card_number')) {
        cardHolder = `${get(receiptJson, 'name', '')}/${get(receiptJson, 'card_number', '')}`
      }
      break
    }
    case toLower(depositVendors.transferDepositCommisions.key):
    case toLower(depositVendors.transferb2b.key):
    case toLower(depositVendors.transferSpoab2b.key):
    case toLower(depositVendors.transfer.key): {
      const transferAccount = get(deposit, 'transferAccount', get(deposit, 'payment.transferFromAccount'))
      if (!transferAccount) {
        cardHolder = ''
        break
      }
      const fromClientId = get(transferAccount, 'memberId', get(transferAccount, 'client.id'))
      const accountName = get(accountTypes[transferAccount.type], 'category', get(accountTypes[transferAccount.__typename], 'category')) === 'pammForex' ?
        'Account' : get(accountTypes[transferAccount.type], 'label', get(accountTypes[transferAccount.__typename], 'label', ''))
      cardHolder = fromClientId === clientId
        ? `Internal Transfer From ${accountName} [${transferAccount.id}]`
        : `Transfer From ${accountName} [${transferAccount.id}] of Client [${fromClientId}]`
      break
    }
    case toLower(depositVendors.rave.key): {
      const cardHolderParts = []
      const custname = get(receiptJson, 'custname')
      const card = get(receiptJson, 'card.last4digits')
      if (custname) cardHolderParts.push(custname)
      if (card) cardHolderParts.push(card)
      cardHolder = cardHolderParts.join('/')
      break
    }
    case toLower(depositVendors.paypal.key): {
      cardHolder = get(receiptJson, 'transaction.paypal.payerEmail')
      break
    }
    case toLower(depositVendors.cardpay.key): {
      if (has(receiptJson, 'card_account.holder')) {
        cardHolder =
          `${get(receiptJson, 'card_account.holder') || '-'}/${get(receiptJson, 'card_account.masked_pan') || '-'}`
      }
      break
    }
    case toLower(depositVendors.appex.key): {
      const cardNumber = get(receiptJson, 'PAN')
      const name = get(receiptJson, 'cardholder')
      if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.checkout.key): {
      const name = get(receiptJson, 'source.name')
      const cardNumber = `${get(receiptJson, 'source.bin') || '-'}******${get(receiptJson, 'source.last4') || '-'}`
      if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.powercash21.key): {
      const name = get(receiptJson, 'cardholder')
      const cardNumber = `${get(receiptJson, 'bin') || '-'}******${get(receiptJson, 'ccn_four') || '-'}`
      if (!name && !get(receiptJson, 'ccn_four')) {
        cardHolder = ''
      } else if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.jmFinancial.key): {
      const name = get(receiptJson, 'token.card.name')
      const cardNumber = `${get(receiptJson, 'card.first_six') || '-'}******${get(receiptJson, 'card.last_four') || '-'}`
      if (!name && !get(receiptJson, 'card.last_four')) {
        cardHolder = ''
      } else if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.paypugs.key): {
      const cardNumber = get(receiptJson, 'card')
      const name = get(receiptJson, 'customer_name')
      if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.lionPay.key): {
      const cardNumber = get(receiptJson, 'card')
      const firstName = get(receiptJson, 'first_name')
      const lastName = get(receiptJson, 'last_name')

      const fullName = [firstName, lastName].filter(e => !isEmpty(e)).join(' ')

      if (cardNumber) {
        cardHolder = `${fullName}/${cardNumber || '-'}`
      }

      break
    }
    case toLower(depositVendors.vaultspay.key): {
      const cardNumber = get(receiptJson, 'customerDetails.cardNumber')
      const name = get(receiptJson, 'customerDetails.cardHolderName')
      if (name || cardNumber) {
        cardHolder = `${name || '-'}/${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.ikajo.key): {
      const cardNumber = get(receiptJson, 'card')
      const name = get(receiptJson, 'customer_name')
      if (name || cardNumber) {
        cardHolder = `${name || '-'} / ${cardNumber || '-'}`
      }
      break
    }
    case toLower(depositVendors.paymentcenter.key): {
      const cardNumber = get(receiptJson, 'CardMasked')
      const name = get(receiptJson, 'Cardholder')
      if (name || cardNumber) {
        cardHolder = `${name || '-'} / ${cardNumber || '-'}`
      }
      break
    }
    default:
      cardHolder = ''
  }
  return cardHolder
}

export function nameMatches(userOrClient, searchText) {
  if (!userOrClient) {
    return false
  }
  const fullName = getFullName(userOrClient).toLowerCase()
  for (const searchPart of searchText.toLowerCase().split(' ')) {
    if (fullName.indexOf(searchPart) === -1) {
      return false
    }
  }
  return true
}

export function getCountryName(address) {
  if (address && address.country) {
    const country = countries[address.country.toLowerCase()]
    return country ? country.label : address.country
  }
  return ''
}

// lower case, remove accents, replace spaces with underscores
export function sanitizeId(identifier) {
  const text = isObject(identifier) ? identifier.props.children[1] : identifier
  return kebabCase(deburr(text))
}

export function readableDate(date, showTime = true) {
  if (!date) {
    return undefined
  }

  return date.format(showTime ? 'lll' : 'll')
}

export function getFromStorage(key, defaultValue = null) {
  const value = localStorage && localStorage.getItem(`bdswiss:${key}`)
  let result = defaultValue
  if (value != null) {
    try {
      result = JSON.parse(value)
    } catch (e) {
      // ignore
    }
  }
  return result
}

export function saveToStorage(key, value) {
  try {
    localStorage.setItem(`bdswiss:${key}`, JSON.stringify(value))
  } catch (e) {
    console.error(e) // eslint-disable-line no-console
    return false
  }
  return true
}

export function deleteFromStorage(key, value) {
  try {
    localStorage.removeItem(`bdswiss:${key}`)
  } catch (e) {
    // ignore
    return false
  }
  return true
}

const newestReadArticleKey = 'newestReadArticle'

export function getNewestReadArticle() {
  return getFromStorage(newestReadArticleKey, 0)
}

export function storeNewestReadArticle(news) {
  const newestArticle = maxBy(news, (article) => article.createdAt)
  if (!newestArticle) {
    return
  }

  const newestVisible = newestArticle.createdAt.clone().valueOf()
  const storedValue = getNewestReadArticle()

  if (!storedValue || storedValue < newestVisible) {
    saveToStorage(newestReadArticleKey, newestVisible)
  }
}

export function negateBooleanProperty(object, key) {
  const result = {...object}

  if (result[key]) {
    delete result[key]
  } else {
    result[key] = true
  }

  return result
}

export function getObjectActivityLogs(obj, objName, allActivityLogs) {
  const {id} = obj
  let activityLogs = obj.activityLogs || []
  if (activityLogs.length === 0 && allActivityLogs) {
    activityLogs = allActivityLogs.filter(
      (activityLog) => activityLog[objName] && activityLog[objName].id === id
    )
  }
  return activityLogs
}

export function getActivityLogsByObjectId(id, objName, allActivityLogs) {
  let activityLogs = []
  if (allActivityLogs) {
    activityLogs = allActivityLogs.filter(
      (activityLog) => activityLog[objName] && activityLog[objName].id === id
    )
  }
  return activityLogs
}

export function getHocName(higherOrderName, component) {
  return `${higherOrderName}_${component.displayName || component.name}`
}

export function isConverted(input) {
  return input !== null
}

export function getWithdrawalPaymentMethodDetails(withdrawal, clientId) {
  if ([withdrawalTypes.transfer.key,
    withdrawalTypes.transferb2b.key,
    withdrawalTypes.transferWithdrawalCommisions.key,
    withdrawalTypes.transferSpoab2b.key,
  ].includes(withdrawal.withdrawalType)) {
    const transferAccount = get(withdrawal, 'transferAccount', get(withdrawal, 'transferToAccount'))
    if (!transferAccount) {
      return ''
    }
    const toClientId = get(transferAccount, 'memberId', get(transferAccount, 'client.id'))
    const accountName = get(accountTypes[transferAccount.type], 'category', get(accountTypes[transferAccount.__typename], 'category')) === 'pammForex' ?
      'Account' : get(accountTypes[transferAccount.type], 'label', get(accountTypes[transferAccount.__typename], 'label', ''))
    return toClientId === clientId
      ? `Internal Transfer To ${accountName} [${transferAccount.id}]`
      : `Transfer To ${accountName} [${transferAccount.id}] of Client [${toClientId}]`
  } else if (withdrawalTypes.performanceFee.key === withdrawal.withdrawalType) {
    const meta = safeParseJSON(withdrawal.meta)
    return get(meta, 'provider') ? `Transfer To IB (Provider) MT4 Live [ID: ${get(meta, 'provider')}]` : ''
  } else {
    const vendor = get(withdrawal, 'vendor', get(withdrawal, 'paymentVendor'))
    const depositVendor = get(find(depositVendors, {value: get(withdrawal, 'depositVendor')}), 'label')
    return vendor ? `${getWithdrawalVendorLabel(vendor)}${depositVendor ? ` - ${depositVendor}` : ''}` : ''
  }
}

export const getDepositRemainingAmount = (withdrawals, deposit, currentWithdrawal, isProcessed) => {
  const filteredWithdrawals = filter(withdrawals, (w) =>
    ![withdrawalStatuses.rejected.key, withdrawalStatuses.reversed.key].includes(w.status))
  const meta = parseJSON(get(deposit, 'meta', get(deposit, 'payment.meta')) || '{}')
  const refunds = get(meta, 'refunds', [])
  const refundedDepositAmount = sumBy(refunds, (r) => {
    if (isProcessed && r.withdrawalId === currentWithdrawal.id) return 0
    return r.refundedAmount
  })
  const reservedAmount = sumBy(filteredWithdrawals, (w) => {
    const withdrawalMeta = parseJSON(w.meta || '{}')
    const currentDeposit = find(get(withdrawalMeta, 'refundedDeposits', {}), {depositId: deposit.id})
    const exists = some(refunds, (r) => r.withdrawalId === w.id)
    return exists ? 0 : get(currentDeposit, 'refundedAmount') || 0
  })
  return Number((deposit.amount - refundedDepositAmount - reservedAmount).toFixed(2))
}

export function getFailedReason(deposit) {
  const vendor = get(deposit, 'vendor', get(deposit, 'payment.vendor')), paymentReceipt = get(deposit, 'receipt', get(deposit, 'payment.receipt'))
  const receiptJson = paymentReceipt && JSON.parse(paymentReceipt)

  if (!vendor || !paymentReceipt) return ''
  switch (vendor.toLowerCase()) {
    case toLower(depositVendors.checkout.key): {
      return get(receiptJson, 'source.response_summary')
    }
    case toLower(depositVendors.zotapay.key): {
      return get(receiptJson, 'errorMessage')
    }
    case toLower(depositVendors.safecharge.key): {
      return get(receiptJson, 'Reason') || get(receiptJson, 'gwErrorReason')
    }
    case toLower(depositVendors.cardpay.key): {
      return get(receiptJson, 'payment_data.decline_reason')
    }
    case toLower(depositVendors.powercash21.key): {
      return get(receiptJson, 'errormessage')
    }
    case toLower(depositVendors.jmFinancial.key): {
      return get(receiptJson, 'response.message')
    }
    case toLower(depositVendors.paypugs.key): {
      return get(receiptJson, 'reason')
    }
    default:
      return ''
  }
}
