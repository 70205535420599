import React, {Component} from 'react'
import PropTypes from 'prop-types'
import RichTextEditor from 'react-rte'

export default class ReactRteEditor extends Component {

  static propTypes = {
    onChange: PropTypes.func,
    initialMarkdown: PropTypes.string,
  };

  componentWillMount() {
    const initialMarkdown = this.props.initialMarkdown
    const initValue = initialMarkdown ?
      RichTextEditor.createValueFromString(initialMarkdown, 'markdown')
      : RichTextEditor.createEmptyValue()

    this.setState({
      value: initValue,
    })
  }

  state = {
    value: RichTextEditor.createEmptyValue(),
  }

  onChange = (value) => {
    const newMarkdown = value.toString('markdown')
    //send event only if markdown changed, so that app dont re-render after each arrow/focus
    if (this.props.onChange && this.state.value !== newMarkdown) {
      this.props.onChange(newMarkdown)
    }

    this.setState({value: value})
  }

  render() {
    const classname = this.props.bsStyle !== 'error' ? 'largeEditor' : 'largeEditor error'
    return (
      <div id={`${this.props.id}`} className={`${classname}`}>
        <RichTextEditor
          value={this.state.value}
          onChange={this.onChange}
        />
      </div>
    )
  }
}
