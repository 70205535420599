import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

export default class DeleteBlacklistModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const {show, onConfirm, onCancel} = this.props
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Delete Blacklist</strong>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button variant="outline-secondary" onClick={onCancel} className="mr-3">
              Cancel
            </Button>
            <Button variant="danger" onClick={onConfirm}>
              <FontAwesomeIcon icon="remove" /> Delete!
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
