import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {get, map, find} from 'lodash'
import moment from 'moment'

function initUsers(users) {
  users.sort(
    (a, b) => (a.firstName || '').localeCompare(b.firstName || '')
      || (a.lastName || '').localeCompare(b.lastName || '')
  )
  return users
}

const excludedUsersViewPermission = ['affiliate_client_view']

export const usersProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const permissions = get(props, 'viewer.permissions', [])
    if (permissions.some((permission) => excludedUsersViewPermission.includes(permission))) {
      return null
    }

    return `{
      users {
        id
        email
        firstName
        lastName
        isActive
        location {
          id
        }
        manager {
          id
        }
        phone
        skype
        avatar
        jobTitle
        roles {
          id
          name
          permissions {
            id
          }
        }
        createdAt
        createdBy {
          firstName
          lastName
        }
        updatedAt
        updatedBy {
          firstName
          lastName
        }
      }
    }`
  },

  onData: (res, dispatch, props) => {
    dispatch(
      'Users loaded',
      (state, res) => ({...state, users: initUsers(res.users) || []}),
      [res]
    )
    props.uiDispatch && props.uiDispatch('set readOnly false', (state) => ({...state, readOnly: false}))
  },
}

export const userProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const viewerId = get(props, 'viewer.id')
    const permissions = get(props, 'viewer.permissions', [])
    if (isNaN(viewerId) || !permissions.some((permission) => excludedUsersViewPermission.includes(permission))) {
      return null
    }

    return `
      query {
        user(id:${viewerId}) {
          id
          email
          firstName
          lastName
          location {
            id
          }
          phone
          skype
          avatar
          jobTitle
          roles {
            id
            name
            permissions {
              id
            }
          }
          username
          bio
          associatedCampaigns
          associatedSubCampaigns
          affiliateManagerCampaigns
          affiliateAssociatedCampaigns
          allowedIps
          department
          telephonyProvider
          startDate
          endDate
          manager {
            id
          }
          languages
          companies
          isActive
          salesAgentType
          selectedPools
          isPool
          countries
          ftdMin
          ftdMax
          departmentRoleType
        }
      }
    `
  },

  onData: (res, dispatch, props) => dispatch(
    'User loaded',
    (state, res) => ({...state, users: [res.user]}),
    [res]
  ),
}

// converts list of roles to {id: role} map
function roleMap(roles) {
  roles.sort((a, b) => a.name.localeCompare(b.name))
  const res = {}
  for (const role of roles) {
    res[role.id] = role
  }
  return res
}

export const rolesProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => `{
        roles {
          id
          name
          description
          restrictedTo
        }
      }`,

  onData: (res, dispatch, props) => dispatch(
    'Roles loaded',
    (state, res) => ({...state, roles: roleMap(res.roles)}),
    [res]
  ),
}

function initLocations(locations) {
  locations.sort((a, b) => a.name.localeCompare(b.name))
  return locations
}

export const locationsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => `{
        locations {
          id
          name
        }
      }`,

  onData: (res, dispatch, props) => dispatch(
    'Locations loaded',
    (state, res) => ({...state, locations: initLocations(res.locations)}),
    [res]
  ),
}

export const countryToAgentLanguageProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => `{
    countryToAgentLanguageMappings {
      country
      language
      lastUpdatedBy {
        firstName
        lastName
      }
      createdAt
      updatedAt
    }
  }`,

  onData: (res, dispatch, props) => dispatch(
    'country agents languages mapping loaded',
    (state, res) => ({...state, countryToAgentLanguageMappings: res.countryToAgentLanguageMappings}),
    [res]
  ),
}

export const shiftsProvider = {

  fetchInterval: getFetchInterval,
  getQuery: (props) => {

    const {dateFilters} = props
    const startDateFromCondition = dateFilters && dateFilters.createdFrom ?
      `startDate: "${moment(dateFilters.createdFrom).startOf('day').toISOString()}"` : ''
    const endDateToCondition = dateFilters && dateFilters.createdTo ?
      `endDate: "${moment(dateFilters.createdTo).endOf('day').toISOString()}"` : ''
    const query = `{
      shifts(
        limit: ${getPageSize()}
        offset: ${getOffset(props.page)}
        orderBy: id
        orderDirection: descending
        ${startDateFromCondition}
        ${endDateToCondition}
      ) {
        id
        user {
          id
          firstName
          lastName
        }
        from
        to
        weight
      }
      shiftsCount(
        ignoreCache: true
        ${startDateFromCondition}
        ${endDateToCondition}
      )
    }`

    return query
  },
  onData: (res, dispatch, props) => dispatch(
    'Shifts loaded',
    (state, res) => ({...state, shifts: res.shifts, shiftsCount: res.shiftsCount}),
    [res]
  ),
}


export const leadDistributionAgentsProvider = {

  fetchInterval: 0,

  getQuery: (props) => `{
    users(isActive: true, isSalesAgent: true) {
        id
        leadDistributionWeight
        lastLeadAssignedTimestamp
        leadDistributionAssigned
        ftdMin
        ftdMax
      }
    }`,

  onData: (res, dispatch, props) => dispatch(
    'Lead distribution agents loaded',
    (state, res) => ({
      ...state,
      agents: map(state.agents, agent => ({
        ...agent,
        ...find(res.users, {id: agent.id})
      })),
    }),
    [res]
  ),
}

export const documentDistributionAgentsProvider = {

  fetchInterval: 0,

  getQuery: (props) => `{
      users {
        id
        documentDistributionWeight
        documentTotalWeight
        documentlastAssignedTimestamp
      }
    }`,

  onData: (res, dispatch, props) => dispatch(
    'Document distribution agents loaded',
    (state, res) => ({
      ...state,
      agents: map(state.agents, agent => ({
        ...agent,
        ...find(res.users, {id: agent.id})
      })),
    }),
    [res]
  ),
}
