import React from 'react'
import {map, size, filter} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {getAccountLabel} from '../utils/general'
import {accountTypes} from '@bdswiss/common-enums'

export default class RemoveTradingAccountModal extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {accountsSelected: []}
  }

  static propTypes = {
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    accounts: PropTypes.array,
  };

  handleChange = (e) => {
    this.setState({accountsSelected: map(e, 'value')})
  }

  render() {
    const {show, onCancel, onConfirm, accounts, clientId} = this.props
    let accountOptions = []
    if (size(accounts) > 0) {
      const forexMt4Types = map(filter(accountTypes, (a) => !a.isDemo && a.category === 'forexMt4' && !a.walletProduct), 'key')
      const appropriateAccounts = accounts.filter((e) =>  forexMt4Types.includes(e.__typename))
      const options = map(appropriateAccounts, (account) => ({
        key: account.id,
        label: `${getAccountLabel(accountTypes[account.__typename])} - ${account.remoteId}`,
      }))
      accountOptions = [...StylishSelect.enumToStylishOptions(options)]
    }

    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong> Remove from mailing list </strong>
        </Modal.Header>
        <Modal.Body>
          <div> Accounts </div>
          <div>
            <span>&nbsp;</span>
            <StylishSelect
              id="t-list-clients-filter-risk-category"
              placeholderText="Select Accounts"
              value={this.state.accountsSelected}
              options={accountOptions}
              multi
              clearable
              onChange={this.handleChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button variant="outline-secondary" onClick={onCancel} size="sm" className="mr-3">
              Cancel
            </Button>
            <Button variant="success"  size="sm"  onClick={ () => onConfirm(clientId, this.state.accountsSelected)}>
              Stop
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
