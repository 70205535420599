import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Col, Row, Button, Form, Card} from 'react-bootstrap'
import style from './components.module.scss'
import {isEmptyStr} from '../common/utils.js'
import PureComponent from '../PureComponent'

export default class SearchIbsModal extends PureComponent {
  static contextTypes = {
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    searchIbs: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  setDefaultState() {
    this.setState({
      keywordArea: {
        keyword: '',
        error: false,
      },
      data: {},
      isTouched: false,
    })
  }

  keywordAreaChange(newValue) {
    this.setState({
      keywordArea: {
        keyword: newValue,
        error: false,
      },
      isTouched: true,
    })
  }

  keywordAreaError() {
    this.setState({
      keywordArea: {
        error: true,
      },
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  search() {
    const {keywordArea: {keyword}} = this.state
    if (isEmptyStr(keyword)) {
      this.keywordAreaError()
      return
    }
    this.props.searchIbs({keyword: keyword.trim()}).then((res) => {
      this.setState({data: JSON.parse(res.searchIbs)})
    }).catch((e) => {
      this.context.logError(e)
    })
  }

  render() {
    const {show} = this.props
    const {data} = this.state

    return (
      <Modal
        id="t-search-ibs-modal"
        keyboard
        show={show}
        onHide={this.hideModal.bind(this)}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Search IBs
        </Modal.Header>
        <Modal.Body>
          <Row className="mb-3">
            <Col xs={12}>
              <Form.Control
                id="t-search-ibs-keyword"
                type="text"
                placeholder="Keyword"
                tabIndex={1}
                value={this.state.keywordArea.keyword}
                isInvalid={this.state.keywordArea.error}
                onChange={(event) => {
                  this.keywordAreaChange(event.target.value)
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className={style.receiptContent}>
                <Card.Body>
                  {JSON.stringify(data, null, 3)}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-search-ibs-cancel"
            variant="outline-secondary"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-search-ibs-search"
            variant="success"
            disabled={!this.state.isTouched}
            onClick={() => this.search()}
          >
            Search
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
