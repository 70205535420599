import React from 'react'
import {Container} from 'react-bootstrap'
import PureComponent from './PureComponent'

export default class Error extends PureComponent {

  render() {
    const {message} = this.props.match.params

    return (
      <Container>
        <h2>
          {decodeURIComponent(message || 'Error happened.')}
        </h2>
      </Container>
    )
  }
}
