/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import {Card} from 'react-bootstrap'
import {getFullName, sanitizeId} from '../useful'
import {calcPreview, endOfParagraph} from '../textUtils'
import marked from 'marked'
import {canWriteArticles} from '@bdswiss/common-permissions'
import {compose, provideProps} from '../decorators'
import style from './news.module.scss'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class Article extends PureComponent {

  static propTypes = {
    article: PropTypes.object.isRequired,
    showFull: PropTypes.bool.isRequired,
    toggleContent: PropTypes.func.isRequired,
    onEditArticle: PropTypes.func.isRequired,
  };

  markupToHtml(markdown) {
    const rawMarkup = marked(markdown, {sanitize: true})
    return {__html: rawMarkup}
  }

  renderHeader(title, author, createdAt) {
    return (
      <div>
        <strong>{title}</strong><small> by {getFullName(author)}</small>
        <div className="float-right">
          <small>{createdAt.fromNow()}</small>
          {canWriteArticles(this.props.viewer) && (
            <span>&nbsp;
              <FontAwesomeIcon
                icon="pencil"
                title="Edit"
                className="t-news-edit-news clickable"
                onClick={() => this.props.onEditArticle()}
              />
            </span>
          )}
        </div>
      </div>
    )
  }

  renderToggleLink() {
    const {showFull, toggleContent} = this.props
    return (
      <div className={`${style.showMore}`}>
        <a href="#" onClick={() => toggleContent()}>{showFull ? 'Show less' : 'Show more'}</a>
      </div>
    )
  }

  render() {
    const {title, body, createdAt, user: author} = this.props.article
    const {showFull} = this.props

    const preview = calcPreview(body, endOfParagraph(body))
    const html = this.markupToHtml(showFull ? body : preview.content)

    return (
      <Card className={`t-news-item-${sanitizeId(title)} mt-3`}>
        <Card.Header>
          {this.renderHeader(title, author, createdAt.clone())}
        </Card.Header>
        <Card.Body>
          <span className="t-news-item-body" dangerouslySetInnerHTML={html} />
          {preview.hasMore && this.renderToggleLink()}
        </Card.Body>
      </Card>
    )
  }

}

export default compose(
  provideProps(),
)(Article)
