export default function create(dbClient) {
  return {
    upsertBlacklist(blacklist) {
      const {id, type, value, expiresAt, reason} = blacklist

      const variables = {
        id,
        type,
        value,
        expires: expiresAt !== undefined && expiresAt !== '' && expiresAt !== null
          ? new Date(expiresAt) : undefined,
        expiresAt,
        reason,
      }

      const query = `
        mutation (
          $id: Int,
          $type: BlacklistTypeType!,
          $value: String!,
          $expires: DateTime,
          $reason: String!,
        ) {
          upsertBlacklist(
            id: $id
            type: $type
            value: $value
            expiresAt: $expires
            reason: $reason
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    deleteBlacklist(id) {
      const variables = {id}

      const query = `
        mutation (
          $id: Int
        ) {
          deleteBlacklist(
            id: $id
          )
        }
      `
      return dbClient.query(query, variables)
    },
  }
}
