import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {find, get, map, includes, isEmpty} from 'lodash'
import {Row, Col, Button, Image, Card, ButtonGroup, Form, Container, InputGroup} from 'react-bootstrap'
import {canQueryPaymentOptions, canWritePaymentOptions} from '@bdswiss/common-permissions'
import {orderDirections, depositFlowTypes, paymentOptionStatuses, depositVendors, companies,
  paymentOptionMethods, cardTypes,
  withKYCStatuses} from '@bdswiss/common-enums'
import {events} from '../enums'
import {readableDate} from '../useful'
import style from './paymentOptions.module.scss'
import PureComponent from '../PureComponent'
import {paymentOptionsProvider} from './providers'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import AddPaymentMethodModal from './AddPaymentMethodModal'
import {getPaymentOptionStatusLabel} from '../utils/rendering'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class PaymentOptionItem extends PureComponent {
  render() {
    const {paymentOption, openDisplayAddPaymentOption, deletePaymentOption, showAvailableCountries,
      availableCountries, viewer} = this.props
    return (
      <div className="t-paymentOption">
        <Row className={style.paymentOptionItem}>
          <Col xs={1}>
            <Image
              width={100}
              src={paymentOption.logoUrl || undefined}
              rounded
              responsive={Boolean(paymentOption.logoUrl).toString()}
            />
          </Col>
          <Col xs={8}>
            <dl className="dl-horizontal">
              <dt> Name: </dt>
              <dd>{paymentOption.name}&nbsp;</dd>
              <dt> Status: </dt>
              <dd>{paymentOption.status ? getPaymentOptionStatusLabel(paymentOptionStatuses[paymentOption.status]) : ''}&nbsp;</dd>
              <dt> With KYC Status: </dt>
              <dd>{paymentOption.withKYCStatus ? withKYCStatuses[paymentOption.withKYCStatus]?.label : ''}&nbsp;</dd>
              <dt> Last Updated At: </dt>
              <dd>{readableDate(moment(paymentOption.updatedAt), true)}</dd>
              <dt> Provider: </dt>
              <dd>{get(find(depositVendors, {value: paymentOption.provider}), 'label')}&nbsp;</dd>
              <dt> Payment Key: </dt>
              <dd>{paymentOption.paymentKey}&nbsp;</dd>
              <dt> Localization Key: </dt>
              <dd>{paymentOption.localizationKey}&nbsp;</dd>
              <dt> Companies: </dt>
              <dd>{map(paymentOption.companies, (c) => get(find(companies, {value: c}), 'label')).join(', ')}&nbsp;</dd>
              <dt> iOS Min Build Version: </dt>
              <dd>{paymentOption.iOSMinBuildVersion}&nbsp;</dd>
              <dt> Android Min Build Version: </dt>
              <dd>{paymentOption.androidMinBuildVersion}&nbsp;</dd>

              {paymentOption.ccType && <>
                <dt> Accepted Credit Card: </dt>
                <dd>{cardTypes[paymentOption.ccType]?.label}&nbsp;</dd>
                <dt> Weekly CC Volume Limit: </dt>
                <dd>{paymentOption.ccVolumeLimit}&nbsp;</dd>
                <dt> Is Primary CC provider: </dt>
                <dd>{paymentOption.ccPrimary ? 'Yes' : 'No'}&nbsp;</dd>
              </>}

              <dt> Url: </dt>
              <dd>{paymentOption.logoUrl}&nbsp;</dd>
              <dt>Deposit flow type</dt>
              <dd>{get(depositFlowTypes, [paymentOption.flowType, 'label'])}&nbsp;</dd>
              <dt> Available Countries: </dt>
              <dd> {!isEmpty(availableCountries)
                ? `${availableCountries.join(', ')} `
                : (<Button
                  className="t-payment-option-show-countries-btn btn-xs"
                  variant="outline-secondary"
                  key="payment-option-show-countries-btn"
                  id="payment-option-show-countries-btn"
                  onClick={() => showAvailableCountries(paymentOption)}
                > Show Available Countries </Button>)
              }</dd>
              <dt> Confluence Link: </dt>
              <dd>{paymentOption.confluenceLink}&nbsp;</dd>
              {paymentOption.notes && [
                <dt key="notes1">Notes:</dt>,
                <dd key="notes2">{paymentOption.notes}&nbsp;</dd>
              ]}
              <dt> Payment Method: </dt>
              <dd>{paymentOption.method && paymentOptionMethods[paymentOption.method].label}&nbsp;</dd>
            </dl>
          </Col>
          {canWritePaymentOptions(viewer) && <Col xs={3}>
            <Row>
              <Button
                className="t-client-payment-option-edit-button btn-xs"
                variant="info"
                id="edit-payment-option"
                onClick={() => openDisplayAddPaymentOption(paymentOption)}
              >Edit Payment Option</Button>
            </Row>
            <br />
            <Row>
              <Button
                className="t-payment-option-delete-btn btn-xs"
                variant="danger"
                key="payment-option-delete-btn"
                id="payment-option-delete-btn"
                onClick={() => deletePaymentOption(paymentOption)}
              >Delete Payment Option</Button>
            </Row>
            <br />
          </Col>}
        </Row>
        <hr />
      </div>
    )
  }
}

class PaymentOptions extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentWillMount() {
    this.setState({
      searchText: '',
      availableCountries: [],
    })
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.paymentOptionsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    paymentOptionsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  openDisplayAddPaymentOption(paymentOption) {
    this.props.uiDispatch('Displaying Add Payment Option',
      (state) => ({
        ...state,
        showAddPaymentMethodModal: true,
        paymentOption,
      }))
  }

  closeDisplayAddPaymentOption() {
    this.props.uiDispatch('Close Add Payment Option',
      (state) => ({
        ...state,
        showAddPaymentMethodModal: false,
        paymentOption: null,
      }))
  }

  deletePaymentOption(paymentOption) {
    this.props.actions.paymentOptions.deletePaymentOption(paymentOption.id)
      .then((res) => {
        this.fetchProvider()
        this.context.showNotification({
          title: 'Delete Payment Option',
          message: 'Payment Option successfully deleted.',
          position: 'tr',
          level: 'success',
        })
      })
      .catch(this.context.logError)
  }

  showAvailableCountries(paymentOption) {
    this.props.actions.paymentOptions.showAvailableCountries(paymentOption.id)
      .then((res) => {
        const {availableCountries} = this.state
        this.setState({availableCountries: [
          ...availableCountries,
          {id: paymentOption.id, availableCountries: res.paymentOption.countries},
        ]})
      })
      .catch(this.context.logError)
  }

  doPaymentOptionsSearch(searchText) {
    this.props.dispatch(
      'Search payment providers',
      (state) => ({...state, paymentOptionsSearch: searchText, paymentOptionsPage: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  doFilterByMethod(e) {
    const {methodFilter} = this.state
    this.props.dispatch(
      'Search payment providers',
      (state) => ({...state, methodFilter: e.value, paymentOptionsPage: 1}),
      [methodFilter]
    )
    this.setState({methodFilter: e.value})
  }

  render() {
    const {paymentOptions, showAddPaymentMethodModal, paymentOption, paymentOptionsSearch, viewer, methodFilter} = this.props
    const {searchText, availableCountries} = this.state
    const methodOptions =
      [{label: 'All Methods', value: undefined}, ...StylishSelect.enumToStylishOptions(paymentOptionMethods)]
    const method = paymentOptions.map((p, k) => {
      const hasAvailableCountries = includes(map(this.state.availableCountries, 'id'), p.id)
      const availableCountriesProps = hasAvailableCountries ? find(availableCountries, {'id': p.id}).availableCountries : []
      return <PaymentOptionItem
        paymentOption={p}
        openDisplayAddPaymentOption={this.openDisplayAddPaymentOption.bind(this)}
        deletePaymentOption={this.deletePaymentOption.bind(this)}
        showAvailableCountries={this.showAvailableCountries.bind(this)}
        availableCountries={availableCountriesProps}
        onHide={this.closeDisplayAddPaymentOption.bind(this)}
        key={k}
        viewer={viewer}
      />}
    )
    const header = (
      <Row>
        <Col xs={4}>
          <InputGroup>
            <Form.Control
              type="text"
              value={searchText !== undefined ? searchText : paymentOptionsSearch || ''}
              placeholder="Search by name"
              onChange={(e) => this.setState({searchText: e.target.value})}
              onKeyUp={(e) => (
                (e.key === 'Enter' && this.doPaymentOptionsSearch(searchText)) ||
                (e.key === 'Escape' && this.doPaymentOptionsSearch(''))
              )}
            />
            <InputGroup.Append>
              {paymentOptionsSearch && <Button
                key={1}
                title="Clear"
                variant={paymentOptionsSearch ? 'success' : 'outline-dark'}
                onClick={() => this.doPaymentOptionsSearch('')}
              >
                <FontAwesomeIcon icon="times" />
              </Button>}
              <Button
                key={2}
                title="Search"
                variant={paymentOptionsSearch ? 'success' : 'outline-dark'}
                onClick={() => this.doPaymentOptionsSearch(searchText)}
              >
                <FontAwesomeIcon icon="search" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col xs={4}>
          <StylishSelect
            placeholderText="All Methods"
            value={methodFilter}
            options={methodOptions}
            highlightIfActive
            onChange={(e) => this.doFilterByMethod(e)}
          />
        </Col>
        {canWritePaymentOptions(viewer) && <Col xs={4}>
          <ButtonGroup className="float-right">
            <Button
              variant="success"
              size="sm"
              onClick={() => this.openDisplayAddPaymentOption()}
              id="t-payment-options-button"
            >
              Add Payment Method
            </Button>
          </ButtonGroup>
        </Col>}
        <div className="clearfix" />
      </Row>
    )
    return (
      <Container>
        <Row>
          <Col>
            <h3> Available Payment Options </h3>
          </Col>
        </Row>
        <Row>
          <Col xs>
            <Card>
              <Card.Header>{header}</Card.Header>
              <Card.Body>
                {method.length > 0 ? method : 'No available payment options'}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <AddPaymentMethodModal
          show={showAddPaymentMethodModal}
          paymentOption={paymentOption}
          onHide={this.closeDisplayAddPaymentOption.bind(this)}
          onSave={this.props.actions.paymentOptions.upsertPaymentOption.bind(this)}
          signUploadUrl={this.props.actions.paymentOptions.signUploadUrl.bind(this)}
        />
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryPaymentOptions),

  uiMount((state) => ['ui', 'paymentoptions']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/paymentOptions',
      (state) => {
        let paymentOptionsSort
        if (!paymentOptionsSort || paymentOptionsSort.orderBy == null
          || paymentOptionsSort.orderDirection == null
          || !(paymentOptionsSort.orderDirection in orderDirections)) {
          paymentOptionsSort = defaultSort
        }

        return ({...state, paymentOptionsSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {paymentOptionsSearch, methodFilter, paymentOptions, paymentOptionsCount, viewer} = state
    const {paymentOption, showAddPaymentMethodModal, paymentOptionsPage, paymentOptionsSort} = uiState

    return ({
      paymentOption,
      showAddPaymentMethodModal,
      paymentOptionsSearch,
      paymentOptions,
      paymentOptionsCount,
      paymentOptionsPage,
      paymentOptionsSort,
      viewer,
      methodFilter,
    })
  }),

  mountDataProviders({paymentOptionsProvider}),
)(PaymentOptions)
