import React from 'react'
import PropTypes from 'prop-types'
import {get, find, has, isFunction, some} from 'lodash'
import {withdrawalPaymentFields, accountTypes, refundStatuses, countries} from '@bdswiss/common-enums'
import {Modal, Button, ButtonToolbar, Container, Row, Col, Form} from 'react-bootstrap'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import {getFormattedAmount} from '../useful'
import {safeParseJSON, parseStringAsJson} from '../common/utils'
import {getWithdrawalVendorLabel} from '../utils/general'

export default class AutoProcessWithdrawalModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
  };

  componentWillMount() {
    this.setState({serviceResponse: '', hasError: false, loading: false, disableProcess: false, processGroup: false})
  }

  onProcess() {
    this.setState({loading: true, disableProcess: true})
    this.props.onProcess(this.props.withdrawal, this.props.clientId, this.state.processGroup)
      .then((resp) => {
        const response = parseStringAsJson(resp.withdrawalAutoProcess.response)
        const isError = has(response, 'error') || some(Object.keys(response), (k) => has(response[k], 'response.error'))
        this.setState({
          serviceResponse: resp.withdrawalAutoProcess.response,
          loading: false,
          hasError: isError,
          disableProcess: !isError}
        )
      }).catch((err) => {
        this.setState({
          serviceResponse: err.toString(),
          loading: false,
          hasError: true,
          disableProcess: false,
        })
      })
  }

  onCancel() {
    this.setState({serviceResponse: '', hasError: false, disableProcess: false})
    this.props.onCancel(this.props.withdrawal, this.props.clientId)
  }

  render() {
    const {show, withdrawal, deposits, country} = this.props
    const {serviceResponse} = this.state
    const isErrorResponse = serviceResponse.includes('GraphQL Error') || has(safeParseJSON(serviceResponse), 'error')
    if (!withdrawal) {return null}

    const meta = get(withdrawal, 'meta') || '{}'
    const {refundedDeposits, skrillExtraParams} = safeParseJSON(meta)
    const accountType = accountTypes[withdrawal.account.__typename].label
    const response = serviceResponse && !isErrorResponse
      ? JSON.stringify(JSON.parse(serviceResponse), null, 3)
      : serviceResponse
    const locale = 'en'

    const groupId = get(safeParseJSON(withdrawal.meta), 'groupId')

    return (
      <Modal
        id="t-auto-process-withdrawal-modal"
        keyboard
        show={show}
        className={style.modal}
      >
        <Modal.Header>
          <strong>{`Auto process ${getWithdrawalVendorLabel(withdrawal.vendor)} withdrawal`}</strong>
        </Modal.Header>
        <Modal.Body className={style.autoTransfersModal}>
          {this.state.loading &&
            <i className={`fa fa-spinner fa-3x fa-spin ${style.spinner}`} title={'spinner'} />}
          {!this.state.loading && <Container fluid>
            <Row>
              <Col md={12}>Account: {`${accountType}[${withdrawal.account.remoteId}]`}</Col>
            </Row>
            <Row>
              <Col md={12}>Amount: {getFormattedAmount(withdrawal)}</Col>
            </Row>
            <Row>
              <Col md={12}>{
                Object.values(withdrawalPaymentFields).map((field, i) => {
                  const options = isFunction(field.options)
                    ? field.options({
                      withdrawalPaymentVendor: withdrawal.vendor,
                      country: get(countries[country], 'value'),
                      bankCode: get(withdrawal.paymentFields, 'provider'),
                    }) : field.options
                  const value = options ? get(options[get(withdrawal.paymentFields, field.key, '')], 'label') : withdrawal.paymentFields[field.key]
                  if (!value) {return null}
                  return ([
                    <span key={i}>{field.localization.t(locale, {id_type: '#'})}: {value}</span>,
                    <br key={`br-${i}`} />,
                  ])
                })
              }</Col>
            </Row>
            {groupId && <Row>
              <Col md={12}>
                <br />
                <Form.Check
                  id="t-process-group"
                  type="checkbox"
                  label={<strong>Process all Group Withdrawals</strong>}
                  checked={this.state.processGroup}
                  onChange={(e) => {this.setState({processGroup: e.target.checked})}}
                />
              </Col>
            </Row>}
            {refundedDeposits && <Row>
              <Col md={12}>
                <br /><span><strong>Refunded Deposits</strong></span><br />
                {Object.values(refundedDeposits).map((deposit, i) => {
                  const {message: refundLabel} = find(refundStatuses, {key: deposit.refundStatus})
                  const currency = get(find(deposits, (d) => d.id === deposit.depositId), 'currency') ||
                    withdrawal.currency
                  const formattedAmount =
                    getFormattedAmount({amount: deposit.refundedAmount, currency})
                  return ([
                    <span key={i}>
                      {`Deposit Id: ${deposit.depositId} - Amount: ${formattedAmount} - ${refundLabel}`}
                    </span>,
                    <br key={`br-${i}`} />,
                  ])
                })}
                {get(skrillExtraParams, 'skrillSendAsMoney') &&
                  [
                    <span key="sendExtraMoney">
                      {`Extra money to send: ${getFormattedAmount({amount: skrillExtraParams.skrillSendMoneyAmount,
                        currency: withdrawal.currency})}`}
                    </span>,
                    <br key="brSendExtraMoney" />,
                  ]
                }
              </Col>
            </Row>}
            {this.state.serviceResponse !== '' &&
              <Row>
                <Col md={12}>
                  <span>Response:
                    {this.state.hasError ? ' Request failed ' : ' Request completed successfully '}
                    {this.state.hasError
                      ? <i className="fa fa-exclamation-circle" title="error" style={{color: '#a94442'}} />
                      : <i className="fa fa-check-circle" title="success" style={{color: 'green'}} />
                    }
                  </span>
                  <span className={style.serviceTextJson}>
                    {JSON.stringify(parseStringAsJson(response), null, 2)}
                  </span>
                </Col>
              </Row>
            }
          </Container>}

        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              key={1}
              variant="outline-secondary"
              size="sm"
              className="mr-3"
              onClick={() => this.onCancel()}
            >Exit</Button>
            <Button
              key={2}
              disabled={this.state.disableProcess}
              variant="success"
              size="sm"
              onClick={() => this.onProcess()}
            >Process</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
