import React from 'react'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar, Form, ListGroup} from 'react-bootstrap'
import {isEmptyStr} from '../common/utils.js'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'

export default class ReasonModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    showSendEmail: PropTypes.bool,
    header: PropTypes.string.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    warningMessage: PropTypes.string.isRequired,
    submitButtonText: PropTypes.string.isRequired,
    submitButtonBsClass: PropTypes.string.isRequired,
  };

  componentWillMount() {
    this.setState({reasonArea: {}, selectedReason: '', sendEmailChecked: true})
  }

  selectValueChanged(value) {
    this.setState({selectedReason: value, reasonArea: {error: false}})
  }

  resetSelectedReason() {
    this.setState({selectedReason: ''})
  }

  resetSendEmailChecked() {
    this.setState({sendEmailChecked: true})
  }

  reasonAreaChange(newValue) {
    this.setState({
      reasonArea: {
        reason: newValue,
        error: false,
      },
    })
  }

  reasonAreaError() {
    this.setState({
      reasonArea: {
        error: true,
      },
    })
  }

  validateAndConfirmReasonCode() {
    const {onDone, reasons} = this.props
    const {sendEmailChecked} = this.state

    let reason
    const showReasonTextArea = get(reasons[this.state.selectedReason], 'showReasonTextArea')
    if (showReasonTextArea) {
      reason = this.state.reasonArea.reason
      if (isEmptyStr(reason)) {
        this.reasonAreaError()
        return
      }
      this.reasonAreaChange('')
    }
    const selectedReason = this.state.selectedReason
    this.resetSelectedReason()
    onDone(reason, selectedReason, sendEmailChecked)
    this.resetSendEmailChecked()
  }

  validateAndConfirmReason() {
    const {onDone} = this.props
    const {sendEmailChecked} = this.state
    const reason = this.state.reasonArea.reason
    if (isEmptyStr(reason)) {
      this.reasonAreaError()
      return
    }
    this.reasonAreaChange('')
    onDone(reason, sendEmailChecked)
    this.resetSendEmailChecked()
  }

  renderReasonsDropDown(reasons, placeholder) {
    if (!reasons) return
    return (
      <div id="t-reason-modal-reason-select">
        <StylishSelect.Input
          label="Reasons"
          value={this.state.selectedReason}
          onChange={(e) => {
            this.selectValueChanged(e.value)
          }}
          placeholderText={placeholder || 'Choose Reason'}
          options={StylishSelect.enumToStylishOptions(reasons)}
        />
      </div>
    )
  }

  render() {
    const {show, onCancel, header, reasons, warningMessage, submitButtonText, submitButtonBsClass, placeholder,
      showSendEmail} = this.props
    let disableSubmit, disableReasonArea
    if (reasons) {
      disableReasonArea = this.state.selectedReason !== ''
        ? !get(reasons[this.state.selectedReason], 'showReasonTextArea')
        : true
      disableSubmit = !this.state.selectedReason
    } else {
      disableSubmit = !this.state.reasonArea.reason
      disableReasonArea = false
    }

    return (
      <Modal
        id="t-reason-modal"
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>{header}</strong>
        </Modal.Header>
        <Modal.Body>
          <ListGroup>
            <ListGroup.Item className={style.warningMessage}>
              <strong id="t-client-reason-warning">{warningMessage}</strong>
            </ListGroup.Item>
            <ListGroup.Item>
              {this.renderReasonsDropDown(reasons, placeholder)}
            </ListGroup.Item>
            <ListGroup.Item className="rejectionReasonTextArea">
              <Form.Group>
                <Form.Label>Reason</Form.Label>
                <Form.Control
                  disabled={disableReasonArea}
                  id="t-reason-modal-reason-text"
                  as="textarea"
                  rows="4"
                  tabIndex={1}
                  value={this.state.reasonArea.reason}
                  isInvalid={this.state.reasonArea.error}
                  onChange={(event) => {
                    this.reasonAreaChange(event.target.value)
                  }}
                />
              </Form.Group>
            </ListGroup.Item>
            {showSendEmail && <ListGroup.Item>
              <Form.Check
                id="t-reason-modal-send-email-selection"
                label="Send email?"
                checked={this.state.sendEmailChecked}
                onChange={(e) => this.setState({sendEmailChecked: e.target.checked})}
              />
            </ListGroup.Item>}
          </ListGroup>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              id="t-reason-modal-cancel-button"
              key={1}
              variant="outline-secondary"
              size="sm"
              className="mr-3"
              onClick={() => {
                this.reasonAreaChange('')
                this.resetSelectedReason()
                onCancel && onCancel()
              }}
            >Cancel</Button>
            <Button
              id="t-reason-modal-submit-button"
              size="sm"
              key={2}
              variant={submitButtonBsClass}
              disabled={disableSubmit}
              onClick={() => reasons ? this.validateAndConfirmReasonCode() : this.validateAndConfirmReason()}
            >{submitButtonText}</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
