import {isEmpty} from 'lodash'
import {getPageSize, getOffset} from '../useful'

export const activityLogsProvider = {

  getQuery: (props) => {
    try {
      const {activityLogsSort, activityLogsPage, activityLogTypesFilter, originTypesFilter,
        searchFilter, createdFromFilter, createdToFilter, includeNullClientIdFilter, activityLogsAgentFilter} = props
      const filters = []
      if (!isEmpty(activityLogTypesFilter)) {
        filters.push(`types: [${activityLogTypesFilter.join(',')}]`)
      }
      if (!isEmpty(originTypesFilter)) {
        filters.push(`origins: [${originTypesFilter.join(',')}]`)
      }
      if (searchFilter) {
        filters.push(`search: "${searchFilter}"`)
      }
      if (createdFromFilter) {
        filters.push(`creationTimeFrom: "${createdFromFilter.toISOString()}"`)
      }
      if (createdToFilter) {
        filters.push(`creationTimeTo: "${createdToFilter.toISOString()}"`)
      }
      if (includeNullClientIdFilter) {
        filters.push(`includeNullClientId: ${includeNullClientIdFilter ? 'true' : 'false'}`)
      }
      if (activityLogsAgentFilter) {
        filters.push(`salesAgentId: ${activityLogsAgentFilter}`)
      }
      const args = filters.join(', ')

      const userBasicData = `
        id
        firstName
        lastName
      `

      const query = `{
        activityLogs(
          ${args}
          limit: ${getPageSize()}
          offset: ${getOffset(activityLogsPage)}
          orderBy: ${activityLogsSort.orderBy}
          orderDirection: ${activityLogsSort.orderDirection}
        ) {
          __typename
          ... on BaseLogInterface {
            id
            ip
            ipCountry
            type
            origin
            createdAt
            client {
              ${userBasicData}
              company
              salesAgent {
                firstName
                lastName
              }
            }
            user {
              ${userBasicData}
            }
            ... on Note {
              content
              fileName
              fileUrl
            }
            ... on GenericLog {
              content
              topVip
              toggleAutochartist
              toggleTradeCompanion
              toggleAutochartistEmails
              toggleAutochartistPlugin
              toggleAutochartistCoelationEmail
              toggleBDXCallCompleted
              bdxWaiverAccepted
              manualWithdrawals
              bdxInvestmentChoiceFinal
              previousBDXInvestmentChoice
              toggleHideWDMethods
              hasDisabledWithdrawals
              potentialType
              statusAndNotes
              assignee {
                firstName
                lastName
              }
              meta
              accountName
              remoteId
              lastActiveDate
              newPlan
            }
            ... on withReasonLog {
              reason
              rejectionCode
            }
            ... on TradingStatusLog {
              status
              statusReason
              statusReasonCode
            }
            ... on KycStatusLog {
              status
              statusReason
              statusReasonCode
            }
            ... on ConversionStatusLog {
              status
            }
            ... on AgentLog {
              agentType
            }
            ... on ComplianceLog {
              otherUsersCount
              accountName
              remoteId
              reason
              rejectionReasonCode
              alertType
              marketingInOut
              smsInOut
              otherUsers
              args
            }
            ... on ClientTypeLog {
              clientType
            }
            ... on DiffLog {
              meta
            }
            ... on AccountCheckLog {
              gbgCheckResult
              gbgStatus
              skrillVerificationResult
            }
            ... on TransferFundsLog {
              allowTransferFunds
            }
            ... on UnderMonitoringLog {
              underMonitoring
            }
            ... on SpoaLog {
              canGrantSpoa
              spoaAuthorization
              spoaClientFName
              spoaClientLName
              spoaClientID
              spoaIbFName
              spoaIbLName
              spoaIbID
              spoaForce
              acceptedSpoaBonusTerms
            }
            ... on DocumentTranslationsLog {
              translationType
              documentId
              translationDetails
            }
          }
        }
        activityLogsCount(estimated: true ${args})
      }`

      return query

    } catch (e) {
      throw e
    }
  },

  onData: (res, dispatch, props) => dispatch(
    'ActivityLogs loaded',
    (state, res) => ({
      ...state,
      activityLogs: res.activityLogs,
      activityLogsCount: res.activityLogsCount,
    }),
    [res]
  ),
}
