import React from 'react'
import PropTypes from 'prop-types'
import TagForm from './TagForm'
import PureComponent from '../PureComponent'
import {tagCategoriesProvider} from './providers'
import {compose, provideProps, mountDataProviders, uiMount} from '../decorators'

class TagCreate extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  render() {
    const {tag, onSave, onCancel} = this.props

    return (
      <TagForm
        tag={tag}
        tagCategories={this.props.tagCategories}
        onSave={onSave}
        onCancel={onCancel}
      />
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'tagCategories']),

  provideProps((state, uiState) => {
    const {tagCategoriesSearch, tagCategories} = state

    return ({
      tagCategoriesSearch,
      tagCategories,
    })
  }),

  mountDataProviders({tagCategoriesProvider}),
)(TagCreate)
