import React from 'react'
import {get} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import {companies} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import style from './client.module.scss'

class GenerateTaxDocument extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
    backendUrl: PropTypes.string.isRequired,
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
  }

  state = {
    dateFrom: '',
    dateFromError: false,
    dateTo: '',
    dateToError: false,
  }

  componentDidMount() {
    this.context.clientProvider.subProviders.periodsInEachCompany.fetch()
  }

  handleDateFilterChanged(name, value) {
    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: !moment.isMoment(value),
      },
    })
  }

  isValid = () => {
    const {dateFrom, dateTo} = this.state
    const dateFromError = !moment.isMoment(dateFrom)
    const dateToError = !moment.isMoment(dateTo)
    this.setState({dateFromError, dateToError})
    return !(dateFromError || dateToError)
      && moment(dateTo).valueOf() >= moment(dateFrom).valueOf()
  }

  handleInputChange = ({target: {name, value}}) => this.setState({[name]: value})

  formatDate = (date) => moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')

  getTaxUrl = () => {
    const {clientId, backendUrl} = this.props
    const {dateFrom, dateTo} = this.state
    return `${backendUrl}/pages/tax/${this.formatDate(dateFrom)}/${this.formatDate(dateTo)}` +
    `/${clientId}`
  }

  openTaxDocument = () => this.isValid() && window.open(this.getTaxUrl())

  sendTaxDocument = () => this.isValid() && window.open(this.getTaxUrl() + '?send=true')

  render() {
    const {onClose, periodsInEachCompany} = this.props
    const {dateFrom, dateTo} = this.state

    return (
      <Card
        id="t-client-generate-tax-document-sidebar"
        key={1}
      >
        <Card.Header><strong>Generate Tax Document</strong></Card.Header>
        <Card.Body>
          <Row>
            <Col md={12} xs={12}>
              <h5>Periods in each company:</h5>
              <ul>
                {periodsInEachCompany.map((o) => <li key={o.dateFrom}>{companies[o.company].label} from {moment(o.dateFrom).format('DD/MM/YYYY')}</li>)}
              </ul>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <h5>Choose dates for the document:</h5>
            </Col>
            <Col md={6} xs={6}>
              <div>
                <span className={style.label}> Date from </span>
                <DateTime
                  id="t-client-generate-tax-document-date-from"
                  timeFormat={false}
                  onChange={(value) => this.handleDateFilterChanged('dateFrom', value)}
                  value={dateFrom}
                  bsStyle={this.state.dateFromError ? 'error' : ''}
                  closeOnSelect
                  className={style.datetime}
                />
              </div>
            </Col>
            <Col md={6} xs={6}>
              <div>
                <span className={style.label}> Date to </span>
                <DateTime
                  id="t-client-generate-tax-document-date-to"
                  timeFormat={false}
                  onChange={(value) => this.handleDateFilterChanged('dateTo', value)}
                  value={dateTo}
                  bsStyle={this.state.dateToError ? 'error' : ''}
                  closeOnSelect
                  className={style.datetime}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col md={12} xs={12}>
              <ButtonToolbar className="float-right mt-2">
                <Button
                  id="t-client-generate-tax-document-close"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  className="mr-2"
                  size="sm"
                >Close
                </Button>
                <Button
                  id="t-client-generate-tax-document-view"
                  tabIndex={-1}
                  onClick={this.openTaxDocument}
                  variant="success"
                  className="mr-2"
                  size="sm"
                >Preview
                </Button>
                <Button
                  id="t-client-generate-tax-document-view"
                  tabIndex={-1}
                  onClick={this.sendTaxDocument}
                  variant="success"
                  size="sm"
                >Send
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps((state, _, __, props) => {
  const {client} = state
  const clientId = Number(get(props, 'match.params.clientId'))
  return ({periodsInEachCompany: get(client[clientId], 'periodsInEachCompany', [])})
})(GenerateTaxDocument)
