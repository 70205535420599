import {getFetchInterval} from '../useful'

export const statsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const query = `{
      users {
        id
        firstName
        lastName
        email
        salesClientsCount
        roles {
          id
        }
        isActive
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Stats loaded',
    (state, res) => ({
      ...state,
      stats: res.users,
    }),
    [res]
  ),

}
