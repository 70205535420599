import {getFetchInterval} from '../useful'

export const depositsReportProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {dateFilter, reportSort, company} = props
    const reportDateStart = dateFilter?.reportDateStart ? `reportDateStart: "${dateFilter.reportDateStart}"` : ''
    const reportDateEnd = dateFilter?.reportDateEnd ? `reportDateEnd: "${dateFilter.reportDateEnd}"` : ''
    const companyCondition = company && company !== 'All' ? `company: "${company}"` : ''

    const query = `{
      getDepositsReport (
        ${reportDateStart}
        ${reportDateEnd}
        ${companyCondition}
        ${reportSort?.orderBy ? `orderBy: ${reportSort.orderBy}` : ''}
        ${reportSort?.orderDirection ? `orderDirection: ${reportSort.orderDirection}` : ''}
      ) {
        date
        deposits
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Deposits Report loaded',
    (state, res) => ({
      ...state,
      depositsReport: res.getDepositsReport != null
        ? res.getDepositsReport
        : state.depositsReport || [],
    }),
    [res]
  ),
}
