import React from 'react'
import PropTypes from 'prop-types'
import NotificationSystem from 'react-notification-system'
import config from './config'
import MainRoutes from './routes'
import {companyName} from './utils/companyName'

const style = {
  Containers: {
    DefaultStyle: {
      width: '500px'
    }
  }
}

export default class MainWrapper extends React.Component {

  componentWillMount() {
    this.config = config.getInstance()
    this.showNotification = this.showNotification.bind(this)
    this.removeNotification = this.removeNotification.bind(this)
    this.setFavicon(companyName())
  }

  static childContextTypes = {
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    removeNotification: PropTypes.func.isRequired,
  };

  getChildContext() {
    return ({
      logError: this.logError,
      showNotification: this.showNotification,
      removeNotification: this.removeNotification,
      config: this.config
    })
  }

  setNotificationRef = (ref) => {
    this.notificationSystem = ref
  }

  showNotification(notificationConfig) {
    return this.notificationSystem.addNotification(notificationConfig)
  }

  removeNotification(notification) {
    this.notificationSystem.removeNotification(notification)
  }

  setFavicon = (companyName) => {
    const favicon = document.getElementById('favicon')
    favicon.href = `/${companyName ?? 'bdswiss'}/favicon.ico`
  }

  logError = (error) => {
    console.error(error) // eslint-disable-line no-console
    error.isShowActualError = true // In CRM it makes sense to display errors, todo: revise it
    const message = error.isShowActualError ?
      error.message :
      'An error occured. Please refresh the page and check if the charges were saved. ' +
      'If not, please try again later or contact your administrator.'
    this.notificationSystem.addNotification({
      title: 'Error',
      message,
      level: 'error',
      autoDismiss: 0,
    })
  }


  render() {
    return (
      <div>
        <NotificationSystem ref={this.setNotificationRef} style={style} />
        <MainRoutes />
      </div>
    )
  }
}
