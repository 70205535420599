import React from 'react'
import PropTypes from 'prop-types'
import {claimedStatuses} from '@bdswiss/common-enums'
import SelectAgent from './SelectAgent'
import PureComponent from '../PureComponent'
import {agentTypes} from '../client/ChangeAgent'

const allSelectOptionValue = ''
const salesAgentFilterOptions = [
  {
    value: allSelectOptionValue,
    label: 'All Sales Agents',
  },
  {
    value: claimedStatuses.unclaimed.key,
    label: claimedStatuses.unclaimed.label,
  },
]

export default class SelectAgentFilter extends PureComponent {

  static propTypes = {
    agents: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
  };

  render() {
    return (
      <SelectAgent
        placeholderText="All Sales Agents"
        agents={this.props.agents}
        onChange={this.props.onChange}
        value={this.props.value}
        highlightIfActive
        optionsFilter={agentTypes.salesAgent.filter}
        optionsPrefix={salesAgentFilterOptions}
      />
    )
  }
}
