import React from 'react'
import PropTypes from 'prop-types'
import {map, keys, pickBy} from 'lodash'
import {documentStatuses} from '@bdswiss/common-enums'
import {Modal, Col, Row, Button} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {compose, uiMount, provideProps} from '../decorators'

class ChangeDocumentsStatusModal extends PureComponent {

  static contextTypes = {
    usersProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    afterSave: PropTypes.func.isRequired,
    user: PropTypes.object,
    args: PropTypes.object,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  setDefaultState() {
    this.setState({
      status: undefined,
      isTouched: false,
    })
  }

  valueChanged(status) {
    this.setState({
      status,
      isTouched: true,
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  save() {
    const {selectedDocuments, documentsProvider} = this.props
    const {status} = this.state
    this.props.actions.document.changeDocumentsStatus(status, keys(selectedDocuments))
      .then(() => {
        this.props.afterSave()
        this.hideModal()
        if (this.props.notify) this.props.notify('success', 'Status Change Successfully')
        if (documentsProvider) documentsProvider.fetch()
      })
      .catch(this.context.logError)
  }

  render() {
    const {onHide, show, selectedDocumentsCount} = this.props
    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          Change Status of Documents
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col id="t-select-document-status" xs={7}>
              <label className="control-label">Status</label>
              <StylishSelect
                placeholderText="Select..."
                options={map(pickBy(documentStatuses, 'userUpdatable'), (s) => ({value: s.key, label: s.label}))}
                value={this.state.status}
                onChange={(e) => this.valueChanged(e.value)}
              />
              <br />
            </Col>
            <Col id="t-note" xs={12}>
              <div className="float-right">
                <strong><sup>*</sup>Important Note:</strong> You are about to change the status of <strong>
                  {selectedDocumentsCount}</strong> documents.
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-change-documents-status-cancel"
            variant="outline-secondary"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-change-documents-status-save"
            variant="success"
            disabled={!this.state.status}
            onClick={() => this.save()}
          >
            Change Status
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'assignDocumentsUi']),
  provideProps()
)(ChangeDocumentsStatusModal)
