import {errors} from '@bdswiss/common-enums'

function createErrorMessage(error, parameters = {}) {
  let message = error.label
  message = message.replace(/\$\{([a-zA-Z0-9_]*)\}/g, (match, group) => parameters[group] || '')

  return message
}

export function clearErrorState(dispatch) {
  dispatch(
    'Clean error state ',
    (state) => ({
      ...state,
      error: undefined,
    })
  )
}

export function dispatchCampaignNotFoundError(dispatch, campaignId) {
  dispatch(
    'Error: campaign not found ' + campaignId,
    (state) => ({
      ...state,
      error: {
        code: errors.campaignDoesNotExist.key,
        message: createErrorMessage(errors.campaignDoesNotExist, {campaignId}),
      },
    })
  )
}

export function dispatchClientNotFoundError(dispatch, clientId) {
  dispatch(
    'Error: client not found ' + clientId,
    (state) => ({
      ...state,
      error: {
        code: errors.clientDoesNotExist.key,
        message: createErrorMessage(errors.clientDoesNotExist, {clientId}),
      },
    })
  )
}

export function dispatchPermissionDeniedError(dispatch) {
  dispatch(
    'Error: permission denied ',
    (state) => ({
      ...state,
      error: {
        code: errors.permissionDenied.key,
        message: createErrorMessage(errors.permissionDenied),
      },
    })
  )
}
