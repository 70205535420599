import {countries, regions} from '@bdswiss/common-enums'
import StylishSelect from '../components/StylishSelect'

const allOption = StylishSelect.enumToStylishOptions({}, 'All Countries').map((all) => ({
  ...all,
  isAll: true,
}))

const regionsOptions = StylishSelect.enumToStylishOptions(regions).map((region) => ({
  ...region,
  isRegion: true,
}))

const countriesOptions = StylishSelect.enumToStylishOptions(countries)

export const countriesAndRegionsOptions = [...allOption, ...regionsOptions, ...countriesOptions]

export function createCountriesFilter(selection) {
  if (selection.isAll) {
    return ''
  } else if (selection.isRegion) {
    return regions[selection.value].countries.map((country) => country.key)
  } else {
    return [selection.value]
  }
}
