import React from 'react'
import PropTypes from 'prop-types'
import {uniqBy, includes, filter} from 'lodash'
import classNames from 'classnames'
import {Row, Col, Card, Pagination, ButtonGroup, Button, Container} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {canQueryJobs, canInitiateJob, canRunAffiliateCommisionsJob} from '@bdswiss/common-permissions'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {accountTypes, jobActions, inactivityFeeTypes} from '@bdswiss/common-enums'
import {putFile} from '../utils/net'
import {compose, uiMount, predispatch, mountDataProviders, checkRights, provideProps} from '../decorators'
import {getPageCount, getPageRange} from '../useful'
import {dormantFeejobsProvider} from './providers'
import ListJobs from './ListJobs'
import DormantFeeForm from './DormantFeeForm'
import style from './jobs.module.scss'

class DormantFee extends PureComponent {

  static contextTypes = {
    dormantFeejobsProvider: PropTypes.object.isRequired,
  }

  componentWillMount() {
    this.setFormDefaultState()
  }

  setFormDefaultState() {
    this.setState({
      formData: {
        values: {
          accountType: accountTypes.ForexAccount.key,
          action: jobActions.apply.key,
          type: inactivityFeeTypes.dormant.key,
          inputFile: '',
          executeAsBonus: false,
        },
        errors: {},
        isTouched: false,
      },
      hasFormError: false,
    })
  }

  clearForm() {
    this.setFormDefaultState()
  }

  hideForm() {
    this.props.uiDispatch(
      'Hiding Dormant Fee Form',
      (uiState) => ({...uiState, showDormantFeeForm: false})
    )
  }

  onFormCancel() {
    this.clearForm()
    this.hideForm()
  }

  showForm(e) {
    e.preventDefault()
    this.props.uiDispatch(
      'Showing Dormant Fee Form',
      (uiState) => ({...uiState, showDormantFeeForm: true})
    )
  }

  onFormValuesChanged(newValues) {
    const {formData} = this.state
    this.setState({
      formData: {
        ...formData,
        values: newValues,
        isTouched: true,
      },
      hasFormError: false,
    })
  }

  validateInputFile() {
    const {formData: {values, errors}} = this.state
    if (values.inputFile === '') {
      errors.inputFile = {isValid: false,
        error: {
          reason: 'Input File is Required',
        },
      }
      this.setState({
        formData: {
          values,
          errors,
          isTouched: false,
        },
      })
      return false
    }
    return true
  }

  onFormSave() {
    if (this.validateInputFile() && canInitiateJob(this.props.viewer)) {
      const {formData: {values}} = this.state
      const {job} = this.props.actions
      job.getSignedInputFileUrl(values.accountType)
        .then((res) => {
          const {signInputFileUrl: {signedUrl, plainUrl}} = res
          putFile(values.inputFile[0], signedUrl)
            .then(() => {
              const params = {
                action: values.action,
                accountType: values.accountType,
                inputFileUrl: plainUrl,
                executeAsBonus: false,
                type: values.type,
              }
              job.initiateDormantFeeJob(params)
                .then((res) => {
                  this.hideForm()
                  this.context.dormantFeejobsProvider.fetch()
                  this.clearForm()
                })
                .catch((e) => {
                  this.setState({
                    hasFormError: true,
                    formErrorMsg: e.toString(),
                  })
                })
            })
            .catch((e) => {
              this.setState({
                hasFormError: true,
                formErrorMsg: e.toString(),
              })
            })
        })
        .catch((e) => {
          this.setState({
            hasFormError: true,
            formErrorMsg: e.toString(),
          })
        })

    }

  }

  render() {
    const {formData, hasFormError, formErrorMsg} = this.state
    const {uiState: {showDormantFeeForm}, state: {dormantFeeJobs, dormantFeeJobsCount},
      page, viewer} = this.props
    const showDormantFeeFormClass = showDormantFeeForm ? style.addJobFormOpen : ''
    const formErrorClass = `bs-callout bs-callout-danger ${hasFormError ? 'd-block' : 'd-none'}`
    const affiliateAccountTypes = uniqBy(filter(accountTypes, (v, k) =>
      includes(['affiliate'], v.category)), (a) => a.label)
    const availableAccountTypes = uniqBy(filter(accountTypes, (v, k) =>
      !v.isDemo && includes(['forexMt4', 'tradeSmarter', 'affiliate', 'forexMt5'], v.category)), (a) => a.label)
    return (
      <Container>
        <Row className="mb-3">
          <Col xs={12}>
            <span className="h3"> Dormant Fee / Maintenance Fee / Commissions Jobs </span>
            {
              canInitiateJob(this.props.viewer) &&
              (
                <ButtonGroup className="float-right">
                  <Button id="t-start-new-dormant-fee-btn" variant="success" onClick={(e) => this.showForm(e)}>
                    Start New
                  </Button>
                </ButtonGroup>
              )
            }
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <ListJobs jobs={dormantFeeJobs} />
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, page: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(page, getPageCount(dormantFeeJobsCount)).map((p) => {
                    if (p === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={p}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, page: page - 1})
                        )}
                      />
                    }

                    if (p === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={p}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, page: page + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={p === page}
                      key={p}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, page: p})
                      )}
                    >
                      {p}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={classNames(style.addJobContainer, showDormantFeeFormClass)}>
          <div className={style.addJobHeader}>
            <FontAwesomeIcon icon="tasks" className={classNames(style.addJobIcon, 'float-right')} />
            Start New Dormant Fee / Maintenance Fee / Commissions Job
          </div>
          <div className={formErrorClass} >
            <h4>
              <FontAwesomeIcon icon="info-circle" />
                &nbsp;An error occured while starting your job
            </h4>
            <p>{formErrorMsg}</p>
          </div>
          <DormantFeeForm
            values={formData.values}
            errors={formData.errors}
            actions={jobActions}
            accountTypes={canRunAffiliateCommisionsJob(viewer) ? affiliateAccountTypes : availableAccountTypes}
            inactivityFeeTypes={inactivityFeeTypes}
            onCancel={() => this.onFormCancel()}
            clearForm={() => this.clearForm()}
            onChange={(newValues) => this.onFormValuesChanged(newValues)}
            onSave={() => this.onFormSave()}
            isTouched={formData.isTouched}
          />
        </div>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryJobs),
  uiMount(() => ['clientsUi']),
  predispatch((props) => {
    props.uiDispatch(
      'Setting dormant fee form to hide by default',
      (uiState) => {
        const showDormantFeeForm = false
        return ({...uiState, showDormantFeeForm})
      }
    )
  }),
  provideProps((state, uiState) => {
    const {page} = uiState

    return {
      page: page || 1,
    }
  }),
  mountDataProviders({dormantFeejobsProvider}),
)(DormantFee)
