import React from 'react'
import PropTypes from 'prop-types'
import {Badge, Nav} from 'react-bootstrap'
import {filter} from 'lodash'
import moment from 'moment'
import classNames from 'classnames'
import {provideProps} from './decorators'
import style from './appointments/appointments.module.scss'
import PureComponent from './PureComponent'
import FontAwesomeIcon from './components/FontAwesomeIcon'

export default provideProps(({appointmentsByDay}) => ({appointmentsByDay}))(class AppointmentsButton extends PureComponent {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  }

  hasPastApointments = () => {
    const {appointmentsByDay} = this.props
    const today = moment().startOf('day').format('YYYY-MM-DD')
    return filter(appointmentsByDay, a => a.day < today && a.dismissed < a.count).length > 0
  }

  render() {
    const {onClick} = this.props
    const {viewer: {appointmentsCount}} = this.props
    return (
      <Nav.Link onClick={onClick} style={{marginTop: 4}}>
        <FontAwesomeIcon icon="bell" />
        {appointmentsCount > 0
          ? <span>&nbsp;<Badge variant="danger">{appointmentsCount}</Badge></span>
          : this.hasPastApointments()
            ? <span className={classNames(style.dot, style.red)}>&nbsp;</span>
            : ''
        }
      </Nav.Link>
    )
  }
})
