import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar, Row, Col, Form} from 'react-bootstrap'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'

export default class ExportWithdrawalsModal extends PureComponent {

  componentWillMount() {
    this.setState({
      dateFrom: moment().startOf('day'),
      dateTo: moment().endOf('day'),
      onlyBannedCountries: false,
    })
  }

  static propTypes = {
    show: PropTypes.bool,
    header: PropTypes.string,
    exporting: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const {show, header, onConfirm, onCancel, exporting} = this.props
    const {dateFrom, dateTo, onlyBannedCountries} = this.state

    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
        backdrop="static"
      >
        <Modal.Header>
          <strong>{header}</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={5}>
              <DateTime
                label="From (UTC)"
                value={dateFrom || ''}
                onChange={(value) => this.setState({dateFrom: value})}
                placeholder="From"
              />
            </Col>
            <Col xs={5}>
              <DateTime
                label="To (UTC)"
                value={dateTo || ''}
                onChange={(value) => this.setState({dateTo: value})}
                placeholder="To"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={5}>
              <Form.Check
                id="only-banned-countries"
                type="checkbox"
                label="Only Banned Countries"
                checked={onlyBannedCountries}
                onChange={(e) => this.setState({onlyBannedCountries: e.target.checked})}
              />
            </Col>
          </Row>
          {exporting && <i className={`fa fa-spinner fa-3x fa-spin ${style.spinner}`} title={'spinner'} />}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              variant="outline-secondary"
              size="sm"
              className="mr-1"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              variant="success"
              size="sm"
              onClick={() => onConfirm(this.state.dateFrom, this.state.dateTo, this.state.onlyBannedCountries)}
              disabled={exporting}
            >
              Export
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
