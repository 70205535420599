import React from 'react'
import Tree from 'react-json-tree'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {keys, startCase, capitalize} from 'lodash'
import {Modal, Row, Col, Card} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {parseJSON, shorten} from '../common/utils'
import mainStyle from '../components/components.module.scss'

const theme = {
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#CC342B',
  base09: '#F96A38',
  base0A: '#FBA922',
  base0B: '#198844',
  base0C: '#3971ED',
  base0D: '#3971ED',
  base0E: '#A36AC7',
  base0F: '#3971ED',
}

class DocumentVerificationResultModal extends PureComponent {

  static propTypes = {
    rawResult: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
  }

  renderBreakDown(breakdown) {

    return keys(breakdown).map((key, i) => {

      const sectionTitle = startCase(key)
      const sectionResult = breakdown[key].result || 'Not Available'
      const sectionBreakDown = breakdown[key] && breakdown[key].breakdown
      return (
        <div key={i}>
          <Row>
            <Col xs={12}>
              <h4>{sectionTitle} {this.renderResultLabel(sectionResult)}</h4>
            </Col>
          </Row>
          {
            sectionBreakDown &&
              <Row>
                <Col xs={12}>
                  {
                    keys(sectionBreakDown).map((sKey, i) => (
                      <ul key={`section-breakdown-t-${sKey}-${i}`}>
                        <li> {startCase(sKey)} {this.renderResultLabel(sectionBreakDown[sKey].result)} </li>
                      </ul>
                    ))
                  }
                </Col>
              </Row>
          }
        </div>
      )
    })
  }

  renderIdCheck(idcheck) {
    return keys(idcheck).map((key, i) => {
      if (['result', 'alerts'].includes(key)) return ''

      const sectionTitle = startCase(key)
      const sectionResult = idcheck[key]
      return (
        <div key={i}>
          <Row>
            <Col xs={12}>
              <h4>{sectionTitle}</h4>
            </Col>
          </Row>
          {
            sectionResult &&
              <Row>
                <Col xs={12}>
                  {
                    keys(sectionResult).map((sKey, i) => (
                      <ul key={`section-result-t-${sKey}-${i}`}>
                        <li> {startCase(sKey)}: {shorten(sectionResult[sKey], 50)} </li>
                      </ul>
                    ))
                  }
                </Col>
              </Row>
          }
        </div>
      )
    })
  }

  renderRawResult(parsedResult) {
    return (
      <Card>
        <Card.Body>
          <Tree data={parsedResult} theme={theme} hideRoot />
        </Card.Body>
      </Card>
    )
  }

  renderResultLabel(label) {
    const classes = ['label']
    switch (label) {
      case 'clear':
      case 'Passed':
        classes.push('label-success')
        break
      case 'consider':
      case 'Caution':
      case 'Attention':
        classes.push('label-warning')
        break
      default:
        classes.push('label-danger')
    }
    return <span className={classNames(classes)}>{capitalize(label)}</span>
  }

  render() {
    const {onHide, show, rawResult, documentId} = this.props
    const parsedResult = parseJSON(rawResult)

    if (parsedResult instanceof Error) {
      onHide && onHide()
      return <div />
    }

    const {breakdown, checkUrl, idcheck} = parsedResult

    return (
      <Modal
        id="t-document-verification-full-result-modal"
        keyboard
        show={show}
        onHide={onHide}
        className={mainStyle.smallModalContent}
      >
        <Modal.Header closeButton className={mainStyle.modalHeader}>
          {`Document #${documentId} Auto Verification Result Full View`}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {breakdown &&
                <div>
                  <h3>Prettified Breakdown</h3>
                  <hr />
                  <div>
                    <a href={checkUrl} target="_blank" rel="noopener noreferrer"> External Link </a>
                  </div>
                  {this.renderBreakDown(breakdown)}
                </div>
              }
              {idcheck &&
                <div>
                  <h4>ID Check {this.renderResultLabel(idcheck.result)}</h4>
                  <hr />
                  {this.renderIdCheck(idcheck)}
                </div>
              }
              <div>
                <h3>Raw Result</h3>
                <hr />
                {this.renderRawResult(parsedResult)}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}
export default DocumentVerificationResultModal
