import React from 'react'
import {find} from 'lodash'
import PropTypes from 'prop-types'
import {DropdownButton, Dropdown, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {tradingStatuses, suspendedTradingStatusReasons, closedTradingStatusReasons, terminatedTradingStatusReasons,
} from '@bdswiss/common-enums'
import {canWriteClientTrading, canRevertClientTradingStatus} from '@bdswiss/common-permissions'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import ReasonModal from '../components/ReasonModal'

export default provideProps()(class TradingStatus extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    tradingStatus: PropTypes.string,
    tradingStatusReason: PropTypes.string,
    tradingStatusReasonCode: PropTypes.string,
  };

  constructor(props) {
    super(props)
    this.state = {
      showPopup: false
    }
  }

  saveTradingStatus(tradingStatusKey, reason, selectedReasonCode) {
    const {optimistic} = this.context.clientProvider
    optimistic.setTradingStatus(this.props.dispatch, this.props.clientId, tradingStatusKey)
    return this.props.actions.client.setTradingStatus(
      this.props.clientId, tradingStatusKey, reason, selectedReasonCode)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.closeModalWindow()
      }).catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  changeTradingStatus(newTradingStatus) {
    const {tradingStatus} = this.props
    if (tradingStatuses[newTradingStatus].allowTransition(tradingStatus)) {
      if (newTradingStatus === tradingStatuses.closed.key
        || newTradingStatus === tradingStatuses.suspended.key
        || newTradingStatus === tradingStatuses.rejected.key
        || newTradingStatus === tradingStatuses.terminated.key) {
        this.openModalWindow(newTradingStatus)
      } else {
        this.saveTradingStatus(newTradingStatus, '')
      }
    }
  }

  openModalWindow(tradingStatusKey) {
    this.setState({reasonArea: {}})
    this.props.uiDispatch(
      'Trading status change to ' + tradingStatusKey,
      (state) => ({
        ...state,
        tradingStatusModal: {
          show: true,
          newStatus: tradingStatusKey,
        },
      })
    )
  }

  closeModalWindow() {
    this.props.uiDispatch(
      'Closing trading status reason window',
      (state) => ({
        ...state,
        tradingStatusModal: {
          show: false,
        },
      })
    )
  }

  render() {
    const {tradingStatus, tradingStatusReason, tradingStatusReasonCode} = this.props
    const dropdownBsStyle = tradingStatus ? tradingStatuses[tradingStatus].bsStyle : 'outline-secondary'
    const modal = this.props.uiState.tradingStatusModal || {}
    const newStatusLabel = modal.newStatus ? tradingStatuses[modal.newStatus].label : ''
    const message = modal.newStatus ? tradingStatuses[modal.newStatus].warningMessage
      : tradingStatuses[tradingStatus].warningMessage
    const buttonBsClass = modal.newStatus ? tradingStatuses[modal.newStatus].bsStyle
      : tradingStatuses[tradingStatus].bsStyle
    const buttonBsText = modal.newStatus ? tradingStatuses[modal.newStatus].label
      : tradingStatuses[tradingStatus].label
    let reasons, placeholder
    if (modal.newStatus === 'suspended') {
      reasons = suspendedTradingStatusReasons
      placeholder = 'Choose Suspended Reason'
    }
    if (modal.newStatus === 'closed') {
      reasons = closedTradingStatusReasons
      placeholder = 'Choose Closure Reason'
    }
    if (modal.newStatus === 'rejected') {
      reasons = closedTradingStatusReasons
      placeholder = 'Choose Rejection Reason'
    }
    if (modal.newStatus === 'terminated') {
      reasons = terminatedTradingStatusReasons
      placeholder = 'Choose Termination Reason'
    }
    const disabled = !canWriteClientTrading(this.props.viewer)
      || (tradingStatus === tradingStatuses.closed.key && !canRevertClientTradingStatus(this.props.viewer))
    const suspendedReason = tradingStatus === tradingStatuses.suspended.key
      ? find(suspendedTradingStatusReasons, {key: tradingStatusReasonCode})?.label
      : ''
    return (
      <div id="t-client-trading-status">
        <OverlayTrigger
          placement="left"
          onToggle={(nextShow) => {
            if (nextShow) {
              this.setState({showPopup: tradingStatus === tradingStatuses.suspended.key && suspendedReason})
            } else {
              this.setState({showPopup: nextShow})
            }
          }}
          show={!!this.state.showPopup}
          overlay={<Tooltip>{`${suspendedReason}${tradingStatusReason ? ` - ${tradingStatusReason}` : ''}`}</Tooltip>}
        >
          <span>
            <DropdownButton
              variant={dropdownBsStyle}
              disabled={disabled}
              title={tradingStatus ? tradingStatuses[tradingStatus].label : <span>&nbsp;</span>}
              onSelect={(event) => this.changeTradingStatus(event)}
              id="trading-status"
              style={disabled ? {pointerEvents: 'none'} : {}}
            >
              {Object.values(tradingStatuses)
                .filter((opt) => opt.allowTransition(tradingStatuses[tradingStatus]))
                .map((opt, i) => <Dropdown.Item key={opt.key} eventKey={opt.key}>{opt.label}</Dropdown.Item>)}
            </DropdownButton>
          </span>
        </OverlayTrigger>

        <ReasonModal
          show={modal.show}
          header={`Changing Trading Status to ${newStatusLabel}`}
          placeholder={placeholder}
          onCancel={this.closeModalWindow.bind(this)}
          onDone={(reason, selectedReasonCode) => {
            this.saveTradingStatus(modal.newStatus, reason, selectedReasonCode)
            this.closeModalWindow()
          }}
          reasons={reasons}
          warningMessage={message}
          submitButtonBsClass={buttonBsClass}
          submitButtonText={buttonBsText}
        />
      </div>
    )
  }
})
