import {yesNo, depositStatuses} from '@bdswiss/common-enums'
import {map, find, get} from 'lodash'
import {getOffset} from '../useful'
import {formatAccountTypeFilterInput} from '../schemaUtils'

export default function create(dbClient) {
  return {
    depositsExportSearch(props, page, limit) {
      const {depositsStatusFilter, depositsSalesAgentFilter,
        depositsAccountsFilter, depositsSearch, companyFilter, vendorsFilter, dateFilters,
        clientTypesFilter, countriesFilter, amountFilters, currencyFilter, topVipFilter, partnersAgentFilter,
        conversionStatusFilter,
      } = props

      const depositStatusValues = map(depositsStatusFilter, (status) => depositStatuses[status].value)

      const searchArgs = {
        status: depositStatusValues,
        salesAgentId: depositsSalesAgentFilter,
        accountTypeSubtype: formatAccountTypeFilterInput(depositsAccountsFilter),
        search: depositsSearch,
        company: companyFilter,
        vendor: vendorsFilter,
        clientTypes: clientTypesFilter,
        countries: countriesFilter,
        currencies: currencyFilter,
        topVip: get(find(yesNo, {value: topVipFilter})),
        supportAgentId: partnersAgentFilter,
        conversionStatus: conversionStatusFilter,
      }

      if (dateFilters && dateFilters.createdFrom) {
        searchArgs.creationTimeFrom = dateFilters.createdFrom
      }

      if (dateFilters && dateFilters.createdTo) {
        searchArgs.creationTimeTo = dateFilters.createdTo
      }

      if (amountFilters && amountFilters.amountFrom) {
        searchArgs.amountFrom = amountFilters.amountFromom
      }

      if (amountFilters && amountFilters.amountTo) {
        searchArgs.amountTo = amountFilters.amountTo
      }

      const variables = {
        searchArgs: JSON.stringify(searchArgs),
        limit: limit,
        offset: getOffset(page, limit),
        orderBy: 'id',
        orderDirection: 'descending'
      }

      const query = `mutation (
          $searchArgs: String
          $limit: Int
          $offset: Int
          $orderBy: OrderBy
          $orderDirection: OrderDirection
        ) {
        depositsExportSearch(
          searchArgs: $searchArgs
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          orderDirection: $orderDirection
        )
      }`

      return dbClient.query(query, variables)
    },
  }
}

