import React from 'react'
import classnames from 'classnames'
import {Navbar, OverlayTrigger, Popover, Button, ButtonGroup, Image, Form, Row, Col, Tooltip} from 'react-bootstrap'
import {socketEvents} from '@bdswiss/common-enums'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'
import {first, map, split} from 'lodash'
import {provideProps} from './decorators'
import {minuteMillis, getPageSize, setPageSize, getFetchInterval, setFetchInterval, isNumeric,
  getDepositNotificationEnabled, setDepositNotificationEnabled, getWithdrawalNotificationEnabled,
  setWithdrawalNotificationEnabled,
  getUserProfileSectionsEnabled,
  setUserProfileSectionsEnabled} from './useful'
import style from './app.module.scss'
import PureComponent from './PureComponent'
import FontAwesomeIcon from './components/FontAwesomeIcon'

const fetchIntervals = [
  {
    label: 'Off',
    value: 0,
  },
  {
    label: '5 min',
    value: 5 * minuteMillis,
  },
  {
    label: '10 min',
    value: 10 * minuteMillis,
  },
]

const depositNotificationSettings = [
  {
    label: 'Enable',
    value: 1,
  },
  {
    label: 'Disable',
    value: 0,
  },
]

const withdrawalsNotificationSettings = [
  {
    label: 'Enable',
    value: 1,
  },
  {
    label: 'Disable',
    value: 0,
  },
]

const userProfileSectionsSettings = [
  {
    label: 'User Settings',
    value: 'showClientToolsSection'
  },
  {
    label: 'Marketing',
    value: 'showMarketingSection'
  },
  {
    label: 'Risk',
    value: 'showRiskSection',
  },
  {
    label: 'Economic Profile',
    value: 'showEPSection',
  },
  {
    label: 'Appropriateness Test',
    value: 'showAPTestSection',
  },
  {
    label: 'Partner Data',
    value: 'showPartnerDataSection',
  },
  {
    label: 'Events',
    value: 'showEventsSection',
  },
  {
    label: 'Strategic Partners',
    value: 'showStrategicPartnerSection'
  },
  {
    label: 'BDX',
    value: 'showBDXSection'
  },
]

export default provideProps()(class GlobalSettings extends PureComponent {

  static contextTypes = {
    logOut: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const pageSize = getPageSize(),
      fetchInterval = getFetchInterval(),
      depositNotification = getDepositNotificationEnabled(),
      withdrawalNotification = getWithdrawalNotificationEnabled(),
      userProfileSections = getUserProfileSectionsEnabled()
    // temp disable to check speed
    // if (fetchInterval < 5 * minuteMillis) setFetchInterval(5 * minuteMillis)
    this.setState({values: {pageSize, depositNotification, withdrawalNotification, userProfileSections,
      fetchInterval}, errors: {}, touched: false})
  }

  resetForm = () => this.setState({
    values: {},
    errors: {},
  })

  onExitSettings = () => {
    const {values, errors, touched} = this.state

    if (!touched) {
      return
    }

    setFetchInterval(values.fetchInterval)
    setDepositNotificationEnabled(values.depositNotification)
    setWithdrawalNotificationEnabled(values.withdrawalNotification)
    setUserProfileSectionsEnabled(values.userProfileSections)

    if (!errors.pageSize) {
      setPageSize(values.pageSize)
      this.props.dispatch('Refresh page', (state) => ({...state, refreshKey: (state.refreshKey || 0) + 1}))
    }
    this.setState({touched: false})
  }

  formValueChanged(key, value) {
    let values = {...this.state.values, [key]: value}

    if (split(key, '.')[0] === 'userProfileSections') {
      values = {...this.state.values,
        [split(key, '.')[0]]: {
          ...this.state.values.userProfileSections,
          [split(key, '.')[1]]: value,
        }
      }
    }

    const errors = this.validate(values)
    this.setState({values, errors, touched: true})
  }

  validate(values = this.state.values) {
    let {pageSize} = values
    pageSize = Number(pageSize)
    const errors = {}
    if (!pageSize || (!(isNumeric(pageSize) && pageSize > 0 && pageSize <= 50))) {
      errors.pageSize = true
    }

    return errors
  }

  logout = () => {
    this.context.logOut().then(() => {
      if (window.ioSocket) {
        window.ioSocket.emit(socketEvents.offline.value)
        window.ioSocket.disconnect()
      }
      window.location.reload()
    })
  }

  renderSettingsPopover() {
    const {values: {pageSize, fetchInterval, depositNotification, withdrawalNotification, userProfileSections},
      errors} = this.state

    return (
      <Popover
        id="global_settings"
        className={style.globalSettings}
      >
        <Row xs={12}>
          <Col xs={6}>
            <Col className={style.sectionTitle}>
              Items per Page
            </Col>
            <Col xs={12}>
              <Form.Control
                type="number"
                variant={errors.pageSize && 'error'}
                value={pageSize}
                onChange={(e) => this.formValueChanged('pageSize', e.target.value)}
              />
            </Col>
            <hr />
            <Col className={style.sectionTitle}>
              Automatic Refresh
            </Col>
            <Col xs={12}>
              <ButtonGroup className={style.tripleButtons}>
                {fetchIntervals.map((intervalDefinition) => (
                  <Button
                    size="sm"
                    key={intervalDefinition.value}
                    variant="outline-secondary"
                    active={fetchInterval === intervalDefinition.value}
                    onClick={() => this.formValueChanged('fetchInterval', intervalDefinition.value)}
                  >
                    {intervalDefinition.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
            <hr />
            <Col className={style.sectionTitle}>
              Deposit Notification
            </Col>
            <Col xs={12}>
              <ButtonGroup className={style.doubleButtons}>
                {depositNotificationSettings.map((notification) => (
                  <Button
                    size="sm"
                    key={notification.value}
                    variant="outline-secondary"
                    active={depositNotification === notification.value}
                    onClick={() => this.formValueChanged('depositNotification', notification.value)}
                  >
                    {notification.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
            <hr />
            <Col className={style.sectionTitle}>
              Withdrawal Notification
            </Col>
            <Col xs={12}>
              <ButtonGroup className={style.doubleButtons}>
                {withdrawalsNotificationSettings.map((notification) => (
                  <Button
                    size="sm"
                    key={notification.value}
                    variant="outline-secondary"
                    active={withdrawalNotification === notification.value}
                    onClick={() => this.formValueChanged('withdrawalNotification', notification.value)}
                  >
                    {notification.label}
                  </Button>
                ))}
              </ButtonGroup>
            </Col>
            <hr />
          </Col>
          <Col xs={6}>
            <Col className={style.sectionTitle}>
              User Profile
              <OverlayTrigger
                placement="bottom"
                trigger="hover"
                overlay={<Tooltip>
                  <span> Select which sections will be loaded by default when opening the client profile </span>
                </Tooltip>}
              >
                <span> <FontAwesomeIcon icon="info-circle" /> </span>
              </OverlayTrigger>
            </Col>
            {map(userProfileSectionsSettings, (userProfileSection, index) => (
              <Row key={`toggle-user-profile-sections-${index}`}>
                <Col xs={7} style={{marginTop: 4}}>
                  <span><b> {userProfileSection.label} </b></span>
                </Col>
                <Col xs={4}>
                  <input id={`toggle-user-profile-sections-${userProfileSection.value}`} type="checkbox"
                    className={classnames([style.toggleSwitch, style.toggleLight, 'float-right'])}
                    checked={userProfileSections[userProfileSection.value]}
                    onChange={(e) => {
                      this.formValueChanged(`userProfileSections.${userProfileSection.value}`, e.target.checked)
                    }}
                  />
                  <label id={`t-toggle-user-profile-sections-${index}`} htmlFor={`toggle-user-profile-sections-${userProfileSection.value}`}
                    className={classnames([style.toggleButton, 'float-right'])}
                  />
                </Col>
              </Row>
            ))}
            <hr />
            <Col>
              <Button
                size="sm"
                id="t-logout-btn"
                variant="outline-secondary"
                className={style.logoutButton}
                block
                onClick={this.logout}
              >
                <FontAwesomeIcon icon="sign-out" /> Logout
              </Button>
            </Col>
          </Col>
        </Row>
      </Popover>
    )
  }

  render() {
    const {firstName, lastName, avatar} = this.props.viewer
    const imagePlaceHolder = (<Image height={24} width={24} className={style.avatar} rounded />)
    return (
      <OverlayTrigger
        id="t-user-info"
        trigger="click"
        placement="bottom"
        overlay={this.renderSettingsPopover()}
        onExit={this.onExitSettings}
        rootClose
      >
        <Navbar.Text className={style.globalSettingsTrigger} title={`${firstName} ${lastName}`} id="t-user-settings">
          <span className={style.userName}>{firstName} {first(lastName)} {''}</span>
          {avatar &&
            <LazyLoad overflow once scroll throttle={100} placeholder={imagePlaceHolder}>
              <Image height={24} width={24} src={avatar} className={style.avatar} rounded />
            </LazyLoad>
          }
          <FontAwesomeIcon icon="caret-down" />
        </Navbar.Text>
      </OverlayTrigger>
    )
  }
})
