import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import BlacklistForm from './BlacklistForm'
import PureComponent from '../PureComponent'

export default class BlacklistCreate extends PureComponent {
  render() {
    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/blacklists"
            active={false}
          >
            <BreadcrumbItem>
              Blacklist
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            Create
          </BreadcrumbItem>
        </Breadcrumb>
        <BlacklistForm />
      </Container>
    )
  }
}
