import moment from 'moment'
import {head, find, get, filter} from 'lodash'
import {yesNo, generateKeys, orderDirections} from '@bdswiss/common-enums'
import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {formatAccountTypeFilterInput} from '../schemaUtils'
import {orderByFields} from '../common/utils'

export const showEnum = generateKeys({
  adminBonus: {
    label: 'Admin Bonus',
  },
  deposits: {
    label: 'Deposits',
  },
})

function initDeposits(deposits) {
  for (const deposit of deposits) {
    deposit.createdAt = moment(deposit.createdAt)
    deposit.createdAt.isValid()
  }
  return deposits
}

function initAdminBonuses(bonusOffers) {
  for (const bonusOffer of bonusOffers) {
    bonusOffer.createdAt = moment(bonusOffer.createdAt)
    bonusOffer.createdAt.isValid()
  }
  return bonusOffers
}

export const depositsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {depositsStatusFilter, depositsSalesAgentFilter,
      depositsAccountsFilter, depositsSearch, show, companyFilter, vendorsFilter, dateFilters,
      clientTypesFilter, countriesFilter, amountFilters, currencyFilter, topVipFilter, partnersAgentFilter,
      orderByFilter, orderDirectionFilter, conversionStatusFilter, campaignId,
    } = props

    const countriesAsList = countriesFilter ? countriesFilter.join(', ') : ''
    const cpart1 = depositsStatusFilter ? `status: [${depositsStatusFilter}]` : ''
    const cpart2 = depositsSalesAgentFilter ? `salesAgentId: ${depositsSalesAgentFilter}` : ''
    const cpart3 = companyFilter ? `company: ${companyFilter}` : ''
    const cpart4 = vendorsFilter ? `vendors: [${vendorsFilter}]` : ''
    const cpart5 = head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : ''
    const cpart6 = countriesFilter ? `countries: [${countriesAsList}]` : ''
    const cpart7 = currencyFilter ? `currencies: [${currencyFilter}]` : ''
    const cpart8 = topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : ''
    let condition = [cpart1, cpart2, cpart3, cpart4, cpart5, cpart6, cpart7, cpart8,
      depositsAccountsFilter ? `accountTypeSubtype: ${formatAccountTypeFilterInput(depositsAccountsFilter)}` : '',
      dateFilters && dateFilters.createdFrom ?
        `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : '',
      dateFilters && dateFilters.createdTo ?
        `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : '',
      amountFilters && amountFilters.amountFrom ? `amountFrom: ${amountFilters.amountFrom}` : '',
      amountFilters && amountFilters.amountTo ? `amountTo: ${amountFilters.amountTo}` : '',
      partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
      conversionStatusFilter ? `conversionStatus: ${conversionStatusFilter}` : '',
      campaignId ? `campaignId: ${campaignId}` : '',
    ]
    // TODO: i assume this was written to filter truthy values, but its incorrect use one of the following 2 instead:
    //  condition = condition.filter(c => c)
    //  condition = condition.filter(Boolean)
    condition = filter(condition, (c) => c)

    const estimated = !((depositsStatusFilter && depositsStatusFilter.length <= 2) ||
      (vendorsFilter && vendorsFilter.length <= 2) || condition.length >= 3)

    const orderBy = orderByFilter ? `orderBy: ${orderByFields[orderByFilter].key}` : 'orderBy: id'
    const orderDirection = orderDirectionFilter
      ? `orderDirection: ${orderDirections[orderDirectionFilter].key}`
      : 'orderDirection: descending'

    let query
    switch (show) {
      case showEnum.adminBonus.key: {

        query = `{
          bonusOffers(
            limit: ${getPageSize()}
            offset: ${getOffset(props.depositsPage)}
            ${orderBy}
            ${orderDirection}
            status:[pending]
            type: adminBonus
          ) {
            id
            currency
            amount
            type
            account {
              __typename
              ... on BaseAccount {
                id
                deletedAt
                client {
                  id
                  firstName
                  lastName
                  email
                  salesAgent {
                    id
                    firstName
                    lastName
                  }
                  topVip
                }
                ... on BaseForexAccount {
                  forexId
                }
                ... on BaseOptionAccount {
                  spotOptionId
                }
              }
            }
            status
            createdAt
          }
          bonusOffersCount(estimated: true)
      }`
        break
      }
      default:
        query = `{
          deposits(
            ${condition.length ? condition.join('\n').trim() : ''}
            ${depositsSearch ? `search: "${depositsSearch}"` : ''}
            limit: ${getPageSize()}
            offset: ${getOffset(props.depositsPage)}
            ${orderBy}
            ${orderDirection}
          ) {
              id
              currency
              amount
              salesAgent {
                id
                firstName
                lastName
              }
              account {
                __typename
                ... on BaseAccount {
                  id
                  remoteId
                  client {
                    id
                    firstName
                    lastName
                    email
                    company
                    topVip
                    registrationCampaigns {
                      campaignId
                      campaign {
                        noCall
                      }
                    }
                    accounts {
                      __typename
                      ... on BaseAccount {
                        id
                      }
                    }
                  }
                }
                ... on BaseForexAccount {
                  forexPositionsCount(tradingPositionStatus: open)
                }
              }
              payment {
                vendor
                receipt
                productReceipt
                meta
                transferFromAccount {
                  __typename
                  ... on BaseAccount {
                    id
                    remoteId
                    client {
                      id
                    }
                  }
                }
              }
              status
              createdAt
            }
            depositsCount(estimated: ${estimated} ${condition.length ? condition.join('\n').trim() : ''})
  }`
        return query
    }

    return query
  },

  onData: (res, dispatch) => dispatch(
    'Deposits loaded',
    (state, res) => ({
      ...state,
      deposits: res.deposits != null
        ? initDeposits(res.deposits)
        : state.deposits || [],
      depositsCount: res.depositsCount != null
        ? res.depositsCount
        : state.depositsCount || 0,
      bonusOffers: res.bonusOffers != null
        ? initAdminBonuses(res.bonusOffers)
        : state.bonusOffers || [],
      bonusOffersCount: res.bonusOffersCount != null
        ? res.bonusOffersCount
        : state.bonusOffersCount || 0,
    }),
    [res]
  ),

}

export const depositsTopVipCountProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const query = `{
      depositsTopVipCount {
        count
        status
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Deposits Vip Count loaded',
    (state, res) => ({
      ...state,
      depositsTopVipCount: res.depositsTopVipCount != null
        ? res.depositsTopVipCount
        : state.depositsTopVipCount || 0
    }),
    [res]
  ),
}


export const thisMonthDepositsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const now = new Date()
    const startOfTheMonth = new Date(now.getFullYear(),now.getMonth(),1,0, 0, 0).toISOString()

    const query = `{
      deposits(creationTimeFrom: "${startOfTheMonth}") {
        id
        currency
        amount
        createdAt
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'This month deposits loaded',
    (state, res) => ({
      ...state,
      thisMonthDeposits: res.deposits || []
    }),
    [res]
  ),
}
