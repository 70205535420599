import {getFetchInterval} from '../useful'

export const rolesProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const query = `{
      roles {
        id
        name
        description
        permissions {
          id
        }
      }
    }`
    return query
  },

  onData: (res, dispatch) =>
    dispatch(
      'Roles list loaded',
      (state, res) => ({
        ...state,
        roles: res.roles
          ? res.roles.sort((a, b) => a.name.localeCompare(b.name))
          : [],
      }),
      [res]
    ),
}
