import React from 'react'
import PropTypes from 'prop-types'
import {Row, Card, Col, Badge, Button, Modal} from 'react-bootstrap'
import classnames from 'classnames'
import {communicationChannels, telephonyProviders} from '@bdswiss/common-enums'
import ReactAudioPlayer from 'react-audio-player'
import {isNil, unescape, get, includes} from 'lodash'
import {provideProps} from '../../decorators'
import 'react-datetime/css/react-datetime.css'
import PureComponent from '../../PureComponent'
import style from './Communications.module.scss'
import CommunicationLink from './CommunicationLink'
import EmailDetail from '../emailview/EmailDetail'
import {safeParseJSON} from '../../common/utils'
import FontAwesomeIcon from '../../components/FontAwesomeIcon'

class Communications extends PureComponent {
  state = {}
  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }
  static propTypes = {
    communications: PropTypes.array.isRequired,
    communicationsCount: PropTypes.number.isRequired,
    clientId: PropTypes.number.isRequired,
  }

  componentWillReceiveProps (nextProps) {
    nextProps.isActive && this.props.communications.length === 0 && this.fetch()
    const tab = get(nextProps, 'match.params.tab')
    const oldMessageId = get(this.props, 'match.params.sidebarArg')
    const newMessageId = get(nextProps, 'match.params.sidebarArg')
    if (tab === 'emails' && newMessageId !== oldMessageId) {
      this.fetchThread(newMessageId)
    }
  }

  fetch = () => {
    const {dataLoading, dataError} = this.state
    if (!(dataLoading || dataError)) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.communications.fetch()
          .then(() => this.setState({dataLoading: false}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  fetchThread = (messageId) => {
    if (!messageId) {
      return this.setState({showModal: false})
    }
    this.props.actions.client.fetchEmailThread(messageId)
      .then((res) => {
        this.setState({
          thread: res.emailThread,
          showModal: true,
        })
      })
      .catch(this.context.logError)
  }

  render() {
    const {communications, communicationsCount, callRecordingsUri, clientId, match} = this.props
    const {dataLoading, dataError, showModal, thread} = this.state
    if (dataError) return <pre> Error Loading Communications : {dataError.message} </pre>

    return (
      <div>
        <Row>
          <Col xs={12} className="communications">
            {communications.length === 0 && <span>No Communications</span>}
            <Card className={classnames(['panel-events'])}>
              <Card.Body>
                {communications.map((communication, i) => {
                  const {channels, type, createdAt, title, summary, meta} = communication
                  const parsedMeta = safeParseJSON(meta)
                  const {labelIds, hasAttachments} = parsedMeta
                  const isTrashed = includes(labelIds, 'TRASH')
                  return (
                    <Row key={`comm-${i}`} className={style.row}>
                      <Col xs={12}>
                        <CommunicationLink communication={communication}>
                          <div>
                            {channels.map((channel, i) => <Badge
                              className={style.channels}
                              variant="success"
                              pill
                              key={`channel-${i}`}
                            >{communicationChannels[channel].label}</Badge>
                            )}
                            <small className={style.createdAt}>{createdAt.fromNow()}</small>
                          </div>
                          <div className={style.title}>
                            {hasAttachments && <small><FontAwesomeIcon icon="paperclip" />{' '}</small>}
                            {unescape(title)}{isTrashed && <small> - <span className={style.deleted}>DELETED</span></small>}
                          </div>
                          <div className={style.summary}>{unescape(summary)}</div>
                        </CommunicationLink>
                        {type === 'call' && <ReactAudioPlayer
                          src={`${callRecordingsUri[get(parsedMeta, 'provider', telephonyProviders.impactech.value)]}${parsedMeta.recording}`}
                          preload="none"
                        />}
                      </Col>
                    </Row>
                  )
                })}
                {communications.length !== communicationsCount && <Row>
                  <Col xs={12} style={{textAlign: 'center', padding: '10px'}}>
                    <Button onClick={this.fetch}>Fetch more</Button>
                  </Col>
                </Row>}
                {(isNil(communications) || dataLoading) && <Row>
                  <Col xs={12} className="text-center">Loading Communications...</Col>
                </Row>}
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Modal
          id="t-email-full-view-modal"
          keyboard
          show={showModal}
          onHide={() => {
            this.setState({showModal: false})
            this.context.router.push(`/clients/${clientId}`)
          }}
          className={style.emailModal}
        >
          <Modal.Header closeButton className={style.modalHeader}>
            <strong>{get(thread, '0.subject')}</strong>
          </Modal.Header>
          <Modal.Body>
            <EmailDetail thread={thread} match={match} />
          </Modal.Body>
        </Modal>
      </div>
    )
  }
}

export default provideProps((state, _, __, props) => {
  const {client,} = state
  const clientId = Number(get(props, 'match.params.clientId'))
  return ({
    communications: get(client[clientId], 'communications', []),
    communicationsCount: get(client[clientId], 'communicationsCount', 0),
    clientId,
  })
})(Communications)
