export default function create(dbClient, dispatch) {
  return {
    assignDocuments(userId, documentIds) {
      const variables = {
        userId,
        documentIds,
      }

      const query = `
        mutation (
          $userId: Int!
          $documentIds: [Int]
        ) {
          assignDocuments(
            userId: $userId,
            documentIds: $documentIds,
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    changeDocumentsStatus(status, documentIds) {
      const variables = {
        status,
        documentIds,
      }

      const query = `
        mutation (
          $status: DocumentStatus!
          $documentIds: [Int]
        ) {
          changeDocumentsStatus(
            status: $status
            documentIds: $documentIds
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
  }
}
