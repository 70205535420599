import {map, filter, uniq} from 'lodash'
import {accountTypes} from '@bdswiss/common-enums'

export const affiliateAccountsProvider = {

  fetchInterval: 0,

  getQuery: (props) => {
    const affiliateSearch = props.affiliateSearch
    const productIds = uniq(map(filter(accountTypes, {category: 'affiliate'}), 'productId'))

    if (!affiliateSearch || affiliateSearch.length < 0) return null

    const query = `
      {
        accounts (
          limit: 100
          productIds: [${productIds}]
          remoteId: ${affiliateSearch}
        ) {
          ... on BaseAccount {
            id
            remoteId
            client {
              firstName
              lastName
            }
          }
        }
      }
    `
    return query
  },

  onData: (res, dispatch) => {
    dispatch(
      'Affiliate Accounts Loaded',
      (state, res) => ({...state, affiliateList: res.accounts}),
      [res]
    )
  }
}

export const lastCampaignProvider = {

  fetchInterval: 0,

  getQuery: (props) => {
    const affiliateId = props.affiliateId

    if (!affiliateId) return null

    const query = `
    {
      accounts (remoteId: ${affiliateId}) {
      ... on BaseAffiliateAccount {
          lastRegistrationCampaign {
            id
            campaignId
            subCampaignId
            campaign {
							name
            }
          }
        }
      }
    }
  `
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Last Campaign Loaded',
    (state, res) => ({...state, lastCampaign: res.accounts[0]?.lastRegistrationCampaign, lastCampaignFound: !!res.accounts[0]?.lastRegistrationCampaign}),
    [res]
  ),

}

export const campaignsProvider = {

  fetchInterval: 0,

  getQuery: (props) => {
    const campaignSearch = props.campaignSearch

    if (!campaignSearch || campaignSearch.length < 0) return null

    const query = `
    {
      campaigns(
        limit: 100
        search: "${campaignSearch}"
      ) {
        id
        name
      }
    }
  `
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Campaigns Loaded',
    (state, res) => ({...state, campaignList: res.campaigns}),
    [res]
  ),

}

