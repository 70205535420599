/* eslint-disable react/no-direct-mutation-state */
import React from 'react'
import {Row, Col, ButtonToolbar, Button, Card, Form} from 'react-bootstrap'
import {companies, telephonyProviders} from '@bdswiss/common-enums'
import moment from 'moment'
import PropTypes from 'prop-types'
import {get, map} from 'lodash'
import ReactAudioPlayer from 'react-audio-player'
import {canQueryClients} from '@bdswiss/common-permissions'
import StylishSelect from '../components/StylishSelect'
import {callLogProvider} from './providers'
import {events} from '../enums'
import {compose, provideProps, mountDataProviders, uiMount} from '../decorators'
import {getFullName} from '../utils/general'
import {safeParseJSON} from '../common/utils'

const durationLabel = duration => duration > 120 ? Math.round(duration / 60) + 'm' : duration + 's'
const questionaire = {
  doesAgentIdentifyHimself: 'Does the agent identify him/herself correctly? (his name, name of the company, brand, location)',
  doesAgentMentionRisl: 'If the agent mentions profit does he also mention risk of losing?',
  doesAgentAttempToManageAssets: 'Does the agent appear to manage client\'s money/portfolios/accounts/assets?',
  doesAgentAttempToDepositTooMuch: 'Does the agent push the client to deposit more than he can afford to lose (to use CC, to break savings or pension funds, to make loans etc)?',
  doesAgentAskAboutExp: 'Does the AM ask client about past experience/knowledge?	',
  doesAgentExplainResponsibility: 'Does the AM explain that the client is the only one responsible for his trading?',
  doesAgentTalkAboutEvents: 'Does the agent talk about an event (news) in the market mentioning the source?',
  doesAgentSuggestOtherCommunication: 'Does the AM suggest to communicate in a different way besides the work phone number?',
  doesAgentComplyWithRegulations: 'Does the agent comply with the rules and regulations(risk warning, investment advise, aggressive call, pushing etc)?',
}

const profOptions = ['High professionalism', 'Good communication', 'Language barrier', 'Poor professionalism', 'Not Professional at all'].reverse()

class CallLogDetails extends React.Component {
  static contextTypes = {
    callLogsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      monitoring: {}
    }
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.callLogsProvider.fetch()
  }

  static getDerivedStateFromProps({callLog}, prevState) {
    if (callLog && (!prevState.monitoring || callLog.id !== prevState.callLogId)) {
      return {
        callLogId: callLog.id,
        monitoring: safeParseJSON(callLog.monitoring),
        needsComplianceCheck: callLog.needsComplianceCheck || false
      }
    }
    return prevState
  }

  render() {
    const {callLog, viewer, baseUri} = this.props
    const provider = get(callLog, 'provider', telephonyProviders.impactech.value)

    return (
      <div>
        <Card id="t-client-edit-details-sidebar">
          <Card.Header>{`Call details #${callLog.id}`}</Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} style={{textAlign: 'center', paddingBottom: 20}}>
                <ReactAudioPlayer
                  src={`${baseUri[provider]}${callLog.recording}`}
                  preload="none"
                  controls
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={5}>
                <Form.Label><strong>Agent</strong></Form.Label><br />{getFullName(callLog.user)}
              </Col>
              <Col xs={3}>
                <Form.Label><strong>Status</strong></Form.Label><br />{callLog.disposition}
              </Col>
              <Col xs={4}>
                <Form.Label><strong>Monitoring Agent</strong></Form.Label><br />{getFullName(callLog.monitoringAgent) || '-'}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={5}>
                <Form.Label><strong>Date/Time</strong></Form.Label><br />{moment(callLog.startedAt).format('LLL')}
              </Col>
              <Col xs={3}>
                <Form.Label><strong>Duration</strong></Form.Label><br />{durationLabel(callLog.durationBillable)}
              </Col>
              <Col xs={4}>
                <Form.Label><strong>Company</strong></Form.Label><br />{get(companies, [callLog.company, 'label'])}
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={12}>
                {canQueryClients(viewer) && [
                  <Form.Label key="lb-client"><strong>Client</strong></Form.Label>,
                  <br key="br-client" />,
                  <span key="sp-client">{`[${callLog.client.id}] ${getFullName(callLog.client)}`}</span>
                ]}
                <Form.Check
                  className="mt-3 mb-3"
                  label='Needs compliance check'
                  checked={this.state.needsComplianceCheck || callLog.needsComplianceCheck || false}
                  onChange={(e) => this.setState({needsComplianceCheck: e.target.checked})}
                />
                <Form.Group>
                  <Form.Label>Critical minute</Form.Label>
                  <Form.Control
                    type='text'
                    value={this.state.monitoring.criticalMinute || ''}
                    onChange={(e) => this.onMonitoringFieldChanged('criticalMinute', e.target.value)}
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label>Score</Form.Label>
                  <Form.Control
                    type='number'
                    max={5}
                    value={this.state.monitoring.score || ''}
                    onChange={(e) => this.onMonitoringFieldChanged('score', e.target.value)}
                  />
                </Form.Group>
                {map(questionaire, (label, key) =>
                  <StylishSelect.Input
                    key={key}
                    label={label}
                    options={[{label: 'Yes', value: 'true'}, {label: 'No', value: 'false'}, {label: 'N/A', value: ''}]}
                    value={this.state.monitoring[key]}
                    onChange={({value}) => this.onMonitoringFieldChanged(key, value)}
                  />
                )}
                <StylishSelect.Input
                  label='Please rate the professionalism of the agent '
                  options={map(profOptions, (label, value) => ({label, value: value.toString()}))}
                  value={this.state.monitoring.professionalism || ''}
                  onChange={({value}) => this.onMonitoringFieldChanged('professionalism', value)}
                />
                <Form.Group>
                  <Form.Label>Comments</Form.Label>
                  <Form.Control
                    as='textarea'
                    rows={3}
                    value={this.state.monitoring.comments || ''}
                    onChange={(e) => this.onMonitoringFieldChanged('comments', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <ButtonToolbar className="float-right">
                  <Button
                    id="t-client-edit-callog-save"
                    variant="success"
                    onClick={() => this.save()}>
                    Save
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    )
  }

  onMonitoringFieldChanged(key, value) {
    this.setState({
      monitoring: {
        ...this.state.monitoring,
        [key]: value
      }
    })
  }

  async save() {
    const {callLog: {id}} = this.props
    const {needsComplianceCheck, monitoring} = this.state
    try {
      await this.props.actions.callLog.updateCallLog({
        id,
        monitoring,
        needsComplianceCheck
      })
      this.context.showNotification({
        title: 'Call log updated',
        position: 'tr',
        level: 'success',
      })
      this.context.router.push('/call-logs')
    } catch (error) {
      this.context.showNotification({
        title: 'Failed to update call log',
        message: error.message,
        position: 'tr',
        level: 'error',
      })
    }
  }
}

export default compose(
  uiMount((state) => ['ui', 'call-logs']),

  provideProps((state, uiState) => {
    const {callLog, callRecordingsUri} = state
    return ({
      callRecordingsUri,
      callLog,
    })
  }),

  mountDataProviders({callLogProvider}),
)(CallLogDetails)
