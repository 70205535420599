import React from 'react'
import PropTypes from 'prop-types'
import {filter, some} from 'lodash'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import SelectAgent from '../components/SelectAgent'
import {canWriteClientKycStatus, canWriteClientSalesAgent, canUpdateClientSupportAgent,
} from '@bdswiss/common-permissions'
import {compose, provideProps, uiMount, predispatch} from '../decorators'
import {conversionStatuses} from '@bdswiss/common-enums'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import {agentTypes} from '../client/ChangeAgent'

class BatchAssign extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    selectedClientsCount: PropTypes.number.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  };

  clearForm() {
    this.props.uiDispatch(
      'Clear form in batch assign dropdown',
      (state) => ({
        ...state,
        selectedAgent: null,
        conversionStatus: null,
        selectedPartnersAgent: null,
        formError: '',
        formErrorMessage: '',
      })
    )
  }

  setAgent(agentId) {
    this.props.uiDispatch(
      'Select agent in batch assign dropdown',
      (state) => ({
        ...state,
        selectedAgent: agentId,
        formError: '',
        formErrorMessage: '',
      })
    )
  }

  setPartnersAgent(agentId) {
    this.props.uiDispatch(
      'Select partners agent in batch assign dropdown',
      (state) => ({
        ...state,
        selectedPartnersAgent: agentId,
        formError: '',
        formErrorMessage: '',
      })
    )
  }

  setConversionStatus(conversionStatusKey) {
    this.props.uiDispatch(
      'Select conversion status in batch assign dropdown',
      (state) => ({
        ...state,
        conversionStatus: conversionStatusKey,
        formError: '',
      })
    )
  }

  dispatchError() {
    this.props.uiDispatch(
      'And error in batch assign dropdown',
      (state) => ({
        ...state,
        formError: 'error',
      })
    )
  }

  saveAll() {
    this.clearForm()
    this.props.onSave(this.props.selectedAgent, this.props.conversionStatus, this.props.selectedPartnersAgent)
  }

  close() {
    this.clearForm()
    this.props.onCancel()
  }

  trySave() {
    const {selectedAgent, conversionStatus, selectedPartnersAgent} = this.props
    if (!selectedAgent && !conversionStatus && !selectedPartnersAgent) {
      this.dispatchError()
    } else {
      this.saveAll()
    }
  }

  render() {
    const outerClassName = this.props.formError ? 'form-group has-error' : 'form-group'
    const {selectedClientsCount: clientsCount} = this.props
    const headerText = `Batch Assign ${clientsCount} ${clientsCount === 1 ? 'Client' : 'Clients'}`

    const partnerAgents = filter(this.props.agents, (agent) =>
      agent.isActive && some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)))

    return (
      <Modal
        keyboard
        show={this.props.show}
        onHide={() => this.close()}
      >
        <Modal.Header>
          <strong>{headerText}</strong>
        </Modal.Header>
        <Modal.Body>
          <div className={outerClassName}>
            {canWriteClientSalesAgent(this.props.viewer) && (
              <SelectAgent.Input
                key="input"
                placeholderText="Select Sales Agent"
                label="Sales Agent"
                agents={filter(this.props.agents, (agent) => agent.isActive)}
                value={this.props.selectedAgent}
                clearable
                optionsFilter={agentTypes.salesAgent.filter}
                onChange={(o) => this.setAgent(o && o.value)}
              />
            )}
            {canUpdateClientSupportAgent(this.props.viewer) && (
              <SelectAgent.Input
                key="partners-agent-input"
                placeholderText="Select Partners Agent"
                label="Partners Agent"
                agents={partnerAgents}
                optionsPrefix={[{value: 'unset', label: 'Unassigned'}]}
                value={this.props.selectedPartnersAgent}
                clearable
                optionsFilter={agentTypes.supportAgent.filter}
                onChange={(o) => this.setPartnersAgent(o && o.value)}
              />
            )}
            {canWriteClientKycStatus(this.props.viewer) &&
              (<StylishSelect.Input
                placeholderText="Sales Status"
                options={StylishSelect.enumToStylishOptions(conversionStatuses)}
                value={this.props.conversionStatus}
                clearable
                label="Sales Status"
                onChange={(o) => this.setConversionStatus(o && o.value)}
              />)
            }
          </div>
          {this.props.formErrorMessage}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              variant="outline-secondary"
              onClick={() => this.close()}
              className="mr-3"
            >Cancel</Button>
            <Button
              variant="success"
              onClick={() => this.trySave()}
            >Save</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  uiMount(() => ['clientsUi', 'batchAssignModal']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for batch assign sales',
      (state) => ({state})
    )
  }),

  provideProps((state, uiState) => {
    const {agents} = state
    const {selectedAgent, conversionStatus, formError, formErrorMessage, selectedPartnersAgent} = uiState

    return ({
      // global state
      agents,
      // component state
      selectedAgent,
      conversionStatus,
      formError,
      formErrorMessage,
      selectedPartnersAgent,
    })
  }),
)(BatchAssign)
