import React from 'react'
import PropTypes from 'prop-types'
import Tree from 'react-json-tree'
import {Modal, Card} from 'react-bootstrap'
import style from './components.module.scss'
import PureComponent from '../PureComponent'

const theme = {
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#CC342B',
  base09: '#F96A38',
  base0A: '#FBA922',
  base0B: '#198844',
  base0C: '#3971ED',
  base0D: '#3971ED',
  base0E: '#A36AC7',
  base0F: '#3971ED',
}

export default class JsonDataModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
  };

  render() {
    const {data, heading, onHide, show} = this.props
    const shouldExpandNode = this.props.shouldExpandNode || (() => false)

    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
        className={style.receipt}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          {heading}
        </Modal.Header>
        <Modal.Body>
          <Card className={style.jsonData}>
            <Card.Body>
              <Tree
                data={data}
                theme={theme}
                hideRoot
                shouldExpandNode={shouldExpandNode}
              />
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    )
  }
}
