import React from 'react'
import PropTypes from 'prop-types'
import {includes, get, map, replace, isNil} from 'lodash'
import {ButtonToolbar, Button, Row, Col, Card, Form} from 'react-bootstrap'
import classnames from 'classnames'
import {accountTypes, yesNo, rawSubscriptionPlans, clientTypes, accountSubtypes} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import {canWriteLeverageFunctions, canEditAccountGeneral, canEditAccountIsMobile,
  canToggleAffiliateAutomaticCommissions} from '@bdswiss/common-permissions'
import styles from './client.module.scss'
import {isIntroducingBrokerAccount} from '../utils/general'
import ConfirmationModal from '../components/ConfirmationModal'

const allowedCategories = ['forexMt4', 'forexMt5', 'mam']
const forexCategories = ['forexMt4', 'forexMt5']

export default provideProps()(class EditAccount extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setDefaultState(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setDefaultState(nextProps)
  }

  setDefaultState(props) {
    const {account: {hidden, leverage, isReadOnly, approved, parentIbId, ibId, swapFree,
      automaticCommissions, mobile, readOnlyNote, subscription, performanceFee, accountName}} = props
    this.setState({hidden, leverage, readOnly: isReadOnly, approved, parentIbId, ibId, swapFree, mobile,
      automaticCommissions, touched: false, readOnlyNote: readOnlyNote || '', plan: get(subscription, 'plan'),
      performanceFee, accountName,
    })
  }

  handleChange(key, value) {
    this.setState({[key]: value, touched: true})
  }

  swapFreeChanged(e) {
    this.setState({swapFree: e.value === yesNo.yes.value, touched: true})
  }

  toggleAccountCommissions(e) {
    const {actions, account} = this.props
    const {automaticCommissions} = this.state

    return actions.client.toggleAffiliateAutomaticCommissions(account.id, automaticCommissions)
      .then(() => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
      })
      .then(this.props.onClose)
  }

  saveChanges(e) {
    const {actions, account, viewer: {roles}} = this.props
    const {readOnly, hidden, leverage, approved, swapFree, mobile, readOnlyNote, parentIbId, ibId, performanceFee, accountName,
    } = this.state
    const accountProps = {
      accountId: account.id,
    }

    const isAffiliateAccount = accountTypes[account.__typename].category === 'affiliate'

    if (isAffiliateAccount) {
      return this.toggleAccountCommissions(e)
    }

    if (hidden !== account.hidden) {
      accountProps.hidden = hidden
    }

    if (leverage !== account.leverage) {
      accountProps.leverage = leverage
    }

    if (readOnly !== account.isReadOnly) {
      accountProps.readOnly = readOnly
    }

    if (readOnlyNote !== account.readOnlyNote) {
      accountProps.readOnlyNote = readOnlyNote
    }

    if ((readOnly !== account.isReadOnly) || (readOnlyNote !== account.readOnlyNote)) {
      accountProps.readOnlyNote = (readOnlyNote !== account.readOnlyNote) ? accountProps.readOnlyNote : account.readOnlyNote
      accountProps.readOnlyDealer = (accountProps.readOnly === false) ? accountProps.readOnlyDealer = false : includes(roles, 'dealing')
    }

    if (approved !== account.approved) {
      accountProps.approved = approved
    }

    if (swapFree !== account.swapFree) {
      accountProps.swapFree = swapFree
    }

    if (mobile !== account.mobile) {
      accountProps.mobile = mobile
    }

    if (ibId !== account.ibId) {
      accountProps.ibId = replace(ibId, ' ', '')
    }

    if (parentIbId !== account.parentIbId) {
      accountProps.parentIbId = parentIbId
    }

    if (performanceFee !== account.performanceFee) {
      accountProps.performanceFee = performanceFee
    }

    if (accountName !== account.accountName) {
      accountProps.accountName = accountName
    }

    return actions.client.updateAccount(accountProps)
      .then(() => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
      })
      .then(this.props.onClose)
  }

  changeSubscription() {
    const {account} = this.props
    const {planChanged, forceChange} = this.state
    this.props.actions.client.changeRawSubscription(account.id, planChanged, forceChange).then((succ) => {
      this.context.clientProvider.subProviders.accounts.fetch()
      this.context.clientProvider.subProviders.activityLogs.fetch()
      this.setState({showSubscriptionModal: false, planChanged: false})
      this.context.showNotification({
        title: 'Change Subscription Plan',
        message: `Plan changed subscription to ${rawSubscriptionPlans[planChanged].label}`,
        position: 'tr',
        level: 'success',
      })
    })
      .catch((err) => {
        this.setState({showSubscriptionModal: false, planChanged: false})
        this.context.showNotification({
          title: 'Change Subscription Plan',
          message: err.message,
          position: 'tr',
          level: 'error',
        })
      })
      .then(this.props.onClose)
  }

  cancelSubscription() {
    const {account} = this.props
    this.props.actions.client.cancelRawSubscription(account.id).then((succ) => {
      this.context.clientProvider.subProviders.accounts.fetch()
      this.context.clientProvider.subProviders.activityLogs.fetch()
      this.setState({showSubscriptionModal: false})
    })
      .catch((err) => {
        this.setState({showSubscriptionModal: false})
        this.context.showNotification({
          title: 'Cancel Subscription',
          message: err.message,
          position: 'tr',
          level: 'error',
        })
      })
      .then(this.props.onClose)
  }

  reactivateSubscription() {
    const {account} = this.props
    this.props.actions.client.reactivateRawSubscription(account.id).then((succ) => {
      this.context.clientProvider.subProviders.accounts.fetch()
      this.context.clientProvider.subProviders.activityLogs.fetch()
      this.setState({showSubscriptionModal: false})
    })
      .catch((err) => {
        this.setState({showSubscriptionModal: false})
        this.context.showNotification({
          title: 'Reactivate Subscription',
          message: err.message,
          position: 'tr',
          level: 'error',
        })
      })
      .then(this.props.onClose)
  }

  render() {
    const {account, viewer, client: {clientType, allowCopyTrading, linkedPartnerClientId}} = this.props
    const yesNoOptions = StylishSelect.enumToStylishOptions(yesNo)
    const accountType = accountTypes[account.__typename]
    const {category} = accountType
    const {hidden, readOnly, leverage, approved, swapFree, mobile, automaticCommissions,
      parentIbId, ibId, touched, readOnlyNote, plan, showSubscriptionModal, planChanged, performanceFee,
      accountName} = this.state
    const availableLeverages = account.allowedLeverages
    const isIbAccount = accountTypes[account.__typename].subCategory === 'ib'
    const subscriptionPlan = get(account, 'subscription')
    const copyTradingClientTypes = [
      clientTypes.ib.key,
      clientTypes.ibCorporate.key,
      clientTypes.affiliate.key,
      clientTypes.affiliateCorporate.key,
    ]
    const showPerformanceFee =  !accountType.isDemo && category === 'forexMt4' &&
      !['ib', 'bdx'].includes(accountType.subCategory) && account.accountSubtype === accountSubtypes.basic.key &&
      ((allowCopyTrading && copyTradingClientTypes.includes(clientType)) || !isNil(linkedPartnerClientId))
    const availablePerformanceFees = [0, 5, 10, 15, 20, 25, 30, 35, 40 ,45, 50]
    return (
      <Card
        id="t-client-edit-account-sidebar"
        className={classnames(['panel-short', styles.fixedSidePanel])}
      >
        <Card.Header><strong>Edit Account {account.id}</strong></Card.Header>
        <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
          <Row>
            {canToggleAffiliateAutomaticCommissions(viewer) &&
              category === 'affiliate' &&
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-affiliate-automatic-commissions"
                  label="Affiliate Automated Commissions"
                  value={automaticCommissions ? yesNo.yes.value : yesNo.no.value}
                  onChange={(e) => this.handleChange('automaticCommissions', e.value === yesNo.yes.value)}
                  placeholderText="Select"
                  options={yesNoOptions}
                />
              </Col>
            }
          </Row>
          <Row>
            {canEditAccountGeneral(viewer) && category !== 'affiliate' &&
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-account-hidden"
                  label="Hidden Account"
                  value={hidden ? yesNo.yes.value : yesNo.no.value}
                  onChange={(e) => this.handleChange('hidden', e.value === yesNo.yes.value)}
                  placeholderText="Select"
                  options={yesNoOptions}
                />
              </Col>
            }
          </Row>
          {canEditAccountGeneral(viewer) && allowedCategories.includes(category) &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-readonly"
                  label="Read Only"
                  value={readOnly ? yesNo.yes.value : yesNo.no.value}
                  onChange={(e) => this.handleChange('readOnly', e.value === yesNo.yes.value)}
                  placeholderText="Change ReadOnly"
                  options={yesNoOptions}
                />
              </Col>
            </Row>
          }
          {readOnly && <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Read Only Note</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  defaultValue={readOnlyNote}
                  onChange={(e) => this.handleChange('readOnlyNote', e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          }
          {isIbAccount &&
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>IB ID</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={ibId}
                    onChange={(e) => this.handleChange('ibId', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
          {isIbAccount &&
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Parent IB</Form.Label>
                  <Form.Control
                    type="text"
                    label="Parent IB"
                    defaultValue={parentIbId}
                    onChange={(e) => this.handleChange('parentIbId', e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
          <Row>
            {canWriteLeverageFunctions(viewer) &&
              allowedCategories.includes(category) &&
              !accountType.isDemo &&
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-account-edit-leverage_selector"
                  label="Edit Leverage Selector"
                  value={leverage}
                  onChange={(e) => this.handleChange('leverage', e.value)}
                  placeholderText="Select"
                  options={Object.values(availableLeverages)
                    .map((i) => ({
                      label: `1:${i}`,
                      value: i,
                    })
                    )}
                />
              </Col>
            }
          </Row>
          {canEditAccountIsMobile(viewer) && category === 'forexMt4' &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-is-mobile"
                  label="Mobile Account"
                  value={mobile ? yesNo.yes.value : yesNo.no.value}
                  onChange={(e) => this.handleChange('mobile', e.value === yesNo.yes.value)}
                  placeholderText="Set Mobile Account"
                  options={yesNoOptions}
                />
              </Col>
            </Row>
          }
          {canEditAccountGeneral(viewer) && category === 'forexMt4' && isIntroducingBrokerAccount(account) &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-ib-is-approved"
                  label="Approved"
                  value={approved ? yesNo.yes.value : yesNo.no.value}
                  onChange={(e) => this.handleChange('approved', e.value === yesNo.yes.value)}
                  placeholderText="Change approved status"
                  options={yesNoOptions}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Account Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={accountName}
                  onChange={(e) => this.handleChange('accountName', e.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          {showPerformanceFee &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-account-edit-performance-fee"
                  label="Performance Fee (%)"
                  value={performanceFee}
                  onChange={(e) => this.handleChange('performanceFee', e.value)}
                  placeholderText="Select Performance Fee"
                  options={availablePerformanceFees.map((i) => ({label: `${i}%`, value: i}))}
                  disabled={account.provideCopyTrading}
                />
              </Col>
            </Row>
          }
          <Row>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-accounts-edit-account-cancel-button"
                  tabIndex={-1}
                  onClick={this.props.onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >Cancel
                </Button>
                <Button
                  id="t-client-accounts-edit-account-create-button"
                  variant="success"
                  size="sm"
                  onClick={() => this.saveChanges()}
                  disabled={!touched}
                >
                  Save
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
          {subscriptionPlan && !get(account, 'subscription.terminatedAt') && <Row className={styles.rawRow}>
            <p className={styles.rawTitle}>Raw Subscription</p>
            {get(subscriptionPlan, 'cancelledAt') || !get(subscriptionPlan, 'isActive')
              ? <p>Current Plan: {rawSubscriptionPlans[account.subscription.plan].label} (subscription cancelled)</p>
              : <Col xs={12} className={styles.rawSelect}>
                <StylishSelect.Input
                  id="t-client-accounts-edit-subscription"
                  label="Change Subscription"
                  value={planChanged || plan}
                  onChange={(e) => this.setState({planChanged: e.value})}
                  placeholderText="Change account subscription"
                  options={map(rawSubscriptionPlans, (p) => ({
                    label: p.label,
                    value: p.key,
                  })
                  )}
                />
                <Form.Check
                  id="force"
                  type="checkbox"
                  label="Force Change"
                  onChange={(e) => this.setState({forceChange: e.target.checked})}
                />
              </Col>}
            {get(account, 'subscription.pendingPlan') &&  <Row><Col xs={12}>
              <p>Pending Plan: {rawSubscriptionPlans[account.subscription.pendingPlan].label}</p>
            </Col></Row>}
            <Col xs={12} className={styles.rawSelect}>
              <ButtonToolbar className={'float-right'}>
                <Button
                  id="t-client-accounts-edit-subscription-cancelReactivate-button"
                  size="sm"
                  className="mr-1"
                  variant={get(subscriptionPlan, 'cancelledAt') || !get(subscriptionPlan, 'isActive') ? 'success' : 'danger'}
                  onClick={() => this.setState({showSubscriptionModal: true})}
                >{get(subscriptionPlan, 'cancelledAt') || !get(subscriptionPlan, 'isActive') ? 'Re-activate Subscription' : 'Cancel Subscription'}
                </Button>
                {!get(subscriptionPlan, 'cancelledAt') && get(subscriptionPlan, 'isActive') && <Button
                  id="t-client-accounts-edit-account-create-button"
                  variant="success"
                  onClick={() => this.changeSubscription()}
                  disabled={!planChanged}
                >Save</Button>}
              </ButtonToolbar>
            </Col>
          </Row>
          }
          <ConfirmationModal
            show={showSubscriptionModal}
            body="Are you sure you want to proceed?"
            confirmLabel="Proceed"
            onConfirm={() =>
              get(subscriptionPlan, 'cancelledAt') || !get(subscriptionPlan, 'isActive')
                ?  this.reactivateSubscription()
                : this.cancelSubscription()
            }
            onCancel={() => this.setState({showSubscriptionModal: false, planChanged: ''})}
          />
        </Card.Body>
      </Card>
    )
  }
})
