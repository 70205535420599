import moment from 'moment'
import {find, get} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {yesNo} from '@bdswiss/common-enums'

function initNotifcations(notifications) {
  for (const notification of notifications) {
    notification.createdAt = moment(notification.createdAt)
    notification.createdAt.isValid() // set _isValid property now
  }
  return notifications
}

export const notificationsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {notificationsSearch, companyFilter, notificationTypeFilter,
      dateFilters, clientsSearch, countryFilter, topVipFilter} = props
    const cpart1 = companyFilter ? `company: ${companyFilter}` : ''
    const cpart2 = notificationTypeFilter ? `types: [${notificationTypeFilter}]` : ''
    const cpart4 = countryFilter ? `country: ${countryFilter}` : ''
    const cpart5 = topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : ''
    const condition = [cpart1, cpart2, cpart4, cpart5,
      clientsSearch ? `search: ${JSON.stringify(clientsSearch)}` : '',
      dateFilters && dateFilters.createdFrom ? `creationTimeFrom: "${dateFilters.createdFrom}"` : '',
    ].join('\n').trim()

    const query = `{
      activityLogs(
        isNotification: true,
        orderBy: id,
        orderDirection: descending,
        ${condition.length ? condition : ''}
        ${notificationsSearch ? `search: "${notificationsSearch}"` : ''}
        limit: ${getPageSize()}
        offset: ${getOffset(props.notificationsPage)}
      ) {
        __typename
        ... on BaseLogInterface {
          id
          type
          createdAt
          client {
            id
            email
            firstName
            lastName
            registration
            convertedAt
            topVip
          }
        }
        ... on ComplianceLog {
          otherUsersCount
          otherUsers
        }
      }
      activityLogsCount(
        isNotification: true,
        ${condition ? `${condition}` : ''}
      )
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Notifications loaded',
    (state, res) => ({
      ...state,
      notifications: res.activityLogs != null
        ? initNotifcations(res.activityLogs)
        : state.notifications || [],
      notificationsCount: res.activityLogsCount != null
        ? res.activityLogsCount
        : state.notificationsCount || 0,
    }),
    [res]
  ),

}
