import moment from 'moment'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

function initPerformanceFees(performanceFees) {
  for (const fee of performanceFees) {
    fee.depositDate = moment(fee.depositDate)
    fee.depositDate.isValid()
  }
  return performanceFees
}

export const performanceFeesProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {performanceFeesSearch, dateFilters, amountFilters} = props

    const condition = [
      dateFilters && dateFilters.createdFrom ?
        `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : '',
      dateFilters && dateFilters.createdTo ?
        `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : '',
      amountFilters && amountFilters.amountFrom ? `amountFrom: ${amountFilters.amountFrom}` : '',
      amountFilters && amountFilters.amountTo ? `amountTo: ${amountFilters.amountTo}` : '',
      performanceFeesSearch ? `search: "${performanceFeesSearch}"` : '',
    ].join('\n').trim()

    return `{
      performanceFees(
        ${condition.length ? condition : ''}
        limit: ${getPageSize()}
        offset: ${getOffset(props.performanceFeesPage)}
      ) {
        depositId
        providerName
        providerId
        depositAmount
        depositCurrency
        depositDate
        depositStatus
        followerName
        followerId
        withdrawalId
        withdrawalAmount
        withdrawalCurrency
        }
        performanceFeesCount${condition ? `(${condition})` : ''}
      }`
  },

  onData: (res, dispatch) => dispatch(
    'Deposits loaded',
    (state, res) => ({
      ...state,
      performanceFees: res.performanceFees != null
        ? initPerformanceFees(res.performanceFees)
        : state.performanceFees || [],
      performanceFeesCount: res.performanceFeesCount != null
        ? res.performanceFeesCount
        : state.performanceFeesCount || 0,
    }),
    [res]
  ),

}
