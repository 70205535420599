import React from 'react'
import PropTypes from 'prop-types'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import PureComponent from '../PureComponent'

export default class ShowHideFilterButton extends PureComponent {

  static propTypes = {
    isHiding: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    id: PropTypes.string,
    tooltip: PropTypes.string,
    size: PropTypes.string,
    labelShow: PropTypes.string,
    labelHide: PropTypes.string,
    disabled: PropTypes.bool,
  }

  render() {
    const {tooltip, id, size, isHiding, onClick, disabled, labelShow, labelHide} = this.props
    const tooltipOverlay = (<Tooltip id="t-show-hide-rejected-client-documents-button-tooltip">
      {tooltip}
    </Tooltip>)
    return (
      <OverlayTrigger placement="bottom" overlay={tooltipOverlay}>
        <Button
          id={id}
          size={size}
          active={isHiding}
          onClick={onClick}
          disabled={disabled}
          variant="outline-secondary"
        >
          {!isHiding ? labelShow : labelHide}
        </Button>
      </OverlayTrigger>
    )
  }
}
