import {cloneDeep, isEmpty, isEqual} from 'lodash'
import {getIn, setIn} from './stateUtils'


/*
 * True, if `o` is not considered as a primitive value.
 */
function isObj(o) {
  if (o == null) {
    return false
  }
  // It's best to consider arrays as primitive values;
  // inserting / removing from arrays is pain.
  if (o instanceof Array) {
    return false
  }
  if (o instanceof Date) {
    return false
  }
  if (typeof o === 'object') {
    return true
  }
  return false
}

/*
 * Sets target[key] to be the same as source[key] (including unset).
 */
function setOrDelete(target, source, key) {
  if (key in source) {
    target[key] = source[key]
  } else {
    delete target[key]
  }
}

/*
 * Checks if objA[key] deeply equals objB[key].
 * Handles non-presence of key correctly.
 */
function equal(objA, objB, key) {
  if (!(key in objA) && !(key in objB)) {
    return true
  }
  if ((key in objA) && (key in objB)) {
    return isEqual(objA[key], objB[key])
  }
  return false
}

/*
 * Applies diff `newComputed - oldComputed` to `state`.
 * Returns object with new `state`, `dirty` changes
 * and `forced` data (overriden dirty changes).
 */
export function merge(oldComputed, newComputed, state) {

  const now = new Date()
  /*
   * Recursively called helper function.
   * Supposes that `oldComputed`, `newComputed` and `state` are objects.
   * Modifies `state`, `forced` and `dirty`.
   */
  function _merge(oldComputed, newComputed, state, forced, dirty) {

    const allKeys = new Set(
      Object.keys(oldComputed)
        .concat(Object.keys(newComputed))
        .concat(Object.keys(state)))

    for (const key of allKeys) {
      if (isObj(oldComputed[key]) && isObj(newComputed[key]) && isObj(state[key])) {
        // resolve objects recursively
        const subdirty = {}
        const subforced = {}
        _merge(oldComputed[key], newComputed[key], state[key], subforced, subdirty)
        if (!isEmpty(subdirty)) {
          dirty[key] = subdirty
        }
        if (!isEmpty(subforced)) {
          forced[key] = subforced
          forced[key]._time = now
        }
      } else {
        // resolve possible conflict
        if (equal(oldComputed, newComputed, key)) {
          // no computed change, keep possible dirty value
          if (!equal(newComputed, state, key) && key in state) {
            dirty[key] = state[key]
          }
        } else if (equal(newComputed, state, key)) {
          // changes match, keep result
        } else {
          // changes conflict, use the computed one
          setOrDelete(state, newComputed, key)

          forced[key] = {_time: now}
        }
      }
    }
  }


  state = {_: cloneDeep(state)}
  const forced = {}
  const dirty = {}

  // wraping state into object, so _merge is called with valid paramaters
  _merge({_: oldComputed}, {_: newComputed}, state, forced, dirty)
  return {state: state._ || null, forced: forced._ || {}, dirty: dirty._ || null}
}

/*
 * Synchronizes props dependent part of subState.
 *
 * Translator will extract the desired uiSubState from the props by a given function `translate`
 * Then it will dispatch the diff `translate(newProps) - translate(oldProps)` using the provided
 * `dispatch` function.
 *
 * Example:
 * When mounting the FruitBasket component, the uiSubState of it was computed such as:
 * {apples: 1, oranges: 1}
 * Then, the user changed the number of fruits:
 * {apples: 2, oranges: 2}
 * But then, the new data was dispatched to the appstate, and the component received new props
 * according to which, the uiSubState should be such as:
 * {apples: 3, oranges: 1}
 *
 * Translator.sync(newProps) dispatches the new state:
 * {apples: 3, oranges: 2}
 *
 * - apples is set to 3, overwriting user's (pending) modification
 * - oranges is kept to 2, (there is no need to overwrite these, as there was 1 orange in both old
 *   and new props)
 *
 * Moreover, path ['apples'] is considered forced and path ['oranges'] is
 * considered dirty in this moment. Translator mounts all the data
 * on specified subpaths (see constructor).
 */
export class Translator {

  /*
   * name: Name used in `dispatch`
   * translate: (props) => uiSubState
   * dispatch: (msg, fn, data) => null
   * paths: `{state: [...mountpath], dirty: [...mountpath], forced: [...mountpath]}`
   */
  constructor(name, translate, dispatch, paths = {}) {
    this.name = name
    this.translate = translate
    this.dispatch = dispatch
    this.paths = paths
    this.paths.state = this.paths.state || []
    this.oldComputed = null
    this.oldSubState = null
    this.forced = {}
  }

  /*
   * Generates new uiSubState and dispatches eventual changes.
   */
  sync(props, state) {
    const newComputed = this.translate(props)
    if (
      this.oldSubState
      && isEqual(getIn(state, this.paths.state, {any: null}), this.oldSubState)
      && isEqual(newComputed, this.oldComputed)
    ) {
      return
    }
    this.dispatch(
      `Translating ${this.name}`,
      (state) => {

        const subState = getIn(state, this.paths.state, {any: null})
        const {state: newSubState, forced, dirty} = merge(this.oldComputed, newComputed, subState)

        state = setIn(state, this.paths.state, newSubState)

        this.forced = {...this.forced, ...forced}
        if (this.paths.forced) {
          state = setIn(state, this.paths.forced, this.forced)
        }

        if (this.paths.dirty) {
          state = setIn(state, this.paths.dirty, dirty)
        }

        this.oldComputed = newComputed
        this.oldSubState = newSubState
        return state
      },
    )
  }
}
