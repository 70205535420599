import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {isEmpty, map, split} from 'lodash'
import {countries} from '@bdswiss/common-enums'
import {Col, Form, Row, Card, Button, ButtonToolbar} from 'react-bootstrap'
import {setIn} from '../stateUtils'
import {readableDate} from '../useful'
import {provideProps} from '../decorators'
import style from './bookallocations.module.scss'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'

class BookAllocationForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    bookAllocationProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const state = {
      form: this.props.data ? this.props.data : {},
      formError: {},
      formState: {},
    }
    this.setState(state)
  }

  formValueChanged(name, value) {
    let {state} = this
    if (name === 'type') {
      state = setIn(state, ['form', 'value'], '')
    }
    state = setIn(state, ['form', name], value)
    state = setIn(state, ['formError', name], null)
    this.setState(state)
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  validateForm() {
    const errors = {}
    const {form} = this.state

    if (!form.bookCategory) {
      errors.bookCategory = true
    }

    if ((!form.ibs && !form.countries && !form.campaigns)) {
      errors.checkBookAllocation = true
    }
    this.setFormErrors(errors)
    return isEmpty(errors)
  }

  formAction = () => {
    const {form} = this.state
    form.ibs = isEmpty(form.ibs) ? [] : split(form.ibs, ',')
    form.campaigns = isEmpty(form.campaigns) ? [] : split(form.campaigns, ',')
    return this.props.actions.bookAllocations.upsertBookAllocation(form)
  }

  close = () => {
    this.context.router.push('/book-allocation')
  }

  renderButton = () => {
    const {state, state: {form: {id}, formState: {isLoading}}, context: {bookAllocationProvider}} = this

    return (
      <Row>
        <Col xs={12}>
          <ButtonToolbar className="float-right">
            <Button
              id="t-book-allocation-editor-cancel-button"
              variant="outline-secondary"
              onClick={this.close}
              className="mr-3"
            >Cancel</Button>
            <Button
              id="t-book-allocation-editor-create-update-button"
              variant="success"
              disabled={isLoading}
              onClick={() => {
                const ok = this.validateForm()
                if (ok && !isLoading) {
                  this.setState(setIn(state, ['formState', 'isLoading'], true))
                  this.formAction()
                    .then((res) => {
                      this.context.router.push('/book-allocation')
                      bookAllocationProvider && bookAllocationProvider.fetch()
                    })
                    .catch((e) => {
                      this.setState(setIn(state, ['formState', 'isLoading'], false))
                      this.context.logError(e)
                    })
                }
              }}
            >
              {id ? 'Update' : 'Create'}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    )
  }

  render() {
    const {form: {createdAt, bookCategory, countries: selectedCountries, ibs, campaigns}, formError} = this.state
    const created = moment(createdAt)
    return (
      <Card className={style.form}>
        <Card.Body>
          <Row className={style.fields}>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Book Category *</Form.Label>
                <Form.Control
                  id="t-book-allocation-form-bookCategory"
                  type="input"
                  value={bookCategory}
                  isInvalid={formError.bookCategory}
                  onChange={(evt) => this.formValueChanged('bookCategory', evt.target.value)}
                  placeholder="Enter value"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Date Created</Form.Label>
                <Form.Control
                  id="t-book-allocation-form-createdAt"
                  type="input"
                  label="Date Created"
                  value={readableDate(created)}
                  isInvalid={formError.createdAt}
                  onChange={(evt) => this.formValueChanged('createdAt', evt.target.createdAt)}
                  disabled
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Ibs (numbers comma seperated)</Form.Label>
                <Form.Control
                  id="t-book-allocation-form-ibs"
                  as="textarea"
                  rows={3}
                  label=""
                  value={ibs}
                  isInvalid={formError.checkBookAllocation}
                  onChange={(evt) => this.formValueChanged('ibs', evt.target.value)}
                  placeholder="Enter ibs (numbers comma seperated)"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <StylishSelect.Input
                id="t-book-allocation-form-countries"
                label="Countries"
                placeholderText="All Countries"
                value={selectedCountries}
                options={StylishSelect.enumToStylishOptions(countries)}
                isInvalid={formError.checkBookAllocation}
                multi
                clearable
                onChange={(evt)=> this.formValueChanged('countries', map(evt, 'value'))}
              />
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Campaigns (numbers comma seperated)</Form.Label>
                <Form.Control
                  id="t-book-allocation-form-book-campaigns"
                  type="input"
                  label=""
                  value={campaigns}
                  isInvalid={formError.checkBookAllocation}
                  onChange={(evt) => this.formValueChanged('campaigns', evt.target.value)}
                  placeholder="Enter Campaigns (numbers comma seperated)"
                />
              </Form.Group>
            </Col>
          </Row>
          {this.renderButton()}
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(BookAllocationForm)
