/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {reject, includes} from 'lodash'
import {Row, Col, Form} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'

class PartnersCustomizationRowMinDeposit extends PureComponent {
  render() {
    const {minDepositCustomizationKey, amount, selectedMinDepositAccTypes,
      removeMinDepositCustomizationRow, accountTypes, accountSubType} = this.props

    const accountOpenTypes = reject(accountTypes, (accType) =>
      accType.key !== accountSubType && includes(selectedMinDepositAccTypes, accType.key))

    return (
      <div key={minDepositCustomizationKey}>
        <Row>
          <Col xs={6}>
            <StylishSelect.Input
              label="Account Types Allowed"
              value={accountSubType}
              options={StylishSelect.enumToStylishOptions({...accountOpenTypes})}
              onChange={(evt) => this.props.onChange('accountSubType', evt)}
            />
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Minimum Deposit</Form.Label>
              <Form.Control
                type="number"
                value={amount || ''}
                maxLength="255"
                onChange={(evt) => {
                  if (evt.target.value >= 0) {
                    this.props.onChange('amount', evt.target.value)
                  }
                }}
                placeholder="Amount"
              />
            </Form.Group>
          </Col>
          <Col xs={12}>
            <a
              id="t-partners-customization-form-account-remove"
              className="float-right clickable"
              onClick={() => removeMinDepositCustomizationRow(minDepositCustomizationKey)}
            > Remove Minimum Deposit
            </a>
          </Col>
        </Row>
      </div>
    )
  }
}

export default provideProps()(PartnersCustomizationRowMinDeposit)
