import React from 'react'
import PropTypes from 'prop-types'
import {Row, Card, Col, Badge, Button} from 'react-bootstrap'
import classnames from 'classnames'
import {communicationChannels, userDepartments} from '@bdswiss/common-enums'
import {isNil, unescape, includes, get, map} from 'lodash'
import {provideProps} from '../decorators'
import 'react-datetime/css/react-datetime.css'
import PureComponent from '../PureComponent'
import style from './communications/Communications.module.scss'
import CommunicationLink from './communications/CommunicationLink'
import {safeParseJSON} from '../common/utils'
import {readableDate} from '../useful'
import StylishSelect from '../components/StylishSelect'

class Emails extends PureComponent {

  state = {}

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
  }
  static propTypes = {
    emails: PropTypes.array.isRequired,
    emailsCount: PropTypes.number.isRequired,
    filterDispatch: PropTypes.func.isRequired,
  }

  componentWillReceiveProps (nextProps) {
    const {isActive, filterState: {userDepartmentFilter}} = nextProps
    if (isActive &&
      (
        this.props.emails.length === 0 ||
        this.props.filterState.userDepartmentFilter !== userDepartmentFilter ||
        this.props.filterState.fetchingMoreEmails
      )
    )  {
      this.fetch()
    }
  }

  fetch = () => {
    const {dataLoading, dataError} = this.state
    if (!(dataLoading || dataError)) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.emails.fetch()
          .then(() => this.setState({dataLoading: false}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  render() {
    const {emails, emailsCount, userDepartmentFilter, emailsPage} = this.props
    const {dataLoading, dataError} = this.state
    if (dataError) return <pre> Error Loading Emails : {dataError.message} </pre>

    return (
      <Row>
        <Col xs={12} className="emails">
          {emails.length === 0 && <span>No Emails</span>}
          <Card className={classnames(['panel-events'])}>
            <Card.Body>
              <span style={{fontSize: 12}}>*Should you see emails not related to this profile, please check for another trading account under a different entity using the same email address</span>
              <hr />

              <Row>
                <Col xs={12}>
                  <StylishSelect
                    id="t-emails-departments-filter"
                    placeholderText="Any Department"
                    value={userDepartmentFilter}
                    options={map(userDepartments, o => ({label: o.label, value: o.value}))}
                    highlightIfActive
                    onChange={(e) =>
                      this.props.filterDispatch(
                        'Filter emails by user department',
                        (state) => ({...state, userDepartmentFilter: map(e, o => o.value), fetchingMoreEmails: false, emailsPage: 1})
                      )
                    }
                    multi
                    clearable
                  />
                </Col>
              </Row>

              {emails.map((communication, i) => {
                const {channels, createdAt, title, summary, meta} = communication
                const {labelIds} = safeParseJSON(meta)
                const isTrashed = includes(labelIds, 'TRASH')
                return (
                  <Row key={`comm-${i}`} className={style.row}>
                    <Col xs={12}>
                      <CommunicationLink communication={communication}>
                        <div>
                          {channels.map((channel, i) => <Badge
                            className={style.channels}
                            variant="success"
                            pill
                            key={`channel-${i}`}
                          >{communicationChannels[channel].label}</Badge>
                          )}
                          <small title={createdAt.fromNow()} className={style.createdAt}>{readableDate(createdAt)}</small>
                        </div>
                        <div className={style.title}>{unescape(title)}{isTrashed && <small> - <span className={style.deleted}>DELETED</span></small>}</div>
                        <div className={style.summary}>{unescape(summary)}</div>
                      </CommunicationLink>
                    </Col>
                  </Row>
                )
              })}
              {emails.length !== emailsCount && <Row>
                <Col xs={12} style={{textAlign: 'center', padding: '10px'}}>
                  <Button onClick={() => {
                    const page = emailsPage || 0
                    this.props.filterDispatch(
                      'Fetch more emails',
                      (state) => ({...state, fetchingMoreEmails: true, emailsPage: page + 1})
                    )
                  }}>Fetch more</Button>
                </Col>
              </Row>}
              {(isNil(emails) || dataLoading) && <Row>
                <Col xs={12} className="text-center">Loading emails...</Col>
              </Row>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    )
  }
}

export default provideProps((state, _, filterState, props) => {
  const {client} = state
  const clientId = Number(get(props, 'match.params.clientId'))
  return ({
    emails: get(client[clientId], 'emails', []),
    emailsCount: get(client[clientId], 'emailsCount', 0),
    userDepartmentFilter: filterState.userDepartmentFilter,
    fetchingMoreEmails: filterState.fetchingMoreEmails,
    emailsPage: filterState.emailsPage,
  })
})(Emails)
