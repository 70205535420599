import React from 'react'
import {Link} from 'react-router-dom'
import {LinkContainer} from 'react-router-bootstrap'
import PropTypes from 'prop-types'
import {
  Breadcrumb,
  BreadcrumbItem, Button,
  Card,
  Col,
  Container,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {getPageCount, getPageRange} from '../useful'
import {
  compose,
  mountDataProviders,
  predispatch,
  provideProps,
  uiMount,
} from '../decorators'
import style from './competitions.module.scss'
import {leaderboardProvider} from './providers'

class CompetitionsLeaderboard extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      exporting: false,
    }
  }

  renderCompetitor = (competitor) => (<tr className="t-competitor" key={competitor.login}>
    <td>
      {competitor.rank}
    </td>
    <td>
      <Link to={`/clients/${competitor.clientId}`} >
        {competitor.clientId}
      </Link>
    </td>
    <td>
      {competitor.fullName}
    </td>
    <td>
      {competitor.tradesCount}
    </td>
    <td>
      {competitor.balance || 0}
    </td>
    <td>
      {competitor.roi || 0}
    </td>
  </tr>)

  renderTotalInfo(name, value) {
    return <p><span>{name}:</span> {value}</p>
  }

  handleSelectPage(page) {
    this.props.uiDispatch(
      'Show page',
      (state) => ({...state, page})
    )
  }

  exportCompetitionData() {
    const {match: {params: {competitionId}}} = this.props
    this.setState((state) => ({...state, exporting: true}))
    this.props.actions.competition.exportData(competitionId).then((res) => {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = res.exportCompetitionData
      document.body.appendChild(a)
      a.click()
      this.setState((state) => ({...state, exporting: false}))
    }).catch((e) => {
      this.setState((state) => ({...state, exporting: false}))
      e.isShowActualError = true
      this.context.logError(e)
    })
  }

  render() {
    const {exporting} = this.state
    const {
      leaderboard,
      totalInfo: {
        participants,
        totalROI,
        totalAmountOfTrades
      },
      match: {params: {competitionId}},
      page,
      pageSize,
    } = this.props

    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/competitions"
            active={false}
          >
            <BreadcrumbItem>
              Competitions
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            Competition {competitionId} leaderboard
          </BreadcrumbItem>
        </Breadcrumb>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <div className={style.leaderboardHeader}>
                  <div className={style.leaderboardHeaderInfo}>
                    {this.renderTotalInfo('Participants', participants)}
                    {this.renderTotalInfo('Total ROI', totalROI.toFixed(2))}
                    {this.renderTotalInfo('Total amount of trades', totalAmountOfTrades)}
                  </div>
                  <div className={style.leaderboardHeaderExport}>
                    <Button
                      id={`t-competition-export-${competitionId}`}
                      variant="success"
                      size="sm"
                      onClick={() => this.exportCompetitionData(competitionId)}
                      disabled={exporting}
                    >
                      Export to CSV
                    </Button>
                  </div>
                </div>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Position</th>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Total amount of trades</th>
                      <th>Account Balance</th>
                      <th>ROI</th>
                    </tr>
                  </thead>
                  <tbody>
                    {leaderboard.map((c) => this.renderCompetitor(c))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch(
                'Show page',
                (state) => ({...state, page: selectedEvent.eventKey})
              )}
            >
              {getPageRange(page, getPageCount(participants, pageSize)).map((p) => {
                if (p === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={p}
                    onClick={() => this.handleSelectPage(page - 1)}
                  />
                }

                if (p === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={p}
                    onClick={() => this.handleSelectPage(page + 1)}
                  />
                }

                return <Pagination.Item
                  active={page === p}
                  key={p}
                  onClick={() => this.handleSelectPage(p)}
                >
                  {p}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'competitionLeaderboard']),
  predispatch((props) => props.uiDispatch(
    'Initialize ui/competitionLeaderboard',
    (state) => state)
  ),
  provideProps((state, uiState) => {
    const {leaderboard, totalInfo} = state
    const {page} = uiState
    return ({
      page: page || 1,
      leaderboard,
      totalInfo,
      pageSize: 50,
    })
  }),
  mountDataProviders({leaderboardProvider}),
)(CompetitionsLeaderboard)
