import React from 'react'
import PropTypes from 'prop-types'
import {isEmpty, get} from 'lodash'
import {phoneTypes} from '@bdswiss/common-enums'
import {Modal, Button, ButtonToolbar, Form} from 'react-bootstrap'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

export default class NewCallModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    phone: PropTypes.string,
    secondaryPhones: PropTypes.object,
  };

  changePhone(e) {
    if (e.target.value.length > 0 && !/(^\+?)\d*$/.test(e.target.value)) {
      e.preventDefault()
      return false
    }
    this.setState({phone: e.target.value})
    return true
  }

  componentWillMount() {
    const {phone, secondaryPhones} = this.props
    this.setState({
      phone,
      useFiledNumber: !isEmpty(phone),
      phoneType: phoneTypes.primary.value,
      secondaryPhone1: get(secondaryPhones, 'secondaryPhone1'),
    })
  }

  componentWillReceiveProps(newProps) {
    if (!newProps) {
      return
    }
    const {phone, secondaryPhones} = newProps
    this.setState({
      phone,
      useFiledNumber: !isEmpty(phone),
      secondaryPhone1: get(secondaryPhones, 'secondaryPhone1'),
    })
  }

  render() {
    const {show, onConfirm, onCancel} = this.props
    const {phone, useFiledNumber, secondaryPhone1} = this.state
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Start call</strong>
        </Modal.Header>
        <Modal.Body>
          <Form.Check
            type="checkbox"
            checked={useFiledNumber}
            onChange={(e) => {
              const {checked} = e.target
              const phone = checked ? this.props.phone : ''
              this.setState({useFiledNumber: checked, phone})
            }}
            label="Use Number On File"
            className="mb-2"
          />
          <Form.Control
            value={phone}
            type="text"
            onChange={(e) => this.changePhone(e)}
            disabled={useFiledNumber}
            className="mb-2"
          />
          <fieldset className={style.fieldSet}>
            <Form.Check
              type="radio"
              value={phoneTypes.primary.value}
              checked={this.state.phoneType === phoneTypes.primary.value}
              label={phoneTypes.primary.label}
              onChange={(e) => e.target.checked &&
                  this.setState({phoneType: e.target.value, phone: this.props.phone})}
              className="mb-2"
            />
            {secondaryPhone1 && <Form.Check
              type="radio"
              value={phoneTypes.secondary.value}
              checked={this.state.phoneType === phoneTypes.secondary.value}
              label={phoneTypes.secondary.label}
              onChange={(e) => e.target.checked &&
                this.setState({
                  phoneType: e.target.value,
                  phone: get(this.props, 'secondaryPhones.secondaryPhone1'),
                })}
              className="mb-2"
            />}
          </fieldset>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button onClick={onCancel} variant="outline-secondary" className="mr-3" size="sm">
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={(e) => {
              const phone = this.state.useFiledNumber ? null : this.state.phone
              return onConfirm(e, phone, this.state.phoneType)
            }}>
              <FontAwesomeIcon icon="phone" /> Call
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
