import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Table, ButtonToolbar, Button, Row, Col, Pagination, Form} from 'react-bootstrap'
import {List as Loading} from 'react-content-loader'
import Promise from 'bluebird'
import {get, reject, find, isEmpty, head, includes, map, filter, isNil, some, orderBy, capitalize, has} from 'lodash'
import DisabledTooltipButton from '../components/DisabledTooltipButton'
import {accountTypes, withdrawalStatuses, withdrawalTypes, processWithdrawalResults, withdrawalPendingReasons,
  withdrawalRejectionReasons, withdrawalPaymentVendors, transferTypes, depositVendors, paymentCardTokenStatuses, currencies,
} from '@bdswiss/common-enums'
import {canCreateWithdrawals, canApproveWithdrawals, canProcessWithdrawals,
  canAutoProcessWithdrawals,
  canQueryAffiliateManagerClients, canHoldWithdrawals, canToggleWithdrawalApprovals, canWriteGeneralWithdrawalNote} from '@bdswiss/common-permissions'
import {getFormattedAmount, readableDate, getPageCount,
  getWithdrawalPaymentMethodDetails, getDepositRemainingAmount, getPageRange} from '../useful'
import {provideProps, uiMount, compose} from '../decorators'
import ReasonModal from '../components/ReasonModal'
import AutoProcessWithdrawalModal from '../components/AutoProcessWithdrawalModal'
import {createClientWithdrawalsUrl, createClientNewWithdrawalUrl} from '../utils/links'
import {getWithdrawalStatusLabel} from '../utils/rendering'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import ConfirmationModal from './../components/ConfirmationModal'
import {getAccountLabel, getWithdrawalVendorLabel} from '../utils/general'
import RefundModal from '../components/RefundModal'
import {getRelatedDeposits, isCentAccount, safeParseJSON} from '../common/utils'
import WithdrawalReceiptModal from '../components/WithdrawalReceiptModal'
import WithdrawalsSummaryModal from '../components/WithdrawalsSummaryModal'
import StatusFilter from './../components/StatusFilter'
import TransactionTypeFilter from './../components/TransactionTypeFilter'
import RevertModal from '../components/RevertModal'
import StylishSelect from '../components/StylishSelect'

const moment = require('moment')

const filterableStatuses = {
  rejected: withdrawalStatuses.rejected,
}

const filterableTransferTypes = {
  nonTransfers: transferTypes.nonTransfers,
  transfers: transferTypes.transfers,
  [withdrawalTypes.performanceFee.key]: {
    key: withdrawalTypes.performanceFee.key,
    value: withdrawalTypes.performanceFee.value,
    label: withdrawalTypes.performanceFee.label,
  },
}

class Withdrawals extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  static propTypes = {
    client: PropTypes.object.isRequired,
    activeId: PropTypes.any,
    switchSidebar: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({})
  }

  componentDidMount() {
    if (window.ioSocket) {
      window.ioSocket.on('processWithdrawal', (data) => {
        if (data.error) {
          const e = new Error(data.error)
          e.isShowActualError = true
          this.context.logError(e)
          this.setState({processing: false})
        } else {
          this.context.clientProvider.subProviders.nonTransferDeposits.fetch()
          this.context.clientProvider.subProviders.withdrawals.fetch()
          this.context.clientProvider.subProviders.activityLogs.fetch()
          this.setState({processing: false})
          this.props.uiDispatch('Withdrawal Processed', (state) => ({
            ...state,
            refundedDeposits: [],
            skrillExtraParams: {},
          }))
          if ([withdrawalStatuses.accepted.key, withdrawalStatuses.rejected.key].includes(data.withdrawal.status)) {
            this.optimisticSetPendingCount(-1)
          }
        }
      })
    }
  }

  componentWillReceiveProps(newProps) {
    const {isActive, filterState: {withdrawalsTypeFilterState, withdrawalStatusFilter, withdrawalVendorsFilter}} = newProps
    const {filterState} = this.props
    const {dataFetched, dataLoading, dataError} = this.state
    if (isActive && !dataLoading && !dataError
       && (!dataFetched || (filterState.withdrawalsPage !== newProps.filterState.withdrawalsPage)
       || (filterState.withdrawalsTypeFilterState !== withdrawalsTypeFilterState) || (filterState.withdrawalStatusFilter !== withdrawalStatusFilter)
       || (filterState.withdrawalVendorsFilter !== withdrawalVendorsFilter))) {
      this.setState({dataLoading: true}, () => {
        Promise.all([
          this.context.clientProvider.subProviders.nonTransferDeposits.fetch(),
          this.context.clientProvider.subProviders.withdrawals.fetch(),
        ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  newWithdrawalSidebar() {
    this.context.router.push(createClientNewWithdrawalUrl(this.props.client.generalInfo.id))
  }

  showWithdrawalSidebar(withdrawal) {
    this.props.fetchActivityLogs(withdrawal.id)
    const clientId = this.props.client.generalInfo.id
    this.props.setNonTransferDeposits && this.props.setNonTransferDeposits(this.props.client.nonTransferDeposits)
    this.context.router.push(createClientWithdrawalsUrl(clientId, withdrawal.id))
  }

  processWithdrawal(withdrawal, value, reason, selectedReasonCode) {
    let meta = {}
    const isApproved = value === processWithdrawalResults.approve.key
    const isRejected = value === processWithdrawalResults.reject.key
    if (isApproved) {
      const refundedDeposits = get(this.props, 'refundedDeposits', [])
      meta = isEmpty(refundedDeposits) ? {} : {refundedDeposits}
      if (get(this.props, 'skrillExtraParams.skrillSendAsMoney')) {
        meta.skrillExtraParams = get(this.props, 'skrillExtraParams')
      }
    }
    this.setState({processing: true})
    this.props.actions.client.processWithdrawal(withdrawal, value, reason, selectedReasonCode, meta)
      .then((res) => {
        if (res.processWithdrawal) {
          this.context.clientProvider.subProviders.nonTransferDeposits.fetch()
          this.context.clientProvider.subProviders.withdrawals.fetch()
          this.context.clientProvider.subProviders.activityLogs.fetch()
          this.setState({processing: false})
          this.props.uiDispatch('Withdrawal Processed', (state) => ({
            ...state,
            refundedDeposits: [],
            skrillExtraParams: {},
          }))
          if (isApproved || isRejected) this.optimisticSetPendingCount(-1)
        }
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.setState({processing: false})
      })
  }

  optimisticSetPendingCount(delta) {
    const {client: {personalDetails: {id: clientId, pendingWithdrawalsCount}}} = this.props
    let newPendingCount = pendingWithdrawalsCount + delta
    newPendingCount = newPendingCount < 0 ? 0 : newPendingCount
    this.props.dispatch('Optimistic Update Pending Withdrawal Count', (state) => ({
      ...state,
      client: {
        ...state.client,
        [clientId]: {
          ...state.client[clientId],
          personalDetails: {
            ...state.client[clientId].personalDetails,
            pendingWithdrawalsCount: newPendingCount,
          }
        }
      }
    }))
  }

  verifySkrillAccount(withdrawal) {
    this.props.actions.client.verifySkrillAccount(withdrawal)
      .then((res) => {
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
      })
  }

  openRejectionReasonWindow(withdrawal) {
    this.props.uiDispatch('Open rejection reason', (state) => ({
      ...state,
      showRejectionReasonModal: true,
      rejectingWithdrawal: withdrawal,
    }))
  }

  closeRejectionReasonWindow() {
    this.props.uiDispatch('Close rejection reason', (state) => ({
      ...state,
      showRejectionReasonModal: false,
      rejectingWithdrawal: undefined,
    }))
  }

  openPendingReasonWindow(withdrawal) {
    this.props.uiDispatch('Open pending reason', (state) => ({
      ...state,
      showPendingReasonModal: true,
      pendingWithdrawal: withdrawal,
    }))
  }

  closePendingReasonWindow() {
    this.props.uiDispatch('Close pending reason', (state) => ({
      ...state,
      showPendingReasonModal: false,
      pendingWithdrawal: undefined,
    }))
  }

  openAutoProcessWithdrawalWindow(withdrawal) {
    this.setState({
      showWithdrawalModal: true,
      selectedWithdrawalToProcess: withdrawal,
    })
  }

  closeAutoProcessWithdrawalWindow() {
    this.setState({
      showWithdrawalModal: false,
      selectedWithdrawalToProcess: undefined,
    })
  }

  renderAcceptedWithdrawalButton(withdrawal) {
    return canProcessWithdrawals(this.props.viewer) && (
      <div className="mb-1">
        <Button
          variant="success"
          className="btn-xs"
          onClick={(e) => {
            this.processWithdrawal(withdrawal, processWithdrawalResults.process.key)
            this.props.activeId == null && e.stopPropagation()
          }}
          disabled={this.state.processing}
        >{processWithdrawalResults.process.label}</Button>
      </div>
    )
  }

  renderPendingWithdrawalButton(withdrawal) {
    return (
      <div className="mb-1">
        <Button
          variant="warning"
          className="btn-xs"
          onClick={(e) => {
            this.openPendingReasonWindow(withdrawal)
            this.props.activeId == null && e.stopPropagation()
          }}
          disabled={this.state.processing}
        >{withdrawalStatuses.pending.label}</Button>
      </div>
    )
  }

  renderAutoProcessWithdrawalButton(withdrawal) {
    return canAutoProcessWithdrawals(this.props.viewer) && (
      <div>
        <Button
          variant="success"
          className="btn-xs mb-1"
          onClick={(e) => {
            this.openAutoProcessWithdrawalWindow(withdrawal)
          }}
        >Auto Process</Button>
      </div>
    )
  }

  renderOnHoldWithdrawalButton(withdrawal) {
    const {status} = withdrawal
    return canHoldWithdrawals(this.props.viewer) &&  (status === withdrawalStatuses.notProcessed.key) &&
    <div className="mb-1">
      <Button
        variant="warning"
        className="btn-xs"
        onClick={(e) => {
          this.processWithdrawal(withdrawal, withdrawalStatuses.onHold.key)
          this.props.activeId == null && e.stopPropagation()
        }}
      >{withdrawalStatuses.onHold.label}</Button>
    </div>
  }

  renderWithdrawalReceiptButton(withdrawal) {
    return (<div>
      <Button
        variant="outline-secondary"
        className="btn-xs mb-1"
        onClick={(e) => {
          const payment = {}
          payment.receipt = withdrawal.receipt || '{}'
          payment.meta = safeParseJSON(withdrawal.meta || '{}')
          payment.track = safeParseJSON(withdrawal.trackUser || '{}')
          this.setState({receipt: {id: withdrawal.id, payment}})
          e.stopPropagation()
        }}
      >Receipt</Button>
    </div>)
  }

  renderRevertWithdrawalButton(withdrawal) {
    const acceptedStatuses = [
      withdrawalStatuses.accepted.key,
      withdrawalStatuses.processing.key,
      withdrawalStatuses.partiallyReversed.key,
    ]
    const meta = safeParseJSON(withdrawal.meta)
    const revertedAmount = get(meta, 'revertedAmount') || 0
    const amount = get(withdrawal, 'paymentFields.withdrawalNetAmount') || get(withdrawal, 'amount')
    return amount > revertedAmount && canAutoProcessWithdrawals(this.props.viewer)
      && acceptedStatuses.includes(withdrawal.status) && (
      <div>
        <Button
          variant="info"
          className="btn-xs"
          onClick={(e) => {
            this.openRevertModal(withdrawal)
            this.props.activeId == null && e.stopPropagation()
          }}
          disabled={this.state.processing}
        >
          Revert
        </Button>
      </div>
    )
  }

  openRevertModal = (withdrawal) => {
    this.props.uiDispatch('Open revert modal', (state) => ({
      ...state,
      showRevertModal: true,
      revertingWithdrawal: withdrawal,
    }))
  }

  closeRevertModal = () => {
    this.props.uiDispatch('Close revert modal', (state) => ({
      ...state,
      showRevertModal: false,
      revertingWithdrawal: undefined,
    }))
  }

  revertWithdrawal(withdrawal, amount) {
    this.setState({disableCompleteAction: true}, () => {
      this.props.actions.client.revertWithdrawal(withdrawal.id, amount)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.withdrawals.fetch(),
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableCompleteAction: false})
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableCompleteAction: false})
        })
    })
  }

  openAcceptanceWindow(withdrawal) {
    this.props.uiDispatch('Open acceptance modal', (state) => ({
      ...state,
      showAcceptanceModal: true,
      acceptingWithdrawal: withdrawal,
    }))
  }

  toggleRefundWindow(show, withdrawal, refundedDeposits, skrillExtraParams) {
    this.props.uiDispatch('Toggle Refund modal', (state) => ({
      ...state,
      showRefundModal: show,
      acceptingWithdrawal: withdrawal,
      refundedDeposits,
      skrillExtraParams,
    }))
  }

  closeAcceptanceWindow() {
    this.props.uiDispatch('Close acceptance modal', (state) => ({
      ...state,
      showAcceptanceModal: false,
      acceptingWithdrawal: undefined,
    }))
  }

  closeWDSummaryModal() {
    this.props.uiDispatch('Close wd summary modal', (state) => ({
      ...state,
      showWDSummaryModal: false,
    }))
  }

  renderPendingWithdrawalButtons(withdrawal) {
    const {client: {nonTransferDeposits: deposits, withdrawals}} = this.props
    const relatedDeposits = filter(getRelatedDeposits(withdrawal, deposits, false), (d) =>
      getDepositRemainingAmount(withdrawals, d) !== 0)
    return canApproveWithdrawals(this.props.viewer) && (
      <React.Fragment>
        <div className="mb-1">
          <Button
            id="t-client-withdrawal-accept-button"
            variant="success"
            className="btn-xs"
            onClick={(e) => {
              if (this.props.client.personalDetails.hasDisabledWithdrawals &&
                withdrawalTypes[withdrawal.withdrawalType].category !== 'transfer') {
                this.context.showNotification({
                  title: 'Withdrawal approvals are disabled for this client',
                  position: 'tr',
                  level: 'error',
                })
              } else {
                if ([withdrawalPaymentVendors.skrill.key,
                  withdrawalPaymentVendors.creditDebitCard.key,
                  withdrawalPaymentVendors.paypal.key,
                ].includes(withdrawal.vendor) &&
                  head(relatedDeposits)
                ) {
                  this.toggleRefundWindow(true, withdrawal, undefined)
                } else {
                  this.openAcceptanceWindow(withdrawal)
                }
                this.props.activeId == null && e.stopPropagation()
              }
            }}
          >{processWithdrawalResults.approve.label}</Button>
        </div>
        <div className="mb-1">
          <Button
            id="t-client-withdrawal-reject-button"
            variant="danger"
            className="btn-xs"
            onClick={(e) => {
              this.openRejectionReasonWindow(withdrawal)
              this.props.activeId == null && e.stopPropagation()
            }}
          >{processWithdrawalResults.reject.label}</Button>
        </div>
        {withdrawal.status !== withdrawalStatuses.pending.value && this.renderPendingWithdrawalButton(withdrawal)}
        {this.renderOnHoldWithdrawalButton(withdrawal)}
      </React.Fragment>
    )
  }

  activateStatusFilter(value) {
    const oldValue = this.props.filterState.withdrawalStatusFilter
    let withdrawalStatusFilter
    if (includes(oldValue, value)) {
      withdrawalStatusFilter = reject(oldValue, (v) => v === value)
    } else {
      withdrawalStatusFilter = [...oldValue, value]
    }
    this.props.filterDispatch(
      'Filter client withdrawal by status',
      (state, arg) => ({...state, withdrawalStatusFilter, withdrawalsPage: 1})
    )
  }


  convertTypesFilterToTypeFilter(withdrawalsTypeFilterState) {
    const transfers = map(filter(withdrawalTypes, (o) => o.category && o.category === 'transfer'), 'key')
    const nonTransfers = map(
      filter(withdrawalTypes, (o) =>
        (!o.category || (o.category && o.category !== 'transfer')) && o.key !== withdrawalTypes.performanceFee.key
      ),
      'key'
    )
    const performanceFee = withdrawalTypes.performanceFee.key
    return [
      ...includes(withdrawalsTypeFilterState, transferTypes.nonTransfers.key) ? nonTransfers : [],
      ...includes(withdrawalsTypeFilterState, transferTypes.transfers.key) ? transfers : [],
      ...includes(withdrawalsTypeFilterState, withdrawalTypes.performanceFee.key) ? [performanceFee] : [],
    ]
  }

  activateTypesFilter(value) {
    const oldValue = this.props.filterState.withdrawalsTypeFilterState
    let withdrawalsTypeFilterState
    if (includes(oldValue, value)) {
      withdrawalsTypeFilterState = reject(oldValue, (v) => v === value)
    } else {
      withdrawalsTypeFilterState = [...oldValue, value]
    }

    this.props.filterDispatch(
      'Filter transfer withdrawals',
      (state, arg) => ({
        ...state,
        withdrawalsTypeFilter: this.convertTypesFilterToTypeFilter(withdrawalsTypeFilterState),
        withdrawalsTypeFilterState,
        withdrawalsPage: 1
      })
    )
  }

  doFilterByVendors(selections) {
    const values = !isEmpty(selections) ? selections.map((v) => v.value) : []

    this.props.filterDispatch(
      'Filter client withdrawals by vendor',
      (state, arg) => ({...state, withdrawalVendorsFilter: values, withdrawalsPage: 1})
    )
  }

  toggleHideWDMethods(value) {
    this.props.actions.client.toggleHideWDMethods(this.props.client.generalInfo.id, value)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  toggleWithdrawalsApproval(value) {
    this.props.actions.client.toggleWithdrawalsApproval(this.props.client.generalInfo.id, value)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  saveGeneralWithdrawalNote() {
    this.props.actions.client.upsertGeneralWithdrawalNote(this.props.client.generalInfo.id, this.state.generalWithdrawalNote)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  renderWithdrawalButtons() {
    const {client, viewer, activeId} = this.props
    const hasAccount = client.withdrawalAccounts && client.withdrawalAccounts.length > 0
    const currentWithdrawal = find(client.withdrawals, {id: activeId})

    return (
      <ButtonToolbar className="float-right">
        {currentWithdrawal && currentWithdrawal.vendor === withdrawalPaymentVendors.skrill.key &&
          canApproveWithdrawals(viewer) &&
          <Button
            id="t-client-withdrawals-verify-skrill-button"
            variant="success"
            size="sm"
            className="mr-1"
            style={{fontSize: 12}}
            onClick={() => this.verifySkrillAccount(currentWithdrawal)}
          >
            Verify Skrill Account
          </Button>
        }
        <Button
          id="t-client-withdrawals-summary-button"
          variant="success"
          size="sm"
          style={{fontSize: 12}}
          onClick={(e) => {
            this.props.uiDispatch('Open wd summary modal', (state) => ({
              ...state,
              showWDSummaryModal: true,
            }))
          }}
        >
          Withdrawals Summary
        </Button>
        {canCreateWithdrawals(viewer) &&
          <DisabledTooltipButton
            id="t-client-withdrawals-create-button"
            variant="success"
            size="sm"
            className="ml-1"
            style={{fontSize: 12}}
            onClick={() => this.newWithdrawalSidebar()}
            disabled={!hasAccount}
            disabledTooltip="Client has no accounts"
          >
            New Withdrawal
          </DisabledTooltipButton>
        }
      </ButtonToolbar>
    )
  }

  render() {
    const {client: {generalInfo, withdrawalAccounts, generalInfo: {paymentCardTokens, company}, nonTransferDeposits: deposits, withdrawals, withdrawalsCount},
      activeId, showRejectionReasonModal, rejectingWithdrawal, refundedDeposits, viewer, showAcceptanceModal, acceptingWithdrawal,
      showRefundModal, showWDSummaryModal, filterState: {withdrawalsTypeFilterState, withdrawalStatusFilter, withdrawalVendorsFilter},
      showRevertModal, revertingWithdrawal, isActive, showPendingReasonModal, pendingWithdrawal, withdrawalsPage} = this.props
    const {showWithdrawalModal, selectedWithdrawalToProcess, receipt, dataLoading, dataError} = this.state
    if (isNil(withdrawals) || isNil(deposits) || dataLoading || !isActive) {
      return <Loading speed={1} style={{padding:'20px'}} />
    }
    if (dataError) return <pre> Error Loading Accounts : {dataError.message} </pre>
    const fastPayoutEligible = isEmpty(refundedDeposits) && some(paymentCardTokens, (t) =>
      t.status === paymentCardTokenStatuses.valid.key &&
      get(acceptingWithdrawal, 'vendor') === withdrawalPaymentVendors.creditDebitCard.key &&
      [depositVendors.safecharge.key, depositVendors.checkout.key, depositVendors.powercash21.key].includes(t.vendor) &&
      get(acceptingWithdrawal, 'paymentFields.cardNumber') === t.lastFour
    )

    const visibleWithdrawals = orderBy(withdrawals, ['createdAt'], ['desc'])

    const confirmationFee = acceptingWithdrawal && get(acceptingWithdrawal, 'paymentFields.withdrawalFee')
      ? [<span key="fee-amount">Withdrawal Fee: <span style={{color: 'red'}}>
        {getFormattedAmount({
          currency: acceptingWithdrawal.currency,
          amount: get(acceptingWithdrawal, 'paymentFields.withdrawalFee')
        })}
      </span></span>, <br key="fee-br-1" />, <br key="fee-br-2" />]
      : ['']
    const payoutAutoProcess = fastPayoutEligible
      ? <span key="payout-msg" style={{color: 'red'}}>This withdrawal can be Auto Processed as a Payout.</span> : ''

    const extraBody = filter([...confirmationFee, payoutAutoProcess], (b) => b)

    const accountObj = find(withdrawalAccounts, {id: get(acceptingWithdrawal, 'account.id')})
    const noteClasses = [style.generalClientNoteTextArea, style.withdrawalClientNoteTextArea]
    if (canWriteGeneralWithdrawalNote(viewer)) noteClasses.push(style.generalClientNoteTextAreaEditable)

    return (
      <div>
        <Row className="mb-3">
          <Col xs={2}>
            <ButtonToolbar>
              <StatusFilter
                value={withdrawalStatusFilter}
                statuses={filterableStatuses}
                onClick={this.activateStatusFilter.bind(this)}
              />
            </ButtonToolbar>
          </Col>
          <Col xs={2}>
            <Form.Check
              id="hide-wd-methods"
              type="checkbox"
              label="Hide WD Methods"
              disabled={!canApproveWithdrawals(this.props.viewer)}
              checked={get(generalInfo, 'hideWDMethods', false)}
              onChange={(e) => this.toggleHideWDMethods(e.target.checked)}
            />
          </Col>
          {canToggleWithdrawalApprovals(this.props.viewer) && <Col xs={2}>
            <Form.Check
              id="disable-wd-approvals"
              type="checkbox"
              label="Disable WD Approvals"
              disabled={!canToggleWithdrawalApprovals(this.props.viewer)}
              checked={get(generalInfo, 'hasDisabledWithdrawals', false)}
              onChange={(e) => this.toggleWithdrawalsApproval(e.target.checked)}
            />
          </Col>}
          <Col xs={6}>
            {this.renderWithdrawalButtons()}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col xs={5} sm={4}>
            <Row>
              <Col xs={12}>
                <ButtonToolbar>
                  <TransactionTypeFilter
                    value={withdrawalsTypeFilterState}
                    types={filterableTransferTypes}
                    onClick={this.activateTypesFilter.bind(this)}
                  />
                </ButtonToolbar>
              </Col>
              <Col xs={12} className="mt-3">
                <StylishSelect
                  id="t-withdrawals-vendors-filter"
                  placeholderText="All Vendors"
                  value={withdrawalVendorsFilter}
                  options={StylishSelect.enumToStylishOptions(map(withdrawalPaymentVendors, (p) => ({
                    key: p.key,
                    label: getWithdrawalVendorLabel(p.key),
                  })))}
                  highlightIfActive
                  multi
                  clearable
                  onChange={(e) => this.doFilterByVendors(e)}
                />
              </Col>
            </Row>
          </Col>
          <Col xs={7} sm={8} id="t-client-general-client-note">
            <textarea
              className={classnames(noteClasses)}
              maxLength="1000"
              value={get(this.state, 'generalWithdrawalNote', generalInfo.generalWithdrawalNote) || ''}
              onChange={(e) => this.setState({generalWithdrawalNote: e.target.value})}
              onBlur={this.saveGeneralWithdrawalNote.bind(this)}
              placeholder={!canWriteGeneralWithdrawalNote(viewer) ? '' : 'Click to edit withdrawal notes'}
              disabled={!canWriteGeneralWithdrawalNote(viewer)}
              rows={4}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            {withdrawals.length > 0 ? (
              <div>
                <Table bordered hover className={style.withdrawals}>
                  <thead>
                    <tr>
                      <th>Amount</th>
                      <th>Account Type/Login</th>
                      <th>Type</th>
                      <th>Payment Method/ Description</th>
                      <th>Date</th>
                      <th>Status</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {visibleWithdrawals.map((withdrawal) => {
                      const createdAt = moment(withdrawal.createdAt)

                      const {id, account, withdrawalType, status} = withdrawal
                      const remoteId = account && account.remoteId

                      const currencyDisplay = getFormattedAmount(withdrawal)

                      const withdrawalStatus = find(withdrawalStatuses, {key: status})
                      const meta = safeParseJSON(get(withdrawal, 'meta', '{}'))
                      let revertedTransactionType = ''
                      if (has(meta, 'revertedTransactionId')) {
                        revertedTransactionType = `/${get(depositVendors, [get(meta, 'vendor'), 'label'], capitalize(get(meta, 'vendor') || ''))}`
                      }
                      return (
                        <tr
                          className={`t-client-withdrawal ${activeId === id ? 'active' : ''} ${style.clickable}`}
                          key={id}
                          onClick={() => !canQueryAffiliateManagerClients(viewer) && this.showWithdrawalSidebar(withdrawal)}
                        >
                          <td>
                            {currencyDisplay}
                            <br />
                            {isCentAccount(account)
                              ? getFormattedAmount({
                                ...withdrawal,
                                currency: currencies.CUD.baseCurrency,
                                amount: withdrawal.amount / currencies.CUD.baseCurrencyRate,
                                paymentFields: {
                                  ...(withdrawal.paymentFields || {}),
                                  ...(get(withdrawal, 'paymentFields.withdrawalNetAmount')
                                    ? {withdrawalNetAmount: withdrawal.paymentFields.withdrawalNetAmount / currencies.CUD.baseCurrencyRate}
                                    : {}
                                  ),
                                }
                              })
                              : ''
                            }
                          </td>
                          <td>
                            {account && getAccountLabel(accountTypes[account.__typename])}
                            <br />
                            [{remoteId}]
                          </td>
                          <td>
                            {withdrawalType && withdrawalTypes[withdrawalType].label}{revertedTransactionType}
                          </td>
                          <td>
                            {getWithdrawalPaymentMethodDetails(withdrawal, generalInfo.id)}
                          </td>
                          <td title={createdAt.fromNow()}>
                            {readableDate(createdAt)}
                          </td>
                          <td>
                            {getWithdrawalStatusLabel(withdrawalStatus)}
                          </td>
                          <td style={{minWidth: 115}}>
                            {(() => {
                              switch (withdrawalStatus.key) {
                                case withdrawalStatuses.pending.key:
                                case withdrawalStatuses.notProcessed.key:
                                case withdrawalStatuses.onHold.key:
                                  return this.renderPendingWithdrawalButtons(withdrawal)
                                case withdrawalStatuses.accepted.key: {
                                  const canAutoTransfer =
                                    get(withdrawalPaymentVendors[withdrawal.vendor], 'withdrawalAutoTransfer')
                                  if (canAutoTransfer) {
                                    return this.renderAutoProcessWithdrawalButton(withdrawal)
                                  } else {
                                    return this.renderAcceptedWithdrawalButton(withdrawal)
                                  }
                                }
                                default: {
                                  return null
                                }
                              }
                            })()}
                            {!canQueryAffiliateManagerClients(viewer) && this.renderWithdrawalReceiptButton(withdrawal)}
                            {this.renderRevertWithdrawalButton(withdrawal)}
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
                <Row className="mt-3">
                  <Col xs={12}>
                    <Pagination
                      size="sm"
                      className="justify-content-center"
                      onSelect={(e, selectedEvent) => this.props.filterDispatch(
                        'Show page',
                        (state) => ({...state, withdrawalsPage: selectedEvent.eventKey})
                      )}
                    >
                      {getPageRange(withdrawalsPage, getPageCount(withdrawalsCount)).map((page) => {
                        if (page === 'LEFT_PAGE') {
                          return <Pagination.Prev
                            key={page}
                            onClick={(e, selectedEvent) => this.props.filterDispatch(
                              'Show page',
                              (state) => ({...state, withdrawalsPage: withdrawalsPage - 1})
                            )}
                          />
                        }

                        if (page === 'RIGHT_PAGE') {
                          return <Pagination.Next
                            key={page}
                            onClick={(e, selectedEvent) => this.props.filterDispatch(
                              'Show page',
                              (state) => ({...state, withdrawalsPage: withdrawalsPage + 1})
                            )}
                          />
                        }

                        return <Pagination.Item
                          active={page === withdrawalsPage}
                          key={page}
                          onClick={(e, selectedEvent) => this.props.filterDispatch(
                            'Show page',
                            (state) => ({...state, withdrawalsPage: page})
                          )}
                        >
                          {page}
                        </Pagination.Item>
                      })}
                    </Pagination>
                  </Col>
                </Row>
              </div>
            ) : (
              <div>No withdrawals.</div>
            )}

            <ReasonModal
              show={showRejectionReasonModal}
              header="Withdrawal Rejection Reason"
              placeholder="Choose Rejection Reason"
              onCancel={this.closeRejectionReasonWindow.bind(this)}
              onDone={(reason, selectedReasonCode) => {
                this.processWithdrawal(rejectingWithdrawal,
                  processWithdrawalResults.reject.key, reason, selectedReasonCode)
                this.closeRejectionReasonWindow()
              }}
              reasons={withdrawalRejectionReasons}
              warningMessage="Warning! The rejection reason is visible to client and cannot be changed."
              submitButtonBsClass="danger"
              submitButtonText="Reject"
            />

            <ReasonModal
              show={showPendingReasonModal}
              header="Withdrawal Pending Reason"
              placeholder="Choose Pending Reason"
              onCancel={this.closePendingReasonWindow.bind(this)}
              onDone={(reason, selectedReasonCode) => {
                this.processWithdrawal(pendingWithdrawal,
                  processWithdrawalResults.pending.key, reason, selectedReasonCode)
                this.closePendingReasonWindow()
              }}
              reasons={withdrawalPendingReasons}
              warningMessage="Warning! The pending reason is visible to client and cannot be changed."
              submitButtonBsClass="warning"
              submitButtonText="Submit as Pending"
            />

            {showAcceptanceModal && <ConfirmationModal
              show={showAcceptanceModal}
              header="Withdrawal Acceptance"
              body="Are you sure you would like to approve this request?"
              extraBody={extraBody}
              confirmLabel="Accept"
              onConfirm={() => {
                this.processWithdrawal(acceptingWithdrawal, processWithdrawalResults.approve.key)
                this.closeAcceptanceWindow()
              }}
              onCancel={this.closeAcceptanceWindow.bind(this)}
              totalDeposits={get(accountObj, 'totalDeposits') || 0}
              totalWithdrawals={get(accountObj, 'totalWithdrawals') || 0}
              currency={get(acceptingWithdrawal, 'currency') || 'EUR'}
              isWithdrawal
            />}

            {showWithdrawalModal && <AutoProcessWithdrawalModal
              show={showWithdrawalModal}
              withdrawal={selectedWithdrawalToProcess}
              deposits={deposits}
              country={generalInfo.address.country}
              onCancel={() => {
                this.closeAutoProcessWithdrawalWindow()
                this.context.clientProvider.fetch()
                this.context.clientProvider.subProviders.withdrawals.fetch()
                this.context.clientProvider.subProviders.activityLogs.fetch()
              }}
              onProcess={(withdrawal, clientId, processGroup) =>
                this.props.actions.client.sendAutoProcessRequest(withdrawal.id, clientId, processGroup)
                //this.closeAutoProcessWithdrawalWindow()
              }
              clientId={generalInfo.id}
            />}

            {showRefundModal && <RefundModal
              show={showRefundModal}
              deposits={deposits}
              withdrawals={withdrawals}
              withdrawal={acceptingWithdrawal}
              company={company}
              onProcessAsRefund={(refundedDeposits, skrillExtraParams) => {
                this.toggleRefundWindow(false, acceptingWithdrawal, refundedDeposits, skrillExtraParams)
                this.openAcceptanceWindow(acceptingWithdrawal)
              }}
              onCancel={() => this.toggleRefundWindow(false, undefined, undefined)}
              onProcessAsWithdrawal={() => {
                this.toggleRefundWindow(false, acceptingWithdrawal, undefined, undefined)
                this.openAcceptanceWindow(acceptingWithdrawal)
              }}
            />}
            {receipt && <WithdrawalReceiptModal
              show={!!receipt}
              data={receipt}
              onHide={() => this.setState({receipt: null})}
            />}
            {showWDSummaryModal && <WithdrawalsSummaryModal
              actions={this.props.actions}
              show={showWDSummaryModal}
              accounts={withdrawalAccounts}
              onHide={() => this.closeWDSummaryModal()}
            />}

            {showRevertModal && <RevertModal
              show={showRevertModal}
              header={`Revert Withdrawal #${get(revertingWithdrawal, 'id')}`}
              amount={get(revertingWithdrawal, 'paymentFields.withdrawalNetAmount') || get(revertingWithdrawal, 'amount')}
              onConfirm={(amount) => {
                this.revertWithdrawal(revertingWithdrawal, amount)
                this.closeRevertModal()
              }}
              onCancel={this.closeRevertModal}
            />}
          </Col>
        </Row>
      </div>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'withdrawalsUi']),
  provideProps((state, uiState, filterState) => {
    const {showRejectionReasonModal, rejectingWithdrawal, showAcceptanceModal, acceptingWithdrawal, showRefundModal,
      refundedDeposits, skrillExtraParams, showWDSummaryModal, withdrawalStatusFilter, withdrawalsTypeFilterState,
      showRevertModal, revertingWithdrawal, showPendingReasonModal, pendingWithdrawal,
    } = uiState
    const {withdrawalVendorsFilter, withdrawalsPage} = filterState

    return {
      showRejectionReasonModal,
      showAcceptanceModal,
      rejectingWithdrawal,
      acceptingWithdrawal,
      showRefundModal,
      refundedDeposits,
      skrillExtraParams,
      showWDSummaryModal,
      withdrawalStatusFilter,
      withdrawalsTypeFilterState,
      showRevertModal,
      revertingWithdrawal,
      showPendingReasonModal,
      pendingWithdrawal,
      withdrawalVendorsFilter,
      withdrawalsPage: withdrawalsPage || 1,
    }
  }),
)(Withdrawals)
