import React from 'react'
import moment from 'moment'
import {canQueryClients} from '@bdswiss/common-permissions'
import {companies, telephonyProviders} from '@bdswiss/common-enums'
import ReactAudioPlayer from 'react-audio-player'
import {Link} from 'react-router-dom'
import {get} from 'lodash'
import {getFullName} from '../useful'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import style from './calllogs.module.scss'
import {safeParseJSON} from '../common/utils'

const durationLabel = duration => duration > 120 ? Math.round(duration / 60) + 'm' : duration + 's'
const callLogLink = (id, content) => <Link to={`/call-logs/${id}`}>{content}</Link>

const CallLogRow = ({callLog, baseUri, viewer}) => {
  const {id, recording, durationBillable, startedAt, user, client,
    needsComplianceCheck, provider, isMonitored, monitoring} = callLog
  return (
    <tr id={`t-call-log-${id}`} className="t-call-log" key={id}>
      <td style={{textAlign: 'center', verticalAlign: 'middle'}}>
        {(isMonitored && !needsComplianceCheck) && <FontAwesomeIcon icon='check' className={style.successIcon} />}
        {needsComplianceCheck && <FontAwesomeIcon icon='exclamation-triangle' className={style.warningIcon} />}
      </td>
      <td>{callLogLink(id, id)}</td>
      <td>{callLogLink(id, getFullName(user))}</td>
      {canQueryClients(viewer) && (
        <td>
          {callLogLink(id, `[${client.id}] ${getFullName(client)}`)}
        </td>
      )}
      <td>{callLogLink(id, get(safeParseJSON(monitoring), 'score', '-'))}</td>
      <td>{callLogLink(id, get(companies, [callLog.company, 'label']))}</td>
      <td>
        {recording &&
          (<ReactAudioPlayer
            src={`${baseUri[provider || telephonyProviders.impactech.value]}${recording}`}
            preload="none"
            controls
          />)
        }
      </td>
      <td>{callLogLink(id, durationLabel(durationBillable))}</td>
      <td>{callLogLink(id, moment(startedAt).format('LLL'))}</td>
      <td>{callLogLink(id, get(telephonyProviders[provider], 'label', telephonyProviders.impactech.value))}</td>
    </tr>
  )
}

export default CallLogRow
