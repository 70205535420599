const _ = require('lodash')
const enums = require('@bdswiss/common-enums')
const moment = require('moment')
const utils = require('../utils/general')

const SAFECHARGE_MAX_YEARS_FOR_REFUND = 1

function isEmptyStr(string) {
  //remove all spaces including new lines and zero width unicode
  const convertedString = string && string.toString()
  return !convertedString || !convertedString.replace(/[\s\u200B-\u200D\uFEFF]/g, '')
}

/*
 * Like _.merge, but `null` values are treated as `undefined`.
 */
function mergeIgnoreNull(object, sources) {
  const mergeFn = (objValue, srcValue, key) => srcValue != null ? undefined : objValue
  return _.mergeWith(object, sources, mergeFn)
}

function postgresEnumArrayToJs(enumArr) {
  if (enumArr === '{}') return []
  return enumArr && enumArr.replace('{', '').replace('}', '').split(',')
}

const sanitizeLoggerOutput = (body) => _.cloneDeepWith(body, (i, k) => {
  if (k === 'args') {
    return JSON.stringify(sanitizeLoggerOutput(parseStringAsJson(i)))
  }
  return (/cardNumber/i).test(String(k))
    ? String(i).slice(-4)
    : (/password|authtoken|cvc|pares|apiSecret|apiKey|caFile/i).test(String(k)) ? '[HIDDEN]' : undefined
})

function parseJSON(value) {
  return _.attempt(JSON.parse.bind(null, value))
}

function hasValue(object, path) {
  return !_.isEmpty(_.get(object, path))
}

function hasValues(object, paths) {
  return paths.map((p) => !_.isEmpty(_.get(object, p))).every((p) => p)
}

function isWeekend() {
  const currentDay = moment(new Date()).day()
  return ((currentDay === 6) || (currentDay === 7))
}

function stringsAreEqual(a, b, ignoreCase = true) {
  if (ignoreCase) {
    return _.toLower(a) === _.toLower(b)
  }
  return a === b
}


function getDormantFeeJobType(accountType) {
  let jobType
  switch (accountType) {
    case enums.accountTypes.ForexAccount.value :
    case enums.accountTypes.ForexMauritiusAccount.value :
    case enums.accountTypes.ForexAsiaAccount.value :
    case enums.accountTypes.ForexVanuatuAccount.value :
    case enums.accountTypes.ForexRawAccount.value :
    case enums.accountTypes.ForexRawMauritiusAccount.value:
    case enums.accountTypes.ForexRawVanuatuAccount.value:
    case enums.accountTypes.ForexClassicAccount.value :
    case enums.accountTypes.ForexClassicMauritiusAccount.value :
    case enums.accountTypes.ForexClassicVanuatuAccount.value :
    case enums.accountTypes.ForexMT5Account.value :
    case enums.accountTypes.ForexMT5MauritiusAccount.value :
    case enums.accountTypes.ForexMT5VanuatuAccount.value :
    case enums.accountTypes.ForexClassicSkyGroundAccount.productId :
    case enums.accountTypes.ForexClassicSkyGroundDemoAccount.productId :
    case enums.accountTypes.ForexClassicEquityFlowAccount.productId :
    case enums.accountTypes.ForexClassicEquityFlowDemoAccount.productId :
    case enums.accountTypes.ForexClassicLydianAccount.productId :
    case enums.accountTypes.ForexClassicLydianDemoAccount.productId :
    case enums.accountTypes.ForexClassicTauroMarketsAccount.productId :
    case enums.accountTypes.ForexClassicTauroMarketsDemoAccount.productId :
      jobType = enums.jobTypes.forexDormantFee.value
      break
    case enums.accountTypes.TradesmarterAccount.value :
    case enums.accountTypes.TradesmarterMauritiusAccount.value :
    case enums.accountTypes.TradesmarterVanuatuAccount.value :
      jobType = enums.jobTypes.tradeSmarterDormantFee.value
      break
    case enums.accountTypes.AffiliateAccount.value :
    case enums.accountTypes.AffiliateMauritiusAccount.value :
    case enums.accountTypes.AffiliateVanuatuAccount.value :
    case enums.accountTypes.AffiliateSkyGroundAccount.productId :
    case enums.accountTypes.AffiliateEquityFlowAccount.productId :
    case enums.accountTypes.AffiliateLydianAccount.productId :
    case enums.accountTypes.AffiliateTauroMarketsAccount.productId :

      jobType = enums.jobTypes.affiliateCommissions.value
      break
    default :
  }
  return jobType
}


function deepReplaceValue(key, replacement, object, maxDepth = 3) {
  const newObject = {...object}
  _.each(object, (v, k) => {
    if (k === key) {
      newObject[k] = replacement
    } else if (typeof (v) === 'object' && maxDepth > 0) {
      newObject[k] = deepReplaceValue(key, replacement, v, maxDepth - 1)
    }
  })
  return newObject
}

const safeParseJSON = (s) => {
  try {
    return JSON.parse(s)
  } catch (e) {
    return s
  }
}

function parseStringAsJson(s) {
  const parsedString = safeParseJSON(s)
  if (_.isString(parsedString)) return parsedString
  for (const key in parsedString) {
    if (typeof parsedString[key] === 'string') {
      const jsonValue = safeParseJSON(parsedString[key])
      parsedString[key] = _.isObject(jsonValue) ? parseStringAsJson(JSON.stringify(jsonValue)) : parsedString[key]
    } else if (_.isObject(parsedString[key])) {
      parsedString[key] = parseStringAsJson(JSON.stringify(parsedString[key]))
    }
  }
  return sanitizeLoggerOutput(parsedString)
}

const getCardNumber = (depositVendor, receipt, meta) => {
  let cardNumber = ''
  switch (depositVendor) {
    // case enums.depositVendors.kalixa.value:
    //   cardNumber = _.get(receipt, 'account.cardNumber', '')
    //   break
    // case enums.depositVendors.wirecard.value:
    //   cardNumber = _.get(receipt, 'masked_account_number') ||
    //     _.get(receipt, 'payment.card-token.masked-account-number') || ''
    //   break
    case enums.depositVendors.payzoff.value:
      if (_.get(meta, 'method') === enums.payzoffPaymentOptions.creditCard.value) {
        cardNumber = _.get(receipt, 'card_number', '')
      }
      break
    case enums.depositVendors.safecharge.value:
      cardNumber = _.get(receipt, 'cardNumber', '') || _.get(receipt, 'ccCardNumber', '')
      break
    case enums.depositVendors.rave.value:
      cardNumber = _.get(receipt, 'card.last4digits', '')
      break
    case enums.depositVendors.cardpay.value:
      cardNumber = _.get(receipt, 'card_account.masked_pan') || ''
      cardNumber = _.last(cardNumber.split('.'))
      break
    case enums.depositVendors.appex.value:
      cardNumber = _.get(receipt, 'PAN') || ''
      break
    case enums.depositVendors.checkout.value:
      cardNumber = _.get(receipt, 'source.last4') || ''
      break
    case enums.depositVendors.powercash21.value:
      cardNumber = _.get(receipt, 'ccn_four') || ''
      break
    case enums.depositVendors.jmFinancial.value:
      cardNumber = _.get(receipt, 'card.last_four') || ''
      break
    case enums.depositVendors.paypugs.value:
      cardNumber = _.get(receipt, 'card') || ''
      break
    case enums.depositVendors.lionPay.value:
      cardNumber = _.get(receipt, 'card') || ''
      break
    case enums.depositVendors.vaultspay.value:
      cardNumber = _.last(_.get(receipt, 'customerDetails.cardNumber', '').split('X'))
      break
    case enums.depositVendors.ikajo.value:
      cardNumber = _.get(receipt, 'card') || ''
      break
    default:
      cardNumber = ''
  }
  return _.last(cardNumber.split('*'))
}

function getScoringResult(forexScore, binaryScore) {
  switch (true) {
    case (forexScore >= 500 || binaryScore >= 500):
      return enums.scoringResults.advancedUnderstanding.value
    case (forexScore >= 300 || binaryScore >= 300):
      return enums.scoringResults.basicUnderstanding.value
    case (forexScore >= 75 || binaryScore >= 75):
      return enums.scoringResults.productNotSuitableLeverage100.value
    case (forexScore >= 25 || binaryScore >= 25):
      return enums.scoringResults.productNotSuitableLeverage50.value
    default:
      return enums.scoringResults.cannotTrade.value
  }
}

function getScoringResultV2(forexScore, binaryScore, company, appropTestQuestionsV2) {
  const activeQuestions = _.pickBy(appropTestQuestionsV2, (q) => {
    const checkCompanies = _.filter(q.company, (o) => o.value === company)
    return (!_.isEmpty(checkCompanies))
  })

  let totalScore = 0
  _.values(activeQuestions).forEach((question) => {
    if (!question.options) return

    const answers = _.omitBy(question.options, 'disabled')
    const answerValues = _.values(answers)
    const maxScore = _.maxBy(answerValues, 'forex_points_v2').forex_points_v2
    totalScore = totalScore + maxScore
  })

  const appropTestPassLimit = _.round(totalScore * 0.0917)

  switch (true) {
    case ((!forexScore || forexScore < appropTestPassLimit) ||
    (!binaryScore || binaryScore < appropTestPassLimit)):
      return enums.scoringResultsV2.productNotSuitableLeverage50.value
    default:
      return enums.scoringResultsV2.advancedUnderstanding.value
  }
}

function getRelatedDeposits(withdrawal, deposits, excludeBlockedSey = true) {
  if (_.isEmpty(deposits)) return []
  switch (withdrawal.vendor) {
    case enums.withdrawalPaymentVendors.skrill.key:
      return deposits.filter((d) => {
        const receipt = JSON.parse(_.get(d, 'receipt', '{}'))
        const meta = JSON.parse(_.get(d, 'meta', '{}'))
        const refunds = _.get(meta, 'refunds', [])
        const payFormEmail = _.toLower(_.get(receipt, 'pay_from_email', ''))
        const vendorAccountEmail = _.toLower(_.get(withdrawal, 'paymentFields.vendorAccountEmail', ''))
        return d.status === enums.depositStatuses.completed.key &&
          _.get(d, 'vendor') === enums.depositVendors.skrill.value &&
          payFormEmail === vendorAccountEmail &&
          _.sumBy(refunds, 'refundedAmount') !== d.amount &&
          !excludeBlockedSey
      })
    case enums.withdrawalPaymentVendors.creditDebitCard.key: {
      const filteredDeposits = deposits.filter((d) => {
        const receipt = JSON.parse(_.get(d, 'receipt', '{}'))
        const meta = JSON.parse(_.get(d, 'meta', '{}'))
        const refunds = _.get(meta, 'refunds', [])
        const cardNumber = getCardNumber(_.get(d, 'vendor'), receipt, meta)
        return d.status === enums.depositStatuses.completed.key &&
          cardNumber === _.get(withdrawal, 'paymentFields.cardNumber') &&
          _.sumBy(refunds, 'refundedAmount') !== d.amount &&
          ((d.vendor === enums.depositVendors.safecharge.key &&
            moment().diff(moment(d.createdAt), 'years') < SAFECHARGE_MAX_YEARS_FOR_REFUND) ||
            d.vendor !== enums.depositVendors.safecharge.key
          )
      })
      if (excludeBlockedSey) {
        return _.reject(filteredDeposits, (d) => d.vendor === enums.depositVendors.safecharge.value)
      }
      return filteredDeposits
    }
    // case enums.withdrawalPaymentVendors.paypal.key:
    //   return deposits.filter((d) => {
    //     const receipt = JSON.parse(_.get(d, 'receipt', '{}'))
    //     const meta = JSON.parse(_.get(d, 'meta', '{}'))
    //     const refunds = _.get(meta, 'refunds', [])
    //     const payFormEmail = _.toLower(_.get(receipt, 'transaction.paypal.payerEmail', ''))
    //     const vendorAccountEmail = _.toLower(_.get(withdrawal, 'paymentFields.vendorAccountEmail', ''))
    //     return d.status === enums.depositStatuses.completed.key &&
    //       _.get(d, 'vendor') === enums.depositVendors.paypal.value &&
    //       payFormEmail === vendorAccountEmail &&
    //       _.sumBy(refunds, 'refundedAmount') !== d.amount
    //   })
    default:
      return []
  }
}

/**
 * Parse a string template with tags like {var}
 *
 * @param {string} string ex. /clients/{memberId}/deposits/{depositId}
 * @param {Object} vars Map to replace template tags ex. {memberId: 140133, depositId: 1}
 */
const parseTemplate = (string, vars) => string.replace(/{(.*?)}/g, (match, offset) => vars[offset])

function checkIfIsOnlyAffiliateManager(viewer) {
  return _.get(viewer, 'permissions', []).length === 1 &&
    viewer.permissions.indexOf('affiliate_manager_clients_read') >= 0 &&
    _.get(viewer, 'roles', []).length === 1 && viewer.roles.indexOf('affiliate_manager') >= 0
}

function getDocumentLabel(type, fileDescription) {
  if ([enums.documentTypes.other.key, enums.documentTypes.otherPOF.key].includes(type) &&
    !_.isEmpty(fileDescription)
  ) {
    return fileDescription
  } else if (type === enums.documentTypes.bankStatementPOR.key) {
    return 'Bank Statement (POR)'
  } else if (type === enums.documentTypes.bankStatementPOF.key) {
    return 'Bank Statement (POF)'
  } else if (type === enums.documentTypes.otherPOF.key) {
    return 'Other (POF)'
  } else {
    return enums.documentTypes[type] ? enums.documentTypes[type].label : type
  }
}

const shorten = (text, chars) => (text || '').length > chars ? text.substring(0, chars) + '...' : text

const pageSizeEnum = {
  p10: {
    key: 10,
    label: 10,
    value: 10,
  },
  p25: {
    key: 25,
    label: 25,
    value: 25,
  },
  p50: {
    key: 50,
    label: 50,
    value: 50,
  },
  p100: {
    key: 100,
    label: 100,
    value: 100,
  },
  p200: {
    key: 200,
    label: 200,
    value: 200,
  },
}

const priorityLevels = {
  low: {
    key: 'low',
    label: 'Low',
    value: 'low',
  },
  medium: {
    key: 'medium',
    label: 'Medium',
    value: 'medium',
  },
  high: {
    key: 'high',
    label: 'High',
    value: 'high',
  },
}

const orderByFields = {
  id: {
    key: 'id',
    value: 'id',
    label: 'ID',
  },
  createdAt: {
    key: 'createdAt',
    value: 'created_at',
    label: 'Created At',
  },
  amount: {
    key: 'amount',
    value: 'amount',
    label: 'Amount',
  },
}

function findAccountTypes(notAllowedSubtypes = []) {
  const allowedAccounts = []
  _.forEach(enums.accountSubtypes, (subtype) => {
    const type = !_.includes(notAllowedSubtypes, subtype.value) &&
      !subtype.hideFromClients &&
      _.find(enums.accountTypes, (type) => type.clientOpenPermitted &&
      _.includes(type.supportedSubtypes, subtype.value))
    type && allowedAccounts.push({...subtype, company: enums.companies[type.company].brand})
  })
  return allowedAccounts
}

function getAccountOpenTypes() {
  const accountTypes = findAccountTypes()
  return _.sortBy(
    _.map(accountTypes, (account) =>
      ({...account, label: `${enums.companies[account?.company]?.brandLabel} - ${account?.label}`})),
    'label'
  )
}

const bankDetailsFields = {
  clientAccountCurrency: {
    key: 'clientAccountCurrency',
    label: 'Client Account Currency',
  },
  recipient: {
    key: 'recipient',
    label: 'Recipient',
  },
  iban: {
    key: 'iban',
    label: 'IBAN',
  },
  creditingAccount: {
    key: 'creditingAccount',
    label: 'Crediting Account',
  },
  swift: {
    key: 'swift',
    label: 'BIC-SWIFT-SEPA',
  },
  swiftBic: {
    key: 'swiftBic',
    label: 'SWIFT BIC',
  },
  bank: {
    key: 'bank',
    label: 'Bank',
  },
  correspondentBank: {
    key: 'correspondentBank',
    label: 'Correspondent Bank',
  },
  address: {
    key: 'address',
    label: 'Address',
  },
  city: {
    key: 'city',
    label: 'City',
  },
  country: {
    key: 'country',
    label: 'Country',
  },
  currency: {
    key: 'currency',
    label: 'Currency',
  },
  reference: {
    key: 'reference',
    label: 'Reference',
  }
}

const getDefaultServer = (serversWeights, servers, partnerDefaultMT4Server, accountSubType, company) => {
  if (partnerDefaultMT4Server && accountSubType === enums.accountSubtypes.basic.key &&
    !utils.companyCheck.isCysec(company)
  ) {
    return _.find(enums.mt4Servers, {key: partnerDefaultMT4Server})?.value
  }
  // Weight-based
  const randomNumber = Math.random() * 100
  let total = 0
  let selectedServer
  for (let i = 0; i < servers.length; i++) {
    total += _.get(serversWeights, servers[i])
    if (isNaN(total)) total = 100
    if (randomNumber < total) {
      selectedServer = servers[i]
      break
    }
  }
  return selectedServer
}

function getAccountForexServers(accountTypeKey, accountSubtype, whiteLabel, partnerClassicDefaultMT4Server,
  serversWeights, whitelabelServersWeights, company,
) {
  const accountType = _.find(enums.accountTypes, {key: accountTypeKey})
  if (!accountType || !accountSubtype || !['forexMt4'].includes(accountType.category)) {
    return {forexServers: [], defaultServer: ''}
  }
  if (accountType.isDemo) return {
    forexServers: [enums.mt4Servers.demo.value],
    defaultServer: enums.mt4Servers.demo.value,
  }

  let whiteLabelBasedOnProduct, mt4CompanyServers
  if (whiteLabel) {
    const {category, internal} = _.find(enums.whiteLabels, {key: whiteLabel})
    if (internal) {
      mt4CompanyServers = _.find(enums.accountSubtypes, {key: accountSubtype})?.mt4CompanyServers?.[company]
    } else {
      whiteLabelBasedOnProduct = _.find(enums.whiteLabels, (w) => w.company.value === accountType.company
        && w.category === category)
    }
  } else {
    mt4CompanyServers = _.find(enums.accountSubtypes, {key: accountSubtype})?.mt4CompanyServers?.[company]
  }

  const forexServers = mt4CompanyServers
    || _.get(_.find(enums.accountSubtypes, {key: accountSubtype}), 'mt4Servers')
    || _.get(whiteLabelBasedOnProduct, 'mt4Servers')
    || accountType.mt4Servers
    || [enums.mt4Servers.real01.value]
  const weights = !_.isEmpty(_.get(whiteLabelBasedOnProduct, 'mt4Servers', []))
    ? whitelabelServersWeights : serversWeights
  return {
    forexServers,
    defaultServer: getDefaultServer(weights, forexServers, partnerClassicDefaultMT4Server, accountSubtype, company)
  }
}

function isForexAccount({__typename}) {
  return ![
    enums.accountTypes.PAMMAccount.key,
    enums.accountTypes.PAMMSMAccount.key,
    enums.accountTypes.PAMMMauritiusAccount.key,
    enums.accountTypes.PAMMVanuatuAccount.key,
    enums.accountTypes.PAMMSMMauritiusAccount.key,
    enums.accountTypes.PortfolioManagementAccount.key,
    enums.accountTypes.PortfolioManagementMauritiusAccount.key,
    enums.accountTypes.IntroducingBrokerSwissMarketsAccount.key,
    enums.accountTypes.IntroducingBrokerAccount.key,
    enums.accountTypes.IntroducingBrokerSwissMarketsMauritiusAccount.key,
    enums.accountTypes.IntroducingBrokerSwissMarketsVanuatuAccount.key,
    enums.accountTypes.IntroducingBrokerMauritiusAccount.key,
    enums.accountTypes.IntroducingBrokerEquityFlowAccount.key,
    enums.accountTypes.IntroducingBrokerBDSwissVanuatuAccount.key,
    enums.accountTypes.BondAccount.key,
    enums.accountTypes.BondDemoAccount.key,
    enums.accountTypes.BondMauritiusAccount.key,
    enums.accountTypes.BondMauritiusDemoAccount.key,
    enums.accountTypes.BondVanuatuAccount.key,
    enums.accountTypes.BondVanuatuDemoAccount.key,
  ].includes(__typename) &&
    _.filter(
      enums.accountTypes,
      (a) => _.includes(['forexMt4', 'forexMt5'], a.category)
    ).map((c) => c.key).includes(__typename)
}

function isCentAccount({__typename}) {
  return [
    enums.accountTypes.CentMauritiusAccount.key,
    enums.accountTypes.CentVanuatuAccount.key,
  ].includes(__typename)
}

module.exports = {
  isEmptyStr,
  mergeIgnoreNull,
  postgresEnumArrayToJs,
  parseJSON,
  hasValue,
  hasValues,
  isWeekend,
  stringsAreEqual,
  getDormantFeeJobType,
  deepReplaceValue,
  parseStringAsJson,
  getRelatedDeposits,
  sanitizeLoggerOutput,
  safeParseJSON,
  getScoringResult,
  getScoringResultV2,
  parseTemplate,
  checkIfIsOnlyAffiliateManager,
  getDocumentLabel,
  shorten,
  pageSizeEnum,
  priorityLevels,
  orderByFields,
  findAccountTypes,
  getAccountOpenTypes,
  bankDetailsFields,
  getAccountForexServers,
  isForexAccount,
  isCentAccount,
}
