import {useState} from 'react'
import {Form, Table, Modal, ButtonToolbar, Button} from 'react-bootstrap'
import classnames from 'classnames'

import {permissionsProvider} from './../../Permissions/providers'
import {compose, mountDataProviders, provideProps} from './../../decorators'

import styles from './RoleCreateModal.module.scss'

const RoleCreateModal = ({
  role,
  permissions,
  onSubmit,
  onCancel,
  actions,
}) => {
  const [name, setName] = useState(role?.name ?? '')
  const [description, setDescription] = useState(role?.description ?? '')
  const [selectedPermissions, setSelectedPermissions] = useState(() => {
    if (role?.permissions) {
      return role.permissions.reduce((summ, permission) => {
        summ[permission.id] = true
        return summ
      }, {})
    } else {
      return {}
    }
  })

  const handleCreate = async () => {
    const id = role?.id ?? name.toLowerCase().split(' ').join('_')
    const permissionsList = Object.keys(selectedPermissions).filter(
      (permission) => selectedPermissions[permission]
    )
    const roleData = {
      id,
      name,
      description,
      permissions: permissionsList,
    }

    try {
      await actions.roles.createRole(roleData)
      onSubmit()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  const canSubmit = name && description && Object.values(selectedPermissions).some(value => !!value)

  return (
    <Modal keyboard show={true} onHide={onCancel} scrollable={true}>
      <Modal.Header closeButton>
        <strong>{role ? 'Edit Role' : 'Create Role'}</strong>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            autoFocus
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control
            type="text"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            autoFocus
          />
        </Form.Group>
        <Table bordered hover>
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Description</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {permissions.map(({id, name, description}) => (
              <tr
                key={id}
                id={id}
                className={classnames(
                  'clickable',
                  selectedPermissions[id] && styles['selectedRow']
                )}
                onClick={() =>
                  setSelectedPermissions((state) => ({
                    ...state,
                    [id]: !state[id],
                  }))
                }
              >
                <td>{name}</td>
                <td>{description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="float-right">
          <Button
            variant="outline-secondary"
            onClick={onCancel}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button disabled={!canSubmit} variant="danger" onClick={handleCreate}>
            {role ? 'Update' : 'Create'}
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  )
}

export default compose(
  provideProps(({permissions}) => ({permissions})),
  mountDataProviders({permissionsProvider})
)(RoleCreateModal)
