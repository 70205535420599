import React from 'react'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import {compose, provideProps, mountDataProviders} from '../decorators'
import SeminarForm from './SeminarForm'
import {seminarProvider} from './providers'
import PureComponent from '../PureComponent'

class SeminarEdit extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  render() {
    const {seminar} = this.props
    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/seminars"
            active={false}
          >
            <BreadcrumbItem>
              Seminars
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            {seminar.id}
          </BreadcrumbItem>
        </Breadcrumb>
        <SeminarForm data={seminar} />
      </Container>
    )
  }
}

export default compose(
  provideProps((state) => ({
    seminar: state.seminar,
  })),
  mountDataProviders({seminarProvider}),
)(SeminarEdit)
