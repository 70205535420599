import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

export default class RemoveDocumentModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const {show, onConfirm, onCancel} = this.props
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Delete Document</strong>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to delete this document?</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button onClick={onCancel} variant="outline-secondary" size="sm" className="mr-2">
              Cancel
            </Button>
            <Button variant="danger" size="sm" onClick={onConfirm}>
              <FontAwesomeIcon icon="times" /> Remove
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
