import React from 'react'
import moment from 'moment'
import {get, includes, slice, map} from 'lodash'
import {Table, Row, Col, Pagination} from 'react-bootstrap'
import {referralStatuses} from '@bdswiss/common-enums'
import PureComponent from '../PureComponent'
import {getPageCount, getPageRange} from '../useful'
import {linkClient}  from '../utils/links'
import {provideProps} from '../decorators'
import style from './client.module.scss'

export default provideProps()(class Referrals extends PureComponent {

  state = {
    page: 1,
    rowsPerPage: 10,
  }

  getReferralStatus(status) {
    if (includes([referralStatuses.clientDeclined.value, referralStatuses.activationOverdue.value], status)) {
      return 'red'
    } else if (includes([referralStatuses.claimed.value, referralStatuses.activated.value], status)) {
      return 'green'
    } else {
      return 'orange'
    }
  }

  renderReferralTable(referralDetails) {
    const {page, rowsPerPage} = this.state
    const referrals = get(referralDetails, 'referrals')

    return <Table bordered hover className={style.table}>
      <thead>
        <tr>
          <th>Client ID</th>
          <th>Registration Date</th>
          <th>Status</th>
          <th>Profit, €</th>
        </tr>
      </thead>
      <tbody>
        {map(slice(referrals, rowsPerPage * (page - 1), rowsPerPage * (page - 1) + rowsPerPage), (referral) =>
          <tr key={referral.id}>
            <td>{linkClient(referral.referralId, referral.referralId, true, true)}</td>
            <td>{moment(referral.registrationDate).format('Do MMM YYYY')}</td>
            <td><span className={style[`${this.getReferralStatus(referral.status)}Text`]}>
              {referralStatuses[referral.status] && referralStatuses[referral.status].label}
            </span></td>
            <td>{referral.amountReferrer}</td>
          </tr>)}
      </tbody>
    </Table>
  }

  render() {
    const {personalDetails: {referralDetails}} =  this.props.client
    const {page} = this.state

    return get(referralDetails, 'countReferrals') &&
    <Row className={style.personalDetails}>
      <Col xs={12}>{this.renderReferralTable(referralDetails)}</Col>
      {referralDetails.countReferrals > this.state.rowsPerPage  && <Col xs={12} style={{textAlign: 'center'}}>
        <Col xs={12} className="mt-3">
          <Pagination
            size="sm"
            className="justify-content-center"
            onSelect={(e, selectedEvent) => this.setState({page: selectedEvent.eventKey})}
          >
            {getPageRange(page, getPageCount(referralDetails.countReferrals, this.state.rowsPerPage)).map((p) => {
              if (p === 'LEFT_PAGE') {
                return <Pagination.Prev
                  key={p}
                  onClick={(e, selectedEvent) => this.setState({page: page - 1})}
                />
              }

              if (p === 'RIGHT_PAGE') {
                return <Pagination.Next
                  key={p}
                  onClick={(e, selectedEvent) => this.setState({page: page + 1})}
                />
              }

              return <Pagination.Item
                active={p === page}
                key={p}
                onClick={(e, selectedEvent) => this.setState({page: p})}
              >
                {p}
              </Pagination.Item>
            })}
          </Pagination>
        </Col>
      </Col>}
    </Row>
  }
})

