import React from 'react'
import {omit} from 'lodash'
import PropTypes from 'prop-types'
import {Button, OverlayTrigger, Tooltip} from 'react-bootstrap'
import classNames from 'classnames'
import PureComponent from '../PureComponent'

/**
 * Button that shows tooltip when disabled.
 *
 * Truly disabled button does not send mouseenter/mouseleave events and therefore tooltip over it does
 * not work.  If the `disabled` property is on, this class renders fake disabled button which looks like disabled
 * and does not send `onClick` even when users click it. However, underlying htlm element is not disabled and
 * thereforetooltip works.
 *
 * Note: I did tried solutions here https://github.com/react-component/tooltip/issues/18 but tooltip was
 * less perfect - sometimes did not shown up or did not disappeared or appeared/disappers after delay etc. It
 * seemed to have problems especially when mouse moved semi-fast.
 *
 */
export default class DisabledTooltipButton extends PureComponent {

  static propTypes = {
    disabledTooltip: PropTypes.string.isRequired,
  };

  render() {
    // enabled button can be rendered normally
    if (!this.props.disabled) {
      return (<Button {...omit(this.props, ['disabledTooltip'])} />)
    }

    // make button look disabled and remove its onClick handler
    const fakeDisabledProps = {
      ...this.props,
      className: classNames(this.props.className, 'fakeDisabled'),
      tabIndex: -1,
      onFocus: (e) => e.target.blur(),
    }
    const {disabledTooltip} = this.props

    // generate tooltip over fake disabled button
    const tooltip = (<Tooltip id="disabled">{disabledTooltip}</Tooltip>)
    return (
      <OverlayTrigger placement="bottom" overlay={tooltip}>
        <Button {...omit(fakeDisabledProps, ['onClick', 'disabledTooltip'])} />
      </OverlayTrigger>
    )
  }
}
