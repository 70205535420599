import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {orderDirections, languages, jobStatuses} from '@bdswiss/common-enums'
import {Row, Col, Table, Button, Card, Pagination, Container} from 'react-bootstrap'
import {canCancelMarketingEmails, canViewMarketingEmails} from '@bdswiss/common-permissions'
import {events} from '../enums'
import style from './marketingemails.module.scss'
import {marketingEmailsProvider} from './providers'
import {getJobStatusLabel} from '../utils/rendering'
import {readableDate, getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class MarketingEmails extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.marketingEmailsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    marketingEmailsProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  renderMessages(language, body, key) {
    return (
      <tr className="t-marketing-email-message" key={key}>
        <td>
          <div>
            {language && languages[language].label}
          </div>
        </td>
        <td>
          <div>
            {body}
          </div>
        </td>
      </tr>
    )
  }

  renderCancelButton(id) {
    const {viewer} = this.props
    return (
      canCancelMarketingEmails(viewer) && <Button
        variant="danger"
        size="sm"
        onClick={() => {
          this.props.actions.marketingEmails.cancelMarketingEmails(id)
            .then((res) => {
              this.context.marketingEmailsProvider.fetch()
            })
            .catch((error) => {
              error.isShowActualError = true
              this.context.logError(error)
            })
        }}
      > Cancel
      </Button>
    )
  }

  renderMarketingEmail(marketingEmail) {
    const {id, clientCount, createdAt, template, unsubscribeGroup, status} = marketingEmail

    return (
      <tr className="t-marketing-email" key={id}>
        <td>
          <div>
            {id}
          </div>
        </td>
        <td>
          <div>
            {clientCount}
          </div>
        </td>
        <td>
          <div>
            {template}
          </div>
        </td>
        <td>
          <div>
            {unsubscribeGroup}
          </div>
        </td>
        <td>
          <div>
            {readableDate(moment(createdAt))}
          </div>
        </td>
        <td>
          <div>
            {getJobStatusLabel(jobStatuses[status])}
          </div>
        </td>
        <td>
          <div>
            {status === jobStatuses.queued.key &&
              this.renderCancelButton(id)}
          </div>
        </td>
      </tr>
    )
  }

  render() {
    const {marketingEmails, marketingEmailsCount, marketingEmailsPage} = this.props
    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Client Count</th>
                      <th>Template</th>
                      <th>Unsubscribe Group</th>
                      <th>Created At</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketingEmails.map((marketingEmail) => this.renderMarketingEmail(marketingEmail))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch(
                'Show page',
                (state) => ({...state, marketingEmailsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(marketingEmailsPage, getPageCount(marketingEmailsCount)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, marketingEmailsPage: marketingEmailsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, marketingEmailsPage: marketingEmailsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === marketingEmailsPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, marketingEmailsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canViewMarketingEmails),

  uiMount((state) => ['ui', 'marketing-emails']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/marketing-emails',
      (state) => {
        let marketingEmailsSort
        if (!marketingEmailsSort || marketingEmailsSort.orderBy == null
            || marketingEmailsSort.orderDirection == null
            || !(marketingEmailsSort.orderDirection in orderDirections)) {
          marketingEmailsSort = defaultSort
        }

        return ({...state, marketingEmailsSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {marketingEmails, marketingEmailsCount} = state
    const {marketingEmailsPage, marketingEmailsSort} = uiState

    return ({
      marketingEmails,
      marketingEmailsCount,
      marketingEmailsPage: marketingEmailsPage || 1,
      marketingEmailsSort,
    })
  }),

  mountDataProviders({marketingEmailsProvider}),
)(MarketingEmails)
