import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {merge, kebabCase, debounce, map, size, filter, some} from 'lodash'
import {canQueryDocuments, canAssignDocument} from '@bdswiss/common-permissions'
import {documentStatuses, profileChangeStatuses, appropTestStatuses, yesNo, countries,
  documentTypes, clientTypes, kycStatuses, tradingStatuses, dueDiligenceStatuses} from '@bdswiss/common-enums'
import {Row, Col, Card, Pagination, ButtonGroup, Button, Badge, DropdownButton, Dropdown, Container, Form, InputGroup} from 'react-bootstrap'
import ListDueDiligences from './ListDueDiligences'
import style from './documents.module.scss'
import ListDocuments from './ListDocuments'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import ListAppropTests from './ListAppropTests'
import {getPageCount, allUsers, getPageRange} from '../useful'
import SelectAgent from '../components/SelectAgent'
import {getAllowedCompanies} from '../utils/general'
import ListProfileChanges from './ListProfileChanges'
import StylishSelect from '../components/StylishSelect'
import {events, depositorsFilterOptions} from '../enums'
import AssignDocumentsModal from './AssignDocumentsModal'
import SelectAgentFilter from '../components/SelectAgentFilter'
import ChangeDocumentsStatusModal from './ChangeDocumentsStatusModal'
import {countriesAndRegionsOptions, createCountriesFilter} from '../utils/country'
import {showEnum, documentsProvider, documentsTopVipCountProvider} from './providers'
import {compose, provideProps, mountDataProviders, predispatch, uiMount, checkRights} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

const allClientsDepositorsSelectOption = {
  value: '',
  label: 'All Clients',

}
const depositorsSelectOptions = [
  allClientsDepositorsSelectOption,
  ...Object.values(depositorsFilterOptions),
]

class Documents extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
  }

  static contextTypes = {
    documentsProvider: PropTypes.object.isRequired,
    documentsTopVipCountProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {createdFrom: ''}
    this.setState({dateFilters: stateDateFilters})
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.documentsProvider.fetch()
    this.context.documentsTopVipCountProvider.fetch()
  }

  activateShowFilter(value) {
    this.props.uiDispatch(
      'Show on documents page',
      (state) => ({...state, show: value, page: 1})
    )
  }

  activateDocumentStatusFilter(value) {
    this.props.uiDispatch(
      'Filter documents by status',
      (state) => ({...state, documentStatus: value, page: 1})
    )
  }

  activateProfileChangeStatusFilter(value) {
    this.props.uiDispatch(
      'Filter profile changes by status',
      (state) => ({...state, profileChangeStatus: value, page: 1})
    )
  }

  activateAppropTestStatusFilter(value) {
    this.props.uiDispatch(
      'Filter approp tests by status',
      (state) => ({...state, appropTestStatus: value, page: 1})
    )
  }

  activateDueDiligenceStatusFilter(value) {
    this.props.uiDispatch(
      'Filter due diligences by status',
      (state) => ({...state, dueDiligenceStatus: value, page: 1})
    )
  }

  activateCountryRegionFilter(selection) {
    this.props.uiDispatch(
      'Filter documents by client\'s country or region',
      (state) => ({
        ...state,
        countriesFilter: createCountriesFilter(selection),
        countriesFilterValue: selection.value,
        page: 1,
      })
    )
  }

  togglePrioritizeProfileChange = () => {
    this.props.uiDispatch(
      'Prioritize profile changes by Firstname, lastname, dob',
      (state) => ({...state, priorityProfileChange: !state.priorityProfileChange, page: 1})
    )
  }

  doFilterByCompany(company) {
    this.props.uiDispatch(
      'Filter documents by company',
      (state, arg) => ({...state, companyFilter: company, page: 1}),
      [company]
    )
  }

  doFilterByKycStatus(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter approp tests by KYC status',
      (state, arg) => ({...state, kycStatusFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByDepositors(isDepositor) {
    this.props.uiDispatch(
      'Filter depositors',
      (state) => ({...state, depositorsFilter: isDepositor, page: 1})
    )
  }

  renderShowButton(value, label, show) {
    return (
      <Button
        id={`t-documents-toolbar-show-${kebabCase(label)}`}
        key={value}
        variant="outline-secondary"
        active={value === show}
        onClick={() => this.activateShowFilter(value)}
      >{label}</Button>
    )
  }

  renderDocumentStatusButton(value, label, filterStatus) {
    const {documentsTopVipCount} = this.props
    const vipCount = {}
    for (const result of documentsTopVipCount) {
      vipCount[result.status] = result.count
    }
    const topVipCount = vipCount[value] &&
      <span><Badge id="alert-badge" variant="danger">{vipCount[value]}</Badge></span>
    return (
      <Button
        id={`t-documents-toolbar-filter-documents-${kebabCase(label)}`}
        style={{fontSize: 11}}
        key={value}
        size="sm"
        variant="outline-secondary"
        active={value === filterStatus}
        onClick={() => this.activateDocumentStatusFilter(value)}
      >{label}{topVipCount}</Button>
    )
  }

  renderProfileChangeStatusButton(value, label, filterStatus) {
    return (
      <Button
        id={`t-documents-toolbar-filter-profile-changes-${kebabCase(label)}`}
        key={value}
        size="sm"
        variant="outline-secondary"
        active={value === filterStatus}
        onClick={() => this.activateProfileChangeStatusFilter(value)}
      >{label}</Button>
    )
  }

  renderAppropTestStatusButton(value, label, filterStatus) {
    return (
      <Button
        id={`t-documents-toolbar-filter-approp-tests-${kebabCase(label)}`}
        key={value}
        size="sm"
        variant="outline-secondary"
        active={value === filterStatus}
        onClick={() => this.activateAppropTestStatusFilter(value)}
      >{label}</Button>
    )
  }

  renderDueDiligenceStatusButton(value, label, filterStatus) {
    return (
      <Button
        id={`t-documents-toolbar-filter-due-dilgences-${kebabCase(label)}`}
        key={value}
        size="sm"
        variant="outline-secondary"
        active={value === filterStatus}
        onClick={() => this.activateDueDiligenceStatusFilter(value)}
      >{label}</Button>
    )
  }

  handleDateFilterChanged(name, value) {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })
    if (!value || moment.isMoment(value)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilters} = this.state
    if (dateFilters) {
      if (moment.isMoment(dateFilters.createdFrom)) {
        dateFilters.createdFrom.startOf('day')
      }
      if (moment.isMoment(dateFilters.createdTo)) {
        dateFilters.createdTo.endOf('day')
      }
      if (moment.isMoment(dateFilters.expirationFrom)) {
        dateFilters.expirationFrom.startOf('day')
      }
      if (moment.isMoment(dateFilters.expirationTo)) {
        dateFilters.expirationTo.endOf('day')
      }
      if (moment.isMoment(dateFilters.lastUpdated)) {
        dateFilters.lastUpdated.endOf('day')
      }
    }
    this.props.uiDispatch(
      'Filter documents by dates',
      (state, arg) => ({...state, dateFilters, page: 1}),
      [dateFilters]
    )
  }

  doFilterByDocumentTypes(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter documents by types',
      (state, arg) => ({...state, documentTypesFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByTradingStatuses(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter documents by trading status',
      (state, arg) => ({...state, tradingStatusesFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByClientTypes(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter documents by client types',
      (state, arg) => ({...state, clientTypesFilter: values, page: 1}),
      [values]
    )
  }

  doFilterByTopVip(e) {
    const {topVipFilter} = this.state
    this.props.uiDispatch(
      'Filter documents by VIP',
      (state, arg) => ({...state, topVipFilter: e.value, page: 1}),
      [topVipFilter]
    )
  }

  doFilterByHasPendingWithdrawals(e) {
    const {hasPendingWithdrawalsFilter} = this.state
    this.props.uiDispatch(
      'Filter documents by Has Pending Withdrawals',
      (state, arg) => ({...state, hasPendingWithdrawalsFilter: e.value, page: 1}),
      [hasPendingWithdrawalsFilter]
    )
  }

  activateSalesAgentFilter(value) {
    this.props.uiDispatch(
      'Filter documents by sales agents',
      (state, arg) => ({...state, documentSalesAgentFilter: value, page: 1}),
      [value]
    )
  }

  assigneeFilter(value) {
    this.props.uiDispatch(
      'Filter documents by assignee',
      (state, arg) => ({...state, assigneeFilter: value, page: 1}),
      [value]
    )
  }

  filterProfileChangesByCountry = ({value}) => {
    this.props.uiDispatch(
      'Filter profile changes by country',
      (state) => ({...state, profileChangeCountry: value, page: 1})
    )
  }

  doDocumentSearch(searchText) {
    this.props.uiDispatch(
      'Search Documents',
      (state, arg) => ({...state, documentSearch: arg, page: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  getStatusFilter() {
    const {show, documentStatus, profileChangeStatus, appropTestStatus, dueDiligenceStatus, priorityProfileChange} = this.props
    let statusFilter
    switch (show) {
      case showEnum.documents.key:
        statusFilter = (
          <ButtonGroup>
            {this.renderDocumentStatusButton('', 'All', documentStatus)}
            {Object.values(documentStatuses).map((status) => (
              this.renderDocumentStatusButton(status.key, status.label, documentStatus)
            ))}
          </ButtonGroup>
        )
        break
      case showEnum.profileChanges.key:
        statusFilter = (
          <div className={style.inlineBlock}>
            <ButtonGroup key="profileChangeStatus" className="mr-3">
              {this.renderProfileChangeStatusButton('', 'All', profileChangeStatus)}
              {Object.values(profileChangeStatuses).map((status) => (
                this.renderProfileChangeStatusButton(status.key, status.label, profileChangeStatus)
              ))}
            </ButtonGroup>
            <ButtonGroup key="important">
              <Button
                id="t-documents-toolbar-filter-profile-changes-important"
                size="sm"
                variant="outline-secondary"
                active={priorityProfileChange}
                onClick={this.togglePrioritizeProfileChange}
              >Prioritize Firstname, Lastname, or DOB</Button>
            </ButtonGroup>
          </div>
        )
        break
      case showEnum.appropTests.key:
        statusFilter = (
          <ButtonGroup>
            {this.renderAppropTestStatusButton('', 'All', appropTestStatus)}
            {Object.values(appropTestStatuses).map((status) => (
              this.renderAppropTestStatusButton(status.key, status.label, appropTestStatus)
            ))}
          </ButtonGroup>
        )
        break
      case showEnum.dueDiligences.key:
        statusFilter = (
          <ButtonGroup>
            {this.renderDueDiligenceStatusButton('', 'All', dueDiligenceStatus)}
            {Object.values(dueDiligenceStatuses).map((status) => (
              this.renderDueDiligenceStatusButton(status.key, status.label, dueDiligenceStatus)
            ))}
          </ButtonGroup>
        )
        break
      default:
        console.warn('Unexpected show property ', show) // eslint-disable-line no-console
        return
    }
    return statusFilter
  }

  showPartnersAgentFilter(partnerAgents, partnersAgentFilter, showLabel = false) {
    return (<Col xs={2}>
      {showLabel && <span className={style.label}>Partners Agent</span>}
      <SelectAgent
        id="t-documents-page-partners-agent-filter"
        agents={partnerAgents}
        value={partnersAgentFilter}
        placeholderText="All Partners Agents"
        optionsPrefix={[{value: '', label: 'All Partners Agents'}]}
        onChange={({value}) => this.props.uiDispatch(
          'Filter by partners agent',
          (state, arg) => ({...state, partnersAgentFilter: value, page: 1}),
          [value]
        )}
      />
    </Col>)
  }

  renderGenericFilters() {
    const {viewer, agents, countriesFilterValue, documentSalesAgentFilter, companyFilter,
      partnersAgentFilter, clientTypesFilter, topVipFilter} = this.props
    const {dateFilters} = this.state
    const companyOptions = [
      {label: 'All Companies', value: undefined},
      ...StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer)),
    ]

    const activeAgents = filter(agents, (agent) => agent.isActive)
    const partnerAgents = filter(activeAgents, (agent) =>
      some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)))

    return (
      <div key="div-generic-filters">
        <Row className={style.toolbar} key="documents-filters-generic-row-1">
          <Col xs={3}>
            <StylishSelect
              id="t-documents-filter-country"
              placeholderText="All Countries"
              value={countriesFilterValue}
              options={countriesAndRegionsOptions}
              highlightIfActive
              onChange={this.activateCountryRegionFilter.bind(this)}
            />
          </Col>
          <Col xs={3}>
            <StylishSelect
              id="t-documents-company-filter"
              placeholderText="All Companies"
              value={companyFilter}
              options={companyOptions}
              highlightIfActive
              onChange={(e) => this.doFilterByCompany(e.value)}
            />
          </Col>
          <Col xs={3}>
            <SelectAgentFilter
              viewer={viewer}
              agents={activeAgents}
              onChange={(target) => this.activateSalesAgentFilter(target.value)}
              value={documentSalesAgentFilter}
            />
          </Col>
          <Col xs={3}>
            <SelectAgent
              id="t-documents-page-partners-agent-filter"
              agents={partnerAgents}
              value={partnersAgentFilter}
              placeholderText="All Partners Agents"
              optionsPrefix={[{value: '', label: 'All Partners Agents'}]}
              onChange={({value}) => this.props.uiDispatch(
                'Filter by partners agent',
                (state, arg) => ({...state, partnersAgentFilter: value, page: 1}),
                [value]
              )}
            />
          </Col>
        </Row>
        <Row className={style.toolbar} key="documents-filters-generic-row-2">
          <Col xs={3}>
            <div>
              <span className={style.label}>Client Type</span>
              <StylishSelect
                id="t-client-types-filter"
                placeholderText="All Client Types"
                value={clientTypesFilter}
                options={StylishSelect.enumToStylishOptions(clientTypes)}
                highlightIfActive
                multi
                clearable
                onChange={(e) => this.doFilterByClientTypes(e)}
              />
            </div>
          </Col>
          <Col xs={3}>
            <div>
              <span className={style.label}>Vip</span>
              <StylishSelect
                id="t-deposits-top-vip-filter"
                placeholderText="All Clients"
                value={topVipFilter}
                options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                highlightIfActive
                onChange={(e) => this.doFilterByTopVip(e)}
              />
            </div>
          </Col>
          <Col xs={3}>
            <span className={style.label}>Created From</span>
            <DateTime
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('createdFrom', e)}
              value={dateFilters.createdFrom}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              id="t-documents-filter-created-from"
              inputProps={{
                placeholder: 'Created From'
              }}
            />
          </Col>
          <Col xs={3}>
            <span className={style.label}>Created To</span>
            <DateTime
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('createdTo', e)}
              value={dateFilters.createdTo}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              id="t-documents-filter-created-to"
              inputProps={{
                placeholder: 'Created To'
              }}
            />
          </Col>
        </Row>
      </div>
    )
  }

  renderDocumentsFilters() {
    const {depositorsFilter, documentTypesFilter, tradingStatusesFilter, kycStatusFilter, assigneeFilter,
      hasPendingWithdrawalsFilter, viewer, agents, documentSearch} = this.props
    const {searchText, dateFilters} = this.state

    const allAgents = [
      {label: 'All Users', value: undefined},
      {value: -1, label: 'Unassigned'},
    ]

    const showAllAssignees = some(viewer.roles, (r) => ['admin', 'super_user'].includes(r))
    const assignees = showAllAssignees ? agents : filter(agents, (a) => a.id === viewer.id)

    return (
      <div key="div-documents">
        <Row className={style.toolbar} key="documents-filters-documents">
          <Col xs={2}>
            <span className={style.label}> &nbsp;</span>
            <InputGroup>
              <Form.Control
                type="text"
                value={searchText !== undefined ? searchText : documentSearch || ''}
                placeholder="Search by ID"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doDocumentSearch(searchText)) ||
                      (e.key === 'Escape' && this.doDocumentSearch(''))
                )}
              />
              <InputGroup.Append>
                {documentSearch && <Button
                  key={1}
                  title="Clear"
                  variant={documentSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doDocumentSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={documentSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doDocumentSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={2}>
            <span className={style.label}>Trading Status</span>
            <StylishSelect
              id="t-trading-statuses-filter"
              className={style.tradingStatus}
              placeholderText="All Trading Statuses"
              value={tradingStatusesFilter}
              options={StylishSelect.enumToStylishOptions(tradingStatuses)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByTradingStatuses(e)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}>KYC Status</span>
            <StylishSelect
              id="t-documents-kyc-status-filter"
              placeholderText="All KYC Statuses"
              value={kycStatusFilter}
              options={StylishSelect.enumToStylishOptions(kycStatuses)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByKycStatus(e)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}>Depositors</span>
            <StylishSelect
              id="t-documents-depositors-filter"
              placeholderText="All Clients"
              value={depositorsFilter}
              options={depositorsSelectOptions}
              highlightIfActive
              onChange={(e) => this.doFilterByDepositors(e.value)}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}> Expiration From </span>
            <DateTime
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('expirationFrom', e)}
              onFocus={() => this.doDateFilterSearch.cancel()}
              value={dateFilters.expirationFrom}
              closeOnSelect
              id="t-documents-filter-expiration-from"
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}> Expiration To </span>
            <DateTime
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('expirationTo', e)}
              onFocus={() => this.doDateFilterSearch.cancel()}
              value={dateFilters.expirationTo}
              closeOnSelect
              id="t-documents-filter-expiration-to"
            />
          </Col>
        </Row>
        <Row className={style.toolbar}>
          <Col xs={4}>
            <span className={style.label}>Document Types</span>
            <StylishSelect
              id="t-documents-types-filter"
              placeholderText="All Document Types"
              value={documentTypesFilter}
              options={StylishSelect.enumToStylishOptions(documentTypes)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByDocumentTypes(e)}
            />
          </Col>
          <Col xs={4}>
            <span className={style.label}>Assignee</span>
            <SelectAgent
              placeholderText={allUsers}
              agents={assignees}
              value={assigneeFilter}
              optionsPrefix={allAgents}
              onChange={(target) => this.assigneeFilter(target.value)}
            />
          </Col>
          <Col xs={4}>
            <div>
              <span className={style.label}>Has Pending / On Hold / Not Processed Withdrawals</span>
              <StylishSelect
                id="t-deposits-has-pending-withdrawals-filter"
                placeholderText=""
                value={hasPendingWithdrawalsFilter}
                options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                highlightIfActive
                onChange={(e) => this.doFilterByHasPendingWithdrawals(e)}
              />
            </div>
          </Col>
        </Row>
      </div>
    )
  }

  renderProfileChangesFilters() {
    return (
      <Row className={style.toolbar} key="documents-filters-profile-changes">
        <Col xs={3} className={style.mb10}>
          <StylishSelect
            placeholderText="All Countries"
            value={this.props.profileChangeCountry}
            options={StylishSelect.enumToStylishOptions(countries, 'All Countries')}
            highlightIfActive
            onChange={this.filterProfileChangesByCountry}
          />
        </Col>
      </Row>
    )
  }

  renderAppropTestsFilters() {
    const {kycStatusFilter} = this.props
    const {dateFilters} = this.state

    return (
      <Row className={style.toolbar} key="documents-filters-approp-tests">
        <Col xs={6}>
          <span className={style.label}>KYC Status</span>
          <StylishSelect
            id="t-approp-tests-kyc-status-filter"
            placeholderText="All KYC Statuses"
            value={kycStatusFilter}
            options={StylishSelect.enumToStylishOptions(kycStatuses)}
            highlightIfActive
            multi
            clearable
            onChange={(e) => this.doFilterByKycStatus(e)}
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}>Last Updated</span>
          <DateTime
            timeFormat={false}
            onChange={(e) => this.handleDateFilterChanged('lastUpdated', e)}
            onFocus={() => this.doDateFilterSearch.cancel()}
            value={dateFilters.lastUpdated}
            closeOnSelect
            id="t-approp-tests-filter-last-updated"
          />
        </Col>
      </Row>
    )
  }

  renderDueDiligencesFilters() {
    const {dateFilters} = this.state

    return (
      <Row className={style.toolbar} key="documents-filters-due-diligences">
        <Col xs={2}>
          <span className={style.label}>Last Updated</span>
          <DateTime
            timeFormat={false}
            onChange={(e) => this.handleDateFilterChanged('lastUpdated', e)}
            onFocus={() => this.doDateFilterSearch.cancel()}
            value={dateFilters.lastUpdated}
            closeOnSelect
            id="t-approp-tests-filter-last-updated"
          />
        </Col>
      </Row>
    )
  }

  renderFilters() {
    const {show} = this.props
    const isDisplayingDocuments = show === showEnum.documents.key
    const isDisplayingAppropTests = show === showEnum.appropTests.key
    const isDisplayingDueDiligences = show === showEnum.dueDiligences.key
    const isDisplayingProfileChanges = show === showEnum.profileChanges.key

    return ([
      this.renderGenericFilters(),
      isDisplayingDocuments && this.renderDocumentsFilters(),
      isDisplayingProfileChanges && this.renderProfileChangesFilters(),
      isDisplayingAppropTests && this.renderAppropTestsFilters(),
      isDisplayingDueDiligences && this.renderDueDiligencesFilters(),
    ])
  }

  allDocumentIds() {
    const result = {}
    this.props.documents.forEach((d) => {result[d.id] = true})
    return result
  }

  toggleDocumentSelectionHeader(selectedDocumentsHeader) {
    const selectedDocuments = selectedDocumentsHeader ? this.allDocumentIds() : {}

    this.props.uiDispatch(
      'Check/Uncheck documents',
      (state) => ({...state, selectedDocuments, selectedDocumentsHeader})
    )
  }

  toggleDocumentSelection(document) {
    this.props.uiDispatch(
      'Check/Uncheck document',
      (state) => {
        const selectedDocuments = {...state.selectedDocuments}

        if (selectedDocuments[document.id]) delete selectedDocuments[document.id]
        else selectedDocuments[document.id] = true

        return ({
          ...state,
          selectedDocuments,
        })
      }
    )
  }

  onAssignSelectedDocuments = (showAssignDocuments) => {
    this.props.uiDispatch(
      'Show/Hide assign documents modal',
      (state) => ({...state, showAssignDocuments})
    )
  }

  onChangeStatusForSelectedDocuments = (showChangeStatus) => {
    this.props.uiDispatch(
      'Show/Hide change documents status modal',
      (state) => ({...state, showChangeStatus})
    )
  }

  render() {
    const {documents, documentsCount, profileChanges, profileChangesCount, appropTests, appropTestsCount, show,
      page, agents, actions, viewer, dueDiligences, dueDiligencesCount, showAssignDocuments,
      selectedDocuments, showChangeStatus
    } = this.props
    let listComponent, count
    switch (show) {
      case showEnum.documents.key:
        listComponent = <ListDocuments
          documents={documents}
          agents={agents}
          actions={actions}
          viewer={viewer}
          selectedDocuments={this.props.selectedDocuments}
          documentsProvider={this.context.documentsProvider}
          selectedDocumentsHeader={this.props.selectedDocumentsHeader}
          toggleDocumentSelection={(document) => this.toggleDocumentSelection(document)}
          toggleDocumentSelectionHeader={(checked) => this.toggleDocumentSelectionHeader(checked)}
        />
        count = documentsCount
        break
      case showEnum.profileChanges.key:
        listComponent = <ListProfileChanges profileChanges={profileChanges} />
        count = profileChangesCount
        break
      case showEnum.appropTests.key:
        listComponent = <ListAppropTests appropTests={appropTests} />
        count = appropTestsCount
        break
      case showEnum.dueDiligences.key:
        listComponent = <ListDueDiligences dueDiligences={dueDiligences} />
        count = dueDiligencesCount
        break
      default:
        console.warn('Unexpected show property ', show) // eslint-disable-line no-console
        return
    }
    const statusFilter = this.getStatusFilter()
    return (
      <Container>
        {this.renderFilters()}
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <div>
                  <Row>
                    <Col md={12} className={style.mb10}>
                      <ButtonGroup size="sm">
                        {Object.values(showEnum).map(({key, label}) => (
                          this.renderShowButton(key, label, show)
                        ))}
                      </ButtonGroup>
                    </Col>
                    <Col md={12} className={style.mb10}>
                      {statusFilter}
                    </Col>
                  </Row>
                </div>
                {canAssignDocument(viewer) && show === showEnum.documents.key && <div>
                  <DropdownButton
                    variant="outline-secondary"
                    title="Actions"
                    key="dropdown"
                    size="sm"
                    id="t-documents-more-actions"
                  >
                    <Dropdown.Item
                      id="t-assign-documents"
                      onClick={() => this.onAssignSelectedDocuments(true)}
                    >Assign Documents</Dropdown.Item>
                    <Dropdown.Item
                      id="t-change-documents-status"
                      onClick={() => this.onChangeStatusForSelectedDocuments(true)}
                    >Change Status</Dropdown.Item>
                  </DropdownButton>
                  <br /><br />
                </div>}
                {listComponent}
              </Card.Body>
            </Card>
            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, page: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(page, getPageCount(count)).map((p) => {
                    if (p === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={p}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, page: page - 1})
                        )}
                      />
                    }

                    if (p === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={p}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, page: page + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={p === page}
                      key={p}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, page: p})
                      )}
                    >
                      {p}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
        <AssignDocumentsModal
          show={showAssignDocuments}
          onHide={() => this.onAssignSelectedDocuments(false)}
          notify={(level, message) => this.context.showNotification({
            message,
            level,
            autoDismiss: 5,
          })}
          selectedDocuments={selectedDocuments}
          selectedDocumentsCount={size(selectedDocuments)}
          documentsProvider={this.context.documentsProvider}
        />
        <ChangeDocumentsStatusModal
          show={showChangeStatus}
          onHide={() => this.onChangeStatusForSelectedDocuments(false)}
          notify={(level, message) => this.context.showNotification({
            message,
            level,
            autoDismiss: 5,
          })}
          selectedDocuments={selectedDocuments}
          selectedDocumentsCount={size(selectedDocuments)}
          documentsProvider={this.context.documentsProvider}
          afterSave={() => this.toggleDocumentSelectionHeader(false)}
        />
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryDocuments),

  uiMount(() => ['documentsUi']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for documentStatus and appropTestStatus',
      (state) => {
        const dateFilters = state && state.dateFilters
        return merge({
          show: showEnum.documents.key,
          documentStatus: documentStatuses.notProcessed.key,
          profileChangeStatus: profileChangeStatuses.pending.key,
          appropTestStatus: appropTestStatuses.pending.key,
          dueDiligenceStatus: dueDiligenceStatuses.underReview.key,
          dateFilters,
          priorityProfileChange: false,
        }, state)
      }
    )
  }),

  provideProps((state, uiState) => {
    const {documents, documentsCount, profileChanges, profileChangesCount, appropTests, appropTestsCount,
      agents, dueDiligences, dueDiligencesCount, documentsTopVipCount} = state
    const {show, documentStatus, profileChangeStatus, appropTestStatus, countriesFilter, countriesFilterValue,
      page, companyFilter, documentTypesFilter, documentSalesAgentFilter, dateFilters, clientTypesFilter,
      depositorsFilter, kycStatusFilter, topVipFilter, tradingStatusesFilter, assigneeFilter,
      dueDiligenceStatus, selectedDocuments, selectedDocumentsHeader, showAssignDocuments, showChangeStatus,
      partnersAgentFilter, hasPendingWithdrawalsFilter, priorityProfileChange, profileChangeCountry,
      documentSearch
    } = uiState

    return {
      // data`
      documents,
      documentsCount,
      profileChanges,
      profileChangesCount,
      appropTests,
      appropTestsCount,
      agents,
      dueDiligences,
      dueDiligencesCount,
      documentsTopVipCount,
      // filters
      show,
      documentStatus,
      profileChangeStatus,
      appropTestStatus,
      countriesFilter,
      countriesFilterValue,
      companyFilter,
      kycStatusFilter,
      dateFilters,
      documentTypesFilter,
      tradingStatusesFilter,
      documentSalesAgentFilter,
      clientTypesFilter,
      depositorsFilter,
      topVipFilter,
      hasPendingWithdrawalsFilter,
      assigneeFilter,
      dueDiligenceStatus,
      selectedDocuments: selectedDocuments || {},
      selectedDocumentsHeader,
      showAssignDocuments,
      showChangeStatus,
      partnersAgentFilter,
      priorityProfileChange,
      profileChangeCountry,
      page: page || 1,
      documentSearch
    }
  }),

  mountDataProviders({documentsProvider, documentsTopVipCountProvider}),
)(Documents)
