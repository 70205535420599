import React from 'react'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {events} from '../enums'
import style from './clients.module.scss'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {safeLinkToClient} from '../utils/links'
import {canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'

export default class MaskedField extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }
  static contextTypes = {
    router: PropTypes.object.isRequired,
    clientsProvider: PropTypes.object.isRequired,
  };

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  componentWillMount() {
    this.setInitialState()
  }

  setInitialState() {
    this.setState({
      email: false,
      phone: false,
    })
  }

  fetchProvider() {
    this.context.clientsProvider.fetch()
  }

  trackCopyEvent(clientId, field) {
    this.props.actions.client.trackCopyEvent(clientId, field).then(() => true)
  }

  revealPhone(clientId) {
    if (this.state.phoneRevealed) {
      return
    }
    this.props.actions.client.revealPhone(clientId).then((phones) => {
      const phone = get(phones, 'phone')
      phone && this.setState({phone, phoneRevealed: true})
    })
  }

  revealEmail(clientId) {
    if (this.state.emailRevealed) {
      return
    }
    this.props.actions.client.revealEmail(clientId).then((clientEmails) => {
      clientEmails && this.setState({
        email: clientEmails.email,
        emailRevealed: true,
      })
    })
  }

  render() {
    const {viewer, column, client, column: {key}} = this.props
    const canRequestRevealEmailRoles = ['super_user', 'view_client_email']
    const hideRevealButton =
      canRequestRevealEmailRoles && viewer.roles.some((r) => canRequestRevealEmailRoles.includes(r))
    let value = key === 'email' && this.state.email ?
      (<a href={`mailto:${this.state.email}`}>{this.state.email}</a>) :
      key === 'phone' && this.state.phone ?
        (<a href={`tel:${this.state.phone}`}>{this.state.phone}</a>) :
        column.resolve ? column.resolve(client) : client[key]

    if (!column.containsLink) {
      value = safeLinkToClient(viewer, value, client)
    }

    return (
      <div>
        <span onCopy={() => {this.trackCopyEvent(client.id, key)}}>
          {value}
        </span>
        { value !== null && (!hideRevealButton) && !canQueryAffiliateManagerClients(viewer) &&
          <span>
            <FontAwesomeIcon
              icon={'eye'}
              className={style.eyeIcon}
              onClick={
                () => key === 'email' ? this.revealEmail(client.id) :
                  key === 'phone' ? this.revealPhone(client.id) :
                    () => true
              }
            />
          </span>
        }
      </div>
    )
  }
}
