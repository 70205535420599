import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {merge, debounce, get, pickBy, filter} from 'lodash'
import {yesNo, activityLogTypes} from '@bdswiss/common-enums'
import {Row, Col, Table, Button, Card, Pagination, Form, Container, InputGroup} from 'react-bootstrap'
import {events} from '../enums'
import style from './notifications.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {notificationsProvider} from './providers'
import {safeLinkClientDetails} from '../utils/links'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {countriesAndRegionsOptions} from '../utils/country'
import {getAllowedCompanies, replaceParameters} from '../utils/general'
import {getFullName, getPageCount, readableDate, isConverted, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch} from '../decorators'

class Notifications extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    notificationsProvider: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {
      createdFrom: moment().subtract(14, 'days'),
    }
    this.setState({dateFilters: stateDateFilters})
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.notificationsProvider.fetch()
  }

  doSearch(searchText) {
    this.props.uiDispatch(
      'Search notifications',
      (state, arg) => ({...state, notificationsSearch: arg, notificationsPage: 1}),
      [searchText]
    )
    this.setState({searchText})
  }

  doFilterByCompany(company) {
    this.props.uiDispatch(
      'Filter notifications by company',
      (state, arg) => ({...state, companyFilter: company, notificationsPage: 1}),
      [company]
    )
  }

  doFilterByType(selections) {
    const values = selections && selections.length > 0 && selections.map((v) => v.value)
    this.props.uiDispatch(
      'Filter notifications by type',
      (state, arg) => ({...state, notificationTypeFilter: values, notificationsPage: 1}),
      [values]
    )
  }

  handleDateFilterChanged(name, momentValue, value) {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })

    if (!momentValue || moment.isMoment(momentValue)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilters} = this.state
    this.props.uiDispatch(
      'Filter notifications by dates',
      (state, arg) => ({...state, dateFilters, page: 1}),
      [dateFilters]
    )
  }

  doFilterByCountry(e) {
    const {countryFilter} = this.state
    this.props.uiDispatch(
      'Filter notifications by country or region',
      (state, arg) => ({...state, countryFilter: e.value, page: 1}),
      [countryFilter]
    )
  }

  doFilterByTopVip(e) {
    const {topVipFilter} = this.state
    this.props.uiDispatch(
      'Filter VIP',
      (state, arg) => ({...state, topVipFilter: e.value, page: 1}),
      [topVipFilter]
    )
  }

  getExtraMessage(notification) {
    if ([activityLogTypes.clientHasSamePhoneNumber.key, activityLogTypes.clientHasSameLastnameAndDob.key]
      .includes(notification.type)
    ) {
      return (get(notification, 'otherUsers') || []).join(', ')
    }

    return ''
  }

  renderNotification(notification) {
    const {id, client, createdAt, type, otherUsersCount} = notification
    const clientName = getFullName(client)
    const activityLogMessage = activityLogTypes[type].activityLogMessage
    const placeholders = {otherUsersCount}
    const message = replaceParameters(activityLogMessage, placeholders)
    const extraMessage = this.getExtraMessage(notification)

    const clientConverted = isConverted(client.convertedAt)
    const registrationFromNow = clientConverted ?
      moment(client.registration).fromNow() : 'Not Registered'
    return (
      <tr
        key={id}
        id={`t-client-notifications-id-${id}`}
        className={classNames(['t-notification', client.topVip ? 'vip' : ''])}
      >
        <td>{safeLinkClientDetails(client, client.id)}</td>
        <td>{safeLinkClientDetails(client, clientName)}</td>
        <td>{safeLinkClientDetails(client, client.email)}</td>
        <td>{safeLinkClientDetails(client, registrationFromNow)}</td>
        <td>{safeLinkClientDetails(client, `${message} ${extraMessage}`)}</td>
        <td title={createdAt.fromNow()}>
          {safeLinkClientDetails(client, readableDate(createdAt))}
        </td>
      </tr>
    )
  }

  renderNotifications(notifications) {
    return notifications.map((n) => this.renderNotification(n))
  }

  render() {
    const {notifications, notificationsSearch, companyFilter, notificationsPage, notificationsCount,
      countryFilter, topVipFilter, notificationTypeFilter, viewer} = this.props

    const {searchText} = this.state || {}
    const companyOptions = [
      {label: 'All Companies', value: undefined},
      ...StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer))]

    const {dateFilters} = this.state
    const filteredNotifications = filter(notifications, (l) => l.client)

    return (
      <Container>
        <Row key="filter-row-1">
          <Col xs={4}>
            <span className={style.label}>&nbsp;</span>
            <InputGroup>
              <Form.Control
                type="text"
                value={searchText !== undefined ? searchText : notificationsSearch || ''}
                placeholder="Search by Client ID, Email"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doSearch(searchText)) ||
                  (e.key === 'Escape' && this.doSearch(''))
                )}
              />
              <InputGroup.Append>
                {notificationsSearch && <Button
                  key={1}
                  title="Clear"
                  variant={notificationsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={notificationsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          {viewer.companies.length > 1 &&
            <Col xs={2}>
              <span className={style.label}>&nbsp;</span>
              <StylishSelect
                id="t-notifications-company-filter"
                placeholderText="All Companies"
                value={companyFilter}
                options={companyOptions}
                highlightIfActive
                onChange={(e) => this.doFilterByCompany(e.value)}
              />
            </Col>
          }
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-notifications-country-filter"
              placeholderText="All Countries"
              value={countryFilter}
              options={countriesAndRegionsOptions}
              highlightIfActive
              onChange={(e) => this.doFilterByCountry(e)}
            />
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}> Created From </span>
              <DateTime
                id="t-notifications-created-from-filter"
                timeFormat={false}
                onChange={(e) => this.handleDateFilterChanged('createdFrom', e, moment.isMoment(e) ? e.format('YYYY-MM-DD') : '')}
                value={dateFilters.createdFrom}
                onFocus={() => this.doDateFilterSearch.cancel()}
                closeOnSelect
                className={style.datetime}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Vip</span>
              <StylishSelect
                id="t-notifications-top-vip-filter"
                placeholderText="All Clients"
                value={topVipFilter}
                options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                highlightIfActive
                onChange={(e) => this.doFilterByTopVip(e)}
              />
            </div>
          </Col>
        </Row>
        <Row key="filter-row-2">
          <Col xs={4}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-notifications-types-filter"
              placeholderText="Notifications"
              value={notificationTypeFilter}
              options={StylishSelect.enumToStylishOptions(pickBy(activityLogTypes, {isNotification: true}))}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByType(e)}
            />
            <span className={style.label}>&nbsp;</span>
          </Col>
        </Row>

        <Row>&nbsp;</Row>
        <Row>
          <Col xs={12}>
            <Card className="two-rows-filter">
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Client ID</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Signed Up</th>
                      <th>Notification</th>
                      <th>Created At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderNotifications(filteredNotifications)}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-5">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, notificationsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(notificationsPage, getPageCount(notificationsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, notificationsPage: notificationsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, notificationsPage: notificationsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === notificationsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, notificationsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'notifications']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/notifications',
    (state) => {
      let dateFilters = state && state.dateFilters
      if (!dateFilters) {
        dateFilters = {createdFrom: moment().subtract(14, 'days').startOf('day').format('YYYY-MM-DD')}
      }

      return merge({
        dateFilters,
      }, state)
    })
  ),

  provideProps((state, uiState) => {
    const {notifications, notificationsCount} = state
    const {companyFilter, countryFilter, notificationsSearch, notificationsPage, topVipFilter,
      notificationTypeFilter,
    } = uiState
    let {dateFilters} = uiState
    if (!dateFilters) {
      dateFilters = {
        createdFrom: moment().subtract(14, 'days').format('YYYY-MM-DD'),
      }
    }
    return ({
      notifications,
      notificationsCount,
      notificationTypeFilter,
      companyFilter,
      countryFilter,
      notificationsSearch,
      topVipFilter,
      notificationsPage: notificationsPage || 1,
      dateFilters,
    })
  }),

  mountDataProviders({notificationsProvider}),
)(Notifications)
