import {getFetchInterval, getPageSize, getOffset} from '../useful'
import get from 'lodash/get'

export const agentsCommissionsProvider = {
  fetchInterval: getFetchInterval,
  getQuery: (props) => {
    const {agentsCommissionsSort, agentsCommissionsFilter, agentsCommissionsPage} = props
    const condition = [
      agentsCommissionsFilter?.startDate && `startDate: "${agentsCommissionsFilter.startDate}"`,
      agentsCommissionsFilter?.endDate && `endDate: "${agentsCommissionsFilter.endDate}"`,
    ].filter(condition => condition).join('\n').trim()

    return `{
        agentsCommissions (
          limit: ${getPageSize()}
          offset: ${getOffset(agentsCommissionsPage)}
          ${agentsCommissionsSort?.orderBy ? `orderBy: ${agentsCommissionsSort.orderBy}` : ''}
          ${agentsCommissionsSort?.orderDirection ? `orderDirection: ${agentsCommissionsSort.orderDirection}` : ''}
          ${condition}
        ) {
          rows {
            agentId
            agentName
            clientsCount
            commissionSize
            ftdAmount
            ftdBonus
            qualifiedFTDs
            unqualifiedFTDs
          }
          info {
            size
          }
       }
    }`
  },
  onData: (res, dispatch) => dispatch(
    'Agents commissions loaded',
    (state, res) => ({
      ...state,
      agentsCommissions: get(res, 'agentsCommissions', []),
      agentsCommissionsCount: get(res, 'agentsCommissionsCount', 0),
    }),
    [res]
  ),
}

export const agentCommissionsProvider = {
  fetchInterval: getFetchInterval,
  getQuery: (props) => {
    const {agentCommissionsSort, agentCommissionsPage} = props
    const condition = [
      props.match?.params?.startDate && `startDate: "${props.match.params.startDate}"`,
      props.match?.params?.endDate && `endDate: "${props.match.params.endDate}"`,
      props.match?.params?.showGoodOnly && 'goodOnly: true',
    ].filter(condition => condition).join('\n').trim()
    return `{
        agentCommissions (
          agentId: ${props.match.params.agentId}
          limit: ${getPageSize()}
          offset: ${getOffset(agentCommissionsPage)}
          ${agentCommissionsSort?.orderBy ? `orderBy: ${agentCommissionsSort.orderBy}` : ''}
          ${agentCommissionsSort?.orderDirection ? `orderDirection: ${agentCommissionsSort.orderDirection}` : ''}
          ${condition}
        ) {
          rows {
            client
            clientId
            clientCompany
            callStarted
            callDuration
            callEffective
            ftdAmount
            ftdBonus
            ftdDate
            ftdId
            ftdQualified
          }
          info {
            size
            agentName
          }
        }
    }`
  },
  onData: (res, dispatch) => dispatch(
    'Agent commissions loaded',
    (state, res) => ({
      ...state,
      agentCommissions: get(res, 'agentCommissions.rows', []),
      agentCommissionsCount: get(res, 'agentCommissions.info.size', 0),
      agentName: get(res, 'agentCommissions.info.agentName','')
    }),
    [res]
  ),
}
