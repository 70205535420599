export default function create(dbClient) {
  return {
    dismissAppointment(id) {
      const variables = {
        id,
        isDismissed: true,
      }

      const query = `
        mutation (
          $id: Int,
          $isDismissed: Boolean,
        ) {
          upsertAppointment (
            id: $id,
            isDismissed: $isDismissed,
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    dismissAppointmentNotification(id) {
      const variables = {
        id,
      }

      const query = `
        mutation (
          $id: Int!,
        ) {
          dismissAppointmentNotification (
            id: $id,
          )
        }
      `

      return dbClient.query(query, variables)
    },
  }
}
