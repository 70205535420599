import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import LeadForm from './LeadForm'
import {clientTypes} from '@bdswiss/common-enums'

export default class LeadAdd extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  cancel() {
    this.props.onCancel()
  }

  save(values) {
    const params = {
      ...values,
    }
    let result = {}
    this.props.actions.addLead(
      {
        email: params.email.trim(),
        firstName: params.firstName.trim(),
        lastName: params.lastName.trim(),
        phone: params.phone.trim(),
        clientType: params.clientType,
        salesAgentId: this.props.salesAgentId,
        company: params.company,
        skypeId: params.skypeId,
        website: params.website,
        country: params.country,
        otherCompany: params.otherCompany,
        title: params.title,
        bdswissPartner: params.bdswissPartner,
        priority: params.priority,
      }
    ).then((res) => {
      result = {newLeadId: res.createLead.id, success: true}
      this.props.onSave(result)
    }).catch((e) => {
      this.context.logError(e)
      this.props.onSave({success: false})
    })
  }

  trySave(params) {
    let {email, company} = params
    email = email.trim()
    this.props.actions.checkEmailInUse({email, checkConverted: false, company}).then((res) => {
      if (res.emailInUse) {
        const message = 'Duplicate email, possibly lead exists'
        this.props.onSave({message, success: false})
      } else {
        this.save(params)
      }
    }).catch((e) => {
      this.context.logError(e)
      this.props.onSave({success: false})
    })
  }

  render() {
    return (
      <LeadForm
        onCancel={this.cancel.bind(this)}
        onSave={(params) => this.trySave(params)}
        leadTypes={clientTypes}
        agents={this.props.agents}
        companies={this.props.companies}
      />
    )
  }
}
