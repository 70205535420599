import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {debounce, map} from 'lodash'
import {compose, mountDataProviders, provideProps, uiMount} from '../../decorators'
import StylishSelect from '../../components/StylishSelect'
import {campaignsProvider} from './providers'

class CampaignSelectUI extends Component {
  static contextTypes = {
    campaignsProvider: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.doSearch = debounce(this.doSearch, 1500)
  }

  doSearch(searchText) {
    if (!searchText) {
      return
    }
    this.props.uiDispatch(
      'Search campaigns',
      (state) => ({...state, campaignSearch: searchText, campaignId: undefined}),
      [searchText]
    )
  }

  handleChange(selectedObject) {
    this.props.uiDispatch(`setting campaign to ${selectedObject.value}`, (state) => ({...state, campaignId: selectedObject.value}))
  }

  render() {
    const {campaignList, campaignId} = this.props
    return (
      <StylishSelect
        id="t-affiliate"
        placeholder="Please input"
        options={campaignList}
        value={campaignId}
        onInputChange={this.doSearch.bind(this)}
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'affiliateAssign']),
  provideProps((state, uiState) => {
    const {campaignList} = state
    const {campaignSearch, campaignId} = uiState
    return {
      campaignSearch,
      campaignId,
      campaignList: map(campaignList, (a) => ({
        value: a.id,
        label: `${a.id} ${a.name}`,
      })),
    }
  }),
  mountDataProviders({campaignsProvider})
)(CampaignSelectUI)
