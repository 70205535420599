import React from 'react'
import PropTypes from 'prop-types'
import {get, has, isEmpty, map, omit, some} from 'lodash'
import {Modal, Col, Row, Button, Form} from 'react-bootstrap'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import {validateNonempty} from './validation'
import {bankDetailsFields} from '../common/utils'
import style from '../components/components.module.scss'

class AddBankDetailsModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    addBankDetails: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.bankDetails) {
      this.setDefaultState()
    } else {
      this.setState({
        values: {
          clientAccountCurrency: newProps.bankDetails.clientAccountCurrency,
          recipient: newProps.bankDetails.recipient,
          iban: newProps.bankDetails.iban,
          swift: newProps.bankDetails.swift,
          swiftBic: newProps.bankDetails.swiftBic,
          bank: newProps.bankDetails.bank,
          correspondentBank: newProps.bankDetails.correspondentBank,
          address: newProps.bankDetails.address,
          city: newProps.bankDetails.city,
          country: newProps.bankDetails.country,
          creditingAccount: newProps.bankDetails.creditingAccount,
          currency: newProps.bankDetails.currency,
          reference: newProps.bankDetails.reference,
        },
        isTouched: false,
        errors: {},
      })
    }
  }

  setDefaultState() {
    this.setState({
      values: {
        clientAccountCurrency: '',
        recipient: '',
        iban: '',
        swift: '',
        swiftBic: '',
        bank: '',
        correspondentBank: '',
        address: '',
        city: '',
        country: '',
        creditingAccount: '',
        currency: '',
        reference: '',
      },
      isTouched: false,
      errors: {},
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  validateDetails() {
    const {paymentOption, bankDetails} = this.props
    const {values} = this.state
    const errors = {}
    for (const key of Object.keys(values)) {
      validateNonempty(values, key, errors)
      if (key === bankDetailsFields.clientAccountCurrency.key) {
        if (some(paymentOption.bankDetails, (d) => d.clientAccountCurrency === values[key]) &&
          values[key] !== bankDetails[key]
        ) {
          errors[key] = 'Bank Details for this currency already set up'
        }
      }
    }
    return errors
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    this.setState({
      values,
      isTouched: true,
      errors: {
        ...omit(this.state.errors, key),
        ...validateNonempty(values, key, {}),
      }
    })
  }

  renderErrorMessage(key) {
    const {errors} = this.state
    if (has(errors, key)) {
      return (
        <p className="help-block"> {get(errors, key)} </p>
      )
    }
  }

  render() {
    const {onHide, show, addBankDetails} = this.props

    return (
      <Modal
        id="t-bank-details-option-modal"
        keyboard
        show={show}
        onHide={onHide}
        className={style.modal}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Bank Wire Details
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              {map(bankDetailsFields, (field) => (
                <Form.Group key={field.key}>
                  <Form.Label>{field.label}</Form.Label>
                  <Form.Control
                    id={`t-bank-details-option-${field.key}`}
                    type="text"
                    tabIndex={1}
                    value={this.state.values[field.key]}
                    isInvalid={this.state.errors[field.key]}
                    onChange={(e) => this.formValueChanged(field.key, e.target.value)}
                  />
                  {this.renderErrorMessage(field.key)}
                </Form.Group>
              ))}
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-bank-details-option-cancel"
            variant="outline-secondary"
            className="mr-3"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-bank-details-option-add"
            variant="success"
            disabled={!this.state.isTouched}
            onClick={() => {
              const errors = this.validateDetails()
              if (isEmpty(errors)) {
                addBankDetails(this.state.values)
              } else {
                this.setState({errors})
              }
            }}
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default provideProps()(AddBankDetailsModal)
