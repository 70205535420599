import React from 'react'
import Tree from 'react-json-tree'
import PropTypes from 'prop-types'
import {accountTypes} from '@bdswiss/common-enums'
import {get, reduce, map, omitBy, isNil} from 'lodash'
import {Modal, Button, ButtonToolbar, Row, Col, Card} from 'react-bootstrap'
import style from './components.module.scss'
import StylishSelect from './StylishSelect'
import PureComponent from '../PureComponent'
import {getAccountLabel, getWithdrawalVendorLabel} from '../utils/general'

const theme = {
  base00: '#1d1f21',
  base01: '#282a2e',
  base02: '#373b41',
  base03: '#969896',
  base04: '#b4b7b4',
  base05: '#c5c8c6',
  base06: '#e0e0e0',
  base07: '#ffffff',
  base08: '#CC342B',
  base09: '#F96A38',
  base0A: '#FBA922',
  base0B: '#198844',
  base0C: '#3971ED',
  base0D: '#3971ED',
  base0E: '#A36AC7',
  base0F: '#3971ED',
}

export default class WithdrawalsSummaryModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
  };

  constructor(props) {
    super(props)

    const accounts = map((props.accounts || []), (a) => ({
      value: a.id,
      label: `${getAccountLabel(accountTypes[a.__typename])} [${a.remoteId}]`
    }))
    this.state = {loading: false, accounts, data: {}, selectedAccountId: null}
  }

  onGetMethods(selectedAccountId) {
    this.setState({loading: true})
    this.props.actions.client.getWithdrawableMethods(selectedAccountId).then((res) => {
      const wdSummary = reduce(res.accounts, (result, account) => {
        const methods = map((account.withdrawablePaymentMethods || []), (m) => (omitBy({...m,
          vendor: getWithdrawalVendorLabel(m.vendor),
        }, isNil)))
        result[`${account.id} - ${account.remoteId}`] = methods || []
        return result
      }, {})
      this.setState({data: wdSummary, selectedAccountId, loading: false})
    }).catch(this.context.logError)
  }

  render() {
    const {show} = this.props
    const {data, accounts, selectedAccountId, loading} = this.state

    return (
      <Modal
        id="t-auto-process-withdrawal-modal"
        keyboard
        show={show}
        className={style.smallModalContent}
      >
        <Modal.Header>
          <strong>Withdrawals Summary</strong>
        </Modal.Header>
        <Modal.Body>
          {loading &&
            <i className={`fa fa-spinner fa-3x fa-spin ${style.spinner}`} title={'spinner'} />}
          {!loading && [
            <Row key='1' className="mb-2">
              <Col xs={8}>
                <span className={style.label}>&nbsp;</span>
                <StylishSelect
                  id="t-wd-summary-accounts-filter"
                  placeholderText="Choose Account"
                  value={selectedAccountId}
                  options={accounts}
                  highlightIfActive
                  onChange={(e) => this.onGetMethods(e.value)}
                />
              </Col>
            </Row>,
            <Row key='2'>
              <Col xs={12}>
                <Card className={style.jsonData}>
                  <Card.Body>
                    <Tree
                      data={data || {}}
                      theme={theme}
                      hideRoot
                      shouldExpandNode={(key, data, level) => get(data, 'amountLeft') > 0 || level === 1}
                    />
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          ]}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              key={1}
              variant="outline-secondary"
              size="sm"
              onClick={() => this.props.onHide()}
            >Exit</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
