/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import {getHocName} from '../useful'

export default (provider) => (Component) => class ProvidePropsComponent extends React.Component {

  static contextTypes = {
    getState: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    dispatchEmitter: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    dbClient: PropTypes.object.isRequired,
  };

    static displayName = getHocName('ProvideProps', Component)

    computeProps() {
      const state = this.context.getState()
      const props = provider ? provider(state, this.props.uiState, this.props.filterState, this.props) : {}
      props.dispatch = this.context.dispatch
      props.actions = this.context.actions
      props.dbClient = this.context.dbClient
      props.viewer = state.viewer
      return {...this.props, ...props}
    }

    onDispatch = () => {
      if (this.mounted) {
        this.forceUpdate()
      }
    }

    componentWillMount() {
      this.mounted = true
      this.context.dispatchEmitter.on('dispatch', this.onDispatch)
    }

    componentWillUnmount() {
      this.mounted = false
      this.context.dispatchEmitter.removeListener(this.onDispatch)
    }

    render() {
      return <Component {...this.computeProps()} />
    }

}
