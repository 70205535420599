import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Button, Form, Collapse} from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import style from './client.module.scss'
import {compose, uiMount, provideProps, mountDataProviders} from '../decorators'
import {clientProvider} from './providers'
import {alertStatuses, alertTypes} from '@bdswiss/common-enums'
import {getFullName, readableDate} from '../useful'
import {canResolveAlerts} from '@bdswiss/common-permissions'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import AlertLog from './AlertLog'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class Alert extends PureComponent {

  static propTypes = {
    alert: PropTypes.object.isRequired,
    uiState: PropTypes.object.isRequired,
    onCreate: PropTypes.func,
  }

  static contextTypes = {
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    clientProvider: PropTypes.object.isRequired,
  }

  componentWillMount() {
    this.setDefaultStateFromProps(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setDefaultStateFromProps(nextProps)
  }

  setDefaultStateFromProps(props) {
    this.setState({
      activityLog: this.activityLogFromJson(props.alert.activityLog),
      activityLogIsOpen: false,
      commentIsOpen: false,
      postponeIsOpen: false,
      postponeUntil: '',
      comment: '',
      status: props.alert.status,
      alertPostponeUntil: props.alert.postponeUntil,
    })
  }

  openResolveAlertModal() {
    const {alert} = this.props
    this.props.uiDispatch('Open resolve alert modal', (state) => ({
      ...state,
      showResolveAlertModal: true,
      currentAlert: alert,
    }))
  }

  openAlertDetailsModal() {
    const {alert} = this.props
    const meta = JSON.parse(alert.meta)
    if (meta.date) meta.date = readableDate(moment(meta.date))
    if (meta.expiration_date) meta.expiration_date = readableDate(moment(meta.expiration_date))
    if (meta.deposit_date) meta.deposit_date = readableDate(moment(meta.deposit_date))
    if (meta.last_deposit_date) meta.last_deposit_date = readableDate(moment(meta.last_deposit_date))

    this.props.uiDispatch('Open alert details modal', (state) => ({
      ...state,
      showAlertDetailsModal: true,
      currentAlert: alert,
      alertDetails: meta,
    }))
  }

  renderResolveAlertButton() {
    const {alert} = this.props
    return canResolveAlerts(this.props.viewer) && alert.status !== alertStatuses.resolved.value ? (
      <Button
        id={'t-client-alert-resolve-button-' + alert.id}
        className="btn-xs mr-1"
        variant="info"
        style={{fontSize: 11}}
        onClick={(e) => {
          this.openResolveAlertModal(alert)
        }}
      >Resolve</Button>
    ) : (
      <div>
        <p>
          <b>Resolved by:</b> {getFullName(alert.resolvedBy)} @ {readableDate(alert.resolvedAt)}
        </p>
        <p><b>Comment:</b> {alert.comment}</p>
      </div>
    )
  }

  handlePostponeButtonClick() {
    this.setState({
      postponeIsOpen: !this.state.postponeIsOpen,
      commentIsOpen: false,
    })
  }

  handlePostponeDateChange(postponeUntil) {
    this.setState({
      postponeUntil,
    })
  }

  handlePostponeSubmit(e) {
    e.preventDefault()
    this.postponeAlert({
      type: 'postpone',
      postponeUntil: this.state.postponeUntil,
      createdAt: new Date(),
      createdBy: this.props.viewer.id,
    })
  }

  postponeAlert(item) {
    const {alert} = this.props
    this.props.actions.client.postponeAlert(
      alert,
      item.postponeUntil,
      JSON.stringify([...this.state.activityLog, item])
    )
      .then((res) => {
        this.context.clientProvider.subProviders.alerts.fetch()
      })
      .catch((error) => this.context.showNotification({
        title: 'Postone Alert Error',
        message: error.message,
        position: 'tr',
        level: 'error',
      }))
  }

  handleCommentButtonClick() {
    this.setState({
      commentIsOpen: !this.state.commentIsOpen,
      postponeIsOpen: false,
    })
  }

  handleCommentChange(value) {
    this.setState({
      comment: value,
    })
  }

  handleCommentSubmit(e) {
    e.preventDefault()
    this.addToActivityLog({
      type: 'comment',
      comment: this.state.comment,
      createdAt: new Date(),
      createdBy: this.props.viewer.id,
    })
  }

  addToActivityLog(item) {
    const {alert} = this.props
    this.props.actions.client.updateAlertActivityLog(
      alert,
      JSON.stringify([...this.state.activityLog, item])
    )
      .then((res) => this.context.clientProvider.subProviders.alerts.fetch())
      .catch(this.context.logError)
  }

  activityLogFromJson(jsonString) {
    try {
      const activityLog = JSON.parse(jsonString)
      return Array.isArray(activityLog) ? activityLog : []
    } catch (e) {
      // eslint-disable-next-line
      console.error('Could not parse alerts activityLog')
    }
  }

  decorateWithUsers(activityLog) {
    const agents = this.props.agents
    return activityLog.map((log) => ({
      ...log,
      agent: _.find(agents, (agent) => agent.id === log.createdBy),
    }))
  }

  render() {
    const {alert} = this.props
    const {event, title} = style
    const {activityLogIsOpen} = this.state
    const activityLog = this.decorateWithUsers(this.state.activityLog)

    return (
      <Row className={classnames([event, style[`${alert.status}Alert`]])} key={alert.id}>
        <Col xs={12}>
          <h2 className={title} style={{display: 'flex', alignItems: 'baseline'}}>
            <div style={{flex: 1}}>
              {alertTypes[alert.type].label}
            </div>
            <div title={readableDate(alert.createdAt)}>
              <small>{alert.createdAt.fromNow()}</small>
            </div>
          </h2>
          <div className={style.noteText}>
            <p>
              <b>
                {alertStatuses[this.state.status].label}
                {' '}
                {this.state.status === 'postponed' &&
                  <abbr title={readableDate(moment(this.state.alertPostponeUntil))}>
                    {moment(this.state.alertPostponeUntil).fromNow().replace('in', 'for')}
                  </abbr>
                }
              </b><br />
            </p>
            {this.renderResolveAlertButton(alert)}
            {alert.status !== 'resolved' &&
              <Button
                className="btn-xs mr-1"
                variant="outline-secondary"
                style={{fontSize: 11}}
                onClick={() => this.handlePostponeButtonClick()}
              >
                Postpone
              </Button>
            }
            <Button
              className="btn-xs mr-1"
              variant="outline-secondary"
              style={{fontSize: 11}}
              onClick={() => this.handleCommentButtonClick()}
            >
              Comment
            </Button>
            <Button
              className="btn-xs"
              variant="outline-secondary"
              style={{fontSize: 11}}
              onClick={() => this.openAlertDetailsModal(alert)}
            >
              Details
            </Button>
            <Button
              className="btn-xs float-right"
              variant="outline-secondary"
              style={{fontSize: 11}}
              onClick={() => this.setState({
                activityLogIsOpen: !this.state.activityLogIsOpen,
              })}
            >
              <span>Activity Log&nbsp;
                <FontAwesomeIcon icon={`caret-${activityLogIsOpen ? 'down' : 'up'}`} />
              </span>
            </Button>
            <Collapse in={this.state.postponeIsOpen} className={style.collapsePostpone}>
              <form onSubmit={(e) => this.handlePostponeSubmit(e)}>
                <DateTime
                  id="postponeUntil"
                  name="postponeUntil"
                  label="Postpone until:"
                  timeFormat={false}
                  onChange={(postponeUntil) => this.handlePostponeDateChange(postponeUntil)}
                  value={this.props.postponeUntil}
                  className={style.dateTime}
                  closeOnSelect
                />
                <Button
                  onClick={() => this.setState({postponeIsOpen: false})}
                  className="btn-xs mr-1"
                  variant="outline-secondary"
                >
                  Cancel
                </Button>
                <Button type="submit" variant="success" className="btn-xs">Submit</Button>
              </form>
            </Collapse>
            <Collapse in={this.state.commentIsOpen} className={style.collapseComment}>
              <form onSubmit={(e) => this.handleCommentSubmit(e)}>
                <Form.Control
                  as="textarea"
                  rows={4}
                  label="Comment:"
                  value={this.state.comment}
                  onChange={(e) => this.handleCommentChange(e.target.value)}
                  className="mb-2"
                />
                <Button type="reset" className="btn-xs mr-1" variant="outline-secondary">Cancel</Button>
                <Button type="submit" variant="success" className="btn-xs" style={{marginLeft: '.5em'}}>Submit</Button>
              </form>
            </Collapse>
            <Collapse in={this.state.activityLogIsOpen}>
              <div>
                {activityLog.map((log) => <AlertLog log={log} key={log.createdAt} />)}
              </div>
            </Collapse>
          </div>
        </Col>
      </Row>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'alertsUi']),

  provideProps((state, uiState) => {
    const {showResolveAlertModal, currentAlert, showAlertDetailsModal, alertDetails} = uiState
    const {agents, viewer} = state
    return {
      agents,
      viewer,
      showResolveAlertModal,
      currentAlert,
      showAlertDetailsModal,
      alertDetails,
    }
  }),
  mountDataProviders({
    clientProvider,
  })
)(Alert)
