import {getFetchInterval} from '../useful'

export const tradesReportProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {dateFilter, reportSort, source, company} = props
    const reportDateStart = dateFilter?.reportDateStart ? `reportDateStart: "${dateFilter.reportDateStart}"` : ''
    const reportDateEnd = dateFilter?.reportDateEnd ? `reportDateEnd: "${dateFilter.reportDateEnd}"` : ''
    const sourceCondition = source ? `source: "${source}"` : ''
    const companyCondition = company && company !== 'All' ? `company: "${company}"` : ''

    const query = `{
      getTradesReport(
              ${reportDateStart}
              ${reportDateEnd}
              ${sourceCondition}
              ${companyCondition}
        ${reportSort?.orderBy ? `orderBy: ${reportSort.orderBy}` : ''}
        ${reportSort?.orderDirection ? `orderDirection: ${reportSort.orderDirection}` : ''}
       ) {
        trades
        date
        source
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Trades Report loaded',
    (state, res) => ({
      ...state,
      tradesReport: res.getTradesReport != null
        ? res.getTradesReport
        : state.registrationsReport || [],
      reportCount: res.getTradesReportCount != null
        ? res.getTradesReportCount
        : state.reportCount || 0,
    }),
    [res]
  ),
}
