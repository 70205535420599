import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {get} from 'lodash'

export default class CopyStrategyModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const {show, onConfirm, onCancel, selectedStrategy: {isActive, accountCopying}, selectedAccount: {remoteId},
      loading,
    } = this.props
    const buttonText = isActive ? 'Stop' : 'Start'
    const accountCopyingId = get(accountCopying, 'remoteId', '')
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Copy strategy confirmation modal</strong>
        </Modal.Header>
        <Modal.Body>
          <p>You are about to {buttonText.toLowerCase()} copy trading from: </p>
          <p><strong>IB Account: {accountCopyingId}</strong> to <strong>Client Account: {remoteId}</strong></p>
          <p>If IB's Account ("{accountCopyingId}") has any due performance fees, they will be calculated and withdrawn from Client's Account ("{remoteId}") once all the copied positions are closed.</p>
          <p>Are you sure you want to proceed?</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button variant="outline-secondary" size="sm" className="mr-1" onClick={onCancel}>
              Cancel
            </Button>
            <Button variant="success" size="sm" onClick={onConfirm}>
              {loading ? <i className="fa fa-spinner fa-spin" title={'spinner'} /> : `${buttonText} Copying`}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
