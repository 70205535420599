/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import classnames from 'classnames'
import Promise from 'bluebird'
import {Button, ButtonToolbar, Row, Col, Form} from 'react-bootstrap'
import {List as Loading} from 'react-content-loader'
import {
  flatten, uniqBy, filter, throttle, toUpper, includes, get, isEmpty, map,
  clone, union, find, sortBy
} from 'lodash'
import {accountTypes, tradingStatuses, closedTradingStatusReasons, deleteIBWalletReasons} from '@bdswiss/common-enums'
import {
  canCreateAccounts, canCreateTransfers, canToggleAllowNewAccounts, canToggleAllowManuallyAccounts, canAddBonus,
} from '@bdswiss/common-permissions'
import PropTypes from 'prop-types'
import Account from './Account'
import {provideProps} from '../../decorators'
import {createClientNewAccountUrl, createClientTransfersUrl} from '../../utils/links'
import style from '../client.module.scss'
import PureComponent from '../../PureComponent'
import ReasonModal from '../../components/ReasonModal'
import CopyStrategyModal from '../CopyStrategyModal'
import {backendRequest} from '../../utils/net'
import ConfirmationModal from '../../components/ConfirmationModal'
import StylishSelect from '../../components/StylishSelect'
import RemoveTradingAccountModal from '../RemoveTradingAccountModal'
export default provideProps()(class Accounts extends PureComponent {

  static propTypes = {
    client: PropTypes.object,
    switchSidebar: PropTypes.func.isRequired,
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.toggleAllowNewAccounts = throttle(this.toggleAllowNewAccounts, 2000, {trailing: false})
    this.state = {
      ...this.state,
      showCopyStrategyModal: false,
      bonusModal: {},
      selectedStrategy: {},
      selectedAccount: {},
      manuallyAccounts: filter(accountTypes, (account) => account.clientOpenPermitted && account.manuallyAllowed && account.company === get(props, 'client.generalInfo.company')),
      accountCacheModal: {
        show: false,
        accountId: null,
      },
      removeTradingAccountModal: {
        show: false,
        accounts: [],
        clientId: 0
      },
      hideDeletedAccounts: false,
      additionalBonus: get(props, 'client.additional_bonus')
    }
  }

  componentWillMount() {
    const {client: {personalDetails: {allowNewAccounts, swapFree, additionalBonus}, generalInfo: {manuallyAllowedAccounts}}} = this.props
    this.setState({allowNewAccounts, swapFree, additionalBonus, manuallyAccountsSelected: manuallyAllowedAccounts})
    const {generalInfo: {id: clientId}} = this.props.client
    this.props.actions.client.getSwapBehaviour(clientId).then((res) => {
      const swapFree = 'forcedisable' === res.swapBehaviour.toLowerCase()
      this.setState({swapFree})
    })
  }

  componentWillReceiveProps(newProps) {
    const {isActive, client: {personalDetails: {allowNewAccounts, additionalBonus}, generalInfo: {manuallyAllowedAccounts}}} = newProps
    const {dataFetched, dataLoading, dataError, manuallyAccounts} = this.state
    if (allowNewAccounts !== this.state.allowNewAccounts) {
      this.setState({allowNewAccounts})
    }
    if (additionalBonus !== this.state.additionalBonus) {
      this.setState({additionalBonus})
    }
    if (manuallyAccounts.length === 1 && manuallyAllowedAccounts !== this.state.manuallyAccountsSelected) {
      this.setState({manuallyAccountsSelected: manuallyAllowedAccounts})
    }
    if (isActive && !dataLoading && !dataError && !dataFetched) {
      Promise.all([
        this.context.clientProvider.subProviders.accounts.fetch(),
        this.context.clientProvider.subProviders.clientBrandAccounts.fetch(),
      ]).then(() => {
        this.setState({dataFetched: true, dataLoading: false})
      }).catch((e) => this.setState({dataError: e, dataFetched: true, dataLoading: false}))
      this.setState({dataLoading: true})
    }
  }


  newAccountSidebar() {
    this.context.router.push(createClientNewAccountUrl(this.props.client.generalInfo.id))
  }

  deleteAccount(accountId, reason, selectedReasonCode) {
    return this.props.actions.client.deleteAccount(accountId, reason, selectedReasonCode)
      .then((res) => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch(this.context.logError)
  }

  removeBonus(accountId) {
    return this.props.actions.client.removeBonus(accountId)
      .then((res) => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.context.clientProvider.subProviders.withdrawals.fetch()
      }).catch(this.context.logError)
  }

  addBonus(accountId) {
    return this.props.actions.client.addBonus(accountId)
      .then((res) => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.context.clientProvider.subProviders.withdrawals.fetch()
      }).catch(this.context.logError)
  }

  async closeAllTrades(accountId) {
    try {
      await this.props.actions.client.closeAllTrades(accountId)
      this.context.showNotification({
        title: 'Closing all trades started',
        message: `Closing all trades on account #${accountId} has started. Depending
        on the positions and market trading hours it might take some time.
        Once all positions are closed you will receive an email.`,
        position: 'tr',
        level: 'info',
        autoDismiss: 0,
      })
    } catch (e) {
      this.context.logError(e)
    }
  }

  showDeleteTradingAccountsModal = (accounts, clientId) => {
    this.setState({
      removeTradingAccountModal: {
        show: true,
        accounts,
        clientId
      }
    })
  }

  hideDeleteTradingAccountsModal = () => {
    this.setState({
      removeTradingAccountModal: {
        show: false,
        accounts: [],
        clientId: null
      }
    })
  }

  removeTradingAccounts = (clientId, accountIds) => {
    this.props.actions.client.removeTradingAccounts(clientId, accountIds).then((result) => {
      this.context.clientProvider.subProviders.accounts.fetch()
      this.setState({removeTradingAccountModal: {show: false}})
      if (result.removeTradingAccounts) {
        this.context.showNotification({
          title: 'Acccount Removed',
          message: 'Account was removed from the mailing list',
          position: 'tr',
          level: 'success',
          autoDismiss: 10,
        })
      }
    }).catch((e) => {
      this.context.showNotification({
        title: 'Acccount Removed Error',
        message: e.message,
        position: 'tr',
        level: 'error',
      })
    })
  }

  closeDeleteAccountModal() {
    this.props.uiDispatch(
      'Close Confirm Delete Account',
      (state) => ({
        ...state,
        deleteAccountModal: {
          show: false,
        },
      })
    )
  }

  stopCopying(accountId) {
    const {backendUrl} = this.context.config
    return backendRequest(`${backendUrl}/api/v1/crm/strategies/${accountId}/stop_copying`, {sendEmail: true})
      .then((res) => res.json())
      .catch((error) => error)
  }

  startCopying(accountId, providerAccountId) {
    const {backendUrl} = this.context.config
    return backendRequest(`${backendUrl}/api/v1/crm/strategies/${accountId}/start_copying/${providerAccountId}`)
      .then((res) => res.json())
      .catch((error) => error)
  }

  onSelectNewStrategy(e, account) {
    if (e.value === '') {
      this.setState({
        showCopyStrategyModal: true,
        selectedStrategy: account.copyTrading,
        selectedAccount: account,
        startCopy: true,
      })
    } else {
      const strategy = find(account.copyTradingStrategies, {providerAccountId: e.value})
      const selectedStrategy = {
        isActive: false,
        accountCopying: {remoteId: get(strategy, 'remoteId') || e.label},
        providerAccountId: e.value,
      }
      this.setState({
        showCopyStrategyModal: true,
        selectedStrategy,
        selectedAccount: account,
        startCopy: false,
      })
    }
  }

  confirmCopyStrategy(confirm) {
    if (confirm) {
      const {selectedAccount, selectedStrategy} = this.state
      this.setState({changingStrategy: true})
      if (selectedStrategy.isActive) {
        this.stopCopying(selectedAccount.id).then((res) => {
          if (get(res, 'error')) {
            this.context.logError(res.error)
            this.setState({showCopyStrategyModal: false, changingStrategy: false})
          } else {
            this.context.clientProvider.subProviders.accounts.fetch()
            this.context.clientProvider.subProviders.activityLogs.fetch()
            this.setState({showCopyStrategyModal: false, changingStrategy: false})
          }
        })
      } else {
        const canChangeStrategy = !selectedAccount.isReadOnly && !selectedAccount.hidden &&
          selectedAccount.balance > 0 && !selectedAccount.isArchived && !selectedAccount.isViewOnly
        if (!canChangeStrategy) {
          this.context.logError({
            message: 'Cannot change strategy for this account. Check account status',
            isShowActualError: true,
          })
          this.setState({changingStrategy: false})
          return
        }
        if (get(selectedAccount, 'copyTrading.isActive')) {
          this.stopCopying(selectedAccount.id).then((res) => {
            if (get(res, 'error')) {
              this.context.logError(res.error)
              this.setState({showCopyStrategyModal: false, changingStrategy: false})
            } else {
              this.startCopying(selectedAccount.id, selectedStrategy.providerAccountId).then((res) => {
                if (get(res, 'error')) {
                  this.context.logError(res.error)
                  this.setState({showCopyStrategyModal: false, changingStrategy: false})
                } else {
                  this.context.clientProvider.subProviders.accounts.fetch()
                  this.context.clientProvider.subProviders.activityLogs.fetch()
                  this.setState({showCopyStrategyModal: false, changingStrategy: false})
                }
              })
            }
          })
        }
        this.startCopying(selectedAccount.id, selectedStrategy.providerAccountId).then((res) => {
          if (get(res, 'error')) {
            this.context.logError(res.error)
            this.setState({showCopyStrategyModal: false, changingStrategy: false})
          } else {
            this.context.clientProvider.subProviders.accounts.fetch()
            this.context.clientProvider.subProviders.activityLogs.fetch()
            this.setState({showCopyStrategyModal: false, changingStrategy: false})
          }
        })
      }
    } else {
      this.setState({showCopyStrategyModal: false, changingStrategy: false})
    }
  }

  toggleAllowAccountManually(accounts) {
    const {generalInfo: {id: clientId}} = this.props.client
    this.props.actions.client.toggleAllowAccountManually(clientId, accounts)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }



  toggleAllowNewAccounts(allowNewAccounts) {
    const {generalInfo: {id: clientId}} = this.props.client
    this.setState({togglingAllowNewAccounts: true})
    return this.props.actions.client.toggleAllowNewAccounts(clientId, allowNewAccounts)
      .then((res) => {
        Promise.all(
          [
            this.context.clientProvider.subProviders.basicData.fetch(),
            this.context.clientProvider.subProviders.activityLogs.fetch(),
          ]
        ).then(() => this.setState({togglingAllowNewAccounts: false}))
      }).catch((e) => {
        this.setState({togglingAllowNewAccounts: false})
        this.context.logError(e)
      })
  }

  toggleSwapFree(swapFree) {
    const {generalInfo: {id: clientId}} = this.props.client
    this.setState({togglingSwapFree: true})
    const behaviour = swapFree ? 'ForceDisable' : 'Default'
    return this.props.actions.client.toggleSwapFree(clientId, behaviour)
      .then((res) => {
        Promise.all([
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.basicData.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          const swapFree = 'forcedisable' === res.swapBehaviour.toLowerCase()
          this.setState({togglingSwapFree: false})
          this.setState({swapFree})
          this.context.showNotification({
            title: 'Swap Free Change',
            message: `Successful change ${swapFree ? 'to' : 'from'} Swap Free groups`,
            position: 'tr',
            level: 'success',
            autoDismiss: 10,
          })
        })
      }).catch((e) => {
        this.setState({togglingSwapFree: false, swapFree: !swapFree})
        this.context.logError(e)
      })
  }

  hideClearAccountCache = () => {
    this.setState({
      accountCacheModal: {
        show: false,
        accountId: null,
      }
    })
  }

  clearAccountCache = () => {
    const {accountCacheModal: {accountId}} = this.state
    return this.props.actions.client.clearAccountCache(accountId)
      .then((res) => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.hideClearAccountCache()
        this.context.showNotification(res.clearAccountCache
          ? {
            title: 'Account cache cleared.',
            position: 'tr',
            level: 'success',
          }
          : {
            title: 'Tried clearing account cache, but it was already clear.',
            position: 'tr',
            level: 'warning',
          }
        )
      }).catch(this.context.logError)
  }

  manuallyAccountsChanged(accounts) {
    this.setState({manuallyAccountsSelected: accounts})
    this.toggleAllowAccountManually(map(accounts, 'value'))
  }

  handleClearAccountCacheClick = (accountId) => {
    this.setState({
      accountCacheModal: {
        show: true,
        accountId,
      }
    })
  }

  takeOpenPositionsSnapshot = (accountId) => {
    this.props.actions.client.takeOpenPositionsSnapshot(accountId)
      .then((res) => {
        this.context.showNotification(res.takeOpenPositionsSnapshot
          ? {
            title: 'Snapshot Saved.',
            position: 'tr',
            level: 'success',
          }
          : {
            title: 'No open positions to take snapshot.',
            position: 'tr',
            level: 'warning',
          }
        )
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }

  renderManuallyAccounts(accounts) {
    const {manuallyAccountsSelected} = this.state
    const {client: {generalInfo}} = this.props
    const canToggle = canToggleAllowManuallyAccounts(this.props.viewer)
    const country = toUpper(get(generalInfo, 'address.country'))
    if (accounts.length > 1) {
      const disabledAndActive = map(filter(accounts, (account) => ((account.clientPermittedByCountry && !includes(get(account, 'disallowedCountries'), country)))), 'key')
      const options = map(accounts, (account) => ({...account, disabledAndActive: includes(disabledAndActive, account.key)}))
      return <StylishSelect.Input
        id="t-manually-accounts"
        label="Allow Manually Accounts"
        value={union(clone(manuallyAccountsSelected), disabledAndActive)}
        options={StylishSelect.enumToStylishOptions(options)}
        multi
        clearable
        onChange={(evt) => this.manuallyAccountsChanged(filter(evt, (e) => !includes(disabledAndActive, e.value)))}
        disabled={!canToggle}
      />
    } else {
      const showActive = get(accounts, '[0].clientPermittedByCountry') && !includes(get(accounts, '[0].disallowedCountries'), country)
      return <Form.Check
        id="t-allow-manually-account"
        label={<strong>Allow {get(accounts, '[0].label')}</strong>}
        checked={showActive || includes(manuallyAccountsSelected, get(accounts, '[0].key'))}
        onChange={(e) => { this.toggleAllowAccountManually(e.target.checked ? get(accounts, '[0].key') : []) }}
        disabled={showActive || !canToggle}
      />
    }
  }

  toggleAdditionalBonus(additionalBonus) {
    const {generalInfo: {id: clientId}} = this.props.client
    // this.setState({togglingAdditionalBonus: true})
    return this.props.actions.client.toggleAdditionalBonus(clientId, additionalBonus)
      // return this.props.actions.client.toggleSwapFree(clientId, additionalBonus)
      .then((res) => {
        Promise.all([
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.basicData.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          // this.setState({togglingAdditionalBonus: false})
          this.context.showNotification({
            title: '50% Bonus On Deposit Change',
            message: `Successful change ${additionalBonus ? 'to' : 'from'} 50% Bonus On Deposit groups`,
            position: 'tr',
            level: 'success',
            autoDismiss: 10,
          })
        })
      }).catch((e) => {
        // this.setState({togglingAdditionalBonus: false, additionalBonus: !additionalBonus})
        this.context.logError(e)
      })
  }

  render() {
    const {dataLoading, dataError, manuallyAccounts, accountCacheModal, removeTradingAccountModal} = this.state
    const {isActive, client: {generalInfo: {id: clientId}, accounts: allAccounts, clientBrandAccounts}} = this.props
    if (!allAccounts || dataLoading || !isActive) return <Loading speed={1} style={{padding: '20px'}} />
    if (dataError) return <pre> Error Loading Accounts : {dataError.message} </pre>

    const canCreateNew = canCreateAccounts(this.props.viewer)
    const deleteAccountModal = this.props.uiState.deleteAccountModal || {}
    const closeAllTradesModal = this.props.uiState.closeAllTradesModal || {}
    const bonusModal = this.props.uiState.bonusModal || {}
    const canToggleAdditionalBonus = canAddBonus(this.props.viewer)

    const accounts = this.state.hideDeletedAccounts
      ? filter(allAccounts, (a) => !a.deletedAt)
      : allAccounts

    const allLiveAccounts = filter(uniqBy(flatten([accounts, clientBrandAccounts]), (a) => a && a.id),
      (a) => a && !accountTypes[a.__typename].isDemo)
    const newAccountButton = canCreateNew && (
      <Button
        className="mr-3"
        variant="success"
        size="sm"
        onClick={() => this.newAccountSidebar()}
        id="t-client-accounts-button-new-account"
      >
        New Account
      </Button>
    )

    if (allAccounts.length === 0) {
      return (
        <div id="t-client-accounts">
          No accounts.
          <Row>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                {newAccountButton}
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
      )
    }

    return (
      <div id="t-client-accounts">
        <Row>
          <Col xs={4}>
            <Row>
              <Col xs={3}>
                <ButtonToolbar className={classnames(['pull-left', style.allowNewAccounts])}>
                  <div>
                    <input id="toggle-allow-new-accounts" type="checkbox"
                      className={classnames([style.toggleSwitch, style.toggleLight])}
                      checked={this.state.allowNewAccounts}
                      disabled={!canToggleAllowNewAccounts(this.props.viewer) || this.state.togglingAllowNewAccounts}
                      onChange={(e) =>
                        this.toggleAllowNewAccounts(e.target.checked)
                      }
                    />
                    <label id="t-toggle-allow-new-accounts" htmlFor="toggle-allow-new-accounts"
                      className={classnames([style.toggleButton])} title="Allow New Accounts"
                    />
                    <span>Allow New Accounts</span>
                  </div>
                </ButtonToolbar>
              </Col>
              <Col xs={3}>
                <ButtonToolbar className={classnames(['pull-left', style.allowNewAccounts])}>
                  <div >
                    {(
                      <input
                        id="toggle-swap-free"
                        type="checkbox"
                        className={classnames([style.toggleSwitch, style.toggleLight])}
                        checked={this.state.swapFree}
                        disabled={this.state.togglingSwapFree}
                        onChange={(e) => this.toggleSwapFree(e.target.checked)}
                      />
                    )}

                    <label id="t-toggle-swap-free" htmlFor="toggle-swap-free"
                      className={classnames([style.toggleButton])} title="Forced Swap Free"
                    />
                    <span>Forced Swap Free</span>
                  </div>
                </ButtonToolbar>
              </Col>
              <Col xs={3}>
                <ButtonToolbar className={style.allowNewAccounts}>
                  <div >
                    <input id="hide-deleted-accounts" type="checkbox"
                      className={classnames([style.toggleSwitch, style.toggleLight])}
                      checked={this.state.hideDeletedAccounts}
                      onChange={(e) =>
                        this.setState({hideDeletedAccounts: e.target.checked})
                      }
                    />
                    <label id="t-hide-deleted-accounts" htmlFor="hide-deleted-accounts"
                      className={style.toggleButton} title="Hide Deleted Accounts"
                    />
                    <span>Hide Deleted Accounts</span>
                  </div>
                </ButtonToolbar>
              </Col>
              {canToggleAdditionalBonus && (<Col xs={3}>
                <ButtonToolbar className={style.allowNewAccounts}>
                  <div >
                    <input id="bonus-additional" type="checkbox"
                      className={classnames([style.toggleSwitch, style.toggleLight])}
                      checked={this.state.additionalBonus}
                      onChange={(e) =>
                        this.toggleAdditionalBonus(e.target.checked)
                      }
                    />
                    <label id="t-hide-deleted-accounts" htmlFor="bonus-additional"
                      className={style.toggleButton} title="50% Bonus On Deposits"
                    />
                    <span>50% Bonus On Deposits</span>
                  </div>
                </ButtonToolbar>
              </Col>)}
            </Row>
          </Col>
          <Col xs={8}>
            <ButtonToolbar className="float-right">
              {newAccountButton}
              {
                allLiveAccounts.length > 1 && canCreateTransfers(this.props.viewer) &&
                <Button
                  id="t-client-accounts-new-transfer-button"
                  variant="success"
                  size="sm"
                  className="mr-3"
                  onClick={() => this.context.router.push(createClientTransfersUrl(clientId))}
                >
                  Transfer Funds
                </Button>
              }
              <Button
                id="t-client-accounts-refresh-button"
                variant="success"
                size="sm"
                onClick={() => {
                  this.context.clientProvider.subProviders.accounts.fetch().then(() => {
                    if (this.state.expanded) {
                      this.context.clientProvider.subProviders.accountsForexData.fetch()
                    }
                  })
                }}
              >
                Refresh
              </Button>
              <Button
                className="ml-3"
                size="sm"
                id="t-client-accounts-refresh-button"
                variant="success"
                onClick={() => this.showDeleteTradingAccountsModal(allAccounts, clientId)}
              >
                Stop MT4 Reports
              </Button>
            </ButtonToolbar>
          </Col>
        </Row>
        {!isEmpty(manuallyAccounts) && <Row className={style.allowManuallyAccounts}>
          <Col xs={5}>
            {this.renderManuallyAccounts(manuallyAccounts)}
          </Col>
        </Row>}
        <hr />
        {sortBy([...accounts], (a) => {
          if (a.deletedAt || a.isArchived) return 1
          if (a.isDemo) return 0
          return -1
        }).map(
          (account) => <Account
            key={`account-${account.id}`}
            client={this.props.client}
            account={account}
            deposits={account.totalDeposits}
            bonuses={account.totalBonuses || account.totalActiveBonus}
            withdrawals={account.totalWithdrawals}
            viewer={this.props.viewer}
            uiDispatch={this.props.uiDispatch}
            onSelectNewStrategy={(e, account) => this.onSelectNewStrategy(e, account)}
            handleClearAccountCacheClick={this.handleClearAccountCacheClick}
            onExpand={(expanded) => this.setState({expanded})}
            takeOpenPositionsSnapshot={this.takeOpenPositionsSnapshot}
          />
        )}
        <ReasonModal
          show={deleteAccountModal.show}
          header="Closing Account"
          placeholder="Choose Account Deletion Reason"
          onCancel={() => {
            this.closeDeleteAccountModal()
          }}
          onDone={(reason, selectedReasonCode) => {
            this.deleteAccount(deleteAccountModal.accountId, reason, selectedReasonCode)
            this.closeDeleteAccountModal()
          }}
          reasons={(deleteAccountModal.isIbAccount) ? deleteIBWalletReasons : closedTradingStatusReasons}
          warningMessage={tradingStatuses.closed.warningMessage}
          submitButtonBsClass={tradingStatuses.closed.bsStyle}
          submitButtonText={tradingStatuses.closed.label}
        />
        <ConfirmationModal
          show={bonusModal.show}
          header="Confirmation"
          body={`Are you sure you want to ${bonusModal.type} the Bonus?`}
          confirmLabel="Yes"
          onConfirm={() => {
            if (bonusModal.type === 'remove') {
              this.removeBonus(bonusModal.accountId)
            } else {
              this.addBonus(bonusModal.accountId)
            }
            this.props.uiDispatch(
              'Close Bonus Modal',
              (state) => ({
                ...state,
                bonusModal: {
                  show: false,
                },
              })
            )
          }}
          onCancel={() => this.props.uiDispatch(
            'Close Bonus Modal',
            (state) => ({
              ...state,
              bonusModal: {
                show: false,
              },
            })
          )}
        />
        <ConfirmationModal
          show={closeAllTradesModal.show}
          header="Confirmation"
          body={`Are you sure you want to close all positions on #${closeAllTradesModal.accountId}?`}
          confirmLabel="Yes"
          onConfirm={() => {
            this.closeAllTrades(closeAllTradesModal.accountId)
            this.props.uiDispatch(
              'Close Positions Modal',
              (state) => ({
                ...state,
                closeAllTradesModal: {
                  show: false,
                },
              })
            )
          }}
          onCancel={() => this.props.uiDispatch(
            'Close Positions Modal',
            (state) => ({
              ...state,
              closeAllTradesModal: {
                show: false,
              },
            })
          )}
        />
        <CopyStrategyModal
          show={this.state.showCopyStrategyModal}
          selectedStrategy={this.state.selectedStrategy}
          selectedAccount={this.state.selectedAccount}
          onCancel={() => this.confirmCopyStrategy(false)}
          onConfirm={() => this.confirmCopyStrategy(true)}
          loading={this.state.changingStrategy}
        />
        <ConfirmationModal
          show={accountCacheModal.show}
          body="Are you sure you want to clear the account's cache?"
          confirmLabel="Clear"
          confirmStyle="danger"
          onConfirm={this.clearAccountCache}
          onCancel={this.hideClearAccountCache}
        />
        <RemoveTradingAccountModal
          show={removeTradingAccountModal.show}
          accounts={removeTradingAccountModal.accounts}
          clientId={removeTradingAccountModal.clientId}
          onCancel={this.hideDeleteTradingAccountsModal}
          onConfirm={this.removeTradingAccounts}
        />
      </div>
    )
  }
})
