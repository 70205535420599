import get from 'lodash/get'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

export const netDepositsProvider = {
  fetchInterval: getFetchInterval,
  getQuery: (props) => {
    const {netDepositsSort, netDepositsPage, netDepositsFilter = {}} = props

    const startDate = netDepositsFilter.startDate ? new Date(netDepositsFilter.startDate) : null
    const endDate = netDepositsFilter.endDate ? new Date(netDepositsFilter.endDate) : null

    const adjustedStartDate = startDate 
      ? new Date(Date.UTC(
        startDate.getFullYear(), 
        startDate.getMonth(), 
        startDate.getDate() 
      ))
      : null

    const adjustedEndDate = endDate
      ? new Date(Date.UTC(
        endDate.getFullYear(), 
        endDate.getMonth(), 
        endDate.getDate(), 
        23, 59, 59, 999 
      ))
      : null

    const condition = [
      netDepositsFilter.search && `clientId: ${netDepositsFilter.search}`,
      adjustedStartDate && `startDate: "${adjustedStartDate.toISOString()}"`,
      adjustedEndDate && `endDate: "${adjustedEndDate.toISOString()}"`,
      netDepositsFilter.currency?.value && `currency: "${netDepositsFilter.currency?.value}"`,
    ].filter(condition => condition).join('\n').trim()

    const query = `{
      getTransactionsCommissions(
        agentId: ${netDepositsFilter?.agentId}
        limit: ${getPageSize()}
        offset: ${getOffset(netDepositsPage)}
        ${netDepositsSort?.orderBy ? `orderBy: ${netDepositsSort.orderBy}` : ''}
        ${netDepositsSort?.orderDirection ? `orderDirection: ${netDepositsSort.orderDirection}` : ''}
        ${condition}
      ) {
        clientId
        transactionType
        vendor
        transactionId
        amount
        currency
        ftd
        transactionCreatedAt
        effectiveCall
      }
      getTransactionsCommissionSize
      getTransactionsCommissionsCount (agentId: ${netDepositsFilter?.agentId} ${condition})
      getTransactionsCommissionsSum (agentId: ${netDepositsFilter?.agentId} ${condition}) {
        totalAmount
        depositCount
        withdrawalCount
        currency
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Net Deposits Loaded',
    (state, res) => ({
      ...state,
      netDeposits: get(res, 'getTransactionsCommissions', []),
      netDepositsCommissionSize: get(res, 'getTransactionsCommissionSize', 0),
      netDepositsCount: get(res, 'getTransactionsCommissionsCount', 0),
      netDepositsSum: get(res, 'getTransactionsCommissionsSum', []),
    }),
    [res]
  ),
}
