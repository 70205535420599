import React from 'react'
import {isEmpty, map} from 'lodash'
import PropTypes from 'prop-types'
import {salesAgentEmailTemplateReasons} from '@bdswiss/common-enums'
import {Modal, DropdownButton, Dropdown, Button, ButtonToolbar, Row, Col, Form} from 'react-bootstrap'
import {putFile} from '../utils/net'
import PureComponent from '../PureComponent'

export default class SendEmailTemplateModal extends PureComponent {
  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    client: PropTypes.object,
    viewer: PropTypes.object,
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    header: PropTypes.string,
    signFile: PropTypes.func.isRequired,
    saveAction: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.setState({hideConfirmation: true, template: 'allTemplates', btnText: 'Select a template'})
  }

  trySave() {
    this.setState({buttonDisabled: true})
    const {template, fileToUpload} = this.state

    if (!isEmpty(fileToUpload)) {
      return this.props.signFile()
        .then((res) => {
          const {imageSignedUrl, imagePlainUrl} = res.signDepartmentTemplateEmailFileUrl
          putFile(fileToUpload[0], imageSignedUrl)
            .then(() => {
              const args = {
                clientId: this.props.client.id,
                selectedTemplate: template,
                imageUrl: imagePlainUrl,
              }
              this.props.saveAction(args)
                .then((res) => {
                  this.setState({buttonDisabled: false})
                  this.props.onHide()
                })
                .catch((error) => {
                  error.isShowActualError = true
                  this.context.logError(error)
                  this.setState({buttonDisabled: false})
                })
            })
        })
    } else {
      const args = {
        clientId: this.props.client.id,
        selectedTemplate: template,
      }
      this.props.saveAction(args)
        .then((res) => {
          this.setState({buttonDisabled: false})
          this.props.onHide()
        })
        .catch((error) => {
          error.isShowActualError = true
          this.context.logError(error)
          this.setState({buttonDisabled: false})
        })
    }
  }

  onSelectTemplate(selectedTemplate) {
    if (selectedTemplate !== 'allTemplates') {
      this.setState({
        hideConfirmation: false,
        template: selectedTemplate,
        btnText: salesAgentEmailTemplateReasons[selectedTemplate].label
      })
    } else {
      this.setState({hideConfirmation: true, template: 'allTemplates', btnText: 'Select a template'})
    }
  }

  renderModalBody() {
    const {template} = this.state
    switch (template) {
      case salesAgentEmailTemplateReasons.affiliateContract.value: {
        return (
          <Form.Control
            // multiple
            id="send-email-templates-input-file"
            type="file"
            onChange={(e) => this.setState({fileToUpload: e.target.files})}
          />
        )
      }
      default: {
        return null
      }
    }
  }

  render() {
    const {btnText, buttonDisabled} = this.state
    const {onHide, show, viewer, client} = this.props
    const confirmationText = 'Are you sure you want to send this template to the client?'

    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          {this.props.header && <strong>{this.props.header}</strong>}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <DropdownButton
                id="send-email-templates-select-template"
                title={btnText}
                variant="outline-secondary"
                size="sm"
                onSelect={(event) => this.onSelectTemplate(event)}
              >
                {map(salesAgentEmailTemplateReasons, (template) =>
                  (template.isAvailable(viewer, client) && <Dropdown.Item
                    className={`${template.key}-brand-company-menu-item`}
                    key={template.key}
                    eventKey={template.key}
                  >
                    {template.label}
                  </Dropdown.Item>)
                )}
              </DropdownButton>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={12}>
              {this.renderModalBody()}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <p hidden={this.state.hideConfirmation}> {confirmationText} </p>
          <ButtonToolbar hidden={this.state.hideConfirmation} bsClass="float-right">
            <Button
              id="change-company-yesbtn"
              variant="success"
              size="sm"
              className="mr-2"
              onClick={this.trySave.bind(this)}
              disabled={buttonDisabled}
            >Yes</Button>
            <Button
              id="change-company-nobtn"
              variant="outline-secondary"
              size="sm"
              onClick={onHide}
              disabled={buttonDisabled}
            >No</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
