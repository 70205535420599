import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Button,
  Card,
  Col,
  Container, Form,
  InputGroup,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {compose, mountDataProviders, provideProps, uiMount} from '../decorators'
import {workersActivityProvider} from './providers'
import {getPageCount, getPageRange} from '../useful'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import style from './workersactivity.module.scss'

class WorkersActivity extends PureComponent {
  static contextTypes = {
    workersActivityProvider: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      workerName: ''
    }
  }

  fetchProvider() {
    this.context.workersActivityProvider.fetch()
  }

  handleChange(input) {
    const {value} = input.target
    this.setState({workerName: value})
  }

  applyFilter() {
    this.props.uiDispatch(
      'Filter worker by name',
      (state) => ({
        ...state,
        workersPage: 1,
        workerNameFilter: this.state.workerName,
      })
    )
  }

  clearFilter() {
    this.setState({workerName: ''})
    this.props.uiDispatch(
      'Clear filter',
      (state) => ({
        ...state,
        workerNameFilter: '',
        workersPage: 1,
      })
    )
  }

  render () {
    const {workersActivity, workersPage, workersActivityCount} = this.props
    const {workerName} = this.state
    return (
      <Container>
        <h3>Workers Activity</h3>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className={style.search}>
                  <Col xs={3}>
                    <InputGroup>
                      <Form.Control
                        id="t-list-workers-activity"
                        type="text"
                        size="sm"
                        placeholder="Enter worker name"
                        value={workerName || ''}
                        onChange={this.handleChange.bind(this)}
                      />
                      <InputGroup.Append>
                        <Button
                          key={1}
                          title="Clear"
                          size="sm"
                          variant={'outline-secondary'}
                          onClick={this.clearFilter.bind(this)}
                        >
                          <FontAwesomeIcon
                            title="Clear"
                            role="button"
                            className={style.clickable}
                            icon={'close'}
                          />
                        </Button>
                        <Button
                          key={2}
                          title="Search"
                          size="sm"
                          onClick={this.applyFilter.bind(this)}
                        >
                          <FontAwesomeIcon
                            title="Search"
                            role="button"
                            className={style.clickable}
                            icon={'search'}
                          />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Row>

                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Worker name</span>
                      </th>

                      <th>
                        <span>Started at</span>
                      </th>

                      <th>
                        <span>Finished at</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {workersActivity.map((worker, i) => {
                      const {name, startedAt, finishedAt} = worker
                      return (
                        <tr
                          key={i}
                          id={`t-worker-id-${i}`}
                        >
                          <td>{name}</td>
                          <td>{moment(startedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                          <td>{moment(finishedAt).format('YYYY-MM-DD HH:mm:ss')}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                ...state,
                workersPage: selectedEvent.eventKey
              }))}
            >
              {getPageRange(workersPage, getPageCount(workersActivityCount)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      workersPage: workersPage - 1
                    }))}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      workersPage: workersPage + 1
                    }))}
                  />
                }

                return <Pagination.Item
                  active={page === workersPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                    ...state,
                    workersPage: page
                  }))}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount(() => ['workersActivityUi']),
  provideProps((state, uiState) => {
    const {workersActivity, workersActivityCount} = state
    const {workersPage, workerNameFilter} = uiState

    return ({
      workersActivity,
      workersActivityCount,
      workersPage: workersPage || 1,
      workerNameFilter: workerNameFilter || ''
    })
  }),
  mountDataProviders({workersActivityProvider})
)(WorkersActivity)
