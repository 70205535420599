import PureComponent from '../PureComponent'
import {Button, Card, Col, Container, Row, Table} from 'react-bootstrap'
import {checkRights, compose, mountDataProviders, predispatch, provideProps, uiMount} from '../decorators'
import {orderDirections} from '@bdswiss/common-enums'
import {debounce, merge} from 'lodash'
import {copiersReportProvider} from './providers'
import style from '../firsttimedeposits/reportFtd.module.scss'
import DateTime from '../components/DateTime'
import moment from 'moment'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {events} from '../enums'
import PropTypes from 'prop-types'
import {canQueryCopiersReport} from '@bdswiss/common-permissions'
import StylishSelect from '../components/StylishSelect'
// import React from '@types/react'

const ALL_COMPANIES = 'All'
const generateDownloadLink = (backendUrl, company, reportDateStart, reportDateEnd) => {
  let link = `${backendUrl}/api/v1/reports/report?type=copiers`

  if (company && company !== ALL_COMPANIES) {
    link += `&company=${company}`
  }
  if (reportDateStart) {
    link += `&reportDateStart=${reportDateStart}`
  }
  if (reportDateEnd) {
    link += `&reportDateEnd=${reportDateEnd}`
  }

  return link
}

class CopiersReport extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
  }

  static contextTypes = {
    copiersReportProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilter = {
      date: moment().format('YYYY-MM'),
    }

    const companyNameFilter = this.props.companyNameFilter ?? ALL_COMPANIES

    const stateReportSort = this.props.reportSort || {
      orderBy: 'date', orderDirection: orderDirections.descending.key,
    }

    this.setState({
      sorting: {}, dateFilter: stateDateFilter, reportSort: stateReportSort, companyNameFilter
    })
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.copiersReportProvider.fetch()
  }

  handleDateFilterChanged(name, momentValue, value) {
    const dateFilter = {
      [name]: value
    }
    this.setState({dateFilter})

    if (!momentValue || moment.isMoment(momentValue)) {
      this.doDateFilterSearch(dateFilter)
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch({date}) {
    const dateFilter = {
      reportDateStart: moment(date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
      reportDateEnd: moment(date, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
    }
    this.props.uiDispatch('Filter report by dates', (state) => ({...state, dateFilter}), [dateFilter])
  }


  doSortByOrderAndOrderDirection(field) {
    const {reportSort} = this.props
    const {orderBy, orderDirection} = reportSort

    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting report by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      reportSort: newSort
    }), [reportSort])
  }

  getSortArrow(field) {
    const {reportSort} = this.props
    const arrowDirection = reportSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (reportSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  filterReportsByCompanyName(companyNameFilter) {
    this.setState({companyNameFilter: companyNameFilter})
    this.props.uiDispatch('Filter report by company name', (state) => ({...state, companyNameFilter}), [companyNameFilter])
  }

  render() {
    const {copiersReport, companies, companyNameFilter} = this.props
    const {dateFilter} = this.state
    const {backendUrl} = this.context.config

    const companiesOptions = [ALL_COMPANIES, ...companies].map(company => ({
      label: company,
      value: company
    }))

    return (<Container>
      <h3>Copiers Report</h3>
      <span className={style.label}>Date</span>
      <Row key="filter-row-1" className={style.filter}>
        <Col xs={6}>
          <Row>
            <Col xs={4}>
              <div>
                <DateTime
                  id="t-ftd-date-filter"
                  timeFormat={false}
                  dateFormat="YYYY-MM"
                  onChange={(e) => this.handleDateFilterChanged('date', e, moment.isMoment(e) ? e.format('YYYY-MM') : '')}
                  value={dateFilter.date}
                  onFocus={() => this.doDateFilterSearch.cancel()}
                  closeOnSelect
                  className={style.datetime}
                />
              </div>
            </Col>
            <Col xs={4}>
              <StylishSelect.Input
                value={this.state.companyNameFilter}
                options={companiesOptions}
                onChange={(e) => this.filterReportsByCompanyName(e.value)}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={1}>
          <div style={{textAlign: 'center'}}>
            <Button
              href={generateDownloadLink(backendUrl, companyNameFilter, this.props.dateFilter.reportDateStart, this.props.dateFilter.reportDateEnd)}
              id={'t-copiers-export'}
              variant="success"
              style={{width: '100%'}}
              disabled={!copiersReport.length}
            >
              Download
            </Button>
          </div>
        </Col>

      </Row>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body style={{overflowX: 'scroll', minHeight: 200}}>
              <Table bordered hover className={style.table}>
                <thead>
                  <tr style={{textAlign: 'center'}}>
                    <th style={{minWidth: 40}}>
                      <span>Member ID</span>
                      <FontAwesomeIcon icon={this.getSortArrow('member_id')}
                        onClick={() => this.doSortByOrderAndOrderDirection('id')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>IB Account Copying ID</span>
                      <FontAwesomeIcon icon={this.getSortArrow('ib_account_copying')}
                        onClick={() => this.doSortByOrderAndOrderDirection('ib_account_copying')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Account ID</span>
                      <FontAwesomeIcon icon={this.getSortArrow('account_id')}
                        onClick={() => this.doSortByOrderAndOrderDirection('account_id')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Start Requested Date</span>
                      <FontAwesomeIcon icon={this.getSortArrow('start_requested_date')}
                        onClick={() => this.doSortByOrderAndOrderDirection('start_requested_date')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Stop Requested Date</span>
                      <FontAwesomeIcon icon={this.getSortArrow('start_requested_date')}
                        onClick={() => this.doSortByOrderAndOrderDirection('start_requested_date')}/>
                    </th>

                    {companies.length > 1 &&
                      <th style={{minWidth: 100}}>
                        <span>Company</span>
                      </th>
                    }

                  </tr>
                </thead>
                <tbody>

                  {copiersReport.map((report) => {
                    const {member_id, ib_account_copying, account_id, start_requested_date, stop_requested_date, company} = report
                    return (<tr
                      key={start_requested_date + stop_requested_date}
                      id={member_id}
                      style={{minWidth: 100, textAlign: 'center'}}
                    >
                      <td>{member_id}</td>
                      <td>{ib_account_copying}</td>
                      <td>{account_id}</td>
                      <td>{start_requested_date && moment(start_requested_date).format('YYYY-MM-DD')}</td>
                      <td>{stop_requested_date && moment(stop_requested_date).format('YYYY-MM-DD')}</td>
                      {companies.length > 1 &&
                        <td>{company}</td>
                      }
                    </tr>)
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>)

  }
}

export default compose(checkRights(canQueryCopiersReport),

  uiMount((state) => ['ui', 'copiersReport']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/copiers report',
    (state) => {
      const dateFilter = {
        reportDateStart: moment().startOf('month').format('YYYY-MM-DD'),
        reportDateEnd: moment().endOf('month').format('YYYY-MM-DD')
      }
      let reportSort = state && state.reportSort
      if (!reportSort) {
        reportSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}
      }

      return merge({dateFilter, reportSort}, state)
    })),


  provideProps((state, uiState) => {
    const {copiersReport, viewer: {companies}} = state
    const {dateFilter, reportSort, companyNameFilter} = uiState
    return {copiersReport, dateFilter, reportSort, companies, companyNameFilter}
  }),

  mountDataProviders({copiersReportProvider}),)(CopiersReport)
