import React from 'react'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {communicationTypes} from '@bdswiss/common-enums'
import {Link} from 'react-router-dom'
import style from './Communications.module.scss'
import {parseTemplate} from '../../common/utils'

/**
 * Links to the object ex. deposit the communication relates to
 * It parses a template string from communicationTypes enum
 */
const CommunicationLink = ({communication, children}) => {
  const memberId = communication.client.id
  const vars = {...communication, memberId}
  const link = get(communicationTypes[communication.type], 'link', '')
  return link !== ''
    ? <Link className={style.link} to={parseTemplate(link, vars)}>{children}</Link>
    : <div>{children}</div>
}

CommunicationLink.propTypes = {
  communication: PropTypes.object.isRequired,
  children: PropTypes.any.isRequired,
}

export default CommunicationLink
