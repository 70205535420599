import React from 'react'
import {Link} from 'react-router-dom'
import {clientTabs} from '../enums'
import {isRendered} from './rendering'
import {checkIfIsOnlyAffiliateManager} from '../common/utils'

export function createAccountIconUrl(account) {
  return require(`../assets/${account.icon}`).default
}

export function createClientNewAccountUrl(clientId) {
  return `/clients/${clientId}/${clientTabs.accounts.key}/new`
}

export function createClientEditAccountUrl(clientId, accountId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.accounts.key}`
  return accountId ? `${linkToTab}/${accountId}` : linkToTab
}

export function createVpsSubscriptionUrl(clientId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.vps.key}/createVpsSubscription`
  return linkToTab
}

export function createClientDetailsUrl(clientId, sidebarName) {
  const linkToTab = `/clients/${clientId}/${clientTabs.details.key}`
  return sidebarName ? `${linkToTab}/${sidebarName}` : linkToTab
}

export function createClientWithdrawalsUrl(clientId, withdrawalId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.withdrawals.key}`
  return withdrawalId ? `${linkToTab}/${withdrawalId}` : linkToTab
}

export function createClientAppendixUrl(clientId) {
  return `/clients/${clientId}/${clientTabs.acknowledgements.key}/addAppendix`
}

export function editClientAppendixUrl(clientId) {
  return `/clients/${clientId}/${clientTabs.acknowledgements.key}/editAppendix`
}

export function createClientTransfersUrl(clientId) {
  return `/clients/${clientId}/${clientTabs.accounts.key}/createTransfer`
}

export function createClientNewWithdrawalUrl(clientId) {
  return createClientWithdrawalsUrl(clientId, 'new')
}

export function linkClientWithdrawals(content, clientId, withdrawalId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientWithdrawalsUrl(clientId, withdrawalId)}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

export function createClientUrl(clientId) {
  return `/clients/${clientId}`
}

export function linkClient(content, clientId, linkEnabled, newTab = false) {
  return linkEnabled ?
    <Link to={createClientUrl(clientId)} target={newTab ? '_blank' : '_self'}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

/**
 * Convenience method. If client is undefined or does not have id, returns content. Otherswise returns content
 * inside a link that opens withdrawal page of the client.
 *
 * @param content
 * @param client
 * @param withdrawalId
 * @returns element
 */
export function safeLinkClientWithdrawals(content, client, withdrawalId, linkEnabled) {
  if (client == null || client.id == null) {
    return <div>{content}</div>
  }

  return linkClientWithdrawals(content, client.id, withdrawalId, linkEnabled)
}

export function createClientDepositsUrl(clientId, depositId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.deposits.key}`
  return depositId ? `${linkToTab}/${depositId}` : linkToTab
}

export function createClientNewDepositUrl(clientId) {
  return createClientDepositsUrl(clientId, 'new')
}

export function linkClientDeposits(content, clientId, depositId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientDepositsUrl(clientId, depositId)}>
      {isRendered(content) ? content : '\u200c'}
    </Link> :
    <span>
      {content}
    </span>
}

export function linkClientDetails(content, clientId) {
  return (
    <Link to={createClientDetailsUrl(clientId)}>
      {isRendered(content) ? content : '\u200c'}
    </Link>
  )
}

/**
 * Convenience method. If client is undefined or does not have id, returns content. Otherswise returns content
 * inside a link that opens deposit page of the client.
 *
 * @param client
 * @param content
 * @param depositId
 * @returns element
 */
export function safeLinkClientDeposits(client, content, depositId, linkEnabled) {
  if (client == null || client.id == null) {
    return <div>{content}</div>
  }

  return linkClientDeposits(content, client.id, depositId, linkEnabled)
}

export function safeLinkClientDetails(client, content) {
  if (client == null || client.id == null) {
    return <div>{content}</div>
  }

  return linkClientDetails(content, client.id)
}

export function createClientBonusOfferUrl(clientId, bonusOfferId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.deposits.key}`
  return bonusOfferId ? `${linkToTab}/bo-${bonusOfferId}` : linkToTab
}

export function linkClientBonusOffer(content, clientId, bonusOfferId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientBonusOfferUrl(clientId, bonusOfferId)}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

export function createClientDocumentsUrl(clientId, documentId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.documents.key}`
  return documentId ? `${linkToTab}/${documentId}` : linkToTab
}

export function createClientNewDocumentlUrl(clientId) {
  return createClientDocumentsUrl(clientId, 'new')
}

export function linkClientDocuments(content, clientId, documentId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientDocumentsUrl(clientId, documentId)}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

export function createClientProfileChangesUrl(clientId, profileTestId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.documents.key}`
  return profileTestId ? `${linkToTab}/pc-${profileTestId}` : linkToTab
}

export function linkClientProfileChanges(content, clientId, profileTestId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientProfileChangesUrl(clientId, profileTestId)}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

export function createClientAppropTestsUrl(clientId, appropTestId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.documents.key}`
  return appropTestId ? `${linkToTab}/apt-${appropTestId}` : linkToTab
}

export function linkClientAppropTests(content, clientId, appropTestId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientAppropTestsUrl(clientId, appropTestId)}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

export function createClientDueDiligencesUrl(clientId, appropTestId) {
  const linkToTab = `/clients/${clientId}/${clientTabs.documents.key}`
  return appropTestId ? `${linkToTab}/edd-${appropTestId}` : linkToTab
}

export function linkClientDueDiligences(content, clientId, appropTestId, linkEnabled) {
  return linkEnabled ?
    <Link to={createClientDueDiligencesUrl(clientId, appropTestId)}>
      {content}
    </Link> :
    <span>
      {content}
    </span>
}

export function createUserUrl(userId) {
  return `/users/${userId}`
}

export function linkUser(content, userId) {
  return (
    <Link to={createUserUrl(userId)}>
      {content}
    </Link>
  )
}

/**
 * If the client parameter is null, returns content. Otherwise converts content into a link to client.
 *
 * @param client
 * @param content
 * @param tab
 * @returns link component
 */
export function safeLinkToClient(viewer, content, client, tab = null) {
  if (!client) {
    return (
      <div>{content}</div>
    )
  }

  if (checkIfIsOnlyAffiliateManager(viewer)) {
    tab = 'deposits'
  }

  return (
    <Link to={`/clients/${client.id}${tab ? `/${tab}` : ''}`}>
      {isRendered(content) ? content : '\u200c'}
    </Link>
  )
}

export function createEditCampaignUrl(campaignId) {
  return `/campaigns/${campaignId}`
}

export function linkEditCampaign(content, campaignId) {
  return (
    <Link to={createEditCampaignUrl(campaignId)}>
      {content}
    </Link>
  )
}
