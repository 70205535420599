import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import {provideProps} from '../decorators'
import style from './client.module.scss'
import PureComponent from '../PureComponent'

export default provideProps()(class ResetPassword extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  resetClientPasswords = () => this.props.actions.client.resetClientPassword(this.props.clientId)
    .then((res) => {
      this.setState({newPassword: res.resetClientPassword.password})
      this.context.clientProvider.subProviders.activityLogs.fetch()
    })
    .catch(this.context.logError)

  renderNewPassword() {
    const {newPassword} = this.state

    return (
      <div>
        Passwords have been successfully reset. New password for all accounts is:<br />
        <div className={style.passwordInfo}>
          <code>{newPassword}</code>
        </div>
      </div>
    )
  }

  render() {
    const {onClose} = this.props
    const {newPassword} = this.state || {}
    let message, showResetButton

    if (newPassword != null) {
      message = this.renderNewPassword()
      showResetButton = false
    } else {
      message = (
        <div>
          Confirm reset of all passwords. It will be impossible to revert back to the old passwords.
        </div>
      )
      showResetButton = true
    }

    return (
      <Card id="t-client-reset-password-sidebar" key={1}>
        <Card.Header><strong>Reset Passwords</strong></Card.Header>
        <Card.Body>
          {message}
          <br />
          <Row>
            <Col md={12} xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-reset-password-close"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  className="mr-1"
                  size="sm"
                >Close
                </Button>
                {showResetButton ?
                  <Button
                    id="t-client-reset-password-reset"
                    variant="danger"
                    size="sm"
                    onClick={this.resetClientPasswords}
                  >
                    Reset Passwords
                  </Button> : null
                }
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
})
