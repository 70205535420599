import {getFetchInterval} from '../useful'

function initReport(report) {
  for (const item of report) {
    item['date'] = item['date'] ? item['date'].slice(0, 10) : ''
  }
  return report
}

export const registrationsReportProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {dateFilter, companyNameFilter, reportSort} = props
    const reportDateStart = dateFilter?.reportDateStart ? `reportDateStart: "${dateFilter.reportDateStart}"` : ''
    const reportDateEnd = dateFilter?.reportDateEnd ? `reportDateEnd: "${dateFilter.reportDateEnd}"` : ''
    const companyCondition = companyNameFilter && companyNameFilter !== 'All' ? `company: "${companyNameFilter}"` : ''

    const query = `{
      getRegistrationsReport(
        ${reportDateStart}
        ${reportDateEnd}
        ${companyCondition}
        ${reportSort?.orderBy ? `orderBy: ${reportSort.orderBy}` : ''}
        ${reportSort?.orderDirection ? `orderDirection: ${reportSort.orderDirection}` : ''}
      ) {
        count
        date
        company
      }
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Registrations Report loaded',
    (state, res) => ({
      ...state,
      registrationsReport: res.getRegistrationsReport != null
        ? initReport(res.getRegistrationsReport)
        : state.registrationsReport || [],
      reportCount: res.getRegistrationsReportCount != null
        ? res.getRegistrationsReportCount
        : state.reportCount || 0,
    }),
    [res]
  ),
}
