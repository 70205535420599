import {isNil, omitBy} from 'lodash'

export default function create(dbClient) {
  return {
    checkoutSearch(data, link) {
      const variables = {
        company: data.company,
        fromDate: data.fromDate,
        toDate: data.toDate,
        download: data.download,
      }

      if (link) variables.link = link

      const query = `query (
        $company: Company!
        $fromDate: String
        $toDate: String
        $link: String
        $download: Boolean
        ){
          checkoutSearch(
            company: $company
            fromDate: $fromDate
            toDate: $toDate
            link: $link
            download: $download
          )
        }`

      return dbClient.query(query, omitBy(variables, isNil))
    },

    signSearchToolFileUrl() {
      const query = `
        mutation {
          signSearchToolFileUrl {
            csvKey
            csvPlainUrl
            csvSignedUrl
          }
        }
      `

      return dbClient.query(query)
    },

    searchTool(args) {
      const variables = {
        csvLink: args.csvLink,
        inputField: args.inputField,
        outputFields: args.outputFields,
      }

      const query = `
        mutation(
          $csvLink: String 
          $inputField: String
          $outputFields: [String]
        ) {
          searchTool(
            csvLink: $csvLink
            inputField: $inputField
            outputFields: $outputFields
          ) {
            deposits {
              memberId
              receiptNumber
              productName
              company
              vendor
              email
            }
            withdrawals {
              memberId
              receiptNumber
              productName
              company
              vendor
              email
            }
            status
          }
        }
      `

      return dbClient.query(query, variables)
    },
  }
}
