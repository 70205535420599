import {Modal, ButtonToolbar, Button} from 'react-bootstrap'

import {compose, provideProps} from './../../decorators'

const RoleDeleteModal = ({role, onSubmit, onCancel, actions}) => {
  const handleDelete = async () => {
    try {
      await actions.roles.deleteRole({id: role.id})
      onSubmit()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err)
    }
  }
  return (
    <Modal keyboard show={true} onHide={onCancel} scrollable={true}>
      <Modal.Header closeButton>
        <strong>Delete Role</strong>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete this role?</p>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="float-right">
          <Button
            variant="outline-secondary"
            onClick={onCancel}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  )
}

export default compose(
  provideProps()
)(RoleDeleteModal)
