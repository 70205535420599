/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import classnames from 'classnames'
import {Button, Row, Col, DropdownButton, Dropdown, Collapse} from 'react-bootstrap'
import {filter, has, toUpper, includes, get, isEmpty, find, isNil, map} from 'lodash'
import {accountTypes, currencies, accountSubtypes, yesNo, tradingStatuses, mt4Servers,
  rawSubscriptionPlans, companies, regulators, clientTypes} from '@bdswiss/common-enums'
import {canDeleteAccount, canSubscribeClientToAi, canWriteClientAccount,
  canEditAccountIsMobile, canToggleAffiliateAutomaticCommissions, canToggleCopyTrading,
  canAddBonus, canRemoveBonus, canClearAccountCache, canCloseClientPositions, canTakeOpenPositionsSnapshot,
} from '@bdswiss/common-permissions'
import PropTypes from 'prop-types'
import {getFormattedAmount, readableDate} from '../../useful'
import {getAccountLabel, formatAccountValue, formatPercentValue, companyCheck} from '../../utils/general'
import {createClientEditAccountUrl} from '../../utils/links'
import style from '../client.module.scss'
import PureComponent from '../../PureComponent'
import StylishSelect from '../../components/StylishSelect'
import FontAwesomeIcon from '../../components/FontAwesomeIcon'

export default class Account extends PureComponent {
  static propTypes = {
    client: PropTypes.object.isRequired,
    viewer: PropTypes.object.isRequired,
    account: PropTypes.object.isRequired,
    uiDispatch: PropTypes.func.isRequired,
    bonuses: PropTypes.number,
    deposits: PropTypes.number,
    withdrawals: PropTypes.number,
    handleClearAccountCacheClick: PropTypes.func.isRequired.length,
    takeOpenPositionsSnapshot: PropTypes.func.isRequired.length,
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  state = {
    expanded: false,
  }

  editAccountSidebar(accountId) {
    this.context.router.push(createClientEditAccountUrl(this.props.client.generalInfo.id, accountId))
  }

  openDeleteAccountModal(accountId, isIbAccount) {
    this.props.uiDispatch(
      `Open Confirm Delete account ${accountId}`,
      (state) => ({
        ...state,
        deleteAccountModal: {
          show: true,
          accountId,
          isIbAccount
        },
      })
    )
  }

  subscribeToAI(accountId) {
    return this.props.actions.client.subscribeToAI(accountId)
      .then((res) => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch(this.context.logError)
  }

  unsubscribeFromAI(accountId) {
    return this.props.actions.client.unsubscribeFromAI(accountId)
      .then((res) => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch(this.context.logError)
  }

  openCloseAllTradesModal(accountId) {
    this.props.uiDispatch(
      `Open Confirm closing all positions on account ${accountId}`,
      (state) => ({
        ...state,
        closeAllTradesModal: {
          show: true,
          accountId,
        },
      })
    )
  }

  removeFromFallback(accountId) {
    this.props.actions.client.removeFromFallback(accountId)
      .then(() => {
        this.context.clientProvider.subProviders.accounts.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch(this.context.logError)
  }

  render() {
    const {
      account, deposits, bonuses, withdrawals = 0, viewer, uiDispatch,
      client: {
        personalDetails: {clientType, allowCopyTrading, linkedPartnerClientId},
        generalInfo: {underMonitoring, tradingStatus, company},
      }
    } = this.props
    const canTrade = get(tradingStatuses, [tradingStatus, 'canTrade'])
    const createdAt = account.createdAt.clone()
    const accCategoriesToDelete = ['forexMt5', 'forexMt4', 'tradeSmarter']
    const canToggleAffiliatesAutomation = canToggleAffiliateAutomaticCommissions(viewer)
    const canEditAnAccount = canWriteClientAccount(viewer)
    const canEditMobileAccount = canEditAccountIsMobile(viewer)
    const isAffiliateAccount = accountTypes[account.__typename].category === 'affiliate'
    const productCompany = find(companies, {value: accountTypes[account.__typename].company})
    const getRegulator = get(accountTypes[account.__typename], 'regulator.value', 'Unknown')
    const showLeverageLabel = ['forexMt5', 'forexMt4'].includes(accountTypes[account.__typename].category) &&
        !['bond', 'ib'].includes(accountTypes[account.__typename].subCategory) &&
    (getRegulator === regulators.cysec.value) ? 'per asset' : account.leverage && `1:${account.leverage}`
    const isIbAccount = accountTypes[account.__typename].subCategory === 'ib'
    const minDepositLabel = get(account, 'isReadOnly', false) && includes([accountSubtypes.black.key, accountSubtypes.raw.key], get(account, 'accountSubtype', '')) &&
  get(account, 'totalDeposits', 0) < get(account, 'minimumDeposit', 0)
    const showCopyingStrategy = (!canToggleCopyTrading(viewer) && get(account, 'copyTrading.isActive', false)) ||
    (canToggleCopyTrading(viewer) && !isEmpty(get(account, 'copyTrading')) && !get(account, 'copyTrading.stopCopyingDate')) ||
    (!isEmpty(account.copyTradingStrategies))
    const accountType = accountTypes[account.__typename]
    const copyTradingClientTypes = [
      clientTypes.ib.key,
      clientTypes.ibCorporate.key,
      clientTypes.affiliate.key,
      clientTypes.affiliateCorporate.key,
    ]
    const showPerformanceFee = !accountType.isDemo && accountType.category === 'forexMt4' &&
    !['ib', 'bdx'].includes(accountType.subCategory) && account.accountSubtype === accountSubtypes.basic.key &&
    ((allowCopyTrading && copyTradingClientTypes.includes(clientType)) || !isNil(linkedPartnerClientId))
    const activeCompetition = find(account.competitions, {active: true})
    const accountProviding = get(account, 'provideCopyTrading')
    const currentStrategyAccountId = get(account.copyTrading, 'isActive') ? get(account.copyTrading, 'accountCopying.accountId') : ''
    const currentStrategy = find(account.copyTradingStrategies, {providerAccountId: currentStrategyAccountId})
    const liveForexMT4ProductIds = map(filter(accountTypes, (t) =>
      t.category === 'forexMt4' && !t.isDemo && t.subCategory !== 'ib'
    ), 'productId')
    const centProductIds = map(filter(accountTypes, (t) => t.category === 'cent'), 'productId')

    const dropdownButtons = []

    if ((canToggleAffiliatesAutomation && isAffiliateAccount) || canEditAnAccount || canEditMobileAccount) {
      dropdownButtons.push(<Dropdown.Item
        id={`t-edit-account-${account.id}`}
        onClick={() => this.editAccountSidebar(account.id)}
      >Edit</Dropdown.Item>)
    }

    if (canDeleteAccount(viewer) &&
    !account.deletedAt && includes(accCategoriesToDelete, accountTypes[account.__typename].category)
    ) {
      dropdownButtons.push(<Dropdown.Item
        id={`t-delete-account-${account.id}`}
        onClick={() => this.openDeleteAccountModal(account.id, isIbAccount)}
      >Delete</Dropdown.Item>)
    }

    if ((viewer.isClient || canSubscribeClientToAi(viewer)) && accountType.category === 'forexMt4Ai') {
      dropdownButtons.push(<Dropdown.Item
        id={`t-toggle-account-${account.id}`}
        onClick={() => toUpper(account.accountSubtype) === accountSubtypes.ai.value ?
          this.unsubscribeFromAI(account.id) : this.subscribeToAI(account.id)
        }
      >{toUpper(account.accountSubtype) === accountSubtypes.ai.value ? 'Unsubscribe' : 'Subscribe'}</Dropdown.Item>)
    }

    if (canCloseClientPositions(viewer) &&
    accountType.category === 'forexMt4' &&
    account.subCategory !== 'ib' &&
    (underMonitoring || !canTrade) &&
    companyCheck.isBdSwissBrand(company)
    ) {
      dropdownButtons.push(<Dropdown.Item
        id={`t-closeAllTrades-account-${account.id}`}
        onClick={() => this.openCloseAllTradesModal(account.id)}
      >Close all positions</Dropdown.Item>)
    }

    if (canClearAccountCache(viewer) && liveForexMT4ProductIds.includes(accountType.productId)) {
      dropdownButtons.push(<Dropdown.Item
        id={`t-clear-account-cache-${account.id}`}
        onClick={() => this.props.handleClearAccountCacheClick(account.id)}
      >Clear cache</Dropdown.Item>)
    }

    if (canTakeOpenPositionsSnapshot(viewer) &&
      [...liveForexMT4ProductIds, ...centProductIds].includes(accountType.productId)
    ) {
      dropdownButtons.push(<Dropdown.Item
        id={`t-take-snapshot-${account.id}`}
        onClick={() => this.props.takeOpenPositionsSnapshot(account.id)}
      >Take OP Snapshot</Dropdown.Item>)
    }

    const actionsButton = dropdownButtons.length > 0
      ? <DropdownButton
        variant="link"
        title="Actions"
        key="dropdown"
        size="sm"
        id={`t-client-details-more-actions-${account.id}`}
        style={{color: '#ef3b28'}}
      >
        {dropdownButtons.map((button, i) => ({...button, key: `ddb-${i}`}))}
      </DropdownButton>
      : <div />
    const copyTradingClientType = copyTradingClientTypes.includes(clientType)

    return (<div key={account.id} className={`${style.account} t-client-account`}>
      <Row>
        <Col xs={6}>
          <dl className="dl-horizontal">
            <dt>Account</dt>
            <dd className={classnames('t-account-name', {[style.strikethrough]: account.deletedAt})}>
              {getAccountLabel(accountTypes[account.__typename])}
              {accountProviding && <span className={style.providing}>Providing</span>}
            </dd>
            {account.accountSubtype && [
              <dt key={1}>Account Type</dt>,
              <dd className={'t-account-type'} key={2}>{accountSubtypes[account.accountSubtype].label}</dd>,
            ]}
            {accountTypes[account.__typename].subCategory === 'bdx' && [
              <dt key={1}>Sub Type</dt>,
              <dd className={'t-account-sub-type'} key={2}>BDX</dd>,
            ]}
            {has(account, 'server') && account.server && [
              <dt key={1}>Server</dt>,
              <dd key={2} id={`account-server-${account.id}`}>
                {get(mt4Servers[account.server], `name[${productCompany.key}]`)}
              </dd>,
            ]}
            <dt>Remote ID</dt>
            <dd>{account.remoteId}</dd>
            {accountTypes[account.__typename].subCategory === 'ib' && [
              <dt key={1}>IB ID</dt>,
              <dd key={2} id={`account-ibId-${account.id}`}>
                {get(account, 'ibId', []).join(', ')}&nbsp;
              </dd>
            ]}
          </dl>
        </Col>
        <Col xs={6}>
          <div className="pull-right">
            {actionsButton}
          </div>
          <dl className="dl-horizontal long-title">
            {has(account, 'subscription') && !get(account, 'subscription') && [
              <dt key={1}>Has Subscription</dt>,
              <dd key={2}>
                <span className={style.deleteAccount}>{yesNo.no.label}</span>
              </dd>,
            ]}
            {accountTypes[account.__typename].category !== 'cryptocoin' &&
              accountTypes[account.__typename].category !== 'gold' ? [
                <dt key={1}>Balance</dt>,
                <dd key={2}>
                  <span style={account.balance < 0 ? {background: 'yellow'} : {}}>
                    {formatAccountValue(account, 'balance')}
                  </span>
                </dd>,
              ] :
              accountTypes[account.__typename].category === 'gold' ? [
                <dt key={1}>Gold</dt>,
                <dd key={2}>{account.gold} oz / {parseFloat(account.gold * 28.3495).toFixed(5)} gr</dd>,
              ] : [
                <dt key={1}>Coins</dt>,
                <dd key={2}>{account.coins}</dd>,
                <dt key={3}>Withdrawable Balance</dt>,
                <dd key={4}>{formatAccountValue(account, 'balance')}</dd>,
                <dt key={5}>Non Withdrawable Balance</dt>,
                <dd key={6}>{formatAccountValue(account, 'bonusBalance')}</dd>,
                <dt key={7}>Potential FX Bonus</dt>,
                <dd key={8}>{formatAccountValue(account, 'bonusFXBalance')}</dd>,
                <dt key={9}>Wallet Address</dt>,
                <dd key={10}>{account.walletAddress}</dd>,
                <dt key={11}>Deposit Wallet Address</dt>,
                <dd key={12}>{account.depositWalletAddress}</dd>,
              ]}
            {'openPositionsCount' in account && [
              <dt key={1}>Open Positions</dt>,
              <dd key={2}>{account.openPositionsCount}</dd>,
            ]}
            <dt>Total Deposits</dt>
            <dd>{getFormattedAmount({amount: deposits || 0, currency: account.currency})}</dd>
          </dl>
        </Col>
        <Col xs={12} className="text-center">
          <Button
            variant="link"
            size="sm"
            className={style.accountCollapseButton}
            onClick={() => {
              if (!this.state.expanded) {
                this.context.clientProvider.subProviders.accountsForexData.fetch().then(() => {
                  this.setState({expanded: !this.state.expanded})
                })
              } else {
                this.setState({expanded: !this.state.expanded})
              }
              this.props.onExpand(!this.state.expanded)
            }}
          >
            {this.state.expanded ? 'Less' : 'More'} <FontAwesomeIcon icon={`angle-${this.state.expanded ? 'up' : 'down'}`} />
          </Button>
        </Col>
      </Row>
      <Collapse in={this.state.expanded}>
        <Row>
          <Col xs={6}>
            <dl className="dl-horizontal">
              <dt>Local ID</dt>
              <dd>{account.id}</dd>
              {'isViewOnly' in account && [
                <dt key={1}>View Only</dt>,
                <dd key={2} id={`account-view-only-${account.id}`}>
                  {account.isViewOnly ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              <dt>Regulator</dt>
              <dd>{get(accountTypes[account.__typename], 'regulator.label', 'Unknown')}</dd>
              <dt>Created</dt>
              <dd title={readableDate(createdAt)}>{createdAt.fromNow()}</dd>
              <dt>Currency</dt>
              <dd>{account.currency && currencies[account.currency].label}</dd>
              {accountType.category === 'cryptoWallet' && [
                <dt key={1}>Wallet Address</dt>,
                <dd key={2}>{get(account, 'walletAddress')}</dd>,
              ]}
              {'leverage' in account && [
                <dt key={1}>Leverage</dt>,
                <dd key={2} id={`account-leverage-${account.id}`}>{showLeverageLabel}&nbsp;</dd>,
              ]}
              {'margin' in account && [
                <dt key={1}>Margin</dt>,
                <dd key={2} id={`account-margin-${account.id}`}>{formatAccountValue(account, 'margin')}&nbsp;</dd>,
              ]}
              {'freeMargin' in account && [
                <dt key={1}>Free Margin</dt>,
                <dd key={2} id={`account-free-margin-${account.id}`}>{formatAccountValue(account, 'freeMargin')}&nbsp;</dd>,
              ]}
              {'marginLevel' in account && [
                <dt key={1}>Margin Level</dt>,
                <dd key={2} id={`account-margin-level-${account.id}`}>{formatPercentValue(account, 'marginLevel', '—')}&nbsp;</dd>,
              ]}
              {'hidden' in account && [
                <dt key={1}>Hidden</dt>,
                <dd key={2} id={`account-hidden-${account.id}`}>
                  {account.hidden ? yesNo.yes.label : yesNo.no.label}&nbsp;
                </dd>,
              ]}
              {'isEnabled' in account && [
                <dt key={1} className={(account.readOnlyDealer) ? style.readOnlyDealer : ''}> Enabled </dt>,
                <dd key={2} style={{textTransform: 'capitalize'}} id={`account-enabled-${account.id}`}>
                  <span className={(account.readOnlyDealer) ? style.readOnlyDealer : ''}>
                    {account.isEnabled !== null ? account.isEnabled ? yesNo.yes.label : yesNo.no.label : 'Unknown'}
                  </span>&nbsp;
                </dd>,
              ]}
              {'isReadOnly' in account && [
                <dt key={1} className={(account.readOnlyDealer) ? style.readOnlyDealer : ''}>ReadOnly</dt>,
                <dd key={2} style={{textTransform: 'capitalize'}} id={`account-readonly-${account.id}`}>
                  <span className={(account.readOnlyDealer) ? style.readOnlyDealer : ''}>{account.isReadOnly !== null ? account.isReadOnly ? yesNo.yes.label : yesNo.no.label : 'Unknown'}</span>&nbsp;
                  {account.isReadOnly && !isEmpty(account.readOnlyNote) && `(${account.readOnlyNote})`}
                  {minDepositLabel && <small className={style.ip}>(Min. deposit not reached)</small>}&nbsp;
                </dd>,
              ]}
              {'swapFree' in account && [
                <dt key={1}>Swap Free</dt>,
                <dd key={2} id={`account-swapFree-${account.id}`}>
                  {account.swapFree ? yesNo.yes.label : yesNo.no.label}
                </dd>
              ]}
              {has(account, 'leverageChangedBasedOnEquity') && [
                <dt key={1} title="Leverage Changed">Leverage Changed</dt>,
                <dd key={2} id={`account-leverage-changed-${account.id}`}>
                  {account.leverageChangedBasedOnEquity ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {accountTypes[account.__typename].subCategory === 'ib' && [
                <dt key={1}>Parent IB</dt>,
                <dd key={2} id={`account-parentIbId-${account.id}`}>
                  {account.parentIbId}&nbsp;
                </dd>
              ]}
              {'deletedAt' in account && account.deletedAt && [
                <dt key={1}>Deleted</dt>,
                <dd key={2} id={`account-delete-${account.id}`}>
                  <span className={style.deleteAccount}>
                    {account.deletedAt ? yesNo.yes.label : yesNo.no.label}</span>
                </dd>,
              ]}
            </dl>
          </Col>
          <Col xs={6}>
            <dl className="dl-horizontal long-title">
              {'withdrawableBalance' in account && [
                <dt key={1}>Withdrawable Balance</dt>,
                <dd key={2}>{formatAccountValue(account, 'withdrawableBalance')}</dd>,
              ]}
              {'activatedBalance' in account && [
                <dt key={1}>Activated Balance</dt>,
                <dd key={2}>{formatAccountValue(account, 'activatedBalance')}</dd>,
              ]}
              {has(account, 'balanceInfo.availableBalance') && [
                <dt key={1}>Available Balance</dt>,
                <dd key={2}>{formatAccountValue(account, 'balanceInfo.availableBalance')}</dd>,
              ]}
              {has(account, 'balanceInfo.heldBalance') && [
                <dt key={1}>Held Balance</dt>,
                <dd key={2}>{formatAccountValue(account, 'balanceInfo.heldBalance')}</dd>,
              ]}
              {'credit' in account && [
                <dt key={1}>Credit/Bonus</dt>,
                <dd key={2}>
                  {formatAccountValue(account, 'credit')}
                  <div className="float-right">
                    {account.credit > 0 && canAddBonus(viewer) &&
                    <a
                      id={`t-add-credit-account-${account.id}`}
                      className={style.editDeleteAccountLink}
                      onClick={() => {
                        uiDispatch(
                          `Open Confirm Add bonus for ${account.id}`,
                          (state) => ({
                            ...state,
                            bonusModal: {
                              show: true,
                              accountId: account.id,
                              type: 'add',
                            },
                          })
                        )
                      }}
                    >
                      Add
                    </a>
                    }
                    {account.credit > 0 && canRemoveBonus(viewer) &&
                    <a
                      id={`t-remove-credit-account-${account.id}`}
                      className={style.editDeleteAccountLink}
                      onClick={() => {
                        uiDispatch(
                          `Open Confirm Remove bonus for ${account.id}`,
                          (state) => ({
                            ...state,
                            bonusModal: {
                              show: true,
                              accountId: account.id,
                              type: 'remove',
                            },
                          })
                        )
                      }}
                    >
                      Remove
                    </a>
                    }
                  </div>
                </dd>,
              ]}
              {'profitAndLoss' in account && [
                <dt key={1}>P&amp;L</dt>,
                <dd key={2}>{formatAccountValue(account, 'profitAndLoss')}</dd>,
              ]}
              {'profitAndLossFloating' in account && [
                <dt key={1}>Floating P&amp;L</dt>,
                <dd key={2}>{formatAccountValue(account, 'profitAndLossFloating')}</dd>,
              ]}
              {'profitAndLossClosed' in account && [
                <dt key={1}>Closed P&amp;L</dt>,
                <dd key={2}>{formatAccountValue(account, 'profitAndLossClosed')}</dd>,
              ]}
              {'turnoverClosed' in account && [
                <dt key={1}>Closed Turnover</dt>,
                <dd key={2}>{formatAccountValue(account, 'turnoverClosed')}&nbsp;</dd>,
              ]}
              {'volumeClosed' in account && [
                <dt key={1}>Closed Turnover</dt>, // Forex volume is displayed as turnover
                <dd key={2}>{account.volumeClosed}&nbsp;</dd>,
              ]}
              {'pendingPositionsCount' in account && [
                <dt key={1}>Pending Orders</dt>,
                <dd key={2}>{account.pendingPositionsCount}</dd>,
              ]}
              <dt>Total Bonuses</dt>
              <dd>{getFormattedAmount({amount: bonuses || 0, currency: account.currency})}</dd>
              <dt>Total Withdrawals</dt>
              <dd>{
                accountTypes[account.__typename].category === 'gold' ?
                  `${withdrawals} oz / ${parseFloat(withdrawals * 28.3495).toFixed(5)} gr` :
                  getFormattedAmount({amount: withdrawals || 0, currency: account.currency})
              }
              </dd>
              <dt>Mobile</dt>
              <dd id={`account-mobile-${account.id}`}>
                {account.mobile ? yesNo.yes.label : yesNo.no.label}
              </dd>
              {has(account, 'approved') && [
                <dt key={1}>Approved</dt>,
                <dd key={2} id={`account-is-approved-${account.id}`}>
                  {account.approved ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {has(account, 'isArchived') && [
                <dt key={1}>Archived</dt>,
                <dd key={2} id={`account-archived-${account.id}`}>
                  {account.isArchived ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {has(account, 'accountName') && [
                <dt key={1}>Account Name</dt>,
                <dd key={2} id={`account-account-name-${account.id}`}>
                  {account.accountName}&nbsp;
                </dd>,
              ]}
              {has(account, 'copyTradingStrategies') && [
                <dt key={1}>Copy Trading Enabled</dt>,
                <dd key={2} id={`account-copy-trading-enabled-${account.id}`}>
                  {!isEmpty(account.copyTradingStrategies) ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {has(account, 'isOnMailingList') && liveForexMT4ProductIds.includes(accountType.productId) && [
                <dt key={1}>MT4 Reports</dt>,
                <dd key={2} id={`account-is-on-mailing-list-${account.id}`}>
                  {account.isOnMailingList ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {accountProviding  && get(account, 'providerStartDate') && [
                <dt key={1}>Provider Start Date</dt>,
                <dd key={2} id={`account-provider-start-date-${account.id}`}>
                  {moment(account.providerStartDate).format('DD / MM / YYYY')}&nbsp;
                </dd>,
              ]}
              {showPerformanceFee && [
                <dt key={1}>Performance Fee</dt>,
                <dd key={2} id={`account-performance-fee-${account.id}`}>
                  {account.performanceFee > -1 ? `${account.performanceFee} %` : '-'}
                </dd>,
              ]}
              {showCopyingStrategy && [
                <dt key={1}>Copying</dt>,
                <dd key={2} id={`account-allow-copy-trading-${account.id}`}
                  className={canToggleCopyTrading(viewer) ? style.updateCopyTrading : ''}>
                  {canToggleCopyTrading(viewer) && <StylishSelect
                    value={currentStrategyAccountId}
                    options={StylishSelect.enumToStylishOptions(map(account.copyTradingStrategies, (s) => ({
                      key: s.providerAccountId,
                      label: `${s.accountName} (${s.remoteId})`,
                    })), 'N/A')}
                    onChange={(e) => this.props.onSelectNewStrategy(e, account)}
                  />}
                  {!canToggleCopyTrading(viewer) &&
                  <strong>{currentStrategyAccountId
                    ? `${get(currentStrategy, 'accountName')} (${get(currentStrategy, 'remoteId')})`
                    : 'N/A'}
                  </strong>
                  }
                </dd>,
              ]}
              {showCopyingStrategy && [
                <dt key={1}>Withdrawals Blocked</dt>,
                <dd key={2} id={`account-blocked-widthdrawals-${account.id}`}>
                  {account.withdrawalsBlocked ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {copyTradingClientType && [
                <dt key={1} className="dt-horizontal space-wrap">Password Protected Strategy</dt>,
                <dd key={2} className="dd-horizontal space-wrap" id={`account-password-protected-${account.id}`}>
                  <span>
                    {account.passwordProtectedStrategies ? yesNo.yes.label : yesNo.no.label}</span>
                </dd>,
              ]}
              {!get(account, 'bonus.bonusLost') && get(account, 'bonus.requiredLots') > 0 && [
                <dt key={1}>Lots To Trade (Bonus)</dt>,
                <dd key={2} id={`account-lots-to-trade-bonus-${account.id}`}>
                  {get(account, 'bonus.requiredLots')}&nbsp;
                </dd>,
              ]}
              {!get(account, 'bonus.bonusLost') && get(account, 'bonus.requiredLots') > 0 && has(account, 'bonus.lotsTraded') && [
                <dt key={1}>Lots Traded (Bonus)</dt>,
                <dd key={2} id={`account-lots-traded-bonus-${account.id}`}>
                  {get(account, 'bonus.lotsTraded')}&nbsp;
                </dd>,
              ]}
              {has(account, 'lotsToTrade') && [
                <dt key={1}>Lots To Trade</dt>,
                <dd key={2} id={`account-lots-to-trade-${account.id}`}>
                  {account.lotsToTrade}&nbsp;
                </dd>,
              ]}
              {has(account, 'lotsTraded') && [
                <dt key={1}>Lots Traded</dt>,
                <dd key={2} id={`account-lots-traded-${account.id}`}>
                  {account.lotsTraded}&nbsp;
                </dd>,
              ]}
              {has(account, 'volumeTraded') && [
                <dt key={1}>Volume Traded</dt>,
                <dd key={2} id={`account-volume-traded-${account.id}`}>
                  {getFormattedAmount({amount: account.volumeTraded || 0, currency: 'USD'})}&nbsp;
                </dd>,
              ]}
              {has(account, 'isInFallback') && [
                <dt key={1}>Fallback group</dt>,
                <dd key={2} id={`account-is-in-fallback-${account.id}`}>
                  <div className="float-right">
                    {canEditAnAccount && account.isInFallback &&
                    <a
                      id={`t-remove-from-fallback-${account.id}`}
                      className={style.editDeleteAccountLink}
                      onClick={() => this.removeFromFallback(account.id)}
                    >
                      Remove from Fallback
                    </a>
                    }
                  </div>
                  {account.isInFallback ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {has(account, 'isInOverTheLimit') && get(account, 'isInOverTheLimit') && [
                <dt key={1}>Over-the-limit group</dt>,
                <dd key={2} id={`account-is-in-over-the-limit-${account.id}`}>
                  {account.isInOverTheLimit ? yesNo.yes.label : yesNo.no.label}
                </dd>,
              ]}
              {'automaticCommissions' in account && [
                <dt key={1}>Auto Commissions</dt>,
                <dd key={2}>{account.automaticCommissions ? yesNo.yes.label : yesNo.no.label}</dd>,
              ]}
              {get(account, 'serviceFields.affiliateStatus') && [
                <dt key={1}>Cellxperts Status</dt>,
                <dd key={2}>{account.serviceFields.affiliateStatus}&nbsp;</dd>,
              ]}
              {get(account, 'serviceFields.parentAffiliateId') && [
                <dt key={1}>Parent Affiliate ID</dt>,
                <dd key={2}>{account.serviceFields.parentAffiliateId}&nbsp;</dd>,
              ]}
              {activeCompetition && [
                <dt key={1}>Trading Competition</dt>,
                <dd key={2} id={`account-competition-${account.id}`}>
                  {get(activeCompetition, 'competitionDetails.name', '-')}&nbsp;
                </dd>,
              ]}
              {get(activeCompetition, 'acceptTerms') && [
                <dt key={1}>Competition Terms</dt>,
                <dd key={2} id={'competition-terms'}>
                  {get(activeCompetition, 'acceptTerms', '-')}&nbsp;
                </dd>,
              ]}
            </dl>
          </Col>
          <Col xs={12} className={style.dividerDiv}></Col>
          {get(account, 'subscription') &&
          <Col xs={6}>
            <dl className="dl-horizontal">
              {get(account, 'subscription.nextPaymentDate') && [
                <dt className={style.dtSubscripton} key={1}>Next payment date</dt>,
                <dd key={2} id={`account-next-payment-date-${account.id}`}>
                  {readableDate(moment(account.subscription.nextPaymentDate))}&nbsp;
                </dd>,
              ]}
              {has(account, 'subscription.lastPaymentFailed') && [
                <dt className={style.dtSubscripton} key={1}>Last payment failed</dt>,
                <dd key={2} id={`account-last-payment-failed-${account.id}`}>
                  <span className={account.subscription.lastPaymentFailed ? style.deleteAccount : ''}>
                    {account.subscription.lastPaymentFailed ? yesNo.yes.label : yesNo.no.label}
                  </span>
                </dd>,
              ]}
            </dl>
          </Col>}
          {get(account, 'subscription') &&
          <Col xs={6}>
            <dl className="dl-horizontal long-title">
              {get(account, 'subscription.cancelledAt') && [
                <dt key={1}>Cancelled at</dt>,
                <dd key={2} id={`account-next-cancelled-at-${account.id}`}>
                  {readableDate(moment(account.subscription.cancelledAt))}&nbsp;
                </dd>,
              ]}
              {get(account, 'subscription.pendingPlan') && [
                <dt key={1}>Pending Plan</dt>,
                <dd key={2} id={`account-pending-plan-${account.id}`}>
                  {rawSubscriptionPlans[account.subscription.pendingPlan].label}&nbsp;
                </dd>,
              ]}
              {get(account, 'subscription.terminatedAt') && [
                <dt key={1}>Terminated At</dt>,
                <dd key={2} id={`account-terminated-at-${account.id}`}>
                  {readableDate(moment(account.subscription.terminatedAt))}&nbsp;
                </dd>,
              ]}
            </dl>
          </Col>
          }
        </Row>
      </Collapse>
    </div>)
  }
}
