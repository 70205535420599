import React from 'react'
import {Route, Switch} from 'react-router-dom'
import App from './App'
import ResetPassword from './ResetPassword'

const Routes = () => (
  <Switch>
    <Route component={ResetPassword} path='/reset-password/:token' />
    <Route component={App} path="/"></Route>
  </Switch>
)

export default Routes
