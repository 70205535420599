import {get} from 'lodash'
import {competitionRules} from '@bdswiss/common-enums'

export default function create(dbClient) {
  return {
    upsertCompetition(competition) {
      const {id, name, startDate, endDate, prizes, icon, termsLink,
        applicableClientTypes, applicableCompanies, applicableCountries, acceptPreviousWinners, limitedSymbols, excludedAffiliates, allowedAffiliates, competitionWinner,
      } = competition

      const rules = []
      for (let i = 0; i < competition.rulesCount; i++) {
        const formRule = get(competition.rules, `rule${i}`)
        const rule = competitionRules[formRule]
        const values = {}
        for (const value of rule.values) {
          values[value] = competition[`rule${i}-${value}`]
        }
        rules.push({
          rule: formRule,
          values,
        })
      }

      const variables = {
        id, name, startDate, endDate, prizes: JSON.stringify(prizes), icon, termsLink,
        applicableClientTypes, applicableCompanies, applicableCountries, rules: JSON.stringify(rules),
        acceptPreviousWinners, limitedSymbols, excludedAffiliates, allowedAffiliates, competitionWinner,
      }

      const query = `
        mutation (
          $id: Int
          $name: String!
          $startDate: UnsetOrDateTime
          $endDate: UnsetOrDateTime
          $prizes: String
          $icon: String
          $termsLink: String
          $applicableClientTypes: [ClientType]
          $applicableCompanies: [Company]
          $applicableCountries: [Country]
          $rules: String
          $acceptPreviousWinners: Boolean
          $limitedSymbols: String
          $excludedAffiliates: String
          $allowedAffiliates: String
          $competitionWinner: String
        ) {
          upsertCompetition(
            id: $id
            name: $name
            startDate: $startDate
            endDate: $endDate
            prizes: $prizes
            icon: $icon
            termsLink: $termsLink
            applicableClientTypes: $applicableClientTypes
            applicableCompanies: $applicableCompanies
            applicableCountries: $applicableCountries
            rules: $rules
            acceptPreviousWinners: $acceptPreviousWinners
            limitedSymbols: $limitedSymbols
            excludedAffiliates: $excludedAffiliates
            allowedAffiliates: $allowedAffiliates
            competitionWinner: $competitionWinner
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    signUploadUrl(competitionName) {
      const query = `mutation($competitionName:String!) {
        signCompetitionFileUrl(competitionName: $competitionName) {
          key
          plainUrl
          signedUrl
        }
      }`

      return dbClient.query(query, {competitionName})
    },

    exportData(competitionId) {
      const query = `mutation($competitionId: Int!) {
        exportCompetitionData(id: $competitionId)
      }`

      return dbClient.query(query, {competitionId})
    },
  }
}
