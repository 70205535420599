import {useState} from 'react'
import {Form, Table, Modal, ButtonToolbar, Button} from 'react-bootstrap'
import classnames from 'classnames'

import {rolesProvider} from './../../Roles/providers'
import {compose, mountDataProviders, provideProps} from '../../decorators'

import styles from './PermissionEditModal.module.scss'

const PermissionEditModal = ({
  permission,
  roles,
  onSubmit,
  onCancel,
  actions,
}) => {
  const [selectedRoles, setSelectedRoles] = useState(() => {
    if (permission.roles) {
      return permission.roles.reduce((summ, role) => {
        summ[role.id] = true
        return summ
      }, {})
    } else {
      return {}
    }
  })

  const handleCreate = async () => {
    const id = permission.id
    const rolesList = Object.keys(selectedRoles).filter(
      (role) => selectedRoles[role]
    )
    const permissionData = {
      id,
      roles: rolesList,
    }

    try {
      await actions.permissions.updatePermission(permissionData)
      onSubmit()
    } catch (err) {
      // eslint-disable-next-line no-console
      console.log(err)
    }
  }

  return (
    <Modal keyboard show={true} onHide={onCancel} scrollable={true}>
      <Modal.Header closeButton>
        <strong>Edit Permission</strong>
      </Modal.Header>
      <Modal.Body>
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" value={permission.name} disabled />
        </Form.Group>
        <Form.Group>
          <Form.Label>Description</Form.Label>
          <Form.Control type="text" value={permission.description} disabled />
        </Form.Group>
        <Table bordered hover>
          <thead>
            <tr>
              <th>
                <span>Name</span>
              </th>
              <th>
                <span>Description</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {roles.map(({id, name, description}) => (
              <tr
                key={id}
                id={id}
                className={classnames(
                  'clickable',
                  selectedRoles[id] && styles['selectedRow']
                )}
                onClick={() =>
                  setSelectedRoles((state) => ({
                    ...state,
                    [id]: !state[id],
                  }))
                }
              >
                <td>{name}</td>
                <td>{description}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer>
        <ButtonToolbar className="float-right">
          <Button
            variant="outline-secondary"
            onClick={onCancel}
            className="mr-3"
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={handleCreate}>
            Update
          </Button>
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  )
}

export default compose(
  provideProps(({roles}) => ({roles})),
  mountDataProviders({rolesProvider})
)(PermissionEditModal)
