import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class SendInvitation extends PureComponent {

  componentWillMount() {
    this.setState({sending: false})
  }

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  sendInvitation = () => {
    this.setState({sending: true})
    return this.props.actions.client.sendInvitation(this.props.clientId)
      .then(() => {
        this.setState({sending: false})
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.props.onClose()
      })
      .catch((e) => {
        this.setState({sending: false})
        this.context.logError(e)
      })
  }

  render() {
    const {onClose} = this.props
    const {sending} = this.state

    return (
      <Card
        id="t-client-send-invitation-sidebar"
        key={1}
      >
        <Card.Header><strong>WhatsApp Invitation</strong></Card.Header>
        <Card.Body>
          <Row>
            <Col md={12} xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-send-invitation-close"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >Close
                </Button>
                <Button
                  id="t-client-send-invitation-generate"
                  variant="success"
                  size="sm"
                  className="mr-1"
                  onClick={this.sendInvitation}
                  disabled={sending}
                >
                  {sending && <FontAwesomeIcon icon={'spinner'} className="fa-spin" />}
                  {sending ? 'Sending' : 'Send Invitation'}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(SendInvitation)
