import {jobActions, accountTypes} from '@bdswiss/common-enums'
import {getDormantFeeJobType} from '../common/utils'

const initiateApplyDormantFeeJob = async (dbClient, params) => {
  const {accountType, executeAsBonus, inputFileUrl, type} = params
  const variables = {
    accountType,
    executeAsBonus,
    inputFileUrl,
    type,
  }

  const query = `
    mutation (
      $inputFileUrl:String!,
      $accountType:AccountType!,
      $executeAsBonus:Boolean!
      $type:InactivityFeeType
    ) {
        initiateApplyDormantFeeJob(
          accountType:$accountType
          inputFileUrl:$inputFileUrl
          executeAsBonus:$executeAsBonus
          type:$type
        )
    }
  `
  return dbClient.query(query, variables)
}

const initiateApplyAffiliateCommissionsJob = async (dbClient, params) => {
  const {accountType, executeAsBonus, inputFileUrl} = params
  const variables = {
    accountType,
    executeAsBonus,
    inputFileUrl,
  }

  const query = `
    mutation (
      $inputFileUrl:String!,
      $accountType:AccountType!,
      $executeAsBonus:Boolean!
    ) {
      initiateApplyAffiliateCommissionsJob(
          accountType:$accountType
          inputFileUrl:$inputFileUrl
          executeAsBonus:$executeAsBonus)
    }
  `
  return dbClient.query(query, variables)
}

const initiateRevertDormantFeeJob = async (dbClient, params) => {
  const {accountType, executeAsBonus, inputFileUrl, type} = params
  const variables = {
    accountType,
    executeAsBonus,
    inputFileUrl,
    type,
  }

  const query = `
    mutation (
      $inputFileUrl:String!,
      $accountType:AccountType!,
      $executeAsBonus:Boolean!
      $type:InactivityFeeType
    ) {
        initiateRevertDormantFeeJob(
          accountType:$accountType
          inputFileUrl:$inputFileUrl
          executeAsBonus:$executeAsBonus
          type:$type
        )
    }
  `
  return dbClient.query(query, variables)
}


export default function create(dbClient, dispatch) {
  return {
    initiateDormantFeeJob: async (params) => {
      const {action, accountType} = params
      switch (action) {
        case jobActions.apply.key:
          if (accountType === 'AffiliateAccount') {
            return initiateApplyAffiliateCommissionsJob(dbClient, params)
          } else {
            return initiateApplyDormantFeeJob(dbClient, params)
          }
        case jobActions.revert.key:
          return initiateRevertDormantFeeJob(dbClient, params)
        default:
          throw new Error('initiateDormantFeeJob: Invalid Job Type')
      }
    },

    getSignedInputFileUrl: async (accountType) => {
      const jobType = getDormantFeeJobType(accountTypes[accountType].value)

      if (!jobType) throw new Error(`Unknown accountType ${accountType}`)

      const variables = {
        jobType,
      }

      const query = `
        mutation(
          $jobType:JobType!
        ) {
          signInputFileUrl(jobType:$jobType) {
            key
            plainUrl
            signedUrl
          }
        }
      `
      return dbClient.query(query, variables)
    },
  }
}
