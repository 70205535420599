import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar, Form, InputGroup} from 'react-bootstrap'
import PureComponent from '../PureComponent'

export default class LogInAsUserModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    user: PropTypes.object.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  state = {}

  render() {
    const {show, onConfirm, onCancel, user} = this.props
    const {loginReason = ''} = this.state
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Login to crm as {user.firstName}</strong>
        </Modal.Header>
        <Modal.Body>
          <InputGroup>
            <Form.Control
              type="text"
              value={loginReason}
              placeholder="Enter login reason"
              onChange={(e) => this.setState({loginReason: e.target.value})}
              autoFocus
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button onClick={onCancel} className="mr-3" variant="outline-secondary">
              Cancel
            </Button>
            <Button variant="success" onClick={() => onConfirm(loginReason)}>
              Login
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
