import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Badge} from 'react-bootstrap'
import {includes, map, isEmpty} from 'lodash'
import PureComponent from '../../PureComponent'
import style from '../communications/Communications.module.scss'
import AutoHeightIframe from './AutoHeightIframe'
import FontAwesomeIcon from '../../components/FontAwesomeIcon'

export default class EmailDetail extends PureComponent {
  static contextTypes = {
    config: PropTypes.object.isRequired,
  }

  render() {
    const {thread} = this.props
    const {backendUrl} = this.context.config
    return (
      <div>
        {thread.map(({id, from, to, cc, bcc, date, subject, body, labelIds, attachments}) =>
          <div key={id}>
            <Row className={style.emailHeaders}>
              <Col xs={6}>
                <h6>{!isEmpty(attachments) && <small><FontAwesomeIcon icon="paperclip" /></small>} {from}</h6>
                <h6>{subject}{includes(labelIds, 'TRASH') && <span className={style.deleted}> - DELETED</span>}</h6>
                <div>To: {to}</div>
                {cc && <div>Cc: {cc}</div>}
                {bcc && <div>Bcc: {bcc}</div>}
                {!isEmpty(attachments) && <div>Attachments: {map(attachments, ({attachmentId, filename, data, mime}) => data
                  ? <a href={`data:${mime};base64,${data}`} key={attachmentId} target="_blank" rel="noopener noreferrer" className={style.attachment}>
                    <Badge pill>{filename}</Badge>
                  </a>
                  : <a href={`${backendUrl}/attachment?messageId=${id}&attachmentId=${attachmentId}`} key={attachmentId} target="_blank" rel="noopener noreferrer" className={style.attachment}>
                    <Badge pill>{filename}</Badge>
                  </a>
                )}</div>}
              </Col>
              <Col xs={6} className={style.right}>
                <h5>{date}</h5>
              </Col>
            </Row>
            <AutoHeightIframe title={subject} srcDoc={body} />
          </div>
        )}
      </div>
    )
  }
}
