import {get} from 'lodash'
import {getPageSize, getOffset} from '../useful'

export const blacklistsProvider = {

  getQuery: (props) => {
    try {
      const {blacklistsSort, blacklistsPage, blacklistTypesFilter} = props
      const cpart1 = blacklistTypesFilter && blacklistTypesFilter.length > 0
        ? `types: [${blacklistTypesFilter}]`
        : ''
      const args = [cpart1].join('\n').trim()

      const query = `{
        blacklists(
          ${args}
          limit: ${getPageSize()}
          offset: ${getOffset(blacklistsPage)}
          orderBy: ${blacklistsSort.orderBy}
          orderDirection: ${blacklistsSort.orderDirection}
        ) {
          id
          type
          value
          createdAt
          updatedAt
          expiresAt
          reason
        }
        blacklistsCount
        ${args && `(${args})`}
      }`

      return query

    } catch (e) {
      throw e
    }
  },

  onData: (res, dispatch, props) => dispatch(
    'Blacklists loaded',
    (state, res) => ({
      ...state,
      blacklists: res.blacklists,
      blacklistsCount: res.blacklistsCount,
    }),
    [res]
  ),
}

export const blacklistProvider = {
  getQuery: (props) => {
    const blacklistId = get(props.match, 'params.blacklistId')
    const query = `{
      blacklist(id: ${blacklistId}) {
        id
        type
        value
        createdAt
        updatedAt
        expiresAt
        reason
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Blacklist loaded',
    (state, res) => ({
      ...state,
      blacklist: res.blacklist,
    }),
    [res]
  ),
}
