import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {merge, debounce} from 'lodash'
import {Row, Col, Table, Card, Pagination, Container, Button} from 'react-bootstrap'
import {canQueryFtdReport} from '@bdswiss/common-permissions'
import {orderDirections} from '@bdswiss/common-enums'
import style from './reportFtd.module.scss'
import {events} from '../enums'
import {reportFtdProvider} from './providers'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

class FirstTimeDeposits extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
  }

  static contextTypes = {
    reportFtdProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilter = this.props.dateFilter || {
      date: '',
    }

    const stateftdSort = this.props.ftdSort || {
      orderBy: 'id', orderDirection: orderDirections.descending.key,
    }

    this.setState({
      sorting: {}, dateFilter: stateDateFilter, ftdSort: stateftdSort,
    })
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.reportFtdProvider.fetch()
  }

  handleDateFilterChanged(name, momentValue, value) {
    const {dateFilter} = this.state
    this.setState({
      dateFilter: {...dateFilter, [name]: value},
    })

    if (!momentValue || moment.isMoment(momentValue)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilter} = this.state
    this.props.uiDispatch('Filter report by dates', (state) => ({...state, dateFilter, reportPage: 1}), [dateFilter])
  }

  doSortByOrderAndOrderDirection(field) {
    const {ftdSort} = this.props
    const {orderBy, orderDirection} = ftdSort

    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting report by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      ftdSort: newSort
    }), [ftdSort])
  }

  getSortArrow(field) {
    const {ftdSort} = this.props
    const arrowDirection = ftdSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (ftdSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  render() {
    const {ftds, reportPage, ftdCount} = this.props
    const {dateFilter} = this.state
    const {backendUrl} = this.context.config

    return (<Container>
      <h3>First Time Deposits Report</h3>
      <span className={style.label}>Date</span>
      <Row key="filter-row-1" className={style.filter}>
        <Col xs={2}>
          <div>
            <DateTime
              id="t-ftd-date-filter"
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('date', e, moment.isMoment(e) ? e.format('YYYY-MM-DD') : '')}
              value={dateFilter.date}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              className={style.datetime}
            />
          </div>
        </Col>

        <Col xs={1}>
          <div style={{textAlign: 'center'}}>
            <Button
              href={`${backendUrl}/api/v1/reports/report?type=ftd&date=${dateFilter.date}`}
              id={'t-ftd-export'}
              variant="success"
              style={{width: '100%'}}
              disabled={!ftds.length}
            >
              Download
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body style={{overflowX: 'scroll'}}>
              <Table bordered hover className={style.table}>
                <thead>
                  <tr>
                    <th style={{minWidth: 40}}>
                      <span>ID</span>
                      <FontAwesomeIcon icon={this.getSortArrow('id')}
                        onClick={() => this.doSortByOrderAndOrderDirection('id')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Name</span>
                      <FontAwesomeIcon icon={this.getSortArrow('clientFullName')}
                        onClick={() => this.doSortByOrderAndOrderDirection('clientFullName')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Date of Birth</span>
                      <FontAwesomeIcon icon={this.getSortArrow('clientDOB')}
                        onClick={() => this.doSortByOrderAndOrderDirection('clientDOB')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Entity</span>
                      <FontAwesomeIcon icon={this.getSortArrow('entity')}
                        onClick={() => this.doSortByOrderAndOrderDirection('entity')}/>
                    </th>
                  </tr>
                </thead>
                <tbody>

                  {ftds.map((ftd) => {
                    const {id, fullName, entity, dob} = ftd
                    return (<tr
                      key={id}
                      id={`t-ftd-id-${id}`}
                      className={classNames(['t-ftd'])}
                    >
                      <td>{id}</td>
                      <td>{fullName}</td>
                      <td>{dob ?? 'N/A'}</td>
                      <td>{entity}</td>
                    </tr>)
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>

          <Row className="mt-4">
            <Col xs={12}>
              <Pagination
                size="sm"
                className="justify-content-center"
                onSelect={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                  ...state,
                  reportPage: selectedEvent.eventKey
                }))}
              >
                {getPageRange(reportPage, getPageCount(ftdCount)).map((page) => {
                  if (page === 'LEFT_PAGE') {
                    return <Pagination.Prev
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                        ...state,
                        reportPage: reportPage - 1
                      }))}
                    />
                  }

                  if (page === 'RIGHT_PAGE') {
                    return <Pagination.Next
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                        ...state,
                        reportPage: reportPage + 1
                      }))}
                    />
                  }

                  return <Pagination.Item
                    active={page === reportPage}
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      reportPage: page
                    }))}
                  >
                    {page}
                  </Pagination.Item>
                })}
              </Pagination>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>)
  }
}

export default compose(checkRights(canQueryFtdReport),

  uiMount((state) => ['ui', 'ftdReport']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/first time deposit report',
    (state) => {
      const dateFilter = state && state.dateFilter
      let ftdSort = state && state.ftdSort
      if (!ftdSort) {
        ftdSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}
      }

      return merge({dateFilter, ftdSort}, state)
    })),

  provideProps((state, uiState) => {
    const {ftds, ftdCount} = state
    const {reportPage, dateFilter, ftdSort} = uiState

    return ({
      ftds,
      ftdCount,
      reportPage: reportPage || 1,
      ftdSort,
      dateFilter,
    })
  }),

  mountDataProviders({reportFtdProvider}),)(FirstTimeDeposits)
