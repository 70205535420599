import {Card, Col, Container, Row, Table, Button} from 'react-bootstrap'
import {memo, useState, useMemo} from 'react'
import {canUpsertRoles} from '@bdswiss/common-permissions'

import {PermissionDetails} from './PermissionDetails'
import {PermissionEditModal} from './PermissionEditModal'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {compose, mountDataProviders, provideProps} from '../decorators'
import {permissionsProvider} from './providers'

export const Permissions = memo(({permissions, viewer, permissionsProvider}) => {
  const [selectedPermission, setSelectedPermission] = useState(null)
  const [editPermission, setEditPermission] = useState(null)

  const canModifyRoles = useMemo(() => canUpsertRoles(viewer), [viewer])

  const handleUpdatePermissionSuccess = () => {
    setEditPermission(null)
    setSelectedPermission(null)
    permissionsProvider.fetch()
  }

  const handleUpdatePermissionCancel = () => {
    setEditPermission(null)
  }

  return (
    <Container>
      <h3>Permissions list</h3>
      <Row>
        <Col xs={7}>
          <Card>
            <Card.Body className="fit-to-page">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>
                      <span>Name</span>
                    </th>
                    <th>
                      <span>Description</span>
                    </th>
                    {canModifyRoles && (
                      <th>
                        <span>Actions</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {permissions.map(({id, name, description, roles}) => (
                    <tr
                      key={id}
                      id={id}
                      className={'clickable'}
                      onClick={() => setSelectedPermission(id)}
                    >
                      <td>{name}</td>
                      <td>{description}</td>
                      {canModifyRoles && (
                        <td>
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              setEditPermission({
                                id,
                                name,
                                description,
                                roles,
                              })
                            }
                          >
                            <FontAwesomeIcon icon="pencil" />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={5}>
          <PermissionDetails permissionId={selectedPermission} />
        </Col>
      </Row>
      {editPermission && (
        <PermissionEditModal
          permission={editPermission}
          onCancel={handleUpdatePermissionCancel}
          onSubmit={handleUpdatePermissionSuccess}
        />
      )}
    </Container>
  )
})

export default compose(
  provideProps(({permissions}) => ({permissions})),
  mountDataProviders({permissionsProvider})
)(Permissions)
