import React from 'react'
import PropTypes from 'prop-types'
import {Modal} from 'react-bootstrap'
import {provideProps} from '../decorators'
import style from '../components/components.module.scss'
import PureComponent from '../PureComponent'
import TranslationForm from '../translations/TranslationForm'

class DocumentTranslationModal extends PureComponent {

  static propTypes = {
    document: PropTypes.object.isRequired,
    clientId: PropTypes.number.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    alert: PropTypes.object,
  }

  render() {
    const {onHide, show, document} = this.props
    return (
      <Modal
        id="t-document-translation-modal"
        keyboard
        show={show}
        onHide={onHide}
        className={style.modal}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Document translation form
        </Modal.Header>
        <Modal.Body>
          <TranslationForm
            document={document}
            onSave={(params) => this.props.onSave(params).then(onHide())}
          />
        </Modal.Body>
      </Modal>
    )
  }
}
export default provideProps()(DocumentTranslationModal)
