import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import {
  Button,
  Card,
  Col,
  Container, Form, InputGroup,
  Pagination,
  Row,
  Table,
} from 'react-bootstrap'
import isEmpty from 'lodash/isEmpty'
import {canQueryOrphanedCopytradingFollowers} from '@bdswiss/common-permissions'
import PureComponent from '../PureComponent'
import {checkRights, compose, mountDataProviders, provideProps, uiMount} from '../decorators'
import {orphanedCopytradingFollowersProvider} from './providers'
import {getPageCount, getPageRange} from '../useful'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import style from './orphanedCopytradingFollowers.module.scss'
import {safeLinkToClient} from '../utils/links'

class OrphanedCopytradingFollowers extends PureComponent {
  static contextTypes = {
    orphanedCopytradingFollowersProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  static propTypes = {
    client: PropTypes.object,
  }

  constructor(props) {
    super(props)
    this.state = {
      clientId: ''
    }
  }

  handleChange(input) {
    const {value} = input.target
    this.setState({accountId: value})
  }

  applyFilter() {
    this.props.uiDispatch(
      'Filter by account id',
      (state) => ({
        ...state,
        orphanedCopytradingFollowersPage: 1,
        accountId: this.state.accountId,
      })
    )
  }

  clearFilter() {
    this.setState({clientId: ''})
    this.props.uiDispatch(
      'Clear filter',
      (state) => ({
        ...state,
        accountId: '',
        orphanedCopytradingFollowersPage: 1,
      })
    )
  }

  fetchProvider() {
    this.context.orphanedCopytradingFollowersProvider.fetch()
  }

  handleResolveFollower(clientAccountId, providerAccountId) {
    this.props.actions.client.resolveOrphanedFollower(clientAccountId, providerAccountId)
      .then(() => {
        this.context.showNotification({
          title: 'Success',
          message: 'Copiers data resolved successfully',
          position: 'tr',
          level: 'success',
        })
      })
      .catch((e) => {
        this.context.logError(e)
      })
      .finally(() => this.fetchProvider())
  }

  render() {
    const {orphanedCopytradingFollowers, orphanedCopytradingFollowersCount, orphanedCopytradingFollowersPage, viewer} = this.props
    const {accountId} = this.state
    return (
      <Container>
        <h3>Invalid state copiers</h3>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Row className={style.search}>
                  <Col xs={3}>
                    <InputGroup>
                      <Form.Control
                        id="t-list-client-id"
                        type="text"
                        size="sm"
                        placeholder="Enter account id"
                        value={accountId || ''}
                        onChange={this.handleChange.bind(this)}
                      />
                      <InputGroup.Append>
                        <Button
                          key={1}
                          title="Clear"
                          size="sm"
                          variant={'outline-secondary'}
                          onClick={this.clearFilter.bind(this)}
                        >
                          <FontAwesomeIcon
                            title="Clear"
                            role="button"
                            className={style.clickable}
                            icon={'close'}
                          />
                        </Button>
                        <Button
                          key={2}
                          title="Search"
                          size="sm"
                          onClick={this.applyFilter.bind(this)}
                        >
                          <FontAwesomeIcon
                            title="Search"
                            role="button"
                            className={style.clickable}
                            icon={'search'}
                          />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                </Row>

                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Client ID</th>
                      <th>Account ID</th>
                      <th>IB Account copying</th>
                      <th>Start requested date</th>
                      <th>Stop requested date</th>
                      <th>Start copying date</th>
                      <th>Stop copying date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !isEmpty(orphanedCopytradingFollowers) && orphanedCopytradingFollowers
                        .filter(record => record.client && record.account && record.accountCopying) // Filter out invalid records
                        .map((record, i) => {
                          const {
                            client: {id: clientId},
                            account: {id: memberAccountId},
                            accountCopying: {accountId: ibAccountId},
                            startCopyingDate,
                            stopCopyingDate,
                            startRequestedDate,
                            stopRequestedDate,
                          } = record
                          return (
                            <tr key={i}>
                              <td>{safeLinkToClient(viewer, clientId, record.client)}</td>
                              <td>{safeLinkToClient(viewer, memberAccountId, record.client, 'accounts')}</td>
                              <td>{ibAccountId}</td>
                              <td>{moment(startRequestedDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>{moment(stopRequestedDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>{moment(startCopyingDate).format('YYYY-MM-DD HH:mm:ss')}</td>
                              <td>{stopCopyingDate ? moment(stopCopyingDate).format('YYYY-MM-DD HH:mm:ss') : '-'}</td>
                              <td><Button variant="outline-primary" size="sm" onClick={() => this.handleResolveFollower(memberAccountId, ibAccountId)}>Resolve</Button></td>
                            </tr>
                          )
                        })
                    }
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                    ...state,
                    ghostCopiersPage: selectedEvent.eventKey
                  }))}
                >
                  {getPageRange(orphanedCopytradingFollowersPage, getPageCount(orphanedCopytradingFollowersCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                          ...state,
                          orphanedCopytradingFollowersPage: orphanedCopytradingFollowersPage - 1
                        }))}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                          ...state,
                          orphanedCopytradingFollowersPage: orphanedCopytradingFollowersPage + 1
                        }))}
                      />
                    }

                    return <Pagination.Item
                      active={page === orphanedCopytradingFollowersPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                        ...state,
                        orphanedCopytradingFollowersPage: page
                      }))}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(checkRights(canQueryOrphanedCopytradingFollowers),
  uiMount((state) => ['ui', 'orphanedCopytradingFollowers']),
  provideProps((state, uiState) => {
    const {orphanedCopytradingFollowers, orphanedCopytradingFollowersCount} = state
    const {orphanedCopytradingFollowersPage, accountId} = uiState

    return ({
      orphanedCopytradingFollowers,
      orphanedCopytradingFollowersCount,
      orphanedCopytradingFollowersPage: orphanedCopytradingFollowersPage || 1,
      accountId: accountId || ''
    })
  }),
  mountDataProviders({orphanedCopytradingFollowersProvider}),
)(OrphanedCopytradingFollowers)
