import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Card, Table, Container, Button} from 'react-bootstrap'
import {canQueryStats} from '@bdswiss/common-permissions'
import {compose, checkRights, mountDataProviders, provideProps} from '../decorators'
import style from './strategiesequity.module.scss'
import PureComponent from '../PureComponent'
import {strategiesEquityProvider} from './providers'
import {events} from '../enums'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {orderBy} from 'lodash'

class StrategiesEquity extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const initialSort = {
      order: 'strategyName',
      orderDirection: 'asc',
    }
    this.setState({
      sort: initialSort,
    })
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.strategiesEquityProvider.fetch()
  }

  static contextTypes = {
    config: PropTypes.object.isRequired,
    strategiesEquityProvider: PropTypes.object.isRequired,
  }

  doSortByOrderAndOrderDirection(field) {
    const {sort} = this.state
    const {order, orderDirection} = sort
    let newSort = {}
    if (order === field && orderDirection === 'asc') {
      newSort = {order: field, orderDirection: 'desc'}
    } else {
      newSort = {order: field, orderDirection: 'asc'}
    }
    this.setState({sort: newSort})
  }

  getSortArrow(field) {
    const {sort} = this.state
    const arrowDirection = sort.orderDirection === 'desc' ? 'down' : 'up'
    if (sort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  render() {
    const {statistics} = this.props
    const {sort} = this.state
    const {backendUrl} = this.context.config

    return (
      <Container fluid>
        <Row>
          <Col xs={10}>
            <div className={`h4 ${style.toolbar}`}>
              Equity Held Under Strategies
            </div>
          </Col>

          <Col xs={2}>
            <div style={{textAlign: 'center',}}>
              <Button
                href={`${backendUrl}/api/v1/reports/report?type=strategies-equity`}
                id={'t-registrations-export'}
                variant="success"
                style={{width: '100%'}}
                disabled={!statistics?.length}
              >
                Download
              </Button>
            </div>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Card className="no-filter">
              <Table bordered hover>
                <thead>
                  <tr style={{textAlign: 'center'}}>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Strategy Name</span>
                      <FontAwesomeIcon icon={this.getSortArrow('strategyName')}
                        onClick={() => this.doSortByOrderAndOrderDirection('strategyName')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Strategy Account ID</span>
                      <FontAwesomeIcon icon={this.getSortArrow('accountId')}
                        onClick={() => this.doSortByOrderAndOrderDirection('accountId')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Remote ID</span>
                      <FontAwesomeIcon icon={this.getSortArrow('remoteId')}
                        onClick={() => this.doSortByOrderAndOrderDirection('remoteId')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Number of Followers</span>
                      <FontAwesomeIcon icon={this.getSortArrow('numberOfFollowers')}
                        onClick={() => this.doSortByOrderAndOrderDirection('numberOfFollowers')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Number of Trades</span>
                      <FontAwesomeIcon icon={this.getSortArrow('numberOfTrades')}
                        onClick={() => this.doSortByOrderAndOrderDirection('numberOfTrades')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Overall PNL</span>
                      <FontAwesomeIcon icon={this.getSortArrow('overallPnL')}
                        onClick={() => this.doSortByOrderAndOrderDirection('overallPnL')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>PNL for the last 60 days</span>
                      <FontAwesomeIcon icon={this.getSortArrow('pnlSixtyDays')}
                        onClick={() => this.doSortByOrderAndOrderDirection('pnlSixtyDays')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Followers' Equity</span>
                      <FontAwesomeIcon icon={this.getSortArrow('followersEquity')}
                        onClick={() => this.doSortByOrderAndOrderDirection('followersEquity')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>WhiteLabel</span>
                      <FontAwesomeIcon icon={this.getSortArrow('whitelabel')}
                        onClick={() => this.doSortByOrderAndOrderDirection('whitelabel')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Total withdrawals 30 days</span>
                      <FontAwesomeIcon icon={this.getSortArrow('withdrawalsThirtyDays')}
                        onClick={() => this.doSortByOrderAndOrderDirection('withdrawalsThirtyDays')}/>
                    </th>
                    <th style={{minWidth: 40}}>
                      <span style={{marginRight: 8}}>Total withdrawals 90 days</span>
                      <FontAwesomeIcon icon={this.getSortArrow('withdrawalsNinetyDays')}
                        onClick={() => this.doSortByOrderAndOrderDirection('withdrawalsNinetyDays')}/>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {statistics && orderBy(statistics, [sort.order], [sort.orderDirection]).map((strategy) => {
                    const {strategyName, accountId, remoteId, numberOfFollowers, numberOfTrades, overallPnL, pnlSixtyDays, followersEquity, whitelabel, withdrawalsThirtyDays, withdrawalsNinetyDays} = strategy
                    return (
                      <tr className="t-stats-user" key={accountId}>
                        <td>{strategyName}</td>
                        <td>{accountId}</td>
                        <td>{remoteId}</td>
                        <td>{numberOfFollowers}</td>
                        <td>{numberOfTrades}</td>
                        <td>{overallPnL}</td>
                        <td>{pnlSixtyDays}</td>
                        <td>{followersEquity}</td>
                        <td>{whitelabel}</td>
                        <td>{withdrawalsThirtyDays}</td>
                        <td>{withdrawalsNinetyDays}</td>
                      </tr>
                    )}
                  )}
                </tbody>
              </Table>
            </Card>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryStats),

  provideProps((state) => {
    const {statistics, config} = state
    return {
      statistics,
      config,
    }
  }),

  mountDataProviders({strategiesEquityProvider}),

)(StrategiesEquity)
