/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {Row, Col} from 'react-bootstrap'
import {includes, map, filter, get, reject} from 'lodash'
import {accountSubtypes, accountTypes, regulators} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'

class PartnersCustomizationRowLeverage extends PureComponent {

  getAccountSubTypesLeverages() {
    const {accountSubType} = this.props

    const accSubType = accountSubType.value || accountSubType

    if (accSubType === 'demo') {
      return [
        {key: 10, label: 10},
        {key: 50, label: 50},
        {key: 100, label: 100},
        {key: 200, label: 200},
        {key: 300, label: 300},
        {key: 400, label: 400},
        {key: 500, label: 500},
        {key: 1000, label: 1000},
      ]
    }

    const subType = get(accountSubtypes[accSubType], 'value')
    const availableAccountTypes = filter(accountTypes, (account) => includes(account.supportedSubtypes, subType)
      && account.regulator !== regulators.cysec && account.subCategory !== 'bdx'
    )

    return map(availableAccountTypes[0].leverages, (leverage) => ({
      key: leverage,
      label: leverage
    }))
  }

  render() {
    const {leverageCustomizationKey, accountSubType, leverage, selectedLeverageAccTypes,
      removeLeverageCustomizationRow, accountTypes} = this.props

    const accountOpenTypes = reject(accountTypes, (accType) =>
      accType.key !== accountSubType && includes(selectedLeverageAccTypes, accType.key))

    const accountSubTypeLeverages = accountSubType && this.getAccountSubTypesLeverages()

    return (
      <div key={leverageCustomizationKey}>
        <Row>
          <Col xs={6}>
            <StylishSelect.Input
              label="Account Types Allowed"
              value={accountSubType}
              options={StylishSelect.enumToStylishOptions({...accountOpenTypes})}
              onChange={(evt) => this.props.onChange('accountSubType', evt)}
            />
          </Col>
          {<Col xs={6}>
            <StylishSelect.Input
              id="t-partners-customization-form-account-type-category"
              label="Leverage"
              value={leverage || ''}
              onChange={(evt) => this.props.onChange('leverage', evt.value)}
              placeholderText="Leverage"
              options={StylishSelect.enumToStylishOptions(accountSubTypeLeverages)}
              bsStyle={leverage ? undefined : 'error'}
            />
          </Col>}
          <Col xs={12}>
            <a
              id="t-partners-customization-form-account-remove"
              className="float-right clickable"
              onClick={() => removeLeverageCustomizationRow(leverageCustomizationKey)}
            > Remove Leverage
            </a>
          </Col>
        </Row>
      </div>
    )
  }
}

export default provideProps()(PartnersCustomizationRowLeverage)
