import {Card, Col, Container, Row, Table, Button} from 'react-bootstrap'
import {memo, useMemo, useState} from 'react'
import {canUpsertRoles} from '@bdswiss/common-permissions'

import {RoleDetails} from './RoleDetails'
import {RoleCreateModal} from './RoleCreateModal'
import {RoleDeleteModal} from './RoleDeleteModal'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {compose, mountDataProviders, provideProps} from '../decorators'
import {rolesProvider} from './providers'

import styles from './Roles.module.scss'

export const Roles = memo(({roles, rolesProvider, viewer}) => {
  const [selectedRole, setSelectedRole] = useState(null)
  const [isRoleCreating, setIsRoleCreating] = useState(false)
  const [editRole, setEditRole] = useState(null)
  const [deleteRole, setDeleteRole] = useState(null)

  const canModifyRoles = useMemo(() => canUpsertRoles(viewer), [viewer])

  const handleUpsertRoleSuccess = () => {
    setIsRoleCreating(false)
    setEditRole(null)
    setSelectedRole(null)
    rolesProvider.fetch()
  }

  const handleUpsertRoleCancel = () => {
    setIsRoleCreating(false)
    setEditRole(null)
  }

  const handleDeleteRole = (e, role) => {
    e.stopPropagation()
    setDeleteRole(role)
  }

  const handleDeleteRoleSuccess = () => {
    setDeleteRole(null)
    setSelectedRole(null)
    rolesProvider.fetch()
  }

  const handleDeleteRoleCancel = () => {
    setDeleteRole(null)
  }

  return (
    <Container>
      <Row>
        <Col xs={7}>
          <Row className={styles['Roles-header']}>
            <h3>Roles list</h3>
            {canModifyRoles && (
              <Button onClick={() => setIsRoleCreating(true)}>
                Create Role
              </Button>
            )}
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={7}>
          <Card>
            <Card.Body className="fit-to-page">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>
                      <span>Name</span>
                    </th>
                    <th>
                      <span>Description</span>
                    </th>
                    {canModifyRoles && (
                      <th>
                        <span>Actions</span>
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {roles.map(({id, name, description, permissions}) => (
                    <tr
                      key={id}
                      id={id}
                      className={'clickable'}
                      onClick={() => setSelectedRole(id)}
                    >
                      <td>{name}</td>
                      <td>{description}</td>
                      {canModifyRoles && (
                        <td className={styles['Roles-actions']}>
                          <Button
                            variant="outline-primary"
                            onClick={() =>
                              setEditRole({
                                id,
                                name,
                                description,
                                permissions,
                              })
                            }
                          >
                            <FontAwesomeIcon icon="pencil" />
                          </Button>
                          <Button
                            variant="outline-primary"
                            onClick={(e) => handleDeleteRole(e, {id})}
                          >
                            <FontAwesomeIcon icon="trash" />
                          </Button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
        <Col xs={5}>
          <RoleDetails roleId={selectedRole} />
        </Col>
      </Row>
      {(isRoleCreating || editRole) && (
        <RoleCreateModal
          role={editRole}
          onCancel={handleUpsertRoleCancel}
          onSubmit={handleUpsertRoleSuccess}
        />
      )}
      {deleteRole && (
        <RoleDeleteModal
          role={deleteRole}
          onSubmit={handleDeleteRoleSuccess}
          onCancel={handleDeleteRoleCancel}
        />
      )}
    </Container>
  )
})

export default compose(
  provideProps(({roles}) => ({roles})),
  mountDataProviders({rolesProvider})
)(Roles)
