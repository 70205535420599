import {getPageSize, getOffset} from '../useful'
import {orderDirections} from '@bdswiss/common-enums'
import {get} from 'lodash'

export const partnersCustomizationProvider = {
  getQuery: (props) => {
    const {partnersCustomizationSort, partnersCustomizationPage, partnersCustomizationSearch} = props

    let searchTextProperty = ''
    let partnersCustomizationCountQuery = 'partnersCustomizationCount'
    if (partnersCustomizationSearch) {
      searchTextProperty = `search: "${partnersCustomizationSearch}"`
      partnersCustomizationCountQuery = `partnersCustomizationCount(${searchTextProperty})`
    }
    const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

    const query = `{
      partnersCustomization(
        limit: ${getPageSize()}
        offset: ${getOffset(partnersCustomizationPage)}
        orderBy: ${get(partnersCustomizationSort, 'orderBy') || defaultSort.orderBy}
        orderDirection: ${get(partnersCustomizationSort, 'orderDirection') ||  defaultSort.orderDirection}
        ${searchTextProperty}
      ) {
        id
        affiliateId
        ibId
        campaignId
        accountTypeCustomizations {
          accountSubTypes
        }
        minDepositCustomizations {
          accountSubType
          amount
        }
        leverageCustomizations {
          accountSubType
          leverage
        }
        bonus {
          enabled
          startDate
        }
        depositorSalesAgent {
          id
          firstName
          lastName
        }
        nonDepositorSalesAgent {
          id
          firstName
          lastName
        }
        initialDemoDeposit
        gdcDisabled
        spoa {
          enabled
          network
        }
        autochartist {
          enabled
          network
          activateFor
        }
        updatedAt
        classicDefaultMT4Server
      }
      ${partnersCustomizationCountQuery}
    }`
    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Partners Customization Loaded',
    (state, res) => ({
      ...state,
      partnersCustomization: res.partnersCustomization,
      partnersCustomizationCount: res.partnersCustomizationCount,
    }),
    [res]
  ),
}

export const partnersSepcificCustomizationProvider = {
  getQuery: (props) => {
    const query = `{
      partnerCustomization(id: ${get(props, 'match.params.partnersCustomizationId')}) {
        id
        affiliateId
        ibId
        campaignId
        accountTypeCustomizations {
          accountSubTypes
        }
        minDepositCustomizations {
          accountSubType
          amount
        }
        leverageCustomizations {
          accountSubType
          leverage
        }
        bonus {
          enabled
          startDate
        }
        depositorSalesAgent {
          id
          firstName
          lastName
        }
        nonDepositorSalesAgent {
          id
          firstName
          lastName
        }
        initialDemoDeposit
        gdcDisabled
        updatedAt
        spoa {
          enabled
          network
        }
        classicDefaultMT4Server
        autochartist {
          enabled
          network
          activateFor
        }
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Partners Customization loaded',
    (state, res) => ({
      ...state,
      selectedCustomization: res.partnerCustomization,
    }),
    [res]
  ),
}

