import {useMutation} from 'urql'

const ApproveAccountMutation = `
  mutation ($id: Int!) {
    approveAccount(accountId: $id)
  }
`

export const useApproveAccount = () => {
  const [result, mutation] = useMutation(ApproveAccountMutation)

  const approveAccount = async (accountId) =>
    mutation({id: accountId})

  return {result, approveAccount}
}
