import styled from 'styled-components'

export const Body1 = styled.h1`
  color: rgba(0, 0, 0, 0.60);
  font-size: 16px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
`
