import React from 'react'
import moment from 'moment'
import {orderBy} from 'lodash'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Button, Card, Col, Container, Pagination, Row, Table} from 'react-bootstrap'
import {canQueryGhostCopiers} from '@bdswiss/common-permissions'
import {events} from '../enums'
import PureComponent from '../PureComponent'
import style from './ghostCopiers.module.scss'
import {ghostCopiersProvider} from './providers'
import {getPageCount, getPageRange} from '../useful'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {checkRights, compose, mountDataProviders, provideProps, uiMount} from '../decorators'
import AddCopyTradingModal from './AddCopyTradingModal'

class GhostCopiers extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      showAddModal: false,
      ghostCopierId: null,
    }
  }

    static contextTypes = {
      ghostCopiersProvider: PropTypes.object.isRequired,
      config: PropTypes.object.isRequired,
      logError: PropTypes.func.isRequired,
      showNotification: PropTypes.func.isRequired,
    }

    componentDidMount() {
      window.addEventListener(events.fetchProviders.key, this.fetchProvider)
    }

    componentWillMount() {
      const initialSort = {
        order: 'id',
        orderDirection: 'asc',
      }
      this.setState({
        sort: initialSort,
      })
    }

    componentWillUnmount() {
      window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
    }

    fetchProvider() {
      this.context.ghostCopiersProvider.fetch()
    }

    doSortByOrderAndOrderDirection(field) {
      const {sort} = this.state
      const {order, orderDirection} = sort
      let newSort = {}
      if (order === field && orderDirection === 'asc') {
        newSort = {order: field, orderDirection: 'desc'}
      } else {
        newSort = {order: field, orderDirection: 'asc'}
      }
      this.setState({sort: newSort})
    }

    getSortArrow(field) {
      const {sort} = this.state

      const arrowDirection = sort.orderDirection === 'desc' ? 'down' : 'up'
      if (sort.order === field) {
        return `long-arrow-${arrowDirection}`
      }
      return 'arrows-v'
    }

    handleAddClick (ghostCopierId) {
      this.setState({showAddModal: true, ghostCopierId})
    }

    confirmAdd (confirm) {
      this.setState({showAddModal: false})
      if (confirm) {
        const {actions} = this.props
        actions.ghostcopiers.addCopyTrading(Number(this.state.ghostCopierId))
          .then((res) => {
            this.context.showNotification({
              title: 'Add CopyTrading record',
              message: 'CopyTrading record successfully added.',
              position: 'tr',
              level: 'success',
            })
            this.fetchProvider()
          })
          .catch((error) => {
            this.context.showNotification({
              title: 'Add CopyTrading record',
              message: error.message,
              position: 'tr',
              level: 'error',
            })
          })
      }
      this.setState({ghostCopierId: null})
    }

    render() {
      const {ghostCopyTradings, ghostCopyTradingsCount, ghostCopiersPage} = this.props
      const {sort} = this.state

      return (<Container>
        <h3>Ghost Copiers</h3>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body style={{overflowX: 'scroll'}}>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th style={{minWidth: 40}}>
                        <span>ID</span>
                        <FontAwesomeIcon icon={this.getSortArrow('id')}
                          onClick={() => this.doSortByOrderAndOrderDirection('id')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>Member ID</span>
                        <FontAwesomeIcon icon={this.getSortArrow('memberId')}
                          onClick={() => this.doSortByOrderAndOrderDirection('memberId')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>Remote ID</span>
                        <FontAwesomeIcon icon={this.getSortArrow('accountId')}
                          onClick={() => this.doSortByOrderAndOrderDirection('accountId')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>Copying Remote ID</span>
                        <FontAwesomeIcon icon={this.getSortArrow('copyingAccountId')}
                          onClick={() => this.doSortByOrderAndOrderDirection('copyingAccountId')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>Status</span>
                        <FontAwesomeIcon icon={this.getSortArrow('status')}
                          onClick={() => this.doSortByOrderAndOrderDirection('status')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>Is Active</span>
                        <FontAwesomeIcon icon={this.getSortArrow('isActive')}
                          onClick={() => this.doSortByOrderAndOrderDirection('isActive')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>First Copying Date</span>
                        <FontAwesomeIcon icon={this.getSortArrow('firstCopyingDate')}
                          onClick={() => this.doSortByOrderAndOrderDirection('firstCopyingDate')}/>
                      </th>

                      <th style={{minWidth: 100}}>
                        <span>Last Copying Date</span>
                        <FontAwesomeIcon icon={this.getSortArrow('lastCopyingDate')}
                          onClick={() => this.doSortByOrderAndOrderDirection('lastCopyingDate')}/>
                      </th>
                      <th style={{minWidth: 100}}>
                        <span>Action</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>

                    {ghostCopyTradings && orderBy(ghostCopyTradings, [sort.order], [sort.orderDirection]).map((gct) => {
                      const {id, memberId, remoteId, copyingRemoteId, status, isActive, firstCopyingDate, lastCopyingDate} = gct
                      return (<tr
                        key={id}
                        id={`t-gct-id-${id}`}
                        className={classNames(['t-ftd'])}
                      >
                        <td>{id}</td>
                        <td>{memberId}</td>
                        <td>{remoteId}</td>
                        <td>{copyingRemoteId}</td>
                        <td>{status || '-'}</td>
                        <td>{isActive ? 'YES' : 'NO'}</td>
                        <td>{moment(firstCopyingDate).format('LLL')}</td>
                        <td>{moment(lastCopyingDate).format('LLL')}</td>
                        <td>
                          <div style={{textAlign: 'center'}}>
                            <Button
                              id={`t-ghost-copier-${id}`}
                              variant="success"
                              size="sm"
                              onClick={() => this.handleAddClick(id)}
                              style={{width: '90%'}}
                              disabled={status === 'FIXED'}
                            >
                              Add record
                            </Button>
                          </div>
                        </td>
                      </tr>)
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                    ...state,
                    ghostCopiersPage: selectedEvent.eventKey
                  }))}
                >
                  {getPageRange(ghostCopiersPage, getPageCount(ghostCopyTradingsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                          ...state,
                          ghostCopiersPage: ghostCopiersPage - 1
                        }))}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                          ...state,
                          ghostCopiersPage: ghostCopiersPage + 1
                        }))}
                      />
                    }

                    return <Pagination.Item
                      active={page === ghostCopiersPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                        ...state,
                        ghostCopiersPage: page
                      }))}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
        <AddCopyTradingModal
          show={this.state.showAddModal}
          onCancel={() => this.confirmAdd(false)}
          onConfirm={() => this.confirmAdd(true)}
        />
      </Container>
      )
    }
}

export default compose(checkRights(canQueryGhostCopiers),
  uiMount((state) => ['ui', 'ghostCopiers']),

  provideProps((state, uiState) => {
    const {ghostCopyTradings, ghostCopyTradingsCount} = state

    return ({
      ghostCopyTradings,
      ghostCopyTradingsCount,
      ghostCopiersPage: 1
    })
  }),

  mountDataProviders({ghostCopiersProvider}),

)(GhostCopiers)
