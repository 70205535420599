import React from 'react'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Card} from 'react-bootstrap'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import {parseStringAsJson} from '../common/utils'

export default class WithdrawalReceiptModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    data: PropTypes.object,
    onHide: PropTypes.func.isRequired,
  };

  render() {
    const {data, onHide, show} = this.props
    const receipt = parseStringAsJson(get(data, 'payment.receipt') || '{}')
    const withdrawalData = {receipt, meta: get(data, 'payment.meta', {}), track: get(data, 'payment.track', {})}

    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
        className={style.receipt}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Receipt #{data.id}
        </Modal.Header>
        <Modal.Body style={{maxHeight: 'calc(100% - 50px)', overflowY: 'scroll'}}>
          <Card className={style.receiptContent}>
            <Card.Body>
              {JSON.stringify(withdrawalData, null, 3)}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    )
  }
}
