import React from 'react'
import PropTypes from 'prop-types'
import {filter, get, isEmpty} from 'lodash'
import {Col, Form, Row, Button, ButtonToolbar} from 'react-bootstrap'
import {priorityLevels} from '../common/utils.js'
import StylishSelect from '../components/StylishSelect'
import FormComponent from '../components/FormComponent'
import {countries, clientTypes} from '@bdswiss/common-enums'

export default class LeadForm extends FormComponent {

  static propTypes = {
    leadTypes: PropTypes.object.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {
      form: {
        email: '',
        company: null,
        lastName: '',
        firstName: '',
        phone: '',
        clientType: null,
      },
      errors: {},
    }
  }

  generateInputProps(key) {
    const res = {
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.value)
        } else if (e && e.value) {
          this.valueChanged(key, e.value)
        } else if (e) {
          this.valueChanged(key, e)
        } else {
          this.valueChanged(key, '')
        }
      },
    }
    if (this.state.errors && key in this.state.errors) {
      res.isInvalid = true
    }
    return res
  }

  valueChanged(key, value) {
    this.setState((state) => ({
      ...state,
      form: {
        ...state.form,
        [key]: value,
      },
    }))
  }

  renderErrorMessage(key) {
    const {errors} = this.state
    if (errors && key in errors && !errors[key].isValid) {
      return (
        errors[key].error &&
          <p className="help-block"> {errors[key].error.reason} </p>
      )
    }
  }

  isFormValid() {
    const {form} = this.state

    const errors = {}
    let hasError = false
    Object.keys(form).forEach((key) => {
      if (isEmpty(form[key])) {
        errors[key] = true
        hasError = true
      }
    })
    this.setState({errors})
    return !hasError
  }

  onSubmit(e) {
    if (this.isFormValid()) this.props.onSave(this.state.form)
  }

  render() {
    const isStrategicPartner = get(this.state.form, 'clientType') === clientTypes.strategicPartner.value
    return (
      <Row>
        <Col md={12}>
          <form ref={(form) => this.messageForm = form}>
            <StylishSelect.Input
              id="t-add-lead-form-type"
              label="Type *"
              options={StylishSelect.enumToStylishOptions(this.props.leadTypes)}
              {...this.generateInputProps('clientType')}
            />
            {this.renderErrorMessage('clientType')}
            { this.props.companies && Object.values(this.props.companies).length > 1 &&
              (<StylishSelect.Input
                id="t-add-lead-form-company"
                label="Company *"
                options={StylishSelect.enumToStylishOptions(this.props.companies)}
                {...this.generateInputProps('company')}
              />)
            }
            <Form.Group>
              <Form.Label>First Name *</Form.Label>
              <Form.Control
                id="t-add-lead-form-fname"
                type="text"
                maxLength="255"
                placeholder="Enter first name"
                {...this.generateInputProps('firstName')}
              />
              {this.renderErrorMessage('firstName')}
            </Form.Group>
            <Form.Group>
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                id="t-add-lead-form-lname"
                type="text"
                maxLength="255"
                placeholder="Enter last name"
                {...this.generateInputProps('lastName')}
              />
              {this.renderErrorMessage('lastName')}
            </Form.Group>
            <Form.Group>
              <Form.Label>Email Address *</Form.Label>
              <Form.Control
                id="t-add-lead-form-email"
                type="email"
                maxLength="255"
                placeholder="Enter email address"
                {...this.generateInputProps('email')}
              />
              {this.renderErrorMessage('email')}
            </Form.Group>
            <Form.Group>
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                id="t-add-lead-form-phone"
                type="text"
                maxLength="255"
                placeholder="Enter Telephone"
                {...this.generateInputProps('phone')}
              />
              {this.renderErrorMessage('phone')}
            </Form.Group>
            <StylishSelect.Input
              id="t-add-lead-form-country"
              label="Country"
              options={StylishSelect.enumToStylishOptions(filter(countries, country => !country.hidden))}
              {...this.generateInputProps('country')}
            />
            {isStrategicPartner && <Form.Group>
              <Form.Label>Company (Other)</Form.Label>
              <Form.Control
                id="t-add-lead-form-otherCompany"
                type="text"
                maxLength="255"
                placeholder="Enter Company (Other)"
                {...this.generateInputProps('otherCompany')}
              />
              {this.renderErrorMessage('otherCompany')}
            </Form.Group>}
            {isStrategicPartner && <Form.Group>
              <Form.Label>Title</Form.Label>
              <Form.Control
                id="t-add-lead-form-title"
                type="text"
                maxLength="255"
                placeholder="Title"
                {...this.generateInputProps('title')}
              />
              {this.renderErrorMessage('title')}
            </Form.Group>}
            {isStrategicPartner && <Form.Group>
              <Form.Label>Partner</Form.Label>
              <Form.Control
                id="t-add-lead-form-bdswissPartner"
                type="text"
                maxLength="255"
                placeholder="Partner"
                {...this.generateInputProps('bdswissPartner')}
              />
              {this.renderErrorMessage('bdswissPartner')}
            </Form.Group>}
            {isStrategicPartner && <StylishSelect.Input
              id="t-add-lead-form-priority"
              label="Priority"
              options={StylishSelect.enumToStylishOptions(priorityLevels)}
              {...this.generateInputProps('priority')}
            />}
            <ButtonToolbar className="float-right">
              <Button id="t-add-lead-form-cancel-btn" variant="danger"
                onClick={this.props.onCancel}
                className="mr-3"
                size="sm"
              >
                Cancel
              </Button>
              <Button id="t-add-lead-form-save-btn" variant="success"
                size="sm"
                onClick={() => this.onSubmit()}
              >
                Save
              </Button>
            </ButtonToolbar>
          </form>
        </Col>
      </Row>
    )
  }
}
