import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar, Form, Row, Col} from 'react-bootstrap'
import PureComponent from '../PureComponent'

export default class RevertModal extends PureComponent {

  componentWillMount() {
    this.setState({
      amount: this.props.amount
    })
  }

  static propTypes = {
    show: PropTypes.bool,
    header: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
  };

  changeAmount(e) {
    this.setState({amount: Number(e.target.value)})
  }

  render() {
    const {show, header, onConfirm, onCancel} = this.props

    return (
      <Modal
        id="t-revert-modal"
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>{header}</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={4}>
              <Form.Control
                id="t-revert-modal-amount"
                type="number"
                label="Amount to revert"
                placeholder="Amount to revert"
                value={this.state.amount}
                onChange={this.changeAmount.bind(this)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              id="t-revert-modal-cancel-btn"
              onClick={onCancel}
              className="mr-3"
              variant="outline-secondary"
              size="sm"
            >
              Cancel
            </Button>
            <Button
              id="t-revert-modal-accept-btn"
              variant="success"
              size="sm"
              onClick={() => onConfirm(this.state.amount)}
            >
              Confirm
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
