import {includes, split} from 'lodash'

export const noAccountType = 'noAccount'
export const inactiveForexDemoType = 'hasInactiveForexDemoAccount'
export const activeForexDemoType = 'hasActiveForexDemoAccount'

export function formatAccountTypeFilterInput(accountType) {
  let result

  if (accountType) {
    if (includes(accountType, '-')) {
      const accountTypeSubtype = split(accountType, '-')
      result = `{accountType: "${accountTypeSubtype[0]}", accountSubType: "${accountTypeSubtype[1]}"}`
    } else {
      result = `{accountType: "${accountType}"}`
    }
  }

  return result
}
