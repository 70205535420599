import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import style from './app.module.scss'
import PureComponent from './PureComponent'

export default class Sidebar extends PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
  }

  render() {
    const {show, hide} = this.props
    return (
      <div>
        <div onClick={hide} className={style.overlay} style={{display: show ? 'block' : 'none'}}></div>
        <div className={classNames(style.sidebarContainer, show ? style.showSidebar : '')}>
          {this.props.children}
        </div>
      </div>
    )
  }
}
