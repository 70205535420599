import React from 'react'
import moment from 'moment'
import {find} from 'lodash'
import {Table, Badge} from 'react-bootstrap'
import {jobStatuses, jobTypes, jobActions} from '@bdswiss/common-enums'
import PureComponent from '../PureComponent'


export default class ListJobs extends PureComponent {

  renderRow(jobItem) {
    const statusLabelClass = this.getstatusLabelClass(jobItem.status)
    const actionLabelClass = jobItem.action === jobActions.apply.value ?
      'info' : 'secondary'
    const key = `job-${jobItem.id}`
    return (
      <tr id={key} key={key}>
        <td> {jobItem.id} </td>
        <td> {find(jobTypes, {key: jobItem.type}).label} </td>
        <td>
          <Badge variant={actionLabelClass}>
            {find(jobActions, {key: jobItem.action}).label}
          </Badge>
        </td>
        <td> {moment(jobItem.startedAt).fromNow()} </td>
        <td>
          <Badge variant={statusLabelClass}>
            {find(jobStatuses, {key: jobItem.status}).label}
          </Badge>
        </td>
        <td> {jobItem.completedAt && moment(jobItem.completedAt).fromNow()} </td>
        <td> {jobItem.rawInputUrl && (
          <a href={jobItem.rawInputUrl} target="_blank" rel="noopener noreferrer">Raw Input</a>
        )}
        </td>
        <td> {jobItem.inputUrl && (
          <a href={jobItem.inputUrl} target="_blank" rel="noopener noreferrer">Processed Input</a>
        )}
        </td>
        <td> {jobItem.outputUrl && (
          <a href={jobItem.outputUrl} target="_blank" rel="noopener noreferrer">Output</a>
        )}
        </td>
        <td> {jobItem.creator} </td>
      </tr>
    )
  }

  getstatusLabelClass(status) {
    switch (status) {
      case jobStatuses.running.key:
        return 'info'
      case jobStatuses.completed.key:
        return 'success'
      case jobStatuses.completedWithError.key:
        return 'warning'
      case jobStatuses.stopped.key:
        return 'danger'
      case jobStatuses.queued.key:
      default:
        return 'secondary'
    }
  }

  render() {
    const {jobs} = this.props
    if (!jobs.length) {
      return <h4> No Previous Jobs </h4>
    }

    return (
      <Table hover>
        <thead>
          <tr>
            <th> #Id </th>
            <th> Type </th>
            <th> Action </th>
            <th> Started At</th>
            <th> Status </th>
            <th> Completed At</th>
            <th> Raw Input Link </th>
            <th> Input Link </th>
            <th> Output Link </th>
            <th> Start By </th>
          </tr>
        </thead>
        <tbody>
          {jobs.map((j) => this.renderRow(j))}
        </tbody>
      </Table>
    )
  }
}
