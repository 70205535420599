export default function create(dbClient) {
  return {
    upsertBookAllocation(data) {
      const variables = {
        id: data.id,
        ibs: data.ibs,
        bookCategory: data.bookCategory,
        countries: data.countries,
        campaigns: data.campaigns,
      }
      const query = `
        mutation (
          $id: Int,
          $ibs: [Int],
          $bookCategory: String!,
          $countries: [Country],
          $campaigns: [Int],
        ) {
          upsertBookAllocation(
            id: $id
            ibs: $ibs
            bookCategory: $bookCategory
            countries: $countries
            campaigns: $campaigns
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    }
  }
}
