import React from 'react'
import {get} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {canQueryCampaigns} from '@bdswiss/common-permissions'
import {campaignTypes, countries} from '@bdswiss/common-enums'
import {Button, Col, Form, Pagination, Row, Card, Table, Container, Badge, InputGroup} from 'react-bootstrap'
import {events} from '../enums'
import style from './campaigns.module.scss'
import PureComponent from '../PureComponent'
import CampaignEditor from './CampaignEditor'
import {linkEditCampaign} from '../utils/links'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {campaignProvider, campaignsProvider} from './providers'
import {getPageCount, getPageRange, isNumeric, readableDate} from '../useful'
import {compose, provideProps, mountDataProviders, checkRights} from '../decorators'

const ExistingCampaignEditor = compose(
  provideProps((state) => ({
    campaign: state.campaign
  })),
  mountDataProviders({campaignProvider})
)(CampaignEditor)

const NewCampaignEditor = compose(
  provideProps(),
)(CampaignEditor)

class Campaigns extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  static contextTypes = {
    campaignsProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.campaignsProvider.fetch()
  }

  doCampaignsSearch(searchText) {
    this.props.dispatch(
      'Search campaign',
      (state) => ({...state, campaignsSearch: searchText, campaignsPage: 1})
    )
    this.setState({searchText: searchText})
  }

  render() {
    const {campaignsSearch, campaigns, campaignsCount, campaignsPage, match: {params: {campaignId}}} = this.props
    const {backendUrl} = this.context.config

    if (campaignId === 'new') {
      return <NewCampaignEditor />
    } else if (isNumeric(campaignId)) {
      return <ExistingCampaignEditor campaignId={campaignId} />
    }

    const {searchText} = this.state || {}

    return (
      <Container>
        <Row>
          <Col md={9} sm={8} xs={12}>
            <InputGroup>
              <Form.Control
                id="t-list-campaigns-search"
                type="text"
                value={searchText !== undefined ? searchText : campaignsSearch || ''}
                placeholder="Search by ID, Name"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doCampaignsSearch(searchText)) ||
                  (e.key === 'Escape' && this.doCampaignsSearch(''))
                )}
              />
              <InputGroup.Append>
                {campaignsSearch && <Button
                  key={1}
                  title="Clear"
                  id="t-list-campaigns-clear-button"
                  variant={campaignsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doCampaignsSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={campaignsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doCampaignsSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col md={2} sm={2} xs={12}>
            <LinkContainer to="/campaigns/new">
              <Button
                id="t-campaigns-new-campaign-button"
                variant="success"
                className="w-100"
              >
                New Campaign
              </Button>
            </LinkContainer>
          </Col>
          <Col md={1} sm={2} xs={12}>
            <Button
              href={`${backendUrl}/api/campaigns`}
              id={'t-campaigns-export'}
              variant="success"
              className="w-100"
              disabled={!campaigns.length}
            >
              Export
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Spot Campaign ID</th>
                      <th>Type</th>
                      <th className={style.largeColumn}>Name</th>
                      <th>Promo code</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Country</th>
                    </tr>
                  </thead>
                  <tbody>
                    {campaigns.map((campaign, i) => (
                      <CampaignItem key={i} campaign={campaign} />
                    ))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.dispatch(
                'Show page',
                (state) => ({...state, campaignsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(campaignsPage, getPageCount(campaignsCount)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={() => this.props.dispatch(
                      'Show page',
                      (state) => ({...state, campaignsPage: campaignsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={() => this.props.dispatch(
                      'Show page',
                      (state) => ({...state, campaignsPage: campaignsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === campaignsPage}
                  key={page}
                  onClick={() => this.props.dispatch(
                    'Show page',
                    (state) => ({...state, campaignsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

class CampaignItem extends PureComponent {

  render() {
    const {id, spotCampaignId, name, country, start, end, type, promoCode} = this.props.campaign
    const startDate = start ? readableDate(moment(new Date(start)), false) : ''
    const endDate = end ? readableDate(moment(new Date(end)), false) : ''
    const typeLabel = this.getTypeLabel(type)
    const countryDisplayName = get(countries[country], 'label', country)
    return (
      <tr className={'t-campaign t-list-campaigns-item-' + id}>
        <td>{linkEditCampaign(spotCampaignId, id)}</td>
        <td className="text-center">{linkEditCampaign(typeLabel, id)}</td>
        <td className="largeColumn">{linkEditCampaign(name, id)}</td>
        <td>{linkEditCampaign(promoCode, id)}</td>
        <td>{linkEditCampaign(startDate, id)}</td>
        <td>{linkEditCampaign(endDate, id)}</td>
        <td>{linkEditCampaign(countryDisplayName, id)}</td>
      </tr>
    )
  }

  getTypeLabel(type) {
    let variant
    switch (type) {
      case campaignTypes.ib.key:
        variant = 'info'
        break
      case campaignTypes.ftdRetarget.key:
        variant = 'warning'
        break
      case campaignTypes.regular.key:
      case campaignTypes.affiliate.key:
      default:
        variant = 'secondary'
    }
    return <Badge pill variant={variant}>{campaignTypes[type].label}</Badge>
  }
}

export default compose(
  checkRights(canQueryCampaigns),

  provideProps((state) => {
    const {campaignsSearch, campaigns, campaignsCount, campaignsPage} = state

    return {
      campaignsSearch,
      campaigns,
      campaignsCount,
      campaignsPage: campaignsPage || 1,
    }
  }),

  mountDataProviders({campaignsProvider}),
)(Campaigns)
