import React from 'react'
import {Card, Col, Container, Row, Table} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import moment from 'moment'
import {isEmpty} from 'lodash'
import runtimeConfig from '../config'
import {backendRequest} from '../utils/net'

const {backendUrl} = runtimeConfig.getInstance()
const BACKGROUND_COLORS = {
  'RED': '#ff000080',
  'ORANGE': 'rgba(255,174,0,0.85)',
  'BLACK': '#00000080'
}

class JobsStats extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      reportLines:[],
    }
  }


  getData() {
    const params = null
    const requestPath = `${backendUrl}/api/v1/reports/jobs/statistics`
    const HTTPMethod = 'GET'
    return backendRequest(requestPath, params, HTTPMethod)
      .then((res) => res.json())
      .then((body) => body)
      .catch((error) => error)
  }


  componentDidMount() {
    this.getData()
      .then((data) => {
        this.setState({
          reportLines: data,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error)
        alert('Something went wrong')
      })
  }


  render() {
    const {reportLines} = this.state
    return (
      <Container>
        <h3>Worker jobs statistics</h3>
        <Row className="mb-3">
          <Col xs={4}>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th colSpan="2" className="text-center">Legend</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.ORANGE}}></td>
                  <td>60 minutes elapsed since last update</td>
                </tr>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.RED}}></td>
                  <td>1  day elapsed since last update</td>
                </tr>
                <tr>
                  <td style={{backgroundColor: BACKGROUND_COLORS.BLACK}}></td>
                  <td>31 days elapsed since last update</td>
                </tr>
                <tr>
                  <td colSpan={2}>* All times displayed in user locale</td>
                </tr>

              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Type</span>
                      </th><th>
                        <span>Failed Jobs</span>
                      </th>
                      <th>
                        <span>Active Jobs</span>
                      </th>
                      <th>
                        <span>Inactive Jobs</span>
                      </th>
                      <th>
                        <span>Delayed Jobs</span>
                      </th>
                      <th>
                        <span>Other Jobs</span>
                      </th>
                      <th>
                        <span>Total Jobs</span>
                      </th>
                      <th>
                        <span>Last update</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !isEmpty(reportLines) && reportLines.map((job) => {
                        const {type,failedJobsCount,activeJobsCount,inactiveJobsCount,delayedJobsCount,otherJobsCount,jobCount,lastUpdate} = job
                        const minutesSinceStart = moment().diff(moment(lastUpdate), 'minutes')
                        const daysSinceStart = moment().diff(moment(lastUpdate), 'days')
                        let rowStyle = {}
                        if (minutesSinceStart >= 60) {
                          rowStyle = {backgroundColor: BACKGROUND_COLORS.ORANGE}
                        }
                        if (daysSinceStart >= 1) {
                          rowStyle = {backgroundColor: BACKGROUND_COLORS.RED}
                        }
                        if(daysSinceStart >= 31) {
                          rowStyle = {backgroundColor: BACKGROUND_COLORS.BLACK}
                        }
                        return (
                          <tr
                            style={rowStyle}
                          >
                            <td>{type}</td>
                            <td>{failedJobsCount}</td>
                            <td>{activeJobsCount}</td>
                            <td>{inactiveJobsCount}</td>
                            <td>{delayedJobsCount}</td>
                            <td>{otherJobsCount}</td>
                            <td>{jobCount}</td>
                            <td>{moment(lastUpdate).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

      </Container>
    )
  }
}

export default JobsStats
