export default function create(dbClient) {
  return {
    updatePermission({id, roles}) {
      const variables = {id, roles}

      const query = `
        mutation (
          $id: String!,
          $roles: [String]
        ) {
          updatePermission(
            id: $id
            roles: $roles
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
  }
}
