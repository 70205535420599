import {getNewestReadArticle} from './useful'

export const userBasicData = `
        id
        firstName
        lastName
        avatar
`

export const agentsProvider = {

  fetchInterval: 0,

  getQuery: (props) => `{
      users {
        ${userBasicData}
        roles {
          id
        }
        locationId
        companies
        isActive
        isPool
        managerId
        department
      }
    }`,

  onData: (res, dispatch, props) => dispatch(
    'Agents loaded',
    (state, res) => ({...state, agents: res.users}),
    [res]
  ),
}

export const unreadNewsProvider = {

  fetchInterval: 0,

  getQuery: (props) => {
    const fromDate = getNewestReadArticle()
    const newestReadArticle = fromDate ? new Date(fromDate) : new Date(1)
    const condition = `createdAfter: ${JSON.stringify(newestReadArticle)}`
    const query = `{
      articlesCount(${condition})
      articles(${condition}) {
        id
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Unread articles count loaded',
    (state, res) => ({
      ...state,
      unreadArticlesCount: res.articlesCount,
      unreadArticles: res.articles,
    }),
    [res]
  ),
}

export const viewerQuery = `
  {
    viewer {
      __typename
      ... on User {
        id
        companies
        roles {
          id
          permissions {
            id
          }
        }
        firstName
        lastName
        avatar
        department
        departmentRoleType
        salesAgentType
      }
    }
  }
`


export const configProvider = {

  fetchInterval: 0,

  getQuery: (props) => {
    const query = `{
      config {
        serversWeights
        whitelabelServersWeights
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Config loaded',
    (state, res) => ({
      ...state,
      appConfig: res.config,
    }),
    [res]
  ),
}
