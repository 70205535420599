import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import ReactAudioPlayer from 'react-audio-player'
import {merge, capitalize, debounce, map, filter, isEmpty, range} from 'lodash'
import {canQueryCommunications} from '@bdswiss/common-permissions'
import {communicationChannels, communicationTypes, userDepartments} from '@bdswiss/common-enums'
import {Row, Col, Table, Button, Card, Pagination, Form, Modal, Container, InputGroup, Badge} from 'react-bootstrap'
import {events} from '../enums'
import style from './communications.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {communicationsProvider} from './providers'
import {getAllowedCompanies} from '../utils/general'
import {getPageCount, getPageRange, readableDate} from '../useful'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import SelectAgentFilter from '../components/SelectAgentFilter'
import CommunicationLink from '../client/communications/CommunicationLink'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'
import {safeParseJSON} from '../common/utils'
import EmailDetail from '../client/emailview/EmailDetail'

const EXPORT_LIMIT = 1000

class Communications extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
    this.state = {
      modalShown: false,
      modalId: '',
      modalMeta: '{}',
      searchText: '',
      loading: false,
      exportModalShown: false,
      exportPage: 1,
      showEmailModal: false,
      emailThread: [],
    }
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    communicationsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {
      createdFrom: '',
      createdTo: '',
    }
    this.setState({dateFilters: stateDateFilters})
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.communicationsProvider.fetch()
  }

  showModal = (modalId, modalMeta) => e => this.setState({
    modalShown: true,
    modalId,
    modalMeta,
  })

  hideModal = () => this.setState({modalShown: false})

  doCommunicationsSearch = searchText => {
    this.props.uiDispatch(
      'Search communications',
      (state, arg) => ({...state, communicationsSearch: arg, communicationsPage: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  doFilter = filterName => values => {
    this.props.uiDispatch(
      `Filter ${filterName}`,
      (state) => ({...state, [`communications${capitalize(filterName)}Filter`]: map(values, o => o.value), communicationsPage: 1})
    )
  }

  handleDateFilterChanged = (name, value) => {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })

    if (!value || moment.isMoment(value)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch = () => {
    const {dateFilters} = this.state
    if (dateFilters && moment.isMoment(dateFilters.createdFrom)) {
      dateFilters.createdFrom.startOf('day')
    }
    if (dateFilters && moment.isMoment(dateFilters.createdTo)) {
      dateFilters.createdTo.endOf('day')
    }
    this.props.uiDispatch(
      'Filter documents by dates',
      (state, arg) => ({...state, dateFilters, communicationsPage: 1}),
      [dateFilters]
    )
  }

  onSearch() {
    this.setState({loading: true})
    const {exportPage} = this.state
    this.props.actions.communications.communicationsExportSearch(this.props, exportPage, EXPORT_LIMIT).then((res) => {
      const columns = ['Client ID', 'Client Name', 'Sales Agent', 'Date', 'Type', 'Department', 'Title', 'Summary',
        'To', 'From', 'Snippet'
      ]
      const result = map(filter(res.communications, (c) => c.client), (c) => {
        const fullName = `${c.client.firstName} ${c.client.lastName}`
        const salesAgentName = c.client.salesAgent
          ? `${c.client.salesAgent.firstName} ${c.client.salesAgent.lastName}` : 'Unassigned'
        return [
          c.client.id,
          fullName,
          salesAgentName,
          readableDate(moment(c.createdAt)),
          communicationTypes[c.type]?.label || c.type,
          userDepartments[c.department].label,
          `"${(c.title || '').replace(/&#\d+;/gm, (s) => String.fromCharCode(s.match(/\d+/gm)[0]))}"`,
          `"${(c.summary || '').replace(/&#\d+;/gm, (s) => String.fromCharCode(s.match(/\d+/gm)[0]))}"`,
          `"${(safeParseJSON(c.meta)?.to ?? '').replace(/&#\d+;/gm, (s) => String.fromCharCode(s.match(/\d+/gm)[0]))}"`,
          `"${(safeParseJSON(c.meta)?.from ?? '').replace(/&#\d+;/gm, (s) => String.fromCharCode(s.match(/\d+/gm)[0]))}"`,
          `"${(safeParseJSON(c.meta)?.snippet ?? '').replace(/&#\d+;/gm, (s) => String.fromCharCode(s.match(/\d+/gm)[0]))}"`,
        ].join(',')
      })
      const csvContent = columns + '\r\n' + result.join('\r\n')
      const blob = new Blob([csvContent], {type: 'text/csv;charset=utf-8;'})
      const url = URL.createObjectURL(blob)

      const link = document.createElement('a')
      link.href = url
      link.download = `communications-export-page-${exportPage}-${new Date().toISOString()}.csv`

      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      this.setState({loading: false, exportModalShown: false, exportPage: 1})
    }).catch((e) => {
      this.setState({loading: false})
      this.context.showNotification({
        message: e.message,
        level: 'error',
        autoDismiss: 0,
      })
    })
  }

  fetchThread = (messageId) => {
    if (!messageId) {
      return this.setState({showModal: false})
    }
    this.props.actions.client.fetchEmailThread(messageId)
      .then((res) => {
        this.setState({
          emailThread: res.emailThread,
          showEmailModal: true,
        })
      })
      .catch(this.context.logError)
  }

  render() {
    const {viewer, communications, communicationsCount, communicationsPage, communicationsSearch,
      communicationsChannelsFilter, communicationsTypesFilter, communicationsDepartmentsFilter,
      communicationsCompaniesFilter, communicationsSalesAgentFilter, state: {config: {callRecordingsUri}},
      agents, communicationsExcludeAutoEmailsFilter,
    } = this.props
    const {modalShown, modalId, modalMeta, searchText, dateFilters, loading, exportModalShown,
      exportPage, showEmailModal, emailThread
    } = this.state
    const validCommunications = filter(communications, (c) => c.client)

    return (
      <Container>
        <Row key="filter-row-1">
          <Col xs={3}>
            <InputGroup>
              <Form.Control
                type="text"
                value={searchText !== undefined ? searchText : communicationsSearch || ''}
                placeholder="Search by ID or ClientID"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doCommunicationsSearch(searchText)) ||
                  (e.key === 'Escape' && this.doCommunicationsSearch(''))
                )}
              />
              <InputGroup.Append>
                {communicationsSearch && <Button
                  key={1}
                  title="Clear"
                  variant={communicationsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doCommunicationsSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={communicationsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doCommunicationsSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={3}>
            <StylishSelect
              id="t-channels-filter"
              placeholderText="Any Channel"
              value={communicationsChannelsFilter}
              options={map(communicationChannels, o => ({label: o.label, value: o.value}))}
              highlightIfActive
              onChange={this.doFilter('channels')}
              multi
              clearable
            />
          </Col>
          <Col xs={3}>
            <StylishSelect
              id="t-types-filter"
              placeholderText="Any Type"
              value={communicationsTypesFilter}
              options={map(communicationTypes, o => ({label: o.label, value: o.value}))}
              highlightIfActive
              onChange={this.doFilter('types')}
              multi
              clearable
            />
          </Col>
          <Col xs={3}>
            <StylishSelect
              id="t-types-filter"
              placeholderText="Any Department"
              value={communicationsDepartmentsFilter}
              options={map(userDepartments, o => ({label: o.label, value: o.value}))}
              highlightIfActive
              onChange={this.doFilter('departments')}
              multi
              clearable
            />
          </Col>
        </Row>
        <Row key="filter-row-2" className="mt-3">
          <Col xs={2}>
            <span className={style.label}> Created From </span>
            <DateTime
              id="t-deposits-created-from-filter"
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('createdFrom', e)}
              value={dateFilters.createdFrom}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              className={style.datetime}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}> Created To </span>
            <DateTime
              id="t-deposits-created-to-filter"
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('createdTo', e)}
              value={dateFilters.createdTo}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              className={style.datetime}
            />
          </Col>
          <Col xs={2}>
            <span className={style.label}>&nbsp;</span>
            <SelectAgentFilter
              viewer={viewer}
              agents={agents}
              onChange={({value}) => this.props.uiDispatch(
                'Filter by sales agent',
                (state, arg) => ({...state, communicationsSalesAgentFilter: value, page: 1}),
                [value]
              )}
              value={communicationsSalesAgentFilter}
            />
          </Col>
          {viewer.companies.length > 1 &&
            <Col xs={3}>
              <span className={style.label}>&nbsp;</span>
              <StylishSelect
                id="t-companies-filter"
                placeholderText="All Companies"
                value={communicationsCompaniesFilter}
                options={StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer))}
                highlightIfActive
                onChange={this.doFilter('companies')}
                multi
                clearable
              />
            </Col>
          }
          <Col xs={3}>
            <span className={style.label}>&nbsp;</span>
            <Form.Check
              id="t-exclude-automated-emails"
              label="Exclude Automated Emails"
              checked={communicationsExcludeAutoEmailsFilter}
              onChange={(e) => this.props.uiDispatch(
                'Filter by automated emails',
                (state, arg) => ({...state, communicationsExcludeAutoEmailsFilter: e.target.checked, page: 1}),
                [e.target.checked]
              )}
            />
          </Col>
        </Row>
        {[userDepartments.compliance.key, userDepartments.complaints.key].includes(viewer.department) && <Row className="mt-3">
          <Col xs={6}>
            {!isEmpty(validCommunications) && <Button
              id="t-communications-export-button"
              variant="outline-secondary"
              onClick={() => this.setState({exportModalShown: true})}
              disabled={loading}
            >
              {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
            </Button>}
          </Col>
        </Row>}
        <Row className="mt-3">
          <Col xs={12}>
            <Card className="two-rows-filter">
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Client ID</th>
                      <th>Client Name</th>
                      <th>Sales Agent</th>
                      <th>Channels</th>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Department</th>
                      <th>Title</th>
                      <th width="40%">Summary</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {validCommunications.map((communication) => {
                      const {id, channels, type, department, title, summary, meta, createdAt, client, foreignId} = communication
                      const fullName = `${client.firstName} ${client.lastName}`
                      const salesAgentName = client.salesAgent
                        ? `${client.salesAgent.firstName} ${client.salesAgent.lastName}` : 'Unassigned'
                      const showMoreBtn = channels.includes(communicationChannels.email.key) ? <Button
                        className="btn-xs mt-1"
                        variant="outline-secondary"
                        onClick={() => this.fetchThread(foreignId)}
                      >Show Email</Button> : null
                      return <tr key={id}>
                        <td>
                          <CommunicationLink communication={communication}>{client.id}</CommunicationLink>
                        </td>
                        <td>
                          <CommunicationLink communication={communication}>{fullName}</CommunicationLink>
                        </td>
                        <td>
                          <CommunicationLink communication={communication}>{salesAgentName}</CommunicationLink>
                        </td>
                        <td>
                          <CommunicationLink communication={communication}>
                            {channels.map((channel, i) => <Badge
                              className={style.channels}
                              variant="success"
                              pill
                              key={`channel-${i}`}
                            >{communicationChannels[channel].label}</Badge>
                            )}
                          </CommunicationLink>
                        </td>
                        <td title={createdAt.fromNow()}><CommunicationLink communication={communication}>{readableDate(createdAt)}</CommunicationLink></td>
                        <td><CommunicationLink communication={communication}>{communicationTypes[type]?.label || type}</CommunicationLink></td>
                        <td><CommunicationLink communication={communication}>{userDepartments[department].label}</CommunicationLink></td>
                        <td><CommunicationLink communication={communication}>{title}</CommunicationLink></td>
                        <td>
                          {type === 'call' ? <ReactAudioPlayer
                            src={`${callRecordingsUri}${JSON.parse(meta).recording}`}
                            preload="none"
                            controls
                          /> : summary}
                        </td>
                        {!isEmpty(safeParseJSON(meta))
                          ? <td className="text-center">
                            <Button
                              className="btn-xs"
                              variant="outline-secondary"
                              onClick={this.showModal(id, meta)}
                            >Details</Button>
                            {showMoreBtn}
                          </td>
                          : <td>{showMoreBtn}</td>
                        }
                      </tr>
                    }
                    )}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, communicationsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(communicationsPage, getPageCount(communicationsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, communicationsPage: communicationsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, communicationsPage: communicationsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === communicationsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, communicationsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>

        <Modal
          keyboard
          show={modalShown}
          onHide={this.hideModal}
          className={style.receipt}
        >
          <Modal.Header closeButton className={style.modalHeader}>
            Communication #{modalId}
          </Modal.Header>
          <Modal.Body>
            <Card className={style.receiptContent}>
              <Card.Body>
                <pre>{JSON.stringify(JSON.parse(modalMeta), null, 2)}</pre>
              </Card.Body>
            </Card>
          </Modal.Body>
        </Modal>

        <Modal
          keyboard
          show={exportModalShown}
          onHide={() => this.setState({
            exportModalShown: false,
            exportPage: 1,
          })}
          // className={style.receipt}
        >
          <Modal.Header closeButton className={style.modalHeader}>
            Export Communications
          </Modal.Header>
          <Modal.Body>
            <Row className="mb-3">
              <Col xs={12}>
                <span style={{fontSize: 12}}>
                  *The export is limited to 1,000 results. If the search results are more than 1,000, please select
                  another page from the below dropdown or use a more specific search.
                </span>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col xs={6}>
                <StylishSelect
                  id="t-communications-export-page-select"
                  placeholderText="Select a page"
                  defaultValue={{value: 1, label: 1}}
                  options={map(range(0, getPageCount(communicationsCount, EXPORT_LIMIT)), (p) => ({
                    value: p + 1,
                    label: p + 1,
                  }))}
                  onChange={(e) => this.setState({exportPage: e.value})}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Button
                  id="t-communications-export-modal-button"
                  variant="outline-secondary"
                  className="float-right"
                  onClick={() => this.onSearch()}
                  disabled={loading  || !exportPage}
                >
                  {loading ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
                </Button>
              </Col>
            </Row>
          </Modal.Body>
        </Modal>

        <Modal
          id="t-email-full-view-modal"
          keyboard
          show={showEmailModal}
          onHide={() => {
            this.setState({showEmailModal: false})
          }}
          className={style.emailModal}
        >
          <Modal.Header closeButton className={style.modalHeader}>
            <strong>{emailThread[0]?.subject}</strong>
          </Modal.Header>
          <Modal.Body>
            <EmailDetail thread={emailThread} />
          </Modal.Body>
        </Modal>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryCommunications),

  uiMount((state) => ['ui', 'communications']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/communications',
    (state) => {
      const dateFilters = state && state.dateFilters
      return merge({dateFilters}, state)
    })
  ),

  provideProps((state, uiState) => {
    const {communications, communicationsCount, agents} = state
    const {communicationsPage, communicationsSearch, communicationsChannelsFilter,
      communicationsTypesFilter, communicationsDepartmentsFilter, dateFilters, communicationsCompaniesFilter,
      communicationsSalesAgentFilter, communicationsExcludeAutoEmailsFilter,
    } = uiState

    return ({
      agents,
      communications,
      communicationsCount,
      communicationsPage: communicationsPage || 1,
      communicationsSearch,
      communicationsChannelsFilter,
      communicationsTypesFilter,
      communicationsDepartmentsFilter,
      dateFilters,
      communicationsCompaniesFilter,
      communicationsSalesAgentFilter,
      communicationsExcludeAutoEmailsFilter,
    })
  }),

  mountDataProviders({communicationsProvider}),
)(Communications)
