import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {size, get, filter, isEmpty, merge, uniqBy, omitBy, keyBy, values, assign, sortBy} from 'lodash'
import {List as Loading} from 'react-content-loader'
import {Table, Row, Col, Button, ButtonToolbar, Badge} from 'react-bootstrap'
import {clientNoticeTypes, clientNoticeAcknowledgementTypes, signableNotices,
  partnersAppendices, clientTypes} from '@bdswiss/common-enums'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import PureComponent from '../PureComponent'
import {readableDate} from '../useful'
import {createClientAppendixUrl, editClientAppendixUrl}  from '../utils/links'
import {provideProps} from '../decorators'
import ApendixPreviewModal from './ApendixPreviewModal'
import {canCreateEditPartnerAppendix} from '@bdswiss/common-permissions'

export default provideProps()(class Acknowledgements extends PureComponent {

  state = {
    showAppendixModal: false
  }

  static propTypes = {
    client: PropTypes.object.isRequired,
    switchSidebar: PropTypes.func.isRequired,
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  componentWillReceiveProps(newProps) {
    const {isActive} = newProps
    const {dataFetched, dataError,dataLoading} = this.state
    if (isActive && !dataFetched && !dataError && !dataLoading) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.notices.fetch()
          .then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e, dataFetched: true}))
      })
    }
  }

  newAppendixSidebar() {
    this.context.router.push(createClientAppendixUrl(this.props.client.generalInfo.id))
  }

  editAppendixSidebar() {
    this.context.router.push(editClientAppendixUrl(this.props.client.generalInfo.id))
  }

  closeAppendixPreview() {
    this.setState({showAppendixModal: false})
  }

  getPartnerNotices() {
    const {client: {pendingNotices, acknowledgedNotices}} = this.props

    const pendingPartnersNotices = filter(pendingNotices, (notice) =>
      notice.type === clientNoticeTypes.partners.value).map((notice) => assign({}, notice, {status: 'Pending'}))

    const seenPartnerNotices = uniqBy(sortBy(filter(acknowledgedNotices,(acknowledgement) =>
      acknowledgement.notice.type === clientNoticeTypes.partners.value),(n) => n.id).reverse(),
    ({notice}) => notice.id).map(({notice, acknowledgementType})=>
      assign({}, notice, {status: acknowledgementType}))

    const partnersNotices = values(merge(keyBy(pendingPartnersNotices, 'id'), keyBy(seenPartnerNotices, 'id')))
    return partnersNotices

  }

  renderPartnersNotices() {
    const partnersNotices = this.getPartnerNotices()

    if (!partnersNotices || size(partnersNotices) < 1) {
      return <div> No Partners Notices </div>
    }
    return (<Table>
      <thead>
        <tr>
          <th> Template </th>
          <th> Created At </th>
          <th> Status </th>
          <th> Actions </th>
        </tr>
      </thead>
      <tbody>
        {!isEmpty(partnersNotices) && partnersNotices.map((partnerNotice, i) => {
          const showEditBtn = clientNoticeAcknowledgementTypes.accepted.value !==
          partnerNotice.status && canCreateEditPartnerAppendix(this.props.viewer)
          const actionLabelClass = partnerNotice.status !== 'Pending' &&
            this.getAcknowledgedTypeLabelClass(partnerNotice.status)

          return (<tr key={i}>
            <td> {partnerNotice && partnersAppendices[partnerNotice.template].label} </td>
            <td> {partnerNotice && readableDate(moment(partnerNotice.createdAt))}</td>
            <td>
              {partnerNotice.status && partnerNotice.status !== 'Pending' &&
              <Badge pill variant={actionLabelClass}>
                {clientNoticeAcknowledgementTypes[partnerNotice.status].label}
              </Badge>}
              {partnerNotice.status === 'Pending' &&
              <Badge variant="secondary">
                Pending
              </Badge>}
            </td>
            <td>
              <ButtonToolbar>
                {showEditBtn && <Button
                  id="t-client-appendix-edit"
                  variant="info"
                  className="btn-xs mr-1"
                  key="appendix-edit-btn"
                  onClick={() => this.editAppendixSidebar(partnerNotice)}
                >Edit</Button>}
                <Button
                  id="t-client-appendix-view"
                  onClick={()=>this.setState({showAppendixModal: partnerNotice.id})}
                  variant="success"
                  className="btn-xs"
                  key="appendix-view-btn"
                >View
                </Button>
                <ApendixPreviewModal
                  appendix={partnerNotice}
                  show={this.state.showAppendixModal === partnerNotice.id}
                  onCancel={() => this.closeAppendixPreview()}
                />
              </ButtonToolbar>
            </td>
          </tr>)}
        )
        }
      </tbody>

    </Table>)
  }

  renderPendingNotices() {
    const {client: {pendingNotices}} = this.props
    if (!pendingNotices || size(pendingNotices) < 1) {
      return <div> No Pending Notices </div>
    }

    const filterPendingNotices = filter(pendingNotices, (o) => o.type !== clientNoticeTypes.partners.value)
    return (<Table>
      <thead>
        <tr>
          <th> Reference Code </th>
          <th> Version </th>
          <th> Type </th>
          <th> Created At </th>
        </tr>
      </thead>
      <tbody>
        {filterPendingNotices.map((a, i) => (<tr key={i}>
          <td> {a.referenceCode} </td>
          <td> {a.version} </td>
          <td> {clientNoticeTypes[a.type].label} </td>
          <td> {readableDate(a.createdAt)} </td>
        </tr>))}
      </tbody>
    </Table>)
  }

  getAcknowledgedTypeLabelClass(type) {
    switch (type) {
      case clientNoticeAcknowledgementTypes.seen.key :
        return 'warning'
      case clientNoticeAcknowledgementTypes.accepted.key :
        return 'success'
      default:
        return 'default'
    }
  }

  renderAcknowledgedNotices() {
    const {client: {acknowledgedNotices}} = this.props
    if (!acknowledgedNotices || size(acknowledgedNotices) < 1) {
      return <div> No Acknowledged Notices </div>
    }
    return (<Table hover striped>
      <thead>
        <tr>
          <th> Reference Code </th>
          <th>  Type </th>
          <th> Acknowleded At </th>
        </tr>
      </thead>
      <tbody>
        {!isEmpty(acknowledgedNotices) && acknowledgedNotices.map((a, i) => {
          const actionLabelClass = this.getAcknowledgedTypeLabelClass(a.acknowledgementType)
          return (<tr key={i}>
            <td> {a.notice.referenceCode} </td>
            <td>
              <Badge pill variant={actionLabelClass}>
                {clientNoticeAcknowledgementTypes[a.acknowledgementType].label}
              </Badge>
            </td>
            <td> {readableDate(a.createdAt)} </td>
          </tr>)
        })}
      </tbody>
    </Table>)
  }

  renderOtherNotices() {
    const {client: {generalInfo: {signableNoticeAckSignatures}}} = this.props
    if (!signableNoticeAckSignatures || size(signableNoticeAckSignatures) < 1) {
      return <div> No Other Notices</div>
    }
    return (<Table hover striped>
      <thead>
        <tr>
          <th>Signature</th>
          <th>Notice Id</th>
          <th>Date Signed</th>
        </tr>
      </thead>
      <tbody>
        {signableNoticeAckSignatures.map((a, i) =>
          <tr key={i}>
            <td> {a.signature} </td>
            <td>{get(signableNotices[a.noticeId], 'label', a.noticeId)}</td>
            <td> {readableDate(moment(a.dateSigned))} </td>
          </tr>
        )}
      </tbody>
    </Table>)
  }

  render() {
    const {client:{generalInfo: {clientType}}} = this.props
    const partnerNotices = this.getPartnerNotices()
    const notAcceptedPartnerNotices = omitBy(partnerNotices, (notice) =>
      notice.status === clientNoticeAcknowledgementTypes.accepted.value)

    const enableAppendixButton = [clientTypes.affiliate.key,
      clientTypes.affiliateCorporate.key].includes(clientType) &&
       (isEmpty(partnerNotices) || isEmpty(notAcceptedPartnerNotices)) &&
    canCreateEditPartnerAppendix(this.props.viewer)

    const {dataLoading, dataError} = this.state
    if (dataLoading) return <Loading speed={1} style={{padding:'20px'}}/>
    if (dataError) return <pre> Error Loading Notices : {dataError.message} </pre>

    return (<div>
      <Row style={{marginBottom: 20}}>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h6> <FontAwesomeIcon icon={'history'} /> Pending Notices </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderPendingNotices()}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{marginBottom: 20}}>
        <Col xs={12}>
          <Row>
            <Col xs={6}>
              <h6> <FontAwesomeIcon icon={'history'} /> Partner Notices </h6>
            </Col>
            {enableAppendixButton && <Col xs={6}>
              <div className="float-right">
                <Button
                  id="t-add-appendix"
                  variant="success"
                  className="btn-xs"
                  onClick={() => this.newAppendixSidebar()}
                >
                  Add Appendix
                </Button>
              </div>
            </Col>}
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderPartnersNotices()}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{marginBottom: 20}}>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h6> <FontAwesomeIcon icon={'check-circle'} /> Acknowledged Notices </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderAcknowledgedNotices()}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h6> <FontAwesomeIcon icon={'flag'} /> Other Notices</h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              {this.renderOtherNotices()}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>)
  }
})

