import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Row, Col, Table, Card, Pagination, Container} from 'react-bootstrap'
import {permissions} from '@bdswiss/common-permissions'
import {orderDirections} from '@bdswiss/common-enums'
import style from './agentsCommissions.module.scss'
import {events} from '../enums'
import {agentsCommissionsProvider} from './providers'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkOneOfPermissions} from '../decorators'
import {withRouter} from 'react-router-dom'
import AgentsCommissionsFilter from './AgentsCommissionsFilters'
import moment from 'moment'

class AgentsCommissions extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.clickRowHandle = this.clickRowHandle.bind(this)
    this.doSortByOrderAndOrderDirection = this.doSortByOrderAndOrderDirection.bind(this)
    this.paginationSelectHandle = this.paginationSelectHandle.bind(this)
    this.paginationClickHandle = this.paginationClickHandle.bind(this)
    this.state = {}
  }

  static contextTypes = {
    agentsCommissionsProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.agentsCommissionsProvider.fetch()
  }

  clickRowHandle(e) {
    const {agentId} = e.currentTarget.dataset
    if (agentId) {
      const endDate = this.props.agentsCommissionsFilter.endDate ?? moment().format('YYYY-MM-DD')
      const showOnlyGoodDeposits = '1'
      const route = `/agent-commissions/${agentId}/${this.props.agentsCommissionsFilter.startDate}/${endDate}/${showOnlyGoodDeposits}`
      this.props.history.push(route)
    } else {
      // eslint-disable-next-line no-console
      console.warn('Agent ID doesn\'t specified')
    }
  }

  doSortByOrderAndOrderDirection(e) {
    const {field} = e.currentTarget.dataset
    const {agentsCommissionsSort} = this.props
    const {orderBy, orderDirection} = agentsCommissionsSort

    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting agents commissions by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      agentsCommissionsSort: newSort
    }), [agentsCommissionsSort])
  }

  getSortArrow(field) {
    const {agentsCommissionsSort} = this.props
    const arrowDirection = agentsCommissionsSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (agentsCommissionsSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  paginationSelectHandle(e, selectedEvent) {
    this.props.uiDispatch('Show page', (state) => ({
      ...state,
      agentsCommissionsPage: selectedEvent.eventKey
    }))
  }

  paginationClickHandle(e) {
    this.props.uiDispatch('Show page', (state) => ({
      ...state,
      agentsCommissionsPage: +e.currentTarget.dataset.page
    }))
  }

  render() {
    const {agentsCommissions, agentsCommissionsCount, agentsCommissionsPage} = this.props

    return (
      <Container>
        <Row>
          <Col xs={10}>
            <div className={`h4 ${style.toolbar}`}>
              Conversion report
            </div>
          </Col>
        </Row>
        <AgentsCommissionsFilter/>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body style={{overflowX: 'scroll'}}>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      {[
                        {header: 'Agent ID', sortKey: 'agentId', minWidth: 40},
                        {header: 'Agent Name', sortKey: 'agentName', minWidth: 100},
                        {header: 'Number of clients', sortKey: 'clientsCount', minWidth: 100},
                        {header: 'Commission Size (%)', sortKey: 'commissionSize', minWidth: 100},
                        {header: 'FTD Amount', sortKey: 'ftdAmount', minWidth: 100},
                        {header: 'FTD Bonus', sortKey: 'ftdBonus', minWidth: 100},
                        {header: 'Qualified FTDs', sortKey: 'qualifiedFTDs', minWidth: 100},
                        {header: 'Unqualified FTDs', sortKey: 'unqualifiedFTDs', minWidth: 100},
                      ].map(({header, sortKey, minWidth}) => (
                        <th key={sortKey} style={{minWidth}}>
                          <span>{header}</span>
                          <FontAwesomeIcon
                            icon={this.getSortArrow(sortKey)}
                            onClick={this.doSortByOrderAndOrderDirection}
                            data-field={sortKey}
                          />
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className={style['agents-commissions-table__tbody']}>
                    {agentsCommissions?.map(({agentId, agentName, clientsCount, commissionSize, ftdAmount, ftdBonus, qualifiedFTDs, unqualifiedFTDs}) => (<tr
                      key={agentId}
                      id={'t-sc-id-' + {agentName}}
                      className={classNames(['t-sc'])}
                      onClick={this.clickRowHandle}
                      data-agent-id={agentId}
                    >
                      <td>{agentId}</td>
                      <td>{agentName}</td>
                      <td>{clientsCount}</td>
                      <td>{(commissionSize * 100).toFixed(2)}</td>
                      <td>{ftdAmount}</td>
                      <td>{ftdBonus}</td>
                      <td>{qualifiedFTDs}</td>
                      <td>{unqualifiedFTDs}</td>
                    </tr>))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={this.paginationSelectHandle}
                >
                  {getPageRange(agentsCommissionsPage, getPageCount(agentsCommissionsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={this.paginationClickHandle}
                        data-page={agentsCommissionsPage - 1}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={this.paginationClickHandle}
                        data-page={agentsCommissionsPage + 1}
                      />
                    }

                    return <Pagination.Item
                      active={page === agentsCommissionsPage}
                      key={page}
                      onClick={this.paginationClickHandle}
                      data-page={page}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkOneOfPermissions(permissions.conversion_report_read, permissions.own_conversion_report_read),
  uiMount(() => ['ui', 'agentsCommissions']),
  predispatch((props) => props.uiDispatch(
    'Initialize ui/agent\'s commissions report',
    (state) => ({
      ...state,
      ...(!state.agentsCommissionsSort ? {
        agentsCommissionsSort: {
          orderBy: 'agentId',
          orderDirection: orderDirections.descending.key
        }
      } : {})
    })
  )),
  provideProps((state, uiState) => {
    const {agentsCommissions} = state
    const {agentsCommissionsSort, agentsCommissionsFilter, agentsCommissionsPage = 1} = uiState

    // todo: structure of data on back (it was optimized on the back) and front is different, make sense to align it on the frontend
    return ({
      agentsCommissions: agentsCommissions?.rows,
      agentsCommissionsCount: agentsCommissions?.info.size,
      agentsCommissionsSort,
      agentsCommissionsFilter,
      agentsCommissionsPage,
    })
  }),
  withRouter,
  mountDataProviders({agentsCommissionsProvider}),
)(AgentsCommissions)
