/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Promise from 'bluebird'
import {isEmpty, orderBy} from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import 'react-datetime/css/react-datetime.css'
import {userDepartments, activityLogTypes} from '@bdswiss/common-enums'
import {canCreateNotes} from '@bdswiss/common-permissions'
import {Row, Button, Card, Col, Form, InputGroup, FormControl} from 'react-bootstrap'
import Event from './Event'
import style from './client.module.scss'
import {putFile} from '../utils/net'
import {eventCategories} from '../enums'
import {shortenFilename} from '../textUtils'
import {isEmptyStr} from '../common/utils'
import {isKey, keys} from '../utils/keyboard'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import ConfirmationModal from '../components/ConfirmationModal'
import {compose, provideProps, predispatch} from '../decorators'
import TextareaAutosizeInput from '../components/TextareaAutosizeInput'
import {negateBooleanProperty, defaultPageSizeActivityLog} from '../useful'

const orderEvents = (a, b) => -a.date.diff(b.date)


class Events extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    appointmentsProvider: PropTypes.object.isRequired,
    viewerAppointmentsCountProvider: PropTypes.object.isRequired,
    appointmentsByDayProvider: PropTypes.object.isRequired,
  };

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    activityLogs: PropTypes.array,
    appointments: PropTypes.array,
    showAppointmentEditor: PropTypes.func,
    uiDispatch: PropTypes.func.isRequired,
    fetchMore: PropTypes.func,
    uiState: PropTypes.object.isRequired,
    onCreate: PropTypes.func,
    // following properties are for creating notes to objects
    depositId: PropTypes.number,
    bonusOfferId: PropTypes.number,
    withdrawalId: PropTypes.number,
    documentId: PropTypes.number,
    // following properties are needed only if there are appointments among events
    // if they are not present, edit appointment tab wont be rendered
    salesAgentId: PropTypes.number,
    supportAgentId: PropTypes.number,
    externalAgentId: PropTypes.number,
    sideTab: PropTypes.string.isRequired,
  };

  state = {
    searchTypeFilter: '',
  }

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  componentWillMount() {
    this.resetForm()
  }

  componentWillReceiveProps(nextProps) {
    const {fetchMore, visiblePages, userDepartmentFilter, typesFilter, searchTypeFilter, userFilter} = this.props
    const {visiblePages: nextVisiblePages} = nextProps
    if (fetchMore &&
      (
        nextVisiblePages !== visiblePages
        || userDepartmentFilter !== nextProps.userDepartmentFilter
        || typesFilter !== nextProps.typesFilter
        || searchTypeFilter !== nextProps.searchTypeFilter
        || userFilter !== nextProps.userFilter
      )
    ) {
      fetchMore()
    }
  }

  resetForm = () => this.setState({
    createButtonDisabled: false,
    checkCurrentUserActivityLogs: false,
    checkBackofficeActivityLogs: false,
    values: {},
    errors: {},
    feedback: false,
  })

  completeOrDeleteAppointment = ({id}, appointmentAction) => {
    this.setState({completeOrDeleteAppointmentId: id, appointmentAction})
  }

  clearCompleteOrDeleteAppointment = () => {
    this.setState({completeOrDeleteAppointmentId: null})
  }

  requeryAppointmentProviders = () => {
    const {appointments: appointmentsProvider} = this.context.clientProvider.subProviders
    appointmentsProvider.fetch()
    this.context.appointmentsProvider.fetch()
    this.context.viewerAppointmentsCountProvider.fetch()
    this.context.appointmentsByDayProvider.fetch()
    this.context.appointmentsProvider.fetch()
  }

  confirmCompleteOrDeleteAppointment = () => {
    const {actions} = this.props
    const id = this.state.completeOrDeleteAppointmentId
    this.clearCompleteOrDeleteAppointment()
    const mutation = this.state.appointmentAction === 'delete'
      ? actions.client.deleteAppointment(id)
      : actions.client.completeAppointment(id)
    return mutation
      .then(this.requeryAppointmentProviders)
      .catch(this.context.logError)
  }

  noteAction = () => {
    const errors = this.validate(undefined)
    if (!isEmpty(errors)) {
      this.setState({errors, feedback: true})
      return
    }
    if (this.state.createButtonDisabled) {
      return
    }

    const {actions, clientId, depositId, bonusOfferId, withdrawalId, documentId, onCreate} = this.props
    const {activityLogs: activityLogsProvider} = this.context.clientProvider.subProviders
    const {content, files} = this.state.values

    let uploadPromise
    if (files && files.length > 0) {
      uploadPromise = this.props.actions.client.signUploadUrl(clientId)
        .then((res) => {
          const {key, signedUrl} = res.signUploadUrl
          const file = files[0]
          return putFile(file, signedUrl)
            .then(() => ({fileName: file.name, fileKey: key}))
        })
    } else {
      uploadPromise = Promise.resolve({})
    }

    this.disableCreateButton()
    uploadPromise
      .then(({fileName, fileKey}) => actions.client.addNote(
        clientId, content, depositId, bonusOfferId, withdrawalId, documentId, fileName, fileKey
      ))
      .then(activityLogsProvider.fetch)
      .then(() => onCreate && onCreate())
      .then(this.resetForm)
      .catch(this.context.logError)
  }

  showMoreEvents() {
    this.props.uiDispatch(
      'Show more events ',
      (state) => ({
        ...state,
        visiblePages: state.visiblePages + 1,
        fetchingMore: true,
      })
    )
  }

  dispatchToggleShowFullEvent(eventId) {
    this.props.uiDispatch(
      'Toggle full event ' + eventId,
      (state) => ({
        ...state,
        revealedEvents: negateBooleanProperty(state.revealedEvents, eventId),
      })
    )
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    if (this.state.feedback) {
      this.setState({values, errors: this.validate(values)})
    } else {
      this.setState({values})
    }
  }

  validate(values = this.state.values) {
    const errors = {}

    if (isEmptyStr(values.content)) {
      errors.content = true
    }

    return errors
  }

  filterUserActivityLogs = (e) => {
    this.props.uiDispatch(
      'Search by current user',
      (state) => ({
        ...state,
        userFilter: e.target.checked ? this.props.viewer.id : null,
        visiblePages: 1,
        fetchingMore: false,
      })
    )
  }

  setSearchTypeFilter = (value) => {
    this.props.uiDispatch(
      'Search by type',
      (state) => ({
        ...state,
        searchTypeFilter: value,
        visiblePages: 1,
        fetchingMore: false,
      })
    )
  }

  handleTypesFilterChange = (filter) => {
    const values = filter && filter.length > 0 ? filter.map((v) => v.value) : ''
    this.props.uiDispatch(
      'Filter by types',
      (state) => ({
        ...state,
        typesFilter: values,
        visiblePages: 1,
        fetchingMore: false,
      })
    )
  }

  doSearch = (value) => this.setState({searchTypeFilter: value},
    () => this.setSearchTypeFilter(value))

  renderActivityLogEvents(events) {
    return (
      isEmpty(events)
        ? <div className={style.noEvents}>No events.</div>
        : events.map((event, index) => {
          // const currentUserCheck = event.user && event.user.id === this.props.viewer.id
          const prefix = eventCategories[event.category].keyPrefix
          return (<Event
            key={prefix + event.id}
            {...this.props}
            event={event}
            showFull={this.props.revealedEvents[event.id]}
            toggleShowFull={this.dispatchToggleShowFullEvent.bind(this, event.id)}
            editAppointment={this.props.showAppointmentEditor}
            completeOrDeleteAppointment={this.completeOrDeleteAppointment}
            index={index}
            sideTab={this.props.sideTab}
          />)
        })
    )
  }

  renderNoteInputs() {
    if (!canCreateNotes(this.props.viewer)) {
      return null
    }

    const {values, errors} = this.state

    return (
      <Row className={style.noteInputs}>
        <Col xs={12}>
          <TextareaAutosizeInput
            id="t-client-add-note-input"
            placeholder="Press Ctrl + Enter to submit your note."
            isInvalid={errors.content}
            value={values.content || ''}
            onChange={(e) => this.formValueChanged('content', e.target.value)}
            onKeyUp={(e) => e.ctrlKey && isKey(e, keys.enter) && this.noteAction()}
          />
        </Col>
        <Col xs={12}>
          <label className="btn btn-default btn-file" title={values.files
            ? values.files[0].name : ''}>
            <FontAwesomeIcon icon="paperclip" />&nbsp;
            {values.files
              ? shortenFilename(values.files[0].name)
              : 'Upload File'
            }
            <Form.Control
              type="file"
              isInvalid={errors.files}
              onChange={(e) => this.formValueChanged('files', e.target.files)}
            />
          </label>
          <Button
            id="t-client-add-note-button"
            className="float-right"
            variant="success"
            size="sm"
            disabled={this.state.createButtonDisabled}
            onClick={this.noteAction}
          >
            Create
          </Button>
        </Col>
      </Row>
    )
  }

  reloadActivityLogs() {
    const {isLoading} = this.state
    const {clientProvider} = this.context
    if (isLoading) return
    this.setState({isLoading: true})
    clientProvider.subProviders.activityLogs.fetch().then(() => this.setState({isLoading: false}))
  }

  render() {
    const {appointments, activityLogs, visiblePages, activityLogsCount = 0, userDepartmentFilter, typesFilter, userFilter} = this.props
    const maxVisibleEvents = visiblePages * defaultPageSizeActivityLog
    const {appointmentAction, isLoading} = this.state
    const searchTypeFilter = this.state.searchTypeFilter ?? this.props.searchTypeFilter

    if (!appointments && !activityLogs) {
      return (
        <Row>
          Loading...
        </Row>
      )
    }

    let events = [...(appointments || []), ...(activityLogs || [])].sort(orderEvents)
    const totalEventsCount = activityLogsCount + (appointments || []).length
    const hasMoreEvents = maxVisibleEvents < totalEventsCount
    events = events.splice(0, maxVisibleEvents)

    const userDepartmentsAndClient = {
      client: {
        key: 'client',
        label: 'Client',
      },
      ...userDepartments
    }

    const deleteConfirmation = this.state.completeOrDeleteAppointmentId != null && (
      <ConfirmationModal
        show
        body={`Do you really want to ${appointmentAction === 'complete' ? 'complete' : 'delete'} this appointment?`}
        confirmLabel={appointmentAction === 'complete' ? 'Complete' : 'Delete'}
        confirmStyle="danger"
        onConfirm={this.confirmCompleteOrDeleteAppointment}
        onCancel={this.clearCompleteOrDeleteAppointment}
      />
    )

    return (
      <div>
        <Row>
          <Col xs={12}>
            <Card className={classnames(['panel-events'])}>
              <Card.Body>
                {this.renderNoteInputs()}
                <Row className={style.noteInputs}>
                  <Col xs={6} className="mb-3">
                    <Form.Check
                      id="t-checkbox-current-user-activity-logs"
                      type="checkbox"
                      label="Show Only My Activites"
                      checked={userFilter}
                      onChange={this.filterUserActivityLogs}
                    />
                  </Col>
                  <Col xs={6}>
                    <Button
                      onClick={() => this.reloadActivityLogs()}
                      disabled={isLoading}
                      size="sm"
                      alt="reload"
                      variant="outline-secondary"
                    >
                      <FontAwesomeIcon icon="refresh" />
                    </Button>
                  </Col>
                  <Col xs={6}>
                    <StylishSelect.Input
                      value={userDepartmentFilter}
                      options={StylishSelect.enumToStylishOptions(userDepartmentsAndClient, 'All Departments')}
                      onChange={(e) => {
                        this.props.uiDispatch(
                          'Filter by user department',
                          (state) => ({
                            ...state,
                            userDepartmentFilter: e.value,
                            visiblePages: 1,
                            fetchingMore: false,
                          })
                        )
                      }}
                    />
                  </Col>
                  <Col xs={6}>
                    <InputGroup>
                      <FormControl
                        type="text"
                        placeholder="Search"
                        onChange={(e) => this.setState({searchTypeFilter: e.target.value})}
                        value={searchTypeFilter}
                        onKeyUp={(e) => (
                          (e.key === 'Enter' && this.doSearch(searchTypeFilter)) ||
                          (e.key === 'Escape' && this.doSearch(''))
                        )}
                      />
                      <InputGroup.Append>
                        {this.props.searchTypeFilter && <Button
                          key={1}
                          title="Clear"
                          variant="success"
                          onClick={(e) => this.doSearch('')}
                        >
                          <FontAwesomeIcon icon="times" />
                        </Button>}
                        <Button
                          key={2}
                          title="Search"
                          variant={this.props.searchTypeFilter ? 'success' : 'outline-dark'}
                          onClick={(e) => this.doSearch(searchTypeFilter)}
                        >
                          <FontAwesomeIcon icon="search" />
                        </Button>
                      </InputGroup.Append>
                    </InputGroup>
                  </Col>
                  <Col xs={12} className="mb-2">
                    <StylishSelect
                      id="t-activity-logs-types-filter"
                      placeholderText="All Types"
                      value={typesFilter}
                      options={StylishSelect.enumToStylishOptions(orderBy(activityLogTypes, 'label'))}
                      highlightIfActive
                      multi
                      clearable
                      onChange={this.handleTypesFilterChange}
                    />
                  </Col>
                </Row>
                {this.renderActivityLogEvents(events)}

                {
                  hasMoreEvents &&
                    (<a
                      id="t-show-more-events"
                      className={style.showMoreEvents}
                      onClick={() => this.showMoreEvents()}
                    >Show more</a>)
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>

        {deleteConfirmation}
      </div>
    )
  }
}

export default compose(
  predispatch((props) => {
    props.uiDispatch(
      'Setting default for sidebar activity log',
      (state) => ({
        ...state,
        visiblePages: 1,
        userDepartmentFilter: '',
        revealedEvents: {},
        typesFilter: [],
        searchTypeFilter: '',
        userFilter: null,
      })
    )
  }),
  provideProps((state, uiState) => {
    const {agents} = state
    const {visiblePages, revealedEvents, userDepartmentFilter, typesFilter, searchTypeFilter, userFilter} = uiState
    return ({
      visiblePages,
      revealedEvents,
      userDepartmentFilter,
      agents,
      typesFilter,
      searchTypeFilter,
      userFilter,
    })
  }),
)(Events)
