import React, {useState} from 'react'
import {capitalize} from 'lodash'
import {Button, Container, Table} from 'react-bootstrap'
import classnames from 'classnames'
import {canQueryPaymentOptions} from '@bdswiss/common-permissions'
import {cardTypes, paymentOptionStatuses, companies} from '@bdswiss/common-enums'
import {paymentOptionsProvider, ccPaymentOptionsProvider} from './providers'
import AddPaymentMethodModal from './AddPaymentMethodModal'
import {compose, provideProps, mountDataProviders, uiMount, checkRights} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

const calcReach = (totalEur, ccVolumeLimit) => {
  const ratio = totalEur / ccVolumeLimit
  if (ratio === Infinity || isNaN(ratio)) return
  return (totalEur / ccVolumeLimit * 100).toFixed(2) + ' %'
}

export default compose(
  checkRights(canQueryPaymentOptions),
  uiMount(() => ['ui', 'ccPaymentOptions']),
  provideProps((state, uiState) => {
    const {paymentOptions, ccPaymentOptions, viewer} = state
    const {paymentOption, showAddPaymentMethodModal} = uiState
    return ({
      paymentOptions,
      ccPaymentOptions,
      paymentOption,
      showAddPaymentMethodModal,
      viewer,
    })
  }),
  mountDataProviders({paymentOptionsProvider, ccPaymentOptionsProvider}),
)
(({uiDispatch, actions, paymentOptions, ccPaymentOptions, paymentOption, showAddPaymentMethodModal}) => {
  const [availableCountries, setAvailableCountries] = useState({})
  // const [currentVendorChangeResponse, setCurrentVendorChangeResponse] = useState('')
  return <Container>
    <h3>Credit Card Payment Options</h3>
    <Table striped bordered hover>
      <thead>
        <tr>
          <th>ID</th>
          <th>Vendor</th>
          <th className="text-right">Weekly Limit Volume Caps</th>
          <th className="text-right">Current Limit Volume Caps</th>
          <th className="text-right">Reach (%)</th>
          <th className="text-right">Primary</th>
          <th className="text-right">Total Failed Deposits</th>
          <th>CC Type</th>
          <th className="text-right">EU</th>
          <th className="text-right">MAU</th>
          <th className="text-right">SEY</th>
          <th className="text-right">iOS/Android</th>
          <th>Available Countries</th>
          <th>Status</th>
          <th>&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {ccPaymentOptions?.map(({id, vendor, totalEur, ccVolumeLimit, ccType, ccCurrent, ccPrimary, failedDeposits,
          status, androidMinBuildVersion, iOSMinBuildVersion, companies: companiesValues}) => {
          const ratio = totalEur / ccVolumeLimit
          const approachingLimitClassname = classnames({
            'text-right': true,
            'text-danger': ratio !== Infinity && !isNaN(ratio) && ratio >= 0.9,
          })
          return <tr key={String(id)}>
            <td>{id}</td>
            <td><span style={ccCurrent ? {borderBottom: '2px solid #dc3545'} : {}}>{capitalize(vendor)}</span></td>
            <td className="text-right">{ccVolumeLimit?.toLocaleString()}</td>
            <td className={approachingLimitClassname}>{totalEur?.toLocaleString()}</td>
            <td className={approachingLimitClassname}>{calcReach(totalEur, ccVolumeLimit)}</td>
            <td className="text-right">{ccPrimary && <FontAwesomeIcon icon="check" />}</td>
            <td className="text-right">{failedDeposits?.toLocaleString()}</td>
            <td>{cardTypes[ccType]?.label}</td>
            <td className="text-right">{companiesValues?.some(c => [
              companies.bdSwiss.value,
              companies.swissMarkets.value,
              companies.bitrace.value,
            ].includes(c)) && <FontAwesomeIcon icon="check" />}</td>
            <td className="text-right">{companiesValues?.some(c => [
              companies.bdSwissMauritius.value,
              companies.swissMarketsMauritius.value,
              companies.bdxAccounts.value,
              companies.makeYourDay.value,
              companies.cleanCoinAccounts.value,
            ].includes(c)) && <FontAwesomeIcon icon="check" />}</td>
            <td className="text-right">{companiesValues?.some(c => [
              companies.bdSwissVanuatu.value,
              companies.swissMarketsVanuatu.value
            ].includes(c)) && <FontAwesomeIcon icon="check" />}</td>
            <td className="text-right">{iOSMinBuildVersion}/{androidMinBuildVersion}</td>
            <td>
              {!availableCountries[id]?.countries && <Button
                size="xs"
                variant="outline-secondary"
                onClick={
                  () => actions.paymentOptions.showAvailableCountries(id)
                    .then((res) =>
                      setAvailableCountries({
                        ...availableCountries,
                        [id]: {countries: res.paymentOption.countries, error: ''},
                      })
                    )
                    .catch(() =>
                      setAvailableCountries({
                        ...availableCountries,
                        [id]: {error: 'Error fetching!'},
                      })
                    )
                }
              > Show Available Countries </Button>}
              {availableCountries[id]?.countries && availableCountries[id].countries.join(', ')}
              {availableCountries[id]?.error && <>{' '}{availableCountries[id].error}</>}
            </td>
            <td>{paymentOptionStatuses[status]?.label}</td>
            <td>
              <Button
                onClick={() => {
                  const paymentOption = paymentOptions?.find(o => o.id === id)
                  uiDispatch('Displaying Add Payment Option',
                    (state) => ({
                      ...state,
                      showAddPaymentMethodModal: true,
                      paymentOption,
                    }))}
                }
                size="xs"
              >Edit</Button>
            </td>
          </tr>}
        )}
      </tbody>
    </Table>
    <p style={{textAlign: 'center'}}>
      <u>Note:</u> If the Current Limit Caps reached more than 90% then font color
      will be marked <span style={{color: 'red'}}><u>red</u></span>
    </p>
    {/* <p>
      <Button
        onClick={() => {
          setCurrentVendorChangeResponse('')
          actions.paymentOptions.changeCurrentCcProvider()
            .then(({changeCurrentCcProvider}) => {
              if (changeCurrentCcProvider) {
                window.location.reload()
              } else {
                setCurrentVendorChangeResponse('Current vendor not changed')}
            })
            .catch(({changeCurrentCcProvider}) => setCurrentVendorChangeResponse('Error'))
        }}
        size="xs"
      >Trigger change of current cc vendor</Button> {currentVendorChangeResponse}
    </p> */}
    <AddPaymentMethodModal
      show={showAddPaymentMethodModal}
      paymentOption={paymentOption}
      onHide={() =>
        uiDispatch('Close Add Payment Option',
          (state) => ({
            ...state,
            showAddPaymentMethodModal: false,
            paymentOption,
          }))
      }
      onSave={actions.paymentOptions.upsertPaymentOption}
      signUploadUrl={actions.paymentOptions.signUploadUrl}
    />
  </Container>})
