/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PureComponent from '../PureComponent'
import {Row, Col, Form} from 'react-bootstrap'
import StylishSelect from '../components/StylishSelect'
import {languages} from '@bdswiss/common-enums'

export default class PushNotification extends PureComponent {

  componentWillMount() {
    const {language, body, title} = this.props
    this.setState({
      title: title,
      language: language,
      body: body,
    })
  }

  inputNotificationLanguage(key, value) {
    const {body, title} = this.state
    this.setState({language: languages[value].value})
    this.props.onChange(key, {language: languages[value].value, body, title})
  }

  inputNotificationTitle(key, value) {
    const {language, body} = this.state
    this.setState({title: value})
    this.props.onChange(key, {language, body, title: value})
  }

  inputNotificationBody(key, value) {
    const {language, title} = this.state
    this.setState({body: value})
    this.props.onChange(key, {language, body: value, title})
  }

  render() {
    const {notificationId} = this.props
    const {language, body, title} = this.state

    return (
      <Row key={notificationId}>
        <Col xs={3}>
          <StylishSelect.Input
            label="Notification Language"
            variant={!language ? 'error' : ''}
            value={language}
            options={StylishSelect.enumToStylishOptions(languages)}
            onChange={(e) => this.inputNotificationLanguage(notificationId, e.value)}
          />
        </Col>
        <Col xs={3}>
          <Form.Group>
            <Form.Label>Notification Title</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!title}
              value={title}
              maxLength="100"
              onChange={(e) => this.inputNotificationTitle(notificationId, e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={5}>
          <Form.Group>
            <Form.Label>Notification Message</Form.Label>
            <Form.Control
              type="text"
              placeholder="Max Chars: 100"
              isInvalid={!body}
              value={body}
              maxLength="100"
              onChange={(e) => this.inputNotificationBody(notificationId, e.target.value)}
            />
          </Form.Group>
        </Col>
        <Col xs={1} className="mt-4">
          <a href="#" onClick={this.props.removeLanguage}> Remove </a>
        </Col>
      </Row>
    )
  }
}
