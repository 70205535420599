import moment from 'moment'
import {has, update, map, omitBy, find, filter, get, uniqBy, castArray, isEmpty} from 'lodash'
import {eventCategories} from '../enums'
import {appropTestQuestions, appropTestQuestionsV2, withdrawalPaymentFields,
  dueDiligenceQuestions, suitabilityTestQuestions} from '@bdswiss/common-enums'
import {setIn, updateIn} from '../stateUtils'
import {dispatchClientNotFoundError} from '../errorsUtils'
import {userBasicData} from '../providers'
import {getFetchInterval, getPageSize, getOffset, defaultPageSizeActivityLog} from '../useful'
import {personalDetailsFields} from './personalDetailsFields'
import {canQueryAlerts} from '@bdswiss/common-permissions'

const paymentFields = omitBy(withdrawalPaymentFields,
  (f) => f.key === 'amount' || f.key === 'withdrawalReason' || f.key === 'withdrawalReasonText')

function clientPath(props, name) {
  return clientPathById(name, props.clientId)
}

function clientPathById(name, clientId) {
  return ['client', clientId, name]
}

function generalInfoPath(clientId, property) {
  return clientPathById('generalInfo', clientId).concat([property])
}

function clientQuery(query, props) {
  return `{
      client(id: ${props.clientId}) {
        ${query}
      }
    }`
}

function validateClient(res, dispatch, props, dispatchError = true) {
  if (!res.client) {
    dispatchError && dispatchClientNotFoundError(dispatch, props.clientId)
    return false
  }

  return true
}

function createChildProvider(descriptor) {
  return {
    getQuery: (props) => clientQuery(descriptor.buildQuery(props), props),

    onData: (res, dispatch, props) => {
      if (!validateClient(res, dispatch, props)) {
        return
      }
      dispatch(
        descriptor.description,
        (state, res) => descriptor.initState(res, props, state),
        [res]
      )
    },
  }
}

const depositsDescriptor = {

  description: 'Deposits loaded',

  buildQuery: (props) => {
    const {client, depositsStatusFilter, depositsVendorFilter2, hasPartialRefundsFilter, depositsCreatedFromFilter,
      depositsCreatedToFilter} = props
    const vendorFilterArgs = isEmpty(depositsVendorFilter2) ? castArray(client.generalInfo.whiteLabelConfig.availablePsps) : depositsVendorFilter2
    const statusFilter = depositsStatusFilter ? `depositStatus: [${depositsStatusFilter}], ` : ''
    const vendorFilter = `depositVendors: [${vendorFilterArgs}], `
    const hasPartialRefunds = hasPartialRefundsFilter ? `hasPartialRefunds: ${hasPartialRefundsFilter}, ` : ''
    const createdFromFilter =  depositsCreatedFromFilter ? `dpCreatedFromFilter: "${depositsCreatedFromFilter.toISOString()}"` : ''
    const createdToFilter = depositsCreatedToFilter ? `dpCreatedToFilter: "${depositsCreatedToFilter.toISOString()}"` : ''

    const query = `
      transactions(
        type: deposit
        limit: ${getPageSize()}
        offset: ${getOffset(props.depositsPage)}
        ${statusFilter}
        ${vendorFilter}
        ${hasPartialRefunds}
        ${createdFromFilter}
        ${createdToFilter}
        ) {
      id
      memberId
      paymentFields {
        ${Object.keys(paymentFields).join(' ')}
        exchangeRate
        cryptoAmount
      }
      depositPaymentFields {
        bankAccountHolderName
        iban
        swiftCode
        bankName
        transferToAccount
        dateReceived
      }
      currency
      createdAt
      status
      amount
      withdrawalType
      withdrawalRejectionReason
      withdrawalRejectionReasonText
      vendor
      transactionType
      depositDetails
      withdrawalQueuePosition
      meta
      receipt
      productReceipt
      comments
      transferAccount {
        memberId
        firstName
        lastName
        id
        remoteId
        type
      }
      account {
        __typename
        ... on BaseAccount {
          id
          remoteId
          client {
            salesAgent {
              id
              firstName
              lastName
            }
          }
        }
      }
      trackUser
    }
    transactionsCount(type: deposit, ${statusFilter}, ${vendorFilter}, ${hasPartialRefunds}, ${createdFromFilter}, ${createdToFilter})
    accounts {
      __typename
      ... on BaseAccount {
        id
        currency
        hidden
        remoteId
      }
      ... on BaseForexAccount {
        isViewOnly
        isArchived
      }
    }
    `
    return query
  },

  initState: (res, props, state) => {
    const deposits = res.client.transactions
    const depositsCount = res.client.transactionsCount
    const accounts = res.client.accounts
    for (const deposit of deposits) {
      const newResults = initActivityLog(deposit.activityLogs || [])
      const oldResults = get(get(state, clientPath(props, 'deposits')), 'activityLogs') || []
      const activityLogs = uniqBy([...newResults, ...oldResults], 'id')
      setIn(state, [...clientPath(props, 'deposits'), 'acitivityLogs'], activityLogs, true)
    }
    state = setIn(state, clientPath(props, 'depositsCount'), depositsCount, true)
    const validAccounts = filter(accounts, (a) => !a.isArchived && !a.isViewOnly && !a.hidden)
    state = setIn(state, clientPath(props, 'depositAccounts'), validAccounts, true)
    return setIn(state, clientPath(props, 'deposits'), deposits, true)
  },
}

const depositAccountsDescriptor = {

  description: 'Deposit Accounts loaded',

  buildQuery: (props) => {
    const query = `
      accounts {
        __typename
        ... on BaseAccount {
          id
          currency
          hidden
          remoteId
        }
        ... on BaseForexAccount {
          isViewOnly
          isArchived
        }
      }
    `
    return query
  },

  initState: (res, props, state) => {
    const validAccounts = filter(res.client.accounts, (a) => !a.isArchived && !a.isViewOnly && !a.hidden)
    return setIn(state, clientPath(props, 'depositAccounts'), validAccounts, true)
  },
}

const allNonTransferDepositsDescriptor = {

  description: 'Deposits loaded',

  buildQuery: (props) => {
    const {client} = props
    const availablePsps = client.generalInfo.whiteLabelConfig.availablePsps

    const query = `
      transactions(
        type: deposit
        depositStatus: [completed]
        depositVendors: [${map(filter(availablePsps, (o) => !o.isTransfer), 'key')}]
      ) {
        id
        memberId
        currency
        createdAt
        status
        amount
        vendor
        transactionType
        meta
        receipt
      }
    `
    return query
  },

  initState: (res, props, state) => {
    const deposits = res.client.transactions
    return setIn(state, clientPath(props, 'nonTransferDeposits'), deposits, true)
  },
}

function initBonusOffers(bonusOffers = []) {
  for (const bonusOffer of bonusOffers) {
    bonusOffer.createdAt = moment(bonusOffer.createdAt)
    bonusOffer.createdAt.isValid()
    bonusOffer.activityLogs = [] // initActivityLog(bonusOffer.activityLogs)
  }
  return bonusOffers
}

const bonusOffersDescriptor = {

  description: 'Bonus offers loaded',

  buildQuery: (props) => `
    bonusOffers(orderBy: id, orderDirection: descending) {
      id
      currency
      amount
      account {
        __typename
        ... on BaseAccount {
          id
          remoteId
        }
    }
      type
      status
      createdAt
  }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'bonusOffers'), initBonusOffers(res.client.bonusOffers), true),
}

function initDocuments(documents = []) {
  for (const document of documents) {
    document.createdAt = moment(document.createdAt)
    document.createdAt.isValid()
    const expiration = moment(document.expiration)
    document.expiration = expiration.isValid() ? expiration : null
    document.activityLogs = [] // initActivityLog(document.activityLogs)
  }
  return documents
}

const documentsDescriptor = {

  description: 'Documents loaded',

  buildQuery: (props) => `
    documents(orderBy: id, orderDirection: descending) {
      id,
      type,
      fileDescription,
      url,
      expiration,
      createdAt,
      autoProcessingResult,
      cardNumber,
      status,
      rejectionReason,
      assignee {
        id
        firstName
        lastName
      },
      translation {
        id,
        translationType,
        meta,
        translator {
          id
        }
        createdAt
      }
      relatesTo
    }
    paymentMethods {
      id
      details
      fundingCategory
      relatedDocumentIds
      confirmed
      meta
      pendingNotes
    }`,

  initState: (res, props, state) => {
    state = setIn(state, clientPath(props, 'paymentMethods'), res.client.paymentMethods, true)
    return setIn(state, clientPath(props, 'documents'), initDocuments(res.client.documents), true)
  }

}


function initProfileChanges(profileChanges = []) {
  for (const profileChange of profileChanges) {
    profileChange.createdAt = moment(profileChange.createdAt)
    profileChange.createdAt.isValid()
    personalDetailsFields.forEach((field) => {
      if (has(profileChange.answers, ...field.path)) {
        update(profileChange.answers, field.path, field.afterReceived)
      }
    })
  }
  return profileChanges
}

const profileChangesDescriptor = {

  description: 'Profile changes loaded',

  buildQuery: (props) => `
    profileChanges(orderBy: id, orderDirection: descending) {
      id,
      status,
      comments
      answers {
        firstName
        lastName
        phone
        birthday
        secondaryEmails {
          secondaryEmail1
          secondaryEmail2
          secondaryEmail3
        }
        address {
          line1
          city
          zip
          region
          country
        }
        nationality
        language
        gender
        mifirId
        mifirType
        globalQuestionnaire {
          transactionPurpose
          jobTitle
          approxYearlyIncome
          approxNetWorth
          approxExpectedDeposit
          sourceOfFunds
          natureOfTransactions
          originOfFunds
          politicallyExposed
          usCitizen
          taxJurisdictionCountry
          tin
          tinReason
          transactionPurposeClarify
          natureOfTransactionsClarify
          sourceOfFundsClarify
          tinClarify
          politicallyExposedReason
        }
        secondaryPhones {
          secondaryPhone1
        }
        nickname
      }
      createdAt,
    }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'profileChanges'), initProfileChanges(res.client.profileChanges), true),

}

function initAppropTests(appropTests = []) {
  for (const appropTest of appropTests) {
    appropTest.createdAt = moment(appropTest.createdAt)
    appropTest.createdAt.isValid()
  }
  return appropTests
}

function appropTestQueryFields() {
  const testQuestions = map(appropTestQuestions, 'key')
  const testQuestionsV2 = map(appropTestQuestionsV2, 'key')
  return [...testQuestions, ...testQuestionsV2]
}

const appropTestsDescriptor = {

  description: 'Approp tests loaded',
  //add in query the rest questions
  buildQuery: (props) => `
    appropTests(orderBy: id, orderDirection: descending) {
      id,
      status,
      answers {
        ${appropTestQueryFields().join('\n')}
      }
      createdAt,
      forexPoints,
      binaryPoints,
      scoreResult,
    }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'appropTests'), initAppropTests(res.client.appropTests), true),

}

function initDueDiligences(dueDiligences = []) {
  for (const dueDiligence of dueDiligences) {
    dueDiligence.createdAt = moment(dueDiligence.createdAt)
    dueDiligence.createdAt.isValid()
  }
  return dueDiligences
}

function dueDiligenceQueryFields() {
  const dueDiligence = map(dueDiligenceQuestions, 'key')
  return [...dueDiligence]
}

const dueDiligencesDescriptor = {

  description: 'Due Diligences loaded',
  buildQuery: (props) => `
    dueDiligences(orderBy: id, orderDirection: descending) {
      id,
      status,
      answers {
        ${dueDiligenceQueryFields().join('\n')}
      },
      rejectionCode,
      rejectionReason,
      createdAt,
    }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'dueDiligences'), initDueDiligences(res.client.dueDiligences), true),

}

function initSuitabilityTests(suitabilityTests = []) {
  for (const suitabilityTest of suitabilityTests) {
    suitabilityTest.createdAt = moment(suitabilityTest.createdAt)
    suitabilityTest.createdAt.isValid()
  }
  return suitabilityTests
}

function suitabilityTestQueryFields() {
  const suitabilityTest = map(suitabilityTestQuestions, 'key')
  return [...suitabilityTest]
}

const suitabilityTestsDescriptor = {

  description: 'Suitability Tests loaded',
  buildQuery: (props) => `
    suitabilityTests(orderBy: id, orderDirection: descending) {
      id,
      status,
      answers {
        ${suitabilityTestQueryFields().join('\n')}
      },
      rejectionCode,
      rejectionReason,
      createdAt,
      portfolioPoints,
      scoreResult,
    }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'suitabilityTests'), initSuitabilityTests(res.client.suitabilityTests), true),

}

const withdrawalsDescriptor = {

  description: 'Withdrawals loaded',

  buildQuery: (props) => {
    const {withdrawalsTypeFilter, withdrawalStatusFilter, withdrawalVendorsFilter} = props
    const typeFilter = `withdrawalTypes: [${castArray(withdrawalsTypeFilter)}], `
    const statusFilter = withdrawalStatusFilter ? `withdrawalStatus: [${withdrawalStatusFilter}], ` : ''
    const vendorsFilter = !isEmpty(withdrawalVendorsFilter) ? `withdrawalVendors: [${withdrawalVendorsFilter}]` : ''

    const query = `
    transactions(
      type: withdrawal
      limit: ${getPageSize()}
      offset: ${getOffset(props.withdrawalsPage)}
      ${typeFilter}
      ${statusFilter}
      ${vendorsFilter}
    ) {
      id
      memberId
      paymentFields {
        ${Object.keys(paymentFields).join(' ')}
        exchangeRate
        cryptoAmount
      }
      depositPaymentFields {
        bankAccountHolderName
        iban
        swiftCode
        bankName
        transferToAccount
        dateReceived
      }
      currency
      createdAt
      status
      amount
      withdrawalType
      withdrawalRejectionReason
      withdrawalRejectionReasonText
      vendor
      transactionType
      depositDetails
      withdrawalQueuePosition
      meta
      receipt
      productReceipt
      comments
      transferAccount {
        memberId
        firstName
        lastName
        id
        remoteId
        type
      }
      account {
        __typename
        ... on BaseAccount {
          id
          remoteId
          client {
            salesAgent {
              id
              firstName
              lastName
            }
          }
        }
      }
      trackUser
      depositVendor
    }
    transactionsCount(type: withdrawal, ${statusFilter}, ${typeFilter}, ${vendorsFilter})
    accounts {
      __typename
      ... on BaseAccount {
        id
        currency
        hidden
        remoteId
        balance
        isDemo
        totalDeposits
        totalWithdrawals
      }
    }
    `
    return query
  },

  initState: (res, props, state) => {
    const withdrawals = res.client.transactions
    const withdrawalsCount = res.client.transactionsCount
    for (const withdrawal of withdrawals) {
      const newResults = initActivityLog(withdrawal.activityLogs || [])
      const oldResults = get(get(state, clientPath(props, 'withdrawals')), 'activityLogs') || []
      const activityLogs = uniqBy([...newResults, ...oldResults], 'id')
      setIn(state, [...clientPath(props, 'withdrawals'), 'acitivityLogs'], activityLogs, true)
    }
    state = setIn(state, clientPath(props, 'withdrawalsCount'), withdrawalsCount, true)
    state = setIn(state, clientPath(props, 'withdrawalAccounts'), res.client.accounts, true)
    return setIn(state, clientPath(props, 'withdrawals'), withdrawals, true)
  },

}

const withdrawalAccountsDescriptor = {

  description: 'Withdrawal Accountss loaded',

  buildQuery: (props) => {
    const query = `
      accounts {
        __typename
        ... on BaseAccount {
          id
          currency
          hidden
          remoteId
          balance
          isDemo
          totalDeposits
          totalWithdrawals
        }
      }
    `
    return query
  },

  initState: (res, props, state) => setIn(state, clientPath(props, 'withdrawalAccounts'), res.client.accounts, true),
}

function initAccounts(accounts = []) {
  for (const account of accounts) {
    account.createdAt = moment(account.createdAt)
  }
  return accounts
}

const accountsDescriptor = {

  description: 'Accounts loaded',

  buildQuery: (props) => {
    const query = `
      accounts(includeDeleted: true) {
        __typename
        ... on BaseAccount {
          id
          balance
          currency
          hidden
          deletedAt
          remoteId
          mobile
          createdAt
          isDemo
          totalDeposits
          totalWithdrawals
          totalBonuses(
            status: [completed, authorized],
            vendors: [bonus]
          )
          minimumDeposit
          accountName
        }
        ... on BaseOptionAccount {
          spotOptionId
          activatedBalance
          turnoverClosed
          profitAndLoss
        }
        ... on BaseForexAccount {
          forexId
          accountSubtype
          isReadOnly
          isEnabled
          leverage
          margin
          freeMargin
          marginLevel
          allowedLeverages
          isArchived
          isViewOnly
          swapFree
          readOnlyNote
          readOnlyDealer
          server
          openPositionsCount: forexPositionsCount(tradingPositionStatus: open)
          pendingPositionsCount: forexPositionsCount(tradingPositionStatus: pending)
          copyTrading {
            id
            startRequestedDate
            stopRequestedDate
            startCopyingDate
            stopCopyingDate
            isActive
            account {
              __typename
              ... on ForexAccount {
                id
              }
            }
            accountCopying {
              accountId
              remoteId
            }
          }
          performanceFee
          provideCopyTrading
          providerStartDate
          withdrawalsBlocked
          competitions {
            active
            competitionDetails {
              name
            }
            acceptTerms
          }
          credit
          bonus {
            requiredLots
            lotsTraded
            bonusLost
          }
          copyTradingStrategies {
            accountName
            providerAccountId
            remoteId
          }
          isOnMailingList
          passwordProtectedStrategies
        }
        ... on BaseForexPremiumAccount {
          leverageChangedBasedOnEquity
        }
        ... on BaseCuboidAccount {
          cuboidId
          withdrawableBalance
        }
        ... on BaseTradesmarterAccount {
          tradesmarterId
          withdrawableBalance
          totalActiveBonus
          isViewOnly
        }
        ... on BaseCryptoCoinAccount {
          coins
          walletAddress
          depositWalletAddress
          bonusBalance
          bonusFXBalance
        }
        ... on BaseGoldAccount {
          gold
        }
        ... on BaseAffiliateAccount {
          serviceFields {
            affiliateStatus
            parentAffiliateId
          }
          automaticCommissions
        }
        ... on BaseIntroducingBrokerAccount {
          approved
          parentIbId
          ibId
        }
        ... on BaseExchangeAccount {
          balanceInfo {
            availableBalance
            heldBalance
          }
        }
        ... on BaseForexBdxAccount {
          lotsTraded
          lotsToTrade
        }
        ... on BaseSMPrimeWalletAccount {
          margin
          freeMargin
          equity
        }
        ... on BaseForexRawAccount {
          volumeTraded
          isInFallback
          isInOverTheLimit
        }
        ... on BaseBitnukWalletAccount {
          walletAddress
        }
      }`

    return query
  },

  initState: (res, props, state) => setIn(state, clientPath(props, 'accounts'), initAccounts(res.client.accounts), true),
}

const accountsForexDataDescriptor = {

  description: 'Accounts Forex Data loaded',

  buildQuery: (props) => {
    const query = `
      accounts(includeDeleted: true) {
        __typename
        ... on BaseAccount {
          id
        }
        ... on BaseForexAccount {
          volumeClosed
          profitAndLossFloating
          profitAndLossClosed
        }
      }`

    return query
  },

  initState: (res, props, state) => {
    const updatedAccounts = []
    const stateAccounts = get(state, clientPath(props, 'accounts').join('.'))
    for (const account of stateAccounts) {
      updatedAccounts.push({
        ...account,
        ...(find(res.client.accounts, {id: account.id}) || {}),
      })
    }
    return setIn(state, clientPath(props, 'accounts'), updatedAccounts, true)
  },
}

const positionsDescriptor = {
  description: 'positions loaded',

  buildQuery: (props) => {
    const {visibleAccount, positionsPageSizeFilter, tradingPositionStatusFilter,
      filterState: {positionsSearchFilter, positionsCloseDateFromFilter, positionsCloseDateToFilter}} = props
    const condition = [
      `tradingPositionStatus: ${tradingPositionStatusFilter}`,
      positionsSearchFilter ? `positionsSearchFilter: "${positionsSearchFilter}"` : '',
      positionsCloseDateFromFilter ? `positionsCloseDateFromFilter: "${positionsCloseDateFromFilter}"` : '',
      positionsCloseDateToFilter ? `positionsCloseDateToFilter: "${positionsCloseDateToFilter}"` : '',
    ].join('\n')
    const query = `
      accounts(
        includeDeleted: true
        ${visibleAccount ? `id: ${visibleAccount}` : ''}
      ) {
        ... on BaseAccount {
          id
        }
        ... on BaseOptionAccount {
          spotOptionPositionsCount(${condition})
          spotOptionPositions(
            ${condition}
            limit: ${positionsPageSizeFilter || getPageSize()}
            offset: ${getOffset(props.spotOptionsPositionsPage, positionsPageSizeFilter)}
          ) {
            id
            name
            position
            amount
            openTime
            openRate
            closeTime
            closeRate
            payout
            currency
            status
            format
          }
        }
        ... on BaseForexAccount {
          openPositionsCount: forexPositionsCount(tradingPositionStatus: open)
          forexPositionsCount(${condition})
          forexPositions(
            ${condition}
            limit: ${positionsPageSizeFilter || getPageSize()}
            offset: ${getOffset(props.forexPositionsPage, positionsPageSizeFilter)}
          ) {
            ticket
            symbol
            command
            volume
            openTime
            openRate
            closeTime
            closeRate
            profit
            stopLoss
            takeProfit
            commission
            swaps
            comment
            leverage
            investment
            openedThroughApi
            closedThroughApi
            openUuid
            closeUuid
          }
        }
        ... on BaseCuboidAccount {
          cuboidPositionsCount(
            ${condition}
          )
          cuboidPositions(
            ${condition}
            limit: ${positionsPageSizeFilter || getPageSize()}
            offset: ${getOffset(props.cuboidPositionsPage, positionsPageSizeFilter)}
          ) {
            upperBound
            failureValue
            investment
            payout
            failureTime
            assetId
            resultedPrice
            expiryTime
            currency
            startTime
            lowerBound
            status
          }
        }
        ... on BaseTradesmarterAccount {
          tradesmarterPositionsCount(
            ${condition}
          )
          tradesmarterPositions(
            ${condition}
            limit: ${positionsPageSizeFilter || getPageSize()}
            offset: ${getOffset(props.tradesmarterPositionsPage, positionsPageSizeFilter)}
          ) {
            tradeId
            volume
            currency
            instrument
            type
            referencePrice
            expiryPrice
            payout
            expiry
            pnl
            bonusPnl
            status
            date
            direction
            result
          }
        }
      }`

    return query
  },

  initState: (res, props, state) => updateIn(state, clientPath(props, 'accounts'), (accounts) => {
    if (!accounts) return
    const newAccounts = []
    accounts.forEach((a) => {
      const accountWithPositions = find(res.client.accounts, {id: a.id})
      newAccounts.push({...a, ...accountWithPositions})
    })
    return newAccounts
  }),
}

const brandAccountsDescriptor = {
  description: 'Brand Accounts loaded',

  buildQuery: (props) => {
    const query = `
      clientBrandAccounts(clientId: ${props.clientId}) {
        __typename
        ... on BaseAccount {
          id
          remoteId
          balance
          currency
          client {
            company
          }
          hidden
        }
        ... on BaseForexAccount {
          isViewOnly
          isArchived
        }
      }`

    return query
  },

  initState: (res, props, state) => setIn(state,
    clientPath(props, 'clientBrandAccounts'), initAccounts(res.client.clientBrandAccounts), true),
}

function initAppointments(appointments = []) {
  for (const appointment of appointments) {
    const start = moment(appointment.start)
    start.isValid() // set _isValid property now
    appointment.date = start
    appointment.appointmentCategory = appointment.category
    appointment.category = eventCategories.appointment.key
  }
  return appointments
}

const appointmentsDescriptor = {

  description: 'Appointments loaded',

  buildQuery: (props) => `
    appointments(orderBy: start, orderDirection: descending) {
      id,
      client {
        id
        firstName
        lastName
        company
      },
      user {
        id
        firstName
        lastName
        avatar
      },
      start
      description
      color
      category
      addToGoogleCalendar
      isNew
    }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'appointments'), initAppointments(res.client.appointments), true),

}


export const activityLogsQuery = (limit, offset, queryCount, userDepartmentFilter, searchTypeFilter, typesFilter = [], userFilter, isClientPage) => {
  const filters = [
    userDepartmentFilter ? `userDepartment: ${userDepartmentFilter}` : '',
    searchTypeFilter ? `searchType: "${searchTypeFilter}"` : '',
    typesFilter.length > 0 ? `types: [${typesFilter.join(',')}]` : '',
    userFilter ? `userId: ${userFilter}` : '',
    isClientPage ? 'isClientPage: true' : '',
  ].join('\n')
  return `${queryCount
    ? `activityLogsCount ${filters.trim() ? `(${filters})` : ''}`
    : ''}
  activityLogs(
    orderBy: id,
    orderDirection: descending
    ${limit ? `limit: ${limit}` : ''}
    ${offset ? `offset: ${offset}` : ''}
    ${filters}
  ) {
    __typename
    ... on BaseLogInterface {
      id
      ip
      ipCountry
      type
      origin
      createdAt
      user {
        id
        firstName
        lastName
        department
        jobTitle
        avatar
        roles {
          id
        }
      }
      objectDeposit {
        id
      }
      objectBonusOffer {
        id
      }
      objectWithdrawal {
        id
      }
      objectDocument {
        id
      }
      objectProfileChange {
        id
      }
      objectAppropTest {
        id
      }
      objectUser {
        ${userBasicData}
      }
    }
    ... on GenericLog {
      content
      topVip
      effectiveFTD
      toggleAutochartist
      toggleAutochartistEmails
      toggleAutochartistPlugin
      toggleAutochartistCoelationEmail
      toggleBDXCallCompleted
      bdxWaiverAccepted
      manualWithdrawals
      isPhoneVerified
      emailConfirmed
      bdxInvestmentChoiceFinal
      previousBDXInvestmentChoice
      automaticAffiliateCommissions
      toggleHideWDMethods
      hasDisabledWithdrawals
      canIbTransfer
      hasHotAssets
      hasTelegram
      hasTradingAcademyAccess
      fundManager
      hasPortfolioManagement
      hasRawAccount
      hasVipAccount
      ignoreIbTermination
      isDirectIB
      toggleCopyTrading
      showMasterStrategies
      hasOpenComplaint
      clientRequestCopyTrading
      copyTradingPercentage
      copyTradingMethod
      userUpdateCopyTrading
      dormantReactivation
      competitionId
      competitionName
      assignee {
        firstName
        lastName
      }
      meta
      accountName
      remoteId
      lastActiveDate
      newPlan
      retailClientId
      retailClientName
      partnerClientId
      partnerClientName
      hasSurvey
      hasPreSurvey
      performanceFee
      provideCopyTrading
      clientAccountName
      oldAccountName
      statusAndNotes
      providerRemoteId
      action
      details
      manuallyAccountType
      allowManuallyAccount
      globalQuestionnaire
      verificationType
    }
    ... on withReasonLog {
      reason
      rejectionCode
      pendingCode
    }
    ... on Note {
      content
      fileName
      fileUrl
      diff
    }
    ... on TradingStatusLog {
      tradingStatus: status
      tradingStatusReason: statusReason
      tradingStatusReasonCode: statusReasonCode
    }
    ... on ConversionStatusLog {
      conversionStatus: status
    }
    ... on ClientTypeLog {
      clientType: clientType
    }
    ... on KycStatusLog {
      kycStatus: status
      kycStatusReason: statusReason
      kycStatusReasonCode: statusReasonCode
    }
    ... on AgentLog {
      agentType
    }
    ... on ComplianceLog {
      otherUsersCount
      alertType
      accountName
      remoteId
      reason
      rejectionReasonCode
      marketingInOut
      smsInOut
      otherUsers
      args
    }
    ... on TransferFundsLog {
      allowTransferFunds
    }
    ... on UnderMonitoringLog {
      underMonitoring
      underMonitoringReason
      underMonitoringReasonCode
    }
    ... on AllowNewAccountCreationLog {
      allowNewAccounts
    }
    ... on AccountCheckLog {
      gbgCheckResult
      gbgStatus
      skrillVerificationResult
    }
    ... on SpoaLog {
      canGrantSpoa
      spoaAuthorization
      spoaClientFName
      spoaClientLName
      spoaClientID
      spoaIbFName
      spoaIbLName
      spoaIbID
      acceptedSpoaBonusTerms
      spoaForce
    }
    ... on DocumentTranslationsLog {
      translationType
      documentId
      translator{
        firstName
        lastName
      }
    }
  }`
}

export function initActivityLog(activityLogs) {
  for (const activity of activityLogs) {
    const date = moment(activity.createdAt)
    date.isValid() // set _isValid property now
    activity.date = date
    activity.category = eventCategories.activityLog.key
  }
  return activityLogs
}

const activityLogsDescriptor = {

  description: 'Activity log loaded',

  buildQuery: (props) => {
    const visiblePage = get(props, 'uiState.Events.visiblePages')
    const userDepartmentFilter = get(props, 'uiState.Events.userDepartmentFilter')
    const searchTypeFilter = get(props, 'uiState.Events.searchTypeFilter')
    const typesFilter = get(props, 'uiState.Events.typesFilter')
    const userFilter = get(props, 'uiState.Events.userFilter')
    const offset = getOffset(visiblePage, defaultPageSizeActivityLog)
    const queryCount = offset === 0
    const isClientPage = true
    return activityLogsQuery(defaultPageSizeActivityLog, offset, queryCount, userDepartmentFilter,
      searchTypeFilter, typesFilter, userFilter, isClientPage)
  },

  initState: (res, props, state) => {
    const newResults = initActivityLog(res.client.activityLogs)
    const oldResults = get(state, clientPath(props, 'activityLogs')) || []
    if (props.uiState?.Events?.fetchingMore) {
      state = setIn(state, clientPath(props, 'activityLogs'), uniqBy([...newResults, ...oldResults], 'id'), true)
    } else {
      state = setIn(state, clientPath(props, 'activityLogs'), newResults, true)
    }
    if (res.client.activityLogsCount) {
      state = setIn(state, clientPath(props, 'activityLogsCount'), res.client.activityLogsCount, true)
    }
    return state
  },
}

function initPersonalDetails(personalDetails = []) {
  personalDetailsFields.forEach((field) => {
    if (has(personalDetails, field.path)) {
      update(personalDetails, field.path, field.afterReceived)
    }
  })
  return personalDetails
}

function initGeneralInfo(client) {
  client.registration = moment(client.registration)
  client.registration.isValid() // set _isValid property now
  client.lastLogin = moment(client.lastLogin)
  client.lastLogin.isValid() // set _isValid property now
  return client
}

const basicDataDescriptor = {
  description: 'Basic data loaded',

  buildQuery: (props) => `
    id
    firstName
    lastName
    skypeId
    website
    birthday
    gender
    vps
    topVip
    agentEffectiveFTD
    lastEffectiveCall
    assignedToAgent
    tradeCompanionEnabled
    autoChartistEnabled
    autoChartistEmails
    autoChartistPlugin
    autoChartistCoelationEmail
    nationality
    language
    email
    phone
    isPhoneVerified
    emailConfirmed
    originatedFromMobile
    customDomain
    bdxAcceptedNDA
    bdxInvestmentChoice
    bdxWaiverAccepted
    bdxInvestmentChoiceFinal
    bdxCallCompleted
    tags
    {
      id
      name
    }
    deletedAt
    registration
    terminationDate
    lastLogin
    isOnline
    ftdDate
    isPool
    locale
    globalQuestionnaire {
      transactionPurpose
      jobTitle
      approxYearlyIncome
      approxNetWorth
      approxExpectedDeposit
      sourceOfFunds
      natureOfTransactions
      originOfFunds
      politicallyExposed
      usCitizen
      taxJurisdictionCountry
      tin
      tinReason
      transactionPurposeClarify
      natureOfTransactionsClarify
      sourceOfFundsClarify
      tinClarify
      politicallyExposedReason
    }
    secondaryEmails {
      secondaryEmail1
      secondaryEmail2
      secondaryEmail3
    }
    conversionStatus
    conversionStep
    convertedAt
    createdAt
    clientType
    tradingStatus
    tradingStatusReason
    tradingStatusReasonCode
    canTrade
    company
    fromCompany
    migratedAt
    ignoreAutoMigration
    whiteLabel
    whiteLabelConfig {
      disabledPsps
      availablePsps
    }
    generalClientNote
    generalWithdrawalNote
    salesClientNote
    affiliateClientNote
    canEditLeverage
    kycStatus
    kycStatusReason
    kycStatusReasonCode
    allowTransfers
    underMonitoring
    underMonitoringReason
    underMonitoringReasonCode
    allowNewAccounts
    manualWithdrawals
    hideWDMethods
    salesAgent {
      ${userBasicData}
    }
    supportAgent {
      ${userBasicData}
    }
    externalAgent {
      ${userBasicData}
    }
    address {
      line1
      city
      zip
      region
      country
    }
    legacyAppropTest {
      employmentStatus
      employer
      employerCategory
      positionTitle
      sourceOfFunds
      sourceOfFundsOther
      expectedDeposit
      expectedTurnover
      isUsReportable
      tradingPurpose
      tradingPurposeOther
      isExperienced
      experienceShares
      experienceExchange
      experienceOtc
      experienceMethod
      avgTransactionSize
      netWorth
      annualIncome
    }
    accountChecks {
      riskCategory
      riskSubCategory
      clientCategory
      politicallyExposedPerson
      worldCheck
      clientGBG
      officialName
      gbgCheckResult
      policy15Days
      inWatchlists
      inWatchlistsDate
    }
    riskAcceptance
    eulaAcceptance
    privacyAcceptance
    ageAcceptance
    riskAcceptanceDate
    eulaAcceptanceDate
    privacyAcceptanceDate
    ageAcceptanceDate
    mifirId
    mifirType
    spoaRemoteId
    canGrantSpoa
    canIbTransfer
    hasHotAssets
    hasTelegram
    hasTradingAcademyAccess
    fundManager
    hasPortfolioManagement
    hasRawAccount
    hasVipAccount
    hasJMSharedDrive
    lastUpdatedJMSharedDrive
    ignoreIbTermination
    isDirectIB
    allowCopyTrading
    showMasterStrategies
    hasOpenComplaint
    clientCopyTrading
    userCopyTrading
    secondaryPhones {
      secondaryPhone1
    }
    totalDeposits {
      currency
      amount
    }
    totalTransfers {
      currency
      amount
    }
    totalSubscriptions {
      currency
      amount
    }
    totalWithdrawals {
      currency
      amount
    }
    heldIncompleDepositsCount: depositsCount(status: [incomplete, held])
    pendingDocumentsCount: documentsCount(statuses: [notProcessed,pending])
    pendingWithdrawalsCount: withdrawalsCount(statuses: [notProcessed, pending])
    pendingApTestCount: appropTestsCount(status: pending)
    pendingDueDiligenceCount: dueDiligencesCount(status: underReview)
    pendingProfileChangesCount: profileChangesCount(status: pending)
    pendingBonusOffersCount: bonusOffersCount(status: pending)
    missingDocuments
    potentialLevel
    signableNoticeAckSignatures {
      noticeId
      signature
      dateSigned
    }
    cookiePath
    paymentCardTokens {
      id
      lastFour
      brand
      vendor
      status
    }
    swapFree
    additionalBonus
    dormantReactivation
    trackEventsCount
    trackEvents {
      id
      subCampId
      campaign
      meta
      pParams
      existing
      trackEventType
      createdAt
      attended
    }
    registrationCampaigns {
      affiliateUserName
      campaignId
      subCampaignId
      appsFlyerSiteId
      affiliate {
        id
        firstName
        lastName
        website
        hasRawAccount
        hasVipAccount
        affiliateClientNote
      }
      partnerCustomization {
        minDepositCustomizations {
          accountSubType
          amount
        }
        gdcDisabled
        accountTypeCustomizations {
          accountSubTypes
        }
        classicDefaultMT4Server
      }
      campaign{
        type
        affiliateGroup
        minimumDeposits{
          currency
          amount
        }
        ftdBonusMinimumDeposits{
          currency
          amount
        }
        redepositBonusMinimumDeposits{
          currency
          amount
        }
        ftdBonusMinimumDepositsVIP{
          currency
          amount
        }
        redepositBonusMinimumDepositsVIP{
          currency
          amount
        }
        ftdBonusMinimumDepositsRAW{
          currency
          amount
        }
        redepositBonusMinimumDepositsRAW{
          currency
          amount
        }
        minimumDepositsAccountType{
          accountType
          amount
        }
        accountTypes
        name
        noCall
        noCallRatio
        ftdBonusPercentage
        redepositBonusPercentage
        ftdBonusPercentageVIP
        redepositBonusPercentageVIP
        ftdBonusPercentageRAW
        redepositBonusPercentageRAW
        vipDepositMinimum
        promoCode
      }
    }
    linkedRetailClientId
    linkedRetailClientName
    linkedPartnerClientId
    linkedPartnerClientName
    nickname
    otherCompany
    title
    bdswissPartner
    priority
    hasPreSurvey
    hasSurvey
    optInMarketing
    optInSms
    hasDisabledWithdrawals
    referredBy {
      id
      firstName
      lastName
    }
    referralDetails {
      referrals {
        id
        referralId
        status
        amountReferrer
        registrationDate
      }
      countReferrals
    }
    regulationAcceptance
    manuallyAllowedAccounts
    competitionWinners
    blockedPhoneBirthdayReveal
    licenseAcknowledgement
    partnerCustomizations {
      id
      ibId
      affiliateId
      classicDefaultMT4Server
    }
    partnerIds
    acceptEuMigrationTerms
    multiRegulationClientId
    showPasswordProtectedStrategies
    tradingCentralEnabled
    solicitationAcceptance
    solicitationAcceptanceDate
    kartwheelReferrerAccount {
      remoteId
      client {
        email
        firstName
        id
        lastName
      }
    }
    metadata
  `,

  initState: (res, props, state) => {
    state = setIn(state, clientPath(props, 'personalDetails'), initPersonalDetails(res.client), true)
    state = setIn(state, clientPath(props, 'generalInfo'), initGeneralInfo(res.client), true)
    return state
  },

}

const callsDescriptor = {
  description: 'Calls Information Loaded',

  buildQuery: (props) => `
    calls (
      orderBy: id
      orderDirection: descending
    ) {
      phoneNumber
      createdAt
      startedAt
      endedAt
      recording
      durationTotal
      durationBillable
      notes
      disposition
      user {
        id
        firstName
        lastName
      }
      provider
    }
  `,

  initState: (res, props, state) => {
    const calls = map(get(res, 'client.calls'), (call) => {
      if (!(call.durationTotal && call.durationBillable) && call.endedAt && call.startedAt) {
        call.durationTotal = moment(call.endedAt).diff(call.startedAt, 'seconds')
      }
      call.createdAt = moment(call.createdAt)
      call.createdAt.isValid()
      return call
    })
    state = setIn(state, clientPath(props, 'calls'), calls, true)
    return state
  },
}

const registrationCampaignsDescriptor = {
  description: 'Registration Campaigns Loaded',

  buildQuery: (props) => `registrationCampaigns {
     affiliateUserName
      campaignId
      subCampaignId
      appsFlyerSiteId
      affiliate {
        id
        firstName
        lastName
      }
      campaign{
        type
        affiliateGroup
        minimumDeposits{
          currency
          amount
        }
        accountTypes
        name
        noCall
        noCallRatio
      }
      partnerCustomization {
        minDepositCustomizations {
          accountSubType
          amount
        }
      }
    }`,
  initState: (res, props, state) => {

    const registrationCampaigns = get(res, 'client.registrationCampaigns', [])
    state = setIn(state, clientPath(props, 'registrationCampaigns'), registrationCampaigns, true)
    return state
  },
}

const alertsDescriptor = {
  description: 'Alerts Information Loaded',

  buildQuery: (props) => `
    alerts(
      orderBy: id
      orderDirection: descending
    ) {
      id
      meta
      type
      status
      createdAt
      updatedAt
      ip
      amount
      comment
      resolvedBy {
        id
        firstName
        lastName
      }
      resolvedAt
      activityLog
      postponeUntil
    }
  `,

  initState: (res, props, state) => {

    const alerts = res.client.alerts && res.client.alerts.map((alert) => {
      alert.createdAt = moment(alert.createdAt)
      alert.createdAt.isValid()
      if (alert.resolvedAt) {
        alert.resolvedAt = moment(alert.resolvedAt)
        alert.resolvedAt.isValid()
      }
      return alert
    })
    state = setIn(state, clientPath(props, 'alerts'), alerts, true)
    return state
  },
}

const walkthroughDescriptor = {
  description: 'Walkthrough Information Loaded',

  buildQuery: (props) => `
    walkthroughs {
      id
      type
      createdAt
      createdBy {
        firstName
        lastName
      }
    }
  `,

  initState: (res, props, state) => {
    state = setIn(state, clientPath(props, 'walkthroughs'), initWalkthroughs(res.client.walkthroughs), true)
    return state
  },
}

function initWalkthroughs(walkthroughs) {
  if (walkthroughs.length > 0) {
    for (const walkthrough of walkthroughs) {
      walkthrough.createdAt = moment(walkthrough.createdAt)
      walkthrough.createdAt.isValid()
      walkthrough.givenBy = `${walkthrough.createdBy.firstName} ${walkthrough.createdBy.lastName}`
    }
  }
  return walkthroughs
}

const potentialDescriptor = {
  description: 'Pontential Level Information Loaded',

  buildQuery: (props) => `
  potentialLevel
  `,

  initState: (res, props, state) => {
    state = setIn(state, clientPath(props, 'potentialLevel'), initPotentials(res.client.potentialLevel), true)
    return state
  },
}

function initPotentials(potentials) {
  return potentials
}

const noticesDescriptor = {
  description: 'Pending/Acknowledged Notices Loaded',

  buildQuery: (props) => `
    acknowledgedNotices {
      id
      acknowledgementType
      notice {
        id
        createdAt
        type
        template
        values
        referenceCode
        content
        isContentHtml
      }
      createdAt
    }
    pendingNotices {
      id
      version
      type
      referenceCode
      createdAt
      template
      values
      content
      isContentHtml
    }
    `,

  initState: (res, props, state) => {
    state =
      setIn(state, clientPath(props, 'pendingNotices'), initNotices(res.client.pendingNotices), true)
    state =
      setIn(state, clientPath(props, 'acknowledgedNotices'), initNotices(res.client.acknowledgedNotices), true)
    return state
  },
}

function initNotices(notices) {
  if (notices && notices.length > 0) {
    for (const notice of notices) {
      notice.createdAt = moment(notice.createdAt)
      notice.createdAt.isValid()
    }
  }
  return notices
}

const affiliateCountriesDescriptor = {
  description: 'Affiliate Countries Loaded',

  buildQuery: (props) => `
    affiliateCountries {
      countriesPromoted
    }
  `,

  initState: (res, props, state) => {
    const affiliateCountries = res.client.affiliateCountries &&
    res.client.affiliateCountries.map((affiliateCountries) => affiliateCountries.countriesPromoted)

    state = setIn(state, clientPath(props, 'affiliateCountries'), affiliateCountries, true)
    return state
  },
}

const eIDVerificationResultsDescriptor = {
  description: 'eID Verification Results Loaded',

  buildQuery: (props) => `
    eIdVerificationResults {
      id
      identityReliability
      rawResult
      requestedBy {
        firstName
        lastName
      }
      createdAt
    }
  `,

  initState: (res, props, state) => {
    const eIdVerificationResults = res.client.eIdVerificationResults &&
    res.client.eIdVerificationResults.map((eIdVerificationResult) => {
      eIdVerificationResult = {
        ...eIdVerificationResult,
        createdAt: moment(eIdVerificationResult.createdAt),
      }
      eIdVerificationResult.createdAt.isValid()
      return eIdVerificationResult
    })

    state = setIn(state, clientPath(props, 'eIdVerificationResults'), eIdVerificationResults, true)
    return state
  },
}

const communicationsDescriptor = {
  description: 'Communications Loaded',

  buildQuery: (props) => `
    communications (
      orderBy: createdAt
      orderDirection: descending
      limit: ${getPageSize()}
      offset: ${getOffset(get(props.state.client[props.clientId], 'communicationsPage', 1))}
    ) {
      id
      channels
      type
      department
      title
      summary
      meta
      createdAt
      foreignTable
      foreignId
      client {
        id
      }
    }
    communicationsCount
  `,

  initState: (res, props, state) => {
    const commsAlreadyFetched = state.client[props.clientId].communications || []
    const communications = res.client.communications && res.client.communications.map(o => {
      const createdAt = moment(o.createdAt)
      createdAt.isValid()
      return {...o, createdAt}
    })
    state = setIn(state, clientPath(props, 'communications'), [...commsAlreadyFetched, ...communications], true)
    state = setIn(state, clientPath(props, 'communicationsCount'), get(res, 'client.communicationsCount'), true)
    state = setIn(state, clientPath(props, 'communicationsPage'), get(state.client[props.clientId], 'communicationsPage', 1) + 1, true)
    return state
  },
}

const smsDescriptor = {
  description: 'SMSs Loaded',

  buildQuery: (props) => `
    communications (
      orderBy: createdAt
      orderDirection: descending
      limit: ${getPageSize()}
      offset: ${getOffset(get(props.state.client[props.clientId], 'smsPage', 1))}
      channels: [sms]
    ) {
      id
      channels
      type
      department
      title
      meta
      createdAt
      foreignTable
      foreignId
      client {
        id
      }
    }
    communicationsCount(channels: [sms])
  `,

  initState: (res, props, state) => {
    const smsAlreadyFetched = state.client[props.clientId].sms || []
    const sms = res.client.communications && res.client.communications.map(o => {
      const createdAt = moment(o.createdAt)
      createdAt.isValid()
      return {...o, createdAt}
    })
    state = setIn(state, clientPath(props, 'sms'), [...smsAlreadyFetched, ...sms], true)
    state = setIn(state, clientPath(props, 'smsCount'), get(res, 'client.communicationsCount'), true)
    state = setIn(state, clientPath(props, 'smsPage'), get(state.client[props.clientId], 'smsPage', 1) + 1, true)
    return state
  },
}

const pushNotificationsDescriptor = {
  description: 'Push Notifications Loaded',

  buildQuery: (props) => `
    communications (
      orderBy: createdAt
      orderDirection: descending
      limit: ${getPageSize()}
      offset: ${getOffset(get(props.state.client[props.clientId], 'pushNotificationsPage', 1))}
      channels: [pushNotification]
    ) {
      id
      channels
      type
      department
      title
      summary
      meta
      createdAt
      foreignTable
      foreignId
      client {
        id
      }
    }
    communicationsCount(channels: [pushNotification])
  `,

  initState: (res, props, state) => {
    const pushNotificationsAlreadyFetched = state.client[props.clientId].pushNotifications || []
    const pushNotifications = res.client.communications && res.client.communications.map(o => {
      const createdAt = moment(o.createdAt)
      createdAt.isValid()
      return {...o, createdAt}
    })
    state = setIn(state, clientPath(props, 'pushNotifications'), [...pushNotificationsAlreadyFetched, ...pushNotifications], true)
    state = setIn(state, clientPath(props, 'pushNotificationsCount'), get(res, 'client.communicationsCount'), true)
    state = setIn(state, clientPath(props, 'pushNotificationsPage'), get(state.client[props.clientId], 'pushNotificationsPage', 1) + 1, true)
    return state
  },
}

const vpsSubscriptionsDescriptor = {
  description: 'VPS Subscriptions Loaded',

  buildQuery: (props) => `
    vpsSubscriptions
    {
      id
      status
      type
      vms
      params
      note
      suspendReason
      activatedAt
      createdAt
      deactivatedAt
      billingCycle
      paymentMethod
      planName
      serviceId
      meta
    }
  `,

  initState: (res, props, state) => {
    state = setIn(state, clientPath(props, 'vpsSubscriptions'), res.client.vpsSubscriptions, true)
    return state
  },
}

const emailsDescriptor = {
  description: 'Emails Loaded',

  buildQuery: (props) => {
    const {userDepartmentFilter, emailsPage} = props
    const conditions = [
      !isEmpty(userDepartmentFilter) ? `departments: [${userDepartmentFilter}]` : '',
    ].join('\n').trim()

    return `
      communications (
        orderBy: createdAt
        orderDirection: descending
        limit: ${getPageSize()}
        offset: ${getOffset(emailsPage)}
        channels: [email]
        ${conditions}
      ) {
        id
        channels
        type
        department
        title
        summary
        meta
        createdAt
        foreignTable
        foreignId
        client {
          id
        }
      }
      communicationsCount(channels: [email] ${conditions})
    `
  },

  initState: (res, props, state) => {
    const emailsAlreadyFetched = state.client[props.clientId].emails || []
    const emails = res.client.communications && res.client.communications.map(o => {
      const createdAt = moment(o.createdAt)
      createdAt.isValid()
      return {...o, createdAt}
    })
    const newEmails = props.fetchingMoreEmails ? [...emailsAlreadyFetched, ...emails] : emails
    state = setIn(state, clientPath(props, 'emails'), newEmails, true)
    state = setIn(state, clientPath(props, 'emailsCount'), get(res, 'client.communicationsCount'), true)
    return state
  },
}

const periodsInEachCompanyDescriptor = {
  description: 'Periods in each company loaded',

  buildQuery: (props) => `periodsInEachCompany {
      company
      dateFrom
      dateTo
    }`,

  initState: (res, props, state) => setIn(state, clientPath(props, 'periodsInEachCompany'), get(res, 'client.periodsInEachCompany'), true),

}

export const clientProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const query = clientQuery(`
        ${basicDataDescriptor.buildQuery(props)}
        ${appointmentsDescriptor.buildQuery(props)}
        ${walkthroughDescriptor.buildQuery(props)}
        ${potentialDescriptor.buildQuery(props)}
        ${documentsDescriptor.buildQuery(props)}
        ${canQueryAlerts(props.viewer) ? alertsDescriptor.buildQuery(props) : ''}
    `, props)

    return query
  },

  onData: (res, dispatch, props) => {
    if (!validateClient(res, dispatch, props)) {
      return
    }

    dispatch(
      'Client data loaded',
      (state) => {
        state = basicDataDescriptor.initState(res, props, state)
        state = appointmentsDescriptor.initState(res, props, state)
        state = walkthroughDescriptor.initState(res, props, state)
        state = potentialDescriptor.initState(res, props, state)
        state = documentsDescriptor.initState(res, props, state)
        state = alertsDescriptor.initState(res, props, state)
        return state
      },
      [res]
    )
  },

  subProviders: {
    basicData: createChildProvider(basicDataDescriptor),
    activityLogs: createChildProvider(activityLogsDescriptor),
    appointments: createChildProvider(appointmentsDescriptor),
    documents: createChildProvider(documentsDescriptor),
    appropTests: createChildProvider(appropTestsDescriptor),
    profileChanges: createChildProvider(profileChangesDescriptor),
    deposits: createChildProvider(depositsDescriptor),
    depositAccounts: createChildProvider(depositAccountsDescriptor),
    nonTransferDeposits: createChildProvider(allNonTransferDepositsDescriptor),
    bonusOffers: createChildProvider(bonusOffersDescriptor),
    withdrawals: createChildProvider(withdrawalsDescriptor),
    accounts: createChildProvider(accountsDescriptor),
    withdrawalAccounts: createChildProvider(withdrawalAccountsDescriptor),
    accountsForexData: createChildProvider(accountsForexDataDescriptor),
    walkthroughs: createChildProvider(walkthroughDescriptor),
    potentials: createChildProvider(potentialDescriptor),
    notices: createChildProvider(noticesDescriptor),
    calls: createChildProvider(callsDescriptor),
    alerts: createChildProvider(alertsDescriptor),
    clientBrandAccounts: createChildProvider(brandAccountsDescriptor),
    registrationCampaigns: createChildProvider(registrationCampaignsDescriptor),
    affiliateCountries: createChildProvider(affiliateCountriesDescriptor),
    positions: createChildProvider(positionsDescriptor),
    eIDVerificationResults: createChildProvider(eIDVerificationResultsDescriptor),
    communications: createChildProvider(communicationsDescriptor),
    sms: createChildProvider(smsDescriptor),
    pushNotifications: createChildProvider(pushNotificationsDescriptor),
    emails: createChildProvider(emailsDescriptor),
    vpsSubscriptions: createChildProvider(vpsSubscriptionsDescriptor),
    dueDiligences: createChildProvider(dueDiligencesDescriptor),
    periodsInEachCompany: createChildProvider(periodsInEachCompanyDescriptor),
    suitabilityTests: createChildProvider(suitabilityTestsDescriptor),
  },

  optimistic: {
    setSalesAgent: (dispatch, clientId, agent) => {
      const path = generalInfoPath(clientId, 'salesAgent')

      dispatch(
        'GeneralInfo optimistic setSalesAgent ',
        (state) => setIn(state, path, agent, true),
      )
    },

    setSupportAgent: (dispatch, clientId, agent) => {
      const path = generalInfoPath(clientId, 'supportAgent')

      dispatch(
        'GeneralInfo optimistic setSupportAgent ',
        (state) => setIn(state, path, agent, true),
      )
    },

    setExternalAgent: (dispatch, clientId, agent) => {
      const path = generalInfoPath(clientId, 'externalAgent')

      dispatch(
        'GeneralInfo optimistic setExternalAgent ',
        (state) => setIn(state, path, agent, true),
      )
    },

    setConversionStatus(dispatch, clientId, conversionStatusKey) {
      const path = generalInfoPath(clientId, 'conversionStatus')

      dispatch(
        'GeneralInfo optimistic setConversionStatus ',
        (state) => setIn(state, path, conversionStatusKey, true),
      )
    },

    setClientType(dispatch, clientId, clientTypeKey) {
      const path = generalInfoPath(clientId, 'clientType')

      dispatch(
        'GeneralInfo optimistic setClientType ',
        (state) => setIn(state, path, clientTypeKey, true),
      )
    },

    setTradingStatus(dispatch, clientId, tradingStatusKey) {
      const path = generalInfoPath(clientId, 'tradingStatus')

      dispatch(
        'GeneralInfo optimistic setTradingStatus ',
        (state) => setIn(state, path, tradingStatusKey, true),
      )
    },

    setPotentialLevel(dispatch, clientId, potentialLevelKey) {
      const path = generalInfoPath(clientId, 'potentialLevel')

      dispatch(
        'GeneralInfo optimistic setPotentialLevel ',
        (state) => setIn(state, path, potentialLevelKey, true),
      )
    },
  },

}

export const activityLogsProvider = {
  fetchInterval: 0,

  getQuery: (props) => {
    const query = clientQuery(`
        ${activityLogsDescriptor.buildQuery(props)}
    `, props)

    return query
  },

  onData: (res, dispatch, props) => {
    if (!validateClient(res, dispatch, props, false)) {
      return
    }

    dispatch(
      'Activity logs data loaded',
      (state) => activityLogsDescriptor.initState(res, props, state),
      [res]
    )
  }
}
