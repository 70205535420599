import {dispatchCampaignNotFoundError} from '../errorsUtils'
import {getFetchInterval, getOffset, getPageSize} from '../useful'

function validateCampaign(res, dispatch, props) {
  console.log('res:', res)

  if (!res.campaign) {
    dispatchCampaignNotFoundError(dispatch, props.campaignId)
    return false
  }

  return true
}

export const allCampaignsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => `{
      campaigns(
        orderBy: id
        orderDirection: descending
      ) {
        id
        spotCampaignId
        name
        country
        type
        start
        end
      }
    }`,

  onData: (res, dispatch) => dispatch(
    'All Campaigns loaded',
    (state, res) => ({...state, campaigns: res.campaigns}),
    [res]
  ),

}

export const campaignsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    let searchTextProperty = ''
    let campaignsCountQuery = 'campaignsCount'
    if (props.campaignsSearch) {
      searchTextProperty = `search: "${props.campaignsSearch}"`
      campaignsCountQuery = `campaignsCount(${searchTextProperty})`
    }

    const query = `{
      campaigns(
        limit: ${getPageSize()}
        offset: ${getOffset(props.campaignsPage)}
        orderBy: id
        orderDirection: descending
        ${searchTextProperty}
      ) {
        id
        spotCampaignId
        name
        country
        type
        start
        end
        promoCode
      }
      ${campaignsCountQuery}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Campaigns loaded',
    (state, res) => ({...state, campaigns: res.campaigns, campaignsCount: res.campaignsCount}),
    [res]
  ),

}

export const campaignProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (props.campaignId == null) {
      return null
    }

    const query = `{
      campaign(id: ${props.campaignId}) {
        id
        name
        country
        start
        end
        cookieTimeout
        spotCampaignId
        kycThreshold
        type
        clientId
        showWelcomePage
        redirectWebTraderFtd
        affiliateGroup
        avoidPhoneVerification
        emailVerification
        noCall
        noCallRatio
        pixels {
          id
          name
          eventName
          productName
          subcampaignKeyName
          userIdKeyName
          type
          baseUrl
          params {
            key
            type
            value
          }
        }
        minimumDeposits {
          currency
          amount
        }
        ftdBonusMinimumDeposits {
          currency
          amount
        }
        redepositBonusMinimumDeposits {
          currency
          amount
        }
        ftdBonusMinimumDepositsVIP {
          currency
          amount
        }
        redepositBonusMinimumDepositsVIP {
          currency
          amount
        }
        ftdBonusMinimumDepositsRAW {
          currency
          amount
        }
        redepositBonusMinimumDepositsRAW {
          currency
          amount
        }
        minimumDepositsAccountType {
          accountType
          amount
        }
        accountTypes
        ftdBonusPercentage
        redepositBonusPercentage
        vipDepositMinimum
        ftdBonusPercentageVIP
        redepositBonusPercentageVIP
        rawDepositMinimum
        ftdBonusPercentageRAW
        redepositBonusPercentageRAW
        globalBonusLimit
        promoCode
      }
    }`
    console.log('query:', query)
    return query
  },

  onData: (res, dispatch, props) => {
    if (!validateCampaign(res, dispatch, props)) {
      return
    }

    dispatch(
      `Campaign ${props.campaignId} loaded`,
      (state) => ({...state, campaign: res.campaign}),
      [res]
    )
  },

}
