import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Form} from 'react-bootstrap'
import {companies} from '@bdswiss/common-enums'
import {getPageSize} from '../useful'
import StylishSelect from './StylishSelect'
import PureComponent from '../PureComponent'
import {provideProps} from '../decorators'

export default provideProps()(class SelectClient extends PureComponent {
  static propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.number,
    inputLabel: PropTypes.string,
    selectLabel: PropTypes.string,
    initialClient: PropTypes.object,
  }

  static defaultProps = {
    inputLabel: 'Client: search,',
    selectLabel: 'then select.',
  }

  state = {
    clients: [],
    clientSelectPlaceholder: '',
    clientSearch: '',
    prevClientSearch: '',
    isMaxClients: false,
  }

  componentDidMount = () => {
    const initialClient = this.props.initialClient
    if (initialClient) {
      this.setState({clients: [initialClient]})
    }
  }

  getClients = () => {
    const {clientSearch, prevClientSearch} = this.state
    const limit = getPageSize()
    if (clientSearch && clientSearch !== prevClientSearch) {
      this.setState({
        clients: [],
        clientSelectPlaceholder: 'Loading...',
      })
      return this.props.dbClient.query(`{
        clients(search:"${clientSearch}", limit:${limit}) {
          id
          firstName
          lastName
          company
        }
      }`)
        .then((res) => this.setState({
          clients: res.clients,
          clientSelectPlaceholder: res.clients.length > 0 ? 'Select a client' : '',
          prevClientSearch: clientSearch,
          isMaxClients: res.clients.length === Number(limit)
        }))
    }
  }

  render() {
    const {inputLabel, selectLabel, onChange, value} = this.props
    const {clients, clientSearch, clientSelectPlaceholder, isMaxClients} = this.state
    return (
      <Row>
        <Col xs={6}>
          <Form.Group>
            <Form.Label>&nbsp;</Form.Label>
            <Form.Control
              type="text"
              label={inputLabel}
              placeholder="Client ID, Firstname, lastname, etc"
              value={clientSearch}
              onChange={(e) => this.setState({clientSearch: e.target.value})}
            />
          </Form.Group>
        </Col>
        <Col xs={6}>
          <StylishSelect.Input
            {...this.props}
            label={selectLabel}
            placeholder={clientSelectPlaceholder}
            options={clients.map(({id, firstName, lastName, company}) => ({
              value: id,
              label: `${firstName} ${lastName} ${id} ${companies[company].label}`}))}
            onChange={onChange}
            onFocus={this.getClients}
            value={value}
          />
        </Col>
        {isMaxClients && <Col xs={12}>Showing maximum {getPageSize()} clients. There may be more.</Col>}
      </Row>
    )
  }
})
