import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Form} from 'react-bootstrap'
import {appointmentCategories} from '@bdswiss/common-enums'
import {merge, chain, filter, sortBy, findIndex, map, flatten} from 'lodash'
import StylishSelect from './StylishSelect'
import PureComponent from '../PureComponent'

export default class SelectAppointmentCategory extends PureComponent {

  static propTypes = {
    highlightIfActive: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string,
    multi: PropTypes.bool,
    viewer: PropTypes.object.isRequired,
  }

  static defaultProps = {
    disabled: false,
    clearable: false,
  }

  getGroupedOptions = () => {
    const {viewer} = this.props
    const parents = chain(appointmentCategories)
      .filter('parentCategory')
      .map('parentCategory')
      .uniq()
      .map((category) => appointmentCategories[category])
      .value()

    const withoutChildren = sortBy(filter(appointmentCategories, (o) => !o.parentCategory), 'label')

    for (const parent of parents) {
      const children = sortBy(filter(appointmentCategories, (o) => o.parentCategory === parent.key), 'label')
      const parentIndex = findIndex(withoutChildren, (o) => o.key === parent.key)
      withoutChildren.splice(parentIndex + 1, 0, children)
    }

    const filteredByDepartment = filter(flatten(withoutChildren), o => !viewer.department || o.key === 'other' ? o : viewer.department === o.department)

    return map(filteredByDepartment, (o) => o.parentCategory ? {...o, label: ` -- ${o.label}`} : o)
  }

  render() {
    return (
      <StylishSelect
        id={`t-appointment-categories-filter${this.props.id ? `-${this.props.id}` : ''}`}
        onChange={this.props.onChange}
        options={this.getGroupedOptions()}
        highlightIfActive={this.props.highlightIfActive}
        value={this.props.value}
        placeholderText={this.props.placeholderText}
        disabled={this.props.disabled}
        clearable={this.props.clearable || false}
        multi={this.props.multi || false}
        isInvalid={this.props.isInvalid}
      />
    )
  }
}

class SelectAppointmentCategoryInput extends PureComponent {

  static propTypes = merge({}, SelectAppointmentCategory.propTypes, {
    isInvalid: PropTypes.bool,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
  });

  render() {
    return (
      <Form.Group {...this.props}>
        {this.props.label &&
          <label className={classNames('control-label', this.props.labelClassName)}>{this.props.label}</label>
        }
        <SelectAppointmentCategory
          {...this.props}
          className={this.props.wrapperClassName || ''}
        />
      </Form.Group>
    )
  }

}

SelectAppointmentCategory.Input = SelectAppointmentCategoryInput

