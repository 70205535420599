import React from 'react'
import moment from 'moment'
import {get, find} from 'lodash'
import PropTypes from 'prop-types'
import Loading from 'react-loading-bar'
import {Redirect, Route, Switch} from 'react-router-dom'
import {canQueryAppointments} from '@bdswiss/common-permissions'
import {socketEvents, connectionStatus, depositStatuses, withdrawalStatuses} from '@bdswiss/common-enums'
import {News} from './news'
import Error from './Error'
import Header from './Header'
import {StrategiesEquity} from './strategiesequity'
import {Stats} from './stats'
import {Alerts} from './alerts'
import {Client} from './client'
import NotFound from './NotFound'
import {Clients} from './clients'
import {FxRates} from './fxrates'
import {DormantFee} from './jobs'
import {Roles} from './Roles'
import {Permissions} from './Permissions'
import {CallLogs} from './calllogs'
import {NetDeposits} from './netdeposits'
import {Deposits} from './deposits'
import {Campaigns} from './campaigns'
import {Documents} from './documents'
import {Withdrawals} from './withdrawals'
import {agentsProvider} from './providers'
import PureComponent from './PureComponent'
import {ActivityLogs} from './activitylogs'
import {Notifications} from './notifications'
import {Communications} from './communications'
import {MarketingEmails} from './marketingemails'
import {PerformanceFees} from './performancefees'
import {PushNotifications} from './pushnotifications'
import {DocumentTranslationEdit} from './translations'
import AppointmentsSidebar from './AppointmentsSidebar'
import {TagCategories, TagCreate, TagEdit} from './tags'
import AnnotateCallModal from './client/AnnotateCallModal'
import {Seminars, SeminarCreate, SeminarEdit} from './seminars'
import AppointmentEditorModal from './client/AppointmentEditorModal'
import {CountryPaymentOptions, PaymentOptions, CcPaymentOptions} from './paymentoptions'
import {Blacklists, BlacklistCreate, BlacklistEdit} from './blacklists'
import CheckoutReconciliation from './accounting/CheckoutReconciliation'
import AppointmentNotification from './appointments/AppointmentNotification'
import {CompetitionCreate, CompetitionEdit, Competitions} from './competitions'
import {PartnersCustomization, PartnersCustomizationCreate, PartnersCustomizationEdit} from './partnerscustomization'
import {BookAllocations, BookAllocationCreate, BookAllocationEdit} from './bookallocations'
import {compose, mountDataProviders, provideProps, uiMount, predispatch} from './decorators'
import {joinRoom, getVoxUpdateMessage, getLeadAssignmentNotifMessage} from './utils/general'
import {Users, LeadDistributionSettingsEditor, AutoAssignDocumentSettingsEditor} from './users'
import {getDepositNotificationEnabled, getWithdrawalNotificationEnabled, getFromStorage, deleteFromStorage} from './useful'
import {appointmentsProvider, appointmentsCountProvider, appointmentsByDayProvider, viewerAppointmentsCountProvider} from './appointments/providers'
// import {TicketPageV, TicketReactionsV} from './tickets'
import AccountingSearchTool from './accounting/AccountingSearchTool'
import {FirstTimeDeposits} from './firsttimedeposits'
import {GhostCopiers} from './ghostcopiers'
import {RegistrationsReport} from './registrationsreport'
import {CopiersReport} from './copiersreport'
import {DepositsReport} from './depositsreport'
import {TradesReport} from './tradesreport'
import CompetitionsLeaderboard from './competitions/CompetitionsLeaderboard'
import AgentsCommissions from './agentscommissions/AgentsCommissions'
import AgentCommissions from './agentscommissions/AgentCommissions'
import WorkersActivity from './workersactivity/WorkersActivity'
import ManualExecutions from './manualexecutions/ManualExecutions'
import {OrphanedCopytradingFollowers} from './orphanedcopytradingfollowers'
import {FetcherService} from './fetcherservice'
import {IBAccounts, PAMMAccounts} from './unapproveaccounts'
import JobsReport from './workers'


const MainRoutes = () => (
  <Switch>
    <Route exact path="/news" component={News} />
    <Route exact path="/users/:userId?" component={Users} />
    <Route exact path="/lead-distribution-settings" component={LeadDistributionSettingsEditor} />
    <Route exact path="/strategies-equity" component={StrategiesEquity} />
    <Route exact path="/view-stats" component={Stats} />
    <Route exact path="/call-logs" component={CallLogs} />
    <Route exact path="/call-logs/:callLogId" component={CallLogs} />
    <Route exact path="/agents-commissions/:startDate?/:endDate?" component={AgentsCommissions} />
    <Route exact path="/agent-commissions/:agentId/:startDate/:endDate?/:showGoodOnly?" component={AgentCommissions} />
    <Route exact path="/net-transactions" component={NetDeposits} />
    <Route exact path="/clients" component={Clients} />
    <Route exact path="/clients/ibAccounts" component={IBAccounts} />
    <Route exact path="/clients/pammAccounts" component={PAMMAccounts} />
    <Route exact path="/clients/search/:type?" component={Clients} />
    <Route exact path="/clients/:clientId" component={Client} />
    <Route exact path="/clients/:clientId/:tab?/:sidebarArg?" component={Client} />
    <Route exact path="/deposits" component={Deposits} />
    <Route exact path="/withdrawals" component={Withdrawals} />
    <Route exact path="/documents" component={Documents} />
    <Route exact path="/alerts" component={Alerts} />
    <Route exact path="/activitylogs" component={ActivityLogs} />
    <Route exact path="/notifications" component={Notifications} />
    <Route exact path="/communications" component={Communications} />
    <Route exact path="/fxrates" component={FxRates} />
    <Route exact path="/blacklists" component={Blacklists} />
    <Route exact path="/blacklists/create" component={BlacklistCreate} />
    <Route exact path="/blacklists/:blacklistId" component={BlacklistEdit} />
    <Route exact path="/book-allocation" component={BookAllocations} />
    <Route exact path="/book-allocation/create" component={BookAllocationCreate} />
    <Route exact path="/book-allocation/:bookAllocationId" component={BookAllocationEdit} />
    <Route exact path="/auto-assign-document-settings" component={AutoAssignDocumentSettingsEditor} />
    <Route exact path="/performance-fees" component={PerformanceFees} />
    <Route exact path="/document-translation/:translationId" component={DocumentTranslationEdit} />
    <Route exact path="/campaigns/:campaignId?" component={Campaigns} />
    <Route exact path="/tag-categories" component={TagCategories} />
    <Route exact path="/tag-categories/create" component={TagCreate} />
    <Route exact path="/tag-categories/:tagCategory/:tagId?/(edit)?" component={TagEdit} />
    <Route exact path="/payment-options" component={PaymentOptions} />
    <Route exact path="/cc-payment-options" component={CcPaymentOptions} />
    <Route exact path="/country-payment-options" component={CountryPaymentOptions} />
    <Route exact path="/push-notifications" component={PushNotifications} />
    <Route exact path="/marketing-emails" component={MarketingEmails} />
    <Route exact path="/seminars" component={Seminars} />
    <Route exact path="/seminars/create" component={SeminarCreate} />
    <Route exact path="/seminars/:seminarId" component={SeminarEdit} />
    <Route exact path="/competitions" component={Competitions} />
    <Route exact path="/competitions/leaderboard/:competitionId" component={CompetitionsLeaderboard} />
    <Route exact path="/competitions/create" component={CompetitionCreate} />
    <Route exact path="/competitions/:competitionId" component={CompetitionEdit} />
    <Route exact path="/partners-customization" component={PartnersCustomization} />
    <Route exact path="/partners-customization/create" component={PartnersCustomizationCreate} />
    <Route exact path="/partners-customization/:partnersCustomizationId" component={PartnersCustomizationEdit} />
    <Route exact path="/jobs/dormant-fee" component={DormantFee} />
    <Route exact path="/admin/roles" component={Roles} />
    <Route exact path="/admin/permissions" component={Permissions} />
    <Route exact path="/admin/workers-activity" component={WorkersActivity} />
    <Route exact path="/admin/manual-executions" component={ManualExecutions} />
    <Route exact path="/admin/orphaned-copytrading-followers" component={OrphanedCopytradingFollowers} />
    <Route exact path="/admin/fetcher-services" component={FetcherService} />
    <Route exact path="/admin/jobs-report" component={JobsReport} />
    {/* <Route exact path="/tickets" component={TicketPageV} />
    <Route exact path="/ticket-reactions" component={TicketReactionsV} /> */}
    <Route exact path="/reports/first-time-deposits" component={FirstTimeDeposits} />
    <Route exact path="/reports/ghost-copiers" component={GhostCopiers} />
    <Route exact path="/reports/registrations-report" component={RegistrationsReport} />
    <Route exact path="/reports/copiers-report" component={CopiersReport} />
    <Route exact path="/reports/trades-report" component={TradesReport} />
    <Route exact path="/reports/deposits-report" component={DepositsReport} />
    <Route exact path="/accounting/search-tool" component={AccountingSearchTool} />
    <Route exact path="/accounting/checkout-reconciliation" component={CheckoutReconciliation} />
    <Route exact path="/error/:message?" component={Error} />
    <Redirect to="/news" from="/" />
    <Route path="*" component={NotFound} />
  </Switch>
)

// Top-level component. Instead of App, this has already everything bootstrapped, for example,
// context looks as expected and therefore the decorators work normally

const appointmentNotificationsMap = {}

class Main extends PureComponent {

  static contextTypes = {
    dispatch: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    removeNotification: PropTypes.func.isRequired,
    appointmentsProvider: PropTypes.object.isRequired,
    appointmentsCountProvider: PropTypes.object.isRequired,
    appointmentsByDayProvider: PropTypes.object.isRequired,
    viewerAppointmentsCountProvider: PropTypes.object.isRequired,
  }

  static childContextTypes = {
    toggleAppointmentsSidebar: PropTypes.func.isRequired,
    toggleAppointmentEditor: PropTypes.func.isRequired,
  };

  getChildContext() {
    return ({
      toggleAppointmentsSidebar: this.toggleAppointmentsSidebar,
      toggleAppointmentEditor: this.toggleAppointmentEditor,
    })
  }

  componentWillMount() {
    const pathname = getFromStorage('pathname')
    if (pathname)  {
      window.location = pathname
      deleteFromStorage('pathname')
    }
    this.setState({
      showAppointments: false,
      showAppointmentEditor: false,
      appointment: {},
      appointmentsFirstFetch: false,
    })
  }

  componentDidMount() {
    const {config: {backendUrl}, viewerAppointmentsCountProvider} = this.context
    this.setupSocketAndNotifyOnline(`${backendUrl}/users`)
    canQueryAppointments(this.props.viewer) && viewerAppointmentsCountProvider.fetch()
  }

  toggleAppointmentsSidebar = () => {
    const {showAppointments, appointmentsFirstFetch} = this.state
    const {appointmentsProvider, appointmentsCountProvider, appointmentsByDayProvider} = this.context
    if (!showAppointments && !appointmentsFirstFetch) {
      Promise.all([
        appointmentsProvider.fetch(),
        appointmentsCountProvider.fetch(),
        appointmentsByDayProvider.fetch(),
      ])
      this.setState({showAppointments: !showAppointments, appointmentsFirstFetch: true})
    } else {
      this.setState({showAppointments: !showAppointments})
    }
  }

  toggleAppointmentEditor = (appointment, clientAppointmentsProvider) => {
    this.setState({
      showAppointmentEditor: !this.state.showAppointmentEditor,
      appointment,
      clientAppointmentsProvider
    })
  }

  removeAppointmentNotification = ({id, client}) => {
    this.props.actions.appointments.dismissAppointment(id)
      .then(this.context.router.push(`/clients/${client.id}`))
      .catch(this.context.logError)
  }

  dismissAppointmentNotification = ({id}) => {
    this.props.actions.appointments.dismissAppointmentNotification(id)
      .catch(this.context.logError)
  }

  setupSocketAndNotifyOnline(url) {
    const {viewer} = this.props
    const {config} = this.context
    const userRoomId = `web-user-${viewer.id}`
    if (window.io) {
      window.ioSocket = window.io(url, {
        transports: ['websocket'],
        reconnection: true,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        reconnectionAttempts: 5,
      })
      if (!window.ioSocket.rooms) {
        window.ioSocket.rooms = []
      }
      window.ioSocket.on('connect', (channel) => {
        window.ioChannel = channel
        window.ioSocket.emit(socketEvents.online.value)
        const {socketConnectionStatus} = this.props
        if (!window.ioSocket.rooms.includes(userRoomId)) {
          window.ioSocket.rooms.push(userRoomId)
          if (socketConnectionStatus !== connectionStatus.reconnecting.key) {
            joinRoom(userRoomId)
          }
        }
        if (socketConnectionStatus === connectionStatus.reconnecting.key) {
          // window.dispatchEvent(new Event(events.fetchProviders.key))
          window.ioSocket.rooms.forEach((room) => joinRoom(room))
        }
        joinRoom(`user-${this.props.viewer.id}`)
        this.props.dispatch(
          'Setting Connection Status to connected',
          ((state) => ({...state, socketConnectionStatus: connectionStatus.connected.key}))
        )
      })
      window.ioSocket.on('reconnecting', () => {
        const {socketConnectionStatus} = this.props
        if (socketConnectionStatus !== connectionStatus.reconnecting.key) {
          this.props.dispatch(
            'Setting Connection Status to reconnecting',
            ((state) => ({...state, socketConnectionStatus: connectionStatus.reconnecting.key}))
          )
        }
      })
      window.ioSocket.on('checkVersion', (data) => {
        try {
          const {appVerisonInfo: {releaseVersion: runningVersion, showVersionUpdateNotification}} = config
          if (!showVersionUpdateNotification) {
            return
          }
          const {appVersion: currentAppVersion} = data
          const parsedCurrVersion = parseInt(currentAppVersion.replace('v', ''), 10),
            parsedRunningVersion = parseInt(runningVersion.replace('v', ''), 10)
          const message = (<div>
            <span dangerouslySetInnerHTML={{__html: getVoxUpdateMessage(currentAppVersion)}} /> <br />
            Click the button below to get the update now or dismiss this message and hard reload page later<br />
            Check the release notes on the news page<br />
          </div>)
          if (parsedCurrVersion > parsedRunningVersion) {
            this.context.showNotification({
              title: `Vox has been updated to ${currentAppVersion}!`,
              message,
              position: 'tl',
              autoDismiss: 0,
              action: {
                label: 'Reload',
                callback: () => {
                  window.location.reload(true)
                },
              },
              level: 'info',
            })
          }
        } catch (e) {
          //
        }
      })
      window.ioSocket.on(socketEvents.newLeadAssigned.value, (data) => {
        const agent = this.props.agents && this.props.agents.find((a) => a.id === viewer.id)
        const message = getLeadAssignmentNotifMessage({...data, agent})
        this.context.showNotification({
          title: 'New Lead Notification',
          message,
          position: 'tr',
          autoDismiss: 30,
          action: {
            label: 'Got to client\'s Profile',
            callback: () => {
              this.context.router.push(`/clients/${data.clientId}`)
            },
          },
          level: 'info',
        })
      })

      window.ioSocket.on(socketEvents.newDeposit.value, (data) => {
        const showNotification = getDepositNotificationEnabled()
        if (showNotification) {
          const {client, transaction} = data
          const agent = this.props.agents && this.props.agents.find((a) => a.id === viewer.id)
          const depositStatus = get(find(depositStatuses, {value: transaction.status}), 'label')
          let message = `${agent ? `${agent.firstName}, ` : ''}`
          message += `New deposit by your client
            ${client.first_name} ${client.last_name} with id: ${transaction.id}`
          message += ` Amount: ${transaction.amount}${transaction.currency}`
          this.context.showNotification({
            title: `New Deposit with status ${depositStatus}`,
            message,
            position: 'tr',
            autoDismiss: 30,
            action: {
              label: 'Got to client\'s deposit',
              callback: () => {
                const {protocol, host} = window.location
                window.open(`${protocol}//${host}/clients/${client.id}/deposits/${transaction.id}`, '_blank')
              },
            },
            level: 'success',
          })
        }
      })

      window.ioSocket.on(socketEvents.newWithdrawal.value, (data) => {
        const showNotification = getWithdrawalNotificationEnabled()
        if (showNotification) {
          const {client, transaction} = data
          const agent = this.props.agents && this.props.agents.find((a) => a.id === viewer.id)
          const withdrawalStatus = get(find(withdrawalStatuses, {value: transaction.status}), 'label')
          let message = `${agent ? `${agent.firstName}, ` : ''}`
          message += `New withdrawal by your client
            ${client.first_name} ${client.last_name} with id: ${transaction.id}`
          message += ` Amount: ${transaction.amount}${transaction.currency}`
          this.context.showNotification({
            title: `New Withdrawal with status ${withdrawalStatus}`,
            message,
            position: 'tr',
            autoDismiss: 30,
            action: {
              label: 'Got to client\'s withdrawal',
              callback: () => {
                const {protocol, host} = window.location
                window.open(`${protocol}//${host}/clients/${client.id}/withdrawals/${transaction.id}`, '_blank')
              },
            },
            level: 'error',
          })
        }
      })

      window.ioSocket.on(socketEvents.appointmentNotification.value, async (appointmetId) => {
        const {appointment} = await this.props.dbClient.query(`{
          appointment(id: ${appointmetId}) {
            id
            client {
              id
              firstName
              lastName
            }
            start
            category
            user {
              id
              firstName
              lastName
            }
            description
            color
          }
        }`)
        appointmentNotificationsMap[appointment.id] = this.context.showNotification({
          appointmentId: appointment.id,
          clientId: appointment.client.id,
          title: 'Reminder',
          level: 'info',
          autoDismiss: 0,
          children: <AppointmentNotification
            appointment={appointment}
            viewer={this.props.viewer}
            removeAppointmentNotification={this.removeAppointmentNotification}
          />,
          onRemove: ({appointmentId}) => this.props.actions.appointments.dismissAppointmentNotification(appointmentId)
            .catch(this.context.logError)
        }).uid
      })

      window.ioSocket.on(socketEvents.dismissAppointmentNotification.value, async (appointmentId) => {
        if (appointmentNotificationsMap[appointmentId]) {
          this.context.removeNotification(appointmentNotificationsMap[appointmentId])
          this.context.viewerAppointmentsCountProvider.fetch()
        }
      })

      window.ioSocket.on(socketEvents.userUpdated.value, () => deleteFromStorage('viewerProvider'))
    }
  }

  render() {
    const {callAnnotationRequest, viewer, location} = this.props
    const showCallAnnotationDialog = !!callAnnotationRequest && !document.hidden
    const {showAppointmentEditor, appointment, showAppointments, clientAppointmentsProvider} = this.state
    return (
      <div>
        {showCallAnnotationDialog  &&
        (<AnnotateCallModal
          show
          onCancel={() => this.props.dispatch('Finished call annotation', (state) => ({...state, callAnnotationRequest: null}))}
          onConfirm={() => this.props.dispatch('Finished call annotation', (state) => ({...state, callAnnotationRequest: null}))}
          callAnnotationRequest={callAnnotationRequest}
          dbClient={this.props.dbClient}
        />)}
        <Loading show={this.props.queryCount > 0} color="#ef3b28" />
        <Header
          showStageWarning={this.context.config.showStageWarning}
          viewer={this.props.viewer}
          pathname={get(location, 'pathname')}
        />
        <MainRoutes />
        {canQueryAppointments(viewer) && [
          <AppointmentsSidebar
            show={showAppointments}
            key="appointmentSidebar"
            hide={this.toggleAppointmentsSidebar}
          />,
          <AppointmentEditorModal
            show={showAppointmentEditor}
            appointment={appointment}
            clientAppointmentsProvider={clientAppointmentsProvider}
            key="appointmentEditorModal"
          />
        ]}
      </div>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'appointments']),
  mountDataProviders({
    agentsProvider: {
      ...agentsProvider,
      cache: true,
      cacheInterval: 60 * 60 * 1000, // 1hr
    },
  }, true),
  predispatch((props) => {
    props.uiDispatch(
      'Setting default for appointment: show appointments today',
      (state) => ({
        ...state,
        startFrom: moment().startOf('day').toISOString(),
        startTo: moment().endOf('day').toISOString(),
        isNew: true,
        tab: 'today',
        appointment: {},
        offset: 0,
      })
    )
  }),
  provideProps((state, uiState) => {
    const {queryCount, appointmentNotifications = [], socketConnectionStatus, refreshKey, callAnnotationRequest, agents, viewer} = state
    const {startFrom, startTo, isNew, tab, appointment, userId = state.viewer.id, offset} = uiState
    return {
      queryCount,
      refreshKey,
      socketConnectionStatus,
      viewer,
      agents,
      appointmentNotifications,
      callAnnotationRequest,
      startFrom,
      startTo,
      isNew,
      tab,
      appointment,
      userId,
      offset,
    }
  }),
  mountDataProviders({
    appointmentsProvider: {...appointmentsProvider, skipInitialFetch: true},
    appointmentsCountProvider: {...appointmentsCountProvider, skipInitialFetch: true},
    appointmentsByDayProvider: {...appointmentsByDayProvider, skipInitialFetch: true},
    viewerAppointmentsCountProvider: {...viewerAppointmentsCountProvider, skipInitialFetch: true},
  }, true),
)(Main)
