import React from 'react'
import PropTypes from 'prop-types'
import {dispatchPermissionDeniedError} from '../errorsUtils'
import {getHocName} from '../useful'


const checkRights = (...canQueryMethods) => (Component) => class CheckRightsComponent extends React.Component {

  static contextTypes = {
    getState: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

    static displayName = getHocName('CheckRights', Component)

    checkRights() {
      const state = this.context.getState()
      const viewer = state.viewer
      const dispatch = this.context.dispatch

      canQueryMethods.forEach((canQuery) => {
        if (!canQuery(viewer)) {
          dispatchPermissionDeniedError(dispatch)
        }
      })
    }

    componentDidMount() {
      this.checkRights()
    }

    componentWillReceiveProps(nextProps) {
      this.checkRights()
    }


    render() {
      return <Component {...this.props} />
    }

}

export default checkRights
