import {isEmpty, identity, set} from 'lodash'
import moment from 'moment'
import * as enums from '@bdswiss/common-enums'
import {isValidOptionalEmail, isValidEmail} from '../utils/validators'
import {priorityLevels} from '../common/utils'

const isNonEmpty = (value) => !isEmpty(value)
const noValidation = (value) => true

function parseMoment(x) {
  const res = moment(x)
  return res.isValid() ? res : null
}

const textType = {
  type: 'text',
  afterReceived: identity,
  beforeSent: identity,
  toForm: (x) => x || '',
  fromForm: identity,
}

const dateType = {
  type: 'date',
  afterReceived: parseMoment,
  beforeSent: (mom) => mom ? mom.format('YYYY-MM-DD') : '',
  toForm: (mom) => mom ? mom.format('YYYY-MM-DD') : '',
  fromForm: parseMoment,
}

const selectType = (options) => ({
  type: 'select',
  afterReceived: identity,
  beforeSent: identity,
  toForm: (x) => x || '',
  fromForm: identity,
  options: options,
  isEnum: true,
})

const defaults = {
  ...textType,
  isValid: noValidation,
  isEnum: false,
}

export const fieldCategories = {
  details: 1,
  accountChecks: 2,
  economicProfile: 3,
}

export const personalDetailsFields = [

  // Personal details

  {
    ...defaults,
    key: 'firstName',
    path: ['firstName'],
    label: 'First Name',
    isValid: isNonEmpty,
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'lastName',
    path: ['lastName'],
    label: 'Last Name',
    isValid: isNonEmpty,
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'skypeId',
    path: ['skypeId'],
    label: 'SkypeID',
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'website',
    path: ['website'],
    label: 'Website',
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...dateType,
    key: 'birthday',
    path: ['birthday'],
    label: 'Date of Birth',
    graphQlType: 'String',
    category: fieldCategories.details,
    loginVerification: true
  },
  {
    ...defaults,
    ...selectType(enums.genders),
    key: 'gender',
    path: ['gender'],
    label: 'Gender',
    graphQlType: 'Gender',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...selectType(enums.nationalities),
    key: 'nationality',
    path: ['nationality'],
    label: 'Nationality',
    graphQlType: 'Nationality',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'vps',
    path: ['vps'],
    label: 'VPS',
    graphQlType: 'Boolean',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...selectType(enums.languages),
    sort: true,
    key: 'language',
    path: ['language'],
    label: 'Language',
    graphQlType: 'Language',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'mifirId',
    path: ['mifirId'],
    label: 'MIFIR ID',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...selectType(enums.clientMifirTypes),
    sort: false,
    key: 'mifirType',
    path: ['mifirType'],
    label: 'MIFIR Type',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'nickname',
    path: ['nickname'],
    label: 'Nickname',
    isValid: isNonEmpty,
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'email',
    path: ['email'],
    label: 'Email',
    isValid: isValidEmail,
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'secondaryEmail1',
    path: ['secondaryEmails', 'secondaryEmail1'],
    label: 'Secondary Email 1',
    emailId: 'secondary_email_1',
    graphQlType: 'String',
    maxLength: 255,
    isValid: isValidOptionalEmail,
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'secondaryEmail2',
    path: ['secondaryEmails', 'secondaryEmail2'],
    label: 'Secondary Email 2',
    emailId: 'secondary_email_2',
    graphQlType: 'String',
    maxLength: 255,
    isValid: isValidOptionalEmail,
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'secondaryEmail3',
    path: ['secondaryEmails', 'secondaryEmail3'],
    label: 'Secondary Email 3',
    emailId: 'secondary_email_3',
    graphQlType: 'String',
    maxLength: 255,
    isValid: isValidOptionalEmail,
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'phone',
    path: ['phone'],
    label: 'Phone',
    isValid: isNonEmpty,
    graphQlType: 'String',
    category: fieldCategories.details,
    loginVerification: true
  },
  {
    ...defaults,
    key: 'secondaryPhone1',
    path: ['secondaryPhones', 'secondaryPhone1'],
    label: 'Secondary Phone 1',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'addr_line1',
    path: ['address', 'line1'],
    label: 'Street',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'addr_city',
    path: ['address', 'city'],
    label: 'City',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'addr_zip',
    path: ['address', 'zip'],
    label: 'Zip',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'addr_region',
    path: ['address', 'region'],
    label: 'Region',
    graphQlType: 'String',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...selectType(enums.countries),
    key: 'addr_country',
    path: ['address', 'country'],
    label: 'Country',
    graphQlType: 'Country',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    key: 'otherCompany',
    path: ['otherCompany'],
    label: 'Company (Other)',
    isValid: isNonEmpty,
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
    hide: ({clientType}) => enums.clientTypes.strategicPartner.key !== clientType,
  },
  {
    ...defaults,
    ...selectType(priorityLevels),
    key: 'priority',
    path: ['priority'],
    label: 'Priority',
    graphQlType: 'String',
    category: fieldCategories.details,
    hide: ({clientType}) => enums.clientTypes.strategicPartner.key !== clientType,
  },
  {
    ...defaults,
    key: 'title',
    path: ['title'],
    label: 'Title',
    isValid: isNonEmpty,
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
    hide: ({clientType}) => enums.clientTypes.strategicPartner.key !== clientType,
  },
  {
    ...defaults,
    key: 'bdswissPartner',
    path: ['bdswissPartner'],
    label: 'Partner',
    isValid: isNonEmpty,
    maxLength: 255,
    graphQlType: 'String',
    category: fieldCategories.details,
    hide: ({clientType}) => enums.clientTypes.strategicPartner.key !== clientType,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'optInMarketing',
    path: ['optInMarketing'],
    label: 'Opt-In Marketing',
    graphQlType: 'Boolean',
    category: fieldCategories.details,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'optInSms',
    path: ['optInSms'],
    label: 'Opt-In SMS',
    graphQlType: 'Boolean',
    category: fieldCategories.details,
  },

  // Account checks

  {
    ...defaults,
    ...selectType(enums.riskCategories),
    key: 'apTest_riskCategory',
    path: ['accountChecks', 'riskCategory'],
    label: 'Risk',
    graphQlType: 'RiskCategory',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.clientCategories),
    key: 'apTest_clientCategory',
    path: ['accountChecks', 'clientCategory'],
    label: 'Category',
    graphQlType: 'ClientCategory',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'apTest_accountChecks_politicallyExposedPerson',
    path: ['accountChecks', 'politicallyExposedPerson'],
    label: 'PEP',
    graphQlType: 'YesNo',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'apTest_worldCheck',
    path: ['accountChecks', 'worldCheck'],
    label: 'World Check',
    graphQlType: 'YesNo',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.clientGBGTypes),
    key: 'apTest_GBG',
    path: ['accountChecks', 'clientGBG'],
    label: 'GBG',
    graphQlType: 'ClientGBGType',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    key: 'apTest_officialName',
    path: ['accountChecks', 'officialName'],
    label: 'Official Name',
    graphQlType: 'String',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'canEditLeverage',
    path: ['canEditLeverage'],
    label: 'Can Edit Leverage',
    isValid: isNonEmpty,
    graphQlType: 'YesNo',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'policy15Days',
    path: ['accountChecks', 'policy15Days'],
    label: '15 Days Policy',
    graphQlType: 'YesNo',
    category: fieldCategories.accountChecks,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'inWatchlists',
    path: ['accountChecks', 'inWatchlists'],
    label: 'Client matched in watchlists',
    graphQlType: 'YesNo',
    category: fieldCategories.accountChecks,
  },

  //Economic Profile
  {
    ...defaults,
    ...selectType(enums.profileTransactionPurpose),
    key: 'apTest_transactionPurpose',
    path: ['globalQuestionnaire', 'transactionPurpose'],
    label: 'Transaction Purpose',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropNatureOfTransactions),
    key: 'apTest_appropNatureOfTransactions',
    path: ['globalQuestionnaire', 'natureOfTransactions'],
    label: 'Nature of Transactions',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropTestSourceOfFunds),
    key: 'apTest_sourceOfFunds',
    path: ['globalQuestionnaire', 'sourceOfFunds'],
    label: 'Source of Funds',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropTestEmployerCategory),
    key: 'apTest_jobTitle',
    path: ['globalQuestionnaire', 'jobTitle'],
    label: 'Job Title',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropTestAnnualIncome),
    key: 'apTest_approxYearlyIncome',
    path: ['globalQuestionnaire', 'approxYearlyIncome'],
    label: 'Approximate Yearly Income',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.profileNetWorth),
    key: 'apTest_approxNetWorth',
    path: ['globalQuestionnaire', 'approxNetWorth'],
    label: 'Approximate total net worth in Euro',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropTestExpectedDeposit),
    key: 'apTest_approxExpectedDeposit',
    path: ['globalQuestionnaire', 'approxExpectedDeposit'],
    label: 'Estimated amount to deposit (yearly)',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropOriginOfFunds),
    key: 'apTest_originOfFunds',
    path: ['globalQuestionnaire', 'originOfFunds'],
    label: 'Origin of funds',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'apTest_politicallyExposedPerson',
    path: ['globalQuestionnaire', 'politicallyExposed'],
    label: 'PEP',
    graphQlType: 'YesNo',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.yesNo),
    key: 'apTest_usCitizen',
    path: ['globalQuestionnaire', 'usCitizen'],
    label: 'US citizen or resident',
    graphQlType: 'YesNo',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.countries),
    key: 'apTest_countries',
    path: ['globalQuestionnaire', 'taxJurisdictionCountry'],
    label: 'Country/Jurisdiction of Tax Residence',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropTestTinReason),
    key: 'apTest_tinReason',
    path: ['globalQuestionnaire', 'tinReason'],
    label: 'TIN Reason',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    key: 'apTest_tin',
    path: ['globalQuestionnaire', 'tin'],
    label: 'TIN',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    key: 'apTest_transactionPurposeClarify',
    path: ['globalQuestionnaire', 'transactionPurposeClarify'],
    label: 'Transaction Purpose Clarify',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    key: 'apTest_sourceOfFundsClarify',
    path: ['globalQuestionnaire', 'sourceOfFundsClarify'],
    label: 'Source of funds Clarify',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    key: 'apTest_natureOfTransactionsClarify',
    path: ['globalQuestionnaire', 'natureOfTransactionsClarify'],
    label: 'Nature of Transactions Clarify',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    key: 'apTest_tinClarify',
    path: ['globalQuestionnaire', 'tinClarify'],
    label: 'TIN Clarify',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
  {
    ...defaults,
    ...selectType(enums.appropTestPoliticallyExposedReason),
    key: 'apTest_politicallyExposedReason',
    path: ['globalQuestionnaire', 'politicallyExposedReason'],
    label: 'PEP Reason',
    graphQlType: 'String',
    category: fieldCategories.economicProfile,
  },
]

export function processFieldsData(data) {
  const formatted = {}
  const graphQlTypes = {}
  const variables = {}
  personalDetailsFields.forEach((field) => {
    if (field.key in data) {
      const graphQlVariable = field.path.join('_')
      set(formatted, field.path, `$${graphQlVariable}`)
      graphQlTypes[`$${graphQlVariable}`] = field.graphQlType
      variables[graphQlVariable] = field.beforeSent(data[field.key])
    }
  })
  return {formatted, graphQlTypes, variables}
}
