import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {orderBy, debounce} from 'lodash'
import {Row, Col, Table, Card, Pagination, Form, Container} from 'react-bootstrap'
import {companies, orderDirections, activityLogTypes, activityLogOrigins} from '@bdswiss/common-enums'
import {events} from '../enums'
import style from './activityLogs.module.scss'
import DateTime from '../components/DateTime'
import PureComponent from '../PureComponent'
import {activityLogsProvider} from './providers'
import ActivityLogDetail from './ActivityLogDetail'
import {safeLinkClientDetails} from '../utils/links'
import StylishSelect from '../components/StylishSelect'
import {getFullName, readableDate, getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch} from '../decorators'
import SelectAgent from '../components/SelectAgent'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class ActivityLogs extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      ...this.state,
      selectedActivityLog: null,
      searchFilter: '',
      activityLogTypesFilter: props.activityLogTypesFilter,
      originTypesFilter: props.originTypesFilter,
      includeNullClientIdFilter: props.includeNullClientIdFilter,
      activityLogsAgentFilter: this.props.activityLogsAgentFilter,
    }
    this.dispatchUIFilterStateChanges = debounce(this.dispatchUIFilterStateChanges, 500)
  }

  componentWillMount() {
    this.setState({
      selectedActivityLog: null,
      searchFilter: '',
      activityLogTypesFilter: this.props.activityLogTypesFilter,
      originTypesFilter: this.props.originTypesFilter,
      includeNullClientIdFilter: this.props.includeNullClientIdFilter,
      activityLogsAgentFilter: this.props.activityLogsAgentFilter,
    })
  }


  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.activityLogsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    activityLogsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  handleTypesFilterChange = (filter) => {
    const values = filter && filter.length > 0 ? filter.map((v) => v.value) : ''
    this.setState({selectedActivityLog: null, activityLogTypesFilter: values},
      () => this.dispatchUIFilterStateChanges())
  }

  handleOriginsFilterChange = (filter) => {
    const values = filter && filter.length > 0 ? filter.map((v) => v.value) : ''
    this.setState({selectedActivityLog: null, originTypesFilter: values},
      () => this.dispatchUIFilterStateChanges())
  }

  handleSearchFilterChange = (e) => {
    this.setState({searchFilter: e.target.value, selectedActivityLog: null},
      () => this.dispatchUIFilterStateChanges())
  }

  handleAgentChange = (e) => {
    this.setState({activityLogsAgentFilter: e?.value, selectedActivityLog: null},
      () => this.dispatchUIFilterStateChanges())
  }

  handleDateFilterChange(name, value) {
    this.setState({selectedActivityLog: null})
    if (!value || moment.isMoment(value)) {
      this.dispatchUIFilterStateChanges.cancel()
      if (name.indexOf('To') !== -1 && moment.isMoment(value)) {
        value = value.endOf('day')
      }
      this.setState({[name]: value}, () => this.dispatchUIFilterStateChanges())
    }
  }

  handleIncludeNullClientIdFilterChange = (e) => {
    this.setState({includeNullClientIdFilter: e.target.checked},
      () => this.dispatchUIFilterStateChanges())
  }

  dispatchUIFilterStateChanges() {
    const {searchFilter, activityLogTypesFilter, originTypesFilter, createdToFilter, createdFromFilter,
      includeNullClientIdFilter, activityLogsAgentFilter}
     = this.state
    this.props.uiDispatch(
      'Filter activityLog',
      (state) => ({
        ...state,
        searchFilter,
        activityLogTypesFilter,
        originTypesFilter,
        createdToFilter,
        createdFromFilter,
        includeNullClientIdFilter,
        activityLogsAgentFilter,
        activityLogsPage: 1})
    )
  }

  showDetails(activityLog) {
    this.setState({
      selectedActivityLog: activityLog,
    })
  }

  renderActivityLog(activityLog) {
    const {id, type, user, client, createdAt} = activityLog
    const created = moment(createdAt)

    return (
      <tr key={id} onClick={(e) => this.showDetails(activityLog, e)}>
        <td>
          {id}
        </td>
        <td>
          {activityLogTypes[type].label}
        </td>
        <td>
          {safeLinkClientDetails(client, getFullName(client))}
        </td>
        <td>
          {client ? companies[client.company]?.label : null}
        </td>
        <td>
          {getFullName(client?.salesAgent)}
        </td>
        <td>
          {getFullName(user)}
        </td>
        <td>
          {readableDate(created)}
        </td>
      </tr>
    )
  }

  renderFilters() {
    const {searchFilter, activityLogTypesFilter, originTypesFilter, includeNullClientIdFilter,
      activityLogsAgentFilter} = this.state
    return ([
      <Row key="filter-row-1">
        <Col xs={6}>
          <span className={style.label}>Search</span>
          <Form.Control
            id="t-activity-logs-client-filter"
            type="text"
            placeholder="Log ID, Client or User (ID, name or email)"
            value={searchFilter}
            onFocus={() => this.dispatchUIFilterStateChanges.cancel()}
            onChange={this.handleSearchFilterChange}
          />
        </Col>
        <Col xs={3}>
          <span className={style.label}> Created From </span>
          <DateTime
            id="t-activity-logs-created-from-filter"
            timeFormat={false}
            onFocus={() => this.dispatchUIFilterStateChanges.cancel()}
            onChange={(e) => this.handleDateFilterChange('createdFromFilter', e)}
            closeOnSelect
            className={style.datetime}
          />
        </Col>
        <Col xs={3}>
          <span className={style.label}> Created To </span>
          <DateTime
            id="t-activity-logs-created-to-filter"
            onFocus={() => this.dispatchUIFilterStateChanges.cancel()}
            timeFormat={false}
            onChange={(e) => this.handleDateFilterChange('createdToFilter', e)}
            closeOnSelect
            className={style.datetime}
          />
        </Col>
      </Row>,
      <Row key="filter-row-2">
        <Col xs={5}>
          <span className={style.label}>Type</span>
          <StylishSelect
            id="t-activity-logs-types-filter"
            placeholderText="All Types"
            value={activityLogTypesFilter}
            options={StylishSelect.enumToStylishOptions(orderBy(activityLogTypes, 'label'))}
            highlightIfActive
            multi
            clearable
            onChange={this.handleTypesFilterChange}
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}>Sales Agent</span>
          <SelectAgent.Input
            id="t-activity-logs-sales-agent-filter"
            agents={this.props.agents}
            value={activityLogsAgentFilter}
            onChange={this.handleAgentChange}
            placeholderText="All Agents"
            clearable
          />
        </Col>
        <Col xs={2}>
          <span className={style.label}>Include no client</span>
          <Form.Check
            id="t-activity-logs-include-null-client-filter"
            label="Include null client"
            checked={includeNullClientIdFilter}
            onChange={this.handleIncludeNullClientIdFilterChange}
          />
        </Col>
        <Col xs={3}>
          <span className={style.label}>Origin</span>
          <StylishSelect
            id="t-activity-logs-origins-filter"
            placeholderText="All Origins"
            value={originTypesFilter}
            options={StylishSelect.enumToStylishOptions(orderBy(activityLogOrigins, 'label'))}
            highlightIfActive
            multi
            clearable
            onChange={this.handleOriginsFilterChange}
          />
        </Col>
      </Row>,
    ])
  }

  render() {
    const {activityLogs, activityLogsCount, activityLogsPage} = this.props
    const {selectedActivityLog} = this.state
    return (
      <Container>
        <div className={style.toolbar}>
          {this.renderFilters()}
        </div>
        <Row>
          <Col xs={9}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Type</th>
                      <th>Client</th>
                      <th>Company</th>
                      <th>Sales Agent</th>
                      <th>User</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityLogs.map((activityLog) => this.renderActivityLog(activityLog))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-5">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, activityLogsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(activityLogsPage, getPageCount(activityLogsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, activityLogsPage: activityLogsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, activityLogsPage: activityLogsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === activityLogsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, activityLogsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
          <Col xs={3}>
            <ActivityLogDetail selectedActivityLog={selectedActivityLog} />
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'activityLogs']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/activityLogs',
      (state) => {
        let activityLogsSort
        if (!activityLogsSort || activityLogsSort.orderBy == null
            || activityLogsSort.orderDirection == null || !(activityLogsSort.orderDirection in orderDirections)) {
          activityLogsSort = defaultSort
        }

        return ({...state, activityLogsSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {activityLogs, activityLogsCount, agents} = state
    const {activityLogsPage, activityLogsSort, activityLogTypesFilter, originTypesFilter, searchFilter,
      createdFromFilter, createdToFilter, includeNullClientIdFilter, activityLogsAgentFilter} = uiState

    return ({
      activityLogs,
      activityLogsCount,
      activityLogsPage: activityLogsPage || 1,
      activityLogsSort,
      activityLogTypesFilter,
      originTypesFilter,
      searchFilter,
      createdFromFilter,
      createdToFilter,
      includeNullClientIdFilter,
      agents,
      activityLogsAgentFilter,
    })
  }),

  mountDataProviders({activityLogsProvider}),
)(ActivityLogs)
