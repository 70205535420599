import React from 'react'
import PropTypes from 'prop-types'
import {List as Loading} from 'react-content-loader'
import {ButtonToolbar, Button, Form, Row, Col, Card} from 'react-bootstrap'
import {filter, isEmpty, flatten, uniqBy, map, keyBy} from 'lodash'
import {canCreateTransfers} from '@bdswiss/common-permissions'
import {isNumeric, isPositiveNumber, getFormattedAmount} from '../useful'
import {createAccountIconUrl} from '../utils/links'
import {accountTypes} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import {getAccountLabel} from '../utils/general'

export default provideProps()(class CreateTransfer extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  static propTypes = {
    client: PropTypes.object.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  state = {values: {}, errors: {}}

  componentDidMount() {
    const {dataFetched, dataLoading, dataError} = this.state
    if (!dataLoading && !dataError && !dataFetched) {
      this.setState({dataLoading: true}, () => {
        Promise.all([
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.clientBrandAccounts.fetch(),
        ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  componentWillReceiveProps(props) {
    const {client: {accounts, clientBrandAccounts}, onClose} = props
    const allAccounts = map(filter(uniqBy(flatten([accounts || [], clientBrandAccounts || []]), (a) => a.id),
      (a) => !accountTypes[a.__typename].isDemo && accountTypes[a.__typename].category !== 'affiliate'),
    (a) => ({...a, label: getAccountLabel(a)}))

    if (!canCreateTransfers(this.props.viewer)) {
      if (onClose) onClose()
      else this.context.router.push('/')
    }
    const allAccountsKeyed = keyBy(allAccounts, (a) => a.id)
    const state = {values: {}, errors: {}, allAccounts, allAccountsKeyed}
    this.setState(state)
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    if (this.state.feedback) {
      this.setState({values, errors: this.validate(values)})
    } else {
      this.setState({values})
    }
  }

  validate(values = this.state.values) {
    const {allAccountsKeyed} = this.state
    const errors = {}

    if (!isNumeric(values.fromAccountId)) {
      errors.fromAccountId = true
    }

    if (!isNumeric(values.toAccountId)) {
      errors.fromAccountId = true
    }

    if (!isPositiveNumber(values.amount) ||
      values.amount > allAccountsKeyed[values.fromAccountId].balance) {
      errors.amount = true
    }

    return errors
  }

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  tryCreateNewTransfer = () => {
    const errors = this.validate()

    if (!isEmpty(errors)) {
      this.setState({errors, feedback: true})
      return
    }

    this.disableCreateButton()

    const {props: {onClose}, state: {values}} = this

    this.createTransfer(values)
      .then(onClose)
  }

  createTransfer(params) {
    const {
      deposits: depositsProvider,
      accounts: accountsProvider,
      activityLogs: activityLogsProvider,
      withdrawals: withdrawalsProvider,
    }
      = this.context.clientProvider.subProviders

    return this.props.actions.client.createFundTransfer(params)
      .then((res) => {
        accountsProvider.fetch()
        depositsProvider.fetch()
        withdrawalsProvider.fetch()
        activityLogsProvider.fetch()
        this.context.showNotification({
          title: 'Transfer',
          message: 'Fund Transfer Completed',
          position: 'tr',
          level: 'info',
        })
      })
      .catch(this.context.logError)
  }

  render() {
    const {onClose} = this.props
    const {values, errors, createButtonDisabled, allAccounts, allAccountsKeyed, dataLoading,
      dataError,
    } = this.state

    if (dataLoading) {
      return <Loading speed={1} style={{padding:'20px'}} />
    }
    if (dataError) return <pre> Error Loading Accounts : {dataError.message} </pre>

    const {fromAccountId, toAccountId, amount} = values
    const eligibleFromAccounts = filter(allAccounts, (a) => a.balance > 0)
    const eligibleToAccounts = filter(allAccounts, (a) => fromAccountId !== a.id && !a.isArchived && !a.isViewOnly
      && !a.hidden)

    const fromAccountOptions = map(eligibleFromAccounts, (a) => {
      const balance = getFormattedAmount({amount: a.balance, currency: a.currency})
      return {
        value: a.id,
        label: `${accountTypes[a.__typename].label} [${a.remoteId}] [${balance}]`,
        avatar: createAccountIconUrl(accountTypes[a.__typename]),
      }
    })

    const toAccountOptions = map(eligibleToAccounts, (a) => {
      const balance = getFormattedAmount({amount: a.balance, currency: a.currency})
      return {
        value: a.id,
        label: `${accountTypes[a.__typename].label} [${a.remoteId}] [${balance}]`,
        avatar: createAccountIconUrl(accountTypes[a.__typename]),
      }
    })

    const isB2B = (fromAccountId && toAccountId &&
      accountTypes[allAccountsKeyed[fromAccountId].__typename].company !==
      accountTypes[allAccountsKeyed[toAccountId].__typename].company)

    const showCurrencyMismatchWarning = fromAccountId && toAccountId &&
      allAccountsKeyed[fromAccountId].currency !== allAccountsKeyed[toAccountId].currency ? '' : 'd-none'

    return (
      <Card id="t-client-new-transfer-sidebar" className="panel-short">
        <Card.Header><strong>New Transfer {isB2B ? 'B2B' : ''}</strong></Card.Header>
        <Card.Body>
          <Row id="t-client-new-transfer-from-account">
            <Col xs={12}>
              <StylishSelect.Input
                label="Source Account"
                value={fromAccountId || ''}
                bsStyle={errors.fromAccountId && 'error'}
                onChange={(e) => {
                  this.formValueChanged('fromAccountId', Number(e.value))
                }}
                placeholderText="Choose Source Account"
                options={fromAccountOptions}
              />
            </Col>
          </Row>
          <Row id="t-client-new-transfer-to-account">
            <Col xs={12}>
              <StylishSelect.Input
                label="Destination Account"
                value={toAccountId || ''}
                bsStyle={errors.toAccountId && 'error'}
                onChange={(e) => {
                  this.formValueChanged('toAccountId', Number(e.value))
                }}
                placeholderText="Choose Destination Account"
                options={toAccountOptions}
              />
            </Col>
          </Row>
          <Row id="t-client-new-transfer-amount">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  title="Amount"
                  value={amount || ''}
                  isInvalid={errors.amount}
                  onChange={(event) => this.formValueChanged('amount', event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <small className={showCurrencyMismatchWarning}>
                * Source and destination currency do not match,
                please note that currency conversion would be applied.
              </small>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-new-tranfer-cancel-button"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >
                  Cancel
                </Button>
                <Button
                  id="t-client-new-transfer-create-button"
                  variant="success"
                  size="sm"
                  disabled={createButtonDisabled}
                  onClick={this.tryCreateNewTransfer}
                >
                  Create
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
})
