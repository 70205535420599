/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonToolbar, Row, Col} from 'react-bootstrap'
import {get} from 'lodash'
import {provideProps, compose} from '../decorators'
import PureComponent from '../PureComponent'
import style from './client.module.scss'
import {getFullName} from '../utils/general'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {createClientDetailsUrl} from '../utils/links'

class RestrictedPersonalDetails extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    client: PropTypes.object,
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const {client: {generalInfo: {isPhoneVerified}}} = this.props
    this.setState({
      isPhoneVerified,
    })
  }

  revealPhone(clientId) {
    this.props.actions.client.revealPhone(clientId).then((phones) => {
      const currentPhone = get(this.props, 'client.personalDetails.phone', '')
      const phone = get(phones, 'phone', currentPhone)
      if (phone) {
        this.props.dispatch('Dispatching Reveal Phone',
          (state) => ({
            ...state,
            client: {
              [clientId]: {
                ...state.client[clientId],
                generalInfo: {
                  ...state.client[clientId].generalInfo,
                  phone,
                },
                personalDetails: {
                  ...state.client[clientId].personalDetails,
                  phone,
                },
              },
            },
          }))
        this.setState({
          phoneRevealed: true,
        })
      }
    })
  }

  trackCopyEvent(clientId, field) {
    this.props.actions.client.trackCopyEvent(clientId, field).then(() => true)
  }

  render() {
    const {personalDetails} = this.props.client
    const {phone, isPhoneVerified, id} = personalDetails
    const hideRevealPhoneButton = get(personalDetails, 'blockedPhoneBirthdayReveal')

    return (
      <div>
        <Row className={style.personalDetails} style={{clear: 'both'}} />
        <Row className={style.personalDetails} style={{clear: 'both'}}>
          <Col xs={6}>
            <dl className="dl-horizontal long-title">
              <dt>Client ID</dt>
              <dd>{id}</dd>

              <dt>Full Name</dt>
              <dd id="t-client-personal-details-full-name">{getFullName(personalDetails)}</dd>

              <dt>Phone</dt>
              <dd>
                <a href="#" onCopy={() => {this.trackCopyEvent(id, 'phone')}}>{phone}</a>
                {isPhoneVerified && <FontAwesomeIcon icon="check-circle" className={style.blueIcon} />}
                {!hideRevealPhoneButton && <ButtonToolbar className="float-right">
                  {!this.state.phoneRevealed &&
                    <Button
                      id={'t-client-personal-details-phone-reveal'}
                      className={style.actionButton}
                      variant="outline=secondary"
                      size="sm"
                      onClick={() => {this.revealPhone(id)}}
                    >Reveal</Button>
                  }
                </ButtonToolbar>}
              </dd>
            </dl>
          </Col>
          <Col xs={6}>
            <Row className={style.affiliateButtonWrap}>
              <Button
                id={'t-client-restricted-personal-details-user'}
                key={'attachToAffiliate'}
                variant="success"
                size="sm"
                onClick={() => {this.context.router.push(createClientDetailsUrl(this.props.clientId, 'attachToAffiliate'))
                }}
              ><FontAwesomeIcon icon="handshake-o" /> Attach to Affiliate</Button>
            </Row>
          </Col>
        </Row>
        <Row className={style.personalDetails} style={{clear: 'both'}} />
      </div>
    )
  }
}

export default compose(
  provideProps((state) => ({config: state.config}))
)(RestrictedPersonalDetails)
