import React from 'react'
import PropTypes from 'prop-types'
//import {textarea} from 'react-bootstrap'
import {isEmpty, debounce, get, first} from 'lodash'
import {events} from '../enums'
import classNames from 'classnames'
import style from './clients.module.scss'
import {canEditAffiliateUserName} from '@bdswiss/common-permissions'
import {isEmptyStr} from '../common/utils.js'
import PureComponent from '../PureComponent'

export default class AffiliateUserNameField extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.saveAffiliateUserName = debounce(this.saveAffiliateUserName, 1500)
  }
  static contextTypes = {
    router: PropTypes.object.isRequired,
    clientsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  showNotification(message, level) {
    this.context.showNotification({
      message,
      level,
      autoDismiss: 5,
    })
  }
  componentWillMount() {
    this.setInitialState()
  }

  setInitialState() {
    const {registrationCampaigns} = this.props.client
    const affUserName = get(first(registrationCampaigns), 'affiliateUserName') || ''
    const editable = isEmpty(affUserName) || isEmptyStr(affUserName) || affUserName.startsWith('IB-')
    this.setState({
      affUserName: affUserName,
      initialAffUserName: affUserName,
      editable,
      editMode: false,
      hasError: false,
    })
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.clientsProvider.fetch()
  }

  changeAffiliateUserName(e) {
    this.setState({affUserName: e.target.value, hasError: false})
  }

  saveAffiliateUserName() {
    const {initialAffUserName, affUserName} = this.state
    if (initialAffUserName !== affUserName) {
      this.props.actions.client.upsertAffiliateUserName(this.props.client.id, this.state.affUserName)
        .then(() => {
          this.showNotification('Affiliate information has been updated successfully', 'success')
          this.setState({initialAffUserName: affUserName})
          this.context.clientsProvider.fetch()
        })
        .catch((e) => {
          this.showNotification(e.message, 'error')
          this.setState({hasError: true, editMode: true})
        })
    }
  }

  render() {
    const {editable, editMode, hasError} = this.state
    const title = editable ? 'Double click to edit' : 'Not editable'
    if (editMode && editable && canEditAffiliateUserName(this.props.viewer)) {
      return (
        <input
          ref={(r) => r && !hasError && r.focus()}
          id={'t-list-clients-selection-affiliate-' + this.props.client.id}
          className={classNames(style.editableField, hasError ? style.editableFieldError : '')}
          maxLength="20"
          value={this.state.affUserName}
          onChange={(e) => this.changeAffiliateUserName(e)}
          onBlur={() => {
            this.setState({editMode: false})
            this.saveAffiliateUserName()
          }}
          onFocus={() => this.saveAffiliateUserName.cancel()}
          onKeyUp={(e) => {
            if (e.keyCode === 27) {
              this.saveAffiliateUserName.cancel()
              this.setInitialState()
            } else if (e.keyCode === 13) {
              this.setState({editMode: false})
              this.saveAffiliateUserName()
            }
          }}
        />
      )
    } else {
      return (<span title={title} className={style.nonEditableField} onDoubleClick={() => {
        this.setState({editMode: true})
      }}>{this.state.affUserName}&nbsp;</span>)
    }
  }
}
