import PureComponent from '../PureComponent'
import {Button, Card, Col, Container, Row, Table} from 'react-bootstrap'
import {merge} from 'lodash'
import moment from 'moment'
import {orderDirections} from '@bdswiss/common-enums'
import {checkRights, compose, mountDataProviders, provideProps, uiMount, predispatch} from '../decorators'
import {depositsReportProvider} from './providers'
import style from '../firsttimedeposits/reportFtd.module.scss'
import PropTypes from 'prop-types'
import {canQueryDepositsReport} from '@bdswiss/common-permissions'
import DateTime from '../components/DateTime'
import StylishSelect from '../components/StylishSelect'
// import React from '@types/react'

const ALL_COMPANIES = 'All'

const generateDownloadLink = (backendUrl, company, reportDateStart, reportDateEnd) => {
  let link = `${backendUrl}/api/v1/reports/report?type=deposits`

  if (company && company !== ALL_COMPANIES) {
    link += `&company=${company}`
  }
  if (reportDateStart) {
    link += `&reportDateStart=${reportDateStart}`
  }
  if (reportDateEnd) {
    link += `&reportDateEnd=${reportDateEnd}`
  }

  return link
}

class DepositsReport extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  static contextTypes = {
    depositsReportProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const stateDateFilter = {
      date: moment().format('YYYY-MM'),
    }
    const company = this.props.company ?? ALL_COMPANIES

    const stateReportSort = this.props.reportSort || {
      orderBy: 'date', orderDirection: orderDirections.descending.key,
    }

    this.setState({
      sorting: {}, dateFilter: stateDateFilter, reportSort: stateReportSort, company
    })
  }

  handleDateFilterChanged(name, momentValue, value) {
    const dateFilter = {
      [name]: value
    }
    this.setState({dateFilter})

    if (!momentValue || moment.isMoment(momentValue)) {
      this.doDateFilterSearch(dateFilter)
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch({date}) {
    const dateFilter = {
      reportDateStart: moment(date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
      reportDateEnd: moment(date, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
    }
    this.props.uiDispatch('Filter report by dates', (state) => ({...state, dateFilter}), [dateFilter])
  }

  filterReportsByCompany(company) {
    this.setState({company})
    this.props.uiDispatch('Filter report by company', (state) => ({...state, company}), [company])
  }

  fetchProvider() {
    this.context.depositsReportProvider.fetch()
  }

  render() {
    const {depositsReport} = this.props
    const {backendUrl} = this.context.config
    const companiesOptions = [ALL_COMPANIES, ...this.props.viewer.companies].map(company => ({
      label: company,
      value: company
    }))

    return (<Container>
      <h3>Deposits Report</h3>
      <Row className={style.filter}>
        <Col xs={6}>
          <Row>
            <Col xs={4}>
              <div>
                <DateTime
                  timeFormat={false}
                  dateFormat="YYYY-MM"
                  onChange={(e) => this.handleDateFilterChanged('date', e, moment.isMoment(e) ? e.format('YYYY-MM') : '')}
                  value={this.state.dateFilter.date}
                  onFocus={() => this.doDateFilterSearch.cancel()}
                  closeOnSelect
                  className={style.datetime}
                />
              </div>
            </Col>
            <Col xs={4}>
              <StylishSelect.Input
                value={this.state.company}
                options={companiesOptions}
                onChange={(e) => this.filterReportsByCompany(e.value)}
              />
            </Col>
          </Row>
        </Col>
        <Col xs={1} style={{float: 'right'}}>
          <div style={{textAlign: 'center'}}>
            <Button
              href={generateDownloadLink(backendUrl, this.state.company, this.props.dateFilter.reportDateStart, this.props.dateFilter.reportDateEnd)}
              id={'t-deposits-export'}
              variant="success"
              style={{width: '100%', marginBottom: 20}}
              disabled={!depositsReport.length}
            >
              Download
            </Button>
          </div>
        </Col>

      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body style={{overflowX: 'scroll'}}>
              <Table bordered hover className={style.table}>
                <thead>
                  <tr style={{textAlign: 'center'}}>
                    <th style={{minWidth: 40}}>
                      <span>Date</span>
                    </th>
                    <th style={{minWidth: 100}}>
                      <span>Deposits</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {depositsReport.map((report) => {
                    const {date, deposits} = report
                    return (<tr
                      key={date}
                      id={date}
                      style={{minWidth: 100, textAlign: 'center'}}
                    >
                      <td>{moment(date).format('YYYY-MM-DD')}</td>
                      <td>{deposits}</td>
                    </tr>)
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>)
  }
}

export default compose(checkRights(canQueryDepositsReport),

  uiMount((state) => ['ui', 'depositsReport']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/deposits report',
    (state) => {
      const dateFilter = {
        reportDateStart: moment().startOf('month').format('YYYY-MM-DD'),
        reportDateEnd: moment().endOf('month').format('YYYY-MM-DD')
      }
      const company = ALL_COMPANIES
      let reportSort = state && state.reportSort
      if (!reportSort) {
        reportSort = {orderBy: 'date', orderDirection: orderDirections.descending.key}
      }

      return merge({dateFilter, reportSort, company}, state)
    })),

  provideProps((state, uiState) => {
    const {depositsReport} = state
    const {dateFilter, company, reportSort} = uiState
    return {depositsReport, dateFilter, company, reportSort}
  }),

  mountDataProviders({depositsReportProvider}),)(DepositsReport)
