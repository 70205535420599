import React from 'react'
import PropTypes from 'prop-types'
import {bankWireBanks} from '@bdswiss/common-enums'
import {Modal, Button, ButtonToolbar, Form, Row, Col} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import StylishSelect from '../components/StylishSelect'

export default class DepositCompleteModal extends PureComponent {

  componentWillMount() {
    this.setState({
      amount: this.props.amount
    })
  }

  static propTypes = {
    show: PropTypes.bool,
    header: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    amount: PropTypes.number.isRequired,
  };

  changeAmount(e) {
    this.setState({amount: Number(e.target.value)})
  }

  changeBank(e) {
    this.setState({bank: e.value})
  }

  render() {
    const {show, header, onConfirm, onCancel} = this.props

    return (
      <Modal
        id="t-dp-complete-modal"
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>{header}</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={5}>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  id="t-dp-complete-modal-amount"
                  type="number"
                  placeholder="Amount"
                  value={this.state.amount}
                  onChange={this.changeAmount.bind(this)}
                />
              </Form.Group>
            </Col>
            <Col xs={7}>
              <StylishSelect.Input
                label="Bank"
                value={this.state.bank}
                placeholderText="Select Bank"
                options={StylishSelect.enumToStylishOptions(bankWireBanks)}
                onChange={this.changeBank.bind(this)}
              />
            </Col>
            <Col xs={5}>
              <DateTime
                label="Date Received"
                value={this.state.dateReceived || ''}
                onChange={(value) => this.setState({dateReceived: value})}
                placeholder="Enter Date Received"
                timeFormat={false}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              id="t-dp-complete-modal-cancel-btn"
              onClick={onCancel}
              variant="outline-secondary"
              size="sm"
              className="mr-1"
            >
              Cancel
            </Button>
            <Button
              id="t-dp-complete-modal-accept-btn"
              variant="success"
              size="sm"
              onClick={() => onConfirm(this.state.amount, this.state.bank, this.state.dateReceived)}
            >
              Confirm
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
