
export default function create(dbClient) {
  return {
    updateCallLog({id, monitoring, needsComplianceCheck = false}) {
      const query = `
        mutation (
          $id: Int!
          $needsComplianceCheck: Boolean!
          $monitoring: String!
        ) {
          updateCallLog(
            id: $id
            needsComplianceCheck: $needsComplianceCheck
            monitoring: $monitoring
          ) {
            id
          }
       }
      `

      return dbClient.query(query, {id, needsComplianceCheck, monitoring: JSON.stringify(monitoring)})
    },

    updateLastEffectiveCall({clientId, lastEffectiveCall}) {
      const query = `
        mutation(
          $clientId: Int!
          $lastEffectiveCall: DateTime!
        ) {
          updateLastEffectiveCall(
            clientId: $clientId
            lastEffectiveCall: $lastEffectiveCall
          )
        }
      `

      return dbClient.query(query, {clientId, lastEffectiveCall})
    }
  }
}
