import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {Card, Button, Form, Row, Col, ButtonToolbar} from 'react-bootstrap'
import {compose, provideProps, uiMount} from '../../decorators'
import AffiliateSelect from './AffiliateSelect'
import LastCampaignSelect from './LastCampaignSelect'
import CampaignSelect from './CampaignSelect'
import clientStyle from '../client.module.scss'

class AttachToAffiliate extends PureComponent {
  static contextTypes = {
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    clientProvider: PropTypes.func.isRequired,
  };

  handleChange(key, value) {
    this.props.uiDispatch(
      'Getting Affiliate Id',
      (state) => ({...state, [key]: value}),
      [value]
    )
  }

  handleSubmit() {
    const {affiliateId, campaignId, trackingCode, saveAction, client: {id: clientId}} = this.props

    saveAction(clientId, Number(affiliateId), campaignId, trackingCode)
      .then((res) => {
        this.props.onHide()
        this.context.showNotification({
          title: 'Attaching client to affiliate',
          message: 'The client was attached to affiliate',
          position: 'tr',
          level: 'success',
        })
        this.context.clientProvider.fetch()
      })
      .catch((error) => {
        error.isShowActualError = true
        this.context.logError(error)
        this.context.showNotification({
          title: 'Attaching client to affiliate',
          message: error.message,
          position: 'tr',
          level: 'error',
        })
      })
  }


  render() {
    const {campaignId, trackingCode, showCampaignSelect, affiliateId} = this.props
    const disabled = !affiliateId || !campaignId || !trackingCode

    return (
      <>
        <Card>
          {this.props.header && <Card.Header><strong>{this.props.header}</strong></Card.Header>}
          <Card.Body>
            <Row>
              <Col xs={12}>

                <Form.Group>
                  <Form.Label>Affiliate account</Form.Label>
                  <AffiliateSelect />
                </Form.Group>

                <Form.Group>
                  <Form.Label>Campaign</Form.Label>
                  {showCampaignSelect
                    ? <CampaignSelect />
                    : <LastCampaignSelect affiliateId={affiliateId}/>
                  }
                </Form.Group>

                <Form.Group>
                  <Form.Label>Tracking code</Form.Label>
                  <Form.Control
                    id="t-tracking-code"
                    type="text"
                    value={trackingCode}
                    placeholder="Please enter"
                    onChange={(event) => this.handleChange('trackingCode', event.currentTarget.value)}
                    className={clientStyle.styledInput}
                    autoFocus
                    disabled={!showCampaignSelect}
                  />
                </Form.Group>
              </Col>
              <Col>
                <ButtonToolbar className="float-right">
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="mr-2"
                    onClick={() => this.props.onHide()}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={(e) => this.handleSubmit(e)}
                    disabled={disabled}
                  >
                    Submit
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'affiliateAssign']),

  provideProps((state, uiState) => {
    const {lastCampaign, lastCampaignFound} = state
    const {selectedAffiliate, trackingCode, campaignId} = uiState
    const showCampaignSelect = lastCampaignFound === false

    return {
      showCampaignSelect,
      affiliateId: selectedAffiliate,
      lastCampaign,
      campaignId: lastCampaign ? lastCampaign.campaignId : campaignId,
      trackingCode: lastCampaign ? lastCampaign.subCampaignId : trackingCode
    }
  }),
)(AttachToAffiliate)
