import React from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonToolbar, Form, Row, Col, Card, Image, Dropdown, DropdownButton} from 'react-bootstrap'
import {isEmpty, cloneDeep, filter, some, includes, map, get} from 'lodash'
import classnames from 'classnames'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {setInLocal, getIn} from '../stateUtils'
import {
  canWriteUsers, canChangeUserRoles, canWriteClientSalesAgent,
  canAssignUserAsSalesAgent, userPermissionChecker, permissions,
  canUpdateSalesAgentCampaign, canUpdateAffiliateManagerCampaign, canWriteAffiliateAssociatedCampaign
} from '@bdswiss/common-permissions'
import {languagesWithVariation, companies, salesAgentTypes, departmentRoles, userDepartments, telephonyProviders, countries} from '@bdswiss/common-enums'
import {isValidEmail} from '../utils/validators'
import {deleteFromStorage, sanitizeId} from '../useful'
import {compose, uiMount, translate, predispatch, provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import ReassignAgentClientsModal from '../components/ReassignAgentClientsModal'
import DateTime from '../components/DateTime'
import style from './users.module.scss'
import ChangePasswordModal from './ChangePasswordModal'
import moment from 'moment'

function getTranslatedUiState(props) {
  let userId = get(props.match, 'params.userId')
  const {users} = props
  let user
  if (userId === 'new') {
    user = {
      isActive: true,
      allowedIps: '.*' // Added initial value for user allowedIps on creation of new user
    }
  } else {
    userId = Number(userId)
    user = users.find((user) => user.id === userId)
    user = user ? cloneDeep(user) : {}
  }
  user.roles = user.roles || []
  user.location = user.location || {}
  user.manager = user.manager || {}
  return user
}

function initializeUiState(props) {
  props.uiDispatch(
    'Mounting User Editor',
    (state) => {
      const _state = {...state}
      // don't override properties if they already exists in state
      _state.validation = {}
      _state.createButtonDisabled = false
      _state.showReassignClients = false
      const userId = get(props.match, 'params.userId')
      if (userId === 'new') {
        _state.readOnly = false
      } else {
        _state.readOnly = (userId !== undefined)
      }

      return _state
    }
  )
}

export const EditableLabel = ({isEditMode, onClickHandler, id, labelText, className}) => (
  <label id={id} className={classnames(['label', className, style.removableLabel])}>
    {labelText}
    {isEditMode &&
    <FontAwesomeIcon
      id={`${id}-remove`}
      icon="remove" title="Remove" className={style.removeIcon} onClick={onClickHandler}
    />
    }
  </label>
)

const canApplySetThisRole = (viewer, role) => {
  const restriction = role.restrictedTo || role.restricted_to
  return !restriction || viewer.permissions.includes(restriction)
}

class UserEditor extends PureComponent {

  state = {
    newCampaignValue: '',
    newSubCampaignValue: '',
    newAffiliateCampaignValue: '',
    newAffiliateUserCampaignValue: '',
    associatedCampaigns: [],
    associatedSubCampaigns: [],
    affiliateManagerCampaigns: [],
    affiliateAssociatedCampaigns: [],
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    usersProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  /**
   * Roles that cannot be chosen with others
   * @type {[string]}
   */
  unshareableRoles = [
    'affiliate'
  ]

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  enableCreateButton() {
    this.setState({createButtonDisabled: false})
  }

  static getDerivedStateFromProps({user}, prevState) {
    if (user && user.id !== get(prevState, 'currentUser.id')) {
      return {
        ...prevState,
        currentUser: user,
        associatedCampaigns: (user && cloneDeep(user.associatedCampaigns)) || [],
        associatedSubCampaigns: (user && cloneDeep(user.associatedSubCampaigns)) || [],
        affiliateManagerCampaigns: (user && cloneDeep(user.affiliateManagerCampaigns)) || [],
        affiliateAssociatedCampaigns: (user && cloneDeep(user.affiliateAssociatedCampaigns)) || [],
        newCampaignValue: '',
        newSubCampaignValue: '',
        newAffiliateCampaignValue: '',
        newAffiliateUserCampaignValue: '',
      }
    }
    return prevState
  }

  getAvailableManagerOptions() {
    const {users, user} = this.props
    const availableManagers =
      filter(users, (u) => u.id !== get(user, 'id') &&
        (some(u.roles, (r) => includes(map(r.permissions, 'id'), permissions.manage_user.id))))
    return [
      {value: null, label: 'No Manager'},
      ...map(availableManagers, (m) => ({label: `${m.firstName} ${m.lastName}`, value: m.id})),
    ]
  }

  formValid() {
    const validation = {}
    for (const propName of ['firstName', 'lastName', 'department', 'location', 'email', 'companies']) {
      if (isEmpty(this.props.user[propName]) ||
        (propName === 'email' && !isValidEmail(this.props.user[propName]))) {
        validation[propName] = 'error'
      }
    }
    setInLocal(this, 'set validation result', ['validation'], validation)
    return isEmpty(validation)
  }

  canSelectRole(role) {
    const userUnshareableRoles = this.props.user.roles.filter(element => this.unshareableRoles.includes(element.id))
    const isUserRole = this.props.user.roles.find((userRole) => userRole.id === role)
    const isUnshareableRole = this.unshareableRoles.indexOf(role) >= 0

    return (userUnshareableRoles.length !== 0 && !isUserRole) ||
        (isUnshareableRole && userUnshareableRoles.length === 0 && this.props.user.roles.length > 0)
  }

  inputProps(name, path, validity, disable) {
    return {
      id: 't-edit-user-' + sanitizeId(name),
      type: 'text',
      size: 'sm',
      isInvalid: validity === 'error',
      variant: validity || null,
      value: getIn(this.props, path, {last: ''}) || '',
      onChange: (e) => {
        setInLocal(this, 'form change', path, e.target.value)
      },
      placeholder: name,
      disabled: disable || this.props.readOnly,
    }
  }

  resetChanges() {
    this.props.uiDispatch(
      'Reseting changes in UserEdit form',
      (state) => ({
        user: getTranslatedUiState(this.props),
        validation: {},
        readOnly: true,
        createButtonDisabled: false,
      })
    )
  }

  resetNewUserChanges() {
    initializeUiState(this.props)
  }

  cancel = () => {
    if (get(this.props.match, 'params.userId') === 'new') {
      this.resetNewUserChanges()
      this.props.history.push('/users')
    } else {
      this.resetChanges()
      this.props.history.push('/users')
    }
  }

  trySave = () => {
    if (!this.formValid()) {
      return
    }

    const {context, props: {actions, user, fetchUser}} = this
    const saveData = cloneDeep(this.props.dirty)
    const userId = get(this.props.match, 'params.userId')

    //nothing changed
    if (isEmpty(saveData)) {
      setInLocal(this, 'readonly -> true', ['readOnly'], true)
      return
    }

    if (userId !== 'new') {
      saveData.id = user.id
    } else {
      saveData.isActive = true
    }

    if (saveData.allowedIps) {
      saveData.allowedIps = saveData.allowedIps.split(',')
    }

    this.disableCreateButton()
    let userToRedirect = null
    actions.user.upsertUser(saveData)
      .then((res) => {
        const {upsertUser: {id}} = res
        if (userId === 'new') {
          userToRedirect = id
          this.props.actions.user.resetUserPassword(id)
        }
        deleteFromStorage('agentsProvider')
        this.enableCreateButton()
        setInLocal(this, 'readonly -> true', ['readOnly'], true)
        return context.usersProvider.fetch()
      }).then(() => {
        if (userToRedirect != null) {
          this.resetNewUserChanges()
          this.context.router.push(`/users/${userToRedirect}`)
        } else {
          this.resetChanges()
          fetchUser(saveData.id)
        }
        if (this.props.notify) {
          this.props.notify('User successfully updated', 'success')
        }
      }).catch((e) => {
        //TODO we need proper general mechanism for error handling
        if (this.props.notify) {
          this.props.notify(e.message, 'error')
        }
        this.enableCreateButton()
        if (e.message && e.message.indexOf('admin_users_email_key') >= 0) {
          setInLocal(this, 'set email validation error', ['validation', 'email'], 'error')
        } else {
          this.context.logError(e)
          this.resetChanges()
        }
      })
  }

  hideReassignClientsModal() {
    this.props.uiDispatch('Close reassign clients modal', (state) => ({...state, showReassignClients: false}))
  }

  changeOwnPassword() {
    this.props.uiDispatch('Open change password modal', (state) => ({...state, showChangePasswordModal: true}))
  }

  onPasswordChanged() {
    window.location.reload()
  }

  hideChangePasswordModal() {
    this.props.uiDispatch('Close change password modal', (state) => ({...state, showChangePasswordModal: false}))
  }

  removeCampaignAssociation(campaign, isSubCampaign = false, isAffCampaign = false) {
    const {associatedSubCampaigns, associatedCampaigns, affiliateManagerCampaigns, affiliateAssociatedCampaigns} = this.state
    if (isSubCampaign) {
      if (associatedSubCampaigns && associatedSubCampaigns.includes(campaign)) {
        associatedSubCampaigns.splice(associatedSubCampaigns.indexOf(campaign), 1)
      }
      this.setState({associatedSubCampaigns: [...associatedSubCampaigns]})
      setInLocal(this, 'form change', ['user', 'associatedSubCampaigns'], associatedSubCampaigns)
    } else if (isAffCampaign) {
      if (affiliateManagerCampaigns && affiliateManagerCampaigns.includes(campaign)) {
        affiliateManagerCampaigns.splice(affiliateManagerCampaigns.indexOf(campaign), 1)
      }
      this.setState({affiliateManagerCampaigns: [...affiliateManagerCampaigns]})
      setInLocal(this, 'form change', ['user', 'affiliateManagerCampaigns'], affiliateManagerCampaigns)

      if (affiliateAssociatedCampaigns && affiliateAssociatedCampaigns.includes(campaign)) {
        affiliateAssociatedCampaigns.splice(affiliateAssociatedCampaigns.indexOf(campaign), 1)
      }
      this.setState({affiliateAssociatedCampaigns: [...affiliateAssociatedCampaigns]})
      setInLocal(this, 'form change', ['user', 'affiliateAssociatedCampaigns'], affiliateAssociatedCampaigns)

    } else {
      if (associatedCampaigns && associatedCampaigns.includes(campaign)) {
        associatedCampaigns.splice(associatedCampaigns.indexOf(campaign), 1)
      }
      this.setState({associatedCampaigns: [...associatedCampaigns]})
      setInLocal(this, 'form change', ['user', 'associatedCampaigns'], associatedCampaigns)
    }
  }

  addCampaignAssociation(isSubCampaign = false) {
    const {newCampaignValue, associatedCampaigns, newSubCampaignValue, associatedSubCampaigns,
      newAffiliateCampaignValue, affiliateManagerCampaigns, affiliateAssociatedCampaigns, newAffiliateUserCampaignValue} = this.state

    if (isSubCampaign) {
      if (newSubCampaignValue) {
        if (associatedSubCampaigns && !associatedSubCampaigns.includes(newSubCampaignValue)) {
          associatedSubCampaigns.push(newSubCampaignValue)
        }
      }
      this.setState({associatedSubCampaigns: [...associatedSubCampaigns], newSubCampaignValue: ''})
      setInLocal(this, 'form change', ['user', 'associatedSubCampaigns'], associatedSubCampaigns)
    } else if (newCampaignValue) {
      if (associatedCampaigns && !associatedCampaigns.includes(newCampaignValue)) {
        associatedCampaigns.push(newCampaignValue)
      }
      this.setState({associatedCampaigns: [...associatedCampaigns], newCampaignValue: ''})
      setInLocal(this, 'form change', ['user', 'associatedCampaigns'], associatedCampaigns)
    } else if (newAffiliateUserCampaignValue) {
      if (affiliateAssociatedCampaigns && !affiliateAssociatedCampaigns.includes(newAffiliateUserCampaignValue)) {
        affiliateAssociatedCampaigns.push(newAffiliateUserCampaignValue)
      }
      this.setState({affiliateAssociatedCampaigns: [...affiliateAssociatedCampaigns], newAffiliateUserCampaignValue: ''})
      setInLocal(this, 'form change', ['user', 'affiliateAssociatedCampaigns'], affiliateAssociatedCampaigns)
    } else {
      if (newAffiliateCampaignValue) {
        if (affiliateManagerCampaigns && !affiliateManagerCampaigns.includes(newAffiliateCampaignValue)) {
          affiliateManagerCampaigns.push(newAffiliateCampaignValue)
        }
      }
      this.setState({affiliateManagerCampaigns: [...affiliateManagerCampaigns], newAffiliateCampaignValue: ''})
      setInLocal(this, 'form change', ['user', 'affiliateManagerCampaigns'], affiliateManagerCampaigns)
    }
  }

  render() {
    const {context, props} = this
    const {user, readOnly, validation, viewer, showReassignClients, showChangePasswordModal} = props
    const canEditEmail = canWriteUsers(viewer)
    const canChangeRoles = canChangeUserRoles(viewer)
    const canEditCurrentUser = canWriteUsers(this.props.viewer) || (this.props.viewer.isUser && this.props.viewer.id === get(user, 'id'))
    const avaliableCompanies = filter(companies, (c) => viewer.companies.includes(c.value))
    const availableUsers = map(props.users, (user) => ({
      key: user.id,
      label: `${user.firstName} ${user.lastName}`,
      value: user.id,
    }))
    const defaultCompanies = !user ? companies[viewer.companies[0]].value : user.companies
    const currUserIsSalesAgent = get(user, 'roles', []).some((r) => r.id === 'sales_agent')
    const currUserIsAffiliate = get(user, 'roles', []).some((r) => r.id === 'affiliate')
    const currUserIsAffManager = get(user, 'roles', []).some((r) => r.id === 'affiliate_manager')
    const currUserIsRestrictedAgent = get(user, 'roles', []).some((r) => r.id === 'restricted_agent')
    const renderEditButton = canEditCurrentUser ||
      (currUserIsSalesAgent && canUpdateSalesAgentCampaign(this.props.viewer)) ||
      (currUserIsAffManager && canUpdateAffiliateManagerCampaign(this.props.viewer))

    function makeClickableInReadOnly(content, href) {
      return readOnly ? <a href={href}>{content}</a> : content
    }

    const {associatedCampaigns, associatedSubCampaigns, newCampaignValue, newSubCampaignValue,
      newAffiliateCampaignValue, affiliateManagerCampaigns, affiliateAssociatedCampaigns, newAffiliateUserCampaignValue} = this.state

    return !user ? null : (
      <React.Fragment>
        <Row>
          <Col xs={12}>
            <ButtonToolbar className={`float-right ${style.toolbar}`} >
              {readOnly ||
                <Button
                  id="t-edit-user-cancel"
                  size="sm"
                  variant="outline-secondary"
                  className="mr-2"
                  onClick={() => this.cancel()}
                >
                  Cancel
                </Button>
              }
              {readOnly ||
                <Button
                  id="t-edit-user-save"
                  variant="success"
                  size="sm"
                  disabled={isEmpty(this.props.dirty) || this.state.createButtonDisabled}
                  onClick={this.trySave}
                >
                  Save
                </Button>
              }
              {readOnly && user.id && canWriteUsers(this.props.viewer) &&
                <DropdownButton title="Actions" variant="success" className="mr-2" size="sm" id="actions-button">
                  <Dropdown.Item as="button" onClick={() => {
                    props.actions.user.watchGmail(user.id)
                      .then(() => this.context.showNotification({
                        title: 'Success!',
                        position: 'tr',
                        level: 'success',
                      }))
                      .catch((e) => {
                        e.isShowActualError = true
                        return this.context.logError(e)
                      })
                  }}>Watch Gmail</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => {
                    props.actions.user.stopWatchingGmail(user.id)
                      .then(() => this.context.showNotification({
                        title: 'Success!',
                        position: 'tr',
                        level: 'success',
                      }))
                      .catch((e) => {
                        e.isShowActualError = true
                        return this.context.logError(e)
                      })
                  }}>Stop Watching Gmail</Dropdown.Item>
                  <Dropdown.Item as="button" onClick={() => {
                    props.actions.user.resetUserPassword(user.id)
                      .then(() => {
                        this.props.notify('Password Reset Email Sent To User', 'success')
                      })
                      .catch(this.context.logError)
                  }}>Reset Password</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Item as="button" id="t-edit-user-toggle-activate" onClick={() => {
                    props.actions.user.toggleActive(user)
                      .then((res) => context.usersProvider.fetch())
                      .catch(this.context.logError)
                  }}>{!user.isActive ? 'Activate' : 'Deactivate'}</Dropdown.Item>
                </DropdownButton>
              }
              {readOnly && user.id && canWriteClientSalesAgent(this.props.viewer) &&
                userPermissionChecker(this.props.viewer, canAssignUserAsSalesAgent)(user) && user.isActive &&
                <Button
                  id="t-edit-user-reassign-clients"
                  variant="outline-secondary"
                  size="sm"
                  className="mr-2"
                  onClick={() => {
                    this.props.uiDispatch(
                      'Show reassign clients',
                      (state) => ({...state, showReassignClients: true})
                    )
                  }}
                >
                  Reassign Clients
                </Button>
              }
              {readOnly && canEditCurrentUser &&
                <Button
                  id="t-edit-user-change-own-password"
                  variant="success"
                  size="sm"
                  className="mr-2"
                  onClick={() => this.changeOwnPassword()}
                >
                  Change Own Password
                </Button>
              }
              {readOnly && renderEditButton &&
                <Button
                  id="t-edit-user-edit"
                  variant="success"
                  size="sm"
                  onClick={() => setInLocal(this, 'readonly -> false', ['readOnly'], false)}
                >
                  Edit
                </Button>
              }
            </ButtonToolbar>
          </Col>
        </Row>
        <Row>
          <Card>
            <Card.Body className="fit-to-page">
              <Row>
                <Col xs={3}>
                  <Image src={user.avatar} className={style.largeAvatar} rounded />
                </Col>
                <Col xs={9}>
                  <Row>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                          {...this.inputProps('First Name', ['user', 'firstName'],
                            validation.firstName, !canEditCurrentUser)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                          {...this.inputProps('Last Name', ['user', 'lastName'],
                            validation.lastName, !canEditCurrentUser)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Form.Group>
                        <Form.Check
                          {...this.inputProps('Is Pool', ['user', 'isPool'], validation.isPool, !canEditCurrentUser)}
                          checked={getIn(this.props, ['user', 'isPool'], {last: ''})}
                          onChange={(e) => {
                            setInLocal(this, 'form change', ['user', 'isPool'], e.target.checked)
                          }}
                          type={undefined}
                          label="Is Pool"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Job Title</Form.Label>
                    <Form.Control
                      {...this.inputProps('Job Title', ['user', 'jobTitle'], undefined, !canEditCurrentUser)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={6}>
                  <DateTime
                    id="t-edit-user-start-date"
                    timeFormat={false}
                    label='Start Date'
                    value={user.startDate ? moment(user.startDate).format('DD/MM/YYYY') : null}
                    onChange={(e) => {
                      setInLocal(
                        this,
                        'set form startDate',
                        ['user', 'startDate'],
                        e
                      )
                    }}
                    closeOnSelect
                    className={style.datetime}
                    inputProps={{
                      disabled: !canEditCurrentUser || this.props.readOnly,
                    }}
                  />
                </Col>
                <Col xs={6}>
                  <DateTime
                    id="t-edit-user-end-date"
                    timeFormat={false}
                    label='End Date'
                    value={user.endDate ? moment(user.endDate).format('DD/MM/YYYY') : null}
                    onChange={(e) => {
                      setInLocal(
                        this,
                        'set form endDate',
                        ['user', 'endDate'],
                        e
                      )
                    }}
                    closeOnSelect
                    className={style.datetime}
                    inputProps={{
                      disabled: !canEditCurrentUser || this.props.readOnly,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Department</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Department', ['user', 'department'],
                        validation.department, !canEditCurrentUser)}
                      onChange={(e) => {
                        setInLocal(
                          this,
                          'set form department',
                          ['user', 'department'],
                          e.value,
                        )
                      }}
                      placeholderText="Department"
                      options={Object.values(userDepartments).sort((a, b) => a.label < b.label ? -1 : 1)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Location</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Location', ['user', 'location', 'id'],
                        validation.location, !canEditCurrentUser)}
                      onChange={(e) => {
                        setInLocal(
                          this,
                          'set form location',
                          ['user', 'location', 'id'],
                          Number(e.value)
                        )
                      }}
                      placeholderText="Location"
                      options={Object.values(props.locations).map((l) => ({label: l.name, value: l.id}))}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Manager</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Manager', ['user', 'manager', 'id'], undefined, !canEditCurrentUser)}
                      onChange={(e) => {
                        setInLocal(
                          this,
                          'set form managerId',
                          ['user', 'manager', 'id'],
                          Number(e.value)
                        )
                      }}
                      placeholderText="Assign Manager"
                      options={this.getAvailableManagerOptions()}
                      isClearable
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Department Role </Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Department Role', ['user', 'departmentRoleType'],
                        validation.departmentRoleType, !canEditCurrentUser)}
                      onChange={(e) => {
                        setInLocal(
                          this,
                          'set form department role',
                          ['user', 'departmentRoleType'],
                          e.value
                        )
                      }}
                      placeholderText="Sales Agent Type"
                      options={StylishSelect.enumToStylishOptions(departmentRoles, null, true).filter((e)=> e.value !==  departmentRoles['employee'].value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      {...this.inputProps('Username', ['user', 'username'],
                        validation.username, !canEditCurrentUser)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Bio</Form.Label>
                    <Form.Control
                      {...this.inputProps('Bio', ['user', 'bio'], undefined, !canEditCurrentUser)}
                      as="textarea"
                      rows="4"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Email <small><i>(use lowercase only)</i></small> </Form.Label>
                    {makeClickableInReadOnly(
                      <Form.Control
                        {...this.inputProps('Email', ['user', 'email'], validation.email, !canEditCurrentUser)}
                        disabled={readOnly || !canEditEmail}
                        maxLength="255"
                        value={getIn(this.props, ['user', 'email'], {last: ''}).toLowerCase()}
                        onChange={(e) => {
                          // Force lowercase (SKYG-1034)
                          // Need to update the state here as well, bcs otherwise user could copy-paste an email with
                          // uppercase letters and it would pass, but would still be shown as lowercase in the input
                          setInLocal(this, 'form change', ['user', 'email'], e.target.value?.toLowerCase())
                        }}
                      />,
                      `mailto:${user.email}`
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Skype</Form.Label>
                    <Form.Control
                      {...this.inputProps('Skype', ['user', 'skype'], undefined, !canEditCurrentUser)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Phone</Form.Label>
                    {makeClickableInReadOnly(
                      <Form.Control
                        {...this.inputProps('Phone', ['user', 'phone'], undefined, !canEditCurrentUser)}
                      />,
                      `tel:${user.phone}`
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Sales Agent Telephony Provider</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Sales Agent Telephony Provider', ['user', 'telephonyProvider'],
                        validation.telephonyProvider, !canEditCurrentUser)}
                      onChange={(e) => {
                        setInLocal(
                          this,
                          'set form telephonyProvider',
                          ['user', 'telephonyProvider'],
                          e.value
                        )
                      }}
                      placeholderText="Sales Agent Telephony Provider"
                      options={StylishSelect.enumToStylishOptions(telephonyProviders, null, true)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Countries (for Pools)</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Countries (for Pools)', ['user', 'countries'], undefined, !canEditCurrentUser)}
                      onChange={(selection) => {
                        const countries = selection.map((o) => o.value)
                        setInLocal(
                          this,
                          'set form countries',
                          ['user', 'countries'],
                          countries
                        )
                      }}
                      placeholderText="Countries"
                      multi
                      options={StylishSelect.enumToStylishOptions(countries, null, true)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Languages</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Languages', ['user', 'languages'], undefined, !canEditCurrentUser)}
                      onChange={(selection) => {
                        const languages = selection.map((o) => o.value)
                        setInLocal(
                          this,
                          'set form languages',
                          ['user', 'languages'],
                          languages
                        )
                      }}
                      placeholderText="Languages"
                      multi
                      options={StylishSelect.enumToStylishOptions(languagesWithVariation, null, true)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Companies</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Companies', ['user', 'companies'], validation.companies, !canEditCurrentUser)}
                      onChange={(selection) => {
                        const companies = selection.map((o) => o.value)
                        setInLocal(
                          this,
                          'set form companies',
                          ['user', 'companies'],
                          companies
                        )
                      }}
                      value={defaultCompanies}
                      placeholderText="Associated Companies"
                      multi
                      options={StylishSelect.enumToStylishOptions(avaliableCompanies, null, true)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {currUserIsRestrictedAgent && <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Selected Pools</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Selected Pools', ['user', 'selectedPools'], undefined, !canEditCurrentUser)}
                      onChange={(selection) => {
                        const users = selection.map((o) => o.value)
                        setInLocal(
                          this,
                          'set form client pool\'s',
                          ['user', 'selectedPools'],
                          users
                        )
                      }}
                      placeholderText="Selected Pools"
                      multi
                      options={StylishSelect.enumToStylishOptions(availableUsers, null, true)}
                    />
                  </Form.Group>
                </Col>
              </Row>}
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Allowed IP Addresses For Password Login [Regex, Comma Separated]</Form.Label>
                    <Form.Control
                      {...this.inputProps('Allowed IP Addresses For Password Login', ['user', 'allowedIps'], undefined, !canEditCurrentUser)}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {user.department === userDepartments.affiliate.value && currUserIsAffManager &&
                <Row key="affiliate-manager-campaigns">
                  <Col xs={12}>
                    <Card>
                      <Card.Header>Affiliate Manager Campaigns</Card.Header>
                      <Card.Body>
                        <div className={style.campaignsBody}>
                          {
                            affiliateManagerCampaigns && affiliateManagerCampaigns.map((c, i) => (
                              <EditableLabel
                                className="label-info"
                                isEditMode={!this.props.readOnly}
                                key={i}
                                labelText={c}
                                id={`t-associated-campaign-${c}`}
                                onClickHandler={(e) => this.removeCampaignAssociation(c, false, true)}
                              />))
                          }
                        </div>
                        {!this.props.readOnly &&
                          <Row>
                            <Col xs={10}>
                              <Form.Control
                                type="text"
                                id={'t-affiliate-campaign-input'}
                                value={newAffiliateCampaignValue}
                                onChange={(e) => this.setState({newAffiliateCampaignValue: e.target.value.trim()})}
                              />
                            </Col>
                            <Col xs={2}>
                              <Button
                                id={'t-add-affiliate-campaign-btn'}
                                disabled={!newAffiliateCampaignValue.length}
                                variant="success" onClick={(e) => this.addCampaignAssociation()}
                              >
                                Add
                              </Button>
                            </Col>
                          </Row>
                        }
                      </Card.Body>
                    </Card>
                  </Col>
                </Row>
              }
              {(canWriteAffiliateAssociatedCampaign(this.props.viewer)) && currUserIsAffiliate &&
              <Row key="affiliate-campaigns">
                <Col xs={12}>
                  <Card>
                    <Card.Header>Affiliate Associated Campaigns</Card.Header>
                    <Card.Body>
                      <div className={style.campaignsBody}>
                        {
                          affiliateAssociatedCampaigns && affiliateAssociatedCampaigns.map((c, i) => (
                            <EditableLabel
                              className="label-info"
                              isEditMode={!this.props.readOnly}
                              key={i}
                              labelText={c}
                              id={`t-associated-campaign-${c}`}
                              onClickHandler={(e) => this.removeCampaignAssociation(c, false, true)}
                            />))
                        }
                      </div>
                      {!this.props.readOnly &&
                      <Row>
                        <Col xs={10}>
                          <Form.Control
                            type="text"
                            id={'t-affiliate-campaign-input'}
                            value={newAffiliateUserCampaignValue}
                            onChange={(e) => this.setState({newAffiliateUserCampaignValue: e.target.value.trim()})}
                          />
                        </Col>
                        <Col xs={2}>
                          <Button
                            id={'t-add-affiliate-campaign-btn'}
                            disabled={!newAffiliateUserCampaignValue.length}
                            variant="success" onClick={(e) => this.addCampaignAssociation()}
                          >
                            Add
                          </Button>
                        </Col>
                      </Row>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              }
              {(canEditCurrentUser || canUpdateSalesAgentCampaign(this.props.viewer)) && currUserIsSalesAgent &&
                [
                  <Row key="associated-campaigns">
                    <Col xs={12}>
                      <Card>
                        <Card.Header>Sales Agent Associated Campaigns</Card.Header>
                        <Card.Body>
                          <div className={style.campaignsBody}>
                            {associatedCampaigns && associatedCampaigns.map((c, i) => (
                              <EditableLabel
                                className="label-info"
                                isEditMode={!this.props.readOnly}
                                key={i}
                                labelText={c}
                                id={`t-associated-campaign-${c}`}
                                onClickHandler={(e) => this.removeCampaignAssociation(c)}
                              />))
                            }
                          </div>
                          {!this.props.readOnly &&
                            <Row>
                              <Col xs={10}>
                                <Form.Control
                                  type="text"
                                  id={'t-associated-campaign-input'}
                                  value={newCampaignValue}
                                  onChange={(e) => this.setState({newCampaignValue: e.target.value.trim()})}
                                />
                              </Col>
                              <Col xs={2}>
                                <Button
                                  id={'t-add-associated-campaign-btn'}
                                  disabled={!newCampaignValue.length}
                                  variant="success" onClick={(e) => this.addCampaignAssociation()}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          }
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>,
                  <Row key="associated-sub-campaigns">
                    <Col xs={12}>
                      <Card>
                        <Card.Header>Associated Sub-Campaigns</Card.Header>
                        <Card.Body>
                          <div className={style.campaignsBody}>
                            {
                              associatedSubCampaigns && associatedSubCampaigns.map((c, i) => (
                                <EditableLabel
                                  className="label-info"
                                  isEditMode={!this.props.readOnly}
                                  key={i}
                                  labelText={c}
                                  id={`t-associated-subcampaign-${c}`}
                                  onClickHandler={(e) => this.removeCampaignAssociation(c, true)}
                                />))
                            }
                          </div>
                          {!this.props.readOnly &&
                            <Row>
                              <Col xs={10}>
                                <Form.Control
                                  type="text"
                                  id={'t-associated-subcampaign-input'}
                                  value={newSubCampaignValue}
                                  onChange={(e) => this.setState({newSubCampaignValue: e.target.value.trim()})}
                                />
                              </Col>
                              <Col xs={2}>
                                <Button
                                  id={'t-add-associated-subcampaign-btn'}
                                  variant="success"
                                  disabled={!newSubCampaignValue.length}
                                  onClick={(e) => this.addCampaignAssociation(true)}
                                >
                                  Add
                                </Button>
                              </Col>
                            </Row>
                          }
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>,
                ]
              }

              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Sales Agent Type</Form.Label>
                    <StylishSelect.Input
                      {...this.inputProps('Sales Agent Type', ['user', 'salesAgentType'],
                        validation.salesAgentType, !canEditCurrentUser)}
                      onChange={(e) => {
                        setInLocal(
                          this,
                          'set form salesAgentType',
                          ['user', 'salesAgentType'],
                          e.value
                        )
                      }}
                      placeholderText="Sales Agent Type"
                      options={StylishSelect.enumToStylishOptions(salesAgentTypes, null, true)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>FTD minimum (For retention agent)</Form.Label>
                    <Form.Control
                      {...this.inputProps('FTD minimum', ['user', 'ftdMin'], undefined, !canEditCurrentUser)}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group>
                    <Form.Label>FTD maximum (For retention agent)</Form.Label>
                    <Form.Control
                      {...this.inputProps('FTD maximum', ['user', 'ftdMax'], undefined, !canEditCurrentUser)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              {canChangeRoles &&
                <Row>
                  <Col xs={12}>
                    <h4>Roles</h4>
                    {Object.values(props.roles).map((role) => (
                      <Form.Group key={role.id}>
                        <Form.Check
                          id={'t-edit-user-role-' + sanitizeId(role.name)}
                          label={<span>
                            <strong>{role.name}</strong><br />
                            <small className={style.gray}>{role.description}</small>
                          </span>}
                          checked={!!user.roles.find((userRole) => userRole.id === role.id)}
                          disabled={readOnly || !canChangeRoles || !canApplySetThisRole(this.props.viewer, role) || this.canSelectRole(role.id)}
                          onChange={(e) => {
                            let roles = [...user.roles]
                            if (e.target.checked) {
                              roles.push(role)
                            } else {
                              roles = roles.filter((userRole) => userRole.id !== role.id)
                            }
                            setInLocal(this, 'set roles', ['user', 'roles'], roles)
                          }}
                        />
                      </Form.Group>
                    ))}
                  </Col>
                </Row>
              }
            </Card.Body>
          </Card>
        </Row>
        <ReassignAgentClientsModal
          show={showReassignClients}
          onHide={this.hideReassignClientsModal.bind(this)}
          user={user}
          notify={this.props.notify}
        />
        <ChangePasswordModal
          show={showChangePasswordModal}
          onHide={() => this.hideChangePasswordModal()}
          onPasswordChanged={() => this.onPasswordChanged()}
        />
      </React.Fragment>
    )
  }
}

export default compose(
  uiMount((state) => ['usersUi', 'editor']),

  translate('User Editor', getTranslatedUiState, {state: ['user'], dirty: ['dirty']}),

  predispatch(initializeUiState),

  provideProps((_, uiState, __, props) => {
    const {user: currentUser, readOnly, validation, dirty, showReassignClients, showChangePasswordModal} = uiState
    const {user} = props

    const userId = get(props, 'match.params.userId')

    return ({
      user: userId === 'new'
        ? currentUser
        : {
          ...user,
          ...(currentUser.id === user.id ? currentUser : {}),
        },
      readOnly: userId === 'new' ? false : readOnly,
      validation,
      dirty,
      showReassignClients,
      showChangePasswordModal
    })
  }),
)(UserEditor)
