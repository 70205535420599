import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {map, reject, isEmpty, get, find} from 'lodash'
import {canToggleGdcPartners} from '@bdswiss/common-permissions'
import {Col, Row, Card, Button, ButtonToolbar, Form} from 'react-bootstrap'
import {compose, mountDataProviders, provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import SelectAgent from '../components/SelectAgent'
import style from './partnerscustomization.module.scss'
import {PartnersCustomizationRowAccountType, PartnersCustomizationRowMinDeposit, PartnersCustomizationRowLeverage} from '.'
import StylishSelect from '../components/StylishSelect'
import {countries, existingOrNewClients, ibNetworkOptions} from '@bdswiss/common-enums'
import {getAccountOpenTypes} from '../common/utils'
import {agentsProvider} from '../providers'

class PartnersCustomizationForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func,
    config: PropTypes.object.isRequired,
  }

  componentWillMount() {
    const {selectedPartnerCustomization} = this.props

    const mappedCustomization = selectedPartnerCustomization ? {
      ...selectedPartnerCustomization,
      depositorSalesAgent: get(selectedPartnerCustomization, 'depositorSalesAgent.id'),
      nonDepositorSalesAgent: get(selectedPartnerCustomization, 'nonDepositorSalesAgent.id'),
    } : {}

    const state = {
      partnerCustomization: !isEmpty(mappedCustomization)
        ? mappedCustomization
        : {
          accountTypeCustomizations: {
            accountSubTypes: []
          },
          minDepositCustomizations: [],
          leverageCustomizations: [],
          bonus: {
            enabled: false,
            startDate: '',
          },
          gdcDisabled: false,
          spoa: {
            enabled: false,
            network: ibNetworkOptions.direct.value,
          },
          autochartist: {
            enabled: false,
            network: ibNetworkOptions.direct.value,
            activateFor: existingOrNewClients.new.value,
          }
        },
      formError: {},
      formState: {},
    }
    this.setState(state)
    if (get(selectedPartnerCustomization, 'ibId')) this.checkIbAccount(selectedPartnerCustomization.ibId, 'all')
  }

  emptyMinDepositCustomization() {
    return {
      accountSubType: '',
      amount: '',
    }
  }

  emptyLeverageCustomization() {
    return {
      accountSubType: '',
      leverage: '',
    }
  }

  addMinDepositCustomizationRow() {
    const {partnerCustomization, partnerCustomization: {minDepositCustomizations}} = this.state
    this.setState({partnerCustomization: {
      ...partnerCustomization,
      minDepositCustomizations: [
        ...minDepositCustomizations, this.emptyMinDepositCustomization()
      ]}
    })
  }

  removeMinDepositCustomizationRow(key) {
    const {partnerCustomization, partnerCustomization: {minDepositCustomizations}} = this.state
    const newMinDepositCustomization = reject(minDepositCustomizations, (customization, i) => i === key)
    this.setState({partnerCustomization: {
      ...partnerCustomization,
      minDepositCustomizations: [
        ...newMinDepositCustomization,
      ]}
    })
  }

  addLeverageCustomizationRow() {
    const {partnerCustomization, partnerCustomization: {leverageCustomizations}} = this.state
    this.setState({partnerCustomization: {
      ...partnerCustomization,
      leverageCustomizations: [
        ...leverageCustomizations, this.emptyLeverageCustomization()
      ]}
    })
  }

  removeLeverageCustomizationRow(key) {
    const {partnerCustomization, partnerCustomization: {leverageCustomizations}} = this.state
    const newLeverageCustomization = reject(leverageCustomizations, (customization, i) => i === key)
    this.setState({partnerCustomization: {
      ...partnerCustomization,
      leverageCustomizations: [
        ...newLeverageCustomization,
      ]}
    })
  }

  partnerCustomizationValueChanged(key, customizationKey, inputKey, value) {
    const {partnerCustomization} = this.state
    let customization

    if (customizationKey === 'accountTypeCustomizations') {
      customization = partnerCustomization[customizationKey]
    } else {
      customization = [...partnerCustomization[customizationKey]]
    }

    if (customization[key]) {
      customization[key] = {
        ...customization[key],
        [inputKey]: value,
      }
    } else {
      customization = {
        ...customization,
        [inputKey]: value,
      }
    }

    this.setState({partnerCustomization: {
      ...partnerCustomization,
      [customizationKey]: customization
    }})
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  isValidForm() {
    const {affiliateId, ibId, campaignId} = this.state

    if (!(affiliateId && (!ibId && !campaignId))) {
      return false
    }

    if (!(ibId && (!affiliateId && !campaignId))) {
      return false
    }

    if (!(campaignId && (!ibId && !affiliateId))) {
      return false
    }

    return true
  }

  handleSubmit() {
    const {partnerCustomization: {id, accountTypeCustomizations, minDepositCustomizations, leverageCustomizations,
      affiliateId, ibId, campaignId, initialDemoDeposit, depositorSalesAgent, bonus, autochartist,
      nonDepositorSalesAgent, gdcDisabled, spoa}, formError} = this.state

    const hasAccountTypeCustomizations = !isEmpty(accountTypeCustomizations)
    const hasMinDepositCustomizations = !isEmpty(minDepositCustomizations)
    const hasLeverageCustomizations = !isEmpty(leverageCustomizations)
    const hasBonusCustomization = !isEmpty(bonus)

    if (hasAccountTypeCustomizations ||
      hasMinDepositCustomizations ||
      hasLeverageCustomizations ||
      hasBonusCustomization ||
      (initialDemoDeposit || 0) > 0
    ) {
      if (isEmpty(formError)) {
        const args = {
          affiliateId, ibId, campaignId, accountTypeCustomizations, minDepositCustomizations,
          leverageCustomizations, id, initialDemoDeposit, depositorSalesAgent, nonDepositorSalesAgent, gdcDisabled,
          bonus: {
            ...bonus,
            startDate: get(bonus, 'enabled') ? get(bonus, 'startDate') || moment().format('YYYY-MM-DD') : undefined,
          },
          spoa,
          autochartist,
        }

        this.props.actions.partnersCustomization.upsertPartnerCustomization(args)
          .then((res) => {
            this.context.showNotification({
              title: 'Partner Customization',
              message: 'Partner customization was successfully created',
              position: 'tr',
              level: 'success',
            })
          })
          .then(() => this.context.router.push('/partners-customization'))
          .catch((e) => {
            this.context.logError(e)
          })
      } else {
        this.context.showNotification({
          title: 'Partner Customization',
          message: 'Partner customization form fields are missing. Please fill all the fields before saving',
          position: 'tr',
          level: 'warning',
        })
      }
    } else {
      this.context.showNotification({
        title: 'Partner Customization',
        message: 'Partner customization form is empty. Please add a customization before saving',
        position: 'tr',
        level: 'warning',
      })
    }
  }

  getSelectedMinDepositAccTypes() {
    const {partnerCustomization: {minDepositCustomizations}} = this.state

    return map(minDepositCustomizations, 'accountSubType')
  }

  getSelectedLeverageAccTypes() {
    const {partnerCustomization: {leverageCustomizations}} = this.state

    return map(leverageCustomizations, 'accountSubType')
  }

  checkIbAccount(ibId, countriesSelected) {
    let filteredCountries
    switch (countriesSelected) {
      case 'all': {
        filteredCountries = map(countries, 'key')
        break
      }
      case 'nonEu': {
        filteredCountries = map(reject(countries, (country) => country.isEU), 'key')
        break
      }
      default: {
        break
      }
    }

    const args = {ibId, isIbApproved: true, countries: filteredCountries}
    const validIbId = new RegExp('^[0-9]{1,10}$', 'i').test(ibId)
    validIbId && this.props.actions.partnersCustomization.getIbFromIbId(args).then(
      (res) => this.setState({ibSelected: get(res, 'getIbFromIbId')})
    )
  }

  render() {
    const {agents} = this.props
    const {
      partnerCustomization,
      partnerCustomization: {
        accountTypeCustomizations,
        minDepositCustomizations,
        leverageCustomizations,
        initialDemoDeposit,
        depositorSalesAgent,
        nonDepositorSalesAgent,
        affiliateId,
        ibId,
        campaignId,
        gdcDisabled,
        updatedAt,
        bonus,
        spoa,
        autochartist,
      },
      ibSelected
    } = this.state
    const selectedMinDepositAccTypes = this.getSelectedMinDepositAccTypes()
    const selectedLeverageAccTypes = this.getSelectedLeverageAccTypes()
    const accountTypes = getAccountOpenTypes()
    const accountTypesWithDemo = [
      ...accountTypes,
      {key: 'demo', label: 'Demo Account', value: 'demo'}
    ]

    return (
      <Card>
        <Card.Body>
          {get(partnerCustomization, 'id') && <Row>
            <Col xs={12}><span className={style.updatedAt}>Last Updated At: {moment(updatedAt).format('DD/MM/YYYY HH:mm:ss')}</span></Col>
          </Row>}
          <Row>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Affiliate ID</Form.Label>
                <Form.Control
                  id="t-partners-customization-form-affiliate-id"
                  type="text"
                  defaultValue={affiliateId || ''}
                  maxLength="255"
                  onChange={(evt) => this.setState({partnerCustomization: {
                    ...partnerCustomization,
                    affiliateId: evt.target.value
                  }})}
                  placeholder="Affiliate ID"
                  disabled={ibId || campaignId}
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>IB ID</Form.Label>
                <Form.Control
                  id="t-partners-customization-form-ib-id"
                  type="text"
                  defaultValue={ibId || ''}
                  maxLength="255"
                  onChange={(evt) => this.setState({partnerCustomization: {
                    ...partnerCustomization,
                    ibId: evt.target.value
                  }})}
                  placeholder="IB ID"
                  disabled={affiliateId || campaignId}
                  onBlur={(evt) => get(evt, 'target.validity.valid') && this.checkIbAccount(evt.target.value, 'all')}
                  pattern='^[0-9]{1,10}$'
                />
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Campaign ID</Form.Label>
                <Form.Control
                  id="t-partners-customization-form-campaign-id"
                  type="text"
                  defaultValue={campaignId || ''}
                  maxLength="255"
                  onChange={(evt) => this.setState({partnerCustomization: {
                    ...partnerCustomization,
                    campaignId: evt.target.value
                  }})}
                  placeholder="Campaign ID"
                  disabled={affiliateId || ibId}
                />
              </Form.Group>
            </Col>
            <Col xs={12} className="mt-3 mb-4">
              <Form.Check
                id="t-campaigns-form-gdc-disabled"
                type="checkbox"
                label={<span><strong>GDC Disabled</strong></span>}
                value={gdcDisabled}
                checked={gdcDisabled}
                onChange={() => this.setState((state) => ({
                  partnerCustomization: {
                    ...state.partnerCustomization,
                    gdcDisabled: !gdcDisabled
                  }}))
                }
                disabled={!canToggleGdcPartners(this.props.viewer)}
              />
            </Col>
            <Col xs={4}>
              <h3> <strong> Account Types Customization </strong> </h3>
              <PartnersCustomizationRowAccountType
                accountSubTypes={accountTypeCustomizations.accountSubTypes}
                onChange={(inputKey, value) => this.partnerCustomizationValueChanged(0, 'accountTypeCustomizations', inputKey, value)}
              />
            </Col>
            <Col xs={3}>
              <h3> <strong> Initial Demo Deposit </strong> </h3>
              <Form.Label>&nbsp;</Form.Label>
              <Form.Control
                type="number"
                label="&nbsp;"
                defaultValue={initialDemoDeposit || ''}
                maxLength="255"
                onChange={(evt) => {
                  let value = Number(evt.target.value)
                  if (value < 0) value = 0
                  this.setState({partnerCustomization: {
                    ...partnerCustomization,
                    initialDemoDeposit: value
                  }})
                }}
                placeholder="Amount"
              />
            </Col>
            {(ibId || affiliateId) && <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <hr />
                  <h3> <strong> Lead Assignment </strong> </h3>
                </Col>
                <Col xs={6}>
                  <SelectAgent.Input
                    id="t-customization-page-partners-agent-filter"
                    label="Non Depositors Customization"
                    agents={agents}
                    value={nonDepositorSalesAgent}
                    placeholderText="Select Agent/Pool"
                    onChange={({value}) => this.setState({partnerCustomization: {
                      ...partnerCustomization,
                      nonDepositorSalesAgent: value
                    }})}
                  />
                </Col>
                <Col xs={6}>
                  <SelectAgent.Input
                    id="t-customization-page-partners-agent-filter"
                    label="Depositors Customization"
                    agents={agents}
                    value={depositorSalesAgent}
                    placeholderText="Select Agent/Pool"
                    onChange={({value}) => this.setState({partnerCustomization: {
                      ...partnerCustomization,
                      depositorSalesAgent: value
                    }})}
                  />
                </Col>
              </Row>
            </Col>}
            {(affiliateId || ibId) && <Col xs={12}>
              <Row>
                <Col xs={12}>
                  <hr />
                </Col>
                <Col xs={4}>
                  <h3> <strong> Bonus </strong> </h3>
                  <Row>
                    <Col xs={2}>
                      <Form.Check
                        type="checkbox"
                        label="Enabled"
                        checked={!!get(bonus, 'enabled')}
                        onChange={(evt) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            bonus: {
                              ...bonus,
                              enabled: evt.target.checked,
                            },
                          }})
                        }}
                      />
                    </Col>
                    <Col xs={4}>
                      <DateTime
                        closeOnSelect
                        label="Start Date"
                        value={get(bonus, 'startDate')}
                        timeFormat={false}
                        onChange={(value) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            bonus: {
                              ...bonus,
                              startDate: value ? value.format('YYYY-MM-DD') : '',
                            },
                          }})
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                {ibId && <Col xs={4}>
                  <span><h3 className={style.displayInline}> <strong> SPOA </strong></h3><i>(available for Approved Non-EU IBs only)</i></span>
                  <Row>
                    <Col xs={2}>
                      <Form.Check
                        type="checkbox"
                        label="Enabled"
                        checked={!!get(spoa, 'enabled')}
                        onChange={(evt) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            spoa: {
                              ...spoa,
                              enabled: evt.target.checked,
                            },
                          }})
                        }}
                        disabled={!ibSelected}
                      />
                    </Col>
                    <Col xs={4}>
                      <StylishSelect.Input
                        id="t-ib-spoa-network"
                        label="Network"
                        value={spoa.network}
                        onChange={(e) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            spoa: {
                              ...spoa,
                              network: e.value,
                            },
                          }})
                        }}
                        options={StylishSelect.enumToStylishOptions(ibNetworkOptions)}
                        disabled={!ibSelected || !get(spoa, 'enabled')}
                      />
                    </Col>
                  </Row>
                </Col>}
                {ibId && <Col xs={4}>
                  <span><h3 className={style.displayInline}> <strong> Autochartist </strong></h3><i>(available for Approved IBs only)</i></span>
                  <Row>
                    <Col xs={2}>
                      <Form.Check
                        id="t-ib-autochartist-enabled"
                        type="checkbox"
                        label="Enabled"
                        checked={!!get(autochartist, 'enabled')}
                        onChange={(evt) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            autochartist: {
                              ...autochartist,
                              enabled: evt.target.checked,
                            },
                          }})
                        }}
                        disabled={!ibSelected}
                      />
                    </Col>
                    <Col xs={4}>
                      <StylishSelect.Input
                        id="t-ib-autochartist-network"
                        label="Network"
                        value={autochartist.network}
                        onChange={(e) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            autochartist: {
                              ...autochartist,
                              network: e.value,
                            },
                          }})
                        }}
                        options={StylishSelect.enumToStylishOptions(ibNetworkOptions)}
                        disabled={!ibSelected || !get(autochartist, 'enabled')}
                      />
                    </Col>
                    <Col xs={4}>
                      <StylishSelect.Input
                        id="t-ib-autochartist-activateFor"
                        label="Activate for"
                        value={autochartist.activateFor}
                        onChange={(e) => {
                          this.setState({partnerCustomization: {
                            ...partnerCustomization,
                            autochartist: {
                              ...autochartist,
                              activateFor: e.value,
                            },
                          }})
                        }}
                        options={StylishSelect.enumToStylishOptions(existingOrNewClients)}
                        disabled={!ibSelected || !get(autochartist, 'enabled')}
                      />
                    </Col>
                  </Row>
                </Col>}
              </Row>
            </Col>}
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <h3> <strong>  Minimum Deposits Customization </strong> </h3>
              {map(minDepositCustomizations, (customization, key) => {
                const {accountSubType, amount} = customization
                return (
                  <PartnersCustomizationRowMinDeposit
                    key={key}
                    minDepositCustomizationKey={key}
                    selectedMinDepositAccTypes={selectedMinDepositAccTypes}
                    accountSubType={accountSubType}
                    amount={amount}
                    onChange={(inputKey, value) => this.partnerCustomizationValueChanged(key, 'minDepositCustomizations', inputKey, (inputKey === 'accountSubType') ? value.value : value)}
                    removeMinDepositCustomizationRow={() => this.removeMinDepositCustomizationRow(key)}
                    accountTypes={accountTypes}
                  />
                )
              })}
            </Col>
            <Col xs={12}>
              <Button
                id="t-partners-customization-form-min-deposit-remove"
                variant="info"
                size="sm"
                onClick={() => this.addMinDepositCustomizationRow()}
                disabled={find(minDepositCustomizations, (customization) => !customization.accountSubType) || accountTypes.length === selectedMinDepositAccTypes.length}
              > Add Minimum Deposit
              </Button>
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <h3> <strong>  Leverage Customization </strong> </h3>
              {map(leverageCustomizations, (customization, key) => {
                const {accountSubType, leverage} = customization
                return (
                  <PartnersCustomizationRowLeverage
                    key={key}
                    leverageCustomizationKey={key}
                    selectedLeverageAccTypes={selectedLeverageAccTypes}
                    accountSubType={accountSubType}
                    leverage={leverage}
                    onChange={(inputKey, value) => this.partnerCustomizationValueChanged(key, 'leverageCustomizations', inputKey, (inputKey === 'accountSubType') ? value.value : value)}
                    removeLeverageCustomizationRow={() => this.removeLeverageCustomizationRow(key)}
                    accountTypes={accountTypesWithDemo}
                  />
                )
              })}
            </Col>
            <Col xs={12}>
              <Button
                id="t-partners-customization-form-account-remove"
                variant="info"
                size="sm"
                onClick={() => this.addLeverageCustomizationRow()}
                disabled={find(leverageCustomizations, (customization) => !customization.accountSubType) || accountTypesWithDemo.length === selectedLeverageAccTypes.length}
              > Add Leverage
              </Button>
            </Col>
            <Col xs={12}>
              <hr />
            </Col>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-partners-customization-editor-cancel-button"
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => this.context.router.push('/partners-customization')}
                  className="mr-3"
                > Cancel
                </Button>
                <Button
                  id="t-partners-customization-editor-save-button"
                  variant="success"
                  size="sm"
                  onClick={() => this.handleSubmit()}
                > Save
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}
export default compose(
  provideProps((state) => {
    const {agents} = state
    return ({
      agents,
    })
  }),
  mountDataProviders({agentsProvider}),
)(PartnersCustomizationForm)
