import React from 'react'
import {LinkContainer} from 'react-router-bootstrap'
import {userDepartments} from '@bdswiss/common-enums'
import {Nav, NavDropdown, Badge} from 'react-bootstrap'
import {
  canQueryCampaigns,
  canQueryClients,
  canQueryWithdrawals,
  canQueryDocuments,
  canQueryDeposits,
  canQueryUsers,
  canQueryStats,
  canQueryArticles,
  canQueryJobs,
  canModifyLeadDistributionSettings,
  canQueryAlerts,
  canQueryTags,
  canQueryBlacklists,
  canViewPushNotifications,
  canViewCallLogs,
  canQueryCommunications,
  canQueryBookAllocations,
  canQueryPaymentOptions,
  canQueryAffiliateManagerClients,
  canQueryNotifications,
  canViewMarketingEmails,
  canUpsertSeminars,
  canModifyDocumentDistributionSettings,
  canCreateCompetitions,
  canQueryClientsRestricted,
  canQueryAllPerformanceFees,
  canQueryFtdReport,
  canViewClientsIsAffiliate,
  canQueryRegistrationsReport,
  canQueryCopiersReport,
  canQueryDepositsReport,
  canQueryTradesReport,
  canQueryActivityLogs,
  canQueryGhostCopiers,
  canQueryOrphanedCopytradingFollowers,
  canLaunchCRMScripts,
  checkUserPermission,
  permissions
} from '@bdswiss/common-permissions'
import {compose, provideProps, mountDataProviders} from './decorators'
import {unreadNewsProvider} from './providers'
import PureComponent from './PureComponent'
import {sanitizeId} from './useful'

function isActive(path, pathname) {
  return pathname && pathname === path
}

class Menu extends PureComponent {

  get isConversionReportShown() {
    const {viewer} = this.props
    return checkUserPermission(viewer, permissions.conversion_report_read) || checkUserPermission(viewer, permissions.own_conversion_report_read)
  }

  get isRetentionReportShown() {
    const {viewer} = this.props
    return checkUserPermission(viewer, permissions.retention_report_read) || checkUserPermission(viewer, permissions.own_retention_report_read)
  }

  renderMenuItem(path, label, inDropdown) {
    const active = isActive(path, this.props.pathname)
    const Item = inDropdown ? NavDropdown.Item : Nav.Link

    return (
      <LinkContainer
        id={`t-header-navbar-${path.substring(1)}`}
        key={path}
        to={path}
      >
        <Item className={active ? 'dropdown-item-active' : ''}>{label}</Item>
      </LinkContainer>
    )
  }

  renderDropdown(title, items) {
    let show, active
    items.forEach((item) => {
      show = show || item.show
      active = active || isActive(item.path, this.props.pathname)
    })

    if (!show) {
      return null
    }

    return (
      <NavDropdown
        id={`t-header-navbar-${sanitizeId(title)}`}
        title={title}
        className={active ? 'active' : ''}
      >
        {items.map(({path, label, show}) => show && this.renderMenuItem(path, label, true))}
      </NavDropdown>
    )
  }

  render() {
    const {unreadArticlesCount, viewer} = this.props
    const badge = unreadArticlesCount > 0 ? (<Badge variant="danger">{unreadArticlesCount}</Badge>) : ''

    return (
      <Nav>
        {canQueryArticles(viewer) && !canQueryAffiliateManagerClients(viewer) &&
          this.renderMenuItem('/news', <span>News {badge}</span>)}
        {canModifyLeadDistributionSettings(viewer) ? this.renderDropdown('Users', [
          {path: '/users', label: 'View Users', show: canQueryUsers(viewer)},
          {
            path: '/lead-distribution-settings',
            label: 'Modify Lead Distribution Settings', show: true,
          },
        ]) : canQueryUsers(viewer) && !canQueryAffiliateManagerClients(viewer) && !canQueryClientsRestricted(viewer) &&
          this.renderMenuItem('/users', 'Users')
        }
        {(canQueryStats(viewer) ||
          this.isConversionReportShown ||
          this.isRetentionReportShown
        ) && this.renderDropdown('Stats', [
          {path: '/agents-commissions', label: 'Conversion report', show: this.isConversionReportShown},
          {path: '/strategies-equity', label: 'Strategies Equity', show: canQueryStats(viewer)},
          {path: '/view-stats', label: 'View Stats', show: canQueryStats(viewer)},
          {path: '/call-logs', label: 'Call Logs', show: canViewCallLogs(viewer)},
          {path: '/net-transactions', label: 'Retention Report', show: this.isRetentionReportShown},
        ])}
        {(!canQueryStats(viewer) && canViewCallLogs(viewer)) && this.renderMenuItem('/call-logs', 'Call Logs')}
        {(canQueryClientsRestricted(viewer) || canViewClientsIsAffiliate(viewer)) && this.renderMenuItem('/clients', 'Clients')}
        {canQueryClients(viewer) && !canQueryClientsRestricted(viewer) && !canViewClientsIsAffiliate(viewer) && this.renderDropdown('Accounts', [
          {key: 'all', path: '/clients', label: 'All', show: true},
          {key: 'clients', path: '/clients/search/retail', label: 'Clients', show: true},
          {key: 'ibAccounts', path: '/clients/ibAccounts', label: 'IB Accounts', show: true},
          {key: 'pammAccounts', path: '/clients/pammAccounts', label: 'PAMM Accounts', show: true},
          {key: 'partners', path: '/clients/search/partners', label: 'Partners', show: true},
          {key: 'strategicPartners', path: '/clients/search/strategic', label: 'Strategic Partners', show: true},
        ])}
        {canQueryDeposits(viewer) && this.renderMenuItem('/deposits', 'Deposits')}
        {canQueryWithdrawals(viewer) && this.renderMenuItem('/withdrawals', 'Withdrawals')}
        {!canQueryAffiliateManagerClients(viewer) && this.renderDropdown('Back Office', [
          {path: '/documents', label: 'Documents', show: canQueryDocuments(viewer)},
          {path: '/fxrates', label: 'FX Rates', show: canQueryClients(viewer)},
          {path: '/alerts', label: 'Alerts', show: canQueryAlerts(viewer)},
          {path: '/blacklists', label: 'Blacklists', show: canQueryBlacklists(viewer)},
          {path: '/book-allocation', label: 'Book Allocation', show: canQueryBookAllocations(viewer)},
          {path: '/notifications', label: 'Notifications', show: canQueryNotifications(viewer)},
          {path: '/activitylogs', label: 'Activity Logs', show: canQueryActivityLogs(viewer)},
          {path: '/communications', label: 'Communications', show: canQueryCommunications(viewer)},
          {
            path: '/auto-assign-document-settings',
            label: 'Auto Assign Document Settings', show: canModifyDocumentDistributionSettings(viewer),
          },
          {path: '/performance-fees', label: 'Performance Fees', show: canQueryAllPerformanceFees(viewer)},
        ])}
        {/* {canQueryClients(viewer) && viewer.department !== userDepartments.clientRelationsTraining.value  &&  this.renderMenuItem('/tickets', 'Tickets')}
        {canQueryClients(viewer) && viewer.department === userDepartments.clientRelationsTraining.value && this.renderDropdown('Tickets', [
          {path: '/tickets', label: 'Tickets', show: true},
          {path: '/ticket-reactions', label: 'Tickets Reactions', show: true},
        ])} */}
        {this.renderDropdown('Marketing', [
          {path: '/campaigns', label: 'Campaigns', show: canQueryCampaigns(viewer)},
          {path: '/tag-categories', label: 'Tags', show: canQueryTags(viewer)},
          {path: '/payment-options', label: 'Payment Options',
            show: canQueryPaymentOptions(viewer)},
          {path: '/cc-payment-options', label: 'CC Payment Options',
            show: canQueryPaymentOptions(viewer)},
          {path: '/country-payment-options', label: 'Country Payment Options',
            show: canQueryPaymentOptions(viewer)},
          {path: '/push-notifications', label: 'Push Notifications',
            show: canViewPushNotifications(viewer)},
          {path: '/marketing-emails', label: 'Marketing Emails',
            show: canViewMarketingEmails(viewer)},
          {path: '/seminars', label: 'Seminars', show: canUpsertSeminars(viewer)},
          {path: '/competitions', label: 'Trading Competitions', show: canCreateCompetitions(viewer)},
          {path: '/partners-customization', label: 'Partners Customization', show: true}
        ])}
        {this.renderDropdown('Reports', [
          {path: '/reports/first-time-deposits', label: 'First Time Deposits', show: canQueryFtdReport(viewer)},
          {path: '/reports/ghost-copiers', label: 'Ghost Copiers', show: canQueryGhostCopiers(viewer)},
          {path: '/reports/registrations-report', label: 'Registrations report', show: canQueryRegistrationsReport(viewer)},
          {path: '/reports/copiers-report', label: 'Copiers report', show: canQueryCopiersReport(viewer)},
          {path: '/reports/deposits-report', label: 'Deposits report', show: canQueryDepositsReport(viewer)},
          {path: '/reports/trades-report', label: 'Trades report', show: canQueryTradesReport(viewer)},
        ])}
        {this.renderDropdown('Admin', [
          {path: '/jobs/dormant-fee', label: 'Dormant Fee / Commissions', show: canQueryJobs(viewer)},
          {path: '/admin/roles', label: 'Roles', show: true},
          {path: '/admin/permissions', label: 'Permissions', show: true},
          {path: '/admin/workers-activity', label: 'Workers activity', show: true},
          {path: '/admin/orphaned-copytrading-followers', label: 'Invalid state copiers', show: canQueryOrphanedCopytradingFollowers(viewer)},
          {path: '/admin/manual-executions', label: 'Manual executions', show: canLaunchCRMScripts(viewer)},
          {path: '/admin/fetcher-services', label: 'Fetcher services', show: true},
          {path: '/admin/jobs-report', label: 'Workers Monitor', show: true},

        ])}
        {this.renderDropdown('Accounting', [
          {path: '/accounting/search-tool', label: 'Search Tool', show: viewer.department === userDepartments.accounting.value},
          {path: '/accounting/checkout-reconciliation', label: 'Checkout Reconciliation', show: viewer.department === userDepartments.accounting.value},
        ])}
      </Nav>
    )
  }
}

export default compose(
  provideProps((state) => ({
    unreadArticlesCount: state.unreadArticlesCount,
  })),
  mountDataProviders({
    unreadNewsProvider: {
      ...unreadNewsProvider,
      cache: true,
      cacheInterval: 24 * 60 * 60 * 1000, // 1d
    }}, true),
)(Menu)
