import React from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonToolbar, Card, Form} from 'react-bootstrap'
import {compose, provideProps, uiMount, predispatch} from '../decorators'
import ReactRteEditor from './ReactRteEditor'
import {isEmptyStr} from '../common/utils.js'
import style from './news.module.scss'
import PureComponent from '../PureComponent'
import {deleteFromStorage} from '../useful'

class NewsArticleEditor extends PureComponent {

  static contextTypes = {
    newsProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    onClose: PropTypes.func.isRequired,
    id: PropTypes.number,
    title: PropTypes.string,
    markdown: PropTypes.string,
  };

  componentWillMount() {
    this.setState({})
  }

  isNew() {
    return this.props.id === 'new'
  }

  simpleDispatch(description, changedProps) {
    this.props.uiDispatch(
      description,
      (state) => ({
        ...state,
        ...changedProps,
      })
    )
  }

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  dispatchTitleChanged(title) {
    this.simpleDispatch('Open article title changed ', {
      newArticleTitle: title,
      formError: {
        ...this.props.formError,
        title: null,
      },
    })
  }

  dispatchArticleChanged(markdown) {
    this.simpleDispatch('Open article markdown changed ', {
      newArticleMarkdown: markdown,
      formError: {
        ...this.props.formError,
        markdown: null,
      },
    })
  }

  validateForm() {
    const result = {}
    let wasError = false
    const {newArticleMarkdown: markdown, newArticleTitle: title} = this.props

    if (isEmptyStr(markdown)) {
      result.markdown = 'error'
      wasError = true
    }

    if (isEmptyStr(title)) {
      result.title = 'error'
      wasError = true
    }

    return wasError ? result : null

  }

  formChanged() {
    const {newArticleTitle, newArticleMarkdown, title, markdown} = this.props
    return newArticleTitle !== title || newArticleMarkdown !== markdown
  }

  saveArticle() {
    const {newArticleTitle, newArticleMarkdown, onClose} = this.props

    const errors = this.validateForm()
    if (errors) {
      this.simpleDispatch('Form errors', {formError: {...errors}})
      return
    }

    const id = this.isNew() ? null : this.props.id

    if (!this.formChanged()) {
      onClose()
      return
    }

    this.disableCreateButton()
    this.props.actions.news.upsertArticle(id, newArticleTitle, newArticleMarkdown)
      .then((res) => {
        deleteFromStorage('newsProvider')
        this.context.newsProvider.fetch()
        onClose()
      })
      .catch(this.context.logError)
  }

  render() {
    const {newArticleTitle, formError = {}, onClose} = this.props

    return (
      <Card className="largePanel">
        <Card.Body>
          <Form.Control
            id="t-news-editor-title"
            type="text"
            title="Title"
            label="Title"
            value={newArticleTitle || ''}
            isInvalid={formError.title === 'error'}
            placeholder="Title"
            maxLength="255"
            onChange={(event) => this.dispatchTitleChanged(event.target.value)}
          />
          <br />
          <ReactRteEditor
            id="t-news-editor-body"
            onChange={(markdown) => this.dispatchArticleChanged(markdown)}
            bsStyle={formError.markdown}
            initialMarkdown={this.props.markdown}
          />

          <ButtonToolbar className={`float-right ${style.toolbar}`}>
            <Button
              id="t-news-editor-cancel"
              onClick={() => onClose()}
              variant="outline-secondary"
              className="mr-2"
            >Cancel</Button>
            <Button
              id="t-news-editor-done"
              variant="success"
              disabled={this.state.createButtonDisabled}
              onClick={() => {this.saveArticle()}}
            >Save</Button>
          </ButtonToolbar>
        </Card.Body>
      </Card>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'news', 'editor']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for sidebar',
      (state) => ({
        ...state,
        formError: {},
        newArticleMarkdown: props.markdown,
        newArticleTitle: props.title,
      })
    )
  }),

  provideProps((state, uiState) => {
    const {newArticleMarkdown, formError, newArticleTitle} = uiState

    return ({
      newArticleMarkdown,
      formError,
      newArticleTitle,
    })
  }),
)(NewsArticleEditor)
