import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Row, Col, Table, Card, Pagination, Container} from 'react-bootstrap'
import {permissions} from '@bdswiss/common-permissions'
import {orderDirections, companies} from '@bdswiss/common-enums'
import style from './agentsCommissions.module.scss'
import {events} from '../enums'
import {agentCommissionsProvider} from './providers'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkOneOfPermissions} from '../decorators'
import {withRouter} from 'react-router-dom'
import moment from 'moment'
import get from 'lodash/get'

// import ReactAudioPlayer from 'react-audio-player'

class AgentCommissions extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doSortByOrderAndOrderDirection = this.doSortByOrderAndOrderDirection.bind(this)
    this.paginationSelectHandle = this.paginationSelectHandle.bind(this)
    this.paginationClickHandle = this.paginationClickHandle.bind(this)
    this.state = {}
  }

  static contextTypes = {
    agentCommissionsProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.agentCommissionsProvider.fetch()
  }

  doSortByOrderAndOrderDirection(e) {
    const {field} = e.currentTarget.dataset
    const {agentCommissionsSort} = this.props
    const {orderBy, orderDirection} = agentCommissionsSort


    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting agents commissions by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      agentCommissionsSort: newSort
    }), [agentCommissionsSort])
  }

  getSortArrow(field) {
    const {agentCommissionsSort} = this.props
    const arrowDirection = agentCommissionsSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (agentCommissionsSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  paginationSelectHandle(e, selectedEvent) {
    this.props.uiDispatch('Show page', (state) => ({
      ...state,
      agentCommissionsPage: selectedEvent.eventKey
    }))
  }

  paginationClickHandle(e) {
    this.props.uiDispatch('Show page', (state) => ({
      ...state,
      agentCommissionsPage: +e.currentTarget.dataset.page
    }))
  }
  clickRowHandle(clientId, depositId) {
    let route = `/clients/${clientId}/deposits`
    if (depositId) {
      route += `/${depositId}`
    }
    window.open(route, '_blank').focus()
    // this.props.history.push(route)
  }

  render() {
    const {agentName, agentCommissions, agentCommissionsCount, agentCommissionsPage, match} = this.props
    const {agentId} = match.params

    return (
      <Container>
        <Row>
          <Col xs={10}>
            <div className={'h4'}>
              Sales Agent Commissions
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={10}>
            <div className={`h6 ${style.subTitle}`}>
              <p><b>Agent:</b> {agentId} {agentName}</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body style={{overflowX: 'scroll'}}>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      {[
                        {header: 'Call Date', sortKey: 'callStarted', minWidth: 100},
                        {header: 'Client', sortKey: 'client', minWidth: 100},
                        {header: 'Client Company', sortKey: 'clientCompany', minWidth: 100},
                        {header: 'Call Duration', sortKey: 'callDuration', minWidth: 100},
                        {header: 'Effective Call', sortKey: 'callEffective', minWidth: 100},
                        {header: 'FTD Qualified', sortKey: 'ftdQualified', minWidth: 100},
                        // {header: 'Call Record', minWidth: 100},
                        {header: 'FTD Amount', sortKey: 'ftdAmount', minWidth: 100},
                        {header: 'FTD Bonus', sortKey: 'ftdBonus', minWidth: 100},
                        {header: 'FTD Id', sortKey: 'ftdId', minWidth: 100},
                        {header: 'FTD Date', sortKey: 'ftdDate', minWidth: 100},
                      ].map(({header, sortKey, minWidth}) => (
                        <th key={sortKey || header} style={{minWidth}}>
                          <span>{header}</span>
                          {!!sortKey && (<FontAwesomeIcon
                            icon={this.getSortArrow(sortKey)}
                            onClick={this.doSortByOrderAndOrderDirection}
                            data-field={sortKey}
                          />)}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {agentCommissions?.map(({
                      callStarted,
                      client,
                      clientId,
                      clientCompany,
                      callDuration,
                      // callRecording,
                      callProvider,
                      callEffective,
                      ftdAmount,
                      ftdBonus,
                      ftdQualified,
                      ftdId,
                      ftdDate
                    }, key) => (<tr
                      key={key}
                      id={'t-sc-id-' + {key}}
                      onClick={e => this.clickRowHandle(clientId,ftdId)}
                      className={classNames(['t-sc'])}
                      style={{cursor: 'pointer'}}
                    >
                      <td>{moment(callStarted).format('LLL')}</td>
                      <td>{client}</td>
                      <td>{get(companies, [clientCompany, 'label'], clientCompany)}</td>
                      <td>{callDuration
                        ? callDuration > 120 ? Math.round(callDuration / 60) + 'm' : callDuration + 's'
                        : '-'
                      }</td>
                      {/*<td>*/}
                      {/*  {callRecording &&*/}
                      {/*  (<ReactAudioPlayer*/}
                      {/*    src={`${this.props.callRecordingsUri[callProvider || telephonyProviders.impactech.value]}${callRecording}`}*/}
                      {/*    preload="none"*/}
                      {/*    controls*/}
                      {/*  />)*/}
                      {/*  }*/}
                      {/*</td>*/}
                      <td>{JSON.parse(callEffective) ? 'YES' : 'NO'}</td>
                      <td>{JSON.parse(ftdQualified) ? 'YES' : 'NO'}</td>
                      <td>{ftdAmount || '-'}</td>
                      <td>{ftdBonus || '-'}</td>
                      <td>{ftdId || '-'}</td>
                      <td>{ftdDate ? moment(ftdDate).format('LLL') : '-'}</td>
                    </tr>))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={this.paginationSelectHandle}
                >
                  {getPageRange(agentCommissionsPage, getPageCount(agentCommissionsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={this.paginationClickHandle}
                        data-page={agentCommissionsPage - 1}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={this.paginationClickHandle}
                        data-page={agentCommissionsPage + 1}
                      />
                    }

                    return <Pagination.Item
                      active={page === agentCommissionsPage}
                      key={page}
                      onClick={this.paginationClickHandle}
                      data-page={page}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkOneOfPermissions(permissions.conversion_report_read, permissions.own_conversion_report_read),
  uiMount(() => ['ui', 'agentCommissions']),
  predispatch((props) => props.uiDispatch(
    'Initialize ui/agent commissions report',
    (state) => ({
      ...state,
      ...(!state.agentCommissionsSort ? {
        agentCommissionsSort: {
          orderBy: 'callStarted',
          orderDirection: orderDirections.descending.key
        }
      } : {})
    }))),
  provideProps((state, uiState) => {
    const {
      agents,
      agentCommissions,
      agentCommissionsCount,
      agentName,
      config: {callRecordingsUri},
      agentsCommissionsFilter = {},
    } = state
    const {agentCommissionsSort, agentCommissionsPage = 1} = uiState
    return ({
      agents,
      agentCommissions,
      agentCommissionsCount,
      agentName,
      agentCommissionsSort,
      agentsCommissionsFilter,
      callRecordingsUri,
      agentCommissionsPage,
    })
  }),
  withRouter,
  mountDataProviders({agentCommissionsProvider})
)(AgentCommissions)
