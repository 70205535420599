import PropTypes from 'prop-types'
import {useState} from 'react'
import {Button} from 'react-bootstrap'

import {getCountryName} from '../../../useful'

import {AccountsTableContainer} from './components/AccountsTableContainer'
import {ApproveModal} from './components/ApproveModal'
import {Buttons} from './components/Buttons'
import {ClientName} from './components/ClientName'
import {RejectModal} from './components/RejectModal'
import {Table} from './components/Table'
import {useUnapprovedAccounts} from './queries/useUnapprovedAccounts'
import {useApproveAccount} from './mutations/useApproveAccount'
import {useRejectAccount} from './mutations/useRejectAccount'
import {FlavorContext} from './context'

export const AccountsTable = ({flavor}, {showNotification}) =>  {
  const {accounts, reexecuteQuery} = useUnapprovedAccounts(flavor)
  const {approveAccount} = useApproveAccount()
  const {rejectAccount} = useRejectAccount()
  const [approveShown, setApproveShown] = useState(false)
  const [rejectShown, setRejectShown] = useState(false)
  const [targetAccount, setTargetAccount] = useState(null)

  const username = `${targetAccount?.client?.lastName} ${targetAccount?.client?.firstName}`

  const handleApprove = async (account) => {
    setTargetAccount(account)
    setApproveShown(true)
  }

  const handleReject = async (account) => {
    setTargetAccount(account)
    setRejectShown(true)
  }

  const doApprove = async (flavor) => {
    setApproveShown(false)
    await approveAccount(targetAccount.id)
    reexecuteQuery({requestPolicy: 'network-only'})
    showNotification({
      title: 'Request Approved',
      message: `You have successfully approved the ${flavor === 'ib' ? 'IB' : 'PAMM' } Manager Account for ${username}`,
      position: 'tr',
      autoDismiss: 0,
      level: 'success',
    })
  }

  const doReject = async (flavor) => {
    setRejectShown(false)
    const error = await rejectAccount(targetAccount.id)
    reexecuteQuery({requestPolicy: 'network-only'})
    if (error) {
      showNotification({
        title: 'Error rejecting an account',
        message: `The request for an ${flavor === 'ib' ? 'IB' : 'PAMM'} Manager Account for ${username} was not rejected because of ${error}`,
        position: 'tr',
        autoDismiss: 0,
        level: 'error',
      })
    } else {
      showNotification({
        title: 'Account Rejected',
        message: `The request for an ${flavor === 'ib' ? 'IB' : 'PAMM'} Manager Account for ${username} was rejected`,
        position: 'tr',
        autoDismiss: 0,
        level: 'success',
      })

    }
  }

  return (
    <FlavorContext.Provider value={flavor}>
      <AccountsTableContainer>
        <Table>
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Country</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {accounts.map(account => (
              <tr key={account.id}>
                <td>{account.id}</td>
                <td>
                  <ClientName client={account.client} />
                </td>
                <td>{getCountryName(account.client.address)}</td>
                <td>
                  <Buttons>
                    <Button size="sm" variant="outline-secondary" onClick={() => handleReject(account)}>
                      Reject
                    </Button>
                    <Button size="sm" variant="success" onClick={() => handleApprove(account)}>
                      Approve
                    </Button>
                  </Buttons>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <ApproveModal show={approveShown} onClose={() => setApproveShown(false)} onSubmit={() => doApprove(flavor)} username={username} />
        <RejectModal show={rejectShown} onClose={() => setRejectShown(false)} onSubmit={() => doReject(flavor)} username={username} />
      </AccountsTableContainer>
    </FlavorContext.Provider>
  )
}

AccountsTable.contextTypes = {
  showNotification: PropTypes.func.isRequired,
}
