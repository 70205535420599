import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {setIn} from '../stateUtils'
import {isEmpty, isNil} from 'lodash'
import {provideProps} from '../decorators'
import {countries, companies, yesNo} from '@bdswiss/common-enums'
import {Col, Form, Row, Card, Button, ButtonToolbar} from 'react-bootstrap'
import style from './seminars.module.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import StylishSelect from '../components/StylishSelect'

class SeminarForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    seminarProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const state = {
      form: this.props.data ? this.props.data : {},
      formError: {},
      formState: {},
    }
    this.setState(state)
  }

  formValueChanged(name, value) {
    let {state} = this
    if (name === 'type') {
      state = setIn(state, ['form', 'value'], '')
    }
    state = setIn(state, ['form', name], value)
    state = setIn(state, ['formError', name], null)
    this.setState(state)
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  validateForm() {
    const errors = {}
    const {form} = this.state

    if (!form.seminarCode) {
      errors.seminarCode = true
    }
    if (!form.seminarName) {
      errors.seminarName = true
    }
    if (!form.seminarLink) {
      errors.seminarLink = true
    }
    if (!form.seminarDate) {
      errors.seminarDate = true
    }
    if (!form.location) {
      errors.location = true
    }
    if (!form.city) {
      errors.city = true
    }
    if (!form.country) {
      errors.country = true
    }
    if (!form.company) {
      errors.company = true
    }
    if (isNil(form.ibSeminar)) {
      errors.ibSeminar = true
    }
    this.setFormErrors(errors)
    return isEmpty(errors)
  }

  formAction = () => {
    const {form} = this.state
    const updatedForm = {
      ...form,
      seminarDate: moment(form.seminarDate),
    }
    return this.props.actions.seminars.upsertSeminar(updatedForm)
  }

  close = () => {
    this.context.router.push('/seminars')
  }

  renderButton = () => {
    const {state, state: {form: {id}, formState: {isLoading}}, context: {seminarProvider}} = this

    return (
      <Row>
        <Col xs={12}>
          <ButtonToolbar className="float-right">
            <Button
              id="t-seminars-editor-cancel-button"
              onClick={this.close}
              variant="outline-secondary"
              className="mr-3"
            >
              Cancel
            </Button>
            <Button
              id="t-seminars-editor-create-update-button"
              variant="success"
              disabled={isLoading}
              onClick={() => {
                const ok = this.validateForm()
                if (ok) {
                  if (!isLoading) {
                    this.setState(setIn(state, ['formState', 'isLoading'], true))
                    this.formAction()
                      .then((res) => {
                        this.context.router.push('/seminars')
                        seminarProvider && seminarProvider.fetch()
                      })
                      .catch((e) => {
                        this.setState(setIn(state, ['formState', 'isLoading'], false))
                        this.context.logError(e)
                      })
                  }
                }
              }}
            >
              {id ? 'Update' : 'Create'}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    )
  }

  render() {
    const {form: {seminarCode, seminarName, seminarLink, seminarDate, location, ibSeminar,
      city, country, company}, formError} = this.state
    return (
      <Card>
        <Card.Body>
          <Row>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>Seminar Code*</Form.Label>
                <Form.Control
                  id="t-seminars-form-code"
                  type="input"
                  value={seminarCode}
                  isInvalid={formError.seminarCode}
                  onChange={(evt) => this.formValueChanged('seminarCode', evt.target.value)}
                  placeholder="Enter seminar code"
                />
              </Form.Group>
            </Col>
            <Col xs={5}>
              <Form.Group>
                <Form.Label>Seminar Name*</Form.Label>
                <Form.Control
                  id="t-seminars-form-name"
                  type="input"
                  value={seminarName}
                  isInvalid={formError.seminarName}
                  onChange={(evt) => this.formValueChanged('seminarName', evt.target.value)}
                  placeholder="Enter seminar title"
                />
              </Form.Group>
            </Col>
            <Col xs={5}>
              <Form.Group>
                <Form.Label>Seminar Link*</Form.Label>
                <Form.Control
                  id="t-seminars-form-link"
                  type="input"
                  value={seminarLink}
                  isInvalid={formError.seminarLink}
                  onChange={(evt) => this.formValueChanged('seminarLink', evt.target.value)}
                  placeholder="Enter seminar link"
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <label>Seminar Date</label>
              <DateTime
                id="t-seminars-date"
                timeFormat={true}
                onChange={(evt) => this.formValueChanged('seminarDate', evt.format('llll'))}
                value={seminarDate && moment(seminarDate)}
                closeOnSelect
                className={style.datetime}
                bsStyle={formError.seminarDate && 'error'}
              />
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label>Seminar Address*</Form.Label>
                <Form.Control
                  id="t-seminars-form-location"
                  type="input"
                  value={location}
                  isInvalid={formError.location}
                  onChange={(evt) => this.formValueChanged('location', evt.target.value)}
                  placeholder="Enter seminar full address"
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <Form.Group>
                <Form.Label>City*</Form.Label>
                <Form.Control
                  id="t-seminars-form-city"
                  type="input"
                  value={city}
                  isInvalid={formError.city}
                  onChange={(evt) => this.formValueChanged('city', evt.target.value)}
                  placeholder="Enter seminar city"
                />
              </Form.Group>
            </Col>
            <Col xs={2}>
              <StylishSelect.Input
                id="t-seminars-form-country"
                label="Country *"
                value={country}
                onChange={(evt) => this.formValueChanged('country', evt.value)}
                placeholderText="Select country"
                options={StylishSelect.enumToStylishOptions(countries)}
                isInvalid={formError.country}
              />
            </Col>
            <Col xs={2}>
              <StylishSelect.Input
                id="t-seminars-form-company"
                label="Company *"
                value={company}
                onChange={(evt) => this.formValueChanged('company', evt.value)}
                placeholderText="Select company"
                options={StylishSelect.enumToStylishOptions(companies)}
                isInvalid={formError.company}
              />
            </Col>
            <Col xs={2}>
              <StylishSelect.Input
                id="t-seminars-form-ib-seminar"
                label="IB Seminar"
                value={ibSeminar ? yesNo.yes.value : ibSeminar !== undefined ? yesNo.no.value : ''}
                onChange={(evt) => this.formValueChanged('ibSeminar', evt.value === yesNo.yes.value)}
                placeholderText="IB Seminar"
                options={StylishSelect.enumToStylishOptions(yesNo)}
                isInvalid={formError.ibSeminar}
              />
            </Col>
          </Row>
          {this.renderButton()}
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(SeminarForm)
