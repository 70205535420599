import moment from 'moment'
import {isEmpty} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

function initCommunications(communications) {
  for (const communication of communications) {
    communication.createdAt = moment(communication.createdAt)
    communication.createdAt.isValid() // set _isValid property now
  }
  return communications
}

export const communicationsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {communicationsSearch, communicationsChannelsFilter, communicationsTypesFilter, dateFilters,
      communicationsDepartmentsFilter, communicationsCompaniesFilter, communicationsSalesAgentFilter,
      communicationsExcludeAutoEmailsFilter,
    } = props
    const conditions = [
      communicationsSearch ? `search: "${communicationsSearch}"` : '',
      !isEmpty(communicationsChannelsFilter) ? `channels: [${communicationsChannelsFilter}]` : '',
      !isEmpty(communicationsTypesFilter) ? `types: [${communicationsTypesFilter}]` : '',
      !isEmpty(communicationsDepartmentsFilter) ? `departments: [${communicationsDepartmentsFilter}]` : '',
      dateFilters && dateFilters.createdFrom ? `creationTimeFrom: "${dateFilters.createdFrom.toISOString()}"` : '',
      dateFilters && dateFilters.createdTo ? `creationTimeTo: "${dateFilters.createdTo.toISOString()}"` : '',
      !isEmpty(communicationsCompaniesFilter) ? `companies: [${communicationsCompaniesFilter}]` : '',
      communicationsSalesAgentFilter ? `salesAgentId: ${communicationsSalesAgentFilter}` : '',
      communicationsExcludeAutoEmailsFilter ? 'excludeAutomatedEmails: true' : ''
    ].join('\n').trim()

    return `{
      communications (
        ${conditions}
        limit: ${getPageSize()}
        offset: ${getOffset(props.communicationsPage)}
        orderBy: createdAt
        orderDirection: descending
      )
      {
        id
        channels
        type
        department
        title
        summary
        meta
        createdAt
        foreignTable
        foreignId
        client {
          id
          firstName
          lastName
          salesAgent {
            id
            firstName
            lastName
          }
        }
      }
      communicationsCount(estimated: false ${conditions})
    }`
  },

  onData: (res, dispatch) => dispatch(
    'Communications loaded',
    (state, res) => ({
      ...state,
      communications: res.communications != null
        ? initCommunications(res.communications)
        : state.communications || [],
      communicationsCount: res.communicationsCount != null
        ? res.communicationsCount
        : state.communicationsCount || 0,
    }),
    [res]
  ),

}
