import {toGraphQlParamsDeclaration} from '../useful'

function addPixelField(name, type, value, pixelIndx, pixelsTypes, pixelsVariables, queryFields) {
  pixelsTypes[`$${name}_${pixelIndx}`] = type
  pixelsVariables[`${name}_${pixelIndx}`] = value
  queryFields.push(`${name}: $${name}_${pixelIndx}`)
}

function pixelsTypesAndQuery(pixels) {
  const pixelsTypes = {}, pixelsVariables = {}, pixelsQueries = []

  if (!pixels) {
    return {pixelsTypes, pixelsVariables, pixelsQueries}
  }

  pixels.forEach((pixel, index) => {
    //todo: params (TrackingParameterInput) were not changed to variables yet
    const queryFields = []
    const args = [pixelsTypes, pixelsVariables, queryFields]

    addPixelField('id', 'Int', pixel.id, index, ...args)

    addPixelField('name', 'String', pixel.name, index, ...args)
    addPixelField('eventName', 'EventName', pixel.eventName, index, ...args)
    addPixelField('productName', 'productNameType', pixel.productName, index, ...args)
    addPixelField('subcampaignKeyName', 'String', pixel.subcampaignKeyName, index, ...args)
    addPixelField('userIdKeyName', 'String', pixel.userIdKeyName, index, ...args)
    addPixelField('type', 'PixelType', pixel.type, index, ...args)
    addPixelField('baseUrl', 'String', pixel.baseUrl, index, ...args)
    addPixelField('params', '[TrackingParameterInput]', pixel.params, index, ...args)

    pixelsQueries.push(`{ ${queryFields.join(',\n')} }`)
  })

  return {pixelsTypes, pixelsVariables, pixelsQueries}
}

export default function create(dbClient, dispatch) {
  return {
    upsertCampaign(id, params) {
      const {name,
        country,
        cookieTimeout,
        budget,
        verifyRequired,
        start,
        end,
        type,
        minimumDeposits,
        ftdBonusMinimumDeposits,
        redepositBonusMinimumDeposits,
        ftdBonusMinimumDepositsVIP,
        redepositBonusMinimumDepositsVIP,
        ftdBonusMinimumDepositsRAW,
        redepositBonusMinimumDepositsRAW,
        kycThreshold,
        clientId,
        showWelcomePage,
        redirectWebTraderFtd,
        affiliateGroup,
        avoidPhoneVerification,
        emailVerification,
        noCall,
        minimumDepositsAccountType,
        accountTypes,
        redepositBonusPercentage,
        ftdBonusPercentage,
        vipDepositMinimum,
        redepositBonusPercentageVIP,
        ftdBonusPercentageVIP,
        rawDepositMinimum,
        redepositBonusPercentageRAW,
        ftdBonusPercentageRAW,
        globalBonusLimit,
        promoCode
      } = params

      const {pixelsTypes, pixelsVariables, pixelsQueries} = pixelsTypesAndQuery(params.pixels)
      const variables = {
        id,
        affiliateGroup,
        avoidPhoneVerification,
        emailVerification,
        noCall,
        name,
        country,
        cookieTimeout,
        budget,
        verifyRequired,
        start,
        end,
        minimumDeposits,
        ftdBonusMinimumDeposits,
        redepositBonusMinimumDeposits,
        ftdBonusMinimumDepositsVIP,
        redepositBonusMinimumDepositsVIP,
        ftdBonusMinimumDepositsRAW,
        redepositBonusMinimumDepositsRAW,
        type,
        clientId,
        showWelcomePage,
        redirectWebTraderFtd,
        kycThreshold,
        ...pixelsVariables,
        minimumDepositsAccountType,
        accountTypes,
        ftdBonusPercentage: ftdBonusPercentage ?? null,
        redepositBonusPercentage: redepositBonusPercentage ?? null,
        vipDepositMinimum: vipDepositMinimum ?? null,
        ftdBonusPercentageVIP: ftdBonusPercentageVIP ?? null,
        redepositBonusPercentageVIP: redepositBonusPercentageVIP ?? null,
        rawDepositMinimum: rawDepositMinimum ?? null,
        ftdBonusPercentageRAW: ftdBonusPercentageRAW ?? null,
        redepositBonusPercentageRAW: redepositBonusPercentageRAW ?? null,
        globalBonusLimit: globalBonusLimit ?? null,
        ...(promoCode ? {promoCode} : {})
      }

      const query = `
        mutation (
          $id: Int,
          $name: String,
          $country: String,
          $cookieTimeout: Int,
          $budget: Int,
          $verifyRequired: Boolean,
          $start: DateTime,
          $end: DateTime,
          $type: CampaignType,
          $clientId: Int,
          $kycThreshold: Int,
          $showWelcomePage: Boolean,
          $redirectWebTraderFtd: Boolean,
          $avoidPhoneVerification: Boolean,
          $emailVerification: Boolean,
          $noCall: Boolean,
          $minimumDeposits: [MinimumDepositInput],
          $ftdBonusMinimumDeposits: [FtdBonusMinimumDepositInput],
          $redepositBonusMinimumDeposits: [RedepositBonusMinimumDepositInput],
          $ftdBonusMinimumDepositsVIP: [FtdBonusMinimumDepositVIPInput],
          $redepositBonusMinimumDepositsVIP: [RedepositBonusMinimumDepositVIPInput],
          $ftdBonusMinimumDepositsRAW: [FtdBonusMinimumDepositRAWInput],
          $redepositBonusMinimumDepositsRAW: [RedepositBonusMinimumDepositRAWInput],
          $minimumDepositsAccountType: [MinimumDepositAccountTypeInput],
          ${toGraphQlParamsDeclaration(pixelsTypes)}
          $affiliateGroup: String,
          $accountTypes: [String],
          $ftdBonusPercentage: Int,
          $redepositBonusPercentage: Int,
          $vipDepositMinimum: Int,
          $ftdBonusPercentageVIP: Int,
          $redepositBonusPercentageVIP: Int,
          $rawDepositMinimum: Int,
          $ftdBonusPercentageRAW: Int,
          $redepositBonusPercentageRAW: Int,
          $globalBonusLimit: Int,
          $promoCode: String
        ) {
          upsertCampaign(
            id: $id,
            name: $name,
            country: $country,
            cookieTimeout: $cookieTimeout,
            budget: $budget,
            verifyRequired: $verifyRequired,
            start: $start,
            end: $end,
            type:$type,
            clientId:$clientId,
            showWelcomePage: $showWelcomePage,
            redirectWebTraderFtd: $redirectWebTraderFtd,
            avoidPhoneVerification: $avoidPhoneVerification,
            emailVerification: $emailVerification,
            noCall: $noCall,
            kycThreshold:$kycThreshold,
            minimumDeposits: $minimumDeposits,
            ftdBonusMinimumDeposits: $ftdBonusMinimumDeposits,
            redepositBonusMinimumDeposits: $redepositBonusMinimumDeposits,
            ftdBonusMinimumDepositsVIP: $ftdBonusMinimumDepositsVIP,
            redepositBonusMinimumDepositsVIP: $redepositBonusMinimumDepositsVIP,
            ftdBonusMinimumDepositsRAW: $ftdBonusMinimumDepositsRAW,
            redepositBonusMinimumDepositsRAW: $redepositBonusMinimumDepositsRAW,
            minimumDepositsAccountType: $minimumDepositsAccountType,
            ${pixelsQueries ? `pixels: [${pixelsQueries.join(', ')}]` : ''}
            affiliateGroup: $affiliateGroup,
            accountTypes: $accountTypes,
            ftdBonusPercentage: $ftdBonusPercentage,
            redepositBonusPercentage: $redepositBonusPercentage,
            vipDepositMinimum: $vipDepositMinimum,
            ftdBonusPercentageVIP: $ftdBonusPercentageVIP,
            redepositBonusPercentageVIP: $redepositBonusPercentageVIP,
            rawDepositMinimum: $rawDepositMinimum,
            ftdBonusPercentageRAW: $ftdBonusPercentageRAW,
            redepositBonusPercentageRAW: $redepositBonusPercentageRAW,
            globalBonusLimit: $globalBonusLimit,
            promoCode: $promoCode
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
  }
}
