import PureComponent from '../PureComponent'
import {Button, Card, Col, Container, Row, Table} from 'react-bootstrap'
import {checkRights, compose, mountDataProviders, predispatch, provideProps, uiMount} from '../decorators'
import {canQueryRegistrationsReport} from '@bdswiss/common-permissions'
import {orderDirections} from '@bdswiss/common-enums'
import {debounce, merge} from 'lodash'
import {registrationsReportProvider} from './providers'
import style from '../firsttimedeposits/reportFtd.module.scss'
import DateTime from '../components/DateTime'
import moment from 'moment'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {events} from '../enums'
import PropTypes from 'prop-types'
import StylishSelect from '../components/StylishSelect'
// import React from '@types/react'

const ALL_COMPANIES = 'All'
const generateDownloadLink = (backendUrl, company, reportDateStart, reportDateEnd) => {
  let link = `${backendUrl}/api/v1/reports/report?type=registrations`

  if (company && company !== ALL_COMPANIES) {
    link += `&company=${company}`
  }
  if (reportDateStart) {
    link += `&reportDateStart=${reportDateStart}`
  }
  if (reportDateEnd) {
    link += `&reportDateEnd=${reportDateEnd}`
  }

  return link
}

class RegistrationsReport extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
  }

  static contextTypes = {
    registrationsReportProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilter = {
      date: moment().format('YYYY-MM'),
    }

    const companyNameFilter = this.props.companyNameFilter ?? ALL_COMPANIES

    const stateReportSort = this.props.reportSort || {
      orderBy: 'date', orderDirection: orderDirections.descending.key, // 'id'
    }

    this.setState({
      sorting: {}, dateFilter: stateDateFilter, reportSort: stateReportSort, companyNameFilter,
    })
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.registrationsReportProvider.fetch()
  }

  handleDateFilterChanged(name, momentValue, value) {
    const dateFilter = {
      [name]: value
    }
    this.setState({dateFilter})

    if (!momentValue || moment.isMoment(momentValue)) {
      this.doDateFilterSearch(dateFilter)
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch({date}) {
    const dateFilter = {
      reportDateStart: moment(date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
      reportDateEnd: moment(date, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
    }
    this.props.uiDispatch('Filter report by dates', (state) => ({...state, dateFilter}), [dateFilter])
  }

  doSortByOrderAndOrderDirection(field) {
    const {reportSort} = this.props
    const {orderBy, orderDirection} = reportSort

    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting report by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      reportSort: newSort
    }), [reportSort])
  }

  getSortArrow(field) {
    const {reportSort} = this.props
    const arrowDirection = reportSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (reportSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  filterReportsByCompanyName(companyNameFilter) {
    this.setState( {companyNameFilter: companyNameFilter} )
    this.props.uiDispatch('Filter report by company name', (state) => ({...state, companyNameFilter}), [companyNameFilter])
  }

  render() {
    const {registrationsReport, companies, companyNameFilter} = this.props
    const {dateFilter} = this.state
    const {backendUrl} = this.context.config

    const companiesOptions = [ALL_COMPANIES, ...companies].map(company => ({
      label: company,
      value: company
    }))

    return (<Container>
      <h3>Registrations Report</h3>
      <span className={style.label}>Date</span>
      <Row key="filter-row-1" className={style.filter}>
        <Col xs={6}>
          <Row>
            <Col xs={4}>
              <div>
                <DateTime
                  id="t-registrations-date-filter"
                  timeFormat={false}
                  dateFormat="YYYY-MM"
                  onChange={(e) => this.handleDateFilterChanged('date', e, moment.isMoment(e) ? e.format('YYYY-MM') : '')}
                  value={dateFilter.date}
                  onFocus={() => this.doDateFilterSearch.cancel()}
                  closeOnSelect
                  className={style.datetime}
                />
              </div>
            </Col>
            <Col xs={4}>
              <StylishSelect.Input
                value={this.state.companyNameFilter}
                options={companiesOptions}
                onChange={(e) => this.filterReportsByCompanyName(e.value)}
              />
            </Col>
          </Row>
        </Col>

        <Col xs={1}>
          <div style={{textAlign: 'center'}}>
            <Button
              href={generateDownloadLink(backendUrl, companyNameFilter, this.props.dateFilter.reportDateStart, this.props.dateFilter.reportDateEnd)}
              id={'t-registrations-export'}
              variant="success"
              style={{width: '100%'}}
              disabled={!registrationsReport.length}
            >
              Download
            </Button>
          </div>
        </Col>

      </Row>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body style={{overflowX: 'scroll', minHeight: 300}}>
              <Table bordered hover className={style.table}>
                <thead>
                  <tr style={{textAlign: 'center'}}>
                    <th style={{minWidth: 40}}>
                      <span>Count</span>
                      <FontAwesomeIcon icon={this.getSortArrow('count')}
                        onClick={() => this.doSortByOrderAndOrderDirection('count')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Date</span>
                      <FontAwesomeIcon icon={this.getSortArrow('date')}
                        onClick={() => this.doSortByOrderAndOrderDirection('date')}/>
                    </th>

                    {companies.length > 1 &&
                      <th style={{minWidth: 100}}>
                        <span>Company</span>
                      </th>
                    }
                  </tr>
                </thead>
                <tbody>

                  {registrationsReport.map((report) => {
                    const {count, date, company} = report
                    const id = date + company
                    return (<tr
                      key={id}
                      id={id}
                      style={{minWidth: 100, textAlign: 'center'}}
                    >
                      <td>{count}</td>
                      <td>{date}</td>
                      {companies.length > 1 &&
                        <td>{company}</td>
                      }
                    </tr>)
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>)

  }
}

export default compose(checkRights(canQueryRegistrationsReport),

  uiMount((state) => ['ui', 'registrationsReport']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/registrations report',
    (state) => {
      const dateFilter = {
        reportDateStart: moment().startOf('month').format('YYYY-MM-DD'),
        reportDateEnd: moment().endOf('month').format('YYYY-MM-DD')
      }
      let reportSort = state && state.reportSort
      if (!reportSort) {
        reportSort = {orderBy: 'date', orderDirection: orderDirections.descending.key}
      }

      return merge({dateFilter, reportSort}, state)
    })),


  provideProps((state, uiState) => {
    const {registrationsReport, viewer: {companies}} = state
    const {dateFilter, reportSort, companyNameFilter} = uiState
    return {registrationsReport, dateFilter, reportSort, companies, companyNameFilter}
  }),

  mountDataProviders({registrationsReportProvider}),)(RegistrationsReport)
