import {orderDirections} from '@bdswiss/common-enums'
import {getFetchInterval, getOffset, getPageSize} from '../useful'
import {orderByFields} from '../common/utils'

export const workersActivityProvider = {
  fetchInterval: getFetchInterval,
  getQuery: (props) => {
    const {orderByFilter, orderDirectionFilter, workersPage, workerNameFilter} = props
    const orderBy = orderByFilter ? `orderBy: ${orderByFields[orderByFilter].key}` : 'orderBy: id'
    const orderDirection = orderDirectionFilter ? `orderDirection: ${orderDirections[orderDirectionFilter].key}` : 'orderDirection: ascending'
    const workerName = workerNameFilter ? `workerName: "${workerNameFilter}"` : ''

    // limit:  ${getPageSize()}
    const query = `{
      workersActivity(
        limit: ${getPageSize()}
        offset: ${getOffset(workersPage)}
        ${orderBy}
        ${orderDirection}
        ${workerName}
      ) {
        name
        startedAt
        finishedAt
      }
      workersActivityCount(workerName: "${workerNameFilter}")
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Workers activity loaded',
    (state, res) => ({
      ...state,
      workersActivity: res.workersActivity || [],
      workersActivityCount: res.workersActivityCount || 0,
    }),
    [res]
  ),
}
