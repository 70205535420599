import React from 'react'
import {Button, Card} from 'react-bootstrap'
import moment from 'moment'
import classNames from 'classnames'
import PureComponent from '../PureComponent'
import style from './appointments.module.scss'

class Calendar extends PureComponent {

  render() {
    const {appointmentsByDay = [], onSelect} = this.props
    return (
      <Card>
        <Card.Body>
          <ul className="list-unstyled">
            {appointmentsByDay.map(({day, count, dismissed}) =>
              <li key={day}>
                <Button
                  className={style.listItem}
                  variant="link"
                  size="sm"
                  onClick={() => onSelect(day)}
                >
                  {moment(day).format('DD/MM/YYYY')} | {count} Reminder(s)
                  {dismissed !== count && <span className={classNames(style.dot, style.red)}>&nbsp;</span>}
                </Button>
              </li>
            )}
          </ul>
        </Card.Body>
      </Card>
    )
  }
}

export default Calendar
