import {whiteLabels} from '@bdswiss/common-enums'

function json(string) {
  try {
    return JSON.parse(string)
  } catch {
    return {}
  }
}

const releaseVersion = 'development'
// this creates es-lint problems
// try {
//
//   const  build = require('./build.json')
//   releaseVersion = build.buildData.split(',')[1]
// } catch (e) {}

export function getRuntimeBaseDomain() {
  const domain = window.location.host
    .replace(':' + window.location.port, '')
    .split('.')
    .slice(1)
    .join('.')
  return domain
}
/**
 * This is needed to have more than one Vox working properly.
 * Config is not tailored to work under multiple domains.
 * As a temporary solution BASE_DOMAIN is set to non-existent domain and then replaced in runtime.
 */
function getEnvWithRuntimeDomain() {
  const runtimeBaseDomain = getRuntimeBaseDomain()
  const newEnv = {}
  const envKeys = Object.keys(process.env)
  for (const key of envKeys) {
    let val = process.env[key]
    // We need key.includes check because currently all client white labeled apps (dashboard / website)
    // are managed from the same CRM instance, so if link points to the specific
    // white labeled app, then the domain shouldn't be replaced with runtime domain.
    if (typeof process.env[key] === 'string' && !key.includes('_white_label_')) {
      val = process.env[key].replace(process.env.REACT_APP_base_domain, runtimeBaseDomain)
    }
    newEnv[key] = val
  }
  return newEnv
}

const config = (env) => ({
  backendUrl: env.REACT_APP_bd_vox_backend_url,
  frontendUrl: env.REACT_APP_bd_vox_frontend_url,
  whiteLabels: {
    tauroMarkets: {frontendUrl: env.REACT_APP_tauromarkets_white_label_frontend_url},
    tauroMarketsMauritius: {frontendUrl: env.REACT_APP_tauromarkets_mau_white_label_frontend_url},
  },
  showStageWarning: env.REACT_APP_ENV === 'staging',
  minimumFirstTimeDeposit: json(env.REACT_APP_bd_minimum_first_time_deposit), // JSON
  minimumSubsequentDeposit: json(env.REACT_APP_bd_minimum_subsequent_deposit),
  callRecordingsUri: {
    impactech: env.REACT_APP_bd_call_recordings_uri,
    didlogic: env.REACT_APP_bd_didlogic_call_recordings_uri,
  },
  tableauReports: {
    showTableauReports: env.REACT_APP_bd_show_tableau_reports,
    urls: {
      overallSalesRetention: env.REACT_APP_bd_tableau_overall_sales_retention_report_url,
      salesRetention: env.REACT_APP_bd_tableau_sales_retention_report_url,
      tradingOverviewReport: env.REACT_APP_bd_tableau_trading_overview_url,
    },
  },
  gbgAutoCheckEnabledCountries: env.REACT_APP_bd_gdc_supported_countries.split(','),
  eIdVCheckCountries: env.REACT_APP_bd_gdc_supported_countries.split(','),
  appVerisonInfo: {
    releaseVersion,
    showVersionUpdateNotification: env.REACT_APP_bd_show_update_notifications === 'true',
  },
  allowForceLogin: env.NODE_ENV === 'development' || env.REACT_APP_ENV === 'staging',
  debug: env.REACT_APP_bd_debug === 'true',
  ticketBaseUrl: env.REACT_APP_bd_ticket_base_url,
  defaultCompetitionSymbols: env.REACT_APP_default_competitions_symbols,
})

// This wrapper is needed for future when we will have different features depending on the domain
let instance = null
const runtimeConfig = {
  getInstance: () => {
    if (instance) {
      return instance
    }
    const env = getEnvWithRuntimeDomain()
    instance = config(env)
    return instance
  },
}
export default runtimeConfig
