import moment from 'moment'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

function initJobs(jobs) {
  for (const job of jobs) {
    job.createdAt = moment(job.createdAt)
    job.completedAt = job.completedAt != null ? moment(job.completedAt) : job.completedAt
    job.startedAt = job.startedAt != null ? moment(job.startedAt) : job.startedAt
    job.creator = `${job.createdBy.firstName} ${job.createdBy.lastName}`
    if (job.meta != null) {
      const meta = JSON.parse(job.meta)
      job.inputUrl = meta.remoteInputPath
      job.outputUrl = meta.remoteLogPath
      job.rawInputUrl = meta.rawInputRemotePath
    }
  }
  return jobs
}

export const dormantFeejobsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {page} = props
    const types = ['cuboidDormantFee', 'forexDormantFee', 'spotOptionDormantFee', 'tradeSmarterDormantFee', 'affiliateCommissions']
    const query =
      `{
        jobs(
          limit: ${getPageSize()}
          offset: ${getOffset(page)}
          orderBy: id
          orderDirection: descending
          types: [${types}]
        ) {
          id
          type
          action
          status
          startedAt
          completedAt
          meta
          createdBy {
            firstName
            lastName
          }
        }
        jobsCount
      }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Dormant Fee Jobs loaded',
    (state, res) => ({
      ...state,
      dormantFeeJobs: res.jobs ? initJobs(res.jobs) : state.jobs || [],
      dormantFeeJobsCount: res.jobsCount || state.jobsCount || 0,
    }),
    [res]
  ),

}
