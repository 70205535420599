/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {orderDirections} from '@bdswiss/common-enums'
import {canQueryTags} from '@bdswiss/common-permissions'
import {Row, Col, Table, Button, Card, Pagination, Form, Container, InputGroup} from 'react-bootstrap'
import style from './tags.module.scss'
import {events} from '../enums'
import TagCreate from './TagCreate'
import PureComponent from '../PureComponent'
import {tagCategoriesSearchProvider} from './providers'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {readableDate, getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class TagCategories extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  UNSAFE_componentWillMount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.tagCategoriesSearchProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    tagCategoriesSearchProvider: PropTypes.object.isRequired,
  }

  linkToTag(content, id) {
    return (
      <LinkContainer to={`/tag-categories/${id}`}>
        <a>{content !== undefined ? content : ''}</a>
      </LinkContainer>
    )
  }

  renderTag(tag) {
    const {id, name, createdAt} = tag
    return (
      <tr className="t-tag" key={id}>
        <td>
          {this.linkToTag(id, id)}
        </td>
        <td>
          {this.linkToTag(name, id)}
        </td>
        <td>
          {this.linkToTag(readableDate(moment(createdAt)), id)}
        </td>
      </tr>
    )
  }

  doTagCategoriesSearch(searchText) {
    this.props.dispatch(
      'Search tag',
      (state) => ({...state, tagCategoriesSearch: searchText, tagCategoriesPage: 1})
    )
    this.setState({searchText: searchText})
  }

  toggleDisplayAddTag(displayForm) {
    this.props.uiDispatch('Displaying Add Tag', (state) => ({...state, displayAddTag: displayForm, displayAddLeadErr: false}))
  }

  closeAddLead() {
    this.toggleDisplayAddTag(false)
  }

  render() {
    const {uiState, tagCategories, tagCategoriesCount, tagCategoriesPage, tagCategoriesSearch} = this.props
    const {searchText} = this.state || {}
    const createTagClass = uiState.displayAddTag ?
      `${style.addCreateTag} ${style.addTagOpen}` : style.addCreateTag
    let addLeadErrClass = 'bs-callout bs-callout-danger '
    addLeadErrClass += uiState.displayAddLeadErr && uiState.displayAddLead ? '' : 'd-none'

    return (
      <Container>
        <div className={style.toolbar}>
          <Row>
            <Col md={10} sm={9} xs={12}>
              <InputGroup>
                <Form.Control
                  id="t-list-tags-search"
                  type="text"
                  value={searchText !== undefined ? searchText : tagCategoriesSearch || ''}
                  placeholder="Search by ID, Name"
                  onChange={(e) => this.setState({searchText: e.target.value})}
                  onKeyUp={(e) => (
                    (e.key === 'Enter' && this.doTagCategoriesSearch(searchText)) ||
                    (e.key === 'Escape' && this.doTagCategoriesSearch(''))
                  )}
                />
                <InputGroup.Append>
                  {tagCategoriesSearch && <Button
                    key={1}
                    title="Clear"
                    variant={tagCategoriesSearch ? 'success' : 'outline-dark'}
                    onClick={() => this.doTagCategoriesSearch('')}
                  >
                    <FontAwesomeIcon icon="times" />
                  </Button>}
                  <Button
                    key={2}
                    title="Search"
                    variant={tagCategoriesSearch ? 'success' : 'outline-dark'}
                    onClick={() => this.doTagCategoriesSearch(searchText)}
                  >
                    <FontAwesomeIcon icon="search" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col md={2} sm={3} xs={12}>
              <Button
                id="t-tags-new-tag-button"
                className="float-right"
                variant="success"
                onClick={() => this.toggleDisplayAddTag(true)}
              >
                New Tag
              </Button>
            </Col>
          </Row>
        </div>
        <br />
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>id</th>
                      <th className={style.largeColumn}>Tag Category</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody id="t-list-tags">
                    {tagCategories && tagCategories.map((tag) => this.renderTag(tag))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, tagCategoriesPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(tagCategoriesPage, getPageCount(tagCategoriesCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, tagCategoriesPage: tagCategoriesPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, tagCategoriesPage: tagCategoriesPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === tagCategoriesPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, tagCategoriesPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={createTagClass}>
          <div className={style.addTag}>
            <div className={style.addTagHeader}>
              <FontAwesomeIcon icon="tag" className={style.addTagIcon} /> Add New Tag
            </div>
            <div className={addLeadErrClass} >
              <h4>
                <FontAwesomeIcon icon="info-circle" />
                &nbsp;An error occured while adding a tag
              </h4>
              <p>{uiState.displayAddLeadErrMsg}</p>
            </div>
            <TagCreate
              tagCategories={this.props.tagCategories}
              onSave={this.toggleDisplayAddTag.bind(this)}
              onCancel={this.closeAddLead.bind(this)}
            />
            <hr />
          </div>
        </div>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryTags),

  uiMount((state) => ['ui', 'tagCategories']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/tagCategories',
      (state) => {
        let tagCategoriesSort
        if (!tagCategoriesSort || tagCategoriesSort.orderBy == null
            || tagCategoriesSort.orderDirection == null
            || !(tagCategoriesSort.orderDirection in orderDirections)) {
          tagCategoriesSort = defaultSort
        }

        return ({...state, tagCategoriesSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {tagCategoriesSearch, tagCategories, tagCategoriesCount} = state
    const {tagCategoriesPage, tagCategoriesSort} = uiState

    return ({
      tagCategoriesSearch,
      tagCategories,
      tagCategoriesCount,
      tagCategoriesPage: tagCategoriesPage || 1,
      tagCategoriesSort,
    })
  }),

  mountDataProviders({tagCategoriesSearchProvider}),
)(TagCategories)
