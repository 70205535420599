import React from 'react'
import PropTypes from 'prop-types'
import {List as Loading} from 'react-content-loader'
import {ButtonToolbar, Button, Form, Row, Col, Card} from 'react-bootstrap'
import {filter, isEmpty, reject, find, get, isObject, has, pick} from 'lodash'
import {canCreateDeposits} from '@bdswiss/common-permissions'
import {isNumeric, isPositiveNumber} from '../useful'
import {accountTypes, depositVendors, bonusOfferTypes, bankWireBanks} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import PureComponent from '../PureComponent'
import {getAccountLabel} from '../utils/general'
import {safeParseJSON} from '../common/utils'
import DateTime from '../components/DateTime'

export default provideProps()(class CreateDeposit extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    accounts: PropTypes.array,
    availablePsps: PropTypes.array,
    onClose: PropTypes.func.isRequired,
  };

  componentWillMount() {
    const state = {values: {}, errors: {}, depositOptionsEnabled: false}

    // if there is only one accountId option, prefill it
    if (this.props.accounts.length === 1) {
      state.values.accountId = this.props.accounts[0].id
    }

    // if there is only one deposit type option, prefill it
    const depositTypes = this.getDepositTypes()
    if (depositTypes.length === 1) {
      state.values.depositVendor = depositTypes[0].key
    }
    this.setState(state)
  }

  componentDidMount() {
    const {dataFetched, dataLoading, dataError} = this.state
    if (!dataLoading && !dataError && !dataFetched && isEmpty(this.props.accounts)) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.depositAccounts.fetch()
          .then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  getDepositTypes(account) {
    const {availablePsps} = this.props
    const filteredDepositVendors = pick(depositVendors, availablePsps)
    const isDemoAccount = account && get(accountTypes[account.__typename], 'isDemo')
    let depositTypes = []
    if (canCreateDeposits(this.props.viewer)) {
      depositTypes = filter(filteredDepositVendors, (d) =>
        (
          (isDemoAccount && d.isDemoDeposit) ||
          (!isDemoAccount && !d.isDemoDeposit)
        ) && !d.deprecated
        && d.value !== filteredDepositVendors.transfer.value
      )
    }
    return depositTypes
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    if (key === 'depositVendor' && value === depositVendors.bankWire.key) {
      const bankAccount = {
        iban: null,
        swift: null,
        holderName: null,
        bankName: null,
      }
      this.setState({
        form: {
          ...this.state.form, ...bankAccount
        }
      })
    } else {
      this.hideDepositOptions()
      this.setState({showBankWireOptions: false})
    }

    if (this.state.feedback) {
      this.setState({values, errors: this.validate(values)})
    } else {
      this.setState({values})
    }
  }

  validate(values = this.state.values) {
    const errors = {}

    if (!isNumeric(values.accountId)) {
      errors.accountId = true
    }

    if (!isPositiveNumber(values.amount)) {
      errors.amount = true
    }

    if (!values.depositVendor) {
      errors.depositVendor = true
    }

    if (values.depositVendor === depositVendors.bankWire.key) {
      if (!values.dateReceived) {
        errors.dateReceived = true
      }
      if (!values.bankAccountHolderName) {
        errors.bankAccountHolderName = true
      }
      if (!values.iban) {
        errors.iban = true
      }

      if (!values.swiftCode) {
        errors.swiftCode = true
      }

      if (!values.bankName) {
        errors.bankName = true
      }

      if (!values.bankWireCompletedBank) {
        errors.bankWireCompletedBank = true
      }
    }

    if (values.receipt && !isObject(safeParseJSON(values.receipt))) {
      errors.receipt = true
    }

    return errors
  }

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  showDepositOptions() {
    this.setState({depositOptionsEnabled: true})
  }

  hideDepositOptions() {
    this.setState({depositOptionsEnabled: false})
  }

  tryCreateNewDeposit = () => {
    const errors = this.validate()

    if (!isEmpty(errors)) {
      this.setState({errors, feedback: true})
      return
    }

    this.disableCreateButton()

    const {props: {onClose}, state: {values}} = this
    const account = this.props.accounts.find((account) => account.id === values.accountId)
    const data = {
      ...values,
      vendor: values.depositVendor,
      currency: account.currency,
      meta: values.bankWireCompletedBank ? {bankWireCompletedBank: values.bankWireCompletedBank} : {}
    }

    const createPromise = values.depositVendor === bonusOfferTypes.adminBonus.key
      ? this.createAdminBonusOffer(data) : this.createDeposit(data)

    createPromise
      .then(onClose)
  }

  createDeposit(deposit) {
    const {deposits: depositsProvider, accounts: accountsProvider, activityLogs: activityLogsProvider}
      = this.context.clientProvider.subProviders

    return this.props.actions.client.createDeposit(deposit)
      .then((res) => {
        accountsProvider.fetch()
        depositsProvider.fetch()
        activityLogsProvider.fetch()
      })
      .catch(this.context.logError)
  }

  createAdminBonusOffer(bonusOffer) {
    const {bonusOffers: bonusOffersProvider, activityLogs: activityLogsProvider}
      = this.context.clientProvider.subProviders

    return this.props.actions.client.createAdminBonusOffer(bonusOffer)
      .then((res) => {
        bonusOffersProvider.fetch()
        activityLogsProvider.fetch()
      })
      .catch(this.context.logError)
  }

  renderErrorMessage(key) {
    const {errors} = this.state
    if (has(errors, key) && errors[key]) {
      return (
        <p className="help-block" style={{color: '#a94442'}}>Field is required</p>
      )
    }
  }

  render() {
    const {props: {accounts, onClose}, state: {values, errors, createButtonDisabled, dataLoading, dataError}} = this

    if (dataLoading) {
      return <Loading speed={1} style={{padding:'20px'}} />
    }
    if (dataError) return <pre> Error Loading Accounts : {dataError.message} </pre>

    const accountIdOptions = accounts ? accounts.map((account) => {
      const remoteId = account && account.remoteId
      const currency = account.currency ? ' - ' + account.currency : ''
      return {
        value: account.id,
        label: `${getAccountLabel(accountTypes[account.__typename])} [${remoteId}]${currency}`,
        isDemo: account.isDemo,
        hidden: account.hidden,
        isArchived: account.isArchived,
      }
    }) : []

    const showSourceAccount = this.state.values.depositVendor === depositVendors.transfer.value ||
      this.state.values.depositVendor === depositVendors.transferb2b.value

    const sourceAccount = reject(accountIdOptions, (a) => a.isDemo || a.value === this.state.values.accountId || a.isArchived || a.hidden)
    const selectedAccount = find(accounts, {id: values.accountId})

    return (
      <Card id="t-client-deposits-new-deposit-sidebar" className="panel-short">
        <Card.Header><strong>New Deposit/Bonus</strong></Card.Header>
        <Card.Body>
          <Row id="t-client-deposits-new-deposit-account">
            <Col xs={12}>
              <StylishSelect.Input
                label="Account"
                value={values.accountId || ''}
                bsStyle={errors.accountId && 'error'}
                onChange={(e) => {
                  this.formValueChanged('accountId', Number(e.value))
                }}
                placeholderText="Choose Account"
                options={reject(accountIdOptions, (a) => a.isArchived || a.hidden)}
              />
            </Col>
          </Row>
          <Row id="t-client-deposits-new-deposit-amount">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Amount</Form.Label>
                <Form.Control
                  type="text"
                  title="Amount"
                  value={values.amount || ''}
                  isInvalid={errors.amount}
                  onChange={(event) => this.formValueChanged('amount', event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row id="t-client-deposits-new-deposit-vendor">
            <Col xs={12}>
              <StylishSelect.Input
                label="Type"
                value={values.depositVendor || ''}
                bsStyle={errors.depositVendor && 'error'}
                onChange={(e) => {
                  this.formValueChanged('depositVendor', e.value)
                }}
                placeholderText="Choose Vendor"
                options={StylishSelect.enumToStylishOptions(this.getDepositTypes(selectedAccount))}
              />
            </Col>
          </Row>
          <Row id="t-client-deposits-new-deposit-receipt">
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Receipt</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  title="Receipt"
                  value={values.receipt || ''}
                  isInvalid={errors.receipt}
                  onChange={(event) => this.formValueChanged('receipt', event.target.value)}
                />
              </Form.Group>
            </Col>
          </Row>
          {showSourceAccount && <Row id="t-client-deposits-new-deposit-source-account">
            <Col xs={12}>
              <StylishSelect.Input
                label="Source Account"
                value={values.sourceAccount || ''}
                bsStyle={errors.type && 'error'}
                onChange={(e) => {
                  this.formValueChanged('sourceAccount', Number(e.value))
                }}
                placeholderText="Choose Account"
                options={sourceAccount}
              />
            </Col>
          </Row>}
          {values.depositVendor === depositVendors.bankWire.key &&
            <Row id="t-client-deposits-new-deposit-bankWireOptions">
              <Col xs={12}>
                <Form.Group>
                  <DateTime
                    label="Date Received"
                    value={values.dateReceived || ''}
                    onChange={(value) => this.formValueChanged('dateReceived', value)}
                    placeholder="Enter Date Received"
                    timeFormat={false}
                  />
                  {this.renderErrorMessage('dateReceived')}
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Bank Account Holder</Form.Label>
                  <Form.Control
                    type="text"
                    title="Bank Account Holder"
                    value={values.bankAccountHolderName || ''}
                    isInvalid={errors.bankAccountHolderName}
                    onChange={(event) => this.formValueChanged('bankAccountHolderName', event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Bank account / IBAN</Form.Label>
                  <Form.Control
                    type="text"
                    title="Bank account / IBAN"
                    value={values.iban || ''}
                    isInvalid={errors.iban}
                    onChange={(event) => this.formValueChanged('iban', event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>SWIFT/BIC Code</Form.Label>
                  <Form.Control
                    type="text"
                    title="SWIFT/BIC Code"
                    value={values.swiftCode || ''}
                    isInvalid={errors.swiftCode}
                    onChange={(event) => this.formValueChanged('swiftCode', event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Bank Name</Form.Label>
                  <Form.Control
                    type="text"
                    title="Bank Name"
                    value={values.bankName || ''}
                    isInvalid={errors.bankName}
                    onChange={(event) => this.formValueChanged('bankName', event.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col xs={12}>
                <StylishSelect.Input
                  label="Bank"
                  menuPlacement="auto"
                  value={values.bankWireCompletedBank}
                  placeholderText="Select Bank"
                  bsStyle={errors.bankWireCompletedBank && 'error'}
                  options={StylishSelect.enumToStylishOptions(bankWireBanks)}
                  onChange={(event) => this.formValueChanged('bankWireCompletedBank', event.value)}
                />
              </Col>
            </Row>}
          <Row>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-deposits-new-deposit-cancel-button"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-1"
                >
                  Cancel
                </Button>
                <Button
                  id="t-client-deposits-new-deposit-create-button"
                  variant="success"
                  size="sm"
                  disabled={createButtonDisabled}
                  onClick={this.tryCreateNewDeposit}
                >
                  Create
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
})
