import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {isEmpty, map, get} from 'lodash'
import PureComponent from '../PureComponent'
import {accountSubtypes, orderDirections, ibNetworkOptions, yesNo, existingOrNewClients} from '@bdswiss/common-enums'
import {canUpsertPartnersCustomization} from '@bdswiss/common-permissions'
import {Row, Col, Table, Button, Card, Pagination, Form, Container, InputGroup} from 'react-bootstrap'
import {events} from '../enums'
import {getPageCount, getPageRange} from '../useful'
import style from './partnerscustomization.module.scss'
import {partnersCustomizationProvider} from './providers'
import {compose, provideProps, mountDataProviders, uiMount} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class PartnersCustomization extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    partnersCustomizationProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
    logError: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      searchText: '',
    }
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.partnersCustomizationProvider.fetch()
  }

  doPartnersCustomizationSearch(searchText) {
    this.props.dispatch(
      'Search partner customizations',
      (state) => ({...state, partnersCustomizationSearch: searchText, partnersCustomizationPage: 1})
    )
    this.setState({searchText: searchText})
  }

  renderPartnerCustomization(partnerCustomization) {
    const {id, affiliateId, ibId, campaignId, accountTypeCustomizations: {accountSubTypes}, minDepositCustomizations,
      leverageCustomizations, initialDemoDeposit, depositorSalesAgent, nonDepositorSalesAgent, bonus,
      gdcDisabled, spoa, autochartist,
    } = partnerCustomization

    let label = ''
    if (affiliateId) label = 'Affiliate:'
    else if (ibId) label = 'IB:'
    else if (campaignId) label = 'Campaign:'

    const canViewerEdit = canUpsertPartnersCustomization(this.props.viewer)

    const autochartistNetworkLabel = `${get(autochartist, 'network') ? `Network: ${ibNetworkOptions[get(autochartist, 'network')].label}` : ''}`
    const autochartistActivateForLabel = `${get(autochartist, 'activateFor', '') ? `Activate For: ${existingOrNewClients[get(autochartist, 'activateFor')].label}` : ''}`
    const autochartistLabel = `Autochartist: ${get(autochartist, 'enabled')
      ? `Enabled - ${autochartistNetworkLabel} - ${autochartistActivateForLabel}`
      : 'Not enabled'}`

    return (
      <tr
        key={partnerCustomization.id}
        className={canViewerEdit ? 'clickable' : ''}
        onClick={() => {
          if (canViewerEdit) {
            this.props.uiDispatch(
              'Edit partner customization',
              (state) => ({...state, selectedPartnerCustomization: partnerCustomization})
            )
            this.context.router.push(`/partners-customization/${id}`)
          }
        }}
      >
        <td> {id} </td>
        <td> {`${label} ${affiliateId || ibId || campaignId}`} </td>
        <td>
          {map(accountSubTypes, (accSubType) => accountSubtypes[accSubType].label).join(', ')}
        </td>
        <td> {map(minDepositCustomizations, (customization, i) => {
          const label = `${accountSubtypes[customization.accountSubType].label}: ${customization.amount}`
          return <p key={`deposit-${i}`}> {label} </p>
        })} </td>
        <td> {map(leverageCustomizations, (customization, i) => {
          const subtypeLabel = customization.accountSubType === 'demo'
            ? 'Demo'
            : accountSubtypes[customization.accountSubType].label
          const label = `${subtypeLabel}: ${customization.leverage}`
          return <p key={`subtype-${i}`}> {label} </p>
        })} </td>
        <td>
          {depositorSalesAgent && <p>{`Depositors Agent: ${depositorSalesAgent.firstName} ${depositorSalesAgent.lastName}`}</p>}
          {nonDepositorSalesAgent && <p>{`Non Depositors Agent: ${nonDepositorSalesAgent.firstName} ${nonDepositorSalesAgent.lastName}`}</p>}
          {!isEmpty(bonus) && (affiliateId || ibId) &&
            <p>
              {`Bonus: ${get(bonus, 'enabled') ? 'Enabled' : 'Not enabled'} ${get(bonus, 'enabled') ? `- Start Date: ${moment(get(bonus, 'startDate')).format('DD/MM/YYYY')}` : ''}`}
            </p>
          }
          {!isEmpty(spoa) && ibId &&
            <p>
              {`Spoa: ${get(spoa, 'enabled') ? 'Enabled' : 'Not enabled'} ${get(spoa, 'enabled') ? `- Network: ${ibNetworkOptions[get(spoa, 'network')].label}` : ''}`}
            </p>
          }
          {!isEmpty(autochartist) && ibId &&
            <p>
              {autochartistLabel}
            </p>
          }
        </td>
        <td> {initialDemoDeposit || 'Default'} </td>
        <td>{yesNo[gdcDisabled ? 'yes' : 'no'].label}</td>
      </tr>
    )
  }

  render() {
    const {viewer, partnersCustomization, partnersCustomizationCount, partnersCustomizationPage,
      partnersCustomizationSearch} = this.props
    const {searchText} = this.state

    return (
      <Container>
        <Row>
          <Col xs={4}>
            <InputGroup>
              <Form.Control
                id="t-list-campaigns-search"
                type="text"
                value={searchText !== undefined ? searchText : partnersCustomizationSearch || ''}
                placeholder="Search by Affiliate ID, IB ID, Campaign ID"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doPartnersCustomizationSearch(searchText)) ||
                  (e.key === 'Escape' && this.doPartnersCustomizationSearch(''))
                )}
              />
              <InputGroup.Append>
                {partnersCustomizationSearch && <Button
                  key={1}
                  title="Clear"
                  variant={partnersCustomizationSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doPartnersCustomizationSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={partnersCustomizationSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doPartnersCustomizationSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          <Col xs={8} className={style.toolbar}>
            {canUpsertPartnersCustomization(viewer) && <Button
              id="t-trading-alerts-new-alert"
              className={style.actionbuttons}
              variant="success"
              size="sm"
              onClick={() => {
                this.props.uiDispatch(
                  'Create partner customization',
                  (state) => ({...state, selectedPartnerCustomization: null})
                )
                this.context.router.push('/partners-customization/create')}
              }
            > New Partner Customization
            </Button>}
          </Col>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table striped hover className={style.table}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Partner/Campaign ID</th>
                      <th>Account Types</th>
                      <th>Minimum Deposits</th>
                      <th>Leverage</th>
                      <th>Partner Related</th>
                      <th>Initial Demo Deposit</th>
                      <th>GDC Disabled</th>
                    </tr>
                  </thead>
                  <tbody>
                    {partnersCustomization.map((partnerCustomizaion) => this.renderPartnerCustomization(partnerCustomizaion))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, partnersCustomizationPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(partnersCustomizationPage, getPageCount(partnersCustomizationCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, partnersCustomizationPage: partnersCustomizationPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, partnersCustomizationPage: partnersCustomizationPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === partnersCustomizationPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, partnersCustomizationPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'partners-customization']),

  provideProps((state, uiState) => {
    const {partnersCustomization, partnersCustomizationCount, partnersCustomizationSearch} = state
    const {partnersCustomizationPage} = uiState
    let partnersCustomizationSort

    if (!partnersCustomizationSort || partnersCustomizationSort.orderBy == null
              || partnersCustomizationSort.orderDirection == null
              || !(partnersCustomizationSort.orderDirection in orderDirections)) {
      partnersCustomizationSort = defaultSort
    }
    return ({
      partnersCustomization,
      partnersCustomizationCount,
      partnersCustomizationPage: partnersCustomizationPage || 1,
      partnersCustomizationSort,
      partnersCustomizationSearch,
    })
  }),

  mountDataProviders({partnersCustomizationProvider}),
)(PartnersCustomization)
