import React from 'react'
import PropTypes from 'prop-types'
import {provideProps, compose, uiMount} from '../decorators'
import {Modal, Row, Button, ButtonToolbar, Form, Col, DropdownButton, Dropdown} from 'react-bootstrap'
import DateTime from '../components/DateTime'
import {mergeMoments} from '../utils/dateTime'
import {isEmpty, map, find, get} from 'lodash'
import moment from 'moment'
import PureComponent from '../PureComponent'
import SelectAgent from '../components/SelectAgent'
import SelectClient from '../components/SelectClient'
import ConfirmationModal from '../components/ConfirmationModal'
import SelectAppointmentCategory from '../components/SelectAppointmentCategory'
import {appointmentColors} from '@bdswiss/common-enums'

const defaultState = {
  values: {
    addToGoogleCalendar: false,
  },
  errors: {},
  feedback: false,
  createButtonDisabled: false,
  showDeleteAppointmentConfirmation: false,
}

class AppointmentEditorModal extends PureComponent {
  state = defaultState

  static contextTypes = {
    appointmentsProvider: PropTypes.object.isRequired,
    viewerAppointmentsCountProvider: PropTypes.object.isRequired,
    appointmentsByDayProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    toggleAppointmentEditor: PropTypes.func.isRequired,
  };

  static propTypes = {
    show: PropTypes.bool,
    appointment: PropTypes.object,
    clientAppointmentsProvider: PropTypes.object,
  }

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  componentWillReceiveProps(nextProps) {
    const {date: start, description, id, category, color, addToGoogleCalendar, user, client} = nextProps.appointment || {}
    this.resetForm({
      id,
      startDate: start,
      startTime: start,
      userId: get(user, 'id'),
      clientId: get(client, 'id'),
      description,
      category,
      color,
      addToGoogleCalendar: Boolean(addToGoogleCalendar),
    })
  }

  resetForm = (values = {}) => {
    this.setState({
      ...defaultState,
      values,
    })
  }

  close = () => {
    this.resetForm()
    this.context.toggleAppointmentEditor()
  }

  validate(values = this.state.values) {
    const errors = {}

    if (!moment.isMoment(values.startDate)) {
      errors.startDate = true
    }
    if (!moment.isMoment(values.startTime)) {
      errors.startTime = true
    }
    if (!values.category) {
      errors.category = true
    }
    if (!values.userId) {
      errors.userId = true
    }
    if (!values.clientId) {
      errors.clientId = true
    }

    return errors
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    if (this.state.feedback) {
      this.setState({values, errors: this.validate(values)})
    } else {
      this.setState({values})
    }
  }

  requeryProviders = () => {
    const {clientAppointmentsProvider} = this.props
    this.context.appointmentsProvider.fetch()
    this.context.viewerAppointmentsCountProvider.fetch()
    this.context.appointmentsByDayProvider.fetch()
    if (clientAppointmentsProvider) {
      clientAppointmentsProvider.fetch()
    }
  }

  appointmentAction = () => {
    const errors = this.validate(undefined)
    if (!isEmpty(errors)) {
      this.setState({errors, feedback: true})
      return
    }

    this.disableCreateButton()

    const {actions} = this.props
    const {id, startDate, startTime, description, userId, category, color = 'graphite', addToGoogleCalendar, clientId} = this.state.values
    const start = mergeMoments(startDate, startTime)

    actions.client.upsertAppointment(id, clientId, start, color, addToGoogleCalendar, userId, category, description)
      .then(this.requeryProviders)
      .then(this.close)
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.setState({createButtonDisabled: false})
      })
  }

  handleDeleteAppointmentClick = () => {
    this.setState({showDeleteAppointmentConfirmation: true})
  }

  clearDeleteAppointment = () => {
    this.setState({showDeleteAppointmentConfirmation: false})
  }

  deleteAppointment = () => {
    const {actions, appointment: {id}} = this.props
    this.clearDeleteAppointment()
    actions.client.deleteAppointment(id)
      .then(this.requeryProviders)
      .then(this.close)
      .catch(this.context.logError)
  }

  getFilteredAgents = () => {
    const {viewer, agents = []} = this.props
    const managedAgents = agents.filter(a => get(a, 'managerId') === viewer.id)
    const viewerAgent = {id: viewer.id, firstName: viewer.firstName, lastName: `${viewer.lastName} (You)`}
    return [...managedAgents, viewerAgent]
  }

  renderForm() {
    const {values, errors} = this.state
    const {clientAppointmentsProvider, viewer} = this.props
    const client = get(this.props, 'appointment.client')

    return (
      <div>
        <Row>
          <Col>
            <DateTime
              label="Start Date"
              bsStyle={errors.startDate && 'error'}
              value={values.startDate}
              closeOnSelect
              timeFormat={false}
              onChange={(value) => this.formValueChanged('startDate', value)}
            />
          </Col>
          <Col>
            <DateTime
              label="Start Time"
              bsStyle={errors.startTime && 'error'}
              defaultValue={values.startTime}
              dateFormat={false}
              onChange={(value) => this.formValueChanged('startTime', value)}
            />
          </Col>
          <Col>
            <label style={{display: 'block'}}>Color</label>
            <DropdownButton
              id="apointment-color"
              variant="outline-light"
              title={get(find(appointmentColors, {value: values.color}), 'label', 'Graphite')}
              onSelect={(eventKey) => this.formValueChanged('color', eventKey)}
              className={`appointment-${values.color || 'graphite'}`}
            >
              {map(appointmentColors, (color) =>
                <Dropdown.Item eventKey={color.value} key={color.key}>{color.label}</Dropdown.Item>)}
            </DropdownButton>
          </Col>
        </Row>
        <Row>
          <Col>
            <Form.Check
              type="checkbox"
              label="Add to google calendar"
              checked={values.addToGoogleCalendar}
              onChange={(e) => this.formValueChanged('addToGoogleCalendar', e.target.checked)}
            />
          </Col>
        </Row>
        <Row>
          {!clientAppointmentsProvider && <Col xs={12}>
            <span>&nbsp;</span>
            <SelectClient
              onChange={(e) => this.formValueChanged('clientId', e.value)}
              value={values.clientId}
              initialClient={client}
              isInvalid={errors.clientId}
            />
          </Col>}
          <Col xs={12}>
            <SelectAgent.Input
              key="input"
              label="Agent"
              agents={this.getFilteredAgents()}
              value={values.userId}
              onChange={(e) => this.formValueChanged('userId', e.value)}
              isInvalid={errors.userId}
            />
          </Col>
          <Col xs={12}>
            <SelectAppointmentCategory.Input
              label="Reminder category"
              value={values.category}
              onChange={(evt) => this.formValueChanged('category', evt.value)}
              viewer={viewer}
              placeholder="Reminder category"
              isInvalid={errors.category}
            />
          </Col>
          <Col xs={12}>
            <Form.Control
              as="textarea"
              label="Description"
              placeholder="Your description goes here."
              rows="8"
              isInvalid={errors.description}
              value={values.description}
              onChange={(e) => this.formValueChanged('description', e.target.value)}
            />
          </Col>
        </Row>
      </div>
    )
  }

  render() {
    const {show} = this.props
    const {values, showDeleteAppointmentConfirmation} = this.state

    return (
      <div>
        <Modal
          keyboard
          show={show}
          onHide={this.close}
          backdrop="static"
        >
          <Modal.Header>
            <strong>Reminder</strong>
          </Modal.Header>
          <Modal.Body>
            {this.renderForm()}
          </Modal.Body>
          <Modal.Footer>
            {values.id && <ButtonToolbar className="pull-left">
              <Button
                variant="danger"
                size="sm"
                className="mr-1"
                disabled={this.state.createButtonDisabled}
                onClick={this.handleDeleteAppointmentClick}
              >
                Delete
              </Button>
            </ButtonToolbar>}
            <ButtonToolbar className="float-right">
              <Button onClick={this.close} variant="outline-secondary" className="mr-1" size="sm">
                Cancel
              </Button>
              <Button
                variant="success"
                size="sm"
                disabled={this.state.createButtonDisabled}
                onClick={this.appointmentAction}
              >
                {'id' in values ? 'Save' : 'Create'}
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Modal>
        {showDeleteAppointmentConfirmation && <ConfirmationModal
          show
          body="Do you really want to delete this appointment?"
          confirmLabel="Delete"
          confirmStyle="danger"
          onConfirm={this.deleteAppointment}
          onCancel={this.clearDeleteAppointment}
        />}
      </div>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'appointments']),

  provideProps((state, uiState) => {
    const {agents, viewer} = state
    const {startFrom, startTo, isNew, tab, userId} = uiState
    return {
      agents,
      startFrom,
      startTo,
      isNew,
      tab,
      userId,
      viewer,
    }
  }),
)(AppointmentEditorModal)
