import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {merge, debounce} from 'lodash'
import {Row, Col, Table, Button, Card, Pagination, Form, Container, InputGroup} from 'react-bootstrap'
import {events} from '../enums'
import style from './performanceFees.scss'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {performanceFeesProvider} from './providers'
import {depositStatuses} from '@bdswiss/common-enums'
import {getDepositStatusLabel} from '../utils/rendering'
import {canQueryAllPerformanceFees} from '@bdswiss/common-permissions'
import {getFormattedAmount, getPageCount, readableDate, getPageRange} from '../useful'
import {linkClientDeposits, linkClientWithdrawals, safeLinkClientDeposits} from '../utils/links'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class PerformanceFees extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
    this.doFilterByAmount = debounce(this.doFilterByAmount, 1500)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    performanceFeesProvider: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {
      createdFrom: '',
    }
    this.setState({dateFilters: stateDateFilters})

    const stateAmountFilters = this.props.amountFilters || {
      amountFrom: '',
      amountTo: '',
    }
    this.setState({amountFilters: stateAmountFilters})
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.performanceFeesProvider.fetch()
  }

  doPerformanceFeesSearch(searchText) {
    this.props.uiDispatch(
      'Search performance fees',
      (state, arg) => ({...state, performanceFeesSearch: arg, performanceFeesPage: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  handleAmountFilterChanged(name, value) {
    const {amountFilters} = this.state
    this.setState({
      amountFilters: {...amountFilters, [name]: value},
    })
    this.doFilterByAmount()
  }

  doFilterByAmount() {
    const {amountFilters} = this.state
    this.props.uiDispatch(
      'Filter documents by amount range',
      (state, arg) => ({...state, amountFilters, page: 1}),
      [amountFilters]
    )
  }

  handleDateFilterChanged(name, value) {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })

    if (!value || moment.isMoment(value)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilters} = this.state
    if (dateFilters && moment.isMoment(dateFilters.createdFrom)) {
      dateFilters.createdFrom.startOf('day')
    }
    if (dateFilters && moment.isMoment(dateFilters.createdTo)) {
      dateFilters.createdTo.endOf('day')
    }
    this.props.uiDispatch(
      'Filter documents by dates',
      (state, arg) => ({...state, dateFilters, page: 1}),
      [dateFilters]
    )
  }

  exportPerformanceFees() {
    const {performanceFeesSearch, dateFilters, amountFilters} = this.props
    this.setState({exporting: true})
    this.props.actions.performanceFees.exportPerformanceFees({performanceFeesSearch, dateFilters, amountFilters})
      .then((res) => {
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = res.exportPerformanceFees
        document.body.appendChild(a)
        a.click()
        this.setState({exporting: false})
      }).catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.setState({exporting: false})
      })
  }

  renderPerformanceFee(performanceFee) {
    const {depositId, providerId, providerName, depositAmount, depositCurrency, followerName, followerId,
      withdrawalId, withdrawalAmount, withdrawalCurrency, depositDate, depositStatus,
    } = performanceFee


    return (
      <tr key={`${depositId}-${withdrawalId}`} id={`t-performance-fees-id-${depositId}`}>
        <td>{safeLinkClientDeposits({id: providerId}, depositId, depositId, true)}</td>
        <td>{providerName}</td>
        <td>{linkClientDeposits(providerId, providerId, undefined, true)}</td>
        <td>{getFormattedAmount({amount: depositAmount, currency: depositCurrency})}</td>
        <td>{followerName}</td>
        <td>{linkClientWithdrawals(followerId, followerId, undefined, true)}</td>
        <td>{getFormattedAmount({amount: withdrawalAmount, currency: withdrawalCurrency})}</td>
        <td>{readableDate(depositDate)}</td>
        <td>{getDepositStatusLabel(depositStatuses[depositStatus])}</td>
      </tr>
    )
  }

  renderPerformanceFees(performanceFees) {
    return performanceFees.map((performanceFee) => this.renderPerformanceFee(performanceFee))
  }

  render() {
    const {performanceFees, performanceFeesSearch, performanceFeesPage, performanceFeesCount} = this.props

    const {searchText} = this.state || {}

    const {dateFilters, amountFilters, exporting} = this.state
    return (
      <Container>
        <Row>
          <Col xs={3}>
            <div>
              <span>&nbsp;</span>
              <InputGroup>
                <Form.Control
                  type="text"
                  value={searchText !== undefined ? searchText : performanceFeesSearch || ''}
                  placeholder="Search by ID/Provider/Follower"
                  onChange={(e) => this.setState({searchText: e.target.value})}
                  onKeyUp={(e) => (
                    (e.key === 'Enter' && this.doPerformanceFeesSearch(searchText)) ||
                    (e.key === 'Escape' && this.doPerformanceFeesSearch(''))
                  )}
                />
                <InputGroup.Append>
                  {performanceFeesSearch && <Button
                    key={1}
                    title="Clear"
                    variant={performanceFeesSearch ? 'success' : 'outline-dark'}
                    onClick={() => this.doPerformanceFeesSearch('')}
                  >
                    <FontAwesomeIcon icon="times" />
                  </Button>}
                  <Button
                    key={2}
                    title="Search"
                    variant={performanceFeesSearch ? 'success' : 'outline-dark'}
                    onClick={() => this.doPerformanceFeesSearch(searchText)}
                  >
                    <FontAwesomeIcon icon="search" />
                  </Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Created From</span>
              <DateTime
                id="t-performance-fees-created-from-filter"
                timeFormat={false}
                onChange={(e) => this.handleDateFilterChanged('createdFrom', e)}
                value={dateFilters.createdFrom}
                onFocus={() => this.doDateFilterSearch.cancel()}
                closeOnSelect
                className={style.datetime}
              />
            </div>
          </Col>
          <Col xs={2}>
            <span className={style.label}>Created To</span>
            <DateTime
              id="t-performance-fees-created-to-filter"
              timeFormat={false}
              onChange={(e) => this.handleDateFilterChanged('createdTo', e)}
              value={dateFilters.createdTo}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
              className={style.datetime}
            />
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Amount From (Deposit)</span>
              <Form.Control
                id="t-performance-fees-amount-from-filter"
                type="number"
                variant={amountFilters.amountFrom ? 'success' : 'outline-secondary'}
                onChange={(e) => this.handleAmountFilterChanged('amountFrom', e.target.value)}
                value={amountFilters.amountFrom}
                onFocus={() => this.doFilterByAmount.cancel()}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Amount To (Deposit)</span>
              <Form.Control
                id="t-performance-fees-amount-to-filter"
                type="number"
                variant={amountFilters.amountTo ? 'success' : 'outline-secondary'}
                onChange={(e) => this.handleAmountFilterChanged('amountTo', e.target.value)}
                value={amountFilters.amountTo}
                onFocus={() => this.doFilterByAmount.cancel()}
              />
            </div>
          </Col>
          <Col xs={1}>
            <span className={style.label}>&nbsp;</span>
            <Button
              className="float-right"
              variant="success"
              onClick={() => this.exportPerformanceFees()}
              disabled={exporting}
            >
              {exporting ? <i className="fa fa-spinner fa-2x fa-spin" title={'spinner'} /> : 'Export'}
            </Button>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col xs={12}>
            <Card className="two-rows-filter">
              <Card.Body>
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>Deposit ID</th>
                      <th>Provider Name</th>
                      <th>Provider ID</th>
                      <th>Performance Fee (Deposit)</th>
                      <th>Follower Name</th>
                      <th>Follower ID</th>
                      <th>Performance Fee (Withdrawal)</th>
                      <th>Transaction Date</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderPerformanceFees(performanceFees)}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, performanceFeesPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(performanceFeesPage, getPageCount(performanceFeesCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, performanceFeesPage: performanceFeesPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, performanceFeesPage: performanceFeesPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === performanceFeesPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, performanceFeesPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryAllPerformanceFees),

  uiMount((state) => ['ui', 'performanceFees']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui for performance fees',
    (state) => {
      const dateFilters = state && state.dateFilters
      let amountFilters = state && state.amountFilters
      if (!amountFilters) {
        amountFilters = {amountFrom: '', amountTo: ''}
      }
      return merge({
        dateFilters,
        amountFilters,
      }, state)
    })
  ),

  provideProps((state, uiState) => {
    const {performanceFees, performanceFeesCount} = state
    const {performanceFeesSearch, performanceFeesPage, dateFilters, amountFilters} = uiState

    return ({
      performanceFees,
      performanceFeesCount,
      performanceFeesSearch,
      performanceFeesPage: performanceFeesPage || 1,
      dateFilters,
      amountFilters,
    })
  }),

  mountDataProviders({performanceFeesProvider}),
)(PerformanceFees)
