import React from 'react'
import PropTypes from 'prop-types'
import {filter, map, keys} from 'lodash'
import {Modal, Col, Row, Button} from 'react-bootstrap'
import {compose, provideProps, uiMount} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {getFullName} from '../utils/general'

class AssignDocumentsModal extends PureComponent {

  static contextTypes = {
    usersProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    user: PropTypes.object,
    args: PropTypes.object,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  setDefaultState() {
    this.setState({
      status: undefined,
      userSelected: undefined,
      isTouched: false,
    })
  }

  valueChanged(userSelected) {
    this.setState({
      userSelected,
      isTouched: true,
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  save() {
    const {selectedDocuments, documentsProvider} = this.props
    const {userSelected} = this.state
    this.props.actions.document.assignDocuments(userSelected, keys(selectedDocuments))
      .then((res) => {
        this.hideModal()
        if (this.props.notify) this.props.notify('success', 'Documents Assigned Successfully')
        if (documentsProvider) documentsProvider.fetch()
      })
      .catch(this.context.logError)
  }

  render() {
    const {onHide, show, selectedDocumentsCount, backOfficeUsers} = this.props
    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          Assign Documents
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col id="t-select-user-agent" xs={7}>
              <label className="control-label">Select BO Agent(s)</label>
              <StylishSelect
                placeholderText="Select..."
                options={map(backOfficeUsers, (u) => ({value: u.id, label: `${getFullName(u)}`}))}
                value={this.state.userSelected}
                onChange={(e) => this.valueChanged(e.value)}
              />
              <br />
            </Col>
            <Col id="t-note" xs={12}>
              <div className="float-right">
                <strong><sup>*</sup>Important Note:</strong> You are about to assign <strong>
                  {selectedDocumentsCount}</strong> documents.
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-reassign-clients-cancel"
            variant="outline-secondary"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-reassign-clients-save"
            variant="success"
            disabled={!this.state.userSelected}
            onClick={() => this.save()}
          >
            Assign
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'assignDocumentsUi']),
  provideProps((state, uiState) => {
    const {agents} = state
    let {backOfficeUsers} = uiState
    if (!backOfficeUsers) {
      backOfficeUsers = agents && agents.filter((a) => a.roles.map((a) => a.id).includes('back_office'))
    }

    return ({
      backOfficeUsers: filter(backOfficeUsers, 'isActive'),
    })
  }),
)(AssignDocumentsModal)
