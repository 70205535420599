import {find, get} from 'lodash'
import {yesNo, withdrawalStatuses} from '@bdswiss/common-enums'
import {getOffset} from '../useful'
import {formatAccountTypeFilterInput} from '../schemaUtils'

export default function create(dbClient) {
  return {
    exportWithdrawals(data) {
      const variables = {
        dateFrom: data.dateFrom,
        dateTo: data.dateTo,
        onlyBannedCountries: data.onlyBannedCountries,
      }
      const query = `mutation (
                      $dateFrom: DateTime!
                      $dateTo: DateTime!
                      $onlyBannedCountries: Boolean!
                      ){
                        exportWithdrawals(
                          statuses: [accepted]
                          dateFrom: $dateFrom
                          dateTo: $dateTo
                          onlyBannedCountries: $onlyBannedCountries
                        )
                      }`

      return dbClient.query(query, variables)
    },

    withdrawalsExportSearch(props, page, limit) {
      const {withdrawalStatus, salesAgentFilter, accountsFilter, companyFilter, dateFilters,
        clientTypesFilter, vendorsFilter, countriesFilter, topVipFilter, amountFilters, withdrawalsSearch,
        typesFilter, partnersAgentFilter, hasPendingFundingDocumentsFilter, depositVendorFilterValue,
      } = props

      const searchArgs = {
        salesAgentId: salesAgentFilter,
        accountTypeSubtype: formatAccountTypeFilterInput(accountsFilter),
        company: companyFilter,
        clientTypes: clientTypesFilter,
        vendors: vendorsFilter,
        countries: countriesFilter,
        topVip: get(find(yesNo, {value: topVipFilter}), 'boolValue'),
        search: withdrawalsSearch,
        types: typesFilter,
        supportAgentId: partnersAgentFilter,
        hasPendingFundingDocuments: get(find(yesNo, {value: hasPendingFundingDocumentsFilter})),
        depositVendor: depositVendorFilterValue,
      }

      if (withdrawalStatus !== '') {
        searchArgs.status = withdrawalStatuses[withdrawalStatus].value
      }

      if (dateFilters && dateFilters.createdFrom) {
        searchArgs.creationTimeFrom = dateFilters.createdFrom
      }

      if (dateFilters && dateFilters.createdTo) {
        searchArgs.creationTimeTo = dateFilters.createdTo
      }

      if (amountFilters && amountFilters.amountFrom) {
        searchArgs.amountFrom = amountFilters.amountFromom
      }

      if (amountFilters && amountFilters.amountTo) {
        searchArgs.amountTo = amountFilters.amountTo
      }

      const variables = {
        searchArgs: JSON.stringify(searchArgs),
        limit: limit,
        offset: getOffset(page, limit),
        orderBy: 'id',
        orderDirection: 'descending'
      }

      const query = `mutation (
          $searchArgs: String
          $limit: Int
          $offset: Int
          $orderBy: OrderBy
          $orderDirection: OrderDirection
        ) {
        withdrawalsExportSearch(
          searchArgs: $searchArgs
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          orderDirection: $orderDirection
        )
      }`

      return dbClient.query(query, variables)
    },
  }
}

