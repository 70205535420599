export default function create(dbClient) {
  return {
    upsertArticle(id, title, body) {
      const variables = {id, title, body}
      const query = `
        mutation ($id: Int, $title: String!, $body: String!) {
          upsertArticle(
            id: $id
            title: $title 
            body: $body
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

  }
}
