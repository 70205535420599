import React from 'react'
import PropTypes from 'prop-types'
import {Button, ButtonToolbar, Col, Container, Row} from 'react-bootstrap'
import CampaignForm from './CampaignForm'
import PureComponent from '../PureComponent'
import TitleBreadcrumb from './TitleBreadcrumb'
import {emptyCampaign, validateCampaign, noCampaignValidationError, transformCampaign} from './validation'


function calculatePixels(pixels, changes) {
  pixels = pixels || []
  changes = changes || []

  const result = []
  for (let i = 0; i < Math.max(pixels.length, changes.length); i++) {
    let res = {}
    if (i < pixels.length) {
      res = {...res, ...pixels[i]}
    }
    if (i < changes.length) {
      res = {...res, ...changes[i]}
    }
    if (!res.deleted) {
      result.push(res)
    }
  }
  return result
}

export default class CampaignEditor extends PureComponent {

  static propTypes = {
    campaign: PropTypes.object,
    campaignId: PropTypes.string,
  };

  static contextTypes = {
    router: PropTypes.object.isRequired,
    campaignsProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  componentWillMount() {
    if (this.props.campaignId == null) {
      this.valuesChanged(emptyCampaign(), false, true)
    } else {
      this.valuesChanged({}, false, true)
    }
    window.addEventListener('onbeforeunload', this.handlePageUnload.bind(this), true)
  }

  componentWillUnmount() {
    window.removeEventListener('onbeforeunload', this.handlePageUnload.bind(this), true)
  }

  handlePageUnload() {
    return this.hasChanges() ? 'Exit without saving?' : null
  }

  cancel() {
    this.context.router.push('/campaigns')
  }

  save() {
    const {campaignId, campaign} = this.props
    const pixels = campaign ? campaign.pixels : null

    const data = transformCampaign(this.state.values, campaign)
    const params = {
      ...data,
      pixels: calculatePixels(pixels, this.state.values.pixels),
    }

    this.props.actions.campaign.upsertCampaign(
      campaignId,
      params
    ).then((res) => {
      this.context.campaignsProvider.fetch()
      this.context.router.push('/campaigns')
    }).catch(this.context.logError)
  }

  trySave() {
    const errors = validateCampaign(this.state.values)
    if (!this.hasErrors(errors)) {
      this.save()
    } else {
      this.setState({
        feedback: true,
        errors: errors,
      })
    }
  }

  valuesChanged(values, feedback = this.state && this.state.feedback, isInitialization = false) {
    this.setState({
      valuesChanged: !isInitialization,
      feedback: feedback,
      values: values,
      errors: feedback ? validateCampaign(values) : noCampaignValidationError(),
    })
  }

  hasChanges() {
    return this.state.valuesChanged
  }

  hasErrors(errors) {
    errors = errors || this.state.errors
    return errors._count !== 0
  }

  render() {
    const {campaign} = this.props
    const title = campaign ? campaign.name : 'New Campaign'
    const actionButton = campaign ? 'Save' : 'Create'

    return (
      <Container>
        <div className="h4">
          <Row>
            <Col xs={6}>
              <TitleBreadcrumb name={title} hasChanges={this.hasChanges()} />
            </Col>
            <Col xs={6}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-campaign-editor-cancel-button"
                  onClick={() => this.cancel()}
                  variant="outline-secondary"
                  className="mr-3"
                >
                  Cancel
                </Button>
                <Button
                  id="t-campaign-editor-save-button"
                  variant="success"
                  disabled={!this.hasChanges()}
                  onClick={() => this.trySave()}
                >{actionButton}</Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </div>
        <div>
          <CampaignForm
            campaign={campaign}
            values={this.state.values}
            onChange={this.valuesChanged.bind(this)}
            errors={this.state.errors}
          />
        </div>
      </Container>
    )
  }

}
