import {get} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

export const tagCategoriesSearchProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    let searchTextProperty = ''
    let tagCategoriesCountQuery = 'tagCategoriesCount'
    if (props.tagCategoriesSearch) {
      searchTextProperty = `search: "${props.tagCategoriesSearch}"`
      tagCategoriesCountQuery = `tagCategoriesCount(${searchTextProperty})`
    }
    const {tagCategoriesSort} = props

    const query = `{
      tagCategories(
        limit: ${getPageSize()}
        offset: ${getOffset(props.tagCategoriesPage)}
        orderBy: ${tagCategoriesSort.orderBy}
        orderDirection: ${tagCategoriesSort.orderDirection}
        ${searchTextProperty}
      ) {
        id
        name
        createdAt
      }
      ${tagCategoriesCountQuery}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'TagCategories loaded',
    (state, res) => ({...state, tagCategories: res.tagCategories, tagCategoriesCount: res.tagCategoriesCount}),
    [res]
  ),

}

export const tagCategoriesProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const query = `{
      tagCategories
        {
        id
        name
        createdAt
        tags {
          id
          name
        }
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Tags loaded',
    (state, res) => ({...state, tagCategories: res.tagCategories}),
    [res]
  ),
}

export const tagCategoryProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (!get(props.match, 'params.tagCategory')) {
      return null
    }

    const query = `{
      tagCategory(id: ${get(props.match, 'params.tagCategory')}) {
        id
        name
        createdAt
        tags {
          id
          tagCategory {
            id
          }
          name
        }
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => {
    dispatch(
      'Tag loaded',
      (state, res) => ({
        ...state,
        tag: res.tagCategory,
      }),
      [res]
    )
  },
}
