import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Container, InputGroup} from 'react-bootstrap'
import {isEmpty, debounce, get} from 'lodash'
// import Form.Group from './components/asBootstrap/Form.Group'
import FontAwesomeIcon from './components/FontAwesomeIcon'
import style from './app.module.scss'
import PureComponent from './PureComponent'
import {backendRequest} from './utils/net'
import {isValidEmail} from './utils/validators'
import {getFromStorage, saveToStorage, deleteFromStorage} from './useful'

export default class Login extends PureComponent {

  constructor(props) {
    super(props)
    this.validateEmailForm = debounce(this.validateEmailForm, 200, {leading: true})
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  state = {}

  componentDidMount() {
    saveToStorage('pathname', window.location.pathname)
  }

  redirectPathname(pathname) {
    window.location = pathname
    deleteFromStorage('pathname')
  }

  loginAs() {
    const {loginAs} = this.state
    const {backendUrl} = this.context.config
    const pathname = getFromStorage('pathname')
    backendRequest(`${backendUrl}/auth/force/user/${loginAs}?redirect=false`, undefined, 'GET').then(() => {
      (pathname) ? this.redirectPathname(pathname) : window.location = '/'
    }).catch(this.context.logError)
  }

  loginWithEmail() {
    if (this.validateEmailForm()) {
      const {email, password} = this.state
      const {backendUrl} = this.context.config
      const pathname = getFromStorage('pathname')
      this.setState({loggingIn: true}, () => {
        backendRequest(`${backendUrl}/auth/user-login`, {email, password}, 'POST')
          .then((res) => {
            if (res.ok) {
              (pathname) ? this.redirectPathname(pathname) : window.location = '/'
            } else {
              res.json().then((errMessage) => {
                this.setState({emailLoginError: errMessage.result, loggingIn: false})
              })
            }
          })
          .catch((e) => {
            this.context.logError(e)
            this.setState({emailLoginError: e.message, loggingIn: false})
          })
      })
    }
  }

  validateEmailForm() {
    const {email, password} = this.state
    const emailFormErrors = {}
    if (!email || !isValidEmail(email)) emailFormErrors['email'] = 'Invalid Email'
    if (!password) emailFormErrors['password'] = 'Invalid Password'
    this.setState({emailFormErrors})
    return isEmpty(emailFormErrors)
  }

  render() {
    const {backendUrl, allowForceLogin} = this.context.config
    const {loginAs = '', email = '', password = '', emailFormErrors, emailLoginError} = this.state
    return (
      <Container className={style.centerContainer}>
        <Col sm={12} md={{span: 5, offset: 4}}>
          <Row>
            <Col sm={12}>
              <Row className={style.loginContainer}>
                <Col sm={12}>
                  <Row>
                    <Col xs={12}>
                      <div className={style.loginHeader}>
                        <span> <FontAwesomeIcon icon="lock" className={style.loginIcon} /> Sign in</span>
                        <hr />
                      </div>
                    </Col>
                  </Row>
                  <Col xs={12}>
                    <Form.Group>
                      <label>Email Address</label>
                      <Form.Control
                        type="text"
                        variant={get(emailFormErrors, 'email') && 'error'}
                        value={email}
                        placeholder="Enter Email Address"
                        onChange={(e) => {
                          // Email field is always converted to lowercase
                          this.setState({email: e.target.value?.toLowerCase()}, this.validateEmailForm)}}
                      />
                    </Form.Group>
                    <Form.Group>
                      <label>Password</label>
                      <Form.Control
                        type="password"
                        variant={get(emailFormErrors, 'password') && 'error'}
                        value={password}
                        placeholder="Enter Password"
                        onChange={(e) => this.setState({password: e.target.value}, this.validateEmailForm)}
                      />
                    </Form.Group>
                    <Row>
                      <Col xs={12} className={style.textCenter}>
                        <Button
                          variant="info"
                          disabled={!isEmpty(emailFormErrors)}
                          onClick={() => this.loginWithEmail()}
                        >
                          Sign in with Email
                        </Button>
                        {emailLoginError && <div className={style.loginError}>{emailLoginError}</div>}
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <div className={style.loginOr}>
                      <hr />
                      <span>or</span>
                    </div>
                  </Col>
                  <Col xs={12} className={style.textCenter}>
                    <Button
                      className={style.button}
                      onClick={() => (window.location = `${backendUrl}/auth/start`)}
                    >
                      <FontAwesomeIcon icon="google" className={style.icon} />
                      <span> Sign in with Google </span>
                    </Button>
                  </Col>
                  {
                    allowForceLogin &&
                    <div>
                      <Col xs={12}>
                        <div className={style.loginOr}>
                          <hr />
                          <span>Devs</span>
                        </div>
                      </Col>
                      <Col xs={12}>
                        <label> Login As [Dev Only] </label>
                        <InputGroup>
                          <Form.Control
                            type="text"
                            value={loginAs}
                            placeholder="Enter User ID to Login As"
                            onChange={(e) => this.setState({loginAs: e.target.value})}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter' || e.key === 'NumpadEnter') {
                                this.loginAs()
                              }
                            }}
                            autoFocus
                          />
                          <InputGroup.Append>
                            <Button
                              key={1}
                              title="Login In As"
                              variant="outline-secondary"
                              onClick={() => this.loginAs()}
                              type="submit"
                            >
                              <FontAwesomeIcon icon="sign-in" />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                    </div>
                  }


                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Container>
    )
  }
}
