import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {Table, Form} from 'react-bootstrap'
import {filter, map} from 'lodash'
import {documentStatuses, documentTypes, companies} from '@bdswiss/common-enums'
import {canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import style from './documents.module.scss'
import PureComponent from '../PureComponent'
import {getDocumentStatusLabel} from '../utils/rendering'
import {linkClientDocuments, linkClientWithdrawals} from '../utils/links'
import {getFullName, getCountryName, readableDate} from '../useful'

export default class ListDocuments extends PureComponent {
  static contextTypes = {
    showNotification: PropTypes.func,
    documentsProvider: PropTypes.object.isRequired,
    documentsTopVipCountProvider: PropTypes.object.isRequired,
  }

  render() {
    const {showNotification, documentsProvider, documentsTopVipCountProvider} = this.context
    const {documents, agents, actions, viewer, selectedDocuments, toggleDocumentSelection} = this.props
    if (!documents.length) {
      return null
    }

    return (
      <Table bordered hover className={style.table}>
        <thead>
          <tr>
            <th className="text-center">
              <Form.Check
                id="t-list-documents-selection-header"
                checked={!!this.props.selectedDocumentsHeader}
                onChange={(e) => this.props.toggleDocumentSelectionHeader(e.target.checked)}
              />
            </th>
            <th>Full Name</th>
            <th>Company</th>
            <th>Country</th>
            <th>Type</th>
            <th>Expiration</th>
            <th>Created</th>
            <th>Pending Withdrawals</th>
            <th>Status</th>
            {/*<th className={style.assignee}>Assignee</th>*/}
            <th>Reason</th>
          </tr>
        </thead>
        <tbody>
          {filter(documents, (d) => d.client).map((document) => <Document key={document.id} document={document} agents={agents}
            actions={actions} viewer={viewer} documentsProvider={documentsProvider} selectedDocuments={selectedDocuments}
            showNotification={showNotification} toggleDocumentSelection={toggleDocumentSelection}
            documentsCountProvider={documentsTopVipCountProvider}/>)}
        </tbody>
      </Table>
    )
  }
}

const Document = ({document, agents, actions, viewer, documentsProvider, showNotification, selectedDocuments,
  toggleDocumentSelection}) => {
  // const allAgents = [
  //   {
  //     value: null,
  //     label: 'Unassigned',
  //   },
  // ]
  const {id, type, url, expiration, status, client, createdAt, pendingWithdrawals, rejectionReason} = document
  const documentLabel = type ? documentTypes[type].label : ''
  const clientId = client.id
  const linkEnabled = !canQueryAffiliateManagerClients(viewer)
  // const showAssignee = viewer.id === get(assignee, 'id') ||
  //   some(viewer.roles, (r) => ['admin', 'super_user'].includes(r))
  return (
    <tr className={classNames(['t-document', client.topVip ? 'vip' : ''])} key={id}>
      <td className="text-center">
        <Form.Check
          id={`t-list-documents-selection-${id}`}
          checked={!!selectedDocuments[id]}
          onChange={() => toggleDocumentSelection(document)}
        />
      </td>
      <td>
        {linkClientDocuments(getFullName(client), clientId, id, linkEnabled)}
      </td>
      <td>
        {linkClientDocuments(companies[client.company].label, clientId, id, linkEnabled)}
      </td>
      <td>
        {linkClientDocuments(getCountryName(client.address), clientId, id, linkEnabled)}
      </td>
      <td className={style.externalLink}>
        <a href={url} target="_blank" rel="noopener noreferrer">{documentLabel}</a>
      </td>
      <td title={expiration && expiration.fromNow()}>
        {linkClientDocuments(expiration ? readableDate(expiration, false) : '\xA0', clientId, id, linkEnabled)}
      </td>
      <td title={createdAt.fromNow()}>
        {linkClientDocuments(readableDate(createdAt), clientId, id, linkEnabled)}
      </td>
      <td className={style.externalLink}>
        <div>
          {map(pendingWithdrawals, (wd) =>
            <div key={wd.id}>{linkClientWithdrawals(`Withdrawal ID: ${wd.id}`, client.id, wd.id, true)}</div>
          )}
        </div>
      </td>
      <td className="text-center">
        {linkClientDocuments(status ? getDocumentStatusLabel(documentStatuses[status]) : '\xA0', clientId, id, linkEnabled)}
      </td>
      {/*<td>*/}
      {/*  {showAssignee || !assignee*/}
      {/*    ? <SelectAgent*/}
      {/*      placeholderText={unassigned}*/}
      {/*      agents={filter(agents, (a) => a.isActive && a.companies.includes(client.company))}*/}
      {/*      value={showAssignee ? get(assignee, 'id', null) : null}*/}
      {/*      optionsPrefix={allAgents}*/}
      {/*      disabled={!canAssignDocument(viewer)}*/}
      {/*      onChange={(o) => actions.client.upsertDocument(clientId, id, null, type, expiration,*/}
      {/*        document.fileDescription, status, null, document.cardNumber, o.value).then((res) => {*/}
      {/*        showNotification({*/}
      {/*          title: 'Assign Document',*/}
      {/*          message: 'Assigned document successfully',*/}
      {/*          position: 'tr',*/}
      {/*          level: 'success',*/}
      {/*        })*/}
      {/*        documentsProvider.fetch()*/}
      {/*        return res*/}
      {/*      })}*/}
      {/*    />*/}
      {/*    : linkClientDocuments('-', clientId, id, linkEnabled)}*/}
      {/*</td>*/}
      <td>
        {rejectionReason}
      </td>
    </tr>
  )
}
