export default function create(dbClient) {
  return {
    upsertPartnerCustomization(args) {
      const {id, affiliateId, ibId, campaignId, accountTypeCustomizations, minDepositCustomizations,
        leverageCustomizations, initialDemoDeposit, nonDepositorSalesAgent, depositorSalesAgent,
        bonus, gdcDisabled, spoa, classicDefaultMT4Server, autochartist,
      } = args

      const variables = {
        id,
        affiliateId: affiliateId || '',
        ibId: ibId || '',
        campaignId: campaignId || '',
        accountTypeCustomizations: JSON.stringify(accountTypeCustomizations),
        minDepositCustomizations: JSON.stringify(minDepositCustomizations),
        leverageCustomizations: JSON.stringify(leverageCustomizations),
        initialDemoDeposit,
        nonDepositorSalesAgent,
        depositorSalesAgent,
        bonus: JSON.stringify(bonus),
        gdcDisabled: !!gdcDisabled,
        spoa: JSON.stringify(spoa),
        autochartist: JSON.stringify(autochartist),
      }

      if (classicDefaultMT4Server) {
        variables.classicDefaultMT4Server = classicDefaultMT4Server
      }

      const query = `
        mutation (
          $id: Int
          $affiliateId: String
          $ibId: String
          $campaignId: String
          $accountTypeCustomizations: String       
          $minDepositCustomizations: String
          $leverageCustomizations: String
          $initialDemoDeposit: Float
          $nonDepositorSalesAgent: Int
          $depositorSalesAgent: Int
          $bonus: String
          $gdcDisabled: Boolean
          $spoa: String
          $classicDefaultMT4Server: Server
          $autochartist: String
        ) {
          upsertPartnerCustomization (
            id: $id
            affiliateId: $affiliateId
            ibId: $ibId
            campaignId: $campaignId
            accountTypeCustomizations: $accountTypeCustomizations
            minDepositCustomizations: $minDepositCustomizations
            leverageCustomizations: $leverageCustomizations
            initialDemoDeposit: $initialDemoDeposit
            nonDepositorSalesAgent: $nonDepositorSalesAgent
            depositorSalesAgent: $depositorSalesAgent
            bonus: $bonus
            gdcDisabled: $gdcDisabled
            spoa: $spoa
            classicDefaultMT4Server: $classicDefaultMT4Server
            autochartist: $autochartist
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    getIbFromIbId(args) {
      const {ibId, isIbApproved, countries} = args

      const query = `
        {
          getIbFromIbId(ibId: ${ibId}, isIbApproved: ${isIbApproved}, countries: [${countries}]) {
            id
          }
        }
      `

      return dbClient.query(query, args)
    },
  }
}
