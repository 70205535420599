import React from 'react'
import PropTypes from 'prop-types'
import {Col, Form, Row, Button} from 'react-bootstrap'
import {shortenFilename} from '../textUtils'
import StylishSelect from '../components/StylishSelect'
import FormComponent from '../components/FormComponent'
import style from './jobs.module.scss'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

export default class DormantFeeForm extends FormComponent {

  static propTypes = {
    values: PropTypes.object.isRequired,
    accountTypes: PropTypes.array.isRequired,
    actions: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    clearForm: PropTypes.func.isRequired,
    errors: PropTypes.object,
  }

  componentDidMount() {
    if (this.fileInput) {
      // this function is used for file upload in testing
      this.fileInput['t-file-input-on-change'] = (files) => this.valueChanged('inputFile', files)
    }
  }

  generateInputProps(key) {
    const res = super.generateInputProps(key)
    if (this.props.errors && key in this.props.errors && !this.props.errors[key].isValid) {
      res.bsStyle = 'error'
    }
    return res
  }

  generateFileInputProps(key) {
    const res = super.generateFileInputProps(key)
    if (this.props.errors && key in this.props.errors && !this.props.errors[key].isValid) {
      res.isInvalid = true
    }
    return res
  }

  renderErrorMessage(key) {
    const {errors} = this.props
    if (errors && key in errors && !errors[key].isValid) {
      return (
        errors[key].error &&
          <p className="help-block"> {errors[key].error.reason} </p>
      )
    }
  }

  onSubmit(e) {
    e.preventDefault()
    this.props.onSave()
  }

  render() {
    const {values} = this.props
    return (
      <Row>
        <Col md={12}>
          <form className={style.dormantFeeForm} onSubmit={(e) => this.onSubmit(e)}>
            <StylishSelect.Input
              id="t-dormantfee-form-account-type"
              label="Account Type *"
              options={StylishSelect.enumToStylishOptions(this.props.accountTypes)}
              {...this.generateInputProps('accountType')}
            />
            <StylishSelect.Input
              id="t-dormantfee-form-action"
              label="Action *"
              options={StylishSelect.enumToStylishOptions(this.props.actions)}
              {...this.generateInputProps('action')}
            />
            <StylishSelect.Input
              id="t-dormantfee-form-type"
              label="Type *"
              options={StylishSelect.enumToStylishOptions(this.props.inactivityFeeTypes)}
              {...this.generateInputProps('type')}
            />
            <div className="formgroup" id="t-dormantfee-form-inputfile"
              ref={(el) => (this.fileInput = el)}
            >
              <label className="btn btn-default btn-file"
                title={values.inputFile ? values.inputFile[0].name : ''}
              >
                <FontAwesomeIcon icon="paperclip" />&nbsp;
                {values.inputFile
                  ? shortenFilename(values.inputFile[0].name)
                  : 'Input File *'
                }
                <Form.Control
                  type="file"
                  {...this.generateFileInputProps('inputFile')}
                />
              </label>
            </div>
            <div className="clearfix">{null}</div>
            {this.renderErrorMessage('inputFile')}
            <Button id="t-dormantfee-form-clear-btn" variant="outline-secondary"
              onClick={this.props.clearForm}
            >
              Clear
            </Button>
            <Button id="t-dormantfee-form-save-btn" variant="success" className="float-right"
              type="submit" disabled={!this.props.isTouched}
            >
              Start
            </Button>
            <Button id="t-dormantfee-form-cancel-btn" variant="danger" className="float-right mr-1"
              onClick={this.props.onCancel}
            >
              Cancel
            </Button>
          </form>
        </Col>
      </Row>
    )
  }
}
