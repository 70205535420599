export default function create(dbClient) {
  return {

    addCopyTrading(id) {
      const query = `
        mutation (
          $ghostCopierId: Int!
        ) {
          addCopyTrading(
            ghostCopierId: $ghostCopierId
          )
        }
      `
      return dbClient.query(query, {ghostCopierId: id})
    },
  }
}
