import React from 'react'
import moment from 'moment'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {blacklistTypes, countries} from '@bdswiss/common-enums'
import {Col, Form, Row, Card, Button, ButtonToolbar} from 'react-bootstrap'
import {setIn} from '../stateUtils'
import style from './blacklists.module.scss'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import StylishSelect from '../components/StylishSelect'
import {isValidEmail, isValidIp, isValidPhone} from '../utils/validators'

class BlacklistForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    blacklistProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const state = {
      form: this.props.data ? this.props.data : {},
      formError: {},
      formState: {},
    }
    this.setState(state)
  }

  formValueChanged(name, value) {
    let {state} = this
    if (name === 'type') {
      state = setIn(state, ['form', 'value'], '')
    }
    state = setIn(state, ['form', name], value)
    state = setIn(state, ['formError', name], null)
    this.setState(state)
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  validateForm() {
    const errors = {}
    const {form} = this.state

    if (!form.type) {
      errors.type = true
    }

    if (form.type === 'country' && !form.value) {
      errors.value = true
    }
    if (form.type === 'email' && !isValidEmail(form.value)) {
      errors.value = true
    }
    if (form.type === 'ipAddress' && !isValidIp(form.value)) {
      errors.value = true
    }
    if (form.type === 'phone' && !isValidPhone(form.value)) {
      errors.value = true
    }

    if (!form.reason) {
      errors.reason = true
    }

    this.setFormErrors(errors)
    return isEmpty(errors)
  }

  formAction = () => {
    const {form} = this.state
    return this.props.actions.blacklist.upsertBlacklist(form)
  }

  close = () => {
    this.context.router.push('/blacklists')
  }

  renderButton = () => {
    const {state, state: {form: {id}, formState: {isLoading}}, context: {blacklistProvider}} = this

    return (
      <Row>
        <Col xs={12}>
          <ButtonToolbar className="float-right">
            <Button
              id="t-blacklists-editor-cancel-button"
              onClick={this.close}
              variant="outline-secondary"
              className="mr-3"
            >Cancel</Button>
            <Button
              id="t-blacklists-editor-create-update-button"
              variant="success"
              disabled={isLoading}
              onClick={() => {
                const ok = this.validateForm()
                if (ok) {
                  if (!isLoading) {
                    this.setState(setIn(state, ['formState', 'isLoading'], true))
                    this.formAction()
                      .then((res) => {
                        this.context.router.push('/blacklists')
                        blacklistProvider && blacklistProvider.fetch()
                      })
                      .catch((e) => {
                        this.setState(setIn(state, ['formState', 'isLoading'], false))
                        this.context.logError(e)
                      })
                  }
                }
              }}
            >
              {id ? 'Update' : 'Create'}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    )
  }

  render() {
    const {form: {type, value, expiresAt, reason}, formError} = this.state

    return (
      <Card className={style.form}>
        <Card.Body>
          <Row className={style.fields}>
            <Col xs={6}>
              <StylishSelect.Input
                id="t-blacklists-form-type"
                label="Type *"
                value={type}
                onChange={(evt) => this.formValueChanged('type', evt.value)}
                placeholderText="Select type"
                options={StylishSelect.enumToStylishOptions(blacklistTypes)}
              />
            </Col>
            <Col xs={6}>
              {type === 'country' ?
                <StylishSelect.Input
                  id="t-blacklists-form-country"
                  label="Country *"
                  value={value}
                  onChange={(evt) => this.formValueChanged('value', evt.value)}
                  placeholderText="Select country"
                  options={StylishSelect.enumToStylishOptions(countries)}
                />
                :
                <Form.Group>
                  <Form.Label>Value *</Form.Label>
                  <Form.Control
                    id="t-blacklists-form-value"
                    type="input"
                    value={value}
                    isInvalid={formError.value}
                    onChange={(evt) => this.formValueChanged('value', evt.target.value)}
                    placeholder="Enter value"
                  />
                </Form.Group>
              }
            </Col>
            <Col xs={6}>
              <label>Expires</label>
              <DateTime
                id="t-deposits-created-from-expires-at"
                timeFormat={false}
                onChange={(evt) => this.formValueChanged('expiresAt', evt ? evt.format('ll') : '')}
                value={expiresAt ? moment(expiresAt).format('DD/MM/YYYY') : ''}
                closeOnSelect
                className={style.datetime}
              />
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Reason *</Form.Label>
                <Form.Control
                  id="t-blacklists-form-reason"
                  as="textarea"
                  rows={2}
                  value={reason}
                  isInvalid={formError.reason}
                  onChange={(evt) => this.formValueChanged('reason', evt.target.value)}
                  placeholder="Enter a reason"
                />
              </Form.Group>
            </Col>
          </Row>
          {this.renderButton()}
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(BlacklistForm)
