import React from 'react'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {getFormattedAmount} from '../useful'

export default class ConfirmationModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    hasDisabledWithdrawals: PropTypes.bool,
    header: PropTypes.string,
    body: PropTypes.node.isRequired,
    confirmLabel: PropTypes.string,
    cancelLabel: PropTypes.string,
    confirmStyle: PropTypes.string,
    cancelStyle: PropTypes.string,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  };

  static defaultProps = {
    header: 'Warning',
    confirmLabel: 'OK',
    confirmStyle: 'primary',
    cancelLabel: 'Cancel',
  };

  render() {
    const {show, header, body, confirmLabel, cancelLabel, confirmStyle, cancelStyle, onConfirm, onCancel,
      extraBody, totalDeposits, totalWithdrawals, currency, isWithdrawal} = this.props

    return (
      <Modal
        id="t-confirmation-modal"
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>{header}</strong>
        </Modal.Header>
        <Modal.Body>
          {body}
          {!isEmpty(extraBody) && [<br key="1" />, <br key="2" />, ...extraBody]}
          {isWithdrawal && <table style={{width: '40%', marginTop: '20px'}}>
            <tbody>
              <tr>
                <td>Total Deposits:</td>
                <td>{getFormattedAmount({currency: currency, amount: totalDeposits})}</td>
              </tr>
              <tr>
                <td>Total Withdrawals:</td>
                <td>{getFormattedAmount({currency: currency, amount: totalWithdrawals})}</td>
              </tr>
            </tbody>
          </table>}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              id="t-confirmation-modal-cancel-btn"
              variant={cancelStyle || 'outline-secondary'}
              size="sm"
              onClick={onCancel}
              className="mr-1"
            >
              {cancelLabel}
            </Button>
            <Button
              id="t-confirmation-modal-accept-btn"
              variant={confirmStyle || 'success'}
              size="sm"
              onClick={onConfirm}
            >
              {confirmLabel}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
