import {useState, useCallback} from 'react'

import {whiteLabels} from '@bdswiss/common-enums'
import {Button, Modal} from 'react-bootstrap'
import classNames from 'classnames'
import {pick} from 'lodash'

import runtimeConfig from '../../config'
import StylishSelect from '../../components/StylishSelect'
import classes from './styles.module.scss'

const {whiteLabels: whiteLabelsConfig} = runtimeConfig.getInstance()
const activeWhiteLabels = pick(whiteLabels, [whiteLabels.tauroMarkets.key, whiteLabels.tauroMarketsMauritius.key])

export const ActiveWhiteLabelNames = Object.values(activeWhiteLabels).map((wl) => wl.value)

const whiteLabelUrlsMap = ActiveWhiteLabelNames.reduce(
  (memo, wln) => ({
    [wln]: whiteLabelsConfig[wln].frontendUrl,
    ...memo,
  }),
  {},
)

export function InviteClientsButton({viewer}) {
  const [isModalShown, setIsModalShown] = useState(false)
  const toggleModal = useCallback(() => {
    setIsModalShown(!isModalShown)
  }, [isModalShown])

  const viewerWhiteLabelNames = ActiveWhiteLabelNames.filter((wl) => viewer.companies?.includes(wl))
  const viewerWhiteLabels = pick(activeWhiteLabels, viewerWhiteLabelNames)

  const [selectedWhiteLabel, setSelectedWhiteLabel] = useState(viewerWhiteLabelNames[0])
  const handleSelectChange = useCallback((target) => {
    setSelectedWhiteLabel(target.value)
  }, [])

  const ibLink = `${whiteLabelUrlsMap[selectedWhiteLabel]}/register/ib?partnerManagerId=${viewer.id}`
  const affiliateLink = `${whiteLabelUrlsMap[selectedWhiteLabel]}/register/affiliate?partnerManagerId=${viewer.id}`

  return (
    <>
      <Button variant="success" size="sm" className="" onClick={toggleModal}>
        Invite Clients
      </Button>
      <Modal keyboard centered show={isModalShown} onHide={toggleModal} dialogClassName={classes.modalContent}>
        <Modal.Header closeButton>
          <Modal.Title as={'h5'} className={classes.fw700}>
            Invite Clients
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classNames(['d-flex flex-column', classes.gap4])}>
          <div>Here is your personal referral links to share with your clients and grow your network.</div>
          <div className={classNames(['d-flex align-items-center', classes.gap4])}>
            <h6 className="m-0">White Label</h6>
            <StylishSelect
              options={StylishSelect.enumToStylishOptions(viewerWhiteLabels)}
              className={classes.whiteLabelsPicker}
              onChange={handleSelectChange}
              value={selectedWhiteLabel}
            />
          </div>
          {/* https://skyground.atlassian.net/browse/PT-2424 Activation task
          <div>
            <h6>IB Referral Link</h6>
            <div className={classNames(['p-3 rounded-lg', classes.copySection])}>
              {ibLink}
              <i
                onClick={() => navigator.clipboard?.writeText(ibLink)}
                className={classNames(['fa fa-copy text-primary float-right', classes.fs5, classes.pointer])}
              />
            </div>
          </div>
          */}
          <div>
            <h6>Affiliate Referral Link</h6>
            <div className={classNames(['p-3 rounded-lg', classes.copySection])}>
              {affiliateLink}
              <i
                onClick={() => navigator.clipboard?.writeText(affiliateLink)}
                className={classNames(['fa fa-copy text-primary float-right', classes.fs5, classes.pointer])}
              />
            </div>
          </div>
          <div>
            Make sure to use these links if you want your clients to be connected to your account as affiliates,
            ensuring you receive credit for their referrals.
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
