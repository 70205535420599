import React from 'react'
import PropTypes from 'prop-types'
import {find, get, isEmpty} from 'lodash'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Row, Col, Card, Container} from 'react-bootstrap'
import TagList from './TagList'
import TagForm from './TagForm'
import PureComponent from '../PureComponent'
import {tagCategoryProvider} from './providers'
import {compose, provideProps, mountDataProviders} from '../decorators'

class TagEdit extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    tagCategoryProvider: PropTypes.object.isRequired,
  }

  cancel() {
    const {match} = this.props
    this.context.router.push(`/tag-categories/${get(match, 'params.tagCategory')}`)
  }

  save() {
    const {match} = this.props
    this.context.router.push(`/tag-categories/${get(match, 'params.tagCategory')}`)
    this.context.tagCategoryProvider.fetch()
  }

  render() {
    const {tag, match, tagCategories} = this.props
    const tagId = get(match, 'params.tagId')
    const subTag = tagId ? find(tag.tags, {id: Number(tagId)}) : undefined

    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/tag-categories"
            active={false}
          >
            <BreadcrumbItem>
              Tag Categories
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            {tag.name}
          </BreadcrumbItem>
        </Breadcrumb>

        <Row>
          <Col xs={7}>
            <TagList
              tag={tag}
              tagCategory={this.props.tagCategory}
            />
          </Col>
          {
            !isEmpty(tag.tags) && <Col xs={5}>
              {tagId === undefined ?
                <Card><Card.Body>Select tag from the list.</Card.Body></Card>
                : <TagForm
                  tag={subTag}
                  tagCategories={tagCategories}
                  onCancel={this.cancel.bind(this)}
                  onSave={this.save.bind(this)}
                />
              }
            </Col>
          }
        </Row>
      </Container>
    )
  }
}

export default compose(
  provideProps((state) => {
    const {tagCategories} = state

    return ({
      tag: state.tag,
      tagCategories,
    })
  }),

  mountDataProviders({tagCategoryProvider}),
)(TagEdit)
