import {Button, Modal} from 'react-bootstrap'
import {FlavorContext} from '../../context'
import {useContext} from 'react'

const headers = {
  ib: 'Approve IB Account Request?',
  pamm: 'Approve PAMM Manager Account Request?',
}

const bodies = {
  ib: username => `Do you want to approve the IB Manager Account request for ${username}?`,
  pamm: username => `Do you want to approve the PAMM Manager Account request for ${username}?`,
}

export const ApproveModal = ({show, onClose, onSubmit, username}) => {
  const flavor = useContext(FlavorContext)
  return (<Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      {headers[flavor]}
    </Modal.Header>
    <Modal.Body>
      {bodies[flavor](username)}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={onClose}>Cancel</Button>
      <Button variant="success" onClick={onSubmit}>Yes, Approve</Button>
    </Modal.Footer>
  </Modal>
  )
}
