import {isNil} from 'lodash'

export function validateNonempty(obj, key, errors) {
  if ((!obj[key] && key in obj) || isNil(obj[key]) || obj[key].length === 0) {
    errors[key] = 'This field is required.'
  }
}

export function validatePaymentOption(paymentOption) {
  const errors = {}
  validateNonempty(paymentOption, 'name', errors)
  validateNonempty(paymentOption, 'provider', errors)
  validateNonempty(paymentOption, 'localizationKey', errors)
  validateNonempty(paymentOption, 'logoUrl', errors)

  errors._direct_count = Object.keys(errors).length

  return errors
}

export function noPaymentOptionValidationError() {
  return {
    _direct_count: 0,
    media: [],
    _count: 0,
  }
}
