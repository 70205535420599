import {getFetchInterval} from '../../useful'

export const roleDetailsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (!props.roleId) {
      return null
    }

    const query = `{
      role(
        id: "${props.roleId}"
      ) {
        id
        name
        description
        users {
          id
          email
          firstName
          lastName
        }
        permissions {
          id
          name
          description
        }
      }
    }`
    return query
  },

  onData: (res, dispatch) =>
    dispatch(
      'Role details loaded',
      (state, res) => ({
        ...state,
        role: {
          ...res.role,
          permissions: res.role.permissions
            ? res.role.permissions.sort((a, b) => a.name.localeCompare(b.name))
            : [],
        },
      }),
      [res]
    ),
}
