import {get} from 'lodash'
import {getPageSize, getOffset} from '../useful'

export const bookAllocationsProvider = {

  getQuery: (props) => {
    try {
      const {bookAllocationsSort, bookAllocationsPage} = props
      const query = `{
        bookAllocations(
          limit: ${getPageSize()}
          offset: ${getOffset(bookAllocationsPage)}
          orderBy: ${bookAllocationsSort.orderBy}
          orderDirection: ${bookAllocationsSort.orderDirection}
        ) {
          id
          ibs
          bookCategory
          countries
          createdAt
          updatedAt
          campaigns
        }
        bookAllocationsCount
      }`

      return query

    } catch (e) {
      throw e
    }
  },

  onData: (res, dispatch, props) => dispatch(
    'BookAllocations loaded',
    (state, res) => ({
      ...state,
      bookAllocations: res.bookAllocations,
      bookAllocationsCount: res.bookAllocationsCount,
    }),
    [res]
  ),
}

export const  bookAllocationProvider = {
  getQuery: (props) => {
    const bookAllocationId = get(props.match, 'params.bookAllocationId')
    const query = `{
      bookAllocation(id: ${bookAllocationId}) {
        id
        ibs
        bookCategory
        countries
        createdAt
        updatedAt
        campaigns
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'BookAllocation loaded',
    (state, res) => ({
      ...state,
      bookAllocation: res.bookAllocation,
    }),
    [res]
  ),
}
