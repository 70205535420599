import React from 'react'
import PropTypes from 'prop-types'
import {map, filter, get, has} from 'lodash'
import {canViewCallLogs, canQueryClients} from '@bdswiss/common-permissions'
import {Row, Col, Table, Card, Pagination, Container} from 'react-bootstrap'
import {callLogsProvider} from './providers'
import style from './calllogs.module.scss'
import CallLogRow from './CallLogRow'
import CallLogsFilter from './CallLogsFilter'
import {events} from '../enums'
import {getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, checkRights} from '../decorators'
import CallLogDetails from './CallLogDetails'

class CallLogs extends React.Component {
  static contextTypes = {
    callLogsProvider: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)

    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {}
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.callLogsProvider.fetch()
  }

  render() {
    const {match, callLogs, callLogsPage, callLogsCount, viewer, callRecordingsUri} = this.props
    const callLogId = get(match, 'params.callLogId')
    const filteredCallLogs = filter(callLogs, (l) => !has(l, 'client') || get(l, 'client') !== null)

    return (
      <Container>
        <Row>
          <Col xs={12}>
            <CallLogsFilter />
          </Col>
          <Col xs={callLogId ? 8 : 12}>
            <Card style={{overflowX: 'scroll'}}>
              <Table bordered hover className={style.table}>
                <thead>
                  <tr>
                    <th></th>
                    <th> # </th>
                    <th> User </th>
                    {canQueryClients(viewer) && (<th> Client </th>)}
                    <th> Score </th>
                    <th> Company </th>
                    <th> Recording </th>
                    <th> Duration </th>
                    <th> Date/Time </th>
                    <th> Provider </th>
                  </tr>
                </thead>
                <tbody>
                  {map(filteredCallLogs, (callLog) =>
                    <CallLogRow
                      key={callLog.id} callLog={callLog}
                      baseUri={callRecordingsUri}
                      viewer={viewer} />)}
                </tbody>
              </Table>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, callLogsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(callLogsPage, getPageCount(callLogsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={() => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, callLogsPage: callLogsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={() => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, callLogsPage: callLogsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === callLogsPage}
                      key={page}
                      onClick={() => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, callLogsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
          {callLogId && <Col xs={4}>
            <CallLogDetails callLogId={callLogId} viewer={viewer} baseUri={callRecordingsUri} />
          </Col>}
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canViewCallLogs),

  uiMount(() => ['ui', 'call-logs']),

  provideProps((state, uiState) => {
    const {callLogs, callLogsCount, config: {callRecordingsUri}} = state
    const {callLogsSort, callLogsPage, callLogsFilter} = uiState

    return ({
      sort: callLogsSort,
      callLogsPage: callLogsPage || 1,
      callRecordingsUri,
      callLogs,
      callLogsCount,
      callLogsFilter
    })
  }),

  mountDataProviders({callLogsProvider}),
)(CallLogs)
