import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Row, Col, Badge} from 'react-bootstrap'
import {countries, eIdDataSourceCodes, eIdMatchTypes} from '@bdswiss/common-enums'
import {filter, get, map, keyBy, groupBy, keys, each, find, values, capitalize, size, isEmpty} from 'lodash'
import PureComponent from '../PureComponent'
import {parseJSON} from '../common/utils'
import mainStyle from '../components/components.module.scss'
import style from './EIDVerificationResultFullViewModal.module.scss'

const extractAutonomousCodeSet = (codes) => filter(codes, (a) => a.code.indexOf('AU') > -1
  && a.code.indexOf('WATCHLIST') < 0)
const extractMatchTypeCodeSet = (codes) => filter(codes, (a) => a.code.indexOf('MT') > -1)

const expandAutonomousKey = (key) => {
  const tokens = key.split('-')
  return {
    category: get(tokens, '0'),
    code: get(tokens, '1'),
    field: get(tokens, '2'),
  }
}

const expandMatchTypeKey = (key) => {
  const tokens = key.split('-')
  const dataSource = get(tokens, '2', '')
  const dataSourceKey = dataSource.substring(0, 3)
  const dataSourceIndex = dataSource.substring(3)
  return {
    matchType: get(tokens, '0'),
    country: get(tokens, '1'),
    dataSourceKey,
    dataSource,
    dataSourceIndex,
    field: get(tokens, '3'),
  }
}

class EIDVerificationResultFullViewModal extends PureComponent {

  static propTypes = {
    rawResult: PropTypes.string.isRequired,
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const {rawResult} = this.props
    const parsedResult = parseJSON(rawResult)
    if (parsedResult instanceof Error) {
      return
    }

    const identityResultSets = this.extractIdentityResultSets(parsedResult)
    const informationUsed = this.extractInformationUsed(parsedResult)
    const {pepSanctionsCheckResult} = parsedResult

    this.setState({
      ...identityResultSets,
      informationUsed,
      pepSanctionsCheckResult,
    })
  }

  extractIdentityResultSets(rawResult) {
    const identityVerificationCodes = get(rawResult, 'identity.codes.messages')

    const identityAUSet = extractAutonomousCodeSet(identityVerificationCodes)
    const identityAUResults = keyBy(map(identityAUSet, (a) => ({
      ...expandAutonomousKey(a.code),
      message: a.value,
    })), 'field')

    const identityMTSet = extractMatchTypeCodeSet(identityVerificationCodes)
    const identityMTResults = groupBy(map(identityMTSet, (a) => ({
      ...expandMatchTypeKey(a.code),
      message: a.value,
    })), 'dataSource')

    return {identityAUResults, identityMTResults}
  }

  extractInformationUsed(rawResult) {
    return {
      dob: get(rawResult, 'identity.dob'),
      nationalId: get(rawResult, 'identity.nationalid'),
      completeName: get(rawResult, 'identity.completename'),
      surname: get(rawResult, 'identity.surnameFirst'),
      givenName: get(rawResult, 'identity.givenfullname'),
      address: {
        locality: get(rawResult, 'address.locality'),
        postalCode: get(rawResult, 'address.postalCode'),
        countryCode: get(rawResult, 'address.countryCode'),
        countryName: get(rawResult, 'address.countryName'),
        addressLine1: get(rawResult, 'address.addressLine1'),
        addressLine2: get(rawResult, 'address.addressLine2'),
      },
    }
  }

  getDatasourcesFieldMatchMessage(field, datasources) {
    const matchMessages = []
    each(datasources, (dsMessages) => {
      const matchMessage = find(dsMessages, (d) => d.field === field)
      if (matchMessage) matchMessages.push(matchMessage)
    })
    return matchMessages
  }

  renderInformationUsed() {
    const {informationUsed: {dob, nationalId, completeName, surname, givenName, address}} = this.state
    return (
      <div>
        <Row>
          <Col xs={12}>
            <h3>Name Details</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <dl>
              <dt>Complete Name</dt>
              <dd>{completeName}</dd>
              <dt>Given Name</dt>
              <dd>{givenName}</dd>
              <dt>Surname</dt>
              <dd>{surname}</dd>
            </dl>
          </Col>
          <Col xs={6}>
            <dl>
              <dt>Date of Birth</dt>
              <dd>{dob}</dd>
              <dt>National ID</dt>
              <dd>{nationalId}</dd>
            </dl>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h3>Address</h3>
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <dl>
              <dt>Address Line1</dt>
              <dd>{address.addressLine1}</dd>
              <dt>Address Line2</dt>
              <dd>{address.addressLine2}</dd>
              <dt>Postal Code</dt>
              <dd>{address.postalCode}</dd>
            </dl>
          </Col>
          <Col xs={6}>
            <dl>
              <dt>Locality [Region]</dt>
              <dd>{address.locality}</dd>
              <dt>Country</dt>
              <dd>{`${address.countryName} [${address.countryCode}]`}</dd>
            </dl>
          </Col>
        </Row>
      </div>
    )
  }

  renderMatchMessage(matchMessage, index) {
    const {country, dataSourceKey, dataSourceIndex, matchType, message, dataSource} = matchMessage || {}
    const countryLabel = get(find(countries, {value: country}), 'label', country)
    const dsLabel = get(find(eIdDataSourceCodes, {key: dataSourceKey}), 'label', dataSource)
    const matchTypeLabelClass = get(find(eIdMatchTypes, {key: matchType}), 'bsStyle', 'default')

    return (
      [
        <dt key={`eidv-match-title-${index}`}>
          <Badge pill variant={matchTypeLabelClass}>
            {countryLabel} {dsLabel} {`${!isNaN(+dataSourceIndex) ? `[${dataSource}]` : ''}`}
          </Badge>
        </dt>,
        <dd key={`eidv-match-info-${index}`}>{message}</dd>,
      ]
    )
  }

  renderMatches() {
    const {identityAUResults, identityMTResults} = this.state
    const hasResults = keys(identityAUResults).length > 0

    if (!hasResults) {
      return <h5>No Match Found With Above Details</h5>
    }

    return keys(identityAUResults).map((field, index) => {

      const matchMessages = this.getDatasourcesFieldMatchMessage(field, values(identityMTResults))
      const matchMessagesJsx = matchMessages.map(this.renderMatchMessage)

      return (
        <div className={style.eidMatchMesageContainer} key={index}>
          <h5>{capitalize(field)}</h5>
          <dl>
            {matchMessagesJsx}
          </dl>
          <div><p>Aggregated Autonomous Result[AU]</p> {get(identityAUResults[field], 'message')}</div>
        </div>
      )
    })


  }

  renderPepSanctionsMatches() {
    const {pepSanctionsCheckResult} = this.state
    const {matchedPEPMessages, matchedSanctionsMessages, isPEP, hasSanctions} = pepSanctionsCheckResult
    return (
      <div>
        <Row>
          <Col xs={6}>
            <dt>Is Politically Exposed</dt>
            <dd>{isPEP ? 'Yes' : 'No'}</dd>
          </Col>
          <Col xs={6}>
            <dt>Has Sanctions</dt>
            <dd>{hasSanctions ? 'Yes' : 'No'}</dd>
          </Col>
        </Row>
        <div>
          {size(matchedPEPMessages) > 0 &&
            <pre>
              <h5>PEP Matches</h5>
              {JSON.stringify(matchedPEPMessages, null, 2)}
            </pre>
          }
          {size(matchedSanctionsMessages) > 0 &&
            <pre>
              <h5>Sanctions Matches</h5>
              {JSON.stringify(matchedSanctionsMessages, null, 2)}
            </pre>
          }
        </div>
      </div>
    )
  }

  render() {
    const {onHide, show} = this.props
    const {pepSanctionsCheckResult} = this.state

    return (
      <Modal
        id="t-eid-full-result-modal"
        keyboard
        show={show}
        onHide={onHide}
        className={style.modal}
      >
        <Modal.Header closeButton className={mainStyle.modalHeader}>
          {'eID Result Full View'}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <div>
                {this.renderInformationUsed()}
              </div>
              {pepSanctionsCheckResult && (!isEmpty(pepSanctionsCheckResult.matchedPEPMessages) || !isEmpty(pepSanctionsCheckResult.matchedSanctionsMessages)) &&
                <div>
                  <h3>Watchlist PEP/Sanctions</h3>
                  {this.renderPepSanctionsMatches()}
                </div>
              }
              <div>
                <h3>Matches</h3>
                {this.renderMatches()}
              </div>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    )
  }
}
export default EIDVerificationResultFullViewModal
