import React from 'react'
import {Container, Row, Col, Badge} from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import {appointmentCategories, appointmentColors} from '@bdswiss/common-enums'
import {get} from 'lodash'
import PureComponent from '../PureComponent'
import style from './appointments.module.scss'

export default class AppointmentNotification extends PureComponent {
  static propTypes = {
    viewer: PropTypes.object.isRequired,
    appointment: PropTypes.object,
    removeAppointmentNotification: PropTypes.func.isRequired,
  }

  render() {
    const {appointment: {start, category, client, user, description, color}, viewer,
      appointment, removeAppointmentNotification} = this.props
    return (
      <Container fluid onClick={() => removeAppointmentNotification(appointment)}>
        <Row>
          <Col md={6}>
            <h4>
              {moment(start).format('HH:mm a DD/MM/YYYY')}{' '}
              <Badge
                className={style.badge}
                style={{backgroundColor: appointmentColors[color].hex}}
              >&nbsp;</Badge>
            </h4>
          </Col>
          {category && <Col md={6}>
            <h4>
              {get(appointmentCategories, `${category}.label`)}
            </h4>
          </Col>}
        </Row>
        <Row>
          <Col md={6}>
            <p>
              Client: {client.firstName} {client.lastName} <span>({client.id})</span>
            </p>
          </Col>
          <Col md={6}>
            <p>
              Assignee: {user.firstName} {user.lastName} {viewer.id === user.id && '(You)'}
            </p>
          </Col>
        </Row>
        {description && <Row>
          <Col md={12}>
            <p>
              <strong className={style.descriptionLabel}>Description:</strong><br/>
              {description}
            </p>
          </Col>
        </Row>}
      </Container>
    )
  }
}
