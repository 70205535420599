import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import ReasonModal from '../components/ReasonModal'
import {underMonitoringReasons} from '@bdswiss/common-enums'

export default class UnderMonitoring extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  };

  render() {
    const {show, onCancel, onSave} = this.props
    return (
      <ReasonModal
        show={show}
        header={'Under Monitoring Reason Descriptor'}
        placeholder='Choose Under Monitoring Reason'
        onCancel={onCancel}
        reasons={underMonitoringReasons}
        onDone={(reason, selectedReasonCode) => {
          onSave(true, reason, selectedReasonCode)
          onCancel()
        }}
        warningMessage={'These reasons are not shown to client, this is purely for internal needs.'}
        submitButtonBsClass={'success'}
        submitButtonText={'Submit'}
      />
    )
  }
}
