import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Card, Table, Container} from 'react-bootstrap'
import classNames from 'classnames'
import {tableauReportTypes} from '@bdswiss/common-enums'
import {canViewOverallSalesRetentionReport, canViewSalesRetentionReport, canQueryStats} from '@bdswiss/common-permissions'
import TableauReport from '../components/TableauReport'
import {find} from 'lodash'
import {compose, provideProps, mountDataProviders, checkRights} from '../decorators'
import {statsProvider} from './providers'
import {setIn} from '../stateUtils'
import {getFullName} from '../useful'
import {events} from '../enums'
import style from './stats.module.scss'
import PureComponent from '../PureComponent'
import {agentTypes} from '../client/ChangeAgent'

const reportAccesses = {
  [tableauReportTypes.overallSalesRetention.key]: (viewer) => canViewOverallSalesRetentionReport(viewer),
  [tableauReportTypes.salesRetention.key]: (viewer) => canViewSalesRetentionReport(viewer),
}

class Stats extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.statsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    statsProvider: PropTypes.object.isRequired,
  };

  selectUser(userId) {
    this.props.dispatch(
      'Filter clients by sales agent from stats',
      (state) => setIn(state, ['clientsUi', 'clientsSalesAgents'], [userId], true)
    )
    this.context.router.push('/clients')
  }

  renderUser(user) {
    const {id, salesClientsCount, isActive} = user
    const isSalesAgent = agentTypes.salesAgent.filter(user)
    const title = !isActive ? 'Deactivated User' : !isSalesAgent ? 'Not a Sales Agent' : ''
    const props = {
      title,
      className: classNames({clickable: true, [style.invalid]: !isActive || !isSalesAgent}),
      onClick: () => this.selectUser(id),
    }

    return (
      <tr className="t-stats-user" key={id}>
        <td {...props}>{id}</td>
        <td {...props}>{getFullName(user)}</td>
        <td {...props}>{salesClientsCount}</td>
      </tr>
    )
  }

  render() {
    const {stats, viewer, config: {tableauReports}} = this.props
    const user = find(stats, (user) => user.id === viewer.id)
    const type = find((tableauReportTypes), (r) => reportAccesses[r.key](viewer))

    if (type && tableauReports.showTableauReports) {
      const parameters = type.requiresParameters ? {'Email Current Agent': user.email} : {}

      return (
        <Container fluid>
          <Row>&nbsp;</Row>
          <Row>
            <Col xs={12} style={{height: 600}}>
              <TableauReport
                url={tableauReports.urls[type.key]}
                parameters={parameters}
                options={{height: '100%', width: '100%', hideTabs: false, hideToolbar: true}}
              />
            </Col>
          </Row>
        </Container>
      )
    } else if (type && !tableauReports.showTableauReports) {
      return (
        <Container fluid>
          <Row>&nbsp;</Row>
          <Row>
            <h2>Tableau Report is disabled for now.</h2>
          </Row>
        </Container>
      )
    } else {
      const salesStats = stats
        .filter((user) => user.salesClientsCount > 0)
        .sort((a, b) => b.salesClientsCount - a.salesClientsCount)

      return (
        <Container fluid>
          <Row>
            <Col xs={12}>
              <div className={`h4 ${style.toolbar}`}>
                Clients per Sales Agent
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card className="no-filter">
                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Full Name</th>
                      <th>Clients</th>
                    </tr>
                  </thead>
                  <tbody>
                    {salesStats.map((user) => this.renderUser(user))}
                  </tbody>
                </Table>
              </Card>
            </Col>
          </Row>
        </Container>
      )
    }
  }
}

export default compose(
  checkRights(canQueryStats),

  provideProps((state) => {
    const {stats, config} = state
    return {
      // data
      stats,
      config,
    }
  }),

  mountDataProviders({statsProvider}),
)(Stats)
