import React from 'react'
import PropTypes from 'prop-types'
import {get, uniqBy} from 'lodash'
import {Tab, Tabs} from 'react-bootstrap'
import {depositVendors} from '@bdswiss/common-enums'
import {renderCloseTab} from '../utils/rendering'
import Events from './Events'
import {getActivityLogsByObjectId} from '../useful'
import {uiMount} from '../decorators'
import {uiStatePath} from './Client'
import PureComponent from '../PureComponent'
import BankWireDetails from './BankWireDetails'

const EventsMounted = uiMount(() => [...uiStatePath, 'Events'])(Events)

export default class DepositDetails extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object,
  };

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    depositId: PropTypes.number,
    bonusOfferId: PropTypes.number,
    activityLogs: PropTypes.array,
    onClose: PropTypes.func.isRequired,
  };

  render() {
    const {depositId, bonusOfferId, clientId, onClose, deposit, activityLogs} = this.props
    const allActivityLogs = uniqBy([...(activityLogs || []), ...get(deposit, 'activityLogs', [])], 'id')

    let objectId, objectName
    if (depositId) {
      objectId = depositId
      objectName = 'objectDeposit'
    } else {
      objectId = bonusOfferId
      objectName = 'objectBonusOffer'
    }

    return (
      <Tabs>
        <Tab title="Notes" eventKey="notes">
          <EventsMounted
            clientId={clientId}
            depositId={depositId}
            bonusOfferId={bonusOfferId}
            activityLogs={getActivityLogsByObjectId(objectId, objectName, allActivityLogs)}
            onCreate={() => this.props.fetchActivityLogs && this.props.fetchActivityLogs(depositId)}
          />
        </Tab>
        {get(deposit, 'vendor') === depositVendors.bankWire.key && <Tab title="Wire Details" eventKey="wireDetails">
          <BankWireDetails
            clientId={clientId}
            deposit={deposit}
          />
        </Tab>}

        {renderCloseTab(onClose)}
      </Tabs>
    )
  }
}
