import React from 'react'
import PropTypes from 'prop-types'
import {Navbar, Nav, Badge, Button, Card} from 'react-bootstrap'
import {sumBy, get, isArray} from 'lodash'
import {compose, provideProps, predispatch, uiMount} from './decorators'
import style from './appointments/appointments.module.scss'
import PureComponent from './PureComponent'
import Sidebar from './Sidebar'
import Appointment from './appointments/Appointment'
import moment from 'moment'
import Calendar from './appointments/Calendar'
import SelectAgent from './components/SelectAgent'
import {getPageSize} from './useful'
import FontAwesomeIcon from './components/FontAwesomeIcon'
// import {TicketNotificationV} from './tickets'

class AppointmentsSidebar extends PureComponent {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    hide: PropTypes.func.isRequired,
  }

  static contextTypes = {
    appointmentsProvider: PropTypes.object.isRequired,
    viewerAppointmentsCountProvider: PropTypes.object.isRequired,
    appointmentsByDayProvider: PropTypes.object.isRequired,
    toggleAppointmentEditor: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  addWeekdays = (date, days) => {
    date = moment(date)
    while (days > 0) {
      date = date.add(1, 'days')
      if (date.weekday() !== 6 && date.weekday() !== 7) {
        days -= 1
      }
    }
    return date
  }


  handleTodayClick = () => {
    this.props.uiDispatch(
      'Filter upcoming appointments',
      (state) => ({
        ...state,
        startFrom: moment().startOf('day').toISOString(),
        startTo: moment().endOf('day').toISOString(),
        isNew: true,
        tab: 'today',
        offset: 0,
      })
    )
  }

  handleUpcomingClick = () => {
    const tomorrow = moment().add(1, 'day').startOf('day').toISOString()
    const sevenBusinessDaysFromTomorrow = this.addWeekdays(tomorrow, 7).endOf('day').toISOString()
    this.props.uiDispatch(
      'Filter upcoming appointments',
      (state) => ({
        ...state,
        startFrom: tomorrow,
        startTo: sevenBusinessDaysFromTomorrow,
        isNew: true,
        tab: 'upcoming',
        offset: 0,
      })
    )
  }

  handleTicketsClient = () => {
    this.props.uiDispatch(
      'Load ticket of assigned viewer',
      (state) => ({
        ...state,
        tab: 'tickets',
      })
    )
  }

  handleCalendarClick = () => {
    this.props.uiDispatch(
      'Filter upcoming appointments',
      (state) => ({
        ...state,
        tab: 'calendar',
      })
    )
  }

  handleCalendarDaySelect = (day) => {
    this.props.uiDispatch(
      'Filter calendar appointments',
      (state) => ({
        ...state,
        isNew: true,
        startFrom: moment(day).startOf('day').toISOString(),
        startTo: moment(day).endOf('day').toISOString(),
        tab: 'calendarDay',
        offset: 0,
      })
    )
  }

  handleCalendarDayBackClick = () => {
    this.props.uiDispatch(
      'Back to calendar appointments',
      (state) => ({
        ...state,
        tab: 'calendar',
      })
    )
  }

  handleAgentChange = (e) => {
    this.props.uiDispatch(
      'Calendar user change',
      (state) => ({
        ...state,
        userId: e
          ? e.value === 'all'
            ? this.getAllUserIds()
            : e.value
          : null,
      })
    )
  }

  handleNewClick = () => {
    const {viewer, userId} = this.props
    const user = !userId || isArray(userId) ? {id: viewer.id} : {id: userId}
    this.context.toggleAppointmentEditor({user})
  }

  handleEditClick = (e) => {
    e.stopPropagation()
    const appointment = this.props.appointments.find(a => a.id === Number(e.target.value)) || {}
    this.context.toggleAppointmentEditor(appointment)
  }

  handleLoadMoreClick = () => {
    this.props.uiDispatch(
      'Load more appointments',
      (state) => ({
        ...state,
        offset: state.offset + Number(getPageSize()),
      })
    )
  }

  handleCompleteClick = (e) => {
    e.stopPropagation()
    const appointment = this.props.appointments.find(a => a.id === Number(e.target.value))
    const {id} = appointment
    this.props.actions.client.completeAppointment(id)
      .then(() => {
        this.context.appointmentsProvider.fetch()
        this.context.viewerAppointmentsCountProvider.fetch()
        this.context.appointmentsByDayProvider.fetch()
      }).catch(this.context.logError)
  }

  handleViewClick = (id) => {
    const appointment = this.props.appointments.find(a => a.id === Number(id))
    const {client} = appointment
    this.props.actions.appointments.dismissAppointment(id)
      .then(() => {
        this.context.appointmentsProvider.fetch()
        this.context.viewerAppointmentsCountProvider.fetch()
        this.context.appointmentsByDayProvider.fetch()
      })
      .then(this.context.router.push(`/clients/${client.id}`))
      .catch(this.context.logError)
  }

  getFilteredAgents = () => {
    const {viewer, agents = []} = this.props
    const managedAgents = agents.filter(a => get(a, 'managerId') === viewer.id)
    const viewerAgent = {id: viewer.id, firstName: viewer.firstName, lastName: `${viewer.lastName} (You)`}
    const allAgents = {id: 'all', firstName: 'ALL', lastName: 'Agents'}
    return [...managedAgents, viewerAgent, allAgents]
  }

  getAllUserIds = () => {
    const {viewer, agents = []} = this.props
    const managedAgents = agents.filter(a => get(a, 'managerId') === viewer.id).map(a => a.id)
    return [...managedAgents, viewer.id]
  }

  render() {
    const {show, appointments, appointmentsByDay, uiState: {tab}, offset, appointmentsCount, hide} = this.props
    const userId = typeof this.props.userId === 'object' ? 'all' : this.props.userId
    const filteredAgents = this.getFilteredAgents()
    const appointmentsByDayCount = sumBy(appointmentsByDay, 'new')
    return (

      <Sidebar show={show} hide={hide}>
        {filteredAgents.length > 2 && <SelectAgent.Input
          placeholderText="All Agents"
          label="Agent"
          agents={filteredAgents}
          value={userId}
          onChange={this.handleAgentChange}
          clearable
        />}
        <Navbar bg="light" style={{border: '1px solid #ddd'}}>
          <Nav style={{marginLeft: '-15px'}}>
            <Nav.Link active={tab === 'today'} onClick={this.handleTodayClick}>Today</Nav.Link>
            <Nav.Link active={tab === 'upcoming'} onClick={this.handleUpcomingClick}>Upcoming</Nav.Link>
            <Nav.Link active={tab === 'tickets'} onClick={this.handleTicketsClient}>Tickets</Nav.Link>

          </Nav>
          <Nav className="ml-auto">
            <Nav.Link
              style={{marginTop: 6}}
              active={['calendar', 'calendarDay'].includes(tab)}
              onClick={this.handleCalendarClick}
            >
              <FontAwesomeIcon icon="calendar" />
              {appointmentsByDayCount > 0 &&
                <span>&nbsp;<Badge variant="danger">{appointmentsByDayCount}</Badge></span>
              }
            </Nav.Link>
            <Nav.Link style={{marginRight: 0}}>
              <Button size="sm" variant="success" onClick={this.handleNewClick}>New</Button>
            </Nav.Link>
          </Nav>
        </Navbar>
        {tab === 'calendarDay' && <Card>
          <Button onClick={this.handleCalendarDayBackClick} size="sm">{'<-- Back'}</Button>
        </Card>}
        <div className={style.scrollable}>
          {tab === 'calendar'
            ? appointmentsByDay.length > 0
              ? <Calendar
                appointmentsByDay={appointmentsByDay}
                onSelect={this.handleCalendarDaySelect}
              />
              : 'No reminders'
            : appointments.length > 0
              ? appointments.map(appointment => <Appointment
                key={appointment.id}
                appointment={appointment}
                onEditClick={this.handleEditClick}
                onCompleteClick={this.handleCompleteClick}
                onViewClick={this.handleViewClick}
              />)
              : 'No reminders'
              // : tab === 'tickets'
              //   ?  <TicketNotificationV/>
              //   : 'No reminders'
          }
          {appointmentsCount > offset + Number(getPageSize()) && <div className={style.moreButton}>
            <Button onClick={this.handleLoadMoreClick} size="sm" variant="outline-secondary">{'More ...'}</Button>
          </div>}
        </div>
      </Sidebar>
    )
  }
}

export default compose(
  uiMount(() => ['ui', 'appointments']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for appointment: show appointments today',
      (state) => ({
        ...state,
        startFrom: moment().startOf('day').toISOString(),
        startTo: moment().endOf('day').toISOString(),
        isNew: true,
        tab: 'today',
        appointment: {},
        userId: props.viewer.id,
        offset: 0,
      })
    )
  }),

  provideProps((state, uiState) => {
    const {appointments = [], appointmentsCount, appointmentsByDay = [], agents, viewer} = state
    const {startFrom, startTo, isNew, tab, appointment, userId, offset} = uiState
    return ({
      appointments,
      appointmentsCount,
      appointmentsByDay,
      startFrom,
      startTo,
      isNew,
      tab,
      appointment,
      agents,
      userId,
      viewer,
      offset,
    })
  })
)(AppointmentsSidebar)
