import React from 'react'
import PropTypes from 'prop-types'
import {dispatchPermissionDeniedError} from '../errorsUtils'
import {getHocName} from '../useful'
import {checkUserPermission} from '@bdswiss/common-permissions'

const checkOneOfPermissions = (...permissions) => (Component) => class CheckOneOfPermissionsComponent extends React.Component {
  static contextTypes = {
    getState: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
  };

  static displayName = getHocName('CheckOneOfPermissions', Component)

  checkRights = () => {
    const state = this.context.getState()
    const viewer = state.viewer
    const dispatch = this.context.dispatch

    const permissionsResult = permissions.map(permission => checkUserPermission(viewer, permission))
    if (permissionsResult.every(permission => !permission)) {
      dispatchPermissionDeniedError(dispatch)
    }
  }

  componentDidMount() {
    this.checkRights()
  }

  componentWillReceiveProps() {
    this.checkRights()
  }

  render() {
    return <Component {...this.props} />
  }
}

export {checkOneOfPermissions}
