import React from 'react'
import PropTypes from 'prop-types'
import {includes, filter, get, head, has} from 'lodash'
import {resolveAlertReasons} from '@bdswiss/common-enums'
import {Modal, Col, Row, Button, Form} from 'react-bootstrap'
import style from './components.module.scss'
import {isEmptyStr} from '../common/utils.js'
import PureComponent from '../PureComponent'
import StylishSelect from './StylishSelect'

export default class ResolveAlertModal extends PureComponent {

  static contextTypes = {
    usersProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onDone: PropTypes.func.isRequired,
    alert: PropTypes.object,
  }

  componentWillMount() {
    const {alert} = this.props

    const reasons = filter(resolveAlertReasons, (r) =>
      !r.alerts || (includes(r.alerts, get(alert, 'type')))
    )

    this.setDefaultState(reasons)
  }

  setDefaultState(reasons = []) {
    const reason = head(reasons)
    this.setState({
      commentArea: {
        comment: undefined,
        error: false,
      },
      reasons,
      reason: get(reason, 'value'),
      showReasons: reasons.length > 1,
      showComment: !has(reason, 'comment'),
      isTouched: false,
    })
  }

  commentAreaChange(newValue) {
    this.setState({
      commentArea: {
        comment: newValue,
        error: false,
      },
      isTouched: true,
    })
  }

  commentAreaError() {
    this.setState({
      commentArea: {
        error: true,
      },
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  save() {
    const {onDone} = this.props
    const {commentArea: {comment}, reason} = this.state
    const reasonComment = get(resolveAlertReasons[reason], 'comment')
    if (!reasonComment && isEmptyStr(comment)) {
      this.commentAreaError()
      return
    }
    this.setDefaultState()
    onDone(comment, reason)
    // console.log('heree', reason, comment)
  }

  render() {
    const {onHide, show} = this.props

    return (
      <Modal
        id="t-resolve-alert-modal"
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Alert Resolution Comment
        </Modal.Header>
        <Modal.Body>
          <Row>
            {this.state.showReasons && <Col xs={12}>
              <StylishSelect
                id="t-resolve-alert-reasons-select"
                placeholderText="Reasons"
                value={this.state.reason}
                options={this.state.reasons}
                onChange={(e) => {
                  const reason = resolveAlertReasons[e.value]
                  this.setState({
                    reason: e.value,
                    isTouched: true,
                    showComment: !has(reason, 'comment'),
                    commentArea: {
                      comment: undefined,
                      error: false,
                    },
                  })
                }}
              />
              <br /><br />
            </Col>}
            {this.state.showComment && <Col xs={12}>
              <Form.Control
                id="t-alert-resolve-comment"
                as="textarea"
                rows={4}
                label="Comment"
                tabIndex={1}
                value={this.state.commentArea.comment}
                isInvalid={this.state.commentArea.error}
                onChange={(event) => {
                  this.commentAreaChange(event.target.value)
                }}
              />
            </Col>}
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-alert-resolve-comment-cancel"
            variant="outline-secondary"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-alert-resolve-comment-save"
            variant="info"
            disabled={!this.state.isTouched}
            onClick={() => this.save()}
          >
            Resolve
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
