import {processFieldsData} from './personalDetailsFields'
import {getOffset, stringifyQL, toGraphQlParamsDeclaration} from '../useful'
import {unset, withdrawalPaymentFields, processWithdrawalResults} from '@bdswiss/common-enums'
import {omitBy, get, mapValues} from 'lodash'
import {backendRequest} from '../utils/net'
import runtimeConfig from '../config'

const {backendUrl} = runtimeConfig.getInstance()

const paymentFields = omitBy(withdrawalPaymentFields,
  (f) => f.key === 'amount' || f.key === 'withdrawalReason' || f.key === 'withdrawalReasonText')

export default function create(dbClient) {
  return {
    revertDocument(document) {
      const variables = {
        documentId: document.id,
      }

      const query = `
        mutation ($documentId: Int!) {
          revertDocument(
            id: $documentId
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    processDocument(document, action, rejectionReason, selectedReasonCode, sendRejectionEmail) {
      const variables = {
        documentId: document.id,
        result: action,
        rejectionReasonCode: selectedReasonCode,
        rejectionReason,
        sendRejectionEmail,
      }

      const query = `
        mutation (
          $documentId: Int!,
          $result: ProcessDocumentResult!,
          $rejectionReason: String
          $rejectionReasonCode: DocumentRejectionReasonCode
          $sendRejectionEmail: Boolean
          ) {
          processDocument(
            id: $documentId
            result: $result
            rejectionReason: $rejectionReason
            rejectionReasonCode: $rejectionReasonCode
            sendRejectionEmail: $sendRejectionEmail
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    processProfileChange(profileChange, action, rejectionReason, comments) {
      const variables = {
        id: profileChange.id,
        result: action,
        rejectionReason,
        comments,
      }

      const query = `
        mutation (
          $id: Int!
          $result: ProcessProfileChangeResult!
          $rejectionReason: String
          $comments: String
        ) {
          processProfileChange(
            id: $id
            result: $result
            rejectionReason: $rejectionReason
            comments: $comments
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    processAppropTest(appropTest, action, rejectionReason, selectedReasonCode) {
      const variables = {
        id: appropTest.id,
        result: action,
        rejectionReason,
        rejectionReasonCode: selectedReasonCode,
      }

      const query = `
        mutation (
          $id: Int!
          $result: ProcessAppropTestResult!
          $rejectionReason: String
          $rejectionReasonCode: AppropTestRejectionReasonCode
        ) {
          processAppropTest(
            id: $id
            result: $result
            rejectionReason: $rejectionReason
            rejectionReasonCode: $rejectionReasonCode
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    processWithdrawal(withdrawal, action, rejectionReason, selectedReasonCode, meta) {
      const variables = {
        withdrawalId: withdrawal.id,
        result: action,
        rejectionReason,
        rejectionReasonCode: action === processWithdrawalResults.reject.value ? selectedReasonCode : undefined,
        pendingReasonCode: action === processWithdrawalResults.pending.value ? selectedReasonCode : undefined,
        meta: JSON.stringify(meta),
      }

      const query = `
        mutation (
          $withdrawalId: Int!,
          $result: ProcessWithdrawalResult!,
          $rejectionReason: String,
          $rejectionReasonCode: WithdrawalRejectionReasonCode,
          $pendingReasonCode: WithdrawalPendingReasonCode,
          $meta: String,
        ) {
          processWithdrawal (
            id: $withdrawalId,
            result: $result,
            rejectionReason: $rejectionReason,
            rejectionReasonCode: $rejectionReasonCode,
            pendingReasonCode: $pendingReasonCode,
            meta: $meta
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    releaseDeposit(deposit) {
      const variables = {
        depositId: deposit.id,
      }

      const query = `
        mutation (
          $depositId: Int!
        ) {
          releaseDeposit(id: $depositId) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    rejectDeposit(deposit, rejectionReason, rejectionReasonKey) {
      const variables = {
        depositId: deposit.id,
        rejectionReason,
        rejectionReasonKey
      }

      const query = `
        mutation (
          $depositId: Int!,
          $rejectionReason: String,
          $rejectionReasonKey: DepositRejectionReasonCode
        ) {
          rejectDeposit(id: $depositId, rejectionReason: $rejectionReason, rejectionReasonKey: $rejectionReasonKey) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    completeDeposit(deposit, amount, bank, dateReceived) {
      const variables = {
        depositId: deposit.id,
        amount,
        bankWireCompletedBank: bank,
        dateReceived,
      }

      const query = `
        mutation (
          $depositId: Int!
          $amount: Float
          $bankWireCompletedBank: Bank
          $dateReceived: DateTime
        ) {
          completeDeposit(
            id: $depositId,
            amount: $amount,
            bankWireCompletedBank: $bankWireCompletedBank
            dateReceived: $dateReceived
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    attemptedToIncompleteDeposit(deposit) {
      const variables = {
        depositId: deposit.id,
      }

      const query = `
        mutation (
          $depositId: Int!
        ) {
          attemptedToIncompleteDeposit(id: $depositId) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    processAdminBonusOffer(bonusOffer, action, rejectionReason) {
      const variables = {
        bonusOfferId: bonusOffer.id,
        result: action,
        rejectionReason,
      }

      const query = `mutation (
        $bonusOfferId: Int!,
        $result: ProcessAdminBonusOfferResult!,
        $rejectionReason: String
       ) {
         processAdminBonusOffer (
           id: $bonusOfferId
           result: $result
           rejectionReason: $rejectionReason
         ) {
           id
         }
      }`

      return dbClient.query(query, variables)
    },
    setTradingStatus(clientId, tradingStatus, reason, selectedReasonCode) {
      const variables = {
        clientId,
        tradingStatus,
        reason,
        rejectionReasonCode: selectedReasonCode,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $tradingStatus: TradingStatus!,
          $reason: String,
          $rejectionReasonCode: TradingStatusRejectionReasonCode
        ) {
          setClientTradingStatus (
            id: $clientId
            status: $tradingStatus
            reason: $reason
            rejectionReasonCode: $rejectionReasonCode
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    setKycStatus(clientId, kycStatus, reason, selectedReasonCode) {
      const variables = {
        clientId,
        kycStatus,
        reason,
        rejectionReasonCode: selectedReasonCode,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $kycStatus: KycStatus!,
          $reason: String,
          $rejectionReasonCode: KycRejectionReason
        ) {
          setClientKycStatus (
            id: $clientId,
            status: $kycStatus,
            reason: $reason,
            rejectionReasonCode: $rejectionReasonCode,
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    setConversionStatus(clientId, conversionStatus) {
      const variables = {
        clientId,
        conversionStatus,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $conversionStatus: ConversionStatus!,
        ) {
          setClientConversionStatus (
            id: $clientId,
            status: $conversionStatus,
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    setClientType(clientId, clientType) {
      const variables = {
        clientId,
        clientType,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $clientType: ClientType,
        ) {
          setClientType (
            id: $clientId,
            clientType: $clientType,
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    setSalesAgent(clientId, agentId) {
      return this.batchSetSalesAgent([clientId], agentId)
    },
    revealPhone(clientId, profileChangeId) {
      const variables = {
        clientId,
      }

      if (profileChangeId) {
        variables.profileChangeId = profileChangeId
      }

      const query = `
        mutation (
          $clientId: Int!,
          ${profileChangeId ? '$profileChangeId: Int' : ''}
        ) {
          revealClientPhone (
            clientId: $clientId,
            ${profileChangeId ? 'profileChangeId: $profileChangeId' : ''}
          ){
            phone
            secondaryPhones {
              secondaryPhone1
            }
          }
        }
      `
      return dbClient.query(query, variables).then((res) => get(res, 'revealClientPhone'))
    },
    trackCopyEvent(clientId, field) {
      const variables = {
        clientId,
        field,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $field: String!,
        ) {
          trackCopyEvent (
            clientId: $clientId,
            field: $field
          ){
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    revealEmail(clientId) {
      const variables = {
        clientId,
      }

      const query = `
        mutation (
          $clientId: Int!,
        ) {
          revealClientEmail (
            clientId: $clientId,
          ){
            email,
            secondaryEmails {
              secondaryEmail1
              secondaryEmail2
              secondaryEmail3
            }
          }
        }
      `
      return dbClient.query(query, variables).then((res) => res.revealClientEmail)
    },
    batchSetSalesAgent(clientsIds, agentId) {
      const variables = {
        clientsIds,
        agentId: agentId == null ? unset.unset.key : agentId,
      }

      const query = `
        mutation ($clientsIds: [Int], $agentId: UnsetOrInt!) {
          updateClientsSalesAgent(
            clientsIds: $clientsIds
            agentId: $agentId
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    batchSetSalesAgentAndConversionStatus(clientsIds, agentId, conversionStatus, partnersAgentId) {
      const types = {}
      const variables = {clientsIds}
      let mutations = []

      if (agentId) {
        types.$agentId = 'UnsetOrInt!'
        types.$clientsIds = '[Int]'

        variables.agentId = agentId
        variables.clientsIds = clientsIds

        mutations.push(`
          updateClientsSalesAgent(
            clientsIds: $clientsIds
            agentId: $agentId
          ) {
            id
          }`
        )
      }

      if (conversionStatus) {
        types.$conversionStatus = 'ConversionStatus!'
        variables.conversionStatus = conversionStatus

        mutations = mutations.concat(
          clientsIds.map((clientId) => `
              setClientConversionStatus${clientId}: setClientConversionStatus (
                id: ${clientId},
                status: $conversionStatus
              ) {
                id
              }`)
        )
      }
      if (partnersAgentId) {
        types.$partnerAgentId = 'UnsetOrInt!'
        types.$clientsIds = '[Int]'

        variables.partnerAgentId = partnersAgentId
        variables.clientsIds = clientsIds

        mutations.push(`
          updateClientsSupportAgent(
            clientsIds: $clientsIds
            partnerAgentId: $partnerAgentId
          ) {
            id
          }`
        )
      }

      const query = `
        mutation (${toGraphQlParamsDeclaration(types)}) {
          ${mutations.join('\n')}
        }
      `

      return dbClient.query(query, variables)
    },
    setSupportAgent(clientId, partnerAgentId) {
      const variables = {
        clientsIds: [clientId],
        partnerAgentId: partnerAgentId == null ? unset.unset.key : partnerAgentId,
      }

      const query = `
        mutation ($clientsIds: [Int], $partnerAgentId: UnsetOrInt!) {
          updateClientsSupportAgent(
            clientsIds: $clientsIds
            partnerAgentId: $partnerAgentId
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    setExternalAgent(clientId, agentId) {
      const variables = {
        clientsIds: [clientId],
        agentId: agentId == null ? unset.unset.key : agentId,
      }

      const query = `
        mutation ($clientsIds: [Int], $agentId: UnsetOrInt!) {
          updateClientsExternalAgent(
            clientsIds: $clientsIds
            agentId: $agentId
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    updatePotentialLevel(args) {
      const variables = {
        potentialLevel: args.potentialLevel,
        clientId: args.clientId,
      }
      const query = `
        mutation (
          $clientId: Int!,
          $potentialLevel: PotentialLevel
        ) {
          updateClientsPotentialLevel(
            clientId: $clientId
            potentialLevel: $potentialLevel
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    updateAffiliateCountries(args) {
      const variables = {
        clientId: args.clientId,
        countriesPromoted: args.updatedCountriesPromoted,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $countriesPromoted: [String]
        ) {
          updateAffiliateCountries(
            clientId: $clientId
            countriesPromoted: $countriesPromoted
          ) {
            client {
              id
            }
            countriesPromoted
          }
        }
      `

      return dbClient.query(query, variables)
    },

    upsertAppointment(id, clientId, start, color, addToGoogleCalendar, userId, category, description, isNew) {
      const variables = {
        id,
        clientId,
        start,
        color,
        addToGoogleCalendar,
        userId,
        category,
        description,
        isNew,
      }

      const query = `
        mutation (
          $id: Int,
          $clientId: Int,
          $start: DateTime,
          $color: appointmentColorType,
          $addToGoogleCalendar: Boolean,
          $userId: Int,
          $category: appointmentCategoryType,
          $description: String,
          $isNew: Boolean,
        ) {
          upsertAppointment(
            id: $id
            clientId: $clientId
            start: $start
            color: $color
            addToGoogleCalendar: $addToGoogleCalendar
            userId: $userId
            category: $category
            description: $description
            isNew: $isNew
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    deleteAppointment(id) {
      const variables = {id}

      const query = `
        mutation ($id: Int!) {
          deleteAppointment(
            id: $id
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    completeAppointment(id) {
      const variables = {id}

      const query = `
        mutation ($id: Int!) {
          completeAppointment(
            id: $id
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    deleteMember(id) {
      const variables = {id}

      const query = `mutation ($id: Int!) {
        gdrpDeleteMember(id: $id)
      }`

      return dbClient.query(query, variables)
    },

    fetchEmailThread(messageId) {
      const variables = {messageId}
      const query = `
      query emailThread($messageId: Int!){
        emailThread(messageId: $messageId) {
          id
          threadId
          date
          from
          to
          cc
          bcc
          subject
          body
          labelIds
          attachments {
            attachmentId
            filename
            data
          }
        }
      }`
      return dbClient.query(query, variables)
    },

    addNote(clientId, content, depositId, bonusOfferId, withdrawalId, documentId, fileName, fileKey) {
      const variables = {
        clientId: depositId || bonusOfferId || withdrawalId || documentId ? null : clientId,
        withdrawalId,
        documentId,
        bonusOfferId,
        depositId,
        content,
        fileName,
        fileKey,
      }

      const query = `mutation (
          $content: String,
          $clientId: Int,
          $withdrawalId: Int,
          $documentId: Int,
          $bonusOfferId: Int,
          $depositId: Int,
          $fileName: String,
          $fileKey: String,
        ) { createNote(
          clientId: $clientId
          bonusOfferId: $bonusOfferId
          depositId: $depositId
          withdrawalId: $withdrawalId
          documentId: $documentId
          content: $content
          fileName: $fileName
          fileKey: $fileKey
        ) {
          id
        }
      }
      `

      return dbClient.query(query, variables)
    },
    createWithdrawal(data) {
      const variables = {
        withdrawalReason: data.withdrawalReason,
        withdrawalReasonText: data.withdrawalReasonText,
        amount: data.amount,
        type: data.type,
        paymentVendor: data.paymentVendor,
        paymentFields: data.paymentFields,
        accountId: data.accountId,
      }

      const query = `
      mutation(
        $withdrawalReason: WithdrawalReasonsType,
        $withdrawalReasonText: String,
        $amount: Float!,
        $type: PermittedWithdrawalType!,
        $paymentVendor: WithdrawalPaymentVendorsType,
        $paymentFields: WithdrawalPaymentFieldsInputType!,
        $accountId: Int!,
      ) {
        createWithdrawal(
          withdrawalReason: $withdrawalReason,
          withdrawalReasonText: $withdrawalReasonText,
          amount: $amount,
          type: $type,
          paymentVendor: $paymentVendor,
          paymentFields: $paymentFields,
          accountId: $accountId,
        ) {
          status
          rejectionReason
          createdAt
          currency
          amount
          withdrawalReason
          withdrawalReasonText
          paymentVendor
          paymentFields {
            ${Object.keys(paymentFields).join(' ')}
          }
          account {
            ... on BaseAccount {
              id
              __typename
            }
          }
        }
      }`
      return dbClient.query(query, variables)
    },
    updateWithdrawal(data) {
      const variables = {
        id: data.id,
        amount: data.amount,
        status: data.status,
        comments: data.comments,
        paymentFields: data.paymentFields,
        meta: JSON.stringify(data.meta || {}),
      }

      const query = `mutation(
          $id: Int!,
          $amount: Float!,
          $status: WithdrawalStatusesType!,
          $comments: String,
          $paymentFields: WithdrawalPaymentFieldsInputType,
          $meta: String,
        ) {
          updateWithdrawal(
          id: $id
          amount: $amount
          status: $status
          comments: $comments
          paymentFields: $paymentFields
          meta: $meta
          ) {
            id
          }
        }`
      return dbClient.query(query, variables)
    },
    createDeposit(deposit) {
      const bankWire = {
        bankAccountHolderName: deposit.bankAccountHolderName,
        iban: deposit.iban,
        swiftCode: deposit.swiftCode,
        bankName: deposit.bankName,
        transferToAccount: deposit.sourceAccount,
        dateReceived: deposit.dateReceived,
      }
      const variables = {
        amount: deposit.amount,
        currency: deposit.currency,
        vendor: deposit.vendor,
        accountId: deposit.accountId,
        receipt: deposit.receipt,
        paymentFields: bankWire,
        meta: JSON.stringify(deposit.meta || {})
      }

      const query = `
        mutation (
          $amount: Float!,
          $currency: Currency!,
          $vendor: DepositVendorsType!,
          $accountId: Int!,
          $paymentFields: DepositPaymentFieldsInputType,
          $receipt: String,
          $meta: String
        ) {
          createDeposit(
            amount: $amount,
            currency: $currency,
            vendor: $vendor,
            accountId: $accountId,
            paymentFields: $paymentFields,
            receipt: $receipt,
            meta: $meta
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    createFundTransfer(params) {
      const variables = {
        toAccountId: params.toAccountId,
        fromAccountId: params.fromAccountId,
        amount: params.amount,
      }

      const query = `
        mutation($fromAccountId: Int!, $toAccountId: Int!,  $amount: Float!) {
          createTransferFunds(fromAccountId: $fromAccountId, toAccountId: $toAccountId, amount: $amount) {
            deposit {
              id
              status
            }
            withdrawal {
              id
              status
            }
          }
        }
      `
      return dbClient.query(query, variables)
    },
    createAdminBonusOffer(bonusOffer) {
      const variables = {
        accountId: bonusOffer.accountId,
        amount: bonusOffer.amount,
        currency: bonusOffer.currency,
      }

      const query = `
        mutation (
          $accountId: Int!
          $amount: Float!,
          $currency: Currency!,
        ) {
          createAdminBonusOffer(
            accountId: $accountId,
            amount: $amount,
            currency: $currency,
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    createAccount(account) {
      const variables = {
        currency: account.currency,
        clientId: account.clientId,
        accountType: account.accountType,
        accountSubtype: account.accountSubtype,
        walletAddress: account.walletAddress,
        depositWalletAddress: account.depositWalletAddress,
        parentIbId: account.parentIbId,
        affiliateId: account.affiliateId,
        server: account.server,
      }

      const query = `
        mutation (
          $currency: NewAccountCurrency!,
          $clientId: Int!,
          $accountType: AccountType!,
          $accountSubtype: AccountSubtype,
          $walletAddress: String,
          $depositWalletAddress: String,
          $parentIbId: String,
          $affiliateId: Int,
          $server: Server
        ) {
          createAccount(
            currency: $currency,
            clientId: $clientId,
            accountType: $accountType,
            accountSubtype: $accountSubtype
            walletAddress: $walletAddress,
            depositWalletAddress: $depositWalletAddress,
            parentIbId: $parentIbId,
            affiliateId: $affiliateId,
            server: $server,
          ) {
            ... on BaseAccount {
              id
            }
          }
        }
      `

      return dbClient.query(query, variables)
    },
    resetClientPassword(clientId) {
      const variables = {clientId}

      const query = `
        mutation ($clientId: Int!) {
          resetClientPassword(
            clientId: $clientId
          ) {
            password
          }
        }
      `

      return dbClient.query(query, variables)
    },

    signUploadUrl(clientId) {
      const variables = {clientId}

      const query = `
        mutation ($clientId: Int!) {
          signUploadUrl(
            clientId: $clientId
          ) {
            key
            signedUrl
          }
        }
      `

      return dbClient.query(query, variables)
    },

    generateSecurityCode(clientId) {
      const variables = {clientId}

      const query = `
        mutation ($clientId: Int!) {
          generateSecurityCode(
            clientId: $clientId
          ) {
            newSecurityCode
          }
        }
      `

      return dbClient.query(query, variables)
    },

    upsertDocument(clientId, id, key, type, expiration, fileDescription, status, tag, cardNumber, assignee, relatesTo, paymentMethodId) {
      const variables = {
        id,
        // if document already exists, backend does NOT accept clientId among mutation arguments
        clientId: id ? null : clientId,
        key,
        type,
        fileDescription,
        expiration: expiration || unset.unset.key,
        internalTag: tag,
        status,
        cardNumber,
        assignee: assignee === null ? unset.unset.key : assignee,
        relatesTo,
        paymentMethodId
      }

      const query = `
        mutation (
          $id: Int,
          $clientId: Int,
          $key: String,
          $type: DocumentType,
          $fileDescription: String,
          $expiration: UnsetOrDateTime,
          $internalTag: String,
          $relatesTo: UnsetOrInt,
          $paymentMethodId: UnsetOrInt,
          ${status ? '$status: DocumentStatus,' : ''}
          ${cardNumber ? '$cardNumber: String,' : ''}
          ${assignee ? '$assignee: UnsetOrInt,' : ''}
        ) {
          upsertDocument(
            id: $id
            clientId: $clientId
            key: $key
            type: $type
            fileDescription: $fileDescription
            expiration: $expiration
            internalTag: $internalTag
            relatesTo: $relatesTo
            paymentMethodId: $paymentMethodId
            ${status ? 'status: $status' : ''}
            ${cardNumber ? 'cardNumber: $cardNumber' : ''}
            ${assignee ? 'assignee: $assignee' : ''}
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    updateClientDetails(clientId, data) {
      data = mapValues(data, (value, key) =>
        ['vps', 'optInMarketing', 'optInSms'].includes(key) ? value === 'yes' : value)
      const {formatted, graphQlTypes, variables} = processFieldsData(data)

      variables.id = clientId
      const query = `mutation (
        $id: Int!,
        ${toGraphQlParamsDeclaration(graphQlTypes)}
      ){
        updateClientDetails(
          id: $id
          ${stringifyQL(formatted, true)}
        ) {
          id
        }
      }
      `
      return dbClient.query(query, variables)
    },

    upsertGeneralClientNote(clientId, generalClientNote) {
      const variables = {
        id: clientId,
        generalClientNote,
      }
      const query = `mutation (
        $id: Int!,
        $generalClientNote: String
      ){
        upsertGeneralClientNote(
          id: $id
          generalClientNote: $generalClientNote
        ) {
          id
        }
      }
      `
      return dbClient.query(query, variables)
    },

    upsertGeneralWithdrawalNote(clientId, generalWithdrawalNote) {
      const variables = {
        id: clientId,
        generalWithdrawalNote,
      }
      const query = `mutation (
        $id: Int!,
        $generalWithdrawalNote: String
      ){
        upsertGeneralWithdrawalNote(
          id: $id
          generalWithdrawalNote: $generalWithdrawalNote
        ) {
          id
        }
      }
      `
      return dbClient.query(query, variables)
    },

    upsertAffiliateUserName(clientId, affUserName) {
      const variables = {
        clientId: clientId,
        affUserName,
      }
      const query = `mutation (
        $clientId: Int!,
        $affUserName: String
      ){
        upsertAffiliateUserName(
          clientId: $clientId
          affUserName: $affUserName
        ) {
          id
        }
      }
      `
      return dbClient.query(query, variables)
    },
    updateClientAccountChecks(clientId, data) {
      const {formatted, graphQlTypes, variables} = processFieldsData(data)
      variables.id = clientId
      const query = `mutation (
        $id: Int!,
        ${toGraphQlParamsDeclaration(graphQlTypes)}
      ){
        updateClientAccountChecks(
          id: $id
          ${stringifyQL(formatted, true)}
        ) {
          id
        }
      }
      `

      return dbClient.query(query, variables)
    },

    addLead(data) {
      const variables = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        phone: data.phone,
        clientType: data.clientType,
        salesAgentId: data.salesAgentId,
        company: data.company,
        skypeId: data.skypeId,
        website: data.website,
        address_country: data.country,
        otherCompany: data.otherCompany,
        title: data.title,
        bdswissPartner: data.bdswissPartner,
        priority: data.priority,
      }
      const query = `mutation (
        $email:String,
        $firstName:String,
        $lastName:String,
        $clientType:ClientType,
        $salesAgentId:Int,
        $phone:String,
        $company:Company,
        $skypeId:String,
        $website:String,
        $address_country:Country,
        $otherCompany: String,
        $title: String,
        $bdswissPartner: String,
        $priority: String,
      ){
        createLead(
          email:$email,
          firstName:$firstName,
          lastName:$lastName,
          clientType:$clientType,
          salesAgentId:$salesAgentId,
          phone:$phone,
          company:$company,
          skypeId:$skypeId,
          website:$website,
          address:{country:$address_country},
          otherCompany: $otherCompany,
          title: $title,
          bdswissPartner: $bdswissPartner,
          priority: $priority,
        ) {
          id
        }
      }`

      return dbClient.query(query, variables)
    },

    uploadStrategicPartners(data) {
      const query = `mutation ($url:String, $key:String) {
        uploadStrategicPartners(url:$url, key:$key) {
          rowsInserted,
          rowsFailed,
          errors,
        }
      }`
      return dbClient.query(query, data)
    },

    getSignedUrl() {
      const query = `mutation {
        getSignedUrl {
          key
          plainUrl
          signedUrl
        }
      }`
      return dbClient.query(query, {})
    },

    checkEmailInUse(data) {
      const variables = {
        email: data.email,
        checkConverted: data.checkConverted,
        company: data.company,
      }
      const query = `query (
                      $email:String!,
                      $checkConverted:Boolean!,
                      $company:Company
                      ){
                         emailInUse(email:$email, checkConverted:$checkConverted, company:$company)
                      }`

      return dbClient.query(query, variables)
    },

    updateAccount(args) {
      const variables = {
        accountId: args.accountId,
        hidden: args.hidden,
        leverage: args.leverage,
        readOnly: args.readOnly,
        approved: args.approved,
        swapFree: args.swapFree,
        mobile: args.mobile,
        readOnlyNote: args.readOnlyNote,
        readOnlyDealer: args.readOnlyDealer,
        parentIbId: args.parentIbId,
        ibId: args.ibId,
        performanceFee: args.performanceFee,
        accountName: args.accountName,
      }
      const query = `mutation (
                      $accountId:Int!,
                      $hidden:Boolean,
                      $leverage:Int,
                      $readOnly:Boolean,
                      $approved:Boolean,
                      $swapFree:Boolean,
                      $mobile:Boolean,
                      $readOnlyNote:String,
                      $readOnlyDealer:Boolean,
                      $parentIbId:String,
                      $ibId:String,
                      $performanceFee:Float,
                      $accountName:String
                      ){
                         editAccount(
                           accountId:$accountId,
                           hidden:$hidden,
                           leverage: $leverage
                           readOnly:$readOnly
                           approved:$approved
                           swapFree:$swapFree
                           mobile: $mobile
                           readOnlyNote: $readOnlyNote
                           readOnlyDealer: $readOnlyDealer
                           parentIbId: $parentIbId
                           ibId: $ibId
                           performanceFee: $performanceFee
                           accountName: $accountName
                         ) {
                           ... on BaseAccount {
                             id
                          }
                         }
                      }`

      return dbClient.query(query, variables)
    },

    logWalkthroughProvision(args) {
      const variables = {
        type: args.type,
        clientId: args.clientId,
      }

      const query = `
        mutation(
          $type: WalkthroughType!,
          $clientId: Int!
        ) {
          addWalkthrough(walkthroughType:$type, clientId:$clientId) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    deleteWalkthroughRecord(args) {
      const variables = {
        id: args.walkthroughId,
      }

      const query = `
        mutation($id:Int!) {
          deleteWalkthrough(id:$id)
        }
      `
      return dbClient.query(query, variables)
    },

    startCall(clientId, phoneNumber, phoneType) {
      const variables = {clientId, phoneNumber, phoneType}

      const query = `
        mutation($clientId:Int!, $phoneNumber:String, $phoneType:PhoneType) {
          startCall(clientId:$clientId, phoneNumber: $phoneNumber, type: $phoneType) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    resolveAlert(alert, comment, reason) {
      const variables = {
        alertId: alert.id,
        comment,
        reason,
      }

      const query = `
        mutation (
          $alertId: Int!,
          $comment: String
          $reason: ResolveAlertReasonType!
        ) {
          resolveAlert(
            id: $alertId,
            comment: $comment
            reason: $reason
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    updateAlertActivityLog(alert, activityLog) {
      const variables = {
        alertId: alert.id,
        activityLog,
      }
      const query = `
        mutation (
          $alertId: Int!,
          $activityLog: String!
        ) {
          updateAlertActivityLog(
            id: $alertId,
            activityLog: $activityLog
          ) {
            id
            activityLog
          }
        }
      `
      return dbClient.query(query, variables)
    },

    postponeAlert(alert, postponeUntil, activityLog) {
      const variables = {
        alertId: alert.id,
        postponeUntil,
        activityLog,
      }
      const query = `
        mutation (
          $alertId: Int!,
          $postponeUntil: DateTime!
          $activityLog: String!
        ) {
          postponeAlert(
            id: $alertId,
            postponeUntil: $postponeUntil,
            activityLog: $activityLog
          ) {
            id
            postponeUntil
            activityLog
            status
          }
        }
      `
      return dbClient.query(query, variables)
    },

    sendAutoProcessRequest(withdrawalId, clientId, processGroup) {
      const variables = {
        clientId,
        withdrawalId,
        processGroup,
      }

      const query = `
        mutation($clientId:Int!, $withdrawalId:Int!, $processGroup: Boolean) {
          withdrawalAutoProcess(clientId:$clientId, withdrawalId: $withdrawalId, processGroup: $processGroup) {
            response
          }
        }
      `
      return dbClient.query(query, variables)
    },

    doClientGBGCheck(clientId) {
      const variables = {
        clientId,
      }

      const query = `
        mutation($clientId:Int!) {
          doClientGBGCheck(clientId:$clientId) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    deleteAccount(accountId, reason, selectedReasonCode) {
      const variables = {
        accountId,
        reason,
        rejectionReasonCode: selectedReasonCode,
      }

      const query = `
        mutation (
          $accountId: Int!,
          $reason: String,
          $rejectionReasonCode: DeleteAccountReasonCode
        ) {
          deleteAccount (
            accountId: $accountId
            reason: $reason
            rejectionReasonCode: $rejectionReasonCode
          ) {
            ... on BaseAccount {
              id
            }
          }
        }
      `
      return dbClient.query(query, variables)
    },

    subscribeToAI(accountId, reason, selectedReasonCode) {
      const variables = {
        accountId,
      }

      const query = `
      mutation ($accountId: Int!) {
        subscribeToAI(accountId: $accountId)
      }`

      return dbClient.query(query, variables)
    },

    unsubscribeFromAI(accountId, reason, selectedReasonCode) {
      const variables = {
        accountId,
      }

      const query = `
      mutation ($accountId: Int!) {
        unsubscribeFromAI(accountId: $accountId)
      }`

      return dbClient.query(query, variables)
    },

    toggleAllowTransfers(clientId, allowTransfers) {
      const variables = {
        clientId,
        allowTransfers,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $allowTransfers: Boolean!
        ) {
          toggleAllowTransfers (
            clientId: $clientId
            allowTransfers: $allowTransfers
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleManualWithdrawals(clientId, manualWithdrawals) {
      const variables = {
        clientId,
        manualWithdrawals,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $manualWithdrawals: Boolean!
        ) {
          toggleManualWithdrawals (
            clientId: $clientId
            manualWithdrawals: $manualWithdrawals
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleEffectiveFTD(clientId, effectiveFTD) {
      const variables = {
        clientId,
        effectiveFTD,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $effectiveFTD: Boolean!
        ) {
          toggleEffectiveFTD (
            clientId: $clientId
            effectiveFTD: $effectiveFTD
          ) {
            id
            agentEffectiveFTD
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleUnderMonitoring(clientId, underMonitoring, underMonitoringReasonCode, reason) {
      const variables = {
        clientId,
        underMonitoring,
        underMonitoringReasonCode,
        reason,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $underMonitoring: Boolean!
          $underMonitoringReasonCode: UnderMonitoringReason,
          $reason: String
        ) {
          toggleUnderMonitoring (
            clientId: $clientId
            underMonitoring: $underMonitoring
            underMonitoringReasonCode: $underMonitoringReasonCode
            reason: $reason
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleAllowNewAccounts(clientId, allowNewAccounts) {
      const variables = {
        clientId,
        allowNewAccounts,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $allowNewAccounts: Boolean!
        ) {
          toggleAllowNewAccounts (
            clientId: $clientId
            allowNewAccounts: $allowNewAccounts
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleTopVip(clientId, topVip) {
      const variables = {
        clientId,
        topVip,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $topVip: Boolean!
        ) {
          toggleTopVip (
            clientId: $clientId
            topVip: $topVip
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    changeCompany(clientId, newCompany) {
      const variables = {
        clientId,
        newCompany,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $newCompany: Company!,
        ) {
          changeCompany (
            clientId: $clientId,
            newCompany: $newCompany,
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    searchIbs(data) {
      const variables = {
        keyword: data.keyword,
      }
      const query = `query (
                      $keyword:String!
                      ){
                         searchIbs(keyword:$keyword)
                      }`

      return dbClient.query(query, variables)
    },

    verifySkrillAccount(withdrawal, deposit) {
      const variables = {
        withdrawalId: withdrawal && withdrawal.id,
        depositId: deposit && deposit.id,
      }

      const query = `
        mutation ($withdrawalId: Int, $depositId: Int) {
          verifySkrillAccount(
            withdrawalId: $withdrawalId,
            depositId: $depositId
          )
        }
      `
      return dbClient.query(query, variables)
    },

    toggleCanGrantSpoa(clientId, canGrantSpoa) {
      const variables = {
        clientId,
        canGrantSpoa,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $canGrantSpoa: Boolean!
        ) {
          toggleCanGrantSpoa (
            clientId: $clientId
            canGrantSpoa: $canGrantSpoa
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleCanIbTransfer(clientId, canIbTransfer) {
      const variables = {
        clientId,
        canIbTransfer,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $canIbTransfer: Boolean!
        ) {
          toggleCanIbTransfer (
            clientId: $clientId
            canIbTransfer: $canIbTransfer
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleHasHotAssets(clientId, hasHotAssets) {
      const variables = {
        clientId,
        hasHotAssets,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasHotAssets: Boolean!
        ) {
          toggleHasHotAssets (
            clientId: $clientId
            hasHotAssets: $hasHotAssets
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleHasTelegram(clientId, hasTelegram) {
      const variables = {
        clientId,
        hasTelegram,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasTelegram: Boolean!
        ) {
          toggleHasTelegram (
            clientId: $clientId
            hasTelegram: $hasTelegram
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleHasPortfolioManagement(clientId, hasPortfolioManagement) {
      const variables = {
        clientId,
        hasPortfolioManagement,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasPortfolioManagement: Boolean!
        ) {
          toggleHasPortfolioManagement (
            clientId: $clientId
            hasPortfolioManagement: $hasPortfolioManagement
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleIgnoreAutoMigration(clientId, ignoreAutoMigration) {
      const variables = {
        clientId,
        ignoreAutoMigration,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $ignoreAutoMigration: Boolean!
        ) {
          toggleIgnoreAutoMigration (
            clientId: $clientId
            ignoreAutoMigration: $ignoreAutoMigration
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleHasRawAccount(clientId, hasRawAccount) {
      const variables = {
        clientId,
        hasRawAccount,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasRawAccount: Boolean!
        ) {
          toggleHasRawAccount (
            clientId: $clientId
            hasRawAccount: $hasRawAccount
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleHasVipAccount(clientId, hasVipAccount) {
      const variables = {
        clientId,
        hasVipAccount,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasVipAccount: Boolean!
        ) {
          toggleHasVipAccount (
            clientId: $clientId
            hasVipAccount: $hasVipAccount
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleAutochartistTools(clientId, checkbox) {
      const variables = {
        clientId,
        toggleAutochartist: checkbox.toggleAutochartist,
        toggleAutochartistPlugin: checkbox.toggleAutochartistPlugin,
        toggleAutochartistCoelationEmail: checkbox.toggleAutochartistCoelationEmail,
        toggleAutochartistEmails: checkbox.toggleAutochartistEmails,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $toggleAutochartist: Boolean,
          $toggleAutochartistPlugin: Boolean,
          $toggleAutochartistCoelationEmail: Boolean,
          $toggleAutochartistEmails: Boolean,
        ) {
          toggleAutochartistTools (
            clientId: $clientId
            toggleAutochartist: $toggleAutochartist
            toggleAutochartistPlugin: $toggleAutochartistPlugin
            toggleAutochartistCoelationEmail: $toggleAutochartistCoelationEmail
            toggleAutochartistEmails: $toggleAutochartistEmails
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleAffiliateAutomaticCommissions(accountId, automaticCommissions) {
      const variables = {
        accountId,
        automaticCommissions,
      }

      const query = `
        mutation (
          $accountId: Int!,
          $automaticCommissions: Boolean!
        ) {
          toggleAutomaticCommissions (
            accountId: $accountId
            automaticCommissions: $automaticCommissions
          ) {
            ... on BaseAccount {
              id
            }
          }
        }
      `

      return dbClient.query(query, variables)
    },

    removeDocument(document) {
      const variables = {
        documentId: document.id,
      }

      const query = `
        mutation ($documentId: Int!) {
          removeDocument(
            id: $documentId
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    sendInvitation(clientId) {
      const variables = {clientId}

      const query = `
        mutation ($clientId: Int!) {
          sendInvitation(
            clientId: $clientId
          )
        }
      `
      return dbClient.query(query, variables)
    },

    updateEconomicProfile(clientId, data) {
      const variables = {
        approxNetWorth: data.approxNetWorth,
        approxYearlyIncome: data.approxYearlyIncome,
        sourceOfFunds: data.sourceOfFunds,
        jobTitle: data.jobTitle,
        politicallyExposed: data.politicallyExposed,
        usCitizen: data.usCitizen,
        transactionPurpose: data.transactionPurpose,
        approxExpectedDeposit: data.approxExpectedDeposit,
        originOfFunds: data.originOfFunds,
        natureOfTransactions: data.natureOfTransactions,
        taxJurisdictionCountry: data.taxJurisdictionCountry,
        tin: data.tin,
        tinReason: data.tinReason,
        transactionPurposeClarify: data.transactionPurposeClarify,
        natureOfTransactionsClarify: data.natureOfTransactionsClarify,
        sourceOfFundsClarify: data.sourceOfFundsClarify,
        tinClarify: data.tinClarify,
        politicallyExposedReason: data.politicallyExposedReason,
      }

      variables.id = clientId
      const query = `mutation (
                      $id: Int!,
                      $approxNetWorth: String,
                      $approxYearlyIncome: String,
                      $sourceOfFunds: String,
                      $jobTitle: String,
                      $politicallyExposed: YesNo,
                      $usCitizen: YesNo,
                      $transactionPurpose: String,
                      $approxExpectedDeposit: String,
                      $originOfFunds: String,
                      $natureOfTransactions: String,
                      $taxJurisdictionCountry: Country,
                      $tin: String,
                      $tinReason: String,
                      $transactionPurposeClarify: String,
                      $natureOfTransactionsClarify: String,
                      $sourceOfFundsClarify: String,
                      $tinClarify: String,
                      $politicallyExposedReason: String
                      ){
                        updateClientDetails(
                          id: $id
                          globalQuestionnaire: {
                            approxNetWorth: $approxNetWorth,
                            approxYearlyIncome: $approxYearlyIncome,
                            sourceOfFunds: $sourceOfFunds,
                            jobTitle: $jobTitle,
                            politicallyExposed: $politicallyExposed,
                            usCitizen: $usCitizen,
                            transactionPurpose: $transactionPurpose,
                            approxExpectedDeposit: $approxExpectedDeposit,
                            originOfFunds: $originOfFunds,
                            natureOfTransactions: $natureOfTransactions,
                            taxJurisdictionCountry: $taxJurisdictionCountry,
                            tin: $tin,
                            tinReason: $tinReason,
                            transactionPurposeClarify: $transactionPurposeClarify,
                            natureOfTransactionsClarify: $natureOfTransactionsClarify,
                            sourceOfFundsClarify: $sourceOfFundsClarify,
                            tinClarify: $tinClarify,
                            politicallyExposedReason: $politicallyExposedReason
                          }) {
                           id
                         }
                      }`
      return dbClient.query(query, variables)
    },

    sendPushNotifications(args) {
      const variables = {
        clientIds: args.clientIds,
        searchParams: JSON.stringify(args.searchParams),
        csvLink: args.csvLink,
        defaultLanguage: args.defaultLanguage,
        messages: args.messages,
        type: args.type,
      }

      const query = `
        mutation (
          $clientIds: [Int]
          $searchParams: String
          $csvLink: String,
          $defaultLanguage: Language!
          $messages: [PushNotificationMessageType]!
          $type: pushNotificationsTypesType
        ) {
          sendPushNotifications (
            clientIds: $clientIds
            searchParams: $searchParams
            csvLink: $csvLink
            defaultLanguage: $defaultLanguage
            messages: $messages
            type: $type
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    sendMarketingEmails(clientIds, templateId, unsubscribeGroupId, searchParams, clientCount, template, unsubscribeGroup) {
      const variables = {clientIds, templateId, unsubscribeGroupId, clientCount, template, unsubscribeGroup,
        searchParams: JSON.stringify(searchParams)}

      const query = `
        mutation (
          $clientIds: [Int]
          $searchParams: String
          $templateId: String!
          $unsubscribeGroupId: String!
          $clientCount: Int
          $template: String
          $unsubscribeGroup: String
        ) {
          sendMarketingEmails (
            clientIds: $clientIds
            searchParams: $searchParams
            templateId: $templateId
            unsubscribeGroupId: $unsubscribeGroupId
            clientCount: $clientCount
            template: $template
            unsubscribeGroup: $unsubscribeGroup
          )
        }
      `
      return dbClient.query(query, variables)
    },

    upsertDocumentTranslation(data) {
      const variables = {
        id: data.id,
        clientId: data.clientId,
        documentId: data.documentId,
        meta: JSON.stringify(data.meta),
        translationType: data.translationType,
        translator: data.translator,
      }
      const query = `mutation (
                      $id: Int,
                      $meta: String,
                      $translationType: TranslationTypeTypes!,
                      $documentId: Int!,
                      $translator: Int,
                      ){
                        upsertDocumentTranslation(
                          id: $id,
                          documentId: $documentId,
                          translator: $translator,
                          meta: $meta,
                          translationType: $translationType,
                        ) {
                            id
                        }
                      }`
      return dbClient.query(query, variables)
    },
    updateClientPhones(clientId, data) {
      const {formatted, graphQlTypes, variables} = processFieldsData(data)

      variables.id = clientId
      const query = `mutation (
        $id: Int!,
        ${toGraphQlParamsDeclaration(graphQlTypes)}
      ){
        updateClientPhones(
          id: $id
          ${stringifyQL(formatted, true)}
        ) {
          id
        }
      }
      `
      return dbClient.query(query, variables)
    },
    setMissingDocuments(clientId, documentType, value) {
      const variables = {
        clientId,
        documentType,
        value,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $documentType: String!,
          $value: Boolean!
        ) {
          setMissingDocuments (
            clientId: $clientId
            documentType: $documentType
            value: $value
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    doElectronicIdVerification(clientId) {
      const variables = {
        clientId,
      }
      const query = `
        mutation ($clientId: Int!) {
          doElectronicIDVerification (clientId: $clientId)
          {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    toggleBDXCallCompleted(clientId, toggleBDXCallCompleted) {
      const variables = {
        clientId,
        toggleBDXCallCompleted,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $toggleBDXCallCompleted: Boolean!
        ) {
          toggleBDXCallCompleted (
            clientId: $clientId
            toggleBDXCallCompleted: $toggleBDXCallCompleted
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    bdxWaiverAccepted(clientId,bdxWaiverAccepted) {
      const variables = {
        clientId,
        bdxWaiverAccepted,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $bdxWaiverAccepted: Boolean!
        ) {
          bdxWaiverAccepted (
            clientId: $clientId
            bdxWaiverAccepted: $bdxWaiverAccepted
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleIsPhoneVerified(clientId, isPhoneVerified) {
      const variables = {
        clientId,
        isPhoneVerified,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $isPhoneVerified: Boolean!
        ) {
          toggleIsPhoneVerified (
            clientId: $clientId
            isPhoneVerified: $isPhoneVerified
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleEmailConfirmed(clientId, emailConfirmed) {
      const variables = {
        clientId,
        emailConfirmed,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $emailConfirmed: Boolean!
        ) {
          toggleEmailConfirmed (
            clientId: $clientId
            emailConfirmed: $emailConfirmed
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    bdxInvestmentChoiceFinalEdit(clientId,bdxInvestmentChoiceFinal) {
      const variables = {
        clientId,
        bdxInvestmentChoiceFinal,
      }

      const query = `
        mutation(
          $clientId: Int!,
          $bdxInvestmentChoiceFinal: String!
        ) {
          bdxInvestmentChoiceFinal(
            clientId: $clientId
            bdxInvestmentChoiceFinal : $bdxInvestmentChoiceFinal
          ) { id }
        }
      `

      return dbClient.query(query, variables)
    },

    getWithdrawableMethods(accountId) {
      const variables = {
        accountId,
      }
      const query = `
      query accounts($accountId: Int!){
        accounts(id: $accountId) {
          ... on BaseAccount {
            __typename
            id
            remoteId
            withdrawablePaymentMethods {
              id
              order
              details
              vendor
              cardNumber
              cardHolderName
              vendorAccountEmail
              vendorAccountId
              iban
              swiftCode
              accountNumber
              provider
              paymentOption
              cryptoCurrency
              amount
              currency
              amountLeft
            }
          }
        }
      }`
      return dbClient.query(query, variables)
    },

    toggleHideWDMethods(clientId, hideWDMethods) {
      const variables = {
        clientId,
        hideWDMethods,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hideWDMethods: Boolean!
        ) {
          toggleHideWDMethods (
            clientId: $clientId
            hideWDMethods: $hideWDMethods
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    toggleWithdrawalsApproval(clientId, hasDisabledWithdrawals) {
      const variables = {
        clientId,
        hasDisabledWithdrawals,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasDisabledWithdrawals: Boolean!
        ) {
          toggleWithdrawalsApproval (
            clientId: $clientId
            hasDisabledWithdrawals: $hasDisabledWithdrawals
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    signPushNotificationFileUrl() {
      const query = `
        mutation {
          signPushNotificationFileUrl {
            csvKey
            csvPlainUrl
            csvSignedUrl
          }
        }
      `

      return dbClient.query(query)
    },

    cancelRawSubscription(accountId) {
      const variables = {accountId}
      const query = `
        mutation(
          $accountId: Int!,
        ) {
          cancelRawSubscription(accountId: $accountId){
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    reactivateRawSubscription(accountId) {
      const variables = {accountId}
      const query = `
        mutation(
          $accountId: Int!,
        ) {
          reactivateRawSubscription(accountId: $accountId){
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    changeRawSubscription(accountId, plan, forceChange) {
      const variables = {accountId, plan, forceChange}
      const query = `
        mutation(
          $accountId: Int!,
          $plan: rawSubscriptionPlanType!
          $forceChange: Boolean
        ) {
          changeRawSubscription(plan: $plan, accountId: $accountId, forceChange: $forceChange){
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    removeFromFallback(accountId) {
      const variables = {
        accountId,
      }

      const query = `
        mutation ($accountId: Int!) {
          removeFromfallback (
            accountId: $accountId
          )
        }
      }`
      return dbClient.query(query, variables)
    },

    toggleSwapFree(clientId, swapBehaviour) {
      const requestPath = `${backendUrl}/api/v1/clients/swaps/${clientId}/${swapBehaviour}`
      const params = null
      return backendRequest(requestPath,params).then((res)=>res.json())
    },
    getSwapBehaviour(clientId) {
      const requestPath = `${backendUrl}/api/v1/clients/swaps/${clientId}`
      const params = null
      const HTTPMethod = 'GET'
      return backendRequest(requestPath,params,HTTPMethod).then((res)=>res.json())
    },
    updateDueDiligence(edd, action) {
      const variables = {
        id: edd.id,
        result: action,
      }

      const query = `
        mutation (
          $id: Int!
          $result: DueDiligenceStatusType!
        ) {
          updateDueDiligence(
            id: $id
            result: $result
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },
    revertDueDiligence(dueDiligence) {
      const variables = {
        id: dueDiligence.id,
      }

      const query = `
        mutation ($id: Int!) {
          revertDueDiligence(
            id: $id
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    createDueDiligence(answers, clientId) {
      const variables = {
        clientId,
        answers,
      }

      const query = `
        mutation (
          $clientId: Int,
          $answers: DueDiligenceAnswersInputType!
        ) {
          createDueDiligence(
            clientId: $clientId
            answers: $answers
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleDormantReactivation(clientId, dormantReactivation) {
      const variables = {
        clientId,
        dormantReactivation,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $dormantReactivation: Boolean!
        ) {
          toggleDormantReactivation (
            clientId: $clientId
            dormantReactivation: $dormantReactivation
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    revertDeposit(depositId, amount) {
      const variables = {
        id: depositId,
        amount,
      }

      const query = `
        mutation (
          $id: Int!,
          $amount: Float!,
        ) {
          revertDeposit(id: $id, amount: $amount) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    revertWithdrawal(withdrawalId, amount) {
      const variables = {
        id: withdrawalId,
        amount,
      }

      const query = `
        mutation (
          $id: Int!,
          $amount: Float!,
        ) {
          revertWithdrawal(id: $id, amount: $amount) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    toggleIgnoreIbTermination(clientId, ignoreIbTermination) {
      const variables = {
        clientId,
        ignoreIbTermination,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $ignoreIbTermination: Boolean!
        ) {
          toggleIgnoreIbTermination (
            clientId: $clientId
            ignoreIbTermination: $ignoreIbTermination
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleIsDirectIB(clientId, isDirectIB) {
      const variables = {
        clientId,
        isDirectIB,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $isDirectIB: Boolean!
        ) {
          toggleIsDirectIB (
            clientId: $clientId
            isDirectIB: $isDirectIB
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleHasOpenComplaint(clientId, hasOpenComplaint) {
      const variables = {
        clientId,
        hasOpenComplaint,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $hasOpenComplaint: Boolean!
        ) {
          toggleHasOpenComplaint (
            clientId: $clientId
            hasOpenComplaint: $hasOpenComplaint
          ) {
            id
          }
        }
      `

      return dbClient.query(query, variables)
    },

    fetchDepositActivityLogs(id) {
      const query = `query($id: Int!) {
        deposit(id: $id) {
          activityLogs {
            __typename
            ... on BaseLogInterface {
              id
              ip
              ipCountry
              type
              origin
              createdAt
              user {
                id
                firstName
                lastName
                avatar
              }
              objectDeposit {
                id
              }
              objectWithdrawal {
                id
              }
            }
            ... on Note {
              content
            }
            ... on withReasonLog {
              reason
              rejectionCode
              pendingCode
            }
            ... on AccountCheckLog {
              skrillVerificationResult
            }
            ... on Note {
              content
              fileName
              fileUrl
            }
          }
        }
      }`

      return dbClient.query(query, {id})
    },
    fetchWithdrawalActivityLogs(id) {
      const query = `query($id: Int!) {
        withdrawal(id: $id) {
          activityLogs {
            __typename
            ... on BaseLogInterface {
              id
              ip
              ipCountry
              type
              origin
              createdAt
              user {
                id
                firstName
                lastName
                avatar
              }
              objectDeposit {
                id
              }
              objectWithdrawal {
                id
              }
            }
            ... on Note {
              content
            }
            ... on withReasonLog {
              reason
              rejectionCode
              pendingCode
            }
            ... on AccountCheckLog {
              skrillVerificationResult
            }
            ... on Note {
              content
              fileName
              fileUrl
            }
          }
        }
      }`

      return dbClient.query(query, {id})
    },
    fetchDocumentActivityLogs(id) {
      const query = `query($id: Int!) {
        document(id: $id) {
          activityLogs {
            __typename
            ... on BaseLogInterface {
              id
              ip
              ipCountry
              type
              origin
              createdAt
              user {
                id
                firstName
                lastName
                avatar
              }
              objectDocument {
                id
              }
            }
            ... on Note {
              content
            }
            ... on withReasonLog {
              reason
              rejectionCode
              pendingCode
            }
            ... on Note {
              content
              fileName
              fileUrl
            }
            ... on GenericLog {
              assignee {
                firstName
                lastName
              }
            }
          }
        }
      }`

      return dbClient.query(query, {id})
    },
    toggleAllowCopyTrading(clientId, allowCopyTrading, force) {
      const variables = {
        clientId,
        allowCopyTrading,
        force,
      }
      const query = `
        mutation (
          $clientId: Int!,
          $allowCopyTrading: Boolean!,
          $force: Boolean
        ) {
          toggleAllowCopyTrading (
            clientId: $clientId
            allowCopyTrading: $allowCopyTrading
            force: $force
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    updateCopyTrading(id, clientId, account, accountCopying, action) {
      const variables = {
        id,
        clientId,
        account,
        accountCopying,
        action,
      }
      const query = `
        mutation (
          $id: Int,
          $clientId: Int,
          $account: Int,
          $accountCopying: Int,
          $action: String
        ) {
          upsertCopyTrading (
            id: $id
            clientId: $clientId
            account: $account
            accountCopying: $accountCopying
            action: $action
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    linkPartnerWithRetailClient(clientId, linkedClientId) {
      const variables = {
        clientId,
        linkedClientId: linkedClientId == null ? unset.unset.key : linkedClientId,
      }
      const query = `
        mutation (
          $clientId: Int!
          $linkedClientId: UnsetOrInt!
        ) {
          linkPartnerWithRetailClient (
            clientId: $clientId
            linkedClientId: $linkedClientId
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    upsertClientNotice(data) {
      const variables = {
        id: data.form.id,
        template: data.form.template,
        clientId: data.clientId,
        values: JSON.stringify(data.form.values),
        type: data.type,
      }
      const query = `mutation (
        $id: Int,
        $template: partnersAppendicesTypeType,
        $values: String,
        $clientId: Int,
        $type: clientNoticeTypeType,
      ){
        upsertClientNotice(
          id: $id
          template: $template
          values: $values
          clientId: $clientId
          type: $type
        ) {
          id
        }
      }
      `
      return dbClient.query(query, variables)
    },

    upsertSalesClientNote(clientId, note) {
      const variables = {
        id: clientId,
        note,
      }
      const query = `mutation (
          $id: Int!,
          $note: String
        ){
          upsertSalesClientNote(
            id: $id
            note: $note
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    upsertAffiliateClientNote(clientId, note) {
      const variables = {
        id: clientId,
        note,
      }
      const query = `mutation (
          $id: Int!,
          $note: String
        ){
          upsertAffiliateClientNote(
            id: $id
            note: $note
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    refundDeposit(depositId) {
      const variables = {
        id: depositId,
      }

      const query = `
        mutation (
          $id: Int!
        ) {
          refundDeposit(id: $id) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    addBonus(accountId) {
      const query = `
        mutation (
          $accountId: Int!
        ) {
          addBonus (
            accountId: $accountId
          ) {
            ... on BaseAccount {
              id
            }
          }
        }
      `
      return dbClient.query(query, {accountId})
    },

    closeAllTrades(accountId) {
      const query = `
        mutation (
          $accountId: Int!
        ) {
          closeAllTrades (
            accountId: $accountId
          )
        }
      `
      return dbClient.query(query, {accountId})
    },

    removeBonus(accountId) {
      const query = `
        mutation (
          $accountId: Int!
        ) {
          removeBonus (
            accountId: $accountId
          ) {
            ... on BaseAccount {
              id
            }
          }
        }
      `
      return dbClient.query(query, {accountId})
    },

    editFundingDocument(args) {
      const variables = {
        fundingDocId: args.fundingDocId,
        confirmed: args.confirmed,
        pendingNotes: args.pendingNotes,
      }

      const query = `
        mutation (
          $fundingDocId: Int!
          $confirmed: Boolean
          $pendingNotes: String
        ) {
          editFundingDocument (
            fundingDocId: $fundingDocId
            confirmed: $confirmed
            pendingNotes: $pendingNotes
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    toggleFundingDocIsThirdPartyVerified(paymentMethodId, isThirdPartyVerified) {
      const variables = {
        paymentMethodId: paymentMethodId,
        isThirdPartyVerified: isThirdPartyVerified,
      }
      const query = `
        mutation (
          $paymentMethodId: Int!
          $isThirdPartyVerified: Boolean!
        ) {
          toggleFundingDocIsThirdPartyVerified(
            paymentMethodId: $paymentMethodId
            isThirdPartyVerified: $isThirdPartyVerified
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    toggleAllowAccountManually(clientId, accounts) {
      const variables = {
        clientId,
        accounts,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $accounts: [String]!
        ) {
          toggleAllowAccountManually (
            clientId: $clientId
            accounts: $accounts
            ) {
              id
            }
          }
        `
      return dbClient.query(query, variables)
    },

    clearAccountCache(accountId) {
      const query = `
        mutation (
          $accountId: Int!
        ) {
          clearAccountCache (
            accountId: $accountId
          )
        }
      `
      return dbClient.query(query, {accountId})
    },

    removeTradingAccounts(clientId, accountIds) {
      const variables = {
        clientId,
        accountIds,
      }
      const query = `
        mutation (
          $clientId: Int!,
          $accountIds: [Int]!
        ) {
          removeTradingAccounts (
            clientId: $clientId
            accountIds: $accountIds
            )
          }
        `
      return dbClient.query(query, variables)
    },

    createVpsSubscription(clientId, planName) {
      const variables = {
        clientId,
        planName
      }

      const query = `
        mutation(
          $clientId: Int!,
          $planName: String!,
          $billingCycle: VpsSubscriptionBillingCycle,
          $paymentMethod: vpsSubscriptionPaymentMethod,
          $tokenId: String,
          $tokenThreeD: String
        ) {
          createVpsSubscription(
            clientId: $clientId,
            planName: $planName,
            billingCycle: $billingCycle,
            paymentMethod: $paymentMethod,
            tokenId: $tokenId,
            tokenThreeD: $tokenThreeD
          ) {
            tokenThreeD
            subscription {
              id
            }
          }
        }
      `
      return dbClient.query(query, variables)
    },

    cancelVpsSubscription(id, clientId, reason, immediate) {
      const variables = {
        id,
        clientId,
        reason,
        immediate
      }

      const query = `
      mutation(
        $id: Int!,
        $clientId: Int!,
        $reason: String!,
        $immediate: Boolean,
      ) {
        cancelVpsSubscription(
          id: $id
          clientId: $clientId,
          reason: $reason
          immediate: $immediate
        ) {
          status
        }
      }
    `
      return dbClient.query(query, variables)
    },

    terminateVpsSubscription(id, clientId, reason) {
      const variables = {
        id,
        clientId,
        reason
      }

      const query = `
      mutation(
        $id: Int!,
        $clientId: Int!,
        $reason: String!,
      ) {
        terminateVpsSubscription(
          id: $id
          clientId: $clientId,
          reason: $reason
        ) {
          status
        }
      }
    `
      return dbClient.query(query, variables)
    },

    verifyClientDetails(clientId, data) {
      data = mapValues(data, (value, key) =>
        ['vps', 'optInMarketing', 'optInSms'].includes(key) ? value === 'yes' : value)
      const {formatted, graphQlTypes, variables} = processFieldsData(data)

      variables.id = clientId
      const query = `mutation (
        $id: Int!,
        ${toGraphQlParamsDeclaration(graphQlTypes)}
      ){
        verifyClientDetails(
          id: $id
          ${stringifyQL(formatted, true)}
        )
      }
      `
      return dbClient.query(query, variables)
    },

    signDepartmentTemplateEmailFileUrl() {
      const query = `
        mutation {
          signDepartmentTemplateEmailFileUrl {
            imageKey
            imagePlainUrl
            imageSignedUrl
          }
        }
      `

      return dbClient.query(query)
    },

    sendDepartmentTemplateEmail({clientId, selectedTemplate, imageUrl}) {
      const variables = {
        clientId,
        selectedTemplate,
        imageUrl,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $selectedTemplate: String!,
          $imageUrl: String,
        ) {
          sendDepartmentTemplateEmail (
            clientId: $clientId,
            selectedTemplate: $selectedTemplate,
            imageUrl: $imageUrl
          )
        }
      `
      return dbClient.query(query, variables)
    },

    clientsExportSearch(searchArgs, page, limit) {
      const variables = {
        searchArgs: JSON.stringify(searchArgs),
        limit: limit,
        offset: getOffset(page, limit),
        orderBy: 'id',
        orderDirection: 'descending'
      }

      const query = `mutation (
          $searchArgs: String
          $limit: Int
          $offset: Int
          $orderBy: OrderBy
          $orderDirection: OrderDirection
        ) {
        clientsExportSearch(
          searchArgs: $searchArgs
          limit: $limit
          offset: $offset
          orderBy: $orderBy
          orderDirection: $orderDirection
        )
      }`

      return dbClient.query(query, variables)
    },

    toggleShowMasterStrategies(clientId, showMasterStrategies) {
      const variables = {
        clientId,
        showMasterStrategies,
      }
      const query = `
        mutation (
          $clientId: Int!
          $showMasterStrategies: Boolean!
        ) {
          toggleShowMasterStrategies (
            clientId: $clientId
            showMasterStrategies: $showMasterStrategies
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    toggleTradeCompanion(clientId, toggleTradeCompanion) {
      const variables = {
        clientId,
        toggleTradeCompanion: toggleTradeCompanion,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $toggleTradeCompanion: Boolean!,
        ) {
          toggleTradeCompanion (
            clientId: $clientId
            toggleTradeCompanion: $toggleTradeCompanion
          ) {
              id
            }
          }
        `
      return dbClient.query(query, variables)
    },

    addMetadataToClient(clientId, metadata) {
      const variables = {
        clientId,
        metadata: JSON.stringify(metadata),
      }

      const query = `
        mutation (
          $clientId: Int!
          $metadata: String!
        ) {
          addMetadataToClient (
            clientId: $clientId
            metadata: $metadata
          )
        }
      `
      return dbClient.query(query, variables)
    },

    togglePasswordProtectedStrategies(clientId, togglePasswordProtected) {
      const variables = {
        clientId,
        togglePasswordProtected,
      }

      const query = `
        mutation (
          $clientId: Int!
          $togglePasswordProtected: Boolean!
        ) {
          togglePasswordProtectedStrategies (
            clientId: $clientId
            togglePasswordProtected: $togglePasswordProtected
          )
        }
      `
      return dbClient.query(query, variables)
    },

    toggleTradingCentral(clientId, tradingCentralEnabled) {
      const variables = {
        clientId,
        tradingCentralEnabled,
      }

      const query = `
        mutation (
          $clientId: Int!
          $tradingCentralEnabled: Boolean!
        ) {
          toggleTradingCentral (
            clientId: $clientId
            tradingCentralEnabled: $tradingCentralEnabled
          ){
            tradingCentralEnabled
          }
        }
      `
      return dbClient.query(query, variables)
    },

    takeOpenPositionsSnapshot(accountId) {
      const variables = {
        accountId,
      }

      const query = `
        mutation (
          $accountId: Int!
        ) {
          takeOpenPositionsSnapshot (
            accountId: $accountId
            )
          }
        `
      return dbClient.query(query, variables)
    },

    attachToAffiliate(clientId, affiliateId, campaignId, trackingCode) {
      const variables = {
        clientId,
        affiliateId,
        campaignId,
        trackingCode,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $affiliateId: Int!,
          $campaignId: Int!,
          $trackingCode: String!,
        ){
          assignClientToAffiliate (
            clientId: $clientId
            affiliateId: $affiliateId
            campaignId: $campaignId
            trackingCode: $trackingCode
          )
        }`
      return dbClient.query(query, variables)
    },

    toggleAdditionalBonus(clientId, additionalBonus) {
      const variables = {
        clientId,
        additionalBonus,
      }

      const query = `
        mutation (
          $clientId: Int!,
          $additionalBonus: Boolean!
        ) {
          toggleAdditionalBonus (
            clientId: $clientId
            additionalBonus: $additionalBonus
          ) {
              clientId
              additionalBonus
          }
        }
      `

      return dbClient.query(query, variables)
    },

    toggleFundManager(clientId, fundManager) {
      const variables = {
        clientId,
        fundManager,
      }
      const query = `
        mutation (
          $clientId: Int!
          $fundManager: Boolean!
        ) {
          toggleFundManager (
            clientId: $clientId
            fundManager: $fundManager
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },

    resolveOrphanedFollower(clientAccountId, providerAccountId) {
      const variables = {
        clientAccountId,
        providerAccountId,
      }
      const query = `
        mutation (
          $clientAccountId: Int!
          $providerAccountId: Int!
        ) {
          resolveOrphanedFollower (
            clientAccountId: $clientAccountId
            providerAccountId: $providerAccountId
          ) {
            result
          }
        }
      `
      return dbClient.query(query, variables)
    },

  }
}
