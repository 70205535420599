import React from 'react'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import {compose, provideProps, mountDataProviders} from '../decorators'
import {bookAllocationProvider} from './providers'
import PureComponent from '../PureComponent'
import BookAllocationForm from './BookAllocationForm'

class BookAllocationEdit extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  render() {
    const {bookAllocation} = this.props
    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/book-allocation"
            active={false}
          >
            <BreadcrumbItem>
              Book Allocation
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            {bookAllocation.id}
          </BreadcrumbItem>
        </Breadcrumb>
        <BookAllocationForm data={bookAllocation} />
      </Container>
    )
  }
}

export default compose(
  provideProps((state) => ({
    bookAllocation: state.bookAllocation,
  })),
  mountDataProviders({bookAllocationProvider}),
)(BookAllocationEdit)
