/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import {dispatchIn, getIn} from '../stateUtils'
import {getHocName} from '../useful'


export default (getPath, getFilterPath) => (
  (Component) => class UiMountComponent extends React.Component {

    static displayName = getHocName('UiMount', Component)

    static contextTypes = {
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
      dispatchEmitter: PropTypes.object.isRequired,
    };

    calculateUiProps(props) {
      const {getState, dispatch} = this.context
      const state = getState()
      const uiProps = {}

      const path = getPath(state)
      if ('uiDispatch' in props || 'uiState' in props) {
        console.error('Overwriting uiDispatch/uiState in props') // eslint-disable-line no-console
      }
      uiProps.uiDispatch = dispatchIn(dispatch, path)
      uiProps.uiState = getIn(state, path, {any: {}})

      if (getFilterPath) {
        const filterPath = getFilterPath(state)
        if ('filterDispatch' in props || 'filterState' in props) {
          console.error('Overwriting filterDispatch/filterState in props') // eslint-disable-line no-console
        }
        uiProps.filterDispatch = dispatchIn(dispatch, filterPath)
        uiProps.filterState = getIn(state, filterPath, {any: {}})
      }
      return uiProps
    }

    componentWillMount() {
      this.mounted = true
      this.onDispatch = () => {
        if (this.mounted) {
          this.forceUpdate()
        }
      }
      this.context.dispatchEmitter.on('dispatch', this.onDispatch)
    }

    componentWillUnmount() {
      this.mounted = false
      this.context.dispatchEmitter.removeListener(this.onDispatch)
    }

    render() {
      return (
        <Component
          {...this.props}
          {...this.calculateUiProps(this.props)}
        />
      )
    }

  }
)
