/*
 * after this refactoring is over, dataProviderNew.js will replace dataProvider.js
 */

import {isEqual, cloneDeep} from 'lodash'
import {getFromStorage, saveToStorage, deleteFromStorage} from './useful'

let WINDOW_FOCUSED = true
let intrvl = false
window.onblur = () => {
  if (intrvl) {
    clearInterval(intrvl)
  }
  intrvl = setTimeout(() => {
    WINDOW_FOCUSED = false
  },20000)
}
window.onfocus = () => {
  if (intrvl) {
    clearInterval(intrvl)
  }
  WINDOW_FOCUSED = true
}
document.onblur = window.onblur
document.focus = window.focus

/*
 * Fetches the data from the GraphQL server
 */

export default class DataProvider {

  /*
   * Params:
   *
   * `getQuery`: () => query (string)
   * `dbClient`: object capable of executing the query.`dbClient.query(query)` should return
   * `Promise(data)`
   * onData: (data) => some action with data
   * `fetchInterval`: millis; if set, dataProvider will poll the query with this interval. If it gets
   * different data than the previous time, `onData` is called. If you use this ability, don't
   * forget to call `.unmount()` when unmounting the component!
   */

  constructor(dispatch, dbClient, getQuery, onData, name, fetchInterval, onError, params = {}) {
    this.dispatch = dispatch
    this.dbClient = dbClient
    this.getQuery = getQuery
    this.onData = onData
    this.query = null
    this.queryIndex = 0
    this.name = name
    this.fetchInterval = fetchInterval
    this.onError = onError
    this.startPolling()
    this.fetchedAtLeastOnce = false
    Object.assign(this, params)
  }

  startPolling() {
    if (this.fetchInterval > 0 && !this.subscription) {
      this.subscription = setInterval(this.onInterval, this.fetchInterval)
    }
  }

  stopPolling() {
    if (this.subscription) {
      clearInterval(this.subscription)
      this.subscription = null
    }
  }

  addListener() {
    if (!this.listenerRegistered) {
      document.addEventListener('visibilitychange', this.onVisibilityChange)
      this.listenerRegistered = true
    }
  }

  removeListener() {
    if (this.listenerRegistered) {
      document.removeEventListener('visibilitychange', this.onVisibilityChange)
      this.listenerRegistered = false
    }
  }

  onInterval = () => {
    if (!WINDOW_FOCUSED || document.hidden) {
      this.stopPolling()
      this.addListener()
      return
    }
    this.fetchOnInterval()
  }

  onVisibilityChange = () => {
    if (!WINDOW_FOCUSED || document.hidden) {
      return
    }
    this.removeListener()
    this.startPolling()
    this.fetchOnInterval()
  }

  fetchOnInterval() {
    if (WINDOW_FOCUSED && !document.hidden && this.fetchProps) {
      this.fetch(true, this.fetchProps, true)
    }
  }

  /*
   * fetch the data and run `onData(data)`. Use `force = true` if you want to query the server even
   * if the query itself does not change from the last fetch (for example, when you want to poll the
   * same query periodically)
   *
   * `props` are used as an argument for `getQuery`. Typically you want to use it such as:
   *
   * componentWillReceiveProps(nextProps) {
   *   this.dataProvider.fetch(false, nextProps)
   * }
   *
   * Although DataProvider has reference to the component it belongs to, nextProps have to be passed
   * explicitly as shown above.
   */

  fetch(force, props, hideLoading) {
    this.fetchProps = props
    return new Promise((resolve, reject) => {
      const newQuery = this.getQuery(props)
      if (newQuery !== null && (force || newQuery !== this.query)) {
        if (!hideLoading) {
          this.dispatch(
            `Query start ${this.name}`,
            (state) => ({...state, queryCount: (state.queryCount || 0) + 1})
          )
        }
        this.query = newQuery
        const queryIndex = ++this.queryIndex
        let cachedData = getFromStorage(this.name)
        if (cachedData && cachedData.expiresAt < Date.now()) {
          deleteFromStorage(this.name)
          cachedData = false
        }
        const fetchPromise = (this.cache && cachedData)
          ? new Promise(resolve => resolve({...cachedData, fromCache: true}))
          : this.dbClient.query(newQuery)
        fetchPromise
          .then((res) => {
            if (!res.fromCache && this.cache) {
              saveToStorage(this.name, {...res, expiresAt: Date.now() + this.cacheInterval})
            }
            if (!hideLoading) {
              this.fetchedAtLeastOnce = true
              this.dispatch(
                `Query end ${this.name} ${res.fromCache ? '(Cached)' : ''}`,
                (state) => ({...state, queryCount: state.queryCount - 1})
              )
            }
            if (((this.oldData && this.queryIndex > queryIndex) || isEqual(res, this.oldData)) && !force) {
              this.fetchedAtLeastOnce = true
              resolve()
            } else {
              this.oldData = cloneDeep(res)
              try {
                this.onData(res)
              } catch (e) {
                console.error(e) // eslint-disable-line no-console
                reject(e)
              }
              this.fetchedAtLeastOnce = true
              resolve()
            }
          })
          .catch((e) => {
            if (this.onError) {
              this.onError(e)
              this.dispatch(
                `Query end In Error ${this.name}`,
                (state) => ({...state, queryCount: state.queryCount - 1})
              )
              resolve()
            }
            console.error(e) // eslint-disable-line no-console
          })
      } else {
        this.fetchedAtLeastOnce = true
        resolve()
      }
    })
  }

  rejectOldData() {
    this.oldData = null
  }

  unmount() {
    this.stopPolling()
    this.removeListener()
  }
}
