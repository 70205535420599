import {get} from 'lodash'
import config from '../config'

export function putFile(file, signedUrl, RequestClass = window.XMLHttpRequest) {
  return new Promise((resolve, reject) => {
    const xhr = new RequestClass()
    xhr.open('PUT', signedUrl)
    if (file.name) {
      xhr.setRequestHeader('Content-Disposition', `filename=${file.name}`)
    }
    xhr.setRequestHeader('x-amz-acl', 'public-read')
    xhr.onreadystatechange = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          resolve()
        } else {
          reject(`PUT: Wrong status code ${xhr.status}`)
        }
      }
    }
    xhr.send(file)
  })
}

export function createPaymentCardToken(data, client, spreedlyApiUrl, fetch) {
  const payload = {
    payment_method: {
      credit_card: {
        full_name: data.cardHolder,
        number: data.cardNumber,
        verification_value: data.cvv,
        month: data.expiryMonth,
        year: data.expiryYear,
        email: client.email,
      },
      data: {
        bdswiss_id: client.id,
      },
    },
  }

  const options = {
    method: 'POST',
    cache: 'no-cache',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
    },
  }

  return fetch(spreedlyApiUrl, options)
    .then((response) => response.json())
    .then((response) => {
      if (response.errors && response.errors.length > 0) {
        // FIXME add console.error when in debug mode
        return Promise.reject(response.errors)
      }
      return {
        token: get(response, 'transaction.payment_method.token'),
        bin: get(response, 'transaction.payment_method.first_six_digits'),
        lastFour: get(response, 'transaction.payment_method.last_four_digits'),
      }
    })
}

export function backendRequest(endpoint, params, method = 'POST', options) {
  const headers = get(options, 'headers', {'Content-Type': 'application/json'})
  const reqOptions = {
    method,
    headers,
    body: params && JSON.stringify(params),
    mode: 'cors',
    credentials: 'include',
    ...options
  }
  return fetch(endpoint, reqOptions)
}



export function backendGetRequest(endpoint, credentials) {
  const reqOptions = {
    method: 'GET',
    mode: (credentials) ? 'cors' : 'no-cors',
    credentials: (credentials) ? 'include' : undefined
  }
  return fetch(`${config.getInstance().backendUrl}${endpoint}`, reqOptions)
}
