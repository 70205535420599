import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'

export default class SearchHelpModal extends PureComponent {
  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
  }

  render() {
    const {show, onHide} = this.props
    return (
      <Modal
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton>
          <Modal.Title> How to search! </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{maxHeight: 'calc(100% - 50px)', overflowY: 'scroll'}}>
          <p>Search specific fields by prefixing search with <code>fieldname:</code></p>
          <p>Examples: <code>id:1115052</code>, <code>email:john.doe@example.com</code>, <code>first_name:Felix</code></p>
          <p>Available fields</p>
          <ul>
            <li>id</li>
            <li>email</li>
            <li>first_name</li>
            <li>last_name</li>
            <li>name</li>
            <li>ip &mdash; any IP address ever used by the client</li>
            <li>conversion_status</li>
            <li>company</li>
            <li>phone</li>
            <li>country</li>
            <li>city</li>
            <li>nationality</li>
            <li>signup_date</li>
            <li>status &mdash; trading status of the client: acitve, suspended, closed, new_account (not passed KYC)</li>
            <li>note &mdash; the note at the top of the client page. This field is not searched by default and has to be specified explicitly</li>
            <li>account.id &mdash; local account id</li>
            <li>account.remote_id &mdash; remote account id</li>
            <li>campaign.campaign_id</li>
            <li>campaign.affiliate</li>
            <li>withdrawal.amount</li>
            <li>withdrawal.iban</li>
            <li>withdrawal.swift</li>
            <li>withdrawal.bankName</li>
            <li>deposit.amount &mdash;&nbsp;<strong>broken</strong></li>
            <li>deposit.status</li>
            <li>deposit.vendor</li>
            <li>deposit.card_number</li>
            <li>deposit.cardholder_name</li>
          </ul>
          <p>fist_name, last_name and name fields will attempt to find similar sounding words, which should help with foreign namesamounts are being indexed in the deposit currency and not converted to EURFor the detail description of the search syntax please refer to&nbsp;<a href="https://www.elastic.co/guide/en/elasticsearch/reference/current/query-dsl-query-string-query.html#query-string-syntax">documentation</a></p>
          <h3 id="sample-queries">Sample queries</h3>
          <p><strong>The case of the field names as well as AND/OR/NOT words is important!</strong></p>
          <p><code>campaign.campaign\_id:51 AND country:Germanycampaign.campaign\_id:51 AND NOT country:Germanycampaign.campaign\_id:51 AND country:Germany AND signup\_date:[2017-05-11 TO 2017-05-12]</code></p>
          <p>search exact word:<code>"Martynas Bieliauskas"</code></p>
          <p>Search based on amount:<code>withdrawal.amount:&gt;10000</code></p>
          <p>Based on date (notice there is no space):<code>signup\_date:&gt;2017-05-12signup\_date:[2017-05-11 TO 2017-05-12]</code></p>
          <p>Gotchas: The following will not work right now as you'd expect:<code>deposit.amount:&gt;=10000 AND desposit.status:completed</code>As this will find any client who has a deposit &gt; 10k (even failed) and at least one completed deposit.</p>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar>
            <Button onClick={onHide} variant="info" size="sm">Close</Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
