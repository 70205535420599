import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {debounce, map, isBoolean, merge, get, filter} from 'lodash'
import {Row, Col, Form, Button, ButtonGroup, Card, Table, Pagination, Container} from 'react-bootstrap'
import style from './users.module.scss'
import ShiftForm from './ShiftForm'
import {shiftsProvider} from './providers'
import {getFullName} from '../utils/general'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {readableDate, getPageCount, getPageRange} from '../useful'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {compose, uiMount, provideProps, mountDataProviders, predispatch} from '../decorators'
import {documentDistributionAgentsProvider} from '../users/providers'

class AutoAssignDocumentSettingsEditor extends PureComponent {
  state = {
    selectedAgents: [],
    weight: 0,
    dateFilters: {
      createdFrom: moment().startOf('week').format('YYYY-MM-DD'),
      createdTo: moment().endOf('week').format('YYYY-MM-DD'),
    },
    isActiveFilter: true,
  }

  constructor(props) {
    super(props)
    this.toggleAgentSelection = debounce(this.toggleAgentSelection, 400)
  }

  static contextTypes = {
    documentDistributionAgentsProvider: PropTypes.object.isRequired,
    shiftsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  handleWeightChanged(newWeight) {
    if (isNaN(newWeight)) {
      this.setState({weight: newWeight, hasError: true})
      return
    }

    if (newWeight < 0) {
      this.setState({weight: 0, hasError: false})
    } else if (newWeight > 100) {
      this.setState({weight: 100, hasError: false})
    } else {
      this.setState({weight: newWeight, hasError: false})
    }
  }

  selectAllAgents(inverse) {
    const {backOfficeUsers} = this.props
    const {isActiveFilter} = this.state
    let visibleBackOfficeUsers = backOfficeUsers
    if (isActiveFilter) {
      visibleBackOfficeUsers = filter(backOfficeUsers, ['isActive', true])
    }
    return inverse ?
      this.setState({selectedAgents: []}) :
      this.setState({selectedAgents: map(visibleBackOfficeUsers, (a) => a.id)})
  }

  toggleAgentSelection(id) {
    const {selectedAgents} = this.state
    if (selectedAgents.includes(id)) {
      selectedAgents.splice(selectedAgents.indexOf(id), 1)
    } else {
      selectedAgents.push(id)
    }
    this.setState({selectedAgents: [...selectedAgents]})
  }

  setDocumentDistributionWeight() {
    const {selectedAgents, weight} = this.state
    this.props.actions.user.setDocumentDistributionWeight(selectedAgents, weight)
      .then(() => {
        this.context.documentDistributionAgentsProvider.fetch(true)
          .then(() => {
            this.setState({weight: 0, selectedAgents: []})
          })
        this.context.shiftsProvider.fetch()
        this.context.showNotification({
          title: 'Document Distribution Weight',
          message: 'Successfully updated.',
          position: 'tr',
          level: 'success',
        })
        this.selectAllAgents(true)
      })
      .catch((e) => {
        this.context.showNotification({
          message: e.message,
          level: 'error',
          autoDismiss: 0,
        })
      })
  }

  handleDateFilterChanged(name, value) {
    this.props.uiDispatch(
      'Filter shifts by dates',
      (state, arg) => ({
        ...state,
        dateFilters: {
          ...state.dateFilters,
          [name]: moment(value).format('YYYY-MM-DD'),
        },
        page: 1})
    )
  }

  closeShiftEditor() {
    this.toggleShiftEditor(false)
  }

  toggleShiftEditor(displayForm) {
    this.setState({selectedShift: isBoolean(displayForm) ? {} : displayForm})
    return this.props.uiDispatch('Displaying Add Shift', (state) => ({
      ...state, displayAddShift: displayForm, displayAddLeadErr: false}))
  }

  closeAddShift() {
    this.toggleShiftEditor(false)
  }

  renderShift(shift) {
    const {id, user, from, to, weight} = shift
    return (
      <tr id="t-shift" className={style.shiftRecord} key={id} onClick={() => this.toggleShiftEditor(shift)}>
        <td>{getFullName(user)}</td>
        <td>{readableDate(moment(from))}</td>
        <td>{readableDate(moment(to))}</td>
        <td>{weight}</td>
      </tr>
    )
  }

  toggleIsActive() {
    this.setState({isActiveFilter: !this.state.isActiveFilter})
  }

  render() {
    const {uiState, backOfficeUsers, shifts, page, shiftsCount} = this.props
    const {selectedAgents, weight, hasError, selectedShift, isActiveFilter} = this.state
    const createShiftClass = uiState.displayAddShift ?
      `${style.addCreateShift} ${style.addShiftOpen}` : style.addCreateShift
    let addLeadErrClass = 'bs-callout bs-callout-danger '
    addLeadErrClass += uiState.displayAddLeadErr && uiState.displayAddLead ? '' : 'd-none'
    let visibleBackOfficeUsers = backOfficeUsers
    if (isActiveFilter) {
      visibleBackOfficeUsers = filter(backOfficeUsers, ['isActive', true])
    }
    return (
      <Container>
        <Row>
          <Col xs={6}>
            <h3>Document Distribution Weight</h3>
            <Row className="mb-3">
              <Col xs={4}>
                <ButtonGroup>
                  <Button
                    id={'t-select-all-agents-btn'}
                    onClick={() => this.selectAllAgents(false)}
                    variant="outline-secondary"
                    size="sm"
                  >
                    Select All
                  </Button>
                  <Button
                    id={'t-deselect-all-agents-btn'}
                    disabled={selectedAgents.length < 1}
                    onClick={() => this.selectAllAgents(true)}
                    variant="outline-secondary"
                    size="sm"
                  >
                    Deselect All
                  </Button>
                </ButtonGroup>
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="t-list-users-filter-active"
                  title="Only Active users"
                  label="Only Active users"
                  defaultChecked={isActiveFilter}
                  onChange={() => this.toggleIsActive()}
                />
              </Col>
              <Col xs={5}>
                <Row>
                  <Col xs={4}>
                    <Form.Control
                      id={'t-distribution-weight-input'}
                      type="text"
                      max={100} min={0}
                      disabled={selectedAgents && selectedAgents.length < 1}
                      value={weight}
                      placeholder="Weight"
                      onChange={(e) => this.handleWeightChanged(e.target.value)}
                    />
                  </Col>
                  <Col xs={8}>
                    <Button
                      id={'t-set-distribution-weight-btn'}
                      disabled={selectedAgents && selectedAgents.length < 1}
                      onClick={() => this.setDocumentDistributionWeight()}
                      variant="success"
                      size="sm"
                    >
                      Set Distribution Weight
                    </Button>
                  </Col>
                </Row>
                <Row >
                  <Col xs={12} className={`${selectedAgents.length < 1 ? '' : 'd-none'}`}>
                    <small> Select at least one Agent to update distribution weight </small>
                  </Col>
                </Row>
                <Row className={`${hasError ? '' : 'd-none'}`}>
                  <Col xs={{offset: 2}}>
                    <div className="text-danger"> Invalid input should be a numeric value between 0 and 100 </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs>
                <Card>
                  <Card.Body>
                    {map(visibleBackOfficeUsers, (agent, i) => {
                      const {selectedAgents} = this.state
                      const selectedClass = selectedAgents && selectedAgents.includes(agent.id) ?
                        style.documentDistributionEditorListSelected : ''
                      return (
                        <Row
                          key={i}
                          id={`t-dw-agent-${agent.id}`}
                          className={classNames([style.documentDistributionEditorListItem, selectedClass])}
                          onClick={() => this.toggleAgentSelection(agent.id)}
                        >
                          <Col xs={12}>
                            <Row>
                              <Col xs={1} className={style.inputCheck}>
                                <input
                                  id={`t-dw-agent-${agent.id}-checkinput`}
                                  type="checkbox"
                                  checked={selectedAgents.includes(agent.id)}
                                  onChange={(e) => this.toggleAgentSelection(agent.id)}
                                />
                              </Col>
                              <Col xs={3}>
                                <p className={style.documentDistributionEditorListTitle}> {agent.firstName} {agent.lastName} </p>
                              </Col>
                              <Col xs={8}>
                                <p className={style.documentDistributionEditorListSubtitle}> {agent.documentlastAssignedTimestamp ?
                                  `Last document assigned ${readableDate(moment(agent.documentlastAssignedTimestamp))}` :
                                  'Last document assignment time unknown'} </p>
                                <p className={style.documentDistributionEditorListSubtitle}>
                                  Document distribution weight: {agent.documentDistributionWeight}</p>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      )
                    })}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={6}>
            <h3>Back office agents shift allocation</h3>
            <Row>
              <Col xs={4}>
                <div>
                  <span className={style.label}> From Date </span>
                  <DateTime
                    timeFormat={false}
                    onChange={(e) => this.handleDateFilterChanged('createdFrom', e)}
                    value={get(this.props, 'dateFilters.createdFrom')}
                    closeOnSelect
                    id="t-documents-filter-created-from"
                  />
                </div>
              </Col>
              <Col xs={4}>
                <div>
                  <span className={style.label}> To Date </span>
                  <DateTime
                    timeFormat={false}
                    onChange={(e) => this.handleDateFilterChanged('createdTo', e)}
                    value={get(this.props, 'dateFilters.createdTo')}
                    closeOnSelect
                    id="t-documents-filter-created-to"
                  />
                </div>
              </Col>
              <Col xs={4}>
                <Button
                  id="t-shifts-new-shift-button"
                  className="float-right"
                  variant="success"
                  onClick={() => this.toggleShiftEditor(true)}
                >
                  New shift
                </Button>
              </Col>
            </Row>
            <Row >
              <Col xs={12}>
                <small className="float-right"> Select existing user to change shift</small>
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={12}>
                <Card>
                  <Card.Body>
                    <Table bordered hover className={style.table}>
                      <thead>
                        <tr>
                          <th className={style.largeColumn}>Assignee</th>
                          <th>From</th>
                          <th>To</th>
                          <th>Weight</th>
                        </tr>
                      </thead>
                      <tbody id="t-list-shifts">
                        {shifts && shifts.map((shift) => this.renderShift(shift))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
                <Row className="mt-3">
                  <Col xs={12}>
                    <Pagination
                      size="sm"
                      className="justify-content-center"
                      onSelect={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, page: selectedEvent.eventKey})
                      )}
                    >
                      {getPageRange(page, getPageCount(shiftsCount)).map((p) => {
                        if (p === 'LEFT_PAGE') {
                          return <Pagination.Prev
                            key={p}
                            onClick={(e, selectedEvent) => this.props.uiDispatch(
                              'Show page',
                              (state) => ({...state, page: page - 1})
                            )}
                          />
                        }

                        if (p === 'RIGHT_PAGE') {
                          return <Pagination.Next
                            key={p}
                            onClick={(e, selectedEvent) => this.props.uiDispatch(
                              'Show page',
                              (state) => ({...state, page: page + 1})
                            )}
                          />
                        }

                        return <Pagination.Item
                          active={p === page}
                          key={p}
                          onClick={(e, selectedEvent) => this.props.uiDispatch(
                            'Show page',
                            (state) => ({...state, page: p})
                          )}
                        >
                          {p}
                        </Pagination.Item>
                      })}
                    </Pagination>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={createShiftClass}>
          <div className={style.addShift}>
            <div className={style.addShiftHeader}>
              <FontAwesomeIcon icon="clock-o" className={style.addShiftIcon} /> Add New Shift
            </div>
            <div className={addLeadErrClass} >
              <h4>
                <FontAwesomeIcon icon="info-circle" />
                  &nbsp;An error occured while adding the shift
              </h4>
              <p>{uiState.displayAddLeadErrMsg}</p>
            </div>
            <ShiftForm
              backOfficeUsers={map(visibleBackOfficeUsers, (u) => ({value: u.id, label: `${getFullName(u)}`}))}
              shift={selectedShift}
              onSave={()=>this.closeAddShift()}
              onCancel={()=>this.closeAddShift()}
            />
            <hr />
          </div>
        </div>
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'autoAssignDocumentEditorUi']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for datefilters on shifts',
      (state) => {
        const dateFilters = {
          createdFrom: moment().startOf('week').format('YYYY-MM-DD'),
          createdTo: moment().endOf('week').format('YYYY-MM-DD'),
        }
        return merge({
          dateFilters,
        }, state)
      }
    )
  }),

  provideProps((state, uiState) => {
    const {shifts, shiftsCount, agents, viewer} = state
    let {backOfficeUsers, dateFilters, page} = uiState
    if (!backOfficeUsers) {
      backOfficeUsers = agents && agents.filter((a) =>
        a.roles.map((a) => a.id).includes('back_office'))
    }

    return ({
      shifts,
      shiftsCount,
      agents,
      backOfficeUsers,
      viewer,
      dateFilters,
      page: page || 1,
    })
  }),
  mountDataProviders({
    documentDistributionAgentsProvider,
    shiftsProvider,
  }),
)(AutoAssignDocumentSettingsEditor)
