import React from 'react'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {Breadcrumb, BreadcrumbItem, Container} from 'react-bootstrap'
import {compose, provideProps, mountDataProviders} from '../decorators'
import CompetitionForm from './CompetitionForm'
import {competitionProvider} from './providers'
import PureComponent from '../PureComponent'

class CompetitionEdit extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  render() {
    const {competition} = this.props

    return (
      <Container>
        <Breadcrumb className="h4">
          <LinkContainer
            to="/competitions"
            active={false}
          >
            <BreadcrumbItem>
              Competition
            </BreadcrumbItem>
          </LinkContainer>
          <BreadcrumbItem active>
            {competition.name}
          </BreadcrumbItem>
        </Breadcrumb>
        <CompetitionForm data={competition} />
      </Container>
    )
  }
}

export default compose(
  provideProps((state) => ({
    competition: state.competition,
  })),
  mountDataProviders({competitionProvider}),
)(CompetitionEdit)
