import PureComponent from '../PureComponent'
import {Button, Card, Col, Container, Row, Table} from 'react-bootstrap'
import {checkRights, compose, mountDataProviders, predispatch, provideProps, uiMount} from '../decorators'
import {orderDirections} from '@bdswiss/common-enums'
import {merge} from 'lodash'
import {
  tradesReportProvider,
} from './providers'
import style from '../firsttimedeposits/reportFtd.module.scss'
import DateTime from '../components/DateTime'
import moment from 'moment'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {events} from '../enums'
import PropTypes from 'prop-types'
import {canQueryTradesReport} from '@bdswiss/common-permissions'
import StylishSelect from '../components/StylishSelect'
// import React from '@types/react'

const sources = [
  {
    label: 'WebTrader',
    value: 'WebTrader'
  },
  {
    label: 'Copied',
    value: 'Copied'
  }
]

const ALL_COMPANIES = 'All'

const generateDownloadLink = (backendUrl, source, company, reportDateStart, reportDateEnd) => {
  let link = `${backendUrl}/api/v1/reports/report?type=trades`

  if (source) {
    link += `&source=${source}`
  }
  if (company && company !== ALL_COMPANIES) {
    link += `&company=${company}`
  }
  if (reportDateStart) {
    link += `&reportDateStart=${reportDateStart}`
  }
  if (reportDateEnd) {
    link += `&reportDateEnd=${reportDateEnd}`
  }

  return link
}

class TradesReport extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)

    // const {backendUrl} = this.context.config

  }

  static contextTypes = {
    tradesReportProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  filterReportsBySourceType(source) {
    this.setState({source: source})
    this.props.uiDispatch('Filter report by source', (state) => ({...state, source}), [source])
  }

  filterReportsByCompany(company) {
    this.setState({company})
    this.props.uiDispatch('Filter report by company', (state) => ({...state, company}), [company])
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilter = {
      date: moment().format('YYYY-MM'),
    }

    const source = this.props.source ?? 'Copied'
    const company = this.props.company ?? ALL_COMPANIES

    const stateReportSort = this.props.reportSort || {
      orderBy: 'date', orderDirection: orderDirections.descending.key,
    }

    this.setState({
      sorting: {}, dateFilter: stateDateFilter, reportSort: stateReportSort, source, company
    })
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.tradesReportProvider.fetch()
  }

  handleDateFilterChanged(name, momentValue, value) {
    const dateFilter = {
      [name]: value
    }
    this.setState({dateFilter})

    if (!momentValue || moment.isMoment(momentValue)) {
      this.doDateFilterSearch(dateFilter)
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch({date}) {
    const dateFilter = {
      reportDateStart: moment(date, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
      reportDateEnd: moment(date, 'YYYY-MM').endOf('month').format('YYYY-MM-DD')
    }
    this.props.uiDispatch('Filter report by dates', (state) => ({...state, dateFilter}), [dateFilter])
  }

  doSortByOrderAndOrderDirection(field) {
    const {reportSort} = this.props
    const {orderBy, orderDirection} = reportSort

    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting report by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      reportSort: newSort
    }), [reportSort])
  }

  getSortArrow(field) {
    const {reportSort} = this.props
    const arrowDirection = reportSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (reportSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  render() {
    const companiesOptions = [ALL_COMPANIES, ...this.props.viewer.companies].map(company => ({
      label: company,
      value: company
    }))

    const {tradesReport, source} = this.props
    const {backendUrl} = this.context.config

    return (<Container>
      <h3>Trades Report</h3>
      <span className={style.label}>Date</span>
      <Row key="filter-row-1" className={style.filter}>
        <Col xs={6}>
          <Row>
            <Col xs={4}>
              <div>
                <DateTime
                  id="t-trades-date-filter"
                  timeFormat={false}
                  dateFormat="YYYY-MM"
                  onChange={(e) => this.handleDateFilterChanged('date', e, moment.isMoment(e) ? e.format('YYYY-MM') : '')}
                  value={this.state.dateFilter.date}
                  onFocus={() => this.doDateFilterSearch.cancel()}
                  closeOnSelect
                  className={style.datetime}
                />
              </div>
            </Col>
            <Col xs={4}>
              <StylishSelect.Input
                value={this.state.source}
                options={sources}
                onChange={(e) => this.filterReportsBySourceType(e.value)}
              />
            </Col>
            <Col xs={4}>
              <StylishSelect.Input
                value={this.state.company}
                options={companiesOptions}
                onChange={(e) => this.filterReportsByCompany(e.value)}
              />
            </Col>
          </Row>
        </Col>

        {source && <Col xs={1}>
          <div style={{textAlign: 'center'}}>
            <Button
              href={generateDownloadLink(backendUrl, source, this.state.company, this.props.dateFilter.reportDateStart, this.props.dateFilter.reportDateEnd)}
              id={'t-trades-export'}
              variant="success"
              style={{width: '100%'}}
              disabled={!tradesReport.length}
            >
              Download
            </Button>
          </div>
        </Col>}
      </Row>

      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body style={{overflowX: 'scroll', minHeight: 200}}>
              <Table bordered hover className={style.table}>
                <thead>
                  <tr style={{textAlign: 'center'}}>
                    <th style={{minWidth: 40}}>
                      <span>Trades</span>
                      <FontAwesomeIcon icon={this.getSortArrow('trades')}
                        onClick={() => this.doSortByOrderAndOrderDirection('trades')}/>
                    </th>

                    <th style={{minWidth: 100}}>
                      <span>Date</span>
                      <FontAwesomeIcon icon={this.getSortArrow('date')}
                        onClick={() => this.doSortByOrderAndOrderDirection('date')}/>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {tradesReport.map((report) => {
                    const {trades, date} = report
                    return (<tr
                      key={date}
                      id={date}
                      style={{minWidth: 100, textAlign: 'center'}}
                    >
                      <td>{trades}</td>
                      <td>{moment(date).format('YYYY-MM-DD')}</td>
                    </tr>)
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>)

  }
}

export default compose(checkRights(canQueryTradesReport),

  uiMount((state) => ['ui', 'registrationsReport']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/trades report',
    (state) => {
      const dateFilter = {
        reportDateStart: moment().startOf('month').format('YYYY-MM-DD'),
        reportDateEnd: moment().endOf('month').format('YYYY-MM-DD')
      }
      const source = 'Copied'
      const company = ALL_COMPANIES
      let reportSort = state && state.reportSort
      if (!reportSort) {
        reportSort = {orderBy: 'date', orderDirection: orderDirections.descending.key}
      }

      return merge({dateFilter, reportSort, source, company}, state)
    })),


  provideProps((state, uiState) => {
    const {tradesReport} = state
    const {dateFilter, reportSort, source, company} = uiState
    return {tradesReport, dateFilter, reportSort, source, company}
  }),

  mountDataProviders({tradesReportProvider}),)(TradesReport)
