import {getFetchInterval} from '../useful'

export const fxRatesProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const query =
      `{
        fxRates { data }
      }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'FX Rates loaded',
    (state, res) => ({
      ...state,
      fxRates: res.fxRates ? JSON.parse(res.fxRates.data) : {},
    }),
    [res]
  ),

}
