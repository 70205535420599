import ReactDOM from 'react-dom'
import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import 'react-loading-bar/dist/index.css'
import 'bootstrap/dist/css/bootstrap.css'
import './custom_bootstrap.global.scss'
import './custom_react-select.global.scss'
import './rich-editor.global.scss'
import MainWrapper from './MainWrapper'

const app = document.getElementById('app')

ReactDOM.render(
  <BrowserRouter>
    <MainWrapper />
  </BrowserRouter>, app)
