import {pixelTypes, eventNames, trackingParameterTypes, accountTypes,
  campaignTypes} from '@bdswiss/common-enums'
import moment from 'moment'
import {pickBy, map} from 'lodash'

function _validateNonempty(obj, key, errors) {
  if (!obj[key] && key in obj) {
    errors[key] = 'Cannot be empty!'
  }
}

function _validateMinMax(obj, key, errors, min, max) {
  _validateNonempty(obj, key, errors)
  if (Number(obj[key]) < min || Number(obj[key]) > max) {
    errors[key] = `Must be between ${min} and ${max}!`
  }
}

export function emptyTrackingPixelParameter() {
  return {
    key: null,
    type: trackingParameterTypes.static.key,
  }
}

export function validateTrackingPixelParameter(parameter) {
  const errors = {}
  _validateNonempty(parameter, 'key', errors)
  errors._count = Object.keys(errors).length
  return errors
}

export function emptyTrackingPixel() {
  return {
    name: null,
    type: pixelTypes.post.key,
    eventName: eventNames.register.key,
    productName: accountTypes.SpotOptionAccount.key,
    baseUrl: null,
  }
}

export function validateTrackingPixel(pixel) {
  if (pixel.deleted) {
    return {
      _direct_count: 0,
      _count: 0,
    }
  }

  const errors = {}
  _validateNonempty(pixel, 'name', errors)
  _validateNonempty(pixel, 'baseUrl', errors)
  _validateNonempty(pixel, 'productName', errors)

  errors._direct_count = Object.keys(errors).length
  errors.params = (pixel.params || []).map((param) => validateTrackingPixelParameter(param))
  errors._count = errors.params.reduce((prev, errs) => prev + errs._count, errors._direct_count)

  return errors
}

function validateMinimumDeposits(minimumDeposits) {
  const errors = {}
  minimumDeposits && minimumDeposits.forEach((deposit) => {
    if (deposit.amount < 0) {
      errors[deposit.currency] = 'Must be positive number!'
    }
  })
  return errors
}

export function emptyCampaign() {
  return {
    name: null,
    start: moment(new Date()).startOf('day'),
    end: moment(new Date()).add(5, 'years').endOf('day'),
    cookieTimeout: 3000000,
    type: campaignTypes.regular.value,
  }
}

export function validateCampaign(campaign) {
  const errors = {}
  _validateNonempty(campaign, 'name', errors)
  _validateNonempty(campaign, 'start', errors)
  _validateNonempty(campaign, 'end', errors)
  _validateNonempty(campaign, 'cookieTimeout', errors)
  _validateNonempty(campaign, 'type', errors)
  _validateMinMax(campaign, 'noCallRatio', errors, 0, 100)

  if (campaign.start && !moment.isMoment(campaign.start)) {
    errors.start = 'Invalid start date'
  }

  const affiliateCampaignTypes = pickBy(campaignTypes, {isAffiliate: true})
  if (!map(affiliateCampaignTypes, 'key').includes(campaign.type) && campaign.clientId) {
    errors.clientId = `campaign type ${map(affiliateCampaignTypes, 'label').join(' or ')} required`
  }

  if (campaign.end && !moment.isMoment(campaign.end)) {
    errors.end = 'Invalid end date'
  }
  errors.minimumDeposits = validateMinimumDeposits(campaign.minimumDeposits)
  errors.ftdBonusMinimumDeposits = validateMinimumDeposits(campaign.ftdBonusMinimumDeposits)
  errors.redepositBonusMinimumDeposits = validateMinimumDeposits(campaign.redepositBonusMinimumDeposits)

  // -3 is for the number of fields, so since we have 3 fields like minimumDeposits, ftdBonusMinimumDeposits... its 3
  errors._direct_count =
    Object.keys(errors).length - 3 +
    Object.keys(errors.minimumDeposits).length +
    Object.keys(errors.ftdBonusMinimumDeposits).length +
    Object.keys(errors.redepositBonusMinimumDeposits).length
  errors.pixels = (campaign.pixels || []).map((pixel) => validateTrackingPixel(pixel))
  errors._count = errors.pixels.reduce((prev, errs) => prev + errs._count, errors._direct_count)

  return errors
}

export function noCampaignValidationError() {
  return {
    _direct_count: 0,
    pixels: [],
    _count: 0,
  }
}

export function transformCampaign(data, campaign) {
  const nullableFields = ['ftdBonusPercentage', 'redepositBonusPercentage', 'vipDepositMinimum', 'ftdBonusPercentageVIP', 'redepositBonusPercentageVIP', 'rawDepositMinimum', 'ftdBonusPercentageRAW', 'redepositBonusPercentageRAW', 'globalBonusLimit']
  for (const key in data) {
    if (nullableFields.includes(key) && data[key].trim() === '') {
      data[key] = null
    }
  }

  for (const key in campaign) {
    if (data[key] === undefined) {
      data[key] = campaign[key]
    }
  }

  return data
}
