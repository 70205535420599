import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'

export default class GeneralModal extends PureComponent {

  componentWillMount() {
    this.setState({
      amount: this.props.amount
    })
  }

  static propTypes = {
    show: PropTypes.bool,
    header: PropTypes.object,
    body: PropTypes.any,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
  };

  changeAmount(e) {
    this.setState({amount: Number(e.target.value)})
  }

  render() {
    const {show, header, body, onConfirm, onCancel} = this.props

    return (
      <Modal
        id="t-general-modal"
        show={show}
        onHide={onCancel}
      >
        {header && <Modal.Header>
          <strong>{header}</strong>
        </Modal.Header>
        }
        <Modal.Body>
          {body}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              id="t-general-modal-cancel-btn"
              onClick={onCancel}
              variant="outline-secondary"
              size="sm"
              className="mr-3"
            >
              {onConfirm ? 'Cancel' : 'Close'}
            </Button>
            {onConfirm && <Button
              id="t-general-modal-confirm-btn"
              variant="success"
              size="sm"
              onClick={() => onConfirm()}
            >
              Confirm
            </Button>}
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
