import React from 'react'
import {Container, Row, Col, Badge, Button, Card} from 'react-bootstrap'
import moment from 'moment'
import PropTypes from 'prop-types'
import {appointmentCategories, appointmentColors} from '@bdswiss/common-enums'
import {get} from 'lodash'
import PureComponent from '../PureComponent'
import style from './appointments.module.scss'
import {provideProps} from '../decorators'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

export default provideProps((state) => ({viewer: state.viewer}))(
  class Appointment extends PureComponent {
    static contextTypes = {
      router: PropTypes.object.isRequired,
    }

    getCategory = (categoryKey) => {
      const category = get(appointmentCategories, categoryKey)
      return category.parentCategory
        ? appointmentCategories[category.parentCategory].label + ' - ' + category.label
        : category.label
    }

    render() {
      const {appointment: {id, start, category, client, user, description, color, isNew}, viewer, onEditClick, onCompleteClick, onViewClick} = this.props
      return (
        <Card onClick={() => onViewClick(id)} className={style.appointment}>
          <Card.Body>
            <Container fluid>
              <Row>
                <Col md={6}>
                  <h4>
                    {moment(start).format('HH:mm a DD/MM/YYYY')}{' '}
                    <Badge
                      className={style.badge}
                      style={{backgroundColor: appointmentColors[color].hex}}
                    >&nbsp;</Badge>
                  </h4>
                </Col>
                {category && <Col md={6}>
                  <h4>
                    {this.getCategory(category)}
                  </h4>
                </Col>}
              </Row>
              <Row>
                {client && <Col md={6}>
                  <p>
                    Client: {client.firstName} {client.lastName} <span>({client.id})</span>
                  </p>
                </Col>}
                <Col md={6}>
                  <p>
                    Assignee: {user.firstName} {user.lastName} {viewer.id === user.id && '(You)'}
                  </p>
                </Col>
              </Row>
              {description && <Row>
                <Col md={12}>
                  <strong className={style.descriptionLabel}>Description:</strong><br/>
                  {description}
                </Col>
              </Row>}
              {isNew && <Row className={style.mt10}>
                <Col md={12} className="text-right">
                  <Button variant="outline-secondary" size="sm" className={`${style.editButton} mr-1`}
                    onClick={onEditClick} value={id}
                  >
                    <FontAwesomeIcon icon="pencil" /> Edit
                  </Button>
                  <Button variant="success" size="sm" onClick={onCompleteClick} value={id}>
                    <FontAwesomeIcon icon="check" /> Complete
                  </Button>
                </Col>
              </Row>}
            </Container>
          </Card.Body>
        </Card>
      )
    }
  })
