import React from 'react'
import {Card} from 'react-bootstrap'
import {get, startCase} from 'lodash'
import {activityLogGraphqlTypes, documentTranslationTypes} from '@bdswiss/common-enums'
import {parseJSON} from '../common/utils'
import PureComponent from '../PureComponent'
import style from './activityLogs.module.scss'

export default class ActivityLogDetail extends PureComponent {
  getLog() {
    const {selectedActivityLog} = this.props

    if (!selectedActivityLog) return null

    const base = {
      'Origin': selectedActivityLog.origin,
      'IP address': selectedActivityLog.ip,
      'Country': selectedActivityLog.ipCountry,
    }

    switch (selectedActivityLog.__typename) {
      case activityLogGraphqlTypes.note.name: {
        return {
          ...base,
          'Content': selectedActivityLog.content,
          'Filename': selectedActivityLog.fileName,
          'File url': selectedActivityLog.fileUrl,
        }
      }
      case activityLogGraphqlTypes.genericLog.name: {
        return {
          ...base,
          'Content': selectedActivityLog.content,
          'VIP': selectedActivityLog.topVip,
          'Meta': selectedActivityLog.meta,
          'Autochartist': selectedActivityLog.toggleAutochartist,
          'AutochartistEmails': selectedActivityLog.toggleAutochartistEmails,
          'AutochartistPlugin': selectedActivityLog.toggleAutochartistPlugin,
          'AutochartistCoelationEmail': selectedActivityLog.toggleAutochartistCoelationEmail,
          'BDXCallCompleted': selectedActivityLog.toggleBDXCallCompleted,
          'BDXWaiverAccepted': selectedActivityLog.bdxWaiverAccepted,
          'TradeCompanion': selectedActivityLog.toggleTradeCompanion,
        }
      }
      case activityLogGraphqlTypes.withReasonLog.name: {
        return {
          ...base,
          'Reason': selectedActivityLog.reason,
          'Rejection code': selectedActivityLog.rejectionCode,
        }
      }
      case activityLogGraphqlTypes.tradingStatusLog.name: {
        return {
          ...base,
          'Status': selectedActivityLog.status,
          'Status reason': selectedActivityLog.statusReason,
          'Status reason code': selectedActivityLog.statusReasonCode,
        }
      }
      case activityLogGraphqlTypes.kycStatusLog.name: {
        return {
          ...base,
          'Status': selectedActivityLog.status,
          'Status reason': selectedActivityLog.statusReason,
          'Status reason code': selectedActivityLog.statusReasonCode,
        }
      }
      case activityLogGraphqlTypes.conversionStatusLog.name: {
        return {
          ...base,
          Status: selectedActivityLog.status,
        }
      }
      case activityLogGraphqlTypes.agentLog.name: {
        return {
          ...base,
          'Agent type': selectedActivityLog.agentType,
        }
      }
      case activityLogGraphqlTypes.complianceLog.name: {
        return {
          ...base,
          'Account name': selectedActivityLog.accountName,
          'Remote id': selectedActivityLog.remoteId,
          'Reason': selectedActivityLog.reason,
          'Rejection reason code': selectedActivityLog.rejectionReasonCode,
          'Alert type': selectedActivityLog.alertType,
          'Marketing in/out': selectedActivityLog.marketingInOut,
          'SMS in/out': selectedActivityLog.smsInOut,
          'Other users': selectedActivityLog.otherUsers,
          'Other user count': selectedActivityLog.otherUsersCount,
          'Arguments': selectedActivityLog.args,
        }
      }
      case activityLogGraphqlTypes.clientTypeLog.name: {
        return {
          ...base,
          'Client type': selectedActivityLog.clientType,
        }
      }
      case activityLogGraphqlTypes.diffLog.name: {
        return {
          ...base,
          Meta: selectedActivityLog.meta,
        }
      }
      case activityLogGraphqlTypes.accountCheckLog.name: {
        return {
          ...base,
          'GBG check result': selectedActivityLog.gbgCheckResult,
          'GBG status': selectedActivityLog.gbgStatus,
          'Skrill verification result': selectedActivityLog.skrillVerificationResult,
        }
      }
      case activityLogGraphqlTypes.transferFundsLog.name: {
        return {
          ...base,
          'Allow transfer funds': selectedActivityLog.allowTransferFunds,
        }
      }
      case activityLogGraphqlTypes.underMonitoringLog.name: {
        return {
          ...base,
          'Under monitoring': selectedActivityLog.underMonitoring,
        }
      }
      case activityLogGraphqlTypes.spoaLog.name: {
        return {
          ...base,
          'Can grant SPOA': selectedActivityLog.canGrantSpoa,
          'SPOA authorisation': selectedActivityLog.spoaAuthorization,
          'SPOA client id': selectedActivityLog.spoaClientID,
          'SPOA client firstname': selectedActivityLog.spoaClientFName,
          'SPOA client lastname': selectedActivityLog.spoaClientLName,
          'SPOA IB id': selectedActivityLog.spoaIbID,
          'SPOA IB firstname': selectedActivityLog.spoaIbFName,
          'SPOA IB lastname': selectedActivityLog.spoaIbLName,
        }
      }
      case activityLogGraphqlTypes.docTranslationsLog.name: {
        const details = {}
        if (selectedActivityLog.translationDetails) {
          const parsedDetails = parseJSON(selectedActivityLog.translationDetails)
          if (!(parsedDetails instanceof Error)) {
            Object.keys(parsedDetails).sort().forEach((k) => {
              const casedKey = startCase(k)
              details[casedKey] = parsedDetails[k]
            })
          }
        }
        return {
          ...base,
          'Document Id': selectedActivityLog.documentId,
          'Type': get(documentTranslationTypes, `${selectedActivityLog.translationType}.label`),
          ...details,
        }
      }
      default : return base
    }
  }

  render() {
    const {selectedActivityLog} = this.props
    const log = this.getLog()

    return log ? (
      <Card>
        <Card.Header>
          <h3>#{selectedActivityLog.id} {selectedActivityLog.__typename}</h3>
        </Card.Header>
        <Card.Body>
          <table className={style.table}>
            <tbody>
              {Object.keys(log).map((k) =>
                <tr key={k}>
                  <th>
                    {k}
                  </th>
                  <td>
                    {log[k]}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Card.Body>
      </Card>
    ) : (
      <Card><Card.Body>Select an activity log.</Card.Body></Card>
    )
  }
}
