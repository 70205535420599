import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {Col, Form, Row, Button, ButtonToolbar} from 'react-bootstrap'
import {isNil, isEmpty, get} from 'lodash'
import style from './clients.module.scss'
import {putFile} from '../utils/net'

export default class UploadStrategicPartnersForm extends PureComponent {
  state = {
    file: undefined,
    buttonText: 'Save',
    disableSave: false,
    rowsInserted: null,
    rowsFailed: null,
    errors: [],
  }
  initialState = {...this.state}

  static propTypes = {
    uploadStrategicPartners: PropTypes.func.isRequired,
    getSignedUrl: PropTypes.func.isRequired,
    closeAddLead: PropTypes.func.isRequired,
  }

  static contextTypes = {
    logError: PropTypes.func.isRequired,
    clientsProvider: PropTypes.object.isRequired,
  }

  handleFileChange = (e) => {
    this.setState({
      ...this.initialState,
      file: get(e, 'target.files.0'),
    })
  }

  handleSubmit = async (e) => {
    e.preventDefault()
    if (!this.state.file) {
      return
    } else {
      try {
        this.setState({
          ...this.initialState,
          disableSave: true,
          buttonText: 'Uploading...',
          file: this.state.file,
        })
        const {getSignedUrl: {signedUrl, plainUrl, key}} = await this.props.getSignedUrl()
        await putFile(this.state.file, signedUrl)
        this.setState({buttonText: 'Inserting...'})
        const response = await this.props.uploadStrategicPartners({url: plainUrl, key})
        this.setState({
          ...this.initialState,
          ...response.uploadStrategicPartners,
        })
        this.context.clientsProvider.fetch()
      } catch (e) {
        this.setState(this.initialState)
        this.context.logError(e)
      }
    }
  }

  handleClose = () => {
    this.setState(this.initialState)
    this.props.closeAddLead()
  }

  render() {
    const {buttonText, disableSave, rowsInserted, rowsFailed, errors} = this.state
    return (
      <Row>
        <Col md={12}>
          <form onSubmit={this.handleSubmit}>
            <Form.Control
              type="file"
              id="strategic-partners-file"
              onChange={this.handleFileChange}
            />
            <ButtonToolbar className={`${style.strategicPartnersUpload} float-right`}>
              <Button id="t-add-strategic-form-cancel-btn" variant="danger"
                onClick={this.handleClose}
                size="sm"
                className="mr-3"
              >
                Close
              </Button>
              <Button id="t-add-strategic-form-save-btn" variant="success"
                type="submit" disabled={disableSave}
                size="sm"
              >
                {buttonText}
              </Button>
            </ButtonToolbar>
          </form>
        </Col>
        <Col md={12}>
          {!isNil(rowsInserted) && <p>Rows Inserted: {rowsInserted}</p>}
          {!isNil(rowsFailed) && (<p>Rows Failed: {rowsFailed}</p>)}
          {!isEmpty(errors) && (<pre>{errors.join('\n')}</pre>)}
        </Col>
      </Row>
    )
  }
}
