/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {orderDirections, countries, companies, yesNo} from '@bdswiss/common-enums'
import {Row, Col, Table, Button, Card, Pagination, Form, ButtonToolbar, InputGroup, Container} from 'react-bootstrap'
import {events} from '../enums'
import style from './seminars.module.scss'
import {seminarsProvider} from './providers'
import PureComponent from '../PureComponent'
import AttendanceModal from './AttendanceModal'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {readableDate, getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class Seminars extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      ...this.state,
      showAttendanceModal: false,
      selectedSeminar: {},
    }
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.seminarsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    seminarsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  linkToSeminar(seminar, content) {
    return (
      <LinkContainer to={`/seminars/${seminar.id}`}>
        <a>{content}</a>
      </LinkContainer>
    )
  }

  countryLabel(seminar, content) {
    return seminar.country === content
      ? this.linkToSeminar(seminar, countries[content].label)
      : this.linkToSeminar(seminar, content)
  }

  activateTypesFilter = (filter) => {
    this.props.uiDispatch(
      'Filter seminar types',
      (state) => ({...state, seminarTypesFilter: filter, seminarsPage: 1})
    )
  }

  showAttendanceModal(seminar) {
    this.setState({showAttendanceModal: true, selectedSeminar: seminar})
  }

  hideAttendanceModal() {
    this.setState({showAttendanceModal: false})
  }

  renderSeminar(seminar) {
    const {id, seminarCode, seminarName, seminarDate, location, city, country, company, createdAt,
      ibSeminar} = seminar
    const created = moment(createdAt)
    const date = moment(seminarDate)
    return (
      <tr className="t-seminar" key={id}>
        <td>
          {this.linkToSeminar(
            seminar,
            id)}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            seminarCode)}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            seminarName)}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            readableDate(date))}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            location)}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            city)}
        </td>
        <td>
          {this.countryLabel(
            seminar,
            country)}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            companies[company].label)}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            readableDate(created))}
        </td>
        <td>
          {this.linkToSeminar(
            seminar,
            ibSeminar ? yesNo.yes.label : yesNo.no.label,)}
        </td>
        <td style={{padding: '8px'}}>
          <ButtonToolbar>
            <Button className="btn-xs" variant="info" onClick={() => this.context.router.push(`/seminars/${id}`)}>
              <FontAwesomeIcon icon="edit" /> Edit
            </Button>
            <Button className="btn-xs" variant="danger" onClick={() => this.showAttendanceModal(seminar)}>
              <FontAwesomeIcon icon="edit" /> Attendance
            </Button>
          </ButtonToolbar>
        </td>
      </tr>
    )
  }

  doSeminarsSearch(searchText) {
    this.props.dispatch(
      'Search seminar',
      (state) => ({...state, seminarsSearch: searchText, seminarsPage: 1})
    )
    this.setState({searchText: searchText})
  }

  renderFilters() {
    const {seminarsSearch} = this.props
    const {searchText} = this.state || {}
    return (
      <Row>
        <Col xs={10}>
          <InputGroup>
            <Form.Control
              id="t-list-seminars-search"
              type="text"
              value={searchText !== undefined ? searchText : seminarsSearch || ''}
              placeholder="Search by ID, Name, Seminar Code"
              onChange={(e) => this.setState({searchText: e.target.value})}
              onKeyUp={(e) => (
                (e.key === 'Enter' && this.doSeminarsSearch(searchText)) ||
                    (e.key === 'Escape' && this.doSeminarsSearch(''))
              )}
            />
            <InputGroup.Append>
              {seminarsSearch && <Button
                key={1}
                title="Clear"
                variant={seminarsSearch ? 'success' : 'outline-dark'}
                onClick={() => this.doSeminarsSearch('')}
              >
                <FontAwesomeIcon icon="times" />
              </Button>}
              <Button
                key={2}
                title="Search"
                variant={seminarsSearch ? 'success' : 'outline-dark'}
                onClick={() => this.doSeminarsSearch(searchText)}
              >
                <FontAwesomeIcon icon="search" />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
        <Col xs={2}>
          <Button
            id="t-seminars-new-seminar-button"
            className="float-right"
            variant="success"
            size="sm"
            onClick={() => this.context.router.push('/seminars/create')}
          >
            New Seminar
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {seminars, seminarsCount, seminarsPage} = this.props
    return (
      <Container>
        <div className={style.toolbar}>
          {this.renderFilters()}
        </div>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Code</th>
                      <th>Name</th>
                      <th>Seminar Date</th>
                      <th>Location</th>
                      <th>City</th>
                      <th>Country</th>
                      <th>Company</th>
                      <th>Created</th>
                      <th>IB Seminar</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {seminars.map((seminar) => this.renderSeminar(seminar))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, seminarsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(seminarsPage, getPageCount(seminarsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, seminarsPage: seminarsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, seminarsPage: seminarsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === seminarsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, seminarsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
        <AttendanceModal
          show={this.state.showAttendanceModal}
          seminar={this.state.selectedSeminar}
          onCancel={() => this.hideAttendanceModal()}
          updateSeminarAttendance={this.props.actions.seminars.updateSeminarAttendance}
          signTrackEventFileUrl={this.props.actions.seminars.signTrackEventFileUrl}
        />
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'seminars']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/seminars',
      (state) => {
        let seminarsSort
        if (!seminarsSort || seminarsSort.orderBy == null
            || seminarsSort.orderDirection == null || !(seminarsSort.orderDirection in orderDirections)) {
          seminarsSort = defaultSort
        }

        return ({...state, seminarsSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {seminars, seminarsCount, seminarsSearch} = state
    const {seminarsPage, seminarsSort} = uiState

    return ({
      seminars,
      seminarsCount,
      seminarsPage: seminarsPage || 1,
      seminarsSort,
      seminarsSearch,
    })
  }),

  mountDataProviders({seminarsProvider}),
)(Seminars)
