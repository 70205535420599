/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import {pick, map} from 'lodash'
import {Row, Col, Form} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import moment from 'moment'

const FIELDS = {
  bankAccountHolderName: 'Account Holder Name',
  bankName: 'Bank Name',
  iban: 'IBAN / Account Number',
  swiftCode: 'SWIFT / BIC',
  dateReceived: 'Date Received'
}

export default class BankWireDetails extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    deposit: PropTypes.object.isRequired,
  };

  render() {
    const {deposit} = this.props

    if (!deposit) {
      return (
        <Row>
          Loading...
        </Row>
      )
    }

    const fields = pick(deposit.depositPaymentFields, ['bankAccountHolderName', 'bankName', 'iban', 'swiftCode', 'dateReceived'])
    const wireDetails = Object.keys(fields).map(key => ({key, value: fields[key]}))

    return (
      <div>
        <Row>
          {map(wireDetails, (d) => <Col xs={12} key={d.key}>
            <Form.Group>
              <Form.Label>{FIELDS[d.key]}</Form.Label>
              <Form.Control
                id={d.key}
                type="text"
                title={FIELDS[d.key]}
                value={d.key === 'dateReceived' && moment(d.value).isValid() ? moment(d.value).format('DD/MM/YYYY') : d.value}
                disabled
              />
            </Form.Group>
          </Col>)}
        </Row>
      </div>
    )
  }
}
