export default function create(dbClient) {
  return {
    createRole({id, name, description, permissions}) {
      const variables = {id, name, description, permissions}

      const query = `
        mutation (
          $id: String!,
          $name: String!,
          $description: String,
          $permissions: [String]
        ) {
          upsertRole(
            id: $id
            name: $name
            description: $description
            permissions: $permissions
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    deleteRole({id}) {
      const variables = {id}

      const query = `
        mutation (
          $id: String!,
        ) {
          deleteRole(
            id: $id
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    }
  }
}
