import React from 'react'
import {map} from 'lodash'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {Row, Col, Card, Table, Container} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import styles from './style.module.scss'
import {fxRatesProvider} from './providers'
import {compose, uiMount, mountDataProviders, provideProps} from '../decorators'

class FxRates extends PureComponent {

  componentWillMount() {
    this.setState({activeSection: ''})
  }

  static contextTypes = {
    fxRatesProvider: PropTypes.object.isRequired,
  }

  renderCurrencyPanel(baseCurrency) {
    const {fxRates} = this.props
    const active = baseCurrency === this.state.activeSection
    const height = Object.keys(fxRates[baseCurrency]).length * 40
    return (
      <Col xs={12} key={baseCurrency}>
        <Card style={{marginTop: 20}} key={baseCurrency}>
          <Card.Body style={{marginTop: 20}} key={baseCurrency}>
            <span className={classnames(['h4', styles.sectionHeader])}
              onClick={(e) => this.setState({activeSection: baseCurrency})}
            >
              {baseCurrency}
            </span>
            {
              <div className={styles.currencyTable} style={{maxHeight: active ? height : 0}}>
                <Table bordered hover>
                  <tbody>
                    {map(fxRates[baseCurrency], (rate, currency) => baseCurrency !== currency &&
                      (<tr key={`${baseCurrency}${currency}`}>
                        <td>{baseCurrency}:{currency}</td>
                        <td>{rate}</td>
                      </tr>))}
                  </tbody>
                </Table>
              </div>
            }
          </Card.Body>
        </Card>
      </Col>
    )
  }

  render() {
    const {fxRates} = this.props
    return (
      <Container>
        <Row>
          <Col xs>
            <h3> FX Rates </h3>
          </Col>
        </Row>
        <Row>
          {Object.keys(fxRates).map((baseCurrency) => this.renderCurrencyPanel(baseCurrency))}
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount(() => ['clientsUi']),
  provideProps((state, uiState) => {
    const {fxRates} = state
    return {
      fxRates,
    }
  }),
  mountDataProviders({fxRatesProvider}),
)(FxRates)
