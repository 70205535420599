import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {compose, provideProps} from '../decorators'
import {Row, Card, Col} from 'react-bootstrap'
import classnames from 'classnames'
import {isEmpty} from 'lodash'
import style from './client.module.scss'
import Event from './Event'

class Notifications extends PureComponent {
  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    notification: PropTypes.array,
    sideTab: PropTypes.string.isRequired,
  };

  render() {
    const {notifications} = this.props

    return (
      <div>
        <Row>
          <Col xs={12}>
            <Card className={classnames(['panel-events'])}>
              <Card.Body>
                {isEmpty(notifications)
                  ? <div className={style.noEvents}> No notifications. </div>
                  : notifications.map((notification) => (
                    <Event
                      clientId={this.props.clientId}
                      key={notification.id}
                      event={notification}
                      {...this.props}
                    />
                  ))
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default compose(
  provideProps(),
)(Notifications)
