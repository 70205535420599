import {getFetchInterval, getOffset, getPageSize} from '../useful'
import {orderDirections} from '@bdswiss/common-enums'

export const orphanedCopytradingFollowersProvider = {
  fetchInterval: getFetchInterval,
  getQuery: (props) => {
    const {accountId} = props
    const account = accountId ? `accountId: ${accountId}` : ''

    const query = `{
      getOrphanedCopytradingFollowers(
        limit: ${getPageSize()}
        offset: ${getOffset(props.orphanedCopytradingFollowersPage)}
        orphanedCopytradingFollowers: true
        orderDirection: ${orderDirections.descending.key}
        ${account}
      ) {
        client {
          id
        }
        accountCopying {
          accountId
          remoteId
        }
        account {
          ... on BaseAccount {
            id
            __typename
          }
        }
        startRequestedDate
        stopRequestedDate
        startCopyingDate
        stopCopyingDate
      }
      getOrphanedCopytradingFollowersCount(
        orphanedCopytradingFollowers: true
        ${account}
      )
    }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Orphaned copytrading followers loaded',
    (state, res) => ({
      ...state,
      orphanedCopytradingFollowers: res.getOrphanedCopytradingFollowers || [],
      orphanedCopytradingFollowersCount: res.getOrphanedCopytradingFollowersCount || 0,
    }),
    [res]
  ),
}
