import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Row, Col, Form, Popover, Badge, Container} from 'react-bootstrap'
import {isArray, pick, get, map, isEmpty, first, find, isUndefined} from 'lodash'
import {accountTypes, tradingStatuses, conversionStatuses, kycStatuses, orderDirections, clientTypes, companies,
  yesNo, generateKeys, balanceFilterCurrencies,
} from '@bdswiss/common-enums'
import {canWriteClientSalesAgent, canWriteClientKycStatus, canQueryClients, canOnlyViewSupportAssignments,
  canWriteStrategicPartners,
} from '@bdswiss/common-permissions'
import List from './List'
import LeadAdd from './LeadAdd'
import style from './clients.module.scss'
import {clientPageTypes} from '../enums'
import {clientsProvider} from './providers'
import PureComponent from '../PureComponent'
import {tagCategoriesProvider} from '../tags/providers'
import StylishSelect from '../components/StylishSelect'
import SearchIbsModal from '../components/SearchIbsModal'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {registerClientConnectionHandler} from '../utils/socket'
import {allCompetitionsProvider} from '../competitions/providers'
import UploadStrategicPartnersForm from './UploadStrategicPartnersForm'
import {joinRoom, leaveRoom, getFullName, getAccountLabel} from '../utils/general'
import {getQueryFields, getCountryName, readableDate, getFromStorage, saveToStorage, sanitizeId,
  getFormattedAmount, defaultErrorMessage, isConverted,
} from '../useful'
import {compose, provideProps, mountDataProviders, predispatch, uiMount, checkRights} from '../decorators'

function canDoBatchAssign(viewer) {
  return canWriteClientSalesAgent(viewer) || canWriteClientKycStatus(viewer)
}

function notCanOnlyViewSupportAssignments(viewer) {
  return !canOnlyViewSupportAssignments(viewer)
}

const getColumnDefinitions = (currency) => generateKeys({
  selection: {
    label: 'Selection',
    containsLink: true,
    minWidth: 20,
    condition: canDoBatchAssign,
  },
  id: {
    label: 'ID',
    orderBy: 'id',
    minWidth: 70,
  },
  name: {
    label: 'Name',
    fields: ['firstName', 'lastName'],
    resolve: (client) => getFullName(client),
  },
  skypeId: {
    label: 'SkypeId',
    fields: 'skypeId',
    resolve: (client) => client.skypeId,
  },
  website: {
    label: 'Website',
    fields: 'website',
    resolve: (client) => client.website,
  },
  email: {
    label: 'Email',
    fields: 'email',
    resolve: (client) => client.email ? <a href={'mailto:' + client.email}>{client.email}</a> : null,
    containsLink: true,
  },
  phone: {
    label: 'Phone',
    fields: ['phone', 'isPhoneVerified'],
    resolve: (client) =>
      client.phone &&
      <span><a href={'tel:' + client.phone}>{client.phone} </a>
        {client.isPhoneVerified && <FontAwesomeIcon icon="check-circle" className={style.blueIcon} />}
      </span>,
    containsLink: true,
    minWidth: 100,
  },
  country: {
    label: 'Country',
    fields: {address: 'country'},
    resolve: (client) => client.address ? getCountryName(client.address) : '',
    minWidth: 150,
  },
  tags: {
    label: 'Tag Category',
    fields: {tags: ['id', 'name']},
    resolve: (client) => !isEmpty(client.tags) ? first(client.tags).name : '',
  },
  clientType: {
    label: 'Client Type',
    fields: 'clientType',
    resolve: (client) => client.clientType && clientTypes[client.clientType].label,
    minWidth: 150,
  },
  registrationCampaign: {
    label: 'Campaign',
    fields: {registrationCampaigns: ['campaignId', 'campaign {noCall}']},
    resolve: (client) => map(client.registrationCampaigns, (regCampaign) => ({
      campaignId: regCampaign.campaignId,
      noCall: get(regCampaign, 'campaign.noCall', false),
    })),
  },
  registrationSubCampaign: {
    label: 'SubCampaign',
    fields: {registrationCampaigns: 'subCampaignId'},
    resolve: (client) => map(client.registrationCampaigns, 'subCampaignId').join(', '),
  },
  registrationCampaignAffiliate: {
    label: 'Affiliate',
    fields: {registrationCampaigns: 'affiliateUserName'},
    resolve: (client) => map(client.registrationCampaigns, 'affiliateUserName').join(', '),
  },
  accounts: {
    label: 'Trading Accounts',
    fields: {accounts: '__typename'},
    resolve: (client) => client.accounts ? client.accounts.map(
      (account) =>  getAccountLabel(accountTypes[account.__typename])
    ).sort().join(',\xa0') : '',
    minWidth: 150,
  },
  canTrade: {
    label: 'Can Trade',
    fields: 'canTrade',
    resolve: (client) => ([
      <Badge
        pill
        variant={client.canTrade ? 'success' : 'danger'}
        key="1"
        // className={style.label}
      >
        {client.canTrade ? 'Yes' : 'No'}
      </Badge>,
      '\u200c', // &zwnj; is rendered to stretch the content as Label uses font size of just 75%
    ]),
    minWidth: 50,
  },
  tradingStatus: {
    label: 'Trading Status',
    fields: 'tradingStatus',
    resolve: (client) => client.tradingStatus && tradingStatuses[client.tradingStatus].label,
    minWidth: 130,
  },
  conversionStep: {
    label: 'Conversion Status',
    fields: 'conversionStep',
    resolve: (client) => client.conversionStatus && conversionStatuses[client.conversionStatus].label,
    minWidth: 150,
  },
  conversionStatus: {
    label: 'Sales Status',
    fields: 'conversionStatus',
    resolve: (client) => client.conversionStatus && conversionStatuses[client.conversionStatus].label,
    minWidth: 150,
  },
  kycStatus: {
    label: 'KYC Status',
    fields: 'kycStatus',
    resolve: ({kycStatus}) => ([
      <Badge
        pill
        variant={kycStatus ? kycStatuses[kycStatus].bsStyle : 'default'}
        key="1"
        // className={style.label}
      >
        {kycStatus && kycStatuses[kycStatus].label}
      </Badge>,
      '\u200c', // &zwnj; is rendered to stretch the content as Label uses font size of just 75%
    ]),
    minWidth: 80,
  },
  registration: {
    label: 'Signed Up',
    fields: ['registration', 'convertedAt'],
    orderBy: 'registration',
    resolve: (client) => {
      const clientConverted = isConverted(client.convertedAt)
      const registrationReadable = clientConverted ?
        readableDate(moment(client.registration)) : 'Not Registered'
      const registrationFromNow = clientConverted ?
        moment(client.registration).fromNow() : 'Not Registered'
      return <span title={registrationReadable}>{registrationFromNow}</span>
    },
    minWidth: 110,
  },
  lastLogin: {
    label: 'Last Login',
    fields: ['lastLogin', 'convertedAt'],
    orderBy: 'lastLogin',
    resolve: (client) => {
      const clientConverted = isConverted(client.convertedAt)
      const lastLoginReadable = clientConverted ?
        readableDate(moment(client.lastLogin)) : 'Never'
      const lastLoginFromNow = clientConverted ?
        moment(client.lastLogin).fromNow() : 'Never'
      return <span title={lastLoginReadable}>{lastLoginFromNow}</span>
    },
    minWidth: 110,
  },
  convertedAt: {
    label: 'Converted',
    fields: 'convertedAt',
    resolve: (client) => {
      const clientConverted = isConverted(client.convertedAt)
      const convertedAtReadable = clientConverted ?
        readableDate(moment(client.convertedAt)) : 'Not Converted'
      const convertedAtFromNow = clientConverted ?
        moment(client.convertedAt).fromNow() : 'Not Converted'
      return <span title={convertedAtReadable}>{convertedAtFromNow}</span>
    },
    minWidth: 110,
  },
  lastNote: {
    label: 'Last Note',
    fields: 'lastNote',
    orderBy: 'lastNote',
    resolve: (client) => {
      const lastNote = client.lastNote && moment(client.lastNote)
      if (!lastNote) {
        return ''
      }
      return <span title={readableDate(lastNote)}>{lastNote.fromNow()}</span>
    },
    minWidth: 120,
  },
  nextAppointment: {
    label: 'Next Appointment',
    fields: 'nextAppointment',
    orderBy: 'nextAppointment',
    resolve: (client) => {
      const nextAppointment = client.nextAppointment && moment(client.nextAppointment)
      if (!nextAppointment) {
        return ''
      }
      return <span title={readableDate(nextAppointment)}>{nextAppointment.fromNow()}</span>
    },
  },
  depositedAmount: {
    label: 'Total Deposits',
    fields: ['depositedAmount', 'depositedAmountCurrency'],
    orderBy: 'depositedAmount',
    resolve: (client) =>
      client.depositedAmountCurrency &&
      getFormattedAmount({amount: client.depositedAmount, currency: client.depositedAmountCurrency}),
  },
  salesAgent: {
    label: 'Sales Agent',
    fields: {salesAgent: ['id', 'firstName', 'lastName']},
    resolve: (client) => getFullName(client.salesAgent),
    condition: notCanOnlyViewSupportAssignments,
    minWidth: 200,
  },
  supportAgent: {
    label: 'Partners Agent',
    fields: {supportAgent: ['id', 'firstName', 'lastName']},
    resolve: (client) => getFullName(client.supportAgent),
    minWidth: 200,
  },
  externalAgent: {
    label: 'External Agent',
    fields: {externalAgent: ['id', 'firstName', 'lastName']},
    resolve: (client) => getFullName(client.externalAgent),
    minWidth: 200,
  },
  company: {
    label: 'Company',
    fields: ['company'],
    resolve: (client) => get(companies[client.company], 'label'),
    minWidth: 200,
  },
  balance: {
    label: `Balance ${currency}`,
    fields: ['aggregatedBalanceEur'],
    orderBy: 'aggregatedBalanceEur',
    resolve: (client) => {
      const balance = get(client, 'aggregatedBalanceEur') || 0
      return getFormattedAmount({amount: balance, currency})
    },
    minWidth: 110,
  },
  ftdDate: {
    label: 'FTD Date',
    fields: ['ftdDate'],
    orderBy: 'ftdDate',
    resolve: (client) => {
      const ftdDateReadable = client.ftdDate ? readableDate(moment(client.ftdDate)) : 'No FTD'
      const ftdDateFromNow = client.ftdDate ? moment(client.ftdDate).fromNow() : 'No FTD'
      return <span title={ftdDateReadable}>{ftdDateFromNow}</span>
    },
    minWidth: 110,
  },
  kycDeadlineDate: {
    label: 'KYC Deadline',
    fields: ['ftdDate', 'company'],
    orderBy: 'ftdDate',
    resolve: (client) => {
      const companyObj = find(companies, {value: client.company})
      const kycDays = get(companyObj, 'kycDeadline') + get(companyObj, 'kycDeadlineExtension', 0)
      const ftdDateReadable = client.ftdDate ? readableDate(moment(client.ftdDate).add('days', kycDays)) : 'No FTD'
      const ftdDateFromNow = client.ftdDate ? moment(client.ftdDate).add('days', kycDays).fromNow() : 'No FTD'
      return <span title={ftdDateReadable}>{ftdDateFromNow}</span>
    },
    minWidth: 110,
  },
  policy15Days: {
    label: '15 Days Policy',
    fields: ['accountChecks {policy15Days}'],
    resolve: (client) => {
      const value = get(client, 'accountChecks.policy15Days') || 'no'
      return yesNo[value].label
    },
    minWidth: 70,
  },
  lastEffectiveCall: {
    label: 'Last Effective Call',
    orderBy: 'lastEffectiveCall',
    fields: ['lastEffectiveCall'],
    resolve: (client) => {
      const defaultDate = new Date('1970-01-01').toISOString()
      const lastEffectiveCall = new Date(client.lastEffectiveCall).toISOString()
      if (defaultDate === lastEffectiveCall) {
        return 'No Effective Call'
      } else {
        return <span title={readableDate(moment(client.lastEffectiveCall))}>{readableDate(moment(client.lastEffectiveCall))}</span>
      }
    },
    minWidth: 70,
  },
  assignedToAgent: {
    label: 'Assigned To Agent',
    fields: ['assignedToAgent'],
    resolve: (client) => {
      if (client.assignedToAgent) {
        return <span title={readableDate(moment(client.assignedToAgent))}>{readableDate(moment(client.assignedToAgent))}</span>
      } else {
        return <span>Not assigned</span>
      }
    },
    minWidth: 70,
  },
  kartwheelReferrerId: {
    label: 'IB ID',
    fields: ['kartwheelReferrerAccount { remoteId }'],
    minWidth: 70,
    resolve: (client) => client.kartwheelReferrerAccount?.remoteId || '-',
  },
  pammMMId: {
    label: 'PAMM MM ID',
    fields: ['pammMMAccount { remoteId }'],
    minWidth: 70,
    resolve: (client) => client.pammMMAccount?.remoteId || '-',
  }

  // FIXME add conversionStatus and use orderBy from backend
})

const defaultColumns = [
  'id', 'name', 'country', 'accounts', 'kartwheelReferrerId', 'pammMMId', 'registration', 'salesAgent', 'convertedAt'
]

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

function clientQueryFields(clientsColumns, columnDefinitions) {
  return clientsColumns ? getQueryFields(columnDefinitions, clientsColumns) : null
}

class Clients extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  componentDidMount() {
    registerClientConnectionHandler()
    joinRoom('clients-statuses')
  }

  handleUpdateCurrencyFilter(currency, resetAfterFilter) {
    this.props.uiDispatch('Changed balance currency',
      (state) => ({
        ...state,
        ...resetAfterFilter,
        balanceCurrencyFilter: currency.value,
        columnDefinitions: getColumnDefinitions(currency.value),
      })
    )
  }

  componentWillUnmount() {
    leaveRoom('clients-statuses')
  }

  renderSettingsPopover() {
    let {clientsColumns, clientsSort, balanceMinFilter, uiState: {columnDefinitions}} = this.props
    const allColumns = Object.keys(columnDefinitions)

    const directionsOptions = Object.values(columnDefinitions)
      .filter((column) => column.orderBy)
      .map((column) => ({
        label: column.label,
        value: column.orderBy,
      }))

    return (
      <Popover id="clients_settings" title={<strong>Table Settings</strong>}>
        {allColumns.map((key) => {
          const column = columnDefinitions[key]
          if (column.condition && !column.condition(this.props.viewer)) {
            return false
          }
          return (<Form.Check
            id={'t-list-clients-columns-settings-column-' + sanitizeId(column.label)}
            key={key}
            label={column.label}
            style={{margin: 10}}
            checked={clientsColumns.indexOf(key) >= 0}
            onChange={(e) => {
              clientsColumns = allColumns.filter(
                (key2) => key2 === key ? e.target.checked : clientsColumns.indexOf(key2) >= 0
              )
              saveToStorage('clientsColumns', clientsColumns)
              this.props.uiDispatch(
                'Setting clientsColumns',
                (state) => ({...state, clientsColumns: clientsColumns})
              )
            }}
          />)
        })}
        <StylishSelect.Input
          id="t-list-clients-columns-settings-order-by"
          label="Order by"
          value={clientsSort.orderBy}
          onChange={(o) => {
            const orderBy = o.value
            saveToStorage('clientsSort', {...clientsSort, orderBy})
            let minFilter = balanceMinFilter
            if (orderBy === columnDefinitions.balance.orderBy && isEmpty(balanceMinFilter)) {
              minFilter = 0
            }
            this.props.uiDispatch(
              'Setting clientsSort.orderBy',
              (state) => ({...state, clientsSort: {...clientsSort, orderBy}, balanceMinFilter: minFilter})
            )
          }}
          placeholderText="Order by"
          options={directionsOptions}
        />
        <StylishSelect.Input
          id="t-list-clients-columns-settings-order-direction"
          label="Direction"
          value={clientsSort.orderDirection}
          onChange={(o) => {
            const orderDirection = o.value
            saveToStorage('clientsSort', {...clientsSort, orderDirection})
            this.props.uiDispatch(
              'Setting clientsSort.orderDirection',
              (state) => ({...state, clientsSort: {...clientsSort, orderDirection}})
            )
          }}
          options={StylishSelect.enumToStylishOptions(orderDirections)}
        />
      </Popover>
    )
  }

  toggleDisplayAddLeadForm(displayForm) {
    this.props.uiDispatch('Displaying Lead Add', (state) => ({...state, displayAddLead: displayForm, displayAddLeadErr: false}))
  }

  displayAddLeadAddErr(message) {
    const displayAddLeadErrMsg = message || defaultErrorMessage
    this.props.uiDispatch('Displaying Lead Add Error', (state) => ({...state, displayAddLeadErr: true, displayAddLeadErrMsg}))
  }

  displayAddLead() {
    this.toggleDisplayAddLeadForm(true)
  }

  closeAddLead = () => {
    this.toggleDisplayAddLeadForm(false)
  }

  leadSaved(result) {
    if (result.success) {
      this.props.history.push(`/clients/${result.newLeadId}`)
      this.closeAddLead()
    } else {
      this.displayAddLeadAddErr(result.message)
    }
  }

  toggleDisplaySearchIbsModal(show) {
    this.props.uiDispatch('Opening Search IBs modal', (state) => ({...state, showSearchIbsModal: show}))
  }

  toggleDisplayPushNotificationModal(showHide) {
    this.props.uiDispatch('Opening/Closing Push Notifications Modal', (state) => ({...state, showPushNotificationsModal: showHide}))
  }

  render() {
    const {clientsColumns, uiState} = this.props
    const {showSearchIbsModal, columnDefinitions} = uiState
    const addLeadClass = uiState.displayAddLead ?
      `${style.addClientBanner} ${style.addClientOpen}` : style.addClientBanner
    let addLeadErrClass = 'bs-callout bs-callout-danger '
    addLeadErrClass += uiState.displayAddLeadErr && uiState.displayAddLead ? '' : 'd-none'
    const {addLead, checkEmailInUse, searchIbs, uploadStrategicPartners, getSignedUrl} = this.props.actions.client
    const availableCompanies = pick(companies, this.props.viewer.companies)
    return (
      <Container>
        <Row>
          <Col md={12} xs={12}>
            <List
              columnDefinitions={columnDefinitions}
              columns={clientsColumns}
              popoverContent={this.renderSettingsPopover()}
              onAddLead={this.displayAddLead.bind(this)}
              onPushNotification={() => this.toggleDisplayPushNotificationModal(true)}
              onMarketingEmails={() => this.toggleDisplayMarketingEmailsModal(true)}
              onSearchIbs={() => this.toggleDisplaySearchIbsModal(true)}
              onCurrencySelect={this.handleUpdateCurrencyFilter.bind(this)}
              {...this.props}
            />
          </Col>
          <div className={addLeadClass}>
            <div className={style.addLead}>
              <div className={style.addLeadHeader}>
                <FontAwesomeIcon icon="user-circle-o" className={style.addLeadIcon} /> Add New Lead
              </div>
              <div className={addLeadErrClass} >
                <h4>
                  <FontAwesomeIcon icon="info-circle" />
                  &nbsp;An error occured while adding your lead
                </h4>
                <p>{uiState.displayAddLeadErrMsg}</p>
              </div>
              <LeadAdd
                onCancel={this.closeAddLead}
                onSave={this.leadSaved.bind(this)}
                defaultValues={{clientType: clientTypes.retail.key}}
                salesAgentId={this.props.viewer.id}
                actions={{checkEmailInUse, addLead}}
                companies={availableCompanies}
              />
              {canWriteStrategicPartners(this.props.viewer) && <div>
                <div className={style.addStrategicPartnersHeader}>
                  Add Strategic Partners
                </div>
                <UploadStrategicPartnersForm
                  uploadStrategicPartners={uploadStrategicPartners}
                  getSignedUrl={getSignedUrl}
                  closeAddLead={this.closeAddLead}
                />
              </div>}
              <hr />
              <SearchIbsModal
                show={showSearchIbsModal}
                onHide={() => this.toggleDisplaySearchIbsModal(false)}
                searchIbs={searchIbs}
              />
            </div>
          </div>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryClients),

  uiMount(() => ['clientsUi']),

  predispatch((props) => {
    const columnDefinitions = getColumnDefinitions(balanceFilterCurrencies.EUR.value)
    props.uiDispatch(
      'Setting default for clientsColumns and clientsSort',
      (state) => {
        const stateCopy = {...state, columnDefinitions}
        let {forexAPTestScoreFilter, binaryAPTestScoreFilter, onlineOnlyFilter, balanceMinFilter,
          underMonitoringFilter} = stateCopy

        if (!forexAPTestScoreFilter) {
          stateCopy.forexAPTestScoreFilter = 0
        }

        if (!binaryAPTestScoreFilter) {
          stateCopy.binaryAPTestScoreFilter = 0
        }

        stateCopy.onlineOnlyFilter = !!onlineOnlyFilter
        stateCopy.underMonitoringFilter = !!underMonitoringFilter

        if (stateCopy.clientColumns == null) {
          let clientsColumns = getFromStorage('clientsColumns')
          clientsColumns = isArray(clientsColumns)
            ? clientsColumns.filter((key) => key in columnDefinitions)
            : defaultColumns

          let clientsSort = getFromStorage('clientsSort')
          const allOrderBy = Object.values(columnDefinitions).map((column) => column.orderBy)
          if (!clientsSort || clientsSort.orderBy == null || allOrderBy.indexOf(clientsSort.orderBy) < 0
            || clientsSort.orderDirection == null || !(clientsSort.orderDirection in orderDirections)) {
            clientsSort = defaultSort
          }
          const selectedClients = {}
          if (clientsSort && clientsSort.orderBy === columnDefinitions.balance.orderBy &&
            isEmpty(balanceMinFilter)) {
            balanceMinFilter = 0
          }
          return ({...stateCopy, clientsColumns, clientsSort, selectedClients, balanceMinFilter})
        } else {
          return ({...stateCopy})
        }
      }
    )
  }),

  provideProps((state, uiState, _, props) => {
    const {clients, clientsCount, watchlistCheckedAt, agents, tagCategories,
      competitions,
    } = state
    const {clientsColumns, clientsSort, clientsSalesAgents, clientsCountriesFilter, clientsCountriesFilterValue,
      clientsSearch, clientsPage, clientsSalesAgentFilterValue, assignSalesModal, clientsAccountsFilter,
      selectedClients, selectedClientsHeader, conversionStatusFilter,conversionStepFilter, tradingStatusFilter, kycStatusFilter,
      depositorsFilter, onlineOnlyFilter, forexAPTestScoreFilter, binaryAPTestScoreFilter,
      balanceMaxFilter, balanceMinFilter, companyFilter, clientTypesFilter, showReassignClients,
      reassignClientsArgs, tagCategoriesFilter, tagFilter, agentPoolFilter, showSearchIbsModal,
      showPushNotificationsModal, underMonitoringFilter, showMarketingEmailsModal, showSearchHelpModal,
      riskCategoryFilter, potentialsFilter, ibApprovedFilter, searchIbId, searchPammMMID, masterIbOnlyFilter,
      trackEventFilter, attendedFilter, searchTrackEvent, searchCampaign, searchPparams, trackEventClientType,
      eventTypeFilter, partnersAgentFilter, competitionFilter, copyTradingFilter, missingPoiPorFilter,
      signedUpFromFilter, signedUpToFilter, convertedFromFilter, convertedToFilter, ftdFromFilter, ftdToFilter,
      lastEffectiveCallFromFilter, lastEffectiveCallToFilter, lastLoginFromFilter, lastLoginToFilter,noEffectiveCallFilter,
      languageFilter, birthdayFromFilter, birthdayToFilter, lastActivityFromFilter, lastActivityToFilter, jobTitleFilter, netWorthFilter,
      lastAttemptedCallFromFilter, lastAttemptedCallToFilter, balanceCurrencyFilter, columnDefinitions,
    } = uiState
    const type = get(props.match, 'params.type')

    let clientTypesFilterValue = []
    switch (type) {
      case clientPageTypes.retail.key:
        clientTypesFilterValue = [
          clientTypes.retail.key,
          clientTypes.corporate.key,
          clientTypes.jointAccount.key,
          clientTypes.testClient.key,
          clientTypes.professional.key,
          clientTypes.eligibleCounterParty.key,
        ]
        break
      case clientPageTypes.partners.key:
        clientTypesFilterValue = [
          clientTypes.ib.key,
          clientTypes.ibCorporate.key,
          clientTypes.affiliate.key,
          clientTypes.affiliateCorporate.key,
          clientTypes.merchant.key,
        ]
        break
      case clientPageTypes.strategic.key:
        clientTypesFilterValue = [
          clientTypes.strategicPartner.key,
        ]
        break
      default:
        break
    }
    return ({
      // global state
      clients,
      clientsCount,
      watchlistCheckedAt,
      agents,
      // ui state
      clientsColumns,
      clientsSort,
      tagCategories,
      queryFields: clientQueryFields(clientsColumns, columnDefinitions),
      clientsSalesAgents,
      clientsCountriesFilter,
      clientsCountriesFilterValue,
      clientsSearch,
      clientsPage: clientsPage || 1,
      clientsSalesAgentFilterValue,
      assignSalesModal,
      clientsAccountsFilter,
      selectedClients,
      selectedClientsHeader,
      conversionStatusFilter,
      conversionStepFilter,
      tradingStatusFilter,
      kycStatusFilter,
      riskCategoryFilter,
      depositorsFilter,
      onlineOnlyFilter,
      underMonitoringFilter,
      forexAPTestScoreFilter,
      binaryAPTestScoreFilter,
      companyFilter,
      clientTypesFilter: !isUndefined(clientTypesFilter) ? clientTypesFilter : clientTypesFilterValue,
      showReassignClients,
      reassignClientsArgs,
      tagCategoriesFilter,
      tagFilter,
      balanceMinFilter,
      balanceMaxFilter,
      agentPoolFilter,
      showSearchIbsModal,
      showPushNotificationsModal,
      showMarketingEmailsModal,
      showSearchHelpModal,
      potentialsFilter,
      ibApprovedFilter,
      searchIbId,
      searchPammMMID,
      masterIbOnlyFilter,
      trackEventFilter,
      attendedFilter,
      searchTrackEvent,
      searchCampaign,
      searchPparams,
      trackEventClientType,
      eventTypeFilter,
      partnersAgentFilter,
      competitions,
      competitionFilter,
      copyTradingFilter,
      missingPoiPorFilter,
      signedUpFromFilter,
      signedUpToFilter,
      convertedFromFilter,
      convertedToFilter,
      ftdFromFilter,
      ftdToFilter,
      lastEffectiveCallFromFilter,
      lastEffectiveCallToFilter,
      lastAttemptedCallFromFilter,
      lastAttemptedCallToFilter,
      lastLoginFromFilter,
      lastLoginToFilter,
      noEffectiveCallFilter,
      languageFilter,
      birthdayFromFilter,
      birthdayToFilter,
      lastActivityFromFilter,
      lastActivityToFilter,
      jobTitleFilter,
      netWorthFilter,
      balanceCurrencyFilter: balanceCurrencyFilter || balanceFilterCurrencies.EUR.value,
    })
  }),

  mountDataProviders({clientsProvider, tagCategoriesProvider, allCompetitionsProvider})
)(Clients)
