import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Card, OverlayTrigger, Tooltip} from 'react-bootstrap'
import classNames from 'classnames'
import {get} from 'lodash'
import {fieldCategories, personalDetailsFields} from './personalDetailsFields'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import {processProfileChangeResults} from '@bdswiss/common-enums'

export default class ProfileChangeDetails extends PureComponent {

  static propTypes = {
    client: PropTypes.object.isRequired,
    profileChange: PropTypes.object,
    header: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  };

  getQuestionAnswer(profile, {path, options, toForm, emailId}) {
    const value = get(profile, ['answers', ...path])
    return options && value in options ? options[value].label : value && toForm(value)
  }

  render() {
    const {profileChange = {}, client, header, onClose, revealPhone, phoneRevealed,
      revealedPhones,
    } = this.props
    const currentProfile = {answers: client}
    const fields = personalDetailsFields.filter(
      ({category, key}) => (category === fieldCategories.details && key !== 'email')
        || fieldCategories.economicProfile
    )
    const blockedPhoneBirthdayReveal = get(client, 'blockedPhoneBirthdayReveal')

    return (
      <div>
        <Row className={style.toolbar}>
          <Col xs={12}>
            <Button
              id="t-client-profile-change-details-close"
              key={1}
              variant="outline-secondary"
              size="sm"
              className="float-right"
              onClick={onClose}
            >
              Close
            </Button>
          </Col>
        </Row>
        {profileChange.answers && (
          <Card id="t-client-profile-change-details-sidebar">
            {header && <Card.Header><strong>{header}</strong></Card.Header>}
            <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
              {profileChange.status === processProfileChangeResults.underReview.key &&
                <Row className={style.toolbar}>
                  <Col xs={12}>
                    <div className="alert alert-warning" id="comment">
                      <strong>Placed under review: </strong> {profileChange.comments}
                    </div>
                  </Col>
                </Row>}
              {fields.map((field) => {
                let answer = this.getQuestionAnswer(profileChange, field, field.emailId)
                if (answer == null) {
                  // not answered
                  return null
                }
                if (['phone', 'secondaryPhone1'].includes(field.key) && phoneRevealed) {
                  answer = revealedPhones[field.key]
                }
                const currentAnswer = this.getQuestionAnswer(currentProfile, field)
                const changed = answer !== currentAnswer

                const formControl = (
                  <Form.Group>
                    <Form.Label>{field.label}</Form.Label>
                    <Form.Control
                      id={`profile-change-${field.key}`}
                      defaultValue={answer}
                      disabled
                      className={classNames([
                        'form-control',
                        changed ? style.aptAnswerChanged : style.aptAnswerUnchanged,
                      ])}
                    />
                  </Form.Group>
                )

                return (
                  <Row key={field.key}>
                    <Col xs={['phone', 'secondaryPhone1'].includes(field.key) && !phoneRevealed && !blockedPhoneBirthdayReveal ? 9 : 12}>
                      {changed ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            <Tooltip id="disabled">
                              Current Value:<br />
                              {currentAnswer || '[empty]'}
                            </Tooltip>
                          }
                        >
                          {formControl}
                        </OverlayTrigger>
                      ) : (
                        formControl
                      )}
                    </Col>
                    {['phone', 'secondaryPhone1'].includes(field.key) && !phoneRevealed && !blockedPhoneBirthdayReveal &&
                      <Col xs={3}>
                        <br />
                        <Button
                          id={`t-client-profile-change-${field.key}-reveal`}
                          variant="outline-secondary"
                          size="sm"
                          className="float-right"
                          onClick={() => revealPhone(client.id)}
                        >
                          Reveal
                        </Button>
                      </Col>
                    }
                  </Row>
                )
              })}
            </Card.Body>
          </Card>
        )}
      </div>
    )
  }
}
