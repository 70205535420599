import React from 'react'
import {isNumber} from 'lodash'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card, Form} from 'react-bootstrap'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class LinkClient extends PureComponent {

  componentWillMount() {
    this.setState({loading: false, formError: {}})
  }

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  linkClient = () => {
    this.setState({loading: true})

    const {linkedClientId} = this.state
    if (!isNumber(Number(linkedClientId)) || isNaN(Number(linkedClientId))) {
      this.setState({loading: false, formError: {linkedClientId: true}})
      return
    }

    return this.props.actions.client.linkPartnerWithRetailClient(this.props.clientId, linkedClientId)
      .then(() => {
        this.setState({loading: false})
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
        this.props.onClose()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.setState({loading: false})
        this.context.logError(e)
      })
  }

  render() {
    const {onClose} = this.props
    const {loading} = this.state

    return (
      <Card
        id="t-client-send-invitation-sidebar"
        key={1}
      >
        <Card.Header><strong>Link Partner with another Retail Profile</strong></Card.Header>
        <Card.Body>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Client ID</Form.Label>
                <Form.Control
                  id="t-link-client-retail-client-id"
                  type="text"
                  isInvalid={this.state.formError.linkedClientId}
                  onChange={(evt) =>
                    this.setState({linkedClientId: evt.target.value}, () => this.setState({formError: {linkedClientId: false}}))
                  }
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-link-client-close"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                  size="sm"
                  className="mr-2"
                >Close
                </Button>
                <Button
                  id="t-link-client-save"
                  variant="success"
                  size="sm"
                  onClick={this.linkClient}
                  disabled={loading}
                >
                  {loading && <FontAwesomeIcon icon={'spinner'} className="fa-spin" />}
                  {loading ? 'Linking clients...' : 'Link'}
                </Button>
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(LinkClient)
