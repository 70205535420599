import React from 'react'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {get, has, pickBy, map, filter} from 'lodash'
import {Modal, Col, Row, Button, Form, ButtonToolbar, ButtonGroup} from 'react-bootstrap'
import {depositVendors, companies, depositFlowTypes, paymentOptionStatuses, paymentOptionMethods,
  cardTypes,
  withKYCStatuses} from '@bdswiss/common-enums'
import {putFile} from '../utils/net'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import {bankDetailsFields} from '../common/utils'
import AddBankDetailsModal from './AddBankDetailsModal'
import style from '../components/components.module.scss'
import StylishSelect from '../components/StylishSelect'
import TextareaAutosizeInput from '../components/TextareaAutosizeInput'
import {noPaymentOptionValidationError, validatePaymentOption} from './validation'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class AddPaymentMethodModal extends PureComponent {

  static contextTypes = {
    paymentOptionsProvider: PropTypes.object,
    ccPaymentOptionsProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  componentWillReceiveProps(newProps) {
    if (!newProps.paymentOption) {
      this.setDefaultState()
    } else {
      this.setState({
        values: {
          id: newProps.paymentOption.id,
          status: newProps.paymentOption.status,
          name: newProps.paymentOption.name,
          provider: newProps.paymentOption.provider,
          paymentKey: newProps.paymentOption.paymentKey,
          localizationKey: newProps.paymentOption.localizationKey,
          logoUrl: newProps.paymentOption.logoUrl,
          companies: newProps.paymentOption.companies,
          iOSMinBuildVersion: newProps.paymentOption.iOSMinBuildVersion,
          androidMinBuildVersion: newProps.paymentOption.androidMinBuildVersion,
          flowType: newProps.paymentOption.flowType,
          confluenceLink: newProps.paymentOption.confluenceLink,
          notes: newProps.paymentOption.notes,
          method: newProps.paymentOption.method,
          ccType: newProps.paymentOption.ccType,
          ccPrimary: newProps.paymentOption.ccPrimary,
          ccVolumeLimit: newProps.paymentOption.ccVolumeLimit,
          bankDetails: newProps.paymentOption.bankDetails || [],
          withKYCStatus: newProps.paymentOption.withKYCStatus || withKYCStatuses.kyc_status_any.value,
        },
        isTouched: false,
        feedback: false,
        errors: {},
        addNewBankDetails: false,
        editingBankDetails: {},
      })
    }
  }

  setDefaultState() {
    this.setState({
      values: {
        name: '',
        status: paymentOptionStatuses.disabled.value,
        provider: '',
        paymentKey: '',
        localizationKey: '',
        logoUrl: '',
        iOSMinBuildVersion: '',
        androidMinBuildVersion: '',
        flowType: 'urlRedirect',
        error: false,
        confluenceLink: '',
        notes: '',
        ccType: '',
        ccPrimary: false,
        ccVolumeLimit: '',
        bankDetails: [],
        withKYCStatus: withKYCStatuses.kyc_status_any.value,
      },
      isTouched: false,
      errors: {},
      addNewBankDetails: false,
      editingBankDetails: {},
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }


  valueChanged(key, value, feedback = this.state && this.state.feedback) {
    const values = ({...this.state.values, [key]: value})
    this.setState({
      values: values,
      isTouched: true,
      feedback: feedback,
      errors: feedback ? validatePaymentOption(values) : noPaymentOptionValidationError(),
    })
  }

  generateFileInputProps(key) {
    const res = {
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.files)
        } else {
          this.valueChanged(key, '')
        }
      },
    }
    return res
  }

  renderErrorMessage(key) {
    const {errors} = this.state
    if (has(errors, key)) {
      return (
        <p className="help-block"> {get(errors, key)} </p>
      )
    }
  }

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    if (this.state.feedback) {
      this.setState({values, isTouched: true, errors: this.validate(values)})
    } else {
      this.setState({values, isTouched: true})
    }
  }

  async getPath(file) {
    const {values} = this.state
    if (typeof file === 'string') {
      return Promise.resolve(file)
    }
    const res = await this.props.signUploadUrl(values.provider)
    const {plainUrl, signedUrl} = res.signPaymentLogoFileUrl
    return putFile(file[0], signedUrl).then(() => plainUrl)
  }

  save() {
    let paramsId
    const {values} = this.state
    if (this.props.paymentOption && this.props.paymentOption.id) {
      paramsId = this.props.paymentOption.id
    }
    return this.getPath(values.logoUrl).then((pathRes) => this.props.onSave({
      id: paramsId,
      name: values.name,
      status: values.status,
      provider: values.provider,
      paymentKey: values.paymentKey,
      localizationKey: values.localizationKey,
      logoUrl: pathRes,
      companies: values.companies,
      iOSMinBuildVersion: values.iOSMinBuildVersion,
      androidMinBuildVersion: values.androidMinBuildVersion,
      flowType: values.flowType,
      confluenceLink: values.confluenceLink,
      notes: values.notes,
      method: values.method,
      ccType: values.ccType,
      ccPrimary: values.ccPrimary,
      ccVolumeLimit: values.ccVolumeLimit,
      bankDetails: values.bankDetails,
      withKYCStatus: values.withKYCStatus,
    }).then(() => {
      this.context.paymentOptionsProvider.fetch()
      this.context?.ccPaymentOptionsProvider?.fetch()
      this.props.onHide()
    }))
      .catch(this.context.logError)
  }

  render() {
    const {onHide, show, paymentOption} = this.props
    const availableProviders = {
      bankWire: {label: 'Bank Wire', key: 'bankwire'},
      ...pickBy(depositVendors, {clientPermitted: true}),
    }
    return (
      <Modal
        id="t-payment-option-modal"
        keyboard
        show={show}
        onHide={onHide}
        className={classnames([style.newPaymentMethodModal, this.state.addNewBankDetails ? style.hiddenModal : ''])}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Add New Payment Method
        </Modal.Header>
        <Modal.Body style={{maxHeight: 'calc(100% - 50px)', overflowY: 'scroll'}}>
          <Row>
            <Col xs={12}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  id="t-payment-option-name"
                  type="text"
                  tabIndex={1}
                  value={this.state.values.name}
                  isInvalid={this.state.values.error}
                  onChange={(e) => this.formValueChanged('name', e.target.value)}
                />
              </Form.Group>
              {this.renderErrorMessage('name')}
              <StylishSelect.Input
                id="t-payment-option-status"
                label="Status"
                placeholderText="Payment Option Status"
                value={this.state.values.status}
                options={StylishSelect.enumToStylishOptions(paymentOptionStatuses)}
                onChange={(e) => this.formValueChanged('status', e.value)}
              />
              <StylishSelect.Input
                id="t-payment-option-with-kyc-status"
                label="With KYC status"
                placeholderText="With KYC Status"
                value={this.state.values.withKYCStatus}
                options={StylishSelect.enumToStylishOptions(withKYCStatuses)}
                onChange={(e) => this.formValueChanged('withKYCStatus', e.value)}
              />
              <StylishSelect.Input
                id="t-payment-option-provider"
                placeholderText="Select Provider"
                label="Payment Provider"
                value={this.state.values.provider}
                options={StylishSelect.enumToStylishOptions(availableProviders)}
                onChange={(e) => this.formValueChanged('provider', e.value)}
              />
              {this.renderErrorMessage('provider')}
              <Form.Group>
                <Form.Label>Payment Key</Form.Label>
                <Form.Control
                  id="t-payment-option-payment-key"
                  type="text"
                  tabIndex={2}
                  value={this.state.values.paymentKey}
                  isInvalid={this.state.values.error}
                  onChange={(e) => this.formValueChanged('paymentKey', e.target.value)}
                />
              </Form.Group>
              {this.renderErrorMessage('paymentKey')}
              {/* NOT USED */}
              {/* <Form.Group>
                <Form.Label>Localization Key</Form.Label>
                <Form.Control
                  id="t-payment-option-localization-key"
                  type="text"
                  label="Localization Key"
                  tabIndex={3}
                  value={this.state.values.localizationKey}
                  isInvalid={this.state.values.error}
                  onChange={(e) => this.formValueChanged('localizationKey', e.target.value)}
                />
              </Form.Group>
              {this.renderErrorMessage('localizationKey')} */}
              <StylishSelect.Input
                id="t-country-payment-option-companies"
                label="Companies"
                multi
                options={StylishSelect.enumToStylishOptions(companies)}
                value={this.state.values.companies}
                onChange={(e) => {
                  const companies = e && map(e, 'value')
                  this.formValueChanged('companies', companies)
                }}
              />
              <Form.Group>
                <Form.Label>iOS Minimum Build Version</Form.Label>
                <Form.Control
                  id="t-payment-option-ios-min-build"
                  type="text"
                  tabIndex={3}
                  value={this.state.values.iOSMinBuildVersion}
                  isInvalid={this.state.values.error}
                  onChange={(e) => this.formValueChanged('iOSMinBuildVersion', e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Android Minimum Build Version</Form.Label>
                <Form.Control
                  id="t-payment-option-android-min-build"
                  type="text"
                  label="Android Minimum Biuld Version"
                  tabIndex={3}
                  value={this.state.values.androidMinBuildVersion}
                  isInvalid={this.state.values.error}
                  onChange={(e) => this.formValueChanged('androidMinBuildVersion', e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Credit card type</Form.Label>
                <StylishSelect
                  id="t-back-office-users"
                  placeholderText="Select..."
                  value={this.state.values.ccType}
                  options={StylishSelect.enumToStylishOptions(cardTypes)}
                  onChange={(e) => this.valueChanged('ccType', e?.value)}
                  clearable
                />
              </Form.Group>
              {this.state.values.ccType && <Row>
                <Col>
                  <Form.Group>
                    <Form.Label>Weekly CC Volume Limit</Form.Label>
                    <Form.Control
                      type="text"
                      label="Is Primary CC provider"
                      value={this.state.values.ccVolumeLimit ?? ''}
                      onChange={(e) => this.formValueChanged('ccVolumeLimit', e.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>&nbsp;</Form.Label>
                    <Form.Check
                      type="checkbox"
                      label="Is Primary CC provider"
                      checked={this.state.values.ccPrimary}
                      onChange={(e) => this.formValueChanged('ccPrimary', e.target.checked)}
                    />
                  </Form.Group>
                </Col>
              </Row>}
              <StylishSelect.Input
                id="t-payment-option-flow-type"
                label="Deposit flow type"
                placeholderText="Deposit flow type"
                value={this.state.values.flowType}
                options={StylishSelect.enumToStylishOptions(depositFlowTypes)}
                onChange={(e) => this.formValueChanged('flowType', e.value)}
              />
              {this.renderErrorMessage('flowType')}
              <Form.Group>
                <Form.Label>Confluence Link</Form.Label>
                <Form.Control
                  id="t-payment-option-confluence-link"
                  type="text"
                  tabIndex={2}
                  value={this.state.values.confluenceLink}
                  onChange={(e) => this.formValueChanged('confluenceLink', e.target.value)}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Choose Payment Logo</Form.Label>
                <Form.Control
                  id="t-payment-option-logo-url"
                  type="file"
                  {...this.generateFileInputProps('logoUrl')}
                />
              </Form.Group>
              {this.renderErrorMessage('logoUrl')}
              {paymentOption && paymentOption.logoUrl ? (
                <img alt={paymentOption.logoUrl} src={paymentOption.logoUrl} style={{maxWidth: '10%'}} />
              ) : ''}
              <Form.Group>
                <Form.Label>Notes</Form.Label>
                <TextareaAutosizeInput
                  label="Notes"
                  id="t-payment-option-notes"
                  placeholder="Add a comment..."
                  value={this.state.values.notes}
                  onChange={(e) => this.formValueChanged('notes', e.target.value)}
                />
              </Form.Group>
              <StylishSelect.Input
                id="t-payment-option-payment-method"
                label="Payment Method"
                placeholderText="Payment Method"
                value={this.state.values.method}
                options={StylishSelect.enumToStylishOptions(paymentOptionMethods)}
                onChange={(e) => this.formValueChanged('method', e.value)}
              />
              {this.state.values.provider === depositVendors.bankWire.value && [
                <h5 key="title">Bank Wire Details</h5>,
                <Row>
                  {map(this.state.values.bankDetails, (details) => (
                    <Col key={details.curerncy} xs={6} className="mb-4">
                      <Row>
                        <Col xs={4}>
                          <h6>{details.clientAccountCurrency}</h6>
                        </Col>
                        <Col xs={8}>
                          <ButtonToolbar className="mr-2">
                            <ButtonGroup aria-label="Actions">
                              <Button
                                variant="outline-secondary"
                                title="Edit"
                                size="sm"
                                onClick={() => {
                                  this.setState({
                                    addNewBankDetails: true,
                                    editingBankDetails: details,
                                  })
                                }}
                              >
                                <FontAwesomeIcon icon="pencil" />
                              </Button>
                              <Button
                                variant="outline-secondary"
                                title="Remove"
                                size="sm"
                                onClick={() => this.setState({
                                  isTouched: true,
                                  values: {
                                    ...this.state.values,
                                    bankDetails: filter(this.state.values.bankDetails, (d) => d.clientAccountCurrency !== details.clientAccountCurrency),
                                  },
                                  addNewBankDetails: false,
                                })}
                              >
                                <FontAwesomeIcon icon="times" />
                              </Button>
                            </ButtonGroup>
                          </ButtonToolbar>
                        </Col>
                      </Row>
                      {map(filter(Object.keys(details), (k) => k !== bankDetailsFields.clientAccountCurrency.key), (key) => (
                        <div key={`key-${key}`}>
                          <dt>{bankDetailsFields[key].label}</dt>
                          <dd>{details[key]}</dd>
                        </div>
                      ))}
                    </Col>)
                  )}
                </Row>,
                <Button
                  id="t-add-new-bank-details"
                  variant="info"
                  size="sm"
                  onClick={() => this.setState({addNewBankDetails: true})}
                >
                  Add New Currency
                </Button>,
              ]}
              <AddBankDetailsModal
                show={this.state.addNewBankDetails}
                bankDetails={this.state.editingBankDetails}
                paymentOption={this.state.values}
                onHide={() => this.setState({addNewBankDetails: false})}
                addBankDetails={(bankDetails) => this.setState({
                  isTouched: true,
                  values: {
                    ...this.state.values,
                    bankDetails: [
                      ...filter(this.state.values.bankDetails, (d) => d.clientAccountCurrency !== bankDetails.clientAccountCurrency),
                      bankDetails,
                    ],
                  },
                  addNewBankDetails: false,
                })}
              />
            </Col>
          </Row>

        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-payment-option-cancel"
            variant="outline-secondary"
            className="mr-3"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-payment-option-save"
            variant="success"
            disabled={!this.state.isTouched}
            onClick={() => this.save()}
          >
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
export default provideProps()(AddPaymentMethodModal)
