import {yesNo} from '@bdswiss/common-enums'
import {filter, keys, head, get, find, map, isNumber} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {formatAccountTypeFilterInput} from '../schemaUtils'

export const clientsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (!('queryFields' in props)) {
      throw new Error(`props do not contain queryFields ${JSON.stringify(props)}`)
    }
    const queryFields = props.queryFields
    const {clientsSalesAgents, clientsCountriesFilter, clientsSearch, clientsPage, clientsSort,
      clientsAccountsFilter, conversionStatusFilter,conversionStepFilter, tradingStatusFilter, kycStatusFilter,
      depositorsFilter, onlineOnlyFilter, forexAPTestScoreFilter, binaryAPTestScoreFilter,
      companyFilter, clientTypesFilter, balanceMaxFilter, balanceMinFilter,
      tagCategoriesFilter, tagFilter, agentPoolFilter, underMonitoringFilter, riskCategoryFilter, potentialsFilter,
      ibApprovedFilter, searchIbId, searchPammMMID, masterIbOnlyFilter, attendedFilter, searchTrackEvent, searchCampaign,
      searchPparams, trackEventClientType, eventTypeFilter, partnersAgentFilter, competitionFilter,
      copyTradingFilter, missingPoiPorFilter, signedUpFromFilter, signedUpToFilter, convertedFromFilter, convertedToFilter, ftdFromFilter, ftdToFilter,
      lastEffectiveCallFromFilter, lastEffectiveCallToFilter, lastLoginFromFilter, lastLoginToFilter, noEffectiveCallFilter, languageFilter, birthdayFromFilter,
      birthdayToFilter, lastActivityFromFilter, lastActivityToFilter, jobTitleFilter, netWorthFilter, lastAttemptedCallFromFilter, lastAttemptedCallToFilter, balanceCurrencyFilter,
    } = props

    const countriesAsList = clientsCountriesFilter ? clientsCountriesFilter.join(', ') : ''
    const providedPotentialsFilter = filter(keys(potentialsFilter), (k) => potentialsFilter[k] === true)
    let providedAgentPoolFilter = filter(keys(agentPoolFilter), (k) => agentPoolFilter[k] === true)
    providedAgentPoolFilter = (providedAgentPoolFilter.length > 0) ? providedAgentPoolFilter[0] : ''
    // WARNING: If another filter is added the method getSearchArgs() in List.js
    // must be updated so that the reassigning of clients' agent won't break.
    // and the clientsExportSearch() in client/actions.js
    const args = [
      head(clientsSalesAgents) ? `salesAgentId: [${clientsSalesAgents}]` : '',
      clientsCountriesFilter ? `countries: [${countriesAsList}]` : '',
      conversionStatusFilter ? `conversionStatus: ${conversionStatusFilter}` : '',
      conversionStepFilter ? `conversionStep: ${conversionStepFilter}` : '',
      head(tradingStatusFilter) ? `tradingStatuses: [${tradingStatusFilter.join(', ')}]` : '',
      head(kycStatusFilter) ? `kycStatuses: [${kycStatusFilter.join(', ')}]` : '',
      head(riskCategoryFilter) ? `riskCategories: [${riskCategoryFilter.join(', ')}]` : '',
      clientsSearch ? `search: ${JSON.stringify(clientsSearch)}` : '',
      depositorsFilter ? `isDepositor: ${depositorsFilter}` : '',
      tagCategoriesFilter ? `tagCategories: ${tagCategoriesFilter}` : '',
      onlineOnlyFilter ? `onlineOnly: ${onlineOnlyFilter}` : '',
      underMonitoringFilter ? `underMonitoring: ${underMonitoringFilter}` : '',
      isNumber(balanceMaxFilter) ? `aggregatedBalanceEurMax: ${balanceMaxFilter}` : '',
      isNumber(balanceMinFilter) ? `aggregatedBalanceEurMin: ${balanceMinFilter}` : '',
      forexAPTestScoreFilter > 0 ? `forexAPScoreLimit: ${forexAPTestScoreFilter}` : '',
      binaryAPTestScoreFilter > 0 ? `binaryAPScoreLimit: ${binaryAPTestScoreFilter}` : '',
      providedPotentialsFilter.length > 0 ? `providedPotentials: ${providedPotentialsFilter[0]}` : '',
      providedAgentPoolFilter.length > 0 ? `providedAgentPool: ${JSON.stringify(providedAgentPoolFilter)}` : '',
      head(companyFilter) ? `companies: [${companyFilter}]` : '',
      head(tagFilter) ? `tags: [${tagFilter}]` : '',
      head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
      clientsAccountsFilter ? `accountTypeSubtype: ${formatAccountTypeFilterInput(clientsAccountsFilter)}` : '',
      ibApprovedFilter ? `isIbApproved: ${get(find(yesNo, {value: ibApprovedFilter}), 'boolValue')}` : '',
      searchIbId ? `ibId: ${searchIbId}` : '',
      searchPammMMID ? `pammMMId: ${searchPammMMID}` : '',
      masterIbOnlyFilter ? `masterIbOnly: ${masterIbOnlyFilter}` : '',
      searchTrackEvent ? `subCampId: "${searchTrackEvent}"` : '',
      searchCampaign ? `campaign: "${searchCampaign}"` : '',
      searchPparams && trackEventClientType === 'IB ID' ? `trackEventIbId: "${searchPparams}"` : '',
      searchPparams && trackEventClientType === 'Affiliate ID' ? `trackEventAffiliateId: "${searchPparams}"` : '',
      attendedFilter ? `attended: ${get(find(yesNo, {value: attendedFilter}), 'boolValue')}` : '',
      eventTypeFilter ? `eventType: ${eventTypeFilter}` : '',
      partnersAgentFilter ? `supportAgentId: ${partnersAgentFilter}` : '',
      competitionFilter ? `competitionId: ${competitionFilter}` : '',
      copyTradingFilter ? `copyTrading: ${copyTradingFilter}` : '',
      missingPoiPorFilter ? `missingPoiPor: [${map(missingPoiPorFilter, (value) => `"${value}"`)}]` : '',
      signedUpFromFilter ? `signedUpFrom: "${signedUpFromFilter.toISOString()}"` : '',
      signedUpToFilter ? `signedUpTo: "${signedUpToFilter.toISOString()}"` : '',
      convertedFromFilter ? `convertedFrom: "${convertedFromFilter.toISOString()}"` : '',
      convertedToFilter ? `convertedTo: "${convertedToFilter.toISOString()}"` : '',
      ftdFromFilter ?  `ftdFrom: "${ftdFromFilter.toISOString()}"` : '',
      ftdToFilter ?  `ftdTo: "${ftdToFilter.toISOString()}"` : '',
      lastLoginFromFilter ?  `lastLoginFrom: "${lastLoginFromFilter.toISOString()}"` : '',
      lastLoginToFilter ?  `lastLoginTo: "${lastLoginToFilter.toISOString()}"` : '',
      lastEffectiveCallFromFilter ?  `lastEffectiveCallFrom: "${lastEffectiveCallFromFilter.toISOString()}"` : '',
      lastEffectiveCallToFilter ?   `lastEffectiveCallTo: "${lastEffectiveCallToFilter.toISOString()}"` : '',
      lastAttemptedCallFromFilter ?  `lastAttemptedCallFrom: "${lastAttemptedCallFromFilter.toISOString()}"` : '',
      lastAttemptedCallToFilter ?   `lastAttemptedCallTo: "${lastAttemptedCallToFilter.toISOString()}"` : '',
      noEffectiveCallFilter ? `noEffectiveCall: ${noEffectiveCallFilter}` : '',
      languageFilter ? `language: ${languageFilter}` : '',
      birthdayFromFilter ? `birthdayFrom: "${birthdayFromFilter.toISOString()}"` : '',
      birthdayToFilter ? `birthdayTo: "${birthdayToFilter.toISOString()}"` : '',
      lastActivityFromFilter ? `lastActivityFrom: "${lastActivityFromFilter.toISOString()}"` : '',
      lastActivityToFilter ? `lastActivityTo: "${lastActivityToFilter.toISOString()}"` : '',
      jobTitleFilter ? `jobTitle: ${jobTitleFilter}` : '',
      netWorthFilter ? `netWorth: ${netWorthFilter}` : '',
    ].join('\n').trim()
    const query = `{
      clients(
        ${args}
        limit: ${getPageSize()}
        offset: ${getOffset(clientsPage)}
        orderBy: ${clientsSort.orderBy}
        orderDirection: ${clientsSort.orderDirection}
        balanceCurrencyFilter: ${balanceCurrencyFilter},
      ) {
        ${queryFields}
        isOnline
        topVip
        whiteLabel
      }
      ${(!clientsPage || clientsPage === 1) ? `clientsCount(estimated: false ${args})` : ''}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Clients loaded',
    (state, res) => ({
      ...state,
      prevClientsCount: get(res, 'clientsCount', state.prevClientsCount),
      clients: res.clients,
      clientsCount: get(res, 'clientsCount', state.prevClientsCount),
      watchlistCheckedAt: get(res, 'watchlistCheckedAt.0.createdAt'),
    }),
    [res]
  ),
}

export const marketingEmailsProvider = {
  getQuery: () => `{
    marketingEmailTemplates {
      id
      name
    }
    marketingEmailUnsubscribeGroups {
      id
      name
    }
  }`,

  onData: (res, dispatch) => dispatch(
    'Marketing Emails loaded',
    (state, {marketingEmailTemplates, marketingEmailUnsubscribeGroups}) => ({
      ...state,
      marketingEmailTemplates,
      marketingEmailUnsubscribeGroups,
    }),
    [res]
  ),
}
