import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Form, Row, Col, Tab, Tabs} from 'react-bootstrap'
import moment from 'moment'
import {get, isEmpty, pickBy, map, find, merge, first, reject, has, omit, filter, range, includes,
  difference, some,
} from 'lodash'
import {renderCloseTab} from '../utils/rendering'
import {getObjectActivityLogs, unassigned} from '../useful'
import {documentTypes, documentStatuses, unset, fundingCategories} from '@bdswiss/common-enums'
import {canEditDocuments, canAssignDocument} from '@bdswiss/common-permissions'
import {putFile} from '../utils/net'
import {provideProps, uiMount} from '../decorators'
import DateTime from '../components/DateTime'
import StylishSelect from '../components/StylishSelect'
import Events from './Events'
import {uiStatePath} from './Client'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import SelectAgent from '../components/SelectAgent'
import {getDocumentLabel} from '../common/utils'

const EventsMounted = uiMount(() => uiStatePath.concat(['Events']))(Events)
const cardNumberRestrictions = [
  documentTypes.cardCopies.groupName,
  documentTypes.cardCopies.key,
  documentTypes.cardCopiesBack.key,
  documentTypes.virtualCard.key,
  documentTypes.bankCard.key,
  documentTypes.cardStatement.key,
  documentTypes.bankStatement.key,
  documentTypes.bankStatementPOR.key,
  documentTypes.bankStatementPOF.key,
  documentTypes.bankPaymentDetails.key
]

const fundingDocumentTypes = map(filter(documentTypes, (d) =>
  get(d, 'category') === 'POF' || get(d, 'subCategories', []).includes('POF')
), 'key')

export default provideProps()(class DocumentEditor extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    document: PropTypes.object,
    activityLogs: PropTypes.array,
    onClose: PropTypes.func.isRequired,
  };

  componentWillMount() {
    let availableDocumentTypes = documentTypes
    if (this.isCreateMode()) {
      const doubleSidedTypes = {
        id: {
          key: documentTypes.idFront.groupName,
          label: 'ID',
          category: 'POI'
        },
        drivingLicence: {
          key: documentTypes.drivingLicenseFront.groupName,
          label: 'Driving Licence',
          category: 'POI'
        },
        cardCopies: {
          key: documentTypes.cardCopies.groupName,
          label: 'Credit/Debit Card Copy',
          category: 'POF'
        },
      }
      const singleSidedTypes = pickBy(documentTypes, (d) => !d.doubleSided)
      availableDocumentTypes = merge(doubleSidedTypes, singleSidedTypes)
      availableDocumentTypes = map(availableDocumentTypes, (documentType) => {
        if (documentType.key === documentTypes.bankStatementPOR.key) {
          documentType.label = 'Bank Statement (POR)'
        } else if (documentType.key === documentTypes.bankStatementPOF.key) {
          documentType.label = 'Bank Statement (POF)'
        } else if (documentType.key === documentTypes.otherPOF.key) {
          documentType.label = 'Other (POF)'
        }
        return documentType
      })
      availableDocumentTypes = reject(availableDocumentTypes, 'disabled')
    }
    this.setState({availableDocumentTypes})
    this.resetForm()
  }

  componentDidMount() {
    if (this.fileInput) {
      // this function is used for file upload in testing
      this.fileInput['t-file-input-on-change'] = (files) => this.formValueChanged('files_0', files)
    }
  }

  resetForm = () => this.setState({
    values: {},
    errors: {},
    feedback: false,
    filesCount: 1,
  })

  isCreateMode = () => !('id' in (this.props.document || {}))

  formValueChanged(key, value) {
    const values = {...this.state.values, [key]: value}
    if (this.state.feedback) {
      this.setState({values, errors: this.validate(values)})
    } else {
      this.setState({values})
    }
  }

  validate(values = this.state.values, showFunding) {
    const isCreateMode = this.isCreateMode()
    const errors = {}

    if (isCreateMode && (!values.files_0 || values.files_0.length === 0)) {
      errors.files_0 = true
    }

    if (values.type === 'other' &&
       ((get(values, 'fileDescription.length', -1) > 20) || isEmpty(values.fileDescription))) {
      errors.fileDescription = true
    }

    if (values.expiration && !moment.isMoment(values.expiration)) {
      errors.expiration = true
    }

    if (isCreateMode && !values.type) {
      errors.type = true
    }

    return errors
  }

  trySave = (showFunding) => {
    const errors = this.validate(this.state.values, showFunding)

    if (!isEmpty(errors)) {
      this.setState({errors, feedback: true})
      return
    }

    const {values} = this.state
    this.resetForm();

    (this.isCreateMode() ? this.createNewDocument(values) : this.editDocument(values))
      .then(this.props.onClose)
  }

  generateDocumentTag(clientId, type) {
    return `${clientId}-${type}-${Date.now()}`
  }

  createNewDocument(values) {
    const {clientId} = this.props
    const {filesCount} = this.state
    const {type, expiration, fileDescription, cardNumber, assignee, relatesTo, paymentMethodId} = values
    let frontType = type, backType
    const isDoubleSided = find(documentTypes, (d) => d.groupName === type) !== undefined
    if (isDoubleSided) {
      frontType = find(documentTypes, (d) => d.groupName === type && d.sideName === 'Front').key
      backType = find(documentTypes, (d) => d.groupName === type && d.sideName === 'Back').key
    }

    const allOperations = []
    for (let i = 0; i < filesCount; i++) {
      const tag = this.generateDocumentTag(clientId, type)
      const files = values[`files_${i}`], files2 = values[`files2_${i}`]
      if (files && files2) {
        this.uploadAndSaveDocument(clientId, first(files2), backType, expiration,
          fileDescription, tag, cardNumber, assignee, relatesTo, paymentMethodId).then(
          () => allOperations.push(
            this.uploadAndSaveDocument(clientId, first(files), frontType, expiration,
              fileDescription, tag, cardNumber, assignee, relatesTo, paymentMethodId),
          ))
      } else if (files) {
        allOperations.push(
          this.uploadAndSaveDocument(clientId, first(files), frontType, expiration,
            fileDescription, tag, cardNumber, assignee, relatesTo, paymentMethodId),
        )
      }
    }

    return Promise.all(allOperations).then(() => {
      this.context.clientProvider.subProviders.basicData.fetch()
      this.context.clientProvider.subProviders.documents.fetch()
      this.context.clientProvider.subProviders.activityLogs.fetch()
    }).catch(this.context.logError)
  }

  uploadAndSaveDocument(clientId, file, type, expiration, fileDescription, tag, cardNumber, assignee, relatesTo, paymentMethodId) {
    const {client: clientActions} = this.props.actions
    return clientActions.signUploadUrl(clientId)
      .then((res) => {
        const {key, signedUrl} = res.signUploadUrl
        return putFile(file, signedUrl)
          .then(() =>
            clientActions.upsertDocument(clientId, null, key, type, expiration,
              fileDescription, null, tag, cardNumber, assignee, relatesTo, paymentMethodId))
          .then(
            () => {
              this.context.clientProvider.subProviders.basicData.fetch()
              this.context.clientProvider.subProviders.documents.fetch()
              this.context.clientProvider.subProviders.activityLogs.fetch()
            }
          )
      })
  }

  editDocument(values) {
    const {clientId, document} = this.props
    const assignee = has(values, 'assignee') && get(values, 'assignee') !== get(document, 'assignee.id')
      ? values.assignee || unset.unset.value
      : null
    return this.props.actions.client
      .upsertDocument(clientId, document.id, null, values.type, values.expiration,
        values.fileDescription, values.status, null, values.cardNumber, assignee, values.relatesTo,
        values.paymentMethodId)
      .then(
        () => {
          this.context.clientProvider.subProviders.basicData.fetch()
          this.context.clientProvider.subProviders.documents.fetch()
          this.context.clientProvider.subProviders.activityLogs.fetch()
        }
      )
      .catch(this.context.logError)
  }

  componentWillReceiveProps({document}) {
    if (!document) {return}
    const assignee = get(document, 'assignee.id')
    const values = {...omit(document, 'assignee')}
    if (assignee) values.assignee = assignee
    this.setState({values})
  }

  getRelatesToDocumentOptions(documents, id) {
    return [
      {label: '', value: 'unset'},
      ...map(filter(documents, o => !o.relatesTo && o.id !== id), o => ({
        label: `${getDocumentLabel(o.type, o.fileDescription)} - ${o.id}`,
        value: o.id,
      }))
    ]
  }

  getFundingDocumentsRelatedToOptions() {
    return map(this.props.paymentMethods, d => ({
      label: `${get(fundingCategories[d.fundingCategory], 'label', '-')} ${(d.details) !== '-' ? d.details : ''}`,
      value: d.id,
    }))
  }


  addMoreFiles = () => {
    const {filesCount} = this.state
    if (filesCount + 1 <= 10) this.setState({filesCount: filesCount + 1})
  }

  findFundingRelatedDocument() {
    const {props: {document, paymentMethods}} = this
    const paymentMethod  = find(paymentMethods, (d) => includes(d.relatedDocumentIds, get(document, 'id')))
    return get(paymentMethod, 'id')
  }

  render() {
    const {
      props: {onClose, document, clientId, agents, documents, paymentMethods, viewer, client: {personalDetails}},
      state: {values, errors, availableDocumentTypes, filesCount}
    } = this
    const isCreateMode = this.isCreateMode()
    const header = isCreateMode ? 'New Document' : `Document #${document.id || ''}`
    const type = get(values, 'type', '')
    const relatesTo = get(values, 'relatesTo', '')
    const paymentMethodId = get(values, 'paymentMethodId', '') || this.findFundingRelatedDocument()
    const showFileDescription = type ? ['other', 'otherPOF'].includes(type) : false
    const updatableStatuses = pickBy(documentStatuses, 'userUpdatable')
    const allowStatusUpdate = !isCreateMode && map(updatableStatuses, 'key').includes(document.status)
    const isDoubleSided = find(documentTypes, (d) => d.groupName === type) !== undefined
    const allAgents = [
      {
        value: null,
        label: 'Unassigned',
      },
    ]

    const showAssignee = viewer.id === get(values, 'assignee') ||
      some(viewer.roles, (r) => ['admin', 'super_user'].includes(r))

    const fundingDocuments = filter(documents, (d) => fundingDocumentTypes.includes(d.type))

    const doubleSidedAndFunding = get(find(documentTypes, (d) => d.groupName === type), 'fundingCategories')
    const showFunding = includes(map(fundingDocuments, 'id'), get(document, 'id')) ||
      fundingDocumentTypes.includes(type) ||
      doubleSidedAndFunding

    const paymentMethod = find(paymentMethods, {id: paymentMethodId})
    const relatesToDocuments = showFunding
      ? filter(fundingDocuments, (d) => get(paymentMethod, 'relatedDocumentIds', []).includes(d.id))
      : difference(documents, fundingDocuments)
    const relatesToDocumentOptions = this.getRelatesToDocumentOptions(relatesToDocuments, get(document, 'id'))


    return (
      <Tabs>
        {!isCreateMode && (
          <Tab title="Notes" eventKey="notes">
            <EventsMounted
              clientId={clientId}
              documentId={document.id}
              activityLogs={getObjectActivityLogs(document, 'objectDocument', this.props.activityLogs)}
              onCreate={() => {
                this.context.clientProvider.subProviders.documents.fetch()
                this.props.fetchActivityLogs && this.props.fetchActivityLogs(document.id)
              }}
            />
          </Tab>
        )}

        {(canEditDocuments(this.props.viewer) || isCreateMode) && (
          <Tab id="t-client-document-editor" title={<strong>{header}</strong>} eventKey="document">
            <Row>
              <Col xs={12}>
                {isCreateMode ? map(range(filesCount), (i) => (
                  <div
                    id={`t-client-document-editor-file-${i}`}
                    ref={(el) => (this.fileInput = el)}
                  >
                    <Form.Control
                      type="file"
                      title={`File ${isDoubleSided ? 'Front' : ''} #${i + 1}`}
                      label={`File ${isDoubleSided ? 'Front' : ''} #${i + 1}`}
                      isInvalid={errors[`files_${i}`]}
                      className="mb-1"
                      onChange={(e) => this.formValueChanged(`files_${i}`, e.target.files)}
                    />
                    {isDoubleSided &&
                      <Form.Control
                        type="file"
                        title={`File Back #${i + 1}`}
                        label={`File Back #${i + 1}`}
                        isInvalid={errors[`files2_${i}`]}
                        onChange={(e) => this.formValueChanged(`files2_${i}`, e.target.files)}
                      />
                    }
                  </div>
                )) : (
                  <div className="form-group">
                    <label>File</label>
                    <a href={document.url} target="_blank" rel="noopener noreferrer" className={style.formLink}>
                      Link
                    </a>
                  </div>
                )}
              </Col>
              <Col xs={12}>
                {isCreateMode && filesCount < 10 &&
                  <Button
                    id="add-more-files"
                    size="sm"
                    className="float-right"
                    variant="link"
                    onClick={this.addMoreFiles}
                  >
                    Add More
                  </Button>
                }
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-client-document-editor-type"
                  label="Type"
                  value={type}
                  bsStyle={errors.type && 'error'}
                  onChange={(e) => {
                    this.formValueChanged('type', e.value)
                  }}
                  placeholderText="Choose Type"
                  options={StylishSelect.enumToStylishOptions(availableDocumentTypes)}
                />
                {showFunding && <StylishSelect.Input
                  id="t-client-document-editor-relates-to-funding"
                  label="Relates to Payment Method"
                  value={paymentMethodId}
                  onChange={(e) => {
                    this.formValueChanged('paymentMethodId', e.value)
                  }}
                  placeholderText="Choose payment method"
                  options={[
                    {value: 'unset', label: 'Unlink'},
                    ...this.getFundingDocumentsRelatedToOptions()
                  ]}
                />}
                {((type && !showFunding) || (type && showFunding && paymentMethodId)) && <StylishSelect.Input
                  id="t-client-document-editor-relates-to"
                  label="Relates to Document"
                  value={relatesTo}
                  bsStyle={errors.relatesTo && 'error'}
                  onChange={(e) => {
                    this.formValueChanged('relatesTo', e.value)
                  }}
                  placeholderText="Choose related document"
                  options={relatesToDocumentOptions}
                />}
                {showFileDescription &&
                  <Form.Group>
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      type="text"
                      id="t-client-document-editor-file-description"
                      title="fileDescription"
                      value={get(values, 'fileDescription', '')}
                      isInvalid={errors.fileDescription}
                      onChange={(e) => this.formValueChanged('fileDescription', e.target.value)}
                    />
                  </Form.Group>
                }
              </Col>
            </Row>
            {(showAssignee || !get(values, 'assignee')) && <Row>
              <Col xs={12}>
                <div className="form-group">
                  <label>Assignee</label>
                  <SelectAgent
                    id='document'
                    placeholderText={unassigned}
                    disabled={!canAssignDocument(this.props.viewer)}
                    agents={filter(agents, (a) => a.isActive && a.companies.includes(personalDetails.company))}
                    value={get(values, 'assignee', '')}
                    optionsPrefix={allAgents}
                    bsStyle={errors.assignee && 'error'}
                    onChange={(e) => this.formValueChanged('assignee', e.id || null)}
                  />
                </div>
              </Col>
            </Row>}
            {(values.type !== documentTypes.cardCopies.key &&
              values.type !== documentTypes.cardCopiesBack.key) &&
              <Row>
                <Col xs={12}>
                  <div id="t-client-document-editor-date">
                    <DateTime
                      label="Expiration Date"
                      bsStyle={errors.expiration && 'error'}
                      timeFormat={false}
                      value={get(values, 'expiration', '')}
                      onChange={(value) => this.formValueChanged('expiration', value)}
                    />
                  </div>
                </Col>
              </Row>}
            {cardNumberRestrictions.includes(values.type) &&
              <Row>
                <Col xs={12}>
                  <Form.Group>
                    <Form.Label>Card Number</Form.Label>
                    <Form.Control
                      type="number"
                      isInvalid={errors.expiration}
                      value={get(values, 'cardNumber', '')}
                      onChange={(e) => this.formValueChanged('cardNumber', e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>}
            {allowStatusUpdate && <Row>
              <Col xs={12}>
                <div id="t-client-document-editor-status">
                  <StylishSelect.Input
                    label="Status"
                    bsStyle={errors.expiration && 'error'}
                    value={get(values, 'status', '')}
                    options={StylishSelect.enumToStylishOptions(updatableStatuses)}
                    onChange={(e) => this.formValueChanged('status', e.value)}
                  />
                </div>
              </Col>
            </Row>}
            <Row>
              <Col xs={12}>
                <ButtonToolbar className="float-right">
                  <Button
                    id="t-client-document-editor-cancel"
                    tabIndex={-1}
                    onClick={onClose}
                    variant="outline-secondary"
                    size="sm"
                    className="mr-1"
                  >
                    Cancel
                  </Button>
                  <Button
                    id="t-client-document-editor-save"
                    variant="success"
                    size="sm"
                    onClick={() => this.trySave(showFunding)}
                    disabled={isEmpty(values)}
                  >
                    {isCreateMode ? 'Create' : 'Save'}
                  </Button>
                </ButtonToolbar>
              </Col>
            </Row>
          </Tab>
        )}

        {renderCloseTab(onClose)}
      </Tabs>
    )
  }
})
