import React from 'react'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Col, Row, Button, Form} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import style from './users.module.scss'

class ChangePasswordModal extends PureComponent {

  static contextTypes = {
    logError: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    onPasswordChanged: PropTypes.func.isRequired,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  setDefaultState() {
    this.setState({
      isTouched: false,
      currentPassword: '',
      newPassword: ''
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  changePassword() {
    const {currentPassword, newPassword} = this.state
    if (this.isValid()) {
      this.context.actions.user.changeOwnPassword(currentPassword, newPassword)
        .then(() => {
          this.props.onPasswordChanged()
        })
        .catch(this.context.logError)
    }
  }

  isValid() {
    const {currentPassword, newPassword} = this.state
    return (!isEmpty(currentPassword) && !isEmpty(newPassword) && newPassword.length > 6)
  }

  render() {
    const {onHide, show} = this.props
    const {currentPassword, newPassword} = this.state
    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Change Current Password
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col id="t-current-password" xs={12}>
              <label> Current Password </label>
              <Form.Control
                value={currentPassword}
                onChange={(e) => this.setState({currentPassword: e.target.value})}
                isInvalid={!this.isValid()}
                placeholderText="Current Password"
                type="password"
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <label> New Password </label>
              <Form.Control
                value={newPassword}
                onChange={(e) => this.setState({newPassword: e.target.value})}
                placeholderText="New Password"
                type="password"
                isInvalid={!this.isValid()}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <div>
                <p className={style.helpInfo}> *New Password Must Have Minimum Length Of 6 Characters </p>
              </div>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-change-password-cancel"
            variant="outline-secondary"
            size="sm"
            className="mr-1"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-change-password-save"
            variant="success"
            size="sm"
            disabled={!(this.isValid())}
            onClick={() => this.changePassword()}
          >
            Change Password
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default ChangePasswordModal
