import React, {Component} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {get, sortBy, orderBy, map, filter, findIndex, find, reverse, isEmpty} from 'lodash'
import {Col, Row, Button, Card, Table, Image, OverlayTrigger, Tooltip, Container} from 'react-bootstrap'
import style from './paymentOptions.module.scss'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {canWritePaymentOptions} from '@bdswiss/common-permissions'

export default class EditCountryPaymentOptions extends Component {
  state = {}

  static contextTypes = {
    paymentOptionsProvider: PropTypes.object.isRequired,
  }

  shiftCountryOptionRank(direction) {
    const {selectedCountryOptionId, allCountryPaymentOptions} = this.state
    const index = findIndex(allCountryPaymentOptions, (a) => a.paymentOption.id === selectedCountryOptionId)
    const listSize = allCountryPaymentOptions.length - 1

    if ((direction === 'up' && index < 1) || (direction === 'down' && index === listSize)) return
    const indexShift = direction === 'up' ? -1 : 1
    const newIndex = index + indexShift
    const opt = allCountryPaymentOptions[index]
    const optsCopy = [...allCountryPaymentOptions]
    optsCopy.splice(index, 1)
    optsCopy.splice(newIndex, 0, opt)
    this.setState({allCountryPaymentOptions: optsCopy, optionsDirty: true})
  }

  addSelectedAvailableToCountryOptions() {
    const {paymentOptions} = this.props
    const {selectedAvailableCountryOptionId, allCountryPaymentOptions} = this.state
    const opt = find(paymentOptions, (a) => a.id === selectedAvailableCountryOptionId)
    if (!opt) return
    const countryOpt = {paymentOption: opt, rank: allCountryPaymentOptions.length}
    const countryOptsCopy = [...allCountryPaymentOptions]
    countryOptsCopy.push(countryOpt)
    this.setState({
      optionsDirty: true,
      selectedAvailableCountryOptionId: null,
      allCountryPaymentOptions: countryOptsCopy,
    })
  }

  removeSelectedCountryOption() {
    const {selectedCountryOptionId, allCountryPaymentOptions} = this.state
    const index = findIndex(allCountryPaymentOptions, (a) => a.paymentOption.id === selectedCountryOptionId)
    const optsCopy = [...allCountryPaymentOptions]
    optsCopy.splice(index, 1)
    this.setState({
      optionsDirty: true,
      selectedCountryOptionId: null,
      allCountryPaymentOptions: optsCopy,
    })
  }

  componentWillMount() {
    this.deriveStateFromProps(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.deriveStateFromProps(nextProps)
  }

  deriveStateFromProps(props) {
    const {countryPaymentOptions: countryOpts, currentCountry: {key: countryKey}} = props
    const allCountryPaymentOptions =
      orderBy(filter(countryOpts, (a) => a.enabled && a.country === countryKey), 'rank', ['desc'])
    const defaultEUPaymentOptions =
      orderBy(filter(countryOpts, (a) => a.enabled && a.country === '_default'), 'rank', ['desc'])
    const defaultNonEUPaymentOptions =
      orderBy(filter(countryOpts, (a) => a.enabled && a.country === '_defaultNonEU'), 'rank', ['desc'])
    this.setState({
      allCountryPaymentOptions,
      optionsDirty: null,
      defaultEUPaymentOptions,
      defaultNonEUPaymentOptions,
    })
  }

  resetCountryOptions() {
    this.deriveStateFromProps(this.props)
  }

  saveCountryOptions() {
    const {allCountryPaymentOptions} = this.state
    const {onSaveCountryOptions} = this.props
    onSaveCountryOptions(reverse(allCountryPaymentOptions))
  }


  makeDefaultAvailable(isEU = true) {
    const defaultKey = isEU ? 'defaultEUPaymentOptions' : 'defaultNonEUPaymentOptions'
    const {allCountryPaymentOptions} = this.state
    if (allCountryPaymentOptions.length > 0) return
    const countryPaymentOpts = get(this.state, [defaultKey])
    this.setState({allCountryPaymentOptions: [...countryPaymentOpts], optionsDirty: true})
  }

  renderPaymentRow(paymentOption) {
    return (
      <Container fluid>
        <Row key={paymentOption.id}>
          <Col xs>
            <Image
              style={{maxHeight: 40, maxWidth: 60}}
              src={paymentOption.logoUrl || undefined}
              rounded
              responsive
            />
          </Col>
          <Col xs>
            <span>{`${paymentOption.name} by ${paymentOption.provider}`}</span>
          </Col>
          {paymentOption.confluenceLink && <Col xs>
            <a
              style={{cursor: 'pointer', textDecoration: 'underline'}}
              href={paymentOption.confluenceLink}
              rel="noopener noreferrer"
              target="_blank"
            >
              Confluence Link
            </a>
          </Col>}
        </Row>
      </Container>
    )
  }

  renderUpdateActions() {
    const {optionsDirty} = this.state
    return (
      <Row>
        <Col xs={{offset: 2, span: 3}}>
          <Button
            disabled={!optionsDirty}
            variant="outline-secondary"
            size="sm"
            className="mr-1"
            onClick={() => this.resetCountryOptions()}
          >Reset</Button>
        </Col>
        <Col xs={3}>
          <Button
            disabled={!optionsDirty}
            variant="success"
            size="sm"
            onClick={() => this.saveCountryOptions()}
          >Save</Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {paymentOptions, companyFilter} = this.props

    const {
      selectedCountryOptionId,
      selectedAvailableCountryOptionId,
      allCountryPaymentOptions,
    } = this.state
    const {currentCountry} = this.props
    const allCountryOptionsId = map(allCountryPaymentOptions, (a) => a.paymentOption.id)
    const availableCountryOptions =
      sortBy(filter(paymentOptions, (a) => !allCountryOptionsId.includes(a.id)), 'name')
    const canEditPaymentOptions = canWritePaymentOptions(this.props.viewer)

    const filteredCOuntryPaymentOptions = filter(allCountryPaymentOptions, (o) => {
      const availableCompanies = get(o, 'paymentOption.companies') || []
      return !companyFilter || isEmpty(availableCompanies) || availableCompanies.includes(companyFilter)
    })

    return (
      <Row>
        {canEditPaymentOptions && <Col xs={1} className={style.controlButtonGroup}>
          <Row className={style.controlButton}>
            <Col xs={{offset: 4}}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{'Move Up'}</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  disabled={!selectedCountryOptionId}
                  onClick={() => this.shiftCountryOptionRank('up')}
                >
                  <FontAwesomeIcon icon={'angle-double-up'} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
          <Row className={style.controlButton}>
            <Col xs={{offset: 4}}>
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{'Move Down'}</Tooltip>}>
                <Button
                  variant="outline-secondary"
                  disabled={!selectedCountryOptionId}
                  onClick={() => this.shiftCountryOptionRank('down')}
                >
                  <FontAwesomeIcon icon={'angle-double-down'} />
                </Button>
              </OverlayTrigger>
            </Col>
          </Row>
        </Col>}
        <Col xs={canEditPaymentOptions ? 4 : 8}>
          <Card>
            <Card.Header><h5>{`Options For ${currentCountry.label}`}</h5></Card.Header>
            <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
              <Table hover className={style.table}>
                <tbody>
                  {
                    map(filteredCOuntryPaymentOptions, (countryOpt) => {
                      const classes = canEditPaymentOptions ? [style.clickable] : []
                      const {paymentOption} = countryOpt
                      if (selectedCountryOptionId === paymentOption.id) {
                        classes.push(style.selectedPaymentOption)
                      }
                      return (
                        <tr
                          className={classNames(classes)}
                          key={`country-opt-${paymentOption.id}`}
                          onClick={() => {
                            if (canEditPaymentOptions) {
                              this.setState({selectedCountryOptionId: paymentOption.id})
                            }
                          }}
                        >
                          <td className={style.countryOption}>
                            {this.renderPaymentRow(paymentOption)}
                          </td>
                        </tr>
                      )
                    })
                  }
                  {
                    allCountryPaymentOptions.length < 1 &&
                      <tr>
                        <td>
                          {'Using Default Options'}
                        </td>
                      </tr>
                  }
                </tbody>
              </Table>
            </Card.Body>
            {canEditPaymentOptions && <Card.Footer>{this.renderUpdateActions()}</Card.Footer>}
          </Card>
        </Col>
        {canEditPaymentOptions && <Col xs={2} className={style.controlButtonGroup}>

          <Row className={style.controlButton}>
            <Col xs={{offset: 4}}>
              <Button
                variant="outline-secondary"
                disabled={!selectedAvailableCountryOptionId}
                onClick={() => this.addSelectedAvailableToCountryOptions()}
              >
                <FontAwesomeIcon icon={'angle-double-left'} />
              </Button>
            </Col>
          </Row>
          { allCountryPaymentOptions.length < 1 &&
            [
              <Row key="copy-eu-default" className={style.controlButton}>
                <Col>
                  <Button size="sm" variant="outline-secondary" onClick={() => this.makeDefaultAvailable(true)}>
                    <FontAwesomeIcon icon={'angle-double-left'} /> {' Copy EU Default'}
                  </Button>
                </Col>
              </Row>,
              <Row key="copy-non-eu-default" className={style.controlButton}>
                <Col>
                  <Button size="sm" variant="outline-secondary" onClick={() => this.makeDefaultAvailable(false)}>
                    <FontAwesomeIcon icon={'angle-double-left'} /> {' Copy Non-EU Default'}
                  </Button>
                </Col>
              </Row>,
            ]
          }
          <Row className={style.controlButton}>
            <Col xs={{offset: 4}}>
              <Button
                variant="outline-secondary"
                disabled={!selectedCountryOptionId}
                onClick={() => this.removeSelectedCountryOption()}
              >
                <FontAwesomeIcon icon={'angle-double-right'} />
              </Button>
            </Col>
          </Row>
        </Col>}
        {canEditPaymentOptions && <Col xs={4}>
          <Card>
            <Card.Header><h5>{'Available Options'}</h5></Card.Header>
            <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
              <Table hover className={style.table}>
                <tbody>
                  {
                    map(availableCountryOptions, (paymentOption) => {
                      const classes = [style.clickable]
                      if (selectedAvailableCountryOptionId === paymentOption.id) {
                        classes.push(style.selectedPaymentOption)
                      }
                      return (
                        <tr
                          className={classNames(classes)}
                          key={paymentOption.id}
                          onClick={(e) => this.setState({selectedAvailableCountryOptionId: paymentOption.id})}
                        >
                          <td> {this.renderPaymentRow(paymentOption)} </td>
                        </tr>
                      )
                    })
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>}
      </Row>
    )
  }
}

