import React from 'react'
import {Lokka} from 'lokka'
import {Transport} from 'lokka-transport-http'
import moment from 'moment'
import 'moment/locale/en-gb'
import PropTypes from 'prop-types'
import {get, map, flatten} from 'lodash'
import url from 'url'
import EventEmitter from 'eventemitter3'
import {List as Loading} from 'react-content-loader'
import {Route} from 'react-router-dom'
import {createAppstate} from './dispatcher'
import Main from './Main'
import Login from './Login'
import {connectionStatus} from '@bdswiss/common-enums'
import createUserActions from './users/actions'
import createCampaignActions from './campaigns/actions'
import createTagActions from './tags/actions'
import createClientActions from './client/actions'
import createNewsActions from './news/actions'
import createRolesActions from './Roles/actions'
import createPermissionsActions from './Permissions/actions'
import createManualExecutionsActions from './manualexecutions/actions'
// import createSettingsActions from './settings/actions'
import createPaymentOptionsActions from './paymentoptions/actions'
import createBlacklistActions from './blacklists/actions'
import createPushNotificationsActions from './pushnotifications/actions'
import createMarketingEmailsActions from './marketingemails/actions'
// import createTradingAlertsActions from './tradingalerts/actions'
import initiateJobActions from './jobs/actions'
import createBookAllocationsActions from './bookallocations/actions'
import createSeminarsActions from './seminars/actions'
import creareCallLogActions from './calllogs/actions'
import creareDocumentActions from './documents/actions'
import {viewerQuery} from './providers'
import {backendRequest} from './utils/net'
import createCompetitionActions from './competitions/actions'
import createWithdrawalsActions from './withdrawals/actions'
import createAppointmentActions from './appointments/actions'
import createPerformanceFeesActions from './performancefees/actions'
import createPartnersCustomizationActions from './partnerscustomization/actions'
import createAccountingActions from './accounting/actions'
import createCommunicationsActions from './communications/actions'
import createDepositsActions from './deposits/actions'
import {getFromStorage, saveToStorage, deleteFromStorage} from './useful'
import createCopyTradingActions from './ghostcopiers/actions'

const AppRoutes = () => (
  <Route path="/" component={Main} />
)

class App extends React.Component {

  static contextTypes = {
    config: PropTypes.object.isRequired,
  }

  componentWillMount() {
    this.dispatchEmitter = new EventEmitter()
    this.dispatchEmitter.on('dispatch', (event) => {
      this.forceUpdate()
    })
    moment.locale('en_gb')
    // lower-case l formats can be created automatically, pre-cache them so that moment does not change internally
    const now = moment()
    now.format('l')
    now.format('ll')
    now.format('lll')
    now.format('llll')
    const {dispatch, getState} = createAppstate(
      {
        viewer: {
          fetching: true,
          data: null,
          error: null
        },
        config: this.context.config,
        socketConnectionStatus: connectionStatus.disconnected.key,
      },
      () => {
        this.dispatchEmitter.emit('dispatch')
      },
      /* 2 is most verbose level, 0 is least verbose (logs nothing) */
      this.context.config.debug ? 2 : 0
    )
    this.dispatch = dispatch.bind(this)
    this.getState = getState.bind(this)
    const {backendUrl} = this.context.config
    const transport = new Transport(`${backendUrl}/graphql`)
    transport.send = function send(query, variables, operationName) {
      const payload = {query, variables, operationName}
      const options = transport._buildOptions(payload)
      return fetch(this.endpoint, options).then(response => {
        if (response.status !== 200 && response.status !== 400) {
          const {viewer} = getState()
          dispatch('Show Login', state => ({...state, viewer: {...viewer, isUser: false}}))
          throw new Error(`Invalid status code: ${response.status}`)
        }
        return response.json()
      }).then(({data, errors}) => {
        if (errors) {
          transport.handleErrors(errors, data)
          return null
        }
        return data
      })
    }
    this.dbClient = new Lokka({transport})
    this.actions = {
      user: createUserActions(this.dbClient),
      campaign: createCampaignActions(this.dbClient, this.dispatch.bind(this)),
      client: createClientActions(this.dbClient),
      news: createNewsActions(this.dbClient),
      job: initiateJobActions(this.dbClient),
      tag: createTagActions(this.dbClient),
      // settings: createSettingsActions(this.dbClient),
      paymentOptions: createPaymentOptionsActions(this.dbClient),
      blacklist: createBlacklistActions(this.dbClient),
      pushNotifications: createPushNotificationsActions(this.dbClient),
      marketingEmails: createMarketingEmailsActions(this.dbClient),
      // tradingAlerts: createTradingAlertsActions(this.dbClient),
      bookAllocations: createBookAllocationsActions(this.dbClient),
      seminars: createSeminarsActions(this.dbClient),
      callLog: creareCallLogActions(this.dbClient),
      document: creareDocumentActions(this.dbClient),
      competition: createCompetitionActions(this.dbClient),
      withdrawals: createWithdrawalsActions(this.dbClient),
      appointments: createAppointmentActions(this.dbClient),
      performanceFees: createPerformanceFeesActions(this.dbClient),
      partnersCustomization: createPartnersCustomizationActions(this.dbClient),
      accounting: createAccountingActions(this.dbClient),
      communications: createCommunicationsActions(this.dbClient),
      deposits: createDepositsActions(this.dbClient),
      ghostcopiers: createCopyTradingActions(this.dbClient),
      roles: createRolesActions(this.dbClient),
      permissions: createPermissionsActions(this.dbClient),
      manualExecutions: createManualExecutionsActions(this.dbClient),
    }
    this.dbClient.query('{viewer{__typename}}').then((res) => {
      const isUser = get(res, 'viewer.__typename') === 'User'
      const viewerProvider = 'viewerProvider'
      let cachedData = getFromStorage(viewerProvider)
      if (cachedData && cachedData.expiresAt < Date.now()) {
        deleteFromStorage(viewerProvider)
        cachedData = false
      }
      (cachedData
        ? Promise.resolve({...cachedData, fromCache: true})
        : this.dbClient.query(viewerQuery))
        .then((res) => {
          if (!res.fromCache) {
            saveToStorage(viewerProvider, {...res, expiresAt: Date.now() + 60 * 60 * 1000}) // 1hr
          }
          const roles = map(get(res, 'viewer.roles'), 'id')
          const permissions = flatten(map(get(res, 'viewer.roles'), (r) => map(r.permissions, 'id')))
          this.dispatch(`dispatching fetch viewer success ${res.fromCache ? '(Cached)' : ''}`, (state) => ({
            ...state,
            viewer: {
              fetching: false,
              id: get(res, 'viewer.id'),
              companies: get(res, 'viewer.companies'),
              roles: isUser && roles,
              permissions: isUser && permissions,
              error: null,
              isUser,
              firstName: get(res, 'viewer.firstName'),
              lastName: get(res, 'viewer.lastName'),
              avatar: get(res, 'viewer.avatar'),
              department: get(res, 'viewer.department'),
              departmentRoleType: get(res, 'viewer.departmentRoleType'),
              salesAgentType: get(res, 'viewer.salesAgentType')
            }
          }))
        })
    }).catch((e) => {
      this.dispatch('dispatching fetch viewer error', (state) => ({
        ...state,
        viewer: {
          fetching: false,
          data: null,
          error: e
        }
      }))
    })
  }

  static childContextTypes = {
    getState: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    dbClient: PropTypes.object.isRequired,
    dispatchEmitter: PropTypes.object.isRequired,
    logOut: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  };

  getChildContext() {
    return ({
      getState: this.getState,
      dispatch: this.dispatch,
      actions: this.actions,
      dbClient: this.dbClient,
      dispatchEmitter: this.dispatchEmitter,
      logOut: this.logOut,
      router: this.props.history,
    })
  }

  componentWillUpdate(nextProps) {
    const state = this.getState()
    if (state.error) {
      const {message} = state.error
      this.props.history.push(`/error/${encodeURIComponent(message)}`)
    }
  }

  logOut = () => {
    const {backendUrl} = this.context.config
    return  backendRequest(url.resolve(backendUrl, '/auth/logout?redirect=false'), undefined)
  }

  render() {
    const state = this.getState()
    const {viewer} = state
    if (viewer.fetching) return <Loading speed={1} style={{padding: '20px'}} />
    if (viewer.error || viewer.isUser === false) {
      deleteFromStorage('viewerProvider')
      return (<Login />)
    }
    return (
      <AppRoutes />
    )
  }
}

export default App
