import {isNil} from 'lodash'

function validateNonempty(obj, key, errors) {
  if ((!obj[key] && key in obj) || isNil(obj[key]) || obj[key].length === 0) {
    errors[key] = 'This field is required.'
  }
}

export function validateShift(shift) {
  const errors = {}
  validateNonempty(shift, 'usersSelected', errors)
  validateNonempty(shift, 'timeSelected', errors)
  validateNonempty(shift, 'fromDate', errors)

  errors._direct_count = Object.keys(errors).length
  return errors
}
