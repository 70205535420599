import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Card} from 'react-bootstrap'
import {depositVendors} from '@bdswiss/common-enums'
import {get} from 'lodash'
import style from './components.module.scss'
import PureComponent from '../PureComponent'

export default class DepositReceiptModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    receipt: PropTypes.object,
    onHide: PropTypes.func.isRequired,
  };

  render() {
    const {receipt, onHide, show} = this.props

    let data = receipt && receipt.payment
    if (data) {
      data = {...data, vendor: get(depositVendors, [data.vendor, 'value'], data.vendor)}
    }

    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Receipt #{receipt && receipt.id}
        </Modal.Header>
        <Modal.Body style={{maxHeight: 'calc(100% - 50px)', overflowY: 'scroll'}}>
          <Card className={style.receiptContent}>
            <Card.Body>
              {JSON.stringify(data, null, 3)}
            </Card.Body>
          </Card>
        </Modal.Body>
      </Modal>
    )
  }

}
