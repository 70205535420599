import React from 'react'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {Row, Col, Card, Container} from 'react-bootstrap'
import {
  usersProvider,
  rolesProvider,
  locationsProvider,
  userProvider,
} from './providers'
import List from './List'
import UserEditor from './UserEditor'
import {events} from '../enums'
import {canQueryUsers} from '@bdswiss/common-permissions'
import {compose, provideProps, mountDataProviders, checkRights} from '../decorators'
import PureComponent from '../PureComponent'
import style from './users.module.scss'
import {checkIfIsOnlyAffiliateManager} from '../common/utils'

class Users extends PureComponent {
  constructor(props) {
    super(props)
    this.notificationSystem = null
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      user: null
    }
  }

  componentDidMount() {
    this.notificationSystem = this.refs.notificationSystem
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)

    const userId = get(this.props, 'match.params.userId')
    if (userId && userId !== 'new') {
      this.onClickUser(userId)
    }
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.usersProvider.fetch()
    this.context.locationsProvider.fetch()
    this.context.rolesProvider.fetch()
  }

  static contextTypes = {
    usersProvider: PropTypes.object.isRequired,
    locationsProvider: PropTypes.object.isRequired,
    rolesProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  showNotificatiom(message, level) {
    this.context.showNotification({
      message,
      level,
      autoDismiss: 5,
    })
  }

  onClickUser(userId) {
    this.props.actions.user.fetchUser(userId).then((res) => {
      this.setState({user: res.user})
    })
  }

  render() {
    const props = this.props

    if (checkIfIsOnlyAffiliateManager(this.props.viewer)) {
      return (
        <div>
          <h3>You do not have permissions for this page.</h3>
        </div>
      )
    }

    return (
      <Container>
        <Row>
          <Col xs={7}>
            <List history={props.history} onClickUser={(userId) => this.onClickUser(userId)} />
          </Col>
          <Col xs={5}>
            {get(props.match, 'params.userId') !== undefined && (this.state.user || get(props, 'match.params.userId') === 'new')
              ? <UserEditor
                notify={(level, message) => this.showNotificatiom(level, message)}
                match={props.match}
                history={props.history}
                locations={props.locations}
                roles={props.roles}
                users={props.users}
                user={get(props, 'match.params.userId') === 'new' ? {} : (this.state.user || {})}
                fetchUser={(userId) => this.onClickUser(userId)}
              />
              : <Card className={style.emptyDetails}><Card.Body>Select user from the list.</Card.Body></Card>
            }
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryUsers),

  provideProps((state) => ({
    users: state.users,
    locations: state.locations,
    roles: state.roles,
  })),

  mountDataProviders({
    userProvider,
    usersProvider,
    locationsProvider,
    rolesProvider,
  }),
)(Users)
