/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import {provideProps, compose} from './'
import {getHocName} from '../useful'


const predispatch = (initialize) => (
  (Component) => class ConnectorComponent extends React.Component {

    static displayName = getHocName('Predispatch', Component)

    static contextTypes = {
      dispatch: PropTypes.func.isRequired,
      getState: PropTypes.func.isRequired,
    };

    UNSAFE_componentWillMount() {
      this.active = false
      initialize(this.props, this.context.dispatch)
      // what if disaptch is not called in initialize? Better dispatch
      // manually to be sure that we rerender the component. I thought of
      // forcing user to dispatch, but that's not so easy; feel free to refactor
      // this, if anyone knows better solution to this
      this.forceUpdate()
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
      this.active = true
    }

    render() {
      if (this.active) {
        return (<Component {...this.props} />)
      } else {
        return (<div> Initializing... </div>)
      }
    }
  }
)

// predispatch should always get at least the whole state to its props,
// such that any change to it will trigger willReveiveProps
export default (initialize) => compose(
  provideProps((state) => ({state})),
  predispatch(initialize)
)

