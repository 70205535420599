import React from 'react'
import PropTypes from 'prop-types'
import {has, get, isNil} from 'lodash'
import {Col, Form, Row, Button, ButtonToolbar, Card} from 'react-bootstrap'
import style from './tags.module.scss'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {validateTag, noTagValidationError} from './validation'

class TagForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    if (isNil(props.tag)) {
      this.state = this.getDefaultState()
    } else {
      const {tag} = props
      this.state = {
        values: {
          id: tag.id,
          tagCategory: tag.tagCategory.id,
          name: tag.name,
        },
        isTouched: false,
        feedback: false,
        errors: {},
      }
    }
  }

  static getDerivedStateFromProps({tag}, prevState) {
    if (tag && (!get(prevState, 'values.id') || tag.id !== get(prevState, 'values.id'))) {
      return {
        ...prevState,
        values: {
          id: tag.id,
          tagCategory: tag.tagCategory.id,
          name: tag.name,
        },
      }
    }
    return prevState
  }

  cancel = () => {
    this.setState(this.getDefaultState())
    this.props.onCancel(false)
  }

  clearValues() {
    this.setState(this.getDefaultState())
  }

  getDefaultState() {
    return {
      values: {
        tagCategory: '',
        name: '',
      },
      isTouched: false,
      feedback: false,
      errors: {},
    }
  }

  getValue(key) {
    if (this.state.values && key in this.state.values) {
      return this.state.values[key]
    } else {
      return null
    }
  }

  generateInputProps(key) {
    const res = {
      value: this.getValue(key) || '',
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.value)
        } else if (e && e.value) {
          this.valueChanged(key, e.value)
        } else if (e) {
          this.valueChanged(key, e)
        } else {
          this.valueChanged(key, '')
        }
      },
    }
    if (this.state.errors && key in this.state.errors) {
      res.isInvalid = true
    }
    return res
  }

  hasErrors(errors) {
    errors = errors || this.state.errors
    return errors._direct_count !== 0
  }

  valueChanged(key, value, feedback = this.state && this.state.feedback) {
    const values = ({...this.state.values, [key]: value})
    this.setState({
      values: values,
      isTouched: true,
      feedback: feedback,
      errors: feedback ? validateTag(values) : noTagValidationError(),
    })
  }

  onValidate(field, validationData) {
    if (this.state.isTouched) {
      const errors = this.state.errors
      errors[field] = validationData
      this.setState({errors})
    }
  }

  trySave(e) {
    e.preventDefault()
    const errors = validateTag(this.state.values)
    if (this.state.isTouched && !this.hasErrors(errors)) {
      this.save()
    } else {
      this.setState({
        isTouched: false,
        feedback: true,
        errors: errors,
      })
    }
  }

  save() {
    const params = {
      id: this.props.tagId,
      ...this.state.values,
    }
    const {id, name, tagCategory} = params
    this.props.actions.tag.upsertTag({
      id: id,
      name: name,
      tagCategory,
    }).then(() => {
      this.props.onSave(false)
    }).catch((e) => {
      this.context.logError(e)
    })
  }

  renderErrorMessage(key) {
    const {errors} = this.state
    if (has(errors, key)) {
      return (
        <p className="help-block"> {get(errors, key)} </p>
      )
    }
  }

  render() {
    const disableSave = !this.state.isTouched
    const {tagCategories} = this.props
    if (!tagCategories) {
      return <div />
    }
    const tagsDropdown = Object.values(tagCategories).map((i) => ({
      key: i.id,
      value: i.name,
      label: i.name,
    }))

    return (
      <Card>
        <Card.Body>
          <Row id="t-tags-form">
            <Col md={12}>
              <form onSubmit={this.trySave.bind(this)} className={style.formStyle}>
                <Row>
                  <Col xs={12} sm={12}>
                    <StylishSelect.Input
                      id="t-tags-form-tagCategory"
                      label="Tag Category"
                      options={StylishSelect.enumToStylishOptions(tagsDropdown)}
                      {...this.generateInputProps('tagCategory')}
                    />
                    {this.renderErrorMessage('tagCategory')}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} sm={12}>
                    <Form.Group>
                      <Form.Label>Tag</Form.Label>
                      <Form.Control
                        id="t-tags-subtags-form-name"
                        type="text"
                        placeholder="Enter tag name"
                        {...this.generateInputProps('name')}
                      />
                      {this.renderErrorMessage('name')}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12}>
                    <ButtonToolbar className="float-right">
                      <Button
                        id="t-add-tag-form-cancel-btn"
                        variant="outline-secondary"
                        className="mr-3"
                        onClick={this.cancel}
                      >
                        Cancel
                      </Button>
                      <Button
                        id="t-add-tag-form-save-btn"
                        variant="success"
                        type="submit"
                        disabled={disableSave}
                      >
                        Save
                      </Button>
                    </ButtonToolbar>
                  </Col>
                </Row>
              </form>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(TagForm)
