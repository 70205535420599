import React from 'react'
import PropTypes from 'prop-types'
import {get, filter, find, map, forEach, some} from 'lodash'
import {Col, Form, Row, Button, Card} from 'react-bootstrap'
import {documentTranslationFields, documentTranslationTypes, documentTypes} from '@bdswiss/common-enums'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import {safeParseJSON} from '../common/utils'
import StylishSelect from '../components/StylishSelect'
class TranslationForm extends PureComponent {
  static contextTypes = {
    showNotification: PropTypes.func.isRequired,
    logError: PropTypes.func.isRequired,
    clientProvider: PropTypes.object,
  }

  static propTypes = {
    document: PropTypes.object.isRequired,
    show: PropTypes.bool,
    onReset: PropTypes.func,
    onSave: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)

    const {document: {id: documentId, translation}} = props
    this.state = {
      values: {
        id: get(translation, 'id'),
        documentId,
        meta: safeParseJSON(get(translation, 'meta')) || {},
        translationType: get(translation, 'translationType'),
      },
      isTouched: false,
      errors: {},
      fields: this.getFields(props.document),
    }
  }

  getFields(document) {
    return filter(documentTranslationFields, (field) => {
      const translationDocumentType = find(documentTranslationTypes, (type) => type.documentTypes.includes(document.type))
      return field.allowedTypes.includes(translationDocumentType.value)
    })
  }

  formValueChanged(key, value) {
    const meta = {...this.state.values.meta, [key]: value}
    this.setState({values: {meta}, isTouched: true}, () => this.hasErrors(key))
  }

  hasErrors(key) {
    const {fields, values} = this.state
    const errors = {}
    if (key) {
      this.setState({
        errors: {
          ...this.state.errors,
          [key]: !values?.meta[key],
        },
      })
    } else {
      forEach(fields, (field) => {
        errors[field.key] = !values?.meta[field.key]
      })
      errors.documentType = !values?.meta?.documentType
      this.setState({errors})
      return some(errors) || !get(values, 'meta.documentType')
    }
  }

  refreshData() {
    if (this.context.clientProvider) {
      this.context.clientProvider.subProviders.documents.fetch()
      this.context.clientProvider.subProviders.activityLogs.fetch()
    }
  }

  save() {
    let paramsId
    const {values} = this.state
    const {viewer, document: {id: documentId, translation}, onSave} = this.props
    if (translation && translation.id) {
      paramsId = translation.id
    }

    if (this.hasErrors()) {
      return
    }

    return onSave({
      id: paramsId,
      documentId: Number(documentId),
      meta: values.meta,
      user: viewer.id,
      translationType: translation.translationType,
      translator: translation.translator.id,
    }).then(() => {
      this.refreshData()
      this.context.showNotification({
        title: 'Document Translation updated',
        message: 'Success',
        position: 'tr',
        level: 'success',
      })
    })
      .catch(this.context.logError)
  }

  renderField(field, uniqueIndex, error) {
    return (
      <Form.Group key={uniqueIndex}>
        <Form.Label>{field.label}</Form.Label>
        <Form.Control
          id="t-document-translation-key"
          type="text"
          defaultValue={this.state.values.meta[field.value]}
          onChange={(e) => this.formValueChanged(field.value, e.target.value)}
          isInvalid={error}
        />
        <Form.Control.Feedback type="invalid">
          Required field
        </Form.Control.Feedback>
      </Form.Group>
    )
  }

  render() {
    const {document} = this.props
    const {fields, errors} = this.state
    const type = get(this.props, 'document.type', '')
    const showFileDescription = type === documentTypes.other.key

    const mappedDocumentTypes = map(documentTypes, (documentType) => {
      if (documentType.key === documentTypes.bankStatementPOR.key) {
        documentType.label = 'Bank Statement (POR)'
      } else if (documentType.key === documentTypes.bankStatementPOF.key) {
        documentType.label = 'Bank Statement (POF)'
      } else if (documentType.key === documentTypes.otherPOF.key) {
        documentType.label = 'Other (POF)'
      }
      return documentType
    })

    return (
      <Card>
        <Card.Body>
          <Row className="mb-3">
            <Col xs={12}>
              Please note that all fields are mandatory. Sufficient information must be filled in; in case
              information is not available, please write 'N/A' in the corresponding field.
            </Col>
          </Row>
          <Row id="t-translations-form">
            <Col xs={12}>
              <StylishSelect.Input
                id="t-client-document-translation-type"
                label="Document Type"
                value={get(this.state, 'values.meta.documentType')}
                onChange={(e) => {
                  this.formValueChanged('documentType', e.value)
                  this.setState({fields: this.getFields(document)})
                }}
                placeholderText="Choose Type"
                options={StylishSelect.enumToStylishOptions(mappedDocumentTypes)}
                isInvalid={errors.documentType}
              />
              {showFileDescription &&
                <Form.Group>
                  <Form.Label>Document Type Description</Form.Label>
                  <Form.Control
                    type="text"
                    id="t-client-document-translation-file-description"
                    title="fileDescription"
                    defaultValue={get(this.state, 'values.meta.fileDescription')}
                    onChange={(e) => this.formValueChanged('fileDescription', e.target.value)}
                  />
                </Form.Group>
              }
            </Col>
            <Col xs={12}>
              {fields.map((field, i) => this.renderField(field, i, errors[field.key]))}
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Button
                id="t-document-translation-save"
                variant="success"
                disabled={!this.state.isTouched}
                onClick={() => this.save()}
                className="float-right"
              >
                Save
              </Button>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(TranslationForm)
