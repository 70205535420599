import {getFetchInterval} from '../useful'

export const permissionsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: () => {
    const query = `{
      permissions {
        id
        name
        description
        roles {
          id
        }
      }
    }`
    return query
  },

  onData: (res, dispatch) =>
    dispatch(
      'Permissions list loaded',
      (state, res) => ({
        ...state,
        permissions: res.permissions
          ? res.permissions.sort((a, b) => a.name.localeCompare(b.name))
          : [],
      }),
      [res]
    ),
}
