import moment from 'moment'
import {find, get, head} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'
import {yesNo, generateKeys} from '@bdswiss/common-enums'

export const showEnum = generateKeys({
  adminBonus: {
    label: 'Admin Bonus',
  },
  deposits: {
    label: 'Deposits',
  },
})

function initAlerts(alerts) {
  for (const alert of alerts) {
    alert.createdAt = moment(alert.createdAt)
    alert.createdAt.isValid() // set _isValid property now
  }
  return alerts
}

export const alertsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {alertsSearch, companyFilter, alertsFilter,
      dateFilters, clientsSearch, statusFilter, countryFilter, topVipFilter, tradingStatusesFilter,
      depositFilters, balanceFilters, clientTypesFilter} = props
    const cpart1 = companyFilter ? `company: ${companyFilter}` : ''
    const cpart2 = alertsFilter ? `types: [${alertsFilter}]` : ''
    const cpart3 = statusFilter ? `status: ${statusFilter}` : ''
    const cpart4 = countryFilter ? `country: ${countryFilter}` : ''
    const cpart5 = topVipFilter ? `topVip: ${get(find(yesNo, {value: topVipFilter}), 'boolValue')}` : ''
    const condition = [cpart1, cpart2, cpart3, cpart4, cpart5,
      clientsSearch ? `search: ${JSON.stringify(clientsSearch)}` : '',
      dateFilters && dateFilters.createdFrom ? `creationTimeFrom: "${dateFilters.createdFrom}"` : '',
      dateFilters && dateFilters.createdTo ? `creationTimeTo: "${dateFilters.createdTo}"` : '',
      dateFilters && dateFilters.lastLogin ? `lastLogin: "${dateFilters.lastLogin}"` : '',
      dateFilters && dateFilters.postponeUntil ? `postponeUntil: "${dateFilters.postponeUntil}"` : '',
      depositFilters && depositFilters.depositFrom ? `depositFrom: ${depositFilters.depositFrom}` : '',
      depositFilters && depositFilters.depositTo ? `depositTo: ${depositFilters.depositTo}` : '',
      balanceFilters && balanceFilters.balanceFrom ? `balanceFrom: ${balanceFilters.balanceFrom}` : '',
      balanceFilters && balanceFilters.balanceTo ? `balanceTo: ${balanceFilters.balanceTo}` : '',
      head(tradingStatusesFilter) ? `tradingStatuses: [${tradingStatusesFilter}]` : '',
      head(clientTypesFilter) ? `clientTypes: [${clientTypesFilter}]` : '',
    ].join('\n').trim()

    const {alertsSort} = props
    const query = `{
          alerts(
            ${condition.length ? condition : ''}
            ${alertsSearch ? `search: "${alertsSearch}"` : ''}
            limit: ${getPageSize()}
            offset: ${getOffset(props.alertsPage)}
            ${alertsSort.orderBy ? `orderBy: ${alertsSort.orderBy}` : ''}
            ${alertsSort.orderDirection ? `orderDirection: ${alertsSort.orderDirection}` : ''}
          ) {
            id
            createdAt
            type
            status
            postponeUntil
            resolvedBy {
              id
              firstName
              lastName
            }
            client {
              id
              firstName
              lastName
              email
              lastLogin
              topVip
              aggregatedBalanceEur
              depositedAmount
              depositedAmountCurrency
              registrationCampaigns {
                affiliateUserName
              }
            }
          }
          alertsCount(estimated: false ${condition})
      }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Alerts loaded',
    (state, res) => ({
      ...state,
      alerts: res.alerts != null
        ? initAlerts(res.alerts)
        : state.alerts || [],
      alertsCount: res.alertsCount != null
        ? res.alertsCount
        : state.alertsCount || 0,
    }),
    [res]
  ),

}
