import React from 'react'
import {Card, Col, Container, Pagination, Row, Table} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import moment from 'moment'
import {getPageCount, getPageRange} from '../useful'
import {isEmpty} from 'lodash'
import runtimeConfig from '../config'
import {backendRequest} from '../utils/net'

const {backendUrl} = runtimeConfig.getInstance()
class FetcherService extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fetcherServiceID: '',
      fetcherService: [],
      fetcherServiceCount: 0,
      fetcherServicePage: 1,
    }
  }

  getData() {
    const params = null
    const requestPath = `${backendUrl}/api/v1/reports/fetcher-service`
    const HTTPMethod = 'GET'
    return backendRequest(requestPath, params, HTTPMethod)
      .then((res) => res.text())
      .then((text) => {
        const lines = text.trim().split('\n')
        const keys = lines[0].split(',').map(key => key.trim().replace(/"/g, ''))
        return lines.slice(1).map(line => {
          const values = line.split(',').map(value => value.trim().replace(/"/g, ''))
          return keys.reduce((obj, key, index) => {
            obj[key] = key === 'timestamp' ? parseInt(values[index], 10) : values[index]
            return obj
          }, {})
        }).filter(item => Object.keys(item).length > 0) // Filter out any empty lines
      })
      .catch((error) => error)
  }

  componentDidMount() {
    this.getData()
      .then((data) => {
        this.setState({
          fetcherService: data,
          fetcherServiceCount: data.length,
        })
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error('Error fetching data:', error)
      })
  }



  handleChange(input) {
    const {value} = input.target
    this.setState({fetcherServiceID: value})
  }

  render() {
    const {fetcherService, fetcherServiceCount, fetcherServicePage} = this.state
    return (
      <Container>
        <h3>Fetcher Service Monitor</h3>
        <Row className="mb-3">
          <Col xs={4}>
            <Table bordered size="sm">
              <thead>
                <tr>
                  <th colSpan="2" className="text-center">Legend</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{backgroundColor: 'red'}}></td>
                  <td>More than 1440 minutes (1 day) elapsed</td>
                </tr>
                <tr>
                  <td style={{backgroundColor: 'orange'}}></td>
                  <td>More than 60 minutes (1 hour) elapsed</td>
                </tr>
                <tr>
                  <td></td>
                  <td>No highlight: Less than 60 minutes (1 hour) elapsed</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>

                <Table bordered hover>
                  <thead>
                    <tr>
                      <th>
                        <span>Key</span>
                      </th>
                      <th>
                        <span>Description</span>
                      </th>
                      <th>
                        <span>Last Update (UTC)</span>
                      </th>
                      <th>
                        <span>Last Update (Locale)</span>
                      </th>
                      <th>
                        <span>Minutes since last update</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      !isEmpty(fetcherService) && fetcherService.map((service, i) => {
                        const {fetcherKeyName, timestamp, description} = service
                        const timeDifferenceMinutes = moment().diff(moment.unix(timestamp), 'minutes')

                        let rowStyle = {}
                        if (timeDifferenceMinutes > 1440) { // more than a day
                          rowStyle = {backgroundColor: 'red'}
                        } else if (timeDifferenceMinutes > 60) { // more than an hour
                          rowStyle = {backgroundColor: 'orange'}
                        }

                        return (
                          <tr
                            key={i}
                            id={`t-fetcher-service-id-${i}`}
                            style={rowStyle}
                          >
                            <td>{fetcherKeyName}</td>
                            <td>{description}</td>
                            <td>{moment.unix(timestamp).utc().format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{moment.unix(timestamp).local().format('YYYY-MM-DD HH:mm:ss')}</td>
                            <td>{timeDifferenceMinutes}</td>
                          </tr>
                        )
                      })
                    }

                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                ...state,
                fetcherServicePage: selectedEvent.eventKey
              }))}
            >
              {getPageRange(fetcherServicePage, getPageCount(fetcherServiceCount)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      fetcherServicePage: fetcherServicePage - 1
                    }))}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                      ...state,
                      fetcherServicePage: fetcherServicePage + 1
                    }))}
                  />
                }

                return <Pagination.Item
                  active={page === fetcherServicePage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.uiDispatch('Show page', (state) => ({
                    ...state,
                    fetcherServicePage: page
                  }))}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default FetcherService
