/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {map} from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {orderDirections, blacklistTypes, countries} from '@bdswiss/common-enums'
import {Row, Col, Table, Button, Card, Pagination, Container} from 'react-bootstrap'
import {events} from '../enums'
import style from './blacklists.module.scss'
import PureComponent from '../PureComponent'
import {blacklistsProvider} from './providers'
import StylishSelect from '../components/StylishSelect'
import DeleteBlacklistModal from './DeleteBlacklistModal'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {readableDate, getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class Blacklists extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      ...this.state,
      showDeleteModal: false,
      deleteId: null,
    }
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.blacklistsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    blacklistsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  linkToBlacklist(blacklist, content) {
    return (
      <LinkContainer to={`/blacklists/${blacklist.id}`}>
        <a>{content}</a>
      </LinkContainer>
    )
  }

  countryLabel(blacklist, content) {
    return blacklist.type === 'country'
      ? this.linkToBlacklist(blacklist, countries[content].label)
      : this.linkToBlacklist(blacklist, content)
  }

  activateTypesFilter = (filter) => {
    this.props.uiDispatch(
      'Filter blacklist types',
      (state) => ({...state, blacklistTypesFilter: map(filter, (v) => v.value), blacklistsPage: 1})
    )
  }

  handleDeleteClick(deleteId) {
    this.setState({showDeleteModal: true, deleteId})
  }

  confirmDelete(confirm) {
    this.setState({showDeleteModal: false})
    if (confirm) {
      const {actions} = this.props
      actions.blacklist.deleteBlacklist(this.state.deleteId)
        .then((res) => {
          this.context.showNotification({
            title: 'Delete blacklist',
            message: 'Blacklist successfully deleted.',
            position: 'tr',
            level: 'success',
          })
          this.fetchProvider()
        })
        .catch((error) => {
          this.context.showNotification({
            title: 'Delete blacklist',
            message: error.message,
            position: 'tr',
            level: 'error',
          })
        })
    }
    this.setState({deleteId: null})
  }

  renderBlacklist(blacklist) {
    const {id, type, value, createdAt, expiresAt, reason} = blacklist
    const created = moment(createdAt)
    const expires = moment(expiresAt)

    return (
      <tr className="t-blacklist" key={id}>
        <td>
          {this.linkToBlacklist(
            blacklist,
            id)}
        </td>
        <td>
          {this.linkToBlacklist(
            blacklist,
            type)}
        </td>
        <td>
          {this.countryLabel(
            blacklist,
            value)}
        </td>
        <td>
          {this.linkToBlacklist(
            blacklist,
            reason)}
        </td>
        <td>
          {this.linkToBlacklist(
            blacklist,
            readableDate(created))}
        </td>
        <td title={expiresAt ? readableDate(expires) : ''}>
          {this.linkToBlacklist(
            blacklist,
            expiresAt ? expires.fromNow() : '')}
        </td>
        <td style={{padding: '8px'}}>
          <Button variant="danger" className="btn-xs" onClick={() => this.handleDeleteClick(blacklist.id)}>
            <FontAwesomeIcon icon="remove" /> Delete
          </Button>
        </td>
      </tr>
    )
  }

  renderFilters() {
    const {blacklistTypesFilter} = this.props
    return (
      <Row>
        <Col xs={2}>
          <StylishSelect
            id="t-blacklists-types-filter"
            placeholderText="All Types"
            value={blacklistTypesFilter}
            options={StylishSelect.enumToStylishOptions(blacklistTypes)}
            highlightIfActive
            multi
            clearable
            onChange={this.activateTypesFilter}
          />
        </Col>
        <Col xs={10}>
          <Button
            id="t-blacklists-new-blacklist-button"
            className="float-right"
            variant="success"
            onClick={() => this.context.router.push('/blacklists/create')}
          >
            New Blacklist
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {blacklists, blacklistsCount, blacklistsPage} = this.props
    return (
      <Container>
        <div className={style.toolbar}>
          {this.renderFilters()}
        </div>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Type</th>
                      <th>Value</th>
                      <th>Reason</th>
                      <th>Created</th>
                      <th>Expires</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {blacklists.map((blacklist) => this.renderBlacklist(blacklist))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, blacklistsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(blacklistsPage, getPageCount(blacklistsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, blacklistsPage: blacklistsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, blacklistsPage: blacklistsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === blacklistsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, blacklistsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
        <DeleteBlacklistModal
          show={this.state.showDeleteModal}
          onCancel={() => this.confirmDelete(false)}
          onConfirm={() => this.confirmDelete(true)}
        />
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'blacklists']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/blacklists',
      (state) => {
        let blacklistsSort
        if (!blacklistsSort || blacklistsSort.orderBy == null
            || blacklistsSort.orderDirection == null || !(blacklistsSort.orderDirection in orderDirections)) {
          blacklistsSort = defaultSort
        }

        return ({...state, blacklistsSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {blacklists, blacklistsCount} = state
    const {blacklistsPage, blacklistsSort, blacklistTypesFilter} = uiState

    return ({
      blacklists,
      blacklistsCount,
      blacklistsPage: blacklistsPage || 1,
      blacklistsSort,
      blacklistTypesFilter,
    })
  }),

  mountDataProviders({blacklistsProvider}),
)(Blacklists)
