import React from 'react'
import {get} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {partnersAppendices} from '@bdswiss/common-enums'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

export default class ApendixPreviewModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  }

  render() {
    const {show, onCancel, appendix, appendix: {template}} = this.props
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Appendix {template && partnersAppendices[template].label} Preview</strong>
        </Modal.Header>
        <Modal.Body>
          {appendix && <div dangerouslySetInnerHTML={{__html: get(appendix, 'content', '')}} />}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button variant="info" size="sm" onClick={onCancel}>
              <FontAwesomeIcon icon="remove" /> Close Preview
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
