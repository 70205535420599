export default function create(dbClient) {
  return {
    upsertSeminar(data) {
      const variables = {
        id: data.id,
        seminarCode: data.seminarCode,
        seminarName: data.seminarName,
        location:  data.location,
        city:  data.city,
        country:  data.country,
        seminarDate:  data.seminarDate,
        company: data.company,
        seminarLink: data.seminarLink,
        ibSeminar:  data.ibSeminar,
      }

      const query = `
        mutation (
          $id: Int,
          $seminarCode: String!,
          $seminarName: String!,
          $seminarDate: UnsetOrDateTime!,
          $location: String!,
          $city: String!,
          $country: Country!,
          $company: Company,
          $seminarLink: String!,
          $ibSeminar: Boolean,
        ) {
          upsertSeminar(
            id: $id
            seminarCode: $seminarCode
            seminarName: $seminarName
            seminarDate: $seminarDate
            location: $location
            city: $city
            country: $country
            company: $company
            seminarLink: $seminarLink
            ibSeminar: $ibSeminar
          ) {
            id
          }
        }
      `
      return dbClient.query(query, variables)
    },
    signTrackEventFileUrl(data) {
      const variables = {
        seminarCode: data.seminarCode,
      }
      const query = `
      mutation ($seminarCode: String!){
        signTrackEventFileUrl(
          seminarCode: $seminarCode
        ) {
          csvKey
          csvPlainUrl
          csvSignedUrl
        }
      }
      `
      return dbClient.query(query, variables)
    },
    updateSeminarAttendance(data) {
      const variables = {
        subCampId: data.subCampId,
        csvLink: data.csvLink,
      }
      const query = `
        mutation (
          $subCampId: String!,
          $csvLink: String!
        ) {
          updateSeminarAttendance (
            subCampId: $subCampId
            csvLink: $csvLink
          ) {
            updated,
            unsuccessful
            logs
          }
        }
      `
      return dbClient.query(query, variables)
    },
  }
}
