import React from 'react'
import {isEmpty, map} from 'lodash'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar, Row, Col, Form, Card, Table} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import {putFile} from '../utils/net'
import style from './seminars.module.scss'
import classnames from 'classnames'

export default class AttendanceModal extends PureComponent {

  static propTypes = {
    show: PropTypes.bool,
    onCancel: PropTypes.func.isRequired,
  }

  static contextTypes = {
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({
      file: null,
      createButtonDisabled: true,
    })
  }

  inputCSVFile(files) {
    this.setState({file: files[0], createButtonDisabled: false})
  }

  close = () => {
    this.setState({file: null, updateSeminarAttendance: null})
    this.props.onCancel()
  }
  disableUploadButton() {
    this.setState({createButtonDisabled: true})
  }

  submit() {
    const {seminar: {seminarCode}} = this.props
    this.setState({createButtonDisabled: true})
    const args = {
      seminarCode
    }

    return this.props.signTrackEventFileUrl(args)
      .then((res) => {
        const {csvSignedUrl, csvPlainUrl} = res.signTrackEventFileUrl
        return putFile(this.state.file, csvSignedUrl)
          .then(() => {
            const args = {
              subCampId: seminarCode,
              csvLink: csvPlainUrl,
            }
            this.props.updateSeminarAttendance(args)
              .then((res) => {
                this.setState({updateSeminarAttendance: res.updateSeminarAttendance})
                if (res.updateSeminarAttendance.updated > 0) {
                  this.context.showNotification({
                    title: `Updated seminar ${seminarCode} attendance`,
                    message: 'Attendance list completed.',
                    position: 'tr',
                    level: 'success',
                  })
                } else {
                  this.context.logError({
                    isShowActualError: true,
                    message: `Some members were not saved. You can see their emails in "Failed Members".
                      Please remove the "Updated Members" from your csv file and try again.`
                  })
                }
                return this.disableUploadButton()
              })
              .catch(this.context.logError)
          })
      })
  }

  render() {
    const {show, onCancel, seminar} = this.props
    const {updateSeminarAttendance, createButtonDisabled} = this.state
    const {seminarCode, seminarName} = seminar
    return (
      <Modal
        keyboard
        show={show}
        onHide={onCancel}
        className={style.modal}
      >
        <Modal.Header>
          <strong>Seminar Attendance for {seminar && seminarCode}</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <strong>Upload the attendance list for {seminarName}</strong>
              <p>*csv file with 6 columns email, firstName, lastName, country, phone, company.</p>
              <p>*For just attendance update only email needed.</p>
              e.g.
              <Table bordered className={classnames([style.table, style.seminarsExample])}>
                <thead>
                  <tr><th>email</th><th>firstName</th><th>lastName</th><th>country</th><th>phone</th><th>company</th></tr>
                </thead>
                <tbody className={style.textCenter}>
                  <tr><td>jsmith@gmail.com</td><td>John</td><td>Smith</td><td>CY</td><td>99123456</td><td>companyName</td></tr>
                  <tr><td>b.dewit@test.com</td><td></td><td></td><td></td><td></td><td></td></tr>
                </tbody>
              </Table>

            </Col>
          </Row>
          <Row>
            <Col xs={6} style={{marginTop: '10px'}}>
              <Form.Control
                type="file"
                className={style.csvInput}
                onChange={(e) => this.inputCSVFile(e.target.files)}
                accept=".csv"
              />
            </Col>
          </Row>
          {!isEmpty(updateSeminarAttendance) && <Row>
            <Col xs={12}>
              <Card>
                <Card.Body>
                  <p>Updated Members: {updateSeminarAttendance.updated}</p>
                  <div>Failed Members: {isEmpty(updateSeminarAttendance.unsuccessful) ? 0
                    : map(updateSeminarAttendance.unsuccessful, (u, key) => <p> <b>{key + 1})</b> {isNaN(u) ? u : `Row ${parseInt(u, 10) + 1} has an error`} </p>)}</div>
                  {!isEmpty(updateSeminarAttendance.unsuccessful) &&
                    <div>Logs: {map(updateSeminarAttendance.logs, (u, key) => <p> <b>{key + 1})</b> {u} </p>)}</div>}
                </Card.Body>
              </Card>
            </Col>
          </Row>}
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              variant="success"
              disabled={createButtonDisabled}
              onClick={() => this.submit()}
              className="mr-3"
            >
              Upload
            </Button>
            <Button variant="outline-secondary" onClick={this.close}>
              Close
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}
