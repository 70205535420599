import React from 'react'
import PropTypes from 'prop-types'
import {Col, Row, Card, Button, Container} from 'react-bootstrap'
import PureComponent from '../PureComponent'
import TranslationForm from './TranslationForm'
import {translationProvider} from './providers'
import {compose, provideProps, mountDataProviders} from '../decorators'

class DocumentTranslationEdit extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  render() {
    const {translation} = this.props
    if (!translation) {
      return (<Container><Card><Card.Body><h2>Document Translation does not exist</h2></Card.Body></Card></Container>)
    }
    const document = {...translation.document, translation}
    return (
      <Container>
        <Card>
          <Card.Body>
            <Row>
              <Col xs={6} style={{textAlign: 'center'}}>
                <p style={{textAlign: 'center'}}><strong>{'Document Preview'}</strong></p>
                <a href={translation.document.url} target="_blank" rel='noreferrer noopener'>
                  <img alt={translation.document.url} src={translation.document.url} style={{maxWidth: '80%', textAlign: 'center'}} />
                </a>
                <p style={{textAlign: 'center', padding: 4}}>
                  <Button variant="outline-secondary" href={translation.document.url} target="_blank" size="sm">Original File</Button>
                </p>
              </Col>
              <Col xs={6}>
                <p style={{textAlign: 'center'}}><strong>{'Document Translation Form'}</strong></p>
                <TranslationForm
                  document={document}
                  onSave={this.props.actions.client.upsertDocumentTranslation}
                />
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    )
  }
}

export default compose(
  provideProps((state) => ({
    translation: state.translation,
  })),

  mountDataProviders({translationProvider}),
)(DocumentTranslationEdit)
