import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {debounce, map} from 'lodash'
import {compose, mountDataProviders, provideProps, uiMount} from '../../decorators'
import StylishSelect from '../../components/StylishSelect'
import {affiliateAccountsProvider} from './providers'

class AffiliateSelectUI extends Component {
  static contextTypes = {
    affiliateAccountsProvider: PropTypes.object.isRequired,
  }

  constructor(props) {
    super(props)
    this.doSearch = debounce(this.doSearch, 1500)
  }

  UNSAFE_componentWillMount() {
    this.setState({
      selectedValue: '',
    })
  }

  doSearch(searchText) {
    this.setState({
      selectedValue: '',
    })

    this.props.uiDispatch(
      'Search affiliates',
      (state) => ({...state, affiliateSearch: searchText}),
      [searchText]
    )
  }

  handleChange(selectedObject) {
    this.props.uiDispatch(`setting affiliate ${selectedObject.value}`, (state) => ({...state, selectedAffiliate: selectedObject.value}))
  }

  render() {
    const {affiliateList, selectedAffiliate} = this.props

    return (
      <StylishSelect
        id="t-affiliate"
        placeholder="Please input"
        options={affiliateList}
        value={selectedAffiliate}
        onInputChange={this.doSearch.bind(this)}
        onChange={this.handleChange.bind(this)}
      />
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'affiliateAssign']),
  provideProps((state, uiState) => {
    const {affiliateList} = state
    const {affiliateSearch, selectedAffiliate} = uiState

    return {
      selectedAffiliate,
      affiliateSearch,
      affiliateList: map(affiliateList, (a) => ({
        value: a.remoteId,
        label: `${a.remoteId} ${a.client?.firstName} ${a.client?.lastName}`,
      })),
    }
  }),
  mountDataProviders({affiliateAccountsProvider})
)(AffiliateSelectUI)
