import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Table, Button, ButtonToolbar, Row, Col, Pagination, Form, Badge} from 'react-bootstrap'
import {List as Loading} from 'react-content-loader'
import {includes, reject, get, capitalize, find, map, filter, isNil, forEach, orderBy, cloneDeep, pickBy, pick} from 'lodash'
import DisabledTooltipButton from '../components/DisabledTooltipButton'
import {accountTypes, depositStatuses, depositVendors, bonusOfferTypes,
  bonusOfferStatuses, transferTypes, depositRejectionReasons, fundingCategories, jmFinancialPaymentTypes, currencies} from '@bdswiss/common-enums'
import {canCreateDeposits, canReleaseDeposit, canCompleteDeposit,
  canChangeAttemptedToIncomplete, canQueryAffiliateManagerClients} from '@bdswiss/common-permissions'
import {getAccountLabel} from '../utils/general'
import {getFormattedAmount, readableDate, getPageCount, getVendorPaymentType, getVendorCardDetails, getPageRange} from '../useful'
import DepositReceiptModal from './../components/DepositReceiptModal'
import {provideProps, uiMount, compose} from '../decorators'
import {createClientDepositsUrl, createClientNewDepositUrl, createClientBonusOfferUrl} from '../utils/links'
import {getDepositStatusLabel} from '../utils/rendering'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import ConfirmationModal from './../components/ConfirmationModal'
import ReasonModal from '../components/ReasonModal'
import {isCentAccount, safeParseJSON} from '../common/utils'
import StatusFilter from './../components/StatusFilter'
import TransactionTypeFilter from './../components/TransactionTypeFilter'
import RevertModal from '../components/RevertModal'
import DepositCompleteModal from '../components/DepositCompleteModal'
import StylishSelect from '../components/StylishSelect'
import DateTime from '../components/DateTime'
import GeneralModal from '../components/GeneralModal'

const filterableDepositStatuses = {
  pending: depositStatuses.pending,
  completed: depositStatuses.completed,
  failed: depositStatuses.failed,
  chargeback: depositStatuses.chargeback,
  held: depositStatuses.held,
  incomplete: depositStatuses.incomplete,
  rejected: depositStatuses.rejected,
  reversed: depositStatuses.reversed,
  partiallyReversed: depositStatuses.partiallyReversed,
  awaitingPayment: depositStatuses.awaitingPayment,
}

const filterableTransactionTypes = {
  nonTransfers: transferTypes.nonTransfers,
  transfers: transferTypes.transfers,
  [depositVendors.copyTradingPerformanceFee.key]: {
    key: depositVendors.copyTradingPerformanceFee.key,
    value: depositVendors.copyTradingPerformanceFee.value,
    label: depositVendors.copyTradingPerformanceFee.label,
  },
  nonCommissions: {
    key: 'nonCommissions',
    value: 'nonCommissions',
    label: 'Non Commissions',
  },
  commissions: {
    key: 'commissions',
    value: 'commissions',
    label: 'Commissions',
  },
}

class Deposits extends PureComponent {

  static propTypes = {
    client: PropTypes.object.isRequired,
    activeDepositId: PropTypes.number,
    activeBonusOfferId: PropTypes.number,
    switchSidebar: PropTypes.func.isRequired,
    filterDispatch: PropTypes.func.isRequired,
    filterState: PropTypes.object.isRequired,
  };

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    dbClient: PropTypes.object.isRequired,
  };

  state = {
    showClientPaymentOptions: false,
    clientPaymentOptions: [],
    loadingClientPaymentOptions: false,
  }

  componentWillMount() {
    const notVisibleStatuses = [
      depositStatuses.pending.key,
      depositStatuses.rejected.key,
    ]
    this.setState({
      statusFilter: reject(map(filterableDepositStatuses, 'key'), (s) => notVisibleStatuses.includes(s)),
    })
  }

  componentWillReceiveProps(newProps) {
    const {isActive, filterState: {depositsStatusFilter, depositsTypeFilter}} = newProps
    const {dataFetched, dataError, dataLoading} = this.state
    if (isActive && !dataLoading && !dataError && !dataFetched) {
      this.setState({dataLoading: true}, () => {
        Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.bonusOffers.fetch(),
        ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    } else if (isActive && !dataLoading && !dataError
      && (!dataFetched || (this.props.filterState.depositsPage !== newProps.filterState.depositsPage) ||
        (this.props.filterState.depositsStatusFilter !== depositsStatusFilter) ||
        (this.props.filterState.depositsTypeFilter !== depositsTypeFilter))
    ) {
      this.setState({dataLoading: true}, () => {
        this.context.clientProvider.subProviders.deposits.fetch()
          .then(() => this.setState({dataLoading: false, dataFetched: true}))
          .catch((e) => this.setState({dataLoading: false, dataError: e}))
      })
    }
  }

  newDepositSidebar() {
    this.context.router.push(createClientNewDepositUrl(this.props.client.generalInfo.id))
  }

  showDepositSidebar(deposit) {
    this.props.fetchActivityLogs(deposit.id)
    const clientId = this.props.client.generalInfo.id
    let url
    if (deposit.type) {
      url = createClientBonusOfferUrl(clientId, deposit.id)
    } else {
      url = createClientDepositsUrl(clientId, deposit.id)
    }
    this.context.router.push(url)
  }

  openAcceptanceWindow(deposit) {
    this.props.uiDispatch('Open acceptance modal', (state) => ({
      ...state,
      showAcceptanceModal: true,
      acceptingDeposit: deposit,
    }))
  }

  closeAcceptanceWindow() {
    this.props.uiDispatch('Close acceptance modal', (state) => ({
      ...state,
      showAcceptanceModal: false,
      acceptingDeposit: undefined,
    }))
  }

  openRevertModal = (deposit) => {
    this.props.uiDispatch('Open revert modal', (state) => ({
      ...state,
      showRevertModal: true,
      revertingDeposit: deposit,
    }))
  }

  closeRevertModal = () => {
    this.props.uiDispatch('Close revert modal', (state) => ({
      ...state,
      showRevertModal: false,
      revertingDeposit: undefined,
    }))
  }

  closeRefundModal = () => {
    this.props.uiDispatch('Close refund modal', (state) => ({
      ...state,
      showRefundModal: false,
      refundingDeposit: undefined,
    }))
  }

  revertDeposit(deposit, amount) {
    this.setState({disableCompleteAction: true}, () => {
      this.props.actions.client.revertDeposit(deposit.id, amount)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.withdrawals.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableCompleteAction: false})
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableCompleteAction: false})
        })
    })
  }

  refundDeposit(deposit) {
    this.setState({disableCompleteAction: true}, () => {
      this.props.actions.client.refundDeposit(deposit.id)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableCompleteAction: false})
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableCompleteAction: false})
        })
    })
  }

  openRejectionReasonWindow(deposit) {
    this.props.uiDispatch('Open rejection reason', (state) => ({
      ...state,
      showRejectionReasonModal: true,
      rejectingDeposit: deposit,
    }))
  }

  closeRejectionReasonWindow() {
    this.props.uiDispatch('Close rejection reason', (state) => ({
      ...state,
      showRejectionReasonModal: false,
      rejectingDeposit: undefined,
    }))
  }

  optimisticSetPendingCount(delta) {
    const {client: {personalDetails: {id: clientId, heldIncompleDepositsCount}}} = this.props
    let newPendingCount = heldIncompleDepositsCount + delta
    newPendingCount = newPendingCount < 0 ? 0 : newPendingCount
    this.props.dispatch('Optimistic Update Pending Deposit Count', (state) => ({
      ...state,
      client: {
        ...state.client,
        [clientId]: {
          ...state.client[clientId],
          personalDetails: {
            ...state.client[clientId].personalDetails,
            heldIncompleDepositsCount: newPendingCount,
          }
        }
      }
    }))
  }

  releaseDeposit(deposit) {
    this.setState({disableReleaseAction: true}, () => {
      this.props.actions.client.releaseDeposit(deposit)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableReleaseAction: false})
          this.optimisticSetPendingCount(-1)
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableReleaseAction: false})
        })
    })
  }

  rejectDeposit(deposit, reason, selectedReasonCode) {
    this.setState({disableRejectAction: true}, () => {
      this.props.actions.client.rejectDeposit(deposit, reason, selectedReasonCode)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableRejectAction: false})
          this.optimisticSetPendingCount(-1)
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableRejectAction: false})
        })
    })
  }

  completeDeposit(deposit, amount, bank, dateReceived) {
    this.setState({disableCompleteAction: true}, () => {
      this.props.actions.client.completeDeposit(deposit, amount, bank, dateReceived)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.accounts.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableCompleteAction: false})
          this.optimisticSetPendingCount(-1)
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableCompleteAction: false})
        })
    })
  }

  changeToIncomplete(deposit) {
    this.setState({disableCompleteAction: true}, () => {
      this.props.actions.client.attemptedToIncompleteDeposit(deposit)
        .then(() => Promise.all([
          this.context.clientProvider.subProviders.deposits.fetch(),
          this.context.clientProvider.subProviders.activityLogs.fetch(),
        ]).then(() => {
          this.setState({disableCompleteAction: false})
          this.optimisticSetPendingCount(1)
        }))
        .catch((e) => {
          this.context.logError(e)
          this.setState({disableCompleteAction: false})
        })
    })
  }

  activateStatusFilter(value) {
    const oldValue = this.props.filterState.depositsStatusFilter
    let depositsStatusFilter
    if (includes(oldValue, value)) {
      depositsStatusFilter = reject(oldValue, (v) => v === value)
    } else {
      depositsStatusFilter = [...oldValue, value]
    }
    this.props.filterDispatch(
      'Filter client deposits by status',
      (state, arg) => ({...state, depositsStatusFilter, depositsPage: 1})
    )
  }

  activateDepositTypeFilter(value) {
    const oldValue = this.props.filterState.depositsTypeFilter
    let depositsTypeFilter
    if (includes(oldValue, value)) {
      depositsTypeFilter = reject(oldValue, (v) => v === value)
    } else {
      depositsTypeFilter = [...oldValue, value]
    }
    this.props.filterDispatch(
      'Filter deposits by type',
      (state, arg) => ({
        ...state,
        depositsVendorFilter: this.convertDepositTypeFilterToVendorFilter(depositsTypeFilter),
        depositsTypeFilter,
        depositsPage: 1
      })
    )
  }

  activateDepositVendorsFilter(selections) {
    const values = map(selections, 'value')
    this.props.filterDispatch(
      'Filter alerts by deposit vendors',
      (state, arg) => ({...state, depositsVendorFilter2: values, depositsPage: 1}),
    )
  }

  activateHasPartialRefundsFilter(value) {
    this.props.filterDispatch(
      'Filter client deposits by has partial refunds',
      (state, arg) => ({...state, hasPartialRefundsFilter: value, depositsPage: 1})
    )
  }

  convertDepositTypeFilterToVendorFilter(depositsTypeFilter) {
    const commissionDepositVendors = [
      depositVendors.depositCommisions.key,
      depositVendors.transferDepositCommisions.key,
    ]
    const transfers = map(filter(depositVendors, 'isTransfer'), 'key')
    const nonTransfers = map(filter(depositVendors, (o) =>
      !o.isTransfer && o.key !== depositVendors.copyTradingPerformanceFee.key), 'key')
    const performanceFee = depositVendors.copyTradingPerformanceFee.key
    const commissions = map(filter(depositVendors, (o) => commissionDepositVendors.includes(o.key)), 'key')
    const nonCommissions = map(reject(depositVendors, (o) => commissionDepositVendors.includes(o.key)), 'key')
    return [
      ...includes(depositsTypeFilter, transferTypes.nonTransfers.key) ? nonTransfers : [],
      ...includes(depositsTypeFilter, transferTypes.transfers.key) ? transfers : [],
      ...(includes(depositsTypeFilter, depositVendors.copyTradingPerformanceFee.key) ? [performanceFee] : []),
      ...includes(depositsTypeFilter, 'nonCommissions') ? nonCommissions : [],
      ...includes(depositsTypeFilter, 'commissions') ? commissions : [],
    ]
  }

  showClientPaymentOptions = async (client) => {
    this.setState({showClientPaymentOptions: true, loadingClientPaymentOptions: true})
    try {
      const data = await this.props.dbClient.query(`{
        countryPaymentOptions(clientId: ${client.generalInfo.id}) {
          rank
          paymentOption {
            provider
            name
          }
        }
      }`, {})
      const paymentOptions = filter(
        orderBy(data.countryPaymentOptions, 'rank', 'desc'),
        ({paymentOption: {provider}}) => provider !== 'bankwire'
      )
      const clientPaymentOptions = map(paymentOptions, ({paymentOption: {name}}) => name)
      this.setState({clientPaymentOptions, loadingClientPaymentOptions: false})
    } catch (error) {
      this.context.logError(error)
    }
  }

  renderIncompleteButton(deposit) {
    return deposit.status === depositStatuses.pending.key &&
      <Button
        id="t-client-deposit-incomplete-button"
        className="btn-xs"
        variant="warning"
        onClick={((e) => {
          this.changeToIncomplete(deposit)
          e.stopPropagation()
        })}
      > Incomplete
      </Button>
  }

  verifySkrillAccount(deposit) {
    this.props.actions.client.verifySkrillAccount(null, deposit)
      .then((res) => {
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
      })
  }

  renderReceiptButton(deposit) {
    return (
      <Button
        className="btn-xs"
        variant="outline-secondary"
        onClick={(e) => {
          const payment = {}
          payment.receipt = deposit.receipt && JSON.parse(deposit.receipt)
          payment.productReceipt = deposit.productReceipt && JSON.parse(deposit.productReceipt)
          payment.meta = deposit.meta && JSON.parse(deposit.meta)
          payment.transferFromAccount = safeParseJSON(deposit.transferAccount)
          payment.paymentFields = safeParseJSON(deposit.depositPaymentFields)
          this.setState({showReceipt: {id: deposit.id, payment}})
          e.stopPropagation()
        }}
      >
        Receipt
      </Button>
    )
  }

  renderRejectButton(deposit) {
    const invalidStatus = deposit.status !== depositStatuses.held.key
      && deposit.status !== depositStatuses.incomplete.key
      && deposit.status !== depositStatuses.awaitingPayment.key
    const invalidPermisions = !canCompleteDeposit(this.props.viewer)
    if (invalidStatus || invalidPermisions) {
      return null
    }
    const {disableRejectAction} = this.state
    return (
      <Button
        className="btn-xs"
        variant="danger"
        onClick={(e) => {
          this.openRejectionReasonWindow(deposit)
          e.stopPropagation()
        }
        }
        disabled={disableRejectAction}
      >
        Reject
      </Button>
    )
  }

  renderReleaseButton(deposit) {
    const {disableReleaseAction} = this.state
    if (deposit.status !== depositStatuses.held.key || !canReleaseDeposit(this.props.viewer)) {
      return null
    }
    const noActiveId = this.props.activeDepositId == null && this.props.activeBonusOfferId == null

    return (
      <Button
        id="t-client-deposit-release-button"
        className="btn-xs"
        variant="success"
        onClick={(e) => {
          this.releaseDeposit(deposit)
          noActiveId && e.stopPropagation()
        }}
        disabled={disableReleaseAction}
      >
        Release
      </Button>
    )
  }

  renderCompleteButton(deposit) {
    if (![depositStatuses.incomplete.key, depositStatuses.awaitingPayment.key,].includes(deposit.status) ||
      !canCompleteDeposit(this.props.viewer)
    ) {
      return null
    }
    const noActiveId = this.props.activeDepositId == null && this.props.activeBonusOfferId == null
    const {disableCompleteAction} = this.state
    return (
      <Button
        id="t-client-deposit-complete-button"
        className="btn-xs"
        variant="success"
        onClick={(e) => {
          this.openAcceptanceWindow(deposit)
          noActiveId && e.stopPropagation()
        }}
        disabled={disableCompleteAction}
      >
        Complete
      </Button>
    )
  }

  renderRevertButton(deposit) {
    const applicableDepositStatuses = [
      depositStatuses.completed.key,
      depositStatuses.partiallyReversed.key,
    ]
    if (applicableDepositStatuses.includes(deposit.status) && canCompleteDeposit(this.props.viewer)) {
      const meta = safeParseJSON(deposit.meta)
      const revertedAmount = get(meta, 'revertedAmount') || 0
      const noActiveId = this.props.activeDepositId == null && this.props.activeBonusOfferId == null
      const {disableCompleteAction} = this.state
      return deposit.amount > revertedAmount && (
        <Button
          id="t-client-deposit-revert-button"
          className="btn-xs"
          variant="info"
          onClick={(e) => {
            this.openRevertModal(deposit)
            noActiveId && e.stopPropagation()
          }}
          disabled={disableCompleteAction}
        >
          Revert
        </Button>
      )
    }
    return null
  }

  renderRefundButton(deposit) {
    const applicableDepositStatuses = [
      depositStatuses.held.key,
    ]
    const {fundingCategory} = getVendorPaymentType(deposit)
    const vendor = get(deposit, 'vendor', get(deposit, 'payment.vendor'))
    if (applicableDepositStatuses.includes(deposit.status) &&
      (fundingCategory === fundingCategories.card.value || vendor === depositVendors.skrill.key) &&
      canCompleteDeposit(this.props.viewer)
    ) {
      const noActiveId = this.props.activeDepositId == null && this.props.activeBonusOfferId == null
      const {disableCompleteAction} = this.state
      return (
        <Button
          id="t-client-deposit-refund-button"
          className="btn-xs"
          variant="info"
          onClick={(e) => {
            this.props.uiDispatch('Open refund modal', (state) => ({
              ...state,
              showRefundModal: true,
              refundingDeposit: deposit,
            }))
            noActiveId && e.stopPropagation()
          }}
          disabled={disableCompleteAction}
        >
          Refund
        </Button>
      )
    }
    return null
  }

  renderDepositButtons(deposit) {
    const {viewer} = this.props
    return (
      <React.Fragment>
        <div className="mb-1">{canChangeAttemptedToIncomplete(viewer) && this.renderIncompleteButton(deposit)}</div>
        <div className="mb-1">{this.renderReceiptButton(deposit)}</div>
        <div className="mb-1">{this.renderRejectButton(deposit)}</div>
        <div className="mb-1">{this.renderReleaseButton(deposit)}</div>
        <div className="mb-1">{this.renderCompleteButton(deposit)}</div>
        <div className="mb-1">{this.renderRevertButton(deposit)}</div>
        <div className="mb-1">{this.renderRefundButton(deposit)}</div>
      </React.Fragment>
    )
  }

  renderDeposit(deposit) {
    const {activeDepositId, client: {generalInfo: {id: clientId}}, viewer} = this.props
    const {id, account, status, vendor, createdAt} = deposit
    const renderVendor = get(depositVendors, [vendor, 'label'], capitalize(vendor))
    const {label: paymentType} = getVendorPaymentType(deposit)
    const formattedVendor = `${renderVendor}${paymentType || ''}`
    const bonusTrStyle = vendor === depositVendors.bonus.key ? 'bonusBackground' : ''
    const activeTrStyle = activeDepositId === id ? 'active' : ''
    const remoteId = account && account.remoteId
    const date = moment(createdAt)
    const getPaymentType = JSON.parse(get(deposit, 'meta', get(deposit, 'payment.meta')) || '{}')
    return (
      <tr key={id}
        className={`t-client-deposit ${activeTrStyle} ${style.clickable} ${bonusTrStyle}`}
        onClick={() => this.showDepositSidebar(deposit)}
      >
        <td>
          {getFormattedAmount(deposit)}
          <br />
          {isCentAccount(account)
            ? getFormattedAmount({
              currency: currencies.CUD.baseCurrency,
              amount: deposit.amount / currencies.CUD.baseCurrencyRate,
            })
            : ''
          }
        </td>
        <td>
          {account && getAccountLabel(accountTypes[account.__typename])}
          <br />
          [{remoteId}]
        </td>
        <td>
          {formattedVendor}
          {get(getPaymentType, 'paymentType') ? <div> <Badge pill variant={'info'}>{'Wire Transfer'}</Badge></div> : ''}
        </td>
        <td>
          {getVendorCardDetails(deposit, clientId)}
        </td>
        <td title={date.fromNow()}>
          {readableDate(date)}
        </td>
        <td>
          {getDepositStatusLabel(depositStatuses[status])}
        </td>
        <td>
          {!canQueryAffiliateManagerClients(viewer) && this.renderDepositButtons(deposit)}
        </td>
      </tr>
    )
  }


  renderBonusOffer(bonusOffer) {
    const {activeBonusOfferId} = this.props
    const {id, account, type, status, createdAt} = bonusOffer
    const date = moment(createdAt)
    return (
      <tr key={id}
        className={`t-client-deposit ${activeBonusOfferId === id ? 'active' : ''} ${style.clickable}`}
        onClick={() => this.showDepositSidebar(bonusOffer)}
      >
        <td>
          {getFormattedAmount(bonusOffer)}
        </td>
        <td>
          {account && accountTypes[account.__typename].label}
        </td>
        <td>
          {bonusOfferTypes[type] && bonusOfferTypes[type].label}
        </td>
        <td />
        <td title={date.fromNow()}>
          {readableDate(date)}
        </td>
        <td>
          {bonusOfferStatuses[status] && bonusOfferStatuses[status].label}
        </td>
        <td />
      </tr>
    )
  }

  renderDepositsTable() {
    const {client: {bonusOffers, deposits, depositsCount}, depositsPage} = this.props
    const bonuses = forEach(cloneDeep(bonusOffers), (b) => b.createdAt = b.createdAt.toISOString())
    const rows = orderBy([...(deposits || []), ...(bonuses || [])], ['createdAt'], ['desc'])

    return (<div>
      <Table bordered className={style.deposits}>
        <thead>
          <tr>
            <th>Amount</th>
            <th>Account Type/No.</th>
            <th>Vendor/Type</th>
            <th>Card Holder/Number</th>
            <th>Date Created</th>
            <th>Status</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {rows.map((row) => (!row.type ? this.renderDeposit(row) : this.renderBonusOffer(row)))}
        </tbody>
      </Table>

      <Row className="mt-3">
        <Col xs={12}>
          <Pagination
            size="sm"
            className="justify-content-center"
            onSelect={(e, selectedEvent) => this.props.filterDispatch(
              'Show page',
              (state) => ({...state, depositsPage: selectedEvent.eventKey})
            )}
          >
            {getPageRange(depositsPage, getPageCount(depositsCount)).map((page) => {
              if (page === 'LEFT_PAGE') {
                return <Pagination.Prev
                  key={page}
                  onClick={(e, selectedEvent) => this.props.filterDispatch(
                    'Show page',
                    (state) => ({...state, depositsPage: depositsPage - 1})
                  )}
                />
              }

              if (page === 'RIGHT_PAGE') {
                return <Pagination.Next
                  key={page}
                  onClick={(e, selectedEvent) => this.props.filterDispatch(
                    'Show page',
                    (state) => ({...state, depositsPage: depositsPage + 1})
                  )}
                />
              }

              return <Pagination.Item
                active={page === depositsPage}
                key={page}
                onClick={(e, selectedEvent) => this.props.filterDispatch(
                  'Show page',
                  (state) => ({...state, depositsPage: page})
                )}
              >
                {page}
              </Pagination.Item>
            })}
          </Pagination>
        </Col>
      </Row>
    </div>)
  }

  renderNoDeposits() {
    return (
      <div>
        <br />
        No deposits.
      </div>
    )
  }

  renderDepositsButtons() {
    const {viewer, client, activeDepositId} = this.props
    const hasPermission = canCreateDeposits(viewer)
    const hasAccount = client.depositAccounts && client.depositAccounts.length > 0
    const currentDeposit = find(client.deposits, {id: activeDepositId})

    return (
      <div>
        {hasPermission &&
          <DisabledTooltipButton
            id="t-client-deposits-create-button"
            variant="success"
            size="sm"
            className="float-right"
            onClick={() => this.newDepositSidebar()}
            disabled={!hasAccount}
            disabledTooltip="Client has no valid accounts to deposit"
            style={{fontSize: 12, marginBottom: '10px'}}
          >
            New Deposit/Bonus
          </DisabledTooltipButton>
        }
        {currentDeposit && currentDeposit.vendor === depositVendors.skrill.value &&
          canCompleteDeposit(viewer) &&
          <Button
            id="t-client-deposits-verify-skrill-button"
            variant="success"
            size="sm"
            className="float-right"
            onClick={() => this.verifySkrillAccount(currentDeposit)}
            style={{fontSize: 12, marginBottom: '10px'}}
          >
            Verify Skrill Account
          </Button>
        }
      </div>
    )
  }

  activateDateFilter(name, value) {
    this.props.filterDispatch(
      'Filter client deposits by dates',
      (state, arg) => ({...state, [name]: value, page: 1}),
    )
  }

  render() {
    const {client: {deposits, bonusOffers, generalInfo}, filterState: {depositsStatusFilter, depositsTypeFilter, depositsVendorFilter2,
      hasPartialRefundsFilter, depositsCreatedFromFilter, depositsCreatedToFilter}, showAcceptanceModal, acceptingDeposit,
    showRejectionReasonModal, rejectingDeposit, revertingDeposit, showRevertModal, isActive, showRefundModal, refundingDeposit,
    client,
    } = this.props

    const {showReceipt, dataLoading, dataError} = this.state || {}

    const filteredAvailablePsps = pick(depositVendors, generalInfo.whiteLabelConfig.availablePsps)
    const availablePaymentSystemProviders = StylishSelect.enumToStylishOptions(filteredAvailablePsps)

    if (isNil(deposits) || isNil(bonusOffers) || dataLoading || !isActive) {
      return <Loading speed={1} style={{padding:'20px'}}/>
    }
    if (dataError) return <pre> Error Loading Deposits : {dataError.message} </pre>
    const acceptingDepositMeta = JSON.parse(get(acceptingDeposit, 'meta', get(acceptingDeposit, 'payment.meta')) || '{}')
    return (
      <Row style={{overflowX: 'scroll'}}>
        <Col xs={10} className="mb-3">
          <ButtonToolbar className="mb-3">
            <StatusFilter
              value={depositsStatusFilter}
              statuses={filterableDepositStatuses}
              onClick={this.activateStatusFilter.bind(this)}
            />
          </ButtonToolbar>
          <div className="clear-fix">
            <ButtonToolbar className="float-left mr-3">
              <TransactionTypeFilter
                value={depositsTypeFilter}
                types={filterableTransactionTypes}
                onClick={this.activateDepositTypeFilter.bind(this)}
              />
            </ButtonToolbar>
            <Button
              onClick={() => this.showClientPaymentOptions(client)}
              variant="outline-secondary"
              size="sm"
              className="float-left"
            >Show client payment options</Button>
          </div>
        </Col>
        <Col xs={2}>
          {this.renderDepositsButtons()}
        </Col>
        <Col xs={4}>
          <StylishSelect
            multi
            clearable
            highlightIfActive
            id="t-deposits-vendors-filter"
            placeholderText="All Vendors"
            value={depositsVendorFilter2}
            options={availablePaymentSystemProviders}
            onChange={(e) => {
              this.activateDepositVendorsFilter(e)
              this.setState({dataLoading: true}, () => {
                Promise.all([
                  this.context.clientProvider.subProviders.deposits.fetch(),
                ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
                  .catch((e) => this.setState({dataLoading: false, dataError: e}))
              })
            }}
          />
          <span className={style.label}>&nbsp;</span>
        </Col>
        <Col xs={3}>
          <Form.Check
            type="checkbox"
            label="Has Partial Refunds / No Refunds"
            checked={hasPartialRefundsFilter}
            onChange={(e) => {
              this.activateHasPartialRefundsFilter(e.target.checked)
              this.setState({dataLoading: true}, () => {
                Promise.all([
                  this.context.clientProvider.subProviders.deposits.fetch(),
                ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
                  .catch((e) => this.setState({dataLoading: false, dataError: e}))
              })
            }}
          />
        </Col>
        <Col xs={2}>
          <div>
            <span className={style.label}> Created From </span>
            <DateTime
              id="t-deposits-created-from-filter"
              timeFormat={false}
              onChange={(e) => {
                this.activateDateFilter('depositsCreatedFromFilter', e && moment.isMoment(e) ? moment(e).startOf('day') : '')
                this.setState({dataLoading: true}, () => {
                  Promise.all([
                    this.context.clientProvider.subProviders.deposits.fetch(),
                  ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
                    .catch((e) => this.setState({dataLoading: false, dataError: e}))
                })
              }}
              value={depositsCreatedFromFilter}
              onFocus={() => this.doDateFilterSearch.cancel()}
              closeOnSelect
            />
          </div>
        </Col>
        <Col xs={2}>
          <span className={style.label}> Created To </span>
          <DateTime
            id="t-deposits-created-to-filter"
            timeFormat={false}
            onChange={(e) => {
              this.activateDateFilter('depositsCreatedToFilter', e && moment.isMoment(e) ? moment(e).endOf('day') : '')
              this.setState({dataLoading: true}, () => {
                Promise.all([
                  this.context.clientProvider.subProviders.deposits.fetch(),
                ]).then(() => this.setState({dataLoading: false, dataFetched: true}))
                  .catch((e) => this.setState({dataLoading: false, dataError: e}))
              })
            }}
            value={depositsCreatedToFilter}
            onFocus={() => this.doDateFilterSearch.cancel()}
            closeOnSelect
          />
        </Col>
        <Col xs={12}>
          {deposits.length + bonusOffers.length === 0 ? this.renderNoDeposits() : this.renderDepositsTable()}
          <DepositReceiptModal
            show={!!showReceipt}
            receipt={showReceipt}
            onHide={() => this.setState({showReceipt: null})}
          />

          {showAcceptanceModal && acceptingDeposit.vendor !== depositVendors.bankWire.key && <ConfirmationModal
            show={showAcceptanceModal && acceptingDeposit.vendor !== depositVendors.bankWire.key}
            header="Deposit Completion"
            body="Are you sure you would like to approve this request?"
            confirmLabel="Accept"
            onConfirm={() => {
              this.completeDeposit(acceptingDeposit)
              this.closeAcceptanceWindow()
            }}
            onCancel={this.closeAcceptanceWindow.bind(this)}
          />}

          {showAcceptanceModal && acceptingDeposit.vendor === depositVendors.bankWire.key && <DepositCompleteModal
            show={showAcceptanceModal && acceptingDeposit.vendor === depositVendors.bankWire.key}
            header={`Complete Deposit #${get(acceptingDeposit, 'id')}`}
            amount={get(acceptingDeposit, 'amount')}
            onConfirm={(amount, bank, dateReceived) => {
              this.completeDeposit(acceptingDeposit, amount, bank, dateReceived)
              this.closeAcceptanceWindow()
            }}
            onCancel={this.closeAcceptanceWindow.bind(this)}
          />}
          {showAcceptanceModal && acceptingDeposit.vendor === depositVendors.jmFinancial.key &&
            get(acceptingDepositMeta, 'paymentType') === jmFinancialPaymentTypes.jmFinancialWireTransfer.value && <DepositCompleteModal
            show={showAcceptanceModal && acceptingDeposit.vendor === depositVendors.jmFinancial.key &&
                get(acceptingDepositMeta, 'paymentType') === jmFinancialPaymentTypes.jmFinancialWireTransfer.value}
            header={`Complete Deposit #${get(acceptingDeposit, 'id')}`}
            amount={get(acceptingDeposit, 'amount')}
            onConfirm={(amount, bank, dateReceived) => {
              this.completeDeposit(acceptingDeposit, amount, bank, dateReceived)
              this.closeAcceptanceWindow()
            }}
            onCancel={this.closeAcceptanceWindow.bind(this)}
          />}

          {showRevertModal && <RevertModal
            show={showRevertModal}
            header={`Revert Deposit #${get(revertingDeposit, 'id')}`}
            amount={get(revertingDeposit, 'amount')}
            onConfirm={(amount) => {
              this.revertDeposit(revertingDeposit, amount)
              this.closeRevertModal()
            }}
            onCancel={this.closeRevertModal}
          />}

          {showRefundModal && <ConfirmationModal
            show={showRefundModal}
            header={`Refund Deposit #${get(refundingDeposit, 'id')}`}
            body="Are you sure you would like to refund this deposit?"
            confirmLabel="Refund"
            onConfirm={() => {
              this.refundDeposit(refundingDeposit)
              this.closeRefundModal()
            }}
            onCancel={this.closeRefundModal}
          />}

        </Col>
        <ReasonModal
          show={showRejectionReasonModal}
          header="Deposit Rejection Reason"
          placeholder="Choose Rejection Reason"
          onCancel={this.closeRejectionReasonWindow.bind(this)}
          onDone={(reason, selectedReasonCode) => {
            this.rejectDeposit(rejectingDeposit, reason, selectedReasonCode)
            this.closeRejectionReasonWindow()
          }}
          reasons={pickBy(depositRejectionReasons, (r) => !r.disabled)}
          warningMessage="Warning! The rejection reason is visible to client and cannot be changed."
          submitButtonBsClass="danger"
          submitButtonText="Reject"
        />
        <GeneralModal
          show={this.state.showClientPaymentOptions}
          header="Client Payment Options"
          body={this.state.loadingClientPaymentOptions
            ? <Loading />
            : <ol>
              {map(this.state.clientPaymentOptions, paymentOption => <li>{paymentOption}</li>)}
            </ol>
          }
          onCancel={() => this.setState({showClientPaymentOptions: false})}
        />
      </Row>
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'depositsUi']),
  provideProps((state, uiState, filterState) => {
    const {depositsStatusFilter, depositsTypeFilter, showAcceptanceModal, acceptingDeposit,
      showRejectionReasonModal, rejectingDeposit, showRevertModal, revertingDeposit, showRefundModal,
      refundingDeposit,
    } = uiState
    const {depositsPage} = filterState
    return {
      depositsStatusFilter,
      depositsTypeFilter,
      showAcceptanceModal,
      acceptingDeposit,
      showRejectionReasonModal,
      rejectingDeposit,
      showRevertModal,
      revertingDeposit,
      showRefundModal,
      refundingDeposit,
      depositsPage: depositsPage || 1,
    }
  }),
)(Deposits)
