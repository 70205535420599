import {socketEvents} from '@bdswiss/common-enums'

export function registerClientConnectionHandler() {
  if (window.ioSocket) {
    window.ioSocket.on(socketEvents.clientOnline.value, (data) => {
      const onlineStatusNode = document.getElementById(`${data.id}_onlinestatus`)
      if (!onlineStatusNode) {
        return
      }
      onlineStatusNode.className = onlineStatusNode.className.replace('client-offline', 'client-online')
      onlineStatusNode.title = 'Online'
    })

    window.ioSocket.on(socketEvents.clientOffline.value, (data) => {
      const onlineStatusNode = document.getElementById(`${data.id}_onlinestatus`)
      if (!onlineStatusNode) {
        return
      }
      onlineStatusNode.className = onlineStatusNode.className.replace('client-online', 'client-offline')
      onlineStatusNode.title = 'Offline'
    })
  }
}
