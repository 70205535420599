/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {groupBy, map} from 'lodash'
import {Row, Col} from 'react-bootstrap'
import {accountSubtypes} from '@bdswiss/common-enums'
import PureComponent from '../PureComponent'
import {provideProps} from '../decorators'
import StylishSelect from '../components/StylishSelect'
import {findAccountTypes, getAccountOpenTypes} from '../common/utils'

class PartnersCustomizationAccountTypeRow extends PureComponent {

  getAllowedSubtypes() {
    const notAllowedSubtypes = [accountSubtypes.basic.value, accountSubtypes.classic.value]
    const accountTypes = findAccountTypes(notAllowedSubtypes)
    const allowedTypes = groupBy(accountTypes, 'company')
    return allowedTypes
  }

  render() {
    const {accountTypeCustomizationKey, accountSubTypes} = this.props

    const accountOpenTypes = getAccountOpenTypes()

    return (
      <div key={accountTypeCustomizationKey}>
        <Row>
          <Col xs={12}>
            <StylishSelect.Input
              multi
              clearable
              label="Account Types Allowed"
              value={accountSubTypes}
              options={StylishSelect.enumToStylishOptions({...accountOpenTypes})}
              onChange={(evt) => this.props.onChange('accountSubTypes', map(evt, 'value'))}
            />
          </Col>
        </Row>
      </div>
    )
  }
}

export default provideProps()(PartnersCustomizationAccountTypeRow)
