import React from 'react'
import PropTypes from 'prop-types'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import {provideProps} from '../decorators'
import style from './client.module.scss'
import PureComponent from '../PureComponent'

export default provideProps()(class SecurityCode extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    onClose: PropTypes.func.isRequired,
  };

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  generateSecurityCode = () => this.props.actions.client.generateSecurityCode(this.props.clientId)
    .then((res) => {
      this.setState({newSecurityCode: res.generateSecurityCode.newSecurityCode})
      this.context.clientProvider.subProviders.activityLogs.fetch()
    })
    .catch(this.context.logError)

  renderNewCode() {
    const {newSecurityCode} = this.state

    return (
      <div id="t-client-security-code-generated">
        Once the client confirms the code and matches, we can then proceed to assist.<br />
        <div className={style.generateCode}>
          <code>{newSecurityCode}</code>
        </div>
      </div>
    )
  }

  render() {
    const {onClose} = this.props
    const {newSecurityCode} = this.state || {}
    let message, showResetButton

    if (newSecurityCode != null) {
      message = this.renderNewCode()
      showResetButton = false
    } else {
      message = (
        <div>
          Generate 5 digit code to the clients REGISTERED email.
        </div>
      )
      showResetButton = true
    }

    return (
      <Card
        id="t-client-security-code-sidebar"
        key={1}
      >
        <Card.Header><strong>Generate Security Code</strong></Card.Header>
        <Card.Body>
          {message}
          <br />
          <Row>
            <Col md={12} xs={12}>
              <ButtonToolbar className="float-right">
                <Button
                  id="t-client-security-code-close"
                  tabIndex={-1}
                  onClick={onClose}
                  variant="outline-secondary"
                >Close
                </Button>
                {showResetButton ?
                  <Button
                    id="t-client-security-code-generate"
                    variant="danger"
                    className="ml-2"
                    onClick={this.generateSecurityCode}
                  >
                    Generate Code
                  </Button> : null
                }
              </ButtonToolbar>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    )
  }
})
