import React from 'react'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {Breadcrumb, BreadcrumbItem} from 'react-bootstrap'
import {LinkContainer} from 'react-router-bootstrap'

export default class TitleBreadcrumb extends PureComponent {

  static propTypes = {
    name: PropTypes.string.isRequired,
    hasChanges: PropTypes.bool,
  };

  render() {
    return (
      <Breadcrumb>
        <LinkContainer
          to="/campaigns"
          active={false}
          disabled={this.props.hasChanges}
          title={this.props.hasChanges ? 'Save or discard before exiting.' : null}
        >
          <BreadcrumbItem>
            Campaigns
          </BreadcrumbItem>
        </LinkContainer>
        <BreadcrumbItem active>
          {this.props.name}
        </BreadcrumbItem>
      </Breadcrumb>
    )
  }

}
