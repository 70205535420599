/* eslint-disable import/no-anonymous-default-export */
import React from 'react'
import PropTypes from 'prop-types'
import {Translator} from '../translator'
import {getHocName} from '../useful'


export default (name, translate, paths) => (
  (Component) => class TranslateComponent extends React.Component {

    static displayName = getHocName('Translate', Component)

    static propTypes = {
      uiDispatch: PropTypes.func.isRequired,
      uiState: PropTypes.any,
    };

    componentWillMount() {
      this.active = false
      this.translator = new Translator(name, translate, this.props.uiDispatch, paths)
      this.translator.sync(this.props, this.props.uiState)
    }

    componentWillReceiveProps(nextProps) {
      this.active = true
      this.translator.sync(nextProps, nextProps.uiState)
    }

    render() {
      if (this.active) {
        return (<Component {...this.props} />)
      } else {
        return (<div />)
      }
    }

  }
)

