import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {Col, Row, Button, ButtonToolbar, Card} from 'react-bootstrap'
import {has, get, split, first, last, omit, isEmpty, map, trim} from 'lodash'
import style from './users.module.scss'
import {validateShift} from './validation'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import StylishSelect from '../components/StylishSelect'

const shiftOptions = [
  {
    label: '08:00 - 17:00',
    value: '08:00:00 - 17:00:00'
  },
  {
    label: '09:00 - 18:00',
    value: '09:00:00 - 18:00:00'
  },
  {
    label: '00:00 - 08:00',
    value: '00:00:01 - 08:00:00'
  },
  {
    label: '13:00 - 21:00',
    value: '13:00:00 - 21:00:00'
  },
  {
    label: '19:00 - 01:00',
    value: '19:00:00 - 01:00:00'
  }
]

class ShiftForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    shiftsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  componentWillMount() {
    if (isEmpty(this.props.shift)) {
      this.setDefaultState()
    } else {
      const {shift} = this.props
      this.setState({
        values: {
          id: shift.id,
          usersSelected: [shift.user.id],
          fromDate: moment(shift.from).startOf('day'),
          timeSelected: moment(shift.to).endOf('day'),
        },
        isTouched: false,
        errors: {},
      })
    }
  }

  componentWillReceiveProps(newProps) {
    if (newProps && !isEmpty(newProps.shift)) {
      const from = moment(newProps.shift.from)
      const to = moment(newProps.shift.to)
      this.setState({
        values: {
          id: newProps.shift.id,
          usersSelected: newProps.shift.user.id,
          fromDate: from,
          timeSelected: `${from} - ${to}`,
        },
      })
    }
  }

  cancel = () => {
    this.setDefaultState()
    this.props.onCancel(false)
  }

  removeShift = () => {
    this.props.actions.user.deleteShift(this.props.shift.id).then(() => {
      this.setDefaultState()
      this.props.onCancel(false)
      this.context.shiftsProvider.fetch(true)
    }).catch((e) => {
      this.context.showNotification({
        message: e.message,
        level: 'error',
        autoDismiss: 0,
      })
    })
  }

  setDefaultState() {
    this.setState({
      values: {
        usersSelected: '',
        fromDate: null,
        timeSelected: null,
      },
      isTouched: false,
      errors: {},
    })
  }

  getValue(key) {
    if (this.state.values && key in this.state.values) {
      return this.state.values[key]
    } else {
      return null
    }
  }

  generateInputProps(key) {
    const res = {
      value: this.getValue(key) || '',
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.value)
        } else if (e && e.value) {
          this.valueChanged(key, e.value)
        } else if (e) {
          this.valueChanged(key, e)
        } else {
          this.valueChanged(key, '')
        }
      },
    }
    if (this.state.errors && key in this.state.errors) {
      res.bsStyle = 'error'
    }
    return res
  }

  hasErrors(errors) {
    errors = errors || this.state.errors
    return errors._direct_count !== 0
  }

  valueChanged(key, value) {
    const values = ({...this.state.values, [key]: value})
    this.setState({
      values: values,
      isTouched: true,
    })
  }

  onValidate(field, validationData) {
    if (this.state.isTouched) {
      const errors = this.state.errors
      errors[field] = validationData
      this.setState({errors})
    }
  }

  trySave(e) {
    e.preventDefault()
    const errors = validateShift(this.state.values)
    if (this.state.isTouched && !this.hasErrors(errors)) {
      this.save()
    } else {
      this.setState({
        isTouched: false,
        errors,
      })
    }
  }

  save() {
    const {values: {timeSelected, fromDate, usersSelected, toDate}} = this.state
    const shiftTime = split(timeSelected, '-')
    const fromTime = trim(first(shiftTime))
    const toTime = trim(last(shiftTime))
    const addADay = toTime < fromTime
    const from = `${trim(moment(fromDate).format('YYYY-MM-DD'))} ${trim(first(shiftTime))}`
    const to = addADay
      ? `${trim(moment(fromDate).add(1, 'day').format('YYYY-MM-DD'))} ${trim(last(shiftTime))}`
      : `${trim(moment(fromDate).format('YYYY-MM-DD'))} ${trim(last(shiftTime))}`
    let params = {
      id: parseInt(this.props.shift && this.props.shift.id),
      userIds: usersSelected,
      from,
      to,
      toDate: `${moment(toDate).format('YYYY-MM-DD')}`,
    }

    if (isEmpty(this.props.shift)) params = omit(params, 'id')
    if (!isEmpty(this.props.shift)) params = omit(params, 'toDate')
    this.props.actions.user.upsertShift(params).then(() => {
      this.setDefaultState()
      this.props.onSave()
      this.context.shiftsProvider.fetch(true)
    }).catch((e) => {
      this.context.showNotification({
        message: e.message,
        level: 'error',
        autoDismiss: 0,
      })
    })
  }

  renderErrorMessage(key) {
    const {errors} = this.state
    if (has(errors, key)) {
      return (
        <p className="help-block"> {get(errors, key)} </p>
      )
    }
  }
  render() {
    const {backOfficeUsers} = this.props
    const {values: {usersSelected}} = this.state

    return (
      <div>
        <Card>
          <Card.Body>
            <Row id="t-shifts-form" className={style.shiftPanel}>
              <Col md={12}>
                <form onSubmit={this.trySave.bind(this)} className={style.formStyle}>
                  <Row>
                    <Col xs={12}>
                      <label className="control-label">Select BO Agent(s)</label>
                      <StylishSelect
                        id="t-back-office-users"
                        placeholderText="Select..."
                        value={usersSelected}
                        options={backOfficeUsers}
                        multi
                        onChange={(e) => this.valueChanged('usersSelected', map(e, 'value'))}
                      />
                      {this.renderErrorMessage('usersSelected')}
                    </Col>
                  </Row>
                  <br />
                  <Row>
                    <Col xs={12}>
                      <DateTime
                        timeFormat={false}
                        id="t-shift-form-date-from"
                        label="Date (From)"
                        {...this.generateInputProps('fromDate')}
                      />
                      {this.renderErrorMessage('fromDate')}
                    </Col>
                  </Row>
                  {isEmpty(this.props.shift) && <Row>
                    <Col xs={12}>
                      <DateTime
                        timeFormat={false}
                        id="t-shift-form-date-to"
                        label="Date (To)"
                        {...this.generateInputProps('toDate')}
                      />
                      {this.renderErrorMessage('toDate')}
                    </Col>
                  </Row>}
                  <Row>
                    <Col xs={12}>
                      <StylishSelect.Input
                        id="t-shift-form-time"
                        label="Select shift"
                        options={shiftOptions}
                        {...this.generateInputProps('timeSelected')}
                      />
                      {this.renderErrorMessage('timeSelected')}
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <ButtonToolbar className="float-right">
                        <Button
                          id="t-add-shift-form-cancel-btn"
                          variant="outline-secondary"
                          onClick={this.cancel}
                          className="mr-3"
                        >
                          Cancel
                        </Button>
                        <Button
                          id="t-add-shift-form-save-btn"
                          variant="success"
                          type="submit"
                        >
                          Save
                        </Button>
                        {get(this.props, 'shift.id') && <Button
                          id="t-add-shift-form-remove-btn"
                          variant="danger"
                          onClick={this.removeShift}
                          className="ml-3"
                        >
                          Remove
                        </Button>}
                      </ButtonToolbar>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </div>
    )
  }
}

export default provideProps()(ShiftForm)
