import React, {Component} from 'react'
import PropTypes from 'prop-types'
import StylishSelect from '../../components/StylishSelect'
import {compose, mountDataProviders, provideProps, uiMount} from '../../decorators'
import {map} from 'lodash'
import {lastCampaignProvider} from './providers'

class LastCampaignSelectUI extends Component {
  static contextTypes = {
    lastCampaignProvider: PropTypes.object.isRequired,
  }

  render() {
    const {lastCampaignList} = this.props

    return (
      <StylishSelect
        id="t-affiliate"
        placeholder="Select campaign"
        options={lastCampaignList || []}
        value={lastCampaignList.length ? lastCampaignList[0].value : ''}
        onChange={() => {}}
        disabled
      />
    )
  }
}

export default compose(
  uiMount(() => ['clientUi', 'affiliateAssign']),
  provideProps((state, uiState, _, props) => {
    const {lastCampaign} = state
    const {affiliateId} = props

    const mappingObj =
      lastCampaign
        ? map([lastCampaign], (a) => ({
          value: a.campaignId,
          label: `${a.campaignId} ${a.campaign.name}`,
        }))
        : []

    return {
      lastCampaignList: mappingObj,
      affiliateId
    }
  }),
  mountDataProviders({lastCampaignProvider})
)(LastCampaignSelectUI)
