import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {LinkContainer} from 'react-router-bootstrap'
import {pickBy, find, get, filter, some, map} from 'lodash'
import {conversionStatuses, clientTypes, companies, kycStatuses, whiteLabels, underMonitoringReasons, conversionSteps} from '@bdswiss/common-enums'
import {
  canWriteClientKycStatus, canToggleAllowTransfers,
  canToggleUnderMonitoring, canWriteClientDetails, canWriteGeneralClientNote,
  canOnlyViewSupportAssignments, canOnlyViewExternalAssignments, canDeleteClient,
  canToggleManualWithdrawals, canQueryAffiliateManagerClients, canInsertUpdateSalesClientNote,
  canInsertUpdateAffiliateClientNote, canLoginAsAnotherClient, canWriteClientConversionStatus,
} from '@bdswiss/common-permissions'
import {Row, Col, Breadcrumb, BreadcrumbItem, Button, ButtonToolbar, DropdownButton, Dropdown, Form,
  Badge, InputGroup, Card, OverlayTrigger, Tooltip,
} from 'react-bootstrap'
import {setIn} from '../stateUtils'
import ChangeAgent from './ChangeAgent'
import TradingStatus from './TradingStatus'
import {provideProps} from '../decorators'
import style from './client.module.scss'
import PureComponent from '../PureComponent'
import NewCallModal from './NewCallModal'
import DeleteMemberModal from './DeleteMemberModal'
import LogInAsClientModal from './LogInAsClientModal'
import UnderMonitoringModal from './UnderMonitoringModal'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {getFullName, readableDate, isConverted} from '../useful'
import {backendRequest} from '../utils/net'


export default provideProps((state) => ({
  agents: state.agents,
}))(class GeneralInfo extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      ...this.state,
      showDeleteMemberModal: false,
      showLogInAsClientModal: false,
      showUnderMonitoringModal: false,
      showPopup: false,
    }
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    dispatch: PropTypes.func.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
    toggleAppointmentEditor: PropTypes.func.isRequired,
  };

  static propTypes = {
    generalInfo: PropTypes.object,
    agents: PropTypes.array,
    showAppointmentEditor: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.setState({
      generalClientNote: this.props.generalInfo.generalClientNote,
      generalClientCompany: this.props.generalInfo.company,
      allowTransfers: this.props.generalInfo.allowTransfers,
      underMonitoring: this.props.generalInfo.underMonitoring,
      manualWithdrawals: this.props.generalInfo.manualWithdrawals,
      salesClientNote: this.props.generalInfo.salesClientNote,
      affiliateClientNote: this.props.generalInfo.affiliateClientNote,
    })
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      generalClientNote: nextProps.generalInfo.generalClientNote,
      generalClientCompany: nextProps.generalInfo.company,
      allowTransfers: nextProps.generalInfo.allowTransfers,
      underMonitoring: nextProps.generalInfo.underMonitoring,
      manualWithdrawals: nextProps.generalInfo.manualWithdrawals,
      salesClientNote: this.props.generalInfo.salesClientNote,
      affiliateClientNote: this.props.generalInfo.affiliateClientNote,
    })
  }

  changeConversionStatus(conversionStatusKey) {
    const {optimistic} = this.context.clientProvider
    optimistic.setConversionStatus(this.props.dispatch, this.props.generalInfo.id, conversionStatusKey)
    this.props.actions.client.setConversionStatus(this.props.generalInfo.id, conversionStatusKey)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  changeClientType(clientTypeKey) {
    const {optimistic} = this.context.clientProvider
    optimistic.setClientType(this.props.dispatch, this.props.generalInfo.id, clientTypeKey)
    this.props.actions.client.setClientType(this.props.generalInfo.id, clientTypeKey)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  clearSearch(e) {
    this.setState({searchText: '', searchValidationState: null})
    e.target.blur()
  }

  searchInputChange = (e) => {
    this.setState({searchText: e.target.value, searchValidationState: null})
  }

  doSearch() {
    const searchText = this.state && this.state.searchText
    if (searchText && searchText.length > 0) {
      this.props.dispatch(
        'Dispatching client search',
        (state) => setIn(state, ['clientsUi', 'clientsSearch'], searchText, true)
      )
      this.context.router.push('/clients')
    } else {
      this.setState({searchValidationState: 'error'})
    }
  }

  renderStatusLabel() {
    const {convertedAt} = this.props.generalInfo
    if (!isConverted(convertedAt)) {
      return (<h6><Badge id="t-trading-status-label" variant="secondary" pill>
        Unregistered Lead
      </Badge></h6>)
    }
  }

  renderPoolLabel() {
    const {isPool} = this.props.generalInfo
    if (isPool) {
      return (<h6><Badge id="t-agent-pool-label" variant="warning" pill>
        Agent Pool
      </Badge></h6>)
    }
  }

  changeGeneralClientNote(e) {
    this.setState({generalClientNote: e.target.value})
  }

  saveGeneralClientNote() {
    this.props.actions.client.upsertGeneralClientNote(this.props.generalInfo.id, this.state.generalClientNote)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  saveSalesClientNote() {
    this.props.actions.client.upsertSalesClientNote(this.props.generalInfo.id, this.state.salesClientNote)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  saveAffiliateClientNote() {
    this.props.actions.client.upsertAffiliateClientNote(this.props.generalInfo.id, this.state.affiliateClientNote)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  renderClientNotes() {
    return (
      <Row className={classnames([style.flexGrow, 'mt-3'])}>
        {canWriteGeneralClientNote(this.props.viewer) &&
          <Col xs={6} id="t-client-general-client-note">
            <Card>
              <Card.Header>Back Office Notes</Card.Header>
              <textarea
                className={classnames(style.generalClientNoteTextArea, style.generalClientNoteTextAreaEditable)}
                maxLength="200"
                value={this.state.generalClientNote || ''}
                onChange={this.changeGeneralClientNote.bind(this)}
                onBlur={this.saveGeneralClientNote.bind(this)}
                placeholder="Click to edit general notes"
                rows={8}
              />
            </Card>
          </Col>
        }
        {!canWriteGeneralClientNote(this.props.viewer) &&
          <Col xs={6} id="t-client-general-client-note">
            <Card>
              <Card.Header>Back Office Notes</Card.Header>
              <textarea
                className={style.generalClientNoteTextArea}
                maxLength="100"
                disabled
                value={this.state.generalClientNote || ''}
                placeholder="(No client notes entered)"
                rows={8}
              />
            </Card>
          </Col>
        }
        {canInsertUpdateSalesClientNote(this.props.viewer) &&
          <Col xs={6} id="t-client-sales-client-note">
            <Card>
              <Card.Header>Sales Notes</Card.Header>
              <textarea
                className={classnames(style.generalClientNoteTextArea, style.generalClientNoteTextAreaEditable)}
                maxLength="200"
                value={this.state.salesClientNote || ''}
                onChange={(e) => this.setState({salesClientNote: e.target.value})}
                onBlur={this.saveSalesClientNote.bind(this)}
                placeholder="Click to edit notes"
                rows={8}
              />
            </Card>
          </Col>
        }
        {canInsertUpdateAffiliateClientNote(this.props.viewer) &&
          <Col xs={6} id="t-client-affiliate-client-note">
            <Card>
              <Card.Header>Partner Notes</Card.Header>
              <textarea
                className={classnames(style.generalClientNoteTextArea, style.generalClientNoteTextAreaEditable)}
                maxLength="200"
                value={this.state.affiliateClientNote || ''}
                onChange={(e) => this.setState({affiliateClientNote: e.target.value})}
                onBlur={this.saveAffiliateClientNote.bind(this)}
                placeholder="Click to edit notes"
                rows={8}
              />
            </Card>
          </Col>
        }
        {!canInsertUpdateAffiliateClientNote(this.props.viewer) && !canInsertUpdateSalesClientNote(this.props.viewer) &&
          <Col xs={6} id="t-client-affiliate-client-note">
            <Card>
              <Card.Header>Partner Notes</Card.Header>
              <textarea
                className={style.generalClientNoteTextArea}
                maxLength="100"
                disabled
                value={this.state.affiliateClientNote || ''}
                placeholder="(No notes entered)"
                rows={8}
              />
            </Card>
          </Col>
        }
      </Row>
    )
  }

  call(phone, phoneType) {
    const {generalInfo} = this.props
    this.setState({startCall: false})
    this.props.actions.client.startCall(generalInfo.id, phone, phoneType)
      .then((call) => {
        const callAnnotationRequest = {callLogId: call.startCall.id, clientName: getFullName(generalInfo)}
        this.props.dispatch('Display call annotation', (state) => ({...state, callAnnotationRequest}))
        this.context.clientProvider.subProviders.calls.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }

  toggleAllowTransfers(allowTransfers) {
    const {generalInfo: {id}} = this.props
    this.props.actions.client.toggleAllowTransfers(id, allowTransfers)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          allowTransfers: !allowTransfers,
        })
      })
  }

  toggleManualWithdrawals(manualWithdrawals) {
    const {generalInfo: {id}} = this.props
    this.props.actions.client.toggleManualWithdrawals(id, manualWithdrawals)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          manualWithdrawals: !manualWithdrawals,
        })
      })
  }

  openUnderMonitoringModal() {
    this.setState({showUnderMonitoringModal: true})
  }

  toggleUnderMonitoring(underMonitoring, selectedReasonCode, reason) {
    const {generalInfo: {id}} = this.props
    this.setState({
      underMonitoring,
    })

    this.props.actions.client.toggleUnderMonitoring(id, underMonitoring, reason, selectedReasonCode)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          underMonitoring: !underMonitoring,
          showUnderMonitoringModal: false,
        })
      })
  }

  closeUnderMonitoringModal() {
    this.setState({showUnderMonitoringModal: false})
  }

  handleDeleteMemberClick() {
    this.setState({showDeleteMemberModal: true})
  }

  confirmDeleteMember(confirm) {
    this.setState({showDeleteMemberModal: false})
    if (confirm) {
      const {actions, generalInfo} = this.props
      actions.client.deleteMember(generalInfo.id)
        .then((res) => {
          this.context.showNotification({
            title: 'Delete member',
            message: 'Member successfully deleted.',
            position: 'tr',
            level: 'success',
          })
          this.context.router.push('/clients')
        })
        .catch((error) => {
          this.context.showNotification({
            title: 'Delete member',
            message: error.message,
            position: 'tr',
            level: 'error',
          })
        })
    }
  }

  handleLogInAsClientClick() {
    this.setState({showLoginAsClientModal: true})
  }

  confirmLoginAsClient(confirm, loginReason) {
    this.setState({showLoginAsClientModal: false})
    if (confirm && loginReason) {
      const {backendUrl} = this.context.config
      const {id} = this.props.generalInfo
      backendRequest(`${backendUrl}/auth/force-as/client`, {id: id, reason: loginReason})
        .then(response => response.json())
        .then(async (response) => {
          await backendRequest(response.backendUrl, {token: response.authtoken})
          const newTab = window.open(`${response.webFrontendUrl}/accounts`, '_blank')
          newTab.focus()
        }).catch(this.context.logError)
    }
  }

  getClientTypeBtnStyle(clientType) {
    switch (clientType) {
      case clientTypes.affiliate.key:
      case clientTypes.affiliateCorporate.key:
        return 'warning'
      case clientTypes.ib.key:
      case clientTypes.ibCorporate.key:
        return 'info'
      case clientTypes.merchant.key:
      case clientTypes.strategicPartner.key:
        return 'success'
      default:
        return 'outline-secondary'
    }
  }

  render() {
    const {generalInfo, viewer} = this.props

    const {id, conversionStatus, conversionStep, tradingStatus, phone, email, registration, lastLogin, kycStatus,
      clientType, convertedAt, ftdDate, company, secondaryPhones, whiteLabel, topVip,
      firstName, lastName, terminationDate, tradingStatusReason, tradingStatusReasonCode,
    } = generalInfo
    const companyObj = find(companies, {value: company})
    const kycDays = get(companyObj, 'kycDeadline') + get(companyObj, 'kycDeadlineExtension',0)
    const kycDeadLine = moment(ftdDate).add(kycDays, 'day')
    const kycDeadLineReadable = ftdDate ? readableDate(kycDeadLine) : 'No FTD'
    const kycDeadLineFromNow = ftdDate ? kycDeadLine.fromNow() : 'No FTD'
    const clientConverted = isConverted(convertedAt)
    const lastLoginFromNow = clientConverted ? lastLogin.fromNow() : 'Never'
    const registrationReadable = clientConverted ? readableDate(registration) : 'Not Registered Yet'
    const registrationFromNow = clientConverted ? registration.fromNow() : 'Not Registered Yet'
    const terminationDateReadable = terminationDate ? readableDate(moment(terminationDate)) : '-'
    const searchText = (this.state && this.state.searchText) || ''
    const searchValidationState = this.state && this.state.searchValidationState
    const availableConversionStatuses =
      pickBy(conversionStatuses, 'userAssignable')

    const agents = filter(this.props.agents, (agent) => agent.isActive)
    const partnerAgents = filter(agents, (agent) =>
      some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id))
    )
    const underMonitoringReason = generalInfo.underMonitoring
      ? find(underMonitoringReasons, {key: generalInfo.underMonitoringReasonCode})?.label
      : ''

    return (
      <Row className="mb-3">
        <Col xs={8} className={style.flexContainer}>
          <Row>
            <Col xs={12}>
              <div className={`h4 ${style.tradeLabel}`}>
                {this.renderStatusLabel()}
                {this.renderPoolLabel()}
              </div>
              <h6 className="mb-0">
                {window.ioSocket && <Badge
                  pill
                  variant={generalInfo.isOnline ? 'success' : 'secondary'}
                  className="mr-2"
                >
                  {`${generalInfo.isOnline ? 'Online' : 'Offline'}: ${lastLoginFromNow}`}
                </Badge>}
                <Badge
                  pill
                  className={classnames('mr-2', style.companyIcon)}
                >
                  {companies[company].label}
                </Badge>
                {topVip && <Badge
                  className="mr-2"
                  pill
                  variant="warning"
                >Vip</Badge>}
                {kycStatus && <Badge
                  className="mr-2"
                  pill
                  variant={kycStatuses[kycStatus].bsStyle}
                >{kycStatuses[kycStatus].label}</Badge>}
                {whiteLabel && <Badge
                  className="mr-2"
                  pill
                  variant="warning"
                >{whiteLabels[whiteLabel].label}</Badge>}
              </h6>
              <div className={`h5 ${style.breadcrumb}`}>
                <Breadcrumb>
                  <LinkContainer to="/clients" active={false}>
                    <BreadcrumbItem>
                      Clients
                    </BreadcrumbItem>
                  </LinkContainer>
                  <BreadcrumbItem active>
                    {getFullName(generalInfo)}
                  </BreadcrumbItem>
                </Breadcrumb>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={4}>
              {!canOnlyViewSupportAssignments(viewer) && <ChangeAgent
                agentType={ChangeAgent.agentTypes.salesAgent}
                agents={agents}
                agent={generalInfo.salesAgent}
                clientId={generalInfo.id}
              />
              }
            </Col>
            <Col xs={4}>
              { clientConverted &&
                <ChangeAgent
                  agentType={ChangeAgent.agentTypes.supportAgent}
                  agents={partnerAgents}
                  agent={generalInfo.supportAgent}
                  clientId={generalInfo.id}
                />
              }
            </Col>
            <Col xs={4}>
              { !canOnlyViewExternalAssignments(viewer) &&
                <ChangeAgent
                  agentType={ChangeAgent.agentTypes.externalAgent}
                  agents={agents}
                  agent={generalInfo.externalAgent}
                  clientId={generalInfo.id}
                />
              }
            </Col>
          </Row>
          {!canQueryAffiliateManagerClients(viewer) && this.renderClientNotes()}
        </Col>

        <Col xs={4}>
          <Row>
            <Col xs={12} style={{paddingRight: 0}}>
              <ButtonToolbar className="float-right mb-2">
                <Button
                  size="sm"
                  onClick={(e) => this.setState({startCall: true})}
                  variant="outline-secondary"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon="phone" /> Call
                </Button>
                <Button
                  size="sm"
                  href={'mailto:' + email} variant="outline-secondary"
                  className="mr-2"
                >
                  <FontAwesomeIcon icon="envelope" /> Email
                </Button>
                <Button
                  size="sm"
                  variant="outline-secondary"
                  onClick={() => this.context.toggleAppointmentEditor({
                    clientId: id,
                    client: {id, firstName, lastName, company},
                    userId: viewer.id,
                    user: {id: viewer.id},
                  }, this.context.clientProvider.subProviders.appointments)}
                >
                  <FontAwesomeIcon icon="calendar" /> Reminder
                </Button>
              </ButtonToolbar>
            </Col>
            <Col xs={12} style={{paddingRight: 0}}>
              <div className={style.topBar}>
                <div id="t-client-type-button" className={style.topBarButton}>
                  <DropdownButton
                    disabled={!canWriteClientDetails(this.props.viewer)}
                    title={clientType ? clientTypes[clientType].label : <span>&nbsp;</span>}
                    onSelect={(event) => this.changeClientType(event)}
                    id="client-type"
                    variant={this.getClientTypeBtnStyle(clientType)}
                  >
                    {map(clientTypes, (opt, i) =>
                      <Dropdown.Item
                        className="t-client-type-menu-item"
                        key={opt.key}
                        eventKey={opt.key}
                      >
                        {opt.label}
                      </Dropdown.Item>
                    )}
                  </DropdownButton>
                </div>
                <div className={style.topBarButton}>
                  { clientConverted &&
                    <div>
                      <TradingStatus
                        uiDispatch={this.props.uiDispatch}
                        uiState={this.props.uiState}
                        clientId={id}
                        tradingStatus={tradingStatus}
                        tradingStatusReason={tradingStatusReason}
                        tradingStatusReasonCode={tradingStatusReasonCode}
                      />
                    </div>
                  }
                </div>
              </div>
            </Col>
            <Col xs={12} style={{paddingRight: 0}}>
              <div className={style.topBar}>
                <div id="t-client-conversion-step-button" className={style.topBarButton}>
                  <DropdownButton
                    disabled={true}
                    title={conversionStep ? conversionSteps[conversionStep].label : <span>&nbsp;</span>}
                    id="conversion-step"
                    variant="outline-secondary"
                  >
                  </DropdownButton>
                </div>
                <div id="t-client-conversion-status-button" className={style.topBarButton}>
                  <DropdownButton
                    disabled={!canWriteClientConversionStatus(this.props.viewer)}
                    title={conversionStatus ? conversionStatuses[conversionStatus].label : <span>&nbsp;</span>}
                    onSelect={(event) => this.changeConversionStatus(event)}
                    id="conversion-status"
                    variant="outline-secondary"
                  >
                    {Object.values(availableConversionStatuses).map((opt, i) =>
                      <Dropdown.Item
                        className={`t-client-conversion-status-menu-item-${opt.key}`}
                        key={opt.key}
                        eventKey={opt.key}
                      >
                        {opt.label}
                      </Dropdown.Item>
                    )
                    }
                  </DropdownButton>
                </div>
              </div>
            </Col>
            <Col xs={12} style={{paddingRight: 0}}>
              <ButtonToolbar className="float-right mb-2">
                {canLoginAsAnotherClient(viewer) && <Button
                  size="sm"
                  onClick={() => this.handleLogInAsClientClick()}
                  variant="outline-secondary"
                  className="mr-2"
                >
                  Login to dashboard
                </Button>}
                {canDeleteClient(viewer) && <Button
                  size="sm"
                  variant="danger"
                  onClick={() => this.handleDeleteMemberClick()}
                >
                  <FontAwesomeIcon icon="times" /> Delete Client
                </Button>}
              </ButtonToolbar>
            </Col>
          </Row>
          <NewCallModal
            show={this.state.startCall}
            phone={phone}
            secondaryPhones={secondaryPhones}
            onCancel={(e) => this.setState({startCall: false})}
            onConfirm={(e, phone, phoneType) => {this.call(phone, phoneType)}}
          />
          <DeleteMemberModal
            show={this.state.showDeleteMemberModal}
            onCancel={() => this.confirmDeleteMember(false)}
            onConfirm={() => this.confirmDeleteMember(true)}
          />
          <LogInAsClientModal
            show={this.state.showLoginAsClientModal}
            onCancel={() => this.confirmLoginAsClient(false)}
            onConfirm={(loginReason) => this.confirmLoginAsClient(true, loginReason)}
          />
          <UnderMonitoringModal
            show={this.state.showUnderMonitoringModal}
            onCancel={() => this.closeUnderMonitoringModal()}
            onSave={(underMonitoring, selectedReasonCode, reason) =>
              this.toggleUnderMonitoring(underMonitoring, selectedReasonCode, reason)}
          />
          <dl className={`dl-horizontal float-right ${style.dates}`}>
            <dt className={style.datesdt}>Signed Up</dt>
            <dd className={style.datesdd} title={registrationReadable}>{registrationFromNow}</dd>
            <dt className={style.datesdt}>Termination Date</dt>
            <dd className={style.datesdd} title={terminationDateReadable}>{terminationDateReadable}</dd>
            <dt className={style.datesdt}>KYC Deadline</dt>
            <dd className={style.datesdd} title={kycDeadLineReadable}>{kycDeadLineFromNow}</dd>
            <dt className={style.datesdt}>Can Transfer Funds</dt>
            <dd className={style.datesdd}>
              <input id="toggle-allow-transfers" type="checkbox"
                className={classnames([style.toggleSwitch, style.toggleLight, 'float-right'])}
                checked={this.state.allowTransfers}
                disabled={!canToggleAllowTransfers(viewer)}
                onChange={(e) =>
                  this.toggleAllowTransfers(e.target.checked)
                }
              />
              <label id="t-toggle-allow-transfers" htmlFor="toggle-allow-transfers"
                className={classnames([style.toggleButton, 'float-right'])}
              />
            </dd>
            <dt className={style.datesdt} style={{marginTop: 6}}>Under Monitoring</dt>
            <dd className={style.datesdd}>
              <OverlayTrigger
                placement="left"
                onToggle={(nextShow) => {
                  if (nextShow) {
                    this.setState({showPopup: generalInfo.underMonitoring && underMonitoringReason})
                  } else {
                    this.setState({showPopup: nextShow})
                  }
                }}
                show={!!this.state.showPopup}
                overlay={
                  <Tooltip>
                    {`${underMonitoringReason}${generalInfo.underMonitoringReason ? ` - ${generalInfo.underMonitoringReason}` : ''}`}
                  </Tooltip>
                }
              >
                {({ref, ...triggerHandler}) => (
                  <span>
                    <input
                      id="toggle-monitor-client"
                      type="checkbox"
                      className={classnames([style.toggleSwitch, style.toggleLight, 'float-right'])}
                      checked={this.state.underMonitoring}
                      disabled={!canToggleUnderMonitoring(viewer)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          return this.openUnderMonitoringModal()
                        } else {
                          this.toggleUnderMonitoring(e.target.checked)
                        }
                      }}
                    />
                    <label id="t-toggle-monitor-client" htmlFor="toggle-monitor-client"
                      ref={ref}
                      {...triggerHandler}
                      // title={`${underMonitoringReason}${generalInfo.underMonitoringReason ? ` - ${generalInfo.underMonitoringReason}` : ''}`}
                      className={classnames([style.toggleButton, 'float-right'])}
                    />
                  </span>
                )}
              </OverlayTrigger>
            </dd>
            <dt className={style.datesdt} style={{marginTop: 6}}>Manual Withdrawals</dt>
            <dd className={style.datesdd}>
              &nbsp;
              <input id="toggle-manual-withdrawals" type="checkbox"
                className={classnames([style.toggleSwitch, style.toggleLight, 'float-right'])}
                checked={this.state.manualWithdrawals}
                onChange={(e) => this.toggleManualWithdrawals(e.target.checked)}
                disabled={!canToggleManualWithdrawals(viewer)}
              />
              <label id="t-toggle-manual-withdrawals" htmlFor="toggle-manual-withdrawals"
                className={classnames([style.toggleButton, 'float-right'])}
              />
            </dd>
          </dl>
          <div>
            <InputGroup>
              <Form.Control
                className={`${style.searchBox} float-right`}
                variant={searchValidationState}
                id="clients-search"
                type="text"
                value={searchText}
                placeholder="Search Clients"
                onChange={this.searchInputChange}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doSearch()) ||
                  (e.key === 'Escape' && this.clearSearch(e))
                )}
              />
              <InputGroup.Append>
                <InputGroup.Text>
                  <FontAwesomeIcon className="clickable" icon="search" onClick={() => this.doSearch()} />
                </InputGroup.Text>
              </InputGroup.Append>
            </InputGroup>
          </div>
        </Col>
      </Row>
    )
  }
})
