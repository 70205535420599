import {getPageSize, getOffset} from '../useful'
import {isEmpty} from 'lodash'

export const pushNotificationsProvider = {
  getQuery: (props) => {
    try {
      const {pushNotificationsSort, pushNotificationsPage, typeFilter} = props

      const cpart1 = !isEmpty(typeFilter) ? `type: ${typeFilter}` : ''
      const condition = [cpart1].join('\n').trim()

      const query = `{
        pushNotifications (
          limit: ${getPageSize()}
          offset: ${getOffset(pushNotificationsPage)}
          orderBy: ${pushNotificationsSort.orderBy}
          orderDirection: ${pushNotificationsSort.orderDirection}
          ${condition.length ? condition : ''}
        ) {
          id
          jobId
          defaultLanguage
          body
          createdBy {
            id
          }
          createdAt
          status
          type
        }
        pushNotificationsCount${condition ? `(${condition})` : ''}
      }`
      return query
    } catch (e) {
      throw e
    }

  },

  onData: (res, dispatch, props) => dispatch(
    'Push Notifications Loaded',
    (state, res) => ({
      ...state,
      pushNotifications: res.pushNotifications,
      pushNotificationsCount: res.pushNotificationsCount,
    }),
    [res]
  ),
}
