import React from 'react'
import {shallowEqual, equals} from './stateUtils'
import lodash from 'lodash'

export default class PureComponent extends React.Component {

  shouldComponentUpdate(nextProps, nextState) {
    if (this.stateRef != null && !equals(this.stateRef, this.stateCopy)) {
      console.error('you shouldn\'t mutate the state!', //eslint-disable-line no-console
        this.stateCopy, this.stateRef)
      throw new Error('You mutated the component state. You really shouldn\'t!')
    }
    this.stateRef = this.state
    this.stateCopy = lodash.cloneDeep(this.state)

    if (shallowEqual(this.props, nextProps) && shallowEqual(this.state, nextState)) {
      return false
    } else {
      return true
    }
  }
}
