import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {List as Loading} from 'react-content-loader'
import {ButtonToolbar, Button, Form, Row, Col, Tab, Tabs, InputGroup} from 'react-bootstrap'
import {get, isEmpty, pickBy, omitBy, isString, isNil, filter, has, find, includes, map, first,
  isFunction, orderBy, some, union, difference, remove, sumBy, values, sortBy, reject, cloneDeep,
} from 'lodash'
import {canCreateWithdrawals} from '@bdswiss/common-permissions'
import {withdrawalStatuses, withdrawalTypes, accountTypes, withdrawalPaymentFields, withdrawalPaymentVendors,
  currencies, depositVendors, refundStatuses, countries,
} from '@bdswiss/common-enums'
import {setIn} from '../stateUtils'
import Events from '../client/Events'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import {isKey, keys} from '../utils/keyboard'
import FontAwesomeIcon from './FontAwesomeIcon'
import {renderCloseTab} from '../utils/rendering'
import {fxRatesProvider} from '../fxrates/providers'
import StylishSelect from '../components/StylishSelect'
import {createClientWithdrawalsUrl} from '../utils/links'
import {dynamicFieldsProvider} from '../withdrawals/providers'
import TextareaAutosizeInput from '../components/TextareaAutosizeInput'
import {compose, provideProps, uiMount, mountDataProviders} from '../decorators'
import {getFullName, unassigned, isNumeric, isPositiveNumber, readableDate,
  getObjectActivityLogs, getDepositRemainingAmount, getFormattedAmount} from '../useful'
import {isEmptyStr, safeParseJSON, getRelatedDeposits, parseStringAsJson} from '../common/utils.js'
import {getAccountLabel, isCryptoVendor, formatAccountValue, getCountryOfResidence, getWithdrawalVendorLabel} from '../utils/general'


// This is workaroud - root cause is that client argument is generated by two different providers
// by withdrawals provider and client provider. They have slightly different structure. The function
// make sure the rest of component does not have to deal with differences nor know about them. It should
// make refactoring easier once we will be doing it.
function getClientId(client) {
  return client.generalInfo ? client.generalInfo.id : client.id
}

const withdrawalTypesPermittedForUser = pickBy(withdrawalTypes,
  (t) => t.userCreatePermitted)

const EventsMounted = uiMount(() => ['withdrawalEditorUi', 'Events'])(Events)

class WithdrawalEditor extends PureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    fxRatesProvider: PropTypes.object.isRequired,
    clientProvider: PropTypes.object.isRequired,
  };

  static propTypes = {
    client: PropTypes.object,
    withdrawal: PropTypes.object,
    activityLogs: PropTypes.array,
    hideClient: PropTypes.bool,
    hideActivityLog: PropTypes.bool,
    clientAccounts: PropTypes.array,
    onClose: PropTypes.func,
    // callback will be called after new withdrawal is created
    // so that caller can refetch data
    onCreate: PropTypes.func,
    // callback will be called after an update is processed on withdrawals
    //so that caller can refetch data
    onUpdate: PropTypes.func,
  };

  disableCreateButton() {
    this.setState({createButtonDisabled: true})
  }

  componentWillMount() {
    this.setDefaultState(this.props)
  }

  componentWillReceiveProps(nextProps) {
    this.setDefaultState(nextProps)
  }

  componentDidMount() {
    const {dataFetched, dataLoading, dataError} = this.state
    if (!dataLoading && !dataError && !dataFetched && !this.props.client.withdrawalAccounts) {
      if (this.context.clientProvider) {
        this.setState({dataLoading: true}, () => {
          this.context.clientProvider.subProviders.withdrawalAccounts.fetch()
            .then(() => this.setState({dataLoading: false, dataFetched: true}))
            .catch((e) => this.setState({dataLoading: false, dataError: e}))
        })
      }
    }
  }

  setDefaultState(props) {
    const {withdrawal, client: {withdrawalAccounts}} = props
    const modeShowExisting = withdrawal && ('id' in withdrawal)
    const form = {withdrawalFee: 0, withdrawalNetAmount: 0}
    const paymentFields = safeParseJSON(get(withdrawal, 'paymentFields'))
    let refundedDeposits = []
    if (modeShowExisting) {
      refundedDeposits = get(safeParseJSON(withdrawal.meta || '{}'), 'refundedDeposits') || []
      Object.values(withdrawalPaymentFields).forEach((f) => {
        if (has(paymentFields, f.key)) {
          if (f.key === 'cardExpiryYear' || f.key === 'cardExpiryMonth') {
            form[f.key] = String(get(paymentFields, f.key, '') || '')
          } else {
            form[f.key] = get(paymentFields, f.key, '') || ''
          }
        } else {
          form[f.key] = withdrawal[f.key] || ''
        }
      })
      form.withdrawalReason = get(withdrawal, 'withdrawalReason', '')
      form.withdrawalReasonText = get(withdrawal, 'withdrawalReasonText', '')
      form.formPaymentVendor = get(withdrawal, 'vendor', get(withdrawal, 'paymentVendor', ''))
      form.id = withdrawal.id
      form.type = withdrawal.withdrawalType || withdrawal.type
      form.status = withdrawal.status
      form.bankCode = get(paymentFields, 'bankCode', get(withdrawal, 'paymentFields.bankCode', ''))
      form.comments = get(withdrawal, 'comments', '')
      form.isChanged = false
      form.withdrawalFee = get(paymentFields, 'withdrawalFee', get(withdrawal, 'paymentFields.withdrawalFee', 0))
      form.refunds = map(refundedDeposits, (r) => r.depositId)
    }

    const fromAccountOptions = reject(map(withdrawalAccounts, (account) => {
      const remoteId = account.remoteId
      const currency = account.currency
      return {
        value: account.id,
        label: `${getAccountLabel(accountTypes[account.__typename])} [${remoteId}] - ${currency} - Balance: ${formatAccountValue(account, 'balance')}`,
        isDemo: account.isDemo,
        balance: account.balance,
        currency: account.currency,
      }
    }), (a) => a.isDemo || (!modeShowExisting && !a.balance))

    // if there is only one fromAccount option, prefill it
    if (fromAccountOptions.length === 1) {
      form.fromAccount = first(fromAccountOptions).value
    }

    this.setState({form, formError: {}, modeShowExisting, fromAccountOptions, refundedDeposits,
      remainingAmount: 0, loading: false})
  }

  formValueChanged(name, value) {
    let state = this.state

    if (name === 'type' && value !== 'withdrawal') {
      const bankAccount = {
        iban: null,
        swift: null,
        holderName: null,
        bankName: null,
      }

      state = setIn(state, ['form'], {...state.form, ...bankAccount})
      state = setIn(state, ['formError'], {...state.formError, ...bankAccount})
    }

    if (name === 'amount' || name === 'withdrawalFee') {
      const {fromAccountOptions, form: {fromAccount}} = this.state
      const account = find(fromAccountOptions, {value: fromAccount})
      const currency = this.props.withdrawal
        ? this.props.withdrawal.currency
        : account.currency
      const isCrypto = get(currencies[currency], 'isCrypto', false)
      const withdrawalFee = Number(name === 'withdrawalFee' ? value : state.form.withdrawalFee)
      const amount = Number(name === 'amount' ? value : state.form.amount)
      const withdrawalNetAmount = Number((amount - withdrawalFee).toFixed(isCrypto ? 8 : 2))
      state = setIn(state, ['form', 'withdrawalNetAmount'], withdrawalNetAmount)
    }

    if (
      (
        (name === 'formPaymentVendor' &&
          [withdrawalPaymentVendors.thunderxpay.key, withdrawalPaymentVendors.dusupay.key].includes(value)
        ) || name === 'paymentOption'
      ) &&
      !this.props.withdrawal
    ) {
      this.setState({loading: true})
      this.props.actions.paymentOptions.getDynamicFields(this.state.form.formPaymentVendor || value, this.props.client.personalDetails.id)
        .then((res) => {
          const data = get(res, 'dynamicWithdrawalFields.fields')
          this.setState({dynamicFields: safeParseJSON(data), loading: false})
        })
        .catch(this.context.logError)
    }

    state = setIn(state, ['form', name], value)
    state = setIn(state, ['formError', name], null)
    state = setIn(state, ['form', 'isChanged'], true)
    this.setState(state)
  }

  setFormErrors(errors) {
    let state = this.state
    state = setIn(state, ['formError'], errors)
    this.setState(state)
  }

  filterFormFields(fields, paymentVendor, modeShowExisting, args) {
    const paymentFields = omitBy(withdrawalPaymentFields, (i) => i.key === 'termsAndConditions')
    return Object.values(modeShowExisting ? withdrawalPaymentFields : paymentFields).filter((field) =>
      (
        (field.visible && field.visible(fields)) ||
        field.commonField || field.isInternal ||
        (field.paymentVendors && field.paymentVendors.includes(paymentVendor) && !(field.hidden && field.hidden(args)))
      ) && (
        ((field.hidden && !field.hidden(args)) || !field.hidden)
      )
    )
  }

  validateForm(modeShowExisting) {
    const form = this.state.form
    const result = {}

    if (!isNumeric(form.fromAccount) && !modeShowExisting) {
      result.fromAccount = 'error'
    }

    if (isEmptyStr(form.type) && !modeShowExisting) {
      result.type = 'error'
    }

    if (!isPositiveNumber(form.amount)) {
      result.amount = 'error'
    }

    if (!isPositiveNumber(form.withdrawalNetAmount)) {
      result.withdrawalNetAmount = 'error'
    }

    if (form.type === 'withdrawal') {
      if (!form.formPaymentVendor) {
        result.formPaymentVendor = 'error'
      }
      const country = getCountryOfResidence(this.props.client)
      const fields = this.filterFormFields(form, form.formPaymentVendor, modeShowExisting, {country, paymentOption: form.paymentOption})
      fields.forEach((field) => {
        if (field.key === 'withdrawalFee' && modeShowExisting) return
        if (field.key === 'termsAndConditions' || field.key === 'withdrawalNetAmount') return
        const invalid = isString(form[field.key]) ? isEmptyStr(form[field.key]) : isNil(form[field.key])
        if (invalid) {
          result[field.key] = 'error'
        }
      })
    }

    return isEmpty(result) ? null : result
  }

  createNewWithdrawal(form) {
    const {actions, onCreate} = this.props

    const {withdrawalFee = 0, transferToAccount} = form
    const withdrawalNetAmount = form.amount - withdrawalFee

    const data = {
      amount: form.amount,
      accountId: form.fromAccount,
      paymentFields: {termsAndConditions: '', withdrawalFee, withdrawalNetAmount, transferToAccount},
      type: form.type,
    }
    if (form.type === 'withdrawal') {
      data.paymentVendor = form.formPaymentVendor
      data.withdrawalReason = form.withdrawalReason
      data.withdrawalReasonText = form.withdrawalReasonText
    }
    const paymentFields = omitBy(withdrawalPaymentFields,
      (f) => f.key === 'amount' || f.key === 'withdrawalReason' || f.key === 'withdrawalReasonText')
    Object.values(paymentFields).forEach((f) => {
      if (form[f.key] && form[f.key] !== 'formPaymentVendor') {
        data.paymentFields[f.key] = isString(form[f.key]) ? form[f.key].trim() : form[f.key]
      }
    })
    return actions.client.createWithdrawal(data)
      .then((res) => {
        onCreate && onCreate()
      })
      .catch(this.context.logError)
  }

  updateWithdrawal() {
    const {actions, onUpdate} = this.props
    const form = this.state.form
    const data = {
      amount: form.amount,
      status: withdrawalStatuses[form.status].key,
      id: form.id,
      comments: form.comments,
      paymentFields: {},
    }

    const paymentFields = omitBy(withdrawalPaymentFields,
      (f) => includes(['amount', 'withdrawalReason', 'withdrawalReasonText'], f.key))
    Object.values(paymentFields).forEach((f) => {
      if (form[f.key] && form[f.key] !== 'formPaymentVendor') {
        data.paymentFields[f.key] = isString(form[f.key]) ? form[f.key].trim() : form[f.key]
      }
    })
    if (!isEmpty(this.state.refundedDeposits)) {
      data.meta = {refundedDeposits: this.state.refundedDeposits || []}
    }

    return actions.client.updateWithdrawal(data)
      .then((res) => {
        onUpdate && onUpdate()
      })
      .catch(this.context.logError)

  }

  isTypeNotWithdrawal() {
    return this.state.form.type !== 'withdrawal'
  }

  isEditable() {
    return !isNil(find(withdrawalStatuses,
      {key: this.state.form.status, userUpdatable: true}))
  }

  canEditRefunds() {
    const {withdrawal} = this.props
    const refundedDeposits = get(safeParseJSON(withdrawal.meta || '{}'), 'refundedDeposits') || []
    return withdrawalStatuses.accepted.value === this.state.form.status && !isEmpty(refundedDeposits)
  }

  changeStatusOptions() {
    let options = {}
    if (this.isEditable()) {
      options = filter(withdrawalStatuses, {userUpdatable: true})
    } else {
      options = withdrawalStatuses
    }
    return options
  }

  refundsChanged(selected, depositId) {
    const {form: {refunds}} = this.state
    const {withdrawal, deposits, withdrawals, fxRates} = this.props
    const withdrawalsExceptCurrent = filter(withdrawals, (w) => w.id !== withdrawal.id)

    if (selected) {
      refunds.push(depositId)
    } else {
      remove(refunds, (id) => id === depositId)
    }

    const receipt = parseStringAsJson(withdrawal.receipt || '{}')
    const processedDeposits = Object.keys(receipt)
    const withdrawalNetAmount = get(withdrawal, 'paymentFields.withdrawalNetAmount') || get(withdrawal, 'amount')
    let amount = withdrawalNetAmount
    const refundedDeposits = []
    refunds.forEach((id) => {
      const deposit = find(deposits, (d) => d.id === id)
      const isProcessed = some(processedDeposits, (d) => Number(d) === deposit.id)
      const depositRemainingAmount = getDepositRemainingAmount(withdrawalsExceptCurrent, deposit, withdrawal,
        isProcessed)
      const rate = Number(get(fxRates, `${deposit.currency}[${withdrawal.currency}]`) || 1)
      const depositAmountConverted = Number((deposit.amount * rate).toFixed(2))
      const depositRemainingAmountConverted = Number((depositRemainingAmount * rate).toFixed(2))
      const refundStatus = (depositAmountConverted <= amount && depositRemainingAmount === deposit.amount)
        ? refundStatuses.full.value : refundStatuses.partial.value
      const refundedAmount = depositRemainingAmountConverted <= amount
        ? depositRemainingAmount
        : Number((amount / rate).toFixed(2))
      refundedDeposits.push({
        depositId: id,
        refundStatus,
        refundedAmount,
        amountConverted: true,
      })
      amount = Number((amount - depositRemainingAmountConverted).toFixed(2))
    })
    const selectedDeposits = values(omitBy(deposits, (d) => !refunds.includes(d.id)))
    const totalAmount = sumBy(selectedDeposits, (d) => {
      const isProcessed = some(processedDeposits, (pd) => Number(pd) === d.id)
      const depositRemainingAmount = getDepositRemainingAmount(withdrawalsExceptCurrent, d, withdrawal,
        isProcessed)
      const rate = Number(get(fxRates, `${d.currency}[${withdrawal.currency}]`) || 1)
      return Number((depositRemainingAmount * rate).toFixed(2))
    }) || 0
    const remainingAmount = Number((withdrawalNetAmount - totalAmount).toFixed(2))

    let state = this.state
    state = setIn(state, ['form', 'refunds'], refunds)
    state = setIn(state, ['form', 'refundsChanged'], true)
    this.setState({
      ...state,
      refundedDeposits,
      remainingAmount: remainingAmount < 0 ? 0 : remainingAmount,
    })
  }

  renderCryptoFields(withdrawal) {
    const {paymentFields: {cryptoAmount, exchangeRate}} = withdrawal

    return (
      <Row>
        <Col xs={12}>
          <Form.Control
            disabled
            type="text"
            title="Crypto Amount"
            label="Crypto Amount"
            defaultValue={cryptoAmount}
          />
        </Col>
        <Col xs={12}>
          <Form.Control
            disabled
            type="text"
            title="Exchange Rate"
            label="Exchange Rate"
            defaultValue={exchangeRate}
          />
        </Col>
      </Row>
    )
  }

  renderTransferFields(showExisting, withdrawal) {
    const {client: {withdrawalAccounts}} = this.props
    const transferType = find(withdrawalTypes, {key: get(this, 'state.form.type'), category: 'transfer'})
    if (!transferType) return

    const fromAccount = get(this, 'state.form.fromAccount')
    const transferToAccount = get(this, 'state.form.transferToAccount')
    const toAccountOptions = filter(withdrawalAccounts, (a) => a.id !== fromAccount && !a.isDemo).map((a) => {
      const remoteId = a.remoteId
      const currency = a.currency
      return {
        value: a.id,
        label: `${get(accountTypes[a.__typename], 'label')} [${remoteId}] - ${currency}`,
      }
    })

    if (withdrawal && withdrawal.memberId  !== get(withdrawal.transferAccount, 'memberId')) {
      const externalAccountOption = {
        value: withdrawal.transferAccount.id,
        label: `${get(accountTypes[withdrawal.transferAccount.type], 'label')} [${withdrawal.transferAccount.remoteId}] - ${withdrawal.transferAccount.firstName} ${withdrawal.transferAccount.lastName}`,
      }
      toAccountOptions.push(externalAccountOption)
    }
    const isB2B = transferType.value === withdrawalTypes.transferb2b.value

    return (
      <Col xs={12}>
        {isB2B
          ? <Form.Group>
            <Form.Label>Destination Account</Form.Label>
            <Form.Control
              id="t-withdrawal-editor-destination-account"
              defaultValue={transferToAccount}
              onChange={(e) => {
                this.formValueChanged('transferToAccount', Number(e.target.value))
              }}
              disabled={showExisting}
              type="text"
            />
          </Form.Group>
          : <StylishSelect.Input
            id="t-withdrawal-editor-destination-account"
            label="Destination Account"
            value={transferToAccount}
            onChange={(e) => {
              this.formValueChanged('transferToAccount', Number(e.value))
            }}
            disabled={showExisting}
            placeholderText="Choose Account"
            options={toAccountOptions}
          />
        }
      </Col>
    )
  }

  render() {
    const {onClose, onCreate, hideClient, withdrawal, client, hideActivityLog, viewer,
      withdrawals, deposits, dynamicFields: propsDynamicFields,
    } = this.props
    const {modeShowExisting, fromAccountOptions, refundedDeposits, remainingAmount: remainingWDAmount,
      form, loading, dynamicFields: stateDynamicFields, dataLoading, dataError
    } = this.state

    if (dataLoading) {
      return <Loading speed={1} style={{padding:'20px'}} />
    }
    if (dataError) return <pre> Error Loading Accounts : {dataError.message} </pre>

    const clientVps = get(client, 'personalDetails.vps', client.vps)
    const salesAgent = get(client, 'generalInfo.salesAgent', '') || get(client, 'salesAgent', '')
    const availableWithdrawalOptions = clientVps ?
      withdrawalTypesPermittedForUser : omitBy(withdrawalTypesPermittedForUser, {key: 'vps_fee'})
    const activityLogVisible = modeShowExisting && !hideActivityLog
    const isTransfer = !!find(withdrawalTypes, {key: get(this, 'state.form.type'), category: 'transfer'})

    const saveDisabled = (modeShowExisting && (!this.isEditable() || !form.isChanged)) && !form.refundsChanged
    const dynamicFields = stateDynamicFields || propsDynamicFields
    const toolbar = (
      <Row className={style.toolbar}>
        <Col xs={12}>
          <ButtonToolbar className="float-right">
            {modeShowExisting ? [<Button
              id="t-withdrawal-editor-close-button"
              key={1}
              variant="outline-secondary"
              className="mr-2"
              tabIndex={-1}
              onClick={() => onClose && onClose()}
            >
              Cancel
            </Button>,
            canCreateWithdrawals(viewer) && (<Button
              id="t-withdrawal-editor-save-button"
              key={2}
              variant="success"
              disabled={saveDisabled}
              onClick={() => {
                const errors = this.validateForm(modeShowExisting)
                if (errors == null) {
                  this.disableCreateButton()
                  this.updateWithdrawal()
                    .then(() => onClose && onClose())
                } else {
                  this.setFormErrors(errors)
                }
              }}
            >
              Save
            </Button>)] : [
              <Button
                id="t-withdrawal-editor-close-button"
                variant="outline-secondary"
                className="mr-2"
                key={1}
                tabIndex={-1}
                onClick={() => onClose && onClose()}
              >Cancel
              </Button>,
              <Button
                id="t-withdrawal-editor-create-button"
                key={2}
                variant="success"
                disabled={this.state.createButtonDisabled}
                onClick={() => {
                  const errors = this.validateForm(modeShowExisting)
                  if (errors == null) {
                    this.disableCreateButton()
                    this.createNewWithdrawal(this.state.form)
                      .then(() => onClose && onClose())

                  } else {
                    this.setFormErrors(errors)
                  }
                }}
              >
                Create
              </Button>]}
          </ButtonToolbar>
        </Col>
      </Row>
    )

    let accountId, statusOptions, mappedDeposits, formattedAmount = 0

    if (modeShowExisting) {
      accountId = get(withdrawal, 'account.id')
      statusOptions = this.changeStatusOptions()
      const receipt = parseStringAsJson(get(withdrawal, 'receipt') || '{}')
      const processedDeposits = Object.keys(receipt)
      const withdrawalsExceptCurrent = filter(withdrawals, (w) => w.id !== withdrawal.id)

      const relatedDeposits = orderBy(getRelatedDeposits(withdrawal, deposits, false), ['id'], 'desc')
      const selectedDeposits = filter(deposits, (d) => some(refundedDeposits, (r) => r.depositId === d.id))

      mappedDeposits = union(relatedDeposits, selectedDeposits).map((deposit) => {
        const isProcessed = some(processedDeposits, (d) => Number(d) === deposit.id)
        const depositRemainingAmount = getDepositRemainingAmount(withdrawalsExceptCurrent, deposit, withdrawal,
          isProcessed)
        if (depositRemainingAmount === 0 && !includes(selectedDeposits, deposit)) return null
        const refundedDeposit = find(refundedDeposits, (d) => d.depositId === deposit.id)
        const amount = getFormattedAmount({
          currency: deposit.currency,
          amount: get(refundedDeposit, 'refundedAmount', 0),
        })
        const depositVendor = get(find(depositVendors, {value: get(deposit, 'vendor', get(deposit, 'payment.vendor'))}), 'label')
        const depositLabel = `${deposit.id} (${depositVendor})`
        let refundLabel = ''
        if (get(refundedDeposit, 'refundStatus') === refundStatuses.full.value) {
          refundLabel = `- Full Refund (${amount})`
        } else if (get(refundedDeposit, 'refundStatus') === refundStatuses.partial.value) {
          refundLabel = `- Partial Refund (${amount})`
        }
        const remainingAmount = getFormattedAmount({
          amount: depositRemainingAmount,
          currency: deposit.currency,
        })
        const remainingAmountLabel = depositRemainingAmount !== deposit.amount && depositRemainingAmount > 0
          ? `(Remaining Amount: ${remainingAmount})` : ''

        const processedLabel = isProcessed ? ' - Processed' : ''

        return {
          value: deposit.id,
          label: `${depositLabel} - ${getFormattedAmount(deposit)} ${remainingAmountLabel} ${refundLabel} ${processedLabel}`, // eslint-disable-line max-len
          disabled: (!includes(selectedDeposits, deposit) && remainingWDAmount === 0) || isProcessed,
        }
      }).filter((d) => d)

      formattedAmount = getFormattedAmount({currency: withdrawal.currency, amount: remainingWDAmount})
    }

    const header = !modeShowExisting ? 'New Withdrawal' : `Withdrawal #${withdrawal.id || ''}`
    const tabsClassName = activityLogVisible ? '' : 'withdrawalEditorNoActivityLog'
    const paymentVendor = modeShowExisting ? (withdrawal.vendor ||  withdrawal.paymentVendor) : this.state.form.formPaymentVendor
    const country = getCountryOfResidence(client)
    const filterFormFields = this.filterFormFields(this.state.form, paymentVendor, modeShowExisting, {country, paymentOption: form.paymentOption})
    const withdrawalObj = cloneDeep(withdrawal)
    if (withdrawalObj) withdrawalObj.paymentFields = safeParseJSON(withdrawalObj.paymentFields)
    return (
      <Tabs id="t-withdrawal-selected" className={tabsClassName}>
        {activityLogVisible && (
          <Tab title="Notes" eventKey="notes">
            <EventsMounted
              clientId={getClientId(client)}
              withdrawalId={withdrawal.id}
              activityLogs={getObjectActivityLogs(withdrawalObj, 'objectWithdrawal', this.props.activityLogs)}
              onCreate={() => {
                onCreate && onCreate()
                this.props.fetchActivityLogs && this.props.fetchActivityLogs(withdrawal.id)
              }}
            />
          </Tab>
        )}

        <Tab title={<strong>{header}</strong>} eventKey="withdrawal">
          {hideClient ||
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Full Name</Form.Label>
                  <InputGroup>
                    <Form.Control
                      id="t-withdrawal-editor-full-name"
                      type="text"
                      title="Full Name"
                      defaultValue={getFullName(client)}
                      disabled={modeShowExisting}
                    />
                    <InputGroup.Append>
                      <Button
                        key={2}
                        id="t-open-withdrawal-client"
                        title="Open"
                        variant="outline-secondary"
                        onClick={() => {
                          const url = createClientWithdrawalsUrl(getClientId(client), withdrawal.id)
                          this.context.router.push(url)
                        }}
                      >
                        <FontAwesomeIcon icon="arrow-right" />
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          }
          <Row>
            <Col xs={12}>
              <StylishSelect.Input
                id="t-withdrawal-editor-account"
                label="Account"
                value={modeShowExisting ? accountId : this.state.form.fromAccount}
                variant={this.state.formError.fromAccount}
                disabled={modeShowExisting}
                onChange={(e) => {
                  this.formValueChanged('fromAccount', Number(e.value))
                }}
                placeholderText="Choose Account"
                options={fromAccountOptions}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <StylishSelect.Input
                id="t-withdrawal-editor-type"
                label="Type"
                value={this.state.form.type}
                variant={this.state.formError.type}
                disabled={modeShowExisting}
                onChange={(e) => {
                  this.formValueChanged('type', e.value)
                }}
                placeholderText="Choose Type"
                options={
                  StylishSelect.enumToStylishOptions(availableWithdrawalOptions, modeShowExisting ? '' : null)
                }
              />
            </Col>
          </Row>
          {isTransfer &&
            <Row>
              {this.renderTransferFields(modeShowExisting, withdrawalObj)}
            </Row>
          }
          <Row>
            <Col xs={12}>
              <StylishSelect.Input
                id="t-withdrawal-editor-payment-method"
                label="Payment Method"
                value={paymentVendor}
                variant={this.state.formError.formPaymentVendor}
                disabled={modeShowExisting || this.isTypeNotWithdrawal()}
                onChange={(e) => {
                  this.formValueChanged('formPaymentVendor', e.value)
                }}
                placeholderText="Choose Payment Method"
                options={Object.values(withdrawalPaymentVendors)
                  .map((i) => ({value: i.key, label: getWithdrawalVendorLabel(i.key)}))}
              />
            </Col>
          </Row>
          {modeShowExisting && isCryptoVendor(withdrawal.vendor || withdrawal.paymentVendor)
            && this.renderCryptoFields(withdrawal)}
          {modeShowExisting &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  id="t-withdrawal-editor-payment-currencies"
                  label="Currency"
                  value={withdrawal.currency}
                  disabled
                  placeholderText="Choose Currency"
                  onChange={(e) => {
                    this.formValueChanged('currency', e.value)
                  }}
                  options={Object.values(currencies)
                    .map((i) => ({value: i.key, label: i.label}))}
                />
              </Col>
            </Row>
          }
          {loading &&
            <i className={`fa fa-spinner fa-3x fa-spin ${style.spinner}`} title={'spinner'} />}
          {!loading &&
            filterFormFields.map((field, i) => {
              if (field.key === withdrawalPaymentFields.document.key) {
                field.label = field.label.replace(' ({{id_type}})', '')
              }
              const options = isFunction(field.options)
                ? field.options({
                  withdrawalPaymentVendor: paymentVendor,
                  dynamicFields,
                  country: get(countries[country], 'value'),
                  paymentOption: get(this.state.form, 'paymentOption'),
                  bankCode: get(this.state.form, 'provider'),
                }) : field.options
              return (
                <Row key={i}>
                  <Col xs={12}>
                    {options &&
                      <StylishSelect.Input
                        id={`t-withdrawal-editor-${field.value}`}
                        label={field.label}
                        value={(this.state.form[field.key])}
                        variant={this.state.formError[field.key]}
                        disabled={modeShowExisting
                          && ((field.key === 'withdrawalReason')
                          || (field.key === 'termsAndConditions')
                          || !this.isEditable())}
                        onChange={(e) => {
                          this.formValueChanged(field.key, e.value)
                        }}
                        placeholderText={`Choose ${field.label}`}
                        options={sortBy(Object.values(options), 'label')
                          .map((i) => ({value: get(i, 'key', '').toString(), label: i.label}))}
                      />
                    }
                    {!options &&
                      <Form.Group>
                        <Form.Label>{field.label}</Form.Label>
                        <Form.Control
                          className={(modeShowExisting
                            && (field.key === 'withdrawalFee'
                            || field.key === 'withdrawalNetAmount')
                            && style.highlightWDFields)}
                          id={`t-withdrawal-editor-${field.value}`}
                          type="text"
                          title={field.label}
                          value={this.state.form[field.key] || ''}
                          variant={this.state.formError[field.key]}
                          maxLength="255"
                          onChange={(event) => {
                            this.formValueChanged(field.key, event.target.value)
                          }}
                          onKeyUp={(e) => e.ctrlKey && isKey(e, keys.enter)}
                          disabled={(modeShowExisting && (field.key === 'withdrawalNetAmount' || !this.isEditable())) ||
                            (!modeShowExisting && field.key === 'withdrawalNetAmount')}
                        />
                      </Form.Group>
                    }
                  </Col>
                </Row>
              )
            })
          }
          {!modeShowExisting ||
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <Form.Label>Created</Form.Label>
                  <Form.Control
                    type="text"
                    title="Created"
                    defaultValue={readableDate(moment(withdrawal.createdAt)) || ''}
                    disabled={modeShowExisting}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
          {modeShowExisting &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  title="Status"
                  label="Status"
                  id="t-withdrawal-editor-status"
                  value={this.state.form.status}
                  disabled={!this.isEditable()}
                  onChange={(e) => {
                    this.formValueChanged('status', e.value)
                  }}
                  options={StylishSelect.enumToStylishOptions(statusOptions)}
                />
              </Col>
            </Row>
          }
          {modeShowExisting && !isEmpty(mappedDeposits) &&
            <Row>
              <Col xs={12}>
                <StylishSelect.Input
                  title="refunds"
                  label={`Refunds - Remaining Net Amount: ${formattedAmount}`}
                  id="t-withdrawal-editor-refunds"
                  value={this.state.form.refunds}
                  disabled={!this.canEditRefunds()}
                  multi
                  onChange={(selections) => {
                    const selectedIds = map(selections, 'value')
                    const refundedIds = map(refundedDeposits, 'depositId')
                    const newDepositId = difference(selectedIds, refundedIds)[0]

                    if (newDepositId && remainingWDAmount === 0) {
                      return
                    } else if (!newDepositId) {
                      const removedDepositId = difference(refundedIds, selectedIds)[0]
                      this.refundsChanged(false, removedDepositId)
                    } else {
                      this.refundsChanged(true, newDepositId)
                    }
                  }}
                  options={mappedDeposits}
                />
              </Col>
            </Row>
          }
          {!modeShowExisting ||
            <Row>
              {salesAgent && <Col xs={12}>
                <Form.Group>
                  <Form.Label>Sales Agent</Form.Label>
                  <Form.Control
                    type="text"
                    title="Sales Agent"
                    defaultValue={getFullName(salesAgent, unassigned)}
                    disabled={modeShowExisting}
                  />
                </Form.Group>
              </Col>}
              <Col xs={12}>
                <TextareaAutosizeInput
                  label="Comments"
                  id="t-withdrawal-editor-comments"
                  placeholder="Add a comment..."
                  value={this.state.form.comments}
                  onChange={(e) => this.formValueChanged('comments', e.target.value)}
                  onKeyUp={(e) => e.ctrlKey && isKey(e, keys.enter)}
                  disabled={modeShowExisting && !this.isEditable()}
                />
              </Col>
            </Row>
          }
          {toolbar}
        </Tab>
        {renderCloseTab(onClose)}
      </Tabs>
    )
  }
}

export default compose(
  provideProps((state) => {
    const {fxRates, dynamicFields} = state

    return {
      fxRates,
      dynamicFields,
    }
  }),
  mountDataProviders({fxRatesProvider, dynamicFieldsProvider}),
)(WithdrawalEditor)
