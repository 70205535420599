/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import PropTypes from 'prop-types'
import {some} from 'lodash'
import {unassigned, sanitizeId} from '../useful'
import {canWriteClientSalesAgent, canUpdateClientSupportAgent, canUpdateClientExternalAgent} from '@bdswiss/common-permissions'
import SelectAgent from '../components/SelectAgent'
import {provideProps} from '../decorators'
import style from './client.module.scss'
import PureComponent from '../PureComponent'

export const agentTypes = {
  salesAgent: {
    label: 'Sales Agent',
    filter: (agent) => some(agent.roles, (r) => ['admin', 'sales_agent', 'lead_distributor', 'external', 'strategic_partnerships'].includes(r.id)),
    permission: canWriteClientSalesAgent,
    action: 'setSalesAgent',
    optimistic: 'setSalesAgent',
  },
  supportAgent: {
    label: 'Partners Agent',
    filter: (agent) => some(agent.roles, (r) => ['sales_agent', 'affiliate_manager'].includes(r.id)),
    permission: canUpdateClientSupportAgent,
    action: 'setSupportAgent',
    optimistic: 'setSupportAgent',
  },
  externalAgent: {
    label: 'External Agent',
    filter: (agent) => some(agent.roles, (r) => ['admin', 'external_agent'].includes(r.id)),
    permission: canUpdateClientExternalAgent,
    action: 'setExternalAgent',
    optimistic: 'setExternalAgent',
  },
}

let ChangeAgent
export default ChangeAgent = provideProps()(class ChangeAgent extends PureComponent {

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  };

  static propTypes = {
    agentType: (props, propName, componentName) => {
      if (Object.values(agentTypes).indexOf(props[propName]) < 0) {
        const general = `Invalid prop \`${propName}\` supplied to \`${componentName}\`.`
        const detail = `Invalid value: \`${props[propName] && props[propName].label}\``

        return new Error(`\`${general}\` \`${detail}\``)
      }
    },
    agents: PropTypes.array.isRequired,
    agent: PropTypes.object,
    clientId: PropTypes.number.isRequired,
  };

  setAgent(agentId) {
    const {agentType, agents} = this.props
    const {optimistic} = this.context.clientProvider
    const agent = agents.find((agent) => agent.id === agentId)

    optimistic[agentType.optimistic](this.props.dispatch, this.props.clientId, agent)
    this.props.actions.client[agentType.action](this.props.clientId, agentId)
      .then((res) => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      }).catch((e) => {
        this.context.logError(e)
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
  }

  render() {
    const {agentType, agents, agent, viewer} = this.props

    const agentId = agent ? agent.id : null
    const canAssignMe = agentId !== viewer.id
      && agentType.permission(viewer, agentId, viewer.id, viewer.permissions)
    const canUnset = agent && agentType.permission(viewer, agentId, null, [])
    const canChange = agentType.permission(viewer)

    const assignMeLink = canAssignMe && (
      <a
        className={`float-right t-assign-me ${style.selectAction} ${style.clickable}`}
        onClick={() => this.setAgent(this.props.viewer.id)}
      >Assign Me</a>
    )

    return (
      <div
        id={`t-client-assign-${sanitizeId(agentType.label)}`}
      >
        <div className={style.selectLabel}>
          {agentType.label}
          {assignMeLink}
        </div>
        <div style={{clear: 'both'}}>
          <SelectAgent
            id={sanitizeId(agentType.label)}
            placeholderText={unassigned}
            agents={agents}
            value={agentId}
            optionsFilter={agentType.filter}
            disabled={!canChange}
            clearable={canUnset}
            onChange={(o) => this.setAgent(o && o.value)}
          />
        </div>
      </div>
    )
  }
})

ChangeAgent.agentTypes = agentTypes
