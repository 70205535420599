import {canQueryClients} from '@bdswiss/common-permissions'
import {getPageSize, getOffset} from '../useful'

export const callLogsProvider = {
  getQuery: (props) => {
    const {callLogsPage, callLogsFilter = {}, viewer} = props

    const condition = [
      callLogsFilter.search && `search: "${callLogsFilter.search}"`,
      callLogsFilter.startedAt && `startedAt: "${callLogsFilter.startedAt.toISOString()}"`,
      callLogsFilter.endedAt && `endedAt: "${callLogsFilter.endedAt.toISOString()}"`,
      callLogsFilter.minDuration && `minDuration: ${callLogsFilter.minDuration}`,
      callLogsFilter.userId && `userId: ${callLogsFilter.userId}`,
      callLogsFilter.monitoringAgent && `monitoringAgent: ${callLogsFilter.monitoringAgent}`,
      callLogsFilter.needsComplianceCheck && `needsComplianceCheck: ${callLogsFilter.needsComplianceCheck}`,
      callLogsFilter.monitoredOnly && `monitoredOnly: ${callLogsFilter.monitoredOnly}`,
      callLogsFilter.companies && callLogsFilter.companies.length > 0 && `companies: [${callLogsFilter.companies.join()}]`,
      callLogsFilter.minScore && `minScore: ${callLogsFilter.minScore}`,
      callLogsFilter.maxScore && `maxScore: ${callLogsFilter.maxScore}`,
    ].filter(condition => condition).join('\n').trim()

    const clientQuery = canQueryClients(viewer) ? `
      client {
        id
        firstName
        lastName
      }
    ` : ''
    const query = `{
      callLogs(
        limit: ${getPageSize()}
        offset: ${getOffset(callLogsPage)}
        ${condition}
        orderBy: id
        orderDirection: descending
      ) {
        id
        user {
          id
          firstName
          lastName
        }
        ${clientQuery}
        company
        durationBillable
        startedAt
        recording
        needsComplianceCheck
        isMonitored
        provider
        monitoring
      }
      callLogsCount${condition !== '' ? `(${condition})` : ''}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Call Logs Loaded',
    (state, res) => ({
      ...state,
      callLogs: res.callLogs,
      callLogsCount: res.callLogsCount,
    }),
    [res]
  ),
}



export const callLogProvider = {
  getQuery: (props) => {
    const {callLogId, viewer} = props
    const clientQuery = canQueryClients(viewer) ? `
      client {
        id
        firstName
        lastName
      }
    ` : ''
    const query = `{
      callLog(id: ${callLogId}) {
        id
        disposition
        user {
          id
          firstName
          lastName
        }
        monitoringAgent {
          firstName
          lastName
        }
        ${clientQuery}
        company
        durationBillable
        startedAt
        recording
        monitoring
        needsComplianceCheck
        isMonitored
        provider
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Call Log Loaded',
    (state, res) => ({
      ...state,
      callLog: res.callLog,
    }),
    [res]
  ),
}
