/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {isEmpty} from 'lodash'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {orderDirections} from '@bdswiss/common-enums'
import {canQueryTags} from '@bdswiss/common-permissions'
import {Row, Col, Table, Card, Pagination} from 'react-bootstrap'
import style from './tags.module.scss'
import {getPageCount, getPageRange} from '../useful'
import PureComponent from '../PureComponent'
import {tagCategoriesSearchProvider} from './providers'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class TagList extends PureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
  }

  linkToEditTag(tag, content) {
    return (
      <LinkContainer to={`/tag-categories/${tag.tagCategory.id}/${tag.id}/edit`}>
        <a>{content !== undefined ? content : ''}</a>
      </LinkContainer>
    )
  }

  renderTag(tag, tagCategories) {
    const {id, name} = tag
    return (
      <tr className="t-tag" key={id}>
        <td id={`tag-${id}`}>
          {this.linkToEditTag(
            tag,
            id, tagCategories)}
        </td>
        <td>
          {this.linkToEditTag(
            tag,
            name, tagCategories)}
        </td>
      </tr>
    )
  }

  render() {
    const {tagCategoriesCount, tagCategoriesPage, tag, tagCategories} = this.props
    if (isEmpty(tag.tags)) {
      return (
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>There are no tags for this category.</Card.Body>
            </Card>
          </Col>
        </Row>
      )
    } else {
      return (
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>id</th>
                      <th>Name</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tag.tags.map((tag) => this.renderTag(tag, tagCategories))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, tagCategoriesPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(tagCategoriesPage, getPageCount(tagCategoriesCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, tagCategoriesPage: tagCategoriesPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, tagCategoriesPage: tagCategoriesPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === tagCategoriesPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, tagCategoriesPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }
  }
}

export default compose(
  checkRights(canQueryTags),

  uiMount((state) => ['ui', 'tagCategories']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/tagCategories',
      (state) => {
        let tagCategoriesSort
        if (!tagCategoriesSort || tagCategoriesSort.orderBy == null
              || tagCategoriesSort.orderDirection == null
              || !(tagCategoriesSort.orderDirection in orderDirections)) {
          tagCategoriesSort = defaultSort
        }

        return ({...state, tagCategoriesSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {tagCategoriesSearch, tagCategories, tagCategoriesCount} = state
    const {tagCategoriesPage, tagCategoriesSort} = uiState

    return ({
      tagCategoriesSearch,
      tagCategories,
      tagCategoriesCount,
      tagCategoriesPage: tagCategoriesPage || 1,
      tagCategoriesSort,
    })
  }),

  mountDataProviders({tagCategoriesSearchProvider}),
)(TagList)
