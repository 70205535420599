import styled from 'styled-components'

export const Table = styled.table`
  border-color: #e0e0e0;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  font-family: Roboto;
  width: 100%;

  & td, th {
    border: solid 1px #e0e0e0;
    font-size: 14px;
    height: 40px;
    padding-left: 15px;
  }

  & th {
    font-weight: 700;
  }
`
