import {Card, Col, Container, Row, Table, Button, Form} from 'react-bootstrap'
import {memo, useEffect, useState} from 'react'
import {compose, mountDataProviders, provideProps} from '../decorators'
import {manualExecutionsProvider, existingScriptNamesProvider} from './providers'

export const ManualExecutions = memo(({manualExecutions, scripts, manualExecutionsProvider, viewer, actions}) => {
  const [name, setName] = useState('')
  const [description, setDescription] = useState(null)
  const [type,] = useState('script')
  const [params, setParams] = useState('')
  const [nameError, setNameError] = useState('')
  // TODO: more types will be implemented in the future
  const [validTypesAndNames, setValidTypesAndNames] = useState({
    script: [{name: 'test', description: 'test description'}],
    // worker: ['testWorker1', 'testWorker2'],
    // job: ['testJob1', 'testJob2'],
  })
  const getValidNamesForType = (selectedType) => validTypesAndNames[selectedType] || []

  // TODO: This will be used once we add more types
  // const handleTypeChange = (selectedType) => {
  //   setType(selectedType)
  //   setName('')
  // }

  const handleNameChange = (selectedName) => {
    setName(selectedName)
    const script = validTypesAndNames[type].find((s) => s.name === selectedName)
    setDescription(script.description)
    setNameError('')
  }

  const validate = () => {
    setNameError('')

    !name && setNameError('Please select a name')

    // This can be extended to more global errors if needed, for now I used just the name for simplicity
    return !!name
  }
  useEffect(() => {
    scripts && setValidTypesAndNames((prevState) => ({
      ...prevState,
      script: scripts
    }))
  }, [scripts])
  const run = () => {
    if (!validate()) {
      return
    }

    const paramsArray = params.split(',').map((param) => param.trim())
    const paramsJson = JSON.stringify(paramsArray)

    actions.manualExecutions
      .createManualExecution(name, type, paramsJson, viewer.id)
      .then(() => manualExecutionsProvider.fetch())
  }

  return (
    <Container>
      <Row>
        <Col xs={12}>
          <Row>
            <h3>Scripts:</h3>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body>
              <Form>
                <Form.Group controlId="formName">
                  <Form.Label>{`Name of the ${type} file:`}</Form.Label>
                  <Form.Control
                    as="select"
                    value={name}
                    required
                    onChange={(e) => handleNameChange(e.target.value)}>
                    <option value="">Select from dropdown</option>
                    { getValidNamesForType(type).map((script) => <option key={script.name} value={script.name}>{script.name}</option>) }
                  </Form.Control>
                  {nameError && <Form.Text className="text-danger">{nameError}</Form.Text>}
                  <Form.Group controlId="description">
                    {description && <p>{description}</p>}
                  </Form.Group>
                </Form.Group>
                {/* TODO: commented out, until we introduce more types */}
                {/*<Form.Group controlId="formType">*/}
                {/*  <Form.Label>Type:</Form.Label>*/}
                {/*  <Form.Control*/}
                {/*    as="select"*/}
                {/*    value={type}*/}
                {/*    onChange={(e) => handleTypeChange(e.target.value)}>*/}
                {/*    {Object.keys(validTypesAndNames).map((type) => (*/}
                {/*      <option key={type} value={type}>{type}</option>*/}
                {/*    ))}*/}
                {/*  </Form.Control>*/}
                {/*</Form.Group>*/}
                <Form.Group controlId="formParams">
                  <Form.Label>Params <small>{`(Enter values separated by comma, they will be parsed as JSON in the ${type})`}</small>:</Form.Label>
                  <Form.Control
                    type="text"
                    value={params}
                    onKeyDown={(e) => e.key === 'Enter' && e.preventDefault()}
                    onChange={(e) => setParams(e.target.value)} />
                </Form.Group>
                <Button variant={nameError ? 'danger' : 'success'} onClick={run}>Run</Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col xs={12} style={{marginTop: 6}}>
          <Row>
            <h3>Run history <small>(100 latest)</small>:</h3>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Body className="fit-to-page">
              <Table bordered hover>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Params</th>
                    <th>Ran by:</th>
                    <th>Ran at:</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    manualExecutions.map(({id, name, type, params, createdAt, createdBy}) => (
                      <tr key={id}>
                        <td>{name}</td>
                        <td>{type}</td>
                        <td>{params}</td>
                        <td>{createdBy}</td>
                        <td>{createdAt}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  )
})

export default compose(
  provideProps(({manualExecutions, scripts}) => ({manualExecutions, scripts})),
  mountDataProviders({manualExecutionsProvider, existingScriptNamesProvider})
)(ManualExecutions)
