import React from 'react'
import {Navbar, Tooltip, OverlayTrigger, Image, Nav, Container} from 'react-bootstrap'
import {canQueryAppointments} from '@bdswiss/common-permissions'
import PropTypes from 'prop-types'
import GlobalSettings from './GlobalSettings'
import AppointmentsButton from './AppointmentsButton'
import Menu from './Menu'
import PureComponent from './PureComponent'
import style from './app.module.scss'
import FontAwesomeIcon from './components/FontAwesomeIcon'
import {companyName} from './utils/companyName'

export default class Header extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    logOut: PropTypes.func.isRequired,
    toggleAppointmentsSidebar: PropTypes.func.isRequired,
  }

  setLogo(companyName) {
    return `/${companyName ?? 'bdswissmauritius'}/logo.png`
  }

  render() {
    const {showStageWarning, viewer, pathname} = this.props
    const logo = this.setLogo(companyName())
    return (
      <header>
        <Navbar fixed="top" className={style.menuNav}>
          <Container>
            <Navbar.Brand>
              <Image src={logo} className={style.logo}/>
            </Navbar.Brand>

            <Menu pathname={pathname} />

            <Nav className="ml-auto">
              {showStageWarning && (
                <OverlayTrigger placement="bottom" overlay={
                  <Tooltip id="warningTooltip">
                    <strong><FontAwesomeIcon icon="exclamation-triangle" /> Warning:</strong> working in staging mode.
                  </Tooltip>
                }>
                  <Navbar.Text>
                    <FontAwesomeIcon icon="exclamation-triangle" />
                  </Navbar.Text>
                </OverlayTrigger>
              )}

              {canQueryAppointments(viewer) && <AppointmentsButton onClick={this.context.toggleAppointmentsSidebar} />}
              <GlobalSettings />
            </Nav>
          </Container>
        </Navbar>
      </header>
    )
  }
}
