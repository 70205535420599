import {getFetchInterval, getOffset, getPageSize} from '../useful'


export const ghostCopiersProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const query = `{
          ghostCopyTradings(
            limit: ${getPageSize()}
            offset: ${getOffset(props.reportPage)}
          ) {
            id
            memberId
            remoteId
            copyingRemoteId
            status
            isActive
            firstCopyingDate
            lastCopyingDate
         }
          ghostCopyTradingsCount
      }`
    return query
  },

  onData: (res, dispatch) => dispatch(
    'Ghost Copy Tradings loaded',
    (state, res) => ({
      ...state,
      ghostCopyTradings: res.ghostCopyTradings || [],
      ghostCopyTradingsCount: res.ghostCopyTradingsCount || 0,
    })
    ,
    [res]
  ),

}
