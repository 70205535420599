/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {map} from 'lodash'
import moment from 'moment'
import {Link} from 'react-router-dom'
import PropTypes from 'prop-types'
import {LinkContainer} from 'react-router-bootstrap'
import {Row, Col, Table, Button, Card, Pagination, Container} from 'react-bootstrap'
import {events} from '../enums'
import style from './competitions.module.scss'
import PureComponent from '../PureComponent'
import {competitionsProvider} from './providers'
import {getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount} from '../decorators'

class Competitions extends PureComponent {

  static contextTypes = {
    router: PropTypes.object.isRequired,
    competitionsProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.state = {
      currentCompetitionId: null,
      exporting: false,
    }
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.competitionsProvider.fetch()
  }

  linkToCompetition(competition, content) {
    return (
      <LinkContainer to={`/competitions/${competition.id}`}>
        <a>{content !== undefined ? content : ''}</a>
      </LinkContainer>
    )
  }

  exportCompetitionData = (id) => {
    this.setState({currentCompetitionId: id, exporting: true})
    this.props.actions.competition.exportData(id).then((res) => {
      const a = document.createElement('a')
      a.style.display = 'none'
      a.href = res.exportCompetitionData
      document.body.appendChild(a)
      a.click()
      this.setState({currentCompetitionId: null, exporting: false})
    }).catch((e) => {
      this.setState({currentCompetitionId: null, exporting: false})
      e.isShowActualError = true
      this.context.logError(e)
    })
  }

  renderCompetition(competition) {
    const {currentCompetitionId, exporting} = this.state
    const {id, name, startDate, endDate, leaderboard} = competition
    const readableStartDate = startDate ? moment(startDate).format('lll') : ''
    const readableEndDate = endDate ? moment(endDate).format('lll') : ''

    const leaders = map(leaderboard, (l, i) => <span key={i} style={{fontWeight: 'bold'}}>
      &nbsp;{`(${l.rank})`}
      <Link to={`/clients/${l.clientId}`} className={style.leadersText}>
        {l.login}
      </Link>
    </span>)

    return (
      <tr className="t-competition" key={id}>
        <td>
          {this.linkToCompetition(competition, id)}
        </td>
        <td>
          {this.linkToCompetition(competition, name)}
        </td>
        <td>
          {leaders}
        </td>
        <td>
          {this.linkToCompetition(competition, readableStartDate)}
        </td>
        <td>
          {this.linkToCompetition(competition, readableEndDate)}
        </td>
        <td>
          <div style={{textAlign: 'center'}}>
            <Button
              id={`t-competition-export-${id}`}
              variant="success"
              size="sm"
              onClick={() => this.exportCompetitionData(id)}
              style={{width: '90%'}}
              disabled={id === currentCompetitionId && exporting}
            >
              Export
            </Button>

            <LinkContainer to={`/competitions/leaderboard/${competition.id}`}>
              <Button
                id={`t-competition-stat-${id}`}
                variant="outline-info"
                size="sm"
                className={style.leaderboardButton}
              >
                View Stats
              </Button>
            </LinkContainer>

          </div>
        </td>
      </tr>
    )
  }

  render() {
    const {competitions, competitionsCount, page} = this.props

    return (
      <Container>
        <Row>
          <Col xs={12}>
            <Button
              id="t-competitions-new-competition-button"
              className="float-right"
              variant="success"
              onClick={() => this.context.router.push('/competitions/create')}
              size="sm"
            >
              New Competition
            </Button>
          </Col>
        </Row>
        <br />
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Name</th>
                      <th>Leaders</th>
                      <th>Start</th>
                      <th>End</th>
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    {competitions.map((c) => this.renderCompetition(c))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, page: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(page, getPageCount(competitionsCount)).map((p) => {
                    if (p === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={p}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, page: page - 1})
                        )}
                      />
                    }

                    if (p === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={p}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, page: page + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={p === page}
                      key={p}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, page: p})
                      )}
                    >
                      {p}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'competitions']),

  provideProps((state, uiState) => {
    const {competitions, competitionsCount} = state
    const {page, competitionsStatusFilter, competitionsCategoryFilter,
      competitionsSingleFilter, competitionsRequiresFTDFilter} = uiState

    return ({
      competitions,
      competitionsCount,
      page: page || 1,
      competitionsStatusFilter,
      competitionsCategoryFilter,
      competitionsSingleFilter,
      competitionsRequiresFTDFilter,
    })
  }),

  mountDataProviders({competitionsProvider}),
)(Competitions)
