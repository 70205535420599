import {backendRequest} from '../../../../utils/net'
import runtimeConfig from '../../../../config'

const {backendUrl} = runtimeConfig.getInstance()

export const useRejectAccount = () => {
  const rejectAccount = async (accountId) => {
    try {
      const result = await backendRequest(`${backendUrl}/api/v2/crm/accounts/${accountId}/reject`, null, 'POST')
      if (result.ok) {
        const json = await result.json()
        return json.error
      } else {
        const text = await result.text()
        return text
      }
    } catch (error) {
      return error.message
    }
  }

  return {rejectAccount}
}
