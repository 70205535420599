import styled from 'styled-components'

export const Buttons = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 32px 0 17px;

  & > button {
    flex-grow: 1;
  }
`
