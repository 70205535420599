import React from 'react'
import PropTypes from 'prop-types'
import {Row, Col, Button, Pagination, ButtonToolbar} from 'react-bootstrap'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'
import {newsProvider} from './providers'
import NewsArticleEditor from './NewsArticleEditor'
import Article from './Article'
import {find} from 'lodash'
import {canQueryArticles, canWriteArticles} from '@bdswiss/common-permissions'
import {getPageCount, storeNewestReadArticle, negateBooleanProperty, getPageRange} from '../useful'
import {events} from '../enums'
import PureComponent from '../PureComponent'
import {checkIfIsOnlyAffiliateManager} from '../common/utils'

class News extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  static contextTypes = {
    newsProvider: PropTypes.object.isRequired,
  };

  resetBadger(props) {
    const {newsPage, unreadArticlesCount, unreadArticles, news} = props
    if (newsPage > 1 || !unreadArticlesCount || !unreadArticles || !news) {
      return
    }

    const invisibleArticle = find(unreadArticles, (unreadArticle) => {
      const foundElement = find(news, (article) => article.id === unreadArticle.id)
      return !foundElement
    })

    if (!invisibleArticle) {
      props.dispatch('Reset news badger', (state) => ({
        ...state,
        unreadArticlesCount: 0,
      }))
    }
  }

  componentWillMount() {
    storeNewestReadArticle(this.props.news)
    this.resetBadger(this.props)
  }

  componentWillReceiveProps(nextProps) {
    storeNewestReadArticle(nextProps.news)
    this.resetBadger(nextProps)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.newsProvider.fetch()
  }

  dispatchToggleArticle(articleId) {
    this.props.uiDispatch(
      'Toggle article ' + articleId,
      (state) => ({
        ...state,
        revealedArticles: negateBooleanProperty(state.revealedArticles, articleId),
      })
    )
  }

  dispatchEditArticle(article) {
    this.simpleDispatch('Open article editor (' + article.id + ') ' + article.title, {
      editorOpened: true,
      editedArticleId: article.id,
    })
  }

  dispatchOpenEditor() {
    this.simpleDispatch('Open new article editor ', {
      editorOpened: true,
      editedArticleId: 'new',
    })
  }

  dispatchCloseEditor() {
    this.simpleDispatch('Close article editor ', {
      editorOpened: false,
    })
  }

  simpleDispatch(description, changedProps) {
    this.props.uiDispatch(
      description,
      (state) => ({
        ...state,
        ...changedProps,
      })
    )
  }

  render() {
    const {news, editorOpened, editedArticleId, newsPage, newsCount} = this.props
    const showNewArticleEditor = editorOpened && editedArticleId === 'new'

    if (checkIfIsOnlyAffiliateManager(this.props.viewer)) {
      this.props.history.push('/clients')
    }

    return (
      <Row>
        <Col xs={{span: 8, offset: 2}}>
          <Row>
            <Col xs={12}>
              {showNewArticleEditor && (<NewsArticleEditor onClose={this.dispatchCloseEditor.bind(this)} />)}
              <ButtonToolbar className="float-right">
                {!editorOpened && canWriteArticles(this.props.viewer) && (
                  <Button
                    id="t-news-open-editor"
                    variant="success"
                    size="sm"
                    onClick={() => {
                      this.dispatchOpenEditor()
                    }}
                  >New</Button>
                )}
              </ButtonToolbar>
            </Col>
          </Row>
          <Row>&zwnj;</Row>
          <Row>
            <Col xs={12}>
              {news && news.map((article) => {
                if (editorOpened && editedArticleId === article.id) {
                  return (
                    <NewsArticleEditor
                      id={article.id}
                      key={article.id}
                      title={article.title}
                      markdown={article.body}
                      onClose={this.dispatchCloseEditor.bind(this)}
                    />
                  )
                } else {
                  return (<Article
                    key={article.id}
                    article={article}
                    showFull={this.props.revealedArticles[article.id] > -1}
                    toggleContent={this.dispatchToggleArticle.bind(this, article.id)}
                    onEditArticle={this.dispatchEditArticle.bind(this, article)}
                  />)
                }
              })}
            </Col>
          </Row>
          <Row className="mt-3">
            <Col xs={12}>
              <Pagination
                size="sm"
                className="justify-content-center"
                onSelect={(e, selectedEvent) => this.props.uiDispatch(
                  'Show page',
                  (state) => ({...state, newsPage: selectedEvent.eventKey})
                )}
              >
                {getPageRange(newsPage, getPageCount(newsCount)).map((page) => {
                  if (page === 'LEFT_PAGE') {
                    return <Pagination.Prev
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, newsPage: newsPage - 1})
                      )}
                    />
                  }

                  if (page === 'RIGHT_PAGE') {
                    return <Pagination.Next
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, newsPage: newsPage + 1})
                      )}
                    />
                  }

                  return <Pagination.Item
                    active={page === newsPage}
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, newsPage: page})
                    )}
                  >
                    {page}
                  </Pagination.Item>
                })}
              </Pagination>
            </Col>
          </Row>
        </Col>
        <Col xs={2} />
      </Row>
    )
  }
}

export default compose(
  checkRights(canQueryArticles),

  uiMount((state) => ['ui', 'news']),

  predispatch((props) => {
    props.uiDispatch(
      'Setting default for sidebar',
      (state) => ({
        ...state,
        editorOpened: false,
        revealedArticles: {},
      })
    )
  }),

  provideProps((state, uiState) => {
    const {news, newsCount, unreadArticlesCount, unreadArticles} = state
    const {newsPage, revealedArticles, editorOpened, editedArticleId} = uiState

    return ({
      news,
      newsCount,
      unreadArticlesCount,
      unreadArticles,
      newsPage: newsPage || 1,
      revealedArticles,
      editorOpened,
      editedArticleId,
    })
  }),

  mountDataProviders({
    newsProvider: {
      ...newsProvider,
      cache: true,
      cacheInterval: 24 * 60 * 60 * 1000, // 1d
    }}),
)(News)
