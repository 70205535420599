import React from 'react'
import {merge} from 'lodash'
import classNames from 'classnames'
import {components} from 'react-select'
import {Form, Image} from 'react-bootstrap'
import PropTypes from 'prop-types'
import {getFullName} from '../useful'
import StylishSelect from './StylishSelect'
import PureComponent from '../PureComponent'

const IconOption = (props) => (
  <components.Option {...props}>
    {props.data.avatar && <Image
      src={props.data.avatar}
      style={{width: 20, borderRadius: '3px'}}
      alt={props.data.label}
    />}
    &nbsp;{props.data.label}
  </components.Option>
)

const SelectValue = (props) => (
  <div>
    {props.data.avatar && <Image
      src={props.data.avatar}
      style={{width: 20, borderRadius: '3px'}}
      alt={props.data.label}
    />}
    &nbsp;{props.data.label}
  </div>
)

export default class SelectAgent extends PureComponent {

  static propTypes = {
    highlightIfActive: PropTypes.bool,
    disabled: PropTypes.bool,
    clearable: PropTypes.bool,
    agents: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    placeholderText: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    optionsFilter: PropTypes.func,
    optionsPrefix: PropTypes.array,
    multi: PropTypes.bool,
  };

  static defaultProps = {
    disabled: false,
    clearable: false,
  };

  showOption(option) {
    if (!this.props.optionsFilter) {
      return true
    }
    return (option.id === this.props.value) || this.props.optionsFilter(option)
  }

  getOptions() {
    return this.props.agents ? [
      ...(this.props.optionsPrefix || []),
      ...this.props.agents.map((option) => ({
        ...option,
        value: option.id,
        label: getFullName(option),
      })).filter(
        this.showOption.bind(this)
      ).sort((x, y) => {
        if (x.label < y.label) {
          return -1
        }
        if (x.label > y.label) {
          return 1
        }
        return 0
      }),
    ] : []
  }

  render() {
    return (
      <StylishSelect
        id={`t-agent-filter${this.props.id ? `-${this.props.id}` : ''}`}
        onChange={this.props.onChange}
        options={this.getOptions()}
        highlightIfActive={this.props.highlightIfActive}
        value={this.props.value}
        placeholderText={this.props.placeholderText}
        disabled={this.props.disabled}
        clearable={this.props.clearable || false}
        multi={this.props.multi || false}
        components={{Option: IconOption, SingleValue: SelectValue}}
      />
    )
  }
}

class SelectAgentInput extends PureComponent {

  static propTypes = merge({}, SelectAgent.propTypes, {
    bsStyle: PropTypes.string,
    labelClassName: PropTypes.string,
    wrapperClassName: PropTypes.string,
  });

  render() {
    return (
      <Form.Group>
        {this.props.label &&
          <label className={classNames('control-label', this.props.labelClassName)}>{this.props.label}</label>
        }
        <SelectAgent
          {...this.props}
          className={this.props.wrapperClassName || ''}
        />
      </Form.Group>
    )
  }

}

SelectAgent.Input = SelectAgentInput

