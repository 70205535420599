
import React from 'react'
import {Tabs, Tab, Container} from 'react-bootstrap'
import JobsMonitor from './jobs_monitor' // Ensure the default export is used
import JobsStats from './jobs_stats'      // Ensure the default export is used

const JobsReport = () => (
  <Container className="mt-4">
    <Tabs defaultActiveKey="monitor" id="job-dashboard-tabs" className="mb-3">
      <Tab eventKey="monitor" title="Jobs Monitor">
        <JobsMonitor />
      </Tab>
      <Tab eventKey="stats" title="Jobs Stats">
        <JobsStats />
      </Tab>
    </Tabs>
  </Container>
)

export default JobsReport
