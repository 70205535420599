import React from 'react'
import moment from 'moment'
import {kebabCase} from 'lodash'
import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'
import {Row, Col, Table, Button, Card, Pagination, ButtonGroup, Container} from 'react-bootstrap'
import {canCancelPushNotifications, canViewPushNotifications} from '@bdswiss/common-permissions'
import {orderDirections, languages, jobStatuses, pushNotificationTypes} from '@bdswiss/common-enums'
import {events} from '../enums'
import style from './pushnotifications.module.scss'
import {getJobStatusLabel} from '../utils/rendering'
import {pushNotificationsProvider} from './providers'
import {readableDate, getPageCount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class PushNotifications extends PureComponent {
  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.pushNotificationsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    pushNotificationsProvider: PropTypes.object.isRequired,
  }

  filterByPushNotificationType(value) {
    this.props.uiDispatch(
      'Trading Alerts Type Filter',
      (state) => ({...state, typeFilter: value, pushNotificationsPage: 1})
    )
  }

  renderPushNotificationTypeFilter(value, label, filterValue) {
    return (
      <Button
        id={`t-push-notifications-filter-type-${kebabCase(label)}`}
        key={value}
        size="sm"
        variant="outline-secondary"
        active={value === filterValue}
        onClick={() => this.filterByPushNotificationType(value)}
      >{label}</Button>
    )
  }

  renderFilters() {
    const {typeFilter} = this.props
    return (
      <Row className={style.toolbar}>
        <Col xs={3}>
          <ButtonGroup>
            {this.renderPushNotificationTypeFilter('', 'All', typeFilter)}
            {Object.values(pushNotificationTypes).map((type) => (
              this.renderPushNotificationTypeFilter(type.key, type.label, typeFilter)
            ))}
          </ButtonGroup>
        </Col>
      </Row>
    )
  }

  renderMessages(language, body, key) {
    return (
      <tr className="t-push-notification-message" key={key}>
        <td>
          <div>
            {language && languages[language].label}
          </div>
        </td>
        <td>
          <div>
            {body}
          </div>
        </td>
      </tr>
    )
  }

  renderCancelButton(id) {
    const {viewer} = this.props
    return (
      canCancelPushNotifications(viewer) && <Button
        variant="danger"
        size="sm"
        onClick={() => {
          this.props.actions.pushNotifications.cancelPushNotification(id).then((res) => {
            this.context.pushNotificationsProvider.fetch()
          })
        }}
      > Cancel
      </Button>
    )
  }

  renderPushNotification(pushNotification) {
    const {id, body, createdAt, defaultLanguage, status} = pushNotification
    const messages = JSON.parse(body)

    return (
      <tr className="t-push-notification" key={id}>
        <td>
          <div>
            {id}
          </div>
        </td>
        <td>
          <div>
            {languages[defaultLanguage].label}
          </div>
        </td>
        <td>
          <div>
            <Table>
              <thead>
                <tr>
                  <th> Language </th>
                  <th> Message </th>
                </tr>
              </thead>
              <tbody>
                {messages.map(({language, body}, key) => this.renderMessages(language, body, key))}
              </tbody>
            </Table>
          </div>
        </td>
        <td>
          <div>
            {readableDate(moment(createdAt))}
          </div>
        </td>
        <td>
          <div className="text-center">
            {getJobStatusLabel(jobStatuses[status])}
          </div>
        </td>
        <td>
          <div>
            {status === jobStatuses.queued.key &&
              this.renderCancelButton(id)}
          </div>
        </td>
      </tr>
    )
  }

  render() {
    const {pushNotifications, pushNotificationsCount, pushNotificationsPage} = this.props
    return (
      <Container>
        <Row>
          <Col xs={12}>
            {this.renderFilters()}
          </Col>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Default Language</th>
                      <th>Body</th>
                      <th>Created At</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pushNotifications.map((pushNotification) => this.renderPushNotification(pushNotification))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col xs={12}>
            <Pagination
              size="sm"
              className="justify-content-center"
              onSelect={(e, selectedEvent) => this.props.uiDispatch(
                'Show page',
                (state) => ({...state, pushNotificationsPage: selectedEvent.eventKey})
              )}
            >
              {getPageRange(pushNotificationsPage, getPageCount(pushNotificationsCount)).map((page) => {
                if (page === 'LEFT_PAGE') {
                  return <Pagination.Prev
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, pushNotificationsPage: pushNotificationsPage - 1})
                    )}
                  />
                }

                if (page === 'RIGHT_PAGE') {
                  return <Pagination.Next
                    key={page}
                    onClick={(e, selectedEvent) => this.props.uiDispatch(
                      'Show page',
                      (state) => ({...state, pushNotificationsPage: pushNotificationsPage + 1})
                    )}
                  />
                }

                return <Pagination.Item
                  active={page === pushNotificationsPage}
                  key={page}
                  onClick={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, pushNotificationsPage: page})
                  )}
                >
                  {page}
                </Pagination.Item>
              })}
            </Pagination>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canViewPushNotifications),

  uiMount((state) => ['ui', 'push-notifications']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/push-notifications',
      (state) => {
        let pushNotificationsSort
        const typeFilter = 'marketing'
        if (!pushNotificationsSort || pushNotificationsSort.orderBy == null
            || pushNotificationsSort.orderDirection == null
            || !(pushNotificationsSort.orderDirection in orderDirections)) {
          pushNotificationsSort = defaultSort
        }

        return ({...state, pushNotificationsSort, typeFilter})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {pushNotifications, pushNotificationsCount} = state
    const {pushNotificationsPage, pushNotificationsSort, typeFilter} = uiState

    return ({
      pushNotifications,
      pushNotificationsCount,
      pushNotificationsPage: pushNotificationsPage || 1,
      pushNotificationsSort,
      typeFilter,
    })
  }),

  mountDataProviders({pushNotificationsProvider}),
)(PushNotifications)
