import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Col, Row, Button, Card, Form} from 'react-bootstrap'
import {compose, provideProps, mountDataProviders} from '../decorators'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import {EditableLabel} from './UserEditor'
import {locationsProvider, usersProvider} from './providers'
import style from './users.module.scss'

class AssociateCampaignByLocationModal extends PureComponent {

  static contextTypes = {
    usersProvider: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onHide: PropTypes.func.isRequired,
    user: PropTypes.object,
  }

  componentWillMount() {
    this.setDefaultState()
  }

  setDefaultState() {
    this.setState({
      isTouched: false,
      campaigns: [],
      newCampaignValue: '',
      isSubCampaign: false,
    })
  }

  hideModal() {
    this.setDefaultState()
    this.props.onHide()
  }

  addRemoveAssociation(remove = false) {
    const {locationId, campaigns, isSubCampaign} = this.state
    this.props.actions.user.associateCampaignByLocation(locationId, campaigns, isSubCampaign, remove)
      .then(() => {
        this.hideModal()
        this.context.showNotification({
          title: 'Campaigns Associated',
          message: 'Campaigns have been associated to specified location',
          level: 'success',
          autoDismiss: 5,
        })
        this.context.usersProvider.fetch(true)
      })
      .catch(this.props.logError)
  }

  addCampaign() {
    const {newCampaignValue, campaigns} = this.state
    if (newCampaignValue) {
      if (campaigns && !campaigns.includes(newCampaignValue)) {
        campaigns.push(newCampaignValue)
      }
    }
    this.setState({campaigns: [...campaigns], newCampaignValue: '', isTouched: true})
  }

  removeCampaigns(campaign) {
    const {campaigns} = this.state
    if (campaigns && campaigns.includes(campaign)) {
      campaigns.splice(campaigns.indexOf(campaign), 1)
    }
    this.setState({campaigns: [...campaigns], isTouched: true})
  }

  render() {
    const {onHide, show} = this.props
    const {campaigns, newCampaignValue, isSubCampaign, locationId} = this.state
    return (
      <Modal
        keyboard
        show={show}
        onHide={onHide}
      >
        <Modal.Header closeButton className={style.modalHeader}>
          Update Campaign Association By Location
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col id="t-select-associate-campaign-location" xs={8}>
              <StylishSelect.Input
                value={locationId}
                onChange={(e) => this.setState({locationId: e.value})}
                placeholderText="Location"
                options={Object.values(this.props.locations).map((location) => ({
                  label: location.name,
                  value: location.id,
                }))}
              />
            </Col>
            <Col xs={4}>
              <Form.Check
                id="t-associate-campaign-is-subcampaign"
                label="Is SubCampaign"
                checked={isSubCampaign}
                onChange={(e) => this.setState({isSubCampaign: e.target.checked})}
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <Card>
                <Card.Header>Campaigns</Card.Header>
                <Card.Body>
                  <div className={style.campaignsBody}>
                    {
                      campaigns && campaigns.map((c, i) => (
                        <EditableLabel
                          className="label-info"
                          isEditMode
                          key={i}
                          labelText={c}
                          id={`t-associated-campaign-${c}`}
                          onClickHandler={(e) => this.removeCampaign(c)}
                        />))
                    }
                  </div>
                  {!this.props.readOnly &&
                    <Row>
                      <Col xs={10}>
                        <Form.Control
                          type="text"
                          id={'t-associated-campaign-input'}
                          value={newCampaignValue}
                          onChange={(e) => this.setState({newCampaignValue: e.target.value})}
                        />
                      </Col>
                      <Col xs={2}>
                        <Button
                          id={'t-add-associated-campaign-btn'}
                          disabled={!newCampaignValue.length}
                          variant="success"
                          onClick={(e) => this.addCampaign()}
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                  }
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            id="t-associate-campaign-by-location-cancel"
            variant="outline-secondary"
            onClick={() => this.hideModal()}
          >
            Cancel
          </Button>
          <Button
            id="t-associate-campaign-by-location-add"
            variant="success"
            disabled={!(campaigns.length > 0 && locationId)}
            onClick={() => this.addRemoveAssociation()}
          >
            Add
          </Button>
          <Button
            id="t-associate-campaign-by-location-remove"
            variant="danger"
            disabled={!(campaigns.length > 0 && locationId)}
            onClick={() => this.addRemoveAssociation(true)}
          >
            Remove
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  provideProps((state) => {
    const {locations} = state

    return ({
      locations,
    })
  }),

  mountDataProviders({locationsProvider, usersProvider}),
)(AssociateCampaignByLocationModal)
