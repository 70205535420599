import React from 'react'
import PropTypes from 'prop-types'
import {Modal, Button, ButtonToolbar, Row, Col} from 'react-bootstrap'
import {get, sumBy, omitBy, values, remove, find, orderBy, floor} from 'lodash'
import style from './components.module.scss'
import {compose, provideProps, mountDataProviders} from '../decorators'
import PureComponent from '../PureComponent'
import {getFormattedAmount, getDepositRemainingAmount} from '../useful'
import {refundStatuses, depositVendors, withdrawalPaymentVendors} from '@bdswiss/common-enums'
import {getRelatedDeposits} from '../common/utils'
import {fxRatesProvider} from '../fxrates/providers'

class RefundModal extends PureComponent {

  static contextTypes = {
    fxRatesProvider: PropTypes.object.isRequired,
  }

  static propTypes = {
    show: PropTypes.bool,
    onProcessAsWithdrawal: PropTypes.func.isRequired,
    onProcessAsRefund: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const {withdrawal} = this.props
    this.setState({
      remainingAmount: get(withdrawal, 'paymentFields.withdrawalNetAmount') || get(withdrawal, 'amount'),
      checkedDepositIds: [],
      refundedDeposits: [],
      skrillSendAsMoney: false,
      skrillSendMoneyAmount: 0,
    })
  }

  onChange(e, depositId) {
    const {checkedDepositIds, skrillSendAsMoney} = this.state
    const {withdrawal, deposits, withdrawals, fxRates} = this.props

    if (e.target.checked) {
      checkedDepositIds.push(depositId)
    } else {
      remove(checkedDepositIds, (id) => id === depositId)
    }

    const withdrawalNetAmount = get(withdrawal, 'paymentFields.withdrawalNetAmount') || get(withdrawal, 'amount')
    let amount = withdrawalNetAmount
    const refundedDeposits = []
    checkedDepositIds.forEach((id) => {
      const deposit = find(deposits, (d) => d.id === id)
      const depositRemainingAmount = getDepositRemainingAmount(withdrawals, deposit)
      const rate = Number(get(fxRates, `${deposit.currency}[${withdrawal.currency}]`) || 1)
      const depositAmountConverted = floor(deposit.amount * rate, 2)
      const depositRemainingAmountConverted = floor(depositRemainingAmount * rate, 2)
      const refundStatus = (depositAmountConverted <= amount && depositRemainingAmount === deposit.amount)
        ? refundStatuses.full.value : refundStatuses.partial.value
      const refundedAmount = depositRemainingAmountConverted <= amount
        ? depositRemainingAmount
        : floor(amount / rate, 2)
      const refundedDeposit = {
        depositId: id,
        refundStatus,
        refundedAmount,
        amountConverted: true,
        rate,
        amountInWdCurrency: floor(refundedAmount * rate, 2),
      }
      refundedDeposits.push(refundedDeposit)
      amount = Number((amount - depositRemainingAmountConverted).toFixed(2))
    })
    const selectedDeposits = values(omitBy(deposits, (d) => !checkedDepositIds.includes(d.id)))
    const totalAmount = sumBy(selectedDeposits, (d) => {
      const depositRemainingAmount = getDepositRemainingAmount(withdrawals, d)
      const rate = Number(get(fxRates, `${d.currency}[${withdrawal.currency}]`) || 1)
      return Number((depositRemainingAmount * rate).toFixed(2))
    }) || 0
    const remainingAmount = Number((withdrawalNetAmount - totalAmount).toFixed(2))

    this.setState({
      checkedDepositIds: [...checkedDepositIds],
      refundedDeposits: [...refundedDeposits],
      remainingAmount: (skrillSendAsMoney || remainingAmount < 0) ? 0 : remainingAmount,
      skrillSendMoneyAmount: skrillSendAsMoney ? remainingAmount : 0,
    })
  }

  processRemaining(e) {
    const {remainingAmount, skrillSendMoneyAmount} = this.state
    const skrillSendAsMoney = e.target.checked

    this.setState({
      skrillSendAsMoney,
      remainingAmount: skrillSendAsMoney ? 0 : skrillSendMoneyAmount,
      skrillSendMoneyAmount: skrillSendAsMoney ? remainingAmount : 0,
    })
  }

  processAsRefund() {
    const {refundedDeposits, skrillSendAsMoney, skrillSendMoneyAmount} = this.state
    this.props.onProcessAsRefund(refundedDeposits, {skrillSendAsMoney, skrillSendMoneyAmount})
  }

  render() {
    const {show, onProcessAsWithdrawal, onCancel, withdrawal, deposits, withdrawals} = this.props
    const {currency} = withdrawal
    const {remainingAmount, checkedDepositIds, refundedDeposits, skrillSendAsMoney} = this.state
    const relatedDeposits = orderBy(getRelatedDeposits(withdrawal, deposits, false), ['id'], 'desc')
    const withdrawalAmount = getFormattedAmount({
      amount: get(withdrawal, 'paymentFields.withdrawalNetAmount') || get(withdrawal, 'amount'),
      currency: withdrawal.currency,
    })
    return (
      <Modal
        id="t-refund-modal"
        keyboard
        show={show}
        onHide={onCancel}
      >
        <Modal.Header>
          <strong>Withdrawal/Refund</strong>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              Client has deposits that were not refunded so this withdrawal request can be processed as refund.
              <br />
              If this is a Refund select the deposit(s) to refund and click "Process as Refund". Otherwise
              click "Process as Withdrawal".
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={6}>
              <strong>Withdrawal Net Amount:</strong> {withdrawalAmount}
            </Col>
            <Col xs={6}>
              <strong>Remaining Net Amount:</strong> {getFormattedAmount({currency, amount: remainingAmount})}
            </Col>
          </Row>
          <Row className={style.scrollable}>
            <Col xs={12}>
              {relatedDeposits.map((deposit) => {
                if (getDepositRemainingAmount(withdrawals, deposit) === 0) return null
                const refundedDeposit = find(refundedDeposits, (d) => d.depositId === deposit.id)
                const amount = getFormattedAmount({
                  currency: deposit.currency,
                  amount: get(refundedDeposit, 'refundedAmount', 0),
                })
                const depositVendor = get(find(depositVendors, {value: get(deposit, 'vendor')}), 'label')
                const depositLabel = `${deposit.id} (${depositVendor})`
                let refundLabel = ''
                if (get(refundedDeposit, 'refundStatus') === refundStatuses.full.value) {
                  refundLabel = `- Full Refund (${amount})`
                } else if (get(refundedDeposit, 'refundStatus') === refundStatuses.partial.value) {
                  refundLabel = `- Partial Refund (${amount})`
                }
                const depositRemainingAmount = getFormattedAmount({
                  amount: getDepositRemainingAmount(withdrawals, deposit),
                  currency: deposit.currency,
                })
                const remainingAmountLabel = getDepositRemainingAmount(withdrawals, deposit) !== deposit.amount
                  ? `(Remaining Amount: ${depositRemainingAmount})` : ''
                return (
                  <div key={deposit.id} className={style.checkboxStyle}>
                    <label>
                      <input
                        type="checkbox"
                        name={deposit.id}
                        checked={checkedDepositIds.includes(deposit.id)}
                        onClick={(e) => this.onChange(e, deposit.id)}
                        disabled={!checkedDepositIds.includes(deposit.id) && remainingAmount === 0}
                      />
                      <span className={style.checkboxLabelStyle}>
                        {`${depositLabel} - ${getFormattedAmount(deposit)} ${remainingAmountLabel} ${refundLabel}`}
                      </span>
                    </label>
                  </div>
                )
              })}
              {withdrawal.vendor === withdrawalPaymentVendors.skrill.value &&
                <div>
                  <label>
                    <input
                      type="checkbox"
                      name="skrillSendMoney"
                      checked={skrillSendAsMoney}
                      onClick={(e) => this.processRemaining(e)}
                      disabled={!skrillSendAsMoney && remainingAmount === 0}
                    />
                    <span className={style.checkboxLabelStyle}>
                      Process Remaining as "Send Money" (only if you select deposits to refund)
                    </span>
                  </label>
                </div>
              }
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              id="t-refund-modal-cancel-btn"
              variant="outline-secondary"
              size="sm"
              className="mr-3"
              onClick={onCancel}
            >
              Cancel
            </Button>
            <Button
              id="t-refund-modal-process-refund-btn"
              variant="success"
              size="sm"
              className="mr-3"
              onClick={() => this.processAsRefund()}
              disabled={remainingAmount > 0}
            >
              Process as Refund
            </Button>
            <Button
              id="t-refund-modal-process-withdrawal-btn"
              variant="success"
              size="sm"
              onClick={onProcessAsWithdrawal}
            >
              Process as Withdrawal
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default compose(
  provideProps((state) => {
    const {fxRates} = state

    return {
      fxRates,
    }
  }),
  mountDataProviders({fxRatesProvider}),
)(RefundModal)
