import React from 'react'
import PropTypes from 'prop-types'
import {Button, Form, Row, Col, Card, OverlayTrigger, Tooltip} from 'react-bootstrap'
import {get, isArray, map} from 'lodash'
import {suitabilityTestQuestions} from '@bdswiss/common-enums'
import style from './client.module.scss'
import PureComponent from '../PureComponent'

export default class SuitabilityTestDetails extends PureComponent {

  static propTypes = {
    suitabilityTest: PropTypes.object,
    approvedSuitabilityTest: PropTypes.object,
    header: PropTypes.string,
    onClose: PropTypes.func.isRequired,
  }

  getQuestionAnswer(suitabilityTest, {key, options}) {
    const value = get(suitabilityTest, ['answers', key])
    return options && value in options ? options[value].label : value
  }

  getFormattedArray(answer, key) {
    return map(answer, (a) => suitabilityTestQuestions[key].options[a].label).join(', ')
  }

  render() {
    const {suitabilityTest = {}, approvedSuitabilityTest, header, onClose} = this.props
    const questions =  {...suitabilityTestQuestions}
    return (
      <div>
        <Row className={style.toolbar}>
          <Col xs={12}>
            <Button
              id="t-client-suitability-test-details-close"
              key={1}
              variant="outline-secondary"
              size="sm"
              className="float-right"
              onClick={onClose}
            >
              Close
            </Button>
          </Col>
        </Row>
        {suitabilityTest.answers && (
          <Card
            id="t-client-suitability-test-details-sidebar"
          >
            {header && <Card.Header><strong>{header}</strong></Card.Header>}
            <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
              {Object.values(questions).map((question) => {
                const answer = this.getQuestionAnswer(suitabilityTest, question)
                const formatedAnswer = isArray(answer) ? this.getFormattedArray(answer, question.key) : answer
                const approvedAnswer = this.getQuestionAnswer(approvedSuitabilityTest, question)
                const changed = approvedSuitabilityTest && answer !== approvedAnswer

                const formControl = (
                  <Form.Group>
                    <Form.Label>{question.label}</Form.Label>
                    <Form.Control
                      defaultValue={formatedAnswer}
                      className={style.eddAnswerTextfield}
                    />
                  </Form.Group>
                )

                return (
                  <Row key={question.key}>
                    <Col xs={12}>
                      {changed ? (
                        <OverlayTrigger
                          placement="bottom"
                          overlay={
                            (<Tooltip id="disabled">
                              <span>
                                Previously Approved Answer:<br />
                                {approvedAnswer || '[empty]'}
                              </span>
                            </Tooltip>)
                          }
                        >
                          {formatedAnswer ? formControl : <div />}
                        </OverlayTrigger>
                      ) : (
                        formatedAnswer && formControl
                      )}
                    </Col>
                  </Row>
                )
              })}
            </Card.Body>
          </Card>
        )}
      </div>
    )
  }
}
