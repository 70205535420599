import React from 'react'
import PropTypes from 'prop-types'
import DateTimeBase from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import {merge} from 'lodash'
import classnames from 'classnames'
import PureComponent from '../PureComponent'

export default class DateTime extends PureComponent {

  static propTypes = merge({}, DateTimeBase.propTypes, {
    label: PropTypes.string,
    bsStyle: PropTypes.string,
  });

  render() {
    const {bsStyle, label, className, id} = this.props

    let defaultClassName = 'form-group'
    if (bsStyle === 'error') {
      defaultClassName += ' has-error'
    }

    return (
      <div id={id} className={classnames(defaultClassName, className)}>
        {label ? <label className="control-label">{label}</label> : null}
        <DateTimeBase {...this.props} closeOnSelect />
      </div>
    )
  }
}
