import PropTypes from 'prop-types'
import PureComponent from '../PureComponent'

export default class FormComponent extends PureComponent {

  static propTypes = {
    values: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
  }

  getValue(key) {
    if (this.props.values && key in this.props.values) {
      return this.props.values[key]
    } else {
      return null
    }
  }

  valueChanged(key, value) {
    this.props.onChange({...this.props.values, [key]: value})
  }

  fieldBlurred(name) {
    this.props.onBlurred && this.props.onBlurred(name)
  }

  generateInputProps(key) {
    const res = {
      value: this.getValue(key) || '',
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.value)
        } else if (e && e.value) {
          this.valueChanged(key, e.value)
        } else if (e) {
          this.valueChanged(key, e)
        } else {
          this.valueChanged(key, '')
        }
      },
      onBlur: (e) => {
        this.fieldBlurred(key)
      },
    }
    return res
  }

  generateFileInputProps(key) {
    const res = {
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.files)
        } else {
          this.valueChanged(key, '')
        }
      },
      onBlur: (e) => {
        this.fieldBlurred(key)
      },
    }
    return res
  }

  generateCheckInputProps(key) {
    const res = {
      checked: this.getValue(key) || '',
      onChange: (e) => {
        if (e && e.target) {
          this.valueChanged(key, e.target.checked)
        } else {
          this.valueChanged(key, '')
        }
      },
      onBlur: (e) => {
        this.fieldBlurred(key)
      },
    }
    return res
  }
}
