import {Button, Modal} from 'react-bootstrap'

export const RejectModal = ({show, onClose, onSubmit, username}) => (
  <Modal show={show} onHide={onClose}>
    <Modal.Header closeButton>
      Reject PAMM Manager Account Request?
    </Modal.Header>
    <Modal.Body>
      You are about to reject the PAMM Manager Account for {username}
    </Modal.Body>
    <Modal.Footer>
      <Button variant="outline-secondary" onClick={onClose}>Cancel</Button>
      <Button variant="danger" onClick={onSubmit}>Yes, Reject</Button>
    </Modal.Footer>
  </Modal>
)
