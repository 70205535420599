import {getPageSize, getOffset} from '../useful'
import moment from 'moment'

function initArticles(articles) {
  for (const article of articles) {
    article.createdAt = moment(article.createdAt)
  }
  return articles
}

export const newsProvider = {

  fetchInterval: 0,

  getQuery: (props) => `{
        articles(
          limit: ${getPageSize()}
          offset: ${getOffset(props.newsPage)}
          orderBy: id
          orderDirection: descending
        ) {
          id
          title
          body
          user {
            firstName
            lastName
          }
          createdAt
          updatedAt
        }
        articlesCount(estimated: false)
    }`,

  onData: (res, dispatch, props) => dispatch(
    'News loaded',
    (state, res) => ({
      ...state,
      news: initArticles(res.articles),
      newsCount: res.articlesCount,
    }),
    [res]
  ),

}
