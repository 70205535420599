import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {merge, debounce, map} from 'lodash'
import {canQueryAlerts} from '@bdswiss/common-permissions'
import {Row, Col, Table, Button, Card, Pagination, Form, OverlayTrigger, Tooltip, Collapse, Container, InputGroup,
} from 'react-bootstrap'
import {alertTypes, alertStatuses, yesNo, orderDirections, tradingStatuses, clientTypes} from '@bdswiss/common-enums'
import style from './alerts.module.scss'
import {events} from '../enums'
import {alertsProvider} from './providers'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {getAllowedCompanies} from '../utils/general'
import {safeLinkClientDetails} from '../utils/links'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {countriesAndRegionsOptions} from '../utils/country'
import {getFullName, getPageCount, readableDate, getFormattedAmount, getPageRange} from '../useful'
import {compose, provideProps, mountDataProviders, uiMount, predispatch, checkRights} from '../decorators'

class Alerts extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doDateFilterSearch = debounce(this.doDateFilterSearch, 1500)
    this.doFilterByDeposit = debounce(this.doFilterByDeposit, 1500)
    this.doFilterByBalance = debounce(this.doFilterByBalance, 1500)
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    alertsProvider: PropTypes.object.isRequired,
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillMount() {
    const stateDateFilters = this.props.dateFilters || {
      createdFrom: '',
    }
    const stateDepositFilters = this.props.depositFilters || {
      depositFrom: '',
      depositTo: '',
    }
    const stateBalanceFilters = this.props.balanceFilters || {
      balanceFrom: '',
      balanceTo: '',
    }

    const stateAlertsSort = this.props.alertsSort || {
      orderBy: 'id',
      orderDirection: orderDirections.descending.key,

    }

    this.setState({
      sorting: {},
      dateFilters: stateDateFilters,
      depositFilters: stateDepositFilters,
      balanceFilters: stateBalanceFilters,
      showMoreFilters: false,
      alertsSort: stateAlertsSort,
    })
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.alertsProvider.fetch()
  }

  doAlertsSearch(searchText) {
    this.props.uiDispatch(
      'Search alerts',
      (state, arg) => ({...state, alertsSearch: arg, alertsPage: 1}),
      [searchText]
    )
    this.setState({searchText: searchText})
  }

  doFilterByReason(selections) {
    const values = selections && selections.length > 0 && selections.map((v) => v.value)
    this.props.uiDispatch(
      'Filter alerts by reason',
      (state, arg) => ({...state, alertsFilter: values, alertsPage: 1}),
      [values]
    )
  }

  doFilterByTradingStatuses(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter alerts by trading status',
      (state, arg) => ({...state, tradingStatusesFilter: values, alertsPage: 1}),
      [values]
    )
  }

  doFilterByClientTypes(selections) {
    const values = map(selections, 'value')
    this.props.uiDispatch(
      'Filter alerts by client type',
      (state, arg) => ({...state, clientTypesFilter: values, alertsPage: 1}),
      [values]
    )
  }

  doFilterByCompany(company) {
    this.props.uiDispatch(
      'Filter alerts by company',
      (state, arg) => ({...state, companyFilter: company, alertsPage: 1}),
      [company]
    )
  }

  handleDateFilterChanged(name, momentVanue, value) {
    const {dateFilters} = this.state
    this.setState({
      dateFilters: {...dateFilters, [name]: value},
    })

    if (!momentVanue || moment.isMoment(momentVanue)) {
      this.doDateFilterSearch()
    } else {
      this.doDateFilterSearch.cancel()
    }
  }

  doDateFilterSearch() {
    const {dateFilters} = this.state
    this.props.uiDispatch(
      'Filter alerts by dates',
      (state, arg) => ({...state, dateFilters, alertsPage: 1}),
      [dateFilters]
    )
  }

  doFilterByAlertStatus(e) {
    const {statusFilter} = this.state
    this.props.uiDispatch(
      'Filter status',
      (state, arg) => ({...state, statusFilter: e.value, alertsPage: 1}),
      [statusFilter]
    )
  }

  doFilterByCountry(e) {
    const {countryFilter} = this.state
    this.props.uiDispatch(
      'Filter alerts by country or region',
      (state, arg) => ({...state, countryFilter: e.value, alertsPage: 1}),
      [countryFilter]
    )
  }

  doFilterByTopVip(e) {
    const {topVipFilter} = this.state
    this.props.uiDispatch(
      'Filter VIP',
      (state, arg) => ({...state, topVipFilter: e.value, alertsPage: 1}),
      [topVipFilter]
    )
  }

  doSortByOrderAndOrderDirection(field) {
    const {alertsSort} = this.props
    const {orderBy, orderDirection} = alertsSort

    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(
      `Sorting alerts by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`,
      (state) => ({...state, alertsSort: newSort}),
      [alertsSort]
    )
  }

  doFilterByCurrency(e) {
    const {currencyFilter} = this.state
    this.props.uiDispatch(
      'Filter alerts by deposit currency',
      (state, arg) => ({...state, currencyFilter: e.value, alertsPage: 1}),
      [currencyFilter]
    )
  }

  handleDepositFilterChanged(filterId, value) {
    const {depositFilters} = this.state
    this.setState({
      depositFilters: {...depositFilters, [filterId]: value},
    })
    this.doFilterByDeposit()
  }

  doFilterByDeposit(e) {
    const {depositFilters} = this.state
    this.props.uiDispatch(
      'Filter alerts by deposit amount range',
      (state, arg) => ({...state, depositFilters, alertsPage: 1}),
      [depositFilters]
    )
  }

  handleBalanceFilterChanged(filterId, value) {
    const {balanceFilters} = this.state
    this.setState({
      balanceFilters: {...balanceFilters, [filterId]: value},
    })
    this.doFilterByBalance()
  }

  doFilterByBalance(e) {
    const {balanceFilters} = this.state
    this.props.uiDispatch(
      'Filter alerts by balance range',
      (state, arg) => ({...state, balanceFilters, alertsPage: 1}),
      [balanceFilters]
    )
  }

  renderAlert(alert) {
    const {id, client, createdAt, type, status, postponeUntil} = alert
    const clientName = getFullName(client)
    let postponeUntilReadable = '', backgroundClass
    if (postponeUntil && status === alertStatuses.postponed.value) {
      postponeUntilReadable = safeLinkClientDetails(client, readableDate(moment(postponeUntil), false))
      if (moment(postponeUntil).isBefore(moment())) {
        backgroundClass = style.postponePast
      }
      const daysToPostponement = moment(postponeUntil).diff(moment(), 'days')
      if (daysToPostponement > 1 && daysToPostponement <= 5) {
        backgroundClass = style.postponeApproaching
      } else {
        backgroundClass = style.postponePast
      }
    }

    const partnerIdLabel = map(client.registrationCampaigns, 'affiliateUserName').join(', ')

    return (
      <tr
        key={id}
        id={`t-client-alerts-alerts-id-${id}`}
        className={classNames(['t-alert', client.topVip ? 'vip' : '', backgroundClass])}
      >
        <td>{safeLinkClientDetails(client, id)}</td>
        <td>{safeLinkClientDetails(client, client.id)}</td>
        <td>{safeLinkClientDetails(client, partnerIdLabel)}</td>
        <td>{safeLinkClientDetails(client, clientName)}</td>
        <td>{safeLinkClientDetails(client, client.email)}</td>
        <td>{safeLinkClientDetails(client, alertTypes[type].label)}</td>
        <td> {safeLinkClientDetails(client,
          getFormattedAmount({amount: client.depositedAmount, currency: client.depositedAmountCurrency}))
        }
        </td>
        <td> {safeLinkClientDetails(client, getFormattedAmount({
          amount: client.aggregatedBalanceEur,
          currency: 'EUR',
        }))
        }
        </td>
        <td title={createdAt.fromNow()}>
          {safeLinkClientDetails(client, readableDate(createdAt))}
        </td>
        <td>{safeLinkClientDetails(client, alertStatuses[status].label)}</td>
        <td>{safeLinkClientDetails(client, readableDate(moment(client.lastLogin)))}</td>
        <td>{postponeUntilReadable}</td>
      </tr>
    )
  }

  renderAlerts(alerts) {
    return alerts.map((alert) => this.renderAlert(alert))
  }

  getSortArrow(field) {
    const {alertsSort} = this.props
    const  arrowDirection = alertsSort.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (alertsSort.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  render() {
    const {alerts, alertsFilter, alertsSearch, statusFilter, companyFilter, clientTypesFilter,
      alertsPage, alertsCount, countryFilter, topVipFilter, tradingStatusesFilter, viewer,
    } = this.props

    const {searchText, showMoreFilters} = this.state || {}
    const companyOptions = [
      {label: 'All Companies', value: undefined},
      ...StylishSelect.enumToStylishOptions(getAllowedCompanies(viewer))]

    const {dateFilters, depositFilters, balanceFilters} = this.state

    return (
      <Container>
        <Row key="filter-row-1">
          <Col xs={3}>
            <InputGroup>
              <Form.Control
                type="text"
                value={searchText !== undefined ? searchText : alertsSearch || ''}
                placeholder="Search by ID, Client ID, Email"
                onChange={(e) => this.setState({searchText: e.target.value})}
                onKeyUp={(e) => (
                  (e.key === 'Enter' && this.doAlertsSearch(searchText)) ||
                  (e.key === 'Escape' && this.doAlertsSearch(''))
                )}
              />
              <InputGroup.Append>
                {alertsSearch && <Button
                  key={1}
                  title="Clear"
                  variant={alertsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doAlertsSearch('')}
                >
                  <FontAwesomeIcon icon="times" />
                </Button>}
                <Button
                  key={2}
                  title="Search"
                  variant={alertsSearch ? 'success' : 'outline-dark'}
                  onClick={() => this.doAlertsSearch(searchText)}
                >
                  <FontAwesomeIcon icon="search" />
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
          {
            viewer.companies.length > 1 &&
              <Col xs={2}>
                <StylishSelect
                  id="t-alerts-company-filter"
                  placeholderText="All Companies"
                  value={companyFilter}
                  options={companyOptions}
                  highlightIfActive
                  onChange={(e) => this.doFilterByCompany(e.value)}
                />
              </Col>
          }
          <Col xs={2}>
            <StylishSelect
              id="t-alerts-country-filter"
              placeholderText="All Countries"
              value={countryFilter}
              options={countriesAndRegionsOptions}
              highlightIfActive
              onChange={(e) => this.doFilterByCountry(e)}
            />
          </Col>
          <Col xs={2}>
            <StylishSelect
              id="t-alerts-status-filter"
              placeholderText="All Alerts"
              value={statusFilter}
              options={StylishSelect.enumToStylishOptions(alertStatuses, 'All Alert Statuses')}
              highlightIfActive
              onChange={(e) => this.doFilterByAlertStatus(e)}
            />
          </Col>
          <Col xs={3}>
            <StylishSelect
              id="t-trading-statuses-filter"
              className={style.tradingStatus}
              placeholderText="All Trading Statuses"
              value={tradingStatusesFilter}
              options={StylishSelect.enumToStylishOptions(tradingStatuses)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByTradingStatuses(e)}
            />
          </Col>
        </Row>
        <Row key="filter-row-2" className="mt-3">
          <Col xs={4}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-alerts-reasons-filter"
              placeholderText="Alert Reasons"
              value={alertsFilter}
              options={StylishSelect.enumToStylishOptions(alertTypes)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByReason(e)}
            />
            <span className={style.label}>&nbsp;</span>
          </Col>
          <Col xs={4}>
            <span className={style.label}>&nbsp;</span>
            <StylishSelect
              id="t-alerts-filter-client-types"
              placeholderText="All Client Types"
              value={clientTypesFilter}
              options={StylishSelect.enumToStylishOptions(clientTypes)}
              highlightIfActive
              multi
              clearable
              onChange={(e) => this.doFilterByClientTypes(e)}
            />
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}> Last Login On or After</span>
              <DateTime
                id="t-alerts-created-last-login-filter"
                timeFormat={false}
                onChange={(e) => this.handleDateFilterChanged('lastLogin', e, moment.isMoment(e) ? e.format('YYYY-MM-DD') : '')}
                value={dateFilters.lastLogin}
                onFocus={() => this.doDateFilterSearch.cancel()}
                closeOnSelect
                className={style.datetime}
              />
            </div>
          </Col>
          <Col xs={2}>
            <div>
              <span className={style.label}>Vip</span>
              <StylishSelect
                id="t-alerts-top-vip-filter"
                placeholderText="All Clients"
                value={topVipFilter}
                options={StylishSelect.enumToStylishOptions(yesNo, 'All Clients')}
                highlightIfActive
                onChange={(e) => this.doFilterByTopVip(e)}
              />
            </div>
          </Col>
        </Row>
        <Row key="filter-row-3">
          <Collapse in={showMoreFilters}>
            <Container>
              <Row className={style.moreFilters}>
                <Col xs>
                  <div>
                    <span className={style.label}>Deposits From</span>
                    <Form.Control
                      id="t-alerts-deposits-min-filter"
                      type="number"
                      onChange={(e) => this.handleDepositFilterChanged('depositFrom', e.target.value)}
                      value={depositFilters.depositFrom}
                      onFocus={() => this.doFilterByDeposit.cancel()}
                    />
                  </div>
                </Col>
                <Col xs>
                  <div>
                    <span className={style.label}>Deposits To</span>
                    <Form.Control
                      id="t-alerts-deposits-max-filter"
                      type="text"
                      value={depositFilters.depositTo}
                      onChange={(e) => this.handleDepositFilterChanged('depositTo', e.target.value)}
                      onFocus={() => this.doFilterByDeposit.cancel()}
                    />
                  </div>
                </Col>
                <Col xs>
                  <div>
                    <span className={style.label}>Balance From</span>
                    <Form.Control
                      id="t-alerts-balance-min-filter"
                      type="text"
                      value={balanceFilters.balanceFrom}
                      onChange={(e) => this.handleBalanceFilterChanged('balanceFrom', e.target.value)}
                      onFocus={() => this.doFilterByBalance.cancel()}
                    />
                  </div>
                </Col>
                <Col xs>
                  <div>
                    <span className={style.label}>Balance To</span>
                    <Form.Control
                      id="t-alerts-balance-max-filter"
                      type="text"
                      value={balanceFilters.balanceTo}
                      onChange={(e) => this.handleBalanceFilterChanged('balanceTo', e.target.value)}
                      onFocus={() => this.doFilterByBalance.cancel()}
                    />
                  </div>
                </Col>
                <Col xs>
                  <div>
                    <span className={style.label}>Postponed Until</span>
                    <DateTime
                      id="t-alerts-created-postponed-until-filter"
                      timeFormat={false}
                      onChange={(e) => this.handleDateFilterChanged('postponeUntil', e, moment.isMoment(e) ? e.format('YYYY-MM-DD') : '')}
                      value={dateFilters.postponeUntil}
                      onFocus={() => this.doDateFilterSearch.cancel()}
                      closeOnSelect
                      className={style.datetime}
                    />
                  </div>
                </Col>
              </Row>
              <Row className={style.moreFilters}>
                <Col xs={2}>
                  <div>
                    <span className={style.label}> Created From </span>
                    <DateTime
                      id="t-alerts-created-from-filter"
                      timeFormat={false}
                      onChange={(e) => this.handleDateFilterChanged('createdFrom', e, moment.isMoment(e) ? e.format('YYYY-MM-DD') : '')}
                      value={dateFilters.createdFrom}
                      onFocus={() => this.doDateFilterSearch.cancel()}
                      closeOnSelect
                      className={style.datetime}
                    />
                  </div>
                </Col>
                <Col xs={2}>
                  <span className={style.label}> Created To </span>
                  <DateTime
                    id="t-alerts-created-to-filter"
                    timeFormat={false}
                    onChange={(e) => this.handleDateFilterChanged('createdTo', e, moment.isMoment(e) ? e.endOf('day').toISOString() : '')}
                    value={dateFilters.createdTo}
                    onFocus={() => this.doDateFilterSearch.cancel()}
                    closeOnSelect
                    className={style.datetime}
                  />
                </Col>
              </Row>
            </Container>
          </Collapse>
        </Row>
        <Row>
          <Col xs={12} >
            <OverlayTrigger placement="bottom"
              overlay={<Tooltip id="t-show-more-filters">
                {`${showMoreFilters ? 'Hide' : 'Show More'} Filters`}</Tooltip>}
            >
              <div
                className={style.moreFiltersIconBar}
                onClick={() => this.setState({showMoreFilters: !showMoreFilters})}
                id="t-more-filters-icon-bar"
              >
                <FontAwesomeIcon icon={`angle-double-${showMoreFilters ? 'up' : 'down'}`} />
              </div>
            </OverlayTrigger>
          </Col>
        </Row>
        <Row>&nbsp;</Row>
        <Row>
          <Col xs={12}>
            <Card className="two-rows-filter">
              <Card.Body style={{overflowX: 'scroll'}}>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th style={{minWidth: 47}}>
                        <span>ID</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('id')} onClick={() => this.doSortByOrderAndOrderDirection('id')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 89}}>
                        <span>Client ID</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('memberId')} onClick={() => this.doSortByOrderAndOrderDirection('memberId')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 89}}>
                        <span>Partner ID</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('affiliateUserName')} onClick={() => this.doSortByOrderAndOrderDirection('affiliateUserName')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 80}}>
                        <span>Name</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('clientFullName')} onClick={() => this.doSortByOrderAndOrderDirection('clientFullName')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 80}}>
                        <span>Email</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('clientEmail')} onClick={() => this.doSortByOrderAndOrderDirection('clientEmail')} />}
                        </span>
                      </th>
                      <th> Alert Reason </th>
                      <th style={{minWidth: 161}}>
                        <span>Total Deposits EUR</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('totalDeposits')} onClick={() => this.doSortByOrderAndOrderDirection('totalDeposits')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 120}}>
                        <span>Balance</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('balance')} onClick={() => this.doSortByOrderAndOrderDirection('balance')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 80}}>
                        <span>Date</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('createdAt')} onClick={() => this.doSortByOrderAndOrderDirection('createdAt')} />}
                        </span>
                      </th>
                      <th> Status </th>
                      <th style={{minWidth: 102}}>
                        <span>Last Login</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('lastLogin')} onClick={() => this.doSortByOrderAndOrderDirection('lastLogin')} />}
                        </span>
                      </th>
                      <th style={{minWidth: 122}}>
                        <span>Postponed to</span>&nbsp;&nbsp;
                        <span style={{cursor: 'pointer', width: 'auto'}}>
                          {<FontAwesomeIcon icon={this.getSortArrow('postponeUntil')} onClick={() => this.doSortByOrderAndOrderDirection('postponeUntil')} />}
                        </span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.renderAlerts(alerts)}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, alertsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(alertsPage, getPageCount(alertsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, alertsPage: alertsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, alertsPage: alertsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === alertsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, alertsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  checkRights(canQueryAlerts),

  uiMount((state) => ['ui', 'alerts']),

  predispatch((props) => props.uiDispatch(
    'Initialize ui/alerts',
    (state) => {
      const dateFilters = state && state.dateFilters
      let depositFilters = state && state.depositFilters
      if (!depositFilters) {
        depositFilters = {depositFrom: '', depositTo: ''}
      }
      let balanceFilters = state && state.balanceFilters
      if (!balanceFilters) {
        balanceFilters = {balanceFrom: '', balanceTo: ''}
      }

      let alertsSort = state && state.alertsSort
      if (!alertsSort) {
        alertsSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}
      }

      return merge({
        dateFilters,
        depositFilters,
        balanceFilters,
        alertsSort,
      }, state)
    })
  ),

  provideProps((state, uiState) => {
    const {alerts, alertsCount} = state
    const {alertsFilter, companyFilter, countryFilter, topVipFilter, currencyFilter, tradingStatusesFilter,
      alertsSearch, alertsPage, statusFilter, clientTypesFilter,  dateFilters, depositFilters, balanceFilters,
      alertsSort,
    } = uiState

    return ({
      alerts,
      alertsCount,
      alertsFilter,
      alertsSearch,
      alertsPage: alertsPage || 1,
      alertsSort,
      dateFilters,
      statusFilter,
      companyFilter,
      countryFilter,
      tradingStatusesFilter,
      topVipFilter,
      currencyFilter,
      depositFilters,
      balanceFilters,
      clientTypesFilter,
    })
  }),

  mountDataProviders({alertsProvider}),
)(Alerts)
