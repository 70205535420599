import {useState} from 'react'
import {ButtonToolbar, Button, Row, Col, Card} from 'react-bootstrap'
import runtimeConfig from '../config'
import {backendRequest} from '../utils/net'

const {backendUrl} = runtimeConfig.getInstance()

export const ResetTotp = ({clientId, onClose}) => {
  const [result, setResult] = useState(null)

  const handleReset = async () => {
    const requestResult = await backendRequest(`${backendUrl}/api/v2/crm/clients/${clientId}/totp_secrets`, {}, 'DELETE')
    if (requestResult.ok) {
      const json = await requestResult.json()
      if (json.error) {
        setResult(json.error)
      } else {
        setResult('All MFA methods have been disabled. They can now sign in using just their username and password.')
      }
    } else {
      setResult(`Failed to reset Multi-Factor Authentication (MFA). Status: ${requestResult.status}`)
    }
  }

  return (
    <Card id="t-client-reset-password-sidebar" key={1}>
      <Card.Header><strong>Reset Multi-Factor Authentication (MFA)</strong></Card.Header>
      <Card.Body>
        {result || (<><strong>Important:&nbsp;</strong>
          Only reset MFA if the user is locked out of their account and you have verified their identity.
          <br /></>)}
        <Row>
          <Col md={12} xs={12}>
            <ButtonToolbar className="float-right">
              <Button
                id="t-client-reset-password-close"
                tabIndex={-1}
                onClick={onClose}
                variant="outline-secondary"
                className="mr-1"
                size="sm"
              >Close
              </Button>
              {result ? null :
                (<Button
                  id="t-client-reset-password-reset"
                  variant="danger"
                  size="sm"
                  onClick={handleReset}
                >
                  Reset MFA
                </Button>)
              }
            </ButtonToolbar>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}
