/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import Promise from 'bluebird'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import momentTz from 'moment-timezone'
import ct from 'countries-and-timezones'
import {Button, ButtonToolbar, Row, Col, Form, Modal, Badge, InputGroup, Card,
  ButtonGroup, Tabs, Tab,
} from 'react-bootstrap'
import moment from 'moment'
import {get, isEmpty, map, find, forEach, capitalize, orderBy, first, last, cloneDeep, head, isNull, pick,
  mapValues, pickBy, includes,
} from 'lodash'
import * as enums from '@bdswiss/common-enums'
import {canChangeClientCompany, canAssignClientToAffiliate, canResetClientPassword, canWriteClientDetails, canWriteClientAccountChecks,
  canToggleTopVip, canSendCodeClient, canSendInvitation,
  canUpdateClientEconomicProfile, canTogglePhoneVerified, canToggleEmailConfirmed,
  canToggleEffectiveFTD, canGenerateTaxDocument, canToggleDormantReactivation, canTogglePortfolioManagement,
  canCreateDeposits, canCreateWithdrawals, canCreateTransfers, canUpdateClientPhones,
} from '@bdswiss/common-permissions'
import {parseJSON, hasValues, safeParseJSON, priorityLevels} from '../common/utils'
import * as frontendEnums from '../enums'
import {getFullName, getCountryName, readableDate, sanitizeId, getFormattedAmount, isConverted, getUserProfileSectionsEnabled} from '../useful'
import JsonDataModal from '../components/JsonDataModal'
import {provideProps, compose} from '../decorators'
import {createClientDetailsUrl, linkClient, linkClientDetails, linkEditCampaign} from '../utils/links'
import PureComponent from '../PureComponent'
import style from './client.module.scss'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {companyCheck} from '../utils/general'
import StylishSelect from '../components/StylishSelect'
import GeneralModal from '../components/GeneralModal'
import config from '../config'
import {MasterIBSection} from './personalDetailsSections/MasterIB'
import {backendRequest} from '../utils/net'
import runtimeConfig from '../config'

const {backendUrl} = runtimeConfig.getInstance()

class PersonalDetails extends PureComponent {

  static propTypes = {
    clientId: PropTypes.number.isRequired,
    client: PropTypes.object,
    switchSidebar: PropTypes.func.isRequired,
  }

  static contextTypes = {
    clientProvider: PropTypes.object.isRequired,
    router: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
    dispatch: PropTypes.func.isRequired,
    showNotification: PropTypes.func.isRequired,
  }


  getMarketingDetails() {
    const clientId = this.props.clientId
    const params = null
    const requestPath = `${backendUrl}/api/v2/crm/clients/${clientId}/marketing-details`
    const HTTPMethod = 'GET'
    return backendRequest(requestPath, params, HTTPMethod)
      .then((res) => res.json())
      .then((body) => body)
      .catch((error) => error)
  }

  componentWillMount() {
    const {client: {generalInfo: {topVip, canGrantSpoa, canIbTransfer, agentEffectiveFTD, autoChartistEnabled,
      isPhoneVerified, bdxCallCompleted, bdxWaiverAccepted, autoChartistEmails, autoChartistPlugin,
      autoChartistCoelationEmail}, personalDetails, emailConfirmed, ignoreIbTermination,
    isDirectIB, allowCopyTrading, hasOpenComplaint}} = this.props
    this.getMarketingDetails().then((data) => {
      this.setState({marketingDetails: data})
    })
    this.setState({
      showGbgResult: false,
      checkingGBG: false,
      topVip,
      canGrantSpoa,
      canIbTransfer,
      agentEffectiveFTD,
      showIdVerificationConfirmation: false,
      editingPhone: false,
      newPhone: get(personalDetails, 'phone', ''),
      editingEmail: false,
      newEmail: get(personalDetails, 'email', ''),
      editingSecondaryPhone1: false,
      newSecondaryPhone1: get(personalDetails, 'secondaryPhones.secondaryPhone1', ''),
      toggleAutochartist: autoChartistEnabled,
      toggleAutochartistEmails: autoChartistEmails,
      toggleAutochartistPlugin: autoChartistPlugin,
      toggleAutochartistCoelationEmail: autoChartistCoelationEmail,
      isPhoneVerified,
      emailConfirmed,
      bdxCallCompleted,
      bdxWaiverAccepted,
      bdxFinalChoice: personalDetails.bdxInvestmentChoiceFinal,
      showTrackEventsModal: false,
      ignoreIbTermination,
      isDirectIB,
      allowCopyTrading,
      hasOpenComplaint,
      showClientToolsSection: getUserProfileSectionsEnabled().showClientToolsSection,
      showMarketingSection: getUserProfileSectionsEnabled().showMarketingSection,
      showRiskSection: getUserProfileSectionsEnabled().showRiskSection,
      showEPSection: getUserProfileSectionsEnabled().showEPSection,
      showAPTestSection: getUserProfileSectionsEnabled().showAPTestSection,
      showPartnerDataSection: getUserProfileSectionsEnabled().showPartnerDataSection,
      showEventsSection: getUserProfileSectionsEnabled().showEventsSection,
      showStrategicPartnerSection: getUserProfileSectionsEnabled().showStrategicPartnerSection,
      showBDXSection: getUserProfileSectionsEnabled().showBDXSection,
      marketingDetails:null

    })
  }

  switchSidebar(sidebarName) {
    this.context.router.push(createClientDetailsUrl(this.props.clientId, sidebarName))
  }

  getYesNoValue(key) {
    const {personalDetails} = this.props.client
    const value = personalDetails[key]
    return get(find(enums.yesNo, {value}), 'label', enums.yesNo.no.label)
  }

  getAgreementsReadableDate(key) {
    const {personalDetails} = this.props.client
    const value = personalDetails[key]
    return value ? `Accepted on ${readableDate(moment(value), false)}` : ''
  }

  getAppropTestQuestionAnswer(appropTest, {key, label, options}) {
    const value = get(appropTest, ['answers', key]) || get(appropTest, [key])
    const answer = get(options, [value, 'label'], value)
    return {
      label,
      answer,
    }
  }

  renderAppropTestItem = ({label, answer}) => [
    <dt key={1} title={label}>{label}</dt>,
    <dd key={2}>{answer}&nbsp;</dd>,
  ]

  renderAppropTestData() {
    const {aptestLoaded,loadingAptest} = this.state
    const {appropTests, personalDetails: {legacyAppropTest, company}} = this.props.client

    if (!aptestLoaded && enums.companies[company].requiresAPTest) {
      return (
        <Row className={style.outerRow}>
          <Col xs={1} className={style.expandColumn}>
            <Row>
              <Col xs={12}>
                <FontAwesomeIcon
                  icon={this.state.showAPTestSection ? 'minus-square-o' : 'plus-square-o'}
                  className={classnames(['clickable', style.expandableIcon])}
                  onClick={() => this.setState({showAPTestSection: !this.state.showAPTestSection})}
                />
              </Col>
            </Row>
            <Row style={{height: '100%'}} className={!this.state.showAPTestSection ? style.hiddenSection : ''}>
              <Col xs={12} className={style.sectionTitle}>
                AP Test
              </Col>
            </Row>
          </Col>
          <Col xs={10}
            className={classnames([style.sectionTitleForHidden, this.state.showAPTestSection ? style.hiddenSection : ''])}
          >
            AP Test
          </Col>
          <Col xs={11} className={!this.state.showAPTestSection ? style.hiddenSection : ''}>
            <Row className={style.personalDetails}>
              <Col xs={12}>
                <Button
                  id="t-client-personal-details-load-approp-test-btn"
                  className="float-right"
                  variant="outline-secondary"
                  size="sm"
                  disabled={loadingAptest}
                  onClick={() => {
                    this.setState({loadingAptest: true}, () => {
                      this.context.clientProvider.subProviders.appropTests.fetch()
                        .then(() => this.setState({aptestLoaded: true}))
                        .catch(() => this.setState({aptestLoaded: true}))
                    })
                  }}
                >
                  Load Appropriateness Test
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )
    }

    const approvedAppropTest = find(appropTests, (at) => at.status === enums.appropTestStatuses.approved.key)
    const renderedAppropTest = approvedAppropTest || legacyAppropTest
    if (renderedAppropTest == null) {
      return
    }

    const questions = approvedAppropTest ? {...enums.appropTestQuestions, ...enums.appropTestQuestionsV2} : enums.legacyAppropTestQuestions
    const answers = Object.values(questions).map((question) =>
      this.getAppropTestQuestionAnswer(renderedAppropTest, question)
    )
    const leftCol = answers.slice(0, Math.ceil(answers.length / 2))
    const rightCol = answers.slice(leftCol.length)
    return (
      <Row className={style.outerRow}>
        <Col xs={1} className={style.expandColumn}>
          <Row>
            <Col xs={12}>
              <FontAwesomeIcon
                icon={this.state.showAPTestSection ? 'minus-square-o' : 'plus-square-o'}
                className={classnames(['clickable', style.expandableIcon])}
                onClick={() => this.setState({showAPTestSection: !this.state.showAPTestSection})}
              />
            </Col>
          </Row>
          <Row style={{height: '100%'}} className={!this.state.showAPTestSection ? style.hiddenSection : ''}>
            <Col xs={12} className={style.sectionTitle}>
              AP Test
            </Col>
          </Row>
        </Col>
        <Col xs={10}
          className={classnames([style.sectionTitleForHidden, this.state.showAPTestSection ? style.hiddenSection : ''])}
        >
          AP Test
        </Col>
        <Col xs={11} className={!this.state.showAPTestSection ? style.hiddenSection : ''}>
          <Row id="t-client-personal-details-approp-test" className={style.personalDetails}>
            <Col xs={6}>
              <dl className="dl-horizontal long-title">
                {leftCol.map(this.renderAppropTestItem)}
              </dl>
            </Col>
            <Col xs={6}>
              <dl className="dl-horizontal long-title">
                {rightCol.map(this.renderAppropTestItem)}
              </dl>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderAffiliateCountriesData() {
    const {affCountriesLoaded, affCountriesLoading} = this.state
    const {affiliateCountries, personalDetails} = this.props.client
    const sidebars = frontendEnums.clientTabs.details.sidebars

    const affCountriesKeys = first(affiliateCountries)
    const affCountriesLabel = []
    forEach(affCountriesKeys, (aff) => {
      map(enums.countries, (c) => {
        if (c.key === aff) {
          affCountriesLabel.push(c.label)
        }
      })
    })
    const mulitpleRegulationClient = get(personalDetails, 'multiRegulationClientId')

    return (
      <Row className={style.outerRow}>
        <Col xs={1} className={style.expandColumn}>
          <Row>
            <Col xs={12}>
              <FontAwesomeIcon
                icon={this.state.showPartnerDataSection ? 'minus-square-o' : 'plus-square-o'}
                className={classnames(['clickable', style.expandableIcon])}
                onClick={() => this.setState({showPartnerDataSection: !this.state.showPartnerDataSection})}
              />
            </Col>
          </Row>
          <Row style={{height: '100%'}} className={!this.state.showPartnerDataSection ? style.hiddenSection : ''}>
            <Col xs={12} className={style.sectionTitle}>
              Partner Data
            </Col>
          </Row>
        </Col>
        <Col xs={10}
          className={classnames([style.sectionTitleForHidden, this.state.showPartnerDataSection ? style.hiddenSection : ''])}
        >
          Partner Data
        </Col>
        <Col xs={11} className={!this.state.showPartnerDataSection ? style.hiddenSection : ''}>
          <Row className={style.personalDetails}>
            <Col xs={12}>
              <dl className="dl-horizontal long-title">
                <dt> Promoting Countries </dt>
                {
                  !affCountriesLoaded && !affiliateCountries ? <dd>
                    <Button
                      id="t-client-personal-details-load-promoting-countries-btn"
                      variant="outline-secondary"
                      size="sm"
                      disabled={affCountriesLoading}
                      onClick={() => {
                        this.setState({affCountriesLoading: true}, () => {
                          this.context.clientProvider.subProviders.affiliateCountries.fetch()
                            .then(() => this.setState({affCountriesLoaded: true}))
                            .catch(() => this.setState({affCountriesLoaded: true}))
                        })
                      }}
                    >
                      Load Promoting Countries
                    </Button>
                  </dd> : <dd>
                    <p> {affCountriesLabel.join(', ')} </p>
                    {canWriteClientDetails(this.props.viewer) && <Button
                      id="t-client-personal-details-edit-promoting-countries-btn"
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => this.switchSidebar(sidebars.editPromotingCountries.name)}
                    >
                      Edit Promoting Countries
                    </Button>}
                  </dd>
                }
              </dl>
              {mulitpleRegulationClient && <dl className="dl-horizontal long-title space-wrap">
                <dt> Multiple Regulation Client </dt>
                <dd>
                  {linkClientDetails(mulitpleRegulationClient, mulitpleRegulationClient)}
                </dd>
              </dl>}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  renderWalkthroughMessage(type) {
    const {walkthroughs} = this.props.client
    const walkthrough = find(walkthroughs, (w) => w.type === type)
    if (!walkthrough) {
      return <div> <i> not yet provided </i> </div>
    }
    return <div> <i>provided by </i> <b>{walkthrough.givenBy}</b>, {walkthrough.createdAt.fromNow()} </div>
  }

  renderPotentialMessage() {
    const {generalInfo} = this.props.client
    const level = get(generalInfo, 'potentialLevel')
    const message = isEmpty(level)
      ? <i> not yet provided </i>
      : find(enums.clientPotentialLevels, {value: level}).label

    return <div>
      <span>{message}</span>
      <span className="float-right">
        <Button
          id="t-update-potentials"
          variant="outline-secondary"
          className="btn-xs"
          title="Edit Potential Level"
          onClick={() => this.switchSidebar(frontendEnums.clientTabs.details.sidebars.updatePotentials.name)}
        >
          <FontAwesomeIcon icon="edit" />
        </Button>
      </span>
    </div>
  }

  runGBGCheck() {
    const {actions: {client: clientActions}, client: {personalDetails}} = this.props
    this.setState({checkingGBG: true, showIdVerificationConfirmation: false, idVerificationType: null}, () => {
      clientActions.doClientGBGCheck(personalDetails.id).then((res) => {
        this.context.clientProvider.fetch()
        this.setState({checkingGBG: false})
      })
        .catch((e) => {
          this.context.logError(e)
          this.setState({checkingGBG: false})
        })
    })
  }


  runEIDVerification() {
    const {actions: {client: clientActions}, client: {personalDetails}} = this.props
    const {clientProvider} = this.context
    this.setState({
      runningEIDVerification: true,
      showIdVerificationConfirmation: false,
      idVerificationType: null,
    }, () => {
      clientActions.doElectronicIdVerification(personalDetails.id)
        .then(() => Promise.all([
          clientProvider.subProviders.basicData.fetch(),
          clientProvider.subProviders.eIDVerificationResults.fetch(),
        ]))
        .then(() => {
          this.setState({runningEIDVerification: false})
          this.context.showNotification({
            title: 'eID Verification Result Ready',
            message: 'Verification result is ready, check documents tab for the result',
            position: 'tr',
            level: 'success',
          })
        })
        .catch((e) => {
          this.context.logError(e)
          this.setState({runningEIDVerification: false})
        })
    })
  }

  canAutoCheckGBG() {
    const {client: {personalDetails}, config} = this.props
    return config.gbgAutoCheckEnabledCountries.includes(get(personalDetails, 'address.country')) &&
      hasValues(personalDetails, [
        'address.line1',
        'address.city',
        'address.zip',
        'address.region',
        'firstName',
        'lastName',
        'birthday'])
  }

  canRunEIDVerification() {
    const {client: {personalDetails}, config} = this.props
    return config.eIdVCheckCountries.includes(get(personalDetails, 'address.country')) &&
      hasValues(personalDetails, [
        'address.line1',
        'address.city',
        'address.zip',
        'address.region',
        'firstName',
        'lastName',
        'birthday'])
  }

  showGBGCheckResult() {
    this.setState({showGbgResult: true})
  }

  renderIdVerificationCheckConfirmation(type) {
    this.setState({showIdVerificationConfirmation: true, idVerificationType: type})
  }

  savePhone() {
    const {client: {generalInfo: {id}, personalDetails}} = this.props
    const phone = get(personalDetails, 'phone', '')
    const secondaryPhone1 = get(personalDetails, 'secondaryPhones.secondaryPhone1', '')
    const variables = {}
    if (this.state.newPhone !== phone) {
      variables.phone = this.state.newPhone
    }
    if (this.state.newSecondaryPhone1 !== secondaryPhone1) {
      variables.secondaryPhone1 = this.state.newSecondaryPhone1
    }
    if (!isEmpty(variables)) {
      this.props.actions.client.updateClientPhones(id, variables)
        .then(() => {
          this.context.clientProvider.subProviders.basicData.fetch(true)
          this.context.clientProvider.subProviders.activityLogs.fetch()

          this.setState({
            editingPhone: false,
            editingSecondaryPhone1: false,
            phoneRevealed: false,
          })
        })
        .catch(this.context.logError)
    }
  }

  saveEmail() {
    const {client: {generalInfo: {id}, personalDetails}} = this.props
    const email = get(personalDetails, 'email', '')
    const variables = {}
    if (this.state.newEmail !== email) {
      variables.email = this.state.newEmail
    }
    if (!isEmpty(variables)) {
      this.props.actions.client.updateClientDetails(id, variables)
        .then(() => {
          this.context.clientProvider.subProviders.basicData.fetch(true)
          this.context.clientProvider.subProviders.activityLogs.fetch()

          this.setState({
            editingEmail: false,
            emailRevealed: false,
          })
        })
        .catch(this.context.logError)
    }
  }

  toggleTopVip(topVip) {
    const {clientId} = this.props
    this.setState({topVip})

    this.props.actions.client.toggleTopVip(clientId, topVip)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          topVip: !topVip,
        })
      })
  }

  toggleEffectiveFTD(agentEffectiveFTD) {
    const {clientId} = this.props
    this.setState({agentEffectiveFTD}, () => {
      this.props.actions.client.toggleEffectiveFTD(clientId, agentEffectiveFTD)
        .then(() => {
          this.context.clientProvider.subProviders.basicData.fetch()
          this.context.clientProvider.subProviders.activityLogs.fetch()
        })
        .catch((e) => {
          this.context.logError(e)
          this.setState({
            agentEffectiveFTD: !agentEffectiveFTD,
          })
        })
    })
  }

  toggleCanGrantSpoa(canGrantSpoa) {
    const {clientId} = this.props
    this.setState({canGrantSpoa})

    this.props.actions.client.toggleCanGrantSpoa(clientId, canGrantSpoa)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          canGrantSpoa: !canGrantSpoa,
        })
      })
  }

  toggleCanIbTransfer(canIbTransfer) {
    const {clientId} = this.props
    this.setState({canIbTransfer})

    this.props.actions.client.toggleCanIbTransfer(clientId, canIbTransfer)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          canIbTransfer: !canIbTransfer,
        })
      })
  }

  toggleHasPortfolioManagement(hasPortfolioManagement) {
    const {clientId} = this.props
    this.setState({hasPortfolioManagement})

    this.props.actions.client.toggleHasPortfolioManagement(clientId, hasPortfolioManagement)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          hasPortfolioManagement: !hasPortfolioManagement,
        })
      })
  }

  toggleIgnoreAutoMigration(ignoreAutoMigration) {
    const {clientId} = this.props
    this.setState({ignoreAutoMigration})

    this.props.actions.client.toggleIgnoreAutoMigration(clientId, ignoreAutoMigration)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          ignoreAutoMigration: !ignoreAutoMigration,
        })
      })
  }

  toggleHasRawAccount(hasRawAccount) {
    const {clientId} = this.props
    this.setState({hasRawAccount})

    this.props.actions.client.toggleHasRawAccount(clientId, hasRawAccount)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          hasRawAccount: !hasRawAccount,
        })
      })
  }

  toggleHasVipAccount(hasVipAccount) {
    const {clientId} = this.props
    this.setState({hasVipAccount})

    this.props.actions.client.toggleHasVipAccount(clientId, hasVipAccount)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          hasVipAccount: !hasVipAccount,
        })
      })
  }

  toggleIgnoreIbTermination(ignoreIbTermination) {
    const {clientId} = this.props
    this.setState({ignoreIbTermination})

    this.props.actions.client.toggleIgnoreIbTermination(clientId, ignoreIbTermination)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          ignoreIbTermination: !ignoreIbTermination,
        })
      })
  }

  toggleIsDirectIB(isDirectIB) {
    const {clientId} = this.props
    this.setState({isDirectIB})

    this.props.actions.client.toggleIsDirectIB(clientId, isDirectIB)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          isDirectIB: !isDirectIB,
        })
      })
  }

  toggleHasOpenComplaint(hasOpenComplaint) {
    const {clientId} = this.props
    this.setState({hasOpenComplaint})

    this.props.actions.client.toggleHasOpenComplaint(clientId, hasOpenComplaint)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          isDirectIB: !hasOpenComplaint,
        })
      })
  }

  toggleAutochartistTools(value, type) {
    const {clientId} = this.props
    this.setState({[type]: value})
    this.props.actions.client.toggleAutochartistTools(clientId, {[type]: value})
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          [type]: !value,
        })
      })
  }

  savePartnerCustomization(partnerCustomization, partnerKey, partnerId, fieldKey, value) {
    let args = {}
    if (partnerCustomization) {
      args = {
        id: partnerCustomization.id,
        ibId: partnerCustomization.ibId,
        affiliateId: partnerCustomization.affiliateId,
        [fieldKey]: value,
      }
    } else {
      args = {
        [partnerKey]: partnerId,
        [fieldKey]: value,
      }
    }

    this.props.actions.partnersCustomization.upsertPartnerCustomization(args)
      .then((res) => {
        this.context.showNotification({
          title: 'Partner Settings',
          message: 'Changes saved successfully',
          position: 'tr',
          level: 'success',
        })
      })
      .then(() => this.context.clientProvider.subProviders.basicData.fetch())
      .catch((e) => {
        this.context.logError(e)
      })
  }

  toggleHasHotAssets(hasHotAssets) {
    const {clientId} = this.props
    this.setState({hasHotAssets})

    this.props.actions.client.toggleHasHotAssets(clientId, hasHotAssets)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          hasHotAssets: !hasHotAssets,
        })
      })
  }

  toggleHasTelegram(hasTelegram) {
    const {clientId} = this.props
    this.setState({hasTelegram})

    this.props.actions.client.toggleHasTelegram(clientId, hasTelegram)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          hasTelegram: !hasTelegram,
        })
      })
  }

  toggleAllowCopyTrading(allowCopyTrading, force) {
    const {clientId, client} = this.props
    if (!allowCopyTrading && !force) {
      this.setState({showFollowersPopup: true})
    } else {
      this.setState({allowCopyTrading})
      this.props.actions.client.toggleAllowCopyTrading(clientId, allowCopyTrading, force)
        .then(() => {
          force && this.setState({showFollowersPopup: false})
          if (!allowCopyTrading && get(client, 'personalDetails.showPasswordProtectedStrategies')) {
            this.togglePasswordProtectedStrategies(allowCopyTrading)
          } else {
            this.context.clientProvider.subProviders.basicData.fetch()
            this.context.clientProvider.subProviders.activityLogs.fetch()
          }
        })
        .catch((e) => {
          const handleError = e.message.replace('GraphQL Error: ', '') === 'Copy Trading has active followers'
          !handleError && this.context.logError(e)
          this.setState({
            allowCopyTrading: !allowCopyTrading,
            showFollowersPopup: handleError,
          })
        })
    }
  }

  toggleShowMasterStrategies(showMasterStrategies) {
    const {clientId} = this.props
    this.setState({showMasterStrategies})
    this.props.actions.client.toggleShowMasterStrategies(clientId, showMasterStrategies)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }

  toggleIsPhoneVerified(isPhoneVerified) {
    const {clientId} = this.props
    this.setState({isPhoneVerified})

    this.props.actions.client.toggleIsPhoneVerified(clientId, isPhoneVerified)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.setState({
          isPhoneVerified: !isPhoneVerified,
        })
      })
  }

  toggleEmailConfirmed(emailConfirmed) {
    const {clientId} = this.props
    this.setState({emailConfirmed})

    this.props.actions.client.toggleEmailConfirmed(clientId, emailConfirmed)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.setState({emailConfirmed: !emailConfirmed})
      })
  }

  toggleDormantReactivation(dormantReactivation) {
    const {clientId} = this.props
    this.setState({dormantReactivation})

    this.props.actions.client.toggleDormantReactivation(clientId, dormantReactivation)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        e.isShowActualError = true
        this.context.logError(e)
        this.setState({dormantReactivation: !dormantReactivation})
      })
  }

  toggleFundManager(fundManager) {
    const {clientId} = this.props
    this.setState({fundManager})

    this.props.actions.client.toggleFundManager(clientId, fundManager)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }

  trackCopyEvent(clientId, field) {
    this.props.actions.client.trackCopyEvent(clientId, field).then(() => true)
  }

  revealPhone(clientId) {
    this.props.actions.client.revealPhone(clientId).then((phones) => {
      const currentPhone = get(this.props, 'client.personalDetails.phone', '')
      const currentSecPhone = get(this.props, 'client.personalDetails.secondaryPhones', '')
      const phone = get(phones, 'phone', currentPhone)
      const secondaryPhones = get(phones, 'secondaryPhones', currentSecPhone)
      if (phone) {
        this.props.dispatch('Dispatching Reveal Phone',
          (state) => ({
            ...state,
            client: {
              [clientId]: {
                ...state.client[clientId],
                generalInfo: {
                  ...state.client[clientId].generalInfo,
                  phone,
                  secondaryPhones,
                },
                personalDetails: {
                  ...state.client[clientId].personalDetails,
                  phone,
                  secondaryPhones,
                },
              },
            },
          }))
        this.setState({
          phoneRevealed: true,
          newPhone: phone,
          newSecondaryPhone1: get(phones, 'secondaryPhones.secondaryPhone1', '')})
      }
    })
  }

  revealEmail(clientId) {
    this.props.actions.client.revealEmail(clientId).then((clientEmails) => {
      if (clientEmails) {
        this.props.dispatch('Dispatching Reveal Email',
          (state) => ({
            ...state,
            client: {
              [clientId]: {
                ...state.client[clientId],
                generalInfo: {
                  ...state.client[clientId].generalInfo,
                  email: clientEmails.email,
                  secondaryEmails: clientEmails.secondaryEmails,
                },
                personalDetails: {
                  ...state.client[clientId].personalDetails,
                  email: clientEmails.email,
                  secondaryEmails: clientEmails.secondaryEmails,
                },
              },
            },
          }))
        this.setState({emailRevealed: true, newEmail: clientEmails.email})
      }
    })
  }

  renderSpoa() {
    const activityLogs = this.props.client.activityLogs
    const spoaActivity = find(activityLogs, (log) => log.type === 'spoaAuthorization')
    if (spoaActivity) {
      const {spoaAuthorization, spoaIbFName, spoaIbLName, spoaIbID} = spoaActivity
      const remoteClientString = `${spoaIbFName} ${spoaIbLName} #${spoaIbID}`
      const authorizationStatus = spoaAuthorization ? 'granted' : 'revoked'
      return (
        <div>
          <dt>SPOA</dt>
          <dd>
            {enums.activityLogSpoaAuthResult[authorizationStatus].label}{' '}
            {enums.activityLogSpoaAuthResult[authorizationStatus].postposition}{' '}
            {linkClient(remoteClientString, spoaIbID)}&nbsp;
          </dd>
        </div>
      )
    }
  }

  renderSaveButton(title, onClick) {
    return (
      <Button
        variant="outline-secondary"
        title={title}
        size="sm"
        onClick={onClick.bind(this)}
      >
        <FontAwesomeIcon icon="save" />
      </Button>
    )
  }

  renderCancelEditButton(editValue, phoneVariable, phoneValue) {
    return (
      <Button
        variant="outline-secondary"
        title="Cancel"
        size="sm"
        onClick={() => {this.setState({[editValue]: false, [phoneVariable]: phoneValue})}}
      >
        <FontAwesomeIcon icon="times" />
      </Button>
    )
  }

  renderIdVerificationConfirmationModal() {
    const {showIdVerificationConfirmation, idVerificationType} = this.state
    let title, verificationAction

    if (idVerificationType === 'gbg') {
      title = 'Confirm Running GBG Check'
      verificationAction = this.runGBGCheck.bind(this)
    } else if (idVerificationType === 'eidv') {
      title = 'Confirm Running eID Verification Check'
      verificationAction = this.runEIDVerification.bind(this)
    } else {
      return null
    }

    return (
      <Modal
        keyboard
        show={showIdVerificationConfirmation}
        onHide={() => this.setState({showIdVerificationConfirmation: false})}
      >
        <Modal.Header>
          <strong>{title}</strong>
        </Modal.Header>
        <Modal.Body>
          Please ensure you have entered correct details and confirm that you want to perform this check.
        </Modal.Body>
        <Modal.Footer>
          <ButtonToolbar className="float-right">
            <Button
              onClick={() => this.setState({showIdVerificationConfirmation: false, idVerificationType: null})}
              variant="outline-secondary"
              size="sm"
              className="mr-2"
            >
              Cancel
            </Button>
            <Button
              variant="success"
              onClick={() => {verificationAction()}}
              size="sm"
            >
              Proceed to check
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }

  toggleBDXCallCompleted(bdxCallCompleted) {
    const {clientId} = this.props
    this.setState({bdxCallCompleted})

    this.props.actions.client.toggleBDXCallCompleted(clientId, bdxCallCompleted)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          bdxCallCompleted: !bdxCallCompleted,
        })
      })
  }

  bdxWaiverAccepted(bdxWaiverAccepted) {
    const {clientId} = this.props
    this.setState({bdxWaiverAccepted})

    this.props.actions.client.bdxWaiverAccepted(clientId, bdxWaiverAccepted)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          bdxWaiverAccepted: !bdxWaiverAccepted,
        })
      })
  }


  handleBDXChange(bdxFinalChoice) {
    this.setState({bdxFinalChoice})
  }

  bdxInvestmentChoiceFinalEdit() {
    const {clientId} = this.props
    const {bdxFinalChoice} = this.state
    this.props.actions.client.bdxInvestmentChoiceFinalEdit(clientId, bdxFinalChoice)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
        this.setState({
          bdxFinalChoice: bdxFinalChoice,
        })
      })
  }

  showTrackEventsModal = () => {
    this.setState({showTrackEventsModal: true})
  }

  hideTrackEventsModal  = () => {
    this.setState({showTrackEventsModal: false})
  }

  hasAvailableTemplate = (viewer, client) =>
    find(enums.salesAgentEmailTemplateReasons, (template) => template.isAvailable(viewer, client) === true) !== undefined

  togglePasswordProtectedStrategies(showPasswordProtectedStrategies) {
    const {clientId} = this.props
    this.setState({showPasswordProtectedStrategies})
    this.props.actions.client.togglePasswordProtectedStrategies(clientId, showPasswordProtectedStrategies)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }

  toggleTradingCentral(tradingCentralEnabled) {
    const {clientId} = this.props
    this.setState({tradingCentralEnabled})
    this.props.actions.client.toggleTradingCentral(clientId, tradingCentralEnabled)
      .then(() => {
        this.context.clientProvider.subProviders.basicData.fetch()
        this.context.clientProvider.subProviders.activityLogs.fetch()
      })
      .catch((e) => {
        this.context.logError(e)
      })
  }

  renderClientDetails() {
    const {
      personalDetails,
      generalInfo: {company, trackEventsCount, fromCompany, migratedAt},
      eIdVerificationResults} = this.props.client
    const lastEiDVerificationResult = first(orderBy(eIdVerificationResults, ['id'], ['desc']))
    const isAffiliate = [
      enums.clientTypes.affiliate.key,
      enums.clientTypes.affiliateCorporate.key,
    ].includes(personalDetails.clientType)

    const viewer = this.props.viewer
    const sidebars = frontendEnums.clientTabs.details.sidebars

    const birthday = personalDetails.birthday ? personalDetails.birthday.clone() : null
    const {
      gender, nationality, language, email, phone, isPhoneVerified, address, secondaryEmails, id,
      registrationCampaigns, vps, skypeId, website, mifirId, mifirType, globalQuestionnaire, whiteLabel,
      clientType, secondaryPhones, totalDeposits, totalTransfers,
      emailConfirmed, totalSubscriptions, cookiePath, dormantReactivation, customDomain,
      ignoreIbTermination, allowCopyTrading, linkedRetailClientId, linkedRetailClientName, linkedPartnerClientId,
      linkedPartnerClientName, hasHotAssets, hasTelegram, nickname, isDirectIB, hasOpenComplaint,
      hasPortfolioManagement, referredBy, hasRawAccount, hasVipAccount, blockedPhoneBirthdayReveal,
      totalWithdrawals, showMasterStrategies, ignoreAutoMigration, showPasswordProtectedStrategies,
      tradingCentralEnabled, fundManager
    } = personalDetails

    const {line1, city, zip, region} = address || {}
    const {secondaryEmail1, secondaryEmail2, secondaryEmail3} = secondaryEmails || {}
    const {secondaryPhone1} = secondaryPhones || {}
    const {riskCategory, clientCategory, politicallyExposedPerson,
      worldCheck, clientGBG, officialName, policy15Days, inWatchlists, inWatchlistsDate} =
      personalDetails.accountChecks || {}
    const {transactionPurpose, jobTitle, approxYearlyIncome, approxNetWorth, sourceOfFunds, natureOfTransactions,
      originOfFunds, politicallyExposed,approxExpectedDeposit, usCitizen, tin, tinReason, taxJurisdictionCountry,
      transactionPurposeClarify, natureOfTransactionsClarify, sourceOfFundsClarify, tinClarify, politicallyExposedReason} = globalQuestionnaire || {}
    const {editingPhone, editingSecondaryPhone1, editingEmail} = this.state
    const hideRevealEmailButton = viewer.roles.some((r) => ['super_user', 'view_client_email'].includes(r))
    const disableAutchartistButton = !viewer.roles.some((r) => ['autochartist_access'].includes(r))
    const netWorthEnums = {
      ...enums.appropTestNetWorth,
      ...enums.profileNetWorth,
    }

    //BDX Investment Choices array
    const BDXInvestementChoices = [
      {label:'STO', key:'sto'},
      {label:'BDSwiss Trading Account',key:'bdswissTrading'},
      {label:'BDSwiss Shares',key:'bdswissShares'},
      {label:'Buy Back',key:'buyback'},
      {label:'Split',key:'split'},
      {label:'Manual',key:'manual'}
    ]
    const trackEvents = cloneDeep(this.props.client.generalInfo.trackEvents)
    const lastTrackEvent = trackEvents && head(trackEvents)

    let trackEvent = find(trackEvents, (e) => !isEmpty(e.pParams) && (e.attended || isNull(e.attended)))
    if (isEmpty(trackEvent)) {
      trackEvent = find(trackEvents, (e) => isEmpty(e.pParams) && (e.attended || isNull(e.attended)))
      if (isEmpty(trackEvent))
        trackEvent = lastTrackEvent
    }

    const hasJMSharedDrive = Boolean(get(personalDetails, 'hasJMSharedDrive', 'false'))
    const enableCopyTrading = [enums.clientTypes.affiliate.key, enums.clientTypes.affiliateCorporate.key,
      enums.clientTypes.ib.key, enums.clientTypes.ibCorporate.key,
      enums.clientTypes.strategicPartner.key].includes(clientType)
    const multipleCallingCodes = address && address.country && get(enums.countries[address.country.toLowerCase()], 'callingCodes')
    const checkWhiteLabel = (whiteLabel && enums.whiteLabels[whiteLabel].internal) || !whiteLabel
    const enablePortfolioManagement = find(enums.accountTypes, (a)=> a.baseProduct === 'portfolioManagementAccount' && a.company === company) && checkWhiteLabel
    const licenseAcknowledgement = get(personalDetails, 'licenseAcknowledgement')
    const titleClass = licenseAcknowledgement ? 'very-long-title' : 'long-title'
    const country = find(enums.countries, {key: address?.country})
    const timezone = country ? ct.getCountry(country.value)?.timezones?.[0] : ''
    const clientLocalTime = timezone
      ? <Badge style={{verticalAlign: 'inherit'}} variant="success">{momentTz().tz(timezone).format('YYYY-MM-DD HH:mm')}</Badge>
      : ''
    const expandableSections = pick(this.state, ['showMarketingSection', 'showRiskSection', 'showEPSection',
      'showAPTestSection', 'showPartnerDataSection', 'showEventsSection', 'showStrategicPartnerSection',
      'showBDXSection', 'showClientToolsSection']
    )
    const ibTypes = [enums.clientTypes.ib.key, enums.clientTypes.ibCorporate.key]
    const isUnderIb = first(registrationCampaigns)?.affiliateUserName?.startsWith('IB-')
    return (
      <React.Fragment>
        <Row className="mb-3 mt-3">
          <Col xs={12}>
            <ButtonGroup>
              <Button
                id="t-expand-all"
                variant="outline-secondary"
                size="sm"
                onClick={() => this.setState({...mapValues(expandableSections, () => true)})}
              >
                Expand All
              </Button>
              <Button
                id="t-collapse-all"
                variant="outline-secondary"
                size="sm"
                onClick={() => this.setState({...mapValues(expandableSections, () => false)})}
              >
                Collapse All
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Row className={style.outerRow}>
          <Col xs={1} className={style.expandColumn}>
            <Row>
              <Col xs={12}>
                <FontAwesomeIcon
                  icon={this.state.showClientToolsSection ? 'minus-square-o' : 'plus-square-o'}
                  className={classnames(['clickable', style.expandableIcon])}
                  onClick={() => this.setState({showClientToolsSection: !this.state.showClientToolsSection})}
                />
              </Col>
            </Row>
            <Row style={{height: '100%'}} className={!this.state.showClientToolsSection ? style.hiddenSection : ''}>
              <Col xs={12} className={style.sectionTitle}>
                User Settings
              </Col>
            </Row>
          </Col>
          <Col xs={10}
            className={classnames([style.sectionTitleForHidden, this.state.showClientToolsSection ? style.hiddenSection : ''])}
          >
            User Settings / Trading Tools
          </Col>
          <Col xs={11} className={!this.state.showClientToolsSection ? style.hiddenSection : ''}>
            <Row className={style.personalDetails} style={{clear: 'both'}}>
              <Col xs={12} style={{textAlign: 'center', fontSize: 17}}><strong>User Settings</strong></Col>
              {canToggleTopVip(viewer) && <Col md={3}>
                <Form.Check
                  id="t-top-vip"
                  type="checkbox"
                  label={<span><strong>Vip</strong></span>}
                  checked={this.state.topVip}
                  disabled={!canToggleTopVip(viewer)}
                  onChange={(e) => {this.toggleTopVip(e.target.checked)}}
                />
              </Col>}
              {canToggleEffectiveFTD(viewer) && <Col md={3}>
                <Form.Check
                  id="t-agent-effective-ftd"
                  type="checkbox"
                  label={<span><strong> {'Effective FTD'} </strong></span>}
                  checked={this.state.agentEffectiveFTD}
                  disabled={!canToggleEffectiveFTD(viewer)}
                  onChange={(e) => {this.toggleEffectiveFTD(e.target.checked)}}
                />
              </Col>}
              <Col md={3}>
                <Form.Check
                  id="t-can-grant-spoa"
                  type="checkbox"
                  label={<strong> {'Can grant SPOA'} </strong>}
                  checked={this.state.canGrantSpoa}
                  onChange={(e) => {this.toggleCanGrantSpoa(e.target.checked)}}
                />
              </Col>
              <Col md={3}>
                <Form.Check
                  id="t-can-ib-transfer"
                  type="checkbox"
                  label={<strong> {'Can IB Transfer'} </strong>}
                  checked={this.state.canIbTransfer}
                  onChange={(e) => {this.toggleCanIbTransfer(e.target.checked)}}
                />
              </Col>
              {canTogglePhoneVerified(viewer) && <Col md={3}>
                <Form.Check
                  id="t-checkbox-phone-verified"
                  type="checkbox"
                  label={<span><strong> {'Phone Verified'} </strong></span>}
                  checked={this.state.isPhoneVerified}
                  onChange={(e) => {this.toggleIsPhoneVerified(e.target.checked)}}
                />
              </Col>}
              {canToggleEmailConfirmed(viewer) && <Col md={3}>
                <Form.Check
                  id="t-checkbox-email-verified"
                  type="checkbox"
                  label={<span><strong> {'Email Verified'} </strong></span>}
                  checked={emailConfirmed}
                  onChange={(e) => {this.toggleEmailConfirmed(e.target.checked)}}
                />
              </Col>}
              {canToggleDormantReactivation(viewer) && <Col md={3}>
                <Form.Check
                  id="t-checkbox-dormant-reactivation"
                  type="checkbox"
                  label={<span><strong>Dormant Reactivation</strong></span>}
                  checked={dormantReactivation}
                  onChange={(e) => {this.toggleDormantReactivation(e.target.checked)}}
                />
              </Col>}
              <Col md={3}>
                <Form.Check
                  id="t-ignore-ib-termination"
                  type="checkbox"
                  label={<strong>Ignore IB Termination</strong>}
                  checked={ignoreIbTermination}
                  onChange={(e) => {this.toggleIgnoreIbTermination(e.target.checked)}}
                />
              </Col>
              <Col md={3}>
                <Form.Check
                  id="t-direct-ib"
                  type="checkbox"
                  label={<strong>Direct IB</strong>}
                  checked={isDirectIB}
                  onChange={(e) => {this.toggleIsDirectIB(e.target.checked)}}
                />
              </Col>
              <Col md={3}>
                <Form.Check
                  id="t-has-open-complaint"
                  type="checkbox"
                  label={<strong>Has Open Complaint</strong>}
                  checked={hasOpenComplaint}
                  onChange={(e) => {this.toggleHasOpenComplaint(e.target.checked)}}
                />
              </Col>
              {enablePortfolioManagement && <Col md={3}>
                <Form.Check
                  id="t-portfolio-management"
                  type="checkbox"
                  label={<strong> {'Portfolio Management'} </strong>}
                  checked={hasPortfolioManagement}
                  disabled={!canTogglePortfolioManagement(viewer)}
                  onChange={(e) => {this.toggleHasPortfolioManagement(e.target.checked)}}
                />
              </Col>}
              {<Col md={3}>
                <Form.Check
                  id="t-ignore-automatic-migration"
                  type="checkbox"
                  label={<strong>Ignore Automatic Migration</strong>}
                  checked={ignoreAutoMigration}
                  disabled={!canWriteClientDetails(viewer)}
                  onChange={(e) => {this.toggleIgnoreAutoMigration(e.target.checked)}}
                />
              </Col>}
              {isAffiliate && <Col md={3}>
                <Form.Check
                  id="t-has-raw-account"
                  type="checkbox"
                  label={<strong>Has Raw Account</strong>}
                  checked={hasRawAccount}
                  disabled={!canWriteClientDetails(viewer)}
                  onChange={(e) => {this.toggleHasRawAccount(e.target.checked)}}
                />
              </Col>}
              {isAffiliate && <Col md={3}>
                <Form.Check
                  id="t-has-vip-account"
                  type="checkbox"
                  label={<strong>Has VIP Account</strong>}
                  checked={hasVipAccount}
                  disabled={!canWriteClientDetails(viewer)}
                  onChange={(e) => {this.toggleHasVipAccount(e.target.checked)}}
                />
              </Col>}
            </Row>
            <Row className={style.personalDetails} style={{clear: 'both'}}>
              <Col xs={12} style={{textAlign: 'center', fontSize: 17}}><strong>Trading Tools</strong></Col>
              <Col xs={3}>
                <Form.Check
                  id="t-toggle-autochartist"
                  type="checkbox"
                  label={<strong>Upgrade Autochartist</strong>}
                  checked={this.state.toggleAutochartist}
                  disabled={disableAutchartistButton}
                  onChange={(e) => {this.toggleAutochartistTools(e.target.checked, 'toggleAutochartist')}}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="t-toggle-autochartist- plugin"
                  type="checkbox"
                  label={<strong>Autochartist Plugin</strong>}
                  checked={this.state.toggleAutochartistPlugin}
                  disabled={disableAutchartistButton}
                  onChange={(e) => {this.toggleAutochartistTools(e.target.checked, 'toggleAutochartistPlugin')}}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="t-toggle-autochartist-coelation-email"
                  type="checkbox"
                  label={<strong>Correlation Email</strong>}
                  checked={this.state.toggleAutochartistCoelationEmail}
                  disabled={disableAutchartistButton}
                  onChange={(e) => {this.toggleAutochartistTools(e.target.checked, 'toggleAutochartistCoelationEmail')}}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="t-toggle-autochartist-emails"
                  type="checkbox"
                  label={<strong>Market Report Email</strong>}
                  checked={this.state.toggleAutochartistEmails}
                  disabled={disableAutchartistButton}
                  onChange={(e) => {this.toggleAutochartistTools(e.target.checked, 'toggleAutochartistEmails')}}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="t-toggle-hot-assets"
                  type="checkbox"
                  label={<strong>Hot Assets</strong>}
                  checked={hasHotAssets}
                  onChange={(e) => {this.toggleHasHotAssets(e.target.checked)}}
                />
              </Col>
              <Col xs={3}>
                <Form.Check
                  id="t-toggle-telegram"
                  type="checkbox"
                  label={<strong>Telegram</strong>}
                  checked={hasTelegram}
                  onChange={(e) => {this.toggleHasTelegram(e.target.checked)}}
                />
              </Col>
              {enableCopyTrading && <Col xs={3}>
                <Form.Check
                  id="t-toggle-allow-copy-trading"
                  type="checkbox"
                  label={<strong>Allow Copy Trading</strong>}
                  checked={allowCopyTrading}
                  onChange={(e) => {this.toggleAllowCopyTrading(e.target.checked)}}
                />
              </Col>}
              {enableCopyTrading && <Col xs={3}>
                <Form.Check
                  id="t-toggle-show-master-strategies"
                  type="checkbox"
                  label={<strong>Show Master Strategies</strong>}
                  checked={showMasterStrategies}
                  onChange={(e) => {this.toggleShowMasterStrategies(e.target.checked)}}
                />
              </Col>}
              {includes(ibTypes, clientType) && <Col xs={3}>
                <Form.Check
                  id="t-toggle-password-protected-strategies"
                  type="checkbox"
                  label={<strong>Password Protected Strategies</strong>}
                  checked={showPasswordProtectedStrategies}
                  onChange={(e) => {this.togglePasswordProtectedStrategies(e.target.checked)}}
                />
              </Col>}
              {canWriteClientDetails(viewer)
                && (includes(ibTypes, clientType) || isUnderIb)
                && <Col xs={3}>
                  <Form.Check
                    id="t-toggle-trading-central"
                    type="checkbox"
                    label={<strong>Trading Central</strong>}
                    checked={tradingCentralEnabled}
                    onChange={(e) => {this.toggleTradingCentral(e.target.checked)}}
                  />
                </Col>}
              {isAffiliate && <Col xs={3}>
                <Form.Check
                  id="t-toggle-fund-manager"
                  type="checkbox"
                  label={<strong>Fund Manager</strong>}
                  checked={fundManager}
                  onChange={(e) => {this.toggleFundManager(e.target.checked)}}
                />
              </Col>}
            </Row>
          </Col>
        </Row>
        <Row className={style.outerRow}>
          <Col xs={1} className={style.expandColumn}>
            <Row style={{height: '100%'}}>
              <Col xs={12} className={style.sectionTitle}>
                Personal Details
              </Col>
            </Row>
          </Col>
          <Col xs={11}>
            <Row className={style.personalDetails}>
              <Col xs={12}>
                {
                  canWriteClientDetails(this.props.viewer) &&
                  <div className="float-right">
                    <Button
                      id="t-client-personal-details-edit-details"
                      variant="dark"
                      size="sm"
                      title="Update Personal Details"
                      onClick={() => this.switchSidebar(sidebars.edit.name)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                  </div>
                }
              </Col>
              <Col xs={6}>
                <dl className="dl-horizontal long-title">
                  <dt>Client ID</dt>
                  <dd>{id}</dd>

                  <dt>Full Name</dt>
                  <dd id="t-client-personal-details-full-name">{getFullName(personalDetails)}</dd>

                  <dt>Date of Birth</dt>
                  <dd>{blockedPhoneBirthdayReveal ? '**********' : birthday ? readableDate(birthday, false) : ''}&nbsp;</dd>

                  <dt>Gender</dt>
                  <dd>{gender && enums.genders[gender].label}&nbsp;</dd>

                  <dt>Nationality</dt>
                  <dd>{nationality && enums.nationalities[nationality].label}&nbsp;</dd>

                  <dt>Language</dt>
                  <dd>{language && enums.languages[language].label}&nbsp;</dd>
                  <dt>SkypeID</dt>
                  <dd>{skypeId}&nbsp;</dd>
                  <dt>Website</dt>
                  <dd>{website}&nbsp;</dd>
                  <dt>Nickname</dt>
                  <dd>{nickname}&nbsp;</dd>
                  <dt>Email</dt>
                  {!editingEmail && <dd>
                    <a href={`mailto:${email}`}
                      onCopy={() => {this.trackCopyEvent(id, 'email')}}
                    >
                      {email || '-'}
                    </a>
                    {!hideRevealEmailButton && !this.state.emailRevealed &&
                    <span>
                      <FontAwesomeIcon
                        icon="eye"
                        className={style.eyeIcon}
                        onClick={() => {this.revealEmail(id)}}
                      />
                    </span>
                    }
                    <ButtonToolbar className="float-right" key="after-reveal-email-btns">
                      {(hideRevealEmailButton || this.state.emailRevealed) && canWriteClientDetails(viewer) && <Button
                        variant="outline-secondary"
                        size="sm"
                        title="Edit Email"
                        onClick={() => this.setState({editingEmail: true})}
                      >
                        <FontAwesomeIcon icon="edit" />
                      </Button>}
                    </ButtonToolbar>
                  </dd>}
                  {editingEmail && <dd>
                    <InputGroup>
                      <Form.Control
                        id="t-edit-email"
                        type="text"
                        size="sm"
                        value={this.state.newEmail}
                        onChange={(e) => {this.setState({newEmail: e.target.value})}}
                      />
                      <InputGroup.Append>
                        {this.renderSaveButton('Save Email', this.saveEmail)}
                        {this.renderCancelEditButton('editingEmail', 'newEmail', email)}
                      </InputGroup.Append>
                    </InputGroup>
                  </dd>}
                  {!isEmpty(secondaryEmail1) && (
                    <div>
                      <dt>Secondary Email 1</dt>
                      <dd id="t-client-personal-details-secondary-email-1">
                        <a href={`mailto:${secondaryEmail1}`}
                          onCopy={() => {this.trackCopyEvent(id, 'secondaryEmail1')}}
                        >
                          {secondaryEmail1}&nbsp;
                        </a>
                      </dd>
                    </div>
                  )}
                  {!isEmpty(secondaryEmail2) && (
                    <div>
                      <dt>Secondary Email 2</dt>
                      <dd id="t-client-personal-details-secondary-email-2">
                        <a href={`mailto:${secondaryEmail2}`}
                          onCopy={() => {this.trackCopyEvent(id, 'secondaryEmail2')}}
                        >
                          {secondaryEmail2}
                        </a>
                        &nbsp;
                      </dd>
                    </div>
                  )}
                  {!isEmpty(secondaryEmail3) && (
                    <div>
                      <dt>Secondary Email 3</dt>
                      <dd id="t-client-personal-details-secondary-email-3">
                        <a href={`mailto:${secondaryEmail3}`}
                          onCopy={() => {this.trackCopyEvent(id, 'secondaryEmail3')}}
                        >
                          {secondaryEmail3}
                        </a>
                        &nbsp;
                      </dd>
                    </div>
                  )}
                  {referredBy &&
                    <div>
                      <dt> Referred By</dt>
                      <dd>{linkClient(`[${referredBy.id}] - ${getFullName(referredBy)}`, referredBy.id, true)}&nbsp;</dd>
                    </div>
                  }

                  <dt>Type</dt>
                  <dd id="t-client-personal-details-type">{get(enums.clientTypes[clientType], 'label', 'Unknown')}</dd>

                  <dt>VPS</dt>
                  <dd id="t-client-personal-details-vps"> { vps && personalDetails.vps ? 'Yes' : 'No'}</dd>

                  <dt>Originated From Mobile</dt>
                  <dd id="t-client-personal-details-originated-from-mobile">
                    {personalDetails.originatedFromMobile ? 'Yes' : 'No'}
                  </dd>

                  {customDomain && [
                    <dt key="dt-customDomain">Custom Domain</dt>,
                    <dd key="dd-customDomain" id="t-client-personal-details-custom-domain">
                      {customDomain}
                    </dd>
                  ]}

                  {!isEmpty(totalSubscriptions) && [
                    <dt key="total-subscriptions">Total Subscription Fees</dt>,
                    totalSubscriptions.map((s) => (
                      <dd
                        id={`t-client-personal-details-total-subscriptions-${s.currency.toLowerCase()}`}
                        key={s.currency}
                      >{getFormattedAmount({amount: s.amount, currency: s.currency})}</dd>
                    ))
                  ]}
                  {linkedPartnerClientId && [
                    <dt key="dt-linked">Linked Partner Client</dt>,
                    <dd id="t-client-personal-details-linked-partner-client" key="dt-value">
                      {linkClient(`${linkedPartnerClientName} [Client ID: ${linkedPartnerClientId}]`, linkedPartnerClientId, true)}
                    </dd>
                  ]}
                  {linkedRetailClientId && [
                    <dt key="dt-linked">Linked Retail Client</dt>,
                    <dd key="dd-linked-value" id="t-client-personal-details-linked-retail-client">
                      {linkClient(`${linkedRetailClientName} [Client ID: ${linkedRetailClientId}]`, linkedRetailClientId, true)}
                    </dd>,
                    <dd id="t-client-personal-details-linked-retail-client-remove" key="dt-linked-remove">
                      {<span
                        style={this.state.unlinkingClients
                          ? {textDecoration: 'underline', cursor: 'wait'}
                          : {textDecoration: 'underline', cursor: 'pointer'}
                        }
                        onClick={() => {
                          this.setState({unlinkingClients: true})
                          this.props.actions.client.linkPartnerWithRetailClient(id)
                            .then(() => {
                              this.setState({unlinkingClients: false})
                              this.context.clientProvider.subProviders.basicData.fetch()
                              this.context.clientProvider.subProviders.activityLogs.fetch()
                            })
                            .catch((e) => {
                              e.isShowActualError = true
                              this.setState({unlinkingClients: false})
                              this.context.logError(e)
                            })
                        }}
                      >
                        Remove
                      </span>}
                    </dd>
                  ]}

                  <dt>Client Potential Level</dt>
                  <dd>{this.renderPotentialMessage()}</dd>
                </dl>
              </Col>
              <Col xs={6}>
                <dl className="dl-horizontal long-title">
                  <dt>Street</dt>
                  <dd>{line1}&nbsp;</dd>

                  <dt>City</dt>
                  <dd>{city}&nbsp;</dd>

                  <dt>Zip</dt>
                  <dd>{zip}&nbsp;</dd>

                  <dt>Region</dt>
                  <dd>{region}&nbsp;</dd>

                  <dt>Country</dt>
                  <dd>{getCountryName(address)} {clientLocalTime}&nbsp;</dd>

                  <dt>Phone</dt>
                  {!editingPhone && <dd>
                    <a href="#" onCopy={() => {this.trackCopyEvent(id, 'phone')}}>{phone}</a>&nbsp;
                    {isPhoneVerified && <FontAwesomeIcon icon="check-circle" className={style.blueIcon} />}
                    {!blockedPhoneBirthdayReveal && [
                      <span key="reveal-btn">
                        {!this.state.phoneRevealed &&
                        <span>
                          <FontAwesomeIcon
                            icon="eye"
                            className={style.eyeIcon}
                            onClick={() => {this.revealPhone(id)}}
                          />
                        </span>
                        }
                      </span>,
                      <ButtonToolbar className="float-right" key="after-reveal-btns">
                        {this.state.phoneRevealed && canUpdateClientPhones(viewer) && <Button
                          variant="outline-secondary"
                          size="sm"
                          title="Edit Phone"
                          onClick={() => this.setState({editingPhone: true})}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Button>}
                      </ButtonToolbar>
                    ]}
                  </dd>}
                  {editingPhone && <dd>
                    <InputGroup>
                      <Form.Control
                        id="t-edit-phone"
                        type="text"
                        size="sm"
                        value={this.state.newPhone}
                        onChange={(e) => {this.setState({newPhone: e.target.value})}}
                      />
                      <InputGroup.Append>
                        {this.renderSaveButton('Save Phone', this.savePhone)}
                        {this.renderCancelEditButton('editingPhone', 'newPhone', phone)}
                      </InputGroup.Append>
                    </InputGroup>
                  </dd>}
                  {multipleCallingCodes &&
                    <div className={style.multipleCallingCodes}>Country with multiple calling codes:
                      ({map(multipleCallingCodes, (c, index) => `${(index ? ', ' : '')}+${c}`)})
                    </div>}
                  {!isEmpty(secondaryPhone1) && (
                    <div>
                      <dt>Secondary Phone</dt>
                      {!editingSecondaryPhone1 && <dd id="t-client-personal-details-secondary-phone-1">
                        <a href={`tel: ${secondaryPhone1}`}
                          onCopy={() => {this.trackCopyEvent(id, 'secondaryPhone1')}}
                        >
                          {secondaryPhone1}
                        </a>
                        {this.state.phoneRevealed && <Button
                          className="float-right"
                          variant="outline-secondary"
                          size="sm"
                          title="Edit Secondary Phone"
                          onClick={() => this.setState({editingSecondaryPhone1: true})}
                        >
                          <FontAwesomeIcon icon="edit" />
                        </Button>}
                      </dd>}
                      {editingSecondaryPhone1 && <dd>
                        <InputGroup>
                          <Form.Control
                            id="t-edit-secondary-phone-1"
                            type="text"
                            size="sm"
                            value={this.state.newSecondaryPhone1}
                            onChange={(e) => {this.setState({newSecondaryPhone1: e.target.value})}}
                          />
                          <InputGroup.Append>
                            {this.renderSaveButton('Save Secondary Phone', this.savePhone)}
                            {this.renderCancelEditButton('editingSecondaryPhone1', 'newSecondaryPhone1', secondaryPhone1)}
                          </InputGroup.Append>
                        </InputGroup>
                      </dd>}
                    </div>
                  )
                  }
                  {
                    viewer.companies.length > 1 && (
                      <div>
                        <dt>Company</dt>
                        <dd id="t-client-personal-details-company">{enums.companies[company].label}</dd>
                      </div>
                    )
                  }
                  {fromCompany && (
                    <div>
                      <dt>Migrated From</dt>
                      <dd id="t-client-personal-details-from-company">
                        {enums.companies[fromCompany].label} - {readableDate(moment(migratedAt))}
                      </dd>
                    </div>
                  )}
                  <dt>MIFIR ID</dt>
                  <dd id="t-client-personal-details-mifir-id">
                    {mifirId}&nbsp;
                  </dd>

                  <dt>MIFIR Type</dt>
                  <dd id="t-client-personal-details-mifir-type">
                    {mifirType && get(enums.clientMifirTypes[mifirType], 'label')}&nbsp;
                  </dd>

                  <dt>PEP</dt>
                  <dd id="t-client-account-checks-pep">
                    {politicallyExposedPerson && enums.yesNo[politicallyExposedPerson].label}&nbsp;
                  </dd>

                  <dt>World Check</dt>
                  <dd id="t-client-account-checks-worldCheck">
                    {worldCheck && enums.yesNo[worldCheck].label}&nbsp;
                  </dd>

                  <dt>In Watchlists</dt>
                  <dd id="t-client-account-checks-watchlist">
                    {inWatchlists && <Badge style={{marginTop: 3}} id="alert-badge" pill variant={inWatchlists === 'no' ? 'success' : 'danger'}>
                      {inWatchlists && `${enums.yesNo[inWatchlists].label} - checked ${moment(inWatchlistsDate).format('LLL')}`}
                    </Badge>}
                    &nbsp;
                  </dd>

                  <dt>Competition Winner</dt>
                  <dd id="t-client-account-competition-winner">
                    {get(find(enums.yesNo, {boolValue: Boolean(get(personalDetails, 'competitionWinners', 'false'))}), 'label')}
                  </dd>

                  <dt>Can Edit Leverage</dt>
                  <dd>{personalDetails.canEditLeverage ? 'Yes' : 'No'}</dd>

                  {hasJMSharedDrive && <div>
                    <dt> Has JM SharedDrive</dt>
                    <dd id="t-client-account-has-jm-shareddrive">
                      {get(find(enums.yesNo, {boolValue: hasJMSharedDrive}), 'label')}
                    </dd>
                  </div>}

                  {hasJMSharedDrive && <div>
                    <dt> Last Update JM SD</dt>
                    <dd id="t-client-account-last-updated -jm-shareddrive">
                      {readableDate(moment(get(personalDetails, 'lastUpdatedJMSharedDrive')), true)}
                    </dd>
                  </div>}

                  {
                    whiteLabel && <div>
                      <dt> White Label </dt>
                      <dd>
                        <Badge pill variant="warning">
                          {get(enums.whiteLabels[whiteLabel], 'label')}
                        </Badge>
                      </dd>
                    </div>
                  }
                  {this.renderSpoa()}
                </dl>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Header>Total Deposits</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col xs={9}>
                          {isEmpty(totalDeposits) ?
                            <span id="t-client-personal-details-total-deposits-no">No Deposits</span> :
                            totalDeposits.map((d) => (
                              <span
                                id={`t-client-personal-details-total-deposits-${d.currency.toLowerCase()}`}
                                key={d.currency}
                              >{getFormattedAmount({amount: d.amount, currency: d.currency})}<br /></span>
                            ))
                          }
                        </Col>
                        <Col xs={3}>
                          {canCreateDeposits(viewer) && <FontAwesomeIcon
                            icon="plus-square"
                            className={classnames(['clickable', style.totalsIcon])}
                            title="New Deposit"
                            onClick={() => this.switchSidebar(sidebars.newClientDeposit.name)}
                          />}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Header>Total Withdrawals</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col xs={9}>
                          {isEmpty(totalWithdrawals) ?
                            <span id="t-client-personal-details-total-withdrawals-no">No Withdrawals</span> :
                            totalWithdrawals.map((d) => (
                              <span
                                id={`t-client-personal-details-total-withdrawals-${d.currency.toLowerCase()}`}
                                key={d.currency}
                              >{getFormattedAmount({amount: d.amount, currency: d.currency})}<br /></span>
                            ))
                          }
                        </Col>
                        <Col xs={3}>
                          {canCreateWithdrawals(viewer) && <FontAwesomeIcon
                            icon="minus-square"
                            className={classnames(['clickable', style.totalsIcon])}
                            title="New Withdrawal"
                            onClick={() => this.switchSidebar(sidebars.newClientWithdrawal.name)}
                          />}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col xs={4}>
                <Card>
                  <Card.Header>Total Transfers</Card.Header>
                  <Card.Body>
                    <Card.Text>
                      <Row>
                        <Col xs={9}>
                          {isEmpty(totalTransfers) ?
                            <span id="t-client-personal-details-total-transfers-no">No Transfers</span> :
                            totalTransfers.map((d) => (
                              <span
                                id={`t-client-personal-details-total-transfers-${d.currency.toLowerCase()}`}
                                key={d.currency}
                              >{getFormattedAmount({amount: d.amount, currency: d.currency})}<br /></span>
                            ))
                          }
                        </Col>
                        <Col xs={3}>
                          {canCreateTransfers(viewer) && <FontAwesomeIcon
                            icon="exchange"
                            className={classnames(['clickable', style.totalsIcon])}
                            title="New Transfer"
                            onClick={() => this.switchSidebar(sidebars.createTransferClient.name)}
                          />}
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
        {this.props.client?.personalDetails?.kartwheelReferrerAccount && <MasterIBSection referrerAccount = {this.props.client?.personalDetails?.kartwheelReferrerAccount} />}
        {clientType === enums.clientTypes.strategicPartner.key &&
          <Row className={style.outerRow}>
            <Col xs={1} className={style.expandColumn}>
              <Row>
                <Col xs={12}>
                  <FontAwesomeIcon
                    icon={this.state.showStrategicPartnerSection ? 'minus-square-o' : 'plus-square-o'}
                    className={classnames(['clickable', style.expandableIcon])}
                    onClick={() => this.setState({showStrategicPartnerSection: !this.state.showStrategicPartnerSection})}
                  />
                </Col>
              </Row>
              <Row style={{height: '100%'}} className={!this.state.showStrategicPartnerSection ? style.hiddenSection : ''}>
                <Col xs={12} className={style.sectionTitle}>
                  Strategic Partner
                </Col>
              </Row>
            </Col>
            <Col xs={10}
              className={classnames([style.sectionTitleForHidden, this.state.showStrategicPartnerSection ? style.hiddenSection : ''])}
            >
              Strategic Partner
            </Col>
            <Col xs={11} className={!this.state.showStrategicPartnerSection ? style.hiddenSection : ''}>
              <Row className={style.personalDetails}>
                <Col xs={6} key="strategic-details-1" className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>Company (Other)</dt>
                    <dd>
                      {personalDetails.otherCompany}
                    </dd>
                  </dl>
                  <dl className="dl-horizontal long-title">
                    <dt>Priority</dt>
                    <dd>
                      {get(priorityLevels[personalDetails.priority], 'label') || ''}
                    </dd>
                  </dl>
                </Col>
                <Col xs={6} key="strategic-details-2" className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>Title</dt>
                    <dd>
                      {personalDetails.title}
                    </dd>
                  </dl>
                  <dl className="dl-horizontal long-title">
                    <dt>Partner</dt>
                    <dd>
                      {personalDetails.bdswissPartner}
                    </dd>
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        {[enums.clientTypes.affiliate.key, enums.clientTypes.ib.key, enums.clientTypes.ibCorporate.key,
          enums.clientTypes.affiliateCorporate.key].includes(clientType) && this.renderAffiliateCountriesData()
        }
        {trackEvent &&
          <Row className={style.outerRow}>
            <Col xs={1} className={style.expandColumn}>
              <Row>
                <Col xs={12}>
                  <FontAwesomeIcon
                    icon={this.state.showEventsSection ? 'minus-square-o' : 'plus-square-o'}
                    className={classnames(['clickable', style.expandableIcon])}
                    onClick={() => this.setState({showEventsSection: !this.state.showEventsSection})}
                  />
                </Col>
              </Row>
              <Row style={{height: '100%'}} className={!this.state.showEventsSection ? style.hiddenSection : ''}>
                <Col xs={12} className={style.sectionTitle}>
                  Events
                </Col>
              </Row>
            </Col>
            <Col xs={10}
              className={classnames([style.sectionTitleForHidden, this.state.showEventsSection ? style.hiddenSection : ''])}
            >
              Events
            </Col>
            <Col xs={11} className={!this.state.showEventsSection ? style.hiddenSection : ''}>
              <Row className={style.personalDetails}>
                <Col xs={12}>
                  {
                    <div className="float-right">
                      <Button
                        id="t-show-track-events"
                        variant="success"
                        size="sm"
                        onClick={() => this.showTrackEventsModal()}
                      >
                        Show All Track Events <Badge>{trackEventsCount}</Badge>
                      </Button>
                    </div>
                  }
                </Col>
                <Col xs={6} className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>Sub Camp Id</dt>
                    <dd id="t-client-personal-details-last-track-event-subcampId">
                      {trackEvent.subCampId}
                    </dd>
                  </dl>
                  <dl className="dl-horizontal long-title">
                    <dt>Event Type</dt>
                    <dd id="t-client-personal-details-last-track-event-event-type">
                      {trackEvent.trackEventType}
                    </dd>
                  </dl>
                  {trackEvent.trackEventType !== enums.trackEventsTypes.webinars.value && <dl className="dl-horizontal long-title">
                    <dt>Attended</dt>
                    <dd id="t-client-personal-details-last-track-event-event-type">
                      <Badge id="alert-badge" className={trackEvent.attended ? style.attended : style.notAttended}>
                        {String(trackEvent.attended ? 'Yes' : 'No').toUpperCase()}
                      </Badge>
                    </dd>
                  </dl>}
                </Col>
                <Col xs={6} className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>Campaign</dt>
                    <dd id="t-client-personal-details-last-track-event-campaign">
                      {trackEvent.campaign}
                    </dd>
                  </dl>
                  <dl className="dl-horizontal long-title">
                    <dt>Created</dt>
                    <dd id="t-client-personal-details-last-track-event-created">
                      {readableDate(moment(trackEvent.createdAt), true)}
                    </dd>
                  </dl>
                </Col>
                {map(parseJSON(get(trackEvent, 'pParams', '{}')), (c, i) =>
                  <Col xs={6} className="dl-horizontal long-title" key={`col-${i}`}>
                    <dl className="dl-horizontal long-title">
                      <dt>{i}</dt>
                      <dd id="t-client-personal-details-last-track-event-p-params">
                        {c}
                      </dd>
                    </dl>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        }
        { companyCheck.isBDX(company) &&
          <Row className={style.outerRow}>
            <Col xs={1} className={style.expandColumn}>
              <Row>
                <Col xs={12}>
                  <FontAwesomeIcon
                    icon={this.state.showBDXSection ? 'minus-square-o' : 'plus-square-o'}
                    className={classnames(['clickable', style.expandableIcon])}
                    onClick={() => this.setState({showBDXSection: !this.state.showBDXSection})}
                  />
                </Col>
              </Row>
              <Row style={{height: '100%'}} className={!this.state.showBDXSection ? style.hiddenSection : ''}>
                <Col xs={12} className={style.sectionTitle}>
                  BDX
                </Col>
              </Row>
            </Col>
            <Col xs={10}
              className={classnames([style.sectionTitleForHidden, this.state.showBDXSection ? style.hiddenSection : ''])}
            >
              BDX
            </Col>
            <Col xs={11} className={!this.state.showBDXSection ? style.hiddenSection : ''}>
              <Row className={style.personalDetails}>
                <Col xs={6} key={'bdx-call-completed'} className="dl-horizontal long-title">
                  <Form.Check
                    id="t-toggle-bdx-call-completed"
                    type="checkbox"
                    label={<strong> {'BDX Call Completed'} </strong>}
                    checked={this.state.bdxCallCompleted}
                    onChange={(e) => {this.toggleBDXCallCompleted(e.target.checked)}}
                  />
                </Col>
                <Col xs={6} key={'bdx-waiver-accepted'} className="dl-horizontal long-title">
                  <Form.Check
                    id="t-bdx-waiver-accepted"
                    type="checkbox"
                    label={<strong> {'BDX Waiver Accepted'} </strong>}
                    checked={this.state.bdxWaiverAccepted}
                    onChange={(e) => {this.bdxWaiverAccepted(e.target.checked)}}
                  />
                </Col>
                <Col xs={6} key={'bdx-accepted-nda'} className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>BDX Accepted NDA</dt>
                    <dd id="t-client-personal-details-bdx-accepted-nda">
                      {personalDetails.bdxAcceptedNDA ? 'Yes' : 'No'}
                    </dd>
                  </dl>
                </Col>
                <Col xs={6} key={'bdx-investment-choice'} className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>BDX Investment Choice</dt>
                    <dd id="t-client-personal-details-bdx-investment-choice">
                      {personalDetails.bdxInvestmentChoice !== 'false' ? personalDetails.bdxInvestmentChoice : ''}
                    </dd>
                  </dl>
                </Col>
                <Col xs={6} key={'bdx-accepted-waiver'} className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>BDX Accepted Waiver</dt>
                    <dd id="t-client-personal-details-bdx-accepted-waiver">
                      {personalDetails.bdxWaiverAccepted ? 'Yes' : 'No'}
                    </dd>
                  </dl>
                </Col>
                <Col xs={6} key={'bdx-investment-choice-final'} className="dl-horizontal long-title">
                  <dl className="dl-horizontal long-title">
                    <dt>Final BDX Choice</dt>
                    {((personalDetails.bdxInvestmentChoiceFinal === 'bdswissTrading') || !personalDetails.bdxWaiverAccepted) &&
                      <dd id="t-client-personal-details-bdx-investment-choice-final">
                        { personalDetails.bdxInvestmentChoiceFinal !== 'false' && personalDetails.bdxInvestmentChoiceFinal}
                      </dd>
                    }
                    {personalDetails.bdxInvestmentChoiceFinal !== 'bdswissTrading' && personalDetails.bdxWaiverAccepted &&
                    <dd id="t-client-personal-details-bdx-investment-choice-final">
                      <Col xs={10}>
                        <StylishSelect
                          id="t-bdx-InvestmentChoiceFinal"
                          placeholderText="BDX Choices"
                          value={this.state.bdxFinalChoice}
                          options={StylishSelect.enumToStylishOptions(orderBy(BDXInvestementChoices, 'label'))}
                          onChange={(e) => this.handleBDXChange(e.value)}
                        />
                      </Col>
                      <Col xs={2}>
                        {this.renderSaveButton('Save BDX Final Choice', this.bdxInvestmentChoiceFinalEdit)}
                      </Col>
                    </dd>
                    }
                  </dl>
                </Col>
              </Row>
            </Col>
          </Row>
        }
        {!isEmpty(registrationCampaigns) && map(registrationCampaigns, (c, i) => {
          const minimumDeposits = get(registrationCampaigns[i], 'campaign?.minimumDeposits')
          const minimumDepositsAccountType = get(registrationCampaigns[i], 'partnerCustomization.minDepositCustomizations')
          const affiliateGroup = get(registrationCampaigns[i], 'campaign?.affiliateGroup')
          const affString = c.affiliate ? `${getFullName(c.affiliate)} [ID: ${c.affiliate.id}]` : ''
          const name = get(registrationCampaigns[i], 'campaign?.name')
          const gdcDisabled = get(registrationCampaigns[i], 'partnerCustomization.gdcDisabled')
          const allowedAccountTypes = get(registrationCampaigns[i], 'partnerCustomization.accountTypeCustomizations.accountSubTypes')
          return (
            <Row className={style.outerRow} key={i}>
              <Col xs={1} className={style.expandColumn}>
                <Row>
                  <Col xs={12}>
                    <FontAwesomeIcon
                      icon={this.state.showMarketingSection ? 'minus-square-o' : 'plus-square-o'}
                      className={classnames(['clickable', style.expandableIcon])}
                      onClick={() => this.setState({showMarketingSection: !this.state.showMarketingSection})}
                    />
                  </Col>
                </Row>
                <Row style={{height: '100%'}} className={!this.state.showMarketingSection ? style.hiddenSection : ''}>
                  <Col xs={12} className={style.sectionTitle}>
                    Marketing
                  </Col>
                </Row>
              </Col>
              <Col xs={10}
                className={classnames([style.sectionTitleForHidden, this.state.showMarketingSection ? style.hiddenSection : ''])}
              >
                Marketing
              </Col>
              <Col xs={11} className={!this.state.showMarketingSection ? style.hiddenSection : ''}>
                <Row className={style.personalDetails}>
                  <Col xs={6} className="mb-0">
                    <dl className="dl-horizontal long-title">
                      <dt>Campaign {`${registrationCampaigns.length > 1 ? i + 1 : ''}`}</dt>
                      <dd id={`t-client-personal-details-campaign-${i}`}>
                        {c.campaignId ? c.campaignId : '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Promo Code {`${registrationCampaigns.length > 1 ? i + 1 : ''}`}</dt>
                      <dd id={`t-client-personal-details-promo-code-${i}`}>
                        {c.campaign?.promoCode ?? '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Type</dt>
                      <dd id={`t-client-personal-details-campaignType-${i}`}>
                        {c.campaign ? get(enums.campaignTypes[c.campaign?.type], 'label') : '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Name</dt>
                      <dd id={`t-client-personal-details-campaignName-${i}`}>{name}</dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>AppsFlyer SiteID</dt>
                      <dd id={`t-client-personal-details-appsflyer-siteid-${i}`}>
                        {c.appsFlyerSiteId ? c.appsFlyerSiteId : '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>GDC Disabled</dt>
                      <dd id={`t-client-personal-details-gdc-disabled-${i}`}>
                        {gdcDisabled ? enums.yesNo[gdcDisabled ? 'yes' : 'no'].label : '-'}
                      </dd>
                    </dl>
                    {get(c, 'affiliate.hasRawAccount') && <dl className="dl-horizontal long-title">
                      <dt>Has Raw Account</dt>
                      <dd>{enums.yesNo[c.affiliate.hasRawAccount ? 'yes' : 'no'].label}</dd>
                    </dl>}
                    {get(c, 'affiliate.hasVipAccount') && <dl className="dl-horizontal long-title">
                      <dt> Has VIP Account </dt>
                      <dd>{enums.yesNo[c.affiliate.hasVipAccount ? 'yes' : 'no'].label}</dd>
                    </dl>}
                  </Col>
                  <Col xs={6} className="mb-0">
                    <dl className="dl-horizontal long-title">
                      <dt>SubCampaign {`${registrationCampaigns.length > 1 ? i + 1 : ''}`}</dt>
                      <dd id={`t-client-personal-details-subcampaign-${i}`}>
                        {c.subCampaignId ? c.subCampaignId : '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Affiliate Group</dt>
                      <dd id={`t-client-personal-details-affiliate-group-${i}`}>
                        {affiliateGroup ? get(enums.accountSubtypes[affiliateGroup], 'label') : '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Affiliate Username</dt>
                      <dd id={`t-client-personal-details-affiliate-username-${i}`}>
                        {c.affiliateUserName ? c.affiliateUserName : '-'}
                      </dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Affiliate</dt>
                      <dd>{c.affiliate ? linkClient(affString, get(c.affiliate, 'id'), true) : '-'}</dd>
                    </dl>
                    <dl className="dl-horizontal long-title">
                      <dt>Call Status</dt>
                      <dd>{get(c.campaign, 'noCall', false) ? `DON'T CALL ${c.campaign?.noCallRatio}%` : 'Call'}</dd>
                    </dl>
                  </Col>
                  <Col xs={12} className="mb-0">
                    <dl className="dl-horizontal long-title">
                      <dt>Minimum FTD</dt>
                      <dd id="t-client-personal-details-minimum-ftds">
                        {
                          !isEmpty(minimumDeposits) ?
                            map(minimumDeposits, (d, j) => (
                              <span key={`minimum-deposit-${j}`}> {d.currency}: {d.amount}
                                {j === (minimumDeposits.length - 1) ? '.' : ','}
                              </span>)) : '-'
                        }
                      </dd>
                    </dl>
                  </Col>
                  {!isEmpty(minimumDepositsAccountType) && <Col xs={12} className="mb-0">
                    <dl className="dl-horizontal long-title">
                      <dt>Account Minimum FTD</dt>
                      <dd id="t-client-personal-details-account-minimum-ftds">
                        {
                          map(minimumDepositsAccountType, (d, j) => (
                            <span key={`minimum-deposit-${j}`}> {get(enums.accountSubtypes[d.accountSubType], 'label')}: {d.amount}
                              {j === (minimumDepositsAccountType.length - 1) ? '.' : ','}
                            </span>))
                        }
                      </dd>
                    </dl>
                  </Col>}
                  {!isEmpty(allowedAccountTypes) && <Col xs={12} className="mb-0">
                    <dl className="dl-horizontal long-title">
                      <dt>Account Types Allowed</dt>
                      <dd id="t-client-personal-details-account-types-allowed">
                        {
                          map(allowedAccountTypes, (accSubType, index) =>
                            <span key={`account-type${index}`}>{get(enums.accountSubtypes[accSubType], 'label')}
                              {index === (allowedAccountTypes.length - 1) ? '.' : ', '}
                            </span>)
                        }
                      </dd>
                    </dl>
                  </Col>}
                  <Col xs={12} className="mb-0">
                    <dl className="dl-horizontal long-title">
                      <dt>Registration Url</dt>
                      <dd id={`t-client-personal-details-registration-url-${i}`}>
                        {get(last(parseJSON(cookiePath)), 'params.href') || '-'}
                      </dd>
                    </dl>
                  </Col>
                  <Col xs={12}>
                    <dl className="dl-horizontal long-title">
                      <dt>Prom. Products Links</dt>
                      <dd>
                        {get(c, 'affiliate.website') || '-'}
                      </dd>
                    </dl>
                  </Col>
                  <Col xs={12}>
                    <dl className="dl-horizontal long-title">
                      <dt>Partner Notes</dt>
                      <dd>
                        <textarea
                          rows={get(c, 'affiliate.affiliateClientNote') ? 4 : 1}
                          disabled={true}
                          defaultValue={get(c, 'affiliate.affiliateClientNote') || '-'}
                          style={get(c, 'affiliate.affiliateClientNote') ? {width: '100%'} : {}}
                        />
                      </dd>
                    </dl>
                  </Col>
                  <div id="campaign-info" style={{paddingLeft: '30px', paddingRight: '30'}}>
                    <div>
                      <b>Campaign Settings</b>
                      <p>
                        <strong>Campaign:</strong> {c.affiliate
                          ? linkEditCampaign(
                            this.state.marketingDetails?.name,
                            this.state.marketingDetails?.campaignId,
                            true
                          )
                          : '-'}
                      </p>
                    </div>
                    <Row
                      className={style.personalDetails}
                      id="t-client-personal-details-marketing-bonus"
                    >
                      <table className="table table-bordered text-center" style={{width: 'auto'}}>
                        <thead className="thead-light">
                          <tr>
                            <th>Settings for</th>
                            <th>Bonus Percentage</th>
                            <th>Minimum Deposit (EUR)</th>
                            <th>Minimum Deposit (USD)</th>
                            <th>Minimum Deposit (GBP)</th>
                          </tr>
                        </thead>
                        <tbody>

                          <tr>
                            <td>First Deposit Bonus Classic</td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusPercentageClassic ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositEURClassic ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositUSDClassic ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositGBPClassic ?? '-'}
                            </td>
                          </tr>

                          <tr>
                            <td>Re-Deposit Bonus Classic</td>
                            <td>
                              {this.state.marketingDetails?.redepositBonusPercentageClassic ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositEURClassic ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositUSDClassic ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositGBPClassic ?? '-'}
                            </td>
                          </tr>

                          <tr>
                            <td>First Deposit Bonus VIP</td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusPercentageVIP ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositEURVIP ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositUSDVIP ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositGBPVIP ?? '-'}
                            </td>
                          </tr>

                          <tr>
                            <td>Re-Deposit Bonus VIP</td>
                            <td>
                              {this.state.marketingDetails?.redepositBonusPercentageVIP ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositEURVIP ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositUSDVIP ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositGBPVIP ?? '-'}
                            </td>
                          </tr>


                          <tr>
                            <td>First Deposit Bonus Raw</td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusPercentageRaw ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositEURRaw ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositUSDRaw ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.ftdBonusMinDepositGBPRaw ?? '-'}
                            </td>
                          </tr>

                          <tr>
                            <td>Re-Deposit Bonus Raw</td>
                            <td>
                              {this.state.marketingDetails?.redepositBonusPercentageRaw ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositEURRaw ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositUSDRaw ?? '-'}
                            </td>
                            <td>
                              {this.state.marketingDetails?.reDepositBonusMinDepositGBPRaw ?? '-'}
                            </td>
                          </tr>

                        </tbody>
                      </table>
                    </Row>

                    <Row
                      className={style.personalDetails}
                      id="t-client-personal-details-marketing-min-deposit"
                    >
                      <table className="table table-bordered text-center" style={{width: 'auto'}}>
                        <colgroup>
                          <col style={{width: '50%'}}/>
                          <col style={{width: '50%'}}/>
                        </colgroup>
                        <thead className="thead-light">
                          <tr>
                            <th>Settings for</th>
                            <th>Read only threshold</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>VIP Account</td>
                            <td>
                              {this.state.marketingDetails?.vipDepositMinimum ?? '-'}
                            </td>
                          </tr>
                          <tr>
                            <td>RAW Account</td>
                            <td>
                              {this.state.marketingDetails?.rawDepositMinimum ?? '-'}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Row>
                  </div>
                </Row>
              </Col>
            </Row>
          )
        })}
        <Row className={style.outerRow}>
          <Col xs={1} className={style.expandColumn}>
            <Row>
              <Col xs={12}>
                <FontAwesomeIcon
                  icon={this.state.showRiskSection ? 'minus-square-o' : 'plus-square-o'}
                  className={classnames(['clickable', style.expandableIcon])}
                  onClick={() => this.setState({showRiskSection: !this.state.showRiskSection})}
                />
              </Col>
            </Row>
            <Row style={{height: '100%'}} className={!this.state.showRiskSection ? style.hiddenSection : ''}>
              <Col xs={12} className={style.sectionTitle}>
                Risk
              </Col>
            </Row>
          </Col>
          <Col xs={10}
            className={classnames([style.sectionTitleForHidden, this.state.showRiskSection ? style.hiddenSection : ''])}
          >
            Risk
          </Col>
          <Col xs={11} className={!this.state.showRiskSection ? style.hiddenSection : ''}>
            <Row className={style.personalDetails}>
              <Col xs={6}>
                <dl className="dl-horizontal long-title">
                  <dt>Risk</dt>
                  <dd id="t-client-account-checks-risk">
                    {riskCategory && enums.riskCategories[riskCategory].label}
                  </dd>
                  <dt>Category</dt>
                  <dd id="t-client-account-checks-category">
                    {clientCategory && enums.clientCategories[clientCategory].label}
                  </dd>
                  <dt>GBG</dt>
                  <dd id="t-client-account-checks-gbg">
                    {clientGBG && enums.clientGBGTypes[clientGBG].label}
                  </dd>
                  {lastEiDVerificationResult &&
                    [
                      <dt key="last-eidv-result-title">Last eId Verification</dt>,
                      <dd id="t-client-account-checks-eidv" key="last-eidv-result-description">
                        {get(
                          find(enums.eIdVerificationReliability, {key: lastEiDVerificationResult.identityReliability}),
                          'label',
                          lastEiDVerificationResult.identityReliability)}
                      </dd>,
                    ]
                  }
                  <dt>Official Name</dt>
                  <dd id="t-client-account-checks-officialName">{officialName}</dd>
                  <dt>15 Days Policy</dt>
                  <dd id="t-client-account-checks-policy15Days">
                    {policy15Days && enums.yesNo[policy15Days].label}
                  </dd>
                </dl>
              </Col>
              <Col xs={6}>
                <dl className="dl-horizontal long-title">
                  <dt>Risk Acceptance</dt>
                  <dd title={this.getAgreementsReadableDate('riskAcceptanceDate')}>
                    {this.getYesNoValue('riskAcceptance')}
                  </dd>

                  <dt>EULA Acceptance</dt>
                  <dd title={this.getAgreementsReadableDate('eulaAcceptanceDate')}>
                    {this.getYesNoValue('eulaAcceptance')}
                  </dd>
                </dl>
                <dl className={`dl-horizontal ${titleClass}`}>
                  <dt>Privacy Acceptance</dt>
                  <dd title={this.getAgreementsReadableDate('privacyAcceptanceDate')}>
                    {this.getYesNoValue('privacyAcceptance')}
                  </dd>
                </dl>
                {get(personalDetails, 'regulationAcceptance') &&  <dl className={`dl-horizontal ${titleClass}`}>
                  <dt>Regulation Acceptance</dt>
                  <dd title={this.getAgreementsReadableDate('regulationAcceptance')}>
                    {enums.yesNo['yes'].label}
                  </dd>
                </dl>}
                {get(personalDetails, 'licenseAcknowledgement') &&  <dl className={`dl-horizontal ${titleClass}`}>
                  <dt>License acknowledgement</dt>
                  <dd title={this.getAgreementsReadableDate('licenseAcknowledgement')}>
                    {enums.yesNo['yes'].label}
                  </dd>
                </dl>}
                {get(personalDetails, 'acceptEuMigrationTerms') &&  <dl className={`dl-horizontal ${titleClass} space-wrap`}>
                  <dt>Migration Terms Acceptance</dt>
                  <dd title={this.getAgreementsReadableDate('acceptEuMigrationTerms')}>
                    {enums.yesNo['yes'].label}
                  </dd>
                </dl>}
                {get(personalDetails, 'solicitationAcceptance') && <dl className={`dl-horizontal ${titleClass}`}>
                  <dt title="Solicitation Acceptance">Solicitation Acceptance</dt>
                  <dd title={this.getAgreementsReadableDate('solicitationAcceptanceDate')}>
                    {enums.yesNo['yes'].label}
                  </dd>
                </dl>}
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className={style.outerRow}>
          <Col xs={1} className={style.expandColumn}>
            <Row>
              <Col xs={12}>
                <FontAwesomeIcon
                  icon={this.state.showEPSection ? 'minus-square-o' : 'plus-square-o'}
                  className={classnames(['clickable', style.expandableIcon])}
                  onClick={() => this.setState({showEPSection: !this.state.showEPSection})}
                />
              </Col>
            </Row>
            <Row style={{height: '100%'}} className={!this.state.showEPSection ? style.hiddenSection : ''}>
              <Col xs={12} className={style.sectionTitle}>
                Economic Profile
              </Col>
            </Row>
          </Col>
          <Col xs={10}
            className={classnames([style.sectionTitleForHidden, this.state.showEPSection ? style.hiddenSection : ''])}
          >
            Economic Profile
          </Col>
          <Col xs={11} className={!this.state.showEPSection ? style.hiddenSection : ''}>
            <Row className={style.personalDetails} id={'t-client-personal-details-economic-profile'}>
              <Col xs={12}>
                {
                  canUpdateClientEconomicProfile(this.props.viewer) &&
                  <div className="float-right">
                    <Button
                      id="t-update-economic-profile"
                      variant="dark"
                      size="sm"
                      title="Update Economic profile"
                      onClick={() => this.switchSidebar(sidebars.updateEconomicProfile.name)}
                    >
                      <FontAwesomeIcon icon="edit" />
                    </Button>
                  </div>
                }
              </Col>
              <Col xs={6} key={'economic-profile-1'}>
                <dl className="dl-horizontal long-title">
                  <dt>Transaction Purpose</dt>
                  <dd id={'t-client-personal-details-transactionPurpose'}>
                    {transactionPurpose && get(enums.profileTransactionPurpose[transactionPurpose], 'label', capitalize(transactionPurpose))}&nbsp;
                  </dd>
                </dl>
                {transactionPurpose === 'other' && <dl className="dl-horizontal long-title">
                  <dt>Please clarify</dt>
                  <dd id={'t-client-personal-details-transactionPurposeClarify'}>
                    {transactionPurposeClarify}&nbsp;
                  </dd>
                </dl>}
                <dl className="dl-horizontal long-title">
                  <dt>Approx Yearly Income</dt>
                  <dd id="t-client-personal-details-approxYearlyIncome">
                    {approxYearlyIncome && get(enums.appropTestAnnualIncome[approxYearlyIncome], 'label', capitalize(approxYearlyIncome))}&nbsp;
                  </dd>
                </dl>
                <dl className="dl-horizontal long-title">
                  <dt>Net Worth</dt>
                  <dd id={'t-client-personal-details-netWorth'}>
                    {approxNetWorth && get(netWorthEnums[approxNetWorth], 'label', capitalize(approxNetWorth))}&nbsp;
                  </dd>
                </dl>
                <dl className="dl-horizontal long-title">
                  <dt>Source of Funds</dt>
                  <dd id={'t-client-personal-details-sourceOfFunds'}>
                    {sourceOfFunds &&
                      get(enums.appropTestSourceOfFunds[sourceOfFunds], 'label', capitalize(sourceOfFunds))}&nbsp;
                  </dd>
                </dl>
                {sourceOfFundsClarify ? <dl className="dl-horizontal long-title">
                  <dt>Please clarify(SOF)</dt>
                  <dd id={'t-client-personal-details-sourceOfFundsClarify'}>
                    {sourceOfFundsClarify}&nbsp;
                  </dd>
                </dl> : null}
                <dl className="dl-horizontal long-title">
                  <dt>Job Title - Industry</dt>
                  <dd id="t-client-personal-details-job-title">
                    {
                      jobTitle && get(enums.appropTestEmployerCategory, `${jobTitle}.label`, capitalize(jobTitle))
                    }&nbsp;
                  </dd>
                </dl>
                <dl className="dl-horizontal long-title">
                  <dt>Nature of Transactions</dt>
                  <dd id="t-client-personal-details-natureOfTransactions" >
                    {natureOfTransactions &&
                      get(
                        enums.appropNatureOfTransactions[natureOfTransactions], 'label',
                        capitalize(natureOfTransactions))}&nbsp;
                  </dd>
                </dl>
                {natureOfTransactions === 'other' && <dl className="dl-horizontal long-title">
                  <dt>Please clarify</dt>
                  <dd id={'t-client-personal-details-natureOfTransactionsClarify'}>
                    {natureOfTransactionsClarify}&nbsp;
                  </dd>
                </dl>}
                <dl className="dl-horizontal long-title">
                  <dt>Estimated Deposit</dt>
                  <dd id={'t-client-personal-details-expectedDeposit'}>
                    {
                      approxExpectedDeposit &&
                      get(
                        enums.appropTestExpectedDeposit[approxExpectedDeposit], 'label',
                        capitalize(approxExpectedDeposit))
                    }&nbsp;
                  </dd>
                </dl>
              </Col>
              <Col xs={6} key={'economic-profile-2'}>
                <dl className="dl-horizontal long-title">
                  <dt>Origin of Funds</dt>
                  <dd id="t-client-personal-details-originOfFunds" >
                    {originOfFunds &&
                      get(enums.appropOriginOfFunds[originOfFunds], 'label', capitalize(originOfFunds))}&nbsp;
                  </dd>
                </dl>

                <dl className="dl-horizontal long-title">
                  <dt>Politically Exposed</dt>
                  <dd id="t-client-personal-details-politicallyExposed" >
                    {politicallyExposed &&
                      get(enums.appropTestPoliticallyExposed[politicallyExposed], 'label',
                        capitalize(politicallyExposed))}&nbsp;
                  </dd>
                </dl>
                {politicallyExposed && politicallyExposedReason && <dl className="dl-horizontal long-title">
                  <dt>Polit. Exp. Reason</dt>
                  <dd id="t-client-personal-details-politicallyExposedReason" >
                    {get(enums.appropTestPoliticallyExposedReason[politicallyExposedReason], 'label')}&nbsp;
                  </dd>
                </dl>}
                <dl className="dl-horizontal long-title">
                  <dt>US Citizen/Resident</dt>
                  <dd id="t-client-personal-details-usCitizen" >
                    {usCitizen && get(enums.appropTestIsUsReportable[usCitizen], 'label', capitalize(usCitizen))}&nbsp;
                  </dd>
                </dl>
                <dl className="dl-horizontal long-title">
                  <dt>Tax Jurisdiction</dt>
                  <dd id={'t-client-personal-details-taxJurisdictionCountry'}>
                    {taxJurisdictionCountry &&
                      get(enums.countries[taxJurisdictionCountry], 'label', capitalize(taxJurisdictionCountry))}&nbsp;
                  </dd>
                </dl>
                <dl className="dl-horizontal long-title">
                  <dt>TIN Reason</dt>
                  <dd id={'t-client-personal-details-tinReason'}>
                    {tinReason && get(enums.appropTestTinReason[tinReason], 'label', capitalize(tinReason))}&nbsp;
                  </dd>
                </dl>
                {tinReason === 'unableToObtain' && <dl className="dl-horizontal long-title">
                  <dt>Please clarify (TIN)</dt>
                  <dd id={'t-client-personal-details-tin'}>
                    {tinClarify}&nbsp;
                  </dd>
                </dl>}
                {tinReason === 'iHaveTin' && <dl className="dl-horizontal long-title">
                  <dt>TIN</dt>
                  <dd id={'t-client-personal-details-tin'}>
                    {tin}&nbsp;
                  </dd>
                </dl>}
              </Col>
            </Row>
          </Col>
        </Row>

        {this.renderAppropTestData()}
      </React.Fragment>
    )
  }

  render() {
    const {personalDetails, generalInfo: {convertedAt}} = this.props.client
    const isPartner = [
      enums.clientTypes.ib.key,
      enums.clientTypes.ibCorporate.key,
      enums.clientTypes.affiliate.key,
      enums.clientTypes.affiliateCorporate.key,
    ].includes(personalDetails.clientType)
    const viewer = this.props.viewer
    const sidebars = frontendEnums.clientTabs.details.sidebars
    const metadata = JSON.parse(this.props.client.generalInfo.metadata ?? '{}')

    const allButtons = [
      {permission: () => true, sidebar: 'overview_report', text: <><FontAwesomeIcon icon="file-text" /> Trading Overview report</>,
        show: true, isReport: true, reportName: 'tradingOverviewReport', column: 4},
      {permission: canSendCodeClient, sidebar: sidebars.securityCode.name, text:  <><FontAwesomeIcon icon="key" /> Security Code</>,
        inDropdown: false, show: true, column: 1},
      {permission: canResetClientPassword, sidebar: sidebars.resetPassword.name, text: <>
        <FontAwesomeIcon icon="lock" /> Reset Passwords
      </>,
      inDropdown: false, show: true, column: 1},
      {
        permission: canResetClientPassword,
        sidebar: sidebars.resetTotp.name,
        text: <>
          <FontAwesomeIcon icon="shield" /> Reset MFA
        </>,
        inDropdown: false,
        show: true,
        column: 1,
        disabled: !metadata.otpEnabled
      },
      {permission: canAssignClientToAffiliate, sidebar: sidebars.attachToAffiliate.name, text: <><FontAwesomeIcon icon="handshake-o" /> Attach to affiliate</>,
        inDropdown: false, show: true, column: 3},
      {permission: canChangeClientCompany, sidebar: sidebars.changeClientCompany.name, text: <><FontAwesomeIcon icon="repeat" /> Change Company</>,
        show: true, column: 3},
      {permission: canWriteClientAccountChecks, sidebar: sidebars.accountChecks.name, text: <>
        <FontAwesomeIcon icon="user" /> Account Checks
      </>,
      inDropdown: false, show: true, column: 1},
      {permission: canSendInvitation, sidebar: sidebars.sendInvitation.name,
        text:  <><FontAwesomeIcon icon="whatsapp" /> WhatsApp Invitation</>, show: false, column: 1},
      {permission: canGenerateTaxDocument, sidebar: sidebars.generateTaxDocument.name,
        text: <><FontAwesomeIcon icon="file-text" /> Generate tax document</>, show: true, column: 3},
      {permission: canWriteClientDetails, sidebar: sidebars.linkClient.name,
        text:  <><FontAwesomeIcon icon="link" /> Link Client</>, show: isPartner, column: 1},
      {permission: canWriteClientDetails, sidebar: sidebars.loginVerificationDetails.name, text:  <><FontAwesomeIcon icon="lock" /> Login Verification</>,
        inDropdown: false, show: get(personalDetails, 'blockedPhoneBirthdayReveal'), column: 1},
      {permission: this.hasAvailableTemplate, sidebar: sidebars.sendEmailTemplate.name,
        text:  <><FontAwesomeIcon icon="envelope" /> Send Email Template</>, show: this.hasAvailableTemplate(viewer, personalDetails), column: 1},
    ]
    const {id, firstName, lastName} = personalDetails
    const {gbgCheckResult} = personalDetails.accountChecks || {}
    const {showGbgResult, checkingGBG} = this.state
    const idVerificationButtons = canWriteClientAccountChecks(this.props.viewer) ? [
      <Button
        id={'t-client-personal-details-run-gbg'}
        key={'run-gbg'}
        variant="success"
        size="xs"
        disabled={!this.canAutoCheckGBG() || checkingGBG}
        onClick={() => {
          const disabled = !this.canAutoCheckGBG() || checkingGBG
          !disabled && this.renderIdVerificationCheckConfirmation('gbg')
        }}
        className="mb-2 mr-2"
      >
        <FontAwesomeIcon icon="globe" /> Run GBG Check
      </Button>,
      <Button
        key={'run-eidv'}
        variant="success"
        size="xs"
        disabled={!this.canRunEIDVerification() || this.state.runningEIDVerification}
        onClick={() => {
          const disabled = !this.canRunEIDVerification() || this.state.runningEIDVerification
          !disabled && this.renderIdVerificationCheckConfirmation('eidv')
        }}
        className="mb-2 mr-2"
      >
        <FontAwesomeIcon icon="id-card" /> Run eID Verification
      </Button>,
      gbgCheckResult &&
        <Button
          id={'t-client-personal-details-gbg-result'}
          key={'gbg-result'}
          variant="success"
          size="xs"
          onClick={() => {this.showGBGCheckResult()}}
          className="mb-2 mr-2"
        > <FontAwesomeIcon icon="globe" /> Last GBG Result </Button>,
    ] : []

    const getButtons = (column) => allButtons.filter((data) => (
      data.permission(this.props.viewer, personalDetails) &&
      (data.sidebar === sidebars.edit.name || isConverted(convertedAt)) &&
      !data.inDropdown &&
      data.show &&
      !data.isReport &&
      data.column === column
    )).map((data) => (
      <Button
        id={`t-client-personal-details-${sanitizeId(data.text)}`}
        disabled={data.disabled}
        key={data.sidebar}
        variant="success"
        size="xs"
        onClick={() => {this.switchSidebar(data.sidebar)}}
        className="mb-2 mr-2"
      >{data.text}</Button>
    ))

    const buttons = getButtons(1)
    const buttons2 = [getButtons(2), ...idVerificationButtons]
    const buttons3 = getButtons(3)
    const reportButtons = allButtons.filter((data) => (
      data.permission(this.props.viewer, personalDetails) &&
      !data.inDropdown &&
      data.show &&
      data.isReport &&
      data.column === 4
    )).map((data, i) => (
      <Button
        id={`t-client-personal-details-${sanitizeId(data.text)}`}
        key={data.sidebar}
        variant="success"
        size="xs"
        className="mb-2 mr-2"
        onClick={() => {window.open(`${config.getInstance().tableauReports.urls[data.reportName]}&Client_ID=${this.props.client.generalInfo.id}`, '_blank')}}
      >{data.text}</Button>
    ))

    const trackEvents = cloneDeep(this.props.client.generalInfo.trackEvents)
    const lastTrackEvent = trackEvents && head(trackEvents)
    const trackEventsReadable = map(trackEvents, (t) => {
      t.createdAt = readableDate(moment(t.createdAt), true)
      t.pParams = safeParseJSON(t.pParams)
      t.seminar = t.subCampId
      return t
    })


    let trackEvent = find(trackEvents, (e) => !isEmpty(e.pParams) && (e.attended || isNull(e.attended)))
    if (isEmpty(trackEvent)) {
      trackEvent = find(trackEvents, (e) => isEmpty(e.pParams) && (e.attended || isNull(e.attended)))
      if (isEmpty(trackEvent))
        trackEvent = lastTrackEvent
    }
    const mappedMt4Servers = map(
      pickBy(enums.mt4Servers, (s) => s.value !== 'DEMO'),
      (s) => ({
        ...s,
        label: s.value,
      })
    )

    return (
      <div>
        <Row>
          <Col xs={2} className={style.quickActionHeader}><strong>Quick Actions</strong></Col>
          <Col xs={10}>
            <Row>
              {buttons.length > 0 && <Col className={style.quickActionCol}>{buttons}</Col>}
              {buttons2.length > 0 && <Col className={style.quickActionCol}>{buttons2}</Col>}
              {buttons3.length > 0 && <Col className={style.quickActionCol}>{buttons3}</Col>}
              {reportButtons.length > 0 && <Col className={style.quickActionCol}>{reportButtons}</Col>}
            </Row>
          </Col>
        </Row>
        {isPartner && <Row>
          <Col xs={12}>
            <Tabs defaultActiveKey="details" id="details-tabs">
              <Tab eventKey="details" title="Details">
                {this.renderClientDetails()}
              </Tab>
              <Tab eventKey="partnerCustomization" title="Partner Settings">
                <Row>
                  {map(personalDetails?.partnerIds ?? [], (partnerId) => {
                    let searchKey = '', partnerLabel = ''
                    switch (personalDetails?.clientType) {
                      case enums.clientTypes.affiliate.key:
                      case enums.clientTypes.affiliateCorporate.key:
                        searchKey = 'affiliateId'
                        partnerLabel = 'Affiliate ID'
                        break
                      case enums.clientTypes.ib.key:
                      case enums.clientTypes.ibCorporate.key:
                        searchKey = 'ibId'
                        partnerLabel = 'IB ID'
                        break
                      default:
                        break
                    }
                    const customization = find(personalDetails?.partnerCustomizations, {[searchKey]: partnerId})
                    return (<Col xs={12} className="mb-5">
                      <Row>
                        <Col xs={12} className="mb-3">
                          <h5>{partnerLabel}: {partnerId}</h5>
                        </Col>
                        <Col xs={12}>
                          <Row>
                            <Col xs={4}>
                              <StylishSelect.Input
                                id={`partner-customization-default-classic-mt4-server-${searchKey}-${partnerId}`}
                                label="Default Classic MT4 Server (for clients)"
                                value={customization?.classicDefaultMT4Server}
                                onChange={(e) => {
                                  this.savePartnerCustomization(
                                    customization,
                                    searchKey,
                                    partnerId,
                                    'classicDefaultMT4Server',
                                    e.value
                                  )
                                }}
                                options={StylishSelect.enumToStylishOptions(mappedMt4Servers)}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>)
                  })}
                </Row>
              </Tab>
            </Tabs>
          </Col>
        </Row>}
        {!isPartner && this.renderClientDetails()}
        <JsonDataModal
          heading={`Client #${personalDetails.id} - Last GBG Check Result`}
          data={gbgCheckResult ? parseJSON(gbgCheckResult) : {}}
          onHide={() => this.setState({showGbgResult: false})}
          show={showGbgResult}
        />
        <JsonDataModal
          show={this.state.showTrackEventsModal}
          data={!isEmpty(trackEventsReadable) ? orderBy(trackEventsReadable, 'createdAt', 'asc') : {}}
          onHide={() => this.hideTrackEventsModal()}
          heading={`Track Events for "${getFullName(personalDetails)}"`}
          shouldExpandNode={() => true}
        />
        <GeneralModal
          show={this.state.showFollowersPopup}
          header={<span className={style.centeredText} style={{display: 'block'}}><span className={style.redText}>STOP</span> Allowing Copy Trading</span>}
          body={<span><p>Are you sure you want to stop allowing Copy Trading for {firstName} {lastName} - {id}?</p>
            <p>All of his  existing followers will be notified, their copied positions will be closed, and their following on the given account will be discontinued.</p>
          </span>}
          onConfirm={() => this.toggleAllowCopyTrading(false, true)}
          onCancel={() => this.setState({showFollowersPopup: false})}
        />
        {this.renderIdVerificationConfirmationModal()}
      </div>
    )
  }
}

export default compose(
  provideProps((state) => ({config: state.config}))
)(PersonalDetails)
