
export default function create(dbClient) {
  return {
    upsertUser(user) {
      const variables = {
        id: user.id,
        email: user.email,
        firstName: user.firstName,
        lastName: user.lastName,
        username: user.username,
        bio: user.bio,
        phone: user.phone,
        skype: user.skype,
        jobTitle: user.jobTitle,
        roles: user.roles ? user.roles.map((role) => role.id) : null,
        location: user.location ? user.location.id : null,
        languages: user.languages,
        isActive: user.isActive,
        companies: user.companies,
        salesAgentType: user.salesAgentType,
        associatedCampaigns: user.associatedCampaigns,
        associatedSubCampaigns: user.associatedSubCampaigns,
        affiliateManagerCampaigns: user.affiliateManagerCampaigns,
        affiliateAssociatedCampaigns: user.affiliateAssociatedCampaigns,
        managerId: user.manager ? user.manager.id : null,
        allowedIps: user.allowedIps ? user.allowedIps : null,
        department: user.department,
        selectedPools: user.selectedPools,
        telephonyProvider: user.telephonyProvider,
        startDate: user.startDate,
        endDate: user.endDate,
        isPool: user.isPool,
        countries: user.countries,
        ftdMin: user.ftdMin,
        ftdMax: user.ftdMax,
        departmentRoleType: user.departmentRoleType,
      }

      const query = `
        mutation (
          $id: Int
          $email: String
          $firstName: String
          $lastName: String
          $username: String
          $bio: String
          $phone: String
          $skype: String
          $jobTitle: String
          $location: Int
          $managerId: Int
          $languages: [Language]
          $isActive: Boolean
          $roles: [String]
          $companies:[Company]
          $salesAgentType: SalesAgentType
          $department: UserDepartmentType
          $selectedPools: [Int]
          $telephonyProvider: TelephonyProviderType
          $startDate: DateTime,
          $endDate: DateTime,
          $isPool: Boolean
          $ftdMin: Int,
          $ftdMax: Int,
          $departmentRoleType: departmentRoleType,
          ${user.allowedIps ? '$allowedIps: [String]' : ''}
          ${user.associatedCampaigns ? '$associatedCampaigns:[String]' : ''}
          ${user.associatedSubCampaigns ? '$associatedSubCampaigns:[String]' : ''}
          ${user.affiliateManagerCampaigns ? '$affiliateManagerCampaigns:[String]' : ''}
          ${user.affiliateAssociatedCampaigns ? '$affiliateAssociatedCampaigns:[String]' : ''}
          ${user.countries ? '$countries:[Country]' : ''}
        ) {
          upsertUser(
            id: $id
            email: $email
            firstName: $firstName
            lastName: $lastName
            username: $username
            bio: $bio
            phone: $phone
            skype: $skype
            jobTitle: $jobTitle
            roles: $roles
            locationId: $location
            managerId: $managerId
            languages: $languages
            isActive: $isActive
            companies: $companies
            salesAgentType: $salesAgentType
            department: $department
            selectedPools: $selectedPools
            telephonyProvider: $telephonyProvider
            startDate: $startDate
            endDate: $endDate
            isPool: $isPool
            ftdMin: $ftdMin
            ftdMax: $ftdMax
            departmentRoleType: $departmentRoleType
            ${user.allowedIps ? 'allowedIps: $allowedIps' : ''}
            ${user.associatedCampaigns ? 'associatedCampaigns: $associatedCampaigns' : ''}
            ${user.associatedSubCampaigns ? 'associatedSubCampaigns: $associatedSubCampaigns' : ''}
            ${user.affiliateManagerCampaigns ? 'affiliateManagerCampaigns: $affiliateManagerCampaigns' : ''}
            ${user.affiliateAssociatedCampaigns ? 'affiliateAssociatedCampaigns: $affiliateAssociatedCampaigns' : ''}
            ${user.countries ? 'countries: $countries' : ''}
          ) {
            id
          }
       }
      `

      return dbClient.query(query, variables)
    },
    toggleActive(user) {
      const variables = {
        id: user.id,
        isActive: !user.isActive,
      }

      const query = `
        mutation (
          $id: Int,
          $isActive: Boolean,
        ){
          upsertUser(
            id: $id
            isActive: $isActive
          ) {
            id
          }
       }
      `
      return dbClient.query(query, variables)
    },
    setDistributionWeight(userIds, weight, assigned, ftdMin, ftdMax) {
      const variables = {
        userIds,
        weight,
        assigned,
        ftdMin,
        ftdMax,
      }

      const query = `
        mutation (
          $userIds: [Int]!,
          ${variables.weight ? '$weight: Int' : ''}
          ${variables.assigned ? '$assigned: Int' : ''}
          ${variables.ftdMin ? '$ftdMin: Int' : ''}
          ${variables.ftdMax ? '$ftdMax: Int' : ''}
        ){
          setLeadDistributionWeight(
            userIds: $userIds
            ${variables.weight ? 'weight: $weight' : ''}
            ${variables.assigned ? 'assigned: $assigned' : ''}
            ${variables.ftdMin ? 'ftdMin: $ftdMin' : ''}
            ${variables.ftdMax ? 'ftdMax: $ftdMax' : ''}
          )
       }
      `
      return dbClient.query(query, variables)
    },

    updateCountryToAgentLanguageMappings(newMappings) {
      const variables = {
        mappings: newMappings,
      }

      const query = `
        mutation (
          $mappings: [UpdateCountryAgentLanguageMappingInput]!,
        ){
          updateAgentCountryToLanguageMapping(
            mappings: $mappings
          )
       }
      `
      return dbClient.query(query, variables)
    },

    reassignAgentClients(user, searchArgs, salesAgentId, status, clientIds = []) {
      let variables
      if (user) {
        variables = {
          currentSalesAgentId: user.id,
          agentId: salesAgentId,
        }
      } else if (searchArgs) {
        variables = {
          searchArgs: JSON.stringify(searchArgs),
          agentId: salesAgentId,
          status: status,
          clientIds: JSON.stringify(clientIds)
        }
      }

      const query = `
        mutation (
          ${user ? '$currentSalesAgentId: Int,' : ''},
          ${searchArgs ? '$searchArgs: String,' : ''},
          ${status ? '$status: ConversionStatus,' : ''},
          ${clientIds ? '$clientIds: String,' : ''},

          $agentId: Int
        ){
          reassignAgentClients(
            ${user ? 'currentSalesAgentId: $currentSalesAgentId' : ''},
            ${searchArgs ? 'searchArgs: $searchArgs' : ''},
            ${status ? 'status: $status' : ''},
            ${clientIds ? 'clientIds: $clientIds' : ''},
            agentId: $agentId,
          )
        }
        `
      return dbClient.query(query, variables)
    },

    associateCampaignByLocation(locationId, campaigns, isSubCampaign, removeAssociation) {
      const variables = {
        locationId,
        campaigns,
        isSubCampaign,
        removeAssociation,
      }

      const query = `
        mutation (
          $locationId: Int!,
          $campaigns: [String],
          $isSubCampaign: Boolean
          $removeAssociation: Boolean
        ){
           updateAgentCampaignsByLocation(
             locationId:$locationId,
             campaigns: $campaigns,
             isSubCampaign: $isSubCampaign,
             removeAssociation: $removeAssociation)
        }
        `
      return dbClient.query(query, variables)
    },

    watchGmail(userId) {
      const variables = {
        userId,
      }

      const query = `
        mutation (
          $userId: Int!,
        ){
          watchGmail(userId: $userId)
        }
        `
      return dbClient.query(query, variables)
    },

    stopWatchingGmail(userId) {
      const variables = {
        userId,
      }

      const query = `
        mutation (
          $userId: Int!,
        ){
          stopWatchingGmail(userId: $userId)
        }
        `
      return dbClient.query(query, variables)
    },

    changeOwnPassword(currentPassword, newPassword) {
      const variables = {
        currentPassword,
        newPassword,
      }

      const query = `
        mutation($currentPassword: String!, $newPassword: String!) {
          success: userChangeOwnPassword(currentPassword: $currentPassword, newPassword: $newPassword)
        }
      `
      return dbClient.query(query, variables)
    },

    resetUserPassword(userId) {
      const variables = {userId}

      const query = `
        mutation($userId: Int!) {
          success: resetUserPassword(userId: $userId)
        }
      `
      return dbClient.query(query, variables)
    },

    setDocumentDistributionWeight(userIds, weight) {
      const variables = {
        userIds,
        weight,
      }

      const query = `
        mutation (
          $userIds: [Int]!,
          $weight: Int!,
        ){
          setDocumentDistributionWeight(
            userIds: $userIds
            weight: $weight
          )
       }
      `
      return dbClient.query(query, variables)
    },

    upsertShift(data) {
      const variables = {
        id: data.id,
        userIds: data.userIds,
        from: data.from,
        to: data.to,
        toDate: data.toDate
      }
      const query = `
        mutation (
          $id: Int,
          $userIds: [Int],
          $from: UnsetOrDateTime,
          $to: UnsetOrDateTime,
          $toDate: String
        ) {
          upsertShift(
            id: $id,
            userIds: $userIds,
            from: $from,
            to: $to
            toDate: $toDate
          ) {
              id
          }
        }`

      return dbClient.query(query, variables)
    },

    deleteShift(shiftId) {
      const variables = {
        id: shiftId
      }
      const query = `
        mutation (
          $id: Int!
        ) {
          deleteShift(
            id: $id
          ) {
            id
          }
        }`

      return dbClient.query(query, variables)
    },

    fetchUser(userId) {
      const variables = {
        id: userId
      }
      const query = `
        query {
          user(id:${userId}) {
            id
            email
            firstName
            lastName
            location {
              id
            }
            phone
            skype
            avatar
            jobTitle
            roles {
              id
              name
              permissions {
                id
              }
            }
            username
            bio
            associatedCampaigns
            associatedSubCampaigns
            affiliateManagerCampaigns
            affiliateAssociatedCampaigns
            allowedIps
            department
            departmentRoleType
            telephonyProvider
            startDate
            endDate
            manager {
              id
            }
            languages
            companies
            isActive
            salesAgentType
            selectedPools
            isPool
            countries
            ftdMin
            ftdMax
          }
        }
      `

      return dbClient.query(query, variables)
    },
  }
}
