import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import {debounce, filter, map, sortBy, keys, isNumber} from 'lodash'
import {countries, languagesWithVariation} from '@bdswiss/common-enums'
import {canModifyLeadDistributionSettings} from '@bdswiss/common-permissions'
import {Row, Col, Button, ButtonGroup, Card, Table, Container, Form, InputGroup} from 'react-bootstrap'
import style from './users.module.scss'
import PureComponent from '../PureComponent'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import {locationsProvider, countryToAgentLanguageProvider, leadDistributionAgentsProvider} from './providers'
import {compose, provideProps, mountDataProviders, uiMount} from '../decorators'

class LeadDistributionSettingsEditor extends PureComponent {

  static contextTypes = {
    leadDistributionAgentsProvider: PropTypes.object.isRequired,
    countryToAgentLanguageProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
  }

  componentWillMount() {
    this.toggleAgentSelection = debounce(this.toggleAgentSelection, 100)
    const renderPage = canModifyLeadDistributionSettings(this.props.viewer)
    this.setState({
      selectedAgents: [],
      weight: '',
      assigned: '',
      ftdMin: '',
      ftdMax: '',
      renderPage,
      modifiedCountryAgentMapping: {},
      editingCountryAgentMapping: false,
    })
    if (!renderPage) {
      this.context.router.push('/users')
    }
  }

  filterByName(filterValue) {
    const {agents, filteredAgents} = this.props
    let newFilteredAgents
    if (!filterValue) {
      newFilteredAgents = filter(agents, (a) => a.roles.map((a) => a.id).includes('sales_agent') && !a.isPool)
    } else {
      const lowercaseFilter = filterValue.trim().toLowerCase()
      newFilteredAgents = filter(filteredAgents, (a) => {
        const agentFullname = `${a.firstName.toLowerCase()} ${a.lastName.toLowerCase()}`
        return agentFullname.includes(lowercaseFilter)  && !a.isPool
      })
    }

    this.props.uiDispatch(
      'Filtering by name',
      (state) => ({...state, nameFilter: filterValue, filteredAgents: newFilteredAgents}))
  }

  filterCountryToAgentMapping(filterValue) {
    const {countryToAgentLanguageMappings} = this.props
    let newFilteredMappings
    if (!filterValue) {
      newFilteredMappings = countryToAgentLanguageMappings
    } else {
      const lowercaseFilter = filterValue.trim().toLowerCase()
      newFilteredMappings =
        filter(countryToAgentLanguageMappings,
          (c) => countries[c.country].label.toLowerCase().includes(lowercaseFilter))
    }

    this.props.uiDispatch(
      'Filtering Country Mappings',
      (state) => ({
        ...state,
        countryToAgentMappingFilter: filterValue,
        filteredCountryToAgentMappings: newFilteredMappings,
      })
    )
  }

  filterByLocation(locationId) {
    const {agents} = this.props
    let filteredAgents = filter(agents, (a) => a.roles.map((a) => a.id).includes('sales_agent') && !a.isPool)
    if (locationId) {
      filteredAgents = filter(filteredAgents, (a) => a.locationId === locationId)
    }

    this.props.uiDispatch(
      'Filtering by location',
      (state) => ({...state, locationFilter: locationId, filteredAgents}))
  }


  handleWeightChanged(newWeight) {
    if (isNaN(newWeight)) {
      this.setState({weight: newWeight, hasError: true})
      return
    }

    if (newWeight < 0) {
      this.setState({weight: 0, hasError: false})
    } else {
      this.setState({weight: newWeight, hasError: false})
    }
  }

  handleAssignedChanged(newAssigned) {
    if (isNaN(newAssigned)) {
      this.setState({assigned: newAssigned, hasError: true})
      return
    }

    if (newAssigned < 0) {
      this.setState({assigned: 0, hasError: false})
    } else {
      this.setState({assigned: newAssigned, hasError: false})
    }
  }

  handleFTDChanged = e => {
    const {target: {value, name}} = e
    if (e.value && isNaN(e.value)) {
      this.setState({[name]: value, hasError: true})
      return
    }
    this.setState({[name]: value, hasError: false})
  }

  handleCountryMappingChanged(country, newLanguage) {
    const {modifiedCountryAgentMapping} = this.state
    this.setState({
      modifiedCountryAgentMapping: {...modifiedCountryAgentMapping, [country]: newLanguage},
    })
  }

  renderFilters() {
    const {locationFilter, nameFilter} = this.props
    const locationOptions = [
      {
        label: 'All Locations',
        value: undefined,
      }].concat(Object.values(this.props.locations).map((l) => ({label: l.name, value: l.id})))
    return (
      <Row>
        <Col xs={6}>
          <StylishSelect.Input
            id="t-list-users-filter-location"
            value={locationFilter}
            onChange={(e) => this.filterByLocation(Number(e.value))}
            placeholderText="All Locations"
            options={locationOptions}
            highlightIfActive
          />
        </Col>
        <Col xs={6}>
          <InputGroup>
            <Form.Control
              id="t-list-users-search"
              type="text"
              placeholder="All Users"
              value={nameFilter || ''}
              onChange={(e) => this.filterByName(e.target.value)}
            />
            <InputGroup.Append>
              <Button
                title="Clear"
                onClick={() => this.filterByName()}
                variant={nameFilter ? 'success' : 'outline-secondary'}
              >
                <FontAwesomeIcon icon={nameFilter ? 'remove' : 'search'} />
              </Button>
            </InputGroup.Append>
          </InputGroup>
        </Col>
      </Row>
    )
  }


  selectAllAgents(inverse) {
    const {filteredAgents} = this.props
    return inverse ?
      this.setState({selectedAgents: []}) :
      this.setState({selectedAgents: map(filteredAgents, (a) => a.id)})
  }

  setDistributionWeight() {
    const {selectedAgents, weight, assigned, ftdMin, ftdMax} = this.state
    this.props.actions.user.setDistributionWeight(selectedAgents, weight, assigned, ftdMin, ftdMax)
      .then(() => {
        this.context.leadDistributionAgentsProvider.fetch(true)
          .then(() => {
            this.filterByLocation()
            this.filterByName()
            this.setState({weight: '', assigned: '', selectedAgents: [], ftdMin: '', ftdMax: ''})
          })
      })
      .catch((e) => {
        this.context.showNotification({
          message: e.message,
          level: 'error',
          autoDismiss: 0,
        })
      })
  }

  saveCountryToAgentMappings() {
    const {modifiedCountryAgentMapping} = this.state
    const mappings = map(keys(modifiedCountryAgentMapping),
      (k) => ({country: k, language: modifiedCountryAgentMapping[k]}))
    this.setState({editingCountryAgentMapping: false})
    this.props.actions.user.updateCountryToAgentLanguageMappings(mappings)
      .then(() => {
        this.context.countryToAgentLanguageProvider.fetch(true)
          .then(() => {
            this.filterCountryToAgentMapping()
            this.setState({modifiedCountryAgentMapping: {}, editingCountryAgentMapping: false})
          })
      })
      .catch((e) => {
        this.setState({editingCountryAgentMapping: true})
        this.context.showNotification({
          message: e.message,
          level: 'error',
          autoDismiss: 0,
        })
      })
  }

  toggleAgentSelection(id) {
    const {selectedAgents} = this.state
    if (selectedAgents.includes(id)) {
      selectedAgents.splice(selectedAgents.indexOf(id), 1)
    } else {
      selectedAgents.push(id)
    }
    this.setState({selectedAgents: [...selectedAgents]})
  }

  render() {
    const {
      selectedAgents,
      weight,
      assigned,
      ftdMin,
      ftdMax,
      hasError,
      renderPage,
      editingCountryAgentMapping,
      modifiedCountryAgentMapping,
    } = this.state
    const {
      filteredAgents,
      filteredCountryToAgentMappings,
      countryToAgentMappingFilter,
    } = this.props
    const sortedCountryAgentMappings = sortBy(filteredCountryToAgentMappings, (c) => countries[c.country].label)
    return renderPage ? (
      <Container>
        <Row>
          <Col xs={6}>
            <h3>Agent Max Leads</h3>
            <Row>
              <Col xs={12}>
                {this.renderFilters()}
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Row>
                  <Col xs={3}>
                    <Form.Group>
                      <Form.Label>Currently assigned</Form.Label>
                      <Form.Control
                        id={'t-distribution-assigned-input'}
                        type="text"
                        disabled={selectedAgents && selectedAgents.length < 1}
                        value={assigned}
                        placeholder="Assigned"
                        onChange={(e) => this.handleAssignedChanged(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group>
                      <Form.Label>Max assigned</Form.Label>
                      <Form.Control
                        id={'t-distribution-weight-input'}
                        type="text"
                        max={100} min={0}
                        disabled={selectedAgents && selectedAgents.length < 1}
                        value={weight}
                        placeholder="Max"
                        onChange={(e) => this.handleWeightChanged(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group>
                      <Form.Label>Min FTD</Form.Label>
                      <Form.Control
                        id={'t-distribution-assigned-ftd-min'}
                        type="text"
                        name="ftdMin"
                        disabled={selectedAgents && selectedAgents.length < 1}
                        value={ftdMin}
                        placeholder="Min FTD"
                        onChange={this.handleFTDChanged}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Form.Group>
                      <Form.Label>Max FTD</Form.Label>
                      <Form.Control
                        id={'t-distribution-weight-ftd-max'}
                        type="text"
                        name="ftdMax"
                        disabled={selectedAgents && selectedAgents.length < 1}
                        value={ftdMax}
                        placeholder="Max FTD"
                        onChange={this.handleFTDChanged}
                      />
                    </Form.Group>
                  </Col>
                  <Col xs={2}>
                    <Button
                      id={'t-set-distribution-weight-btn'}
                      variant="success"
                      className={classNames(['float-right', style.leadDistributionEditorSetButton])}
                      disabled={selectedAgents && selectedAgents.length < 1}
                      onClick={() => this.setDistributionWeight()}
                    >
                      Set
                    </Button>
                  </Col>
                </Row>
                <Row >
                  <Col xs={12} className={`${selectedAgents.length < 1 ? '' : 'd-none'}`}>
                    <small> Select at least one Agent to update distribution settings. </small>
                  </Col>
                </Row>
                <Row className={`${hasError ? '' : 'd-none'}`}>
                  <Col xs={{offset: 2}}>
                    <div className="text-danger"> Invalid input should be a numeric value {'>='} 0 </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            &nbsp;
            <Row>
              <Col xs={12}>
                <ButtonGroup>
                  <Button
                    variant="outline-secondary"
                    id={'t-select-all-agents-btn'}
                    onClick={() => this.selectAllAgents(false)}
                    size="sm"
                  >
                    Select All
                  </Button>
                  <Button
                    id={'t-deselect-all-agents-btn'}
                    disabled={selectedAgents.length < 1}
                    onClick={() => this.selectAllAgents(true)}
                    variant="outline-secondary"
                    size="sm"
                  >
                    Deselect All
                  </Button>
                </ButtonGroup>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
                    {
                      map(filteredAgents, (s, i) => {
                        const {selectedAgents} = this.state
                        const selectedClass = selectedAgents && selectedAgents.includes(s.id) ?
                          style.leadDistributionEditorListSelected : ''
                        return (
                          <Row
                            key={i}
                            id={`t-dw-agent-${s.id}`}
                            className={classNames([style.leadDistributionEditorListItem, selectedClass])}
                            onClick={() => this.toggleAgentSelection(s.id)}
                          >
                            <Col xs={12}>
                              <Row>
                                <Col xs={1}>
                                  <input
                                    id={`t-dw-agent-${s.id}-checkinput`}
                                    type="checkbox"
                                    checked={selectedAgents.includes(s.id)}
                                    onClick={(e) => this.toggleAgentSelection(s.id)}
                                  />
                                </Col>
                                <Col xs={11}>
                                  <p className={style.leadDistributionEditorListTitle}> {s.firstName} {s.lastName} </p>
                                </Col>
                              </Row>
                              <Row>
                                <Col xs={6}>
                                  <span className={style.leadDistributionEditorListSubtitle}>
                                    {s.lastLeadAssignedTimestamp ?
                                      `Last lead assigned ${moment(s.lastLeadAssignedTimestamp).fromNow()}` :
                                      'Last lead assignment time unknown'}
                                  </span>
                                </Col>
                                <Col xs={3}>
                                  <span className={style.leadDistributionEditorListSubtitle}>
                                    Assigned {s.leadDistributionAssigned || 0} / {s.leadDistributionWeight}
                                  </span>
                                </Col>
                                <Col xs={3}>
                                  {(isNumber(s.ftdMin) || isNumber(s.ftdMax)) &&
                                  <span className={style.leadDistributionEditorListSubtitle}>
                                    FTD {s.ftdMin} - {s.ftdMax}
                                  </span>
                                  }
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        )
                      })
                    }
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col xs={{span: 5, offset: 1}}>
            <Row>
              <Col xs={8}>
                <h3> Country To Agent Language Mapping</h3>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                <Card>
                  <Card.Header>
                    <Row>
                      <Col xs={6}>
                        <InputGroup>
                          <Form.Control
                            id="t-county-agent-language-search"
                            type="text"
                            placeholder="All Countries"
                            value={countryToAgentMappingFilter || ''}
                            onChange={(e) => this.filterCountryToAgentMapping(e.target.value)}
                          />
                          <InputGroup.Append>
                            <Button
                              title="Clear"
                              onClick={() => this.filterCountryToAgentMapping()}
                              variant={countryToAgentMappingFilter ? 'success' : 'outline-secondary'}
                            >
                              <FontAwesomeIcon icon={countryToAgentMappingFilter ? 'remove' : 'search'} />
                            </Button>
                          </InputGroup.Append>
                        </InputGroup>
                      </Col>
                      <Col xs={6}>
                        <div className="float-right">
                          <Button
                            id={'t-country-agent-language-edit-cancel-btn'}
                            onClick={() => {
                              if (editingCountryAgentMapping) {
                                this.setState({modifiedCountryAgentMapping: {}})
                              }
                              this.setState({editingCountryAgentMapping: !editingCountryAgentMapping})
                            }}
                            variant="outline-secondary"
                            className="mr-3"
                          >
                            {editingCountryAgentMapping ? 'Cancel' : 'Edit'}
                          </Button>
                          { editingCountryAgentMapping &&
                            <Button variant="success"
                              id={'t-country-agent-language-save-btn'}
                              onClick={() => this.saveCountryToAgentMappings()}
                              disabled={keys(modifiedCountryAgentMapping).length < 1}
                            >
                              Save
                            </Button>
                          }
                        </div>
                      </Col>
                    </Row>
                  </Card.Header>
                  <Card.Body style={{maxHeight: '75vh', overflowY: 'scroll'}}>
                    <Table striped hover>
                      <thead>
                        <tr>
                          <th> Country </th>
                          <th> Agent Language </th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          map(sortedCountryAgentMappings, (m, i) => {
                            const {firstName, lastName} = m.lastUpdatedBy
                            const language = modifiedCountryAgentMapping[m.country] || m.language
                            const modifiedRowClass = modifiedCountryAgentMapping[m.country] ? style.modifiedRow : ''
                            return (
                              <tr id={`t-country-agent-row-${m.country}`} className={modifiedRowClass} key={i}>
                                <td>
                                  <div> {countries[m.country].label} </div>
                                  <div>
                                    <small>
                                      Last updated by {`${firstName} ${lastName}, `}
                                      {moment(m.updatedAt).fromNow()}
                                    </small>
                                  </div>
                                </td>
                                <td> {
                                  editingCountryAgentMapping ?
                                    <StylishSelect
                                      id={`t-country-agent-row-${m.country}-language-input`}
                                      value={language}
                                      onChange={(e) => this.handleCountryMappingChanged(m.country, e.value)}
                                      options={StylishSelect.enumToStylishOptions(languagesWithVariation)}
                                    /> :
                                    languagesWithVariation[m.language].label}
                                </td>
                              </tr>
                            )
                          })
                        }
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>) : null
  }
}

export default compose(
  uiMount(() => ['leadDistributionEditorUi']),
  provideProps((state, uiState) => {
    const {agents, locations, viewer, countryToAgentLanguageMappings} = state
    const {nameFilter, locationFilter, countryToAgentMappingFilter} = uiState
    let {filteredAgents, filteredCountryToAgentMappings} = uiState
    if (!filteredAgents) {
      filteredAgents = agents && agents.filter(
        (a) => a.roles.map((a) => a.id).includes('sales_agent') && !a.isPool
      )
    }

    if (!filteredCountryToAgentMappings) {
      filteredCountryToAgentMappings = sortBy(countryToAgentLanguageMappings, 'country')
    }

    return ({
      agents,
      locations,
      filteredAgents,
      nameFilter,
      locationFilter,
      viewer,
      countryToAgentLanguageMappings,
      countryToAgentMappingFilter,
      filteredCountryToAgentMappings,
    })
  }),
  mountDataProviders({
    leadDistributionAgentsProvider,
    locationsProvider,
    countryToAgentLanguageProvider,
  }),
)(LeadDistributionSettingsEditor)
