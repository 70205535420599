import {isEmpty} from 'lodash'
import {getFetchInterval, getPageSize, getOffset} from '../useful'

export const paymentOptionsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {paymentOptionsSort, paymentOptionsPage, paymentOptionsPaginate, paymentOptionsSearch, methodFilter} = props

    const params = [
      paymentOptionsPaginate ? `limit: ${getPageSize()}` : '',
      paymentOptionsPage ? `offset: ${getOffset(paymentOptionsPage)}` : '',
      paymentOptionsSort ? `orderBy: ${paymentOptionsSort.orderBy}` : '',
      paymentOptionsSort ? `orderDirection: ${paymentOptionsSort.orderDirection}` : '',
      paymentOptionsSearch ? `search: "${props.paymentOptionsSearch}"` : '',
      methodFilter ? `method: ${methodFilter}` : '',
    ].join('\n').trim()

    const countParams = paymentOptionsSearch !== undefined ? `search: "${props.paymentOptionsSearch}"` : ''
    const countQuery =  paymentOptionsSearch === undefined ? 'paymentOptionsCount' : `paymentOptionsCount(${countParams})`

    const query = `{
    paymentOptions ${isEmpty(params) ? '' : `(${params})`} {
        id
        name
        status
        withKYCStatus
        updatedAt
        provider
        paymentKey
        logoUrl
        localizationKey
        companies
        iOSMinBuildVersion
        androidMinBuildVersion
        flowType
        confluenceLink
        notes
        method
        ccType
        ccPrimary
        ccVolumeLimit
        bankDetails {
          clientAccountCurrency
          recipient
          iban
          creditingAccount
          swift
          swiftBic
          bank
          correspondentBank
          address
          city
          country
          currency
          reference
        }
      }
      ${countQuery}
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Payment Options loaded',
    (state, res) => ({...state, paymentOptions: res.paymentOptions, paymentOptionsCount: res.paymentOptionsCount}),
    [res]
  ),

}

export const countryPaymentOptionsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    const {currentCountrySearch} = props
    const args = [
      currentCountrySearch ?
        `(countries: [${currentCountrySearch.key}, _default, _defaultNonEU])` : '',
    ].join('\n').trim()

    const query = `{
      countryPaymentOptions ${args}{
        id
        country
        enabled
        paymentOption{
          id
          name
          provider
          logoUrl
          confluenceLink
          companies
        }
        rank
        popular
        createdAt
        updatedAt
        lastUpdatedBy {
          firstName,
          lastName
        }
      }
  }`

    return query
  },
  onData: (res, dispatch, props) => dispatch(
    'Country Payment Options loaded',
    (state, res) => ({...state, countryPaymentOptions: res.countryPaymentOptions}),
    [res]
  ),
}

export const countryPaymentOptionProvider = {

  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (props.id == null) {
      return null
    }

    const query = `{
      countryPaymentOption(id: ${props.id}) {
        id
        country
        enabled
        paymentOption {
          id
          name
          paymentKey
        }
        rank
        popular
        lastUpdatedBy {
          id
        }
        createdAt
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'Country Payment Option loaded',
    (state, res) => ({...state, countryPaymentOption: res.countryPaymentOption}),
    [res]
  ),
}

export const ccPaymentOptionsProvider = {

  fetchInterval: getFetchInterval,

  getQuery: () => {
    const query = `{
      pspReport {
        id
        vendor
        totalEur
        ccVolumeLimit
        ccType
        ccCurrent
        ccPrimary
        failedDeposits
        companies
        iOSMinBuildVersion
        androidMinBuildVersion
        status
      }
    }`

    return query
  },

  onData: (res, dispatch, props) => dispatch(
    'CC Payment Options loaded',
    (state, res) => ({...state, ccPaymentOptions: res.pspReport}),
    [res]
  ),

}
