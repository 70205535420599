/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {join, map, isNull, isEmpty} from 'lodash'
import {LinkContainer} from 'react-router-bootstrap'
import {orderDirections, countries} from '@bdswiss/common-enums'
import {Row, Col, Table, Button, Card, Pagination, Container} from 'react-bootstrap'
import {compose, provideProps, mountDataProviders, uiMount, predispatch} from '../decorators'
import {events} from '../enums'
import style from './bookallocations.module.scss'
import PureComponent from '../PureComponent'
import {bookAllocationsProvider} from './providers'
import {readableDate, getPageCount, getPageRange} from '../useful'

const defaultSort = {orderBy: 'id', orderDirection: orderDirections.descending.key}

class BookAllocations extends PureComponent {

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.bookAllocationsProvider.fetch()
  }

  static contextTypes = {
    router: PropTypes.object.isRequired,
    bookAllocationsProvider: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  linkToBookAllocation(bookAllocation, content) {
    return (
      <LinkContainer to={`/book-allocation/${bookAllocation.id}`}>
        <a>{content}</a>
      </LinkContainer>
    )
  }

  renderBookAllocation(bookAllocation) {
    const {id, ibs, campaigns, bookCategory, countries: selectedCountries, updatedAt,
      createdAt} = bookAllocation
    const created = moment(createdAt)
    const updated = moment(updatedAt)
    const matchCountries = map(selectedCountries, (c) => countries[c].label)

    return (
      <tr className="t-bookAllocation" key={id}>
        <td>
          {this.linkToBookAllocation(bookAllocation, id)}
        </td>
        <td>
          {isNull(ibs) || isEmpty(ibs) ? this.linkToBookAllocation(bookAllocation, 'No IBs selected') :
            this.linkToBookAllocation(bookAllocation, join(ibs, ', '))}
        </td>
        <td>
          {this.linkToBookAllocation(bookAllocation, bookCategory)}
        </td>
        <td>
          {isNull(selectedCountries) || isEmpty(selectedCountries)
            ? this.linkToBookAllocation(bookAllocation, 'No countries selected')
            : this.linkToBookAllocation(bookAllocation, join(matchCountries, ', '))
          }
        </td>
        <td>
          {isNull(campaigns) || isEmpty(campaigns) ?
            this.linkToBookAllocation(bookAllocation, 'No campaigns selected') :
            this.linkToBookAllocation(bookAllocation, join(campaigns, ', '))}
        </td>
        <td>
          {this.linkToBookAllocation(bookAllocation, readableDate(created))}
        </td>
        <td>
          {this.linkToBookAllocation(bookAllocation, readableDate(updated))}
        </td>
      </tr>
    )
  }

  renderFilters() {
    return (
      <Row>
        <Col xs={12}>
          <Button
            id="t-bookAllocations-new-bookAllocation-button"
            className="float-right"
            variant="success"
            onClick={() => this.context.router.push('/book-allocation/create')}
          >
            New Book Allocation
          </Button>
        </Col>
      </Row>
    )
  }

  render() {
    const {bookAllocations, bookAllocationsCount, bookAllocationsPage} = this.props
    return (
      <Container>
        <div className={style.toolbar}>
          {this.renderFilters()}
        </div>
        <Row>
          <Col xs={12}>
            <Card>
              <Card.Body>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th className={style.tableIbs}>IBs</th>
                      <th>Book Category</th>
                      <th className={style.tableIbs}>Countries</th>
                      <th>Campaigns</th>
                      <th>Created</th>
                      <th>Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    {bookAllocations.map((bookAllocation) => this.renderBookAllocation(bookAllocation))}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>
            <Row className="mt-3">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={(e, selectedEvent) => this.props.uiDispatch(
                    'Show page',
                    (state) => ({...state, bookAllocationsPage: selectedEvent.eventKey})
                  )}
                >
                  {getPageRange(bookAllocationsPage, getPageCount(bookAllocationsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, bookAllocationsPage: bookAllocationsPage - 1})
                        )}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={(e, selectedEvent) => this.props.uiDispatch(
                          'Show page',
                          (state) => ({...state, bookAllocationsPage: bookAllocationsPage + 1})
                        )}
                      />
                    }

                    return <Pagination.Item
                      active={page === bookAllocationsPage}
                      key={page}
                      onClick={(e, selectedEvent) => this.props.uiDispatch(
                        'Show page',
                        (state) => ({...state, bookAllocationsPage: page})
                      )}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    )
  }
}

export default compose(
  uiMount((state) => ['ui', 'bookAllocations']),

  predispatch((props) => {
    props.uiDispatch(
      'Initialize ui/bookAllocations',
      (state) => {
        let bookAllocationsSort
        if (!bookAllocationsSort || bookAllocationsSort.orderBy == null
            || bookAllocationsSort.orderDirection == null || !(bookAllocationsSort.orderDirection in orderDirections)) {
          bookAllocationsSort = defaultSort
        }

        return ({...state, bookAllocationsSort})
      }
    )
  }),

  provideProps((state, uiState) => {
    const {bookAllocations, bookAllocationsCount} = state
    const {bookAllocationsPage, bookAllocationsSort} = uiState

    return ({
      bookAllocations,
      bookAllocationsCount,
      bookAllocationsPage: bookAllocationsPage || 1,
      bookAllocationsSort,
    })
  }),

  mountDataProviders({bookAllocationsProvider}),
)(BookAllocations)
