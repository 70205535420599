import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import {isEmpty, map, has, get, range, isNil} from 'lodash'
import {Col, Form, Row, Card, Button, ButtonToolbar, Table} from 'react-bootstrap'
import {countries, clientTypes, companies, competitionRules, yesNo} from '@bdswiss/common-enums'
import {setIn} from '../stateUtils'
import {putFile} from '../utils/net'
import style from './competitions.module.scss'
import {provideProps} from '../decorators'
import PureComponent from '../PureComponent'
import DateTime from '../components/DateTime'
import {safeParseJSON} from '../common/utils'
import StylishSelect from '../components/StylishSelect'
import FontAwesomeIcon from '../components/FontAwesomeIcon'

class CompetitionForm extends PureComponent {
  static contextTypes = {
    router: PropTypes.object.isRequired,
    competitionProvider: PropTypes.object,
    logError: PropTypes.func.isRequired,
    config: PropTypes.object.isRequired,
    showNotification: PropTypes.func.isRequired,
  }

  componentWillMount() {
    const {defaultCompetitionSymbols} = this.context.config
    const winner = safeParseJSON(this.props.data?.competitionWinner)
    const data = this.props.data ? {
      ...this.props.data,
      startDate: moment(this.props.data.startDate),
      endDate: moment(this.props.data.endDate),
      prizes: safeParseJSON(this.props.data.prizes),
      winnerLogin: winner.winnerLogin,
      winnerRoi: winner.winnerRoi,
      winnerNickname: winner.winnerNickname,
    } : {
      prizes: {},
      rules: {},
      rulesCount: 1,
      limitedSymbols: defaultCompetitionSymbols,
    }

    const rules = safeParseJSON(get(this.props, 'data.rules', []))
    if (!isEmpty(rules)) {
      data.rulesCount = rules.length
      const formRules = {}
      for (let i = 0; i < rules.length; i++) {
        formRules[`rule${i}`] = rules[i].rule
        for (const value in rules[i].values) {
          data[`rule${i}-${value}`] = rules[i].values[value]
        }
      }
      data.rules = formRules
    }

    const state = {
      form: {
        ...data,
      },
      formError: {prizes: {}, rules: {}},
      formState: {},
      isUserNicknameExist: !!data.winnerNickname,
    }
    this.setState(state)
  }

  formValueChanged(name, value, errorValue) {
    let {state} = this
    state = setIn(state, ['form', ...name], value)
    state = setIn(state, ['formError', ...(errorValue || name)], null)
    this.setState(state)
  }

  addMoreRules() {
    const {form: {rulesCount}, form} = this.state
    if (rulesCount + 1 <= 3) {
      this.setState({
        form: {
          ...form,
          rulesCount: rulesCount + 1,
        },
      })
    }
  }

  removeRule() {
    const {form: {rulesCount}, form} = this.state
    if (rulesCount > 1) {
      this.setState({
        form: {
          ...form,
          rulesCount: rulesCount - 1,
        },
      })
    }
  }

  setFormErrors(errors) {
    this.setState({formError: errors})
  }

  validateForm() {
    const errors = {}
    const {form} = this.state

    if (!form.name) {
      errors.name = true
    }

    if (!form.termsLink) {
      errors.termsLink = true
    }

    if (!form.startDate) {
      errors.startDate = true
    }

    if (!form.endDate) {
      errors.endDate = true
    }

    if (!(get(form, 'prizes.1'))) {
      errors.prize1 = true
    }

    if (!(get(form, 'prizes.2'))) {
      errors.prize2 = true
    }

    if (!(get(form, 'prizes.3'))) {
      errors.prize3 = true
    }

    if (!form.icon) {
      errors.icon = true
    }
    if (isNil(form.acceptPreviousWinners)) {
      errors.acceptPreviousWinners = true
    }

    if (form.winnerLogin || form.winnerRoi) {
      if (!form.winnerLogin) {
        errors.winnerLogin = true
      }

      if (!form.winnerRoi) {
        errors.winnerRoi = true
      }
    }

    for (let i = 0; i < form.rulesCount; i++) {
      const formRule = get(form.rules, `rule${i}`)
      if (!formRule) {
        errors[`rule${i}`] = true
      } else {
        const rule = competitionRules[formRule]
        for (const value of rule.values) {
          if (!form[`rule${i}-${value}`]) errors[`rule${i}-${value}`] = true
        }
      }
    }

    this.setFormErrors(errors)
    return isEmpty(errors)
  }

  async getPath(file) {
    const {form} = this.state
    if (typeof file === 'string') {
      return Promise.resolve(file)
    }
    const res = await this.props.actions.competition.signUploadUrl(form.name)
    const {plainUrl, signedUrl} = res.signCompetitionFileUrl
    return putFile(file[0], signedUrl).then(() => plainUrl)
  }

  formAction = () => {
    const {form} = this.state
    const {winnerLogin, winnerRoi, winnerNickname, ...formWithoutWinner} = form
    const competitionWinner = winnerLogin && winnerRoi && winnerNickname
      ? {
        winnerLogin: winnerLogin.trim(),
        winnerRoi: winnerRoi.trim(),
        winnerNickname: winnerNickname.trim(),
      } : {}
    return this.getPath(form.icon).then((pathRes) => this.props.actions.competition.upsertCompetition({
      ...formWithoutWinner,
      competitionWinner: JSON.stringify(competitionWinner),
      icon: pathRes,
    }))
      .catch((e) => {
        this.context.showNotification({
          message: e.message,
          level: 'error',
          autoDismiss: 0,
        })
      })
  }

  close = () => {
    this.context.router.push('/competitions')
  }

  renderButton = () => {
    const {state, state: {form: {id}, formState: {isLoading}}, context: {competitionProvider}} = this

    return (
      <Row>
        <Col xs={12}>
          <ButtonToolbar className="float-right">
            <Button
              id="t-competitions-editor-cancel-button"
              onClick={this.close}
              variant="outline-secondary"
              className="mr-3"
            >Cancel</Button>
            <Button
              id="t-competitions-editor-create-update-button"
              variant="success"
              disabled={isLoading}
              onClick={() => {
                const ok = this.validateForm()
                if (ok) {
                  if (!isLoading) {
                    this.setState(setIn(state, ['formState', 'isLoading'], true))
                    this.formAction().then((res) => {
                      this.setState(setIn(state, ['formState', 'isLoading'], false))
                      if (!id) {
                        this.context.router.push(`/competitions/${res.upsertCompetition.id}`)
                      }
                      competitionProvider && competitionProvider.fetch()
                    }).catch((e) => {
                      this.setState(setIn(state, ['formState', 'isLoading'], false))
                      this.context.logError(e)
                    })
                  }
                }
              }}
            >
              {id ? 'Update' : 'Create'}
            </Button>
          </ButtonToolbar>
        </Col>
      </Row>
    )
  }

  renderErrorMessage(key) {
    const {formError} = this.state
    if (has(formError, key) && formError[key]) {
      return (
        <p className="help-block" style={{color: '#a94442'}}>Field is required</p>
      )
    }
  }

  renderPrize(prize) {
    const {formError} = this.state
    const id = `t-competitions-${prize.position}-prize`
    const error = `prize${prize.position}`

    return (
      <tr key={prize.position} style={{margin: '20px'}}>
        <td style={{textAlign: 'center'}}>
          <Form.Label>{`${prize.position} Prize`}</Form.Label>
        </td>
        <td>
          <Form.Control
            id={id}
            type="text"
            // value={get(prizes, '1') || ''}
            value={prize.amount || ''}
            maxLength="255"
            isInvalid={formError[error]}
            onChange={(evt) => this.formValueChanged(['prizes', prize.position], evt.target.value, [error])}
          />
        </td>
      </tr>
    )
  }

  render() {
    const {
      form: {
        name, startDate, endDate, prizes, termsLink, applicableClientTypes, applicableCompanies,
        applicableCountries, rules, rulesCount, acceptPreviousWinners, limitedSymbols, excludedAffiliates, allowedAffiliates, winnerLogin, winnerRoi, winnerNickname,
      },
      form,
      formError,
      isUserNicknameExist,
    } = this.state
    const {data: competition} = this.props

    const prizesArray = []
    for (let i = 0; i < 10; i++) {
      if (Object.keys(prizes).includes((i + 1).toString())) {
        prizesArray.push({
          position: i + 1,
          amount: prizes[i + 1],
        })
      } else {
        prizesArray.push({
          position: i + 1,
          amount: '',
        })
      }
    }

    return (
      <Card className={style.form} style={{height: 'auto', marginBottom: '40px'}}>
        <Card.Body>
          <Row>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Name</Form.Label>
                <Form.Control
                  id="t-competitions-name"
                  type="text"
                  value={name || ''}
                  maxLength="255"
                  isInvalid={formError.name}
                  onChange={(evt) => this.formValueChanged(['name'], evt.target.value)}
                  placeholder="Enter name for competition"
                />
              </Form.Group>
            </Col>
            <Col xs={6} id="t-competitions-terms-link">
              <Form.Group>
                <Form.Label>Terms Link</Form.Label>
                <Form.Control
                  id="t-competitions-terms-link"
                  type="text"
                  value={termsLink || ''}
                  maxLength="255"
                  isInvalid={formError.termsLink}
                  onChange={(evt) => this.formValueChanged(['termsLink'], evt.target.value)}
                  placeholder="Enter the terms link"
                />
              </Form.Group>
            </Col>
            <Col xs={6} id="t-competitions-start-date">
              <DateTime
                label="Start Date (UTC)"
                value={startDate || ''}
                onChange={(value) => this.formValueChanged(['startDate'], value)}
                placeholder="Enter Start Date"
                displayTimeZone="utc"
              />
              {this.renderErrorMessage('startDate')}
            </Col>
            <Col xs={6} id="t-competitions-end-date">
              <DateTime
                label="End Date (UTC)"
                value={endDate || ''}
                onChange={(value) => this.formValueChanged(['endDate'], value)}
                placeholder="Enter End Date"
                displayTimeZone="utc"
              />
              {this.renderErrorMessage('endDate')}
            </Col>
            <Col xs={6}>
              <StylishSelect.Input
                id="t-competitions-form-countries"
                label="Countries"
                placeholderText="All Countries"
                value={applicableCountries}
                options={StylishSelect.enumToStylishOptions(countries)}
                isInvalid={formError.applicableCountries}
                multi
                clearable
                onChange={(evt)=> this.formValueChanged(['applicableCountries'], map(evt, 'value'))}
              />
            </Col>
            <Col xs={6}>
              <StylishSelect.Input
                id="t-competitions-form-client-types"
                label="Client Types"
                placeholderText="All Client Types"
                value={applicableClientTypes}
                options={StylishSelect.enumToStylishOptions(clientTypes)}
                isInvalid={formError.applicableClientTypes}
                multi
                clearable
                onChange={(evt)=> this.formValueChanged(['applicableClientTypes'], map(evt, 'value'))}
              />
            </Col>
            <Col xs={6}>
              <StylishSelect.Input
                id="t-competitions-form-companies"
                label="Companies"
                placeholderText="All Companies"
                value={applicableCompanies}
                options={StylishSelect.enumToStylishOptions(companies)}
                isInvalid={formError.applicableCompanies}
                multi
                clearable
                onChange={(evt)=> this.formValueChanged(['applicableCompanies'], map(evt, 'value'))}
              />
            </Col>
            <Col xs={6}>
              <StylishSelect.Input
                id="t-competitions-form-accept-previous-winners"
                label="Accept Previous Winners"
                value={acceptPreviousWinners ? yesNo.yes.value : acceptPreviousWinners !== undefined ? yesNo.no.value : ''}
                onChange={(evt) => this.formValueChanged(['acceptPreviousWinners'], evt.value === yesNo.yes.value)}
                options={StylishSelect.enumToStylishOptions(yesNo)}
                bsStyle={formError.acceptPreviousWinners && 'error'}
              />
            </Col>
            <Col xs={6} id="t-competitions-limit-symbols">
              <Form.Group>
                <Form.Label>Limited symbols</Form.Label>
                <Form.Control
                  id="t-competitions-form-limit-symbols"
                  type="text"
                  value={limitedSymbols || ''}
                  maxLength="1000"
                  isInvalid={formError.limitedSymbols}
                  onChange={(evt) => this.formValueChanged(['limitedSymbols'], evt.target.value)}
                  placeholder="Limited symbols"
                />
              </Form.Group>
            </Col>
            <Col xs={6} id="t-excluded-affiliates">
              <Form.Group>
                <Form.Label>Excluded affiliates</Form.Label>
                <Form.Control
                  id="t-excluded-affiliates"
                  type="text"
                  value={excludedAffiliates || ''}
                  maxLength="255"
                  isInvalid={formError.excludedAffiliates}
                  onChange={(evt) => this.formValueChanged(['excludedAffiliates'], evt.target.value)}
                  placeholder="Enter the excluded affiliates id"
                />
              </Form.Group>
            </Col>
            <Col xs={6} id="t-allowed-affiliates">
              <Form.Group>
                <Form.Label>Allowed affiliates</Form.Label>
                <Form.Control
                  id="t-allowed-affiliates"
                  type="text"
                  value={allowedAffiliates || ''}
                  maxLength="255"
                  isInvalid={formError.allowedAffiliates}
                  onChange={(evt) => this.formValueChanged(['allowedAffiliates'], evt.target.value)}
                  placeholder="Enter the allowed affiliates id"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label>Choose Competition Logo</Form.Label>
                <Form.Control
                  id="t-competitions-icon-url"
                  key="t-competitions-icon-url"
                  type="file"
                  onChange={(e) => {
                    if (e && e.target) {
                      this.formValueChanged(['icon'], e.target.files)
                    } else {
                      this.formValueChanged(['icon'], '')
                    }
                  }}
                />
                {this.renderErrorMessage('icon')}
                {competition && competition.icon
                  ? (<img alt={competition.icon} src={competition.icon} style={{maxWidth: '10%'}} />)
                  : ''
                }
              </Form.Group>
            </Col>
          </Row>
          {!isEmpty(prizesArray) && <Row>
            <Col style={{marginTop: '20px', paddingLeft: '80', paddingRight: '80px'}}>
              <Table size="lg">
                <thead style={{textAlign: 'center', fontSize: '16'}}>
                  <tr>
                    <th>Competition Position</th>
                    <th>Prize</th>
                  </tr>
                </thead>
                <tbody>
                  {prizesArray.map((c) => this.renderPrize(c))}
                </tbody>
              </Table>
            </Col>
          </Row>}
          <Row>
            <Col xs={12}><h3>Competition winner account</h3></Col>
          </Row>
          <Row>
            <Col xs={4} id="t-internal-login">
              <Form.Group>
                <Form.Label>Login</Form.Label>
                <Form.Control
                  id="t-internal-winner-login"
                  type="number"
                  value={winnerLogin || ''}
                  maxLength="255"
                  isInvalid={formError.winnerLogin}
                  onChange={(evt) => this.formValueChanged(['winnerLogin'], evt.target.value)}
                  placeholder="Enter the winner remote account ID"
                />
              </Form.Group>
            </Col>
            <Col xs={4} id="t-internal-nickname">
              <Form.Group>
                <Form.Label>Nickname</Form.Label>
                <Form.Control
                  id="t-internal-winner-nickname"
                  type="text"
                  value={winnerNickname || ''}
                  maxLength="255"
                  isInvalid={formError.winnerNickname}
                  onChange={(evt) => this.formValueChanged(['winnerNickname'], evt.target.value)}
                  placeholder="Enter the winner nickname"
                  disabled={isUserNicknameExist}
                />
              </Form.Group>
            </Col>
            <Col xs={4} id="t-internal-roi">
              <Form.Group>
                <Form.Label>ROI</Form.Label>
                <Form.Control
                  style={{appearance: 'none'}}
                  id="t-internal-winner-roi"
                  type="number"
                  value={winnerRoi || ''}
                  maxLength="255"
                  isInvalid={formError.winnerRoi}
                  onChange={(evt) => this.formValueChanged(['winnerRoi'], evt.target.value)}
                  placeholder="Enter the winner ROI"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12}><h3>Rules</h3></Col>
          </Row>
          {map(range(rulesCount), (i) => {
            const rule = competitionRules[rules[`rule${i}`]]
            return (<Row key={i}>
              <Col xs={4}>
                <StylishSelect.Input
                  id={`t-competitions-form-rule-${i + 1}`}
                  label={`Rule #${i + 1}`}
                  placeholderText="Select rule"
                  value={rules[`rule${i}`]}
                  options={StylishSelect.enumToStylishOptions(competitionRules)}
                  isInvalid={formError[`rule${i}`]}
                  onChange={(evt)=> this.formValueChanged(['rules', `rule${i}`], evt.value, [`rule${i}`])}
                />
              </Col>
              {map(get(rule, 'values', []), (v, i) => (
                <Col key={i} xs={3}>
                  <Form.Group>
                    <Form.Label>{v}</Form.Label>
                    <Form.Control
                      id={`t-competitions-form-rule-value-${i + 1}`}
                      type="text"
                      value={form[`rule${i}-${v}`] || ''}
                      maxLength="255"
                      isInvalid={formError[`rule${i}-${v}`]}
                      onChange={(evt) => this.formValueChanged([`rule${i}-${v}`], evt.target.value)}
                      placeholder={`Enter value for ${v}`}
                    />
                  </Form.Group>
                </Col>
              ))}
              <Col xs={1}>
                {(rulesCount === (i + 1) && i !== 0) && (
                  <Button
                    style={{marginTop: 30}}
                    variant="outline-secondary"
                    title="Remove"
                    onClick={() => this.removeRule()}
                  >
                    <FontAwesomeIcon icon="times" />
                  </Button>
                )}
              </Col>
            </Row>)
          })}
          <Row>
            <Col xs={12}>
              {rulesCount < 3 &&
                <Button
                  id="add-more-rules"
                  size="sm"
                  variant="link"
                  onClick={() => this.addMoreRules()}
                >
                  Add More
                </Button>
              }
            </Col>
          </Row>
          <br /><br /><br /><br /><br />
          {this.renderButton()}
        </Card.Body>
      </Card>
    )
  }
}

export default provideProps()(CompetitionForm)
// export default compose(
//   provideProps((state) => ({
//     winnerAccount: state.winnerAccount || 1,
//   })),
//   mountDataProviders({competitionWinnerProvider})
// )(CompetitionForm)
