import React from 'react'
import PropTypes from 'prop-types'
import StylishSelect from '../components/StylishSelect'
import style from './components.module.scss'
import PureComponent from '../PureComponent'
import {canQueryCampaigns} from '@bdswiss/common-permissions'

function selectOptions(campaigns) {
  return campaigns.map(c => ({value: c.id, label: c.name}))
}

export default class CampaignsFilter extends PureComponent {
  static propTypes = {
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.array,
    ]),
    onChange: PropTypes.func.isRequired,
    campaigns: PropTypes.array.isRequired,
  };

  filterChanged(selection) {
    if (!selection) {
      this.props.onChange()
    } else {
      this.props.onChange(selection)
    }
  }

  render() {
    const {value, campaigns, viewer} = this.props

    return (
      <div>
        {canQueryCampaigns(viewer) && (
          <StylishSelect
            id="t-campaigns-filter"
            placeholderText="All Campaigns"
            value={value}
            options={selectOptions(campaigns)}
            highlightIfActive
            onChange={(e) => this.filterChanged(e)}
            clearable
            className={style.campaignsFilter}
          />
        )}
      </div>
    )
  }

}
