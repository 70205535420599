const defaultStartWord = 22
const defaultEndWord = 27

/**
 * Calculates where should text preview end. Preview is never longer then 215 characters. If possible, the function
 * puts at least `startWord` words into preview and at maximum `endWord` words. If possible, the end of preview is
 * at the end of a sentence.
 *
 * The hidden part is never shorter then 15 characters.
 *
 * @param text
 * @param startWord - lowest acceptable index of last preview word
 * @param endWord - highest acceptable index of last preview word
 * @returns number - index where to split the text
 */
export function findSentenceBetweenWords(text, startWord = defaultStartWord, endWord = defaultEndWord) {
  if (!text) {
    return -1
  }
  const maximumBoundary = 200
  let match, count = 0, startWordIndx = 0, endWordIndx = Math.min(text.length, maximumBoundary)

  // find start word and end word boundaries
  const regexp = /[a-z0-9]+/gi
  while ((match = regexp.exec(text)) !== null) {
    const wordLength = match[0] ? match[0].length : 0
    const currentIndex = match.index + wordLength
    const maximumBoundaryReached = currentIndex > maximumBoundary

    if (!maximumBoundaryReached && count <= startWord) {
      startWordIndx = currentIndex
    }
    if (!maximumBoundaryReached && count <= endWord) {
      endWordIndx = currentIndex
    }
    count++
  }

  // Prefer sentence ending
  const endOfSentence = text.substring(startWordIndx, endWordIndx).indexOf('. ')
  const previewEnd = endOfSentence < 0 ? endWordIndx : startWordIndx + endOfSentence + 1

  // dont bother making preview if hidden part is too short
  const hiddenPartLength = text.length - previewEnd
  return hiddenPartLength < 15 ? text.length : previewEnd
}

/**
 * Calculates where should text preview end. Preview ends at the end of first paragraph - two consecutive end
 * lines (as in markdown).
 *
 * @param text
 * @returns number - index where to split the text
 */
export function endOfParagraph(text) {
  if (!text) {
    return -1
  }

  return text.indexOf('\n\n')
}

export function calcPreview(text, endOfPreview) {
  if (endOfPreview < 0) {
    return {
      content: text,
      hasMore: false,
    }
  }

  const preview = text.substring(0, endOfPreview)
  const hasMore = endOfPreview < text.length

  return {
    content: preview,
    hasMore: hasMore,
  }

}

/**
 * Shorten `fileName` longer then `length`. Return first few letters, … and extension of file.
 * Otherwise return original `fileName`.
 *
 * @param fileName - fileName to be shorten
 * @param length - max length of fileName
 *
 * @returns string
 */
export function shortenFilename(fileName, length = 30) {
  if (fileName.length > length) {
    const HELLIP = '…'
    const extension = fileName.slice(fileName.lastIndexOf('.') - 4)
    return `${fileName.slice(0, length - extension.length)}${HELLIP}${extension}`
  }

  return fileName
}
