import React from 'react'
import PropTypes from 'prop-types'
import {filter, find, get, has, map} from 'lodash'
import {permissions} from '@bdswiss/common-permissions'
import {Card, Col, Container, Pagination, Row, Table} from 'react-bootstrap'
import {netDepositsProvider} from './providers'
import style from './netdeposits.module.scss'
import {events} from '../enums'
import {getPageCount, getPageRange} from '../useful'
import {compose, mountDataProviders, predispatch, provideProps, uiMount, checkOneOfPermissions} from '../decorators'
import NetDepositsFilter from './NetDepositsFilter'
import PureComponent from '../PureComponent'
import {orderDirections} from '@bdswiss/common-enums'
import FontAwesomeIcon from '../components/FontAwesomeIcon'
import classNames from 'classnames'
import moment from 'moment'

class NetDeposits extends PureComponent {
  static contextTypes = {
    netDepositsProvider: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    logError: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props)
    this.fetchProvider = this.fetchProvider.bind(this)
    this.doSortByOrderAndOrderDirection = this.doSortByOrderAndOrderDirection.bind(this)
    this.paginationSelectHandle = this.paginationSelectHandle.bind(this)
    this.paginationClickHandle = this.paginationClickHandle.bind(this)
    this.state = {}
  }

  componentDidMount() {
    window.addEventListener(events.fetchProviders.key, this.fetchProvider)
  }

  componentWillUnmount() {
    window.removeEventListener(events.fetchProviders.key, this.fetchProvider, false)
  }

  fetchProvider() {
    this.context.netDepositsProvider.fetch()
  }

  doSortByOrderAndOrderDirection(e) {
    const {field} = e.currentTarget.dataset
    const {netDepositsSort} = this.props
    const {orderBy, orderDirection} = netDepositsSort


    let newSort = {}
    if (orderBy === field && orderDirection === orderDirections.ascending.key) {
      newSort = {orderBy: field, orderDirection: orderDirections.descending.key}
    } else if (orderBy === field && orderDirection === orderDirections.descending.key) {
      newSort = {}
    } else if (orderBy !== field) {
      newSort = {orderBy: field, orderDirection: orderDirections.ascending.key}
    }

    this.props.uiDispatch(`Sorting net deposits by ${newSort.orderBy || '-'} and order ${newSort.orderDirection || '-'}`, (state) => ({
      ...state,
      netDepositsSort: newSort
    }), [netDepositsSort])
  }

  getSortArrow(field) {
    const {netDepositsSort} = this.props
    const arrowDirection = netDepositsSort?.orderDirection === orderDirections.descending.key ? 'down' : 'up'

    if (netDepositsSort?.orderBy === field) {
      return `long-arrow-${arrowDirection}`
    }
    return 'arrows-v'
  }

  paginationSelectHandle (e, selectedEvent) {
    this.props.uiDispatch('Show page', (state) => ({
      ...state,
      netDepositsPage: selectedEvent.eventKey
    }))
  }

  paginationClickHandle (e) {
    this.props.uiDispatch('Show page', (state) => ({
      ...state,
      netDepositsPage: +e.currentTarget.dataset.page
    }))
  }

  render() {
    const {agents, netDeposits, netDepositsPage, netDepositsCount,netDepositsCommissionSize, netDepositsFilter, netDepositsSum/*, viewer*/} = this.props
    const agentId = netDepositsFilter?.agentId
    const filteredNetDeposits = filter(netDeposits, (l) => !has(l, 'client') || get(l, 'client') !== null)
    const agent = agentId && find(agents, {id: Number(agentId)})
    const agentName = `${agent?.firstName} ${agent?.lastName}`

    return (
      <Container className={style.container}>
        <Row>
          <Col xs={12}>
            <div className={'h4'}>
              Retention Report
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <NetDepositsFilter />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <div className={'h6'}>
              {agent && <p><b>Agent:</b> {agentId} {agentName}</p>}
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <Card /*style={{overflowX: 'scroll'}}*/>
              <Card.Body style={{overflowX: 'scroll'}}>
                <Table bordered hover className={style.table}>
                  <thead>
                    <tr>
                      {[
                        {header: 'Date', sortKey: 'transactionCreatedAt', minWidth: 100},
                        {header: 'Client ID', sortKey: 'clientId', minWidth: 100},
                        {header: 'Transaction Type', sortKey: 'transactionType', minWidth: 100},
                        {header: 'Vendor', sortKey: 'vendor', minWidth: 100},
                        {header: 'Amount', sortKey: 'amount', minWidth: 100},
                        {header: 'Currency', sortKey: 'currency', minWidth: 100},
                        {header: 'FTD', sortKey: 'ftd', minWidth: 100},
                        {header: 'Effective Call'/*, sortKey: 'effectiveCall'*/, minWidth: 100},
                      ].map(({header, sortKey, minWidth}) => (
                        <th key={sortKey || header} style={{minWidth}}>
                          <span>{header}</span>
                          {!!sortKey && (<FontAwesomeIcon
                            icon={this.getSortArrow(sortKey)}
                            onClick={this.doSortByOrderAndOrderDirection}
                            data-field={sortKey}
                          />)}
                        </th>
                      ))}
                    </tr>
                  </thead>

                  <tbody>
                    {map(filteredNetDeposits, (deposit, i) => {
                      const {clientId, transactionType, amount, ftd, effectiveCall, transactionCreatedAt, currency, vendor} = deposit
                      return <tr key={i}
                        id={`t-sc-id-${i}`}
                        className={classNames(['t-sc'])}
                        style={{cursor: 'pointer'}}
                        onClick={e => this.gotoClient(deposit)}
                      >
                        <td>{moment(transactionCreatedAt).format('LLL')}</td>
                        <td>{clientId}</td>
                        <td>{transactionType}</td>
                        <td>{vendor}</td>
                        <td>{parseFloat(amount).toFixed( 2 )}</td>
                        <td>{currency}</td>
                        <td>{ftd ? 'Yes' : 'No'}</td>
                        <td>{effectiveCall  ? 'Yes' : 'No'}</td>
                      </tr>
                    })}
                  </tbody>
                </Table>
              </Card.Body>
            </Card>

            <Row className="mt-4">
              <Col xs={12}>
                <Pagination
                  size="sm"
                  className="justify-content-center"
                  onSelect={this.paginationSelectHandle}
                >
                  {getPageRange(netDepositsPage, getPageCount(netDepositsCount)).map((page) => {
                    if (page === 'LEFT_PAGE') {
                      return <Pagination.Prev
                        key={page}
                        onClick={this.paginationClickHandle}
                        data-page={netDepositsPage - 1}
                      />
                    }

                    if (page === 'RIGHT_PAGE') {
                      return <Pagination.Next
                        key={page}
                        onClick={this.paginationClickHandle}
                        data-page={netDepositsPage + 1}
                      />
                    }

                    return <Pagination.Item
                      active={page === netDepositsPage}
                      key={page}
                      onClick={this.paginationClickHandle}
                      data-page={page}
                    >
                      {page}
                    </Pagination.Item>
                  })}
                </Pagination>
              </Col>
            </Row>

            {netDepositsSum?.length && <Row>
              <Col xs={12}>
                <Card /*style={{overflowX: 'scroll'}}*/>
                  <Card.Body /*style={{overflowX: 'scroll'}}*/>
                    <Table bordered className={style.table}>
                      <thead>
                        <tr>
                          <th style={{minWidth: 100}}>{'Currency'}</th>
                          <th style={{minWidth: 100}}>{'Deposits Count'}</th>
                          <th style={{minWidth: 100}}>{'Withdrawals Count'}</th>
                          <th style={{minWidth: 100}}>{'Net Amount'}</th>
                          <th style={{minWidth: 70}}>{`Commission Earned (${parseFloat(netDepositsCommissionSize * 100).toFixed(2)}%)`}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {map(netDepositsSum, (item, i) =>
                          <tr key={i}
                            id={`t-sum-id-${i}`}
                            className={classNames(['t-sc'])}
                          >
                            <td> {item.currency} </td>
                            <td> {item.depositCount}</td>
                            <td> {item.withdrawalCount} </td>
                            <td> {parseFloat(item.totalAmount).toFixed( 2 )} </td>
                            <td> {parseFloat(item.totalAmount * netDepositsCommissionSize).toFixed( 2 )} </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
            </Row>}
          </Col>
        </Row>
      </Container>
    )
  }

  gotoClient(item) {
    let route = `/clients/${item.clientId}`
    if (item.transactionType === 'deposit') {
      route += `/deposits/${item.transactionId}`
    }
    else {
      route += `/withdrawals/${item.transactionId}`
    }
    window.open(route, '_blank').focus()
  }
}

export default compose(
  checkOneOfPermissions(permissions.retention_report_read,  permissions.own_retention_report_read),
  uiMount(() => ['ui', 'netDeposits']),
  predispatch((props) => props.uiDispatch(
    'Initialize ui/net deposits report',
    (state) => ({
      ...state,
      ...(!state.netDepositsSort ? {
        netDepositsSort: {
          orderBy: 'transactionCreatedAt',
          orderDirection: orderDirections.descending.key
        }
      } : {})
    }))),

  provideProps((state, uiState) => {
    const {agents, netDeposits, netDepositsCount, netDepositsSum,netDepositsCommissionSize} = state
    const {netDepositsSort, netDepositsPage, netDepositsFilter} = uiState
    return ({
      agents,
      netDeposits,
      netDepositsCount,
      netDepositsSum,
      netDepositsSort,
      netDepositsFilter,
      netDepositsPage,
      netDepositsCommissionSize,
    })
  }),

  mountDataProviders({netDepositsProvider}),
)(NetDeposits)
