import {getFetchInterval} from '../../useful'

export const permissionDetailsProvider = {
  fetchInterval: getFetchInterval,

  getQuery: (props) => {
    if (!props.permissionId) {
      return null
    }

    const query = `{
      permission(
        id: "${props.permissionId}"
      ) {
        id
        name
        description
        roles {
          id
          name
          description
          users {
            id
            email
            firstName
            lastName
          }
        }
      }
    }`
    return query
  },

  onData: (res, dispatch) =>
    dispatch(
      'Permission details loaded',
      (state, res) => ({
        ...state,
        permission: {
          ...res.permission,
          // permissions: res.role.permissions
          //   ? res.role.permissions.sort((a, b) => a.name.localeCompare(b.name))
          //   : [],
        },
      }),
      [res]
    ),
}
